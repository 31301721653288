import React, { useEffect, useState } from 'react'
import { Popover } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Currency from 'static/Currency/Currency'
import { CustomTooltip } from 'global/globalComponents'
import { ReactComponent as DollarCircle } from 'static/svg/dollar-circle.svg'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

const CostPopup = ({ currency, taskCost, handleUpdateCost, canUpdate }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cost, setCost] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const currencySymbol = Currency[currency].symbol_native

  useEffect(() => {
    setCost(taskCost)
  }, [taskCost])

  // const handleSaveChange = () => {
  //   const value = parseInt(cost)

  //   if (taskCost === value) return
  //   handleUpdateCost(value || null)
  // }

  const handleCostChange = e => {
    setCost(e.target.value)
  }

  const removeCost = () => {
    setCost('')
    // handleUpdateCost(null)
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    closePopper()
  }

  const closePopper = () => {
    const value = parseInt(cost)

    if (taskCost === value) return

    handleUpdateCost(value || null)
    setAnchorEl(null)
  }

  const handleOpenCostPopper = e => {
    if (!canUpdate) {
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return
    }

    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <CustomTooltip title={t('COST')} placement="top">
        <button
          className="flex items-center text-gray-500 dark:text-dark-light"
          onClick={handleOpenCostPopper}
        >
          <DollarCircle />
          {Boolean(parseInt(cost)) && (
            <div className="text-xs ml-1 -mb-1">
              <span>{currencySymbol}</span>
              <span>{cost}</span>
            </div>
          )}
        </button>
      </CustomTooltip>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closePopper}
        style={{ top: '10px', left: '-30px', zIndex: 2000 }}
      >
        <div className="px-4 pt-2 pb-6">
          <div className="flex space-x-2 items-center relative">
            <h3 className="font-semibold text-base tracking-wide flex-1 dark:text-dark-light capitalize">
              {t('COST')}
            </h3>
            <form onSubmit={handleOnSubmit}>
              <div className="flex border px-2 py-1 rounded w-24">
                <input
                  type="number"
                  value={cost}
                  onChange={handleCostChange}
                  autoFocus
                  // onBlur={handleSaveChange}
                  className="bg-transparent min-w-0 text-sm flex-1 dark:text-dark-light"
                  placeholder={currencySymbol}
                  min={0}
                  max={9999}
                />
                <button
                  className={clsx(cost ? 'visible' : 'invisible')}
                  onClick={removeCost}
                  type="button"
                >
                  <CloseIcon
                    style={{ fontSize: 14 }}
                    className="text-gray-500"
                  />
                </button>
              </div>
            </form>
          </div>
          <hr className="mt-4 mb-1" />
          <p className="text-xs dark:text-dark-light">
            {t('CHANGES_ARE_AUTOMATICALLY_SAVED')}
          </p>
        </div>
      </Popover>
    </>
  )
}

export default CostPopup
