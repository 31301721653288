import axios from 'axios'
import { ONBOARDING } from './reducer'
import {
  Uploader,
  catchAsync,
  catchAsyncDispatch,
  errorMessages,
  getQueryString
} from 'utils'
import ObjectID from 'bson-objectid'
import { t } from 'i18next'

// NOTE: Here i'm transforming the old intake form data into new
// const transformIntakeFormData = properties => {
//   const propertiesObj = {
//     ...properties,
//     labelPosition: properties.labelPosition || 'top'
//   }

//   propertiesObj.fieldsGroup = properties.fields.map(field => {
//     const obj = {
//       id: field.id,
//       name: field.type === 'link' ? 'URL' : field.name || field.label,
//       label: field.label,
//       required: field.required,
//       type: field.type === 'link' ? 'text' : field.type
//     }

//     if (field.options) {
//       obj.options = field.options.map(option => option.label).join('|')
//       obj.selected = ''

//       if (field.value) {
//         if (Array.isArray(field.value)) {
//           obj.selected = field.value.map(val => val.value).join('|')
//         } else {
//           obj.selected = field.value.value
//         }
//       }
//     } else {
//       obj.value = field.value
//     }

//     return obj
//   })

//   delete propertiesObj.fields

//   propertiesObj.fieldsGroup.unshift(
//     {
//       id: ObjectID().toString(),
//       name: 'heading1',
//       text: t('INTAKE_FORM'),
//       type: 'heading',
//       appendClass: 'text-2xl py-1 font-medium text-primary-main'
//     },
//     {
//       id: ObjectID().toString(),
//       name: 'divider',
//       type: 'divider',
//       appendClass: 'border-gray-300 my-2'
//     }
//   )
//   return propertiesObj
// }

// const transformDocumentStepData = properties => {
//   const obj = { ...properties, fileData: { ...properties.document.value } }
//   delete obj.document

//   return obj
// }

// const transformVideoStepData = properties => {
//   const obj = {
//     ...properties,
//     thumb: properties.thumb.value,
//     videoUrl: properties.videoUrl.value
//   }
//   return obj
// }

// const transformWorkflowData = workflowData => {
//   const obj = { ...workflowData }

//   for (let j = 0; j < obj.steps.length; j++) {
//     const step = obj.steps[j]

//     if (step.name === 'intakeForm' && step.properties.fields) {
//       step.properties = transformIntakeFormData(step.properties)
//     }
//     if (step.name === 'document' && step.properties.document) {
//       step.properties = transformDocumentStepData(step.properties)
//     }
//     if (step.name === 'video' && typeof step.properties.videoUrl === 'object') {
//       step.properties = transformVideoStepData(step.properties)
//     }
//   }

//   return obj
// }

/**
 * Fetch all onboarding templates (For Agency)
 * @param {Object|null} data
 * @param {Function} callback function (optional)
 */
export const fetchAllWorkflowTemplates = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/onboarding/user',
      method: 'GET'
    })

    dispatch({ type: ONBOARDING.FETCHED, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}
/**
 * Fetch all onboarding templates (For Agency)
 * @param {Object|null} data
 * @param {Function} callback function (optional)
 */
export const fetchPredefinedWorkflowTemplates = catchAsync(
  async (data, callback) => {
    const res = await axios({
      url: '/onboarding/user?type=predefined_template',
      method: 'GET'
    })

    if (callback) callback(res.data)
  }
)

/**
//  * Fetch all onboarding workflows (For Client)
//  * @param {Object|null} data
//  * @param {Function} callback function (optional)
//  */
// export const fetchClientWorkflows = (data, callback) => {
//   return catchAsyncDispatch(async dispatch => {
//     const res = await axios({
//       url: '/onboarding/user',
//       method: 'GET'
//     })

//     dispatch({ type: ONBOARDING.FETCHED, payload: res.data })
//     if (callback) callback(res.data)
//   }, callback)
// }

/**
 * Fetch all onboarding workflows (For Client)
 * @param {String} data.id workflowId
 * @param {String | undefined} data.query optional
 * @param {Function} callback function (optional)
 */
export const fetchWorkflowResponseById = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/onboardingResponse/${data.id}${getQueryString(data.query)}`,
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * Public api to fetch all workflow by its id (For Client)
 * @param {String} data.id workflowId
 * @param {String | undefined} data.query optional
 * @param {Function} callback function (optional)
 */
export const fetchWorkflowByIdPublic = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/onboarding/public/${data.id}${getQueryString(data.query)}`,
    method: 'GET',
    isPublic: true
  })

  if (callback) callback(res.data)
})

/**
 * Create new workflow
 * @param {Object} data.data templateName, isActive, welcomeText, data
 * @param {Function} callback function (optional)
 */
export const createWorkflow = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/onboarding',
      method: 'POST',
      data: data.body
    })

    dispatch({ type: ONBOARDING.ADD_TEMPLATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Create workflow response
 * @param {Object} data.data templateName, isActive, welcomeText, data
 * @param {Function} callback function (optional)
 */
export const createWorkflowResponse = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/onboardingResponse',
      method: 'POST',
      data: data.data
    })

    dispatch({ type: ONBOARDING.ADD_TEMPLATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Create new onboarding template (DEVELOPMENT ONLY)
 */
// const createPredefinedWorkflowTemplate = catchAsync(async () => {
//   await axios({
//     url: '/onboarding',
//     method: 'POST',
//     data: {
//       welcomeText: 'Getting Started',
//       templateName: 'SEO Client Onboarding Funnel',
//       description: 'lorem ipsum',
//       type: 'predefined_template',
//       steps: [],
//       stepsId: [
//         '64b7b7b6a0d17c00527129f4',
//         '64b7b85fa0d17c0052712a00',
//         '64b7b85fa0d17c0052712a02'
//       ],
//       icon: 'Computer'
//     }
//   })
// })

// createPredefinedWorkflowTemplate()

/**
 * Delete workflow template (DEVELOPMENT ONLY)
 */
// export const deleteGlobalWorkflowTemplate = catchAsync(async (id) => {
//   await axios({
//     url: `/workflowTemplate/${id}`,
//     method: 'DELETE'
//   })
// })

// deleteGlobalWorkflowTemplate('64ba63e476b024004dcbaa21')

// /**
//  * Fetch all workflow templates
//  * @param {null | Object} data
//  * @param {Function} callback function (optional)
//  */
// export const fetchAllWorkflowTemplates = catchAsync(async (data, callback) => {
//   const res = await axios({
//     url: '/workflowTemplate',
//     method: 'GET'
//   })

//   if (callback) callback(res.data)
// })

// /**
//  * Create new onboarding template
//  * @param {Object} data.data templateName, isActive, welcomeText, data
//  * @param {String} data.token
//  * @param {Function} callback function (optional)
//  */
// export const createNewTemplatePublic = catchAsync(async (data, callback) => {
//   const res = await axios({
//     url: '/onboarding',
//     method: 'POST',
//     data: data.data,
//     headers: {
//       Authorization: data.token
//     }
//   })

//   if (callback) callback(res.data)
// })

/**
 * Update Template data
 * @param {String} data.id template id
 * @param {Object} data.data update
 * @param {Function} callback function (optional)
 */

export const updateTemplate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/onboarding/${data.id}`,
      data: data.data
    })

    dispatch({ type: ONBOARDING.UPDATE_TEMPLATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update workflow response
 * @param {String} data.id template id
 * * @param {String} data.stepId step id
 * @param {Object} data.data update
 * @param {Function} callback function (optional)
 */
export const updateWorkflowStepByIdForClient = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/onboardingResponse/step/${data.id}/${data.stepId}`,
      data: data.data
    })

    dispatch({ type: ONBOARDING.UPDATE_TEMPLATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

// /**
//  * Update Template data
//  * @param {String} data.id template id
//  * @param {Object} data.data update
//  * @param {String} data.token
//  * @param {Function} callback function (optional)
//  */

// export const updateTemplatePublic = catchAsync(async (data, callback) => {
//   const res = await axios({
//     method: 'PUT',
//     url: `/onboarding/${data.id}`,
//     data: data.data,
//     headers: {
//       Authorization: data.token
//     }
//   })

//   if (callback) callback(res.data)
// })

/**
 * Delete Template data
 * @param {String} ID template id
 * @param {Function} callback function (optional)
 */
export const deleteTemplate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/onboarding/${data.id}`
    })

    dispatch({ type: ONBOARDING.DELETE_ONE, payload: data.id })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete Template data
 * @param {String} ID template id
 * @param {Function} callback function (optional)
 */
export const deleteWorkflowResponse = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/onboardingResponse/${data.id}`
    })

    dispatch({ type: ONBOARDING.DELETE_ONE, payload: data.id })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch steps completed or not data
 * @param {Object} data.data template (workflow id), response (client response data)
 * @param {Function} callback optional
 */
export const submitClientWorkflowData = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/clientonboardingresponse',
    method: 'POST',
    data: data.data
  })

  if (callback) callback(res.data)
})

/**
 * Update steps completed or not data
 * @param {String} data.id submitted workflow data id
 * @param {Object} data
 * @param {Function} callback optional
 */
export const updateSubmittedWorkflowData = catchAsync(
  async (data, callback) => {
    const res = await axios({
      url: `/clientonboardingresponse/${data.id}`,
      method: 'PUT',
      data: data.data
    })

    if (callback) callback(res.data)
  }
)

/**
 * Fetch client workflow data
 * @param {Object|null} data
 * @param {Function} callback optional
 */
export const fetchClientWorkflowResponses = catchAsync(
  async (data, callback) => {
    const res = await axios({
      url: '/onboardingResponse/user?type=response',
      method: 'GET'
    })

    if (callback) callback(res.data)
  }
)

/**
 * Client signup before onboarding
 * @param {Object} data (name, email, password, role etc)
 * @param {Function} callback optional
 */
export const onboardingClientSignup = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/user/signup',
      data: data.data,
      isPublic: true
    })

    if (callback) callback(res)
  } catch (err) {
    if (err.response.status === 409) {
      callback(err, true)
    } else {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

// /**
//  * Send otp to client's email
//  * @param {String} data.email
//  * @param {Function} callback optional
//  */
// export const sendOTP = catchAsync(async (data, callback) => {
//   const res = await axios({
//     url: '/onboarding/client/emailotpsend',
//     method: 'POST',
//     data: data.data
//   })

//   if (callback) callback(res.data)
// })

// /**
//  * Verify otp
//  * @param {String} data.email
//  * @param {Number} data.otp
//  * @param {Function} callback optional
//  */
// export const verifyOTP = catchAsync(async (data, callback) => {
//   const res = await axios({
//     url: '/onboarding/client/emailotpverify',
//     method: 'POST',
//     data: data.data
//   })

//   if (callback) callback(res.data)
// })

/**
 * Fetch onboarding proposal response
 * @param {String} data.proposalId proposal template id
 * @param {String} data.query
 * @param {Function} callback optional
 */
export const fetchOnboardingProposalResponse = catchAsync(
  async (data, callback) => {
    const res = await axios({
      url: `/proposal/onboarding/${data.proposalId}${getQueryString(
        data.query
      )}`,
      method: 'GET'
    })

    if (callback) callback(res.data)
  }
)

/**
 * Fetch onboarding proposal response
 * @param {String} data.workflowId
 * @param {String} data.stepId
 * @param {Object} data.data
 * @param {Function} callback optional
 */
export const updateWorkflowStepProperties = catchAsync(
  async (data, callback) => {
    const res = await axios({
      url: `/onboarding/step/public/${data.workflowId}/${data.stepId}`,
      method: 'PUT',
      data: data.data,
      isPublic: true
    })

    if (callback) callback(res.data)
  }
)

/**
 * upload file
 * @param {Object} fileData fileName, file
 * @param {String} data.data.invoiceId
 * @param {Function} callback callback function
 */
export const uploadFile = (data, callback) => {
  try {
    const uploader = new Uploader({
      ...data.fileData,
      module: 'onboarding',
      metaData: data.data
    })

    uploader
      .onError(error => {
        callback(error.message ?? 'File uploading failed!', true)
      })
      .onComplete(res => {
        callback(res)
      })

    uploader.start()
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE)
  }
}

/**
 * Fetch active workflow for client
 * @param {data} null
 * @param {Function} callback callback function
 */
export const fetchActiveWorkflow = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/onboarding/active`,
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * Create workflow contents (steps, intake forms) (ONLY FOR DEVELOPMENT)
 * @param {Object} data
 * @param {Function} callback callback function
 */
export const createWorkflowContent = catchAsync(async (data, callback) => {
  await axios({
    url: '/workflowContentTemplate',
    method: 'POST',
    data: data.data
  })
})

/**
 * Fetch workflow contents
 * @param {Object | undefined} data
 * @param {Function} callback callback function
 */
export const fetchWorkflowContents = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/workflowContentTemplate${getQueryString(data.query)}`,
      method: 'GET'
    })

    // Removing type key (not needed + don't want this key while copying it)
    const transformedData = res.data.map(item => {
      const obj = { ...item }
      delete obj.type
      return obj
    })

    if (callback) callback(transformedData)
  }, callback)
}

// /**
//  * Delete workflow content by id (ONLY FOR DEVELOPMENT)
//  * @param {String} data.id content id
//  * @param {Function} callback callback function
//  */
// export const deleteWorkflowContentById = catchAsync(async (data, callback) => {
//   const res = await axios({
//     url: `/workflowContentTemplate/${data.id}`,
//     method: 'DELETE'
//   })

//   callback(res.data)
// })

// deleteWorkflowContentById({ id: '64b7b521a0d17c00527129eb' })

export const activateWorkflow = data => {
  return { type: ONBOARDING.SWITCH_ACTIVATION, payload: data }
}

// /**
//  * @param {Object} data name, isActive, welcomeText, data
//  * @param {Function} callback function (optional)
//  */
// export const createNewTemplate = catchAsync(async (data, callback) => {
//   const res = await axios({
//     url: '/onboarding',
//     method: 'POST',
//     data: data.data
//   })

//   if (callback) callback(res.data)
// })

export const addCurrentOnboardingTemplate = template => {
  return {
    type: ONBOARDING.ADD_CURRENT_TEMPLATE,
    payload: template
  }
}

export const reorderSteps = ({ sourceIndex, destinationIndex }) => {
  return {
    type: ONBOARDING.REORDER_STEPS,
    payload: { sourceIndex, destinationIndex }
  }
}

export const reorderIntakeFormFields = ({ stepId, dropResult }) => {
  return {
    type: ONBOARDING.INTAKE_FIELD_MOVE,
    payload: { stepId, dropResult }
  }
}

export const addStep = (data, index) => {
  return { type: ONBOARDING.ADD_STEP, payload: { data, index } }
}

export const removeStep = ({ stepId }) => {
  return {
    type: ONBOARDING.REMOVE_STEP,
    payload: {
      id: stepId
    }
  }
}

export const addEvent = ({ stepId, eventData }) => {
  return { type: ONBOARDING.ADD_EVENT, payload: { stepId, eventData } }
}

export const removeEvent = ({ stepId, eventIndex }) => {
  return { type: ONBOARDING.REMOVE_EVENT, payload: { stepId, eventIndex } }
}

export const updateEvent = ({ stepId, eventIndex, key, value }) => {
  return {
    type: ONBOARDING.UPDATE_EVENT,
    payload: { stepId, eventIndex, key, value }
  }
}
// /**
//  * Show error message in related steps
//  * @param {Object} object stepId, key
//  */
// export const showStepFieldError = ({ stepId, key }) => {
//   return { type: ONBOARDING.STEP_FIELD_ERROR, payload: { stepId, key } }
// }

/**
 * Add new instruction
 * @param {Object} object stepId, value
 */
export const addNewInstruction = ({ stepId, value }) => {
  return {
    type: ONBOARDING.ADD_INSTRUCTIONS_FIELD,
    payload: {
      stepId,
      value: {
        id: ObjectID().toString(),
        text: value
      }
    }
  }
}

export const changesSaved = () => {
  return { type: ONBOARDING.CHANGES_SAVED }
}

export const addIntakeFormField = ({ stepId, data }) => {
  return {
    type: ONBOARDING.ADD_INTAKE_FIELD,
    payload: {
      id: stepId,
      data
    }
  }
}

export const updateIntakeFormField = ({ stepId, fieldId, prop, value }) => {
  return {
    type: ONBOARDING.UPDATE_INTAKE_FIELD,
    payload: {
      stepId,
      fieldId,
      prop,
      value
    }
  }
}

export const updateStep = ({ stepId, prop, value }) => {
  return {
    type: ONBOARDING.UPDATE_STEP,
    payload: {
      id: stepId,
      prop,
      value
    }
  }
}

/**
 *
 * @param {String} stepId
 * @param {Object} data
 * @returns
 */
export const replaceStepData = ({ stepId, data }) => {
  console.log({ stepId, data })
  return {
    type: ONBOARDING.UPDATE_STEP_ALL,
    payload: { stepId, data }
  }
}

export const updateStepProperties = ({ stepId, prop, value }) => {
  return {
    type: ONBOARDING.UPDATE_STEP_PROPERTIES,
    payload: {
      id: stepId,
      prop,
      value
    }
  }
}
export const changesDetected = () => {
  return { type: ONBOARDING.CHANGES_DETECTED }
}

// =========== DEVELOPMENT ===========
// const handleCreateSteps = () => {
// // ====== Document =========
// createWorkflowContent({
//   data: { ...getFormData('intakeForm'), type: 'default' }
// })
// // ====== Document =========
// createWorkflowContent({
//   data: {
//     name: 'document',
//     heading: t('DOCUMENT'),
//     description: ' ',
//     type: 'default',
//     properties: {
//       fileData: {},
//       isComplete: false
//     }
//   }
// })
// // ==== Video =======
// createWorkflowContent({
//   data: {
//     name: 'video',
//     heading: t('VIDEO'),
//     description: ' ',
//     type: 'default',
//     properties: {
//       videoUrl: '',
//       thumb: '',
//       isComplete: false
//     }
//   }
// })
// // ==== Instructions ======
// createWorkflowContent({
//   data: {
//     name: 'instructions',
//     heading: t('INSTRUCTIONS'),
//     description: ' ',
//     type: 'default',
//     properties: {
//       list: [],
//       isComplete: false
//     }
//   }
// })
// // ==== Proposal ======
// createWorkflowContent({
//   data: {
//     name: 'proposal',
//     heading: t('PROPOSAL'),
//     description: ' ',
//     type: 'default',
//     properties: {
//       proposalId: null,
//       proposalUniqueId: null,
//       proposalClientResponseId: null,
//       isComplete: false
//     }
//   }
// })
// // ==== Button link ======
// createWorkflowContent({
//   data: {
//     name: 'buttonLink',
//     heading: t('BUTTON_LINK'),
//     description: ' ',
//     type: 'default',
//     properties: {
//       text: 'Click here',
//       url: '',
//       isComplete: false
//     }
//   }
// })
// // ==== Payment ======
// createWorkflowContent({
//   data: {
//     name: 'payment',
//     heading: t('PAYMENT'),
//     description: ' ',
//     type: 'default',
//     properties: {
//       currency: 'USD',
//       tax: '',
//       serviceId: '', //for service
//       invoiceCategory: 'custom', //service, custom
//       products: [], //for custom
//       isComplete: false
//     }
//   }
// })
// ==== Meeting ======
// createWorkflowContent({
//   data: {
//     name: 'meeting',
//     heading: 'Meeting',
//     description: ' ',
//     type: 'default',
//     properties: {
//       meetingUrl: '',
//       platform: 'googleMeet',
//       isComplete: false
//     }
//   }
// })
// }

// handleCreateSteps()

// const createIntakeFormTemplates = () => {
//   Object.values(formsMetadata)
//     .filter(item => item.templateName !== 'intakeForm')
//     .forEach(item => {
//       const formData = getFormData(item.templateName)
//       createWorkflowContent({
//         data: { ...formData, type: 'other' }
//       })
//     })
// }

// createIntakeFormTemplates()

// fetchWorkflowContents((res, err) => console.log('res', res, err))

// const handleCreatePredefinedTemplates = async () => {
//   // ======= First fetching predefined workflow contents (steps)
//   const res = await axios({
//     url: '/workflowContentTemplate?type=default',
//     method: 'GET'
//   })

//   // Removing type key (not needed + don't want this key while copying it)
//   const workflowContentsData = res.data.map(item => {
//     const obj = { ...item }
//     delete obj.type
//     return obj
//   })

//   const SEOClientOnboardingFunnelSteps = []

//   workflowContentsData.forEach(step => {
//     if (step.name === 'intakeForm') SEOClientOnboardingFunnelSteps[0] = step
//     else if (step.name === 'proposal') SEOClientOnboardingFunnelSteps[1] = step
//     else if (step.name === 'payment') SEOClientOnboardingFunnelSteps[2] = step
//   })

//   const handleCreateTemplate = async steps => {
//     await axios({
//       url: '/onboarding',
//       method: 'POST',
//       data: {
//         welcomeText: 'Getting Started',
//         templateName: 'SEO Client Onboarding Funnel',
//         description: 'lorem ipsum',
//         type: 'predefined_template',
//         steps: steps,
//         icon: 'Computer'
//       }
//     })
//   }

//   handleCreateTemplate(SEOClientOnboardingFunnelSteps)
// }

// handleCreatePredefinedTemplates()
