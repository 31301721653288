import { t } from 'i18next'

const errorMessages = {
  CHANGES_SAVED_MESSAGE: 'Saved successfully',
  CREATED_MESSAGE: 'Created successfully!',
  ERROR_MESSAGE: 'Some error occurred! please try again later',
  NO_DATA: 'No data found!',
  NOT_AUTHORIZED: 'You do not have permission to perform this action',
  PROJECT_COMPLETE:
    'This project is marked as complete, you cannot edit it anymore!',
  LINK_COPIED: 'Link copied!'
}
export const getErrorMessages = () => ({
  CHANGES_SAVED_MESSAGE: t('SAVED_SUCCESSFULLY'),
  CREATED_MESSAGE: t('CREATED_SUCCESSFULLY'),
  ERROR_MESSAGE: t('ERROR_OCCURRED'),
  NO_DATA: t('NO_DATA_FOUND'),
  NOT_AUTHORIZED: t('NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'),
  PROJECT_COMPLETE: t('PROJECT_COMPLETE_ERR_MSG'),
  LINK_COPIED: t('LINK_COPIED')
})

export default errorMessages
