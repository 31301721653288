import React, { useEffect, useState } from 'react'
import { Badge } from '@material-ui/core'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Link, useLocation, useHistory } from 'react-router-dom'
import SidebarCustomTooltip from './SidebarCustomTooltip'
import { toCamelCase } from 'utils'
import { ArrowRight } from '@material-ui/icons'
import StatsPopper from './Popper'

const SidebarLink = ({ isDrawerOpen, navItem, path, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()
  const [isExpanded, setIsExpanded] = useState(false)
  const { pathname } = useLocation()
  const hasSubMenu = navItem.tabs?.length > 0

  useEffect(() => {
    if (!hasSubMenu) return

    const val = localStorage.getItem(`is_${navItem.name}_open`)
    if (val) setIsExpanded(JSON.parse(val))
  }, [])

  const handleMouseOver = e => {
    if (navItem.tabs?.length > 0) {
      setAnchorEl(e.currentTarget)
    }
  }

  const handleMouseLeave = () => {
    if (navItem.tabs?.length > 0) {
      setAnchorEl(null)
    }
  }

  const handleNavLinkClick = e => {
    if (isDrawerOpen && hasSubMenu) {
      localStorage.setItem(`is_${navItem.name}_open`, !isExpanded)
      setIsExpanded(prev => !prev)

      if (navItem.name === 'settings') {
        window.setTimeout(() => {
          e.target.scrollIntoView({ behavior: 'smooth' })
        }, 300)
      }
    } else {
      history.push(path)
    }
  }

  let conditionalClasses = 'hover:bg-primary-light dark:hover:bg-dark-main3'

  if (
    (!isDrawerOpen && pathname.includes(navItem.activeNavString)) ||
    (isDrawerOpen && pathname.includes(navItem.activeNavString) && !hasSubMenu)
  ) {
    conditionalClasses =
      'bg-primary-light text-primary-main dark:bg-dark-main dark:text-white'
  }

  return (
    <div
      onMouseOver={!isDrawerOpen ? handleMouseOver : undefined}
      onMouseLeave={!isDrawerOpen ? handleMouseLeave : undefined}
      className="px-2 mt-0.5"
    >
      <div
        onClick={handleNavLinkClick}
        role="link"
        tabIndex="0"
        id={toCamelCase('sidebar link ' + navItem.text)}
        className={clsx(
          'flex items-center w-full rounded-md font-medium cursor-pointer',
          conditionalClasses
        )}
      >
        <div className="flex flex-1">
          <SidebarCustomTooltip title={isDrawerOpen ? '' : navItem.text}>
            <div className="flex flex-1 items-center">
              <div className={classes.iconItem}>{navItem.icon}</div>

              {isDrawerOpen && (
                <>
                  <span className={classes.label}>{navItem.text}</span>
                  {hasSubMenu && (
                    <ArrowRight
                      className={clsx(
                        'ml-auto text-gray-500',
                        isExpanded && 'rotate-90'
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </SidebarCustomTooltip>
          {navItem.badge !== 0 && (
            <Badge
              color="error"
              badgeContent={navItem.badge}
              max={9}
              style={
                isDrawerOpen ? { right: 20, top: 17 } : { right: 7, top: 10 }
              }
            />
          )}

          {!isDrawerOpen && Boolean(anchorEl) && (
            <StatsPopper anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
              <PopupSubMenuItems
                show={Boolean(anchorEl)}
                tabs={navItem.tabs}
                title={navItem.text}
                history={history}
              />
            </StatsPopper>
          )}
        </div>
      </div>

      {isDrawerOpen && isExpanded && (
        <>
          {navItem.tabs.map(item => (
            <SubMenuItem
              key={item.label}
              label={item.label}
              path={item.to}
              isActive={pathname.includes(item.to)} //exact match
              pathname={pathname}
              history={history}
              classes={classes}
            />
          ))}
        </>
      )}
    </div>
  )
}

const SubMenuItem = ({ label, path, isActive, history }) => {
  const handleNavLinkClick = () => {
    history.push(path)
  }

  return (
    <div
      onClick={handleNavLinkClick}
      role="link"
      tabIndex="0"
      id={toCamelCase('sidebar link ' + label)}
      className={clsx(
        'flex items-center w-full rounded-md py-1 cursor-pointer mt-0.5',
        isActive
          ? 'bg-primary-light text-primary-main dark:bg-dark-main dark:text-white'
          : 'text-gray-600 hover:bg-primary-light dark:hover:bg-dark-main3'
      )}
      style={{ paddingLeft: 36 }}
    >
      <span className="text-xs">{label}</span>
    </div>
  )
}

const PopupSubMenuItems = ({ tabs, title, show, history }) => {
  const handleRedirect = (tab, e) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(tab.to)
  }

  return (
    <Transition
      show={show}
      appear={show}
      as={Fragment}
      enter="transition ease-in duration-[1500]"
      enterFrom="transform opacity-0 scale-25"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-out duration-2000"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-25"
    >
      <div
        className={clsx(
          // classes.menuList,
          'w-56 origin-top-left py-2 rounded-md bg-white border dark:border-dark-main dark:bg-dark-main2 focus:outline-none shadow-md'
        )}
      >
        <div className="text-gray-500 px-4 pt-3 mb-1 text-xs font-semibold uppercase w-full dark:text-dark-light">
          {title}
        </div>
        {tabs.map(tab => (
          <div className="hover:bg-gray-100 dark:hover:bg-dark-main3">
            <div
              role="link"
              tabIndex="0"
              onClick={e => handleRedirect(tab, e)}
              className="cursor-pointer px-4 py-2 text-sm w-full dark:text-dark-light"
            >
              {tab.label}
            </div>
          </div>
        ))}
      </div>
    </Transition>
  )
}

export default SidebarLink
