import { ReactComponent as ImageIcon } from 'static/svg/image-3.svg'
import { ReactComponent as MarketingIcon } from 'static/svg/megaphone.svg'
import { ReactComponent as DesignIcon } from 'static/svg/pen-tool.svg'
import { ReactComponent as SoftwareIcon } from 'static/svg/file-code.svg'
import { ReactComponent as VerticalThreeDot } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { PopupMenu } from 'global/globalComponents'
import { userRoles } from 'utils'
import { useSelector } from 'react-redux'
import Pill from 'global/globalComponents/Pill/Pill'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

const truncateText = (text, maxWord) => {
  text = text.replace(text[0], text[0]?.toUpperCase())
  if (text.length >= maxWord) {
    const transformed = text.substr(0, maxWord - 2)
    return `${transformed}...`
  }
  return text
}

const handleTemplateIcon = category => {
  if (category?.toLowerCase() === 'marketing') {
    return (
      <MarketingIcon
        className="text-primary-main dark:text-dark-light"
        width={40}
        height={40}
      />
    )
  } else if (category?.toLowerCase() === 'design') {
    return (
      <DesignIcon
        className="text-primary-main dark:text-dark-light"
        width={40}
        height={40}
      />
    )
  } else if (category?.toLowerCase() === 'software development') {
    return (
      <SoftwareIcon
        className="text-primary-main dark:text-dark-light"
        width={40}
        height={40}
      />
    )
  } else {
    return (
      <ImageIcon
        className="text-primary-main dark:text-dark-light"
        width={40}
        height={40}
      />
    )
  }
}

const ImageCard = ({
  data,
  handleSingleTemplate,
  openDeleteTemplateModal,
  handleDuplicateTemplate,
  openCreateUpdateTemplateModal
}) => {
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)

  return (
    <div
      className="bg-white border rounded-xl shadow-sm overflow-hidden cursor-pointer hover:shadow-lg dark:bg-dark-main dark:border-black"
      style={{ maxHeight: '280px' }}
    >
      <div className="relative show-on-hover-parent">
        {data.type === 'private' &&
          [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
            meData.role
          ) && (
            <PopupMenu
              targetComp={
                <VerticalThreeDot
                  className="absolute top-5 right-2 text-primary-main mt-1 hover:bg-primary-light rounded-md show-on-hover-child"
                  width="1.2em"
                  height="1.3em"
                />
              }
              menuItems={[
                {
                  label: t('EDIT'),
                  method: () => openCreateUpdateTemplateModal(data),
                  icon: (
                    <EditIcon className="text-primary-main dark:text-dark-light" />
                  )
                },
                {
                  label: t('DUPLICATE'),
                  method: () => handleDuplicateTemplate(data),
                  icon: (
                    <CopyIcon className="text-primary-main dark:text-dark-light" />
                  )
                },
                {
                  label: t('DELETE'),
                  method: () => openDeleteTemplateModal(data),
                  icon: <DeleteIcon />,
                  isDanger: true
                }
              ]}
            />
          )}
        <div onClick={handleSingleTemplate}>
          <div className="flex justify-between items-center px-4 pt-4 dark:text-dark-light">
            <div className="dark:text-dark-light">
              {handleTemplateIcon(data.category)}
            </div>
            <time className="text-xs text-gray-500 font-medium mr-4 dark:text-dark-light">
              created: {format(new Date(data.createdAt), 'MMM d, yyyy')}
            </time>
          </div>
          <div className="w-full px-4 py-4 break-words">
            <div className="flex flex-wrap justify-between items-start gap-2 sm:gap-0 mb-2 sm:mb-0 sm:space-x-2">
              <h1 className="text-sm 2xl:text-base font-semibold overflow-hidden mb-2 dark:text-dark-light2">
                {data.title}
              </h1>
              <Pill variant="green">
                {t(data.category?.toUpperCase()) || t('OTHERS')}
              </Pill>
            </div>
            <p className="text-smaller 2xl:text-sm overflow-hidden dark:text-dark-light">
              {truncateText(data.description, 100)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageCard
