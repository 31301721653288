export const REPORTING = {
  LOADING: 'REPORTING_LOADING',
  FETCHED: 'REPORING_FETCHED',
  ERROR: 'REPORTING_ERROR',
  ADD_ONE_REPORTING: 'ADD_ONE_REPORTING',
  UPDATE_ONE_REPORTING: 'UPDATE_ONE_REPORTING',
  DELETE_ONE_REPORTING: 'DELETE_ONE_REPORTING',
  DATA_CHANGE: 'DATA_CHANGE'
}

const initialState = {
  error: false,
  loading: false,
  data: [],
  fetched: false,
  dataChange: false
}

export const reportingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTING.LOADING:
      return { ...state, loading: true }

    case REPORTING.ERROR:
      return { ...state, loading: false, error: true }

    case REPORTING.DATA_CHANGE:
      return { ...state, dataChange: action.payload }

    case REPORTING.FETCHED:
      return {
        ...state,
        loading: false,
        fetched: true,
        error: false,
        data: action.payload
      }
    case REPORTING.ADD_ONE_REPORTING:
      return { ...state, data: [action.payload, ...state.data] }

    case REPORTING.UPDATE_ONE_REPORTING:
      const updatedData = state.data.map(item => {
        if (item._id === action.payload._id) {
          return action.payload
        } else {
          return { ...item }
        }
      })
      return { ...state, data: updatedData }

    case REPORTING.DELETE_ONE_REPORTING:
      return {
        ...state,
        data: [...state.data].filter(item => item._id !== action.payload._id)
      }

    default:
      return { ...state }
  }
}

export default reportingReducer
