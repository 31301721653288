import { Clientvenue_logo } from 'static/Images/index'

const updateDocumentTitle = (title, image) => {
  let favicon = image || Clientvenue_logo
  let brandName = title || 'ClientVenue'

  let link = document.querySelector("link[rel~='icon']")
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  // link.href = favicon
  link.href = favicon ? `${favicon}?${Date.now()}` : ''
  document.querySelectorAll('[rel="icon"]')[0].replaceWith(link)
  document.title = brandName
}

export default updateDocumentTitle
