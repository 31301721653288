export const DEFAULT_PLATFORM_LANGUAGE = 'en'
export const SORT_PRICE_LOW_TO_HIGH = '+price'
export const SORT_PRICE_HIGH_TO_LOW = '-price'
export const SORT_NEWLY_CREATED = '-createdAt'
export const FILTER_BY_SERVICE_TYPE = 'serviceType'
export const FILTER_BY_SERVICE_PRICE_RANGE = 'priceRange'
export const projectCategories = {
  INTERNAL: 'Internal',
  EXTERNAL: 'External'
}

export const CV_HOST = 'app.clientvenue.com'
export const HUBSPOT_INTEGRATION_NAME = 'hubspot_crm'
