import axios from 'axios'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { WEBHOOK } from './reducer'
// import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { getErrorMessages } from 'utils/errorMessages'
const errorMessages = getErrorMessages()
/**
 * Create  New webhook
 * @param {Object} data Webhook data
 * @param {Function} callback calback function
 */
export const createNewWebhook = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: '/webhook',
        method: 'POST',
        data
      })
      dispatch({ type: WEBHOOK.CREATED, payload: res.data })
      if (callback) callback(res.data, false)
      dispatch(fireSuccessToaster(t('NEW_WEBHOOK_ADDED')))
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          false
        )
    }
  }
}

/**
 * Fetch all possible events
 * @param {Function} callback callback function
 */
export const fetchAllWebhookEvents = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/eventtypes'
      })
      dispatch({ type: WEBHOOK.FETCHED_ALL_EVENTS, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch(
        fireErrorToaster(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
        )
      )

      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          false
        )
    }
  }
}

/**
 * Fetch all created webhooks of current user
 * @param {Function} callback callback funtion
 */
export const fetchUserCreatedWebhooks = callback => {
  return async (dispatch, getState) => {
    try {
      const res = await axios({
        url: `/webhook/user`
      })
      dispatch({ type: WEBHOOK.FETCHED_CREATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch(
        fireErrorToaster(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
        )
      )

      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update a single webhook details
 * @param {String} id webhook id
 * @param {Object} data Webhook data
 * @param {Function} callback callback funtion
 */
export const updateSingleWebhook = (id, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/webhook/${id}`,
        method: 'PUT',
        data
      })
      dispatch({ type: WEBHOOK.UPDATE_ONE_WEBHOOK, payload: res.data })
      if (callback) callback(res.data, false, id)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true,
          id
        )
    }
  }
}

export const deleteSingleWebhook = (id, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/webhook/${id}`,
        method: 'DELETE'
      })
      dispatch({ type: WEBHOOK.DELETE_ONE_WEBHOOK, payload: res.data })
      if (callback) callback(res.data, false, id)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
