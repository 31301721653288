const { useEffect } = require('react')

/**
 * connects dashly chat
 * @param {string} dahslyId dashly app id
 */
const useDashly = (dahslyId) => {
  useEffect(() => {
    window.dashly.connect(dahslyId)
  }, [dahslyId])
}

export default useDashly
