import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllServices,
  fetchServiceCategories,
  deleteServiceCategory
} from 'thunks/service/actions'
import NewService from './NewService'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import CancelRequests from './CancelRequests'
import ServicePreviewModal from './ServicePreviewModal'
import CheckoutLinkModal from './CheckoutLinkModal'
import ServiceHeader from './ServiceHeader'
import CategoriesModal from './CategoriesModal'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import ServiceTable from './ServiceTable'
import { CustomTabs } from 'global/globalComponents'
import { fetchCancelServiceRequests } from 'thunks/cancelClientServiceRequest/actions'
import { useTranslation } from 'react-i18next'
import { userRoles } from 'utils'
import { fetchServicePageView } from 'thunks/views/actions'
import { useModal } from 'customHooks'
import AddOnModal from './AddOnModal'
import AddOnsTable from './AddOnsTable'
import CreateMarketplaceServiceModal from './CreateMarketplaceServiceModal/CreateMarketplaceServiceModal'
import ServiceTemplates from './ServiceTemplates/ServiceTemplates'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'

const Service = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const me = useSelector(state => state.me.data)
  const userServices = useSelector(state => state.userServices)
  const cancelClientServiceReq = useSelector(
    state => state.cancelClientServiceRequest
  )
  const { headerSearch: search, isMarketplaceDomain } = useSelector(
    state => state.generalAppState
  )
  const [selectedTab, setSelectedTab] = useState(pathname)
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [shareLink, setShareLink] = useState({
    open: false,
    data: {}
  })
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false)
  const [serviceCategories, setServiceCategories] = useState([])
  const [categoryAlert, setCategoryAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })
  const { servicePageView } = useSelector(state => ({
    servicePageView: state.views.data.servicePageView
  }))

  const {
    modal: addOnModal,
    openModal: openAddOnModal,
    closeModal: closeAddOnModal
  } = useModal()

  const {
    modal: addServiceModal,
    openModal: openAddServiceModal,
    closeModal: closeAddServiceModal
  } = useModal()

  useEffect(() => {
    if (!servicePageView._id && me.role === userRoles.USER_AGENCY) {
      dispatch(fetchServicePageView(null))
    }
  }, [])

  useEffect(() => {
    dispatch(
      fetchServiceCategories(
        { type: isMarketplaceDomain ? 'marketplaceService' : 'service' },
        (res, err) => {
          if (!err) {
            setServiceCategories(res)
          }
        }
      )
    )
  }, [])

  useEffect(() => {
    if (!cancelClientServiceReq.fetched) {
      dispatch(fetchCancelServiceRequests())
    }
  }, [cancelClientServiceReq.fetched])

  useEffect(() => {
    if (!userServices.fetched) {
      dispatch(fetchAllServices())
      dispatch(fetchCancelServiceRequests())
    }
    // dispatch(updateUserAcces(me.role))
  }, [userServices.fetched])

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const dateFormatter = date => {
    return date.slice(0, 10)
  }

  const handleTabChange = value => {
    history.push(value)
    // setSelectedTab(newVal)
  }

  const shareCheckoutLinkHandler = serviceData => {
    if (shareLink.open) {
      setShareLink(prev => ({ open: false, data: {} }))
    } else {
      if (!serviceData.isActive) {
        dispatch(fireErrorToaster(t('PLEASE_FIRST_ARCHIVE_THIS_SERVICE')))
        return
      }
      if (!serviceData.displayOnCatalog) {
        dispatch(fireErrorToaster(t('PLEASE_DISPLAY_YOUR_SERVICE_IN_CATALOG')))
        return
      }
      setShareLink(prev => ({ open: true, data: serviceData }))
    }
  }

  const handlePreviewModal = () => {
    setOpenPreviewModal(prev => !prev)
  }

  const toggleCategoriesModal = () => {
    setOpenCategoriesModal(prev => !prev)
  }

  const handleUpdateServiceCategory = data => {
    setServiceCategories(prev =>
      prev.map(item => {
        if (item._id === data._id) return data
        return item
      })
    )
  }

  const addNewCategory = data => {
    setServiceCategories(prev => [...prev, data])
  }

  const openCategoryAlert = data => {
    setCategoryAlert({ open: true, loading: false, data })
  }
  const closeCategoryAlert = () => {
    setCategoryAlert(prev => ({ ...prev, open: false, loading: false }))
  }

  const handleDeleteCategory = () => {
    setCategoryAlert(prev => ({ ...prev, loading: true }))
    deleteServiceCategory(
      { id: categoryAlert.data._id },
      deleteServiceCategoryCallback
    )
  }

  const deleteServiceCategoryCallback = (res, err) => {
    if (!err) {
      setCategoryAlert({ loading: false, data: {}, open: false })
      setServiceCategories(prev => prev.filter(item => item._id !== res._id))
    } else {
      setCategoryAlert(prev => ({ ...prev, loading: false }))
      dispatch(fireErrorToaster(res))
    }
  }
  const { t } = useTranslation()

  const tabsData = [
    {
      label: t('SERVICES'),
      value: url,
      number: userServices.data.length
    },
    {
      label: t('ADD_ONS'),
      value: url + '/add-ons',
      number: userServices.addOns.data.length
    },
    {
      label: t('CANCELLATIONS'),
      value: url + '/cancellations',
      number: cancelClientServiceReq.data.length
    },
    {
      label: t('SERVICE_TEMPLATES'),
      value: url + '/service-templates',
      number: 2
    }
  ]

  return (
    <>
      <div>
        <ServiceHeader
          handlePreviewModal={handlePreviewModal}
          services={userServices.data}
          openCategoriesModal={toggleCategoriesModal}
          openCreateAddOnModal={openAddOnModal}
          openAddServiceModal={openAddServiceModal}
        />
        <div>
          <div className="mb-6">
            <CustomTabs
              tabsData={tabsData}
              value={selectedTab}
              onChange={handleTabChange}
            />
          </div>

          {search !== '' && (
            <p className="text-sm mb-2 ml-1 text-gray-500 font-medium dark:text-dark-light">
              {t('SEARCH_RESULT')}:{' '}
              <span className="text-primary-dark dark:text-dark-light">
                {search}
              </span>
            </p>
          )}

          {
            selectedTab === url ? (
              <ServiceTable
                search={search}
                handleOpenServiceModal={openAddServiceModal}
                shareCheckoutLinkHandler={shareCheckoutLinkHandler}
              />
            ) : selectedTab.includes('/add-ons') ? (
              <AddOnsTable
                search={search}
                openEditAddOnModal={openAddOnModal}
              />
            ) : selectedTab.includes('/cancellations') ? (
              <CancelRequests search={search} dateFormatter={dateFormatter} />
            ) : selectedTab.includes('/service-templates') ? (
              <ServiceTemplates />
            ) : null
            // : (
            // selectedTab === 3 && (
            //   <ServiceCustomizationContext>
            //     <ServicePageCustomization />
            //   </ServiceCustomizationContext>
            // )
            // )
          }
        </div>

        {isMarketplaceDomain ? (
          <CreateMarketplaceServiceModal
            open={addServiceModal.open}
            onClose={closeAddServiceModal}
            data={addServiceModal.data}
            serviceCategories={serviceCategories}
          />
        ) : (
          <NewService
            open={addServiceModal.open}
            onClose={closeAddServiceModal}
            data={addServiceModal.data}
            serviceCategories={serviceCategories}
          />
        )}

        {openPreviewModal && (
          <ServicePreviewModal
            open={openPreviewModal}
            handleClose={handlePreviewModal}
          />
        )}
        {shareLink.open && (
          <CheckoutLinkModal
            service={shareLink}
            handleClose={shareCheckoutLinkHandler}
          />
        )}
      </div>

      <CategoriesModal
        open={openCategoriesModal}
        onClose={toggleCategoriesModal}
        serviceCategories={serviceCategories}
        handleUpdateServiceCategory={handleUpdateServiceCategory}
        openCategoryAlert={openCategoryAlert}
        addNewCategory={addNewCategory}
      />
      <AlertModal
        warningText={t('WARNING_DELETE_MESSAGE', {
          data: categoryAlert.data.name
        })}
        open={categoryAlert.open}
        handleDialog={closeCategoryAlert}
        handleDeleteAction={handleDeleteCategory}
        loading={categoryAlert.loading}
      />

      <AddOnModal
        open={addOnModal.open}
        onClose={closeAddOnModal}
        data={addOnModal.data}
      />
    </>
  )
}

export default Service
