export const TIMER = {
  FETCHED_ACTIVE_TIMER: 'FETCHED_ACTIVE_TIMER',
  FETCHED_TASK_TIMER: 'FETCHED_TASK_TIMER',
  FETCHED_SUBTASK_TIMER: 'FETCHED_SUBTASK_TIMER',
  START_TIMER: 'START_TIMER',
  STOP_TIMER: 'STOP_TIMER',
  UPDATE_ACTIVE_TIMER: 'UPDATE_ACTIVE_TIMER',
  UPDATE_TASK_TIMER: 'UPDATE_TASK_TIMER',
  UPDATE_SUBTASK_TIMER: 'UPDATE_SUBTASK_TIMER',
  TOGGLE_TIMER_POSITION: 'TOGGLE_TIMER_POSITION',
  REMOVE: 'TIMER_REMOVE',
  LOADING_TASK_TIMER_DATA: 'LOADING_TASK_TIMER_DATA',
  LOADING_SUBTASK_TIMER_DATA: 'LOADING_SUBTASK_TIMER_DATA',
  TOGGLE_IS_BILLABLE: 'TIMER_TOGGLE_IS_BILLABLE'
}

const initialState = {
  error: false,
  loading: true,
  fetched: false,
  data: {
    float: true,
    activeTimer: null,
    isPaused: false
  },
  taskTimer: {
    loading: true,
    data: [],
    fetched: false
  },
  subtaskTimer: {
    loading: true,
    data: [],
    fetched: false
  }
}

const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TIMER.FETCHED_ACTIVE_TIMER:
      return {
        ...state,
        loading: false,
        fetched: true,
        data: {
          ...state.data,
          activeTimer: action.payload[0]
        }
      }

    case TIMER.START_TIMER:
      return {
        ...state,
        data: { ...state.data, activeTimer: action.payload, isPaused: false }
      }

    case TIMER.STOP_TIMER:
      return {
        ...state,
        data: { ...state.data, activeTimer: action.payload, isPaused: true }
      }

    case TIMER.UPDATE_ACTIVE_TIMER:
      // If active timer is true, update its values
      if (state.data.activeTimer) {
        return {
          ...state,
          data: { ...state.data, activeTimer: action.payload }
        }
      }
      return state

    case TIMER.LOADING_TASK_TIMER_DATA:
      return {
        ...state,
        taskTimer: { loading: true, data: [], fetched: false }
      }

    case TIMER.FETCHED_TASK_TIMER:
      return {
        ...state,
        taskTimer: { loading: false, data: action.payload, fetched: true }
      }

    case TIMER.LOADING_SUBTASK_TIMER_DATA:
      return {
        ...state,
        subtaskTimer: { loading: true, data: [], fetched: false }
      }

    case TIMER.FETCHED_SUBTASK_TIMER:
      return {
        ...state,
        subtaskTimer: { loading: false, data: action.payload, fetched: true }
      }

    case TIMER.UPDATE_TASK_TIMER:
      const updatedData1 = [...state.taskTimer.data]
        .map(item => {
          if (item.user._id === action.payload.user) {
            return {
              ...item,
              intervals: [...item.intervals].filter(
                el => el._id !== action.payload._id
              )
            }
          } else {
            return item
          }
        })
        .filter(item => item.intervals.length)
      return {
        ...state,
        taskTimer: {
          loading: false,
          data: updatedData1,
          fetched: true
        }
      }

    case TIMER.UPDATE_SUBTASK_TIMER:
      const updatedData2 = state.subtaskTimer.data
        .map(item => {
          if (item.user._id === action.payload.user) {
            return {
              ...item,
              intervals: [...item.intervals].filter(
                el => el._id !== action.payload._id
              )
            }
          } else {
            return item
          }
        })
        .filter(item => item.intervals.length)
      return {
        ...state,
        subtaskTimer: {
          loading: false,
          data: updatedData2,
          fetched: true
        }
      }

    case TIMER.TOGGLE_IS_BILLABLE:
      return {
        ...state,
        data: {
          ...state.data,
          activeTimer: { ...state.data.activeTimer, isBillable: action.payload }
        }
      }

    case TIMER.TOGGLE_TIMER_POSITION:
      return { ...state, data: { ...state.data, float: !state.data.float } }

    case TIMER.REMOVE:
      return { ...state, data: { ...state.data, activeTimer: null } }

    default:
      return state
  }
}

export default timerReducer
