import React, { useState } from 'react'
import { Popover, List, ListItem } from '@material-ui/core'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEmbed } from 'thunks/embed/action'
// import Category from 'utils/workspaceCategories'
import UserDashboardStyles from '../userDashboardStyles'
import Tags from './Tags'
import DuplicateModal from './DuplicateModal'
import WorkspaceIconPopover from './WorkspaceIconPopover'
import { useTranslation } from 'react-i18next'
import { openApprovalModal } from 'thunks/generlAppState/actions'
// import CategoriesPopup from './CategoriesPopup'

const WorkspaceMenu = props => {
  const {
    open,
    anchorEl,
    onClose,
    workspace = {},
    EditWorkspace,
    duplicateWorkspace,
    deleteWorkspace,
    archiveWorkspace,
    completeStatus
  } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = UserDashboardStyles()
  const userAccountPermission = useSelector(
    state => state.permission.data.access
  )
  const [iconPopoverData, setIconPopoverData] = useState({
    open: false,
    anchorEl: null
  })

  const [tagsPopoverData, setTagsPopoverData] = useState({
    open: false,
    anchorEl: null
  })
  // const [categoryPopoverData, setCategoryPopoverData] = useState({
  //   open: false,
  //   anchorEl: null
  // })
  const [duplicateProject, setDuplicateProject] = useState({
    open: false
  })
  const [workspaceBoard, setWorkspaceBoard] = useState([])

  const handleDuplicateProjectModal = () => {
    if (duplicateProject.open) {
      setDuplicateProject({
        open: false
      })
    } else {
      setDuplicateProject({
        open: true
      })

      dispatch(
        fetchEmbed({ id: workspace._id }, (res, err) => {
          if (!err) {
            setWorkspaceBoard(res)
          }
        })
      )
      onClose()
    }
  }

  const duplicateWorkspaceHandler = list => {
    duplicateWorkspace(workspace, list)
  }

  const handleOpenApprovalModal = () => {
    dispatch(
      openApprovalModal({
        module: 'project',
        moduleId: workspace._id,
        approvalItemName: workspace.name,
        approvers: workspace.assignedAgencyTeam.map(item => item.user)
      })
    )

    onClose()
  }

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorPosition={{ top: 50, left: 140 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{ paper: classes.popoverPaper }}
      >
        <List
          classes={{ root: classes.listRoot }}
          className="dark:text-dark-light"
          dense
        >
          {!workspace?.isArchived && !workspace?.isComplete && (
            <>
              <ListItem
                onClick={e =>
                  setIconPopoverData({ open: true, anchorEl: e.currentTarget })
                }
                button
              >
                {t('SET_COLOR_AND_ICONS')} <ChevronRightRoundedIcon />
              </ListItem>
              <ListItem
                button
                onClick={e =>
                  setTagsPopoverData({ open: true, anchorEl: e.currentTarget })
                }
              >
                {t('EDIT_TAGS')}
                <ChevronRightRoundedIcon />
              </ListItem>
              {/* <ListItem
                button
                onClick={e =>
                  setCategoryPopoverData({
                    open: true,
                    anchorEl: e.currentTarget
                  })
                }
              >
                {t('EDIT_CATEGORY')}
                <ChevronRightRoundedIcon />
              </ListItem> */}
              <ListItem
                button
                onClick={() => {
                  EditWorkspace(workspace)
                  onClose()
                }}
              >
                {t('EDIT_PROJECT')}
              </ListItem>
              {userAccountPermission?.workspace?.create && (
                <ListItem button onClick={handleDuplicateProjectModal}>
                  {t('DUPLICATE_PROJECT')}
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  completeStatus(workspace)
                  onClose()
                }}
              >
                {t('MARK_AS_COMPLETE')}
              </ListItem>
            </>
          )}

          {/* user with the permission of delete can archive project */}
          {!workspace.isComplete && userAccountPermission?.workspace?.delete && (
            <>
              <ListItem
                button
                onClick={() => {
                  archiveWorkspace(workspace)
                  onClose()
                }}
              >
                {workspace?.isArchived ? t('UNARCHIVE') : t('ARCHIVE')}{' '}
                {t('PROJECT')}
              </ListItem>

              <ListItem button onClick={handleOpenApprovalModal}>
                {t('APPROVALS')}
              </ListItem>
            </>
          )}
          {!workspace?.isArchived && userAccountPermission?.workspace?.delete && (
            <ListItem
              button
              onClick={() => {
                deleteWorkspace(workspace)
                onClose()
              }}
            >
              <span className="text-red-400">{t('DELETE_PROJECT')}</span>
            </ListItem>
          )}
        </List>
      </Popover>
      <WorkspaceIconPopover
        setIconPopoverData={setIconPopoverData}
        iconPopoverData={iconPopoverData}
        onClose={onClose}
        workspace={workspace}
      />
      <Tags
        workspace={workspace}
        setIconPopoverData={setIconPopoverData}
        onClose={onClose}
        tagsPopoverData={tagsPopoverData}
        setTagsPopoverData={setTagsPopoverData}
        elmFor="workspace"
      />
      {/* <CategoriesPopup
        workspace={workspace}
        setIconPopoverData={setIconPopoverData}
        onClose={onClose}
        categoryPopoverData={categoryPopoverData}
        setCategoryPopoverData={setCategoryPopoverData}
        elmFor="workspace"
      /> */}
      <DuplicateModal
        open={duplicateProject.open}
        handleDialog={handleDuplicateProjectModal}
        handleDuplicateAction={duplicateWorkspaceHandler}
        workspace={workspace}
        workspaceBoard={workspaceBoard}
      />
    </>
  )
}

export default WorkspaceMenu
