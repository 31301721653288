import React from 'react'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { LOGIN } from 'thunks/login/reduers'
import useTitle from 'customHooks/useTitle'
import { Clientvenue_logo } from 'static/Images/index'
import AgencyPlanCards2 from './AgencyPlanCards2'
import { Redirect } from 'react-router-dom'
import { CV_HOST } from 'constants/constantKeys'

const AgencyPlans = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const urlSearchParams = new URLSearchParams(search)
  const planId = urlSearchParams.get('planId')
  const { loggedIn } = useSelector(state => state.login)

  useTitle('ClientVenue | Checkout | Select Plan', Clientvenue_logo)

  const handleLogout = () => {
    dispatch({ type: LOGIN.LOGOUT })
  }

  // If this is not the app.cv domain then make this route protected
  if (window.location.host !== CV_HOST && !loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <div className="pb-12">
      {loggedIn && (
        <div className="bg-white py-4 px-4 shadow flex justify-end">
          <Button onClick={handleLogout} variant="outlined" size="small">
            Logout
          </Button>
        </div>
      )}
      <div className="px-8 mt-4 mx-auto" style={{ maxWidth: 1400 }}>
        <div className="text-center">
          <h3 className="text-2xl font-semibold py-3">
            Simple, honest & affordable pricing
          </h3>
          <p className="text-sm text-gray-500">
            Enjoy any plan free for 14 days | Cancel anytime | No questions
            asked
          </p>
        </div>
        <AgencyPlanCards2 planId={planId} />
      </div>
    </div>
  )
}

export default AgencyPlans
