import React, { useEffect, useMemo, useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tab, Tabs, TextField, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlaneIcon } from 'static/svg/plane.svg'
import {
  // archiveChatGroup,
  deleteGroup,
  updateGroup
  // deleteGroupByGroupId,
  // updateChannelRoleForUser,
  // updateGroupByGroupId
} from 'thunks/chat/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  CustomAvatar,
  FormLabel,
  LoadingButton,
  PopupMenuList,
  ToggleSwitch
} from 'global/globalComponents'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Pill from 'global/globalComponents/Pill/Pill'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import userRoles, { getUserPath } from 'utils/userRoles'
import { getRoleBadge } from 'utils'
import { useModal } from 'customHooks'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import socket from 'config/socketConfig'

const UpdateChannelModal = ({ open, onClose, data }) => {
  return (
    <CustomModal
      open={open}
      size="extraLarge"
      modalStyles={{ zIndex: 9999999 }}
    >
      <ModalContent onClose={onClose} data={data} />
    </CustomModal>
  )
}

const channelRoleOptions = { ADMIN: 'admin', MODERATOR: 'moderator' }

const ModalContent = ({ onClose, data }) => {
  // const dispatch = useDispatch()
  const { t } = useTranslation()
  // const history = useHistory()
  const meData = useSelector(state => state.me.data)
  // const [loading, setLoading] = useState(false)
  // const [groupData, setGroupData] = useState({
  //   title: '',
  //   description: '',
  //   members: [],
  //   type: 'internal'
  // })
  const [selectedTab, setSelectedTab] = useState(0)
  const { groups } = useSelector(state => state.chats)
  const currentGroup = useMemo(() => {
    return groups.data.find(group => group._id === data.channelId) || {}
  }, [groups, data.channelId])

  const isUserAdmin =
    currentGroup.members?.find(mem => mem.user?._id === meData._id)?.role ===
    'admin'

  // useEffect(() => {
  //   setGroupData({
  //     title: currentGroup.title,
  //     description: currentGroup.description,
  //     members: currentGroup.members?.filter(member => Boolean(member.user)),
  //     type: currentGroup.type
  //   })
  // }, [currentGroup])

  return (
    <>
      <header className="flex items-center justify-between gap-2 py-4 2xl:py-5 px-6 select-none rounded-t-2xl 2xl:rounded-t-3xl dark:text-dark-light">
        <div className="flex flex-1 space-x-2 items-center">
          <span>
            <PlaneIcon className="text-primary-main w-6 h-6" />
          </span>

          <div className="w-full">
            <h2 className="text-lg 2xl:text-xl font-semibold text-primary-dark dark:text-dark-light">
              {t('UPDATE_CHANNEL')}
            </h2>
            <p className="text-xs text-custom-gray-dark-3 dark:text-dark-light">
              {currentGroup.title}
            </p>
          </div>
        </div>
      </header>

      <CustomModalBody>
        <div className="h-96">
          <Tabs
            value={selectedTab}
            onChange={(e, val) => setSelectedTab(val)}
            aria-label="simple tabs example"
            className="dark:text-dark-light"
          >
            {[t('MEMBERS'), isUserAdmin ? t('SETTINGS') : t('ABOUT')].map(
              (item, index) => (
                <Tab label={item} key={index} value={index} />
              )
            )}
          </Tabs>

          <div className="mt-4">
            {selectedTab === 0 ? (
              <MembersTab
                currentGroup={currentGroup}
                isUserAdmin={isUserAdmin}
              />
            ) : (
              <SettingsTab
                currentGroup={currentGroup}
                closeModal={onClose}
                isUserAdmin={isUserAdmin}
              />
            )}
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter className="pb-5">
        <div className="flex gap-2">
          {/* {isUserAdmin && (
            <LoadingButton loading={loading} onClick={handleUpdateGroup}>
              {t('UPDATE')}
            </LoadingButton>
          )} */}

          <Button size="large" variant="outlined" onClick={onClose}>
            {t('CLOSE')}
          </Button>
        </div>
      </CustomModalFooter>
    </>
  )
}

const MembersTab = ({ currentGroup, isUserAdmin }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const [popper, setPopper] = useState({
    anchorEl: null,
    userRole: '',
    userId: ''
  })
  const hideManageRoles = !isUserAdmin || currentGroup.type === 'newFeed'
  const mappedGroupMembers = currentGroup.members
    .filter(member =>
      member.visitor
        ? Boolean(member.visitor.visitorId)
        : Boolean(member.user?._id)
    )
    .map(member => ({
      ...member,
      user: member.visitor
        ? { ...member.visitor, _id: member.visitorId }
        : member.user
    }))

  const handleChangeUserRole = (userId, role) => {
    // dispatch(
    //   updateChannelRoleForUser(
    //     {
    //       groupId: currentGroup._id,
    //       body: {
    //         updateRole: {
    //           user: userId,
    //           role: role
    //         }
    //       }
    //     },
    //     (res, err) => {
    //       if (err) dispatch(fireErrorToaster(res.message))
    //     }
    //   )
    // )

    socket.emit(
      'updateGroup',
      {
        id: currentGroup._id
      },
      {
        updateRole: {
          user: userId,
          role: role
        }
      },
      ({ status, result, error }) => {
        if (status !== 200) {
          dispatch(
            fireErrorToaster(error?.message || t('ERROR_SOME_ERROR_OCCURED'))
          )
        } else {
          dispatch(updateGroup(result))
        }
      }
    )
  }

  const handleRemoveMember = userId => {
    const groupMemberObj = mappedGroupMembers.find(
      item => item.user._id === userId
    )

    if (groupMemberObj) {
      socket.emit(
        'updateGroup',
        {
          id: currentGroup._id
        },
        {
          removeMembers: [
            {
              user: userId,
              role: groupMemberObj.role,
              _id: groupMemberObj._id
            }
          ]
        },
        ({ status, result, error }) => {
          if (status !== 200) {
            dispatch(
              fireErrorToaster(error?.message || t('ERROR_SOME_ERROR_OCCURED'))
            )
          } else {
            dispatch(updateGroup(result))
          }
        }
      )

      // dispatch(
      //   updateChannelRoleForUser(
      //     {
      //       groupId: currentGroup._id,
      //       body: {
      //         removeMembers: [
      //           {
      //             user: userId,
      //             role: groupMemberObj.role,
      //             _id: groupMemberObj._id
      //           }
      //         ]
      //       }
      //     },
      //     (res, err) => {
      //       if (err) dispatch(fireErrorToaster(res.message))
      //     }
      //   )
      // )
    }
  }

  const getPopupMenu = () => {
    const options = []

    if (popper.userRole === channelRoleOptions.ADMIN) {
      options.push({
        label: 'Remove as Admin',
        method: () =>
          handleChangeUserRole(popper.userId, channelRoleOptions.MODERATOR)
      })
    } else {
      options.push({
        label: 'Assign Admin Role',
        method: () =>
          handleChangeUserRole(popper.userId, channelRoleOptions.ADMIN)
      })
    }

    if (currentGroup.type === 'internal') {
      options.push({
        label: 'Remove from channel',
        method: () =>
          handleRemoveMember(popper.userId, channelRoleOptions.MODERATOR)
      })
    }

    return options
  }

  return (
    <>
      {(currentGroup.type === 'internal' ||
        currentGroup.type === 'publicChat') && (
        <AddMemberListContainer
          groupId={currentGroup._id}
          members={mappedGroupMembers}
        />
      )}

      <div className="flex items-center justify-between">
        <FormLabel className="text-md font-semibold text-custom-dark-main">
          {t('CHANNEL_MEMBERS')} ({mappedGroupMembers.length})
        </FormLabel>

        {!hideManageRoles && (
          <FormLabel className="text-md font-semibold text-custom-dark-main">
            {t('MANAGE_ROLE')}
          </FormLabel>
        )}
      </div>

      {mappedGroupMembers.map(member => (
        <div key={member.user._id} className="mt-2">
          <div className="flex items-center justify-between p-3 hover:bg-primary-light">
            <div className="flex items-center space-x-2">
              <CustomAvatar user={member.user} />
              <div className="text-sm leading-3">{member.user.name}</div>
              {member.user.role >= userRoles.USER_CLIENT &&
                getRoleBadge(member.user.role)}
              {member.role === 'admin' && (
                <Pill variant="green">{member.role}</Pill>
              )}
            </div>

            {meData._id !== member.user._id && !hideManageRoles && (
              <div>
                <button
                  onClick={e =>
                    setPopper({
                      anchorEl: e.currentTarget,
                      userRole: member.role,
                      userId: member.user._id
                    })
                  }
                  className="px-2 py-2 rounded hover:bg-gray-200"
                >
                  <ChevronRight className="w-5 h-5 rotate-90" />
                </button>
              </div>
            )}
          </div>
        </div>
      ))}

      <PopupMenuList
        anchorEl={popper.anchorEl}
        menuItems={popper.anchorEl ? getPopupMenu() : []}
        onClose={() =>
          setPopper(prev => ({
            ...prev,
            anchorEl: null
          }))
        }
      />
    </>
  )
}

const SettingsTab = ({ currentGroup, closeModal, isUserAdmin }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const [toggleLoading, setToggleLoading] = useState(false)
  const [groupData, setGroupData] = useState({
    title: '',
    description: '',
    members: [],
    type: 'internal'
  })
  const {
    modal: alertModal,
    openModal: openAlertModal,
    closeModal: closeAlertModal,
    toggleLoading: toggleAlertLoading
  } = useModal()
  const [updateRequestCount, setUpdateRequestCount] = useState(0)

  useEffect(() => {
    setGroupData({
      title: currentGroup.title,
      description: currentGroup.description,
      members: currentGroup.members?.filter(member => Boolean(member.user)),
      type: currentGroup.type
    })
  }, [currentGroup])

  useEffect(() => {
    if (!updateRequestCount) return

    let timerId = window.setTimeout(() => {
      socket.emit(
        'updateGroup',
        {
          id: currentGroup._id
        },
        {
          newMembers: [],
          title: groupData.title,
          removeMembers: [],
          description: groupData.description
        },
        ({ status, result, error }) => {
          if (status !== 200) {
            dispatch(
              fireErrorToaster(error?.message || t('ERROR_SOME_ERROR_OCCURED'))
            )
          } else {
            dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
            dispatch(updateGroup({ ...result, unread: 0 }))
          }
        }
      )
    }, 1000)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [updateRequestCount])

  const handleArchiveChannel = () => {
    setToggleLoading(true)

    socket.emit(
      'updateGroup',
      {
        id: currentGroup._id
      },
      {
        isArchive: !currentGroup.isArchive
      },
      ({ status, result, error }) => {
        if (status !== 200) {
          dispatch(
            fireErrorToaster(error?.message || t('ERROR_SOME_ERROR_OCCURED'))
          )
        } else {
          dispatch(updateGroup(result))
          dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
        }

        setToggleLoading(false)
      }
    )

    // dispatch(
    //   archiveChatGroup(
    //     {
    //       id: currentGroup._id,
    //       data: { isArchive: !currentGroup.isArchive }
    //     },
    //     (res, err) => {
    //       if (err) dispatch(fireErrorToaster(res.message))
    //       setToggleLoading(false)
    //     }
    //   )
    // )
  }

  const handleDeleteChannel = () => {
    toggleAlertLoading()

    socket.emit(
      'deleteGroup',
      {
        id: currentGroup._id
      },
      ({ status, result, error }) => {
        // empty callback
        if (status !== 200) {
          dispatch(
            fireErrorToaster(error?.message || t('ERROR_SOME_ERROR_OCCURED'))
          )
          toggleAlertLoading()
        } else {
          dispatch(deleteGroup(currentGroup._id))
          dispatch(fireSuccessToaster(t('CHANNEL_DELETED')))
          history.push(`${getUserPath(meData.role)}/chat-stream`)
          closeModal()
        }
      }
    )
    // dispatch(
    //   deleteGroupByGroupId({ groupId: currentGroup._id }, (res, err) => {
    //     if (err) {
    //       dispatch(fireErrorToaster(res.message))
    //       toggleAlertLoading()
    //     } else {
    //       dispatch(fireSuccessToaster(t('CHANNEL_DELETED')))
    //       history.push(`${getUserPath(meData.role)}/chat-stream`)
    //       onClose()
    //     }
    //   })
    // )
  }

  const handleChange = e => {
    setGroupData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    setUpdateRequestCount(prevCount => prevCount + 1)
  }

  return (
    <>
      <div className="mb-4">
        <FormLabel required>{t('NAME_OF_THE_CHANNEL')}</FormLabel>
        <TextField
          className="w-full"
          name="title"
          value={groupData.title}
          type="text"
          placeholder={t('ENTER_CHANNEL_NAME')}
          disabled={!isUserAdmin || groupData.type === 'publicChat'}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <FormLabel>{t('DESCRIPTION')}</FormLabel>
        <TextField
          className="w-full"
          name="description"
          value={groupData.description}
          type="text"
          placeholder={t('ENTER_CHANNEL_DESCRIPTION')}
          disabled={!isUserAdmin || groupData.type === 'publicChat'}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center">
          <FormLabel>{t('ARCHIVE_CHANNEL')}</FormLabel>
          <div className="ml-4">
            <ToggleSwitch
              checked={currentGroup.isArchive}
              onChange={handleArchiveChannel}
              loading={toggleLoading}
            />
          </div>
        </div>
      </div>

      {(groupData.type === 'internal' || groupData.type === 'publicChat') && (
        <div className="mb-4">
          <button
            onClick={openAlertModal}
            className="flex items-center border border-red-400 hover:border-red-500 text-red-400 hover:text-red-500 rounded-md font-semibold p-2 text-sm"
          >
            <span>
              <TrashIcon className="w-4 h-4" />
            </span>
            <span className="ml-2">{t('DELETE')}</span>
          </button>
        </div>
      )}

      <DeleteModal
        open={alertModal.open}
        loading={alertModal.loading}
        warningText={t('WARNING_THE_SELECTED_CHANNEL_WILL_BE_DELETED', {
          data: currentGroup.title
        })}
        deleteBtnText={'DELETE'}
        handleDialog={closeAlertModal}
        handleDeleteAction={handleDeleteChannel}
      />
    </>
  )
}

const AddMemberListContainer = ({ groupId, members }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [teammateOptions, setTeammateOptions] = useState([])
  const { data: userTeammates, loading: userTeammatesLoading } = useSelector(
    state => state.userTeammates
  )
  const { data: clients, loading: clientsLoading } = useSelector(
    state => state.userClients
  )
  const [addMembersList, setAddMembersList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const arr = []
    const addedMembers = members.map(item => item.user._id)

    userTeammates.forEach(mate => {
      if (!addedMembers.includes(mate._id)) {
        arr.push({
          ...mate,
          label: (
            <div className="flex justify-between items-center gap-1">
              <div>{`${mate.name} (${mate?.email})`}</div>
              <Pill variant={'pink'}>{'team'}</Pill>
            </div>
          ),
          value: mate._id
        })
      }
    })

    clients.forEach(item => {
      if (!addedMembers.includes(item._id)) {
        arr.push({
          ...item,
          label: (
            <div className="flex justify-between items-center gap-2">
              <div>{`${item.name} (${item.email})`}</div>
              <Pill variant={'yellow'}>{'client'}</Pill>
            </div>
          ),
          value: item._id
        })
      }
    })
    setTeammateOptions(arr)
  }, [userTeammates, members, clients])

  const filterOption = (candidate, input) => {
    return (
      candidate.data.name.toLowerCase().includes(input.toLowerCase()) ||
      candidate.data.email.toLowerCase().includes(input.toLowerCase())
    )
  }

  const handleAddNewMembers = () => {
    const body = {
      newMembers: addMembersList.map(item => ({
        user: item.value,
        role: 'moderator'
      }))
    }

    setLoading(true)

    socket.emit(
      'updateGroup',
      {
        id: groupId
      },
      body,
      ({ status, result, error }) => {
        setLoading(false)
        if (status !== 200) {
          dispatch(
            fireErrorToaster(error?.message || t('ERROR_SOME_ERROR_OCCURED'))
          )
        } else {
          setAddMembersList([])
          dispatch(updateGroup(result))
        }
      }
    )

    // dispatch(
    //   updateChannelRoleForUser(
    //     {
    //       groupId: groupId,
    //       body
    //     },
    //     (res, err) => {
    //       setLoading(false)
    //       if (err) dispatch(fireErrorToaster(res.message))
    //       else {
    //         setAddMembersList([])
    //       }
    //     }
    //   )
    // )
  }

  return (
    <div className="mb-4 flex space-x-2">
      <Select
        placeholder={t('ADD_MEMBERS')}
        isSearchable={true}
        filterOption={filterOption}
        onChange={setAddMembersList}
        value={addMembersList}
        isMulti
        closeMenuOnSelect={false}
        options={teammateOptions}
        styles={reactSelectCustomStyles(theme, {
          multiValueRemove: {
            top: 6
          }
        })}
        className="flex-grow"
        maxMenuHeight={150}
        isLoading={userTeammatesLoading || clientsLoading}
      />

      <LoadingButton onClick={handleAddNewMembers} loading={loading}>
        {t('ADD')}
      </LoadingButton>
    </div>
  )
}

export default UpdateChannelModal
