import React from 'react'
import ContentEditable from 'react-contenteditable'
import ImageWrapper from '../ImageWrapper'

const Services = ({
  data,
  classes,
  updateTemplateSection,
  updateSectionBlock,
  isEditable
}) => {
  return (
    <div className={classes.sectionContainer}>
      <ContentEditable
        html={data.heading}
        onChange={e =>
          updateTemplateSection({ field: 'heading', value: e.target.value })
        }
        disabled={!isEditable}
        className={classes.sectionHeading}
        style={{ textAlign: 'center' }}
      />
      {/* <div
        className={classes.sectionHeading}
        style={{ textAlign: 'center' }}
        dangerouslySetInnerHTML={{ __html: data.heading }}
      ></div> */}

      <div className={classes.serviceBlocks}>
        {data.blocks.map(item => (
          <div key={item.id}>
            <ImageWrapper
              style={{ width: 75, marginBottom: '0.5rem' }}
              src={item.image}
              alt=""
              isEditable={isEditable}
              onImageUpdate={imgUrl =>
                updateSectionBlock({
                  blockId: item.id,
                  field: 'image',
                  value: imgUrl
                })
              }
            />
            {/* <img src={item.image} alt="" /> */}
            <ContentEditable
              html={item.heading}
              onChange={e =>
                updateSectionBlock({
                  blockId: item.id,
                  field: 'heading',
                  value: e.target.value
                })
              }
              disabled={!isEditable}
              className={classes.serviceBlockHeading}
              style={{ textAlign: 'center' }}
            />
            {/* <div
              className={classes.serviceBlockHeading}
              dangerouslySetInnerHTML={{ __html: item.heading }}
            ></div> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services
