import React, { useState } from 'react'
import ContentEditable from 'react-contenteditable'
import ImageWrapper from '../ImageWrapper'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { CustomPopper, FormLabel } from 'global/globalComponents'
import { Button, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'

const Footer = ({
  data,
  classes,
  isEditable,
  updateTemplateSection,
  updateSectionBlock
}) => {
  const [copyrightBlock, linksBlock] = data.blocks

  const updateFooterLink = ({ index, text, url }) => {
    const updatedData = linksBlock.items.map((item, idx) =>
      idx === index ? { text, url } : item
    )

    updateSectionBlock({
      blockId: linksBlock.id,
      field: 'items',
      value: updatedData
    })
  }

  return (
    <div className={classes.footer}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '1rem 0'
        }}
      >
        <ImageWrapper
          style={{ width: 100 }}
          src={data.image}
          alt=""
          isEditable={isEditable}
          onImageUpdate={imgUrl =>
            updateTemplateSection({
              field: 'image',
              value: imgUrl
            })
          }
        />
        {/* <img style={{ width: 100 }} src={data.image} alt="" /> */}
      </div>
      <hr style={{ border: 'none', borderTop: '1px solid #343434' }} />

      <div className={classes.sectionContainer}>
        <div className={classes.footerContentWrapper}>
          <ContentEditable
            html={copyrightBlock.description}
            onChange={e =>
              updateSectionBlock({
                blockId: copyrightBlock.id,
                field: 'description',
                value: e.target.value
              })
            }
            disabled={!isEditable}
          />

          <div style={{ display: 'flex' }}>
            {linksBlock.items.map((item, index) => (
              <div key={index} className={classes.footerLinkWrapper}>
                <a href={item.url || '#'} target="_blank" rel="noreferrer">
                  {item.text}
                </a>

                {isEditable && (
                  <EditLinkPopup
                    index={index}
                    {...item}
                    updateFooterLink={({ text, url }) =>
                      updateFooterLink({ index, text, url })
                    }
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const EditLinkPopup = ({ text, url, updateFooterLink }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [formData, setFormData] = useState({
    text: text,
    url: url
  })

  const handleSaveChanges = () => {
    if (!formData.text.trim()) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }
    updateFooterLink({ text: formData.text, url: formData.url })
    setAnchorEl(null)
  }

  return (
    <>
      <button onClick={e => setAnchorEl(e.currentTarget)}>
        <PenIcon />
      </button>
      <CustomPopper anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <div className="w-72 p-4">
          <div className="mb-2">
            <FormLabel>{t('TEXT')}</FormLabel>
            <TextField
              value={formData.text}
              onChange={e =>
                setFormData(prev => ({ ...prev, text: e.target.value }))
              }
              fullWidth
            />
          </div>
          <div className="mb-4">
            <FormLabel>{t('URL')}</FormLabel>
            <TextField
              value={formData.url}
              onChange={e =>
                setFormData(prev => ({ ...prev, url: e.target.value }))
              }
              fullWidth
            />
          </div>

          <div className="text-right">
            <Button onClick={handleSaveChanges}>{t('UPDATE')}</Button>
          </div>
        </div>
      </CustomPopper>
    </>
  )
}

export default Footer
