import React from 'react'
import clsx from 'clsx'

const OuterContentWrapper = props => {
  const { children, className, ...restProps } = props
  return (
    <div
      className={clsx('p-8 2xl:p-10', className, 'dark:bg-gray-800')}
      {...restProps}
    >
      {children}
    </div>
  )
}

export default OuterContentWrapper
