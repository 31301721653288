import React from 'react'
import MessageAttachments from 'components/_shared/Chat/ChatWindow/MessageAttachments'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const ChatMessage = ({ message, handleOpenPreview }) => {
  const classes = useStyles()

  return (
    <div className="pt-1 text-sm">
      <div
        className={clsx(
          'break-long-word dark:text-dark-light2',
          classes.container
        )}
        dangerouslySetInnerHTML={{
          __html: message.message
        }}
      ></div>
      {Boolean(message.attachments?.length) && (
        <div>
          <MessageAttachments
            attachments={message.attachments || []}
            handleOpenPreview={handleOpenPreview}
          />
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    '& > *': {
      color: theme.palette.type === 'dark' ? '#fff' : '#17191c'
    }
  }
}))

export default ChatMessage
