import React, { useEffect, useState } from 'react'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, useTheme } from '@material-ui/core'
import { FormLabel, LoadingButton } from 'global/globalComponents'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Currency from 'static/Currency/Currency'
import Pill from 'global/globalComponents/Pill/Pill'
import { createClientSubscription } from 'thunks/subscription/actions'
import {
  fireErrorToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'

const NewSubscriptionModal = ({ open, onClose }) => {
  return (
    <CustomModal open={open} handleClose={onClose} size="medium">
      <ModalContent onClose={onClose} />
    </CustomModal>
  )
}

const ModalContent = ({ onClose }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { t } = useTranslation()
  const userClients = useSelector(state => state.userClients)
  const userServices = useSelector(state => state.userServices)
  const [formData, setFormData] = useState({
    client: {},
    service: {}
  })
  const [loading, setLoading] = useState(false)
  const subscriptions = useSelector(state => state.subscriptions)
  const serviceOptions = userServices.data.filter(
    service => service.isRecurring
  )
  const [subscriptionMatch, setSubscriptionMatch] = useState(false)

  useEffect(() => {
    if (formData.client._id && formData.service._id) {
      const matchIndex = subscriptions.data.findIndex(subscription => {
        const subscriptionService = subscription.services.find(
          service => service?._id === formData.service._id
        )
        return (
          Boolean(subscriptionService) &&
          subscription.client?._id === formData.client._id &&
          subscription.status !== 'canceled'
        )
      })

      setSubscriptionMatch(matchIndex !== -1)
    }
  }, [formData.client._id, formData.service._id])

  // const subscriptionMatch =
  //   formData.client._id && formData.service._id
  //     ? subscriptions.data.find(subscription => {
  //         const subscriptionService = subscription.services.find(
  //           service => service?._id === formData.service._id
  //         )
  //         return (
  //           Boolean(subscriptionService) &&
  //           subscription.client?._id === formData.client._id &&
  //           subscription.status !== 'canceled'
  //         )
  //       })
  //     : false

  const optionLabel = service => {
    return (
      <div className="self-center flex justify-between items-center">
        <span className="pr-2">
          {service.name} (
          {Currency[service?.currency?.toUpperCase()]?.symbol_native}
          {service.amount * 0.01}){' '}
        </span>

        <Pill variant="yellow">Recurring</Pill>
      </div>
    )
  }

  const handleCreateSubscription = () => {
    if (!formData.client._id || !formData.service._id) {
      dispatch(fireWarningToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    const subscriptionBody = {
      services: [formData.service._id],
      trialDays: formData.service.recurring.trial_period_days,
      client: formData.client._id
    }

    setLoading(true)
    dispatch(
      createClientSubscription({ body: subscriptionBody }, (res, err) => {
        if (err) {
          setLoading(false)
          dispatch(fireErrorToaster(res))
        } else {
          onClose()
        }
      })
    )
  }

  return (
    <>
      <CustomModalHeader heading={t('SHARE')} handleClose={onClose} />
      <CustomModalBody>
        <div className="h-72">
          <div className="mb-4">
            <FormLabel required>{t('CLIENT')}</FormLabel>
            <Select
              isLoading={userClients.loading}
              onChange={val => setFormData(prev => ({ ...prev, client: val }))}
              options={userClients.data}
              getOptionLabel={option => option.name}
              getOptionValue={option => option._id}
              styles={reactSelectCustomStyles(theme)}
              value={formData.client._id ? formData.client : null}
              placeholder={t('SELECT_CLIENT')}
              maxMenuHeight={150}
            />
          </div>

          <div className="mb-4">
            <FormLabel required>{t('SELECT_SERVICE')}</FormLabel>
            <Select
              placeholder={t('SELECT_SERVICE')}
              options={serviceOptions}
              getOptionLabel={optionLabel}
              getOptionValue={option => option._id}
              onChange={val => setFormData(prev => ({ ...prev, service: val }))}
              styles={reactSelectCustomStyles(theme)}
              maxMenuHeight={120}
              value={formData.service._id ? formData.service : null}
              isLoading={userServices.loading}
            />
          </div>

          {subscriptionMatch && (
            <p className="text-smaller text-red-400">
              {t('SUBSCRIPTION_EXISTS_WARNING')}
            </p>
          )}
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" type="button" variant="outlined" onClick={onClose}>
          {t('CLOSE')}
        </Button>
        <LoadingButton
          size="large"
          type="button"
          loading={loading}
          onClick={handleCreateSubscription}
          disabled={subscriptionMatch}
        >
          {t('CREATE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default NewSubscriptionModal
