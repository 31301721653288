import { lazy } from 'react'
// import { SEOProposal } from 'static/Images/proposalTemplates'

// const Template001 = lazy(() =>
//   import('./Templates/Template-001/Template-001.js')
// )
const Template002 = lazy(() =>
  import('./Templates/Template-002/Template-002.js')
)
const Template003 = lazy(() =>
  import('./Templates/Template-003/Template-003.js')
)
const Template004 = lazy(() =>
  import('./Templates/Template-004/Template-004.js')
)

export const templates = {
  // '001': Template001,
  '002': Template002,
  '003': Template003,
  '004': Template004
}

export const getProposalTemplateImage = uniqueId => {
  switch (uniqueId) {
    // case '001':
    //   return ServiceProposal
    case '002':
      return 'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/template-assets/template-002.png'
    case '003':
      return 'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/template-assets/template-003.png'
    case '004':
      return 'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/template-assets/template-004.png'
    default:
      return null
  }
}

// const defaultPropsToGet = ['containerName', 'blockName', 'fieldName', 'index']

// export const getElmDataProps = (elm, props = defaultPropsToGet) => {
//   const obj = {}
//   props.forEach(item => (obj[item] = elm.dataset[item]))

//   return obj
// }

export const proposalTemplatesData = [
  // {
  //   uniqueId: '001',
  //   imgUrl: ServiceProposal,
  //   name: 'Service proposal template',
  //   description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
  //   tags: ['business']
  // },
  {
    uniqueId: '002',
    name: 'SEO proposal template',
    imgUrl: getProposalTemplateImage('002'),
    description:
      'Boost online visibility with our SEO template. Drive organic traffic through keyword optimization, content strategy, and on-page tactics. Climb search rankings and expand digital reach.',
    tags: ['business', 'marketing']
  },
  {
    uniqueId: '003',
    name: 'Joint venture and agreement template',
    imgUrl: getProposalTemplateImage('003'),
    description:
      'Forge strong partnerships effortlessly. Our template defines roles, responsibilities, and terms, ensuring smooth collaborations. Establish mutually beneficial agreements.',
    tags: ['business']
  },
  {
    uniqueId: '004',
    name: 'Marketing Proposal',
    imgUrl: getProposalTemplateImage('004'),
    description:
      'Craft a winning marketing strategy that fuels brand growth. Tailor your approach, pinpoint target audiences, and choose effective channels. Elevate engagement and capture market share.',
    tags: ['marketing']
  }
]
