import React from 'react'
import { NavLink } from 'react-router-dom'
import headerStyles from '../../headerStyles'

const StaticTabs = ({ tabsData = [] }) => {
  const classes = headerStyles()

  return (
    <>
      {tabsData.map(item => (
        <SingleTab key={item.name} tabData={item} classes={classes} />
      ))}
    </>
  )
}

const SingleTab = ({ tabData, classes }) => {
  return (
    <>
      {!tabData.hide && (
        <NavLink
          to={tabData.path + tabData.query}
          className={classes.tab}
          isActive={(match, location) =>
            match && location.search === tabData.query
          }
          activeClassName="isActive"
        >
          {tabData.innerHTML ? (
            tabData.innerHTML
          ) : (
            <>
              <span className="inline-flex my-auto justify-center items-center border-l px-3 w-full gap-1 dark:text-dark-light dark:border-white">
                {tabData.icon}
                <span>{tabData.label}</span>
                {tabData.badge}
              </span>
              <div className="tab-line w-3/4 h-1 mx-auto dark:bg-white"></div>
            </>
          )}
        </NavLink>
      )}
    </>
  )
}

export default React.memo(StaticTabs)
