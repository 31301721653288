import axios from 'axios'
import { CLIENT } from './reducers'
import { PULSE } from 'thunks/pulse/reducer'
import { catchAsync, catchAsyncDispatch } from 'utils'

/**
 * Add one client
 * @param {object} data.body name,role,email,password etc
 * @param {Function} callback callback function
 */
export const addClient = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/user/addClient',
      data: data.body
    })
    if (callback) callback(res.data)
    dispatch({ type: CLIENT.ADD_ONE_CLIENT, payload: res.data })
  }, callback)
}

/**
 * Add multi clients
 * @param {Array} data.data
 * @param {Function} callback callback function
 */
export const addMultiClient = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/user/addMultipleClient',
    data: {
      client: data.data
    }
  })
  if (callback) callback(res.data)
})

/**
 * Add one client
 * @param {object} data.data name,role,email,password
 * @param {Function} callback callback function
 */
export const inviteClient = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/user/inviteclient',
    data: data.data
  })
  if (callback) callback(res.data)
})

/**
 * fetch all clients of a user.
 * @param {Function} callback  callback function
 */

export const getClient = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/user/client'
    })

    dispatch({ type: CLIENT.FETCHED, payload: res.data })
    if (callback) callback(res.data, false)
    dispatch({
      type: PULSE.CREATE_USERS_PULSE,
      payload: res.data.map(item => ({
        _id: item._id,
        lastActive: item.lastActive
      }))
    })
  }, callback)
}

/**
 * Delete one client of a user.
 * @param {String} client client Id
 * @param {Function} callback  callback function
 */
export const deleteclient = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/user/${data.clientId}`,
      data: data.data
    })
    if (callback) callback(res.data)
    dispatch({ type: CLIENT.DELETE_ONE_CLIENT, payload: res.data })
  }, callback)
}

/**
 * update client data.
 * @param {String} data.clientId
 * @param {Object} data.data name,workspaces
 * @param {Function} callback callback
 */
export const updateClient = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/clientprofile/client/${data.clientId}`,
      data: data.data
    })
    if (callback) callback(res.data)
    dispatch({
      type: CLIENT.UPDATE_ONE_CLIENT,
      payload: { data: res.data, quota: data.data.quota }
    })
  }, callback)
}

/**
 * Update client status
 * @param {String} data.clientId
 * @param {Object} data.data isActive
 * @param {Function} callback callback function
 */
export const updateClientStatus = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/user/${data.clientId}`,
      data: data.data
    })
    if (callback) callback(res.data)
    dispatch({ type: CLIENT.UPDATE_ONE_CLIENT, payload: res.data })
  }, callback)
}

// /**
//  * Update client active services payment status
//  * @param {String} id service id
//  * @param {Object} data status paid or unpaid
//  * @param {Function} callback callback function
//  */
// export const updateClientServicePaymentStatus = (id, data, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'PUT',
//         url: `/clientpayment/agency/${id}`,
//         data
//       })
//       dispatch({
//         type: CLIENT.UPDATE_CURRENT_CLIENT_PAYMENT_STATUS,
//         payload: res.data
//       })
//       if (callback) callback(res.data, false)
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//     }
//   }
// }

/**
 * Fetch client info
 * @param {String} data.clientId
 * @param {Function} callback callback function
 */
export const fetchClientInfo = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/clientprofile/client/info/${data.clientId}`
  })
  if (callback) callback(res.data)
})
