import React, { useState } from 'react'
import { userRoles, getRoleBadge } from 'utils'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { ReactComponent as AngleDown } from 'static/svg/angle-down.svg'
import { useSelector } from 'react-redux'
import PermissionOptionsPopper from './PermissionOptionsPopper'
import { t } from 'i18next'
import { isCurrentUserRoleHigher } from 'utils/userRoles'

const AgencyTeammatesTab = ({ members = [], projectRole }) => {
  const meData = useSelector(state => state.me.data)
  const isClient = projectRole === userRoles.USER_CLIENT

  return (
    <div className="mt-4">
      <div className="flex justify-between items-center text-sm font-medium mb-3">
        <div className="dark:text-dark-light">
          {t('TEAM_MEMBERS')} ({members.length})
        </div>

        {!isClient && members.length > 0 && (
          <div className="dark:text-dark-light">{t('MANAGE_PERMISSIONS')}</div>
        )}
      </div>
      <div className="h-60 overflow-auto">
        {members.length > 0 ? (
          members.map((mate, index) => (
            <SingleMember
              key={index}
              mate={mate}
              meRole={meData.role}
              meId={meData._id}
              canManagePermissions={isCurrentUserRoleHigher({
                currentUserRole: projectRole,
                otherUserRole: mate.role
              })}
            />
          ))
        ) : (
          <p className="text-sm text-gray-400 dark:text-dark-light">
            {t('NO_USERS')}
          </p>
        )}
      </div>
    </div>
  )
}

const SingleMember = ({ mate, meRole, meId, canManagePermissions }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center gap-2">
        <ColoredAvatars user={mate.user || {}} />
        <div>
          <div className="flex items-center leading-3">
            <span className="mr-2 capitalize text-smaller dark:text-white">
              {mate.user?.name}
            </span>{' '}
            <span>{getRoleBadge(mate.role, { height: 20 })}</span>
          </div>
          {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meRole) && (
            <div className="text-gray-500 text-xs mt-1 dark:text-dark-light">
              {mate.user?.email}
            </div>
          )}
        </div>
      </div>

      {meId !== mate.user?._id && canManagePermissions && (
        <>
          <div
            onClick={e => setAnchorEl(e.currentTarget)}
            className="flex items-center text-smaller cursor-pointer px-2 mr-1 py-1 rounded hover:bg-gray-200"
          >
            <div className="capitalize dark:text-dark-light">
              {getRoleText(mate.role)}
            </div>
            <AngleDown
              className={`h-6 w-6 text-white fill-white`}
              style={{ fill: 'gray', fontSize: '5px' }}
            />
          </div>
          <PermissionOptionsPopper
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            teammate={mate}
            permissionOptions={[
              {
                title: t('CAN_MANAGE'),
                description: t('CAN_MANAGE_DESC'),
                access: [userRoles.AGENCY_MANAGER, userRoles.AGENCY_ADMIN],
                role: userRoles.AGENCY_MANAGER
              },
              {
                title: t('CAN_EDIT'),
                description: t('CAN_EDIT_DESC'),
                access: [userRoles.AGENCY_EDITOR],
                role: userRoles.AGENCY_EDITOR
              },
              {
                title: t('CAN_VIEW'),
                description: t('CAN_VIEW_DESC'),
                access: [userRoles.AGENCY_VIEWER],
                role: userRoles.AGENCY_VIEWER
              },
              {
                title: t('SET_AS_PROJECT_OWNER'),
                description: t('SET_AS_PROJECT_OWNER_DESC'),
                access: [userRoles.PROJECT_OWNER],
                role: userRoles.PROJECT_OWNER
              }
            ]}
          />
        </>
      )}
    </div>
  )
}

const getRoleText = role => {
  const roleTexts = {
    15: t('PROJECT_OWNER'),
    21: t('CAN_MANAGE'),
    22: t('CAN_EDIT'),
    23: t('CAN_VIEW'),
    24: t('CAN_MANAGE')
  }

  return roleTexts[role]
}

export default AgencyTeammatesTab
