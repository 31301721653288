import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { CLIENT } from 'thunks/addClient/reducers'
import { useBreadcrumbs } from 'customHooks'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  ColoredAvatars,
  CustomTooltip,
  CustomTabs,
  GoBack
} from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import { userRoles } from 'utils'
import { getClientCompanies } from 'thunks/clientCompany/action'
import { fetchClientInfo } from 'thunks/addClient/actions'
import { ReactComponent as LockIcon } from 'static/svg/lock.svg'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import clsx from 'clsx'
import { useRouteMatch } from 'react-router-dom'
import { ReactComponent as AngleIcon } from 'static/svg/angle-down.svg'
import CurrencyDropdown from './CurrencyDropdown'
import { updateClientCompany } from 'thunks/clientCompany/action'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { ReactComponent as UserIcon } from 'static/svg/user.svg'
import { ReactComponent as UserPlusIcon } from 'static/svg/user-plus.svg'
import { CircularProgress, TextField } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import AddressModal from './AddressModal'
import clientCompanyStyles from './clientCompanyStyles'
import { updateClient } from 'thunks/addClient/actions'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import { fileToDataURL } from 'utils'
import ProjectCardsPage from './ProjectCardsPage'
import { useTranslation } from 'react-i18next'
import TeamMembers from 'components/_agency/ClientProfile/TeamMembers'
import ClientInvoices from 'components/_agency/ClientProfile/ClientInvoices'

export default function CompanyMain() {
  const { t } = useTranslation()
  const classes = clientCompanyStyles()
  const access = useSelector(state => state.permission.data?.access)
  const meData = useSelector(state => state.me.data)
  const { companyId } = useParams()
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const allClientsData = useSelector(state => state.userClients)
  const clientCompany = useSelector(state => state.clientCompany)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const [companyData, setCompanyData] = useState({})
  const [clientInfo, setClientInfo] = useState({ loading: false, data: {} })
  const userPermission = useSelector(state => state.permission.data)
  const [selectedTab, setSelectedTab] = useState(0)
  const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null)
  const [editText, setEditText] = useState({
    address: false,
    tax: false,
    brandName: false
  })
  const [loading, setLoading] = useState(false)
  const [addressModalState, setAddressModalState] = useState(false)
  const selectedClientCompany = useMemo(() => {
    return clientCompany.data.find(el => el._id === companyId)
  }, [companyId, clientCompany])

  const clientData = useSelector(state => state.userClients.currentClient)
  const agencyTeammates = useSelector(state => state.userTeammates)
  const [clientSelectOptions, setClientSelectOptions] = useState([])
  const [teammateSelectOptions, setTeammateSelectOptions] = useState([])
  const [assignUser, setAssignUser] = useState({
    anchorEl: null,
    list: [],
    handleDialog: null,
    type: 'teammate'
  })

  useEffect(() => {
    setClientSelectOptions(
      allClientsData.data
        .filter(client => !(client.company && client.company._id))
        .map(item => ({
          user: { ...item },
          role: item.role
        }))
    )
  }, [allClientsData])

  useEffect(() => {
    setTeammateSelectOptions(
      agencyTeammates.data.map(item => ({
        user: { ...item },
        role: item.role
      }))
    )
  }, [agencyTeammates.data])

  const getRepresentative = () => {
    if (companyData.clientOwner && clientData?._id) {
      let mates = [
        ...agencyTeammates.data,
        {
          _id: meData._id,
          name: meData.name,
          profileImage: meData.profileImage,
          email: meData.email
        }
      ]
      if (meData.role !== userRoles.USER_AGENCY) mates.push(meData.team)
      const representativeObj = mates.find(
        mate => mate._id === clientData.agencyRepresentative
      )
      if (representativeObj) {
        return (
          <div className="flex items-center space-x-2 mt-1 dark:text-dark-light">
            <ColoredAvatars user={representativeObj} size="small" />
            <span className="text-smaller">{representativeObj?.name}</span>
          </div>
        )
      }
      return (
        <>
          {[
            userRoles.USER_AGENCY,
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_MANAGER
          ].includes(meData.role) ? (
            <CustomTooltip title={t('ASSIGN_ACCOUNT_MANAGER')} placement="top">
              <UserPlusIcon
                className="cursor-pointer w-4 h-4 text-primary-mid-dark mt-1 dark:text-dark-light"
                onClick={e => {
                  setAssignUser({
                    anchorEl: e.currentTarget,
                    list: teammateSelectOptions,
                    handleDialog: handleAgencyRepresentative,
                    type: 'teammate'
                  })
                }}
              />
            </CustomTooltip>
          ) : (
            <UserIcon className="w-4 h-4 text-primary-mid-dark mt-1 dark:text-dark-light" />
          )}
        </>
      )
    } else {
      return (
        <UserIcon className="w-4 h-4 text-primary-mid-dark mt-1 dark:text-dark-light" />
      )
    }
  }

  const getCompanyRepresentative = () => {
    if (
      companyData.clientOwner &&
      clientData?._id === companyData.clientOwner
    ) {
      return (
        <div className="flex items-center space-x-2 mt-1 dark:text-dark-light">
          <ColoredAvatars user={clientData} size="small" />
          <span className="text-smaller">{clientData?.name}</span>
        </div>
      )
    }

    return (
      <>
        {[
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER
        ].includes(meData.role) ? (
          <CustomTooltip
            title={t('ASSIGN_ORGANIZATION_REPRESENTATIVE')}
            placement="top"
          >
            <UserPlusIcon
              className="cursor-pointer w-4 h-4 text-primary-mid-dark mt-1 dark:text-dark-light"
              onClick={e => {
                setAssignUser({
                  anchorEl: e.currentTarget,
                  list: clientSelectOptions,
                  handleDialog: handleCompanyRepresentative,
                  type: 'client'
                })
              }}
            />
          </CustomTooltip>
        ) : (
          <UserIcon className="w-4 h-4 text-primary-mid-dark mt-1 dark:text-dark-light" />
        )}
      </>
    )
  }

  const getCompanyAddress = () => {
    if (companyData.address) {
      const {
        line1,
        line2,
        city,
        state,
        country,
        zipCode
      } = companyData?.address
      return `${line1 ? `${line1}, ` : ''}${line2 ? `${line2}, ` : ''}${
        city ? `${city}, ` : ''
      }${state ? `${state}, ` : ''}${
        country?.label ? `${country.label}, ` : ''
      }${zipCode ? zipCode : ''}`
    }
    return ''
  }

  const handleAgencyRepresentative = item => {
    dispatch(
      updateClient(
        {
          clientId: clientData._id,
          data: {
            agencyRepresentative: item.user._id
          }
        },
        (res, err) => {
          handleClose()
          if (!err) {
            dispatch(fireSuccessToaster(t('UPDATED_SUCCESSFULLY')))
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleCompanyRepresentative = item => {
    dispatch(
      updateClientCompany(
        {
          companyId: companyData._id,
          data: {
            newClientOwner: item.user._id
          }
        },
        (res, err) => {
          handleClose()
          if (!err) {
            dispatch(fireSuccessToaster(t('UPDATED_SUCCESSFULLY')))
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleClose = () => {
    setAssignUser({
      anchorEl: null,
      list: [],
      handleDialog: null,
      type: 'teammate'
    })
  }

  useBreadcrumbs([
    {
      name: t('CLIENTS'),
      link: `/clients/all`,
      index: 0
    },
    {
      name: t('CLIENT_SETTINGS'),
      link: `/clients/company`,
      index: 1
    }
  ])

  useEffect(() => {
    if (!clientCompany.fetched) {
      dispatch(getClientCompanies(null))
    }
  }, [clientCompany.fetched])

  // useEffect(() => {
  //   if (!agencyTeammates.fetched) {
  //     dispatch(fetchTeammateOfCurrentUser())
  //   }
  // }, [agencyTeammates.fetched])

  useEffect(() => {
    if (clientCompany.fetched) {
      setCompanyData(clientCompany.data.find(el => el._id === companyId))
    }
  }, [companyId, clientCompany.fetched, selectedClientCompany])

  useEffect(() => {
    if (allClientsData.fetched) {
      if (companyData.clientOwner) {
        dispatch({
          type: CLIENT.ADD_CURRENT_CLIENT,
          payload: companyData.clientOwner
        })
      } else {
        dispatch({
          type: CLIENT.ADD_CURRENT_CLIENT,
          payload: undefined
        })
      }
    }
  }, [companyData?.clientOwner, allClientsData.fetched])

  useEffect(() => {
    if (allClientsData.currentClient?._id && companyData.clientOwner) {
      setClientInfo(prev => ({ ...prev, loading: true }))
      fetchClientInfo(
        { clientId: allClientsData.currentClient._id },
        (res, err) => {
          if (!err) {
            setClientInfo({ loading: false, data: res })
          } else {
            setClientInfo(prev => ({ ...prev, loading: false }))
            dispatch(
              fireErrorToaster(t('ERROR_OCCURED_WHILE_FETCHING_CLIENT_INFO'))
            )
          }
        }
      )
    }
  }, [allClientsData.currentClient])

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [userWorkspaces.fetchedFirstTime])

  const handleTabChange = newVal => {
    setSelectedTab(newVal)
  }

  const handleCurrencyDropdown = e => {
    e.stopPropagation()
    setCurrencyAnchorEl(e.currentTarget)
  }

  const handleCurrency = currency => {
    dispatch(
      updateClientCompany(
        { companyId: companyData._id, data: { defaultCurrency: currency } },
        updateClientCompanyCallback
      )
    )
  }

  const handleCompanyData = e => {
    const { name, value } = e.target
    setCompanyData(prev => ({ ...prev, [name]: value }))
  }

  const handleBrandName = e => {
    e.preventDefault()
    if (!companyData.brandName) {
      setCompanyData(selectedClientCompany)
      return dispatch(fireErrorToaster(t('BRAND_NAME_IS_REQUIRED')))
    }
    setLoading(true)
    dispatch(
      updateClientCompany(
        {
          companyId: companyData._id,
          data: { brandName: companyData.brandName }
        },
        updateClientCompanyCallback
      )
    )
  }

  const handleTax = e => {
    e.preventDefault()
    setLoading(true)
    dispatch(
      updateClientCompany(
        { companyId: companyData._id, data: { tax: companyData.tax } },
        updateClientCompanyCallback
      )
    )
  }

  const updateClientCompanyCallback = (res, err) => {
    setLoading(false)
    setEditText({
      address: false,
      tax: false,
      brandName: false
    })
    if (!err) {
      setCompanyData(res)
      dispatch(fireSuccessToaster(t('SUCCESS_UPDATED')))
    } else {
      setCompanyData(selectedClientCompany)
      dispatch(fireErrorToaster(res))
    }
  }

  const imageLoadHandler = e => {
    const file = e.target.files[0]
    fileToDataURL(file).then(res => {
      // setCompanyData(prev => ({...prev, logo: res}))
      dispatch(
        updateClientCompany(
          { companyId: companyData._id, data: { file: res } },
          updateClientCompanyCallback
        )
      )
    })
  }

  return (
    <OuterContentWrapper>
      {companyData && (
        <>
          <GoBack
            text={t('ORGANIZATIONS')}
            path={path.split(`/company`)[0] + '/all/company'}
          />
          {/* <Link
            to={path.split(`/company`)[0] + '/all/company'}
            className="text-primary-main text-smaller 2xl:text-sm hover:underline flex items-center space-x-2"
          >
            <BackArrow
              className="h-6 w-4 text-primary-main dark:text-dark-light"
              // style={{ fill: 'rgba(96, 165, 250, 1)' }}
            />
            <span className="dark:text-dark-light">{t('ORGANIZATIONS')}</span>
          </Link> */}
          <Wrapper className="mb-9 mt-4">
            <div
              className={clsx(
                'text-xs 2xl:text-sm',
                classes.companyInfoContainer
              )}
            >
              <div className="flex-1 max-w-lg">
                <div className="flex items-center mb-4">
                  <div className="relative">
                    {access?.clients?.update && (
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="user-image-uploadbtn"
                        multiple={false}
                        type="file"
                        onChange={imageLoadHandler}
                      />
                    )}
                    <label htmlFor="user-image-uploadbtn">
                      <img
                        src={
                          companyData.logo
                            ? companyData.logo.includes('https://s3')
                              ? `${companyData.logo}?${Date.now()}`?.replace(
                                  's3.wasabisys.com/',
                                  ''
                                )
                              : companyData.logo
                            : 'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'
                        }
                        className="object-contain cursor-pointer"
                        style={{ maxWidth: 130, maxHeight: 60, minWidth: 60 }}
                        alt=""
                      />
                    </label>
                  </div>
                  <div className="ml-4">
                    {editText.brandName ? (
                      <div className="flex items-center space-x-1">
                        <form onSubmit={handleBrandName}>
                          <TextField
                            placeholder={t('BRAND_NAME')}
                            value={companyData.brandName}
                            onChange={handleCompanyData}
                            name="brandName"
                            autoFocus
                            style={{ width: '100px' }}
                          />
                        </form>
                        {!loading ? (
                          <Check
                            className="p-0.5 cursor-pointer text-primary-main hover:bg-gray-50 dark:text-dark-light"
                            fontSize="small"
                            onClick={handleBrandName}
                          />
                        ) : (
                          <CircularProgress
                            fontSize="small"
                            style={{ width: '10px', height: '10px' }}
                          />
                        )}
                        <CloseIcon
                          className="p-0.5 cursor-pointer text-red-500 hover:bg-gray-50 dark:text-dark-light"
                          fontSize="small"
                          onClick={() =>
                            setEditText(prev => ({
                              address: false,
                              tax: false,
                              brandName: !prev.brandName
                            }))
                          }
                        />
                      </div>
                    ) : (
                      <p className="text-primary-dark font-semibold capitalize text-sm md:text-base 2xl:text-lg flex items-center space-x-1 py-1 show-on-hover-parent dark:text-dark-light2">
                        <span>{companyData.brandName}</span>
                        {access?.clients?.update && (
                          <Pen
                            className="show-on-hover-child px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 opacity-0 hover:bg-gray-100"
                            onClick={() =>
                              setEditText(prev => ({
                                address: false,
                                tax: false,
                                brandName: !prev.brandName
                              }))
                            }
                          />
                        )}
                      </p>
                    )}
                    <p className=" text-custom-gray-light-6 dark:text-dark-light">
                      <span>{companyData.category}</span>
                    </p>
                  </div>
                </div>

                <div className=" show-on-hover-parent">
                  <p className="text-primary-dark font-medium  flex items-center space-x-2 py-1 dark:text-dark-light">
                    <span className="dark:text-dark-light2">
                      {t('ADDRESS')}
                    </span>
                    {access?.clients?.update && (
                      <Pen
                        className="show-on-hover-child p-1 h-5 w-5 cursor-pointer border-b border-gray-600 opacity-0 hover:bg-gray-100 dark:text-dark-light dark:hover:bg-dark-main rounded-md"
                        onClick={() => setAddressModalState(true)}
                      />
                    )}
                  </p>

                  <p className="text-custom-gray-light-6 flex space-x-1 dark:text-dark-light">
                    {getCompanyAddress()}
                  </p>
                </div>
              </div>

              <div className={clsx(classes.companyInfoRight, 'flex-1')}>
                {/* 1. Company rep */}
                <div className="show-on-hover-parent">
                  <p className="text-primary-dark font-medium  flex items-center space-x-2 dark:text-dark-light2">
                    <span>{t('ORGANIZATION_REPRESENTATIVE')}</span>
                  </p>
                  <div className="flex items-center space-x-1">
                    {getCompanyRepresentative()}
                    {access?.clients?.update && (
                      <CustomTooltip
                        title={t('CHANGE_ORGANIZATION_REPRESENTATIVE')}
                        placement="top"
                      >
                        <AngleIcon
                          className="show-on-hover-child opacity-0 mt-1 h-5 w-5 p-0.5 rounded hover:bg-gray-100 dark:text-dark-light dark:hover:bg-dark-main"
                          onClick={e => {
                            setAssignUser({
                              anchorEl: e.currentTarget,
                              list: clientSelectOptions,
                              handleDialog: handleCompanyRepresentative,
                              type: 'client'
                            })
                          }}
                        />
                      </CustomTooltip>
                    )}
                  </div>
                </div>

                {/* 2. Account Manager */}
                <div>
                  <p className="text-primary-dark font-medium dark:text-dark-light2">
                    {t('ACCOUNT_MANAGER')}
                  </p>
                  {getRepresentative()}
                </div>

                {/* 3. Currency */}
                <div className="show-on-hover-parent">
                  <p className="text-primary-dark font-medium dark:text-dark-light2">
                    {t('CURRENCY')}
                  </p>
                  <p className="text-custom-gray-light-6  flex items-center space-x-2 py-1 dark:text-dark-light">
                    <span>{companyData.defaultCurrency?.toUpperCase()}</span>
                    {access?.clients?.update && (
                      <AngleIcon
                        className="show-on-hover-child h-5 w-5 p-0.5 rounded hover:bg-gray-100 opacity-0"
                        onClick={handleCurrencyDropdown}
                      />
                    )}
                  </p>
                </div>

                {/* 4. Tax information */}
                <div className="show-on-hover-parent">
                  <p
                    className={`text-primary-dark font-medium flex items-center space-x-2 dark:text-dark-light2`}
                  >
                    <span>{t('TAX_INFORMATION')}</span>
                  </p>
                  {editText.tax ? (
                    <div className="flex items-center space-x-1">
                      <form onSubmit={handleTax}>
                        <TextField
                          size="small"
                          placeholder={t('TAX_ID')}
                          value={companyData.tax}
                          onChange={handleCompanyData}
                          name="tax"
                          autoFocus
                          style={{ width: '100px' }}
                        />
                      </form>
                      {!loading ? (
                        <Check
                          className="p-0.5 cursor-pointer text-primary-main hover:bg-gray-50 dark:text-dark-light dark:hover:bg-dark-main rounded-md"
                          fontSize="small"
                          onClick={handleTax}
                        />
                      ) : (
                        <CircularProgress
                          fontSize="small"
                          style={{ width: '10px', height: '10px' }}
                        />
                      )}
                      <CloseIcon
                        className="p-0.5 cursor-pointer text-red-500 hover:bg-gray-50 dark:hover:bg-dark-main rounded-md"
                        fontSize="small"
                        onClick={() =>
                          setEditText(prev => ({
                            address: false,
                            brandName: false,
                            tax: !prev.tax
                          }))
                        }
                      />
                    </div>
                  ) : (
                    <p className="text-custom-gray-light-6 flex items-center space-x-2 pt-1 pb-3 dark:text-dark-light">
                      <span>
                        {companyData.tax ? companyData.tax : t('TAX_ID')}
                      </span>
                      {access?.clients?.update && (
                        <Pen
                          className="show-on-hover-child px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 opacity-0 hover:bg-gray-100"
                          onClick={() =>
                            setEditText(prev => ({
                              address: false,
                              brandName: false,
                              tax: !prev.tax
                            }))
                          }
                        />
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
          <div>
            <div className="w-full mb-6">
              <CustomTabs
                tabsData={[
                  { label: t('PROJECTS'), value: 0 },
                  { label: t('MEMBERS'), value: 1 },
                  { label: t('INVOICES'), value: 2 }
                ]}
                value={selectedTab}
                onChange={handleTabChange}
              />
            </div>
          </div>

          <div>
            {selectedTab === 0 && (
              <ProjectCardsPage
                userWorkspaces={userWorkspaces.data}
                clientInfo={clientInfo}
                clientData={clientData}
                companyData={companyData}
                elmFor="company"
              />
            )}
            {selectedTab === 1 && (
              <TeamMembers
                clientInfo={clientInfo.data}
                setClientInfo={setClientInfo}
                elmFor="company"
              />
            )}
            {selectedTab === 2 &&
              (userPermission.access?.billings?.view ? (
                <ClientInvoices />
              ) : (
                <>
                  <div className="bg-white shadow rounded-xl text-center py-8">
                    <span className="inline-block text-primary-main">
                      <LockIcon className="w-10 h-10" />
                    </span>
                    <p>{t('NOT_ENOUGH_PERMISSION_TO_VIEW_INVOICE')}</p>
                  </div>
                </>
              ))}
          </div>
        </>
      )}
      <CurrencyDropdown
        anchorEl={currencyAnchorEl}
        onClose={() => setCurrencyAnchorEl(null)}
        handleCurrency={handleCurrency}
      />
      <AddressModal
        open={addressModalState}
        handleClose={() => setAddressModalState(false)}
        company={companyData}
      />
      <CollabDrowdown
        open={Boolean(assignUser.anchorEl)}
        anchorEl={assignUser.anchorEl}
        onClose={handleClose}
        list={assignUser.list}
        handleListItemClick={assignUser.handleDialog}
        getUser={listItem => ({ ...listItem.user, role: listItem.role })}
      />
    </OuterContentWrapper>
  )
}
