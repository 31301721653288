import React, { useEffect, useState } from 'react'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading/SectionHeading'
// import { Button } from '@material-ui/core'
// import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import ApprovalsTable from './ApprovalsTable'
import CreateApprovalModal from './CreateApprovalModal'
import { useModal } from 'customHooks'
import { CustomTabs } from 'global/globalComponents'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { fetchApprovals } from 'thunks/approvals/actions'
import { useDispatch, useSelector } from 'react-redux'
import ReceivedApprovalsTable from './ReceivedApprovalsTable'
import { APPROVAL } from 'thunks/approvals/reducer'

const Approvals = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const { modal, openModal, closeModal } = useModal()
  const [selectedTab, setSelectedTab] = useState(pathname)
  const history = useHistory()
  const tabsData = [
    { label: t('RAISED'), value: path + '/raised' },
    {
      label: t('RECEIVED'),
      value: path + '/received'
    }
  ]

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  useEffect(() => {
    fetchApprovals(
      { query: { 'stages.members.user': meData._id } },
      (res, err) => {
        if (!err) {
          dispatch({
            type: APPROVAL.FETCHED_RECEIVED_APPROVALS,
            payload: res
          })
        }
      }
    )

    fetchApprovals({ query: { user: meData._id } }, (res, err) => {
      if (!err) {
        dispatch({
          type: APPROVAL.FETCHED_RAISED_APPROVALS,
          payload: res
        })
      }
    })
  }, [selectedTab])

  const handleTabChange = value => {
    history.push(value)
  }

  return (
    <OuterContentWrapper>
      <header className="mb-12">
        <div className="flex items-center justify-between mb-6">
          <SectionHeading>{t('APPROVALS')}</SectionHeading>
          {/* <Button startIcon={<PlusIcon />} onClick={openModal}>
            New Approval
          </Button> */}
        </div>

        <CustomTabs
          tabsData={tabsData}
          value={selectedTab}
          onChange={handleTabChange}
        />
      </header>

      {selectedTab === path ? <ApprovalsTable /> : <ReceivedApprovalsTable />}

      <CreateApprovalModal open={modal.open} onClose={closeModal} />
    </OuterContentWrapper>
  )
}

export default Approvals
