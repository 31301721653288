import React, { useCallback, useEffect, useRef, useState } from 'react'
import Quill from 'quill'
// import { makeStyles } from '@material-ui/core'
import { ReactComponent as PaperClipIcon } from 'static/svg/paperclip.svg'
import { ReactComponent as EmotionHappy } from 'static/svg/emotion-happy.svg'
import { ReactComponent as SendIcon } from 'static/svg/send.svg'
import { FormatUnderlined, FormatBold, FormatItalic } from '@material-ui/icons'
import Picker from '@emoji-mart/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { getMentionConfig } from 'components/Editors/editorsStatic'
import { useSelector } from 'react-redux'
import uniqueId from 'lodash/uniqueId'
import AttachmentFiles from 'components/Editors/AttachmentFiles'
import editorStyles from './QuillChatEditor.module.css'

const QuillChatEditor = ({
  users,
  onSubmit,
  onChange,
  onBlur,
  fileUploadHandler,
  styles = {}
}) => {
  const editor = useRef()
  const quill = useRef()
  // const classes = useStyles()
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const [fileStack, setFileStack] = useState([])

  const handleSubmitMessage = useCallback(() => {
    const isLoading = fileStack.find(item => item.loading)
    if (isLoading) return

    const editorContents = quill.current.getContents()
    let mentions = editorContents.ops
      .filter(item => item.insert.mention)
      .map(item => item.insert.mention.id)
    let hasEmoji = editorContents.ops.find(item => item.insert.emojiEmbed)

    if (
      quill.current.getText().trim() === '' &&
      !mentions.length &&
      !hasEmoji &&
      fileStack.length === 0
    )
      return

    onSubmit({
      data: editorContents,
      attachments: fileStack.map(item => item.url),
      attachmentIds: fileStack.map(item => item.fileId),
      mentions,
      hasEmoji,
      hasMentions: Boolean(mentions.length),
      callback
    })
  }, [fileStack])

  useEffect(() => {
    // =========== Quill setup =========
    const mention = getMentionConfig(users)

    quill.current = new Quill(editor.current, {
      theme: 'snow',
      modules: {
        toolbar: {
          container: '#chatEditorToolbar' // Selector for toolbar container
        },
        mention: {
          ...mention,
          onSelect: (item, insertItem) => {
            const user = users.find(user => user._id === item.id)
            insertItem({ ...item, ...user, notify: meData._id !== user._id })
          }
        }
      },
      placeholder: t('MESSAGE'),
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/
    })

    quill.current.clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
      let regex = /https?:\/\/[^\s]+/g
      if (typeof node.data !== 'string') return
      let matches = node.data.match(regex)

      if (matches && matches.length > 0) {
        let ops = []
        let str = node.data
        matches.forEach(function (match) {
          let split = str.split(match)
          let beforeLink = split.shift()
          ops.push({ insert: beforeLink })
          ops.push({ insert: match, attributes: { link: match } })
          str = split.join(match)
        })
        ops.push({ insert: str })
        delta.ops = ops
      }

      return delta
    })
  }, [users])

  useEffect(() => {
    if (!quill.current) return

    quill.current.keyboard.bindings[13].unshift({
      key: 13,
      handler: (range, context) => {
        handleSubmitMessage()
      }
    })
  }, [handleSubmitMessage])

  useEffect(() => {
    // ======= Quill event handling =========
    const handleTextChange = (delta, oldDelta, source) => {
      // ====== Detecting if it's a link =====
      // let regex =
      let regex = /https?:\/\/[^\s]+$/
      if (
        delta.ops.length === 2 &&
        delta.ops[0].retain &&
        isWhitespace(delta.ops[1].insert)
      ) {
        let endRetain = delta.ops[0].retain
        let text = quill.current.getText().substr(0, endRetain)
        let match = text.match(regex)

        if (match !== null) {
          let url = match[0]

          let ops = []
          if (endRetain > url.length) {
            ops.push({ retain: endRetain - url.length })
          }

          ops = ops.concat([
            { delete: url.length },
            { insert: url, attributes: { link: url } }
          ])

          quill.current.updateContents({
            ops: ops
          })
        }
      }
      // ====== link detection ends =====

      onChange(quill.current.root.innerHTML)
    }

    // quill.current.on('selection-change', onSelectionChange)
    quill.current.on('text-change', handleTextChange)
    quill.current.on('blur', onBlur)

    return () => {
      quill.current.off('text-change', handleTextChange)
      quill.current.off('blue', onBlur)
    }
  }, [onBlur, onChange])

  const callback = (res, err) => {
    if (!err) {
      quill.current.setContents({})
      setFileStack([])
    }
  }

  const addFileToStack = file => {
    let uniqId = uniqueId()

    setFileStack(prev => [
      ...prev,
      { id: uniqId, name: file.name, loading: true, url: null }
    ])

    fileUploadHandler(file, (res, err) => {
      if (err) {
        setFileStack(prev => prev.filter(item => item.id !== uniqId)) // filter out this file
      } else {
        setFileStack(prev =>
          prev.map(item =>
            item.id === uniqId
              ? { ...item, fileId: res._id, loading: false, url: res.link }
              : item
          )
        )
      }
    })
  }

  return (
    <div
      className={clsx(editorStyles.editor, 'bg-gray-50 dark:bg-dark-main')}
      style={styles.root}
    >
      <div
        ref={editor}
        style={{
          height: 45,
          border: 'none'
        }}
      ></div>

      <AttachmentFiles files={fileStack} />

      <CustomToolbar
        quill={quill.current}
        onSend={handleSubmitMessage}
        addFileToStack={addFileToStack}
      />
    </div>
  )
}

const CustomToolbar = ({ quill, onSend, addFileToStack }) => {
  const ref = useRef()
  const [data, setData] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [mounted, setMounted] = useState(true)

  useEffect(() => {
    return () => setMounted(false)
  }, [])

  useEffect(() => {
    fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
      .then(res => res.json())
      .then(res => {
        if (mounted) {
          setData(res)
        }
      })
  }, [])

  const handleTriggerUpload = () => {
    ref.current.click()
  }

  const handleUploadImage = e => {
    for (let i = 0; i < e.target.files.length; i++) {
      addFileToStack(e.target.files[i])
    }
    e.target.value = ''
  }

  const handleSelectEmoji = emoji => {
    const range = quill.getSelection()
    const idx = range?.index || 0

    quill.insertEmbed(idx, 'emojiEmbed', emoji.native)
    quill.insertText(idx + 1, '')
    quill.setSelection(idx + 2, Quill.sources.SILENT)
    setIsOpen(false)
  }

  const handleFormat = type => {
    const format = quill.getFormat(
      quill.selection.savedRange.index,
      quill.selection.savedRange.length
    )

    quill.format(type, !format[type])
  }

  return (
    <div
      id="chatEditorToolbar"
      className={clsx('flex items-baseline', editorStyles.toolbar)}
    >
      <div
        className={clsx(
          'w-full flex overflow-x-auto items-center dark:text-dark-light',
          editorStyles.toolbarBtns
        )}
      >
        <button title="attach file" onClick={handleTriggerUpload}>
          <PaperClipIcon />
        </button>
        <button title="add emoji" onClick={() => setIsOpen(true)}>
          <EmotionHappy className="w-5 h-5" />
        </button>
        <button title="Bold" onClick={() => handleFormat('bold')}>
          <FormatBold />
        </button>
        <button title="Italic" onClick={() => handleFormat('italic')}>
          <FormatItalic />
        </button>
        <button title="Underline" onClick={() => handleFormat('underline')}>
          <FormatUnderlined />
        </button>

        <div
          style={{
            position: 'fixed',
            transform: 'translate(-60px, -50%)',
            zIndex: 9999
          }}
          className={clsx(!isOpen && 'hidden')}
        >
          {data.emojis && (
            <Picker
              data={data}
              onEmojiSelect={handleSelectEmoji}
              onClickOutside={isOpen ? () => setIsOpen(false) : undefined}
            />
          )}
        </div>
      </div>

      <div className="ml-auto">
        <button className={editorStyles.sendMessageBtn} onClick={onSend}>
          <SendIcon className="ml-0.5 rotate-45" />
        </button>
      </div>

      <input
        ref={ref}
        onChange={handleUploadImage}
        type="file"
        accept="*"
        className="hidden"
        multiple
      />
    </div>
  )
}

const isWhitespace = ch => {
  var whiteSpace = false
  if (ch === ' ' || ch === '\t' || ch === '\n') {
    whiteSpace = true
  }
  return whiteSpace
}

export default QuillChatEditor
