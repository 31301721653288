import React from 'react'
import clsx from 'clsx'
import ContentEditable from 'react-contenteditable'
import ImageWrapper from '../ImageWrapper'

const AboutUs = ({
  data,
  classes,
  updateTemplateSection,
  updateSectionBlock,
  isEditable,
  handleScrollToTop
}) => {
  return (
    <div className={clsx(classes.sectionContainer, classes.aboutUs)}>
      <div className={classes.aboutUsImagesContainer}>
        {data.blocks.map(item => (
          <ImageWrapper
            key={item.id}
            className={classes.aboutUsImage}
            src={item.image}
            alt=""
            isEditable={isEditable}
            onImageUpdate={imgUrl =>
              updateSectionBlock({
                blockId: item.id,
                field: 'image',
                value: imgUrl
              })
            }
          />
          // <img key={item.id} src={item.image} alt="" />
        ))}
      </div>
      <div className={classes.aboutUsContent}>
        <ContentEditable
          html={data.heading}
          onChange={e =>
            updateTemplateSection({ field: 'heading', value: e.target.value })
          }
          disabled={!isEditable}
          className={classes.sectionHeading}
        />
        <ContentEditable
          html={data.description}
          onChange={e =>
            updateTemplateSection({
              field: 'description',
              value: e.target.value
            })
          }
          disabled={!isEditable}
          className={classes.aboutUsDescription}
        />
        {/* <div
          className={classes.sectionHeading}
          dangerouslySetInnerHTML={{ __html: data.heading }}
        ></div> */}
        {/* <div
          className={classes.aboutUsDescription}
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></div> */}
        <button
          onClick={handleScrollToTop}
          className={classes.getStartedButton}
        >
          Get Started
        </button>
      </div>
    </div>
  )
}

export default AboutUs
