import { makeStyles, createStyles } from '@material-ui/core'
// import { BorderBottom } from '@material-ui/icons'

const teammateStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      width: '70vw',
      maxWidth: 600,
      marginTop: theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    },
    selectField: {
      width: '100%'
    },
    textFieldStyle: {
      width: '100%'
    },
    panelFooter: {
      background: '#f3f3f3'
    },
    workspacePill: {
      width: 100,
      margin: '0 auto 4px'
    },
    activeRow: {
      '&.active': {
        backgroundColor: 'rgb(243 244 246)'
      }
    },
    avatarRoot: {
      '& > div': {
        width: '24px',
        height: '24px',
        fontSize: 10,
        textTransform: 'uppercase'
      }
    },
    teammate: {
      '&:hover .cross-btn': {
        opacity: 1
      }
    },

    penIcon: {
      cursor: 'pointer',
      width: '10px',
      height: '10px',
      opacity: 0,
      borderBottom: '1px solid gray'
    },
    editIcon: {
      display: 'flex',
      alignItems: 'baseline',
      '&:hover .pen': {
        opacity: 1
      }
    },

    removeAssigneeBtn: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      color: 'rgba(255, 0, 0, 0.75)',
      opacity: 0,
      transition: 'opacity 150ms ease-in-out'
    }
  })
)

export default teammateStyles
