import React from 'react'
import { Box, Popover } from '@material-ui/core'
import AsanaIcons from 'static/asana/AsanaIcons'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import { useDispatch, useSelector } from 'react-redux'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
import CheckIcon from '@material-ui/icons/Check'
import UserDashboardStyles from '../userDashboardStyles'

const colorOptions = [
  '#ffffff',
  '#fb5779',
  '#ff7511',
  '#ffa800',
  '#ffd100',
  '#ace60f',
  '#19db7e',
  '#00d4c8',
  '#48dafd',
  '#0064fb',
  '#6457f9',
  '#9f46e4',
  '#ff78ff',
  '#ff4ba6',
  '#ff93af',
  '#5a7896'
]

const WorkspaceIconPopover = ({
  setIconPopoverData,
  iconPopoverData,
  onClose,
  workspace
}) => {
  const dispatch = useDispatch()
  const classes = UserDashboardStyles()
  const currentWorkspace = useSelector(state =>
    state.userWorkspaces.data.find(ws => ws._id === workspace?._id)
  )
  const handleUpdateBgColor = backgroundcolor => {
    dispatch(
      updateWorkSpace1(workspace._id, {
        backgroundcolor,
        field: 'backgroundcolor'
      })
    )
  }

  const handleUpdateWSicon = icon => {
    dispatch(updateWorkSpace1(workspace._id, { image: icon, field: 'image' }))
  }

  return (
    <Popover
      open={iconPopoverData.open}
      onClose={() => {
        setIconPopoverData({ open: false, anchorEl: null })
        onClose()
      }}
      anchorEl={iconPopoverData.anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      classes={{ paper: classes.iconsPopoverPaper }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      style={{ top: '-20px' }}
    >
      <Box className={classes.colorsContainer}>
        {colorOptions.map(item => (
          <label
            onClick={() => handleUpdateBgColor(item)}
            key={item}
            style={{ backgroundColor: item }}
            className="flex justify-center items-center"
          >
            {currentWorkspace?.backgroundcolor === item && (
              <CheckIcon
                style={{
                  color: currentWorkspace.backgroundcolor.includes('#fff')
                    ? '#000'
                    : '#fff'
                }}
              />
            )}
          </label>
        ))}
      </Box>
      <div className={classes.iconScrollableWrapper}>
        <div className={classes.iconsContainer}>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Board')}
          >
            <AsanaIcons.Board />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('BriefCase')}
          >
            <AsanaIcons.BriefCase />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Bug')}
          >
            <AsanaIcons.Bug />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Calender')}
          >
            <AsanaIcons.Calender />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('ChatBubbles')}
          >
            <AsanaIcons.ChatBubbles />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Check')}
          >
            <AsanaIcons.Check />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Coins')}
          >
            <AsanaIcons.Coins />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Computer')}
          >
            <AsanaIcons.Computer />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Gear')}
          >
            <AsanaIcons.Gear />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Globe')}
          >
            <AsanaIcons.Globe />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Graph')}
          >
            <AsanaIcons.Graph />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Html')}
          >
            <AsanaIcons.Html />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('LightBulb')}
          >
            <AsanaIcons.LightBulb />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('LineAndSymbols')}
          >
            <AsanaIcons.LineAndSymbols />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('List')}
          >
            <AsanaIcons.List />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Map')}
          >
            <AsanaIcons.Map />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('MegaPhone')}
          >
            <AsanaIcons.MegaPhone />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('MountainFlag')}
          >
            <AsanaIcons.MountainFlag />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Notebook')}
          >
            <AsanaIcons.Notebook />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('PageLayout')}
          >
            <AsanaIcons.PageLayout />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('People')}
          >
            <AsanaIcons.People />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Presentation')}
          >
            <AsanaIcons.Presentation />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Puzzle')}
          >
            <AsanaIcons.Puzzle />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Ribbon')}
          >
            <AsanaIcons.Ribbon />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Rocket')}
          >
            <AsanaIcons.Rocket />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Shoe')}
          >
            <AsanaIcons.Shoe />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('ShoppingBasket')}
          >
            <AsanaIcons.ShoppingBasket />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('SpeedDial')}
          >
            <AsanaIcons.SpeedDial />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Star')}
          >
            <AsanaIcons.Star />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Target')}
          >
            <AsanaIcons.Target />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Ticket')}
          >
            <AsanaIcons.Ticket />
          </div>
          <div
            className={classes.iconWrapper}
            onClick={() => handleUpdateWSicon('Timeline')}
          >
            <AsanaIcons.Timeline />
          </div>
        </div>
      </div>
    </Popover>
  )
}

export default WorkspaceIconPopover
