import React, { useMemo } from 'react'
import stringToHslColor from 'utils/stringToHslColor'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import Tooltip from '../Tooltip/Tooltip'
import styles from './Avatar.module.css'
import { useTranslation } from 'react-i18next'

const sizeClassMap = {
  tiny: styles.avatar__tiny,
  small: styles.avatar__small,
  medium: styles.avatar__medium,
  normal: styles.avatar__normal,
  large: styles.avatar__large,
  extraLarge: styles.avatar__xl,
  full: styles.avatar__full
}

const getNameInitials = (name = '') =>
  name
    .split(' ')
    .slice(0, 2)
    .map(item => item[0])
    .join('')

const Avatar = ({
  user,
  size = 'medium',
  tooltip = true,
  className,
  ...rest
}) => {
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const image = user.profileImage
  const color = stringToHslColor(user.name ?? '')
  const nameInitials = getNameInitials(user.name ?? 'Unknown')
  const userPulses = useSelector(state => state.pulse.data)

  const userActive = useMemo(() => {
    if (user?._id) {
      const userPulse = userPulses.find(pulse => pulse._id === user._id)
      if (
        userPulse &&
        userPulse.lastActive &&
        new Date(userPulse.lastActive).getTime() >=
          new Date().getTime() - 16 * 60 * 1000
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [])

  const avatarElm = (
    <div
      style={{
        backgroundColor: color
      }}
      className={clsx(
        'flex justify-center items-center font-semibold text-white rounded-full uppercase relative flex-shrink-0',
        userActive && styles.avatar__active,
        sizeClassMap[size],
        className
      )}
      {...rest}
    >
      {image ? (
        <img
          src={image}
          alt={user.name}
          className="w-full h-full object-cover rounded-full"
        />
      ) : (
        <span>{nameInitials}</span>
      )}
    </div>
  )

  return (
    <>
      {tooltip ? (
        <Tooltip title={user._id === meData._id ? t('I_AM_ONLINE') : user.name}>
          {avatarElm}
        </Tooltip>
      ) : (
        avatarElm
      )}
    </>
  )
}

export default Avatar
