import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fireWarningToaster } from 'thunks/fireToaster/actions'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as LockIcon } from 'static/svg/lock.svg'
import ProjectCard from 'components/UserDashboard/Workspaces/ProjectsCardView/ProjectCard'
import clsx from 'clsx'
import useStyles from 'components/UserDashboard/Workspaces/ProjectsCardView/projectsCardViewStyles'
import { Skeleton } from '@material-ui/lab'
import { userRoles } from 'utils'
import { useTranslation } from 'react-i18next'
import AddProjectModal from 'components/UserDashboard/Workspaces/AddProjectModal/AddProjectModal'
import { usePermission } from 'customHooks'

const ProjectCardsPage = ({
  clientInfo,
  userWorkspaces,
  clientData,
  companyData,
  elmFor
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const [projectModalOpen, setProjectModalOpen] = useState(false)
  const { clients, workspace: workspacePermission } = usePermission()
  // const userAccountPermission = useSelector(
  //   state => state.permission.data.access
  // )
  const clientWorkspaces = useMemo(() => {
    const clientAssignedWorkspaces = clientInfo.data.assignedWorkspace?.map(
      el => el._id
    )
    return userWorkspaces.filter(project =>
      clientAssignedWorkspaces?.includes(project._id)
    )
  }, [clientInfo.data.assignedWorkspace, userWorkspaces])

  const openAddProjectModal = () => {
    if (elmFor === 'client') {
      setProjectModalOpen(true)
    } else {
      if (
        companyData.clientOwner &&
        companyData.clientOwner === clientData?._id
      ) {
        setProjectModalOpen(true)
      } else {
        dispatch(
          fireWarningToaster(
            'To create the project please assign the company representative'
          )
        )
      }
    }
  }

  return (
    <div className="mt-8">
      {!clientInfo.loading ? (
        clients.update ||
        [userRoles.AGENCY_ADMIN, userRoles.USER_AGENCY].includes(
          meData.role
        ) ? (
          <div className={clsx(classes.cardsContainer, 'mb-12')}>
            {clientWorkspaces.map(item => (
              <ProjectCard
                key={item._id}
                workspaceData={item}
                elmFor="client"
                workspacePermission={workspacePermission}
              />
            ))}
            <div
              className="bg-custom-gray-light-7 border-2 border-dashed border-gray-400 dark:border-dark-main hover:bg-gray-200 hover:shadow-md rounded-lg"
              // style={{ backgroundColor: '#f0f0f0' }}
            >
              <div
                className="flex flex-col justify-center items-center h-full w-full cursor-pointer text-gray-700 text-sm dark:bg-dark-main3"
                onClick={openAddProjectModal}
                style={{ minHeight: 170 }}
              >
                <AddIcon className="h-7 w-7 dark:text-dark-light2" />
                <span className="dark:text-dark-light2">
                  {t('NEW_PROJECT')}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white shadow rounded-xl text-center py-8">
            <span className="inline-block text-primary-main">
              <LockIcon className="w-10 h-10" />
            </span>
            <p>{t('NO_PERMISSION_TO_VIEW_PROJECTS')}</p>
          </div>
        )
      ) : (
        <div className={clsx(classes.cardsContainer, 'mb-12')}>
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
        </div>
      )}

      <AddProjectModal
        open={projectModalOpen}
        onClose={() => setProjectModalOpen(false)}
        selectedClient={clientData}
      />
    </div>
  )
}

export default ProjectCardsPage
