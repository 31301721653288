import React, { useCallback, useEffect, useState } from 'react'
import {
  AlertModal
  // CustomTooltip,
  // OutlinedButton
} from 'global/globalComponents'
import { CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTaskById } from 'thunks/task/actions'
import {
  deleteStandup,
  fetchCurrentDayTasks,
  fetchTodaysStandups,
  markStandupAsComplete
} from 'thunks/standups/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import MorningStandups from './MorningStandups'
import EveningStandups from './EveningStandups'
import TaskPanel from 'components/Workspace/Panel/TaskPanel'
import { fetchWorkspaceTagsById } from 'thunks/tags/actions'
import { useTranslation } from 'react-i18next'
import SuggestedTasks from './SuggestedTasks'

const StandupsBody = ({
  isLoading,
  selectedTab,
  allUserTasksData,
  allProjects,
  standupsDataState
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const standupsData = useSelector(state => state.standups.data)
  const [deleteStandupAlert, setDeleteStandupAlert] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [taskPanel, setTaskPanel] = useState({
    open: false,
    data: {},
    workspace: {}
  })
  const [suggestedTasks, setSuggestedTasks] = useState([])

  useEffect(() => {
    if (suggestedTasks.length && standupsDataState.length) {
      const tasks = suggestedTasks.filter(item => {
        const idx = standupsDataState.findIndex(
          standup => standup.task._id === item._id
        )
        return idx === -1
      })
      setSuggestedTasks(tasks)
    }
  }, [standupsDataState])

  useEffect(() => {
    if (taskPanel.open && !taskPanel.loading) {
      dispatch(
        fetchWorkspaceTagsById({ workspaceId: taskPanel.data.workSpace })
      )
    }
  }, [taskPanel])

  useEffect(() => {
    if (isLoading) return
    fetchCurrentDayTasks(null, (res, err) => {
      if (!err) {
        // res has 2 keys => data, metadata
        const tasks = res.data.filter(item => {
          const idx = standupsDataState.findIndex(
            standup => standup.task._id === item._id
          )
          return idx === -1
        })
        setSuggestedTasks(tasks)
      }
    })
  }, [isLoading])

  const toggleDeleteStandupAlert = data => {
    if (deleteStandupAlert.open) {
      setDeleteStandupAlert(prev => ({ ...prev, open: false }))
    } else {
      setDeleteStandupAlert({ open: true, data, loading: false })
    }
  }

  const toggleStandupCompletion = useCallback(
    standup => {
      dispatch(
        markStandupAsComplete(
          {
            id: standupsData._id,
            taskId: standup.task._id,
            data: { markAsComplete: !standup.isComplete }
          },
          (res, err) => {
            if (err) dispatch(fireErrorToaster(res))
          }
        )
      )
    },
    [selectedTab, standupsData._id]
  )

  const openEditTaskPanel = taskId => {
    const task = allUserTasksData[taskId]

    if (task) {
      const workspace = allProjects.find(item => item._id === task.workSpace)

      setTaskPanel(prev => ({
        data: task,
        open: true,
        workspace
      }))
    } else {
      setTaskPanel(prev => ({ ...prev, open: true }))
      dispatch(
        fetchTaskById({ taskId }, (res, err) => {
          if (err) dispatch(fireErrorToaster(res))
          else {
            const workspace = allProjects.find(
              item => item._id === res.workSpace
            )

            setTaskPanel(prev => ({ ...prev, data: res, workspace }))
          }
        })
      )
    }
  }

  const closeEditTaskPanel = () => {
    setTaskPanel(prev => ({
      ...prev,
      open: false
    }))

    dispatch(fetchTodaysStandups())
  }

  const handleDeleteSavedStandup = () => {
    setDeleteStandupAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteStandup(
        {
          id: standupsData._id,
          taskId: deleteStandupAlert.data.task._id
        },
        (res, err) => {
          if (err) {
            setDeleteStandupAlert(prev => ({ ...prev, loading: false }))
            dispatch(fireErrorToaster(res))
          } else {
            setDeleteStandupAlert(prev => ({
              ...prev,
              open: false,
              loading: false
            }))
            dispatch(fireSuccessToaster(t('STANDUP_REMOVED_SUCCESSFULLY')))
          }
        }
      )
    )
  }

  return (
    <>
      {isLoading ? (
        <div className="h-60">
          <div className="flex items-center">
            <CircularProgress size={20} />
            <p className="text-sm ml-2">
              Please wait while we are fetching your tasks...
            </p>
          </div>
        </div>
      ) : (
        <div style={{ height: 330 }} className="overflow-y-auto mb-4 pr-2">
          {selectedTab === 'morning' ? (
            <MorningStandups
              toggleDeleteStandupAlert={toggleDeleteStandupAlert}
              toggleStandupCompletion={toggleStandupCompletion}
              openEditTaskPanel={openEditTaskPanel}
              standupsData={standupsDataState}
            />
          ) : (
            <EveningStandups
              toggleDeleteStandupAlert={toggleDeleteStandupAlert}
              toggleStandupCompletion={toggleStandupCompletion}
              openEditTaskPanel={openEditTaskPanel}
              standupsData={standupsDataState}
            />
          )}

          {Boolean(suggestedTasks.length) && (
            <div className="my-3">
              <header className="border-l-4 border-green-500 mb-4">
                <h4 className="px-2 text-smaller bg-green-50 py-1.5 mb-4 capitalize">
                  {t('ASSIGNED_TO_YOU')}
                </h4>
              </header>

              <SuggestedTasks
                suggestedTasks={suggestedTasks}
                openEditTaskPanel={openEditTaskPanel}
                allProjects={allProjects}
                selectedTab={selectedTab}
              />
            </div>
          )}
        </div>
      )}

      <AlertModal
        open={deleteStandupAlert.open}
        warningText={t('WARNING_DO_YOU_WANT_TO_DELETE_STANDUP', {
          data: deleteStandupAlert.data.name
        })}
        handleDialog={toggleDeleteStandupAlert}
        handleDeleteAction={handleDeleteSavedStandup}
        loading={deleteStandupAlert.loading}
      />

      <TaskPanel
        taskData={taskPanel.data}
        open={taskPanel.open}
        onClose={closeEditTaskPanel}
        workspace={taskPanel.workspace}
        inDashboardArea={true}
        isOutsideBoardArea={true}
      />
    </>
  )
}

export default StandupsBody
