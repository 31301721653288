import axios from 'axios'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { GENERAL_APP_STATE } from 'thunks/generlAppState/reducers'
import { WORKSPACES } from './reducers'
import { fetchPermissions } from 'thunks/permission/actions'
import { EMBEDS } from 'thunks/embed/reducer'
import { t } from 'i18next'
import { getErrorMessages } from 'utils/errorMessages'
import { catchAsync, catchAsyncDispatch } from 'utils'

const errorMessages = getErrorMessages()

const removeInvalidTeammateData = workspaceData => {
  if (Array.isArray(workspaceData)) {
    // 1. Filtering out invalid user data
    return workspaceData.map(workspace => {
      workspace.assignedAgencyTeam = workspace.assignedAgencyTeam.filter(
        mate => typeof mate.user === 'object' && mate.user !== null
      )
      workspace.assignedClientTeam = workspace.assignedClientTeam.filter(
        mate => typeof mate.user === 'object' && mate.user !== null
      )

      return workspace
    })
  } else if (typeof workspaceData === 'object') {
    workspaceData.assignedAgencyTeam = workspaceData.assignedAgencyTeam.filter(
      mate => typeof mate.user === 'object' && mate.user !== null
    )
    workspaceData.assignedClientTeam = workspaceData.assignedClientTeam.filter(
      mate => typeof mate.user === 'object' && mate.user !== null
    )

    return workspaceData
  }
}

/**
 * fetches all workspace of a user
 * @param {Function} callback callback function
 */
export const fetchAllWorkspacesForCurrentUser = callback => {
  return catchAsyncDispatch(async dispatch => {
    dispatch({ type: WORKSPACES.LOADING })

    const res = await axios({
      method: 'GET',
      url: '/workspace/user'
    })

    const data = removeInvalidTeammateData(res.data)

    const sortedData = data.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )

    if (callback) callback(sortedData, false)
    dispatch({ type: WORKSPACES.FETCHED, payload: sortedData })
  }, callback)
}

export const fetchAllWorkspacesForCurrentProfile = catchAsync(
  async (user, callback) => {
    const res = await axios({
      method: 'GET',
      url: `workspace/user?user=${user}`
    })
    if (callback) callback(res.data)
  }
)

/**
 * Create a Workspace
 * @param {Object} data Workspace data
 * @param {Function} callback callback function
 */
export const createWorkspace = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/workspace',
      data: data
    })

    const filteredData = removeInvalidTeammateData(res.data)
    if (callback) callback(filteredData)
    dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: filteredData })
  }, callback)
}

/**
 * Create a Workspace using template
 * @param {Object} data Workspace data
 * @param {Function} callback callback function
 */
export const createWorkspaceByTemplate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/workspacetemplate/usetemplate',
      data: data
    })

    if (callback) callback(res.data)
    dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: res.data })
  }, callback)
}

/**
 * Create a Workspace
 * @param {Object} data Workspace data
 * @param {Function} callback callback function
 */
export const createWorkspaceCopy = (workspaceId, data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/workspace/copy/${workspaceId}`,
      data
    })

    const filteredData = removeInvalidTeammateData(res.data)

    // dispatch(fetchProjectPermissions())
    if (callback) callback(filteredData)
    dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: filteredData })
  }, callback)
}

/**
 * Create Project from Import
 * @param {Function} callback function.
 */
export const createProjectFromImport = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/workspace/import',
      data
    })

    if (callback) callback(res.data)
    dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: res.data })
  }, callback)
}

/**
 * Change/updae the wokrpsace external/internal type
 * @param {String} wid workspace id
 * @param {String} type Internal/External
 * @param {Function} callback callback funciton
 */
export const updateWorkspaceType = (wid, type, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspace/category/${wid}`,
      data: {
        type
      }
    })

    const filteredData = removeInvalidTeammateData(res.data)

    if (callback) callback(filteredData)
    dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: filteredData })
  }, callback)
}

/**
 * Update a workspace data
 * @param {String} id workspace id
 * @param {{}} data Workspace object
 * @param {Function} callback callback function
 */
export const updateWorkSpace1 = (id, data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspace/${id}`,
      data
    })

    const filteredData = removeInvalidTeammateData(res.data)

    if (callback) callback(filteredData)
    dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: filteredData })
  }, callback)
}

/**
 * Update role of a teammate
 * Either assignToClientTeammate or assignToAgencyTeammate at once.
 * ```data = {
 *  assignToClientTeammate:{
 *    user: teammate  user id,
 *    role: roleId
 *   }
 * }```
 * @param {String} workspaceId workspace id
 * @param {Object} data Role object
 * @param {Function} callback callback function
 */
export const updateTeammateRole = (workspaceId, data, name, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspace/updaterole/${workspaceId}`,
      data
    })

    const filteredData = removeInvalidTeammateData(res.data)

    if (callback) callback(filteredData)
    dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: filteredData })
    dispatch(fetchPermissions())
    // dispatch(fetchProjectPermissions())
  }, callback)
}

/**
 * Remove a teammate from workspace
 * @param {String} workspaceId workspace id
 * @param {Object} data removing teammated from workspace
 * @param {Function} callback callbakc funtion
 */
export const removeTeammateFromWorkspace = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspace/deleteteammate/${data.workspaceId}`,
      data: data.body
    })

    const filteredData = removeInvalidTeammateData(res.data)

    if (callback) callback(filteredData)
    dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: filteredData })
  }, callback)
}

/**
 * move workspace to trash
 * @param {String} workspaceId workspace id
 * @param {Function} callback callback function
 */

export const moveWorkspaceToTrash = (workspaceId, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { me } = getState()

    const res = await axios({
      method: 'PUT',
      url: `/workspace/${workspaceId}`,
      data: {
        isDeleted: true,
        deletedOn: new Date().toISOString(),
        deletedBy: me.data._id
      }
    })

    if (callback) callback(res.data)
    dispatch({ type: WORKSPACES.DELETE_ONE_WORKSPACE, payload: res.data })
    dispatch(
      fireSuccessToaster(t('ITEM_MOVED_TO_TRASH', { data: res.data.name }))
    )
  }, callback)
}

/**
 * restore workspace from trash
 * @param {String} workspaceId workspace id
 * @param {Function} callback callback function
 */

export const restoreDeletedWorkspace = (workspaceId, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspace/${workspaceId}`,
      data: {
        isDeleted: false,
        deletedOn: null,
        deletedBy: null
      }
    })

    const filteredData = removeInvalidTeammateData(res.data)

    dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: filteredData })
    if (callback) callback(filteredData)
  }, callback)
}

/**
 * delete a Workspace
 * @param {String} workspaceId workspace id
 * @param {Function} callback callback function
 */
export const deleteWorkspace = (workspaceId, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/workspace/${workspaceId}`
    })

    if (callback) callback(res.data)
    dispatch({ type: WORKSPACES.DELETE_ONE_WORKSPACE, payload: res.data })
    dispatch(fireSuccessToaster(t('PROJECT_DELETED_SUCCESSFULLY')))
  }, callback)
}

export const addOpenedWorkspace = workspace => {
  return { type: WORKSPACES.ADD_OPENED_WORKSPACE, payload: workspace }
}

export const addCurrentBoard = board => {
  return { type: WORKSPACES.ADD_CURRENT_BOARD, payload: board }
}

/**
 * creates new workspace section
 * @param {Object} data New section data
 * @param {String} data.label Workspace section label
 * @param {Number} data.order Workspace section label
 * @param {String} data.workSpace Workspace section label
 *
 * @param {Function} callback callback function
 */
export const createWorkspaceSection = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/workspacesection`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.CREATE_NEW_SECTION, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update workspace section data
 * @param {String} id Section id
 * @param {Object} data Updating data object
 * @param {Function} callback callback function
 */
export const updateWorkspaceSection = (id, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `workspacesection/${id}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE_SECTION,
        payload: res.data
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE_SECTION,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * @deprecated update section orders for a Workspace
 * @param {Object} data  workspaceSections,arranged workspaceSections
 * @param {Function} callback callback function
 */

// export const updateSectionOrders = (data, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'PUT',
//         url: `/workspacesection/updateorder`,
//         data
//       })
//       if (callback) callback(res.data, false)
//       dispatch({ type: WORKSPACES.UPDATE_SECTION_ORDERS, payload: data })
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//       dispatch({
//         type: WORKSPACES.UPDATE_SECTION_ORDERS,
//         payload: [{ name: 'error' }]
//       })
//     }
//   }
// }

/**
 * @deprecated update section orders for a Workspace
 * @param {Object} data  workspaceSections,arranged workspaceSections
 * @param {Function} callback callback function
 */

// export const updateSectionOrdersOld = (data, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'PUT',
//         url: `/workspacesection/updateorder`,
//         data
//       })
//       if (callback) callback(res.data, false)
//       dispatch({ type: WORKSPACES.UPDATE_SECTION_ORDERS, payload: data })
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//       dispatch({
//         type: WORKSPACES.UPDATE_SECTION_ORDERS,
//         payload: [{ name: 'error' }]
//       })
//     }
//   }
// }

/**
 * Unassign a workspace to a teammate
 * @param {String} userId user id of the teammate
 * @param {String} workspaceId workspace id
 * @param {Number} role teammate role
 * @param {Function} callback Callback function
 */
// export const unassignWorkspace = (userId, workspaceId, role = 2, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'PUT',
//         url: `/workspace/unassignworkspace/${workspaceId}`,
//         params: {
//           userId,
//           role
//         }
//       })
//       dispatch({ type: WORKSPACES.UNASSIGN_TEAMMATE, payload: res.data })
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//     }
//   }
// }

/**
 * Assign a workspace to a teammate
 * @param {Object} data user id of the teammate
 * @param {String} data.workspace workspace id
 * @param {Array<String>} data.assignToAgencyTeammate agency teammates id
 * @param {Array<String>} data.assignToClientTeammate clients id
 * @param {Function} callback Callback function
 */
export const assignWorkspace = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspace/assignedworkspace`,
      data
    })
    dispatch({ type: WORKSPACES.ASSIGN_TEAMMATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Assign user to workspace on mention or on add in task collaborators
 * @param {String} data.workspace workspace id
 * @param {Array<String>} data.assignToAgencyTeammate agency teammates id
 * @param {Array<String>} data.assignToClientTeammate clients id
 * @param {Function} callback Callback function
 */
export const assignUserToWorkspace = (data, callback) => {
  return async (dispatch, getState) => {
    const { userWorkspaces } = getState()
    const ws = userWorkspaces.data.find(item => item._id === data.workspace)

    // If user already assigned then do nothing (prevent duplicate)
    const idx = ws.assignedAgencyTeam
      .concat(ws.assignedClientTeam)
      .findIndex(
        item =>
          item.user?._id === data.assignToAgencyTeammate[0] ||
          item.user?._id === data.assignToClientTeammate[0]
      )

    if (idx !== -1) {
      if (callback) callback(ws)
      return
    }

    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/assignedworkspace`,
        data
      })
      dispatch({ type: WORKSPACES.ASSIGN_TEAMMATE, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Archive a workspace
 * @param {String} workspaceId Worskpace id
 * @param {Function} callback callback function
 */
export const archiveWorkspace = (
  workspaceId,
  archiveStatus = true,
  callback
) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `workspace/archived/${workspaceId}`,
        data: {
          isArchived: archiveStatus
        }
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: WORKSPACES.TOGGLE_ARCHIVE,
        payload: res.data
      })
    } catch (err) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * delete a section order
 * @param {Object} data task data
 * @param {Function} callback callback function
 */

export const deleteSectionOrders = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/workspacesection/${data._id}`
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: WORKSPACES.DELETE_ONE_WORKSPACE_SECTION,
        payload: res.data
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      // dispatch({
      //   type: WORKSPACES.DELETE_ONE_WORKSPACE_SECTION,
      //   payload: [{ name: 'error' }]
      // })
    }
  }
}

/**
 * update a workspace status to complete
 * @param {String} data workspace id
 * @param {Object} data is Complete
 * @param {Function} callback callback function
 */

export const UpdateWorkspceCompleteStatus = (wid, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/complete/${wid}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const updateOneWorkspaceData = data => {
  const transformedData = removeInvalidTeammateData(data)

  return dispatch => {
    // Receiving Board
    dispatch({
      type: WORKSPACES.UPDATE_ONE_WORKSPACE,
      payload: transformedData
    })
  }
}

export const updateBoardData = data => {
  return dispatch => {
    if (data.type === 'board') {
      // Here the data is board object
      dispatch({ type: EMBEDS.UPDATE, payload: data })
    } else if (data.type === 'section') {
      // Here the data is section object
      dispatch({ type: EMBEDS.SECTION_UPDATE, payload: data })
    }
  }
}

export const handleAssignTeammateEvent = data => {
  return dispatch => {
    dispatch({ type: WORKSPACES.PUSHER_ADD_ONE_WORKSPACE, payload: data })
  }
}

export const handleUnAssignPersonEvent = (data, callback) => {
  return (dispatch, getState) => {
    const {
      me: {
        data: { _id: currentUserId }
      }
    } = getState()
    const allAssignedPeopleIds = []
    data?.assignedAgencyTeam?.forEach(i =>
      allAssignedPeopleIds?.push(i.user?._id)
    )
    data?.assignedClientTeam?.forEach(i =>
      allAssignedPeopleIds?.push(i.user?._id)
    )
    if (allAssignedPeopleIds.length > 0) {
      dispatch({ type: WORKSPACES.UNASSIGN_PERSON, payload: data })
      if (!allAssignedPeopleIds.includes(currentUserId)) {
        if (callback) callback()
        dispatch({
          type: GENERAL_APP_STATE.OPEN_REMOVED_FROM_WS_MODAL,
          payload: true
        })

        dispatch({
          type: WORKSPACES.PUSHER_DELETE_ONE_WORKSPACE,
          payload: data
        })
      }
    }
  }
}

/**
 * fetch all project stats (overview data)
 * @param {String} id workspace id
 * @param {Function} callback callback function
 */
export const getProjectStats = async (workspaceId, filterData, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: filterData
        ? `/workspace/overview/${workspaceId}/?${filterData}`
        : `/workspace/overview/${workspaceId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}
/**
 * Get user's billed hours
 * @param {String} id workspace id
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} callback
 */
export const getUserBilledHours = async (
  workspaceId,
  startDate,
  endDate,
  callback
) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspace/billedhours/${workspaceId}?startDate=${startDate}&endDate=${endDate}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * fetch single project's stats (overview data)
 * @param {String} id workspace id
 * @param {String} id board id
 * @param {Function} callback callback function
 */
export const getSingleProjectStats = async (workspaceId, boardId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspace/overview/${workspaceId}?workspaceBoard=${boardId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch all project's financial data
 * @param {Function} callback
 */
export const fetchProjectFinancialData = async (wsId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspace/forecast/${wsId}`
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * fetch workspace logs
 * @param {String} id workspace id
 * @param {Number} page
 * @param {Boolean} isPublic
 * @param {Function} callback callback function
 */
export const fetchWorkspaceLogs = async ({
  workspaceId,
  page = 0,
  isPublic,
  callback
}) => {
  const url = !isPublic
    ? `/logs?type=workspace&workspace=${workspaceId}&page=${page}`
    : `/logs/public?type=workspace&workspace=${workspaceId}&page=${page}`
  try {
    const res = await axios({
      method: 'GET',
      url,
      isPublic: isPublic
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * pin and unpin project
 * @param {String} id workspace id
 * @param {String} actionType add/delete
 * @param {Function} callback callback function
 */
export const pinProject = (id, actionType, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `workspace/${id}?type=pin&method=${actionType}`
      })
      if (callback) callback(res.data)
      dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch all deleted workspace
 * @param {Function} callback callback function
 */
export const fetchDeletedWorkspace = async callback => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/workspace/trash'
    })

    // Taking only the required data
    const transformedData = res.data.map(item => ({
      _id: item._id,
      name: item.name,
      deletedOn: item.deletedOn,
      deletedBy: item.deletedBy
    }))
    if (callback) callback(transformedData)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * fetch single workspace by id
 * @param {String} data.id workspace id
 * @param {Function} callback callback function
 */
export const fetchWorkspaceById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/workspace/${data.id}`
  })

  if (callback) callback(res.data)
})

/**
 * Create Project Category
 * @param {Object} data.data title, description, image(optional), type=[ 'service' | 'project' ]
 * @param {Function} callback
 */
export const createProjectCategory = (data, callback) =>
  catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/category',
      method: 'POST',
      data: data.data
    })

    dispatch({
      type: WORKSPACES.ADD_CATEGORY,
      payload: res.data
    })

    callback(res.data)
  }, callback)

/**
 * fetch Project categories
 * @param {Object|null} data
 * @param {Function} callback
 */
export const fetchProjectCategories = () =>
  catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/category/category?type=project',
      method: 'GET'
    })

    dispatch({
      type: WORKSPACES.FETCH_CATEGORIES,
      payload: res.data
    })
  })

/**
 * Update Project category
 * @param {String} data.id category id
 * @param {Object} data.data title, description, image(optional)
 * @param {Function} callback
 */
export const updateProjectCategory = (data, callback) =>
  catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/category/${data.id}`,
      method: 'PUT',
      data: data.data
    })

    dispatch({
      type: WORKSPACES.UPDATE_CATEGORY,
      payload: res.data
    })

    callback(res.data)
  }, callback)

/**
 * Delete Project category
 * @param {String} data.id category id
 * @param {Function} callback
 */
export const deleteProjectCategory = (data, callback) =>
  catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/category/${data.id}`,
      method: 'DELETE'
    })
    dispatch({
      type: WORKSPACES.DELETE_CATEGORY,
      payload: res.data
    })
    callback(res.data)
  }, callback)

/**
 * update various workspaces together
 * @param {*} data { workspaces: array, data: any}
 * @param {Function} callback
 * @returns
 */
export const updatePropertyinWorkspaces = (data, callback) =>
  catchAsyncDispatch(async dispatch => {
    const { workspaces, data: bodyData } = data

    const modified = workspaces.map(async wid => {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/${wid}`,
        data: bodyData
      })

      let filteredData

      if (res.status === 200) {
        filteredData = removeInvalidTeammateData(res.data)
        dispatch({
          type: WORKSPACES.UPDATE_ONE_WORKSPACE,
          payload: filteredData
        })
      }
      return filteredData || res.data
    })
    Promise.all(modified).then(modified => {
      callback(modified)
    })
  }, callback)

// /**
//  * Fetch all workspace permissions
//  * @param {Function} callback callback function
//  */
// export const fetchProjectPermissions = callback => {
//   return catchAsyncDispatch(async dispatch => {
//     const res = await axios({
//       method: 'GET',
//       url: '/workspace/role/user'
//     })

//     dispatch({ type: WORKSPACES.FETCHED_PERMISSIONS, payload: res.data })
//     if (callback) callback(res.data)
//   }, callback)
// }
