import React, { useEffect, useMemo, useState } from 'react'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Pill from 'global/globalComponents/Pill/Pill'
import { fetchAllSubscriptions } from 'thunks/subscription/actions'
import { ColoredAvatars, Pagination } from 'global/globalComponents'
import SubscriptionDetailsPanel from './SubscriptionDetailsPanel'

const LIMIT = 8
const SubscriptionsTable = ({ selectedStatus }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [subscriptionPanel, setSubscriptionPanel] = useState({
    open: false,
    data: {}
  })
  const [page, setPage] = useState(0)
  const subscriptions = useSelector(state => state.subscriptions)

  const currentPageSubscriptions = useMemo(() => {
    const filteredSubscriptions =
      selectedStatus === 'all'
        ? [...subscriptions.data]
        : subscriptions.data.filter(item => item.status === selectedStatus)

    return filteredSubscriptions.splice(page * LIMIT, LIMIT)
  }, [subscriptions.data, page, selectedStatus])

  useEffect(() => {
    setPage(0)
  }, [selectedStatus])

  useEffect(() => {
    dispatch(fetchAllSubscriptions())
  }, [])

  return (
    <>
      <TableContainer mb={6}>
        <Thead>
          <Th>{t('CLIENT')}</Th>
          <Th>{t('SERVICE')}</Th>
          <Th>{t('STATUS')}</Th>
          <Th>{t('SUBSCRIBED_ON')}</Th>
          {/* <Th></Th> */}
        </Thead>
        <Tbody>
          {subscriptions.loading ? (
            <TableDataLoading cols={4} />
          ) : currentPageSubscriptions.length > 0 ? (
            currentPageSubscriptions.map(item => (
              <Row
                key={item._id}
                t={t}
                {...item}
                viewSubscription={() =>
                  setSubscriptionPanel({ open: true, data: item })
                }
              />
            ))
          ) : (
            <Tr>
              <Td colSpan="6">{t('NO_DATA_AVAILABLE')}</Td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={subscriptions.data.length}
        page={page}
        limit={LIMIT}
      />
      <SubscriptionDetailsPanel
        open={subscriptionPanel.open}
        onClose={() => setSubscriptionPanel(prev => ({ ...prev, open: false }))}
        subscriptionData={subscriptionPanel.data}
      />
    </>
  )
}

const Row = ({ t, client, status, services, createdAt, viewSubscription }) => {
  return (
    <Tr>
      <Td className="cursor-pointer" onClick={viewSubscription}>
        <div className="flex items-center space-x-2 cursor-pointer">
          <ColoredAvatars
            user={client || { name: t('DELETED_CLIENT') }}
            size="medium"
          />
          <div className="flex flex-col">
            <span className=" text-primary-mid-dark font-medium truncate dark:text-dark-light2">
              {client?.name || t('DELETED_CLIENT')}
            </span>
            <span className="text-primary-mid-dark-2 text-xs dark:text-dark-light">
              {client?.email}
            </span>
          </div>
        </div>
      </Td>
      <Td className="space-y-1">
        {services.map(service => (
          <div>
            <Pill variant="purple" key={service._id}>
              {service.name}
            </Pill>
          </div>
        ))}
      </Td>
      <Td>
        <Pill
          variant={
            status === 'active'
              ? 'green'
              : status === 'paused'
              ? 'orange'
              : 'red'
          }
          className="capitalize"
        >
          {status}
        </Pill>
      </Td>
      <Td>
        <time className="text-smaller">
          {new Date(createdAt).toLocaleDateString(undefined, {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}
        </time>
      </Td>
    </Tr>
  )
}

export default SubscriptionsTable
