import { useEffect, useState } from 'react'
import axios from 'axios'
import clsx from 'clsx'
import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core'
// import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import { useDispatch } from 'react-redux'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { ClientVenueLogo } from 'static/Images/index'
// import TextInput from 'global/globalComponents/TextInput/TextInput'
import Testimonial from 'global/globalComponents/Testimonial/Testimonial'
import { userRoles, validator } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
import { userSignup } from 'thunks/signup/actions'
import { appSumoCouponValidation } from 'thunks/couponValidation/couponValidation'
import { AppSumoLogo } from 'static/Images'
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone'
import { useTranslation } from 'react-i18next'
import { createFreePlan } from 'thunks/agencyPayment/actions'

const AppSumoSignup = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [domain, setDomain] = useState('')
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(true)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [codeStatus, setCodeStatus] = useState({
    message: undefined,
    loading: true,
    available: undefined
  })
  const [userDomainStatus, setUserDomainStatus] = useState({
    message: undefined,
    loading: true,
    available: undefined
  })

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    brandName: '',
    website: '',
    role: userRoles.USER_AGENCY,
    type: 'normal'
  })
  const [coupons, setCoupons] = useState([{ value: '', error: '' }])

  useEffect(() => {
    const hasCoupon = coupons.find(item => Boolean(item.value))

    if (userDomainStatus) {
      if (
        formData.name === '' ||
        formData.email === '' ||
        formData.password === '' ||
        formData.brandName === '' ||
        domain === '' ||
        !hasCoupon
      ) {
        setDisable(true)
      } else {
        setDisable(false)
      }
    } else {
      setDisable(true)
    }
  }, [formData, domain, codeStatus, userDomainStatus, confirmPassword, coupons])

  const handleDomain = e => {
    const value = e.target.value.trim().toLowerCase()
    if (/^([\w\d]-?)*$/.test(value)) {
      setDomain(value)
    }
  }

  const handleCode = e => {
    const index = e.target.dataset.index
    const couponsClone = [...coupons]

    couponsClone[index] = { value: e.target.value }
    setCoupons(couponsClone)
  }

  const handleSubmitDomain = async () => {
    setFormData(prev => ({ ...prev, brandName: '' }))
    if (domain.length > 0) {
      setUserDomainStatus(props => ({
        ...props,
        loading: true,
        message: 'Checking',
        available: undefined
      }))
      try {
        const res = await axios({
          method: 'GET',
          url: `/user/checkagencydomain?domain=${domain}.clientvenue.com`,
          isPublic: true
        })
        if (res.data.domainExist) {
          setUserDomainStatus({
            loading: false,
            message: 'Not Available',
            available: false
          })
          dispatch(
            fireErrorToaster(t('ERROR_DOMAIN_ALREADY_EXIST', { data: domain }))
          )
        } else {
          setFormData(prev => ({ ...prev, brandName: domain }))
          setUserDomainStatus({
            loading: false,
            message: 'Available',
            available: true
          })
        }
      } catch (err) {
        setUserDomainStatus({
          loading: false,
          message: 'Not Available',
          available: false
        })
      }
    } else {
      setUserDomainStatus(props => ({
        ...props,
        message: undefined,
        available: undefined
      }))
    }
  }

  const couponValidationCallback = (res, err) => {
    if (!err) {
      userSignup(
        {
          data: {
            ...formData,
            source: window.location.href,
            codeStack: coupons
              .filter(item => Boolean(item.value.trim()))
              .map(item => item.value),
            isAppsumoUser: true,
            agencyDomain: domain + '.clientvenue.com'
          }
        },
        signupCallback
      )
    } else {
      setLoading(false)
      const updatedList = [...coupons].map(item => {
        const errObj = res.coupen.find(item2 => item2.coupen === item.value)

        if (errObj) {
          return { value: item.value, error: errObj.message }
        }
        return item
      })
      setCoupons(updatedList)
    }
  }

  const formInputHandler = e => {
    const { name, value } = e.target
    setFormData(oldState => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validEmailAddress = () => {
    if (!validator.isEmailValid(formData.email)) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_EMAIL_ADDRESS')))
      return false
    } else {
      return true
    }
  }

  // const validPasswordHandler = () => {
  //   const password = formData.password
  //   if (
  //     password.match(/[a-z]/g) &&
  //     password.match(/[A-Z]/g) &&
  //     password.match(/[0-9]/g) &&
  //     password.match(/[^a-zA-Z\d]/g) &&
  //     password.trim().length >= 8 &&
  //     password.trim().length <= 20
  //   ) {
  //     setPasswordValid(true)
  //     return true
  //   } else {
  //     setPasswordValid(false)
  //     return false
  //   }
  // }

  // const phoneNumberIputHandler = (valid, phone, country) => {
  //   setFormData((oldData) => ({
  //     ...oldData,
  //     phoneNumber: phone.length > 0 ? '+' + country.dialCode + ' ' + phone : ''
  //   }))
  // }

  const userSignupHandler = e => {
    e.preventDefault()

    if (!userDomainStatus.available) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_DOMAIN')))
    }

    if (!validator.isEmailValid(formData.email)) {
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_EMAIL_ADDRESS'))
      )
    }

    if (!validator.isPasswordValid(formData.password)) {
      setPasswordValid(false)
      return
    }

    if (formData.password !== confirmPassword) {
      setLoading(false)
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_EMAIL_ADDRESS'))
      )
    }

    setLoading(true)
    setCodeStatus({
      loading: true,
      message: 'Checking',
      available: false
    })

    appSumoCouponValidation(
      {
        codeStack: coupons
          .filter(item => Boolean(item.value.trim()))
          .map(item => item.value)
      },
      couponValidationCallback
    )
  }

  const signupCallback = async (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      try {
        getCouponPlan((planRes, err) => {
          if (err) {
            dispatch(fireErrorToaster(t('ERROR_UNSUCCESSFUL_COUPON_REQUEST')))
            return
          } else {
            if (planRes.status === 200) {
              const queryParams = `?signup=true&access_token=${res.login?.token?.accessToken}&refresh_token=${res.login?.token?.refreshToken}`

              createFreePlan(
                {
                  body: {
                    email: formData.email,
                    plan: planRes.data
                  }
                },
                (res, err) => {
                  window.open(
                    `https://${res.agencyDomain}${
                      planRes.path || res.path
                    }${queryParams}`,
                    '_self'
                  )
                }
              )
              dispatch(fireSuccessToaster(t('SIGNUP_SUCCESSFUL')))
            } else {
              dispatch(fireErrorToaster(t('ERROR_UNSUCCESSFUL_CODE_REQUEST')))
            }
          }
        })
      } catch (err) {
        dispatch(fireErrorToaster(t('UNSUCCESSFUL')))
        return
      }
    }
  }

  const handleStackCoupon = () => {
    setCoupons(prev => [...prev, { value: '', error: '' }])
  }

  return (
    <div className="w-full h-screen grid grid-cols-5">
      <div className="col-span-5 md:col-span-3 p-4 md:p-12">
        <header className="flex flex-col items-center mb-8">
          <div className="text-center h-12 flex items-center gap-4">
            <img
              src={ClientVenueLogo}
              alt="logo"
              target="_self"
              className="h-full"
            />
            <FavoriteTwoToneIcon fontSize="large" className="text-red-400" />
            <img src={AppSumoLogo} style={{ width: 160 }} alt="appsumo logo" />
          </div>
          <p className="font-medium flex items-center mx-auto dark:text-dark-light">
            Redeem Your AppSumo Code
          </p>
        </header>

        <form
          onSubmit={userSignupHandler}
          className="mt-4 py-4 w-full md:w-3/4 mx-auto space-y-4 bg-white dark:bg-dark-main shadow rounded p-8"
        >
          <div>
            <FormLabel required htmlFor="name">
              Name
            </FormLabel>
            <TextField
              onChange={formInputHandler}
              placeholder="Name"
              required
              id="name"
              name="name"
              value={formData.name}
              className="w-full"
            />
          </div>
          <div>
            <FormLabel required htmlFor="email">
              Email
            </FormLabel>
            <TextField
              onChange={formInputHandler}
              required
              value={formData.email}
              placeholder="Email"
              id="email"
              className="w-full"
              name="email"
              onBlur={validEmailAddress}
            />
          </div>
          <div>
            <FormLabel required htmlFor="agencyDomain">
              Agency Domain
            </FormLabel>
            <div className="flex h-8" style={{ margin: '0 0 1.425rem' }}>
              <div className="flex flex-row p-0 m-0 rounded-sm items-center w-full">
                <TextField
                  required
                  value={domain}
                  inputProps={{ onBlur: handleSubmitDomain }}
                  placeholder="Agency Domain"
                  onChange={handleDomain}
                  className="w-full"
                />
                <Typography
                  style={{
                    alignSelf: 'center',
                    fontSize: '12px',
                    color: '#778ca2'
                  }}
                >
                  .clientvenue.com
                </Typography>
              </div>
              {userDomainStatus.message && (
                <div className="flex items-center gap-1 ml-4">
                  {userDomainStatus.loading && (
                    <CircularProgress value={0} size={15} />
                  )}
                  <p
                    className={clsx('font-medium text-xs', {
                      'text-red-500': !userDomainStatus.available,
                      'text-green-500': userDomainStatus.available,
                      'text-blue-600': userDomainStatus.loading
                    })}
                  >
                    {userDomainStatus.message}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div>
            <FormLabel required htmlFor="company">
              Company Name
            </FormLabel>
            <TextField
              required
              placeholder="Company name"
              className="w-full"
              name="brandName"
              id="company"
              value={formData.brandName}
              onChange={formInputHandler}
            />
          </div>

          <div>
            <FormLabel required>{t('PASSWORD')}</FormLabel>
            <TextField
              required
              // error={formData.password && !passwordValid ? true : false}
              placeholder={t('PASSWORD')}
              name="password"
              className="w-full"
              value={formData.password}
              type="password"
              onChange={formInputHandler}
              variant="outlined"
            />
            {formData.password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                Password should be a combination of an uppercase/lowercase
                letter, a number, a symbol and length between 8 to 20.
              </p>
            )}
          </div>

          <div>
            <FormLabel required>Confirm Password</FormLabel>
            <TextField
              required
              placeholder="Confirm Password"
              name="confirm password"
              className="w-full"
              value={confirmPassword}
              type="password"
              onChange={e => setConfirmPassword(e.target.value)}
              // onBlur={confirmPasswordHandler}
            />
          </div>

          <div>
            <FormLabel required htmlFor="agencyDomain">
              AppSumo Code
            </FormLabel>
            <div>
              <div>
                {coupons.map((item, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <TextField
                      inputProps={{ 'data-index': index }}
                      classes={{ root: 'mb-4 w-full block' }}
                      className="mb-4"
                      style={{ marginBottom: '8px' }}
                      required
                      value={item.value}
                      placeholder="Enter AppSumo code"
                      onChange={handleCode}
                    />
                    {item.error && (
                      <p className="text-xs whitespace-nowrap text-red-400">
                        {item.error}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="text-right">
              {coupons.length < 8 && (
                <Button size="small" onClick={handleStackCoupon}>
                  Stack
                </Button>
              )}
            </div>
          </div>

          <div>
            <LoadingButton
              size="large"
              onClick={userSignupHandler}
              style={{ width: '100%' }}
              loading={loading}
              disabled={disable}
            >
              Sign Up
            </LoadingButton>
          </div>
        </form>
      </div>

      <div className="col-span-0 flex flex-col justify-center md:col-span-2 bg-primary-light dark:bg-dark-main2">
        <div className="fixed hidden px-12 md:block">
          <Testimonial />
        </div>
      </div>
      {/* <GeneralFormModal
        open={open}
        handleClose={modalCloseHandler}
        data={response}
      /> */}
    </div>
  )
}

const getCouponPlan = async callback => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/coupen/user`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    callback(res)
  } catch (err) {
    callback(err, true)
  }
}

export default AppSumoSignup
