import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank
} from '@material-ui/icons'

const CheckBoxField = ({
  name,
  onChange,
  checked,
  label,
  disabled = false
}) => {
  return (
    <FormControlLabel
      className="flex items-center"
      name={name}
      control={
        <Checkbox
          onChange={onChange}
          checked={checked}
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={
            <CheckBoxIcon fontSize="small" className="dark:text-dark-light" />
          }
          disabled={disabled}
        />
      }
      label={
        <span className="text-xs font-medium text-gray-700 truncate dark:text-dark-light">
          {label}
        </span>
      }
    />
  )
}

export default CheckBoxField
