import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../components/Login/Login'
import Domain from '../components/Login/Domain'
import ClientSignup from '../components/ClientSignup/ClientSignup'
import { ForgotDomain, ForgotPassword, ResetPassword } from 'components'
// import CheckoutServices from 'components/ClientComponent/ClientCheckout/CheckoutServices'
// import FreeAgencySignup from 'components/Signup/FreeAgencySignup'
// import PitchgroundSignup from 'components/Signup/PitchgroundSignup'
import PageNotFound from 'components/Opening/PageNotFound'
// import OrderCheckout from 'components/ClientComponent/ClientCheckout/OrderCheckout'
// import ClientDirectSignup from 'components/ClientComponent/Signup/ClientDirectSignup'
import PublicProposal from 'components/Proposals/PublicProposal/PublicProposal'
import AppSumoSignup from 'components/Signup/AppSumoSignup'
import { useSelector } from 'react-redux'
import PaymentStatus from 'components/PaymentStatus/PaymentStatus'

const ProjectInvoiceCheckoutPage = React.lazy(() =>
  import('components/Workspace/ProjectInvoices/CheckoutPage/CheckoutPage')
)
const PublicShareBoardRoutes = React.lazy(() =>
  import('components/PublicSharedBoard/PublicShareBoardRoutes')
)
// const PublicShareTask = React.lazy(() => import('components/PublicShareTask'))
const TeammateSignup = React.lazy(() =>
  import('components/Signup/TeammateSignup/TeammateSignup')
)

const PublicAgencyProposal = React.lazy(() =>
  import('components/Proposals/PublicAgencyProposal/PublicAgencyProposal')
)

const PublicWorkflow = React.lazy(() =>
  import('components/Workflow/PublicWorkflow/PublicWorkflow')
)

const ServiceMarketPlace = React.lazy(() =>
  import('components/ServiceMarketPlace/ServiceMarketPlace')
)

const AgencyServicesPublic = React.lazy(() =>
  import('components/_agency/AgencyServicesPublic/AgencyServicesPublic')
)

const AgencySignup = React.lazy(() => import('components/Signup/AgencySignup'))

const PublicRoutes = () => {
  const { isMarketplaceDomain } = useSelector(state => state.generalAppState)

  return (
    <Switch>
      <Route path="/login/domain" component={Domain} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={AgencySignup} />
      {process.env.REACT_APP_ENVIRONMENT === 'development' && (
        <Route path="/agency-signup" component={AgencySignup} />
      )}
      {/* NOTE:- DO NOT remove the following commented routes, we may need these in future */}
      {/* <Route path="/free-trial" component={FreeAgencySignup} />
      <Route path="/pitchground-sale" component={PitchgroundSignup} /> */}
      <Route path="/appsumo" component={AppSumoSignup} />
      <Route path="/clientsignup" component={ClientSignup} />
      {/* <Route path="/client-signup" component={ClientDirectSignup} /> */}
      <Route path="/teammate-signup/:agencyId" component={TeammateSignup} />
      {/* <Route path="/services/:planId/checkout" component={OrderCheckout} /> */}
      <Route
        path="/services"
        component={
          isMarketplaceDomain ? ServiceMarketPlace : AgencyServicesPublic
        }
      />
      <Route path="/service-marketplace" component={ServiceMarketPlace} />
      <Route
        path="/agencyuser/agency/acceptteaminvitation/:inviteToken"
        component={AgencySignup}
      />
      <Route path="/inviteteammate/:inviteToken" component={AgencySignup} />
      <Route path="/forgot-domain" component={ForgotDomain} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/resetpassword/:token" component={ResetPassword} />
      <Route path="/public/proposals/:id" component={PublicProposal} />
      <Route
        path="/public/agency/proposals/:id"
        component={PublicAgencyProposal}
      />
      <Route path="/public/workflow" component={PublicWorkflow} />
      {/* <Route path="/public/share/task/:taskId" component={PublicShareTask} /> */}
      <Route
        path={['/public/share/board/:workspaceId', '/public/:customURL']}
        component={PublicShareBoardRoutes}
      />
      <Route
        path="/i/:invoiceId/checkout"
        component={ProjectInvoiceCheckoutPage}
      />

      <Route path="/payment-status" component={PaymentStatus} />

      <Route exact path="/:anyRoute+">
        <PageNotFound />
      </Route>
    </Switch>
  )
}

export default PublicRoutes
