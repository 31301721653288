export const CLIENTCOMPANY = {
  LOADING: 'LOADING_CLIENTCOMPANY',
  ERROR: 'CLIENTCOMPANY_ERROR',
  FETCHED: 'CLIENTCOMPANY_DATA_FETCHED',
  ADD_ONE_CLIENTCOMPANY: 'ADD_ONE_CLIENTCOMPANY',
  DELETE_ONE_CLIENTCOMPANY: 'DELETE_ONE_CLIENTCOMPANY',
  UPDATE_ONE_CLIENTCOMPANY: 'UPDATE_ONE_CLIENTCOMPANY'
}

const initialState = {
  error: false,
  loading: false,
  data: [],
  fetched: false
}

const clientCompanyReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case CLIENTCOMPANY.LOADING:
      return {
        ...state,
        loading: true,
        error: false
      }

    case CLIENTCOMPANY.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLIENTCOMPANY.ADD_ONE_CLIENTCOMPANY:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data]
      }

    case CLIENTCOMPANY.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case CLIENTCOMPANY.DELETE_ONE_CLIENTCOMPANY:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }

    case CLIENTCOMPANY.UPDATE_ONE_CLIENTCOMPANY:
      // const updatedData1 = [...state.data].unshift(action.payload)
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        data: updatedData1
      }

    default:
      return state
  }
}

export default clientCompanyReducer
