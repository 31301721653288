import React, { useState, useEffect } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTaskLogs } from 'thunks/task/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import TaskLogs from './TaskLogs'
import { useTranslation } from 'react-i18next'
import Comments from './Comments/Comments'

const LogsSection = ({
  task,
  elmFor,
  selectedTab,
  setSelectedTab,
  workspace
}) => {
  const { t } = useTranslation()
  const themeForMode = useSelector(state => state.generalAppState.theme)
  const logsData = useSelector(state => state.logs)
  // const [selectedTab, setSelectedTab] = useState(0)
  const [comments, setComments] = useState({
    loading: true,
    data: []
  })
  const [logs, setLogs] = useState({
    loading: true,
    data: []
  })
  const [page, setPage] = useState(0)
  const dispatch = useDispatch()
  const [fetchingMore, setFetchingMore] = useState(false)
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false)

  useEffect(() => {
    if (
      (elmFor === 'task' && logsData.loading.task) ||
      (elmFor === 'subtask' && logsData.loading.subtask)
    )
      return

    const arr = []
    const data = elmFor === 'task' ? logsData.data : logsData.subtaskLogs

    for (let item of data) {
      if (item.activityType === 'comment') {
        arr.push(item)
      }
    }

    setComments({ loading: false, data: arr })
  }, [logsData, elmFor])

  useEffect(() => {
    if (selectedTab === 1 && logs.data.length === 0) {
      fetchTaskLogs(
        {
          type: elmFor === 'task' ? 'task' : 'subTask',
          page,
          taskId: task._id
        },
        (res, err) => {
          if (err) dispatch(fireErrorToaster(res))
          else {
            setLogs({ loading: false, data: res })
            if (res.length > 20) setShowLoadMoreBtn(true)
          }
        }
      )
    }
  }, [selectedTab, elmFor])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const handleLoadMoreLogs = () => {
    setFetchingMore(true)
    fetchTaskLogs(
      {
        type: elmFor === 'task' ? 'task' : 'subTask',
        page: page + 1,
        taskId: task._id
      },
      (res, err) => {
        setFetchingMore(false)
        if (err) dispatch(fireErrorToaster(res))
        else {
          if (res.length) {
            setLogs(prev => ({ ...prev, data: [...prev.data, ...res] }))
          } else {
            setShowLoadMoreBtn(false)
          }
        }
      }
    )
    setPage(prev => prev + 1)
  }

  return (
    <div>
      <header className="px-4">
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {[t('COMMENTS'), t('HISTORY')].map((item, i) => (
            <Tab
              style={{
                textTransform: 'capitalize'
              }}
              label={item}
              key={i}
              value={i}
            />
          ))}
        </Tabs>
        {/* <hr /> */}
      </header>

      <div className="py-8 bg-gray-100 dark:bg-dark-main">
        {selectedTab === 0 ? (
          <Comments
            comments={comments.data}
            elmFor={elmFor}
            isLoading={comments.loading}
            collaborators={
              workspace._id
                ? [
                    ...workspace.assignedAgencyTeam,
                    ...workspace.assignedClientTeam
                  ]
                : []
            }
          />
        ) : (
          <TaskLogs
            task={task}
            elmFor={elmFor}
            logs={logs}
            showLoadMoreBtn={showLoadMoreBtn}
            handleLoadMoreLogs={handleLoadMoreLogs}
            fetchingMore={fetchingMore}
          />
        )}
      </div>
    </div>
  )
}

export default LogsSection
