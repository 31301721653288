import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const TaskTitle = ({ innerProjectPermission, taskTitle, updateTitle }) => {
  const [title, setTitle] = useState('')
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )

  useEffect(() => {
    if (taskTitle) {
      setTitle(taskTitle)
    }
  }, [taskTitle])

  const handleTitleChange = e => {
    setTitle(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    e.currentTarget.title.blur()
  }

  const handleBlur = () => {
    if (title === '') {
      setTitle(taskTitle)
    } else if (title !== taskTitle) {
      updateTitle(title)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="title"
        // id="Task_name"
        onChange={handleTitleChange}
        disabled={
          !innerProjectPermission.update || currentWorkspace?.isComplete
        }
        value={title}
        // onBlur={() => handletitleBlur({ title: taskState.title })}
        onBlur={handleBlur}
        className="py-2 px-3 text-xl 2xl:text-2xl font-semibold block w-full rounded-md dark:bg-dark-main3 dark:text-dark-light2"
      />
    </form>
  )
}

export default React.memo(TaskTitle)
