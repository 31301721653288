import { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { ToggleSwitch } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
import ShareBoardCopyLink from './ShareBoardCopyLink'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { ReactComponent as Icon } from 'static/svg/i.svg'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import ShareBoardAdvanceOptions from './ShareBoardAdvanceOptions'
import {
  createProjectTabView,
  fetchProjectTabView,
  updateProjectTabView
} from 'thunks/views/actions'
import { fetchCustomURLData } from 'thunks/customUrl/action'
import { useTranslation } from 'react-i18next'

const ShareBoardModal = props => {
  const { t } = useTranslation()
  const {
    open,
    onClose,
    boardSections,
    sharing,
    workspace,
    innerProjectPermission
  } = props
  const boardSectionsIds = boardSections.map(item => item._id)
  const [selectedSections, setSelectedSections] = useState(boardSectionsIds)
  // const [shareProject, setShareProject] = useState(false)

  const dispatch = useDispatch()
  const [passcode, setPasscode] = useState({ loading: false, value: '' })
  const [customizeLink, setCustomizeLink] = useState({})
  // const [loading, setLoading] = useState(false)
  const [loadingToggleBtn, setLoadingToggleBtn] = useState(false)
  const [visibleTabs, setVisibleTabs] = useState()
  const [hiddenTab, setHiddenTab] = useState([])
  const [projectTabViews, setProjectTabViews] = useState({})
  const [url, setURL] = useState(
    `${window.location.origin}/public/share/board/${workspace._id}`
  )
  useEffect(() => {
    setVisibleTabs([
      { label: t('OVERVIEW'), accessor: 'overview', disable: false },
      { label: t('BOARD'), accessor: 'board', disable: false },
      { label: t('LIST'), accessor: 'list', disable: false },
      { label: t('CALENDAR'), accessor: 'calendar', disable: false },
      { label: t('REPORTING'), accessor: 'reporting', disable: false },
      // { label: 'Files', accessor: 'files', disable: false },
      {
        label: t('INVOICE'),
        accessor: 'invoice',
        disable: false
      },
      { label: t('EMBED_VIEWS'), accessor: 'embeds', disable: false }
    ])
  }, [t])
  useEffect(() => {
    fetchCustomURLData(
      { originalUrl: `/public/share/board/${workspace._id}` },
      (res, err) => {
        if (!err) {
          setCustomizeLink(res)
        }
      }
    )
  }, [workspace._id])

  useEffect(() => {
    if (customizeLink._id) {
      setURL(`${window.location.origin}/public/${customizeLink.customUrl}`)
    } else {
      setURL(`${window.location.origin}/public/share/board/${workspace._id}`)
    }
  }, [customizeLink])

  useEffect(() => {
    dispatch(
      fetchProjectTabView(
        workspace._id,
        'shareBoardTab',
        'share',
        (res, err) => {
          if (!err) {
            setProjectTabViews(res)
          } else {
            if (typeof res === 'object') {
              dispatch(
                createProjectTabView(
                  {
                    type: 'shareBoardTab',
                    workspace: workspace._id,
                    content: [{ hiddenColumns: [] }]
                  },
                  'share'
                )
              )
            }
          }
        }
      )
    )
  }, [])

  useEffect(() => {
    if (workspace?.type === 'Internal') {
      setVisibleTabs(prev => prev.filter(el => el.accessor !== 'invoice'))
    }
  }, [workspace?.type])

  useEffect(() => {
    if (projectTabViews._id) {
      setHiddenTab(projectTabViews.content[0].hiddenColumns)
    } else {
      setHiddenTab([])
    }
  }, [projectTabViews])

  useEffect(() => {
    // setShareProject(sharing.public)
    setPasscode({ loading: false, value: sharing.passcode })
  }, [sharing])

  const switchBoardViewState = e => {
    if (!innerProjectPermission.update) {
      dispatch(
        fireErrorToaster(t('NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return
    }

    setLoadingToggleBtn(true)
    const checked = e.target.checked

    dispatch(
      updateWorkSpace1(
        workspace._id,
        {
          sharing: {
            public: checked,
            field: 'public',
            passcodeProtected: Boolean(passcode.value) && checked,
            passcode:
              Boolean(passcode.value) && checked ? passcode.value : null,
            publicData: {
              subtask: true,
              workspaceSection: selectedSections,
              publicFields: [
                'title',
                'description',
                'attachments',
                'dueDate',
                'startDate',
                'priority',
                'status',
                'assignedAgencyTeam',
                'assignedClientTeam'
              ]
            }
          }
        },
        (res, err) => {
          setLoadingToggleBtn(false)
          if (err) {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleSaveChanges = e => {
    e?.preventDefault()
    if (sharing.passcode === passcode.value) {
      return
    }
    setPasscode(prev => ({ ...prev, loading: true }))
    dispatch(
      updateWorkSpace1(
        workspace._id,
        {
          sharing: {
            public: sharing.public,
            field: 'public',
            passcodeProtected: Boolean(passcode.value) && sharing.public,
            passcode:
              Boolean(passcode.value) && sharing.public ? passcode.value : null,
            publicData: {
              subtask: true,
              workspaceSection: selectedSections,
              publicFields: [
                'title',
                'description',
                'attachments',
                'dueDate',
                'startDate',
                'priority',
                'status',
                'assignedAgencyTeam',
                'assignedClientTeam'
              ]
            }
          }
        },
        (res, err) => {
          setPasscode(prev => ({ ...prev, loading: false }))
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            dispatch(fireSuccessToaster(t('PASSCODE_SAVED_SUCCESSFULLY')))
          }
        }
      )
    )
  }

  const handleTabSelection = (accessor, checked) => {
    let updatedHiddenTab = []
    if (checked) {
      // updatedHiddenTab = hiddenTab.filter(el => el !== accessor)
      setHiddenTab(prev => {
        updatedHiddenTab = prev.filter(el => el !== accessor)
        return updatedHiddenTab
      })
    } else {
      // updatedHiddenTab = [...hiddenTab, accessor]
      setHiddenTab(prev => {
        updatedHiddenTab = [...prev, accessor]
        return updatedHiddenTab
      })
    }
    handleSaveView(updatedHiddenTab)
  }

  const handleSaveView = updatedHiddenTab => {
    const hiddenTabData = updatedHiddenTab
    // setLoading(true)
    if (projectTabViews._id) {
      dispatch(
        updateProjectTabView(
          projectTabViews._id,
          {
            content: [{ hiddenColumns: hiddenTabData }]
          },
          'share',
          saveViewCallback
        )
      )
    } else {
      dispatch(
        createProjectTabView(
          {
            type: 'shareBoardTab',
            workspace: workspace._id,
            content: [{ hiddenColumns: hiddenTabData }]
          },
          'share',
          saveViewCallback
        )
      )
    }
  }

  const saveViewCallback = (res, err) => {
    // setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setProjectTabViews(res)
      dispatch(fireSuccessToaster(t('SAVED_SUCCESSFULLY')))
    }
  }

  return (
    <CustomModal onClose={onClose} open={open} size="small">
      <CustomModalHeader heading={t('SHARE_BOARD')} handleClose={onClose} />
      <CustomModalBody>
        <div>
          <p className="text-smaller 2xl:text-sm dark:text-dark-light">
            {t('SHARE_BOARD_MSG')}
          </p>
          <div className="mt-4 flex justify-between items-center border rounded-md py-2 px-4">
            <p className="flex-1 text-sm 2xl:text-base dark:text-dark-light">
              {t('PUBLIC_SHARING')}
            </p>
            <ToggleSwitch
              checked={sharing.public}
              onChange={switchBoardViewState}
              loading={loadingToggleBtn}
            />
          </div>
          {sharing.public && (
            <>
              <ShareBoardCopyLink workspaceId={workspace._id} url={url} />
              <ShareBoardAdvanceOptions
                sharing={sharing}
                workspace={workspace}
                passcode={passcode}
                setPasscode={setPasscode}
                handleSaveChanges={handleSaveChanges}
                handleTabSelection={handleTabSelection}
                hiddenTab={hiddenTab}
                visibleTabs={visibleTabs}
                customizeLink={customizeLink}
                setCustomizeLink={setCustomizeLink}
              />
            </>
          )}
        </div>

        <div className="flex mt-4 text-primary-main mb-4">
          <Icon
            height="12px"
            width="12px"
            style={{ alignSelf: 'center', fill: 'currentColor' }}
            className="dark:text-dark-light"
          />
          <p className="self-center ml-2 text-xs font-normal text-primary-main dark:text-dark-light">
            {t('THIS_BOARD_WILL_BE_SHARED_AS_VIEW_ONLY')}
          </p>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button onClick={onClose}>{t('CLOSE')}</Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default ShareBoardModal
