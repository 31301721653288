import React, { useState, useEffect } from 'react'
import { LoadingButton, Tooltip } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import { userSignup } from 'thunks/signup/actions'
import { storeAccessToken, storeRefreshToken, userRoles } from 'utils'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { generateInvoice } from 'thunks/invoices/actions'
import { RoundedTextInput } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import { validator } from 'utils'
import { useHistory } from 'react-router-dom'
import Currency from 'static/Currency/Currency'
import LoginModal from 'components/Login/LoginModal'
import SubscribeServiceModal from './SubscribeServiceModal'
import { Button } from '@material-ui/core'
import { ReactComponent as CheckIcon } from 'static/svg/check.svg'

const CheckoutForm = ({
  selectedPlan,
  selectedAddOns = [],
  removeAddOn,
  removeLogin
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const { companyInfo } = useSelector(state => state.generalAppState)
  const [passwordValid, setPasswordValid] = useState(true)
  const { loggedIn } = useSelector(state => state.login)
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn)
  const [formData, setFormData] = useState({
    _id: loggedIn ? meData._id : null,
    name: meData.name || '',
    email: meData.email || '',
    password: ''
  })
  const [loading, setLoading] = useState(false)
  // const [domain, setDomain] = useState('')
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [purchaseRequestCount, setPurchaseReqCount] = useState(0)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
  const subscriptions = useSelector(state => state.subscriptions)
  const subscriptionMatch =
    meData._id && selectedPlan._id
      ? subscriptions.data.find(subscription => {
          const match = subscription.services.find(
            service => service?._id === selectedPlan._id
          )
          return Boolean(match) && subscription.client?._id === meData._id
        })
      : false

  useEffect(() => {
    if (purchaseRequestCount > 0) {
      handleServicePurchase()
    }
  }, [purchaseRequestCount])

  const handleFormChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const handleServicePurchase = () => {
    if (!formData.name || !formData.email) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_ALL_THE_FIELDS'))) // just for safety
    }

    const now = new Date()
    const days =
      new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate() || 30

    const invoiceItems = [
      {
        ...selectedPlan,
        name: selectedPlan.name,
        quantity: 1,
        rate: selectedPlan.amount * 0.01,
        total: selectedPlan.amount * 0.01
      }
    ]

    selectedAddOns.forEach(addOn => {
      invoiceItems.push({
        _id: addOn._id,
        name: addOn.name,
        quantity: 1,
        rate: addOn.amount * 0.01,
        total: addOn.amount * 0.01,
        currency: addOn.currency,
        description: addOn.description,
        type: 'addon'
      })
    })

    let data = {
      autoRepeat: selectedPlan.isRecurring ?? false,
      dueDate: new Date(now.setDate(now.getDate() + days)).toISOString(), //will change it soon,
      autoRepeatFrequency: 30, //will change it soon
      client: {
        clientName: formData.name,
        clientEmail: formData.email,
        clientId: formData._id
      },
      invoiceItems,
      // totalAmount: invoiceItems.reduce((prev, curr) => prev + curr.total, 0),
      type: 'service',
      currency: selectedPlan.currency.toUpperCase()
    }

    setLoading(true)
    dispatch(generateInvoice({ data }, selectPlanHandlerCallback))
  }

  const selectPlanHandlerCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let redirectUrl

      if (selectedPlan.clientOnboarding?._id) {
        redirectUrl = `/public/workflow/${selectedPlan.clientOnboarding._id}`
      }

      history.push(
        `/i/${res._id}/checkout?vtID=${res.viewToken}${
          redirectUrl ? `&redirectUrl=${redirectUrl}` : ''
        }`
      )
    }
  }

  const handleSubmit = async e => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.password ||
      // !accountHolderName ||
      !confirmPassword
    ) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_ALL_THE_FIELDS')))
    }
    if (formData.password !== confirmPassword) {
      return dispatch(
        fireErrorToaster(t('ERROR_CONFIRM_PASSWORD_DOES_NOT_MATCH'))
      )
    }
    let validEmail = /.+@.+\..+/.test(formData.email)
    if (!validEmail) {
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_EMAIL_ADDRESS'))
      )
    }
    if (!validator.isPasswordValid(formData.password)) {
      setPasswordValid(false)
      dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_A_VALID_PASSWORD')))
      return
    }

    setLoading(true)
    userSignup(
      {
        data: {
          name: formData.name,
          email: formData.email,
          password: formData.password,
          agencyDomain:
            process.env.REACT_APP_ENVIRONMENT === 'development'
              ? 'roni.clientvenue.com'
              : window.location.host,
          role: userRoles.USER_CLIENT,
          language: companyInfo.data.platformLanguage || 'en' //default english
        }
      },
      signupCallback
    )
  }

  const signupCallback = async (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      storeAccessToken(res.login.token.accessToken)
      storeRefreshToken(res.login.token.refreshToken)
      // setLoader(true)
      setFormData(prev => ({
        ...prev,
        name: res.name,
        email: res.email,
        _id: res._id
      }))
      setPurchaseReqCount(prev => prev + 1)
    }
  }

  // const handleRedirectToLogin = () => {
  //   const url = `/login?redirectUrl=/services/${selectedPlan._id}/checkout`
  //   history.push(url)
  // }

  const handleAfterLogin = userData => {
    setIsLoggedIn(true)
    setFormData({
      _id: userData._id,
      name: userData.name,
      email: userData.email
    })
  }

  let totalAmount = selectedPlan.amount * 0.01
  selectedAddOns.forEach(item => {
    totalAmount += item.amount * 0.01
  })

  return (
    <div className="p-6 bg-white border rounded">
      <div className="mb-2">
        <h3 className="font-semibold text-gray-900 dark:text-dark-light">
          {t('BILLING_INFORMATION')}
        </h3>
      </div>

      <div className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <div className="w-2/3 text-sm font-medium capitalize leading-5 text-gray-700 dark:text-dark-light">
            {selectedPlan?.name}
          </div>
          <div className="block text-sm font-medium leading-5 text-primary-main">
            {
              Currency[selectedPlan.currency?.toUpperCase() || 'USD']
                .symbol_native
            }
            {selectedPlan.amount * 0.01}
          </div>
        </div>

        {selectedAddOns.map(addOn => (
          <div
            key={addOn._id}
            className="flex justify-between items-center text-smaller dark:text-dark-light"
          >
            <div>
              {addOn.name} <span className="text-xs">({t('ADD_ON')})</span>
            </div>

            <div className="text-right font-semibold">
              <span className="text-xs text-primary-main">
                {
                  Currency[selectedPlan.currency?.toUpperCase() || 'USD']
                    .symbol_native
                }
                {addOn.amount * 0.01}
              </span>
              <Tooltip title={t('REMOVE')}>
                <button
                  onClick={() => removeAddOn(addOn._id)}
                  className="ml-2 text-base"
                >
                  &#215;
                </button>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>

      <div className="border-gray-300 border-t border-b mb-4 px-2 flex justify-between items-center border-y py-2">
        <div className="block text-sm font-semibold leading-5 text-gray-700 dark:text-gray-400">
          {t('TOTAL')}:
        </div>
        <div className="block text-sm font-medium leading-5 text-primary-main">
          {
            Currency[selectedPlan?.currency?.toUpperCase() || 'USD']
              .symbol_native
          }
          {totalAmount}
        </div>
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium leading-5 text-gray-700 mb-2 dark:text-dark-light"
          htmlFor="name"
        >
          {t('NAME')}
        </label>
        <RoundedTextInput
          required
          name="name"
          value={formData.name}
          onChange={handleFormChange}
          className="w-full"
          id="name"
          placeholder={t('NAME')}
          disabled={isLoggedIn}
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-sm font-medium leading-5 text-gray-700 mb-2 dark:text-dark-light"
          htmlFor="userEmail"
        >
          {t('EMAIL')}
        </label>
        <RoundedTextInput
          name="email"
          value={formData.email}
          onChange={handleFormChange}
          className="w-full"
          id="userEmail"
          placeholder={t('EMAIL')}
          required
          inputProps={{
            autoComplete: 'off'
          }}
          disabled={isLoggedIn}
        />
      </div>

      {!removeLogin && (
        <div className="mt-2 text-sm text-gray-500 sm:col-span-6 dark:text-gray-400">
          {isLoggedIn ? t('NOT_THIS_ACCOUNT') : t('ALREADY_HAVE_AN_ACCOUNT')}
          &nbsp;
          <button
            role="link"
            className="text-primary-main"
            onClick={() => setIsLoginModalOpen(true)}
          >
            {t('LOGIN')}
          </button>
        </div>
      )}

      {!isLoggedIn && (
        <>
          <div className="mb-4">
            <label
              className="block text-sm font-medium leading-5 text-gray-700 mb-2 dark:text-dark-light"
              htmlFor="password"
            >
              {t('PASSWORD')}
            </label>
            <RoundedTextInput
              name="password"
              type="password"
              inputProps={{
                autoComplete: 'new-password'
              }}
              onChange={handleFormChange}
              className="w-full"
              id="password"
              placeholder={t('PASSWORD')}
              required
              // onBlur={validPasswordHandler}
            />
            {formData.password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                {t('PASSWORD_WARNING_TEXT')}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium leading-5 text-gray-700 mb-2 dark:text-dark-light"
              htmlFor="confirm-password"
            >
              {t('CONFIRM_PASSWORD')}
            </label>
            <RoundedTextInput
              name="password"
              type="password"
              onChange={e => setConfirmPassword(e.target.value)}
              className="w-full"
              id="confirm-password"
              placeholder={t('CONFIRM_PASSWORD')}
              required
            />
          </div>
        </>
      )}

      <div className="mt-6">
        <p className="text-gray-500 mb-3 text-sm dark:text-gray-400">
          {t('BY_SUBMITTING_THIS_FORM_YOU_AGREE')}
        </p>
        {subscriptionMatch ? (
          <Button
            startIcon={<CheckIcon />}
            className="mt-8 w-full"
            size="large"
            disabled
          >
            {t('SUBSCRIBED')}
          </Button>
        ) : (
          <LoadingButton
            className="mt-8 w-full"
            size="large"
            loading={loading}
            disabled={meData.role && meData.role < userRoles.USER_CLIENT} //disable for agency members
            onClick={
              isLoggedIn
                ? selectedPlan.isRecurring
                  ? () => setSubscriptionModalOpen(true)
                  : () => setPurchaseReqCount(prev => prev + 1)
                : handleSubmit
            }
          >
            {selectedPlan.isRecurring ? t('SUBSCRIBE') : t('PROCEED')}
          </LoadingButton>
        )}
      </div>

      <LoginModal
        open={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        afterLoginCallback={handleAfterLogin}
      />

      <SubscribeServiceModal
        open={subscriptionModalOpen}
        serviceData={selectedPlan}
        onClose={() => setSubscriptionModalOpen(false)}
      />
    </div>
  )
}

export default CheckoutForm
