import { CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { updateServiceTemplateImage } from 'thunks/service/actions'
import { fileToDataURL } from 'utils'

const ImageWrapper = ({
  src,
  alt,
  className,
  style,
  isEditable,
  onImageUpdate
}) => {
  return (
    <div
      className={clsx(
        'inline-block leading-3 relative overflow-hidden show-on-hover-parent cursor-pointer',
        className
      )}
      style={style}
    >
      <img className="w-full h-full" src={src} alt={alt} />

      {isEditable && (
        <ImageEditElement imageUrl={src} onImageUpdate={onImageUpdate} />
      )}
    </div>
  )
}

const ImageEditElement = ({ imageUrl, onImageUpdate }) => {
  const dispatch = useDispatch()
  const ref = useRef()
  const [loading, setLoading] = useState(false)
  const { templateId } = useParams()

  const handleClick = () => {
    ref.current.click()
  }

  const handleImageUpload = e => {
    const file = e.target.files[0]

    if (file) {
      setLoading(true)
      fileToDataURL(file).then(res => {
        dispatch(
          updateServiceTemplateImage(
            {
              id: templateId,
              body: {
                image: res,
                fileName: file.name,
                removeImage: imageUrl.includes('-default-')
                  ? undefined
                  : imageUrl
              }
            },
            (res, err) => {
              if (!err) {
                onImageUpdate(res.url)
              }

              setTimeout(() => {
                setLoading(false)
              }, 2000)
            }
          )
        )
      })
    }
    e.target.value = ''
  }

  return (
    <>
      <div
        onClick={handleClick}
        className={clsx(
          'absolute top-0 right-0 w-full h-full text-white flex justify-center items-center',
          !loading && 'show-on-hover-child'
        )}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
      >
        {loading ? <CircularProgress size={20} /> : <PenIcon />}
      </div>
      <input
        onChange={handleImageUpload}
        ref={ref}
        type="file"
        accept="image/*"
        className="hidden"
      />
    </>
  )
}

export default ImageWrapper
