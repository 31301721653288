import { useEffect, useMemo, useState } from 'react'
import {
  TextField,
  InputAdornment,
  useMediaQuery,
  Modal,
  Backdrop,
  Fade
  // makeStyles
} from '@material-ui/core'
// import { CVLogo } from 'static/Images'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
// import { ReactComponent as CVLogo } from 'static/svg/cv logo.svg'
import clsx from 'clsx'
import TemplateModalLeftView from './TemplateModalLeftView'
import TemplateModalRightView from './TemplateModalRightView'
import templateStyles from './templateStyles'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Skeleton } from '@material-ui/lab'
//import useStyles from 'components/UserDashboard/Workspaces/ProjectsCardView/projectsCardViewStyles'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { AlertModal, CustomTooltip } from 'global/globalComponents'
import {
  deleteProjectTemplate,
  fetchAllProjectTemplates
} from 'thunks/projectTemplate/action'
import { useModal } from 'customHooks'
import CreateTemplateModal from './CreateTemplateModal'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const TemplateLibrary = ({
  open,
  onClose,
  onTemplateSelect,
  prefetchedTemplatesData = []
}) => {
  const classes = templateStyles()

  return (
    <Modal
      style={{ zIndex: 1250 }}
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
    >
      <Fade in={open}>
        <div
          className={clsx(
            'h-full rounded-3xl relative overflow-auto',
            classes.paper
          )}
        >
          <TemplateLibraryContent
            onTemplateSelect={onTemplateSelect}
            prefetchedTemplatesData={prefetchedTemplatesData}
            onClose={onClose}
          />
        </div>
      </Fade>
    </Modal>
  )
}

const TemplateLibraryContent = ({
  // step,
  // setStep,
  onClose,
  onTemplateSelect,
  prefetchedTemplatesData
  // templateData,
  // updateDataHandler,
  // handleSearchChange,
  // deleteTemplateHandler
  // updateTemplateHandler,
  // component,
  // loading
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const classes = templateStyles()
  const [linkData, setLinkData] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [search, setSearch] = useState('')
  const [toggleNav, setToggle] = useState(true)
  const [allTemplatesData, setAllTemplatesData] = useState(
    prefetchedTemplatesData
  )
  const [loading, setLoading] = useState(!prefetchedTemplatesData.length)
  const {
    modal: createUpdateTemplateModal,
    openModal: openCreateUpdateTemplateModal,
    closeModal: closeModalCreateUpdateTemplateModal
  } = useModal()

  const {
    modal: deleteTemplateModal,
    openModal: openDeleteTemplateModal,
    closeModal: closeDeleteTemplateModal,
    toggleLoading: toggleDeleteTemplateModalLoading
  } = useModal()

  useEffect(() => {
    if (prefetchedTemplatesData.length === 0) {
      fetchAllProjectTemplates(null, (res, err) => {
        setLoading(false)
        if (!err) {
          setAllTemplatesData(res)
        }
      })
    }
  }, [prefetchedTemplatesData])

  const filteredTemplates = useMemo(() => {
    if (search?.trim()) {
      return allTemplatesData.filter(temp =>
        temp.title.toLowerCase().includes(search.toLowerCase())
      )
    }

    return allTemplatesData
  }, [search, allTemplatesData])

  const handleSelectTemplate = data => {
    onTemplateSelect(data)
    onClose()
  }

  const updateDataHandler = (res, data) => {
    if (data.new) {
      setAllTemplatesData(prev => [res, ...prev])
    } else {
      const updatedData = [...allTemplatesData]
      const index = updatedData.findIndex(el => el._id === res._id)
      updatedData[index] = res
      setAllTemplatesData(updatedData)
    }
  }

  const handleDeleteTemplate = () => {
    toggleDeleteTemplateModalLoading()
    deleteProjectTemplate({ id: deleteTemplateModal.data._id }, (res, err) => {
      toggleDeleteTemplateModalLoading()
      if (!err) {
        setAllTemplatesData(
          allTemplatesData.filter(temp => temp._id !== res._id)
        )
        dispatch(fireSuccessToaster(t('TEMPLATE_DELETED_SUCCESSFULLY')))
      } else {
        dispatch(fireErrorToaster(res))
      }
    })

    closeDeleteTemplateModal()
  }

  const matches = useMediaQuery('(min-width:768px)')

  return (
    <>
      <div className="flex flex-col pb-2 h-full w-full dark:bg-dark-main2">
        <header
          className={clsx(
            'flex items-center justify-between px-6 select-none rounded-t-3xl border-b h-16 w-full'
          )}
          style={{ borderBottom: '1px solid #ddd' }}
        >
          <div className="items-center">
            <h2 className="text-lg 2xl:text-xl font-semibold text-primary-dark mr-8 whitespace-nowrap dark:text-dark-light2">
              {t('TEMPLATE_CENTER')}
            </h2>
          </div>
          <div className="flex-1 pl-2 py-4 mr-4" style={{ maxWidth: '600px' }}>
            <TextField
              type="text"
              placeholder={t('SEARCH_TEMPLATES')}
              className="bg-transparent px-2 py-1 w-full text-sm "
              onChange={e => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon width="1em" height="1em" />
                  </InputAdornment>
                )
              }}
            />
          </div>
          {onClose && (
            <div className="justify-self-end">
              <IconButton onClick={onClose} size="small">
                <CloseIcon size="small" fontSize="small" />
              </IconButton>
            </div>
          )}
        </header>
        {!loading ? (
          <div className="flex flex-1 h-full overflow-hidden">
            <div
              className=" dark:bg-dark-main4 border-r h-full flex flex-col overflow-y-auto overflow-x-hidden"
              style={{
                flex: !toggleNav && !matches ? '0 0 50px' : '0 0 220px'
              }}
            >
              {!toggleNav && !matches ? null : (
                <TemplateModalLeftView
                  linkData={linkData}
                  setLinkData={setLinkData}
                  setSelectedCategory={setSelectedCategory}
                  // setStep={setStep}
                  // component={component}
                />
              )}
              {!matches && (
                <div className="mt-auto pl-4 mb-2">
                  <CustomTooltip
                    title={
                      toggleNav ? t('COLLAPSE_SIDEBAR') : t('EXPAND_SIDEBAR')
                    }
                  >
                    <button
                      type="button"
                      className="rounded-full shadow-sm text-primary-main"
                      onClick={() => setToggle(!toggleNav)}
                    >
                      {toggleNav ? (
                        <KeyboardArrowLeft color="inherit" size="inherit" />
                      ) : (
                        <KeyboardArrowRight color="inherit" size="inherit" />
                      )}
                    </button>
                  </CustomTooltip>
                </div>
              )}
            </div>
            <div className="flex-1 px-8 pt-4 pb-2 w-full h-full overflow-y-auto overflow-x-hidden ">
              <TemplateModalRightView
                linkData={linkData}
                setLinkData={setLinkData}
                templateData={filteredTemplates}
                handleSelectTemplate={handleSelectTemplate}
                updateDataHandler={updateDataHandler}
                selectedCategory={selectedCategory}
                openDeleteTemplateModal={openDeleteTemplateModal}
                openCreateUpdateTemplateModal={openCreateUpdateTemplateModal}
              />
            </div>
          </div>
        ) : (
          <LoadingSkeleton />
        )}
      </div>

      <CreateTemplateModal
        open={createUpdateTemplateModal.open}
        handleClose={closeModalCreateUpdateTemplateModal}
        tempData={createUpdateTemplateModal.data}
        updateDataHandler={updateDataHandler}
      />

      <AlertModal
        warningText={t(
          'WARNING_THIS_TEMPLATE_WILL_BE_DELETED_AND_CANNOT_BE_RECOVERED'
        )}
        open={deleteTemplateModal.open}
        handleDialog={closeDeleteTemplateModal}
        handleDeleteAction={handleDeleteTemplate}
        loading={deleteTemplateModal.loading}
      />
    </>
  )
}

const LoadingSkeleton = () => {
  const { t } = useTranslation()
  const classes = templateStyles()
  return (
    <div className="flex flex-1 h-full overflow-hidden">
      <div className="border-r" style={{ flex: '0 0 220px' }}>
        <div className="p-6">
          <div className="w-full h-full  whitespace-nowrap text-sm text-gray-900">
            {t('EXPLORE')}
          </div>
          <div className="w-full h-full  whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={50} />
          </div>
          <div className="w-full h-full  whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={50} />
          </div>
          <div className="w-full h-full  whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={50} />
          </div>
          <div className="w-full h-full  whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={50} />
          </div>

          <div className="w-full h-full  whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={50} />
          </div>
        </div>
      </div>
      <div className="flex-1 ">
        <div className="p-6">
          <div
            className={clsx(classes.templateGrid, 'pb-4')}
            style={{ overflow: 'hidden' }}
          >
            <Skeleton variant="rect" style={{ height: '180px' }} />
            <Skeleton variant="rect" style={{ height: '180px' }} />
            <Skeleton variant="rect" style={{ height: '180px' }} />
            <Skeleton variant="rect" style={{ height: '180px' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateLibrary
