import axios from 'axios'
import { t } from 'i18next'
import { catchAsync } from 'utils'
import { getErrorMessages } from 'utils/errorMessages'
const errorMessages = getErrorMessages()

/**
 * Forgot password api call
 * @param {String} email User email
 * @param {Function} callback callback function
 */
export const forgotPassword = async (email, callback) => {
  try {
    const res = await axios({
      url: '/user/forgetpassword',
      method: 'POST',
      data: {
        email
      },
      isPublic: true
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    let errMsg = errorMessages.ERROR_MESSAGE

    if (err?.response?.status === 404) {
      errMsg = t('NO_USER_FOUND')
    }

    if (callback) callback(errMsg, true)
    // callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Reset password Api call
 * @param {Object} data.data user new password
 * @param {String} data.token User auth token received in url params
 * @param {Function} callback
 */
export const resetPassword = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/user/resetPassword/${data.token}`,
    method: 'POST',
    data: data.data,
    isPublic: true
  })

  if (callback) callback(res.data)
})

/**
 * Reset password token validity Api call
 * @param {String} data.token User auth token receieved in url params
 * @param {Function} callback Callback function
 */
export const resetPasswordTokenValidity = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/user/resetpassword/${data.token}`,
    method: 'GET',
    isPublic: true
  })

  if (callback) callback(res)
})

/**
 * Forgot domain api call
 * @param {String} email User email
 * @param {Function} callback callback function
 */
export const forgotDomain = async (email, callback) => {
  try {
    const res = await axios({
      url: '/user/forgetDomain',
      method: 'POST',
      data: {
        email
      },
      isPublic: true
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    let errMsg = errorMessages.ERROR_MESSAGE
    if (err.response.status === 404) {
      errMsg = t('NO_USER_FOUND')
    }
    if (callback) callback(errMsg, true)
  }
}

/**
 * Check whether the domain is available or not
 * @param {String} data.domain
 * @param {Function} callback
 */
export const checkDomainAvailability = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/user/checkagencydomain?domain=${data.domain}.clientvenue.com`,
    isPublic: true
  })

  callback(res.data)
})
