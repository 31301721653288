import React, { useEffect, useMemo, useState } from 'react'
// import { Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import useTimerFunctions from './useTimerFunctions'
import { ReactComponent as StartTimer } from 'static/svg/play.svg'
import { ReactComponent as StopTimer } from 'static/svg/stop.svg'
// import { ReactComponent as CloseIcon } from 'static/svg/close.svg'
// import { ReactComponent as MaximizeRec } from 'static/svg/maximize-rec.svg'
import { ReactComponent as ArrowBottomLeftRect } from 'static/svg/arrow-bottom-left-rec.svg'
// import { LoadingButton } from 'global/globalComponents'
// import { toggleTimerPosition } from 'thunks/generlAppState/actions'

const HeaderTimer = () => {
  const {
    getTime,
    showFloatingTimer,
    getTimeFromSeconds,
    startTimer,
    stopTimer
  } = useTimerFunctions()
  let { activeTimer, isPaused: timerIsPaused } = useSelector(
    state => state.timer.data
  )

  const timerTask = useMemo(() => {
    if (activeTimer) {
      if (activeTimer.type === 'task') return activeTimer.task
      return activeTimer.subTask
    }
    return {}
  }, [activeTimer])

  const handleStartTimer = () => {
    let data = {
      workspace: activeTimer.workspace,
      workspaceBoard: activeTimer.workspaceBoard,
      type: activeTimer.type
    }

    if (activeTimer.type === 'task') {
      data.task = timerTask._id
    } else {
      data.subTask = timerTask._id
      data.task = timerTask.task
    }

    startTimer(data, activeTimer.task.title)
  }

  const handleStopTimer = () => {
    let data = { type: activeTimer.type }

    if (activeTimer.type === 'task') {
      data.task = timerTask._id
    } else {
      data.subTask = timerTask._id
      data.task = timerTask.task
    }

   
    stopTimer(data, timerTask.title)
  }

  if (!activeTimer) return null

  return (
    <div className="text-smaller ml-4 relative show-on-hover-parent py-2 px-4 border border-primary-main text-primary-main rounded w-32">
      <button
        className="absolute left-0 bottom-0 show-on-hover-child bg-white"
        onClick={showFloatingTimer}
        style={{ transform: 'translate(-50%, 50%)' }}
      >
        <ArrowBottomLeftRect />
      </button>
      <div className="flex items-center space-x-2">
        <div className="leading-none">
          <button onClick={timerIsPaused ? handleStartTimer : handleStopTimer}>
            {timerIsPaused ? <StartTimer /> : <StopTimer />}
          </button>
        </div>

        <Timer
          activeTimer={activeTimer}
          timerIsPaused={timerIsPaused}
          getTime={getTime}
          getTimeFromSeconds={getTimeFromSeconds}
        />
      </div>
    </div>
  )
}

const Timer = ({ activeTimer, timerIsPaused, getTime, getTimeFromSeconds }) => {
  const [time, setTime] = useState({
    minutes: '00',
    seconds: '00',
    hours: '00'
  })

  useEffect(() => {
    if (activeTimer) {
      let timerId = '',
        timerDuration =
          activeTimer.type === 'task'
            ? activeTimer.task.timerDuration ?? 0
            : activeTimer.subTask.timerDuration ?? 0

      if (!timerIsPaused)
        timerId = getTime(activeTimer.start, timerDuration, setTime)
      else {
        clearInterval(timerId)
        const totalTime = getTimeFromSeconds(timerDuration / 1000)
        setTime(totalTime)
      }

      return () => clearInterval(timerId)
    }
  }, [activeTimer])

  return (
    <span>
      {time.hours} : {time.minutes} : {time.seconds}
    </span>
  )
}

export default HeaderTimer
