export const USERPERMISSION = {
  FETCHED: 'FETCHED_USER_PERMISSIONS',
  UPDATED: 'UPDATED_USER_PERMISSIONS',
  ERROR: 'ERROR_FETCHING_PERMISSIONS'
}

const initialState = {
  data: {},
  error: false,
  loading: true
}

const userPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERPERMISSION.FETCHED:
      return { data: action.payload, error: false, loading: false }

    case USERPERMISSION.UPDATED:
      return { ...state, data: action.payload }

    case USERPERMISSION.ERROR:
      return { data: {}, error: true, loading: false }

    default:
      return state
  }
}

export default userPermissionReducer
