import { OutlinedButton } from 'global/globalComponents'
import Pill from 'global/globalComponents/Pill/Pill'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { createStandups, updateStandupById } from 'thunks/standups/actions'

const SuggestedTasks = ({
  suggestedTasks,
  openEditTaskPanel,
  allProjects,
  selectedTab
}) => {
  return (
    <>
      {suggestedTasks.map((item, index) => (
        <SingleSuggestion
          key={item.id}
          task={item}
          index={index + 1}
          allProjects={allProjects}
          openEditTaskPanel={openEditTaskPanel}
          selectedTab={selectedTab}
        />
      ))}
    </>
  )
}

const SingleSuggestion = ({
  task,
  index,
  allProjects,
  openEditTaskPanel,
  selectedTab
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const project = allProjects.find(item => item._id === task.workSpace)
  const standupsData = useSelector(state => state.standups.data)
  const [loading, setLoading] = useState(false)

  const addTask = () => {
    setLoading(true)
    const reqBody = {
      type: selectedTab === 'morning' ? 'morningStandups' : 'eveningStandups',
      standups: [
        {
          projectName: project.name,
          projectId: project._id,
          name: task.title,
          task: task._id,
          isComplete: false,
          type: selectedTab
        }
      ]
    }

    const standupsCallback = (res, err) => {
      setLoading(false)
      if (err) dispatch(fireErrorToaster(res))
    }

    if (!standupsData._id) {
      dispatch(createStandups({ data: reqBody }, standupsCallback))
    } else {
      dispatch(
        updateStandupById(
          { id: standupsData._id, data: reqBody },
          standupsCallback
        )
      )
    }
  }

  return (
    <div className="flex items-center dark:bg-dark-main py-2 px-2 border-t border-b bg-gray-50">
      <div className="flex items-center flex-1">
        <div className="flex items-center dark:text-dark-light">
          <span className="mr-2 text-sm text-gray-600 dark:text-dark-light">
            {index}.
          </span>

          <div
            className="cursor-pointer"
            onClick={() => openEditTaskPanel(task._id)}
          >
            <span
              className={
                'block text-smaller font-medium capitalize text-gray-600 dark:text-dark-light break-long-word'
              }
            >
              {task.title}
            </span>
          </div>
          <div className="ml-4">
            <Pill
              variant="blue"
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                maxWidth: 300
              }}
              className="truncate"
            >
              {project.name}
            </Pill>
          </div>
        </div>

        <div className="ml-auto leading-3 flex items-center space-x-4">
          <OutlinedButton
            variant="primary"
            onClick={addTask}
            roundedFull={false}
            className="w-14"
          >
            {loading ? (
              '...'
            ) : (
              <>
                <AddIcon />
                <span className="ml-1">{t('ADD')}</span>
              </>
            )}
          </OutlinedButton>
        </div>
      </div>
    </div>
  )
}

export default SuggestedTasks
