import axios from 'axios'
import { CLIENT } from 'thunks/addClient/reducers'
import { CLIENTCOMPANY } from './reducer'
import { catchAsyncDispatch } from 'utils'

/**
 * Add one client Company
 * @param {object} data.data name,role,email,password
 * @param {Function} callback callback function
 */
export const addClientCompany = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/clientcompany',
      data: data.data
    })
    if (callback) callback(res)
    dispatch({ type: CLIENTCOMPANY.ADD_ONE_CLIENTCOMPANY, payload: res.data })
    if (res.data.clientOwner) {
      dispatch({
        type: CLIENT.UPDATE_CLIENT_COMPANY,
        payload: { clientId: res.data.clientOwner, company: res.data }
      })
    }
  }, callback)
}

/**
 * fetch all clients companies of a user.
 * @param {Object|null} data
 * @param {Function} callback  callback function
 */

export const getClientCompanies = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    dispatch({ type: CLIENTCOMPANY.LOADING })
    const res = await axios({
      method: 'GET',
      url: '/clientcompany/agency'
    })

    if (callback) callback(res.data)
    dispatch({ type: CLIENTCOMPANY.FETCHED, payload: res.data })
  }, callback)
}

/**
 * Delete company by id
 * @param {String} data.id
 * @param {Function} callback  callback function
 */
export const deleteCompany = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/clientcompany/${data.id}`
      // data: data
    })

    if (callback) callback(res.data, false)

    dispatch({
      type: CLIENTCOMPANY.DELETE_ONE_CLIENTCOMPANY,
      payload: res.data
    })
    if (res.data.clientOwner) {
      dispatch({
        type: CLIENT.UPDATE_CLIENT_COMPANY,
        payload: { clientId: res.data.clientOwner, company: [] }
      })
    }
  }, callback)
}

/**
 * update client data.
 * @param {String} client clientId
 * @param {Object} data name,workspaces
 * @param {Function} callback callback
 */

export const updateClientCompany = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/clientcompany/${data.companyId}`,
      data: data.data
    })
    if (callback) callback(res.data, false)
    dispatch({
      type: CLIENTCOMPANY.UPDATE_ONE_CLIENTCOMPANY,
      payload: res.data
    })
    if (res.data.clientOwner) {
      dispatch({
        type: CLIENT.UPDATE_CLIENT_COMPANY,
        payload: { clientId: res.data.clientOwner, company: res.data }
      })
    }
  }, callback)
}
