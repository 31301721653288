import { Button } from '@material-ui/core'

const AccountNotApproved = () => {
  const handleGoBack = () => {
    localStorage.clear()
    window.location.reload()
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="text-center">
        <h1 className="font-bold mb-1">Account Under Approval</h1>
        <p className="text-sm mb-4">
          You account is under approval. Please contact your admin for any
          further action.
        </p>
        <Button onClick={handleGoBack}>Logout</Button>
      </div>
    </div>
  )
}

export default AccountNotApproved
