import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { LOGS } from 'thunks/logs/reducer'
import { SUBTASKS } from 'thunks/subTask/reducers'

const TaskPusherWrapper = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.taskId) {
      // 1. Looking for the channel in the pusher instance (whether it exists or not)
      const taskChannel = window.pusherInstance?.channel(`task_${props.taskId}`)

      // 2. If channel does not exist or channel does exist but not subscribed then do the following
      if (!taskChannel || !taskChannel.subscribed) {
        const newTaskChannelSubscribed = window.pusherInstance?.subscribe(
          `task_${props.taskId}`
        )

        if (newTaskChannelSubscribed) {
          newTaskChannelSubscribed.bind('subTask_update', data => {
            dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: data })
          })

          newTaskChannelSubscribed.bind('subTask_delete', data => {
            dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: data })
          })

          newTaskChannelSubscribed.bind('subTask_add', data => {
            // delaying it intentionally to avoid duplication issue
            setTimeout(() => {
              dispatch({ type: SUBTASKS.ADD_ONE_TASK, payload: data })
            }, 1000)
          })

          newTaskChannelSubscribed.bind('log_add', data => {
            dispatch({ type: LOGS.POSTED_LOG, payload: data })
          })

          newTaskChannelSubscribed.bind('log_delete', data => {
            dispatch({ type: LOGS.DELETE_COMMENT, payload: data })
          })
        }
      }

      return () => {
        window.pusherInstance?.unsubscribe(`task_${props.taskId}`)
      }
    }
  }, [props.taskId])

  return <>{props.children}</>
}

export default TaskPusherWrapper
