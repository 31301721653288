import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const SectionContainer = ({ children, className, ...rest }) => {
  const classes = useStyles()

  return (
    <div
      {...rest}
      className={clsx(className, classes.container, 'overflow-auto')}
    >
      {children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: `calc(100vh - ${theme.custom.header.height}px)`
  }
}))

export default SectionContainer
