import axios from 'axios'
import { catchAsync } from 'utils'

/**
 * Update client payment card
 * @param {Object} data
 * @param {Function} callback
 */
export const updateClientPaymentCard = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: '/clientpayment/card',
    data: data.body
  })
  callback(res.data)
})

/**
 * Fetch client's payment cards
 * @param {null} data
 * @param {Function} callback
 */
export const fetchClientCardDetails = catchAsync(async callback => {
  const res = await axios({
    method: 'GET',
    url: '/clientpayment/card'
  })

  if (callback) callback(res.data)
})

/**
 * Delete client's payment card
 * @param {Object} data
 * @param {Function} callback
 */
export const deleteClientPaymentCard = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: '/clientpayment/card',
    data: data.body
  })
  if (callback) callback(res.data)
})

// export const updatePaymentStatus = async (id, data, callback) => {
//   try {
//     const res = await axios({
//       url: `/clientpayment/${id}`,
//       method: 'PUT',
//       data
//     })
//     callback(res.data)
//   } catch (err) {
//     callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
//   }
// }

// export const fetchPaymentsData = async callback => {
//   try {
//     const res = await axios({
//       method: 'GET',
//       url: '/clientpayment/user'
//     })
//     callback(res.data)
//   } catch (err) {
//     callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
//   }
// }

// /**
//  * client can add tip while paying there invoice amount
//  * @param {Object} data tip
//  * @param {String} invoiceId
//  * @param {Function} callback
//  */
// export const addTipInInvoicePayment = async (data, invoiceId, callback) => {
//   try {
//     const res = await axios({
//       method: 'PUT',
//       url: `custominvoice/public/${invoiceId}`,
//       data
//     })
//     callback(res.data)
//   } catch (err) {
//     callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
//   }
// }

/**
 * Create order for Razorpay payment gateway
 * @param {Object} data.body
 * @param {Function} callback
 */
export const createRazorpayOrder = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/razorpay/order',
    data: data.body,
    isPublic: true
  })
  if (callback) callback(res.data)
})

/**
 * Capture Razorpay payment
 * @param {Object} data.body
 * @param {Function} callback
 */
export const captureRayzorpayPayment = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/razorpay/capturePayment',
    data: data.body,
    isPublic: true
  })
  if (callback) callback(res.data)
})
