import axios from 'axios'
import { INVOICES } from './reducer'
import { CLIENT } from 'thunks/addClient/reducers'
import { catchAsync, catchAsyncDispatch } from 'utils'

/**
 * Fetch all invoices
 * @param {Function} callback function.
 */
export const fetchCustomInvoices = callback => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { generalAppState } = getState()

    dispatch({ type: INVOICES.LOADING })
    const res = await axios({
      method: 'GET',
      url: generalAppState.isMarketplaceDomain
        ? '/invoice/user'
        : '/custominvoice/user',
      apiVersion: generalAppState.isMarketplaceDomain ? 2 : undefined
    })

    const sortedData = res.data.sort(
      (a, b) =>
        new Date(b.createdAt || b.created * 1000) -
        new Date(a.createdAt || a.created * 1000)
    )

    dispatch({ type: INVOICES.FETCHED, payload: sortedData })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch client's invoices
 * @param {String} data.id client id
 * @param {Function} callback function.
 */
export const fetchSingleClientInvoices = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `custominvoice/client/${data.id}`
  })

  const sortedData = res.data.sort(
    (a, b) =>
      new Date(b.createdAt || b.created * 1000) -
      new Date(a.createdAt || a.created * 1000)
  )

  if (callback) callback(sortedData)
})

/**
 * Generate invoice
 * @param {Object} data.data
 * @param {Function} callback callback function
 */
export const generateInvoice = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/custominvoice',
      data: data.data
    })

    dispatch({ type: INVOICES.ADD, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update invoice
 * @param {String} data.id invoice id
 * @param {Object} data.data update
 * @param {Function} callback function
 */
export const updateCustomInvoice = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/customInvoice/${data.id}`,
      method: 'PUT',
      data: data.data
    })

    dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete invoice
 * @param {String} data.id invoice id
 * @param {Function} callback function
 */
export const deleteCustomInvoice = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/customInvoice/${data.id}`,
      method: 'DELETE'
    })

    dispatch({ type: INVOICES.DELETE_ONE_INVOICE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Mark Invoice as Paid
 * @param {String} data.id invoice id
 * @param {Function} callback function
 */
export const markInvoiceAsPaid = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/custominvoice/markAsPaid/${data.id}`
    })

    dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

// /**
//  * Save tax details
//  * @param {id} agencyOwnerId .
//  * @param {Function} callback function.
//  */
// export const fetchClientInvoiceForAgency = (agencyOwnerId, callback) => {
//   return async (dispatch) => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/clientinvoice/agency/${agencyOwnerId}`
//       })
//       callback(res.data, false)
//     } catch (err) {
//       if (!axios.isCancel(err)) {
//         callback(err?.response?.data.message ?? errorMessages.ERROR_MESSAGE, true
//         )
//       }
//     }
//   }
// }

// /**
//  * Save tax details
//  * @param {id} stripeInvoiceId
//  * @param {Function} callback function.
//  */
// export const deleteStripeInvoiceById = (stripeInvoiceId, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'DELETE',
//         url: `/clientinvoice/draft/${stripeInvoiceId}`
//       })
//       callback(res.data, false)
//       dispatch({ type: INVOICES.DELETE_ONE_INVOICE, payload: res.data })
//       dispatch({
//         type: CLIENT.DELETE_DRAFT_SERVICE_OF_CLIENT,
//         payload: res.data
//       })
//     } catch (err) {
//       callback(err?.response?.data.message ?? errorMessages.ERROR_MESSAGE, true)
//     }
//   }
// }

/**
 * Change Invoice Status
 * @param {String} data.id
 * @param {Object} data.data update
 * @param {Function} callback function.
 */
export const changeInvoiceStatusById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/clientinvoice/status/${data.id}`,
      data: data.data
    })
    callback(res.data)
    dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data })
    dispatch({
      type: CLIENT.UPDATE_CURRENT_CLIENT_PAYMENT_STATUS,
      payload: res.data
    })
  }, callback)
}

/**
 * Fetch invoice analytics
 * @param {Object|null} data
 * @param {Function} callback
 */
export const fetchInvoiceAnalytics = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/custominvoice/dashboard/analytics/invoice'
  })

  callback(res.data)
})

/**
 * Fetch single invoice by id
 * @param {Object|null} data.
 * @param {Function} callback function.
 */
export const fetchCustomInvoiceById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/custominvoice/${data.id}`
    })

    callback(res.data)
    dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data })
  }, callback)
}

/**
 * Fetch subscription invoice
 * @param {String} data.id subscription id
 * @param {Function} callback function.
 */
export const fetchSubscriptionInvoice = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `customInvoice/subscription/${data.id}`
  })

  if (callback) callback(res.data)
})
