import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import TeammatesHeader from './TeammatesHeader'
import TeammatesTable from './TeammatesTable'
// import AddNewTeammatePanel from './AddNewTeammatePanel'
// import EditTeammatePanelNew from './EditTeammatePanelNew'
import {
  // updateTeammate,
  reInviteTeammate,
  fetchTeammateOfCurrentUser,
  moveTeammateToTrash
} from 'thunks/teammate/actions'
import { userRoles } from 'utils'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import {
  useRouteMatch,
  useLocation,
  useHistory,
  Switch,
  Route
} from 'react-router-dom'
// import VerticalsTable from './VerticalsTable'
// import VerticalsModal from './VerticalsModal'
import {
  // createTeamVerticals,
  fetchTeamVerticals
} from 'thunks/Verticals/action'
import { CustomTabs } from 'global/globalComponents'
import ApproveTeammateTable from './ApproveTeammateTable'
import { useTranslation } from 'react-i18next'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import AddNewTeammatePanel from './AddNewTeammatePanel'
import Timesheets from 'components/ResourceManagement/Timesheets/Timesheets'
import Workload from 'components/ResourceManagement/WorkloadView/Workload'
import SelectedUserTimesheet from 'components/ResourceManagement/Timesheets/SelectedUserTimesheet'
import { usePermission } from 'customHooks'

const AgencyTeammates = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const search = useSelector(state => state.generalAppState.headerSearch)
  const history = useHistory()
  const [teammatePanel, setTeammatePanel] = useState({
    open: false,
    data: {}
  })
  const teammates = useSelector(state => state.userTeammates)
  const { fetchedFirstTime } = useSelector(state => state.userWorkspaces)
  const verticals = useSelector(state => state.teamVerticals)
  const { data: meData } = useSelector(state => state.me)
  const [teammatesData, setTeammatesData] = useState([])
  const [teammatesUnderApproval, setTeammatesUnderApproval] = useState([])
  const [selectedTab, setSelectedTab] = useState(pathname)
  const [deleteTeammateAlert, setDeleteTeammateAlert] = useState({
    open: false,
    data: {},
    loading: false
  })
  const { timesheet: timesheetPermission } = usePermission()

  const tabsData = [
    {
      label: t('TEAM_MEMBERS'),
      value: path + '/all',
      number: teammatesData.length
    },
    {
      label: t('UNDER_APPROVAL'),
      value: path + '/under-approval',
      number: teammatesUnderApproval.length
    },
    ...(timesheetPermission.view
      ? [
          {
            label: t('TIMESHEETS'),
            value:
              path + `/timesheets/${new Date().toISOString().substring(0, 10)}`
          },
          {
            label: t('RESOURCE_MANAGEMENT'),
            value: path + '/resource-management'
          }
        ]
      : [])
  ]

  useEffect(() => {
    if (pathname.includes('timesheets')) {
      setSelectedTab(
        `/agency/teammates/timesheets/${new Date()
          .toISOString()
          .substring(0, 10)}`
      )
    } else {
      setSelectedTab(pathname)
    }
  }, [pathname])

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  // useEffect(() => {
  //   if (!teammates.fetched) {
  //     dispatch(fetchTeammateOfCurrentUser())
  //   }
  // }, [teammates.fetched])

  useEffect(() => {
    dispatch(fetchTeammateOfCurrentUser())
  }, [])

  useEffect(() => {
    if (meData.role < userRoles.USER_CLIENT && !verticals.fetched) {
      dispatch(fetchTeamVerticals())
    }
  }, [verticals])

  useEffect(() => {
    if (search.length > 0) {
      let arr = [...teammates.data].filter(
        item =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase())
      )

      let approved = [],
        underApproval = []

      for (let teammate of arr) {
        if (teammate.status === 'need_approval') {
          underApproval.push(teammate)
        } else approved.push(teammate)
      }

      setTeammatesData(
        teammates.data.filter(
          item =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.email.toLowerCase().includes(search.toLowerCase())
        )
      )

      setTeammatesUnderApproval(
        teammates.underApproval.filter(
          item =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.email.toLowerCase().includes(search.toLowerCase())
        )
      )
    } else {
      setTeammatesData([...teammates.data])
      setTeammatesUnderApproval([...teammates.underApproval])
    }
  }, [teammates.data, teammates.underApproval, search])

  const openEditPanel = teammateData => {
    setTeammatePanel({ open: true, data: teammateData })
  }
  // const closeEditPanel = () => {
  //   setTeammatePanel(prev => ({ ...prev, open: false }))
  // }

  // const handleUpdateTeammate = (id, data, panelCallback) => {
  //   dispatch(
  //     updateTeammate({ id, data }, (res, err) => {
  //       panelCallback()
  //       updateTeammateCallback(res, err)
  //     })
  //   )
  // }

  // const updateTeammateCallback = (res, err) => {
  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //   } else {
  //     dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
  //   }
  // }

  const handleInviteTeammate = teammateId => {
    if (teammateId) {
      reInviteTeammate({ teammateId }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('INVITATION_SENT')))
        }
      })
    }
  }

  const canEditAccount = mateRole => {
    //manager, admin, super admin

    if (
      meData.role === userRoles.AGENCY_ADMIN &&
      mateRole > userRoles.USER_AGENCY
    )
      return true

    // user cannot edit himself or his superiors
    if (meData.role === mateRole) return false
    if (meData.role <= userRoles.USER_AGENCY) return true // agency can edit everyone
    if (
      meData.role === userRoles.AGENCY_MANAGER &&
      mateRole > userRoles.AGENCY_MANAGER &&
      mateRole !== userRoles.AGENCY_ADMIN
    )
      return true
    return false
  }

  const handleTabChange = useCallback(newVal => {
    // console.log(newVal, 'newValll')
    setSelectedTab(newVal)
    history.push(newVal)
  }, [])

  const toggleDeleteTeammateAlert = teammate => {
    if (deleteTeammateAlert.open) {
      setDeleteTeammateAlert(prev => ({ ...prev, open: false }))
    } else {
      setDeleteTeammateAlert({
        open: true,
        data: teammate,
        loading: false
      })
    }
  }

  const handleDeleteTeammate = () => {
    setDeleteTeammateAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      moveTeammateToTrash({ id: deleteTeammateAlert.data._id }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
          setDeleteTeammateAlert(prev => ({
            ...prev,
            loading: false
          }))
        } else {
          setDeleteTeammateAlert(prev => ({
            ...prev,
            open: false
          }))
          dispatch(fireSuccessToaster(t('DELETED_SUCCESSFULLY')))
        }
      })
    )
  }

  return (
    <OuterContentWrapper className="relative">
      {(meData.payment?.status ?? meData.team?.payment?.status) ===
        'canceled' && (
        <div
          className="absolute top-0 bottom-0 left-0 right-0 z-50"
          style={{ background: 'rgba(0,0,0,0.2)', height: '100%' }}
        >
          <div className="h-full flex justify-center items-center text-white">
            <p
              className=" py-4 px-6 rounded-lg text-white text-lg w-96 text-center"
              style={{ background: 'black' }}
            >
              Your plan doesn't allow to access teammates. Please upgrade or
              contact support
            </p>
          </div>
        </div>
      )}
      <TeammatesHeader
        openAddTeammatePanel={() => setTeammatePanel({ open: true, data: {} })}
      />
      <div className="flex justify-between items-center mb-6">
        <div className="w-full">
          <CustomTabs
            tabsData={tabsData}
            value={selectedTab}
            onChange={handleTabChange}
          />
        </div>
      </div>

      {search !== '' && (
        <p className="text-sm mb-2 ml-1 text-gray-500 font-medium dark:text-dark-light2">
          {t('SEARCH_RESULT')}:
          <span className="text-primary-dark dark:text-dark-light">
            {search}
          </span>
        </p>
      )}

      <Switch>
        <Route exact path={path + '/all'}>
          <TeammatesTable
            loading={teammates.loading}
            teammates={teammatesData}
            openEditPanel={openEditPanel}
            handleInviteTeammate={handleInviteTeammate}
            elmFor="agency"
            canEditAccount={canEditAccount}
            openDeleteTeammateAlert={toggleDeleteTeammateAlert}
          />
        </Route>

        <Route path={path + '/under-approval'}>
          <ApproveTeammateTable
            loading={teammates.loading}
            teammates={teammatesUnderApproval}
          />
        </Route>

        {timesheetPermission.view ? (
          <>
            <Route path={`${path}/timesheets/:time/:userId`}>
              <SelectedUserTimesheet />
            </Route>

            <Route path={`${path}/timesheets/:time`}>
              <Timesheets />
            </Route>

            <Route path={`${path}/resource-management`}>
              <Workload />
            </Route>
          </>
        ) : null}
      </Switch>

      <AddNewTeammatePanel
        open={teammatePanel.open}
        data={teammatePanel.data}
        onClose={() => setTeammatePanel(prev => ({ ...prev, open: false }))}
      />

      <AlertModal
        warningText={t('WARNING_TEAM_MEMBER_MOVE_TRASH', {
          data: `"${deleteTeammateAlert.data.name}"`
        })}
        open={deleteTeammateAlert.open}
        handleDialog={toggleDeleteTeammateAlert}
        handleDeleteAction={handleDeleteTeammate}
        loading={deleteTeammateAlert.loading}
      />
    </OuterContentWrapper>
  )
}

export default AgencyTeammates
