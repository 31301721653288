import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Card = ({ children, className, style }) => {
  return (
    <div
      className={clsx(
        'bg-white border border-gray-300 rounded-xl overflow-hidden dark:bg-dark-main3 dark:border-dark-main1',
        className
      )}
      style={style}
    >
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string
}

export default React.memo(Card)
