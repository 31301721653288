import React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceItemsTable, TotalAmountTable } from './PricingTable'
// import { useDispatch, useSelector } from 'react-redux'
// import { fetchAllCoupons } from 'thunks/coupon/actions'

export const PreviewInvoiceTemplate2 = ({ invoiceData, publicView }) => {
  const { t } = useTranslation()
  const timestamp = new Date().getTime()

  return (
    <div className="invoice-print bg-white px-1 py-8 sm:p-8 border border-gray-300 rounded flex-1">
      <header>
        <div className="flex items-center justify-center mb-2 relative">
          <img
            src={`${invoiceData.agency.agencyLogo.smallLogo}?t=${timestamp}}`}
            alt="logo"
            className="w-24"
          />

          {invoiceData.status === 'paid' && (
            <div className="absolute right-0 top-0 inline-block px-10 py-2 border border-primary-main rounded text-primary-main tracking-widest origin-bottom-left rotate-45 opacity-60">
              {t('PAID')}
            </div>
          )}
        </div>

        <div className="uppercase text-center border-t border-b border-gray-400 py-1.5 text-2xl font-bold">
          {invoiceData.customInvoiceNumber || invoiceData.invoiceNumber}
        </div>
      </header>

      <div className="flex space-x-6 text-smaller bg-gray-100 p-2 mt-4">
        <div className="flex-1">
          <h6 className="font-semibold">{t('CLIENT')}</h6>
          <div>{invoiceData.client.clientName}</div>
          <div>{invoiceData.client.clientAddress}</div>
          <div>{invoiceData.client.clientPhoneNumber}</div>
        </div>

        <div className="flex-1 text-right">
          {/* <h6 className="font-semibold">{t('Agency')}</h6> */}
          <div className="font-semibold">{invoiceData.agency.agencyName}</div>
          <div>{invoiceData.agency.agencyAddress}</div>
          <div>{invoiceData.agency.agencyPhoneNumber}</div>
          <div>
            <span>Date: </span>
            <time>
              {new Date(invoiceData.issuedDate).toLocaleDateString('en-GB')}
            </time>
          </div>
        </div>
      </div>

      <div className="mt-16">
        <InvoiceItemsTable
          readOnly={true}
          invoiceItems={invoiceData.invoiceItems}
          currency={invoiceData.currency}
        />
      </div>

      <div className="mt-10">
        <TotalAmountTable
          currency={invoiceData.currency}
          tax={invoiceData.taxPercent || 0}
          taxName={invoiceData.taxName}
          invoiceItems={invoiceData.invoiceItems}
          appliedCoupon={invoiceData.coupon}
          discountAmount={invoiceData.discountAmount}
          invoiceSubtotal={invoiceData.subTotalAmount}
          invoiceTotal={invoiceData.totalAmount}
        />
      </div>

      {/* ===== Bottom empty space ===== */}
      <div className="h-40"></div>
    </div>
  )
}

export default PreviewInvoiceTemplate2
