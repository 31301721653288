import { useEffect, useMemo, useState } from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { colorShades, colorTints } from 'utils/hexToRgb'
import { useAppTheme } from 'customHooks'

const ThemeProvider = ({ children }) => {
  const { sidebarOpen, companyInfo } = useSelector(
    state => state.generalAppState
  )
  const [sidebarWidth, setSidebarWidth] = useState(220)
  const { appThemeMode } = useAppTheme()

  const theme = useMemo(() => {
    const isDarkMode = appThemeMode === 'dark'
    let primaryColor

    const themeData = JSON.parse(localStorage.getItem('customize'))

    if (isDarkMode) primaryColor = '#333333'
    else {
      primaryColor =
        companyInfo.data.theme?.primary ||
        themeData?.theme?.primary ||
        '#1600E5'
    }

    return createMuiTheme({
      // breakpoints: {
      //   values: {
      //     xs: 0,
      //     sm: 600,
      //     md: 900,
      //     lg: 1200,
      //     xl: 1536,
      //   },
      // },
      palette: {
        type: appThemeMode,
        //checking if dark mode property exist and if not then passing light , values are dark and light
        darkBlue: {
          main: '#1600E5'
        },
        primary: {
          main: primaryColor,
          light: colorTints(primaryColor, 0.95)
        },
        secondary: {
          main: '#6B6B71'
        },
        text: {
          primary: '#14142b',
          secondary: '#778CA2'
        },
        custom: {
          gray: {
            main: '#778ca2',
            light: '#F9F9F9'
          },
          dark: {
            main: '#17191C',
            light: '#14142b'
          },
          green: {
            main: '#7acd65'
          }
        }
      },
      typography: {
        h1: {
          color: 'red'
        },
        h5: {
          fontSize: '1.625rem'
        },
        h6: {
          fontSize: '1.125rem',
          fontWeight: 400
        }
      },
      props: {
        MuiButton: {
          fontSize: '14px',
          variant: 'contained',
          color: 'primary',
          disableRipple: true,
          disableElevation: true,
          background: primaryColor
        },
        MuiRadio: {
          color: 'primary',
          size: 'small'
        },
        MuiCheckbox: {
          color: 'primary'
        },
        MuiTextField: {
          size: 'small',
          variant: 'outlined'
        },
        MuiSelect: {
          variant: 'outlined'
        },
        MuiTableRow: {
          hover: true
        }
      },
      overrides: {
        MuiTouchRipple: {
          rippleVisible: {
            display: 'none'
          },
          childLeaving: {
            display: 'none'
          }
        },
        MuiTableContainer: {
          root: {
            boxShadow: 'rgba(198, 198, 198, 0.32) 0px -1px 2px 0px',
            background: '#fff'
          }
        },
        MuiTableCell: {
          root: {
            padding: '8px 16px',
            fontSize: '15px'
          },
          head: {
            color: 'rgba(9,30,66,.74)',
            fontSize: '11px',
            textTransform: 'uppercase',
            fontWeight: '700',
            background: 'rgb(250, 251, 252)'
          },
          body: {
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(9,30,66,.74)',
            letterSpacing: 'normal',
            fontFamily: 'Inter, sans-serif'
          },
          stickyHeader: {
            backgroundColor: 'rgb(250, 251, 252)'
          }
        },

        MuiButton: {
          sizeSmall: {
            fontSize: '11px',
            fontWeight: '500',
            minWidth: 50,
            borderRadius: 6,
            padding: '3px 8px'
            // '@media (min-width: 1684px)': {
            //   fontSize: '12px',
            //   padding: '4px 10px'
            // }
          },
          sizeLarge: {
            padding: '6px 22px',
            fontSize: '13px',
            borderRadius: 8
          },
          root: {
            fontSize: '12px',
            textTransform: 'capitalize',
            backgroundColor: isDarkMode ? '#333' : primaryColor,
            borderRadius: 7,
            padding: '4px 10px',
            fontFamily: 'Inter, sans-serif',
            minWidth: 75
            // '@media (min-width: 1684px)': {
            //   // fontSize: '14px',
            //   // padding: '6px 16px',
            //   minWidth: 100
            // }
          },
          outlined: {
            padding: '4px 10px',
            background: isDarkMode ? 'transparent' : '#fff'
          },
          outlinedPrimary: {
            color: isDarkMode ? colorTints(primaryColor, 0.85) : primaryColor,
            border: `1px solid ${colorTints(primaryColor, 0.85)}`,
            background: isDarkMode ? 'transparent' : '#fff',
            // padding: '6px 16px',
            '&:hover, &:focus': {
              background: isDarkMode ? 'transparent' : '#fff'
            },
            '&$disabled': {
              '&&': {
                background: isDarkMode ? 'transparent' : '#fff',
                color: colorTints(primaryColor, 0.5)
              }
            }
          },
          containedPrimary: {
            border: `1px solid ${primaryColor}`,
            backgroundColor: primaryColor,
            '&:hover, &:focus': {
              backgroundColor: colorShades(primaryColor, '0.90')
            },
            '&$disabled': {
              '&&': {
                background: colorTints(primaryColor, 0.65),
                color: '#fff',
                borderColor: colorTints(primaryColor, 0.65)
              }
            }
          }
        },
        MuiOutlinedInput: {
          input: {
            padding: 14
          },
          root: {
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            backgroundColor: isDarkMode ? '#27272a' : '#fff',
            color: isDarkMode ? '#ccc' : '#000',
            '& fieldset': {
              borderColor: isDarkMode ? '#ccc' : 'rgba(0, 0, 0, 0.23)',
              '&:focused': {
                borderColor: isDarkMode ? '#ccc' : primaryColor
              }
            },
            '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: isDarkMode ? '#ccc' : primaryColor
            }
            // '&.Mui-focused': {
            //   border: '1px solid rgba(0, 0, 0, 0.23)',
            //   '& .MuiOutlinedInput-notchedOutline': {
            //     border: 'none'
            //   }
            // }
            // "&.Mui-focused fieldset": {
            //   border: "2px solid green",
            // },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'rgba(0, 0, 0, 0.23)',
            // }
          }
        },
        MuiDialogTitle: {
          root: {
            borderBottom: '1px solid #d6d6d6',
            padding: '12px 24px'
          }
        },
        MuiDialogActions: {
          root: {
            borderTop: '1px solid #d6d6d6',
            padding: '12px 24px'
          }
        },
        MuiAccordionSummaryContent: {
          margin: 0,
          display: 'flex',
          flexGrow: 1,
          transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        },
        MuiPopover: {
          paper: {
            boxShadow:
              '0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%)'
          }
        },
        MuiInputBase: {
          root: {
            fontSize: '.875rem',
            color: isDarkMode ? '#fff' : '#14142b'
          },
          input: {
            height: '1.01876em'
          }
        },
        MuiDrawer: {
          paperAnchorDockedRight: {
            borderRight: '1px solid rgba(0, 0, 0, 0.12)'
          }
        },
        MuiTypography: {
          body2: {
            fontSize: '0.75rem',
            lineHeight: '1.3',
            fontFamily: 'Inter, sans-serif'
          },
          body1: {
            fontFamily: 'Inter, sans-serif'
          },
          colorTextSecondary: {
            color: isDarkMode ? '#9f9f9f' : '#778ca2'
          }
        },
        MuiTabs: {
          indicator: {
            width: '50%',
            backgroundColor: primaryColor,
            height: 3
          },
          vertical: {
            '& .MuiTabs-indicator': {
              width: '100%',
              opacity: '.05',
              borderRadius: '5px'
            },
            '& .MuiTab-wrapper': {
              alignItems: 'start',
              padding: '0 10px',
              color: isDarkMode ? '#fff' : '#4C4C5B'
            }
          }
        },
        MuiTab: {
          root: {
            padding: 6,
            marginRight: 5,
            textTransform: 'capitalize',
            fontSize: '13.5px',
            fontWeight: 500,
            color: isDarkMode ? '#ccc' : '#4C4C5B',
            '&$selected': {
              color: isDarkMode ? '#fff' : primaryColor,
              fontWeight: '500'
            },
            '@media (min-width: 0px)': {
              minWidth: 'initial'
            },
            '@media (min-width: 1684px)': {
              fontSize: '15px'
            }
          },
          wrapper: {
            color: isDarkMode ? '#ccc' : '#4C4C5B'
          }
        },
        MuiTabScrollButton: {
          root: {
            width: '36px',
            display: 'flex',
            alignItems: 'center',
            '&:first-child': {
              height: '100%',
              justifyContent: 'flex-start',
              position: 'absolute',
              left: '0px',
              zIndex: 20
            },
            height: '100%',
            justifyContent: 'flex-end',
            position: 'absolute',
            zIndex: '20',
            right: 0,
            '&>svg': {
              width: '17px',
              height: '17px',
              fill: '#fff',
              backgroundColor: primaryColor,
              borderRadius: '9999px',
              zIndex: 20
            }
          }
        },
        MuiListItemText: {
          root: {
            color: isDarkMode ? '#fff' : '#000'
          }
        },
        MuiList: {
          root: {
            backgroundColor: isDarkMode ? '#333' : '#fff'
          }
        },
        MuiListItem: {
          root: {
            color: isDarkMode ? '#ccc' : '#000',
            '&:hover': {
              backgroundColor: isDarkMode ? '#1c1c1c' : '#fff'
            }
          }
        },
        MuiAccordionSummary: {
          root: {
            color: isDarkMode ? '#fff' : '#000'
          }
        },
        MuiAccordionDetails: {
          root: {
            color: isDarkMode ? '#c1c1c1' : '#333'
          }
        },
        MuiStepLabel: {
          completed: {
            color: isDarkMode ? '#ccc' : '#14142B'
          },
          active: {
            color: isDarkMode ? '#fff' : '#14142B'
          },
          label: {
            color: isDarkMode ? '#888' : '#778ca2'
          }
        }
      },
      custom: {
        header: {
          height: 55
        },
        projectHeader: {
          height: 64
        },
        payStripe: {
          height: 48
        },
        sidebar: {
          width: sidebarWidth
        },
        sideDrawer: {
          width: 170
        },
        drawerPaper: {
          // boxShadow:'-3px 0px 27px 2px rgb(189 180 180 / 75%)'
          boxShadow: '0 5px 20px 0 rgb(21 27 38 / 8%)'
        },
        logsDrawer: {
          width: 300
        },
        taskCard: {
          width: 260
        },
        darkMode: {
          background: '#333',
          color: '#fff', // light
          lightText: '#ccc',
          dark1: '#424242',
          dark2: '#27272a',
          textField: '#5e5e5e',
          dark3: '#1c1c1c'
        }
      }
    })
  }, [sidebarWidth, appThemeMode, companyInfo.data.theme])

  useEffect(() => {
    if (sidebarOpen) {
      setSidebarWidth(window.innerWidth < 1684 ? 190 : 210)
    } else {
      setSidebarWidth(55)
    }
  }, [sidebarOpen])

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

export default ThemeProvider
