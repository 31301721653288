import { useState } from 'react'
import EmbedModalUI from './EmbedModalUI'
import { Box, useTheme } from '@material-ui/core'
// import { ReactComponent as EmbedCalender } from 'static/svg/embed-calender.svg'
import { ReactComponent as ServiceIcon } from 'static/svg/list-alt.svg'
import { getEmbedObj, sideDrawerLinks } from './EmbedModalData'
import clsx from 'clsx'
import embedStyles from './EmbedStyles'
import { ReactComponent as BoardImg } from 'static/svg/board.svg'
import { useTranslation } from 'react-i18next'

const EmbedModal = ({ anchorPosFromLeft, onClose }) => {
  const { t } = useTranslation()
  const classes = embedStyles(anchorPosFromLeft)
  const embedObj = getEmbedObj()
  const theme = useTheme()
  const [selectedEmbedLink, setSelectedEmbedLink] = useState({
    id: 'board',
    name: 'Board',
    description: t('BOARD_DESC_MSG'),
    image: <BoardImg />
  })

  const handleEmbedLink = key => {
    setSelectedEmbedLink(embedObj[key.toLowerCase()])
  }

  const handleCloseModal = e => {
    if (e.target === e.currentTarget) onClose()
  }
  return (
    <div className={clsx(classes.modalBackdrop)} onClick={handleCloseModal}>
      <div className={clsx(classes.wrapper, '')}>
        <div
          style={{
            overflowY: 'auto',
            borderRadius: '6px 0 6px 6px'
          }}
        >
          <div
            className={`${classes.search} text-smaller 2xl:text-sm dark:text-dark-light`}
          >
            <ServiceIcon className="h-4 w-4 pr-1 dark:text-dark-light" />
            {t('ADD_VIEW')}
          </div>
          <div className={classes.addViewPopup}>
            <div className={classes.sidebarContainer}>
              <Box>
                <p
                  style={{
                    color: theme.palette.type === 'dark' ? '#fff' : '#7c828d',
                    opacity: 0.6,
                    textAlign: 'left',
                    fontSize: '13px',
                    padding: '0 9px 9px'
                  }}
                >
                  {t('EMBED_VIEWS')}
                </p>
              </Box>
              {sideDrawerLinks.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className={clsx(
                      { active: selectedEmbedLink.id === item.id },
                      classes.sidebarItems
                    )}
                    onClick={() => handleEmbedLink(item.text)}
                  >
                    <div className="relative w-4 mr-2">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="text-smaller 2xl:text-sm">{item.text}</div>
                  </Box>
                )
              })}
            </div>
            <div>
              <EmbedModalUI {...selectedEmbedLink} handleClose={onClose} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmbedModal
