export const INTEGRATIONS = {
  LOADING: 'ALL_INTEGRATIONS_LOADING',
  FETCHED: 'ALL_INTEGRATIONS_FETCHED',
  ADD_ONE_INTEGRATION: 'ADD_ONE_INTEGRATION',
  UPDATE_ONE_INTEGRATION: 'UPDATE_ONE_INTEGRATION',
  DELETE_ONE_INTEGRATION: 'DELETE_ONE_INTEGRATION',
  ERROR: 'ALL_INTEGRATIONS_ERROR'
  // PUSHER_ADD_ONE_INTEGRATION: 'PUSHER_ADD_ONE_INTEGRATION',
  // PUSHER_DELETE_ONE_INTEGRATION: 'PUSHER_DELETE_ONE_INTEGRATION'
}

const initialState = {
  error: false,
  loading: true,
  data: [],
  fetchedFirstTime: false,
  currentIntegration: {}
}

const integrationsReducer = (state = initialState, action) => {
  // const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case INTEGRATIONS.LOADING:
      return { ...state, loading: true, error: false }

    case INTEGRATIONS.ERROR:
      return { ...state, loading: false, error: action.payload }

    case INTEGRATIONS.FETCHED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        fetchedFirstTime: true
      }

    case INTEGRATIONS.ADD_ONE_INTEGRATION: {
      const isAvailable = state.data.find((i) => i._id === action.payload._id)
      if (isAvailable !== undefined) {
        return state
      } else {
        return {
          ...state,
          data: [action.payload, ...state.data]
        }
      }
    }

    case INTEGRATIONS.UPDATE_ONE_INTEGRATION:
      const ind = state.data.findIndex(
        (item) => item._id === action.payload?._id
      )
      const newData = [...state.data]
      newData[ind] = action.payload
      return {
        ...state,
        loading: false,
        data: newData,
        currentIntegration:
          state.currentIntegration &&
          state.currentIntegration._id === action.payload?._id
            ? action.payload
            : state.currentIntegration
      }

    case INTEGRATIONS.DELETE_ONE_INTEGRATION:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }

    default:
      return state
  }
}

export default integrationsReducer
