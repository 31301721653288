import { useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import {
  // Modal,
  Button,
  // IconButton,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // Dialog,
  Popover,
  List,
  ListItem,
  // ListItemIcon,
  ListItemText
} from '@material-ui/core'
// import clsx from 'clsx'
// import CloseButton from 'global/globalComponents/CloseButton/CloseButton'
import { LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import { approveRejectServiceCancelReq } from 'thunks/cancelClientServiceRequest/actions'
import { ArrowDropDown } from '@material-ui/icons'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
// import {
//   CustomModal,
//   CustomModalHeader
// } from 'global/globalComponents/CustomModal'

const CancelRequestModal = ({ open, onClose, data: service }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState('')
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const dropdownButtons = [
    { name: 'Cancel Service', method: () => handleAgencyResponse('approve') },
    { name: 'Refund Service', method: () => handleAgencyResponse('refund') }
  ]

  const handleAgencyResponse = res => {
    setLoading(res)

    dispatch(
      approveRejectServiceCancelReq(
        service._id,
        {
          cancelRequest: {
            requestStatus: res
          }
        },
        handleAgencyResponseCallback
      )
    )
  }

  const handleAgencyResponseCallback = (res, err) => {
    setLoading('')
    setAnchorEl(null)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(t('REQUEST_APPROVED_SUCCESSFULLY')))
      onClose()
    }
  }

  return (
    <CustomModal open={open} onClose={onClose} fullWidth size="small">
      <CustomModalHeader
        heading="Service Cancellation Request"
        handleClose={onClose}
      />

      <CustomModalBody>
        <div className="mb-8">
          <div className="mb-4">
            <h3 className="font-medium text-md">Client Info</h3>
            <div className="font-medium capitalize text-sm">
              {service.user.name}
            </div>
            <div className="text-xs text-custom-gray-500">
              {service.user.email}
            </div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div>
              <div className="font-medium text-md">Service</div>
              <p className="text-sm font-medium">{service.name}</p>
            </div>

            <div>
              <div className="font-medium text-md">Amount</div>
              <div className="text-primary-main text-sm font-medium">
                {service.payment_plan.amount * 0.01}{' '}
                {service.payment_plan.currency.toUpperCase()}
              </div>
            </div>
          </div>

          <div>
            <div className="font-medium text-md">Reason</div>
            <p
              className="text-sm"
              style={{ maxHeight: '100px', overflow: 'auto' }}
            >
              {service.cancelRequest.reason || 'N/A'}
            </p>
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        {service.status === 'paid' && service.cancelRequest.refund ? (
          <div>
            <Button
              color="primary"
              onClick={handleClick}
              endIcon={<ArrowDropDown />}
            >
              Action
            </Button>

            <PopOver
              handleClose={handleClose}
              anchorEl={anchorEl}
              dropdownButtons={dropdownButtons}
            />
          </div>
        ) : (
          <LoadingButton
            color="primary"
            onClick={() => handleAgencyResponse('approve')}
            loading={Boolean(loading === 'approve')}
          >
            Approve
          </LoadingButton>
        )}

        <LoadingButton
          onClick={() => handleAgencyResponse('cancel')}
          loading={Boolean(loading === 'cancel')}
          style={{ background: '#E02A2A', borderColor: '#E02A2A' }}
        >
          Reject
        </LoadingButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

const PopOver = ({ handleClose, anchorEl, dropdownButtons }) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <List disablePadding dense>
        {dropdownButtons?.map((item, index) => (
          <ListItem key={index} onClick={item.method} button>
            <ListItemText
              style={{ fontWeight: 500, fontSize: '12px' }}
              primary={item.name}
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  )
}

export default CancelRequestModal
