import { PROPOSAL } from './ProposalContext'
import axios from 'axios'
import { catchAsync, getQueryString } from 'utils'

/**
 * Create new proposal
 * @param {Object} data.data
 * @param {Function} callback
 */
export const createNewProposal = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/proposal',
    data: data.data
  })
  if (callback) callback(res.data)
})

/**
 * Create new proposal
 * @param {Object} data.data
 * @param {String} data.token
 * @param {Function} callback
 */
export const createNewProposalPublic = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/proposal',
    data: data.data,
    headers: {
      Authorization: data.token
    }
  })
  if (callback) callback(res.data)
})

/**
 * Delete proposal by id
 * @param {String} data.id
 * @param {Function} callback
 */
export const deleteProposalById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/proposal/${data.id}`
  })

  if (callback) callback(res.data)
})

/**
 * Fetch user's all proposals
 * @param {Object | null} data.data
 * @param {Object | undefined} data.query
 * @param {Function} callback
 */
export const fetchUserProposals = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/proposal/user${getQueryString(data?.query ?? {})}`
  })

  if (callback) callback(res.data)
})

/**
 * Fetch single proposal by id
 * @param {String} data.id proposal id
 * @param {Function} callback
 */
export const fetchProposalById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/proposal/${data.id}`
  })

  if (callback) callback(res.data)
})

/**
 * Update proposal by id
 * @param {String} data.id proposal id
 * @param {String} data.data updated proposal data
 * @param {Function} callback
 */
export const updateProposalById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/proposal/${data.id}`,
    data: data.data
  })

  if (callback) callback(res.data)
})

/**
 * Update proposal by id (PUBLIC)
 * @param {String} data.id proposal id
 * @param {String} data.data updated proposal data
 * @param {Function} callback
 */
export const updateProposalByIdPublic = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/proposal/public/${data.id}`,
    data: data.data,
    isPublic: true
  })

  if (callback) callback(res.data)
})

/**
 * Upload attachment logo
 * @param {String} data.proposalId proposal template id
 * @param {String} data.fileName
 * @param {String} data.file
 * @param {String} prevLogoUrl // using this to call delete api
 * @param {Function} callback
 */
export const uploadProposalAttachment = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/attachment',
    data: data.data
  })

  if (callback) callback(res.data)
  deleteProposalLogo({
    proposalId: data.data.proposalId,
    fileUrl: data.prevLogoUrl
  })
})

/**
 * Upload attachment logo
 * @param {String} data.proposalId proposal template id
 * @param {String} data.fileUrl
 * @param {Function} callback
 */
export const deleteProposalLogo = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/attachment/${data.proposalId}?url=${data.fileUrl}`
  })

  if (callback) callback(res.data)
})

/**
 * Verify passcode
 * @param {String} data.id proposal id
 * @param {String} data.passcode
 * @param {Function} callback
 */
export const getProposalByPasscode = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/proposal/public/${data.id}?passcode=${data.passcode}`,
    isPublic: true
  })

  if (callback) callback(res.data)
})

/**
 * Send mail
 * @param {String} data.id proposal id
 * @param {String} data.data subject, content
 * @param {Function} callback
 */
export const sendMail = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: `/proposal/mail/${data.proposalId}`,
    data: data.data
  })

  if (callback) callback(res.data)
})

export const fetchedTemplate = value => {
  return { type: PROPOSAL.FETCHED, payload: value }
}

export const updateTemplate = value => {
  return { type: PROPOSAL.UPDATE, payload: value }
}

export const updateThemeColors = value => {
  return { type: PROPOSAL.UPDATE_THEME_COLORS, payload: value }
}

export const updateLogoSize = value => {
  return { type: PROPOSAL.UPDATE_LOGO_SIZE, payload: value }
}

export const updateLogo = value => {
  return { type: PROPOSAL.UPDATE_LOGO, payload: value }
}

export const updateImage = value => {
  return { type: PROPOSAL.UPDATE_IMAGE, payload: value }
}

/**
 *
 * @param {String} data.variableName
 * @param {String} data.value
 * @returns
 */
export const updateSingleVariable = data => {
  return { type: PROPOSAL.UPDATE_SINGLE_CUSTOM_VARIABLE, payload: data }
}

/**
 *
 * @param {Array} data
 * @returns
 */
export const updateMultiVariables = data => {
  return { type: PROPOSAL.UPDATE_MULTI_CUSTOM_VARIABLES, payload: data }
}

export const updateProposalStatus = value => {
  return { type: PROPOSAL.UPDATE_STATUS, payload: value }
}

export const copyPage = value => {
  return { type: PROPOSAL.COPY_PAGE, payload: value }
}

export const removePage = value => {
  return { type: PROPOSAL.REMOVE_PAGE, payload: value }
}

export const undoChanges = () => {
  return { type: PROPOSAL.UNDO }
}

export const redoChanges = () => {
  return { type: PROPOSAL.REDO }
}

export const assignAgencyTeammate = value => {
  return { type: PROPOSAL.ASSIGN_AGENCY_TEAMMATE, payload: value }
}

export const removeAgencyTeammate = value => {
  return { type: PROPOSAL.REMOVE_AGENCY_TEAMMATE, payload: value }
}

export const updatePasscode = value => {
  return { type: PROPOSAL.UPDATE_PASSCODE, payload: value }
}

/**
 * Update proposal's auto saving status
 * @param {String} status => saving / saved / failed
 * @returns
 */
export const updateAutoSavingStatus = value => {
  return { type: PROPOSAL.UPDATE_AUTO_SAVING_STATUS, payload: value }
}

export const resetProposalState = () => {
  return { type: PROPOSAL.RESET_DATA }
}
