import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import {
  getApprovalItemData,
  getApprovalStatus,
  getCurrentStage,
  statusData
} from './approvalStatic'
import { AlertModal, CustomTooltip } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import { useModal } from 'customHooks'
import { deleteApprovalById } from 'thunks/approvals/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useDispatch, useSelector } from 'react-redux'

const RaisedApprovalCard = ({ approvalData, handleDeleteApproval }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const currentStage = getCurrentStage(approvalData)
  const approvalStatus = getApprovalStatus(approvalData)
  const [approvalItem, setApprovalItem] = useState({ loading: true, data: {} })
  const { modal, openModal, closeModal, toggleLoading } = useModal()

  useEffect(() => {
    getApprovalItemData({
      dispatch,
      module: approvalData.module,
      moduleId: approvalData.moduleId,
      callback: (res, err) => {
        setApprovalItem({
          loading: false,
          data: err
            ? {}
            : {
                name:
                  approvalData.module === 'task' ||
                  approvalData.module === 'subTask'
                    ? res.title
                    : approvalData.module === 'invoice'
                    ? `Invoice ${res.invoiceNumber}`
                    : approvalData.module === 'file'
                    ? `${res.name} ${res.extension}`
                    : res.name
              }
        })
      }
    })
  }, [])

  const handleOnDelete = () => {
    toggleLoading()
    deleteApprovalById({ approvalId: modal.data._id }, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        toggleLoading()
      } else {
        handleDeleteApproval(modal.data._id)
      }
    })
  }

  const approvalStatusData = statusData[approvalStatus]
  const Icon = approvalStatusData.icon

  return (
    <>
      <div
        className="px-4 py-2"
        style={{
          // Here 12 is the alpha value
          backgroundColor: `${approvalStatusData.theme.color}12`,
          borderLeft: `4px solid ${approvalStatusData.theme.color}`
        }}
      >
        <header className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <h3
              style={{ color: approvalStatusData.theme.color }}
              className="text-sm font-medium"
            >
              {approvalStatusData.status}
            </h3>
            <div
              style={{ color: approvalStatusData.theme.color }}
              className="ml-2"
            >
              <Icon />
            </div>
          </div>

          {meData._id === approvalData.user._id && (
            <CustomTooltip title={t('DELETE')}>
              <button
                onClick={() => openModal(approvalData)}
                className="text-red-500"
              >
                <TrashIcon />
              </button>
            </CustomTooltip>
          )}
        </header>

        <div className="flex items-center text-smaller mb-4">
          <span>{t('REQUESTED_FOR')}</span>
          <span className="font-semibold ml-2 leading-3">
            {approvalItem.loading ? (
              <CircularProgress size={15} />
            ) : (
              approvalItem.data.name
            )}{' '}
          </span>
        </div>

        <FieldWrapper
          label={t('DATE_SUBMITTED')}
          value={new Date(approvalData.createdAt).toLocaleDateString(
            undefined,
            {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            }
          )}
        />
        <FieldWrapper label={t('TITLE')} value={approvalData.name} />
        <FieldWrapper
          label={t('DESCRIPTION')}
          value={approvalData.description}
        />
        <FieldWrapper
          label={t('DUE_DATE')}
          value={
            approvalData.dueDate
              ? new Date(approvalData.dueDate).toLocaleDateString(undefined, {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                })
              : ''
          }
        />
        <FieldWrapper
          label={t('CURRENT_STAGE')}
          value={currentStage?.name ?? ''}
        />
      </div>
      <AlertModal
        warningText={t('WARNING_DELETE', {
          data: modal.data.name
        })}
        open={modal.open}
        handleDialog={closeModal}
        handleDeleteAction={handleOnDelete}
        loading={modal.loading}
        // style={{ zIndex: 2000 }}
      />
    </>
  )
}

const FieldWrapper = ({ label, value }) => {
  return (
    <div className="flex items-center mb-4">
      <div className="w-32 flex-shrink-0 text-smaller font-semibold">
        {label}:{' '}
      </div>
      <div className="text-smaller">{value}</div>
    </div>
  )
}

export default RaisedApprovalCard
