import React, { useEffect, useState, useImperativeHandle } from 'react'
import { useTheme } from '@material-ui/core'
import Select from 'react-select'
import { CustomTooltip } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import invoiceStyles from '../InvoiceStyles'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { ReactComponent as Icon } from 'static/svg/i.svg'
import { t } from 'i18next'
import { useSelector } from 'react-redux'

const ProjectSelector = React.forwardRef(
  (
    {
      selectedClient,
      selectedProject,
      setSelectedProject
      // selectedInvoiceFrequency,
      // setSelectedInvoiceFrequency,
      // setScheduleInvoice
    },
    ref
  ) => {
    const theme = useTheme()
    const userWorkspaces = useSelector(state => state.userWorkspaces.data)
    const [projectOptions, setProjectOptions] = useState([])
    const [selectedInvoiceType, setSelectedInvoiceType] = useState({
      label: t('SINGLE_INVOICE'),
      value: 'single'
    })

    const classes = invoiceStyles()

    useEffect(() => {
      const options = userWorkspaces
        .filter(item => {
          const match = item.assignedClientTeam.find(item => {
            return item.user._id === selectedClient?._id
          })
          return Boolean(match)
        })
        .map(item => ({ label: item.name, value: item._id, data: item }))

      setProjectOptions(options)
    }, [userWorkspaces, selectedClient])

    useImperativeHandle(ref, () => ({
      selectedInvoiceType: selectedInvoiceType.value
    }))

    const handleProjectChange = val => {
      setSelectedProject(val)
    }

    // const selectInvoiceType = newVal => {
    //   setScheduleInvoice(newVal.value === 'recurringTimeTracked')
    //   setSelectedInvoiceType(newVal)
    // }

    // const selectInvoiceFrequency = newVal => {
    //   setSelectedInvoiceFrequency(newVal)
    // }

    // const invoiceRepeatFrequency =
    //   selectedInvoiceType.value === 'recurringTimeTracked'
    //     ? [
    //         { label: t('EVERY_WEEK'), value: 7 },
    //         { label: t('EVERY_MONTH'), value: 30 }
    //       ]
    //     : [
    //         { label: t('EVERY_WEEK'), value: 7 },
    //         { label: t('EVERY_TWO_WEEK'), value: 15 },
    //         { label: t('EVERY_MONTH'), value: 30 },
    //         { label: t('EVERY_YEAR'), value: 365 }
    //       ]

    // const invoiceTypeOptions = [
    //   { label: t('SINGLE_INVOICE'), value: 'single' },
    //   { label: t('RECURRING_INVOICE'), value: 'recurring' },
    //   { label: t('RECURRING_FROM_TIME_TRACKED'), value: 'recurringTimeTracked' }
    // ]

    return (
      <>
        <div className="mb-3">
          <div className="flex items-center space-x-1">
            <FormLabel required>{t('SELECT_CLIENT_PROJECT')}</FormLabel>
            <CustomTooltip
              title={t('SELECT_A_CLIENT_TO_ENABLE_THIS')}
              placement="top"
            >
              <Icon
                height="12px"
                width="12px"
                className="text-primary-main"
                style={{ alignSelf: 'center', fill: 'currentColor' }}
              />
            </CustomTooltip>
          </div>
          <Select
            isDisabled={!selectedClient}
            menuPosition={'fixed'}
            placeholder={
              !projectOptions.length && selectedClient?.value
                ? t('NO_PROJECT_AVAILABLE')
                : t('SELECT_PROJECT')
            }
            className={classes.textFieldStyle}
            options={projectOptions}
            onChange={handleProjectChange}
            styles={{
              ...reactSelectCustomStyles(theme),
              menuPortal: provided => ({ ...provided, zIndex: 9999 })
            }}
            maxMenuHeight={130}
            value={selectedProject}
            isSearchable={true}
          />
        </div>

        {/* <div className="mb-3">
          <div className="flex items-end space-x-3">
            <div className="flex-1">
              <FormLabel>{t('INVOICE_TYPE')}</FormLabel>
              <Select
                menuPlacement="top"
                options={invoiceTypeOptions}
                onChange={selectInvoiceType}
                value={selectedInvoiceType}
                styles={reactSelectCustomStyles(theme)}
                className="w-full"
                isSearchable={false}
              />
            </div>

            <div className="flex-1">
              {(selectedInvoiceType.value === 'recurring' ||
                selectedInvoiceType.value === 'recurringTimeTracked') && (
                <Select
                  menuPlacement="top"
                  options={invoiceRepeatFrequency}
                  onChange={selectInvoiceFrequency}
                  value={selectedInvoiceFrequency}
                  styles={reactSelectCustomStyles(theme)}
                  className="w-full"
                  maxMenuHeight={100}
                  isSearchable={false}
                />
              )}
            </div>
          </div>
        </div> */}
      </>
    )
  }
)

export default ProjectSelector
