import { makeStyles, createStyles } from '@material-ui/core'

const UserDashboardStyles = makeStyles(theme => {
  const isDarkMode = theme.palette.type === 'dark'

  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
    },
    paper: {
      width: '40vw',
      marginTop: theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    },
    drawerPaper: {
      width: '70vw',
      maxWidth: '600px',
      marginTop: theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    },
    avatarRoot: {
      '& > div': {
        width: '24px',
        height: '24px',
        fontSize: 10,
        textTransform: 'uppercase'
      }
    },
    projectsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(13rem, 1fr))'
    },

    panelFooter: {
      background: '#f3f3f3'
    },
    toolbarRoot: {
      backgroundColor: '#fff',
      borderBottom: 'solid 1px rgba(9,30,66,.12)',
      height: '40px',
      width: '100%',
      minHeight: '50px',
      zIndex: theme.zIndex.appBar
    },
    fabRoot: {
      backgroundColor: 'rgba(9,30,66,.87)',
      borderRadius: '0 0 0 5px',
      color: '#fff',
      float: 'none',
      fontSize: '20px',
      fontWeight: '400',
      height: '50px',
      left: '-51px',
      opacity: '.7',
      position: 'absolute',
      textShadow: '0 0 0 #fff',
      top: '0',
      transition: '.3s',
      width: '50px',
      '&:hover': {
        backgroundColor: 'rgba(9,30,66,1)'
      },
      '&:focus': {
        outline: 'none'
      }
    },

    fieldLabel: {
      fontSize: 16
    },
    textFieldStyle: {
      width: '100%'
    },
    saveBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(2)
    },
    cardContainer: {
      width: 120,
      height: 120,
      backgroundColor: '#00d4c8',
      borderRadius: 20,
      boxShadow: 'inset 0 -2px rgb(0 0 0 / 5%)',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '&.archived:after': {
        position: 'absolute',
        top: 5,
        left: 5,
        display: 'inline',
        borderRadius: 8,
        backgroundColor: 'rgba(255,255,255, 0.2)',
        content: 'attr(data-wstype)',
        fontSize: 10,
        padding: '4px 6px'
      },
      '&.new:after': {
        position: 'absolute',
        top: 5,
        left: 5,
        display: 'inline',
        borderRadius: 8,
        backgroundColor: 'white',
        content: 'attr(data-wstype)',
        fontSize: 10,
        padding: '4px'
      }
    },
    svgIconContainer: {
      width: 48,
      height: 48,
      backgroundColor: 'transparent',
      transitionDuration: '0.4s',
      '&>svg': {
        width: 48,
        height: 48
      }
    },
    avatarContainer: {
      marginBottom: -24,
      marginTop: 8,
      height: 24,
      opacity: 0,
      transitionDuration: '0.4s'
    },
    avatar: {
      '&>div': {
        width: 24,
        height: 24,
        fontSize: 11,
        borderColor: '#00d4c8',
        borderWidth: 1,
        boxShadow: 'inset 0 0 0 1px rgb(0 0 0 / 7%)',
        fontWeight: 300
      }
    },
    moreIconContianer: {
      position: 'absolute',
      top: 8,
      right: 8,
      transition: 'opacity .4s',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
      width: 28,
      height: 28,
      cursor: 'pointer',
      opacity: 0,
      '&>svg': {
        fill: '#fff'
      },
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,.12)'
      }
    },
    selectBox: {
      width: '100%'
    },
    flexRowContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '&>*': {
        flex: '1 0'
      }
    },

    listRoot: {
      '&>div': {
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        height: 36
      }
    },
    popoverPaper: {
      boxShadow:
        '0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%)'
    },
    iconsPopoverPaper: {
      boxShadow:
        '0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%)',
      maxWidth: '220px',
      height: 'auto',
      padding: theme.spacing(1)
    },
    colorsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing(0.8),
      paddingBottom: 16,
      '&>label': {
        width: 20,
        height: 20,
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderRadius: theme.shape.borderRadius
      }
    },
    iconScrollableWrapper: {
      // borderBottom: '1px solid #e8ecee',
      borderTop: '1px solid #e8ecee',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '226.4px',
      // width: 236,
      overflowY: 'auto'
    },
    iconsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0 auto',
      padding: '8px 0'
      // width: 192
    },
    iconWrapper: {
      alignItems: 'center',
      borderRadius: 10,
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'center',
      position: 'relative',
      height: 44,
      margin: 2,
      width: 44,
      '&:hover': {
        backgroundColor: 'rgba(159,70,228,.2)'
      },
      '&>svg': {
        width: 24,
        height: 24
      }
    },

    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2)
    },
    // divider: {
    //   height: '2px',
    //   backgroundColor: 'rgb(214 214 214 / 58%)',
    //   marginTop: '1.5rem'
    // },
    // icons: {
    //   color: '#6f7782',
    //   marginRight: '1rem',
    //   cursor: 'pointer',
    //   transition: '0.2s transform, 0.2s color, 0.2s backround-color',
    //   borderRadius: 4,
    //   '&:hover': {
    //     backgroundColor: 'rgb(214 214 214 / 58%)',
    //     color: '#333'
    //   },
    //   '&.expanded': {
    //     transform: 'rotate(90deg)'
    //   }
    // },
    // workspaceSection: {
    //   cursor: 'pointer',
    //   marginTop: '1.5rem',
    //   fontSize: 16,
    //   fontWeight: '500',
    //   fontFamily: 'Poppins',
    //   textTransform: 'capitalize',
    //   userSelect: 'none'
    // },
    // cardLoader: {
    //   width: 152,
    //   height: 226,
    //   borderRadius: 30,
    //   padding: theme.spacing(2, 0),
    //   display: 'flex',
    //   justifyContent: 'center'
    // },
    projectWrapper: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(13rem, 1fr))'
    },
    cardsWrapper: {
      width: 152,
      height: 226,
      borderRadius: 30,
      padding: theme.spacing(2, 0),
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'column',
      backgroundColor: 'transparent',
      transitionDuration: '0.4s',

      cursor: 'pointer',
      '&:hover, &.active': {
        transform: 'translate3d(0, -4px, 0)',
        // backgroundColor: '#ededed',
        '& $svgIconContainer': {
          transform: 'translate3d(0, -8px, 0)'
        },
        '& $avatarsContainer': {
          opacity: 1
        },
        '& $moreIconContianer': {
          opacity: 1
        }
      }
    },
    cardsContainer: {
      width: 120,
      height: 120,
      backgroundColor: '#00d4c8',
      borderRadius: 20,
      boxShadow: 'inset 0 -2px rgb(0 0 0 / 5%)',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
    },
    cardRoot: {
      maxWidth: 200,
      minWidth: 200,
      background: '#4b598a',

      color: 'white'
    },
    avatarsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '&>div': {
        width: 25,
        height: 25,
        marginRight: '-15px',
        // border: '2px solid black',
        color: theme.palette.grey[500],
        background: 'white'
      }
    },
    listsRoot: {
      '&>li': {
        fontSize: 14
      }
    },
    // logsPaper: {
    //   position: 'fixed',
    //   top: 0,
    //   right: 0,
    //   outline: 0,
    //   overflow: 'auto',
    //   backgroundColor: '#fff',
    //   width: theme.custom.logsDrawer.width,
    //   marginTop: theme.custom.header.height,
    //   // backgroundColor: '#f9f8f9',
    //   display: 'flex',
    //   flexDirection: 'column',
    //   zIndex: 1,
    //   gap: 0,
    //   marginBottom: '20px',
    //   height: `calc(100vh - ${theme.custom.header.height}px)`,
    //   '&.drawerOpen': {
    //     boxShadow: theme.custom.drawerPaper.boxShadow,
    //     overflow: 'visible'
    //   }
    // },

    logAvatar: {
      width: '1.95rem',
      height: '1.95rem',
      background: '#5da283'
    },
    logText: {
      color: '#5da283'
    },
    timeAgo: {
      marginBottom: '-3px',
      // marginLeft: '6px'
      fontSize: '10px'
    },
    tags: {
      borderBottom: '0.5px solid #e8ecee70',
      '&:hover': {
        backgroundColor: isDarkMode
          ? theme.custom.darkMode.background
          : 'rgba(229, 231, 235, 0.5)'
      },
      '&:hover .dot-icon': {
        opacity: 1
      }
    },
    dotIcon: {
      opacity: 0
    },
    // assignTag: {
    //   position: 'relative',
    //   padding: '3px 15px 3px 5px',
    //   borderRadius: '10px 70px 70px 10px',
    //   textTransform: 'capitalize',
    //   fontSize: '11px',
    //   fontWeight: 500,
    //   '&:hover .cross-btn': {
    //     opacity: 1
    //   }
    // },
    removeAssigneeBtn: {
      top: '-4px',
      right: '-10px'
    },
    tagStyles: {
      fontSize: '9px',
      userSelect: 'none',
      height: 22,
      lineHeight: '22px',
      '@media (min-width: 1684px)': {
        fontSize: '10px',
        height: 24,
        lineHeight: '24px'
      }
    },
    // outlinedIcon: {
    //   border: '1px dashed gray',
    //   borderRadius: '50%',
    //   padding: 4,
    //   height: 24,
    //   width: 24,
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   cursor: 'pointer',
    //   display: 'flex',
    //   '&>svg': {
    //     height: 14,
    //     width: 14,
    //     color: 'gray'
    //   }
    // },
    singleLog: {
      '& strong': {
        fontWeight: 500
      }
    },

    datePicker: {
      width: '100%',
      '& .react-datepicker': {
        backgroundColor: isDarkMode ? theme.custom.darkMode.dark2 : '#fff',
        color: isDarkMode ? theme.custom.darkMode.color : '#000'
      },
      '& .react-datepicker__header': {
        backgroundColor: isDarkMode ? theme.custom.darkMode.dark1 : '#fff',
        color: isDarkMode ? theme.custom.darkMode.color : '#000'
      }
    },
    fieldsContainer: {
      width: '100%',
      '@media (min-width: 768px)': {
        minWidth: '70%',
        flex: 1
      }
    }
  })
})

export default UserDashboardStyles
