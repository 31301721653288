import React from 'react'
import ContentEditable from 'react-contenteditable'
import ImageWrapper from '../ImageWrapper'

const WhyChooseUs = ({
  data,
  classes,
  isEditable,
  updateTemplateSection,
  updateSectionBlock,
  handleScrollToTop
}) => {
  return (
    <div className={classes.sectionContainer}>
      <ContentEditable
        html={data.heading}
        onChange={e =>
          updateTemplateSection({ field: 'heading', value: e.target.value })
        }
        disabled={!isEditable}
        className={classes.sectionHeading}
        style={{ textAlign: 'center' }}
      />

      <div className={classes.whyChooseUsCardsContainer}>
        {data.blocks.map(block => (
          <Card
            key={block.id}
            {...block}
            classes={classes}
            updateSectionBlock={updateSectionBlock}
            isEditable={isEditable}
          />
        ))}
      </div>

      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <button
          onClick={handleScrollToTop}
          className={classes.getStartedButton}
        >
          Get Started
        </button>
      </div>
    </div>
  )
}

const Card = ({
  id,
  classes,
  image,
  heading,
  description,
  updateSectionBlock,
  isEditable
}) => {
  return (
    <div className={classes.whyChooseUsCard}>
      <ImageWrapper
        className={classes.whyChooseUsCardImage}
        src={image}
        alt=""
        isEditable={isEditable}
        onImageUpdate={imgUrl =>
          updateSectionBlock({
            blockId: id,
            field: 'image',
            value: imgUrl
          })
        }
      />
      {/* <div>
        <img src={image} alt="" />
      </div> */}
      <ContentEditable
        html={heading}
        onChange={e =>
          updateSectionBlock({
            blockId: id,
            field: 'heading',
            value: e.target.value
          })
        }
        disabled={!isEditable}
        className={classes.whyChooseUsCardHeading}
      />
      <ContentEditable
        html={description}
        onChange={e =>
          updateSectionBlock({
            blockId: id,
            field: 'description',
            value: e.target.value
          })
        }
        disabled={!isEditable}
        className={classes.whyChooseUsCardDescription}
      />
    </div>
  )
}

export default WhyChooseUs
