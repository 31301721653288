import React from 'react'
import { useTheme } from '@material-ui/core'
import { useAgencyLogo } from 'customHooks'

const SidebarHeader = ({ isDrawerOpen }) => {
  const theme = useTheme()
  const { smallLogo, largeLogo } = useAgencyLogo()
  const logo = isDrawerOpen ? largeLogo : smallLogo
  const timestamp = new Date().getTime()

  return (
    <header
      className="flex items-center justify-between px-3"
      style={{ height: theme.custom.header.height }}
    >
      <img
        src={`${logo}?t=${timestamp}`}
        alt="logo"
        className="h-10 object-contain mx-auto mt-3"
        style={{ maxWidth: isDrawerOpen ? 145 : 34, height: '100%' }}
      />
    </header>
  )
}

export default SidebarHeader
