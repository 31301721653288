import { CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import clsx from 'clsx'

const ImagePreviewer = ({ fileUrl, styles = {} }) => {
  const [imgLoading, setImgLoading] = useState(true)

  return (
    <>
      {imgLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <CircularProgress size={50} />
        </div>
      )}
      <img
        src={fileUrl}
        alt=""
        className={clsx('object-contain', imgLoading && 'invisible')}
        onLoad={() => setImgLoading(false)}
        style={{ maxWidth: 800, maxHeight: 500, ...styles }}
      />
    </>
  )
}

export default ImagePreviewer
