import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  createService,
  // updateArchiveService,
  updateService
} from 'thunks/service/actions'
import {
  CheckBoxField,
  CustomTooltip,
  LoadingButton
} from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import Select from 'react-select'
import QuestionMarkTooltip from 'global/globalComponents/QuestionMarkTooltip'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import currencyObj from '../../static/Currency/Currency'
import { fileToDataURL } from 'utils'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import userServiceStyles from './userServiceStyles'
import { t } from 'i18next'
import { gettingAllTasksStatus } from 'thunks/gettingStarted/actions'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { ReactComponent as InfoIcon } from 'static/svg/i.svg'
import { fetchAllWorkflowTemplates } from 'thunks/onboarding/actions'
import Currency from 'static/Currency/Currency'
import RichTextEditor from 'global/globalComponents/QuillEditor/RichTextEditor'

const NewService = ({ open, onClose, data = {}, serviceCategories }) => {
  if (!open) return null

  return (
    <CustomModal size="large" open={open} handleClose={onClose}>
      <CustomModalHeader
        heading={data._id ? t('EDIT_SERVICE') : t('CREATE_A_NEW_SERVICE')}
        handleClose={onClose}
      />

      <ModelContent
        open={open}
        onClose={onClose}
        data={data}
        serviceCategories={serviceCategories}
      />
    </CustomModal>
  )
}

const ModelContent = ({ onClose, data, serviceCategories }) => {
  const { data: onboardingData, fetched: workflowFetched } = useSelector(
    state => state.onboarding
  )
  const theme = useTheme()
  const inputFileRef = useRef()
  const themeForMode = useSelector(state => state.generalAppState.theme)
  const classes = userServiceStyles()
  const dispatch = useDispatch()
  const [radioServiceType, setRadioServiceType] = useState(0)
  const [offering, setOffering] = useState('')
  const [loading, setLoading] = useState(false)
  const [recurring, setRecurring] = useState({})
  const userServicesData = useSelector(state => state.userServices.data)
  const addOnsData = useSelector(state => state.userServices.addOns.data)
  const [serviceAddOns, setServiceAddOns] = useState([]) // we'll use this for comparison
  const [selectedAddOns, setSelectedAddOns] = useState([])

  const [serviceData, setServiceData] = useState({
    name: '',
    description: '',
    image: null,
    offerings: [],
    isRecurring: true,
    displayOnCatalog: true,
    amount: '',
    advancedSettings: {
      quota: {
        totalAllowedProject: 0,
        totalAllowedRequest: 0
      }
    }
  })
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: Currency['USD'].code.toLocaleLowerCase(),
    label: Currency['USD'].code,
    minimumChargeAmount: Currency['USD'].minimum_charge_amount ?? 1
  })
  const [categoryOptions, setCategoryOptions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState({})
  const [selectedWorkflowOption, setSelectedWorkflowOption] = useState({})

  // const { fireMixpanelEvent } = useMixpanelEvent()

  // show Add-ons with the same currency as service
  const filteredAddOns = addOnsData.filter(
    addOn => addOn.currency === selectedCurrency.value
  )

  const intervalPeriods = [
    {
      label: t('MONTHLY'),
      value: { interval: 'month', interval_count: 1, trial_period_days: 0 }
    },
    {
      label: t('QUARTERLY'),
      value: { interval: 'month', interval_count: 3, trial_period_days: 0 }
    },
    {
      label: t('SEMI_ANNUALLY'),
      value: { interval: 'month', interval_count: 6, trial_period_days: 0 }
    },
    {
      label: t('YEARLY'),
      value: { interval: 'year', interval_count: 1, trial_period_days: 0 }
    }
  ]

  useEffect(() => {
    if (!data._id) return

    setServiceData({
      name: data.name,
      description: data.description,
      image: data.image,
      offerings: data.offerings || [],
      isRecurring: data.isRecurring,
      displayOnCatalog: data.displayOnCatalog,
      amount: data.amount * 0.01,
      advancedSettings: data.advancedSettings || {
        quota: {
          totalAllowedProject: 0,
          totalAllowedRequest: 0
        }
      }
    })

    setSelectedWorkflowOption(data.clientOnboarding || {})

    const cur = data.currency.toUpperCase()
    setSelectedCurrency({
      value: Currency[cur].code.toLowerCase(),
      label: Currency[cur].code,
      minimumChargeAmount: Currency[cur].minimum_charge_amount ?? 1
    })

    if (data.isRecurring) {
      const match = intervalPeriods.find(
        item =>
          item.value.interval === data.recurring.interval &&
          item.value.interval_count === data.recurring.interval_count
      )

      setRecurring({
        label: match?.label,
        value: data.recurring
      })
    } else {
      setRadioServiceType(1)
    }
  }, [data])

  useEffect(() => {
    if (serviceCategories.length) {
      setCategoryOptions(
        serviceCategories.map(item => ({ label: item.name, value: item._id }))
      )
    }
  }, [serviceCategories])

  useEffect(() => {
    if (categoryOptions.length && data._id) {
      const serviceCategoryId =
        typeof data.category === 'object' ? data.category._id : data.category
      const category =
        categoryOptions.find(item => item.value === serviceCategoryId) || {}
      setSelectedCategory(category)
    }
  }, [data, categoryOptions])

  useEffect(() => {
    if (!workflowFetched) dispatch(fetchAllWorkflowTemplates())
  }, [workflowFetched])

  // const workflowOptions = useMemo(() => {
  //   return onboardingData.map(data => ({
  //     label: data.templateName,
  //     value: data._id
  //   }))
  // }, [onboardingData])

  useEffect(() => {
    if (data._id) {
      const serviceAddOns = []
      addOnsData.forEach(addOn => {
        const match = addOn.services.find(serviceId => serviceId === data._id)
        if (match) serviceAddOns.push(addOn)
      })

      setSelectedAddOns(serviceAddOns)
      setServiceAddOns(serviceAddOns)
    }
  }, [data, addOnsData])

  const handleRadioChange = event => {
    const value = parseInt(event.target.value)
    setRadioServiceType(value)
    setServiceData(prev => ({
      ...prev,
      isRecurring: value === 0 ? true : false
    }))
  }

  const imageLoadHandler = e => {
    const file = e.target.files[0]
    fileToDataURL(file).then(res =>
      setServiceData(prev => ({ ...prev, image: res }))
    )
  }

  const handlePlanChange = e => {
    setRecurring(e)
  }

  const handleChange = e => {
    if (e.target.name === 'amount') {
      setServiceData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }))
    } else {
      setServiceData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
  }

  const handleCurrencyChange = newVal => {
    setSelectedCurrency(newVal)
    setSelectedAddOns([])
  }

  const handleOffering = (e, index) => {
    setServiceData(prev => ({
      ...prev,
      offerings: Object.assign([], serviceData.offerings, {
        [index]: e.target.value
      })
    }))
  }

  const handleOfferingChange = e => {
    setOffering(e.target.value)
  }

  const handleAddOffering = e => {
    e.preventDefault()

    if (serviceData.offerings.length >= 12) {
      dispatch(fireErrorToaster(t('ERROR_YOU_CAN_ADD_MAXIMUM_12_OFFERINGS')))
      return
    }

    setServiceData(prev => ({
      ...prev,
      offerings: [...prev.offerings, offering]
    }))

    setOffering('')
  }

  const handleDeleteOfferings = value => {
    const arr = serviceData.offerings.filter((item, index) => index !== value)
    setServiceData(prev => ({
      ...prev,
      offerings: arr
    }))
  }

  // const offeringChange = () => {
  //   if (offerings.length === 0 || serviceData.offerings.length > 4) {
  //     offeringInputRef.current.focus()
  //   } else {
  //     setServiceData((prev) => ({
  //       ...prev,
  //       offerings: [...prev.offerings, offerings]
  //     }))
  //     // setOfferings('')
  //   }
  // }

  const handleSaveServiceCallback = (res, error) => {
    onClose()
    if (error) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(t('SERVICE_CREATED_SUCCESSFULLY')))
    }
  }

  const addNewServiceHandler = () => {
    if (!serviceData.name || !serviceData.description) {
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS'))
      )
    }

    if (!selectedCurrency.value) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_SELECT_CURRENCY')))
    }

    if (serviceData.amount < selectedCurrency.minimumChargeAmount) {
      return dispatch(
        fireErrorToaster(
          t('ERROR_AMOUNT_CAN_NOT_BE_LESS_THAN', {
            data1: selectedCurrency.minimumChargeAmount,
            data2:
              currencyObj[selectedCurrency.value.toUpperCase()].symbol_native
          })
        )
      )
    }

    if (serviceData.isRecurring && !recurring.value) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_SELECT_INTERVAL')))
    }

    if (
      !serviceData.advancedSettings.quota.totalAllowedRequest ||
      serviceData.advancedSettings.quota.totalAllowedRequest < 0
    ) {
      return dispatch(fireErrorToaster(t('REQUEST_QUOTA_UPDATE_WARNING')))
    }

    setLoading(true)
    let body = {
      ...serviceData,
      advancedSettings: {
        quota: {
          totalAllowedProject:
            serviceData.advancedSettings.quota.totalAllowedProject || 0,
          totalAllowedRequest:
            serviceData.advancedSettings.quota.totalAllowedRequest
        }
      },
      currency: selectedCurrency.value,
      type: 'service',
      addons: selectedAddOns.map(item => item._id)
    }

    if (body.isRecurring) {
      body.recurring = recurring.value
    }

    if (selectedCategory.value) {
      body.category = selectedCategory.value
    }

    if (selectedWorkflowOption._id) {
      body.clientOnboarding = selectedWorkflowOption._id
    }

    const totalServices = userServicesData.length
    dispatch(
      createService({ data: body }, (res, err) => {
        handleSaveServiceCallback(res, err)
        if (!err && totalServices === 0) {
          dispatch(gettingAllTasksStatus())
          // fireMixpanelEvent({
          //   eventName: 'Setup Services'
          // })
        }
      })
    )
  }

  const updateServiceHandler = () => {
    if (!serviceData.name || !serviceData.description) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    if (serviceData.amount < 1) {
      dispatch(fireErrorToaster(t('ERROR_AMOUNT_CAN_NOT_BE_LESS_THAN_UNIT')))
      return
    }

    if (
      !serviceData.advancedSettings.quota.totalAllowedRequest ||
      serviceData.advancedSettings.quota.totalAllowedRequest < 0
    ) {
      return dispatch(fireErrorToaster(t('REQUEST_QUOTA_UPDATE_WARNING')))
    }

    setLoading(true)
    let updatedData = {
      addons: selectedAddOns
        .filter(addOn => {
          let match = serviceAddOns.find(item => item._id === addOn._id)
          return !Boolean(match)
        })
        .map(addOn => addOn._id),
      removeAddons: serviceAddOns
        .filter(addOn => {
          let match = selectedAddOns.find(item => item._id === addOn._id)
          return !Boolean(match)
        })
        .map(addOn => addOn._id),
      offerings: serviceData.offerings,
      field: 'update',
      advancedSettings: {
        quota: {
          totalAllowedProject:
            serviceData.advancedSettings.quota.totalAllowedProject || 0,
          totalAllowedRequest:
            serviceData.advancedSettings.quota.totalAllowedRequest
        }
      },
      clientOnboarding: selectedWorkflowOption._id
    }

    if (data.image !== serviceData.image) {
      updatedData.image = serviceData.image
    }

    if (data.name !== serviceData.name) {
      updatedData.name = serviceData.name
    }

    if (data.description !== serviceData.description) {
      updatedData.description = serviceData.description
    }

    if (data.amount !== serviceData.amount * 100) {
      updatedData.amount = serviceData.amount * 100
    }

    if (data.currency !== selectedCurrency.value) {
      updatedData.currency = selectedCurrency.value
    }

    if (data.category?._id !== selectedCategory.value) {
      updatedData.category = selectedCategory.value
    }

    if (data.displayOnCatalog !== serviceData.displayOnCatalog) {
      updatedData.displayOnCatalog = serviceData.displayOnCatalog
    }

    dispatch(
      updateService(
        { id: data._id, data: updatedData },
        handleUpdateServiceCallback
      )
    )
  }

  const handleUpdateServiceCallback = (res, err) => {
    setLoading(false)
    onClose()
    if (!err) {
      dispatch(fireSuccessToaster(t('SERVICE_CREATED_SUCCESSFULLY')))
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleFreeTrialChange = e => {
    const value = isNaN(parseInt(e.target.value))
      ? ''
      : parseInt(e.target.value)

    setRecurring(prev => ({
      ...prev,
      value: { ...prev.value, trial_period_days: value }
    }))
  }

  const handleCategoryChange = option => {
    setSelectedCategory(option)
  }

  const handleWorkflowChange = option => {
    setSelectedWorkflowOption(option)
  }

  const handleUpdateQuota = e => {
    let value = parseInt(e.target.value)

    if (isNaN(value)) value = ''
    if (value > 9999) value = 9999

    setServiceData(prev => ({
      ...prev,
      advancedSettings: {
        quota: {
          ...prev.advancedSettings.quota,
          [e.target.name]: value
        }
      }
    }))
  }

  return (
    <>
      <CustomModalBody>
        <div className="flex mb-6">
          {/* Image */}
          <div className="mr-6 mt-2">
            <CustomTooltip title={t('ADD_IMAGE_ICON')} placement="top">
              <div
                className="relative w-20 show-on-hover-parent cursor-pointer rounded overflow-hidden flex justify-center items-center"
                onClick={() => inputFileRef.current.click()}
              >
                <div
                  className="absolute top-0 left-0 w-full h-full z-10 show-on-hover-child flex justify-center items-center"
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                >
                  <PenIcon className="text-white" />
                </div>
                <img
                  src={
                    serviceData.image
                      ? serviceData.image.startsWith('https')
                        ? `${serviceData.image}?t=${Date.now()}`
                        : serviceData.image
                      : 'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'
                  }
                  alt="service icon"
                  className="w-full h-full object-cover"
                />

                <input
                  ref={inputFileRef}
                  accept="image/*"
                  style={{ display: 'none' }}
                  multiple={false}
                  type="file"
                  onChange={imageLoadHandler}
                />
              </div>
            </CustomTooltip>
          </div>

          <div className="flex-1">
            {/* Title */}
            <div className="mb-4">
              <FormLabel htmlFor="name" required>
                {t('NAME')}
              </FormLabel>

              <TextField
                name="name"
                value={serviceData.name}
                onChange={handleChange}
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder={t('ENTER_SERVICE_NAME')}
                id="name"
              />
            </div>

            {/* Description */}
            <div>
              <FormLabel required htmlFor="description">
                {t('DESCRIPTION')}
              </FormLabel>
              <RichTextEditor
                html={data.description}
                onChange={val =>
                  setServiceData(prev => ({ ...prev, description: val }))
                }
                placeholder={t('ENTER_DESCRIPTION')}
                options={{ floatingToolbar: true }}
              />
            </div>
          </div>
        </div>

        <div className="flex mb-4">
          <div className="flex-1 mr-4">
            <FormLabel>{t('SELECT_WORKFLOW')}</FormLabel>

            <Select
              styles={reactSelectCustomStyles(theme)}
              options={onboardingData}
              getOptionLabel={option => option.templateName}
              getOptionValue={option => option._id}
              onChange={handleWorkflowChange}
              value={selectedWorkflowOption}
              maxMenuHeight={120}
              placeholder={t('SELECT_WORKFLOW')}
            />
          </div>

          <div className="flex-1">
            <FormLabel>{t('SELECT_CATEGORY')}</FormLabel>

            <Select
              styles={reactSelectCustomStyles(theme)}
              options={categoryOptions}
              onChange={handleCategoryChange}
              value={selectedCategory}
              maxMenuHeight={120}
              placeholder={t('SELECT_CATEGORY')}
            />
          </div>
        </div>

        <div className="mb-6 flex">
          <div className="flex-1 mr-4">
            <FormLabel required>{t('SERVICE_TYPE')}</FormLabel>
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={radioServiceType}
              onChange={handleRadioChange}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    color={
                      themeForMode.mode === 'light' ? 'primary' : 'default'
                    }
                    disabled={Boolean(data._id)}
                    // disabled={serviceData.serviceType !== 'stripe' || data}
                  />
                }
                label={
                  <p className="text-smaller 2xl:text-sm dark:text-dark-light">
                    {t('RECURRING')}
                  </p>
                }
              />
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    disabled={Boolean(data._id)}
                    color={
                      themeForMode.mode === 'light' ? 'primary' : 'default'
                    }
                  />
                }
                label={
                  <p className="text-smaller 2xl:text-sm dark:text-dark-light">
                    {t('ONE_TIME')}
                  </p>
                }
              />
            </RadioGroup>
          </div>

          {serviceData.isRecurring ? (
            <div className="flex-1">
              <FormLabel htmlFor="offerings" required>
                {t('ADD_INTERVAL')}
              </FormLabel>

              <Select
                menuPosition="fixed"
                value={recurring}
                isSearchable={false}
                onChange={handlePlanChange}
                placeholder={t('BILLING_PERIOD')}
                options={intervalPeriods}
                className={classes.selectStyle}
                styles={reactSelectCustomStyles(theme)}
                isDisabled={Boolean(data._id)} //data true means update service modal
              />
            </div>
          ) : null}
        </div>

        <div className="mb-6">
          <div className="flex flex-row mt-5 gap-3">
            <SelectCurrency
              selectedCurrency={selectedCurrency}
              handleCurrencyChange={handleCurrencyChange}
            />
            <div>
              <FormLabel htmlFor="amount">{t('AMOUNT')}</FormLabel>
              <TextField
                name="amount"
                value={serviceData.amount}
                onChange={handleChange}
                type="number"
                className="hover:border-blue-500 border border-custom-gray-main p-2 rounded-md outline-none w-24"
                placeholder={t('AMOUNT')}
                id="amount"
                // disabled={Boolean(data)}
              />
            </div>
            {serviceData.isRecurring ? (
              <div>
                <FormLabel htmlFor="freeTrial">
                  {t('FREE_TRIAL_DAYS')}
                </FormLabel>
                <TextField
                  name="freeTrial"
                  value={recurring.value?.trial_period_days}
                  type="number"
                  onChange={handleFreeTrialChange}
                  className="hover:border-blue-500 border border-custom-gray-main p-2 rounded-md outline-none w-24"
                  placeholder={t('TRIAL_DAYS')}
                  id="freeTrial"
                  disabled={Boolean(data._id)}
                />
              </div>
            ) : null}

            {radioServiceType === 1 ? (
              <p className="self-end text-custom-gray-main mt-3 ml-2 text-xs dark:text-dark-light">
                ({t('BILLED_ONLY_ONCE')})
              </p>
            ) : null}
          </div>
        </div>

        <div className="mb-6">
          <div className="flex items-center">
            <FormLabel>{t('ATTACH_ADD_ONS')} </FormLabel>
            <CustomTooltip
              title={t('SERVICE_ATTACH_ADD_ONS_MESSAGE')}
              placement="top"
            >
              <InfoIcon className="ml-2 -mt-1 text-primary-main" />
            </CustomTooltip>
          </div>

          <Select
            styles={reactSelectCustomStyles(theme)}
            options={filteredAddOns}
            onChange={setSelectedAddOns}
            value={selectedAddOns}
            maxMenuHeight={120}
            getOptionLabel={option => option.name}
            getOptionValue={option => option._id}
            isMulti
          />
        </div>

        <div className="mb-6">
          <FormLabel htmlFor="offerings">
            {t('ADD_OFFERINGS')}{' '}
            <span className="font-normal ml-1 text-xs">
              ({t('MAXIMUM')} 12)
            </span>
          </FormLabel>
          <form onSubmit={handleAddOffering}>
            <div className="flex justify-between gap-2">
              <TextField
                name="offering"
                value={offering}
                onChange={handleOfferingChange}
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder={t('ENTER_SERVICE_OFFERING')}
                id="offerings"
              />
              <Button
                type="submit"
                disabled={offering.trim().length === 0}
                size="small"
                className="w-20"
              >
                {t('ADD')}
              </Button>
            </div>
          </form>
        </div>

        <div>
          {serviceData.offerings?.map((item, index) => (
            <div
              key={index}
              className="w-full border border-custom-gray-main p-2 hover:border-primary-main focus-within:border-primary-main rounded-md outline-none flex flex-row justify-between mt-3"
            >
              <input
                name="offerings"
                onChange={e => handleOffering(e, index)}
                value={item}
                className="text-sm flex-grow pr-4 dark:bg-dark-main2"
                id="offerings"
              />
              <button
                onClick={() => handleDeleteOfferings(index)}
                className="leading-3"
              >
                <CloseIcon
                  className="cursor-pointer text-gray-600"
                  fontSize="small"
                />
              </button>
            </div>
          ))}
        </div>

        <div className="flex mb-4">
          <div className="mr-4 flex-1">
            <FormLabel required>{t('REQUEST_QUOTA')}</FormLabel>
            <p className="text-smaller text-gray-600 mb-2">
              {t('REQUEST_QUOTA_MESSAGE')}
            </p>
            <TextField
              type="number"
              value={serviceData.advancedSettings.quota.totalAllowedRequest}
              onChange={handleUpdateQuota}
              fullWidth
              name="totalAllowedRequest"
            />
          </div>

          <div className="flex-1">
            <FormLabel>{t('PROJECT_CREATION_QUOTA')}</FormLabel>
            <p className="text-smaller text-gray-600 mb-2">
              {t('PROJECT_QUOTA_MESSAGE')}
            </p>
            <TextField
              type="number"
              value={serviceData.advancedSettings.quota.totalAllowedProject}
              onChange={handleUpdateQuota}
              fullWidth
              name="totalAllowedProject"
            />
          </div>
        </div>

        <div className="flex items-center mt-3">
          <CheckBoxField
            onChange={e =>
              setServiceData(prev => ({
                ...prev,
                displayOnCatalog: e.target.checked
              }))
            }
            checked={serviceData.displayOnCatalog}
            label={
              <span className="pb-1 text-gray-500 py-1 dark:text-dark-light">
                {t('SHOW_IN_CATALOG')}
              </span>
            }
          />

          <QuestionMarkTooltip
            text={t('THIS_SERVICE_WILL_BE_DISPLAYED')}
            direction="right"
            info={true}
          />
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          onClick={data._id ? updateServiceHandler : addNewServiceHandler}
          loading={loading}
        >
          {data._id ? t('SAVE_CHANGES') : t('CREATE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

// const getIntervalLabel = (interval, intervalCount) => {
//   if (interval === 'year') return 'Yearly'
//   return intervalCount === 1
//     ? t('MONTHLY')
//     : intervalCount === 3
//     ? t('QUARTERLY')
//     : t('SEMI_ANNUALLY')
// }

const SelectCurrency = ({ selectedCurrency, handleCurrencyChange }) => {
  const theme = useTheme()
  const [currencyOptions, setCurrencyOptions] = useState([])

  useEffect(() => {
    let arr = Object.keys(currencyObj).map(el => ({
      value: el.toLowerCase(),
      label: el,
      minimumChargeAmount: currencyObj[el].minimum_charge_amount ?? 1
    }))
    setCurrencyOptions(arr)
  }, [])

  return (
    <>
      <div>
        <FormLabel htmlFor="currency">{t('CURRENCY')}</FormLabel>
        <div className="w-28">
          <Select
            styles={reactSelectCustomStyles(theme, {
              control: {
                height: 36,
                minHeight: 36
              }
            })}
            options={currencyOptions}
            onChange={handleCurrencyChange}
            value={selectedCurrency}
            maxMenuHeight={120}
            menuPlacement="top"
          />
        </div>
      </div>
    </>
  )
}

export default NewService
