import { CHAT_WINDOW_COLOR } from 'constants/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chat } from 'static/Images'

const NoChatsFound = () => {
  const { t } = useTranslation()
  return (
    <div
      className="w-full h-full flex flex-col justify-center text-center"
      style={{ backgroundColor: CHAT_WINDOW_COLOR }}
    >
      <div>
        <img src={Chat} alt="chat" className="m-auto w-28 mb-4" />
        <span className="font-semibold dark:text-dark-light">
          {t('CHAT_COMPONENT_MSG')}
        </span>
      </div>
    </div>
  )
}

export default NoChatsFound
