import React from 'react'
import { Skeleton } from '@material-ui/lab'

const LoadingAnimation = () => {
  return (
    <>
      {new Array(3).fill('').map((item, index) => (
        <div key={index} className="flex items-start space-x-4 mb-4">
          <Skeleton
            width={40}
            height={40}
            variant="circle"
            style={{ flexShrink: 0 }}
          />
          <div className="w-full leading-3">
            <Skeleton variant="text" height={20} className="w-20" />
            <Skeleton variant="text" height={20} className="w-40" />
          </div>
        </div>
      ))}
    </>
  )
}

export default LoadingAnimation
