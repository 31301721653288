import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useWorkspacePermission } from 'customHooks'
import {
  createSubTask,
  updateSubTaskDetails,
  updateSubTaskTeammate
} from 'thunks/subTask/actions'
import { FieldLabel } from './TaskPanel'
import {
  fireErrorToaster
  // fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { CustomFlag, CustomTooltip } from 'global/globalComponents'
import { ColoredAvatarGroup } from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { Add, CheckCircle } from '@material-ui/icons'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { format } from 'date-fns'
import { Button } from '@material-ui/core'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import CollabDrowdown from './CollabDrowdown'
import { userRoles } from 'utils'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined'
import clsx from 'clsx'
// import { useParams } from 'react-router-dom'

const Subtasks = ({
  workspaceId,
  boardId,
  taskId,
  // taskStatuses,
  taskTitle,
  onSubtaskClick,
  innerProjectPermission,
  socketId,
  currentWorkspace,
  currentBoard
}) => {
  const { t } = useTranslation()
  const allSubtasks = useSelector(state => state.subTasks.data)
  const dispatch = useDispatch()
  const [addSubtask, setAddSubtask] = useState(false)
  const [subtaskTitle, setSubtaskTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [subtasks, setSubtasks] = useState([])

  useEffect(() => {
    setSubtasks(
      allSubtasks.sort((a, b) => (a.orderIndex || 0) - (b.orderIndex || 0))
    )
  }, [allSubtasks])

  const handleAddSubtaskClick = () => {
    setAddSubtask(true)
  }

  const handleCreateSubtask = e => {
    e.preventDefault()

    if (subtaskTitle.trim() !== '') {
      setLoading(true)

      const lastSubtask = subtasks[subtasks.length - 1]

      dispatch(
        createSubTask(
          {
            data: {
              workSpace: workspaceId,
              workspaceBoard: boardId,
              title: subtaskTitle.trim(),
              task: taskId,
              taskSection: currentBoard.boardSection[0]._id,
              socketId: socketId,
              orderIndex: lastSubtask ? (lastSubtask.orderIndex || 0) + 1 : 0
            }
          },
          createSubTaskCallback
        )
      )
    } else {
      setAddSubtask(false)
    }
  }

  const createSubTaskCallback = (res, err) => {
    setLoading(false)

    if (!err) {
      setAddSubtask(false)
      setSubtaskTitle('')
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleSubtaskChange = e => {
    setSubtaskTitle(e.target.value)
  }

  const updateSubtaskStatus = (subtaskId, data) => {
    dispatch(
      updateSubTaskDetails(
        {
          subtaskId: subtaskId,
          data: {
            ...data,
            workSpace: currentWorkspace._id
          },
          updateField: 'markAsComplete'
        },
        updateSubtaskCallback
      )
    )
  }

  const updateSubtaskCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    }
  }

  const onDragEnd = result => {
    const { destination, source, draggableId } = result

    if (!destination) return
    if (destination.index === source.index) return

    const subtasksArr = [...subtasks]
    const removedItem = subtasksArr.splice(source.index, 1)
    subtasksArr.splice(destination.index, 0, removedItem[0])
    setSubtasks(subtasksArr)

    const aboveTask = subtasksArr[destination.index - 1]
    const belowTask = subtasksArr[destination.index + 1]
    let newOrderIndex

    if (!aboveTask) {
      newOrderIndex = (belowTask.orderIndex || 0) - 1
    }

    if (!belowTask) {
      newOrderIndex = (aboveTask.orderIndex || 0) + 1
    }

    if (aboveTask && belowTask) {
      newOrderIndex = ((aboveTask.orderIndex || 0) + belowTask.orderIndex) / 2
    }

    dispatch(
      updateSubTaskDetails({
        subtaskId: draggableId,
        data: {
          orderIndex: newOrderIndex,
          workSpace: removedItem[0].workSpace
        }
      })
    )
  }

  return (
    <>
      <div className="px-6 pt-2">
        <div className="mb-2">
          <FieldLabel>{t('SUBTASKS')}</FieldLabel>
        </div>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="subtasks">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {subtasks.map((item, idx, arr) => (
                    <SingleSubtask
                      subtaskData={item}
                      key={item._id}
                      total={arr.length}
                      index={idx}
                      innerProjectPermission={innerProjectPermission}
                      currentWorkspace={currentWorkspace}
                      currentBoard={currentBoard}
                      // taskData={taskData}
                      taskTitle={taskTitle}
                      onSubtaskClick={onSubtaskClick}
                      updateSubtaskStatus={updateSubtaskStatus}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div className="mb-2">
        {addSubtask && (
          <div className="bg-blue-50 dark:bg-dark-main px-8">
            <form onSubmit={handleCreateSubtask}>
              <div className="flex h-8 items-center justify-between">
                <div className="flex-1">
                  <input
                    className="w-full text-smaller dark:bg-dark-main dark:text-white"
                    type="text"
                    onBlur={handleCreateSubtask}
                    onChange={handleSubtaskChange}
                    autoFocus
                    style={{ background: 'transparent' }}
                  />
                </div>

                <button
                  className="border rounded text-xs py-1 px-2 border-primary-main text-primary-main font-medium hover:bg-primary-main hover:text-white w-12 text-center ml-4"
                  disabled={loading}
                >
                  {loading ? '...' : 'Save'}
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="mt-2 px-6">
          <Button
            variant="outlined"
            startIcon={<Add fontSize="small" />}
            size="small"
            onClick={handleAddSubtaskClick}
            disabled={
              !innerProjectPermission.create || currentWorkspace?.isComplete
            }
          >
            {t('ADD_SUBTASK')}
          </Button>
        </div>
      </div>
    </>
  )
}

const SingleSubtask = ({
  subtaskData,
  index,
  total,
  innerProjectPermission,
  currentWorkspace,
  currentBoard,
  // taskData,
  taskTitle,
  onSubtaskClick,
  updateSubtaskStatus
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [subtask, setSubtask] = useState({ ...subtaskData })
  const [anchorEl, setAnchorEl] = useState(null)
  const collaborators = useMemo(() => {
    return [...subtask.assignedAgencyTeam, ...subtask.assignedClientTeam]
  }, [subtask.assignedAgencyTeam, subtask.assignedClientTeam])
  const subtaskStatus = useMemo(() => {
    return (
      currentBoard.boardSection?.map(
        item => item._id === subtaskData.taskSection
      ) ?? []
    )
  }, [currentBoard.boardSection, subtaskData.taskSection])
  const [collabOptions, setCollabOptions] = useState([])

  useEffect(() => {
    setSubtask({ ...subtaskData })
  }, [subtaskData])

  useEffect(() => {
    let arr = []
    if (
      currentWorkspace.assignedAgencyTeam &&
      currentWorkspace.assignedClientTeam
    ) {
      currentWorkspace.assignedAgencyTeam.forEach(item => {
        const idx = [
          ...subtaskData.assignedAgencyTeam,
          ...subtaskData.assignedClientTeam
        ].findIndex(mate => mate._id === item.user._id)
        if (idx === -1) arr.push(item)
      })
    }

    setCollabOptions(arr)
  }, [
    currentWorkspace.assignedAgencyTeam,
    currentWorkspace.assignedClientTeam,
    subtaskData
  ])

  // const handleSubtaskClick = () => {
  //   if (currentWorkspace?.isComplete) {
  //     dispatch(
  //       fireErrorToaster(
  //         t('ERROR_TASKSTATE_BELONGS_TO_A_COMPLETE_WORKSPACE', {
  //           data: taskTitle
  //         })
  //       )
  //     )
  //   } else {
  //     innerProjectPermission.update || innerProjectPermission.view
  //       ? onSubtaskClick(subtask)
  //       : dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
  //   }
  // }

  const handleToggleComplete = e => {
    e.stopPropagation()
    let data
    if (!subtask.markAsComplete) {
      const completeSection = subtask.statuses?.find(
        item => item.label.toLowerCase() === 'completed'
      )
      data = { markAsComplete: true }
      if (completeSection) {
        data = { ...data, taskSection: completeSection._id }
      }
      updateSubtaskStatus(subtask._id, data)
      setSubtask(prev => ({
        ...prev,
        ...data
      }))
    }
  }

  const handleAddCollaborator = teammate => {
    const data =
      teammate.role < userRoles.USER_CLIENT
        ? { assignedAgencyTeam: teammate.user._id }
        : { assignedClientTeam: teammate.user._id }

    dispatch(
      updateSubTaskTeammate({ id: subtask._id, data }, (res, err) => {
        if (err) dispatch(fireErrorToaster(res))
        handleClosePopper()
      })
    )
  }

  const handleClosePopper = () => setAnchorEl(null)
  const handleOpenPopper = e => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <Draggable draggableId={subtaskData._id} index={index}>
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <div
              className={clsx(
                'flex items-center border-t border-gray-200',
                total - 1 === index && 'border-b'
              )}
            >
              <div {...provided.dragHandleProps} className="leading-3">
                <span>
                  <DragIndicatorOutlinedIcon
                    fontSize="small"
                    className="text-gray-400 dark:text-dark-light"
                  />
                </span>
              </div>
              <div
                className="w-full bg-white dark:bg-dark-main3 flex flex-row h-9 items-center pr-2 pl-1 justify-between cursor-pointer"
                onClick={() => onSubtaskClick(subtask)}
              >
                <div className="flex items-center justify-start overflow-x-hidden">
                  <div className="leading-none">
                    <button onClick={handleToggleComplete}>
                      {subtaskStatus?.label?.toLowerCase() === 'completed' ||
                      subtask.markAsComplete ? (
                        <CustomTooltip placement="top" title={t('COMPLETE')}>
                          <CheckCircle
                            fontSize="small"
                            className="mr-2 text-primary-main"
                          />
                        </CustomTooltip>
                      ) : (
                        <CustomTooltip
                          placement="top"
                          title={t('MARK_COMPLETE')}
                        >
                          <CheckCircleIcon className="mr-2 text-gray-600 hover:text-primary-main w-4 h-4 dark:text-dark-light" />
                        </CustomTooltip>
                      )}
                    </button>
                  </div>
                  <p
                    className={`text-smaller truncate dark:text-dark-light ${
                      subtaskStatus?.label?.toLowerCase() === 'completed' ||
                      subtask.markAsComplete
                        ? 'text-gray-400'
                        : ''
                    }`}
                  >
                    {subtask.title}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  {subtask.priority && subtask.priority !== 5 && (
                    <div className="mr-2">
                      <CustomFlag priority={subtask.priority} />
                    </div>
                  )}
                  <ColoredAvatarGroup users={collaborators} size="medium" />
                  <button
                    onClick={handleOpenPopper}
                    className="w-5 h-5 border border-dashed border-gray-400 rounded-full flex justify-center items-center"
                  >
                    <PlusIcon className="w-3 h-3 dark:text-dark-light" />
                  </button>
                  <p className="self-center ml-2 text-xs text-custom-gray-main dark:text-dark-light">
                    {format(new Date(subtask.createdAt), 'MMM d')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
      <CollabDrowdown
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopper}
        list={collabOptions}
        handleListItemClick={handleAddCollaborator}
        getUser={listItem => ({ ...listItem.user, role: listItem.role })}
      />
    </>
  )
}

export default React.memo(Subtasks)
