import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from 'static/svg/check.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { ReactComponent as LockIcon } from 'static/svg/lock.svg'

const UserActionArea = ({
  myResponse,
  previousStage,
  handleSaveUserResponse
}) => {
  const [loading, setLoading] = useState(null)
  const { t } = useTranslation()

  const handleSubmitResponse = response => {
    setLoading(response)
    handleSaveUserResponse(response, (res, err) => {
      setLoading(null)
    })
  }

  let elm = null
  if (myResponse === 'approved') {
    elm = (
      <div className="flex items-center rounded text-smaller mt-2 bg-green-100 dark:bg-dark-main4 text-green-600 p-2">
        <CheckIcon className="w-4 h-4 mr-1" />
        <span>{t('APPROVED_BY_YOU')}</span>
      </div>
    )
  } else if (myResponse === 'rejected') {
    elm = (
      <div className="flex items-center rounded text-smaller mt-2 text-red-500 bg-red-100 dark:bg-dark-main4 p-2">
        <CrossIcon className="w-5 h-5 mr-2" />
        <span>{t('REJECTED_BY_YOU')}</span>
      </div>
    )
  } else {
    if (!previousStage || previousStage.status === 'approved') {
      elm = (
        <>
          <hr className="mt-2 mb-4" />
          <div className="flex items-center justify-end">
            <button
              onClick={() => handleSubmitResponse('approved')}
              className="flex items-center text-green-500 text-smaller font-medium border border-green-500 px-2 py-1 rounded mr-2"
            >
              {loading === 'approved' ? (
                'Please wait...'
              ) : (
                <>
                  <CheckIcon className="w-4 h-4 mr-1" />
                  <span>{t('APPROVE')}</span>
                </>
              )}
            </button>

            <button
              onClick={() => handleSubmitResponse('rejected')}
              className="flex items-center text-red-500 text-smaller font-medium border border-red-500 px-2 py-1 rounded"
            >
              {loading === 'rejected' ? (
                'Please wait...'
              ) : (
                <>
                  <CrossIcon className="w-4 h-4 mr-1" />
                  <span>{t('REJECT')}</span>
                </>
              )}
            </button>
          </div>
        </>
      )
    } else {
      elm = (
        <div className="flex items-center rounded mt-2 bg-gray-100 dark:bg-dark-main4 p-2">
          <LockIcon className="w-5 h-5 text-gray-600" />
          <span className="ml-2 text-smaller">
            {t('APPROVAL_CANNOT_TAKE_ACTION')}
          </span>
        </div>
      )
    }
  }

  return elm
}

export default UserActionArea
