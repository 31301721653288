import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as StartTimer } from 'static/svg/play.svg'
import { ReactComponent as StopTimer } from 'static/svg/stop.svg'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import { totalTimeOnTask, totalTimeOnSubtask } from 'thunks/Tracking/action'
import useTimerFunctions from './useTimerFunctions'
import TimeTrackingPopup from './TimeTrackingPopup'
// import theme from 'themes/themes'
import { useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

const PanelTimer = ({ task, elmFor, canUpdate }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
    // fetched: activeTimerFetched,
    data: timerData,
    taskTimer,
    subtaskTimer
  } = useSelector(state => state.timer)
  const taskTimerData = elmFor === 'task' ? taskTimer : subtaskTimer
  const { activeTimer, isPaused: timerIsPaused } = timerData

  const [time, setTime] = useState({
    seconds: '00',
    minutes: '00',
    hours: '00'
  })
  const [timeTrackingAnchor, setTimeTrackingAnchor] = useState(null)
  const [isActive, setIsActive] = useState(false)
  const [totalDuration, setTotalDuration] = useState(0)
  const timerTask = useMemo(() => {
    if (activeTimer) {
      if (activeTimer.type === 'task') return activeTimer.task
      return activeTimer.subTask
    }
    return {}
  }, [activeTimer])

  const {
    getTimeFromSeconds,
    startTimer,
    stopTimer,
    getTime
  } = useTimerFunctions()

  useEffect(() => {
    if (timerTask) {
      setIsActive(timerTask._id === task._id && !timerIsPaused)
    }
  }, [task, timerTask, timerIsPaused])

  useEffect(() => {
    if (!task._id || taskTimerData.loading) return
    if (activeTimer && timerTask._id === task._id) return

    // Setting task recorded time
    let timerDuration = taskTimerData.data.reduce(
      (total, curr) => total + curr.time,
      0
    )
    const totalTime = getTimeFromSeconds(timerDuration / 1000)

    setTotalDuration(timerDuration)
    setTime(totalTime)
  }, [taskTimerData])

  useEffect(() => {
    if (task._id) {
      getTotalTimeTracking() // fetching time intervals
    }
  }, [task?._id, elmFor])

  const getTotalTimeTracking = () => {
    elmFor === 'subtask'
      ? dispatch(totalTimeOnSubtask(task._id))
      : dispatch(totalTimeOnTask(task._id))
  }

  const handleStartTimer = () => {
    if (!canUpdate) {
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return false
    }

    let data = {
      workspace: task.workSpace,
      workspaceBoard: task.workspaceBoard,
      type: elmFor
    }

    if (elmFor === 'task') {
      data.task = task._id
    } else {
      data.subTask = task._id
      data.task = task.task
    }

    startTimer(data, task.title)
  }

  const handleStopTimer = () => {
    if (!canUpdate) {
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return false
    }

    let data = { type: elmFor }

    if (elmFor === 'task') {
      data.task = task._id
    } else {
      data.subTask = task._id
      data.task = task.task
    }

    stopTimer(data, task.title)
  }

  const toggleTimeTrackingPopup = e => {
    if (!canUpdate) {
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return false
    }

    if (timeTrackingAnchor) {
      setTimeTrackingAnchor(null)
    } else {
      setTimeTrackingAnchor(e.currentTarget)
    }
  }

  return (
    <div>
      <p
        className="text-extraSmaller md:text-xs text-gray-500 cursor-pointer dark:text-dark-light"
        onClick={toggleTimeTrackingPopup}
      >
        {t('TIME_TRACKED')}
      </p>
      <div className="flex items-center space-x-1">
        <div
          className="flex items-center cursor-pointer"
          style={{
            flexDirection: 'row-reverse',
            fontSize: '12px',
            borderRadius: '10px',
            fontWeight: 500
          }}
        >
          <Timer
            time={time}
            toggleTimeTrackingPopup={toggleTimeTrackingPopup}
            getTime={getTime}
            setTime={setTime}
            setTotalDuration={setTotalDuration}
            getTimeFromSeconds={getTimeFromSeconds}
            activeTimer={activeTimer}
            timerIsPaused={timerIsPaused}
            taskId={task._id}
            timerTaskId={timerTask._id}
          />
          <div
            style={{
              borderRadius: '50%',
              backgroundColor: isActive
                ? '#fd7179'
                : theme.palette.primary.main,
              cursor: 'pointer',
              padding: '4px'
            }}
            onClick={isActive ? handleStopTimer : handleStartTimer}
          >
            <CustomTooltip
              title={isActive ? t('STOP_TIMER') : t('START_TIMER')}
              placement="top"
            >
              {isActive ? (
                <StopTimer className="h-1.5 w-1.5 text-white" />
              ) : (
                <StartTimer className="h-1.5 w-1.5 text-white" />
              )}
            </CustomTooltip>
          </div>
        </div>
      </div>

      {canUpdate && (
        <TimeTrackingPopup
          anchorEl={timeTrackingAnchor}
          open={Boolean(timeTrackingAnchor)}
          onClose={toggleTimeTrackingPopup}
          time={time}
          isActive={isActive}
          setIsActive={setIsActive}
          startTimer={handleStartTimer}
          stopTimer={handleStopTimer}
          elmFor={elmFor}
          task={task}
          totalTimeTracking={getTotalTimeTracking}
          totalDuration={totalDuration}
        />
      )}
    </div>
  )
}

const Timer = ({
  time,
  toggleTimeTrackingPopup,
  getTime,
  setTime,
  setTotalDuration,
  getTimeFromSeconds,
  activeTimer,
  timerIsPaused,
  taskId,
  timerTaskId
}) => {
  const [timer, setTimer] = useState({
    seconds: '00',
    minutes: '00',
    hours: '00'
  })

  useEffect(() => {
    // if current task doesn't have any active timer
    if (activeTimer && timerTaskId === taskId) {
      let timerId = '',
        timerDuration =
          activeTimer.type === 'task'
            ? activeTimer.task.timerDuration ?? 0
            : activeTimer.subTask.timerDuration ?? 0

      if (!timerIsPaused) {
        timerId = getTime(activeTimer.start, timerDuration, setTimer)
      } else {
        // if timer is paused
        clearInterval(timerId)
        const totalTime = getTimeFromSeconds(timerDuration / 1000)
        setTime(totalTime)
        setTotalDuration(timerDuration)
      }

      return () => clearInterval(timerId)
    }
  }, [taskId, activeTimer, timerIsPaused])

  useEffect(() => {
    setTimer(time)
  }, [time, timerIsPaused])

  return (
    <div
      className="text-extraSmaller md:text-xs mx-2 dark:text-dark-light"
      onClick={toggleTimeTrackingPopup}
    >
      <span>{timer.hours}</span>
      <span>:</span>
      <span>{timer.minutes}</span>
      <span>:</span>
      <span>{timer.seconds}</span>
    </div>
  )
}

export default PanelTimer
