import axios from 'axios'
import { CLIENT } from 'thunks/addClient/reducers'
import { WORKSPACES } from 'thunks/workspace/reducers'
import { TEAMMATES } from './reducers'
// import { storeToken } from 'utils/userLogin'
import { LOGIN } from '../login/reduers'
import { PULSE } from 'thunks/pulse/reducer'
import {
  catchAsync,
  catchAsyncDispatch,
  storeAccessToken,
  storeRefreshToken,
  userRoles
} from 'utils'

// /**
//  * Invite a user by email
//  * @param {String} email User email
//  * @param {Function} callback callback function
//  */
// export const inviteTeammate = async (email, callback) => {
//   try {
//     const res = await axios({
//       method: 'POST',
//       url: '/user/inviteteammate',
//       data: {
//         email
//       }
//     })
//     if (callback) callback(res.data, false)
//   } catch (err) {
//     if (callback)
//       callback(
//         err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//         true
//       )
//   }
// }

/**
 * Re Invite User Teammate
 * @param {String} id teammate id
 * @param {Function} callback callback function
 */
export const reInviteTeammate = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/user/teammate/resend/invitation/${data.teammateId}`
  })
  if (callback) callback(res.data)
})

/**
 * Add a new teammate
 * @param {Object} data Teammate data
 * @param {Function} callback Callback function (res/errorMessage,error status)
 */
export const addTeammate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    data.body.isTeammate = true

    const res = await axios({
      method: 'POST',
      url: '/user/addTeammate',
      data: data.body
    })

    if (callback) callback(res.data)
    if (!data.isAgencyAddingClientTeammate) {
      dispatch({
        type: TEAMMATES.ADD_ONE_TEAMMATE,
        payload: {
          ...res.data,
          assignedWorkspace: data.assignedWorkspace,
          verticals: res.data.profile?.verticals ?? []
        }
      })
    }
  }, callback)
}

/**
 * Fetches all teammates of user
 * @param {Object | null} data
 * @param {Function} callback Callback function to
 */
export const fetchTeammateOfCurrentUser = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const state = getState()

    const res = await axios({
      method: 'GET',
      url: '/user/teammate'
    })

    // filtering me data and super admin
    const filteredResponse = res.data.filter(
      item =>
        item._id !== state.me.data._id && item.role !== userRoles.USER_AGENCY
    )

    if (callback) callback(filteredResponse)

    dispatch({ type: TEAMMATES.FETCHED, payload: filteredResponse })
    dispatch({
      type: PULSE.CREATE_USERS_PULSE,
      payload: filteredResponse.map(item => ({
        _id: item._id,
        lastActive: item.lastActive
      }))
    })
  }, callback)
}

/**
 * Fetches all teammates of user including super admin
 * @param {Object | null} data
 * @param {Function} callback Callback function to
 */
export const fetchAllAgencyTeammates = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/user/teammate'
  })

  if (callback) callback(res.data)
})

/**
 * Move teammate to trash
 * @param {String} data.id User id of deleting teammate
 * @param {Function} callback Callback function (err/response, err status)
 */
export const moveTeammateToTrash = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { me } = getState()

    const res = await axios({
      method: 'PUT',
      url: `/user/${data.id}`,
      data: {
        isDeleted: true,
        deletedOn: new Date().toISOString(),
        deletedBy: me.data._id
      }
    })
    if (callback) callback(res.data, false)
    dispatch({ type: TEAMMATES.DELETE_ONE_TEAMMATE, payload: res.data })
    dispatch({
      type: WORKSPACES.UPDATE_MANY_WORKSPACES,
      payload: { id: res.data._id, key: 'assignedAgencyTeam' }
    })
    dispatch({ type: CLIENT.UPDATE_MANY_CLIENTS, payload: res.data._id })
  }, callback)
}

/**
 * Delete a teammate.
 * @param {Object} props - id, data, callback
 * @param {String} props.id - user id
 * @param {Object | undefined} props.data - transferFileAssets (optional)
 * @param {Function} props.callback callback function optional
 */

export const deleteTeammate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/user/${data.id}`,
      data: data.data
    })

    if (callback) callback(res.data)

    dispatch({ type: TEAMMATES.DELETE_ONE_TEAMMATE, payload: res.data })
    dispatch({
      type: WORKSPACES.UPDATE_MANY_WORKSPACES,
      payload: { id: res.data._id, key: 'assignedAgencyTeam' }
    })
    dispatch({ type: CLIENT.UPDATE_MANY_CLIENTS, payload: res.data._id })
  }, callback)
}

/**
 * update teammate's active status.
 * @param {String} data.id Teammate id
 * @param {Object} data.data isACtive
 * @param {Function} callback callback function
 */
export const updateTeammateStatus = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/user/${data.id}`,
      data: data.data
    })

    dispatch({
      type: TEAMMATES.UPDATE_ONE_TEAMMATE,
      payload: { _id: res.data._id, isActive: res.data.isActive }
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * update teammate.
 * @param {String} id teammate id
 * @param {Object} data.body workspaces id
 * @param {Function} callback  callback function
 */

export const updateTeammate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/agencyprofile/agency/${data.id}`,
      data: data.body
    })

    if (callback) callback(res.data)
    dispatch({ type: TEAMMATES.UPDATE_ONE_TEAMMATE, payload: res.data })
  }, callback)
}

/**
 * Approve teammate signup request.
 * @param {String} id teammate id
 * @param {Object} data {status: "approved"}
 * @param {Function} callback  callback function
 */
export const approveSignupRequest = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/agencyprofile/agency/${data.teammateId}`,
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: TEAMMATES.APPROVE_SIGNUP_REQUEST, payload: res.data })
  }, callback)
}
/**
 * Reject teammate signup request.
 * @param {String} data.teammateId teammate id
 * @param {Object} data workspaces id
 * @param {Function} callback  callback function
 */
export const rejectSignupRequest = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/user/${data.teammateId}`
    })

    if (callback) callback(res.data)
    dispatch({ type: TEAMMATES.REJECT_SIGNUP_REQUEST, payload: res.data })
  }, callback)
}

/**
 * update client teammate.
 * @param {String} id teammate id
 * @param {Object} data workspaces id
 * @param {Function} callback  callback function
 */

export const updateClientTeammate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/clientprofile/client/${data.id}`,
      data: data.body
    })
    dispatch({ type: TEAMMATES.UPDATE_ONE_TEAMMATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch all project's analytics
 * @param {Object} data name, email, permission, password, agencyOwner
 * @param {Function} callback
 */
export const teammateSignup = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/user/addTeammate/public',
      data: data.data,
      isPublic: true
    })

    storeAccessToken(res.data.login.token.accessToken)
    storeRefreshToken(res.data.login.token.refreshToken)
    dispatch({ type: LOGIN.LOGGED_IN })
  }, callback)
}

/**
 * fetch all deleted teammates
 * @param {Object|null} data
 * @param {Function} callback callback function
 */
export const fetchDeletedTeammates = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/user/trash?type=teammate'
  })

  // Taking only the required data
  const transformedData = res.data.map(item => ({
    _id: item._id,
    name: item.name,
    deletedOn: item.deletedOn,
    deletedBy: item.deletedBy
  }))

  if (callback) callback(transformedData)
})

/**
 * Restore deleted teammate
 * @param {Function} data.teammateId
 * @param {Function} callback callback function
 */
export const restoreDeletedTeammate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/user/${data.teammateId}`,
      data: {
        isDeleted: false,
        deletedOn: null,
        deletedBy: null
      }
    })
    dispatch({
      type: TEAMMATES.ADD_ONE_TEAMMATE,
      payload: {
        ...res.data,
        assignedWorkspace: res.data.profile?.assignedWorkspace ?? [],
        verticals: res.data.profile?.verticals ?? []
      }
    })

    if (callback) callback(res.data)
  }, callback)
}
