import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { agencyUserLogin, setUserLoggedOut } from 'thunks/login/actions'
// import useTitle from 'customHooks/useTitle'
import {
  LoadingButton,
  RoundedTextInput,
  PatternScreen,
  PasswordField
  // Loader
} from 'global/globalComponents'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import clientVenueAssets from 'static/clientVenueAssets'
import globalCustomStyles from 'global/stlyeClasses/GlobalCustomStyles'
import { useTranslation } from 'react-i18next'
import { CV_HOST } from 'constants/constantKeys'

const LoginCopy = () => {
  const { t } = useTranslation()
  const classes = globalCustomStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  // const [agencyInfo, setAgencyInfo] = useState({
  //   brandName: '',
  //   favicon: '',
  //   smallLogo: '',
  //   largeLogo: ''
  // })
  const { loggedIn } = useSelector(state => state.login)
  const { companyInfo } = useSelector(state => state.generalAppState)
  const isBasicLayoutActive =
    companyInfo.data.pageCustomization?.layout === 'basic'
  const brandName = companyInfo.data.isWhiteLabelActive
    ? companyInfo.data.brand?.brandName
    : clientVenueAssets.brandName

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    agencyDomain:
      process.env.NODE_ENV === 'development'
        ? 'roni.clientvenue.com'
        : window.location.host
  })

  useEffect(() => {
    if (loggedIn) {
      // If user is logged in and at this route then manually logging him out
      // Reason: If user is logged in that means me data already exists in that case
      // if he logs in again me data won't fetch. Which is fine if he logs in with the same
      // credentials but if he changes it for some reason for example agency to client or vise versa
      // then he won't be able to login until he refreshes the browser.
      dispatch(setUserLoggedOut())
    }
  }, [])

  useEffect(() => {
    if (window.location.href.split('//')[1].startsWith(CV_HOST)) {
      history.push('/login/domain')
    }
    // dispatch(
    //   getAgencyInfoByDomain((res, err) => {
    //     if (!err) {
    //       setAgencyInfo(
    //         res.brand?.smallLogo || res.brand?.largeLogo || res.brand?.favicon
    //           ? res.brand
    //           : clientVenueAssets
    //       )
    //     } else {
    //       setAgencyInfo(clientVenueAssets)
    //     }
    //   })
    // )
  }, [])

  const loginHandler = e => {
    e.preventDefault()
    setLoading(true)
    dispatch(agencyUserLogin({ data: formData }, postLoginAction))
  }

  const formInputHandler = e => {
    setFormData(data => ({ ...data, [e.target.name]: e.target.value }))
  }

  const postLoginAction = (res, err) => {
    if (!err) {
      const searchParams = new URLSearchParams(window.location.search)
      const redirectTo = searchParams.get('redirectUrl') || res.path
      history.push(redirectTo)
      dispatch(fireSuccessToaster(t('LOGIN_SUCCESSFUL')))
    } else {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    }
  }

  return (
    <PatternScreen customize={true}>
      <div>
        <header className="mb-8">
          {isBasicLayoutActive ? (
            <div className="text-center">
              <div className="text-primary-main text-4xl font-bold mb-2">
                {brandName}
              </div>
              <div className="text-xl font-semibold text-gray-700">
                {t('WELCOME_BACK')} 👋
              </div>
            </div>
          ) : (
            <h1 className="text-4xl font-bold">
              {t('WELCOME_BACK')} <br />
              {t('TO')} <span className="text-primary-main">{brandName}</span>
            </h1>
          )}
        </header>

        <div>
          <form onSubmit={loginHandler}>
            <div className="mb-4">
              <FormLabel htmlFor="email">{t('EMAIL_ADDRESS')}</FormLabel>
              <RoundedTextInput
                type="email"
                id="email"
                placeholder={t('ENTER_YOUR_BUSINESS_EMAIL_ADDRESS')}
                name="email"
                onChange={formInputHandler}
                value={formData.email}
                className={classes.inputField}
                style={
                  isBasicLayoutActive ? { border: '1px solid #C9CED6' } : {}
                }
              />
            </div>

            <div className="mb-4">
              <FormLabel htmlFor="password">{t('PASSWORD')}</FormLabel>
              <PasswordField
                placeholder={t('ENTER_YOUR_PASSWORD')}
                id="password"
                name="password"
                onChange={formInputHandler}
                required
                value={formData.password}
                className={classes.inputField}
                style={
                  isBasicLayoutActive ? { border: '1px solid #C9CED6' } : {}
                }
              />
            </div>

            {process.env.NODE_ENV === 'development' && (
              <>
                <FormLabel htmlFor="password">
                  {t('AGENCY_DOMAIN_FOR_DEVELOPMENT_ONLY')}
                </FormLabel>
                <RoundedTextInput
                  type="text"
                  placeholder={t('AGENCY_DOMAIN')}
                  name="agencyDomain"
                  onChange={formInputHandler}
                  required
                  value={formData.agencyDomain}
                  className={classes.inputField}
                  style={
                    isBasicLayoutActive ? { border: '1px solid #C9CED6' } : {}
                  }
                />
              </>
            )}

            <div className="mt-3 flex justify-between items-center">
              <p className="text-xs font-medium">
                {t('FORGOT_PASSWORD')}{' '}
                <Link
                  to="/forgot-password"
                  className="text-primary-main font-bold"
                >
                  {t('CLICK_HERE')}
                </Link>
              </p>
            </div>
            <div className="mt-6">
              <LoadingButton
                size="large"
                loading={loading}
                type="submit"
                className="w-full"
              >
                {t('SIGN_IN')}
              </LoadingButton>
              <p className="text-xs mt-2 font-medium">
                {t('HAVENT_SIGNED_UP')}{' '}
                <Link
                  to={
                    window.location.href.includes(CV_HOST)
                      ? '/signup'
                      : `/clientsignup${window.location.search}`
                  }
                  className="text-primary-main font-bold"
                >
                  {t('REGISTER_HERE')}
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </PatternScreen>
  )
}

const FormLabel = ({ children, ...rest }) => {
  return (
    <label
      className="inline-block text-xs md:text-smaller 2xl:text-sm font-medium tracking-wide mb-2"
      {...rest}
    >
      {children}
    </label>
  )
}

export default LoginCopy
