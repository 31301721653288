import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import { FormLabel } from 'global/globalComponents'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { ReactComponent as NoImage } from 'static/svg/no-image.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { useTranslation } from 'react-i18next'
import { fetchUrlPreview, isUrl } from 'utils'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'

const URLField = ({ canUpdate, formData, handleAddUrl, handleRemoveUrl }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [url, setUrl] = useState('')
  const urls = formData.urls || []

  const onAddUrl = () => {
    let trimmedUrl = url.trim()

    if (!isUrl(trimmedUrl)) {
      dispatch(fireErrorToaster(t('ERROR_INVALID_URL')))
      return
    }

    const alreadyExists = formData.urls.find(item => item.url === trimmedUrl)
    if (alreadyExists) {
      dispatch(fireWarningToaster(t('ERROR_ALREADY_EXISTS', { data: url })))
      return
    }

    handleAddUrl({ url: trimmedUrl, type: 'custom' })
    setUrl('')
  }

  return (
    <div className="mb-6">
      <FormLabel htmlFor="name">{t('ENTER_URL')}</FormLabel>
      <div className="flex text-smaller space-x-2">
        <TextField
          value={url}
          onChange={e => setUrl(e.target.value)}
          placeholder="www.example.com"
          disabled={!canUpdate}
          fullWidth
        />

        <Button onClick={onAddUrl} startIcon={<Add />}>
          {t('ADD')}
        </Button>
      </div>

      {urls.length > 0 && (
        <div className="flex items-center mt-4 overflow-auto">
          {urls.map(urlObj => (
            <URLWithPreview
              key={urlObj.url}
              url={urlObj.url}
              t={t}
              handleRemoveUrl={() => handleRemoveUrl(urlObj.url)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const URLWithPreview = ({ url, t, handleRemoveUrl }) => {
  const [preview, setPreview] = useState({
    loading: true,
    data: null
  })

  useEffect(() => {
    fetchUrlPreview(url)
      .then(({ image }) => setPreview({ loading: false, data: image }))
      .catch(err => setPreview({ loading: false, data: null }))
  }, [])

  return (
    <div className="w-40 mr-4 mb-4 relative show-on-hover-parent">
      <button
        onClick={handleRemoveUrl}
        className="absolute top-2 right-2 bg-white w-6 h-6 rounded-full flex justify-center items-center text-red-500 border show-on-hover-child"
      >
        <CrossIcon />
      </button>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="block w-full h-20 hover:shadow rounded-md overflow-hidden"
      >
        {preview.loading ? (
          <div className="w-full h-full  flex flex-col justify-center items-center bg-gray-100 dark:bg-dark-main">
            <CircularProgress size={20} />
          </div>
        ) : preview.data ? (
          <img src={preview.data} alt="" className="w-full h-full border" />
        ) : (
          <div className="w-full h-full flex flex-col justify-center items-center bg-gray-100 dark:bg-dark-main">
            <NoImage className="w-10 h-10 text-gray-500 mb-1" />
          </div>
        )}
      </a>
      <span className="text-xs text-gray-600 dark:text-gray-400 inline-block w-40 mt-1 truncate">
        {url}
      </span>
    </div>
  )
}

export default URLField
