import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Clientvenue_logo } from 'static/Images/index'
/**
 * Change title of the document according to the current page
 * @param {string} title Title of the current page
 * @param {String}  image  Image for current page
 */
const useTitle = (title, image) => {
  const me = useSelector(state => state.me.data)
  useEffect(() => {
    const timestamp = new Date().getTime()
    let customizeData = localStorage.getItem('customize')
    let favicon = Clientvenue_logo
    let brandName = 'ClientVenue'
    if (customizeData) {
      customizeData = JSON.parse(customizeData)
      favicon = customizeData.brand?.favicon || Clientvenue_logo
      brandName = customizeData.brand?.brandName || 'ClientVenue'
    }

    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }

    // adding timestamp to prevent caching
    link.href = Boolean(image)
      ? `${image}?t=${timestamp}`
      : `${favicon}?t=${timestamp}`
    document.querySelectorAll('[rel="icon"]')[0].replaceWith(link)

    document.title = title || brandName
  }, [title, image, me])
}
export default useTitle
