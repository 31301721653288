export const CLIENT_REQUEST = {
  FETCHED: 'CLIENT_REQUEST_FETCHED',
  UPDATE_ONE: 'CLIENT_REQUEST_UPDATE_ONE',
  ADD_ONE: 'CLIENT_REQUEST_ADD_ONE',
  DELETE_ONE: 'CLIENT_REQUEST_AGENCY_DELETE_ONE',
  FETCHED_FORM_TEMPLATE: 'CLIENT_REQUEST_FETCHED_FORM_TEMPLATE',
  ADD_FORM_TEMPLATE: 'CLIENT_REQUEST_ADD_FORM_TEMPLATE',
  UPDATE_FORM_TEMPLATE: 'CLIENT_REQUEST_UPDATE_FORM_TEMPLATE',
  DELETE_FORM_TEMPLATE: 'CLIENT_REQUEST_DELETE_FORM_TEMPLATE'
}

const initialState = {
  loading: true,
  data: [],
  fetched: false,
  formTemplates: {
    loading: true,
    fetched: false,
    data: []
  }
}

const clientRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_REQUEST.FETCHED:
      return { ...state, loading: false, fetched: true, data: action.payload }

    case CLIENT_REQUEST.UPDATE_ONE:
      const updatedData = state.data.map(item => {
        if (item._id === action.payload._id) return action.payload
        return item
      })

      return { ...state, data: updatedData }

    case CLIENT_REQUEST.ADD_ONE:
      return { ...state, data: [action.payload, ...state.data] }

    case CLIENT_REQUEST.DELETE_ONE:
      const dataAfterDelete = state.data.filter(e => {
        return !(e._id == action.payload)
      })
      return { ...state, data: dataAfterDelete }

    case CLIENT_REQUEST.FETCHED_FORM_TEMPLATE:
      return {
        ...state,
        formTemplates: {
          loading: false,
          fetched: true,
          data: action.payload
        }
      }

    case CLIENT_REQUEST.ADD_FORM_TEMPLATE:
      return {
        ...state,
        formTemplates: {
          ...state.formTemplates,
          data: [...state.formTemplates.data, action.payload]
        }
      }

    case CLIENT_REQUEST.UPDATE_FORM_TEMPLATE:
      return {
        ...state,
        formTemplates: {
          ...state.formTemplates,
          data: state.formTemplates.data.map(item =>
            item._id === action.payload._id ? action.payload : item
          )
        }
      }

    case CLIENT_REQUEST.DELETE_FORM_TEMPLATE:
      return {
        ...state,
        formTemplates: {
          ...state.formTemplates,
          data: state.formTemplates.data.filter(
            item => item._id !== action.payload
          )
        }
      }

    default:
      return state
  }
}

export default clientRequestReducer
