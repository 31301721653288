import produce from 'immer'

export const SERVICES = {
  LOADING: 'SERVICES_LOADING',
  FETCHED: 'ALL_SERVICES_FETCHED',
  ADD_ONS_FETCHED: 'SERVICES_ADD_ONS_FETCHED',
  ADD_ONE_SERVICE: 'SERVICES_ADD_ONE_SERVICE',
  ADD_ONE_ADD_ON: 'SERVICES_ADD_ONE_ADD_ON',
  UPDATE_ONE_SERVICE: 'SERVICES_UPDATE_ONE_SERVICE',
  UPDATE_ONE_ADD_ON: 'SERVICES_UPDATE_ONE_ADD_ON',
  DELETE_ONE_SERVICE: 'SERVICES_DELETE_ONE_SERVICE',
  DELETE_ADD_ON: 'SERVICES_DELETE_ADD_ON',
  FETCHED_CATEGORIES: 'SERVICE_FETCHED_CATEGORIES',
  FETCHED_SERVICE_TEMPLATES: 'SERVICE_FETCHED_SERVICE_TEMPLATES',
  ADD_NEW_SERVICE_TEMPLATE: 'SERVICE_ADD_NEW_SERVICE_TEMPLATE',
  UPDATE_SERVICE_TEMPLATE: 'SERVICE_UPDATE_SERVICE_TEMPLATE',
  DELETE_SERVICE_TEMPLATE: 'SERVICE_DELETE_SERVICE_TEMPLATE',
  ADD_NEW_SERVICE_CATEGORY: 'SERVICE_ADD_NEW_SERVICE_CATEGORY',
  UPDATE_SERVICE_CATEGORY: 'SERVICE_UPDATE_SERVICE_CATEGORY',
  DELETE_SERVICE_CATEGORY: 'SERVICE_DELETE_SERVICE_CATEGORY'
}

const initialState = {
  error: false,
  loading: false,
  data: [],
  addOns: {
    loading: true,
    data: []
  },
  serviceCategories: {
    loading: true,
    data: [],
    fetched: false
  },
  serviceTemplates: {
    loading: true,
    data: [],
    fetched: false
  },
  fetched: false
}

export const serviceReducer = produce((draft, action) => {
  switch (action.type) {
    case SERVICES.LOADING:
      draft.loading = true
      draft.error = false
      return draft

    case SERVICES.FETCHED:
      draft.loading = false
      draft.fetched = true
      draft.data = action.payload
      draft.error = false
      return draft

    case SERVICES.ADD_ONS_FETCHED:
      draft.addOns = {
        loading: false,
        data: action.payload
      }
      return draft

    case SERVICES.ADD_ONE_SERVICE:
      draft.data.unshift(action.payload)
      return draft

    case SERVICES.ADD_ONE_ADD_ON:
      draft.addOns.data.unshift(action.payload)
      return draft

    case SERVICES.UPDATE_ONE_SERVICE:
      const idx = draft.data.findIndex(item => item._id === action.payload._id)

      if (idx !== -1) draft.data[idx] = action.payload
      return draft

    case SERVICES.UPDATE_ONE_ADD_ON:
      const idx2 = draft.addOns.data.findIndex(
        item => item._id === action.payload._id
      )

      if (idx2 !== -1) draft.addOns.data[idx2] = action.payload
      return draft

    case SERVICES.DELETE_ONE_SERVICE:
      draft.data = draft.data.filter(item => item._id !== action.payload)
      return draft

    case SERVICES.DELETE_ADD_ON:
      draft.addOns.data = draft.addOns.data.filter(
        item => item._id !== action.payload
      )
      return draft

    case SERVICES.FETCHED_CATEGORIES:
      draft.serviceCategories.loading = false
      draft.serviceCategories.fetched = true
      draft.serviceCategories.data = action.payload
      return draft

    case SERVICES.FETCHED_SERVICE_TEMPLATES:
      draft.serviceTemplates.loading = false
      draft.serviceTemplates.fetched = true
      draft.serviceTemplates.data = action.payload
      return draft

    case SERVICES.ADD_NEW_SERVICE_TEMPLATE:
      draft.serviceTemplates.data.push(action.payload)
      return draft

    case SERVICES.UPDATE_SERVICE_TEMPLATE:
      const serviceTemplateIdx = draft.serviceTemplates.data.findIndex(
        item => item._id === action.payload._id
      )

      if (serviceTemplateIdx !== -1)
        draft.serviceTemplates.data[serviceTemplateIdx] = action.payload
      return draft

    case SERVICES.DELETE_SERVICE_TEMPLATE:
      draft.serviceTemplates.data = draft.serviceTemplates.data.filter(
        item => item._id !== action.payload
      )
      return draft

    case SERVICES.ADD_NEW_SERVICE_CATEGORY:
      draft.serviceCategories.data.push(action.payload)
      return draft

    case SERVICES.UPDATE_SERVICE_CATEGORY:
      const categoryIdx = draft.serviceCategories.data.findIndex(
        item => item._id === action.payload._id
      )

      if (categoryIdx !== -1) {
        draft.serviceCategories.data[categoryIdx] = action.payload
      }

      return draft

    case SERVICES.DELETE_SERVICE_CATEGORY:
      draft.serviceCategories.data = draft.serviceCategories.data.filter(
        item => item._id !== action.payload
      )
      return draft

    default:
      return draft
  }
}, initialState)
export default serviceReducer
