import ObjectID from 'bson-objectid'
import ServiceTemplate01 from './Template-01'
import ServiceTemplate02 from './Template-02'

export const serviceTemplateComponents = {
  'Template-01': ServiceTemplate01,
  'Template-02': ServiceTemplate02
}

const template01 = {
  name: 'Template-01',
  description:
    "Showcase your company's core values and services with our sleek and professional template. Perfect for modern businesses looking to make a lasting impression.",
  isActive: false,
  type: 'serviceTemplate',
  configuration: {
    label: 'Company Essence',
    image:
      'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/service-template-1.jpg',
    metadata: {
      styles: {}
    },

    sections: [
      {
        id: ObjectID().toString(),
        name: 'features',
        heading: 'Why Choose Us?',
        label: 'Features',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: 'Expertise',
            description:
              'Skilled in diverse platforms and languages for tailored solutions.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/why-choose-up-default-icon1.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Quality & Innovation',
            description:
              'Rigorous QA processes and staying updated with the latest tech trends.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/why-choose-up-default-icon2.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Proven Track Record',
            description:
              'History of successful projects and satisfied clients.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/why-choose-up-default-icon3.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Timely Delivery',
            description: 'Commitment to meeting deadlines.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/why-choose-up-default-icon4.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Dedicated Support',
            description: 'Responsive customer service team.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/why-choose-up-default-icon5.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Value for Money',
            description: 'Competitive pricing for maximum ROI.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/why-choose-up-default-icon6.png'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'about',
        heading: 'About us',
        label: 'About',
        description:
          'CV brings over 3 years of experience, completing 90+ projects for 75+ clients. Our track record showcases our commitment to excellence and client satisfaction. Trust us for innovative solutions that drive results and propel businesses forward.',
        blocks: [
          {
            id: ObjectID().toString(),
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/about-default-image1.png'
          },
          {
            id: ObjectID().toString(),
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/about-default-image2.png'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'achievements',
        label: 'Achievements',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: '3+',
            subHeading: 'Years of experience',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/achievements-default-icon1.png'
          },
          {
            id: ObjectID().toString(),
            heading: '90+',
            subHeading: 'Projects done',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/achievements-default-icon2.png'
          },
          {
            id: ObjectID().toString(),
            heading: '75+',
            subHeading: 'Happy clients',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/achievements-default-icon3.png'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'services',
        heading: 'Our Services',
        label: 'Services',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: 'Web Application <br /> Development',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/service-default-icon1.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Android <br /> Development',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/service-default-icon2.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'IOS <br /> Development',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/service-default-icon3.png'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'testimonials',
        heading: 'Testimonials',
        label: 'Testimonials',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: 'Narang Gupta',
            description:
              'CV has been our trusted IT provider for over a decade, and they continue to deliver outstanding results. Their proactive approach to IT support ensures that our systems are always up and running smoothly, minimising any potential downtime.'
          },
          {
            id: ObjectID().toString(),
            heading: 'Ankit Kumar',
            description:
              'CV has been our trusted IT provider for over a decade, and they continue to deliver outstanding results. Their proactive approach to IT support ensures that our systems are always up and running smoothly, minimising any potential downtime.'
          },
          {
            id: ObjectID().toString(),
            heading: 'Sheetal Singh',
            description:
              'CV has been our trusted IT provider for over a decade, and they continue to deliver outstanding results. Their proactive approach to IT support ensures that our systems are always up and running smoothly, minimising any potential downtime.'
          },
          {
            id: ObjectID().toString(),
            heading: 'Kavya Gupta',
            description:
              'CV has been our trusted IT provider for over a decade, and they continue to deliver outstanding results. Their proactive approach to IT support ensures that our systems are always up and running smoothly, minimising any potential downtime.'
          },
          {
            id: ObjectID().toString(),
            heading: 'Jennifer Alfred',
            description:
              'CV has been our trusted IT provider for over a decade, and they continue to deliver outstanding results. Their proactive approach to IT support ensures that our systems are always up and running smoothly, minimising any potential downtime.'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'faq',
        heading: 'FAQ',
        label: 'FAQ',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: 'What services does your web application offer?',
            description:
              'Our web application offers a range of services including but not limited to web development, web design, e-commerce solutions, content management systems, and custom web application development.'
          },
          {
            id: ObjectID().toString(),
            heading: 'How do I get started with your web application services?',
            description:
              'To get started, simply reach out to us through our website or contact us via email or phone. Our team will guide you through the process, understand your requirements, and discuss the best solutions for your needs.'
          },
          {
            id: ObjectID().toString(),
            heading:
              'What sets your web application services apart from others?',
            description:
              'Our web application services stand out due to our focus on user-centric design, cutting-edge technology, robust security measures, and dedicated customer support. We prioritize client satisfaction and strive to deliver exceptional results.'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'footer',
        label: 'Footer',
        image: '{{smallLogo}}',
        blocks: [
          {
            id: ObjectID().toString(),
            description: '© Copyrights 2024 CV All rights reserved.'
          },
          {
            id: ObjectID().toString(),
            items: [
              { text: 'Privacy Policy', url: '' },
              { text: 'Disclaimer', url: '' },
              { text: 'Terms & Conditions', url: '' }
            ]
          }
        ]
      }
    ]
  }
}

const template02 = {
  name: 'Template-02',
  description:
    'Your gateway to business success. This template offers a comprehensive platform to highlight your services and connect with clients effortlessly.',
  isActive: false,
  type: 'serviceTemplate',
  configuration: {
    label: 'Business Portal',
    image:
      'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/service-template-2.jpg',
    metadata: {
      styles: {}
    },

    sections: [
      {
        id: ObjectID().toString(),
        name: 'about',
        heading: 'Convert visitors to your paying customers',
        label: 'About',
        subHeading: 'Take control over your landing page template',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: 'Optimized for mobile devices',
            description:
              'Suspendisse lobortis, odio in interdum vestibulum, nibh ex porta diam, ac suscipit massa quam quis neque. Aliquam erat volutpat. Etiam auctor luctus nisi vel volutpat. Curabitur sagittis magna eu felis pellentesque, ut tempus orci scelerisque. Quisque feugiat tellus at nibh tincidunt, ut rhoncus risus lobortis.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/about-default-1.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Your customers will love it',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu elit mauris. Integer sollicitudin tempus semper. Ut et tinciduant libero. Quisque eu blandit eros, sit amet luctus sem. In feugiat accumsan tristique. Suspendisse vel enim vel ante fringilla egestas tempus sit amet nulla. Integer sollicitudin tempus semper.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/about-default-2.png'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'testimonials',
        label: 'Testimonials',
        heading: "Don't take our word",
        subHeading: 'Some kind words from our awesome clients',
        blocks: [
          {
            id: ObjectID().toString(),
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/testimonials-default-1.png',
            heading: 'Johnathan Doe',
            subHeading: 'Founder and CEO of Company Inc',
            description:
              "This is some dummy copy. You're not really supposed to read this dummy copy, it is just a place holder for people who need some type to visualize what the actual copy might look like if it were real content. This is some dummy copy. You're not really supposed to read this dummy copy, it is just a place holder for people who need to know what the actual copy is."
          },
          {
            id: ObjectID().toString(),
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/testimonials-default-2.png',
            heading: 'Michael Slats',
            subHeading: 'Project Lead at Envato.com',
            description:
              "For you, the skies will be blue, the birds will sing, and your copy will be crafted by a dedicated little man whose wife will be sitting at home, knitting, wondering why your entry demands more of her husband's time than it should. This is some dummy copy. You're not really supposed to read this dummy copy, it is just a place holder to visualize."
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'features',
        heading: 'Some of the awesome features of our product',
        label: 'Features',
        subHeading:
          'You will be amazed to get this product with awesome features',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: 'Mobile friendly design',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu elit mauris. Integer sollicitudin tempus semper.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/features-default-1.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Conversion optimized',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu elit mauris. Integer sollicitudin tempus semper.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/features-default-2.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Save time and money',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu elit mauris. Integer sollicitudin tempus semper.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/features-default-3.png'
          },
          {
            id: ObjectID().toString(),
            heading: '4+ Layouts to choose',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu elit mauris. Integer sollicitudin tempus semper.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/features-default-4.png'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'services',
        heading: 'Our Services',
        label: 'Services',
        subHeading: 'Popular services we provide',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: 'Mobile friendly design',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu elit mauris. Integer sollicitudin tempus semper.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/services-default-1.png'
          },
          {
            id: ObjectID().toString(),
            heading: 'Save time and money',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu elit mauris. Integer sollicitudin tempus semper.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/services-default-2.png'
          },
          {
            id: ObjectID().toString(),
            heading: '4+ Layouts to choose',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu elit mauris. Integer sollicitudin tempus semper.',
            image:
              'https://s3.us-east-1.wasabisys.com/assets.clientvenue.com/serviceTemplate/template2/services-default-3.png'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'faq',
        heading: 'Questions?',
        subHeading: 'Do you have any questions? We’ve got answers',
        blocks: [
          {
            id: ObjectID().toString(),
            heading: 'How does the free trial work?',
            description:
              'Our 10 day trial is 100% free and does not require credit card information to start. If at the end of your trial you would like to upgrade, great. If not, you can cancel your account altogether, or let us mark it as inactive for you to come back to later.'
          },
          {
            id: ObjectID().toString(),
            heading: 'Do I need to choose a plan now?',
            description:
              "No. You get the full featured, unlimited version of Startup completely free for 10 days. Once you're ready to upgrade, you may choose a plan which suits your needs."
          },
          {
            id: ObjectID().toString(),
            heading: 'Can I switch plans later?',
            description:
              'Absolutely. You can switch between our paid plans, or cancel your account altogether, whenever you like. We will adjust any payments accordingly.'
          },
          {
            id: ObjectID().toString(),
            heading: 'What payment types do you accept?',
            description:
              'We accept payments from MasterCard, Visa, Visa Debit and American Express. We do not accept PayPal. Remember, you do not need to supply card details to start your free trial.'
          }
        ]
      },
      {
        id: ObjectID().toString(),
        name: 'footer',
        label: 'Footer',
        blocks: [
          {
            id: ObjectID().toString(),
            image: '{{smallLogo}}'
          },
          {
            id: ObjectID().toString(),
            description: '24 X 7 Customer Support'
          },
          {
            id: ObjectID().toString(),
            description: '©2024 CV'
          }
        ]
      }
    ]
  }
}

const templatesData = [template01, template02]

export const getTemplatesMeta = () => {
  return templatesData.map(template => ({
    name: template.name,
    label: template.configuration.label,
    image: template.configuration.image,
    description: template.description
  }))
}

export const getDefaultTemplateDataByName = (name, { smallLogo }) => {
  let templateData = templatesData.find(template => template.name === name)

  if (!templateData) return {}

  // Deep cloning the object using JSON methods and also replacing variables with the dynamic value
  let stringifiedTemplate = JSON.stringify(templateData)
  stringifiedTemplate = stringifiedTemplate.replaceAll(
    /{{smallLogo}}/g,
    smallLogo
  )

  return JSON.parse(stringifiedTemplate)
}
