import React from 'react'
import { paymentCardImages } from 'constants/constantValues'
import { Radio } from '@material-ui/core'

const CardDetails = ({ t, card, isSelected, onSelect }) => {
  const cardData = { ...card, ...(card.card ? card.card : {}) }

  return (
    <div>
      <div
        className="inline-flex flex-row items-center cursor-pointer mb-3"
        onClick={onSelect}
      >
        <Radio checked={isSelected} />
        <div className="flex items-center">
          <img alt="" src={paymentCardImages[cardData.brand.toLowerCase()]} />

          <div className="ml-1 flex flex-col justify-center flex-grow capitalize text-smaller font-medium ">
            <div>
              <span className=" text-primary-mid-dark dark:text-dark-light font-semibold text-sm">
                {cardData.brand.toLowerCase() === 'visa'
                  ? `visa ${cardData.funding} card`
                  : 'Master Card'}{' '}
                ****{cardData.last4}
              </span>
            </div>
            <span className="text-gray-500 dark:text-gray-400">
              {t('EXPIRES')}{' '}
              {new Date(
                `${cardData.exp_year}/${cardData.exp_month}/01`
              ).toLocaleDateString(undefined, {
                month: 'short',
                year: 'numeric'
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDetails
