import axios from 'axios'
import { TAGS } from './reducers'
import { catchAsyncDispatch } from 'utils'

/**
 * create tags in a workspace
 * @param {Object} data.data tags data
 * @param {Function} callback Callback function
 */
export const createTags = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/tag`,
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({
      type: TAGS.ADD_ONE_TAG,
      payload: { response: res.data, new: data.data.new }
    })
  }, callback)
}

/**
 * Fetch tags by user
 * @param {Object | null} data
 * @param {Function} callback Callback function
 */
export const fetchTagsByUser = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/tag/user`
    })

    if (callback) callback(res.data)
    dispatch({ type: TAGS.FETCHED, payload: res.data })
  }, callback)
}

/**
 * Fetch tags in a workspace
 * @param {String} data.workspaceId
 * @param {Function} callback Callback function
 */
export const fetchWorkspaceTagsById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `tag/workspace/${data.workspaceId}`
    })

    if (callback) callback(res.data)
    dispatch({ type: TAGS.OPEN_CURRENT_WORKSPACE_TAGS, payload: res.data })
  }, callback)
}

/**
 * Update tags in a workspace or task
 * @param {String} data.id tag id
 * @param {String} data.data update
 * @param {Function} callback Callback function
 */
export const updateTagsById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/tag/${data.id}`,
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: TAGS.UPDATE_ONE_TAG, payload: res.data })
  }, callback)
}

/**
 * Delete tags
 * @param {String} data.id tag id
 * @param {Function} callback Callback function
 */
export const deleteTagsById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/tag/${data.id}`
    })

    if (callback) callback(res.data)
    dispatch({ type: TAGS.DELETE_ONE_TAG, payload: res.data })
  }, callback)
}

/**
 * Unassign tags from workspace and task
 * @param {String} data.id tag id
 * @param {String} data.data
 * @param {Function} callback Callback function
 */
export const unassignTagById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/tag/unassign/${data.id}`,
      data: data.data
    })

    if (callback) callback(res.data, false)
    dispatch({ type: TAGS.UPDATE_ONE_TAG, payload: res.data })
  }, callback)
}
