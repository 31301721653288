import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { useModal } from 'customHooks'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ConnectIcon } from 'static/svg/link.svg'
import { SlackLogo, MicrosoftTeams } from 'static/Images'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllWebhookEvents,
  fetchUserCreatedWebhooks
} from 'thunks/webhooks/action'
import SlackConnectModal from './SlackConnectModal'

const ProjectConnect = () => {
  const { t } = useTranslation()
  const { modal, openModal, closeModal } = useModal()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAllWebhookEvents())
    dispatch(fetchUserCreatedWebhooks())
  }, [])

  return (
    <>
      <Button
        onClick={openModal}
        size="small"
        variant="outlined"
        startIcon={<ConnectIcon className="w-3 h-3" />}
        style={{ color: 'orange', borderColor: 'orange' }}
      >
        {t('CONNECT')}
      </Button>

      <ConnectOptionsModal t={t} open={modal.open} onClose={closeModal} />
    </>
  )
}

const ConnectOptionsModal = ({ t, open, onClose }) => {
  return (
    <CustomModal open={open} handleClose={onClose}>
      <CustomModalHeader heading={t('CONNECT')} handleClose={onClose} />
      <ModalContent t={t} onClose={onClose} />
    </CustomModal>
  )
}

const ModalContent = ({ t, onClose }) => {
  const [isSlackModalOpen, setIsSlackModalOpen] = useState(false)
  const slackWebhookData = useSelector(state => state.userWebhooks.slackData)
  const { currentWorkspace } = useSelector(state => state.userWorkspaces)
  const currentProjectSlackWebhook = slackWebhookData.find(
    item => item.workspace === currentWorkspace._id
  )

  const connectOptions = [
    {
      label: 'Slack',
      name: 'slack',
      image: SlackLogo,
      isConnected: Boolean(currentProjectSlackWebhook)
    },
    {
      label: 'Microsoft Teams',
      name: 'ms',
      image: MicrosoftTeams,
      isComingSoon: true
    }
  ]

  const handleConnect = name => {
    if (name === 'slack') setIsSlackModalOpen(true)
  }

  return (
    <>
      <CustomModalBody>
        <div className="flex gap-4 pt-2">
          {connectOptions.map(item => (
            <div
              className="p-3 w-32 border rounded flex flex-col"
              key={item.label}
            >
              <div className="flex w-1/2 justify-center mx-auto">
                <img
                  className="w-full h-auto mb-2"
                  src={item.image}
                  alt={item.label}
                />
              </div>
              <div className="text-sm text-gray-600 mb-2 text-center font-medium">
                {item.label}
              </div>

              <div className="mt-auto">
                <Button
                  fullWidth
                  className="mt-auto"
                  size="small"
                  disabled={item.isComingSoon}
                  onClick={() => handleConnect(item.name)}
                >
                  {item.isComingSoon
                    ? t('COMING_SOON')
                    : item.isConnected
                    ? t('MANAGE')
                    : t('CONNECT')}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button onClick={onClose}>{t('CLOSE')}</Button>
      </CustomModalFooter>

      <SlackConnectModal
        open={isSlackModalOpen}
        onClose={() => setIsSlackModalOpen(false)}
        data={currentProjectSlackWebhook}
      />
    </>
  )
}

export default ProjectConnect
