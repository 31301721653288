import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import {
  getStripeConnectStatus,
  postStripeAccountData
} from 'thunks/stripeAccount/actions'
import StripeConnect from './StripeConnect'
import Invoices from '../Invoices'
// import { fetchCustomInvoices } from 'thunks/invoices/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { toggleCreateInvoiceModal } from 'thunks/generlAppState/actions'
import CreateInvoiceModal from '../CreateInvoices/CreateInvoiceModal'
import { userRoles } from 'utils'
import { useHistory } from 'react-router-dom'
import { fetchAllServices } from 'thunks/service/actions'
// import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { useTranslation } from 'react-i18next'
import { getErrorMessages } from 'utils/errorMessages'
import AgencyMarketplaceInvoices from 'components/ServiceMarketPlace/AgencyMarketplaceInvoices/AgencyMarketplaceInvoices'

export default function Connect() {
  const { t } = useTranslation()
  const errorMessages = getErrorMessages()
  const dispatch = useDispatch()
  const me = useSelector(state => state.me)
  const invoices = useSelector(state => state.clientInvoices)
  const stripeInfo = useSelector(state => state.userStripeInfo)
  const access = useSelector(state => state.permission.data?.access)
  // const { path } = useRouteMatch()
  const history = useHistory()
  const userServices = useSelector(state => state.userServices)
  const { isMarketplaceDomain } = useSelector(state => state.generalAppState)
  // const isProjectsFetched = useSelector(
  //   state => state.userWorkspaces.fetchedFirstTime
  // )

  const handleToggleInvoiceModal = (invoiceType = 'stripe') => {
    if (access?.billings.create) dispatch(toggleCreateInvoiceModal(invoiceType))
    else
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
  }

  const [stripeConnectedStatus, setStripeConnectStatus] = useState({
    isConnected: stripeInfo.data?.isConnected,
    status: 'notFound'
  })

  useEffect(() => {
    dispatch(
      getStripeConnectStatus(null, (res, err) => {
        if (!err) {
          setStripeConnectStatus(res)
        }
      })
    )

    if (window.location.href.includes('code=')) {
      postStripeAccountData(
        { code: window.location.href.split('code=')[1] },
        (res, err) => {
          if (!err) {
            setStripeConnectStatus(res)
          }
        }
      )
    }
  }, [])

  useEffect(() => {
    if (!userServices.fetched) {
      dispatch(fetchAllServices())
    }
  }, [userServices.fetched])

  const handleClick = () => {
    if (!stripeConnectedStatus.isConnected) {
      stripeConnectedStatus.url
        ? window.open(stripeConnectedStatus.url, '_blank')
        : dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
    }
  }

  const handleViewInvoice = invoice => {
    history.push(`/agency/payments/client-billing/i/${invoice._id}`)
  }

  const handleEditInvoice = invoice => {
    history.push(`/agency/payments/client-billing/i/${invoice._id}/edit`)
  }

  let showInvoicesPage
  if (isMarketplaceDomain) showInvoicesPage = true
  else {
    showInvoicesPage =
      stripeConnectedStatus.isConnected ||
      invoices.data.length ||
      ![userRoles.USER_AGENCY].includes(me.data.role)
  }

  return (
    <>
      {invoices.loading ? (
        <InvoicesLoadingAnimation />
      ) : showInvoicesPage ? (
        <>
          {isMarketplaceDomain ? (
            <AgencyMarketplaceInvoices invoices={invoices} />
          ) : (
            <Invoices
              handleToggleInvoiceModal={handleToggleInvoiceModal}
              invoices={invoices.data.sort(
                (a, b) =>
                  b.invoiceNumber.split('-')[1] - a.invoiceNumber.split('-')[1]
              )}
              loading={invoices.loading}
            />
          )}
        </>
      ) : (
        <StripeConnect
          handleToggleInvoiceModal={handleToggleInvoiceModal}
          connect={handleClick}
        />
      )}

      <CreateInvoiceModal
        handleClose={handleToggleInvoiceModal}
        handleEditInvoice={handleEditInvoice}
        handleViewInvoice={handleViewInvoice}
      />
    </>
  )
}

const InvoicesLoadingAnimation = () => {
  return (
    <div className="py-6">
      <div className="flex-1">
        <Skeleton
          className="rounded"
          variant="text"
          width={'25%'}
          height={80}
        />
      </div>

      <div className="flex justify-between items-center mb-6">
        <div className="flex-1 flex gap-4">
          {Array(4)
            .fill('')
            .map((item, index) => (
              <Skeleton
                key={index}
                className="rounded"
                variant="text"
                width={'15%'}
                height={40}
              />
            ))}
        </div>
        <div className="flex-1 flex gap-4 justify-end">
          <Skeleton
            className="rounded"
            variant="text"
            width={'25%'}
            height={60}
          />
          <Skeleton
            className="rounded"
            variant="text"
            width={'25%'}
            height={60}
          />
        </div>
      </div>

      <div>
        <Skeleton
          variant="rect"
          width={'100%'}
          height={300}
          className="rounded"
        />
      </div>
    </div>
  )
}
