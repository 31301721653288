import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { dateAndTimeHelper, userRoles } from 'utils'
import { WelcomeModal } from 'components/_shared'

const ClientWelcomeModal = () => {
  const meData = useSelector(state => state.me.data)
  const [modal, setModal] = useState({ open: false, data: {} })

  useEffect(() => {
    const isNewClient =
      meData.role >= userRoles.USER_CLIENT &&
      dateAndTimeHelper.getDateDiffInDays(meData.createdAt) <= 2

    if (!isNewClient || localStorage.getItem('hideWelcomeModal')) return

    if (meData.team?.profile?.welcomeMessageForClient?.isActive) {
      setModal({
        open: true,
        data: meData.team.profile.welcomeMessageForClient
      })
    }
  }, [meData])

  const closeModal = () => {
    setModal(prev => ({ ...prev, open: false }))
    localStorage.setItem('hideWelcomeModal', true)
  }

  return (
    <WelcomeModal open={modal.open} onClose={closeModal} data={modal.data} />
  )
}

export default ClientWelcomeModal
