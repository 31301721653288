import React from 'react'
import { useTranslation } from 'react-i18next'

const ApprovalNotificationMessage = ({
  field,
  source,
  before,
  after,
  user
}) => {
  const { t } = useTranslation()

  const generateMessage = () => {
    if (field === 'approval_requested') {
      return (
        <>
          {t('NOTIFICATION_APPROVAL_REQUESTED')}:&nbsp;
          <span className="font-semibold">"{after?.name}"</span>
        </>
      )
    }

    if (field === 'approval_member_status_change') {
      return (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_APPROVAL_APPROVAL_MEMBER_STATUS_CHANGE', {
                userAction: after ? t('ACCEPTED') : t('REJECTED')
              })
            }}
          ></span>
          <span className="font-semibold">"{source?.name}"</span>
        </>
      )
    }
    if (field === 'approval_status') {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: t('NOTIFICATION_APPROVAL_STATUS', {
              approvalName: source?.name,
              userAction: after ? t('ACCEPTED') : t('REJECTED'),
              userName: user?.name
            })
          }}
        >
          {/* Approval named&nbsp;
          <span className="font-semibold">"{source?.name}"</span>&nbsp; has
          been&nbsp;
          <span className="font-semibold">{after || 'rejected'}</span> by&nbsp;
          <span className="font-semibold">{user?.name}</span> */}
        </span>
      )
    }
  }

  return (
    <div>
      <div className="text-smaller">{generateMessage()}</div>
    </div>
  )
}

export default ApprovalNotificationMessage
