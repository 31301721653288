import React, { useMemo } from 'react'
import ChannelsListItem from './ChannelsListItem'
import { useSelector } from 'react-redux'
// import { ArrowDropDownOutlined, ArrowRightOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
// import StyledBadge from 'global/globalComponents/StyledBadge/StyledBadge'

const ChannelAccordion = ({
  channels,
  currentChannelId,
  isPersonalChannel,
  chatPermission,
  setChannelId,
  setIsPersonalChat,
  hideNoChannelFound
}) => {
  const { t } = useTranslation()
  // const [expanded, setExpanded] = useState(false)
  const { unreadMessages, personalUnreadMessages } = useSelector(
    state => state.chats
  )
  const allUnreadMessages = isPersonalChannel
    ? personalUnreadMessages
    : unreadMessages

  // const totalUnreadCount = useMemo(() => {
  //   let total = 0

  //   channels.forEach(channel => {
  //     if (allUnreadMessages[channel._id]) {
  //       total += allUnreadMessages[channel._id].unreadCount
  //     }
  //   })

  //   return 10
  // }, [channels, allUnreadMessages])

  const sortedChannelsList = useMemo(() => {
    return channels.sort((a, b) => {
      const aUnreadMsg = allUnreadMessages[a._id]?.lastUnreadMessage
      const bUnreadMsg = allUnreadMessages[b._id]?.lastUnreadMessage

      if (aUnreadMsg) {
        if (bUnreadMsg) {
          return new Date(bUnreadMsg.createdAt) - new Date(aUnreadMsg.createdAt)
        }
        return -1
      }

      if (bUnreadMsg) return 1

      return 0
    })
  }, [channels, allUnreadMessages])

  // const sortedGroups = useMemo(() => {
  //   return channels.sort(
  //     (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  //   )
  // }, [channels])

  // useEffect(() => {
  //   let match = channels.find(channel => channel._id === currentChannelId)
  //   if (match) setExpanded(true)
  // }, [channels, currentChannelId])

  const getAvatarUser = channel => {
    // For personal channels each channel is actually a user data
    // there are not separate channels, but for others we have channels
    if (isPersonalChannel) return channel
    if (channel.type === 'newFeed') {
      return (
        channel.members?.find(item => item.role === 'moderator')?.user || {}
      )
    }

    return null
  }

  const handleOnChannelClick = (chId, isPersonalChannel) => {
    setChannelId(chId)
    setIsPersonalChat(isPersonalChannel)
  }

  return (
    <ul className={clsx(sortedChannelsList.length > 0 && 'mb-4')}>
      {sortedChannelsList.length ? (
        sortedChannelsList.map(channel => (
          <ChannelsListItem
            channelId={channel._id}
            channelName={channel.title || channel.name}
            channelType={channel.type}
            chatPermission={chatPermission}
            currentChannelId={currentChannelId}
            unreadMessageData={allUnreadMessages[channel._id]}
            avatarUser={getAvatarUser(channel)}
            isPersonalChannel={isPersonalChannel}
            handleOnClick={() =>
              handleOnChannelClick(channel._id, isPersonalChannel)
            }
          />
        ))
      ) : (
        <>
          {hideNoChannelFound ? null : (
            <li
              key="noChannel"
              className="pl-6 dark:text-gray-400 text-smaller"
            >
              {t('NO_CHANNEL_FOUND')}
            </li>
          )}
        </>
      )}
    </ul>
  )
}

export default ChannelAccordion
