import React from 'react'
import Pill from 'global/globalComponents/Pill/Pill'
import clsx from 'clsx'
// import { ReactComponent as Repeat } from 'static/svg/repeat.svg'
// import { ReactComponent as VerticalThreeDots } from 'static/svg/ellipsis-h.svg'
// import { useSelector } from 'react-redux'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import Check from '@material-ui/icons/Check'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import { CustomFlag } from 'global/globalComponents'
import { ReactComponent as FlagOutlined } from 'static/svg/pennant-outlined.svg'
// import { fileToDataUrl } from 'utils'
import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch.svg'
// import EstimateTimePopup from 'components/Workspace/Panel/EstimateTimePopup'
import { adjustColor } from 'utils'
import { CircularProgress, Toolbar, useTheme } from '@material-ui/core'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
// import useWorkspaceFunctions from '../useWorkspaceFunctions'
// import { updateTaskDetails } from 'thunks/task/actions'
// import isEqual from 'lodash/isEqual'
import taskSidePanelStyles from '../Workspace/Panel/taskSidePanelStyles'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

const CreateTaskPanelHeader = ({
  onClose,
  taskState,
  triggerPopover,
  options,
  selectedProject,
  showProjectSelect = false, // to show select
  handleSelectProject,
  isLoading,
  // changeEstimateTime,
  toggleMarkAsComplete
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  // const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  // const taskStatus = useMemo(() => {
  //   if (currentBoard.boardSection) {
  //     return currentBoard.boardSection.find(
  //       (item) => item._id === taskState.taskSection
  //     )
  //   }
  // }, [taskState.taskSection, currentBoard.boardSection])
  // const [menuOptions, setMenuOptions] = useState([])
  // const inputRef = useRef()
  // const [selectedStatus, setSelectedStatus] = useState({ label: '', color: '' })
  // const [esTimeAnchor, setEsTimeAnchor] = useState(null)
  // const [selectedTime, setSelectedTime] = useState('')
  // const dispatch = useDispatch()
  const classes = taskSidePanelStyles()
  // const meData = useSelector((state) => state.me.data)
  // const { canUpdateTask, markTaskAsComplete } = useWorkspaceFunctions()

  // useEffect(() => {
  //   if (taskState.estimateTimeInString) {
  //     setSelectedTime(taskState.estimateTimeInString)
  //   }
  // }, [taskState.estimateTimeInString])

  // useEffect(() => {
  //   if (taskStatus) {
  //     setSelectedStatus({
  //       label: taskStatus.label,
  //       color: taskStatus.color
  //     })
  //   }
  // }, [taskStatus])

  // useEffect(() => {
  //   if (taskPermission?.update) {
  //     const arr = [
  //       {
  //         label: 'Upload image',
  //         method: handleImageUploadClick
  //       }
  //     ]

  //     arr.push({ label: 'Copy link', method: handleCopyLink })
  //     arr.push({ label: 'Delete', method: handleOpenDeleteTaskConfirm })

  //     setMenuOptions(arr)
  //   }
  // }, [taskPermission, elmFor])

  // const handleCopyLink = () => {
  //   navigator.clipboard.writeText(window.location.href)
  //   dispatch(fireSuccessToaster('Link copied!'))
  // }

  const handleChangePriority = e => {
    triggerPopover(e, 'priority')
  }

  const handleChangeStatus = e => {
    triggerPopover(e, 'status')
  }

  // const handleImageUploadClick = () => {
  //   if (inputRef.current) {
  //     inputRef.current.click()
  //   }
  // }

  const toggleEstimateTimePopup = e => {
    triggerPopover(e, 'estimateTime')
    // if (esTimeAnchor) {
    //   setEsTimeAnchor(null)
    // } else {
    //   setEsTimeAnchor(e.currentTarget)
    // }
  }

  // const handleChangeEstimateTime = ({ timeStr, totalMs }) => {
  //   if (timeStr !== '') {
  //     setSelectedTime(timeStr)

  //     changeEstimateTime({
  //       estimateTimeInString: timeStr,
  //       estimateTimeInMilliSecond: totalMs
  //     })
  //     toggleEstimateTimePopup()
  //   } else {
  //     changeEstimateTime({
  //       estimateTimeInString: '',
  //       estimateTimeInMilliSecond: null
  //     })
  //   }
  // }

  // const handleToggleComplete = () => {
  // }

  return (
    <Toolbar onClose={onClose} className={classes.drawerPaper1}>
      <div className={clsx(classes.drawerHeader, 'bg-dark-main')}>
        <div className="flex items-center gap-3 flex-wrap max-h-[20vh]">
          {
            /* // selected && // selectedProject &&*/
            showProjectSelect && (
              <Select
                styles={reactSelectCustomStyles(theme)}
                options={options}
                value={selectedProject?.value ? selectedProject : null}
                className="w-40"
                onChange={handleSelectProject}
                isLoading={isLoading}
                placeholder={t('SELECT_PROJECT')}
              />
            )
          }
          <div onClick={handleChangeStatus} className="cursor-pointer">
            <span>
              {taskState.taskSection?.label ? (
                <Pill
                  style={{
                    color: adjustColor(
                      taskState.taskSection.color,
                      theme.palette.type === 'light' ? -70 : +140
                    ),
                    backgroundColor:
                      taskState.taskSection.color +
                      (theme.palette.type === 'light' ? '20' : '40')
                  }}
                >
                  {taskState.taskSection.label}
                </Pill>
              ) : (
                selectedProject && (
                  <div className="flex items-center px-6 h-7 border border-gray-400 rounded-full">
                    <CircularProgress size={14} />
                  </div>
                )
              )}
            </span>
          </div>

          <div onClick={handleChangePriority} className="leading-3">
            {taskState.priority && taskState.priority !== 5 ? (
              <button>
                <CustomFlag priority={taskState.priority} />
              </button>
            ) : (
              <CustomTooltip title={t('SET_PRIORITY')} placement="top">
                <button className="text-base inline-block text-gray-400 w-8 h-8 border border-gray-400 border-dashed rounded-full flex justify-center items-center">
                  <FlagOutlined />
                </button>
              </CustomTooltip>
            )}
          </div>

          <CustomTooltip title={t('ESTIMATE_TIME')} placement="top">
            <div
              className="flex items-center space-x-1"
              onClick={toggleEstimateTimePopup}
            >
              <div className="cursor-pointer inline-block text-gray-500">
                <StopWatchIcon className="dark:text-dark-light" />
              </div>
              {taskState.estimateTimeInString && (
                <span className="text-xs -mb-1">
                  {taskState.estimateTimeInString}
                </span>
              )}
            </div>
          </CustomTooltip>

          <div className="flex space-x-2 items-center text-gray-700">
            <div
              role="button"
              className={clsx(
                classes.markAsCompleteBtn,
                {
                  [classes.completeButton]: taskState.markAsComplete
                },
                'whitespace-nowrap'
              )}
              onClick={toggleMarkAsComplete}
            >
              <Check />
              {taskState.markAsComplete ? t('COMPLETED') : t('MARK_COMPLETE')}
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4 ml-2">
          <button
            onClick={onClose}
            className="w-8 h-8 rounded-full border flex justify-center items-center border-primary-mid-light"
          >
            <CloseIcon className="text-primary-main w-5 h-5 dark:text-dark-light" />
          </button>
        </div>
      </div>
    </Toolbar>
  )
}

// const areEqual = (prevProps, nextProps) => {
//   return isEqual(prevProps.taskState, nextProps.taskState)
// }

export default React.memo(CreateTaskPanelHeader)
