import React from 'react'
import Select from 'react-select'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core'
import clsx from 'clsx'

const ClientSelector = ({ userClients, selectedClient, setSelectedClient }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const handleGetOptionLabel = option => {
    return (
      <div>
        {option.name}{' '}
        <span
          className={clsx(
            'text-smaller',
            selectedClient?._id === option._id
              ? 'currentColor'
              : 'text-gray-500'
          )}
        >
          ({option.email})
        </span>
      </div>
    )
  }

  return (
    <div className="mb-3">
      <FormLabel>
        {t('CLIENTS')}{' '}
        <span className="text-gray-400 text-xs">({t('OPTIONAL')})</span>
      </FormLabel>
      <Select
        isLoading={userClients.loading}
        menuPosition="fixed"
        isClearable={true}
        isSearchable={true}
        // className={classes.textFieldStyle}
        onChange={setSelectedClient}
        options={userClients.data}
        getOptionLabel={handleGetOptionLabel}
        getOptionValue={option => option._id}
        styles={{
          ...reactSelectCustomStyles(theme),
          menuPortal: provided => ({ ...provided, zIndex: 9999 })
        }}
        value={selectedClient}
        placeholder={t('SELECT_CLIENT')}
        maxMenuHeight={130}
      />
    </div>
  )
}

export default ClientSelector
