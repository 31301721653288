import { makeStyles, createStyles } from '@material-ui/core'

const customTabStyles = makeStyles((theme) =>
  createStyles({
    tabSwitchContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(3),
      // marginBottom: theme.spacing(3),
      // maxWidth: '569px',
      // width: '569px',
      '&>p, &>a': {
        fontSize: 14,
        fontWeight: 500,
        color: '#778CA2',
        paddingBottom: theme.spacing(0.5),
        cursor: 'pointer',
        minWidth: 32,
        textAlign: 'center',
        userSelect: 'none',
        '&.active': {
          boxShadow: `0px 3px ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        }
      }
    }
  })
)

export default customTabStyles
