import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { userRoles } from 'utils'
import { useMemo } from 'react'
import ChannelAccordion from './ChannelAccordion'
import ArchivedChannelsList from './ArchivedChannelsList'
import SearchedChannelsList from './SearchedChannelsList'
import NavbarHeader from './NavbarHeader'
import { CHANNEL_TYPE, getChatPermission } from './chatStatic'
import clsx from 'clsx'

const ALL = 'all',
  PROJECT = 'project',
  CLIENT = 'client',
  INTERNAL = 'internal',
  PERSONAL = 'personal',
  PUBLIC = 'public'

function ChatsNavbar({
  loading,
  channel,
  channels,
  personalChannels,
  openCreateChannelModal,
  setChannelId,
  setIsPersonalChat
}) {
  const meData = useSelector(state => state.me.data)
  const { t } = useTranslation()
  const chatPermission = getChatPermission(meData.role)
  const [hideArchived, setHideArchived] = useState(true)
  const { unreadMessages, personalUnreadMessages } = useSelector(
    state => state.chats
  )
  const [search, setSearch] = useState('')
  const tabs = [
    { label: t('ALL'), value: ALL },
    { label: t('PROJECT'), value: PROJECT },
    ...(meData.role === userRoles.USER_CLIENT ||
    meData.role === userRoles.USER_AGENCY
      ? [
          {
            label:
              meData.role === userRoles.USER_AGENCY
                ? t('CLIENT_CHANNELS')
                : t('FEED_CHANNELS'),
            value: CLIENT
          }
        ]
      : []),
    // Internal channels are only visible for agency members
    // Personal channels are only for agency users
    ...(meData.role < userRoles.USER_CLIENT
      ? [
          { label: t('INTERNAL'), value: INTERNAL },
          { label: t('PERSONAL'), value: PERSONAL }
        ]
      : []),
    { label: t('PUBLIC'), value: PUBLIC }
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  const channelsData = useMemo(() => {
    const channelsObj = {
      projectChannels: [],
      internalChannels: [],
      clientChannels: [],
      publicChannels: []
    }

    for (let channel of channels) {
      if (channel.isArchive) continue

      if (channel.type === CHANNEL_TYPE.NEW_FEED)
        //Don't include archive channels
        channelsObj.clientChannels.push(channel)
      else if (channel.type === CHANNEL_TYPE.PUBLIC_CHAT)
        channelsObj.publicChannels.push(channel)
      else if (channel.type === CHANNEL_TYPE.WORKSPACE || channel.workspace) {
        channelsObj.projectChannels.push(channel)
      } else if (channel.type === CHANNEL_TYPE.INTERNAL) {
        channelsObj.internalChannels.push(channel)
      }
    }

    return channelsObj
  }, [channels, unreadMessages, personalUnreadMessages])

  useEffect(() => {
    if (channel._id) {
      if (channel.isArchive) setHideArchived(false)
    }
  }, [channel._id])

  const commonChannelProps = {
    chatPermission,
    setChannelId,
    setIsPersonalChat,
    currentChannelId: channel._id,
    hideNoChannelFound: selectedTab === ALL
  }

  return (
    <>
      <div className="border-r border-gray-300 dark:border-black w-full h-full">
        <NavbarHeader
          t={t}
          meData={meData}
          setHideArchived={setHideArchived}
          hideArchived={hideArchived}
          handleSetSearch={setSearch}
          openCreateChannelModal={openCreateChannelModal}
        />

        {/* NOTE: 160px is the height of NavbarHeader */}
        <div
          style={{ height: 'calc(100% - 125px)' }}
          className="overflow-y-auto overflow-x-hidden"
        >
          {search.trim() === '' && hideArchived && (
            <div className="flex overflow-auto leading-3 space-x-2 px-4 scrollbar-hide mb-4">
              {tabs.map(tabData => (
                <div
                  key={tabData.label}
                  role="tab"
                  onClick={() => setSelectedTab(tabData.value)}
                  className={clsx(
                    'px-3 py-1 whitespace-nowrap border rounded-full text-xs cursor-pointer',
                    selectedTab === tabData.value
                      ? 'font-medium bg-primary-main text-white'
                      : 'bg-transparent text-gray-600'
                  )}
                >
                  {tabData.label}
                </div>
              ))}
            </div>
          )}

          {loading ? (
            <LoadingAnimation />
          ) : (
            <>
              {search.trim() !== '' ? (
                <SearchedChannelsList
                  search={search}
                  channels={channels}
                  personalChannels={personalChannels}
                  {...commonChannelProps}
                />
              ) : !hideArchived ? (
                <ArchivedChannelsList {...commonChannelProps} />
              ) : (
                <>
                  {(selectedTab === ALL || selectedTab === PROJECT) && (
                    <ChannelAccordion
                      channels={channelsData.projectChannels}
                      hideNoDataFound={selectedTab === ALL}
                      {...commonChannelProps}
                    />
                  )}

                  {/* Client channels are only visible for Super admin and client owner */}
                  {(selectedTab === ALL || selectedTab === CLIENT) && (
                    <>
                      {(meData.role === userRoles.USER_CLIENT ||
                        meData.role === userRoles.USER_AGENCY) && (
                        <ChannelAccordion
                          channels={channelsData.clientChannels}
                          label={
                            meData.role === userRoles.USER_AGENCY
                              ? t('CLIENT_CHANNELS')
                              : t('FEED_CHANNELS')
                          }
                          {...commonChannelProps}
                        />
                      )}
                    </>
                  )}

                  {(selectedTab === ALL || selectedTab === INTERNAL) && (
                    <>
                      {meData.role < userRoles.USER_CLIENT && (
                        <ChannelAccordion
                          channels={channelsData.internalChannels}
                          {...commonChannelProps}
                        />
                      )}
                    </>
                  )}

                  {(selectedTab === ALL || selectedTab === PERSONAL) && (
                    <>
                      {meData.role < userRoles.USER_CLIENT && (
                        <ChannelAccordion
                          channels={personalChannels}
                          isPersonalChannel={true}
                          {...commonChannelProps}
                        />
                      )}
                    </>
                  )}

                  {(selectedTab === ALL || selectedTab === PUBLIC) && (
                    <>
                      {meData.role < userRoles.USER_CLIENT && (
                        <ChannelAccordion
                          channels={channelsData.publicChannels}
                          {...commonChannelProps}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

const LoadingAnimation = () => {
  return (
    <div className="px-6 mt-2">
      {new Array(4).fill('').map((item, index) => (
        <div key={index} className="mb-2 flex items-center">
          <Skeleton
            variant="circle"
            width={42}
            height={42}
            className="mr-4 flex-shrink-0"
          />
          <Skeleton variant="text" width={index % 2 === 0 ? '50%' : '100%'} />
        </div>
      ))}
    </div>
  )
}

export default ChatsNavbar
