import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pill from 'global/globalComponents/Pill/Pill'
import { Td, Tr } from 'global/globalComponents/TailwindTable/TailwindTable'
import pillVariantObj from 'utils/pillVariantObj'
import { format } from 'date-fns'
import Currency from 'static/Currency/Currency'
// import InvoiceStatusModal from './InvoiceStatusModal'
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover
} from '@material-ui/core'
import ReactToPrint from 'react-to-print'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as ViewIcon } from 'static/svg/view.svg'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
import { ReactComponent as BellIcon } from 'static/svg/bell.svg'
import { ReactComponent as SendIcon } from 'static/svg/send.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { ReactComponent as CheckSquareIcon } from 'static/svg/check-square.svg'
import { useTranslation } from 'react-i18next'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import clsx from 'clsx'
import { openApprovalModal } from 'thunks/generlAppState/actions'
import { fetchAllAgencyTeammates } from 'thunks/teammate/actions'
import { userRoles } from 'utils'
import PreviewInvoiceTemplate1 from 'components/_shared/InvoiceTemplates/InvoiceTemplate1/PreviewInvoiceTemplate1'
import PreviewInvoiceTemplate2 from 'components/_shared/InvoiceTemplates/InvoiceTemplate2/PreviewInvoiceTemplate2'
// import { getInvoiceSummary } from 'components/_agency/PaymentsSection/paymentStatic'

export default function InvoiceTableRow({
  invoice,
  openInvoicePreview,
  handleEditInvoice,
  openMarkAsPaidAlert,
  openDeleteAlert,
  handleSendReminder,
  elmFor = 'agency',
  clientCol
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const access = useSelector(state => state.permission.data?.access)
  // const [statusModal, setStatusModal] = useState({ id: '', open: false })
  const [menuOptions, setMenuOptions] = useState([])
  const [download, setDownload] = useState(false)
  const invoiceRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const meData = useSelector(state => state.me.data)

  useEffect(() => {
    const arr = [
      {
        label: t('PREVIEW'),
        method: handleViewInvoice,
        icon: <ViewIcon className="text-primary-main dark:text-dark-light" />
      }
    ]

    if (elmFor === 'agency') {
      if (invoice.status === 'draft' && access?.billings.update) {
        arr.unshift({
          label: t('EDIT'),
          method: () => {
            handleEditInvoice(invoice)
          },
          icon: <EditIcon className="text-primary-main dark:text-dark-light" />
        })
      }

      if (invoice.status !== 'paid' && access?.billings.update) {
        arr.push({
          label: t('APPROVALS'),
          method: () => {
            // First open the modal with empty approvers
            dispatch(
              openApprovalModal({
                module: 'invoice',
                moduleId: invoice._id,
                approvalItemName: `Invoice ${invoice.invoiceNumber}`,
                approvers: []
              })
            )

            // Then run the open modal command again once teammates data fetches
            fetchAllAgencyTeammates(null, (res, err) => {
              dispatch(
                openApprovalModal({
                  module: 'invoice',
                  moduleId: invoice._id,
                  approvalItemName: `Invoice - ${invoice.invoiceNumber}`,
                  approvers: err
                    ? [meData]
                    : meData.role === userRoles.USER_AGENCY
                    ? res
                    : [...res, meData]
                })
              )
            })
          },
          icon: (
            <CheckSquareIcon className="text-primary-main dark:text-dark-light" />
          )
        })
      }

      if (
        invoice.status !== 'paid' &&
        invoice.status !== 'draft' &&
        access?.billings.update
      ) {
        arr.push({
          label: t('MARK_AS_PAID'),
          method: () => {
            openMarkAsPaidAlert(invoice._id)
          },
          icon: (
            <CheckCircleIcon className="text-primary-main dark:text-dark-light" />
          )
        })
        arr.push({
          label: t('SEND_REMINDER'),
          method: () => handleSendReminder(invoice),
          icon: <BellIcon className="text-primary-main dark:text-dark-light" />
        })

        arr.push({
          label: t('COPY_LINK'),
          method: () => {
            navigator.clipboard.writeText(
              `${window.location.protocol}//${window.location.host}/i/${invoice._id}/checkout?vtID=${invoice.viewToken}`
            )
            dispatch(fireSuccessToaster(t('LINK_COPIED')))
          },
          icon: <CopyIcon className="text-primary-main dark:text-dark-light" />
        })
      }

      // if (invoice.status === 'draft' && access?.billings.delete) {
    } else if (
      invoice.status !== 'draft' &&
      elmFor === 'client' &&
      invoice.status !== 'paid'
    ) {
      arr.push({
        label: t('PAY_NOW'),
        icon: <SendIcon className="text-primary-main dark:text-dark-light" />,
        method: () => {
          let a = document.createElement('a')
          a.target = '_blank'
          a.href = `/i/${invoice._id}/checkout?vtID=${invoice.viewToken}`
          a.click()
        }
      })
    }

    if (invoice.status !== 'draft') {
      arr.push({
        label: (
          <ReactToPrint
            trigger={() => {
              return <button>{t('DOWNLOAD')}</button>
            }}
            content={() => invoiceRef.current}
            documentTitle={`${
              invoice.customInvoiceNumber || invoice.invoiceNumber
            }_invoice`}
          />
        ),
        method: () => false,
        icon: (
          <DownloadIcon
            className="text-primary-main dark:text-dark-light"
            fontSize="small"
          />
        )
      })
    }

    if (
      invoice.status === 'draft' &&
      elmFor === 'agency' &&
      access?.billings.delete
    ) {
      // pushing delete in the end
      arr.push({
        label: t('DELETE'),
        method: () => {
          openDeleteAlert(invoice._id)
        },
        icon: <DeleteIcon />,
        isDanger: true
      })
    }

    setMenuOptions(arr)
  }, [invoice])

  const handleOpenMenu = e => {
    setAnchorEl(e.currentTarget)
    setDownload(true)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setDownload(false)
  }

  // const handleCloseStatusModal = () => {
  //   setStatusModal(prev => ({
  //     ...prev,
  //     id: '',
  //     open: false
  //   }))
  // }

  const handleViewInvoice = () => {
    openInvoicePreview(invoice)
  }

  // const { totalAmount } = getInvoiceSummary({
  //   invoiceItems: invoice.invoiceItems,
  //   taxPercent: invoice.taxPercent,
  //   coupon: invoice.coupon,
  //   discountAmount: invoice.discountAmount
  // })

  return (
    <>
      <Tr>
        {clientCol && (
          <Td
            className="px-6 py-4 whitespace-nowrap text-gray-900 cursor-pointer"
            onClick={handleViewInvoice}
          >
            <div className="flex flex-col">
              <div className="flex items-end">
                {invoice.client?.clientName ? (
                  <span className="dark:text-dark-light2">
                    {invoice.client?.clientName}
                  </span>
                ) : (
                  <span className="text-gray-500 dark:text-dark-light">NA</span>
                )}
              </div>
              <span className="text-gray-500 text-xs dark:text-dark-light">
                {invoice.client?.clientEmail}
              </span>
            </div>
          </Td>
        )}
        <Td
          className={clsx(
            'text-xs whitespace-nowrap',
            !clientCol && 'cursor-pointer'
          )}
          onClick={!clientCol ? handleViewInvoice : undefined}
        >
          {invoice.customInvoiceNumber || invoice.invoiceNumber}
        </Td>
        <Td className="text-center text-xs whitespace-nowrap w-0.5 truncate space-y-2">
          {invoice.invoiceItems.length
            ? invoice.invoiceItems.slice(0, 2).map((item, index) => (
                <div key={index} className={index === 0 ? 'leading-6' : ''}>
                  {item.name ? (
                    <Pill variant={['yellow', 'purple'][index]}>
                      {item.name.slice(0, 1).toUpperCase() + item.name.slice(1)}
                    </Pill>
                  ) : (
                    '-'
                  )}
                </div>
              ))
            : 'NA'}
        </Td>
        <Td className="text-center whitespace-nowrap ">
          <Pill variant={pillVariantObj[invoice.status]}>
            {t(invoice.status.toUpperCase())}
          </Pill>
        </Td>
        <Td className="text-right whitespace-nowrap text-gray-900 text-xs ">
          <div className="text-custom-table-primaryText dark:text-indigo-400">
            {Currency[invoice.currency].symbol_native}
            {invoice.totalAmount.toLocaleString()}
          </div>
        </Td>
        {invoice?.issuedDate ? (
          <Td className="whitespace-nowrap text-xs">
            {format(new Date(invoice.issuedDate), 'MMM d, yyyy')}
          </Td>
        ) : (
          <Td className="whitespace-nowrap text-xs">NA</Td>
        )}
        {/* <Td className="text-center whitespace-nowrap">
          <PopupMenu menuItems={getMenuList(invoice)} />
        </Td> */}
        <Td className="text-center">
          <div>
            <button onClick={handleOpenMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>

            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              classes={{ paper: classes.menuPaper }}
            >
              {/*handleCloseMenu => Event Propagation */}
              <List className={classes.list} onClick={handleCloseMenu}>
                {menuOptions.map((item, index) => (
                  <ListItem
                    key={index}
                    onClick={item.method}
                    classes={{ gutters: classes.listItem }}
                    button
                  >
                    <div className="flex items-center py-1">
                      {item.icon && (
                        <span
                          className={clsx(
                            'mr-2',
                            item.isDanger ? 'text-red-400' : 'text-primary-main'
                          )}
                        >
                          {item.icon}
                        </span>
                      )}
                      <ListItemText
                        primary={
                          <p
                            className={clsx(
                              'text-sm dark:text-dark-light',
                              item.isDanger
                                ? 'text-red-400'
                                : 'text-primary-main'
                            )}
                          >
                            {item.label}
                          </p>
                        }
                        classes={{ root: classes.listItemText }}
                      />
                    </div>
                  </ListItem>
                ))}
              </List>
            </Popover>

            <div
              style={{
                position: 'fixed',
                left: '-1000px',
                visibility: 'hidden',
                height: '1000px'
              }}
            >
              <div ref={invoiceRef}>
                {download &&
                  (invoice.invoiceTemplate === 2 ? (
                    <PreviewInvoiceTemplate2 invoiceData={invoice} />
                  ) : (
                    <PreviewInvoiceTemplate1 invoiceData={invoice} />
                  ))}
              </div>
            </div>
          </div>
        </Td>
      </Tr>
      {/* {statusModal.open && (
        <InvoiceStatusModal
          open={statusModal.open}
          onClose={handleCloseStatusModal}
          id={statusModal.id}
        />
      )} */}
    </>
  )
}

const useStyles = makeStyles({
  menuPaper: {
    borderRadius: '0.375rem'
  },

  list: {
    padding: '4px 0'
  },

  listItem: {
    padding: '0.5rem 1.5rem',
    minWidth: 115
  },

  listItemText: {
    margin: 0
  }
})
