import React, { useState } from 'react'
import { TextField, useTheme } from '@material-ui/core'
import Select, { components } from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useModal } from 'customHooks'
import { useTranslation } from 'react-i18next'
import AddSelectOptionModal from './AddSelectOptionModal'
import SingleFileField from './AttachmentField'

const FormField = ({
  fieldData,
  canEdit,
  canFillData,
  updateFieldProperty,
  error
}) => {
  const handleTextChange = e => {
    updateFieldProperty({
      fieldId: fieldData.id,
      prop: 'value',
      value: e.target.value
    })
  }

  return (
    <>
      {fieldData.type === 'textarea' ? (
        <>
          <TextField
            multiline
            value={fieldData.value}
            onChange={handleTextChange}
            rows={3}
            placeholder={fieldData.placeholder}
            disabled={!canFillData}
            fullWidth
          />
        </>
      ) : fieldData.type === 'file' ? (
        <SingleFileField
          fieldData={fieldData}
          canFillData={canFillData}
          updateFieldProperty={updateFieldProperty}
        />
      ) : // <input
      //   type="file"
      //   className="custom-input-file w-full border border-gray-300 rounded-full text-smaller h-11 text-gray-600"
      //   disabled={!canFillData}
      //   onChange={e =>
      //     updateFieldProperty({
      //       fieldId: fieldData.id,
      //       prop: 'value',
      //       value: e.target.files.length > 0 ? e.target.files : null
      //     })
      //   }
      // />
      fieldData.type === 'singleSelector' ||
        fieldData.type === 'multiSelector' ? (
        <SelectFieldWrapper
          field={fieldData}
          canFillData={canFillData}
          canEdit={canEdit}
          updateFieldProperty={updateFieldProperty}
          updateField
          error={error}
        />
      ) : (
        <TextField
          type={fieldData.type}
          error={error}
          fullWidth
          placeholder={fieldData.placeholder}
          disabled={!canFillData}
          defaultValue={fieldData.value}
          onChange={handleTextChange}
          autoComplete="off"
        />
      )}
      {fieldData.subLabel && (
        <SubLabel
          canEdit={canEdit}
          field={fieldData}
          updateFieldProperty={updateFieldProperty}
        />
      )}
    </>
  )
}

const SubLabel = ({ field, canEdit, updateFieldProperty }) => {
  const [subLabel, setSubLabel] = useState({
    edit: false,
    value: field.subLabel
  })

  const toggleEdit = e => {
    if (subLabel.edit) {
      setSubLabel({ edit: false, value: e.target.value })
      updateFieldProperty({
        fieldId: field.id,
        prop: 'subLabel',
        value: e.target.value
      })
    } else {
      setSubLabel(prev => ({ ...prev, edit: true }))
    }
  }

  return (
    <div>
      {subLabel.edit ? (
        <input
          type="text"
          defaultValue={subLabel.value}
          autoFocus
          className="text-xs text-gray-500 w-full"
          onBlur={toggleEdit}
        />
      ) : (
        <span
          className="text-xs text-gray-500 cursor-pointer"
          onClick={canEdit ? toggleEdit : undefined}
        >
          {subLabel.value}
        </span>
      )}
    </div>
  )
}

const SelectFieldWrapper = ({
  field,
  canEdit,
  canFillData,
  updateFieldProperty,
  error
}) => {
  const theme = useTheme()
  const { openModal, closeModal, modal } = useModal()
  const options = field.options !== '' ? field.options.split('|') : []
  const selectedField =
    field.type === 'multiSelector' ? field.value.split('|') : [field.value]

  const handleOnChange = value => {
    if (!canFillData) return
    updateFieldProperty({
      fieldId: field.id,
      prop: 'value',
      value: field.type === 'multiSelector' ? value.join('|') : value
    })
  }

  const updateOptions = options => {
    if (!canEdit) return
    updateFieldProperty({
      fieldId: field.id,
      prop: 'options',
      value: options.join('|')
    })
  }

  return (
    <>
      <Select
        isSearchable={false}
        isMulti={field.type === 'multiSelector'}
        options={options}
        value={canFillData ? selectedField : null}
        getOptionLabel={option => option}
        getOptionValue={option => option}
        className="w-full rounded-full"
        styles={reactSelectCustomStyles(theme)}
        onChange={handleOnChange}
        components={canEdit ? { MenuList: CustomMenu } : undefined}
        selectProps={{
          onAddOption: openModal
        }}
      />

      <AddSelectOptionModal
        open={modal.open}
        onClose={closeModal}
        data={{
          options
        }}
        updateOptions={updateOptions}
      />
    </>
  )
}

const CustomMenu = props => {
  const { t } = useTranslation()

  return (
    <components.MenuList {...props}>
      {props.children}
      <button
        onClick={props.selectProps.selectProps.onAddOption}
        className="flex items-center w-full text-sm text-primary-main px-4 py-2"
      >
        <AddIcon className="mr-1 dark:text-dark-light" />
        <span className="dark:text-dark-light">{t('ADD_OPTIONS')}</span>
      </button>
    </components.MenuList>
  )
}

export default FormField
