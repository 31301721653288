import React, { useEffect, useState } from 'react'
import Collaborator from './Collaborator'

const TaskCollaborators = ({
  taskId,
  innerProjectPermission,
  elmFor,
  removeCollaborator,
  collaborators
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
  }, [collaborators.length])

  const handleRemove = teammate => {
    removeCollaborator({ userId: teammate._id, userRole: teammate.role })
  }

  return (
    <div className="flex flex-grow space-x-2 items-center justify-between">
      {collaborators.map(teammate => (
        <Collaborator
          type={elmFor}
          key={teammate._id}
          teammate={teammate}
          taskId={taskId}
          handleRemove={handleRemove}
          removeBtn={innerProjectPermission.update}
        />
      ))}
    </div>
  )
}

export default TaskCollaborators
