import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Pagination } from 'global/globalComponents'
import { makeStyles, createStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Skeleton } from '@material-ui/lab'
// import Pill from 'global/globalComponents/Pill/Pill'
import moment from 'moment'
import workloadStyles from './workloadStyles'
import { useTranslation } from 'react-i18next'
import WorkloadTableRow from './WorkloadTableRow'
import { userRoles } from 'utils'
import { fetchUsersWorkload } from 'thunks/Tracking/action'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { darkenHex } from 'utils/hexToRgb'

// const countDaysOfWeekBetweenDates = (
//   sDate = '2022-05-09T00:00:00.000Z',
//   eDate = '2022-05-15T00:00:00.000Z'
// ) => {
//   const startDate = moment(sDate)
//   const endDate = moment(eDate)

//   endDate.add(1, 'day')
//   const daysOfWeekCount = {
//     0: 0,
//     1: 0,
//     2: 0,
//     3: 0,
//     4: 0,
//     5: 0,
//     6: 0
//   }
//   while (startDate < endDate) {
//     daysOfWeekCount[startDate.day()] = daysOfWeekCount[startDate.day()] + 1
//     startDate.add(1, 'day')
//   }

//   let weekDays = {}

//   ;['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].forEach((day, index) => {
//     weekDays[day] = daysOfWeekCount[index]
//   })

//   return weekDays
// }

// const totalProjectsToShow = 8
const totalTeammatesToShow = 10

const WorkloadTable = ({
  search = '',
  // timelineData,
  // loading,
  selectedDays
  // weekStartDate,
  // weekEndDate,
  // setLoading
}) => {
  // const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = workloadStyles()
  const classes2 = useStyles()
  const meData = useSelector(state => state.me.data)
  const teamVerticals = useSelector(state => state.teamVerticals.data)
  const { data: teammatesData, loading: teammatesLoading } = useSelector(
    state => state.userTeammates
  )
  const [visibleTeammates, setVisibleTeammates] = useState([])

  // const [visibleData, setVisibleData] = useState([])
  const [page, setPage] = useState(0)
  const [hoverIndex, setHoverIndex] = useState('')
  // const [newTimelineData, setNewTimelineDate] = useState([])
  // const [dayHours, setDayHours] = useState(8)
  const [weeklyWorkCapacity, setWeeklyWorkCapacity] = useState(0)
  // const [offDay, setOffDay] = useState([])
  const [workingDays, setWorkingDays] = useState({
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: false
  })
  const [dayWiseData, setDayWiseData] = useState({})
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    // Only admin and super admin can view other's workload
    // rest can view only their own workload
    if (
      meData.role === userRoles.USER_AGENCY ||
      meData.role === userRoles.AGENCY_ADMIN
    ) {
      let arr = [...teammatesData]

      if (search.trim() !== '') {
        arr = arr.filter(item =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      }

      setVisibleTeammates(
        arr.splice(page * totalTeammatesToShow, totalTeammatesToShow)
      )
    } else {
      setVisibleTeammates([meData])
    }
    // setVisibleTeammates([meData])
  }, [teammatesData, page, meData, search])

  useEffect(() => {
    if (meData?._id) {
      const workingDays = meData?.profile?.workingDays ||
        meData?.team?.profile?.workingDays || {
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: true,
          sun: true
        }

      setWeeklyWorkCapacity(
        meData?.profile?.capacity ?? meData?.team?.profile?.capacity ?? 56
      )
      setWorkingDays(workingDays)
    }
  }, [meData])

  // useEffect(() => {
  //   setDayHours(
  //     capacity / Object.keys(workingDays).filter(day => workingDays[day]).length
  //   )
  // }, [capacity, workingDays])

  // useEffect(() => {
  //   setOffDay(Object.keys(workingDays).filter(day => workingDays[day]))
  // }, [workingDays])

  // useEffect(() => {
  //   if (!newTimelineData.length) return setVisibleData([])

  //   let arr = [...newTimelineData]

  //   if (search.trim() !== '') {
  //     arr = arr.filter(item =>
  //       item.name.toLowerCase().includes(search.toLowerCase())
  //     )
  //   }

  //   setVisibleData(arr.splice(page * totalProjectsToShow, totalProjectsToShow))
  // }, [newTimelineData, page, search])

  useEffect(() => {
    if (visibleTeammates.length === 0) return
    handleFetchUserData()
    setMounted(true)
  }, [visibleTeammates])

  useEffect(
    function () {
      if (visibleTeammates.length === 0 || !mounted) return
      setDayWiseData({}) // reset data on date change
      handleFetchUserData()
    },
    [selectedDays]
  )

  // const userVertical = user => {
  //   if (user.verticals?.length) {
  //     const vertical = teamVerticals.find(el => el._id === user.verticals[0])
  //     if (vertical) {
  //       return <Pill variant="pink">{vertical.name}</Pill>
  //     } else {
  //       return null
  //     }
  //   } else {
  //     return null
  //   }
  // }

  const handleHoverCol = e => {
    setHoverIndex(parseInt(e.currentTarget.dataset.index))
  }

  const handleMouseLeave = e => {
    setHoverIndex('')
  }

  const handleFetchUserData = () => {
    if (!selectedDays) return

    const startDateWithTime = moment(selectedDays[0])
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .toISOString()
    const endDateWithTime = moment(selectedDays[selectedDays.length - 1])
      .set('hours', 23)
      .set('minutes', 59)
      .set('seconds', 59)
      .toISOString()

    const visibleTeammateIds = visibleTeammates.map(user => user._id)

    const callback = (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
      } else {
        // response syntax: {<userId1>: [...], <userId2>: [...]}
        for (let userId in res) {
          const obj = {}

          selectedDays.forEach(item => {
            obj[item] = []
          })

          res[userId].forEach(item => {
            const dateStr = new Date(item._id).toDateString()
            obj[dateStr] = item.tasks
          })

          setDayWiseData(prev => ({
            ...prev,
            [userId]: obj
          }))
        }
      }
    }

    fetchUsersWorkload(
      startDateWithTime,
      endDateWithTime,
      { users: visibleTeammateIds },
      callback
    )
  }

  return (
    <>
      <div
        className={clsx(
          'bg-white shadow sm:rounded-lg mb-6 dark:bg-dark-main overflow-auto',
          classes.table
        )}
      >
        <WorkloadTableHeader
          totalAssignee={teammatesData.length}
          selectedDays={selectedDays}
          classes={classes2}
        />
        {teammatesLoading ? (
          <LoadingAnimation />
        ) : (
          visibleTeammates.map((item, index) => (
            <WorkloadTableRow
              key={item._id}
              rowIndex={index}
              user={item}
              selectedDays={selectedDays}
              weeklyWorkCapacity={weeklyWorkCapacity}
              classes={classes2}
              workingDays={workingDays}
              teamVerticals={teamVerticals}
              handleHoverCol={handleHoverCol}
              handleMouseLeave={handleMouseLeave}
              isHovered={index === hoverIndex}
              dayWiseData={dayWiseData[item._id] || {}}
            />
          ))
        )}
      </div>

      {/*
      No need for pagination if current user is not admin or super admin
      Because only admin and super admin can see other teammates workload
      Rest can see only their own
       */}
      {(meData.role === userRoles.USER_AGENCY ||
        meData.role === userRoles.AGENCY_ADMIN) &&
        teammatesData.length > totalTeammatesToShow && (
          <Pagination
            handlePagination={setPage}
            totalResultsCount={teammatesData.length}
            visibleResultsCount={visibleTeammates.length}
            page={page}
            limit={totalTeammatesToShow}
          />
        )}
    </>
  )
}

const WorkloadTableHeader = ({ totalAssignee, selectedDays, classes }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-1">
      <div
        style={{ minWidth: 250, maxWidth: 300 }}
        className="text-xs font-medium text-gray-600 uppercase py-3 px-6 border-r bg-gray-100 dark:bg-dark-main2 dark:text-dark-light dark:border-dark-main1"
      >
        {t('ASSIGNEE')} ({totalAssignee})
      </div>
      {selectedDays.map(day => (
        <div
          className={clsx(
            'flex justify-between flex-1 text-xs py-3 px-6 border-r dark:border-dark-main1 bg-gray-100 text-gray-600 dark:bg-dark-main2 dark:text-dark-light',
            classes.column
          )}
        >
          <span className="font-medium capitalize">{day.split(' ')[0]}</span>
          <span className="text-gray-500 capitalize dark:text-dark-light">
            {day.split(' ').slice(2, 3).join(' ')}
          </span>
        </div>
      ))}
    </div>
  )
}

const LoadingAnimation = () => {
  return (
    <div className="w-full flex items-center">
      <div className="px-4 py-2">
        <Skeleton
          variant="rect"
          height={80}
          style={{ width: 220 }}
          className="rounded-md"
        />
      </div>

      {new Array(7).fill('').map((item, index) => (
        <div key={index} className="px-4 py-2 flex-1" style={{ minWidth: 120 }}>
          <Skeleton variant="rect" height={80} className="w-full rounded-md" />
        </div>
      ))}
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    arrow: {
      color: theme.palette.common.white
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      borderRadius: '10px'
    },
    columnFill: {
      position: 'absolute',
      left: 0,
      bottom: 0
    },
    column: {
      minWidth: 120
    },
    greenColumn: {
      backgroundColor:
        theme.palette.type === 'dark'
          ? darkenHex(theme.palette.success.dark, 0.6)
          : 'rgb(134, 239, 172)'
    }
  })
)

export default WorkloadTable
