import { useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useRedirect = () => {
  const history = useHistory()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const redirectUrl = searchParams.get('redirectUrl')

    if (redirectUrl) {
      history.replace(redirectUrl)
    }
  }, [])

  return null
}

export default useRedirect
