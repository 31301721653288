import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as CheckSquareIcon } from 'static/svg/check-square.svg'
import { ReactComponent as ArchiveIcon } from 'static/svg/archive.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { ReactComponent as SoftwareIcon } from 'static/svg/file-code.svg'
import DuplicateModal from './DuplicateModal'
import { useTranslation } from 'react-i18next'
import { openApprovalModal } from 'thunks/generlAppState/actions'
import {
  AlertModal,
  CheckBoxField,
  CustomPopper
} from 'global/globalComponents'
import { useWorkspacePermission } from 'customHooks'
import {
  UpdateWorkspceCompleteStatus,
  archiveWorkspace,
  createWorkspaceCopy,
  deleteWorkspace,
  moveWorkspaceToTrash
} from 'thunks/workspace/actions'
import EditProjectModal from './EditProjectModal/EditProjectModal'
import CreateTemplateFormProject from './CreateTemplateFromProject'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
import { List, ListItem, ListItemText, useTheme } from '@material-ui/core'
import clsx from 'clsx'

const WorkspacePopupMenu = ({
  workspace,
  placement, //top, bottom, left, right, top-start, top-end etc. (material doc)
  iconSize = 'large',
  workspacePermission
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [hasOpened, setHasOpened] = useState(false)

  const handleOpenPopper = e => {
    setAnchorEl(e.currentTarget)
    setHasOpened(true)
  }

  return (
    <>
      <button onClick={handleOpenPopper}>
        <ThreeDotsIcon
          className={clsx(
            iconSize === 'large' ? 'w-5 h-5' : 'w-4 h-4',
            'dark:text-dark-light'
          )}
        />
      </button>

      {hasOpened && (
        <PopupContents
          workspace={workspace}
          placement={placement}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          workspacePermission={workspacePermission}
        />
      )}
    </>
  )
}

const PopupContents = ({
  workspace,
  anchorEl,
  onClose,
  placement,
  workspacePermission
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const innerProjectPermission = useWorkspacePermission(workspace._id)
  const [alertModal, setAlertModal] = useState({
    name: null,
    data: {},
    loading: false
  })
  const [moveToTrashChecked, setMoveToTrashChecked] = useState(false)

  const openAlertModal = ({ name, data }) => {
    setAlertModal({ name: name, data, loading: false })
  }

  const closeAlertModal = () => {
    setAlertModal(prev => ({ ...prev, name: '' }))
  }

  const toggleLoading = () => {
    setAlertModal(prev => ({ ...prev, loading: !prev.loading }))
  }

  const handleOpenApprovalModal = workspace => {
    dispatch(
      openApprovalModal({
        module: 'project',
        moduleId: workspace._id,
        approvalItemName: workspace.name,
        approvers: workspace.assignedAgencyTeam.map(item => item.user)
      })
    )

    // onClose()
  }

  const handleArchiveWorkspace = () => {
    toggleLoading()
    dispatch(
      archiveWorkspace(
        alertModal.data._id,
        !alertModal.data.isArchived,
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            toggleLoading()
          } else {
            closeAlertModal()
          }
        }
      )
    )
  }

  const handleDeleteWorkspace = () => {
    toggleLoading()

    if (moveToTrashChecked) {
      dispatch(
        moveWorkspaceToTrash(alertModal.data._id, (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            toggleLoading()
          } else {
            closeAlertModal()
          }
        })
      )
    } else {
      dispatch(
        deleteWorkspace(alertModal.data._id, (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            toggleLoading()
          } else {
            dispatch(fireSuccessToaster(t('PROJECT_DELETED')))
            closeAlertModal()
          }
        })
      )
    }
  }

  const completeStatus = () => {
    toggleLoading()

    dispatch(
      UpdateWorkspceCompleteStatus(
        alertModal.data._id,
        { isComplete: true },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            toggleLoading()
          } else {
            closeAlertModal()
          }
        }
      )
    )
  }

  const duplicateWorkspaceHandler = data => {
    toggleLoading()
    dispatch(
      createWorkspaceCopy(alertModal.data._id, data, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(`${t('PROJECT_DUPLICATED')}`))
          closeAlertModal()
        } else {
          dispatch(fireErrorToaster(res))
          toggleLoading()
        }
      })
    )
    // setLoading(true)
    // dispatch(
    //   fireProcessToaster(
    //     `Duplicate of ${alertModal.data.name} is being created! Please wait...`
    //   )
    // )
  }

  const menuItems = []

  if (innerProjectPermission.update) {
    if (!workspace.isComplete) {
      menuItems.push(
        {
          label: t('EDIT_PROJECT'),
          method: () => openAlertModal({ name: 'edit', data: workspace }),
          icon: <Pen className="text-primary-main dark:text-dark-light" />
        },
        {
          label: t('APPROVALS'),
          method: () => handleOpenApprovalModal(workspace),
          icon: (
            <CheckSquareIcon className="text-primary-main dark:text-dark-light" />
          )
        },
        {
          label: t('MARK_COMPLETE'),
          method: () =>
            openAlertModal({ name: 'markComplete', data: workspace }),
          icon: (
            <CheckCircleIcon className="text-primary-main dark:text-dark-light" />
          )
        }
      )

      if (workspacePermission.create) {
        menuItems.push(
          {
            label: t('DUPLICATE'),
            method: () =>
              openAlertModal({ name: 'duplicate', data: workspace }),
            icon: (
              <CopyIcon className="text-primary-main dark:text-dark-light" />
            )
          },
          {
            label: t('CREATE_TEMPLATE'),
            method: () =>
              openAlertModal({ name: 'createTemplate', data: workspace }),
            icon: (
              <SoftwareIcon className="text-primary-main dark:text-dark-light" />
            )
          }
        )
      }
    }
  }

  if (workspacePermission.delete) {
    menuItems.push(
      {
        label: workspace.isArchived ? t('UNARCHIVE') : t('ARCHIVE'),
        method: () => openAlertModal({ name: 'archive', data: workspace }),
        icon: <ArchiveIcon className="text-primary-main dark:text-dark-light" />
      },
      {
        label: t('DELETE'),
        method: () => openAlertModal({ name: 'delete', data: workspace }),
        icon: <DeleteIcon />,
        isDanger: true
      }
    )
  }

  const handleListItemClick = (e, item) => {
    item.method(e, item)

    if (!item.hasSubmenu) {
      onClose()
    }
  }

  return (
    <>
      <CustomPopper
        anchorEl={anchorEl}
        onClose={onClose}
        placement={placement ? placement : 'bottom-end'}
      >
        <div
          className="dark:text-dark-light"
          style={{
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            backgroundColor:
              theme.palette.type === 'dark'
                ? theme.custom.darkMode.dark2
                : '#fff'
          }}
        >
          <List style={{ padding: '4px 0' }}>
            {menuItems.map((item, index) => (
              <ListItem
                onClick={e => handleListItemClick(e, item)}
                style={{ padding: '8px 16px', minWidth: 115 }}
                // classes={{ gutters: classes.listItem }}
                button
              >
                <div className="flex items-center py-1">
                  {item.icon && (
                    <span
                      className={clsx(
                        'mr-2 dark:text-dark-light',
                        item.isDanger ? 'text-red-400' : 'text-primary-main'
                      )}
                    >
                      {item.icon}
                    </span>
                  )}
                  <ListItemText
                    style={{ margin: 0 }}
                    primary={
                      <p
                        className={clsx(
                          'capitalize text-xs 2xl:text-sm dark:text-dark-light',
                          item.isDanger ? 'text-red-400' : 'text-primary-main'
                        )}
                      >
                        {item.label}
                      </p>
                    }
                    // classes={{ root: classes.listItemText }}
                  />
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      </CustomPopper>

      <EditProjectModal
        open={alertModal.name === 'edit'}
        onClose={closeAlertModal}
        workspaceData={alertModal.data}
      />
      <AlertModal
        open={alertModal.name === 'archive'}
        heading={alertModal.data.name}
        warningText={t('WARNING_PROJECT_ARCHIVE', {
          data: alertModal.data.isArchived ? t('UNARCHIVE') : t('ARCHIVE')
        })}
        handleDialog={closeAlertModal}
        handleDeleteAction={handleArchiveWorkspace}
        loading={alertModal.loading}
        deleteBtnText={
          alertModal.data.isArchived ? t('UNARCHIVE') : t('ARCHIVE')
        }
      />
      <AlertModal
        open={alertModal.name === 'markComplete'}
        heading={alertModal.data.name}
        warningText={t('WARNING_PROJECT_COMPLETE')}
        handleDialog={closeAlertModal}
        handleDeleteAction={completeStatus}
        loading={alertModal.loading}
        deleteBtnText={t('COMPLETE')}
      />
      <CreateTemplateFormProject
        open={alertModal.name === 'createTemplate'}
        handleClose={closeAlertModal}
        workspace={alertModal.data}
      />

      <DuplicateModal
        open={alertModal.name === 'duplicate'}
        handleDialog={closeAlertModal}
        handleDuplicateAction={duplicateWorkspaceHandler}
        loading={alertModal.loading}
        workspace={workspace}
      />
      <AlertModal
        heading={`${t('ARE_YOU_SURE')}?`}
        open={alertModal.name === 'delete'}
        warningText={t('PROJECT_DELETE_ALERT_MESSAGE', {
          data: alertModal.data.name
        })}
        checkboxElm={
          <>
            <CheckBoxField
              label={t('MOVE_TO_TRASH_CHECKBOX_MESSAGE')}
              checked={moveToTrashChecked}
              onChange={e => setMoveToTrashChecked(e.target.checked)}
            />
          </>
        }
        handleDialog={closeAlertModal}
        handleDeleteAction={handleDeleteWorkspace}
        deleteBtnText={moveToTrashChecked ? t('TRASH') : t('DELETE')}
        loading={alertModal.loading}
      />
    </>
  )
}

export default WorkspacePopupMenu
