import React, { useState } from 'react'
import { FormLabel } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import TemplatesPreviewModal from './TemplatesPreviewModal'

const TemplateSelector = ({
  invoiceTemplateOptions,
  selectedInvoiceTemplate,
  setSelectedInvoiceTemplate
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [templatePreview, setTemplatePreview] = useState(false)

  const handleSelectTemplate = templateId => {
    const template = invoiceTemplateOptions.find(
      item => item.value === templateId
    )
    setSelectedInvoiceTemplate(template)
    setTemplatePreview(false)
  }

  return (
    <>
      <div>
        <FormLabel>{t('TEMPLATE')}</FormLabel>
        <Select
          menuPosition="fixed"
          isSearchable={false}
          // className={classes.textFieldStyle}
          onChange={setSelectedInvoiceTemplate}
          options={invoiceTemplateOptions}
          styles={{
            ...reactSelectCustomStyles(theme),
            menuPortal: provided => ({ ...provided, zIndex: 9999 })
          }}
          value={selectedInvoiceTemplate}
          placeholder={t('SELECT_TEMPLATE')}
        />

        <p className="text-xs text-gray-500 mt-1">
          {t('EXPLORE_TEMPLATES')}{' '}
          <button
            onClick={() => setTemplatePreview(true)}
            className="text-primary-main dark:text-dark-light"
          >
            {t('HERE')}
          </button>
        </p>
      </div>

      <TemplatesPreviewModal
        open={templatePreview}
        selectedInvoiceTemplateId={selectedInvoiceTemplate.value}
        handleSelectTemplate={handleSelectTemplate}
        onClose={() => setTemplatePreview(false)}
      />
    </>
  )
}

export default TemplateSelector
