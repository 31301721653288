import React from 'react'
import { ColoredAvatars } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'

const ClientAndTeammateNotificationMessage = ({
  category,
  field,
  source,
  before,
  after,
  user
}) => {
  const { t } = useTranslation()

  const generateMessage = () => {
    const categoryKey = {
      client: t('CLIENT'),
      agencyTeammate: t('AGENCY_TEAM_MEMBER'),
      clientTeammate: t('CLIENT_TEAM_MEMBER')
    }[category]

    if (field === 'add') {
      if (user) {
        return (
          <div className="flex items-center">
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_MEMBER_ADD', {
                  data: categoryKey.toLowerCase()
                })
              }}
            ></span>
            &nbsp;:&nbsp;
            <ColoredAvatars
              user={after || { name: 'unknown' }}
              tooltip={true}
              size="medium"
            />
          </div>
        )
      } else {
        return (
          <div className="flex items-center">
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_MEMBER_SIGNED_UP', {
                  data: categoryKey.toLowerCase(),
                  userName: after?.name
                })
              }}
            ></span>
            &nbsp;:&nbsp;
            <ColoredAvatars
              user={after || { name: 'Unknown' }}
              tooltip={true}
              size="medium"
            />
          </div>
        )
      }
    }

    if (field === 'delete') {
      return (
        <div className="flex items-center">
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_MEMBER_DELETE', {
                data: categoryKey.toLowerCase()
              })
            }}
          ></span>
          &nbsp;
          <span className="font-semibold">"{before?.name || after?.name}"</span>
          &nbsp;:&nbsp;
          <ColoredAvatars
            user={before || after || { name: 'unknown' }}
            tooltip={true}
            size="medium"
          />
        </div>
      )
    }

    if (field === 'update') {
      if (user?._id) {
        if (user._id === after?._id) {
          return <>{t('NOTIFICATION_MEMBER_PROFILE_UPDATE')}</>
        } else if (after?._id) {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_MEMBER_OTHER_PROFILE_UPDATE', {
                  userName: after?.name
                })
              }}
            ></span>
          )
        }
      }
    }

    if (field === 'client_create') {
      return (
        <div className="flex items-center">
          {t('NOTIFICATION_MEMBER_CLIENT_CREATE', {
            userName: after?.name
          })}
          &nbsp;:&nbsp;
          <ColoredAvatars
            user={after || { name: 'unknown' }}
            tooltip={true}
            size="medium"
          />
        </div>
      )
    }

    if (category === 'agencyTeammate') {
      if (field === 'agencyRepresentative') {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_AGENCY_MEMBER_REPRESENTATIVE', {
                clientName: after?.name
              })
            }}
          ></span>
        )
      }
    }

    if (category === 'client') {
      if (field === 'agencyRepresentative') {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_CLIENT_REPRESENTATIVE', {
                userName: after?.name
              })
            }}
          ></span>
        )
      }
    }
  }

  return (
    <div>
      <div className="text-smaller">{generateMessage()}</div>
    </div>
  )
}

export default ClientAndTeammateNotificationMessage
