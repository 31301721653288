import axios from 'axios'
import { getErrorMessages } from 'utils/errorMessages'
import { USER_ACTIVE_PLAN_INFO } from './reducers'
const errorMessages = getErrorMessages()

/**
 * Fetches the active plan infos of the current user
 * @param {Function} callback callback fn
 */
export const fetchUserActivePlanInfo = callback => {
  return async dispatch => {
    // dispatch({ type: USER_ACTIVE_PLAN_INFO.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/agencypayment/user'
      })
      if (callback) callback(res.data)
      dispatch({ type: USER_ACTIVE_PLAN_INFO.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({ type: USER_ACTIVE_PLAN_INFO.ERROR, payload: err })
    }
  }
}
