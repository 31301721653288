import produce from 'immer'

export const APPROVAL = {
  FETCHED_RECEIVED_APPROVALS: 'APPROVAL_FETCHED_RECEIVED_APPROVALS',
  FETCHED_RAISED_APPROVALS: 'APPROVAL_FETCHED_RAISED_APPROVALS',
  UPDATE_RECEIVED_APPROVAL: 'APPROVAL_UPDATE_RECEIVED_APPROVAL',
  REMOVE_RAISED_APPROVAL: 'APPROVAL_REMOVE_RAISED_APPROVAL'
}

const initialState = {
  loading: true,
  fetched: false,
  data: [],
  received: {
    loading: true,
    data: [],
    fetched: false
  },
  raised: {
    loading: true,
    data: [],
    fetched: false
  }
}

const approvalReducer = produce((draft, action) => {
  switch (action.type) {
    case APPROVAL.FETCHED_RECEIVED_APPROVALS: {
      draft.received.fetched = true
      draft.received.loading = false
      draft.received.data = action.payload
      return draft
    }

    case APPROVAL.FETCHED_RAISED_APPROVALS: {
      draft.raised.fetched = true
      draft.raised.loading = false
      draft.raised.data = action.payload
      return draft
    }

    case APPROVAL.UPDATE_RECEIVED_APPROVAL: {
      const idx = draft.received.data.findIndex(
        item => item._id === action.payload._id
      )

      if (idx !== -1) {
        draft.received.data.splice(idx, 1, action.payload)
      }
      return draft
    }

    case APPROVAL.REMOVE_RAISED_APPROVAL: {
      draft.raised.data = draft.raised.data.filter(
        item => item._id !== action.payload
      )
      return draft
    }

    default:
      return draft
  }
}, initialState)

export default approvalReducer
