const initialState = {
  loading: true,
  error: null,
  data: {}
}

export const USER_ACTIVE_PLAN_INFO = {
  LOADING: 'LOADING_USER_ACTIVE_PLAN',
  ERROR: 'ERROR_IN_LOADING_USER_ACTIVE_PLAN',
  FETCHED: 'FETCHED_USER_ACTIVE_PLAN'
}

const userActivePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIVE_PLAN_INFO.LOADING:
      return { ...state, loading: true, error: null }

    case USER_ACTIVE_PLAN_INFO.ERROR:
      return { ...state, loading: false, error: action.payload }

    case USER_ACTIVE_PLAN_INFO.FETCHED:
      return { ...state, loading: false, error: null, data: action.payload }

    default:
      return state
  }
}

export default userActivePlanReducer
