import { userRoles } from 'utils'

export const getStorageAccessPermission = meRole => {
  return {
    personalStorage: meRole < userRoles.USER_CLIENT,
    clientPersonalStorage: meRole >= userRoles.USER_CLIENT,
    teamStorage: meRole < userRoles.USER_CLIENT,
    clientStorage:
      meRole === userRoles.USER_AGENCY || meRole === userRoles.USER_ADMIN,
    sharedStorage: true,
    sharedWithMe: true,
    projectStorage: true
  }
}

export const staticDirId = {
  TEAM_SHARED: 'teamShared',
  SHARED_WITH_ME: 'sharedWithMe'
}

export const dirPermissions = {
  viewOnly: { view: true },
  all: { create: true, view: true, update: true, delete: true },
  updateOnly: { create: false, view: true, update: true, delete: false }
}
