export const ME = {
  LOADING: 'LOADING_ME',
  FETCHED: 'ME_FETCHED',
  ERROR: 'ME_ERROR',
  UPDATE: 'ME_UPDATE',
  UPDATE_AGENCY_DOMAIN: 'ME_UPDATE_AGENCY_DOMAIN',
  SET_LEFT_TRIAL_DAYS: 'ME_SET_LEFT_TRIAL_DAYS'
}

const initialState = {
  error: false,
  loading: true,
  data: {},
  fetched: false,
  leftTrialDays: null
}

const meReducer = (state = initialState, action) => {
  switch (action.type) {
    case ME.LOADING:
      return {
        ...state,
        loading: true,
        error: false
      }
    case ME.ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }
    case ME.FETCHED:
      return {
        ...state,
        fetched: true,
        loading: false,
        error: false,
        data: action.payload
      }
    case ME.UPDATE:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case ME.UPDATE_AGENCY_DOMAIN:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          whitelabelUrl: action.payload
        }
      }
    case ME.SET_LEFT_TRIAL_DAYS: {
      return { ...state, leftTrialDays: action.payload }
    }
    default:
      return state
  }
}

export default meReducer
