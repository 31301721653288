const initialState = {
  loading: true,
  data: [],
  error: null
}

export const CANCEL_CLIENT_SERVICE_REQUEST = {
  FETCHED: 'FETCHED_CANCEL_CLIENT_SERVICE_REQUEST',
  LOADING: 'LOADING_CANCEL_CLIENT_SERVICE_REQUEST',
  ERROR: 'ERROR_CANCEL_CLIENT_SERVICE_REQUEST',
  REMOVE: 'REMOVE_CANCEL_REQUEST_FROM_LIST'
}

const cancelClientServiceRequest = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_CLIENT_SERVICE_REQUEST.LOADING:
      return { ...state, loading: true }

    case CANCEL_CLIENT_SERVICE_REQUEST.FETCHED:
      return { ...state, loading: false, data: action.payload, fetched: true }

    case CANCEL_CLIENT_SERVICE_REQUEST.ERROR:
      return { ...state, loading: false }

    case CANCEL_CLIENT_SERVICE_REQUEST.REMOVE:
      const filteredData = state.data.filter(
        (req) => req._id !== action.payload
      )
      return { ...state, data: filteredData }

    default:
      return state
  }
}

export default cancelClientServiceRequest
