// import { useState } from 'react'
import { useDispatch } from 'react-redux'
import ImageCard from './ImageCard'
import { NoData } from 'static/Images'
import templateStyles from './templateStyles'
import { createProjectTemplate } from 'thunks/projectTemplate/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useTranslation } from 'react-i18next'

const Templates = ({
  templatesData,
  setPageState,
  setSingleTemplateData,
  openDeleteTemplateModal,
  updateDataHandler,
  openCreateUpdateTemplateModal
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = templateStyles()
  // const [loading, setLoading] = useState(false)

  const handleSingleTemplate = data => {
    setPageState(1)
    setSingleTemplateData(data)
  }

  const handleDuplicateTemplate = data => {
    // setLoading(true)
    createProjectTemplate(
      {
        data: {
          title: `${data.title}_copy`,
          description: data.description,
          category: data.category ?? '',
          type: 'private',
          content: data.content
        }
      },
      (res, err) => {
        // setLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster(t('TEMPLATE_DUPLICATED_SUCCESSFULLY')))
          updateDataHandler(res, { new: true })
        } else {
          dispatch(fireErrorToaster(res))
        }
      }
    )
  }

  return (
    <div>
      <div className="mb-8">
        <h1>
          <span className="text-primary-dark font-medium dark:text-dark-light">
            {t('TEMPLATES')}
          </span>{' '}
        </h1>
      </div>
      {templatesData.length ? (
        <div className={`${classes.templateGrid} pb-4`}>
          {templatesData.map(temp => (
            <ImageCard
              data={temp}
              handleSingleTemplate={() => handleSingleTemplate(temp)}
              openDeleteTemplateModal={openDeleteTemplateModal}
              handleDuplicateTemplate={handleDuplicateTemplate}
              openCreateUpdateTemplateModal={openCreateUpdateTemplateModal}
            />
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center gap-4 w-full h-full text-center bg-gray-50 dark:bg-dark-main2 px-6 my-4">
          {/* <p className="text-gray-500">No tasks available</p> */}
          <div style={{ width: '200px', height: '250px' }}>
            <img
              src={NoData}
              alt="No Access"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Templates
