import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Ticket } from 'static/svg/ticket.svg'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import { Button } from '@material-ui/core'

const NoCouponsFound = ({ openModal }) => {
  const { t } = useTranslation()

  return (
    <div className="bg-white p-6 rounded flex justify-center items-center text-center dark:bg-dark-main">
      <div>
        <Ticket className="text-gray-400 w-10 h-10 mx-auto mb-4" />
        <div className="font-medium text-xl mb-2">{t('NO_COUPONS_TEXT')}</div>
        <div className="text-sm text-gray-500 mb-4">
          {t('CREATE_COUPON_MESSAGE')}
        </div>
        <Button onClick={() => openModal()} startIcon={<PlusIcon />}>
          {t('CREATE_COUPON')}
        </Button>
      </div>
    </div>
  )
}

export default NoCouponsFound
