import axios from 'axios'
import { ACCOUNT_NOTIFICATION } from './reducers'
import { catchAsyncDispatch } from 'utils'

/**
 * Fetch unread notifications count
 * @param {String} group
 * @param {Function} callback (optional)
 */
export const fetchUnreadNotificationsCount = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/logs/notification/unread'
    })
    const count = res.data[0]?.unread ?? 0

    if (callback) callback(count)
    dispatch({
      type: ACCOUNT_NOTIFICATION.FETCHED_UNREAD_NOTIFICATIONS_COUNT,
      payload: count
    })
  }, callback)
}

/**
 * Fetch account notifications
 * @param {String} data.group 'all', 'workspace', 'task', 'invoice', 'service', 'teammate'
 * @param {Number} data.page
 * @param {Function} callback (optional)
 */
export const fetchNotifications = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/logs/notification?type=${data.group}&page=${data.page}`
    })

    dispatch(fetchUnreadNotificationsCount())
    dispatch({
      type:
        data.page === 0
          ? ACCOUNT_NOTIFICATION.FETCHED
          : ACCOUNT_NOTIFICATION.LOAD_MORE,
      payload: { group: data.group, data: res.data }
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch remaining notifications of a group (things are really confusing here bcoz of naming, will change this later)
 * @param {String} data.group task, workspace
 * @param {String} data.id if task then task id, if workspace then workspace id
 * @param {String} data.category notification category => all, workspace, task
 * @param {Function} callback (optional)
 */
export const fetchRemainingNotifications = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/logs/notification/specific?type=${data.group}&${data.group}=${data.id}`
    })

    dispatch({
      type: ACCOUNT_NOTIFICATION.UPDATE_ALL,
      payload: { category: data.category, groupId: data.id, data: res.data }
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Mark notifications group as read
 * @param {Object} data.data group (group name), key (group name)
 * @param {String} data.category all, task, workspace
 */
export const markSingleNotificationAsRead = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/logs/read/${data.id}`
    })

    dispatch({
      type: ACCOUNT_NOTIFICATION.MARK_AS_READ,
      payload: {
        notificationId: data.id,
        category: data.category,
        meId: data.meId
      }
    })

    dispatch(fetchUnreadNotificationsCount())
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Mark all notifications as read
 * @param {String} data.category all, task, workspace, teammate, invoice, service
 */
export const markAllNotificationsRead = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: '/logs/notification/allread'
    })

    dispatch({
      type: ACCOUNT_NOTIFICATION.MARK_ALL_AS_READ,
      payload: { category: data.category, meId: data.meId }
    })

    dispatch(fetchUnreadNotificationsCount())
    if (callback) callback(res.data)
  }, callback)
}

export const addNewNotification = data => {
  return (dispatch, getState) => {
    const { userWorkspaces } = getState()
    const transformedData = { ...data }

    if (
      [
        'workspace',
        'workspaceBoard',
        'workspaceView',
        'workspaceSection'
      ].includes(data.category)
    ) {
      const workspace = userWorkspaces.data.find(
        item => item._id === data.workspace
      )

      if (!workspace) return
      transformedData.workspace = workspace
    }

    dispatch({ type: ACCOUNT_NOTIFICATION.ADD_NEW, payload: transformedData })
  }
}
