import React, { useState, useEffect } from 'react'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Tr,
  Td
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { useSelector, useDispatch } from 'react-redux'
import { PopupMenu, ToggleSwitch, Tooltip } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
import { updateService, deleteService } from 'thunks/service/actions'
import { Button } from '@material-ui/core'
import currency from 'static/Currency/Currency'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { useTranslation } from 'react-i18next'
import { useModal } from 'customHooks'
import Pill from 'global/globalComponents/Pill/Pill'
import AttachAddOnsPopper from './AttachAddOnsPopper'
import AttachWorkflowPopper from './AttachWorkflowPopper'

const totalServicesToShow = 8

const ServiceTable = ({
  search = '',
  handleOpenServiceModal,
  shareCheckoutLinkHandler
}) => {
  const { t } = useTranslation()
  // const errorMessages = getErrorMessages()
  const dispatch = useDispatch()
  const { isMarketplaceDomain } = useSelector(state => state.generalAppState)
  const [visibleServices, setVisibleServices] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const permission = useSelector(state => state.permission)
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])
  const userServices = useSelector(state => state.userServices)
  const userServicesData = userServices.data
  const {
    modal: alertModal,
    openModal: openAlert,
    closeModal: closeAlert,
    toggleLoading: toggleAlertLoading
  } = useModal()
  // const [serviceAlert, setServiceAlert] = useState({
  //   open: false,
  //   loading: false,
  //   data: {}
  // })
  const [copy, setCopy] = useState('')
  // const [agencyStripeId, setAgencyStripeId] = useState('')
  // const [linkGenerated, setLinkGenerated] = useState(null)

  useEffect(() => {
    if (userServicesData.length) {
      if (search.length > 0) {
        let arr = [...userServicesData].filter(item =>
          item?.name.toLowerCase().includes(search.toLowerCase())
        )
        setVisibleServices(
          arr.splice(page * totalServicesToShow, totalServicesToShow)
        )
        setPaginationTotalDataCount(arr?.length)
      } else {
        setVisibleServices(
          [...userServicesData].splice(
            page * totalServicesToShow,
            totalServicesToShow
          )
        )
        setPaginationTotalDataCount(userServicesData.length)
      }
    } else {
      setVisibleServices([])
    }
  }, [userServicesData, page, search, paginationTotalDataCount])

  // useEffect(() => {
  //   dispatch(
  //     fetchAgencyStripeAccountId((res, err) => {
  //       if (!err) {
  //         setAgencyStripeId(res.accountId)
  //       }
  //     })
  //   )
  // }, [])

  const handleSwitch = service => {
    if (permission.loading) return

    if (permission.data.access?.services.update) {
      if (!service.isActive) {
        dispatch(
          fireErrorToaster(t('ERROR_YOU_HAVE_TO_UNARCHIVE_YOUR_PLAN_FIRST'))
        )
      } else {
        setToggleSwitchLoading(prevState => [...prevState, service._id])
        dispatch(
          updateService(
            {
              id: service._id,
              data: {
                displayOnCatalog: !service.displayOnCatalog,
                name: service.name,
                description: service.description,
                offerings: service.offerings,
                field: 'displayOnCatalog'
              }
            },
            (res, err) => {
              if (err) {
                dispatch(fireErrorToaster(res))
              } else {
                dispatch(fireSuccessToaster(t('SERVICE_CREATED_SUCCESSFULLY')))
              }

              setToggleSwitchLoading(prevState =>
                prevState.filter(item => item !== service._id)
              )
            }
          )
        )
      }
    } else {
      dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
    }
  }

  const openServiceAlert = data => {
    if (permission.data.access?.services.delete) {
      openAlert(data)
    } else {
      dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
    }
  }

  const handleDeleteService = () => {
    toggleAlertLoading()
    dispatch(
      deleteService({ id: alertModal.data._id }, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(t('SERVICE_DELETED_SUCCESSFULLY')))
          closeAlert()
        } else {
          toggleAlertLoading()
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th className="whitespace-nowrap">{t('NAME')}</Th>
          <Th className="text-right">{t('AMOUNT')}</Th>
          <Th className="whitespace-nowrap">{t('CREATED_AT')}</Th>
          <Th className="text-center">{t('OFFERINGS')}</Th>
          {!isMarketplaceDomain && (
            <>
              <Th>{t('ADD_ONS')}</Th>
              <Th>{t('WORKFLOW')}</Th>
              <Th className="text-center whitespace-nowrap">
                {t('SHOW_IN_CATALOG')}
              </Th>

              <Th className="text-center whitespace-nowrap">
                {t('CHECKOUT_LINK')}
              </Th>
            </>
          )}
          {(permission.data.access?.services?.update ||
            permission.data.access?.services?.delete) && (
            <Th className="text-center">{t('ACTION')}</Th>
          )}
        </Thead>
        <Tbody>
          {userServices.loading ? (
            <TableDataLoading cols={8} />
          ) : visibleServices.length > 0 ? (
            visibleServices.map(service => (
              <Row
                key={service._id}
                service={service}
                access={permission.data.access}
                handleSwitch={handleSwitch}
                toggleSwitchLoading={toggleSwitchLoading}
                handleOpenServiceModal={handleOpenServiceModal}
                shareCheckoutLinkHandler={shareCheckoutLinkHandler}
                openServiceAlert={openServiceAlert}
                copy={copy}
                setCopy={setCopy}
                isMarketplaceDomain={isMarketplaceDomain}
                // Show only the same currency add-ons
                allAddOns={userServices.addOns.data.filter(
                  addOn => addOn.currency === service.currency
                )}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <td
                colSpan="8"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                {t('THERE_ARE_NO_SERVICES')}
              </td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      {!userServices.loading &&
        paginationTotalDataCount > totalServicesToShow && (
          <Pagination
            handlePagination={setPage}
            totalResultsCount={paginationTotalDataCount}
            visibleResultsCount={visibleServices.length}
            page={page}
            limit={totalServicesToShow}
          />
        )}
      <AlertModal
        warningText={t('WARNING_DELETE_MESSAGE', {
          data: alertModal.data.name
        })}
        open={alertModal.open}
        handleDialog={closeAlert}
        handleDeleteAction={handleDeleteService}
        loading={alertModal.loading}
      />
    </div>
  )
}

const Row = ({
  service,
  handleSwitch,
  access,
  toggleSwitchLoading,
  handleOpenServiceModal,
  openServiceAlert,
  copy,
  setCopy,
  allAddOns,
  isMarketplaceDomain
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(false)
  const [workflowAnchorEl, setWorkflowAnchorEl] = useState(false)
  const [attachedWorkflow, setAttachedWorkflow] = useState({})
  const dateFormatter = date => {
    return date.slice(0, 10)
  }

  useEffect(() => {
    setTimeout(() => setCopy(false), 15000)
  }, [copy])

  useEffect(() => {
    if (service.clientOnboarding) {
      setAttachedWorkflow(service.clientOnboarding)
    }
  }, [service.clientOnboarding])

  const getMenuItems = () => {
    let arr = []
    if (access?.services.update) {
      arr.push({
        label: t('EDIT'),
        method: handleEditClick,
        icon: <EditIcon className="text-primary-main dark:text-dark-light" />
      })
    }
    if (access?.services.delete) {
      arr.push({
        label: t('DELETE'),
        method: handleDeleteClick,
        icon: <DeleteIcon />,
        isDanger: true
      })
    }
    return arr
  }

  const handleDeleteClick = () => {
    if (service.subscriptionCount) {
      dispatch(fireWarningToaster(t('SERVICE_DELETE_WARNING')))
      return
    }

    openServiceAlert(service)
  }

  const handleEditClick = () => {
    if (service.subscriptionCount) {
      dispatch(fireWarningToaster(t('SERVICE_EDIT_WARNING')))
      return
    }

    handleOpenServiceModal(service)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.origin}/services/${service._id}/checkout`
    )
    // dispatch(fireSuccessToaster('URL Copied!'))
    setCopy(service._id)
  }

  const handleAttachAddOn = () => {
    if (access?.services.update) {
      return (
        <div>
          <Tooltip title={t('ATTACH_ADD_ONS')} placement="top">
            <AddIcon
              className="h-5 w-5 p-1 ml-1 cursor-pointer border rounded-full"
              onClick={e => setAnchorEl(e.currentTarget)}
            />
          </Tooltip>
        </div>
      )
    }
  }

  const handleRemoveWorkflow = () => {
    setAttachedWorkflow({})
    dispatch(
      updateService(
        {
          id: service._id,
          data: {
            clientOnboarding: null
          }
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const serviceAddOns = []
  allAddOns.forEach(addOn => {
    const match = addOn.services.find(serviceId => serviceId === service._id)
    if (match) serviceAddOns.push(addOn)
  })

  return (
    <>
      <Tr key={service._id}>
        <Td className=" px-6 py-4 whitespace-nowrap">
          <div className="text-primary-mid-dark font-medium dark:text-dark-light">
            {service.name}
          </div>
        </Td>
        <Td className="whitespace-nowrap text-right text-custom-gray-main">
          {service.currency &&
            currency[service.currency.toUpperCase()]?.symbol_native}
          {isMarketplaceDomain ? service.amount : service.amount * 0.01}
        </Td>
        <Td className="whitespace-nowrap text-xs text-custom-gray-main">
          {new Date(dateFormatter(service.createdAt)).toLocaleDateString(
            undefined,
            {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            }
          )}
        </Td>
        <Td className="text-center text-xs text-custom-gray-main">
          <Tooltip
            title={service.offerings.length + ' offering(s) offered'}
            style={{ marginTop: '10px' }}
          >
            <span>{service.offerings?.length}</span>
          </Tooltip>
        </Td>

        {!isMarketplaceDomain && (
          <>
            <Td>
              <>
                {Boolean(serviceAddOns.length)
                  ? serviceAddOns.slice(0, 2).map((addOn, index) => (
                      <div className={index === 0 ? 'leading-6' : 'mt-2'}>
                        {index === 0 ? (
                          <div className="flex items-center leading-3">
                            <Pill variant="pink">{addOn.name}</Pill>
                            {serviceAddOns.length === 1 && handleAttachAddOn()}
                          </div>
                        ) : (
                          <div className="flex items-center leading-3">
                            <Pill variant="blue">{addOn.name}</Pill>

                            {serviceAddOns.length > 2 ? (
                              <Pill
                                variant="black"
                                style={{
                                  fontSize: 10,
                                  fontWeight: 'bold'
                                }}
                              >
                                +{serviceAddOns.length - 2}
                              </Pill>
                            ) : null}
                            {handleAttachAddOn()}
                          </div>
                        )}
                      </div>
                    ))
                  : handleAttachAddOn()}
              </>
            </Td>

            <Td>
              {attachedWorkflow._id ? (
                <div className="inline-block relative show-on-hover-parent">
                  <Pill variant="blue">{attachedWorkflow.templateName}</Pill>
                  <button
                    style={{ top: -4, right: -4 }}
                    className="absolute top-0 right-0 bg-red-500 text-white text-sm rounded-full p-0.5 show-on-hover-child"
                    onClick={handleRemoveWorkflow}
                  >
                    <CrossIcon />
                  </button>
                </div>
              ) : (
                <Tooltip
                  title={t('ATTACH_WORKFLOW')}
                  placement="top"
                  className="capitalize"
                >
                  <AddIcon
                    className="h-5 w-5 p-1 ml-1 cursor-pointer border rounded-full"
                    onClick={e => setWorkflowAnchorEl(e.currentTarget)}
                  />
                </Tooltip>
              )}
            </Td>

            <Td className="text-center whitespace-nowrap">
              <ToggleSwitch
                checked={service.displayOnCatalog}
                onChange={() => handleSwitch(service)}
                loading={toggleSwitchLoading.includes(service._id)}
                style={{ margin: '0 auto' }}
              />
            </Td>

            <Td className="text-center">
              <Button
                variant="outlined"
                // onClick={handleCreateLink}
                onClick={handleCopyLink}
                style={{ margin: '0 auto' }}
                className="whitespace-nowrap"
                size="small"
              >
                {/* Create Link */}
                {copy !== service._id && <CopyIcon fontSize="1rem" />}
                <span className="inline-block py-1 font-medium ml-2">
                  {copy === service._id ? t('COPIED') : t('COPY_SERVICE_URL')}
                </span>
              </Button>
            </Td>
          </>
        )}

        {(access?.services.update || access?.services.delete) && (
          <Td className="text-center whitespace-nowrap">
            <PopupMenu menuItems={getMenuItems()} />
          </Td>
        )}
      </Tr>

      <AttachAddOnsPopper
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        allAddOns={allAddOns}
        selectedAddOns={serviceAddOns}
        serviceId={service._id}
      />

      <AttachWorkflowPopper
        anchorEl={workflowAnchorEl}
        onClose={() => setWorkflowAnchorEl(null)}
        serviceId={service._id}
      />
    </>
  )
}

export default ServiceTable
