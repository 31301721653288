import axios from 'axios'
import { catchAsyncDispatch } from 'utils'
import { FEEDS } from './reducer'

export const fetchFeeds = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const res = await axios({
      method: 'GET',
      url: `/user/feed?limit=${data.limit}&page=${data.page}`
    })

    // Filtering out notifications from feed data
    const filteredFeeds = res.data.filter(feed => {
      if (feed.category) {
        return !['workspace', 'task', 'subTask', 'workspaceView'].includes(
          feed.category
        )
      }
      return feed
    })

    dispatch({
      type: FEEDS.FETCH_FEEDS,
      payload: { data: filteredFeeds, page: data.page }
    })
    if (callback) callback(filteredFeeds)
  }, callback)
}

export const setSelectedFeed = (data, callback) => {
  return dispatch => {
    dispatch({
      type: FEEDS.SELECTED_FEED,
      payload: data
    })
  }
}

export const setLoading = data => dispatch =>
  dispatch({
    type: FEEDS.SET_LOADING,
    payload: data
  })

export const updateFeeds = data => dispatch =>
  dispatch({
    type: FEEDS.UPDATE_FEEDS_BY_ONE,
    payload: data
  })

export const resetFeeds = () => dispatch => {
  dispatch({ type: FEEDS.RESET_FEEDS })
}
