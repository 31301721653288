import React from 'react'

const Wrapper = ({ className, children }) => {
  return (
    <div
      className={`w-full bg-white border border-gray-300 rounded-xl px-8 py-8 dark:bg-dark-main3 ${className}`}
    >
      {children}
    </div>
  )
}

export default Wrapper
