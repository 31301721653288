import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  deleteTaskDetailsTeam,
  updateTaskDetails,
  updateTaskDetailsTeam,
  // updateTaskPosition,
  updateTaskPriority,
  updateTaskSection,
  uploadAttachment
} from 'thunks/task/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { getQuillHTML, userRoles } from 'utils'
// import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import {
  updateSubTaskDetails,
  uploadSubTaskAttachment
} from 'thunks/subTask/actions'
import { fetchSingleEmbedById } from 'thunks/embed/action'
import { addCurrentBoard } from 'thunks/workspace/actions'
// import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

// const indexKey = {
//   status: 'orderIndex',
//   dueDate: 'dueDateOrderIndex',
//   assignee: 'assigneeOrderIndex'
// }

const useWorkspaceFunctions = () => {
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)

  const getCollaboratorsOptions = useCallback((teammates, currentWorkspace) => {
    let arr = []
    if (currentWorkspace.assignedAgencyTeam) {
      const allTeammates = [
        ...currentWorkspace.assignedAgencyTeam,
        ...currentWorkspace.assignedClientTeam
      ]

      allTeammates.forEach(item => {
        const idx = teammates.findIndex(mate => mate._id === item.user?._id)
        if (idx === -1) arr.push(item)
      })
    }

    return arr
  }, [])

  /**
   * Mark task as complete or incomplete
   * @param {Boolean} value true, false
   * @param {Object} Task
   */
  const markTaskAsComplete = useCallback(
    ({ value, task, elmFor, socketId, cb, board }) => {
      let completeSection = {}

      if (board?.boardSection) {
        completeSection = value
          ? board.boardSection.find(
              item => item.label.toLowerCase() === 'completed'
            )
          : {}
      }

      const data = {
        markAsComplete: value,
        field: 'markAsComplete',
        workSpace: task.workSpace,
        socketId,
        completedAt: value ? new Date() : null,
        taskSection: completeSection?._id ?? task.taskSection._id
      }

      if (elmFor === 'subtask') {
        dispatch(
          updateSubTaskDetails(
            {
              subtaskId: task._id,
              data: {
                ...data,
                workSpace: task.workSpace
              },
              updateField: 'markAsComplete'
            },
            (res, err) => {
              if (cb) cb(res, err)
            }
          )
        )
      } else {
        dispatch(
          updateTaskDetails({ taskId: task._id, data }, (res, err) => {
            if (cb) cb(res, err)
          })
        )
      }
    },
    [currentWorkspace?.name, currentBoard?.boardSection]
  )

  /**
   * Remove assigned collaborator from task
   * @param {Object} Assignee
   * @param {String} id task id
   * @param {Function} callback
   */
  const removeTaskCollaborator = useCallback(
    ({ userRole, userId, taskId, cb }) => {
      let data
      if (
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_EDITOR,
          userRoles.AGENCY_VIEWER,
          userRoles.AGENCY_ADMIN
        ].includes(userRole)
      ) {
        data = { assignedAgencyTeam: userId }
      } else {
        data = { assignedClientTeam: userId }
      }

      dispatch(deleteTaskDetailsTeam({ taskId, data }, cb))
    },
    []
  )

  /**
   * Add collaborator to task
   * @param {Number} role
   * @param {String} id user id
   * @param {String} id task id
   * @param {Function} callback
   */
  const addTaskCollaborator = useCallback(
    ({ userRole, userId, taskId, cb }) => {
      const data = {
        [userRole < userRoles.USER_CLIENT
          ? 'assignedAgencyTeam'
          : 'assignedClientTeam']: userId,
        collaborators: [userId]
      }

      dispatch(updateTaskDetailsTeam({ taskId, data }, cb))
    },
    []
  )

  const canUpdateTask = canUpdateTask => {
    if (currentWorkspace.isComplete) {
      dispatch(
        fireErrorToaster(
          t('ERROR_WORKSPACE_ALREADY_COMPLETE_AND_CANNOT_UPDATE_ITS_TASKS', {
            data: currentWorkspace.name
          })
        )
      )
      return false
    }

    if (!canUpdateTask) {
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return false
    }

    return true
  }

  // const updateTaskDueDateInDueDateGroup = useCallback(
  //   ({ updateKey, updateValue, task, aboveTask, cb }) => {
  //     let updateData = {
  //       orderIndexColumn: indexKey[groupBy],
  //       newIndex: aboveTask
  //         ? (parseFloat(aboveTask[indexKey[groupBy]] || 0) + 1).toString()
  //         : '0',
  //       above: aboveTask ? aboveTask[indexKey[groupBy]] || '0' : null, //above index,
  //       aboveTask: aboveTask ? aboveTask._id : null,
  //       belowTask: null,
  //       workSpace: task.workSpace,
  //       [updateKey]: updateValue
  //     }

  //     if (!aboveTask) {
  //       updateData.newIndex = '0'
  //     } else {
  //       updateData.newIndex = (
  //         parseFloat(aboveTask[indexKey[groupBy]] || 0) + 1
  //       ).toString()
  //     }

  //     dispatch(updateTaskPosition(task._id, updateData, cb))
  //   },
  //   [groupBy, currentWorkspace.restrictDueDate, meData.role]
  // )

  const handleTaskDateChange = useCallback(
    ({ updateKey, updateValue, task, cb }) => {
      if (
        ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
          meData.role
        ) &&
        currentWorkspace.restrictDueDate?.isDueDateRestrict &&
        currentWorkspace.restrictDueDate?.totalDueDateChangeCounter <=
          task.editDueDateCounter
      ) {
        dispatch(
          fireErrorToaster(
            t('ERROR_DUE_DATE_EDITING_HAS_BEEN_RESTRICTED_BY_ADMIN')
          )
        )
        return
      }

      dispatch(
        updateTaskDetails(
          {
            taskId: task._id,
            data: {
              [updateKey]: updateValue,
              field: updateKey,
              workSpace: task.workSpace,
              taskSection: task.taskSection._id
            }
          },
          (res, err) => {
            if (cb) cb(res, err)
          }
        )
      )
    },
    [currentWorkspace.restrictDueDate]
  )

  const getTaskById = useCallback((taskId, allTasks = []) => {
    return [...allTasks].find(item => item._id === taskId)
  }, [])

  const getSearchedTasks = useCallback((search, allTasks = []) => {
    let searchedValue = search.trim().toLowerCase()
    let allTasksData = [...allTasks]

    const handler = task => {
      const text = getQuillHTML(task.description)
      return (
        task.title.toLowerCase().includes(searchedValue) ||
        text.toLocaleLowerCase().includes(searchedValue)
      )
    }

    if (searchedValue) {
      allTasksData = allTasksData.filter(handler)
    }

    return { allTasks: allTasksData }
  }, [])

  const getSectionsWithUpdatedTasksPosition = ({
    sections,
    destinationSectionIndex,
    sourceSectionIndex,
    destinationIndex,
    sourceIndex,
    task
  }) => {
    let updatedSections
    if (
      sections[destinationSectionIndex]._id === sections[sourceSectionIndex]._id
    ) {
      updatedSections = sections.map((item, index) => {
        if (index === destinationSectionIndex) {
          const section = {
            ...item,
            tasks: [...item.tasks]
          }
          section.tasks.splice(sourceIndex, 1)
          section.tasks.splice(destinationIndex, 0, task)
          return section
        }
        return item
      })
    } else {
      updatedSections = sections.map((item, index) => {
        if (index === sourceSectionIndex) {
          // 1. if section is the source
          const section = { ...item }
          section.tasks = section.tasks.filter(
            (task, index) => index !== sourceIndex
          )
          return section
        } else if (index === destinationSectionIndex) {
          // 2. if section is the destination
          const section = { ...item, tasks: [...item.tasks] }
          section.tasks.splice(destinationIndex, 0, task)
          return section
        }

        return item
      })
    }

    return updatedSections
  }

  // const dndTaskPositionChange = ({
  //   targetTaskId,
  //   aboveTask,
  //   belowTask,
  //   update
  // }) => {
  //   let updateData = {
  //     orderIndexColumn: indexKey[groupBy],
  //     newIndex: '',
  //     workSpace: currentWorkspace._id,
  //     workspaceBoard: currentBoard._id,
  //     ...update
  //   }

  //   // i) If destination section has not tasks
  //   if (aboveTask === null && belowTask === null) {
  //     updateData.newIndex = 0
  //   } else if (aboveTask === null) {
  //     // if task moved to the top
  //     updateData.newIndex = parseFloat(belowTask[indexKey[groupBy]] || 0) - 1
  //   } else if (belowTask === null) {
  //     // if task moved to the bottom
  //     updateData.newIndex = parseFloat(aboveTask[indexKey[groupBy]] || 0) + 1
  //   } else {
  //     updateData.newIndex =
  //       (parseFloat(aboveTask[indexKey[groupBy]] || 0) +
  //         parseFloat(belowTask[indexKey[groupBy]] || 0)) /
  //       2
  //   }

  //   dispatch(updateTaskPosition(targetTaskId, updateData))
  // }

  const changeTaskSection = useCallback(({ task, section, board, cb }) => {
    // const prevSection = board.boardSection.find(
    //   item => item._id === task.taskSection._id
    // )

    dispatch(
      updateTaskSection(
        {
          taskId: task._id,
          data: {
            field: 'taskSection',
            workSpace: task.workSpace,
            taskSection: section._id,
            completedAt:
              section.label.toLowerCase() === 'completed' ? new Date() : null,
            markAsComplete: section.label.toLowerCase() === 'completed'
          }
        },
        (res, err) => {
          if (cb) cb(res, err)
        }
      )
    )
  }, [])

  const changeTaskPriority = useCallback(({ task, priority, cb }) => {
    dispatch(
      updateTaskPriority(
        { taskId: task._id, data: { priority } },
        (res, err) => {
          if (cb) cb(res, err)
        }
      )
    )
  }, [])

  const updateTaskProperty = useCallback(
    ({ taskId, workspaceId, taskSection, updateData, cb }) => {
      dispatch(
        updateTaskDetails(
          {
            taskId,
            data: {
              workSpace: workspaceId,
              taskSection: taskSection._id,
              ...updateData
            }
          },
          (res, err) => {
            if (cb) cb(res, err)
          }
        )
      )
    },
    [currentWorkspace.isComplete]
  )

  const uploadTaskAttachment = useCallback(
    ({ taskId, workspaceId, file, cb }) => {
      dispatch(
        uploadAttachment(
          {
            fileData: {
              fileName: file.name.slice(0, file.name.lastIndexOf('.')),
              file: file
            },
            data: {
              workSpace: workspaceId,
              task: taskId
            }
          },
          (res, err) => {
            cb(res, err)
          }
        )
      )
    },
    []
  )

  const handleUploadSubtaskAttachment = useCallback(
    ({ taskId, subtaskId, workspaceId, file, cb }) => {
      dispatch(
        uploadSubTaskAttachment(
          {
            fileName: file.name.slice(0, file.name.lastIndexOf('.')),
            file: file
          },
          {
            workSpace: workspaceId,
            task: taskId,
            subtask: subtaskId
          },
          (res, err) => {
            cb(res, err)
          }
        )
      )
    },
    []
  )

  const setCurrentBoardById = useCallback(boardId => {
    if (currentBoard._id === boardId) return
    fetchSingleEmbedById({ id: boardId }, (res, err) => {
      if (!err) dispatch(addCurrentBoard(res))
    })
  }, [])

  const checkAndGetNewFileName = (file, allAttachmentUrls) => {
    let count = 2,
      newName = false,
      fileName = file.name.slice(0, file.name.lastIndexOf('.')),
      extension = file.name.match(/.\w+$/)[0]

    const idx = allAttachmentUrls.findIndex(item => {
      return item.endsWith(file.name)
    })

    if (idx === -1) return file.name

    while (!newName) {
      const tempFileName = fileName + `(${count})` + extension
      const idx = allAttachmentUrls.findIndex(item =>
        item.endsWith(tempFileName)
      )

      if (idx === -1) newName = tempFileName
      else count++
    }

    return newName
  }

  return {
    getCollaboratorsOptions,
    markTaskAsComplete,
    removeTaskCollaborator,
    addTaskCollaborator,
    handleTaskDateChange,
    getTaskById,
    getSearchedTasks,
    getSectionsWithUpdatedTasksPosition,
    // dndTaskPositionChange,
    canUpdateTask,
    changeTaskSection,
    changeTaskPriority,
    updateTaskProperty,
    uploadTaskAttachment,
    handleUploadSubtaskAttachment,
    setCurrentBoardById,
    checkAndGetNewFileName
  }
}

export default useWorkspaceFunctions
