import React, { useEffect, useState } from 'react'
import { Loader } from 'global/globalComponents'

const PDFViewer = ({ fileUrl }) => {
  const [pdfUrl, setPdfUrl] = useState('')

  useEffect(() => {
    if (!fileUrl) return

    fetch(fileUrl)
      .then(res => res.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob)
        setPdfUrl(url)
      })
  }, [fileUrl])

  return (
    <>
      {pdfUrl === '' ? (
        <div className="flex flex-col items-center">
          <Loader />
          <p className="text-white">Please wait this may take some time</p>
        </div>
      ) : (
        <object
          data={pdfUrl + '#toolbar=0'}
          type="application/pdf"
          width="100%"
          height="100%"
          className="flex justify-center items-center" // centering error msg
        >
          <p className="text-white text-center">
            It appears you don't have a PDF plugin for this browser!
          </p>
        </object>
      )}
    </>
  )
}

export default PDFViewer
