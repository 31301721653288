import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
import { ReactComponent as Right } from 'static/svg/arrow-right-basic.svg'
import { ReactComponent as Left } from 'static/svg/arrow-left-basic.svg'
import { ReactComponent as VideoIcon } from 'static/svg/video.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { ReactComponent as AudioIcon } from 'static/svg/music-note.svg'
import { downloadFile, getFileIcon, getFileType, getFileUrl } from 'utils'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core'
import TextPreviewer from './TextPreviewer'
import PDFViewer from './PDFViewer'
import ImagePreviewer from './ImagePreviewer'
import LoadingButton from '../LoadingButton/LoadingButton'
import clsx from 'clsx'

const FilePreview = ({ open, files, onClose, fileIndex = 0, ...rest }) => {
  return createPortal(
    <>
      {open && (
        <FilePreviewContent
          filesData={files}
          fileIndex={fileIndex}
          onClose={onClose}
          {...rest}
        />
      )}
    </>,
    document.getElementById('portal')
  )
}

const FilePreviewContent = ({
  filesData,
  fileIndex,
  onClose,
  fileNameGetter,
  fileExtensionGetter,
  fileUrlGetter
}) => {
  const files = useMemo(() => {
    const mappedFiles = filesData.map(file => {
      return {
        ...file,
        name: fileNameGetter ? fileNameGetter(file) : file.name,
        extension: fileExtensionGetter
          ? fileExtensionGetter(file)
          : file.extension,
        url: fileUrlGetter ? fileUrlGetter(file) : file.url,
        type: file.type
          ? file.type
          : getFileType(fileUrlGetter ? fileUrlGetter(file) : file.url)
      }
    })

    return mappedFiles
  }, [filesData])

  const dispatch = useDispatch()
  const [currentFileIndex, setCurrentFileIndex] = useState(fileIndex)
  const [selectedFile, setSelectedFile] = useState(files[fileIndex])
  const [downloading, setDownloading] = useState(false)
  const fileUrl = getFileUrl(selectedFile)

  useEffect(() => {
    setSelectedFile(files[currentFileIndex])
  }, [currentFileIndex])

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.keyCode === 37) handlePrev()
      if (e.keyCode === 39) handleNext()
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = null
    }
  }, [])

  const handleDownloadFile = () => {
    setDownloading(true)
    downloadFile(selectedFile, (res, err) => {
      setDownloading(false)
      if (err) dispatch(fireErrorToaster(res))
    })
  }

  const handlePrev = () => {
    setCurrentFileIndex(prev => (prev === 0 ? files.length - 1 : prev - 1))
  }

  const handleNext = () => {
    setCurrentFileIndex(prev => (prev === files.length - 1 ? 0 : prev + 1))
    // if (currentIndex < files.length - 1) {
    //   setCurrentIndex(prev => prev + 1)
    //   setSelectedFile(files[currentIndex + 1])
    // }
  }

  // const viewImagePreview = fileUrl => {
  //   const index = files.findIndex(item => item === fileUrl)
  //   setCurrentIndex(index)
  //   setSelectedFile(files[index])
  // }

  // const hideLoadingText = e => {
  //   e.target.style.backgroundImage = 'none'
  // }

  return (
    // using high z-index because we need to hide the intercom chat icon
    <>
      <div
        className="fixed inset-0 flex flex-col mx-auto justify-center items-center"
        style={{ background: 'rgba(0, 0, 0, 0.85)', zIndex: 9999999 }}
        onClick={onClose}
      ></div>
      <div
        className="fixed top-0 left-0 w-full h-full"
        style={{ zIndex: 9999999 }}
      >
        {/* ========== Preview Header ========== */}
        <div
          className="absolute top-0 w-full flex items-center p-4 justify-between z-10"
          style={{
            background: 'linear-gradient(180deg, #222 0%, #333 100%)'
          }}
        >
          <div className="text-white text-sm font-medium flex items-center">
            <span className="mr-2 text-lg">
              {getFileIcon(selectedFile.extension)}
            </span>
            <span>{selectedFile.name}</span>
          </div>
          <div className="flex items-end">
            <button
              onClick={handleDownloadFile}
              className="text-white mr-4 flex items-center space-x-2 text-sm"
            >
              {downloading ? (
                <CircularProgress size={20} color="#ffffff" />
              ) : (
                <>
                  <DownloadIcon className="w-4 h-4" />
                  <span>Download</span>
                </>
              )}
            </button>
            <button onClick={onClose} className="text-white">
              <CloseIcon style={{ width: '1.2rem', height: '1.2rem' }} />
            </button>
          </div>
        </div>

        {/* ========== Body ========== */}
        <div
          // onClick={onClose}
          className="relative flex justify-center items-center w-full h-full"
          style={{ paddingTop: 56, height: '100%' }} //header height 56
        >
          {files.length > 1 && (
            <button
              onClick={handlePrev}
              className={clsx(
                'bg-gray-900 p-1 rounded absolute left-5 hover:bg-gray-800'
              )}
            >
              <Left className="text-white" />
            </button>
          )}
          {files.length > 1 && (
            <button
              onClick={handleNext}
              className="bg-gray-900 p-1 rounded absolute right-4 hover:bg-gray-800"
            >
              <Right className="text-white" />
            </button>
          )}
          {selectedFile.type === 'image' ? (
            <ImagePreviewer fileUrl={fileUrl} />
          ) : selectedFile.type === 'audio' ? (
            <audio controls>
              <source src={fileUrl} />
              Your browser does not support this audio format.
            </audio>
          ) : selectedFile.type === 'video' ? (
            <video
              key={fileUrl} //remounting on url change
              width="75%"
              height="75%"
              style={{ maxWidth: 600, maxHeight: 600 }}
              controls
            >
              <source src={fileUrl} />
              Your browser does not support this video format.
            </video>
          ) : selectedFile.type === 'pdf' ? (
            <PDFViewer fileUrl={fileUrl} />
          ) : selectedFile.type === 'json' || selectedFile.type === 'text' ? (
            <TextPreviewer fileUrl={fileUrl} />
          ) : (
            <div className="bg-white p-6 rounded">
              <p className="mb-4 text-sm">
                <div className="text-3xl mb-1">:&#40;</div>
                Cannot preview this file here, please download.
              </p>
              <LoadingButton
                onClick={handleDownloadFile}
                loading={downloading}
                variant="outlined"
              >
                Download
              </LoadingButton>
            </div>
          )}
        </div>
        {files.length && (
          <div className="absolute bottom-0 left-0 ml-10 mb-8 z-10 flex mt-2 w-11/12 overflow-auto">
            {files.map((file, index) => (
              <BottomImages
                key={index}
                // fileUrl={item.url}
                file={file}
                viewImagePreview={() => setCurrentFileIndex(index)}
                currentIndex={currentFileIndex}
                index={index}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

const BottomImages = ({ file, viewImagePreview, index, currentIndex }) => {
  const classes = iconStyles()
  const fileUrl = getFileUrl(file)

  return (
    <div className="relative mr-2">
      <div
        className={clsx(
          classes.attachmentWrapper,
          'border-2',
          currentIndex === index ? 'border-white' : 'opacity-50 border-black'
        )}
        onClick={() => {
          viewImagePreview(fileUrl)
        }}
        style={{ backgroundColor: '#000' }}
      >
        {file.type === 'image' ? (
          <img src={fileUrl} alt="" />
        ) : file.type === 'audio' ? (
          <span className="text-2xl text-gray-500 dark:text-dark-light">
            <AudioIcon className="dark:text-dark-light" />
          </span>
        ) : file.type === 'video' ? (
          <span className="text-2xl text-gray-500 dark:text-dark-light">
            <VideoIcon className="dark:text-dark-light" />
          </span>
        ) : (
          <span className="text-2xl text-gray-500 dark:text-dark-light">
            <FileIcon className="dark:text-dark-light" />
          </span>
        )}
      </div>
      {/* {files?.length && (
        <div className="flex mt-2">
          {files?.length &&
            files.map((item, index) => (
              <BottomImages
                fileUrl={item}
                viewImagePreview={viewImagePreview}
                currentIndex={currentIndex}
                index={index}
              />
            ))}
        </div>
    </div>
  )
}

const BottomImages = ({ fileUrl, viewImagePreview, index, currentIndex }) => {
  const fileType = getFileType(fileUrl)
  const classes = iconStyles()
  // const [fileName, setFileName] = useState('')

  // useEffect(() => {
  //   if (!fileUrl) return

  // //   const arr = fileUrl.split('/')
  // //   // setFileName(arr[arr.length - 1])
  // }, [fileUrl])

  return (
    <div className="relative mr-2 ">
      <div
        className={clsx(
          classes.attachmentWrapper,
          currentIndex === index
            ? 'border border-white'
            : 'opacity-50 border-black border'
        )}
        onClick={() => {
          viewImagePreview(fileUrl)
        }}
        style={{ backgroundColor: '#000' }}
      >
        {fileType === 'image' ? (
          <img src={fileUrl} alt="" />
        ) : fileType === 'audio' ? (
          <span className="text-2xl text-gray-500 dark:text-dark-light">
            <AudioIcon className="dark:text-dark-light" />
          </span>
        ) : fileType === 'video' ? (
          <span className="text-2xl text-gray-500 dark:text-dark-light">
            <VideoIcon className="dark:text-dark-light" />
          </span>
        ) : (
          <span className="text-2xl text-gray-500 dark:text-dark-light">
            <FileIcon className="dark:text-dark-light" />
          </span>
        )}
      </div>
=======
      )} */}
    </div>
  )
}

const iconStyles = makeStyles(theme =>
  createStyles({
    attachmentWrapper: {
      // minWidth: 60,
      // minHeight: 60,
      width: 80,
      height: 55,
      alignItems: 'center',
      // border: '1px solid #757575',
      borderRadius: 8,
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100%',
      outline: 0,
      overflow: 'hidden',
      position: 'relative',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        content: `""`,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 1,
        opacity: 0,
        transition: 'opacity 150ms ease-in-out'
      },
      '& > svg': {
        display: 'inline-block',
        padding: '5px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 6,
        position: 'absolute',
        right: 4,
        top: 6,
        cursor: 'pointer',
        opacity: 0,
        zIndex: 2,
        transition: 'opacity 150ms ease-in-out'
      },
      '&:hover, &.active': {
        '&:before': {
          opacity: 1
        },
        '&>svg': {
          opacity: 1
        }
      }
    }
  })
)

export default FilePreview
