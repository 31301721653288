import React, { useMemo, useState } from 'react'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AlertModal, Pagination, PopupMenu } from 'global/globalComponents'
import NoCouponsFound from './NoCouponsFound'
import { ReactComponent as EditIcon } from 'static/svg/pen.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { useModal } from 'customHooks'
import { deleteCoupon } from 'thunks/coupon/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const LIMIT = 10

const CouponsTable = ({ openModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const search = useSelector(state => state.generalAppState.headerSearch)
  const coupons = useSelector(state => state.coupons)
  const {
    modal: deleteAlert,
    openModal: openDeleteAlert,
    closeModal: closeDeleteAlert,
    toggleLoading
  } = useModal()

  const filteredCoupons = useMemo(() => {
    const couponsData = search
      ? coupons.data.filter(item =>
          item.code.toLowerCase().includes(search.toLowerCase())
        )
      : [...coupons.data]

    return couponsData.splice(page * LIMIT, LIMIT)
  }, [coupons.data, search, page])

  const handleDeleteCoupon = () => {
    toggleLoading()

    dispatch(
      deleteCoupon({ id: deleteAlert.data._id }, (res, err) => {
        if (err) {
          toggleLoading()
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('DELETED_SUCCESSFULLY')))
          closeDeleteAlert()
        }
      })
    )
  }

  return (
    <>
      <TableContainer mb={6}>
        <Thead>
          <Th>{t('COUPON')}</Th>
          <Th>{t('DESCRIPTION')}</Th>
          <Th center>{t('DISCOUNT')}</Th>
          <Th center>{t('REDEEMED')}</Th>
          <Th>{t('EXPIRY_DATE')}</Th>
          <Th center></Th>
        </Thead>
        <Tbody>
          {coupons.loading ? (
            <TableDataLoading cols={6} />
          ) : filteredCoupons.length > 0 ? (
            filteredCoupons.map((item, index) => (
              <Row
                key={item._id}
                coupon={item}
                t={t}
                onEdit={() => openModal(item)}
                onDelete={() => openDeleteAlert(item)}
              />
            ))
          ) : (
            <tr>
              <td colSpan="6">
                <NoCouponsFound openModal={openModal} />
              </td>
            </tr>
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={coupons.data.length}
        page={page}
        limit={LIMIT}
      />
      <AlertModal
        open={deleteAlert.open}
        handleDialog={closeDeleteAlert}
        warningText={t('WARNING_DELETE', { data: deleteAlert.data.code })}
        handleDeleteAction={handleDeleteCoupon}
        loading={deleteAlert.loading}
      />
    </>
  )
}

const Row = ({ coupon, t, onEdit, onDelete }) => {
  return (
    <Tr>
      <Td className="cursor-pointer" onClick={onEdit}>
        {coupon.code}
      </Td>
      <Td>{coupon.description}</Td>
      <Td center>
        {coupon.discount}
        {coupon.type === 'fixed' ? '/-' : '%'}
      </Td>
      <Td center>{coupon.redeemBy.length}</Td>
      <Td>
        {coupon.expiryDate &&
          new Date(coupon.expiryDate).toLocaleDateString(undefined, {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}
      </Td>
      <Td center>
        <PopupMenu
          menuItems={[
            {
              label: t('EDIT'),
              icon: <EditIcon />,
              method: onEdit
            },
            {
              label: t('DELETE'),
              icon: <TrashIcon />,
              isDanger: true,
              method: onDelete
            }
          ]}
        />
      </Td>
    </Tr>
  )
}

export default CouponsTable
