import axios from 'axios'
import { PERMISSION } from './reducer'
import { catchAsyncDispatch } from 'utils'

export const fetchPermissions = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/permission/user'
    })

    dispatch({ type: PERMISSION.FETCHED, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}
