import stringToHslColor from 'utils/stringToHslColor'

export const mentionPopupMenu = (item, searchTerm) => {
  const nameInitials = item.value
    .split(' ')
    .slice(0, 2)
    .map(item => item[0])
    .join('')
  const color = stringToHslColor(item.value ?? '')

  return `<div class="flex items-center space-x-2 py-2 dark:text-dark-light">
              ${
                item.profileImage
                  ? `
              <img class="ql-mention-list-item__img w-7 h-7 rounded-full object-cover dark:text-dark-light" src="${item.profileImage}">
              `
                  : `
              <div class="w-7 h-7 rounded-full uppercase flex justify-center items-center text-white text-xs dark:text-dark-light" style="background: ${color};">
                ${nameInitials}
              </div>
              `
              }
                <div>
                  <div class="text-sm font-medium leading-none dark:text-dark-light">${
                    item.value
                  }</div>
                </div>
            </div>`
}

/**
 *
 * @param {Array} users
 * @param {String} meId
 * @param {Function} callback on user select callback
 * @returns mention config object
 */
export const getMentionConfig = users => ({
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ['@'],
  source: function (searchTerm, renderItem, mentionChar) {
    let values
    if (mentionChar === '@') {
      values = users || []
    }
    if (searchTerm.length === 0) {
      renderItem(values, searchTerm)
    } else {
      const matches = []
      for (let i = 0; i < values.length; i++) {
        if (values[i].value.toLowerCase().includes(searchTerm.toLowerCase())) {
          matches.push(values[i])
        }
      }
      renderItem(matches, searchTerm)
    }
  },
  renderItem: mentionPopupMenu
})
