import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { App } from 'containers'
import { userRoles } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import ProtectedRoutes from './ProtectedRoutes'
import { AgencyPlans } from 'components/_agency'
// import ClientServices from 'components/ClientComponent/ClientService/ClientServices'
import { Loader } from 'global/globalComponents'
import { fetchMeData } from 'thunks/me/actions'
import { fetchUser, getAgencyInfoByDomain } from 'thunks/user/actions'
import PublicRoutes from './PublicRoutes'

const AgencyRoutes = React.lazy(() => import('./AgencyRoutes'))
const ClientRoutes = React.lazy(() => import('./ClientRoutes'))
const ClientLandingServicesPage = React.lazy(() =>
  import(
    'components/_pages/ClientLandingServicesPage/ClientLandingServicesPage'
  )
)

const Routes = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const { loggedIn } = useSelector(state => state.login)
  const { fetched, data: meData } = useSelector(state => state.me)
  const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(true)

  useEffect(() => {
    if (loggedIn && !fetched) {
      setLoading(true)
      dispatch(
        fetchMeData(null, (res, err) => {
          setLoading(false)
        })
      )
      dispatch(fetchUser())
    } else {
      setLoading(false)
    }
  }, [loggedIn, fetched])

  useEffect(() => {
    dispatch(
      getAgencyInfoByDomain((res, err) => {
        setLoadingCompanyInfo(false)
      })
    )
  }, [])

  return (
    <>
      {loading || loadingCompanyInfo ? (
        <Loader fullScreen={true} className="bg-white dark:bg-dark-main4" />
      ) : (
        <Switch>
          <App path="/">
            <Switch>
              <Route component={AgencyPlans} path="/plans" />
              <Route path="/agency">
                {meData.role < userRoles.USER_CLIENT ? (
                  <AgencyRoutes />
                ) : (
                  <Redirect to={{ pathname: '/login' }} />
                )}
              </Route>
              <Route path="/client">
                {meData.role >= userRoles.USER_CLIENT ? (
                  <ClientRoutes />
                ) : (
                  <Redirect to={{ pathname: '/login' }} />
                )}
              </Route>
              <ProtectedRoutes
                component={ClientLandingServicesPage}
                path="/select-service"
              />

              <Route>
                <Switch>
                  <PublicRoutes />
                </Switch>
              </Route>
            </Switch>
          </App>
        </Switch>
      )}
    </>
  )
}

export default Routes
