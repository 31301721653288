const { makeStyles, createStyles } = require('@material-ui/core')

const taskSidePanelStyles = makeStyles(theme =>
  createStyles({
    drawerRoot: ({ inDashboardArea }) => ({
      zIndex: `${inDashboardArea ? 1999 : 999} !important`
    }),
    drawerPaper: ({ inDashboardArea, chat, isPayNowStripeVisible }) => ({
      width: chat ? '500px' : '75vw',
      marginTop: inDashboardArea
        ? 0
        : isPayNowStripeVisible
        ? theme.custom.projectHeader.height + theme.custom.payStripe.height
        : theme.custom.projectHeader.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${
        inDashboardArea ? 0 : theme.custom.projectHeader.height
      }px)`,
      '&.drawerOpen': {
        boxShadow: '-10px 15px 25px rgba(0, 0, 0, 0.2)',
        overflow: 'visible'
      },
      '@media (min-width: 1300px)': {
        width: chat ? '500px' : '55vw'
      },
      '@media(max-width: 768px)': {
        width: '85vw'
      }
    }),
    subtaskDrawerPaper: {
      width: '75vw',
      // maxWidth: '850px',
      marginTop: theme.custom.projectHeader.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.projectHeader.height}px)`,
      '&.drawerOpen': {
        boxShadow: 'none',
        overflow: 'visible'
      },
      '@media (min-width: 1684px)': {
        width: '55vw'
      }
    },
    drawerPaper1: {
      minHeight: 60,
      borderBottom: '1px solid #eee',
      //   width: '100%'s
      '&>p': {
        width: '100%'
      }
    },
    drawerHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? '#fff' : theme.custom.darkMode.dark1,
      height: '40px',
      width: '100%',
      zIndex: theme.zIndex.appBar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
      // padding: '0 24px'
    },
    rowContainer: {
      display: 'grid',
      gridTemplateColumns: '120px 1fr',
      // gridAutoRows: '36px',
      alignItems: 'center',
      marginBottom: 8
    },
    drawerFooter: {
      width: '100%',
      flexGrow: 0,
      flexShrink: 0,
      zIndex: '2',
      backgroundColor: '#f9f8f8',
      padding: '12px 16px',
      borderTop: '1px solid #c6c6c6'
      // display: 'flex',
      // alignItems: 'center'
    },
    headerIconContainer: {
      width: 28,
      height: 28,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
      transitionDuration: '0.2s',
      transitionProperty: 'background,border,box-shadow,color,fill'
      // '&>svg': {
      //   fill: '#6f7782',
      //   width: 18,
      //   height: 18
      // },
      // '&:hover': {
      //   backgroundColor: 'rgba(21,27,38,.04)',
      //   '&> svg': {
      //     fill: '#151b26'
      //   }
      // }
    },
    rotateSwapIcon: {
      userSelect: 'none',
      width: '74px',
      '&:focus, &:active': {
        '&>svg': {
          transform: 'rotate(180deg)',
          transition: 'transform 0.3s'
        }
      }
    },
    markAsCompleteBtn: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      gap: 4,
      fontSize: 12,
      color: 'gray',
      padding: '3px 6px',
      // height: 28,
      borderRadius: 6,
      transitionDuration: '0.2s',
      transitionProperty: 'background,border,box-shadow,color,fill',
      border: '1px solid #cbd4db',
      '&>svg': {
        fontSize: 16,
        color: 'inherit'
      },
      '&:hover': {
        borderColor: '#00bf9c',
        color: '#00bf9c',
        backgroundColor: '#e2fffa'
      }
    },
    completeButton: {
      borderColor: '#00bf9c',
      color: '#00bf9c',
      backgroundColor: '#e2fffa'
    },
    feildRowContainer: {
      marginBottom: 4,
      display: 'flex',
      flexShrink: 0
    },
    labelledRowLeft: {
      display: 'flex',
      width: 100,
      paddingRight: 8,
      textAlign: 'left',
      flex: '0 0 auto',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&>label': {
        fontSize: 12,
        lineHeight: '18px',
        color: '#6f7782',
        maxWidth: 140,
        overflow: 'hidden',
        paddingTop: 2,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block'
      }
    },
    labelledRowRight: {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center'
    },
    attachmentContainer: {
      margin: '4px 0 16px',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    attachmentWrapper: {
      // minWidth: 60,
      // minHeight: 60,
      width: 100,
      height: 65,
      alignItems: 'center',
      background: '#f6f8f9',
      border: '1px solid #e8ecee',
      borderRadius: 8,
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100%',
      outline: 0,
      overflow: 'hidden',
      position: 'relative',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        content: `""`,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 1,
        opacity: 0,
        transition: 'opacity 150ms ease-in-out'
      },
      '& > svg': {
        display: 'inline-block',
        padding: '5px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 6,
        position: 'absolute',
        right: 4,
        top: 6,
        cursor: 'pointer',
        opacity: 0,
        zIndex: 2,
        transition: 'opacity 150ms ease-in-out'
      },
      '&:hover, &.active': {
        '&:before': {
          opacity: 1
        },
        '&>svg': {
          opacity: 1
        }
      }
    },
    descriptionEditorStyle: {
      // zIndex:12000
      flexGrow: '1 !important',
      border: '1px solid transparent',
      minHeight: 45,
      borderRadius: 6,
      overflow: 'hidden',
      padding: '5px 8px',
      transtiton: '0.2s border',
      '&:hover': {
        border: '1px solid gray'
      }
    },
    // attachmentThumbnail: {
    //   maxHeight: 64,
    //   maxWidth: 124,
    //   cursor: 'pointer'
    // },
    attachmentAddbtn: {
      width: 63,
      height: 63,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px dashed #9ca6af',
      borderRadius: 8,
      cursor: 'pointer',
      '&>svg': {
        color: '#9ca6af'
      }
    },
    logsContainer: {
      backgroundColor: '#f9f8f8',
      marginTop: '16px'
    },
    bottomContainer: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      width: '55vw',
      maxWidth: 650,
      minHeight: 98,
      maxHeight: 200,
      overflowY: 'auto',
      borderTop: '1px solid #e8ecee',
      backgroundColor: '#f6f8f9',
      '@media (max-width: 768px)': {
        maxHeight: 300
      }
    },
    bottomCommentContainer: {
      '& textarea': {
        outline: 'none'
      }
    },
    bottomCollabContainer: {
      padding: '4px 32px 5px',
      display: 'flex',
      alignItems: 'center'
    },
    priorityLabel: {
      // height: 20,
      borderRadius: 10,
      padding: ' 3px 6px',
      '&.urgent': {
        backgroundColor: '#fb5779',
        color: '#fff'
      },
      '&.high': {
        backgroundColor: '#ff7511',
        color: '#331a00'
      },
      '&.medium': {
        backgroundColor: '#14da1a',
        color: '#fff'
      },
      '&.low': {
        backgroundColor: '#225bc4',
        color: '#fff'
      }
    },
    teammatesAvatarRoot: {
      width: '20px',
      height: '20px',
      textTransform: 'uppercase',
      fontSize: 10
    },
    pseudoDropDownContainer: {
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      height: 36,
      padding: theme.spacing(0, 1),
      borderRadius: 6,
      marginLeft: '-8px',
      fontSize: 13,
      '&:hover': {
        backgroundColor: 'rgba(21,27,38,.04)'
      }
    },
    closeBtn: {
      fontSize: 18,
      cursor: 'pointer',
      transform: 'rotate(45deg)',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      position: 'absolute',
      top: 4,
      right: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: theme.spacing(1),
      width: theme.spacing(1)
    },
    view: {
      opacity: 0
    },
    assignTag: {
      position: 'relative',
      padding: '0 10px 0 10px',
      borderRadius: '10px',
      fontSize: '12px',
      '&:hover .cross-btn': {
        opacity: 1
      }
    },
    crossBtn: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      color: 'rgba(255, 0, 0, 0.75)',
      opacity: 0,
      transition: 'opacity 150ms ease-in-out'
    },
    description: {
      '--ck-focus-ring': `1px solid ${theme.palette.primary.main}`,
      '& .ck-editor__editable': {
        minHeight: 245,
        fontSize: 14
      },
      '& .ck-editor__editable a': {
        color: 'blue',
        cursor: 'pointer'
      },
      '& .ck-editor__editable ol': {
        paddingLeft: '2rem',
        listStyleType: 'decimal'
      },
      '& .ck-editor__editable ul': {
        paddingLeft: '2rem',
        listStyleType: 'disc'
      }
    },
    timer: {
      padding: '10px 15px 10px 15px',
      borderBottom: '1px solid #efeef0',
      '&:hover': {
        backgroundColor: '#efeef0'
      }
    },
    timeSpend: {
      // maxHeight: '20vw',
      overflow: 'auto',
      padding: '0 15px',
      borderBottom: '1px solid #efeef0',
      margin: 0,
      backgroundColor: '#fff'
    },
    editor: {
      transition: '150ms ease-in-out',
      borderRadius: 4,
      '&:focus, &:focus-within': {
        height: 100,
        borderColor: `${theme.palette.primary.main}`
      },
      '& .ql-container.ql-snow': {
        borderColor: 'inherit',
        borderRadius: 4
      }
    },
    teammate: {
      '&:hover .cross-btn': {
        opacity: 1
      }
    },

    removeAssigneeBtn: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      color: 'rgba(255, 0, 0, 0.75)',
      backgroundColor: 'white',
      borderRadius: '9999px',
      opacity: 0,
      zIndex: 1,
      transition: 'opacity 150ms ease-in-out',
      '&:hover': {
        color: 'rgba(255, 0, 0)'
      }
    },
    avatar: {
      minWidth: 'initial'
    },
    taskBodyContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '0.5rem 2rem'
    }
  })
)

export default taskSidePanelStyles
