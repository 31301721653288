const pillVariantObj = {
  paid: 'green',
  draft: 'yellow',
  uncollectible: 'pink',
  void: 'gray',
  open: 'blue',
  overdue: 'red',
  send: 'blue',
  pending: 'yellow',
  'in progress': 'orange',
  rejected: 'red',
  resolved: 'green',
  closed: 'gray',
  canceled: 'yellow',
  'partially refunded': 'gray',
  'partially canceled': 'gray',
  'Partial-Cancel': 'gray',
  refunded: 'gray',
  cancel: 'gray'
}

export default pillVariantObj
