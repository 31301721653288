import React, { useEffect, useState } from 'react'
import { Loader } from 'global/globalComponents'
import { useSelector } from 'react-redux'
import PageNotFound from 'components/Opening/PageNotFound'
import { useParams } from 'react-router-dom'

const EmbedPreview = () => {
  const [loading, setLoading] = useState(true)
  const embed = useSelector(state => state.embed)
  const [embedData, setEmbedData] = useState({})
  const { embedId } = useParams()

  // useEffect(() => {
  //   setLoading(true)
  //   setEmbedData({})
  // }, [embedId])

  useEffect(() => {
    if (!embed.loading) {
      setEmbedData(embed.data.find(item => item._id === embedId))
      setLoading(false)
    }
  }, [embed, embedId])

  return (
    <div key={embedId} className="w-full h-full">
      {loading ? (
        <Loader />
      ) : (
        <>
          {embedData.embed_settings?.url ? (
            <iframe
              src={embedData.embed_settings.url}
              title={'Robot'}
              className="h-full w-full"
            />
          ) : (
            <PageNotFound />
          )}
        </>
      )}
    </div>
  )
}

export default EmbedPreview
