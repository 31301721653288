import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import PreviewInvoiceTemplate1 from 'components/_shared/InvoiceTemplates/InvoiceTemplate1/PreviewInvoiceTemplate1'
import PreviewInvoiceTemplate2 from 'components/_shared/InvoiceTemplates/InvoiceTemplate2/PreviewInvoiceTemplate2'
import { fetchCustomInvoices } from 'thunks/invoices/actions'
import ChatWindow from './ChatWindow'
import SideBar from './Sidebar'
import FeedHeader from './FeedHeader'
import { RaiseTicketModal } from 'components/_shared'
import { userRoles } from 'utils'
import { fetchClientRequests } from 'thunks/clientRequests/actions'

const Feed = ({ widgetId, editMode, handleRemoveWidget }) => {
  const dispatch = useDispatch()
  const { loading: reduxLoading } = useSelector(state => state.feeds)
  const [toggleQuote, setToggleQuote] = useState({ open: false, invoice: null })
  const [openReqModal, setOpenReqModal] = useState({ open: false, req: null })
  const meData = useSelector(state => state.me.data)
  const clientRaisedRequests = useSelector(state => state.clientRequests)
  // const userIntegrations = useSelector(state => state.userIntegrations)
  const [toggleSidebar, setToggle] = useState(true)

  const onOpen = () => {
    setToggle(prev => !prev)
  }

  useEffect(() => {
    dispatch(fetchCustomInvoices())
  }, [])

  useEffect(() => {
    if (!clientRaisedRequests.fetched) {
      dispatch(fetchClientRequests())
    }
  }, [clientRaisedRequests.fetched])

  const handleDeleteWidgetClick = () => {
    handleRemoveWidget(widgetId)
  }

  return (
    <>
      <div className="bg-white dark:bg-dark-main h-full rounded-md border border-gray-300 dark:border-dark-main1">
        <div className="flex h-full">
          {/* ========= Feed Left ======== */}
          <div className="flex-1">
            <FeedHeader
              toggleSidebar={toggleSidebar}
              editMode={editMode}
              handleDeleteWidgetClick={handleDeleteWidgetClick}
              onOpen={onOpen}
            />

            <ChatWindow
              widgetId={widgetId}
              openReqModal={setOpenReqModal}
              toggleSidebar={toggleSidebar}
              setToggleQuote={setToggleQuote}
              loading={reduxLoading}
            />
          </div>

          {/* ========= Feed Right ======== */}
          {toggleSidebar && (
            <SideBar
              openReqModal={setOpenReqModal}
              onClose={onOpen}
              editMode={editMode}
              setToggleQuote={setToggleQuote}
              loading={reduxLoading}
              handleDeleteWidgetClick={handleDeleteWidgetClick}
            />
          )}
        </div>
      </div>

      {openReqModal.open && (
        <RaiseTicketModal
          open={openReqModal.open}
          handleClose={() => setOpenReqModal({ open: false })}
          data={openReqModal.data}
          canUpdate={meData.role <= userRoles.CLIENT_MANAGER}
        />
      )}
      {toggleQuote.open && (
        <QuoteModal
          open={toggleQuote.open}
          onClose={() => setToggleQuote(false)}
          feed={toggleQuote.invoice}
        />
      )}
    </>
  )
}

const QuoteModal = ({ open, feed, onClose }) => {
  return (
    <CustomModal open={open} size="medium">
      <CustomModalHeader handleClose={onClose} />
      <CustomModalBody>
        {feed.invoiceTemplate === 2 ? (
          <PreviewInvoiceTemplate2 invoiceData={feed} />
        ) : (
          <PreviewInvoiceTemplate1 invoiceData={feed} />
        )}
      </CustomModalBody>
      <CustomModalFooter />
    </CustomModal>
  )
}

export default Feed
