import axios from 'axios'
import { catchAsync } from 'utils'

export const postUserActivityLogs = catchAsync(async data => {
  await axios({
    url: `/activity`,
    method: 'POST',
    data: data.data
  })
})

export const fetchPulseGraphData = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/activity/active?start=${data.startDate}&end=${data.endDate}`,
    method: 'GET'
  })

  if (callback) callback(res.data)
})
