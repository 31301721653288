import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs, TextField, Button } from '@material-ui/core'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { LoadingButton } from 'global/globalComponents'
import CategoryCard from './CategoryCard'
import userServiceStyles from './userServiceStyles'
import { createServiceCategory } from 'thunks/service/actions'
import { fileToDataURL } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as LinkIcon } from 'static/svg/link.svg'
import { useTranslation } from 'react-i18next'

const CategoriesModal = ({
  open,
  onClose,
  serviceCategories,
  handleUpdateServiceCategory,
  openCategoryAlert,
  addNewCategory
}) => {
  const { t } = useTranslation()
  const { theme: themeForMode, isMarketplaceDomain } = useSelector(
    state => state.generalAppState
  )
  const [selectedTab, setSelectedTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [categoryImg, setCategoryImg] = useState('')
  const formRef = useRef()
  const dispatch = useDispatch()

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const handleCreateCategory = async e => {
    e.preventDefault()
    const form = formRef.current
    const title = form.title.value.trim()
    const description = form.description.value.trim()

    if (!title || !description)
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS'))
      )

    const match = serviceCategories.find(
      item => item.name.toLowerCase() === title.toLowerCase()
    )

    if (match)
      return dispatch(
        fireErrorToaster(t('ERROR_DOMAIN_ALREADY_EXIST', { data: match.name }))
      )

    setLoading(true)
    dispatch(
      createServiceCategory(
        {
          data: {
            name: title,
            description,
            image: categoryImg,
            type: isMarketplaceDomain ? 'marketplaceService' : 'service'
          }
        },
        createServiceCategoryCallback
      )
    )
  }

  const createServiceCategoryCallback = (res, err) => {
    setLoading(false)

    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      addNewCategory(res)
      setCategoryImg('')
      formRef.current.reset()
      dispatch(fireSuccessToaster(t('CREATED_NEW_CATEGORY')))
    }
  }

  const handleCloseModal = () => {
    setSelectedTab(0)
    setLoading(false)
    setCategoryImg('')
    onClose()
  }

  return (
    <CustomModal size="large" open={open} handleClose={handleCloseModal}>
      <CustomModalHeader
        heading={t('CATEGORIES')}
        handleClose={handleCloseModal}
        icon={
          <LinkIcon
            width={40}
            height={40}
            className="text-primary-main bg-primary-light dark:bg-dark-main rounded-lg p-3 dark:text-dark-light"
          />
        }
      />
      <CustomModalBody>
        <div className="mb-6 ">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
            className="dark:text-dark-light"
          >
            {[t('CREATE_NEW'), t('EDIT_CATEGORY')].map((item, index) => (
              <Tab label={item} key={index} />
            ))}
          </Tabs>
        </div>

        {selectedTab === 0 ? (
          <CreateCategory
            ref={formRef}
            handleCreateCategory={handleCreateCategory}
            categoryImg={categoryImg}
            setCategoryImg={setCategoryImg}
          />
        ) : (
          <EditCategory
            serviceCategories={serviceCategories}
            handleUpdateServiceCategory={handleUpdateServiceCategory}
            openCategoryAlert={openCategoryAlert}
          />
        )}
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={handleCloseModal}>
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          loading={loading}
          onClick={handleCreateCategory}
          disabled={Boolean(selectedTab)}
        >
          {t('CREATE')}
        </LoadingButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

const CreateCategory = React.forwardRef(
  ({ handleCreateCategory, categoryImg, setCategoryImg }, ref) => {
    const imageLoadHandler = e => {
      const file = e.target.files[0]

      if (file) {
        fileToDataURL(file).then(res => {
          setCategoryImg(res)
          e.target.value = ''
        })
      }
    }
    const { t } = useTranslation()

    return (
      <form ref={ref} onSubmit={handleCreateCategory}>
        <div className="mb-4">
          <FormLabel htmlFor="categoryTitle" required>
            {t('TITLE')}
          </FormLabel>

          <TextField
            className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
            placeholder={t('ENTER_TITLE')}
            id="title"
            name="title"
          />
        </div>
        <div className="mb-4">
          <FormLabel htmlFor="categoryDesc" required>
            {t('DESCRIPTION')}
          </FormLabel>

          <TextField
            className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
            placeholder={t('ENTER_DESCRIPTION')}
            id="categoryDesc"
            multiline={true}
            rows={2}
            name="description"
          />
        </div>
        <div className="mb-4 flex gap-4">
          <div>
            <FormLabel>{t('ADD_IMAGE')}</FormLabel>

            <FormLabel htmlFor="categoryImg">
              <Button component="span">{t('CHOOSE_FILE')}</Button>
            </FormLabel>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="categoryImg"
              multiple={false}
              type="file"
              name="image"
              onChange={imageLoadHandler}
            />
          </div>
          <div>
            {categoryImg && (
              <img
                src={categoryImg}
                alt=""
                className="w-40 h-32 object-contain rounded"
              />
            )}
          </div>
        </div>
      </form>
    )
  }
)

const EditCategory = ({
  serviceCategories,
  handleUpdateServiceCategory,
  openCategoryAlert
}) => {
  const classes = userServiceStyles()
  const [categories, setCategories] = useState(serviceCategories)
  const { t } = useTranslation()
  useEffect(() => {
    setCategories(serviceCategories)
  }, [serviceCategories])

  return (
    <div>
      {categories.length ? (
        <div className={classes.categoriesWrapper}>
          {categories.map(item => (
            <CategoryCard
              key={item._id}
              data={item}
              handleUpdateServiceCategory={handleUpdateServiceCategory}
              openCategoryAlert={openCategoryAlert}
            />
          ))}
        </div>
      ) : (
        <div className="w-full h-52 flex items-center justify-center">
          <p className="text-2xl text-gray-400 text-center">
            {t('NO_CATEGORIES_FOUND')}
          </p>
        </div>
      )}
    </div>
  )
}

export default CategoriesModal
