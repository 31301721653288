import { CircularProgress, Drawer } from '@material-ui/core'
import React, { useEffect, useState, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import userRoles from 'utils/userRoles'
import {
  updateSubTaskDetails,
  deleteSubTaskByID,
  updateSubTaskTeammate,
  deleteSubtaskCollaborator
} from 'thunks/subTask/actions'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { findCommentsBySubtaskId, removeLogs } from 'thunks/logs/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { deleteSubtaskAttachment } from 'thunks/subTask/actions'
import taskSidePanelStyles from './taskSidePanelStyles'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import LogsSection from './LogsSection/LogsSection'
import TaskPanelHeader from './TaskPanelHeader'
import TaskPanelFooter from './TaskPanelFooter'
import TaskTitle from './TaskTitle'
import TaskBody from './TaskBody'
import CollabDrowdown from './CollabDrowdown'
import AddAttachment from './AddAttachment'
import TaskDescription from './TaskDescription'
import AttachmentImage from './AttachmentImage'
import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import useWorkspaceFunctions from '../useWorkspaceFunctions'
import { useTranslation } from 'react-i18next'
import { getErrorMessages } from 'utils/errorMessages'
import { useLocation } from 'react-router-dom'
import { AutoSavingIndicator } from 'global/globalComponents'
import { openApprovalModal } from 'thunks/generlAppState/actions'

const SubtaskPanel = ({ open, onClose, inDashboardArea, ...rest }) => {
  const { payNowStripe } = useSelector(state => state.generalAppState)
  const classes = taskSidePanelStyles({
    inDashboardArea,
    isPayNowStripeVisible: payNowStripe.isVisible
  })

  return (
    <Drawer
      onClose={onClose}
      classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
      anchor="right"
      open={open}
    >
      <SubtaskPanelContents
        open={open}
        onClose={onClose}
        inDashboardArea={inDashboardArea}
        {...rest}
      />
    </Drawer>
  )
}

const SubtaskPanelContents = ({
  innerProjectPermission,
  open,
  onClose,
  taskId,
  // handlePreviewImage,
  inDashboardArea,
  // groupBy,
  currentBoard,
  workspace
}) => {
  const { t } = useTranslation()
  const classes = taskSidePanelStyles({ inDashboardArea })
  // const currentWorkspace = useSelector(
  //   state => state.userWorkspaces.currentWorkspace
  // )
  const dispatch = useDispatch()
  const logsBottomRef = useRef(null)
  // const logsData = useSelector(state => state.logs.subtaskLogs)
  const allSubTasks = useSelector(state => state.subTasks.data)
  const [taskData, setTaskData] = useState({})
  const [collabOptions, setCollabOptions] = useState([])
  // const meData = useSelector(state => state.me.data)
  const { socketId } = useContext(WorkspacePusherContext)
  const [taskState, setTaskState] = useState({
    creator: '',
    title: '',
    workSpace: '',
    taskSection: '',
    dueDate: `${new Date()}`,
    description: '',
    assignedAgencyTeam: [],
    assignedClientTeam: [],
    imageUrl: [],
    priority: {}
  })
  const [selectedTab, setSelectedTab] = useState(0)
  // const [attachmentDropdownData, setAttachmentDropdownData] = useState({
  //   anchorEl: null,
  //   open: false,
  //   data: {}
  // })
  const [loadingAttachment, setLoadingAttachment] = useState(false)
  // const [approvalPanel, setApprovalPanel] = useState({
  //   open: false,
  //   loading: true,
  //   data: []
  // })

  const {
    getCollaboratorsOptions,
    canUpdateTask,
    // markTaskAsComplete,
    handleUploadSubtaskAttachment,
    checkAndGetNewFileName
  } = useWorkspaceFunctions()
  const [savingStatus, setSavingStatus] = useState('') // statuses: "saving", "saved", "failed"
  const taskDescriptionRef = useRef()
  const [alertModal, setAlertModal] = useState({
    type: '',
    loading: false,
    data: {}
  })

  const { hash } = useLocation()

  useEffect(() => {
    return () => {
      dispatch(removeLogs('subtask'))
    }
  }, [])

  useEffect(() => {
    if (taskId) {
      const task = allSubTasks?.find(i => i._id === taskId)
      if (task) {
        setTaskData(task)
      }
    }
  }, [taskId, allSubTasks])

  useEffect(() => {
    if (taskData._id && currentBoard.boardSection) {
      setTaskState({
        ...taskData,
        description: taskData.description ?? ''
      })
    }
  }, [taskData, currentBoard.boardSection])

  useEffect(() => {
    if (open) {
      dispatch(findCommentsBySubtaskId({ id: taskId }))
    }
  }, [open, taskId])

  useEffect(() => {
    if (taskData.assignedAgencyTeam) {
      setCollabOptions(
        getCollaboratorsOptions(
          [...taskData.assignedAgencyTeam, ...taskData.assignedClientTeam],
          workspace
        )
      )
    }
  }, [taskData.assignedAgencyTeam, taskData.assignedClientTeam])

  useEffect(() => {
    if (hash) scrollToBottom()
  }, [hash])

  const updateTaskState = taskData => {
    setTaskState(taskData)
  }

  const taskUpdateCallback = (res, err, options = {}) => {
    if (err) {
      setSavingStatus('failed')
      dispatch(fireErrorToaster(res))
      if (options.resetProp) {
        setTaskState(prev => ({
          ...prev,
          [options.resetProp]: taskData[options.resetProp]
        }))
      }
    } else {
      setSavingStatus('saved')
    }
  }

  const deleteTaskByIDCallback = (res, err) => {
    setAlertModal(prev => ({ ...prev, loading: false, type: '' }))
    // setDeleteSubtaskLoading(false)
    // setOpenConfirm(false)
    onClose()
    if (err) {
      dispatch(fireErrorToaster(err))
    } else {
      dispatch(fireSuccessToaster(t('TASK_DELETED')))
      // updateLog(`deleted the subtask <strong>${taskState.title}</strong>`)
    }
  }

  const handleOnDeleteTask = event => {
    // setDeleteSubtaskLoading(true)
    setAlertModal(prev => ({ ...prev, loading: true }))
    dispatch(deleteSubTaskByID({ id: taskData._id }, deleteTaskByIDCallback))
  }
  const handleCloseAlert = () => {
    setAlertModal(prev => ({ ...prev, type: '' }))
    setAnchorEl(null)
  }

  // const handleAttachmentDropDown = (e, data) => {
  //   if (e)
  //     setAttachmentDropdownData({ anchorEl: e.currentTarget, open: true, data })
  //   else setAttachmentDropdownData({ anchorEl: null, open: false, data })
  // }

  const updateTitle = title => {
    const errorMessages = getErrorMessages()
    dispatch(
      updateSubTaskDetails(
        {
          subtaskId: taskState._id,
          data: {
            title,
            field: 'title',
            workSpace: taskData.workSpace,
            taskSection: taskData.taskSection._id
          }
        },
        (res, err) => {
          if (!err) {
            setTaskState(prev => ({ ...prev, title }))
            dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
            // updateLog(`changed the title`)
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleDateChange = (e, prop) => {
    const date = e?.toISOString() || null

    if (innerProjectPermission.update) {
      setTaskState(prev => ({
        ...prev,
        [prop]: date
      }))

      setTaskState(prev => ({ ...prev, [prop]: date }))
      setSavingStatus('saving')
      dispatch(
        updateSubTaskDetails(
          {
            subtaskId: taskData._id,
            data: {
              [prop]: date,
              field: prop,
              workSpace: taskData.workSpace,
              taskSection: taskData.taskSection._id
            }
          },
          (res, err) => {
            taskUpdateCallback(res, err, { resetProp: prop })
          }
        )
      )
    } else dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
  }

  const handleFileUpload = (file, callback) => {
    const fileName = checkAndGetNewFileName(file, taskData.imageUrl)
    const newFile = new File([file], fileName, {
      type: file.type,
      lastModified: file.lastModified
    })

    setLoadingAttachment(true)
    handleUploadSubtaskAttachment({
      taskId: taskData.task,
      subtaskId: taskData._id,
      workspaceId: taskData.workSpace,
      file: newFile,
      cb: (res, err) => {
        setLoadingAttachment(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          setTaskState(prev => ({
            ...prev,
            imageUrl: res.data.imageUrl
          }))
          callback(res.link)
          // updateLog(
          //   `uploaded an attachment in the subtask <b>${taskState.title}</b>`
          // )
        }
      }
    })
  }

  const handleAttachmentUpload = file => {
    handleFileUpload(file, source => {
      const fileType = file.type.split('/')[0]
      taskDescriptionRef.current?.handleInsertEmbed({
        source,
        fileType
      })
    })
  }

  const handleUploadDescriptionAttachment = (file, cb) => {
    handleFileUpload(file, source => {
      cb(source)
    })
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const triggerPopover = (event, value) => {
    if (innerProjectPermission.update) {
      setAnchorEl(event.currentTarget)
      setPopperOpen(value)
    } else dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
  }

  const handlePopperClose = () => {
    setPopperOpen('')
    setAnchorEl(null)
  }

  const [popperOpen, setPopperOpen] = useState('')

  // const handlePriorityLabel = priority => {
  //   handlePopperClose()
  //   setSavingStatus('saving')
  //   setTaskState(prev => ({ ...prev, priority: priority.order }))
  //   dispatch(
  //     updateSubTaskPriority(
  //       taskData._id,
  //       { priority: priority.order },
  //       (res, err) => {
  //         taskUpdateCallback(res, err, { resetProp: 'priority' })
  //       }
  //     )
  //   )
  // }

  const handleClickOpenConfirm = e => {
    if (innerProjectPermission.delete) {
      e.stopPropagation()
      // setOpenConfirm(true)
      setAlertModal(prev => ({ ...prev, type: 'taskDelete', loading: false }))
    } else dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
  }

  const handleAddCollaborator = ({ userId, userRole }) => {
    setSavingStatus('saving')
    handlePopperClose()

    // const data = {
    //   field: 'assignee_add'
    // }

    const data = {
      [userRole < userRoles.USER_CLIENT
        ? 'assignedAgencyTeam'
        : 'assignedClientTeam']: userId,
      collaborators: [userId]
    }

    dispatch(
      updateSubTaskTeammate({ id: taskState._id, data }, (res, err) => {
        taskUpdateCallback(res, err)
        if (!err) {
          setTaskState(res)
        }
      })
    )
  }

  const handleRemoveCollaborator = ({ userId, userRole }) => {
    setSavingStatus('saving')
    // const data = {
    //   field: 'assignee_add'
    // }

    const data = {
      [userRole < userRoles.USER_CLIENT
        ? 'assignedAgencyTeam'
        : 'assignedClientTeam']: userId
    }

    dispatch(
      deleteSubtaskCollaborator({ id: taskState._id, data }, (res, err) => {
        taskUpdateCallback(res, err)
        if (!err) {
          setTaskState(res)
        }
      })
    )
  }

  const errorToaster = error => {
    dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
  }

  // const handleTaskStatus = taskStatus => {
  //   handlePopperClose()

  //   if (currentWorkspace.isComplete)
  //     dispatch(
  //       fireErrorToaster(
  //         t('ERROR_TASKSTATE_BELONGS_TO_A_COMPLETE_WORKSPACE', {
  //           data: taskState.title
  //         })
  //       )
  //     )
  //   else {
  //     setSavingStatus('saving')
  //     setTaskState(prev => ({ ...prev, taskSection: taskStatus._id }))
  //     if (subtaskPermission.update) {
  //       dispatch(
  //         updateSubTaskDetails({
  //           id: taskState._id,
  //           data: {
  //             field: 'taskSection',
  //             taskSection: taskStatus._id,
  //             workSpace: currentWorkspace._id,
  //             completedAt:
  //               taskStatus.label.toLowerCase() === 'completed'
  //                 ? new Date()
  //                 : null,
  //             markAsComplete: taskStatus.label.toLowerCase() === 'completed'
  //           },
  //           callback: (res, err) => {
  //             taskUpdateCallback(res, err, { resetProp: 'taskSection' })
  //           }
  //         })
  //       )
  //     }
  //   }
  // }

  const handleDeleteAttachmentDialog = fileUrl => {
    let arr = fileUrl.split('/'),
      fileName = arr[arr.length - 1]

    setAlertModal({
      type: 'attachmentDelete',
      loading: false,
      data: { fileName, fileUrl }
    })

    // setAttachmentDropdownData(prev => ({ ...prev, open: false }))
  }

  const handleDeleteAttachmentCallback = (res, err) => {
    // setAttachmentDropdownData({
    //   anchorEl: null,
    //   open: false,
    //   data: {}
    // })
    setAlertModal(prev => ({ ...prev, type: '' }))

    handlePopperClose()
    if (err) dispatch(fireErrorToaster(t('ERROR_UNABLE_TO_DELETE_ATTACHMENT')))
    else {
      setTaskState(res)
      dispatch(fireSuccessToaster(t('ATTACHMENT_DELETED')))
    }
  }

  const handleDeleteAttachment = () => {
    setAlertModal(prev => ({ ...prev, loading: true }))
    // setDeleteAttachmentModal(prevState => ({
    //   ...prevState,
    //   loading: true
    // }))

    dispatch(
      deleteSubtaskAttachment(
        { subtaskId: taskData._id, fileUrl: alertModal.data.fileUrl },
        handleDeleteAttachmentCallback
      )
    )
  }

  // const handlePreviewImageSubtask = data => {
  //   handleAttachmentDropDown()
  //   handlePreviewImage(data)
  // }

  const updateDescription = description => {
    setSavingStatus('saving')
    dispatch(
      updateSubTaskDetails(
        {
          subtaskId: taskState._id,
          data: {
            description,
            field: 'description',
            workSpace: taskState.workSpace,
            updateType: 'description',
            socketId
          }
        },
        taskUpdateCallback
      )
    )
  }

  const changeEstimateTime = data => {
    dispatch(
      updateSubTaskDetails(
        {
          subtaskId: taskData._id,
          data: {
            ...data,
            field: 'estimateTimeInString',
            workSpace: taskState.workSpace
          }
        },
        (res, err) => {
          if (err) dispatch(fireErrorToaster(res))
        }
      )
    )
  }

  // const handleMarkTaskAsComplete = value => {
  //   setSavingStatus('saving')
  //   setTaskState(prev => ({ ...prev, markAsComplete: value }))
  //   markTaskAsComplete({
  //     value,
  //     task: taskState,
  //     elmFor: 'subtask',
  //     board: currentBoard,
  //     socketId,
  //     cb: (res, err) => {
  //       taskUpdateCallback(res, err, { resetProp: 'markAsComplete' })
  //       if (!err) {
  //         setTaskState(res)
  //       }
  //     }
  //   })
  // }

  const toggleApprovalPanel = () => {
    dispatch(
      openApprovalModal({
        module: 'subTask',
        moduleId: taskState._id,
        approvalItemName: taskState.title,
        approvers: workspace.assignedAgencyTeam
          .concat(workspace.assignedClientTeam)
          .map(item => item.user)
      })
    )
    // setApprovalPanel(prev => ({ ...prev, open: !prev.open }))
  }

  // const addNewApproval = data => {
  //   setApprovalPanel(prev => ({ ...prev, data: [data, ...prev.data] }))
  // }

  // const removeApprovalById = approvalId => {
  //   setApprovalPanel(prev => ({
  //     ...prev,
  //     data: [...prev.data.filter(item => item._id !== approvalId)]
  //   }))
  // }

  // const updateApproval = updatedData => {
  //   setApprovalPanel(prev => ({
  //     ...prev,
  //     data: prev.data.map(item =>
  //       item._id === updatedData._id ? updatedData : item
  //     )
  //   }))
  // }

  const scrollToBottom = () => {
    logsBottomRef.current.scrollIntoView({ behavior: 'smooth' })
    if (selectedTab !== 0) setSelectedTab(0)
  }

  return (
    <>
      <TaskPanelHeader
        task={taskState}
        onClose={onClose}
        handleAttachmentUpload={handleAttachmentUpload}
        handleOpenDeleteTaskConfirm={handleClickOpenConfirm}
        innerProjectPermission={innerProjectPermission}
        triggerPopover={triggerPopover}
        elmFor="subtask"
        changeEstimateTime={changeEstimateTime}
        // socketId={socketId}
        canUpdateTask={canUpdateTask}
        // markTaskAsComplete={handleMarkTaskAsComplete}
        // currentWorkspace={currentWorkspace}
        currentBoard={currentBoard}
        inDashboardArea={inDashboardArea}
        openApprovalPanel={toggleApprovalPanel}
        updateTaskData={updateTaskState}
      />
      {/* <DrawerContent open={open} style={{ padding: 0 }}> */}
      <div className="px-8 flex-grow overflow-y-auto" style={{ padding: 0 }}>
        <div className="overflow-y-auto">
          <div className="px-2 mt-2 mb-2 flex items-center">
            <div className="flex-1">
              <TaskTitle
                innerProjectPermission={innerProjectPermission}
                taskTitle={taskState.title}
                updateTitle={updateTitle}
                elmFor="subtask"
              />
            </div>
            <div className="text-right px-8">
              <AutoSavingIndicator status={savingStatus} />
            </div>
          </div>

          <TaskBody
            innerProjectPermission={innerProjectPermission}
            taskState={taskState}
            handleDateChange={handleDateChange}
            setTaskState={setTaskState}
            triggerPopover={triggerPopover}
            // updateLog={updateLog}
            errorToaster={errorToaster}
            elmFor="subtask"
            addCollaborator={handleAddCollaborator}
            removeCollaborator={handleRemoveCollaborator}
            workspace={workspace}
          />

          <div className="px-6 py-2">
            <TaskDescription
              taskId={taskState._id}
              innerProjectPermission={innerProjectPermission}
              taskDescription={taskState.description}
              updateDescription={updateDescription}
              handleAttachmentUpload={handleUploadDescriptionAttachment}
              ref={taskDescriptionRef}
              workspace={workspace}
              collaborators={taskState.collaborators}
              elmFor="subtask"
            />
          </div>

          <div className="px-4 flex items-start flex-wrap mx-0 my-4 sm:px-6">
            {taskState?.imageUrl?.length > 0 &&
              taskState?.imageUrl?.map((item, index, arr) => (
                <AttachmentImage
                  key={index}
                  fileUrl={item}
                  allFileUrls={arr}
                  fileIndex={index}
                  // attachmentDropdownData={attachmentDropdownData}
                  // handleAttachmentDropDown={handleAttachmentDropDown}
                  handleDeleteAttachment={handleDeleteAttachmentDialog}
                />
              ))}
            {innerProjectPermission.update && (
              <AddAttachment
                labelProps={{ className: classes.attachmentAddbtn }}
                handleFile={handleAttachmentUpload}
                id={'subtask-attachment'} //don't remove this id
                disabled={loadingAttachment}
              >
                {loadingAttachment ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Add />
                )}
              </AddAttachment>
            )}
          </div>
          <div>
            {taskState._id && (
              <LogsSection
                task={taskData}
                elmFor="subtask"
                workspace={workspace}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            )}
            <span ref={logsBottomRef}></span>
          </div>
        </div>
      </div>
      {/* </DrawerContent> */}

      {taskState._id && (
        <TaskPanelFooter
          taskId={taskData.task}
          subtaskId={taskState._id}
          elmFor="subtask"
          setSelectedTab={setSelectedTab}
          workspace={workspace}
          boardId={taskState.workspaceBoard}
          socketId={socketId}
          uploadTaskAttachment={handleUploadSubtaskAttachment}
          scrollToBottom={scrollToBottom}
          checkAndGetNewFileName={checkAndGetNewFileName}
          imageUrls={taskData.imageUrl}
          innerProjectPermission={innerProjectPermission}
          collaborators={taskState.collaborators}
          getCollaboratorsOptions={getCollaboratorsOptions}
          updateTaskState={updateTaskState}
        />
      )}

      <AlertModal
        warningText={t('TASK_DELETE_ALERT_MESSAGE', {
          data: `"${taskData?.title}"`
        })}
        handleDialog={handleCloseAlert}
        open={alertModal.type === 'taskDelete'}
        handleDeleteAction={handleOnDeleteTask}
        // archiveButton={false}
        loading={alertModal.loading}
        style={{ zIndex: 2000 }}
      />
      <AlertModal
        warningText={t('WARNING_THE_ATTACHMENT_NAMED_WILL_BE_DELETED', {
          data: alertModal.data.fileName
        })}
        handleDialog={handleCloseAlert}
        open={alertModal.type === 'attachmentDelete'}
        handleDeleteAction={handleDeleteAttachment}
        loading={alertModal.loading}
      />

      <CollabDrowdown
        open={popperOpen === 'collab' ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        list={collabOptions}
        handleListItemClick={teammate =>
          handleAddCollaborator({
            userId: teammate.user._id,
            userRole: teammate.role
          })
        }
        style={{ zIndex: 2000 }}
        getUser={listItem => ({ ...listItem.user, role: listItem.role })}
      />
    </>
  )
}

export default React.memo(SubtaskPanel)
