import React, { useEffect, useState } from 'react'
import { Button, IconButton, styled } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import userRoles, { getUserPath } from 'utils/userRoles'
import {
  ColoredAvatarGroup,
  sizeStyles
} from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import useWorkspacePermission from 'customHooks/useWorkspacePermission'
import HeaderProfileMenu from '../HeaderProfileMenu'
// import headerStyles from '../headerStyles'
import AsanaIcons from 'static/asana/AsanaIcons'
// import WorkspaceMenu from 'components/UserDashboard/Workspaces/WorkspaceMenu'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { useHistory } from 'react-router'
import {
  archiveWorkspace,
  moveWorkspaceToTrash,
  UpdateWorkspceCompleteStatus,
  createWorkspaceCopy,
  deleteWorkspace
} from 'thunks/workspace/actions'
import WorkspaceTabs from './WorkspaceTabs/WorkspaceTabs'
import Pill from 'global/globalComponents/Pill/Pill'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireProcessToaster
} from 'thunks/fireToaster/actions'
import AssignedTags from 'components/UserDashboard/Workspaces/AssignedTags'
import ShareBoardModal from 'components/Boards/ShareBoardModal'
import { ReactComponent as TwoUserIcon } from 'static/svg/users.svg'
import { ReactComponent as CommentTextIcon } from 'static/svg/comment-text.svg'
import { toggleProjectPermissionModal } from 'thunks/generlAppState/actions'
import WorkspaceIconPopover from 'components/UserDashboard/Workspaces/WorkspaceIconPopover'
import { CheckBoxField, CustomTooltip } from 'global/globalComponents'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useTranslation } from 'react-i18next'
import { getErrorMessages } from 'utils/errorMessages'
import { Badge } from '@material-ui/core'
import { toggleChatPanel } from 'thunks/chat/actions'
import EditProjectModal from 'components/UserDashboard/Workspaces/EditProjectModal/EditProjectModal'
import WorkspaceMenu from 'components/UserDashboard/Workspaces/WorkspaceMenu'
import headerStyles from '../headerStyles'
import CreateStandupsModal from 'components/Standups/CreateStandupsModal'
import ProjectConnect from './ProjectConnect'

const WorkspaceHeader = () => {
  const { t } = useTranslation()
  const errorMessages = getErrorMessages()
  const dispatch = useDispatch()
  const classes = headerStyles()
  const { currentWorkspace, currentBoard } = useSelector(
    state => state.userWorkspaces
  )
  const userTags = useSelector(state => state.userTags)
  const userAccountPermission = useSelector(
    state => state.permission.data.access
  )
  // const { workspace: workspacePermissionData } = useWorkspacePermission(
  //   currentWorkspace?._id
  // )
  const innerProjectPermission = useWorkspacePermission(currentWorkspace?._id)
  const meData = useSelector(state => state.me.data)
  const userData = useSelector(state => state.user.data)
  const history = useHistory()
  const [workspaceMenuEl, setWorkspaceMenuEl] = useState('')
  // const [loading, setLoading] = useState(false)
  const [editWorkspaceDialog, setEditWorkspaceDialog] = useState({
    open: false,
    workspace: {}
  })
  const [deleteWorkspaceModal, setDeleteWorkspaceModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [archiveWorkspaceModal, setArchiveWorkspaceModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [completeWorkspaceModal, setCompleteWorkspaceModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [embedModalOpen, setEmbedModalOpen] = useState(false)
  const [teammates, setTeammates] = useState([])
  const [shareBoladDialogStatus, setShareBoladDialogStatus] = useState(false)
  const [iconPopoverData, setIconPopoverData] = useState({
    open: false,
    anchorEl: null
  })
  const [moveToTrashChecked, setMoveToTrashChecked] = useState(false)
  const [standupsModalOpen, setStandupsModalOpen] = useState(false)
  // const [editName, setEditName] = useState({ edit: false, projectName: '' })

  useEffect(() => {
    if (currentWorkspace.assignedAgencyTeam) {
      setTeammates(
        [
          ...currentWorkspace.assignedAgencyTeam,
          ...currentWorkspace.assignedClientTeam
        ].map(item => item.user)
      )
    }
  }, [currentWorkspace.assignedAgencyTeam, currentWorkspace.assignedClientTeam])

  // const workspacePermission = {
  //   view: userAccess?.workspace.view,
  //   create: userAccess?.workspace.create,
  //   update: workspacePermissionData?.update,
  //   delete: workspacePermissionData?.delete
  // }

  const openWorkspaceMenu = e => {
    setWorkspaceMenuEl(e.currentTarget)
  }
  const closeWorkspaceMenu = e => {
    setWorkspaceMenuEl(null)
  }

  const handleOpenEditWorkspaceDialog = workspace => {
    if (innerProjectPermission.update) {
      if (editWorkspaceDialog.open) {
        setEditWorkspaceDialog(prev => ({ ...prev, open: false }))
      } else {
        setEditWorkspaceDialog({
          open: true,
          workspace
        })
      }
    } else
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
  }

  const handleDeleteWorkspaceModal = workspace => {
    if (userAccountPermission?.workspace?.delete) {
      if (deleteWorkspaceModal.open) {
        setDeleteWorkspaceModal({
          open: false,
          data: {},
          loading: false
        })
      } else {
        setDeleteWorkspaceModal({
          open: true,
          data: workspace,
          loading: false
        })
      }
    } else
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
  }

  const handleArchiveWorkspaceModal = workspace => {
    if (innerProjectPermission.update) {
      if (archiveWorkspaceModal.open) {
        setArchiveWorkspaceModal({
          open: false,
          data: {},
          loading: false
        })
      } else {
        setArchiveWorkspaceModal({
          open: true,
          data: workspace,
          loading: false
        })
      }
    } else
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
  }

  const handleCompleteWorkspaceModal = workspace => {
    if (innerProjectPermission.update) {
      if (completeWorkspaceModal.open) {
        setCompleteWorkspaceModal({
          open: false,
          data: {},
          loading: false
        })
      } else {
        setCompleteWorkspaceModal({
          open: true,
          data: workspace,
          loading: false
        })
      }
    } else
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
  }

  const handleDeleteWorkspace = () => {
    setDeleteWorkspaceModal(prev => ({ ...prev, loading: true }))

    if (moveToTrashChecked) {
      dispatch(
        moveWorkspaceToTrash(
          deleteWorkspaceModal.data._id,
          deleteWorkspaceCallback
        )
      )
    } else {
      dispatch(
        deleteWorkspace(deleteWorkspaceModal.data._id, deleteWorkspaceCallback)
      )
    }
  }

  const deleteWorkspaceCallback = (res, err) => {
    if (err) {
      setDeleteWorkspaceModal(prev => ({ ...prev, loading: false }))
    } else {
      handleDeleteWorkspaceModal()
      history.push(`${getUserPath(meData.role)}/workspace/all`)
    }
  }

  const handleArchiveWorkspace = () => {
    setArchiveWorkspaceModal(prev => ({ ...prev, loading: true }))
    dispatch(
      archiveWorkspace(
        archiveWorkspaceModal.data._id,
        !archiveWorkspaceModal.data.isArchived,
        handleArchiveCallback
      )
    )
  }

  const handleArchiveCallback = (res, err) => {
    if (err) setArchiveWorkspaceModal(prev => ({ ...prev, loading: false }))
    else {
      handleArchiveWorkspaceModal()
      history.push(`${getUserPath(meData.role)}/workspace/all`)
    }
  }

  const completeStatus = () => {
    setCompleteWorkspaceModal(prev => ({ ...prev, loading: true }))
    dispatch(
      UpdateWorkspceCompleteStatus(
        currentWorkspace._id,
        { isComplete: true },
        completeStatusCallback
      )
    )
  }

  const completeStatusCallback = (res, err) => {
    if (err) {
      setCompleteWorkspaceModal(prev => ({ ...prev, loading: false }))
      dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
    } else {
      handleCompleteWorkspaceModal()
      history.push(`${getUserPath(meData.role)}/workspace/all`)
    }
  }

  const assignedTags = id => {
    const workspaceTag = userTags.data?.find(tag =>
      tag.workspaceIds.includes(id)
    )
    if (workspaceTag) {
      return [workspaceTag]
    } else {
      return []
    }
  }

  // const closeEmbedModalHandler = () => {
  //   setEmbedModalOpen(false)
  // }

  const duplicateWorkspaceHandler = (workspace, data) => {
    dispatch(
      createWorkspaceCopy(workspace?._id, data, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(t('DUPLICATE_WAS_CREATED_SUCCESSFULLY')))
        } else {
          // setLoading(false)
          dispatch(fireErrorToaster(res))
        }
      })
    )
    // setLoading(true)
    dispatch(
      fireProcessToaster(
        `Duplicate of ${workspace.name} is being created! Please wait...`
      )
    )
  }

  const handleShareBoardDialog = () => {
    setShareBoladDialogStatus(prev => !prev)
  }

  // const handleSidebar = () => {
  //   dispatch(toggleSidebar())
  // }

  const toggleAddTeammatePanel = () => {
    dispatch(toggleProjectPermissionModal())
  }

  const toggleStandupsModal = () => {
    setStandupsModalOpen(prev => !prev)
  }

  // const openEmbedModalHandler = () => {
  //   setEmbedModalOpen(true)
  // }

  return (
    <>
      <div className="w-full min-w-full h-full px-4 2xl:px-6 bg-white dark:bg-dark-main">
        <div className="w-full h-full flex flex-1 items-center relative">
          <div className="flex items-center h-full flex-auto pr-2 2xl:pr-4">
            <div className="flex items-center h-full flex-grow">
              {/* Icon, Title, tags and edit */}
              <div className="flex items-center font-medium mr-4">
                <div className="flex items-center">
                  <div
                    className="h-10 w-10 2xl:h-12 2xl:w-12 rounded-xl overflow-hidden flex items-center justify-center cursor-pointer mr-2"
                    style={{
                      backgroundColor: currentWorkspace?.backgroundcolor,
                      boxShadow: 'inset 0 -2px rgb(0 0 0 / 5%)'
                    }}
                  >
                    {AsanaIcons[currentWorkspace?.image] ? (
                      AsanaIcons[currentWorkspace?.image]({
                        height: '24px',
                        width: '24px',
                        className: 'selected-icon'
                      })
                    ) : (
                      <AsanaIcons.Bug height="24px" width="24px" />
                    )}
                  </div>
                  <CustomTooltip title={currentWorkspace?.name} placement="top">
                    <h2
                      className="flex-1 truncate text-sm 2xl:text-base dark:text-dark-light mr-2"
                      style={{ maxWidth: 150 }}
                      // onClick={handleProjectNameUpdate}
                    >
                      {currentWorkspace?.name}
                    </h2>
                  </CustomTooltip>
                  {userTags.fetched ? (
                    <AssignedTags
                      assignedTag={assignedTags(currentWorkspace?._id)}
                      workspace={currentWorkspace}
                      elmFor="workspace"
                      className="flex-wrap mr-2"
                    />
                  ) : null}

                  {userAccountPermission?.workspace?.update && (
                    <IconButton
                      className={classes.headerBtns}
                      onClick={openWorkspaceMenu}
                    >
                      <MoreVertRoundedIcon
                        fontSize="small"
                        className="dark:text-dark-light"
                      />
                    </IconButton>
                  )}
                </div>
              </div>

              <WorkspaceTabs
                embedModalOpen={embedModalOpen}
                setEmbedModalOpen={setEmbedModalOpen}
                // openEmbedModalHandler={openEmbedModalHandler}
              />
            </div>
          </div>

          <div className="flex items-center gap-3 flex-shrink-0 pl-2 2xl:pl-4">
            {currentWorkspace?.isArchived && (
              <Pill variant="gray">{t('ARCHIVED')}</Pill>
            )}
            {currentWorkspace?.isComplete && (
              <Pill variant="green">{t('COMPLETED')}</Pill>
            )}

            <div className="flex items-center">
              <ColoredAvatarGroup
                users={teammates}
                tooltip={true}
                // expandOnHover={true}
              />
              {[
                userRoles.USER_AGENCY,
                userRoles.AGENCY_ADMIN,
                userRoles.USER_CLIENT,
                userRoles.PROJECT_OWNER
              ].includes(innerProjectPermission.role) && (
                <button
                  onClick={toggleAddTeammatePanel}
                  className="-ml-2 bg-white text-gray-500 w-6 h-6 2xl:w-8 2xl:h-8 rounded-full border border-gray-300 border-dashed flex justify-center items-center"
                >
                  <AddIcon />
                </button>
              )}
            </div>

            <ChatButton workspaceId={currentWorkspace._id} />

            <div>
              {innerProjectPermission.update &&
                meData.role < userRoles.USER_CLIENT && (
                  <Button
                    size="small"
                    variant="outlined"
                    // color="secondary"
                    startIcon={
                      <TwoUserIcon className="w-3 h-3 2xl:w-4 2xl:h-4" />
                    }
                    onClick={handleShareBoardDialog}
                  >
                    {t('SHARE')}
                  </Button>
                )}
            </div>

            {meData.role === userRoles.USER_AGENCY && <ProjectConnect />}

            {/*
          Here i'm sending user data instead of me data bcoz when user updates his profile picture
          user data gets updated and not the me data (meData vs userData issue)
          */}
            <HeaderProfileMenu
              meData={userData}
              toggleStandupsModal={toggleStandupsModal}
              size="medium"
            />
          </div>
        </div>
      </div>
      <WorkspaceMenu
        open={Boolean(workspaceMenuEl)}
        anchorEl={workspaceMenuEl}
        onClose={closeWorkspaceMenu}
        workspace={currentWorkspace}
        duplicateWorkspace={duplicateWorkspaceHandler}
        EditWorkspace={handleOpenEditWorkspaceDialog}
        deleteWorkspace={handleDeleteWorkspaceModal}
        archiveWorkspace={handleArchiveWorkspaceModal}
        completeStatus={handleCompleteWorkspaceModal}
      />

      <WorkspaceIconPopover
        setIconPopoverData={setIconPopoverData}
        iconPopoverData={iconPopoverData}
        onClose={() => setIconPopoverData({ open: false, anchorEl: null })}
        workspace={currentWorkspace}
      />

      <EditProjectModal
        open={editWorkspaceDialog.open}
        onClose={handleOpenEditWorkspaceDialog}
        workspaceData={editWorkspaceDialog.workspace}
      />

      <AlertModal
        warningText={t('PROJECT_DELETE_ALERT_MESSAGE', {
          data: `"${deleteWorkspaceModal.data.name}"`
        })}
        open={deleteWorkspaceModal.open}
        checkboxElm={
          <>
            <CheckBoxField
              label={t('MOVE_TO_TRASH_CHECKBOX_MESSAGE')}
              checked={moveToTrashChecked}
              onChange={e => setMoveToTrashChecked(e.target.checked)}
            />
          </>
        }
        handleDialog={handleDeleteWorkspaceModal}
        handleDeleteAction={handleDeleteWorkspace}
        loading={deleteWorkspaceModal.loading}
      />

      <AlertModal
        warningText={t(
          'WARNING_ALL_THE_TASKS_AND_ATTACHMENTS_RELATED_TO_THIS_PROJECT_ARCHIVED_OR_UNARCHIVED',
          {
            data: archiveWorkspaceModal.data.isArchived
              ? 'unachived'
              : 'archived'
          }
        )}
        open={archiveWorkspaceModal.open}
        handleDialog={handleArchiveWorkspaceModal}
        handleDeleteAction={handleArchiveWorkspace}
        loading={archiveWorkspaceModal.loading}
        deleteBtnText={
          archiveWorkspaceModal.data.isArchived ? t('UNARCHIVE') : t('ARCHIVE')
        }
      />

      {/* Mark As complete Dialog Box */}
      <AlertModal
        warningText={t(
          'WARNING_ONCE_YOU_MARK_THIS_PROJECT_AS_COMPLETED_YOU_WONT_BE_ABLE_TO_CHANGE_ITS_STATUS_BACK'
        )}
        open={completeWorkspaceModal.open}
        handleDialog={handleCompleteWorkspaceModal}
        handleDeleteAction={completeStatus}
        loading={completeWorkspaceModal.loading}
        deleteBtnText={t('COMPLETE')}
      />

      {currentWorkspace?.sharing && shareBoladDialogStatus && (
        <ShareBoardModal
          open={shareBoladDialogStatus}
          onClose={handleShareBoardDialog}
          workspace={currentWorkspace}
          boardSections={currentBoard.boardSection ?? []}
          sharing={currentWorkspace.sharing}
          innerProjectPermission={innerProjectPermission}
        />
      )}

      {standupsModalOpen && (
        <CreateStandupsModal
          open={standupsModalOpen}
          onClose={toggleStandupsModal}
        />
      )}
    </>
  )
}

const ChatButton = ({ workspaceId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { unreadMessages, groups } = useSelector(state => state.chats)
  const currentWorkspaceChannel = groups.data.find(
    group => group.type === 'workspace' && group.workspace === workspaceId
  )
  let unreadMessageCount = 0

  if (currentWorkspaceChannel && unreadMessages[currentWorkspaceChannel._id]) {
    unreadMessageCount = unreadMessages[currentWorkspaceChannel._id].unreadCount
  }

  const handleChatClick = () => {
    dispatch(toggleChatPanel())
  }

  return (
    <StyledBadge
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant="dot"
      size={sizeStyles.tiny.width.split('px')[0]}
      invisible={!Boolean(unreadMessageCount)}
    >
      <Button
        onClick={handleChatClick}
        size="small"
        variant="outlined"
        // color="secondary"
        startIcon={<CommentTextIcon className="w-4 h-4" />}
        // className="text-primary-main border border-primary-main dark:border-dark-light rounded cursor-pointer"
      >
        {t('CHAT')}
        {/* <span className="inline-flex my-auto justify-center items-center rounded-full hover:bg-primary-light dark:text-dark-light dark:hover:text-dark-light2 dark:hover:bg-gray-900 px-2 py-1 w-full">
          <CommentTextIcon className="text-sm" />
          <span className="ml-1 text-smaller">{t('CHAT')}</span>
        </span> */}

        {/* <div className="tab-line w-3/4 h-1 mx-auto dark:bg-white"></div> */}
      </Button>
    </StyledBadge>
  )
}

const StyledBadge = styled(Badge, {
  shouldForwardProp: prop => prop !== 'size'
})(({ theme, size }) => ({
  '& .MuiBadge-badge': {
    position: 'absolute',
    top: `${size * 0.15}px`,
    right: `${size * 0.15}px`,
    backgroundColor: '#d32f2f',
    // color: '#ba000d',
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`
  },
  '& .MuiBadge-invisible': {
    display: 'none'
  }
}))

export default React.memo(WorkspaceHeader)
