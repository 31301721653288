import React from 'react'
import clsx from 'clsx'
import { CustomTooltip, OutlinedButton } from 'global/globalComponents'
import Pill from 'global/globalComponents/Pill/Pill'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
// import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
// import { ReactComponent as CheckCircleFilled } from 'static/svg/check-circle-filled.svg'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from 'static/svg/checks.svg'

const StandupsList = ({
  data,
  handleRemoveStandup,
  toggleStandupCompletion,
  toggleDeleteStandupAlert,
  openEditTaskPanel,
  canUpdate = false,
  canDelete = false,
  canRemove = false
}) => {
  const { t } = useTranslation()
  let sortedData = data.sort((a, b) => a.idx - b.idx)
  if (data.length === 0) return null
  return (
    <div className="pb-4">
      <div className="flex justify-between my-2 px-2">
        <span className="font-semibold text-smaller dark:text-dark-light">
          {t('TASK_NAME')}
        </span>
        {(canUpdate || canDelete) && (
          <span className="font-semibold text-smaller dark:text-dark-light">
            {t('ACTION')}
          </span>
        )}
      </div>
      <ol>
        {sortedData.map((item, index) => (
          <StandupsListItem
            key={item.idx}
            standupData={item}
            index={index}
            handleRemoveStandup={handleRemoveStandup}
            toggleStandupCompletion={toggleStandupCompletion}
            toggleDeleteStandupAlert={toggleDeleteStandupAlert}
            openEditTaskPanel={openEditTaskPanel}
            canUpdate={canUpdate}
            canDelete={canDelete}
            canRemove={canRemove}
          />
        ))}
      </ol>
    </div>
  )
}

const StandupsListItem = ({
  standupData,
  index,
  handleRemoveStandup,
  toggleStandupCompletion,
  toggleDeleteStandupAlert,
  openEditTaskPanel,
  canUpdate,
  canDelete,
  canRemove
}) => {
  const { t } = useTranslation()
  const handleRemoveState = () => {
    if (canRemove) {
      handleRemoveStandup(index)
    } else {
      toggleDeleteStandupAlert(standupData)
    }
  }

  const handleStandupCompletion = () => {
    toggleStandupCompletion(standupData)
  }

  const handleOpenTaskPanel = () => {
    openEditTaskPanel(standupData.task._id ?? standupData.task)
  }

  return (
    <li className="flex items-center py-2 pr-2 bg-gray-50 border-t border-b pl-2 dark:bg-dark-main">
      <span className="mr-2 text-sm text-gray-600 dark:text-dark-light">
        {index + 1}.
      </span>

      <div className="flex items-center dark:text-dark-light">
        <div className="cursor-pointer" onClick={handleOpenTaskPanel}>
          <CustomTooltip
            title={
              standupData?.fullName?.length > 26 ? standupData?.fullName : ''
            }
          >
            <span
              className={clsx(
                'block text-smaller font-medium capitalize text-gray-600 dark:text-dark-light break-long-word',
                standupData.taskDeleted && 'line-through'
              )}
            >
              {standupData.name}
            </span>
          </CustomTooltip>
        </div>
        <div className="ml-4">
          <Pill
            variant="blue"
            style={{ paddingLeft: 8, paddingRight: 8, maxWidth: 300 }}
            className="truncate"
          >
            {standupData.projectName}
          </Pill>
        </div>
      </div>

      <div className="ml-auto leading-3 flex items-center space-x-4">
        {standupData.task?.estimateTimeInString && (
          <span className="text-xs text-gray-500">
            Est: {standupData.task.estimateTimeInString}
          </span>
        )}
        {/* {canUpdate && !standupData.taskDeleted && (
          <CustomTooltip
            title={
              standupData.isComplete ? t('MARK_INCOMPLETE') : t('MARK_COMPLETE')
            }
            placement="top"
          >
            <button
              onClick={handleStandupCompletion}
              data-id={index}
              className={clsx(
                'text-custom-green-main',
                !standupData.isComplete && 'opacity-60 hover:opacity-100'
              )}
            >
              {standupData.isComplete ? <CheckCircleFilled /> : <CheckCircle />}
            </button>
          </CustomTooltip>
        )} */}
        {canUpdate && !standupData.taskDeleted && (
          <OutlinedButton
            variant="success"
            isActive={standupData.isComplete}
            onClick={handleStandupCompletion}
            roundedFull={false}
            hoverColorChange={false}
            // className={
            //   meData.role > userRoles.AGENCY_ADMIN
            //     ? 'cursor-not-allowed'
            //     : ''
            // }
          >
            <CheckIcon className="dark:text-dark-light" />
            <span className=" ml-2" style={{ fontSize: '12px' }}>
              {standupData.isComplete ? t('COMPLETED') : t('MARK_COMPLETE')}
            </span>
          </OutlinedButton>
        )}
        {(canRemove || canDelete) && (
          <CustomTooltip
            title={canRemove ? t('REMOVE') : t('DELETE')}
            placement="top"
          >
            <button
              className="text-custom-red-main opacity-60 hover:opacity-100"
              onClick={handleRemoveState}
            >
              <TrashIcon />
            </button>
          </CustomTooltip>
        )}
      </div>
    </li>
  )
}

export default React.memo(StandupsList)
