import axios from 'axios'
import { catchAsyncDispatch } from 'utils'
import { SUBSCRIPTIONS } from './reducer'

/**
 * Fetch all subscriptions
 * @param {Object} data.body  invoiceItems, trialDays, client
 * @param {Function} callback callback function
 */
export const fetchAllSubscriptions = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/clientsubscription/user'
    })

    dispatch({ type: SUBSCRIPTIONS.FETCHED, payload: res.data })
    if (callback) callback(res.data)
  })
}

/**
 * Fetch client subscriptions (for client owner)
 * @param {Object} data.body  invoiceItems, trialDays, client
 * @param {Function} callback callback function
 */
export const fetchClientSubscriptions = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/clientsubscription/user'
    })

    // Filtering out canceled subscriptions
    const filteredSubscriptions = res.data.filter(
      item => item.status !== 'canceled'
    )

    dispatch({ type: SUBSCRIPTIONS.FETCHED, payload: filteredSubscriptions })
    if (callback) callback(filteredSubscriptions)
  })
}

/**
 * Create client subscription
 * @param {Object} data.body  invoiceItems, trialDays, client
 * @param {Function} callback callback function
 */
export const createClientSubscription = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/clientsubscription',
      data: data.body
    })

    if (callback) callback(res.data)
    dispatch({ type: SUBSCRIPTIONS.ADD_NEW_SUBSCRIPTION, payload: res.data })
  }, callback)
}

/**
 * Update client subscription
 * * @param {String} data.id  subscription id
 * @param {Object} data.body  update
 * @param {Function} callback callback function
 */
export const updateClientSubscription = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/clientsubscription/${data.id}`,
      data: data.body
    })

    dispatch({ type: SUBSCRIPTIONS.UPDATE_ONE_SUBSCRIPTION, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}
