const toCamelCase = str => {
  if (typeof str !== 'string') {
    throw new TypeError('Expected a string as input')
  }

  const camelCasedStr = str
    .split(' ')
    .map((s, i) => {
      if (i === 0) {
        return s.charAt(0).toLowerCase() + s.slice(1)
      }

      return s.charAt(0).toUpperCase() + s.slice(1)
    })
    .join('')

  return camelCasedStr
}

export default toCamelCase
