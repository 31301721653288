import React, { useState, useEffect } from 'react'
// import { CircularProgress } from '@material-ui/core'
import { FormLabel } from 'global/globalComponents'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
// import { ReactComponent as Add } from 'static/svg/plus.svg'
// import { ReactComponent as PaperClipIcon } from 'static/svg/paperclip.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { useTranslation } from 'react-i18next'
import { getFileType, getFileUrl } from 'utils'

const AttachmentField = ({
  attachments,
  handleAddAttachment,
  handleRemoveAttachment
}) => {
  const { t } = useTranslation()

  const handleAttachmentUpload = e => {
    const file = e.target.files[0]

    if (file) {
      handleAddAttachment(file)
    }
    e.target.value = ''
  }

  return (
    <div>
      <div className="mb-4">
        <FormLabel htmlFor="attachment">{t('ATTACHMENT')}</FormLabel>
        <input
          type="file"
          accept="*"
          className="custom-input-file w-full border border-gray-300 rounded-full text-smaller h-11 text-gray-600"
          onChange={handleAttachmentUpload}
        />
      </div>
      {attachments.length > 0 && (
        <div className="flex items-center overflow-auto pr-4">
          <div className="flex flex-wrap">
            {attachments.map((attachment, index) => (
              <div key={index} className="mr-4 mb-2">
                <Attachment
                  key={index}
                  index={index}
                  attachment={attachment}
                  handleRemoveAttachment={handleRemoveAttachment}
                />
              </div>
            ))}
          </div>

          {/* <button
          onClick={handleChooseFile}
          className="w-12 h-12 border border-dashed flex justify-center items-center rounded border-gray-400 text-gray-500 hover:text-gray-700 flex-shrink-0"
          disabled={loadingAttachment}
        >
          {loadingAttachment ? <CircularProgress size={20} /> : <Add />}
        </button> */}

          {/* <input
          ref={inputFileRef}
          type="file"
          name="attachment"
          hidden
          onChange={handleFileUpload}
          accept="*"
        /> */}
        </div>
      )}
    </div>
  )
}

export const Attachment = ({ attachment, index, handleRemoveAttachment }) => {
  const [fileData, setFileData] = useState({
    url: '',
    type: ''
  })

  useEffect(() => {
    if (attachment instanceof File) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setFileData({
          type: attachment.type.split('/')[0],
          url: reader.result
        })
      }
      reader.readAsDataURL(attachment)
    } else {
      setFileData({
        url:
          typeof attachment === 'object' ? getFileUrl(attachment) : attachment,
        type:
          typeof attachment === 'object'
            ? getFileType(attachment.extension)
            : 'image'
      })
    }
  }, [])

  return (
    <div className="relative w-40 h-24 object-cover border rounded-md overflow-hidden">
      <button
        type="button"
        className="absolute right-0 top-0 text-red-600 z-10"
        onClick={() => handleRemoveAttachment(index)}
      >
        <HighlightOffIcon size="small" />
      </button>
      {fileData.type === 'image' ? (
        <img
          src={fileData.url}
          alt="attachment"
          className="outline-none rounded object-cover w-full h-full"
        />
      ) : fileData.type === 'video' ? (
        <video className="w-full h-full" controls>
          <source src={fileData.url} />
          Your browser does not support this video format.
        </video>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <FileIcon className="w-8 h-8 text-gray-600" />
        </div>
      )}
    </div>
  )
}

export default AttachmentField
