// import { ThreeSixtySharp } from '@material-ui/icons'
import axios from 'axios'
// import { getAuthToken } from 'utils'
import { getErrorMessages } from 'utils/errorMessages'
import { AGENCY_PAYMENT } from './reducers'
import { catchAsync } from 'utils'
const errorMessages = getErrorMessages()

/**
 * Add a new plan for agency
 * @param {object} data email,plan,quantity
 * @param {Function} callback callback function
 */
export const agencyPayment = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/agencypayment',
    data: data.body,
    headers: data.headers || {}
  })

  if (callback) callback(res.data)
})

/**
 * Update plan for agency
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */

export const agencyUpgradePlan = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: '/agencypayment/upgrade',
    data: data.body
  })

  callback(res.data)
})

export const redeemCode = async (data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: '/coupen/update/appsumo',
      data: data.body
    })
    callback(res.data, false)
  } catch (err) {
    if (err.response.data.coupen) {
      callback(err.response.data.coupen[0]?.message, true)
    } else {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Update Card for agency
 * @param {object} data payment method object
 * @param {Function} callback callback function
 */
export const updateAgencyPaymentCard = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: 'agencypayment/card',
    data: data.body
  })
  callback(res.data)
})

/**
 * cancel agency subscription
 * @param {object} data payment method object
 * @param {Function} callback callback function
 */
export const cancelAgencySubscription = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/agencypayment/cancel',
    method: 'PUT',
    data: data.body
  })

  if (callback) callback(res.data)
})

// /**
//  * Generate invoice
//  * @param {object} data
//  * @param {Function} callback callback function
//  */
// export const generateInvoice = (data, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'POST',
//         url: `/agencypayment/invoice`,
//         data
//       })

//       dispatch({
//         type: AGENCY_PAYMENT.ADD_INVOICE,
//         payload: res.data.payment ?? res.data
//       })
//       if (callback) callback(res.data, false)
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//     }
//   }
// }

// /**
//  * send payment reminder to user with pending invoice
//  * @param {String} invoiceId invoice id
//  * @param {Function} callback callback function
//  */
// export const sendPaymentReminder = async (invoiceId, callback) => {
//   try {
//     const res = await axios({
//       method: 'GET',
//       url: `/clientinvoice/sendreminder/${invoiceId}`
//     })
//     if (callback) callback(res.data, false)
//   } catch (err) {
//     if (callback)
//       callback(
//         err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//         true
//       )
//   }
// }

// /**
//  * send payment receipt to user with pending invoice
//  * @param {String} invoiceId invoice id
//  * @param {Function} callback callback function
//  */
// export const sendPaymentReciept = async (invoiceId, callback) => {
//   try {
//     const res = await axios({
//       method: 'GET',
//       url: `/clientinvoice/sendreciept/${invoiceId}`
//     })
//     if (callback) callback(res.data, false)
//   } catch (err) {
//     if (callback)
//       callback(
//         err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//         true
//       )
//   }
// }

/**
 * get card details of user
 * @param {Function} callback callback function
 */
export const fetchCardDetails = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/agencypayment/card'
  })

  if (callback) callback(res.data)
})

/**
 * get card details of user
 * @param {Function} callback callback function
 */
export const deletePaymentCard = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: '/agencypayment/card',
    data: data.body
  })
  if (callback) callback(res.data)
})

export const fetchAllAgencyInvoices = callback => {
  return async dispatch => {
    dispatch({ type: AGENCY_PAYMENT.LOADING, payload: true })
    try {
      const res = await axios({
        url: '/agencypayment/agency/invoice',
        method: 'GET'
      })

      dispatch({
        type: AGENCY_PAYMENT.FETCH_ALL_INVOICES,
        payload: res.data.filter(item => item.status !== 'draft')
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          false
        )
    }
  }
}

export const createFreePlan = catchAsync(async (data, callback) => {
  const res = await axios({
    url: 'agencypayment/freeplan',
    method: 'POST',
    data: data.body
  })

  if (callback) callback(res.data)
})

/**
 * Verify payment by session id
 * @param {Function} callback callback function
 */
export const verifyPaymentBySessionId = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/agencypayment/verify?session_id=${data.sessionId}`,
    method: 'GET',
    isPublic: true
  })

  if (callback) callback(res.data)
})
