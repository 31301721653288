import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, useTheme } from '@material-ui/core'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

import { updateClientCompany } from 'thunks/clientCompany/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Countries from 'static/countries/Countries'
import { fileToDataURL } from 'utils'
import { useTranslation } from 'react-i18next'

const AddressModal = ({ open, handleClose, company }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [companyData, setCompanyData] = useState({
    address: {
      city: '',
      country: '',
      line1: '',
      line2: '',
      state: '',
      zipCode: ''
    }
  })

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    dropdownIndicator: styles => ({
      ...styles,
      paddingTop: 7,
      paddingBottom: 7,
      height: '30px'
    })
  }

  useEffect(() => {
    setCompanyData({
      address: company.address || {
        city: '',
        country: '',
        line1: '',
        line2: '',
        state: '',
        zipCode: ''
      }
    })
  }, [company])

  const handleAddressChange = e => {
    const { name, value } = e.target
    setCompanyData(prev => ({
      ...prev,
      address: { ...prev.address, [name]: value }
    }))
  }

  const handleCountryChange = e => {
    setCompanyData(prev => ({
      ...prev,
      address: { ...prev.address, country: e }
    }))
  }

  const imageLoadHandler = e => {
    const file = e.target.files[0]
    fileToDataURL(file).then(res =>
      setCompanyData(prev => ({ ...prev, file: res }))
    )
  }

  const handleButtonClick = () => {
    setLoading(true)
    dispatch(
      updateClientCompany(
        { companyId: company._id, data: companyData },
        (res, err) => {
          setLoading(false)
          if (!err) {
            dispatch(fireSuccessToaster(t('ADDRESS_UPDATED_SUCCESSFULLY')))
            handleClose()
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  return (
    <CustomModal
      // classes={{ paper: classes.dialogPaper }}
      open={open}
      handleClose={handleClose}
    >
      <CustomModalHeader
        heading={t('CHANGE_ORGANIZATION_ADDRESS')}
        handleClose={handleClose}
      />

      <CustomModalBody className="pb-4">
        <div>
          <FormLabel>{t('ORGANIZATION_ADDRESS')}</FormLabel>
          <div className="grid grid-cols-8 gap-4">
            <TextField
              placeholder={t('LINE_1')}
              name="line1"
              className="w-full col-span-4"
              value={companyData.address.line1}
              onChange={handleAddressChange}
            />
            <TextField
              placeholder={t('LINE_2')}
              name="line2"
              value={companyData.address.line2}
              className="w-full col-span-4"
              onChange={handleAddressChange}
            />
            <TextField
              placeholder={t('CITY')}
              value={companyData.address.city}
              name="city"
              className="w-full col-span-4"
              onChange={handleAddressChange}
            />
            <TextField
              value={companyData.address.state}
              name="state"
              placeholder={t('STATE')}
              className="w-full col-span-4"
              onChange={handleAddressChange}
            />

            <Select
              className="w-full col-span-4 text-sm"
              placeholder={t('COUNTRY')}
              isSearchable={true}
              options={Countries}
              value={companyData.address.country}
              maxMenuHeight={250}
              onChange={handleCountryChange}
              menuPlacement="top"
              styles={{ ...reactSelectCustomStyles(theme), ...customStyles }}
            />
            <TextField
              placeholder={t('ZIP_CODE')}
              name="zipCode"
              value={companyData.address.zipCode}
              className="w-full col-span-4 "
              onChange={handleAddressChange}
              type="text"
            />
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button size="large" onClick={handleClose} variant="outlined">
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          loading={loading}
          onClick={handleButtonClick}
        >
          {t('SAVE_CHANGES')}
        </LoadingButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default AddressModal
