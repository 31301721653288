import Quill from 'quill'

function getQuillHTML(inputDelta, valueType = 'text') {
  var tempQuill = new Quill(document.createElement('div'))
  tempQuill.setContents(inputDelta)
  if (valueType === 'html') return tempQuill.root.innerHTML
  else if (valueType === 'text') return tempQuill.getText()
}

export default getQuillHTML
