import React, { useRef } from 'react'
import { ReactComponent as InvoiceIcon } from 'static/svg/invoice.svg'
import { useHistory } from 'react-router-dom'
import Currency from 'static/Currency/Currency'
import clsx from 'clsx'
import { Button } from '@material-ui/core'
import ReactToPrint from 'react-to-print'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import { InvoiceFileCard } from 'components/_shared'
// import { getInvoiceSummary } from 'components/_agency/PaymentsSection/paymentStatic'

const CheckoutDetailsInvoiceCard = ({ invoiceData }) => {
  const history = useHistory()
  const invoiceRef = useRef()

  if (!invoiceData) return null

  // const totalAmount = getInvoiceSummary({
  //   invoiceItems: invoiceData.invoiceItems,
  //   coupon: invoiceData.coupon,
  //   discountAmount: invoiceData.discountAmount,
  //   taxPercent: invoiceData.taxPercent
  // })

  return (
    <>
      <div>
        <div className="flex justify-center items-center mb-2">
          <InvoiceIcon className="w-8 h-8" />
        </div>

        <div className={clsx('font-semibold text-xl text-center mb-4')}>
          {Currency[invoiceData.currency].symbol_native}
          {invoiceData.totalAmount.toLocaleString()}
        </div>

        <InvoiceField label="Invoice Number">
          {invoiceData.invoiceNumber}
        </InvoiceField>

        <InvoiceField label="Created At">
          {new Date(invoiceData.createdAt).toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            minute: 'numeric',
            hour: 'numeric'
          })}
        </InvoiceField>
        <InvoiceField label="Status">
          <Pill
            variant={pillVariantObj[invoiceData.status]}
            className="capitalize"
          >
            {invoiceData.status}
          </Pill>
        </InvoiceField>

        <div className="mt-6 flex space-x-2">
          <ReactToPrint
            trigger={() => {
              return <Button fullWidth>Download</Button>
            }}
            content={() => invoiceRef.current}
            documentTitle={`${invoiceData.invoiceNumber}_invoice`}
          />

          <Button
            fullWidth
            variant="outlined"
            onClick={() =>
              history.push(`/client/invoices/${invoiceData._id}/preview`)
            }
          >
            Preview
          </Button>
        </div>
      </div>

      <div className="fixed" style={{ right: -1000 }}>
        <div ref={invoiceRef}>
          <InvoiceFileCard invoice={invoiceData} />
        </div>
      </div>
    </>
  )
}

const InvoiceField = ({ label, children }) => {
  return (
    <div className="flex justify-between text-smaller my-2">
      <div className="flex-1 font-medium">{label}: </div>
      <div className="flex-1">{children}</div>
    </div>
  )
}

export default CheckoutDetailsInvoiceCard
