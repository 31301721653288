import React, { useEffect, useState } from 'react'
import {
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Currency from 'static/Currency/Currency'
import { Pagination } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import Pill from 'global/globalComponents/Pill/Pill'
import pillVariantObj from 'utils/pillVariantObj'
import { useHistory } from 'react-router-dom'
// import { getInvoiceSummary } from 'components/_agency/PaymentsSection/paymentStatic'

const totalInvoicesToShow = 8

const AgencyMarketplaceInvoicesTable = ({ invoices, loading }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [visibleInvoices, setVisibleInvoices] = useState([])
  const [page, setPage] = useState(0)

  useEffect(() => {
    setVisibleInvoices(
      [...invoices].splice(page * totalInvoicesToShow, totalInvoicesToShow)
    )
  }, [invoices, page])

  const openInvoicePreview = invoiceId => {
    // history.push(`${url}/${invoiceId}/preview`)
    history.push(`/agency/payments/client-billing/i/${invoiceId}`)
  }

  return (
    <>
      <TableContainer mb={6}>
        <Thead>
          <Th className="whitespace-nowrap">{t('CLIENT')}</Th>
          <Th className="whitespace-nowrap">{t('INVOICE_NO')}</Th>
          <Th className="text-center whitespace-nowrap">{t('ITEMS')}</Th>
          <Th className="text-center whitespace-nowrap">{t('STATUS')}</Th>
          <Th className="text-right whitespace-nowrap">{t('AMOUNT')}</Th>
          <Th className="whitespace-nowrap">{t('CREATED_AT')}</Th>
        </Thead>
        <Tbody>
          {loading ? (
            <TableDataLoading cols={6} />
          ) : visibleInvoices.length === 0 ? (
            <Tr>
              <Td colSpan="6">{t('NO_INVOICE_AVAILABLE')}</Td>
            </Tr>
          ) : (
            visibleInvoices.map(item => (
              <Row invoice={item} openInvoicePreview={openInvoicePreview} />
            ))
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={invoices.length}
        page={page}
        limit={totalInvoicesToShow}
      />
    </>
  )
}

const Row = ({ invoice, openInvoicePreview }) => {
  const { t } = useTranslation()
  const invoiceItem = invoice.invoiceItems[0]
  // const { totalAmount } = getInvoiceSummary({
  //   invoiceItems: invoice.invoiceItems,
  //   coupon: invoice.coupon,
  //   discountAmount: invoice.discountAmount,
  //   taxPercent: invoice.taxPercent
  // })

  return (
    <>
      <Tr>
        <Td
          className="cursor-pointer"
          onClick={() => openInvoicePreview(invoice._id)}
        >
          <div className="flex flex-col">
            <div className="flex items-end">
              {invoice.client?.clientName ? (
                <span className="dark:text-dark-light2">
                  {invoice.client?.clientName}
                </span>
              ) : (
                <span className="text-gray-500 dark:text-dark-light">NA</span>
              )}
            </div>
            <span className="text-gray-500 text-xs dark:text-dark-light">
              {invoice.client?.clientEmail}
            </span>
          </div>
        </Td>
        <Td className="text-xs whitespace-nowrap">{invoice.invoiceNumber}</Td>
        <Td className="text-center text-xs whitespace-nowrap w-0.5 truncate space-y-2">
          {invoiceItem ? <Pill variant="purple">{invoiceItem.name}</Pill> : '-'}
        </Td>
        <Td className="text-center whitespace-nowrap ">
          <Pill variant={pillVariantObj[invoice.status]}>
            {t(invoice.status.toUpperCase())}
          </Pill>
        </Td>
        <Td className="text-right whitespace-nowrap text-gray-900 text-xs ">
          <div className="text-custom-table-primaryText dark:text-indigo-400">
            {Currency[invoice.currency].symbol_native}
            {invoice.totalAmount.toLocaleString()}
          </div>
        </Td>

        {invoice.createdAt ? (
          <Td className="whitespace-nowrap text-xs">
            {new Date(invoice.createdAt).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              minute: 'numeric',
              hour: 'numeric'
            })}
          </Td>
        ) : (
          <Td className="whitespace-nowrap text-xs">NA</Td>
        )}
      </Tr>
    </>
  )
}

export default AgencyMarketplaceInvoicesTable
