import React, { useState } from 'react'
import Currency from 'static/Currency/Currency'
import serviceDefaultImage from 'static/Images/default-service.png'
// import { Tooltip } from 'global/globalComponents'
// import LoopRoundedIcon from '@material-ui/icons/LoopRounded'
import { getTextFromHTML } from 'utils'
import { useTranslation } from 'react-i18next'
import OfferingsPopper from './OfferingsPopper'
import Pill from 'global/globalComponents/Pill/Pill'

const ServiceCard = ({ service, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslation()
  const serviceCurrency = service.currency
    ? Currency[service.currency.toUpperCase()].symbol_native
    : Currency['USD'].symbol_native

  // let recurringPeriod = ''
  // if (service.isRecurring && service.recurring) {
  //   if (service.recurring.interval === 'year') {
  //     recurringPeriod = 'Billed every year'
  //   } else {
  //     recurringPeriod = `Billed every ${
  //       service.recurring.interval_count === 1
  //         ? 'month'
  //         : service.recurring.interval_count + ' months'
  //     }`
  //   }
  // }

  return (
    <>
      <div
        onClick={() => onClick(service._id)}
        className="bg-white cursor-pointer border border-gray-300 rounded flex flex-col dark:bg-dark-main dark:border-dark-main3 dark:text-white hover:shadow-lg transition-all"
        onMouseOver={e => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      >
        <div className="rounded-t relative">
          {/* {recurringPeriod && (
            <Tooltip title={recurringPeriod}>
              <span className="absolute top-2 left-0 z-10 flex items-center bg-primary-main text-white text-smaller p-1 pr-2 cursor-default">
                <LoopRoundedIcon fontSize="small" />
                <span className="ml-1">{t('RECURRING')}</span>
              </span>
            </Tooltip>
          )} */}
          <img
            src={service.image || serviceDefaultImage}
            alt=""
            style={{ minHeight: 150, maxHeight: 150 }}
            className="rounded-t w-full object-cover"
          />
        </div>
        <div className="pt-3 px-4 pb-4 flex-1 flex flex-col">
          <div className="mb-4">
            <div className="flex items-center justify-between mb-2">
              <div className="text-sm truncate font-semibold dark:text-white">
                {service.name}
              </div>

              {service.isRecurring && (
                <Pill variant="blue" className="ml-4">
                  {t('RECURRING')}
                </Pill>
              )}
            </div>
            <p className="text-smaller text-gray-600 dark:text-dark-light2">
              {service.description
                ? getTextFromHTML(service.description).slice(0, 50)
                : ''}
              {service.description?.length > 50 && '...'}
            </p>
          </div>

          <div className="mt-auto">
            <span className="font-semibold text-lg">
              {serviceCurrency}
              {(service.amount / 100).toLocaleString()}
            </span>
          </div>
        </div>
      </div>

      {service.offerings?.length ? (
        <OfferingsPopper anchorEl={anchorEl} offerings={service.offerings} />
      ) : null}
    </>
  )
}

export default ServiceCard
