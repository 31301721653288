export const AGENCY_PAYMENT = {
  LOADING: 'LOADING_INVOICES',
  FETCH_ALL_INVOICES: 'FETCH_ALL_INVOICES_OF_AGENCY',
  ADD_INVOICE: 'AGENCY_PAYMENT_ADD_INVOICE'
}

const initialState = {
  loading: false,
  allInvoices: []
}

const agencyPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGENCY_PAYMENT.LOADING:
      return {
        ...state,
        loading: true
      }

    case AGENCY_PAYMENT.FETCH_ALL_INVOICES:
      return {
        ...state,
        loading: false,
        allInvoices: action.payload
      }

    case AGENCY_PAYMENT.ADD_INVOICE:
      return { ...state, allInvoices: [action.payload, ...state.allInvoices] }

    default:
      return state
  }
}

export default agencyPaymentReducer
