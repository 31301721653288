export const VIEWS = {
  FETCHED_REPORTS_VIEW: 'FETCHED_REPORTS_VIEW',
  ADD_REPORTS_VIEW: 'ADD_REPORTS_VIEW',
  UPDATE_REPORTS_VIEW: 'UPDATE_REPORTS_VIEW',
  FETCHED_PROJECT_LIST_VIEW: 'FETCHED_PROJECT_LIST_VIEW',
  ADD_PROJECT_LIST_VIEW: 'ADD_PROJECT_LIST_VIEW',
  UPDATE_PROJECT_LIST_VIEW: 'UPDATE_PROJECT_LIST_VIEW',
  FETCHED_PROJECT_TAB_VIEW: 'FETCHED_PROJECT_TAB_VIEW',
  ADD_PROJECT_TAB_VIEW: 'ADD_PROJECT_TAB_VIEW',
  UPDATE_PROJECT_TAB_VIEW: 'UPDATE_PROJECT_TAB_VIEW',
  FETCHED_PROJECT_WIDGET_VIEW: 'FETCHED_PROJECT_WIDGET_VIEW',
  ADD_PROJECT_WIDGET_VIEW: 'ADD_PROJECT_WIDGET_VIEW',
  UPDATE_PROJECT_WIDGET_VIEW: 'UPDATE_PROJECT_WIDGET_VIEW',
  FETCHED_PROJECT_TABLE_VIEW: 'FETCHED_PROJECT_TABLE_VIEW',
  ADD_PROJECT_TABLE_VIEW: 'ADD_PROJECT_TABLE_VIEW',
  UPDATE_PROJECT_TABLE_VIEW: 'UPDATE_PROJECT_TABLE_VIEW',
  UPDATE_PROJECT_PAGE_VIEW: 'UPDATE_PROJECT_PAGE_VIEW',
  FETCHED_PROJECT_PAGE_VIEW: 'FETCHED_PROJECT_PAGE_VIEW',
  ADD_PROJECT_PAGE_VIEW: 'ADD_PROJECT_PAGE_VIEW',
  UPDATE_SERVICE_PAGE_VIEW: 'UPDATE_SERVICE_PAGE_VIEW',
  FETCHED_SERVICE_PAGE_VIEW: 'FETCHED_SERVICE_PAGE_VIEW',
  SET_SERVICE_PAGE_VIEW: 'SET_SERVICE_PAGE_VIEW',
  SET_SERVICE_PUBLIC_PAGE_VIEW: 'SET_SERVICE_PUBLIC_PAGE_VIEW'
}

const initialState = {
  loading: true,
  data: {
    reports: {},
    projectList: {},
    projectTab: {},
    projectWidget: {},
    projectTable: {},
    projectPageView: {},
    servicePageView: {},
    servicePublicPageView: {} // has navigation and views key together
  },
  fetched: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEWS.FETCHED_REPORTS_VIEW:
    case VIEWS.ADD_REPORTS_VIEW:
    case VIEWS.UPDATE_REPORTS_VIEW:
      return {
        loading: false,
        fetched: true,
        data: { ...state.data, reports: action.payload }
      }
    case VIEWS.FETCHED_PROJECT_LIST_VIEW:
    case VIEWS.ADD_PROJECT_LIST_VIEW:
    case VIEWS.UPDATE_PROJECT_LIST_VIEW:
      return {
        loading: false,
        fetched: true,
        data: { ...state.data, projectList: action.payload }
      }

    case VIEWS.FETCHED_PROJECT_TAB_VIEW:
    case VIEWS.ADD_PROJECT_TAB_VIEW:
    case VIEWS.UPDATE_PROJECT_TAB_VIEW:
      return {
        loading: false,
        fetched: true,
        data: { ...state.data, projectTab: action.payload }
      }

    case VIEWS.FETCHED_PROJECT_WIDGET_VIEW:
    case VIEWS.ADD_PROJECT_WIDGET_VIEW:
    case VIEWS.UPDATE_PROJECT_WIDGET_VIEW:
      return {
        loading: false,
        fetched: true,
        data: { ...state.data, projectWidget: action.payload }
      }

    case VIEWS.FETCHED_PROJECT_TABLE_VIEW:
    case VIEWS.ADD_PROJECT_TABLE_VIEW:
    case VIEWS.UPDATE_PROJECT_TABLE_VIEW:
      return {
        loading: false,
        fetched: true,
        data: { ...state.data, projectTable: action.payload }
      }

    case VIEWS.FETCHED_PROJECT_PAGE_VIEW:
    case VIEWS.ADD_PROJECT_PAGE_VIEW:
    case VIEWS.UPDATE_PROJECT_PAGE_VIEW:
      return {
        loading: false,
        fetched: true,
        data: { ...state.data, projectPageView: action.payload }
      }

    case VIEWS.FETCHED_SERVICE_PAGE_VIEW:
    case VIEWS.SET_SERVICE_PAGE_VIEW:
    case VIEWS.UPDATE_SERVICE_PAGE_VIEW:
      return {
        loading: false,
        fetched: true,
        data: { ...state.data, servicePageView: action.payload }
      }

    case VIEWS.SET_SERVICE_PUBLIC_PAGE_VIEW: {
      return {
        loading: false,
        fetched: true,
        data: { ...state.data, servicePublicPageView: action.payload }
      }
    }
    default:
      return state
  }
}
export default reducer
