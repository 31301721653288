import React from 'react'
import {
  Backdrop,
  Button,
  Fade,
  IconButton,
  Modal,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'
import { getFileType } from 'utils'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'

const WelcomeModal = ({ open, onClose, data }) => {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
    >
      <Fade in={open}>
        <ModalContent open={open} data={data} onClose={onClose} />
      </Fade>
    </Modal>
  )
}

const ModalContent = ({ open, data, onClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  let file
  if (
    data.link.startsWith('https://youtu.be') ||
    data.link.startsWith('https://www.youtube.com')
  ) {
    file = (
      <iframe
        src={`https://www.youtube.com/embed/${getYoutubeVideoId(data.link)}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen="allowfullscreen"
        className="w-11/12 h-52 mx-auto"
      ></iframe>
    )
  } else if (getFileType(data.link) === 'video') {
    file = (
      <video controls className="w-full h-52">
        <source src={data.link} />
      </video>
    )
  } else {
    file = <img src={data.link} alt="" className="w-40 mx-auto" />
  }

  return (
    <div className={clsx('relative rounded-3xl', classes.paper)}>
      <div className="flex justify-end p-4">
        <IconButton onClick={onClose} size="small">
          <CloseIcon size="small" fontSize="small" />
        </IconButton>
      </div>

      <div className="px-10 pb-12">
        <div className="text-center">
          <div>
            <h4 className="font-bold text-lg mb-2 dark:text-dark-light2">
              {data.heading}
            </h4>
            <p
              className="text-sm text-gray-800 dark:text-dark-light w-2/3 mx-auto mb-6 dangerous-html-container"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </div>

          <div>{file}</div>
        </div>
      </div>

      <div className="flex justify-end p-4">
        <Button onClick={onClose}>{t('CLOSE')}</Button>
      </div>
    </div>
  )
}

const getYoutubeVideoId = url => {
  let regex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  let match = url.match(regex)

  if (match && match[2].length === 11) {
    return match[2]
  }
  return null
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
    // userSelect: 'none'
  },
  paper: props => ({
    width: '90%',
    maxWidth: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none'
    // borderRadius: '1.5rem',
  }),
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  }
}))

export default WelcomeModal
