import { userRoles } from 'utils'

export const CHANNEL_TYPE = {
  NEW_FEED: 'newFeed',
  PUBLIC_CHAT: 'publicChat',
  WORKSPACE: 'workspace',
  INTERNAL: 'internal'
}

export const getChatPermission = role => {
  if ([userRoles.USER_AGENCY, userRoles.USER_CLIENT].includes(role))
    return { create: true, edit: true, delete: true, view: true }
  return { create: false, edit: false, delete: false, view: true }
}
