import React from 'react'
import {
  ColoredAvatarGroup,
  ColoredAvatars,
  CustomFlag
} from 'global/globalComponents'
import { ReactComponent as AudioFile } from 'static/svg/music-note.svg'
// import { ReactComponent as VideoFile } from 'static/svg/film-reel.svg'
import { ReactComponent as NoteText } from 'static/svg/note-text.svg'
import { adjustColor, getFileType } from 'utils'
import Pill from 'global/globalComponents/Pill/Pill'
import { useTranslation } from 'react-i18next'
import { getPriorityLabels } from 'global/globalComponents/CustomFlag/CustomFlag'
import Currency from 'static/Currency/Currency'

const TaskNotificationMessage = ({
  field,
  source,
  before,
  after,
  category,
  workspace
}) => {
  const { t } = useTranslation()
  const sourceCategory = t(category.toUpperCase())

  const generateMessage = () => {
    if (field === 'create') {
      return (
        <>
          {t('NOTIFICATION_TASK_CREATE', {
            category: sourceCategory.toLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">'{after}'</span>
        </>
      )
    }

    if (field === 'title') {
      return (
        <>
          {t('NOTIFICATION_TASK_TITLE_CHANGE', {
            category: sourceCategory.toLowerCase()
          })}
          &nbsp;:&nbsp;
          <span className="font-semibold">{before}</span>&nbsp;&#8594;&nbsp;
          <span className="font-semibold">{after}</span>
        </>
      )
    }

    if (field === 'startDate' || field === 'dueDate') {
      let fieldName = field === 'startDate' ? t('START_DATE') : t('DUE_DATE')
      return (
        <>
          {t('NOTIFICATION_TASK_DATE_CHANGE', {
            fieldName: fieldName.toLowerCase(),
            category: sourceCategory.toLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">'{source?.title}'</span>
          &nbsp;:&nbsp;
          <span className="text-smaller font-medium">
            {before
              ? new Date(before).toLocaleDateString(undefined, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                })
              : t('NONE')}
          </span>
          &nbsp;&#8594;&nbsp;
          <span className="text-smaller font-medium">
            {after
              ? new Date(after).toLocaleDateString(undefined, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                })
              : t('NONE')}
          </span>
        </>
      )
    }

    if (field === 'description') {
      return (
        <>
          {t('NOTIFICATION_TASK_DESCRIPTION_CHANGE', {
            category: sourceCategory.toLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">'{source?.title}'</span>
        </>
      )
    }

    if (field === 'priority') {
      const labels = getPriorityLabels()

      return (
        <>
          {t('NOTIFICATION_TASK_PRIORITY_CHANGE', {
            category: sourceCategory.toLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">'{source?.title}'</span>
          &nbsp;:&nbsp;
          <span className="font-semibold">
            {before === 5 ? t('NONE') : labels[before]}
          </span>
          &nbsp;&#8594;&nbsp;
          <span className="font-semibold">
            {after === 5 ? t('NONE') : labels[after]}
          </span>
        </>
      )
    }

    if (field === 'assignee_add') {
      return (
        <div className="flex items-center">
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_TASK_ASSIGNEE_CHANGE', {
                userName: after?.name,
                category: sourceCategory.toLowerCase(),
                taskTitle: source?.title
              })
            }}
          ></span>
          &nbsp;:&nbsp;
          <ColoredAvatars user={after} tooltip={true} size="medium" />
        </div>
      )
    }

    if (field === 'attachment_add') {
      const fileType = getFileType(after)

      return (
        <div className="flex items-center">
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_TASK_ATTACHMENT_ADD', {
                category: sourceCategory.toLowerCase(),
                taskTitle: source?.title,
                fileType
              })
            }}
          ></span>
          &nbsp;:&nbsp;
          {fileType === 'image' ? (
            <img
              src={after}
              alt=""
              className="w-10 h-10 object-cover rounded"
            />
          ) : fileType === 'audio' ? (
            <AudioFile className="text-primary-main w-10 h-10" />
          ) : (
            <NoteText className="text-primary-main w-10 h-10" />
          )}
        </div>
      )
    }

    if (field === 'attachment_delete') {
      const fileType = getFileType(before)

      return (
        <div className="flex items-center">
          {t('NOTIFICATION_TASK_ATTACHMENT_DELETE', {
            category: sourceCategory.toLowerCase(),
            fileType
          })}
          &nbsp;
          <span className="font-semibold">'{source?.title}'</span>&nbsp;:&nbsp;
          {fileType === 'image' ? (
            <img
              src={before}
              alt=""
              className="w-10 h-10 object-cover rounded"
            />
          ) : fileType === 'audio' ? (
            <AudioFile className="text-primary-main w-10 h-10" />
          ) : (
            <NoteText className="text-primary-main w-10 h-10" />
          )}
        </div>
      )
    }

    if (field === 'isTaskRecurring') {
      return (
        <>
          {t('NOTIFICATION_TASK_ISRECURRING_CHANGE', {
            action:
              before && after ? t('RECURRING_DURATION') : t('RECURRING_MODE'),
            category: sourceCategory.toLocaleLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">{source?.title}</span>&nbsp;:&nbsp;
          {before !== after && (
            <>
              <span className="font-semibold">
                {before ? t('ON') : t('OFF')}
              </span>
              &nbsp;&#8594;&nbsp;
              <span className="font-semibold">
                {after ? t('ON') : t('OFF')}
              </span>
            </>
          )}
        </>
      )
    }

    if (field === 'estimateTimeInString') {
      return (
        <>
          {t('NOTIFICATION_TASK_ESTIMATE_TIME_CHANGE', {
            category: sourceCategory.toLocaleLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">{source?.title}</span>&nbsp;:&nbsp;
          <span className="font-semibold">'{before || t('NONE')}'</span>
          &nbsp;&#8594;&nbsp;
          <span className="font-semibold">'{after || t('NONE')}'</span>
        </>
      )
    }

    if (field === 'taskSection') {
      return (
        <>
          {t('NOTIFICATION_TASK_STATUS_CHANGE', {
            category: sourceCategory.toLocaleLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">{source?.title}</span>&nbsp;:&nbsp;
          <span className="font-semibold">
            <Pill
              style={{
                color: adjustColor(before.color, -70),
                backgroundColor: before.color + '20'
              }}
            >
              {before.label}
            </Pill>
          </span>
          &nbsp;&#8594;&nbsp;
          <span className="font-semibold">
            <span className="font-semibold">
              <Pill
                style={{
                  color: adjustColor(after?.color, -70),
                  backgroundColor: after?.color + '20'
                }}
              >
                {after?.label}
              </Pill>
            </span>
          </span>
        </>
      )
    }

    if (field === 'markAsComplete') {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: t('NOTIFICATION_TASK_MARK_AS_COMPLETE', {
              category: sourceCategory.toLowerCase(),
              taskTitle: source?.title
            })
          }}
        ></span>
      )
    }

    if (field === 'assignee_delete') {
      return (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_TASK_ASSIGNEE_DELETE', {
                category: sourceCategory.toLowerCase(),
                userName: after?.name
              })
            }}
          ></span>
          &nbsp;
          <span className="font-semibold">"{source?.title}"</span>&nbsp;
        </>
      )
    }

    if (field === 'delete') {
      return (
        <>
          {t('NOTIFICATION_TASK_DELETE', {
            category: sourceCategory.toLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">'{before}'</span>
        </>
      )
    }

    if (field === 'comment_like') {
      return (
        <>
          {t('NOTIFICATION_TASK_COMMENT_LIKE', {
            category: sourceCategory.toLowerCase()
          })}
          &nbsp;
          <span className="font-semibold">'{source?.title}'</span>
        </>
      )
    }

    if (field === 'collaborators_add') {
      return (
        <div className="flex items-center">
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_TASK_COLLABORATORS_ADD', {
                category: sourceCategory.toLowerCase(),
                collaboratorsCount: after?.length,
                taskTitle: source?.title
              })
            }}
          ></span>
          &nbsp;:&nbsp;
          <ColoredAvatarGroup users={after} tooltip={true} size="medium" />
        </div>
      )
    }

    if (field === 'cost') {
      return (
        <>
          {t('NOTIFICATION_TASK_SET_COST')}&nbsp;
          <span className="font-semibold">'{source?.title}'</span>&nbsp;
          {t('TO')}&nbsp;
          <span class="font-semibold">
            {after ? (
              <>
                {
                  Currency[workspace?.currency?.toUpperCase() || 'USD']
                    .symbol_native
                }
                {after}
              </>
            ) : (
              t('NONE')
            )}
          </span>
        </>
      )
    }
  }

  return (
    <div>
      <div className="text-smaller">{generateMessage()}</div>
    </div>
  )
}

export default TaskNotificationMessage
