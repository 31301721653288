import React, { useEffect } from 'react'

const Layout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <>{children}</>
}

export default Layout
