export const PULSE = {
  LOADING: 'PULSE_LOADING',
  FETCHED: 'PULSE_FETCHED',
  ERROR: 'PULSE_ERROR',
  UPDATE_USER_PULSE: 'UPDATE_USER_PULSE',
  CREATE_USERS_PULSE: 'CREATE_USERS_PULSE'
}

const initialState = {
  loading: false,
  error: false,
  data: [],
  fetched: false
}

const pulseReducer = (state = initialState, action) => {
  switch (action.type) {
    case PULSE.LOADING:
      return { ...state, loading: action.payload }

    case PULSE.ERROR:
      return { ...state, loading: false, error: action.payload }

    case PULSE.FETCHED:
      return { ...state, data: action.payload, loading: false, error: false }

    case PULSE.CREATE_USERS_PULSE:
      return { ...state, data: [...action.payload, ...state.data] }

    case PULSE.UPDATE_USER_PULSE:
      let updatedData = [...state.data]
      const ind = state.data.findIndex(
        (pulse) => pulse._id === action.payload._id
      )
      if (ind !== -1) {
        updatedData[ind] = action.payload
      } else {
        updatedData = [action.payload, ...state.data]
      }
      return { ...state, data: [...updatedData] }

    default:
      return { ...state }
  }
}

export default pulseReducer
