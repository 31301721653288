import { useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { colorTints } from 'utils/hexToRgb'
import i18nConfig from 'static/i18n/i18n'
import { useLocation } from 'react-router-dom'

const useAppTheme = () => {
  const { companyInfo } = useSelector(state => state.generalAppState)
  const meData = useSelector(state => state.me.data)
  const { mode: userThemeMode } = useSelector(
    state => state.generalAppState.theme
  )
  const { pathname } = useLocation()
  const { loggedIn } = useSelector(state => state.login)
  const appThemeMode = useMemo(() => {
    let themeData = JSON.parse(window.localStorage.getItem('customize'))
    let localStorageTheme = themeData?.theme?.mode || 'light'
    //is private route
    const isPrivateRoute =
      pathname.startsWith('/agency/') || pathname.startsWith('/client/')
    let themeMode = isPrivateRoute ? localStorageTheme : 'light'
    // if user has set theme
    if (isPrivateRoute && loggedIn && userThemeMode) themeMode = userThemeMode
    return themeMode
    // update appThemeMode after companyInfo is updated, as it updates "customize" in localStorage
  }, [userThemeMode, loggedIn, companyInfo])

  // dark theme
  useEffect(() => {
    const themeData = JSON.parse(localStorage.getItem('customize'))
    //  updateLocalStorageTheme
    window.localStorage.setItem(
      'customize',
      JSON.stringify({
        ...themeData,
        theme: {
          ...themeData?.theme,
          mode: appThemeMode
        }
      })
    )
    // updateDocumentClass
    if (appThemeMode === 'dark') {
      document.documentElement.classList.add('dark')
    } else document.documentElement.classList.remove('dark')
  }, [appThemeMode])

  useEffect(() => {
    const themeData = JSON.parse(localStorage.getItem('customize'))
    const theme = {
      primary: '#1600E5',
      heading: '#17191C',
      ...themeData?.theme,
      ...companyInfo.data.theme
    }
    // companyInfo.data.theme ??
    //   ((themeData?.theme?.primary && themeData?.theme?.heading)
    //     ? themeData.theme
    //     : null) ?? { primary: '#1600E5', heading: '#17191C' }

    document.documentElement.style.setProperty('--color-primary', theme.primary)
    document.documentElement.style.setProperty('--color-loader', theme.primary)
    document.documentElement.style.setProperty(
      '--color-primary-light',
      colorTints(theme.primary, 0.95)
    )
    document.documentElement.style.setProperty(
      '--color-primary-mid-light',
      colorTints(theme.primary, 0.85)
    )
    document.documentElement.style.setProperty('--color-heading', theme.heading)
  }, [companyInfo])

  useEffect(() => {
    let language = 'en'

    if (loggedIn && meData._id) {
      language = meData._id
        ? meData.profile?.language
        : companyInfo.data?.platformLanguage
        ? companyInfo.data?.platformLanguage
        : 'en'
    }

    i18nConfig(language)
  }, [companyInfo.data, meData])

  return { appThemeMode }
}

export default useAppTheme
