import React, { useEffect, useState } from 'react'
import { Button, TextField, useTheme } from '@material-ui/core'
import { CheckBoxField, LoadingButton } from 'global/globalComponents'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import userRoles, {
  getAgencyRoleOptions,
  getAgencyRoleOptionsForManager,
  getClientRoleOptions
} from 'utils/userRoles'
import { fetchTeamVerticals } from 'thunks/Verticals/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import teammateStyles from './teammateStyles'
import QuestionMarkTooltip from 'global/globalComponents/QuestionMarkTooltip'
import { InputAdornment, IconButton } from '@material-ui/core'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'
import {
  addTeammate,
  updateClientTeammate,
  updateTeammate
} from 'thunks/teammate/actions'
import VerticalsModal from './VerticalsModal'
import CustomMenu from './CustomMenu'
import { useTranslation } from 'react-i18next'
import { gettingAllTasksStatus } from 'thunks/gettingStarted/actions'
import Currency from 'static/Currency/Currency'
import { validator } from 'utils'
import CryptoJS from 'crypto-js'

const AGENCY = 'agency'
const CLIENT = 'client'
const AGENCY_ADDING_CLIENT_TEAMMATE = 'agencyForClient'

const AddNewTeammatePanel = ({
  open,
  onClose,
  data = {},
  metadata = {},
  callback
}) => {
  const { t } = useTranslation()

  return (
    <CustomDrawer
      onClose={onClose}
      anchor="right"
      open={open}
      styles={metadata.styles}
    >
      <DrawerHeader onClose={onClose}>
        {data._id
          ? `${t('EDIT')} ${t('TEAM_MEMBERS')}`
          : `${t('ADD')} ${t('TEAM_MEMBER')}`}
      </DrawerHeader>
      <PanelContent
        open={open}
        onClose={onClose}
        data={data}
        metadata={metadata}
        callback={callback}
      />
    </CustomDrawer>
  )
}

const PanelContent = ({ open, onClose, data, callback, metadata }) => {
  const { t } = useTranslation()
  const agencyRoleOptionsForManager = getAgencyRoleOptionsForManager()
  const clientRoleOptions = getClientRoleOptions()
  const theme = useTheme()
  const agencyRoleOptions = getAgencyRoleOptions()
  const classes = teammateStyles()
  const [workspaceOptions, setWorkspaceOptions] = useState([])
  const meData = useSelector(state => state.me.data)
  const userWorkspacesData = useSelector(state => state.userWorkspaces.data)
  // const { data: verticalsData } = useSelector(state => state.teamVerticals)
  // const [openVerticalModal, setOpenVerticalModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const userClients = useSelector(state => state.userClients)
  const { companyInfo } = useSelector(state => state.generalAppState)
  const [teammateData, setTeammateData] = useState({
    name: '',
    email: '',
    assignedWorkspace: [],
    verticals: {},
    hourlyRate: ''
  })
  const [roleOptions, setRoleOptions] = useState([])
  const [passwordValid, setPasswordValid] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [selectedRole, setSelectedRole] = useState({ value: '' })
  // const [verticalsOptions, setVerticalsOptions] = useState([])
  const [password, setPassword] = useState('')
  const [selectAllProjects, setSelectAllProjects] = useState(false)
  // const [assignedWorkspaces, setAssignedWorkspaces] = useState([])

  // NOTE: this component is being used in 3 different contexts:
  // 1. Agency adding teammate (agency)
  // 2. Clint adding teammate (client)
  // 3. Agency adding teammate for the client (agencyForClient)
  const context = metadata.isAgencyAddingClientTeammate
    ? AGENCY_ADDING_CLIENT_TEAMMATE
    : meData.role < userRoles.USER_CLIENT
    ? AGENCY
    : CLIENT

  useEffect(() => {
    if (!data._id) return

    setTeammateData({
      name: data.name,
      email: data.email,
      hourlyRate: data.hourlyRate,
      verticals:
        data.verticals?.length > 0
          ? {
              value: data.verticals[0]?._id,
              label: data.verticals[0]?.name
            }
          : {},
      assignedWorkspace: data.assignedWorkspace
    })

    const selectedRole = [...clientRoleOptions, ...agencyRoleOptions].find(
      item => item.value === data.role
    )
    setSelectedRole(selectedRole)

    // if me role is agency admin or super admin then i can see teammate's password
    if (
      (meData.role === userRoles.AGENCY_ADMIN ||
        meData.role === userRoles.USER_AGENCY) &&
      data.passwordInString
    ) {
      try {
        const bytes = CryptoJS.AES.decrypt(
          data.passwordInString,
          process.env.REACT_APP_PASSWORD_SECRET
        )
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        setPassword(decryptedData)
      } catch (err) {
        setPassword(data.passwordInString)
      }
    }
  }, [data])

  useEffect(() => {
    if (context === AGENCY) {
      if (
        ![
          userRoles.USER_ADMIN,
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN
        ].includes(meData.role)
      ) {
        setRoleOptions(agencyRoleOptionsForManager)
      } else {
        setRoleOptions(agencyRoleOptions)
      }
    } else {
      setRoleOptions(clientRoleOptions)
    }

    // const arr = [
    //   userRoles.USER_ADMIN,
    //   userRoles.USER_AGENCY,
    //   userRoles.AGENCY_ADMIN,
    //   userRoles.AGENCY_MANAGER,
    //   userRoles.AGENCY_EDITOR,
    //   userRoles.AGENCY_VIEWER
    // ]

    // if (arr.includes(meData.role) && elmFor !== 'client') {
    //   if (
    //     ![
    //       userRoles.USER_ADMIN,
    //       userRoles.USER_AGENCY,
    //       userRoles.AGENCY_ADMIN
    //     ].includes(meData.role)
    //   ) {
    //     return setRoleOptions(agencyRoleOptionsForManager)
    //   } else return setRoleOptions(agencyRoleOptions)
    // } else return setRoleOptions(clientRoleOptions)
  }, [meData.role])

  useEffect(() => {
    if (context === AGENCY_ADDING_CLIENT_TEAMMATE) {
      const currentClient = userClients.data.find(
        item => item._id === metadata.clientOwner
      )

      if (currentClient) {
        setWorkspaceOptions(
          currentClient.assignedWorkspace
            .filter(item => !item.isArchived)
            .map(item => ({ name: item.name, _id: item._id }))
        )
      }
    } else {
      if (userWorkspacesData.length) {
        setWorkspaceOptions(
          userWorkspacesData
            .filter(item => !item.isArchived)
            .map(item => ({ name: item.name, _id: item._id }))
        )
      }
    }
  }, [userWorkspacesData])

  // useEffect(() => {
  //   if (verticalsData.length) {
  //     setVerticalsOptions(
  //       verticalsData.map(item => ({ label: item.name, value: item._id }))
  //     )
  //   }
  // }, [verticalsData])

  useEffect(() => {
    if (!metadata.defaultValues) return
    const { email, assignedWorkspace } = metadata.defaultValues

    const defaultValues = {
      email,
      assignedWorkspace: assignedWorkspace || []
    }

    setTeammateData(prev => ({
      ...prev,
      ...defaultValues
    }))
  }, [metadata.defaultValues])

  const handleChange = e => {
    setTeammateData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    if (e.target.name === 'password') {
      setPasswordValid(true)
    }
  }

  // const toggleCreateRoleModal = () => {
  //   setOpenVerticalModal(prev => !prev)
  // }

  const handleSelectWorkspace = newVal => {
    setTeammateData(oldState => ({
      ...oldState,
      assignedWorkspace: newVal
    }))
  }

  const handleSelectVerticals = e => {
    setTeammateData(oldState => ({
      ...oldState,
      verticals: e
    }))
  }

  // const handleRoleChange = e => {
  //   // setTeammateData(oldState => ({
  //   //   ...oldState,
  //   //   role: e.value
  //   // }))
  // }

  const handleAddTeammate = e => {
    e.preventDefault()

    let body = {
      name: teammateData.name.trim(),
      email: teammateData.email.trim(),
      role: selectedRole.value
        ? selectedRole.value
        : context === AGENCY
        ? userRoles.AGENCY_VIEWER
        : userRoles.CLIENT_VIEWER,
      verticals: teammateData.verticals.value
        ? [teammateData.verticals.value]
        : [],
      newWorkspace:
        teammateData.role === userRoles.AGENCY_ADMIN
          ? []
          : teammateData.assignedWorkspace.map(item => item._id),
      language: companyInfo.data.platformLanguage || 'en',
      password: password,
      hourlyRate: '' //removed hourly rate field from add teammate
    }

    if (!body.name || !body.email) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    if (!validator.isEmailValid(body.email)) {
      dispatch(fireErrorToaster(t('ERROR_INVALID_EMAIL_ADDRESS')))
      return
    }

    if (body.password && !validator.isPasswordValid(body.password)) {
      setPasswordValid(false)
      dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_A_VALID_PASSWORD')))
      return
    }

    if (context === AGENCY_ADDING_CLIENT_TEAMMATE) {
      body.team = metadata.clientOwner
    }

    setLoading(true)

    // Note: in add teammate response we are not getting the added workspace data
    // That's why here we are manually passing the assignedWorkspace list and using it
    dispatch(
      addTeammate(
        {
          body,
          assignedWorkspace: teammateData.assignedWorkspace,
          isAgencyAddingClientTeammate:
            context === AGENCY_ADDING_CLIENT_TEAMMATE
        },
        (res, err) => {
          setLoading(false)
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            if (callback) callback(res)
            dispatch(fireSuccessToaster(t('TEAM_MEMBER_ADDED_SUCCESSFULLY')))
            onClose()
          }

          if (meData.role === userRoles.USER_AGENCY) {
            dispatch(gettingAllTasksStatus())
          }
        }
      )
    )
  }

  const handleUpdateTeammate = () => {
    if (teammateData.name.trim() === '' || teammateData.email.trim() === '') {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    if (!validator.isEmailValid(teammateData.email)) {
      dispatch(fireErrorToaster(t('ERROR_INVALID_EMAIL_ADDRESS')))
      return
    }
    // From here we need to send 1) assignedWorkspace, 2) Role (only if updated), 3) Password (only if updated)
    const bodyData = {
      removedWorkspace: [],
      newWorkspace: []
    }

    if (data.name !== teammateData.name) {
      bodyData.name = teammateData.name.trim()
    }

    if (data.email !== teammateData.email) {
      bodyData.email = teammateData.email.trim()
    }

    if (data.role !== selectedRole.value) {
      bodyData.role = selectedRole.value
    }

    if (
      data.verticals &&
      data.verticals[0]?._id !== teammateData.verticals.value
    ) {
      bodyData.verticals = [teammateData.verticals.value]
    }

    if (data.hourlyRate !== teammateData.hourlyRate) {
      bodyData.hourlyRate = teammateData.hourlyRate
    }

    if (bodyData.role === userRoles.AGENCY_ADMIN) {
      bodyData.newWorkspace = []
      bodyData.removedWorkspace = []
    } else {
      // Removed Workspaces
      for (let ws of data.assignedWorkspace) {
        const match = teammateData.assignedWorkspace.find(
          item => item._id === ws._id
        )

        if (!match) {
          bodyData.removedWorkspace.push(ws._id)
        }
      }

      // Added Workspaces
      for (let ws of teammateData.assignedWorkspace) {
        const match = data.assignedWorkspace.find(item => item._id === ws._id)

        if (!match) {
          bodyData.newWorkspace.push(ws._id)
        }
      }
    }

    if (password) {
      if (!validator.isPasswordValid(password)) {
        setPasswordValid(false)
        return
      } else {
        bodyData.password = password
      }
    }

    setLoading(true)

    const updateCallback = (res, err) => {
      setLoading(false)

      if (!err) {
        if (callback) callback(res)
        dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
        onClose()
      } else {
        dispatch(fireErrorToaster(err))
      }
    }

    if (context === AGENCY) {
      dispatch(updateTeammate({ id: data._id, body: bodyData }, updateCallback))
    } else {
      dispatch(
        updateClientTeammate({ id: data._id, body: bodyData }, updateCallback)
      )
    }
  }

  const togglePasswordView = () => {
    setShowPassword(prev => !prev)
  }

  const validPasswordHandler = () => {
    setPasswordValid(validator.isPasswordValid(password))
  }

  const handleSalaryChange = e => {
    setTeammateData(prev => ({ ...prev, hourlyRate: e.target.value }))
  }

  const handleSelectAllProjects = e => {
    setSelectAllProjects(e.target.checked)

    if (e.target.checked) {
      setTeammateData(prev => ({
        ...prev,
        assignedWorkspace: workspaceOptions
      }))
    }
  }

  return (
    <>
      <DrawerContent open={open}>
        <form onSubmit={data._id ? handleUpdateTeammate : handleAddTeammate}>
          <div className="mb-4">
            <div className="flex-1">
              <FormLabel required htmlFor="teammate-name">
                {t('NAME')}
              </FormLabel>
              <TextField
                value={teammateData.name}
                autoComplete="one-time-code"
                name="name"
                className={classes.textFieldStyle}
                onChange={handleChange}
                id="teammate-name"
                placeholder={t('ENTER_NAME')}
              />
            </div>
          </div>

          <div className="mb-4">
            <FormLabel required htmlFor="teammate-email">
              {t('EMAIL')}
            </FormLabel>
            {/* <DisableAutofillElement /> */}
            <TextField
              name="email"
              inputProps={{
                autoComplete: 'off'
              }}
              value={teammateData.email}
              type="email"
              className={classes.textFieldStyle}
              onChange={handleChange}
              id="teammate-email"
              placeholder={t('ENTER_EMAIL')}
            />
          </div>

          <div className="mb-4 w-full">
            <FormLabel htmlFor="teammate-role">{t('PERMISSION')}</FormLabel>
            <Select
              value={selectedRole}
              isSearchable={false}
              onChange={setSelectedRole}
              options={roleOptions}
              className={classes.selectField}
              name="role"
              menuShouldScrollIntoView={true}
              id="teammate-role"
              styles={reactSelectCustomStyles(theme)}
            />
          </div>

          {/* This field is only for the agency */}
          {context === AGENCY && (
            <VerticalsField
              t={t}
              handleSelectVerticals={handleSelectVerticals}
              value={teammateData.verticals}
              theme={theme}
              classes={classes}
              dispatch={dispatch}
            />
          )}

          <div className="mb-4">
            <div className="flex items-center justify-between">
              <FormLabel
                htmlFor="teammate-assign-workspace"
                className="flex items-center gap-1"
              >
                {t('ASSIGN_PROJECT')}
                <QuestionMarkTooltip
                  text={t('YOU_CAN_ASSIGN_ANY_PROJECT')}
                  direction="right"
                  toolTipstyles="lowercase"
                  info={true}
                />
              </FormLabel>

              <div className="flex">
                <CheckBoxField
                  onChange={handleSelectAllProjects}
                  checked={selectAllProjects}
                  label={
                    <span className="pb-1 text-gray-500 py-1 dark:text-dark-light">
                      {t('SELECT_ALL')}
                    </span>
                  }
                />
              </div>
            </div>

            <Select
              isSearchable={true}
              onChange={handleSelectWorkspace}
              isMulti
              value={teammateData.assignedWorkspace}
              options={workspaceOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option._id}
              className={classes.selectField}
              id="teammate-assign-workspace"
              styles={reactSelectCustomStyles(theme)}
              name="projects"
              menuPlacement="auto"
            />
          </div>

          <div className="mb-4">
            <FormLabel htmlFor="teammate-role">{t('PASSWORD')}</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              // error={clientData.password && !passwordValid  ? true : false}
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('PASSWORD')}
              value={password}
              onChange={e => setPassword(e.target.value)}
              onBlur={validPasswordHandler}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={togglePasswordView}>
                      {!showPassword ? (
                        <EyeIcon className="text-base" />
                      ) : (
                        <EyeSlashIcon className="text-base" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <p className="text-xs mt-1 dark:text-dark-light">
              {t('PASSWORD_WILL_BE_AUTO_GENERATED')}
            </p>
            {password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                {t('PASSWORD_WARNING_TEXT')}
              </p>
            )}
          </div>

          {data._id && context === AGENCY && teammateData.hourlyRate && (
            <div className="w-full">
              <FormLabel>{t('SALARY')}</FormLabel>
              <TextField
                value={teammateData.hourlyRate}
                onChange={handleSalaryChange}
                placeholder={t('ENTER_SALARY_PER_HOUR')}
                className="w-full"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {meData.role === userRoles.USER_AGENCY
                        ? Currency[
                            meData?.profile?.defaultCurrency?.toUpperCase() ||
                              'USD'
                          ].symbol_native
                        : Currency[
                            meData?.team?.profile?.defaultCurrency?.toUpperCase()
                          ].symbol}
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  style: { paddingTop: '12px', paddingBottom: '12px' }
                }}
              />
            </div>
          )}
        </form>
      </DrawerContent>

      <DrawerFooter className={classes.panelFooter}>
        <Button
          size="large"
          variant="outlined"
          type="button"
          onClick={onClose}
          className="w-full"
        >
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          loading={loading}
          onClick={data._id ? handleUpdateTeammate : handleAddTeammate}
          type="submit"
          className="w-full dark:bg-dark-main1"
        >
          {data._id ? t('SAVE_CHANGES') : t('ADD')}
        </LoadingButton>
      </DrawerFooter>
      {/* <VerticalsModal
        open={openVerticalModal}
        onClose={toggleCreateRoleModal}
        addNewVertical={handleSelectVerticals}
      /> */}
    </>
  )
}

const VerticalsField = ({
  t,
  handleSelectVerticals,
  value,
  theme,
  classes,
  dispatch
}) => {
  const [verticalsOptions, setVerticalsOptions] = useState([])
  const [openVerticalModal, setOpenVerticalModal] = useState(false)
  const verticalsData = useSelector(state => state.teamVerticals.data)

  useEffect(() => {
    dispatch(fetchTeamVerticals())
  }, [])

  useEffect(() => {
    if (verticalsData.length) {
      setVerticalsOptions(
        verticalsData.map(item => ({ label: item.name, value: item._id }))
      )
    }
  }, [verticalsData])

  return (
    <div className="mb-4">
      <FormLabel htmlFor="verticals" className="flex items-center gap-1">
        {t('SELECT_ROLE_EG')}
      </FormLabel>
      <Select
        isSearchable={true}
        onChange={handleSelectVerticals}
        value={value}
        options={verticalsOptions}
        className={classes.selectField}
        styles={reactSelectCustomStyles(theme)}
        placeholder={t('SELECT_ROLE')}
        components={{ MenuList: CustomMenu }}
        selectProps={{
          toggleCreateRoleModal: () => setOpenVerticalModal(true),
          canAdd: true
        }}
      />
      <VerticalsModal
        open={openVerticalModal}
        onClose={() => setOpenVerticalModal(false)}
        addNewVertical={handleSelectVerticals}
      />
    </div>
  )
}

export default React.memo(AddNewTeammatePanel)
