import React, { useEffect, useState } from 'react'
import { Popover } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const EstimateTimePopup = ({
  open,
  anchorEl,
  onClose,
  selectedTime,
  setSelectedTime,
  handleChangeEstimateTime,
  style
}) => {
  const { t } = useTranslation()
  const [time, setTime] = useState('')
  const [timeOption, setTimeOption] = useState({
    show: false,
    value: ''
  })

  useEffect(() => {
    setTime(selectedTime)
  }, [selectedTime])

  const handleTimeChange = e => {
    const value = e.target.value.toLowerCase()
    if (value.includes('.')) return
    setTime(value)

    if (value.trim() !== '') {
      let optionVal = ''

      value.replace(/\d+\s?\w{0,2}/gi, match => {
        optionVal += getTimeAndUnitOption(match) + ' '
      })

      if (optionVal !== null) {
        setTimeOption({ show: true, value: optionVal })
      }
    } else {
      setTimeOption({
        show: false,
        value: ''
      })
    }
  }

  const handleBlur = e => {
    setTimeout(() => {
      setTimeOption({ show: false, value: '' })
    }, 500)
  }

  const handleSelectTime = () => {
    const { timeStr, totalMs } = getEstimateTime(timeOption.value)
    handleChangeEstimateTime({ timeStr, totalMs })
    setTime(timeStr)
  }

  const submitEstimateTime = e => {
    e.preventDefault()

    if (time === '' && time !== selectedTime) {
      handleChangeEstimateTime('')
    } else {
      const { timeStr, totalMs } = getEstimateTime(timeOption.value)
      handleChangeEstimateTime({ timeStr, totalMs })
      setTime(timeStr)
    }
  }

  const removeEstimateTime = () => {
    setTime('')

    if (selectedTime !== '') {
      handleChangeEstimateTime('')
    }
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      style={{ top: '10px', left: '-30px', ...style }}
    >
      <div className="px-4 pt-2 pb-6">
        <div className="flex space-x-2 items-center relative">
          <h3 className="font-semibold text-base tracking-wide flex-1 dark:text-dark-light">
            {t('ESTIMATE_TIME')}
          </h3>
          <form onSubmit={submitEstimateTime}>
            <div className="flex border px-2 py-1 rounded w-24">
              <input
                type="text"
                value={time}
                onChange={handleTimeChange}
                autoFocus
                onBlur={handleBlur}
                className="bg-transparent min-w-0 text-sm dark:text-dark-light"
              />
              <button
                className={clsx(time ? 'visible' : 'invisible')}
                onClick={removeEstimateTime}
              >
                <CloseIcon style={{ fontSize: 14 }} className="text-gray-500" />
              </button>
            </div>
          </form>
          {timeOption.show && (
            <div
              onClick={handleSelectTime}
              className={
                'absolute right-0 top-full cursor-pointer px-4 py-2 rounded shadow-lg border border-gray-200 bg-white mt-2 ml-2 hover:bg-gray-100 text-sm'
              }
            >
              {timeOption.value}
            </div>
          )}
        </div>
        <hr className="mt-4 mb-1" />
        <p className="text-xs dark:text-dark-light">
          {t('CHANGES_ARE_AUTOMATICALLY_SAVED')}
        </p>
      </div>
    </Popover>
  )
}

const units = {
  // s: 'seconds',
  m: 'minutes',
  h: 'hours',
  d: 'days',
  mo: 'months',
  y: 'years'
}

// const shortTimeUnits = {
//   seconds: 's',
//   minutes: 'm',
//   hours: 'h'
// }

const getEstimateTime = str => {
  let ms = 0

  str.replace(/(\d+)\s(\w+)/gi, (match, p1, p2) => {
    switch (p2) {
      case 'minutes':
        ms += p1 * 1000 * 60
        break
      case 'hours':
        ms += p1 * 1000 * 60 * 60
        break
      case 'days':
        ms += p1 * 1000 * 60 * 60 * 24
        break
      case 'months':
        ms += p1 * 1000 * 60 * 60 * 24 * 30
        break
      case 'years':
        ms += p1 * 1000 * 60 * 60 * 24 * 30 * 365
        break
      default:
        ms += p1
    }
  })

  let timeStr = ''
  const hours = ms / (1000 * 60 * 60)
  const mins = (hours % 1) * 60

  if (Math.floor(hours) > 0) timeStr += Math.floor(hours) + 'h '
  if (Math.floor(mins) > 0) timeStr += Math.floor(mins) + 'm'

  return { timeStr, totalMs: ms }
}

// const getTimeAndUnit = (str) => {
//   const res = str.match(/\d+\s?([a-zA-Z]{0,2})/)
//   if (res) {
//     return `${parseFloat(res[0])}${shortTimeUnits[res[1]] ?? 's'}`
//   }
//   return null
// }

// const getTimeAndUnitOption = (str) => {
//   const res = str.match(/\d+\s?([a-zA-Z]{0,2})/)

//   if (res) {
//     return `${parseFloat(res[0])} ${timeUnits[res[1]] ?? 'seconds'}`
//   }
//   return null
// }

const getTimeAndUnitOption = str => {
  return str.replace(/(\d+)\s?(\w{0,2})/, (match, p1, p2) => {
    const key = p2 === 'mo' ? p2 : p2[0]
    return `${parseFloat(p1)} ${units[key] ?? t('MINUTES')}`
  })
}

export default EstimateTimePopup
