import React from 'react'

const SectionHeading = props => {
  const { children, className, ...restProps } = props
  return (
    <h1
      className={`text-lg md:text-xl 2xl:text-2xl font-semibold text-primary-heading font-heading capitalize dark:text-dark-light ${
        className ?? 'mb-1'
      }`}
      {...restProps}
    >
      {children}
    </h1>
  )
}

export default SectionHeading
