import { makeStyles, createStyles } from '@material-ui/core'

const signupStyles = makeStyles((theme) =>
  createStyles({
    // Agency Signup
    root: {
      backgroundColor: 'rgba(245, 245, 245,1)',

      '& header': {
        marginBottom: '4rem'
      }
    },
    rootContainer: {
      flex: '0 1 90%',
      maxWidth: '400px',
      marginTop: '6vh'
    },
    heading: {
      fontSize: '1.6rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '900',
      lineHeight: '1.6rem'
    },
    formCard: {
      overflow: 'initial'
    },
    inputField: {
      padding: '0.5rem',
      fontSize: '0.8rem',

      '&:focus-within': {
        borderColor: 'blue'
      }
    },
    button: {
      width: '100%',
      fontSize: '1rem',
      marginTop: '0.65rem'
    },
    loginHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      // alignItems: 'center',
      fontWeight: '400',
      // padding: '5%',
      paddingTop: '0%',
      width: 'inherit'
    },
    para: {
      // marginBottom: '5px',
      fontWeight: '500',
      color: '#464646',
      fontSize: '0.75rem',
      letterSpacing: '0.321px'
    },
    [theme.breakpoints.down('sm')]: {
      root: {
        background: '#fff'
      },
      formCard: {
        boxShadow: 'none'
      }
    },

    // FreeAgency signup
    formWrapper: {
      maxWidth: 600
    },
    signupForm: {
      maxWidth: 550
    },
    signinInput: {
      backgroundColor: '#fff',
      borderRadius: '0.375rem',
      height: '2.375rem ',
      fontSize: '0.8rem',
      width: '100%',
      '&:hover, &:focus-within': {
        border: `1px solid ${theme.palette.primary.main}`
      }
    },
    dialogPaper: {
      width: '40vw',
      minWidth: '400px',
      maxWidth: '600px',
      height: '60vh',
      maxHeight: '500px',
      minHeight: '400px'
    },
    testimonialWrapper: {
      position: 'sticky',
      top: '25%'
    },
    // signup
    loginContainer: {
      height: '100vh',
      background:
        'linear-gradient(86deg, rgba(35,28,185,1) 0%, rgba(38,57,172,0.771796218487395) 100%)'
    },
    formContainer: {
      backgroundColor: '#fff',
      border: '1px solid #f6f6f6',
      boxShadow: theme.shadows[5],
      borderRadius: theme.shape.borderRadius,
      width: '70%',
      minWidth: '300px',
      maxWidth: '600px'
    },
    inputRowContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      padding: theme.spacing(1),
      '&>div': {
        flexGrow: '1'
      }
    },
    singleInputContainer: {
      width: '100%',
      '&>p': {
        color: '#4e4d65',
        fontSize: 14,
        fontWeight: 500
      },
      '&>*': {
        width: '100%'
      }
    },
    inputTelClass: {
      width: '100%',
      height: '100%',
      border: '1px solid #c4c4c4',
      borderRadius: '4px'
    },
    domain: {
      display: 'flex'
    },
    signupBtn: {
      width: '100%',
      marginTop: '9px',
      position: 'relative',
      height: '2.2rem',
      fontSize: '1rem'
    },
    buttonProgress: {
      color: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%'
    }
  })
)

export default signupStyles
