import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import {
  changeGroupUpdatedAt,
  fetchMessagesByCategory,
  updatePersonalUnreadMessageCount,
  updateUnreadMessageCount,
  uploadChatAttachment
} from 'thunks/chat/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getQuillHTML } from 'utils'
import ChatMessages from './ChatMessages'
import socket from 'config/socketConfig'
import { AlertModal } from 'global/globalComponents'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { getErrorMessages } from 'utils/errorMessages'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { getUserRootFolder } from 'thunks/fileAssets/actions'
import { useModal } from 'customHooks'
import QuillChatEditor from 'global/globalComponents/QuillEditor/QuillChatEditor'
import UpdateChannelModal from '../UpdateChannelModal'
import ChatWindowHeader from './ChatWindowHeader'
import { CHAT_WINDOW_COLOR } from 'constants/colors'

const errorMessages = getErrorMessages()

let timerId
let messageLimit = 20

const ChatWindow = ({
  fromWorkspace,
  channelId,
  channelName,
  channelMembers = [],
  onClose,
  isPersonalChat
}) => {
  const {
    modal: updateModal,
    openModal: openUpdateModal,
    closeModal: closeUpdateModal
  } = useModal()

  const openEditChannelModal = () => {
    openUpdateModal({ channelId })
  }

  return (
    <>
      {channelId ? (
        <ChatWindowContents
          channelId={channelId}
          channelName={channelName}
          channelMembers={channelMembers}
          fromWorkspace={fromWorkspace}
          onClose={onClose}
          openEditChannelModal={openEditChannelModal}
          isPersonalChat={isPersonalChat}
        />
      ) : (
        <LoadingAnimation />
      )}

      <UpdateChannelModal
        open={updateModal.open}
        onClose={closeUpdateModal}
        data={updateModal.data}
      />
    </>
  )
}

const ChatWindowContents = ({
  fromWorkspace,
  onClose,
  openEditChannelModal,
  isPersonalChat,
  channelId,
  channelName,
  channelMembers
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const msgsContainer = useRef()
  const meData = useSelector(state => state.me.data)
  const [messages, setMessages] = useState({
    loading: true,
    data: [],
    loadMore: true
  })
  // const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [typing, setTyping] = useState(false)
  const [usersTyping, setUsersTyping] = useState([])
  const [alertModal, setAlertModal] = useState({
    name: '',
    loading: false,
    data: {}
  })
  const [autoScroll, setAutoScroll] = useState(false)
  const { unreadMessages, personalUnreadMessages } = useSelector(
    state => state.chats
  )
  const [rootDir, setRootDir] = useState(null)
  // const history = useHistory()

  useEffect(() => {
    setMessages({
      loading: true,
      data: [],
      loadMore: true
    })
  }, [channelId])

  useEffect(() => {
    dispatch(
      getUserRootFolder({ userId: meData._id }, rootDir => {
        setRootDir(rootDir)
      })
    )
  }, [meData._id])

  const users = useMemo(() => {
    if (!channelId) return []

    const groupMembers = channelMembers
      .filter(item => Boolean(item.user))
      .map(item => ({
        id: item.user._id,
        value: item.user.name,
        //for backend and frontend
        _id: item.user._id,
        name: item.user.name,
        profileImage: item.user.profileImage,
        role: item.role
      }))

    return groupMembers
  }, [channelMembers, channelId])

  useEffect(() => {
    handleFetchMessages({
      limit: messageLimit,
      skip: 0,
      callback: msgs => {
        setMessages({
          loading: false,
          data: msgs,
          loadMore: msgs.length === messageLimit
        })
        setAutoScroll(true)
      }
    })
  }, [channelId])

  useEffect(() => {
    const unreadMsgData = isPersonalChat
      ? personalUnreadMessages[channelId]
      : unreadMessages[channelId]

    if (unreadMsgData?.unreadCount) {
      handleReadAllUnreadMessages(channelId)
    }
  }, [unreadMessages, personalUnreadMessages, channelId])

  useEffect(() => {
    if (autoScroll) {
      msgsContainer.current.scrollTo(0, msgsContainer.current.scrollHeight)
      setAutoScroll(false)
    }
  }, [autoScroll])

  // =========== Handling Socket =========
  useEffect(() => {
    const onReceiveMessage = messageData => {
      if (
        (isPersonalChat &&
          messageData.user === channelId &&
          messageData.receiver === meData._id) ||
        messageData.group === channelId
      ) {
        setMessages(prev => ({
          ...prev,
          data: [...prev.data, messageData]
        }))
        setAutoScroll(true)

        socket.emit(
          'readOneMessage',
          {
            _id: messageData._id,
            [isPersonalChat ? 'receiver' : 'group']: channelId
          },
          props => {
            // empty callback
          }
        )
      }
    }

    const onUserTyping = value => {
      setUsersTyping(prev => [...prev, value])
    }

    const onUserNotTyping = value => {
      setUsersTyping(prev => prev.filter(item => item.user !== value.user))
    }

    const onDeleteMessage = value => {
      setMessages(prev => ({
        ...prev,
        data: prev.data.filter(msg => msg._id !== value._id)
      }))
    }

    const onReceivedReadOneMessage = value => {
      setMessages(prev => ({
        ...prev,
        data: prev.data.map(msg => {
          if (msg._id === value._id) return value
          return msg
        })
      }))
    }

    socket.on('receiveMessage', onReceiveMessage)
    socket.on('userTyping', onUserTyping)
    socket.on('userNotTyping', onUserNotTyping)
    socket.on('deleteMessage', onDeleteMessage)
    socket.on('receivedReadOneMessage', onReceivedReadOneMessage)
    // socket.on('receivedReadAllMessages', onReceivedReadAllMessages)

    return () => {
      socket.off('receiveMessage', onReceiveMessage)
      socket.off('userTyping', onUserTyping)
      socket.off('userNotTyping', onUserNotTyping)
      socket.off('deleteMessage', onDeleteMessage)
      socket.off('receivedReadOneMessage', onReceivedReadOneMessage)
    }
  }, [meData._id, channelId, isPersonalChat])

  const handleReadAllUnreadMessages = channelId => {
    const unreadMessageData = isPersonalChat
      ? personalUnreadMessages[channelId]
      : unreadMessages[channelId]

    if (!unreadMessageData?.unreadCount) return

    socket.emit(
      'readAllMessages',
      { room: channelId, [isPersonalChat ? 'receiver' : 'group']: channelId },
      res => {
        if (res.status === 200) {
          if (isPersonalChat) {
            dispatch(
              updatePersonalUnreadMessageCount({
                channelId: channelId,
                value: {
                  ...unreadMessageData,
                  unreadCount: 0
                }
              })
            )
          } else {
            dispatch(
              updateUnreadMessageCount({
                groupId: channelId,
                value: {
                  ...unreadMessageData,
                  unreadCount: 0
                }
              })
            )
          }
        }
      }
    )
  }

  const handleSendMessage = useCallback(
    ({
      data,
      attachments,
      attachmentIds,
      mentions,
      hasEmoji,
      hasMentions,
      callback
    }) => {
      const textValue = getQuillHTML(data).trim()
      const htmlValue = getQuillHTML(data, 'html')
      const tempId = Date.now()

      if (!channelId) {
        dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
        return
      }

      //if msg is empty don't store empty html tags
      const messageValue =
        !textValue && !hasEmoji && !hasMentions ? '' : htmlValue

      socket.emit(
        'notTyping',
        { room: channelId, [isPersonalChat ? 'receiver' : 'group']: channelId },
        res => {
          // empty callback
        }
      )

      socket.emit(
        'sendMessage',
        {
          message: messageValue,
          [isPersonalChat ? 'receiver' : 'group']: channelId,
          room: channelId,
          metadata: { mentions },
          attachmentIds,
          attachments
        },
        ({ status, result }) => {
          if (status === 200) {
            setMessages(prev => ({
              ...prev,
              data: prev.data.map(item => {
                if (item._id === tempId) return result
                return item
              })
            }))
          }
        }
      )

      if (!isPersonalChat) {
        dispatch(
          changeGroupUpdatedAt({
            groupId: channelId,
            updatedAt: new Date().toISOString()
          })
        )

        dispatch(
          updateUnreadMessageCount({
            groupId: channelId,
            value: {
              lastUnreadMessage: {
                createdAt: new Date().toISOString(),
                message: messageValue,
                user: meData._id,
                createdBy: {
                  _id: meData._id,
                  name: meData.name
                }
              },
              unreadCount: 0
            }
          })
        )
      } else {
        dispatch(
          updatePersonalUnreadMessageCount({
            channelId: channelId,
            value: {
              lastUnreadMessage: {
                createdAt: new Date().toISOString(),
                message: messageValue,
                user: meData._id,
                createdBy: {
                  _id: meData._id,
                  name: meData.name
                }
              },
              unreadCount: 0
            }
          })
        )
      }
      // setContainsFile(false)
      // setFilesCount(0)
      // Manually adding data to messages
      setMessages(prev => ({
        ...prev,
        data: [
          ...prev.data,
          {
            _id: tempId,
            message: messageValue,
            attachmentIds,
            attachments,
            createdAt: new Date(),
            createdBy: {
              _id: meData._id,
              name: meData.name,
              profileImage: meData.profileImage
            },
            status: 'pending',
            readBy: []
          }
        ]
      }))
      setAutoScroll(true)
      callback()
    },
    [channelId, isPersonalChat, meData._id, meData.name, meData.profileImage]
  )

  const handleOnChange = useCallback(() => {
    clearTimeout(timerId)

    timerId = setTimeout(() => {
      socket.emit(
        'notTyping',
        { room: channelId, [isPersonalChat ? 'receiver' : 'group']: channelId },
        res => {
          // empty callback
        }
      )
      setTyping(false)
    }, 2000)

    if (typing) return
    setTyping(true)
    socket.emit(
      'typing',
      { room: channelId, [isPersonalChat ? 'receiver' : 'group']: channelId },
      res => {
        // empty callback
      }
    )
  }, [channelId, isPersonalChat])

  const handleOnBlur = useCallback(() => {
    if (!typing) return

    setTyping(false)
    socket.emit(
      'notTyping',
      { room: channelId, [isPersonalChat ? 'receiver' : 'group']: channelId },
      res => {
        // empty callback
      }
    )
  }, [channelId, isPersonalChat])

  const openDeleteMessageAlert = message => {
    setAlertModal({
      name: 'deleteMessage',
      data: message
    })
  }

  const closeAlert = () => {
    setAlertModal(prev => ({ ...prev, name: '' }))
  }

  const handleDeleteMessage = () => {
    setAlertModal(prev => ({ ...prev, loading: true }))

    socket.emit(
      'deleteMessage',
      {
        _id: alertModal.data._id,
        [isPersonalChat ? 'receiver' : 'group']: channelId
      },
      ({ status, ...rest }) => {
        if (status === 200) {
          setMessages(prev => ({
            ...prev,
            data: prev.data.filter(msg => msg._id !== alertModal.data._id)
          }))
          closeAlert()
        } else {
          dispatch(fireErrorToaster(t('ERROR_SOME_ERROR_OCCURED')))
          closeAlert()
        }
      }
    )
  }

  const handleScroll = e => {
    if (messages.loadMore && e.target.scrollTop < 1 && messages.data.length) {
      let prevScrollHeight = msgsContainer.current.scrollHeight
      setMessages(prev => ({ ...prev, loadMore: false, loading: true }))
      handleFetchMessages({
        limit: messageLimit,
        skip: messages.data.length,
        callback: msgs => {
          setMessages(prev => ({
            ...prev,
            loading: false,
            data: [...msgs, ...prev.data],
            loadMore: msgs.length === messageLimit
          }))
          msgsContainer.current.scrollTo(
            0,
            msgsContainer.current.scrollHeight - prevScrollHeight
          )
        }
      })
    }
  }

  const handleFetchMessages = ({ limit, skip, callback }) => {
    fetchMessagesByCategory(
      {
        id: channelId,
        query: { category: isPersonalChat ? 'direct' : 'group', limit, skip }
      },
      (res, err) => {
        if (!err) {
          const msgs = res.reverse()
          callback(msgs)
        }
      }
    )
  }

  const fileUploadHandler = useCallback(
    (file, callback) => {
      // setContainsFile(true)
      uploadChatAttachment(
        {
          fileData: {
            fileName: file.name.slice(0, file.name.lastIndexOf('.')),
            file: file
          },
          data: {
            fileAssetsFolder: rootDir._id
          }
        },
        (res, err) => {
          // setContainsFile(true)
          callback(res, err)

          if (err) {
            // if (filesCount === 0) setContainsFile(false)
            dispatch(fireErrorToaster(res))
          }
        }
      )
    },
    [dispatch, rootDir]
  )

  return (
    <>
      <div className="h-full flex flex-col">
        {/* ===== Header ===== */}
        <ChatWindowHeader
          channelMembers={channelMembers}
          channelName={channelName}
          isPersonalChat={isPersonalChat}
          openEditChannelModal={
            !isPersonalChat ? openEditChannelModal : undefined
          }
          onClose={fromWorkspace ? onClose : undefined}
          usersTyping={usersTyping}
        />

        {/* ======== Messages ========= */}
        <div
          className="overflow-y-auto relative flex-1"
          onScroll={handleScroll}
          ref={msgsContainer}
          style={{ backgroundColor: CHAT_WINDOW_COLOR }}
        >
          {messages.loading && (
            <p
              style={{ backgroundColor: CHAT_WINDOW_COLOR }}
              className="absolute top-0 w-full text-center text-xs text-gray-500 py-1"
            >
              {t('LOADING_MESSAGES')}...
            </p>
          )}
          <ChatMessages
            messages={messages.data}
            meId={meData._id}
            openDeleteMessageAlert={openDeleteMessageAlert}
            totalUsers={isPersonalChat ? 2 : users.length}
            noMessages={!messages.data.length && !messages.loadMore}
          />
        </div>

        {/* ===== Footer ===== */}
        <div className="w-full px-6 my-2">
          <QuillChatEditor
            // key={users.length} //remount this component if users length changes
            users={isPersonalChat ? [] : users}
            onSubmit={handleSendMessage}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            fileUploadHandler={fileUploadHandler}
            styles={{ root: { backgroundColor: CHAT_WINDOW_COLOR } }}
          />
          {/* <p className="text-tiny mt-1 font-medium text-gray-500 h-5">
            {Boolean(usersTyping.length) &&
              `${usersTyping[0].name} ${t('IS_TYPING')}...`}
          </p> */}
        </div>
      </div>

      <AlertModal
        warningText={t('DELETE_MESSAGE_WARNING')}
        handleDialog={closeAlert}
        open={alertModal.name === 'deleteMessage'}
        handleDeleteAction={handleDeleteMessage}
        loading={alertModal.loading}
      />
    </>
  )
}

const LoadingAnimation = () => {
  return (
    <div className="bg-white h-full">
      {/* ===== Header ===== */}
      <header className="py-3 px-4 border-b bg-white sticky top-0">
        <Skeleton variant="text" height={40} width={205} />
      </header>

      {/* ======== Messages ========= */}
      <div className="p-2">
        {new Array(3).fill('').map((item, index) => (
          <div key={index} className="flex items-start px-6 py-2">
            <Skeleton variant="circle" width={35} height={35} />
            <Skeleton
              variant="rect"
              className="ml-3"
              height={60}
              width={135 + index * 20}
              style={{ borderRadius: '0 10px 10px' }}
            />
          </div>
        ))}

        {new Array(2).fill('').map((item, index) => (
          <div key={index} className="flex justify-end px-6 py-2">
            <Skeleton
              variant="rect"
              height={60}
              width={135 + index * 20}
              style={{ borderRadius: '10px 0 10px 10px' }}
            />
            <Skeleton
              className="ml-3"
              variant="circle"
              width={35}
              height={35}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ChatWindow
