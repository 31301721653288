import React from 'react'
import { ColoredAvatarGroup, ColoredAvatars } from 'global/globalComponents'
import AsanaIcons from 'static/asana/AsanaIcons'
import { getRoleBadge } from 'utils'
import { useTranslation } from 'react-i18next'

// Generates notification message for category: workspace and workspaceView
const WorkspaceNotificationMessage = ({
  field,
  source,
  workspace,
  category,
  before,
  after
}) => {
  const { t } = useTranslation()

  const generateMessage = () => {
    // ==== Board related ====
    if (category === 'workspaceView') {
      if (field === 'create') {
        return (
          <>
            {t('NOTIFICATION_BOARD_CREATE')}&nbsp;
            <span className="font-semibold">'{after}'</span>
          </>
        )
      } else if (field === 'name') {
        return (
          <>
            {t('NOTIFICATION_BOARD_UPDATE_NAME')}:&nbsp;
            <span className="font-semibold">'{before}'</span>&nbsp;&#8594;&nbsp;
            <span className="font-semibold">'{after}'</span>
          </>
        )
      } else if (field === 'update_section_order') {
        return (
          <>
            {t('NOTIFICATION_BOARD_UPDATE_SECTION_ORDER')}&nbsp;
            <span className="font-semibold">'{workspace.name}'</span>.
          </>
        )
      } else if (field === 'delete') {
        return (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_BOARD_DELETE', {
                  boardName: before
                })
              }}
            ></span>
            &nbsp;
            <span className="font-semibold">'{workspace.name}'</span>
          </>
        )
      }
    }

    // ======== Section related ======
    if (category === 'workspaceSection') {
      if (field === 'create') {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_BOARD_SECTION_CREATE', {
                sectionName: after,
                workspaceName: workspace?.name
              })
            }}
          ></span>
        )
      }

      if (field === 'label') {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_BOARD_SECTION_LABEL_CHANGE', {
                oldSectionName: before,
                newSectionName: after
              })
            }}
          ></span>
        )
      }

      if (field === 'delete') {
        return (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_BOARD_SECTION_DELETE', {
                  sectionName: before
                })
              }}
            ></span>
            &nbsp;
            <span className="font-semibold">'{workspace?.name}'</span>
          </>
        )
      }
    }

    // =========== Workspace related ======
    if (category === 'workspace') {
      if (field === 'create') {
        return (
          <>
            {t('NOTIFICATION_WORKSPACE_CREATE')}&nbsp;
            <span className="font-semibold">'{after}'</span>
          </>
        )
      }

      if (field === 'category') {
        return (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_WORKSPACE_UPDATE_CATEGORY', {
                  workspaceName: source?.name
                })
              }}
            ></span>
            &nbsp;
            <span className="font-semibold">'{before}'</span>&nbsp;&#8594;&nbsp;
            <span className="font-semibold">'{after}'</span>
          </>
        )
      }

      if (field === 'assignee_add') {
        return (
          <div className="flex items-center">
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_WORKSPACE_ASSIGNEE_ADD', {
                  totalAssignee: after.length,
                  workspaceName: source?.name,
                  assignee: t('ASSIGNEE')
                })
              }}
            ></span>
            &nbsp;
            <ColoredAvatarGroup
              users={after.map(item => item.user)}
              tooltip={true}
              size="medium"
            />
          </div>
        )
      }

      if (field === 'assignee_delete') {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_WORKSPACE_ASSIGNEE_DELETE', {
                workspaceName: source?.name,
                userName: after?.name
              })
            }}
          ></span>
        )
      }

      if (field === 'update') {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_WORKSPACE_UPDATE_PROPERTIES', {
                workspaceName: source?.name
              })
            }}
          ></span>
        )
      }

      if (field === 'delete') {
        // before field contains project name
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_WORKSPACE_DELETE_PROPERTIES', {
                workspaceName: before
              })
            }}
          ></span>
        )
      }

      if (field === 'image') {
        return (
          <div className="flex items-center">
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_WORKSPACE_UPDATE_IMAGE', {
                  workspaceName: source?.name
                })
              }}
            ></span>
            &nbsp;:&nbsp;
            <span className="bg-primary-light inline-block p-1.5 rounded">
              {AsanaIcons[before]({
                height: '24px',
                width: '24px',
                className: 'selected-icon'
              })}
            </span>
            &nbsp;&#8594;&nbsp;
            <span className="bg-primary-light inline-block p-1.5 rounded">
              {AsanaIcons[after]({
                height: '24px',
                width: '24px',
                className: 'selected-icon'
              })}
            </span>
          </div>
        )
      }

      if (field === 'backgroundcolor') {
        return (
          <div className="flex items-center">
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_WORKSPACE_UPDATE_BACKGROUND_COLOR', {
                  workspaceName: source?.name
                })
              }}
            ></span>
            &nbsp;:&nbsp;
            <span
              className="inline-block w-8 h-8 rounded border"
              style={{ backgroundColor: before }}
            ></span>
            &nbsp;&#8594;&nbsp;
            <span
              className="inline-block w-8 h-8 rounded border"
              style={{ backgroundColor: after }}
            ></span>
          </div>
        )
      }

      if (field === 'role_update') {
        return (
          <div className="flex items-center">
            <span
              dangerouslySetInnerHTML={{
                __html: t('NOTIFICATION_WORKSPACE_UPDATE_ASSIGNEE_ROLE', {
                  workspaceName: source?.name,
                  userName: before?.user?.name
                })
              }}
            ></span>
            &nbsp;:&nbsp;
            <span>{before?.role && getRoleBadge(before.role)}</span>
            &nbsp;&#8594;&nbsp;
            <span>{after?.role && getRoleBadge(after.role)}</span>
          </div>
        )
      }

      if (field === 'workspace_archived') {
        return (
          <>
            {t('NOTIFICATION_WORKSPACE_UPDATE_ARCHIVE', {
              data: after ? t('ARCHIVED') : t('UNARCHIVED')
            })}
            &nbsp;
            <span className="font-semibold">'{source?.name}'</span>
          </>
        )
      }
    }
  }

  return (
    <div>
      <div className="text-smaller">{generateMessage()}</div>
    </div>
  )
}

export default WorkspaceNotificationMessage
