import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserPath } from 'utils/userRoles'
import DynamicTabs from './DynamicTabs'

const BoardDynamicTabs = ({
  workspaceTab,
  canDelete,
  workspaceId,
  handleDeleteWorkspaceTab,
  handleTabClick
}) => {
  const meData = useSelector(state => state.me.data)
  const userPath = useMemo(() => {
    return getUserPath(meData.role)
  }, [meData.role])
  const { pathname } = useLocation()

  const dynamicTabsData = useMemo(() => {
    return Object.values(workspaceTab.data).map(tab => {
      const tabData = tab.find(item => pathname.endsWith(item._id)) || tab[0]

      return {
        name: tabData.type,
        label: tabData.name,
        _id: tabData._id,
        path: `${userPath}/workspace/${workspaceId}/${tabData.type}/${tabData._id}`,
        query: '',
        total: tab.length,
        isRemovable: canDelete && tab.length === 1
      }
    })
  }, [workspaceTab.data, userPath, canDelete, workspaceId, pathname])

  return (
    <DynamicTabs
      dynamicTabsData={dynamicTabsData}
      handleDeleteWorkspaceTab={handleDeleteWorkspaceTab}
      handleTabClick={handleTabClick}
    />
  )
}

export default BoardDynamicTabs
