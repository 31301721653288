import Quill from 'quill'
import 'react-quill/dist/quill.snow.css'
import 'quill-mention'
import 'quill-mention/dist/quill.mention.css'
import MagicUrl from 'quill-magic-url'

const Embed = Quill.import('blots/embed')

class EmojiEmbed extends Embed {
  static create(value) {
    const node = super.create()
    node.innerText = value
    return node
  }

  static value(domNode) {
    return domNode.innerText
  }
}

class VariableBlot extends Embed {
  static create(value) {
    let node = super.create()
    node.innerText = value
    return node
  }

  static value(domNode) {
    return domNode.innerText
  }
}
VariableBlot.blotName = 'quillVariable'
VariableBlot.className = 'quill-variable'
VariableBlot.tagName = 'SPAN'

EmojiEmbed.blotName = 'emojiEmbed'
EmojiEmbed.tagName = 'SPAN'
EmojiEmbed.className = 'emoji'

Quill.register(EmojiEmbed)
Quill.register(VariableBlot)
Quill.register('modules/magicUrl', MagicUrl) // Checks for URLs and mail addresses during typing and pasting and automatically converts them to links and normalizes the links URL.
