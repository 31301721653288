import React from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { ColoredAvatarGroup } from 'global/globalComponents'
import { userRoles } from 'utils'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { Skeleton } from '@material-ui/lab'
import { Clientvenue_logo } from 'static/Images/index'

const SidePanel = ({ formData }) => {
  const me = useSelector(state => state.me.data)
  const { t } = useTranslation()
  return (
    <div
      className={clsx(
        ' w-64 p-4 px-8 text-xs flex flex-col gap-2 border-r dark:text-dark-light dark:bg-dark-main4'
      )}
    >
      <div className="flex justify-center items-center my-4">
        <img
          src={
            (me.role === userRoles.USER_AGENCY
              ? me.profile.brand.largeLogo
              : me.team.profile.brand.largeLogo) || Clientvenue_logo
          }
          alt="company logo"
          className="rounded-full w-32"
        />
      </div>
      {Boolean(formData.name.length) ? (
        <div className=" text-base">
          <p className="dark:text-dark-light2">{formData.name}</p>
        </div>
      ) : (
        <Skeleton
          animation={false}
          variant="text"
          height={30}
          width={150}
          className="dark:bg-dark-main3"
        />
      )}
      {formData.type !== 'Internal' &&
        (formData.assignedClientTeam?._id ? (
          <div>
            <p className="dark:text-dark-light">
              <span className="font-semibold dark:text-dark-light2">
                {t('CLIENT_NAME') + ': '}
              </span>
              {formData.assignedClientTeam?.name}
            </p>
          </div>
        ) : (
          <Skeleton
            animation={false}
            variant="text"
            height={20}
            width={150}
            className="dark:bg-dark-main3"
          />
        ))}
      {formData.startDate ? (
        <p className="mb-4 dark:text-dark-light">
          {' '}
          {t('STARTS_ON')} {format(new Date(formData.startDate), 'MMM d')}
        </p>
      ) : (
        <Skeleton
          animation={false}
          variant="text"
          height={20}
          width={150}
          className="dark:bg-dark-main3"
        />
      )}

      <div className="my-2">
        <h4 className="font-semibold my-1 dark:text-dark-light2">
          {t('CREATOR')}
        </h4>
        <p className="text-sm dark:text-dark-light">{me?.name}</p>
      </div>

      <div className="my-2">
        <h4 className="font-semibold my-1 dark:text-dark-light2">
          {me.profile?.brand?.brandName} {t('TEAM')}
        </h4>
        {Boolean(formData.assignedAgencyTeam?.length) ? (
          <ColoredAvatarGroup max={9} users={formData.assignedAgencyTeam} />
        ) : (
          <div className="flex">
            {Array(3)
              .fill('')
              .map(el => (
                <Skeleton
                  animation={false}
                  width={20}
                  height={20}
                  variant="circle"
                  className="dark:bg-dark-main3"
                />
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SidePanel
