import React, { useEffect } from 'react'
import { ReactComponent as CheckIcon } from 'static/svg/check-circle.svg'
import { GoBack, Loader } from 'global/globalComponents'
import serviceDefaultImage from 'static/Images/default-service.png'
import Currency from 'static/Currency/Currency'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CheckoutDetailsInvoiceCard from './CheckoutDetailsInvoiceCard'
import { useTranslation } from 'react-i18next'

const CheckoutServiceDetails = () => {
  const { invoiceId } = useParams()
  const { t } = useTranslation()
  const invoices = useSelector(state => state.clientInvoices)
  const invoiceData = invoices.data.find(invoice => invoice._id === invoiceId)
  const serviceData = invoiceData?.invoiceItems
    ? invoiceData?.invoiceItems[0]
    : null

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let recurringPeriod = ''
  if (serviceData?.isRecurring && serviceData?.recurring) {
    if (serviceData.recurring.interval === 'year') {
      recurringPeriod = 'Billed every year'
    } else {
      recurringPeriod = `Billed every ${
        serviceData.recurring.interval_count === 1
          ? 'month'
          : serviceData.recurring.interval_count + ' months'
      }`
    }
  }

  if (!serviceData) return <Loader />

  return (
    <div className="mx-auto px-8" style={{ maxWidth: 1400 }}>
      <GoBack className="mb-4" />
      <div className="flex items-start pb-12">
        <div className="flex-1 mr-8">
          <div className="mb-6">
            <img
              src={serviceData.image || serviceDefaultImage}
              alt=""
              className="w-full object-cover mb-6 rounded"
              style={{ maxHeight: 300 }}
            />
            <h1 className="text-2xl font-semibold dark:text-white">
              {serviceData.name}
            </h1>
          </div>

          <div className="mb-6">
            <ServiceInfoFieldValue
              label="Amount"
              value={
                <>
                  {Currency[serviceData.currency.toUpperCase()].symbol_native}
                  {(serviceData.amount / 100).toLocaleString()}
                </>
              }
            />

            <ServiceInfoFieldValue
              label="Type"
              value={
                <>
                  {!serviceData.isRecurring ? (
                    <>
                      One time{' '}
                      <span className="text-smaller font-normal">
                        (one time payment)
                      </span>
                    </>
                  ) : (
                    <>
                      {t('RECURRING')}
                      <span className="text-smaller font-normal">
                        ({recurringPeriod})
                      </span>
                    </>
                  )}
                </>
              }
            />
          </div>

          <div className="mb-6">
            <div className="tracking-wider text-sm font-semibold dark:text-white mb-2">
              Description:
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: serviceData.description }}
              className="leading-6 dangerous-html-container text-sm text-gray-700 dark:text-dark-light mb-8"
            ></div>
          </div>

          {serviceData.offerings?.length > 0 && (
            <div className="mb-6">
              <div className="text-base font-semibold dark:text-white mb-4">
                {t('OFFERINGS')}:
              </div>

              <div>
                {serviceData.offerings.map((offer, index) => (
                  <div key={index} className="flex start mb-4">
                    <div className="flex-shrink-0">
                      <CheckIcon className="text-green-500 w-6 h-6" />
                    </div>
                    <div className="ml-2 dark:text-white mt-0.5">
                      <div className="text-sm font-medium">{offer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div
          style={{ width: 400 }}
          className="bg-white border shadow px-6 pt-4 pb-6 sticky top-5 rounded dark:bg-dark-main dark:text-white dark:border-dark-main3"
        >
          <CheckoutDetailsInvoiceCard invoiceData={invoiceData} />
        </div>
      </div>
    </div>
  )
}

const ServiceInfoFieldValue = ({ label, value }) => {
  return (
    <div className="flex items-center mb-2">
      <div className="w-20 text-sm font-semibold dark:text-white mr-4">
        {label} :
      </div>
      <div className="text-sm font-medium text-gray-700 dark:text-dark-light">
        {value}
      </div>
    </div>
  )
}

export default CheckoutServiceDetails
