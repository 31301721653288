import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getClient } from 'thunks/addClient/actions'
// import { LOGS } from 'thunks/logs/reducer'
import { SUBTASKS } from 'thunks/subTask/reducers'
import {
  addOneTaskByPusher,
  deleteOneTaskByPusher,
  pusherUpdateOneTask
} from 'thunks/task/actions'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import {
  handleUnAssignPersonEvent,
  updateOneWorkspaceData,
  handleAssignTeammateEvent,
  updateBoardData
} from 'thunks/workspace/actions'
import { WORKSPACES } from 'thunks/workspace/reducers'
import { getUserPath } from 'utils/userRoles'
import userRoles from 'utils/userRoles'
import { EMBEDS } from 'thunks/embed/reducer'
import WorkspacePusherContext from './WorkspacePusherContext'

const WorkspacesPusherWrapper = props => {
  const meData = useSelector(state => state.me.data)
  const userWorkspacesData = useSelector(state => state.userWorkspaces.data)
  const allBoards = useSelector(state => state.embed.data)
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)
  const { pathname } = useLocation()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )

  const dispatch = useDispatch()
  const history = useHistory()
  const [socketId, setSocketId] = useState('')
  const [pusherConnected, setPusherConnected] = useState(false)

  useEffect(() => {
    setPusherConnected(Boolean(window.pusherInstance))
  })

  // ============= Pusher Setup (Subscription) =============

  useEffect(() => {
    if (!window.pusherInstance) return

    if (allBoards.length) {
      // 1. Subscribing each available board
      const workspaceBoardChannels = allBoards.map(i =>
        window.pusherInstance.subscribe(`workspaceView_${i._id}`)
      )

      if (workspaceBoardChannels) {
        workspaceBoardChannels.forEach(ch => {
          setSocketId(ch.pusher.connection.socket_id)
          if (!ch.subscribed) {
            connectBoardToPusher(ch)
          }
        })
      }
    }
  }, [allBoards.length])

  useEffect(() => {
    if (!window.pusherInstance) return
    let agencyOwner
    if (meData.role === userRoles.USER_AGENCY) {
      agencyOwner = meData._id
    } else if (
      [
        userRoles.USER_CLIENT,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_EDITOR,
        userRoles.AGENCY_VIEWER,
        userRoles.AGENCY_ADMIN
      ].includes(meData.role)
    ) {
      agencyOwner = meData.team._id
    } else if (
      [
        userRoles.CLIENT_MANAGER,
        userRoles.CLIENT_EDITOR,
        userRoles.CLIENT_VIEWER
      ].includes(meData.role)
    ) {
      agencyOwner = meData.agency
    }

    // 1. Looking for the channel in the pusher instance (whether it exists or not)
    const myChannel = window.pusherInstance.channel(`create_${agencyOwner}`)

    // 2. If channel does not exist or channel does exist but not subscribed then do the following
    if (!myChannel || !myChannel.subscribed) {
      const newCreateChannelSubscribed = window.pusherInstance.subscribe(
        `create_${agencyOwner}`
      )

      newCreateChannelSubscribed.bind('workspace_add', data => {
        // If me (the current user) is not a member of the project then simply return and do nothing
        const myWorkspace = [
          ...data.assignedAgencyTeam,
          ...data.assignedClientTeam
        ].find(item => item.user._id === meData._id)

        if (!myWorkspace) return

        dispatch({
          type: WORKSPACES.PUSHER_ADD_ONE_WORKSPACE,
          payload: data
        })

        const wsConnected = window.pusherInstance.subscribe(
          `workspace_${data._id}`
        )
        if (wsConnected) {
          connecteWsToPusher(wsConnected)
        }
      })

      // newCreateChannelSubscribed.bind('subTask_add', (data) => {
      //   dispatch({ type: SUBTASKS.ADD_ONE_TASK, payload: data })
      // })

      // newCreateChannelSubscribed.bind('log_add', (data) => {
      //   dispatch({ type: LOGS.POSTED_LOG, payload: data })
      // })

      // newCreateChannelSubscribed.bind('log_delete', (data) => {
      //   dispatch({ type: LOGS.DELETE_COMMENT, payload: data })
      // })
    }
  }, [meData?._id, meData.team?._id, pusherConnected])

  useEffect(() => {
    if (!window.pusherInstance) return
    const workspaceChannels = userWorkspacesData.map(i =>
      window.pusherInstance.subscribe(`workspace_${i._id}`)
    )

    if (workspaceChannels) {
      workspaceChannels.forEach(ch => {
        if (ch.subscribed) {
        } else {
          connecteWsToPusher(ch)
        }
      })
    }
  }, [userWorkspacesData.length])

  const pushUserToWorkspaces = () => {
    // only push when user is in workspaces area
    if (window.location.pathname.includes('/workspace')) {
      history.push(`${getUserPath(meData.role)}/workspace/all`)
    }
  }

  // ============= Receiving Project (Workspace) Related Event ==============
  const connecteWsToPusher = wsPusher => {
    wsPusher.bind('workspace_update', data => {
      dispatch(updateOneWorkspaceData(data))
      // handleUserUnassignEvent(data)
    })
    wsPusher.bind('workspace_delete', data => {
      dispatch({
        type: WORKSPACES.PUSHER_DELETE_ONE_WORKSPACE,
        payload: data
      })
    })

    wsPusher.bind('workspace_assignUser', data => {
      dispatch(updateOneWorkspaceData(data))
      dispatch(handleAssignTeammateEvent(data))
      dispatch(fetchTeammateOfCurrentUser())
      if (meData?.role < userRoles.USER_CLIENT) dispatch(getClient())
      // handleUserUnassignEvent(data)
    })

    wsPusher.bind('workspace_unAssignUser', data => {
      dispatch(handleUnAssignPersonEvent(data, pushUserToWorkspaces))
      dispatch(fetchTeammateOfCurrentUser())
      if (meData?.role < userRoles.USER_CLIENT) dispatch(getClient())
    })

    wsPusher.bind('task_add', data => {
      dispatch(addOneTaskByPusher({ task: data }))
    })

    wsPusher.bind('task_delete', data => {
      // dispatch(deletOneTaskByPusher(data))
      // adding one second delay (to prevent twice task remove)
      window.setTimeout(() => {
        dispatch(deleteOneTaskByPusher({ task: data }))
      }, 1000)
    })

    wsPusher.bind('task_update', data => {
      dispatch(pusherUpdateOneTask({ task: data }))
    })
  }

  // ============= Receiving Board Related Event ==============
  const connectBoardToPusher = boardPusher => {
    boardPusher.bind('workspaceView_delete', data => {
      // If user is on "/board" route and board gets deleted
      if (data._id === currentBoard?._id && pathname.includes('/board')) {
        history.push(
          pathname.split('/board')[0] +
            '/board/' +
            currentWorkspace.defaultBoard._id
        )
      }
      dispatch({ type: EMBEDS.DELETE, payload: data })
    })

    boardPusher.bind('workspaceView_update', data => {
      dispatch(updateBoardData(data))
    })

    boardPusher.bind('subTask_update', data => {
      dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: data })
    })

    boardPusher.bind('subTask_delete', data => {
      dispatch({ type: SUBTASKS.DELETE_ONE_TASK, payload: data._id })
    })
  }

  return (
    <WorkspacePusherContext.Provider value={{ socketId }}>
      {props.children}
    </WorkspacePusherContext.Provider>
  )
}

// async function broadcast() {
//   const d = await fetch('https://clientvenue-dev2.herokuapp.com/broadcast', {
//     method: 'GET',
//     headers: {
//       'content-type': 'application/json'
//     }
//   })
// }

export default WorkspacesPusherWrapper
