import React from 'react'
import { ReactComponent as OptionsIcon } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import clsx from 'clsx'
import { PopupMenu } from 'global/globalComponents'
import categoryImage from 'static/Images/category.jpg'
import { makeStyles } from '@material-ui/core'

const CategoryCard = ({ name, description, image, options, onClick }) => {
  const classes = useStyles()

  return (
    <div className="relative show-on-hover-parent">
      {options && (
        <PopupMenu
          targetComp={
            <button className="w-6 h-6 bg-gray-50 rounded-full absolute border top-2 right-2 flex justify-center items-center text-gray-600 z-10 show-on-hover-child">
              <OptionsIcon className="w-3 h-3" />
            </button>
          }
          menuItems={[
            {
              icon: <PenIcon />,
              label: 'Edit',
              method: options.onEdit
            },
            {
              icon: <TrashIcon />,
              label: 'Delete',
              isDanger: true,
              method: options.onDelete
            }
          ]}
        />
      )}

      <div
        className={clsx(
          'border rounded-md cursor-pointer hover:border-primary-main',
          classes.categoryCard
        )}
        onClick={onClick}
      >
        <div className="mx-auto h-40">
          <img
            src={image || categoryImage}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>

        <div className="p-3">
          <div className="text-sm font-semibold">{name}</div>
          <p className="text-smaller text-gray-600 mb-4">{description}</p>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  categoryCard: {
    '&:hover img': {
      filter: 'brightness(0.7)',
      transition: 'all 240ms ease'
    }
  }
})

export default CategoryCard
