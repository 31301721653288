import {
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useEffect, useState } from 'react'
import { Pagination } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import Pill from 'global/globalComponents/Pill/Pill'
import clsx from 'clsx'
import moment from 'moment'

const totalInvoicesToShow = 8

const ScheduledInvoicesTable = ({ invoicesData, loading }) => {
  const { t } = useTranslation()
  const [visibleInvoices, setVisibleInvoices] = useState([])
  const [page, setPage] = useState(0)

  useEffect(() => {
    setVisibleInvoices(
      [...invoicesData].splice(page * totalInvoicesToShow, totalInvoicesToShow)
    )
  }, [invoicesData, page])

  return (
    <>
      <TableContainer mb={6}>
        <Thead>
          <Th>{t('CLIENT')}</Th>
          <Th>{t('PROJECT')}</Th>
          <Th className="text-center">{t('INVOICE_CYCLE')}</Th>
          <Th>{t('NEXT_INVOICE_DATE')}</Th>
        </Thead>
        <Tbody>
          {loading ? (
            <TableDataLoading cols={4} />
          ) : visibleInvoices.length === 0 ? (
            <Tr>
              <Td colSpan="6">{t('NO_INVOICE_AVAILABLE')}</Td>
            </Tr>
          ) : (
            visibleInvoices.map((item, index) => (
              <Row key={index} {...item} t={t} />
            ))
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={invoicesData.length}
        page={page}
        limit={totalInvoicesToShow}
      />
    </>
  )
}

const Row = ({ project, client, invoiceCycle, t }) => {
  let nextInvoiceDate

  if (invoiceCycle === 7) {
    nextInvoiceDate = moment()
      .startOf('isoWeek')
      .add(1, 'week')
      .day('monday')
      .format('ll')
  } else {
    nextInvoiceDate = moment().add(1, 'M').startOf('month').format('ll')
  }

  return (
    <Tr>
      <Td className="px-6 py-4 whitespace-nowrap text-gray-900">
        <div className="flex flex-col">
          <span className={clsx(!client.name && 'text-gray-500')}>
            {client.name || 'NA'}
          </span>
          <span className="text-gray-500 text-xs dark:text-dark-light">
            {client.email}
          </span>
        </div>
      </Td>
      <Td className="whitespace-nowrap">
        <Pill variant="pink">{project.name}</Pill>
      </Td>
      <Td className="text-center">
        <Pill variant={invoiceCycle === 7 ? 'blue' : 'orange'}>
          {invoiceCycle === 7 ? t('WEEKLY') : t('MONTHLY')}
        </Pill>
      </Td>
      <Td>{nextInvoiceDate}</Td>
    </Tr>
  )
}

export default ScheduledInvoicesTable
