export const CHANNEL = {
  CREATE_GROUP: 'CHANNEL_CREATE_GROUP',
  FETCH_GROUPS: 'CHANNEL_FETCH_GROUPS',
  SET_OPENED_GROUP: 'CHANNEL_SET_OPENED_GROUP',
  // SET_READ_ALL_IN_CHAT: 'CHANNEL_SET_READ_CONVERSATION',
  TOGGLE_CREATE_CHANNEL: 'CHANNEL_TOGGLE_CREATE_CHANNEL',
  SET_SOCKET_STATUS: 'CHANNEL_SET_SOCKET_STATUS_FOR_CURRENT_CHANNEL',
  // FETCH_GROUP_BY_ID: 'CHANNEL_FETCH_GROUP_BY_GROUP_ID',
  UPDATE_GROUP_BY_ID: 'CHANNEL_UPDATE_GROUP_BY_ID',
  DELETE_GROUP_BY_ID: 'CHANNEL_DELETE_GROUP_BY_ID',
  ARCHIVE_GROUP: 'CHANNEL_ARCHIVE_GROUP',
  SET_EDITABLE: 'CHANNEL_SET_EDITABLE_FOR_CURRENT_CHANNEL',
  TOGGLE_CHAT_PANEL: 'TOGGLE_CHAT_PANEL',
  FETCHED_UNREAD_MESSAGES: 'CHAT_FETCHED_UNREAD_MESSAGES',
  FETCHED_PERSONAL_UNREAD_MESSAGES: 'CHAT_FETCHED_PERSONAL_UNREAD_MESSAGES',
  UPDATE_UNREAD_MESSAGE_COUNT: 'CHAT_UPDATE_UNREAD_MESSAGE_COUNT',
  UPDATE_PERSONAL_UNREAD_MESSAGE_COUNT:
    'CHAT_UPDATE_PERSONAL_UNREAD_MESSAGE_COUNT',
  CHANGE_GROUP_UPDATED_AT: 'CHAT_CHANGE_GROUP_UPDATED_AT',
  CLEAR_UNREAD_MESSAGES: 'CHAT_CLEAR_UNREAD_MESSAGES',
  SET_CURRENT_CHANNEL_ID: 'CHAT_SET_CURRENT_CHANNEL_ID'
}

const initialState = {
  loading: true,
  groups: {
    loading: true,
    fetched: false,
    data: []
  },
  currentChannelId: null, //group which is currently active
  // fetchedFirstTime: false,
  totalUnreadCount: 0, // from all channels
  chatPanelOpen: false, // for workspace area
  unreadMessages: {
    // example=> <groupId>: {unreadCount: <unread message count>}
  },
  personalUnreadMessages: {}
}

const channelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANNEL.CREATE_GROUP: {
      const modified = [...state.groups.data, action.payload]
      return {
        ...state,
        groups: {
          loading: false,
          fetched: true,
          data: modified
        }
      }
    }

    case CHANNEL.SET_CURRENT_CHANNEL_ID: {
      return { ...state, currentChannelId: action.payload }
    }

    case CHANNEL.FETCH_GROUPS: {
      return {
        ...state,
        loading: false,
        groups: {
          loading: false,
          fetched: true,
          data: action.payload.groups
        },
        totalUnreadCount: action.payload.totalUnreadCount
      }
    }

    case CHANNEL.FETCHED_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.payload
      }

    case CHANNEL.FETCHED_PERSONAL_UNREAD_MESSAGES:
      return {
        ...state,
        personalUnreadMessages: action.payload
      }

    case CHANNEL.UPDATE_UNREAD_MESSAGE_COUNT: {
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          [action.payload.groupId]: action.payload.value
        }
      }
    }

    case CHANNEL.UPDATE_PERSONAL_UNREAD_MESSAGE_COUNT: {
      return {
        ...state,
        personalUnreadMessages: {
          ...state.personalUnreadMessages,
          [action.payload.channelId]: action.payload.value
        }
      }
    }

    case CHANNEL.UPDATE_GROUP_BY_ID: {
      const chat_id = action.payload._id

      const modifiedGroups = state.groups.data.map(group => {
        if (chat_id === group._id) return action.payload
        return group
      })

      return {
        ...state,
        groups: {
          ...state.groups,
          data: modifiedGroups
        }
      }
    }

    case CHANNEL.DELETE_GROUP_BY_ID: {
      let filteredGroups = []
      let unread = 0 // for case when a group has unread count and is deleted from the navbar in '/chat' without being opened(/read)

      state.groups.data.forEach(group => {
        if (group._id !== action.payload) filteredGroups.push(group)
        else unread = group.unread
      })

      return {
        ...state,
        groups: {
          ...state.groups,
          data: filteredGroups
        },
        totalUnreadCount: state.totalUnreadCount - unread
      }
    }

    case CHANNEL.ARCHIVE_GROUP: {
      const modified = state.groups.data.map(group => {
        if (group._id === action.payload._id) {
          return action.payload
        }
        return group
      })

      return {
        ...state,
        groups: {
          ...state.groups,
          data: modified
        }
      }
    }

    case CHANNEL.TOGGLE_CHAT_PANEL: {
      return {
        ...state,
        chatPanelOpen: !state.chatPanelOpen
      }
    }

    case CHANNEL.CHANGE_GROUP_UPDATED_AT:
      return {
        ...state,
        groups: {
          ...state.groups,
          data: state.groups.data.map(group => {
            if (group._id === action.payload.groupId)
              return { ...group, updatedAt: action.payload.updatedAt }
            return group
          })
        }
      }

    default:
      return state
  }
}

export default channelsReducer
