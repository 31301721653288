import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DogBarking from 'static/Images/dog-barking.png'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { fetchNewReleaseInfo } from 'thunks/user/actions'

const NewReleasePopupCard = () => {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const [newVersion, setNewVersion] = useState(null)

  useEffect(() => {
    let timerId = window.setInterval(() => {
      fetchNewReleaseInfo((res, err) => {
        if (!err) {
          const savedVersion = localStorage.getItem('app_version')

          if (
            savedVersion &&
            res.appVersion &&
            savedVersion !== res.appVersion
          ) {
            setNewVersion(res.appVersion)
            setIsVisible(true)
          }
        }
      })
    }, 1000 * 60 * 3) // 3 mins

    return () => {
      window.clearInterval(timerId)
    }
  }, [])

  const handleReload = () => {
    if (newVersion) {
      localStorage.setItem('app_version', newVersion)
    }
    window.location.reload(true)
  }

  if (!isVisible) return null

  return (
    <div
      className="w-60 fixed bottom-4 left-5 rounded-md p-4"
      style={{
        background: '#804ab5',
        zIndex: 9999,
        boxShadow: '0px 0px 30px -5px rgba(0, 0, 0, 0.2)'
      }}
    >
      <button
        className="absolute right-2 top-2 text-white"
        onClick={() => setIsVisible(false)}
      >
        <CloseIcon className="w-5 h-5" style={{ color: '#f1e6fb' }} />
      </button>

      <div className="flex justify-center">
        <img src={DogBarking} alt="" className="w-2/3" />
      </div>
      <div className="text-center">
        <h3 className="font-medium text-white text-sm capitalize mb-1">
          {t('NEW_RELEASE_HEADING')}
        </h3>
        <p
          className="text-white mb-4 text-smaller"
          style={{ color: '#f1e6fb' }}
        >
          {t('NEW_RELEASE_MESSAGE')}
        </p>

        <div>
          <button
            style={{ backgroundColor: '#e614e3' }}
            className="px-6 py-1.5 rounded-full text-smaller font-semibold shadow-sm text-white"
            onClick={handleReload}
          >
            {t('RELOAD')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default NewReleasePopupCard
