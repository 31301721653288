import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { createService, updateService } from 'thunks/service/actions'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Currency from 'static/Currency/Currency'
import { fileToDataURL } from 'utils'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { t } from 'i18next'
import { ReactComponent as FileUploadIcon } from 'static/svg/file-upload.svg'
// import { fetchAllWorkflowTemplates } from 'thunks/onboarding/actions'
import ServiceOfferings from './ServiceOfferings'
import ServiceInstructions from './ServiceInstructions'

const CreateMarketplaceServiceModal = ({
  open,
  onClose,
  data,
  serviceCategories
}) => {
  if (!open) return null

  return (
    <CustomModal size="large" open={open} handleClose={onClose}>
      <CustomModalHeader
        heading={data._id ? t('EDIT_SERVICE') : t('CREATE_A_NEW_SERVICE')}
        handleClose={onClose}
      />

      <ModelContent
        open={open}
        onClose={onClose}
        data={data}
        serviceCategories={serviceCategories}
      />
    </CustomModal>
  )
}

const ModelContent = ({ onClose, data, serviceCategories }) => {
  // const { fetched: workflowFetched } = useSelector(state => state.onboarding)
  const theme = useTheme()
  const thumbnailRef = useRef()
  const coverRef = useRef()
  const offeringsChildRef = useRef()
  const instructionsChildRef = useRef()
  const themeForMode = useSelector(state => state.generalAppState.theme)
  const dispatch = useDispatch()
  const [radioServiceType, setRadioServiceType] = useState(0)
  const [loading, setLoading] = useState(false)
  const [recurring, setRecurring] = useState({})
  const [serviceData, setServiceData] = useState({
    name: '',
    description: '',
    amount: 0,
    offerings: [],
    instructions: [],
    isRecurring: true
  })

  const [selectedCurrency, setSelectedCurrency] = useState({
    value: Currency['USD'].code,
    label: Currency['USD'].code,
    minimumChargeAmount: Currency['USD'].minimum_charge_amount ?? 1
  })
  const [categoryOptions, setCategoryOptions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState({})
  const [selectedServiceImage, setSelectedServiceImage] = useState({
    cover: {
      name: 'Add cover image',
      url: null
    },
    thumbnail: {
      name: 'Add thumbnail',
      url: null
    }
  })
  // const [attachedFileName, setAttachedFileName] = useState({
  //   cover: '',
  //   thumbnail: ''
  // })

  const intervalPeriods = [
    {
      label: t('MONTHLY'),
      value: { interval: 'month', interval_count: 1 }
    },
    {
      label: t('QUARTERLY'),
      value: { interval: 'month', interval_count: 3 }
    },
    {
      label: t('SEMI_ANNUALLY'),
      value: { interval: 'month', interval_count: 6 }
    },
    {
      label: t('YEARLY'),
      value: { interval: 'year', interval_count: 1 }
    }
  ]

  useEffect(() => {
    if (data._id) {
      setServiceData({
        name: data.name,
        description: data.description,
        amount: data.amount,
        currency: data.currency || 'USD',
        offerings: data.offerings,
        isRecurring: data.isRecurring,
        instructions: data.instructions || []
      })

      setSelectedServiceImage(prev => ({
        cover: {
          name: data.image.cover ? '1 cover image attached' : prev.cover.name,
          url: data.image.cover || null
        },
        thumbnail: {
          name: data.image.thumbnail
            ? '1 thumbnail image attached'
            : prev.cover.name,
          url: data.image.thumbnail || null
        }
      }))

      setSelectedCurrency({
        value: Currency[data.currency].code,
        label: Currency[data.currency].code,
        minimumChargeAmount: Currency[data.currency].minimum_charge_amount ?? 1
      })

      if (data.isRecurring) {
        setRadioServiceType(0)
        const match = intervalPeriods.find(
          item =>
            item.value.interval === data.recurring.interval &&
            item.value.interval_count === data.recurring.interval_count
        )

        setRecurring({
          label: match?.label,
          value: data.recurring
        })
      } else {
        setRadioServiceType(1)
      }
    }
  }, [data])

  // useEffect(() => {
  //   if (!workflowFetched) dispatch(fetchAllWorkflowTemplates())
  // }, [workflowFetched])

  useEffect(() => {
    if (serviceCategories.length) {
      setCategoryOptions(
        serviceCategories.map(item => ({ label: item.name, value: item._id }))
      )
    }
  }, [serviceCategories])

  useEffect(() => {
    if (categoryOptions.length && data._id) {
      const category =
        categoryOptions.find(item => item.value === data.category) || {}
      setSelectedCategory(category)
    }
  }, [data, categoryOptions])

  const handleRadioChange = event => {
    const value = parseInt(event.target.value)
    setRadioServiceType(value)
    setServiceData(prev => ({
      ...prev,
      isRecurring: value === 0
    }))
  }

  const imageLoadHandler = e => {
    const file = e.target.files[0]

    if (file) {
      fileToDataURL(file).then(res => {
        setSelectedServiceImage(prev => ({
          ...prev,
          [e.target.name]: {
            name: file.name,
            url: res
          }
        }))
        // setServiceData(prev => ({
        //   ...prev,
        //   image: {
        //     ...prev.image,
        //     [e.target.name]: res
        //   }
        // }))
      })
    }
  }

  const handlePlanChange = e => {
    setRecurring(e)
  }

  const handleChange = e => {
    if (e.target.name === 'amount') {
      setServiceData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }))
    } else {
      setServiceData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
  }

  const handleCurrencyChange = newVal => {
    setSelectedCurrency(newVal)
  }

  const addNewServiceHandler = () => {
    if (!serviceData.name || !serviceData.description) {
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS'))
      )
    }

    // if (serviceData.amount < selectedCurrency.minimumChargeAmount) {
    //   return dispatch(
    //     fireErrorToaster(
    //       t('ERROR_AMOUNT_CAN_NOT_BE_LESS_THAN', {
    //         data1: selectedCurrency.minimumChargeAmount,
    //         data2: Currency[selectedCurrency.value].symbol_native
    //       })
    //     )
    //   )
    // }

    if (serviceData.isRecurring && !recurring.value) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_SELECT_INTERVAL')))
    }

    setLoading(true)
    let filteredServiceData = {
      ...serviceData,
      image: {
        thumbnail: selectedServiceImage.thumbnail.url,
        cover: selectedServiceImage.cover.url
      },
      currency: selectedCurrency.value,
      type: 'service',
      category: selectedCategory.value,
      offerings: offeringsChildRef.current.getOfferingsList(),
      instructions: instructionsChildRef.current.getInstructions()
    }

    if (filteredServiceData.isRecurring) {
      filteredServiceData.recurring = recurring.value
    }

    dispatch(
      createService({ data: filteredServiceData }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('SERVICE_CREATED_SUCCESSFULLY')))
          onClose()
        }

        setLoading(false)
      })
    )
  }

  console.log({
    prevCategory: data.category,
    newCategory: selectedCategory.value
  })

  const updateServiceHandler = () => {
    if (!serviceData.name || !serviceData.description) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    if (serviceData.amount < 1) {
      dispatch(fireErrorToaster(t('ERROR_AMOUNT_CAN_NOT_BE_LESS_THAN_UNIT')))
      return
    }

    setLoading(true)

    const instructions = instructionsChildRef.current.getInstructions()

    let updatedData = {
      offerings: offeringsChildRef.current.getOfferingsList(),
      field: 'update' //notification related
    }

    if (data.name !== serviceData.name) {
      updatedData.name = serviceData.name
    }

    if (data.description !== serviceData.description) {
      updatedData.description = serviceData.description
    }

    if (data.category !== selectedCategory.value) {
      updatedData.category = selectedCategory.value
    }

    if (
      selectedServiceImage.cover.url !== data.image.cover ||
      selectedServiceImage.thumbnail.url !== data.image.thumbnail
    ) {
      updatedData.removeImage = {
        cover: data.image.cover || null,
        thumbnail: data.image.thumbnail || null
      }

      updatedData.newImage = {
        cover: selectedServiceImage.cover.url,
        thumbnail: selectedServiceImage.thumbnail.url
      }
    }

    if (data.amount !== serviceData.amount) {
      updatedData.amount = serviceData.amount
    }

    if (data.instructions !== instructions) {
      updatedData.instructions = instructions
    }

    dispatch(
      updateService(
        { id: data._id, data: updatedData },
        handleUpdateServiceCallback
      )
    )
  }

  const handleUpdateServiceCallback = (res, err) => {
    setLoading(false)
    onClose()
    if (!err) {
      dispatch(fireSuccessToaster(t('SERVICE_CREATED_SUCCESSFULLY')))
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleCategoryChange = option => {
    setSelectedCategory(option)
  }

  return (
    <>
      <CustomModalBody>
        <div className="mb-4">
          <FormLabel htmlFor="name" required>
            {t('NAME')}
          </FormLabel>

          <TextField
            name="name"
            value={serviceData.name}
            onChange={handleChange}
            className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
            placeholder={t('ENTER_SERVICE_NAME')}
            id="name"
          />
        </div>

        <div className="mb-4">
          <FormLabel required htmlFor="description">
            {t('DESCRIPTION')}
          </FormLabel>
          <TextField
            name="description"
            value={serviceData.description}
            onChange={handleChange}
            className="w-full border border-custom-gray-main p-2 rounded-md outline-none"
            placeholder={t('ENTER_DESCRIPTION')}
            id="description"
            multiline={true}
            rows={6}
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('UPLOAD')}</FormLabel>

          <div
            role="button"
            aria-label="Upload thumbnail"
            onClick={() => thumbnailRef.current.click()}
            className="flex justify-center items-center cursor-pointer border-2 border-dashed text-primary-main p-3 rounded mb-2"
          >
            <FileUploadIcon />
            <span className="text-smaller ml-1">
              {selectedServiceImage.thumbnail.name}
            </span>
          </div>

          <div
            role="button"
            aria-label="Upload cover image"
            onClick={() => coverRef.current.click()}
            className="flex justify-center items-center cursor-pointer border-2 border-dashed text-primary-main p-3 rounded"
          >
            <FileUploadIcon />
            <span className="text-smaller ml-1">
              {selectedServiceImage.cover.name}
            </span>
          </div>

          <input
            ref={thumbnailRef}
            name="thumbnail"
            type="file"
            className="hidden"
            onChange={imageLoadHandler}
            accept="image/*"
          />
          <input
            onChange={imageLoadHandler}
            ref={coverRef}
            name="cover"
            type="file"
            className="hidden"
            accept="image/*"
          />
        </div>
        <div className="mb-4">
          <FormLabel>{t('SELECT_CATEGORY')}</FormLabel>

          <Select
            styles={reactSelectCustomStyles(theme)}
            options={categoryOptions}
            onChange={handleCategoryChange}
            value={selectedCategory}
            maxMenuHeight={120}
            placeholder={t('SELECT_CATEGORY')}
          />
        </div>

        <div className="mb-4">
          <FormLabel required>{t('SERVICE_TYPE')}</FormLabel>

          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={radioServiceType}
            onChange={handleRadioChange}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value={0}
              control={
                <Radio
                  color={themeForMode.mode === 'light' ? 'primary' : 'default'}
                  disabled={Boolean(data._id)}
                  // disabled={serviceData.serviceType !== 'stripe' || data}
                />
              }
              label={
                <p className="text-smaller 2xl:text-sm dark:text-dark-light">
                  {t('RECURRING')}
                </p>
              }
            />
            <FormControlLabel
              value={1}
              control={
                <Radio
                  disabled={Boolean(data._id)}
                  color={themeForMode.mode === 'light' ? 'primary' : 'default'}
                />
              }
              label={
                <p className="text-smaller 2xl:text-sm dark:text-dark-light">
                  {t('ONE_TIME')}
                </p>
              }
            />
          </RadioGroup>

          {radioServiceType === 0 ? (
            <div className="mt-3">
              <FormLabel htmlFor="offerings" required>
                {t('ADD_INTERVAL')}
              </FormLabel>

              <Select
                menuPosition={'fixed'}
                value={recurring}
                isSearchable={false}
                onChange={handlePlanChange}
                placeholder={t('BILLING_PERIOD')}
                options={intervalPeriods}
                styles={reactSelectCustomStyles(theme)}
                isDisabled={Boolean(data._id)} //data true means update service modal
              />
            </div>
          ) : null}
        </div>

        <div className="mb-4">
          <div className="flex flex-row mt-5 gap-3">
            <SelectCurrency
              selectedCurrency={selectedCurrency}
              handleCurrencyChange={handleCurrencyChange}
            />
            <div>
              <FormLabel htmlFor="amount">{t('AMOUNT')}</FormLabel>
              <TextField
                name="amount"
                value={serviceData.amount}
                onChange={handleChange}
                type="number"
                className="hover:border-blue-500 border border-custom-gray-main p-2 rounded-md outline-none w-24"
                placeholder={t('AMOUNT')}
                id="amount"
              />
            </div>
          </div>
        </div>

        <div className="mb-4">
          <ServiceOfferings
            ref={offeringsChildRef}
            offerings={serviceData.offerings}
          />
        </div>

        <div className="mb-4">
          <ServiceInstructions
            ref={instructionsChildRef}
            instructions={serviceData.instructions}
          />
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          onClick={data._id ? updateServiceHandler : addNewServiceHandler}
          loading={loading}
        >
          {data._id ? t('SAVE_CHANGES') : t('CREATE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

const SelectCurrency = ({ handleCurrencyChange, selectedCurrency }) => {
  const theme = useTheme()
  const [currencyOptions, setCurrencyOptions] = useState([])

  useEffect(() => {
    let arr = Object.keys(Currency).map(el => ({
      value: el.toLowerCase(),
      label: el,
      minimumChargeAmount: Currency[el]?.minimum_charge_amount ?? 1
    }))
    setCurrencyOptions(arr)
  }, [])

  return (
    <>
      <div>
        <FormLabel htmlFor="currency">{t('CURRENCY')}</FormLabel>
        <div className="w-28">
          <Select
            styles={reactSelectCustomStyles(theme)}
            options={currencyOptions}
            onChange={handleCurrencyChange}
            value={selectedCurrency}
            maxMenuHeight={120}
            menuPlacement="top"
            isDisabled
          />
        </div>
      </div>
    </>
  )
}

export default CreateMarketplaceServiceModal
