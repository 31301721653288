import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Popover
} from '@material-ui/core'
import { ColoredAvatars } from 'global/globalComponents'
import taskSidePanelStyles from './taskSidePanelStyles'
import { useTranslation } from 'react-i18next'
import { userRoles } from 'utils'
import Pill from 'global/globalComponents/Pill/Pill'

const CollabDrowdown = ({ open, anchorEl, onClose, style = {}, ...rest }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      style={{ ...style }}
    >
      <CollabDropdownContent {...rest} />
    </Popover>
  )
}

const CollabDropdownContent = ({ list, handleListItemClick, getUser }) => {
  const { t } = useTranslation()
  const classes = taskSidePanelStyles()
  const [filteredList, setFilteredList] = useState(list)
  const [page, setPage] = useState(1)

  const handleSearchUser = searchVal => {
    setPage(1)
    if (searchVal === '') setFilteredList(list)
    else {
      setFilteredList(
        list.filter(listItem => {
          if (searchVal !== '') {
            const userName = getUser
              ? getUser(listItem).name.toLocaleLowerCase()
              : listItem.name.toLocaleLowerCase()
            return userName.includes(searchVal.toLocaleLowerCase())
          }
          return true
        })
      )
    }
  }

  const handleScroll = e => {
    const target = e.currentTarget
    if (target.offsetHeight + target.scrollTop + 10 >= target.scrollHeight) {
      setPage(prev => prev + 1)
    }
  }

  let itemsToShow = page * 15

  return (
    <div className="p-2 dark:bg-dark-main2">
      <SearchInput handleSearchUser={handleSearchUser} />

      <div
        style={{ maxHeight: 330 }}
        className="overflow-auto"
        onScroll={handleScroll}
      >
        <List dense style={{ padding: 0 }}>
          {filteredList.length > 0 ? (
            filteredList
              .slice(0, itemsToShow)
              .map((item, index) => (
                <SingleListItem
                  key={index}
                  user={getUser ? getUser(item) : item}
                  handleListItemClick={() => handleListItemClick(item)}
                  classes={classes}
                  t={t}
                />
              ))
          ) : (
            <ListItem>
              <ListItemText className="dark:text-dark-light">
                {t('NO_DATA_FOUND')}
              </ListItemText>
            </ListItem>
          )}
        </List>
      </div>
    </div>
  )
}

const SingleListItem = ({ user, handleListItemClick, classes, t }) => {
  return (
    <ListItem
      className="dark:hover:bg-dark-main dark:text-dark-light"
      button
      onClick={handleListItemClick}
    >
      <ListItemAvatar className={classes.avatar + ' mr-2'}>
        <ColoredAvatars user={user} tooltip={false} size="small" />
      </ListItemAvatar>
      <ListItemText className="text-sm">
        <span className="inline-block pr-4">{user.name}</span>

        {user.role >= userRoles.USER_CLIENT && (
          <Pill variant="purple">{t('CLIENT')}</Pill>
        )}
      </ListItemText>
    </ListItem>
  )
}

const SearchInput = ({ handleSearchUser }) => {
  const [search, setSearch] = useState('')

  useEffect(() => {
    let timerId = setTimeout(() => {
      handleSearchUser(search)
    }, 300)

    return () => clearTimeout(timerId)
  }, [search])

  return (
    <input
      className="w-full border px-2 py-1 text-smaller rounded mb-2 dark:bg-dark-main  dark:text-dark-light"
      placeholder="Search..."
      value={search}
      onChange={e => setSearch(e.target.value)}
      autoFocus
    />
  )
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
}

export default React.memo(CollabDrowdown, areEqual)
