import React from 'react'
import { Tooltip } from '@material-ui/core'
import sidebarStyles from './sidebarStyles'

const SidebarCustomTooltip = ({ title, placement, ...rest }) => {
  const classes = sidebarStyles()
  return (
    <Tooltip
      title={title}
      placement={placement || 'right'}
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      {...rest}
    />
  )
}

export default SidebarCustomTooltip
