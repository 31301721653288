import React from 'react'
import { Popper } from '@material-ui/core'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import { useTranslation } from 'react-i18next'

const OfferingsPopper = ({ anchorEl, offerings = [] }) => {
  const { t } = useTranslation()

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="right"
      disablePortal={false}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window'
        }
      }}
    >
      <div className="bg-white p-4 border shadow rounded dark:bg-dark-main2 dark:text-dark-light">
        <div className="text-sm font-semibold uppercase mb-2 tracking-wide">
          {t('OFFERINGS')}
        </div>
        {offerings.map(item => (
          <div className="text-sm flex items-center mb-2">
            <CheckCircle className="flex-shrink-0 w-6 h-6 text-green-600 mr-2" />
            <span className="w-48">{item}</span>
          </div>
        ))}
      </div>
    </Popper>
  )
}

export default OfferingsPopper
