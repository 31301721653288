import React from 'react'
import {
  makeStyles,
  Popper,
  // ClickAwayListener,
  Backdrop
} from '@material-ui/core'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUserPath } from 'utils/userRoles'
import { useTranslation } from 'react-i18next'
import { ColoredAvatars } from 'global/globalComponents'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as CheckCircleFilledIcon } from 'static/svg/check-circle-filled.svg'
import { Skeleton } from '@material-ui/lab'

const SearchSuggestionPopup = ({ anchorEl, onClose, ...rest }) => {
  const classes = useStyles()

  return (
    <>
      <Backdrop
        open={Boolean(anchorEl)}
        onClick={onClose}
        style={{ zIndex: 9999, background: 'transparent' }}
      >
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-start"
          disablePortal={false}
          className={classes.popper}
          style={{ zIndex: 9999 }}
        >
          <SearchSuggestionPopupContent classes={classes} {...rest} />
        </Popper>
      </Backdrop>
    </>
  )
}

const SearchSuggestionPopupContent = ({
  classes,
  keywords,
  teammates,
  projects,
  tasks
}) => {
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const userPath = getUserPath(meData.role)

  return (
    <div
      className={clsx(
        'bg-white dark:bg-dark-main dark:text-dark-light rounded-lg shadow-lg border mt-6',
        classes.menuPaper
      )}
    >
      <header className="flex justify-between items-center p-4 border-b">
        <h3 className="text-sm dark:text-white flex items-center">
          <SearchIcon />
          <span className="ml-2">
            {keywords === '' ? 'Recent' : t('SEARCH_RESULT')}
          </span>
          <span className="font-semibold ml-2">{keywords}</span>
        </h3>
      </header>

      <div className="overflow-auto py-4 space-y-4">
        {(teammates.loading || Boolean(teammates.data.length)) && (
          <TeammatesResults
            teammates={teammates.data}
            isLoading={teammates.loading}
            userPath={userPath}
          />
        )}
        {(projects.loading || Boolean(projects.data.length)) && (
          <ProjectsResults
            projects={projects.data}
            isLoading={projects.loading}
            userPath={userPath}
          />
        )}

        {(tasks.loading || Boolean(tasks.data.length)) && (
          <TasksResults
            tasks={tasks.data}
            isLoading={tasks.loading}
            userPath={userPath}
          />
        )}
      </div>
    </div>
  )
}

const TeammatesResults = ({ isLoading, teammates, userPath }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleRedirect = userId => {
    history.push(`${userPath}/teammates/all/profile/${userId}`)
  }

  return (
    <div>
      <h5 className="text-xs mb-1 px-4 dark:text-white">{t('TEAM_MEMBERS')}</h5>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        teammates.map(item => (
          <div
            key={item._id}
            className="flex items-center text-smaller py-1 mb-1 px-4 hover:bg-gray-100 dark:hover:bg-dark-main2 cursor-pointer"
            onClick={() => handleRedirect(item._id)}
          >
            <ColoredAvatars user={item} tooltip={false} />
            <span className="text-smaller ml-2">{item.name}</span>
          </div>
        ))
      )}
    </div>
  )
}

const ProjectsResults = ({ isLoading, projects, userPath }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleRedirect = workspace => {
    history.push(
      `${userPath}/workspace/${workspace._id}/board/${workspace.defaultBoard._id}?view=board`
    )
  }

  return (
    <div>
      <h5 className="text-xs mb-1 px-4 dark:text-white">{t('PROJECTS')}</h5>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        projects.map(item => (
          <div
            key={item._id}
            className="flex items-center text-smaller py-2 px-4 hover:bg-gray-100 dark:hover:bg-dark-main2 cursor-pointer"
            onClick={() => handleRedirect(item)}
          >
            <span
              className="inline-block w-3 h-3 rounded-sm border"
              style={{ backgroundColor: item.backgroundcolor }}
            ></span>
            {/* <SearchIcon className="text-primary-main" /> */}
            <span className="ml-2 break-long-word">{item.name}</span>
          </div>
        ))
      )}
    </div>
  )
}

const TasksResults = ({ isLoading, tasks, userPath }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleRedirect = task => {
    history.push(
      `${userPath}/workspace/${task.workSpace}/board/${task.workspaceBoard}/task/${task._id}?view=board`
    )
  }

  return (
    <div>
      <h5 className="text-xs mb-1 px-4 dark:text-white">{t('TASKS')}</h5>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        tasks.map(item => (
          <div
            key={item._id}
            className="flex items-center text-smaller py-2 px-4 hover:bg-gray-100 dark:hover:bg-dark-main2 cursor-pointer"
            onClick={() => handleRedirect(item)}
          >
            {item.markAsComplete ? (
              <CheckCircleFilledIcon className="text-green-500 w-4 h-4" />
            ) : (
              <CheckCircleIcon className="text-gray-500 w-4 h-4" />
            )}
            <span className="ml-2 break-long-word">{item.title}</span>
          </div>
        ))
      )}
    </div>
  )
}

const LoadingAnimation = () => {
  return (
    <div className="px-4">
      {new Array(3).fill('').map((item, index) => (
        <Skeleton key={index} variant="text" height={30} />
      ))}
    </div>
  )
}

const useStyles = makeStyles({
  popper: {
    zIndex: 9999
  },
  menuPaper: {
    width: 420
  },
  mentionDescription: {
    minHeight: 30,
    maxHeight: 120,
    overflow: 'auto'
  }
})

export default SearchSuggestionPopup
