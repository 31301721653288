export const SUBSCRIPTIONS = {
  FETCHED: 'SUBSCRIPTIONS_FETCHED',
  ADD_NEW_SUBSCRIPTION: 'SUBSCRIPTIONS_ADD_NEW_SUBSCRIPTION',
  DELETE_ONE_SUBSCRIPTION: 'DELETE_ONE_SUBSCRIPTION',
  UPDATE_ONE_SUBSCRIPTION: 'UPDATE_ONE_SUBSCRIPTION',
  RESET_DATA: 'SUBSCRIPTIONS_RESET_DATA'
}

const initialState = {
  error: false,
  loading: true,
  data: [],
  fetched: false
}

export const subscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONS.FETCHED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        fetched: true
      }

    case SUBSCRIPTIONS.ADD_NEW_SUBSCRIPTION:
      const idx = state.data.findIndex(item => item._id === action.payload._id)

      // checking if SUBSCRIPTION already exists bcoz we are getting data from pusher as well
      if (idx === -1) {
        return {
          ...state,
          loading: false,
          data: [action.payload, ...state.data]
        }
      } else {
        return state
      }

    case SUBSCRIPTIONS.UPDATE_ONE_SUBSCRIPTION:
      const ind = state.data.findIndex(item => item._id === action.payload._id)
      const newData = [...state.data]
      newData[ind] = action.payload
      return {
        ...state,
        // loading: { ...state.loading, all: true },
        data: [...newData]
      }
    case SUBSCRIPTIONS.DELETE_ONE_SUBSCRIPTION:
      return {
        ...state,
        // loading: false,
        data: state.data.filter(item => item._id !== action.payload)
      }

    case SUBSCRIPTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SUBSCRIPTIONS.UPLOAD_SUBSCRIPTION_ATTACHMENT:
      const index = state.data.findIndex(
        item => item._id === action.payload._id
      )
      const updatedData = [...state.data]
      updatedData[index].imageUrl = action.payload.imageUrl
      return {
        ...state,
        data: [...updatedData]
      }
    case SUBSCRIPTIONS.RESET_DATA: {
      return initialState
    }

    default:
      return state
  }
}
export default subscriptionsReducer
