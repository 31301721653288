import React, { useState, useEffect } from 'react'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { getFileType, getFileUrl } from 'utils'

const SingleFileField = ({ fieldData, canFillData, updateFieldProperty }) => {
  const handleAttachmentUpload = e => {
    const file = e.target.files[0]

    if (file) {
      updateFieldProperty({
        fieldId: fieldData.id,
        prop: 'value',
        value: file
      })
    }
    e.target.value = ''
  }

  return (
    <div>
      <div className="mb-4">
        <input
          type="file"
          accept="*"
          className="custom-input-file w-full border border-gray-300 rounded-full text-smaller h-11 text-gray-600"
          onChange={handleAttachmentUpload}
          disabled={!canFillData}
        />
      </div>
      {fieldData.value && (
        <FilePreview
          file={fieldData.value}
          handleRemoveFile={() =>
            updateFieldProperty({
              fieldId: fieldData.id,
              prop: 'value',
              value: ''
            })
          }
        />
      )}
    </div>
  )
}

export const FilePreview = ({ file, handleRemoveFile }) => {
  const [fileData, setFileData] = useState({
    url: '',
    type: ''
  })

  useEffect(() => {
    if (file instanceof File) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setFileData({
          type: file.type.split('/')[0],
          url: reader.result
        })
      }
      reader.readAsDataURL(file)
    } else {
      setFileData({
        url: typeof file === 'object' ? getFileUrl(file) : file,
        type: typeof file === 'object' ? getFileType(file.extension) : 'image'
      })
    }
  }, [file])

  return (
    <div className="relative w-40 h-24 object-cover border rounded-md overflow-hidden">
      <button
        type="button"
        className="absolute right-0 top-0 text-red-600 z-10"
        onClick={handleRemoveFile}
      >
        <HighlightOffIcon size="small" />
      </button>
      {fileData.type === 'image' ? (
        <img
          src={fileData.url}
          alt="attachment"
          className="outline-none rounded object-cover w-full h-full"
        />
      ) : fileData.type === 'video' ? (
        <video className="w-full h-full" controls>
          <source src={fileData.url} />
          Your browser does not support this video format.
        </video>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <FileIcon className="w-8 h-8 text-gray-600" />
        </div>
      )}
    </div>
  )
}

export default SingleFileField
