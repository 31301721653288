import axios from 'axios'

// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
export class Uploader {
  constructor(options) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15)
    this.file = options.file
    this.fileName = options.fileName.replace(/[%,></]/g, '') //removing not unsupported special characters from name
    this.aborted = false
    this.uploadedSize = 0
    this.progressCache = {}
    this.activeConnections = {}
    this.parts = []
    this.uploadedParts = []
    this.fileId = null
    this.fileKey = null
    this.module = options.module
    this.metaData = options.metaData
    this.onProgressFn = () => {}
    this.onErrorFn = () => {}
    this.onCompleteFn = () => {}
  }

  start() {
    this.initialize()
  }

  getMimeType(ext) {
    if (ext.toLowerCase() === 'psd') return 'application/x-photoshop'
    if (ext.toLowerCase() === 'numbers') return 'application/vnd.apple.numbers'
  }

  async initialize() {
    try {
      // adding the the file extension (if present) to fileName
      let fileName = this.fileName
      const ext = this.file.name.split('.').pop()
      if (ext) {
        fileName += `.${ext}`
      }

      // initializing the multipart request
      const initializationUploadInput = {
        key: fileName,
        mimeType: this.file.type || this.getMimeType(ext),
        module: this.module,
        metadata: {
          ...this.metaData
        }
      }
      const initializeResponse = await axios({
        // url: this.initialUrl,
        url: '/attachment/initializeMultipartUpload',
        method: 'POST',
        data: initializationUploadInput
      })

      const AWSFileDataOutput = initializeResponse.data

      this.fileId = AWSFileDataOutput.UploadId
      this.fileKey = AWSFileDataOutput.Key

      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize)

      const AWSMultipartFileDataInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: numberOfparts
      }

      const urlsResponse = await axios({
        // url: this.preSignedUrl,
        url: '/attachment/getMultipartPreSignedUrls',
        method: 'POST',
        data: AWSMultipartFileDataInput
      })

      const newParts = urlsResponse.data.parts
      this.parts.push(...newParts)

      this.sendNext()
    } catch (error) {
      await this.complete(error)
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length

    if (activeConnections >= this.threadsQuantity) {
      return
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete()
      }

      return
    }

    const part = this.parts.pop()
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext()
        })
        .catch(error => {
          this.parts.push(part)

          this.complete(error)
        })
    }
  }

  async complete(error) {
    if (error && !this.aborted) {
      this.onErrorFn(error)
      return
    }

    if (error) {
      this.onErrorFn(error)
      return
    }

    try {
      await this.sendCompleteRequest()
    } catch (error) {
      this.onErrorFn(error)
    }
  }

  async sendCompleteRequest() {
    const ext = this.file.name.split('.').slice(-1).join('')
    const arr = this.fileKey.split('/')
    const fileName =
      this.module === 'chat' ? arr[arr.length - 1] : this.fileName

    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
        name: fileName,
        mimeType: this.file.type || this.getMimeType(ext),
        extension: '.' + ext,
        size: this.file.size,
        module: this.module,
        metadata: {
          ...this.metaData
        }
      }

      const res = await axios({
        // url: this.finalUploadUrl,
        url: '/attachment/finalizeMultipartUpload',
        method: 'POST',
        data: videoFinalizationMultiPartInput
      })

      // not getting link in chat so generating it
      const data =
        this.module === 'chat'
          ? {
              ...res.data,
              link: `https://s3.wasabisys.com/assets.clientvenue.com/${this.fileKey}`
            }
          : res.data
      this.onCompleteFn(data)
    }
  }

  sendChunk(chunk, part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then(status => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'))
            return
          }

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  handleProgress(part, event) {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0)

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage
      })
    }
  }

  upload(file, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        const xhr = (this.activeConnections[
          part.PartNumber - 1
        ] = new XMLHttpRequest())

        sendChunkStarted()

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        )

        xhr.upload.addEventListener('progress', progressListener)

        xhr.addEventListener('error', progressListener)
        xhr.addEventListener('abort', progressListener)
        xhr.addEventListener('loadend', progressListener)

        xhr.open('PUT', part.signedUrl)

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader('ETag')

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                ETag: ETag.replaceAll('"', '')
              }

              this.uploadedParts.push(uploadedPart)

              resolve(xhr.status)
              delete this.activeConnections[part.PartNumber - 1]
            }
          }
        }

        xhr.onerror = error => {
          reject(error)
          delete this.activeConnections[part.PartNumber - 1]
        }

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'))
          delete this.activeConnections[part.PartNumber - 1]
        }

        xhr.send(file)
      }
    })
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress
    return this
  }

  onError(onError) {
    this.onErrorFn = onError
    return this
  }

  onComplete(onComplete) {
    this.onCompleteFn = onComplete
    return this
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach(id => {
        this.activeConnections[id].abort()
      })

    this.aborted = true
  }
}

export class DropboxUploader {
  // Private methods
  #onProgressFn
  #onErrorFn
  #onCompleteFn

  constructor(options) {
    this.file = options.file
    this.sessionId = null
    this.metadata = options.metadata
    this.#onProgressFn = () => {}
    this.#onErrorFn = () => {}
    this.#onCompleteFn = () => {}

    this.start()
  }

  start() {
    this.initialize()
  }

  async initialize() {
    try {
      const res = await axios({
        method: 'POST',
        url: '/connectedapps/file/upload?api=1&type=dropbox'
      })

      this.sessionId = res.data.sessionId

      this.upload()
    } catch (err) {
      this.#onErrorFn(err)
    }
  }

  upload() {
    const fileReader = new FileReader()
    const CHUNK_SIZE = 1024 * 100 // 100KB (dropbox limit)

    fileReader.onload = async e => {
      const chunksCount = Math.ceil(e.target.result.byteLength / CHUNK_SIZE)

      try {
        for (let i = 0; i < chunksCount; i++) {
          const chunk = e.target.result.slice(
            i * CHUNK_SIZE,
            (i + 1) * CHUNK_SIZE
          )

          await axios({
            url: '/connectedapps/file/upload?api=2&type=dropbox',
            method: 'POST',
            headers: {
              'Content-Type': 'application/octet-stream',
              filename: this.file.name,
              sessionid: this.sessionId,
              offset: i * CHUNK_SIZE
            },
            data: chunk
          })

          let uploadPercentage = (i * 100) / chunksCount
          this.#onProgressFn(uploadPercentage)
        }

        this.#onProgressFn(100) // temp fix
        this.sendCompleteRequest()
      } catch (err) {
        this.#onErrorFn(err)
      }
    }
    fileReader.readAsArrayBuffer(this.file)
  }

  async sendCompleteRequest() {
    try {
      const res = await axios({
        method: 'POST',
        url: '/connectedapps/file/upload?api=3&type=dropbox',
        data: {
          fileId: this.metadata.fileId,
          fileName: this.file.name,
          fileSize: this.file.size,
          sessionId: this.sessionId
        }
      })

      this.#onCompleteFn(res.data)
    } catch (err) {
      this.#onErrorFn(err)
    }
  }

  onProgress(onProgress) {
    this.#onProgressFn = onProgress
    return this
  }

  onError(onError) {
    this.#onErrorFn = onError
    return this
  }

  onComplete(onComplete) {
    this.#onCompleteFn = onComplete
    return this
  }
}
export default Uploader
