import { getElmType } from 'components/Notification/notificationStatic'
import {
  ColoredAvatarGroup,
  ColoredAvatars,
  CustomFlag
} from 'global/globalComponents'
import { adjustColor, getFileType } from 'utils'
import { ReactComponent as AudioFile } from 'static/svg/music-note.svg'
import { ReactComponent as VideoFile } from 'static/svg/film-reel.svg'
import { ReactComponent as NoteText } from 'static/svg/note-text.svg'
import { t } from 'i18next'
// import { useTheme } from '@material-ui/core'

export const getKeywords = () => ({
  create: t('CREATED_THIS_TASK'),
  title: t('CHANGED_TITLE'),
  description: t('UPDATED_DESCRIPTION'),
  attachment_add: t('UPLOADED_ATTACHMENT'),
  attachment_delete: t('REMOVED_ATTACHMENT'),
  priority: t('CHANGED_PRIORITY'),
  taskSection: t('CHANGED_STATUS'),
  startDate: t('CHANGED_START_DATE'),
  dueDate: t('CHANGED_DUE_DATE'),
  assignee_add: t('ADDED_NEW_COLLABORATOR'),
  assignee_delete: t('REMOVED'),
  markAsComplete: t('MARKED_THIS_TASK_AS_COMPLETE'),
  estimateTimeInString: t('SET_ESTIMATED_TIME'),
  isTaskRecurring: t('CHANGED_RECURRING_STATE_OF_THIS_TASK'),
  comment_like: t('LIKED_YOUR_COMMENT'),
  collaborators_add: t('ADDED_COLLABORATOR')
})

// export const keywords = {
//   create: 'created this task',
//   title: 'changed title',
//   description: 'updated description',
//   attachment_add: 'uploaded attachment',
//   attachment_delete: 'removed attachment',
//   priority: 'changed priority',
//   taskSection: 'changed status',
//   startDate: 'changed start date',
//   dueDate: 'changed due date',
//   assignee_add: 'added new collaborator',
//   assignee_delete: 'removed',
//   markAsComplete: 'marked this task as complete',
//   estimateTimeInString: 'set estimated time',
//   isTaskRecurring: 'changed recurring state of this task'
// }

export const getBeforeAfterElm = (elmType, value) => {
  if (elmType === 'string')
    return (
      <span
        className="text-smaller font-medium truncate"
        style={{ maxWidth: 100 }}
      >
        {!value ? 'None' : value}
      </span>
    )
  if (elmType === 'date')
    return (
      <span className="text-xs font-medium">
        {value
          ? new Date(value).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })
          : 'None'}
      </span>
    )
  if (elmType === 'avatar')
    return <ColoredAvatars user={value || {}} tooltip={true} size="medium" />

  if (elmType === 'avatarGroup') {
    if (!value) return null
    return (
      <ColoredAvatarGroup
        users={value.map(item => item.user)}
        tooltip={true}
        size="medium"
      />
    )
  }

  if (elmType === 'collaboratorsGroup') {
    if (!value) return null
    return (
      <ColoredAvatarGroup
        users={value.map(item => item)}
        tooltip={true}
        size="medium"
      />
    )
  }

  if (elmType === 'flag') {
    if (value == 5) return <span className="text-xs font-medium">None</span>
    return <CustomFlag priority={Number(value)} />
  }
  if (elmType === 'statusPill') {
    return (
      <div className="flex items-center">
        <span
          className="w-3 h-3 rounded-sm mr-1"
          style={{
            backgroundColor: adjustColor(value.color, -70)
          }}
        ></span>
        <span className="text-xs capitalize">{value.label}</span>
      </div>
    )
  }
  if (elmType === 'switch') {
    return <span className="text-xs font-medium">{value ? 'On' : 'Off'}</span>
  }

  if (elmType === 'attachment') {
    const fileType = getFileType(value)
    if (fileType === 'image')
      return <img src={value} alt="" className="w-8 h-8" />
    if (fileType === 'audio')
      return <AudioFile className="text-primary-main w-5 h-5" />
    if (fileType === 'video')
      return <VideoFile className="text-primary-main w-5 h-5" />
    return <NoteText className="text-primary-main w-5 h-5" />
  }
  return null
}

export { getElmType }
