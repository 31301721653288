import axios from 'axios'
import { catchAsync, catchAsyncDispatch } from 'utils'
import { SERVICES } from './reducers'

/**
 * Create a new service of the agency user
 * @param {Object} data service object data
 * @param {Function} callback Callback function
 */
export const createService = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { generalAppState } = getState()
    const res = await axios({
      method: 'POST',
      url: '/service',
      data: generalAppState.isMarketplaceDomain
        ? data.data
        : {
            ...data.data,
            amount: data.data.amount * 100
          },
      apiVersion: generalAppState.isMarketplaceDomain ? 2 : undefined
    })

    if (callback) callback(res.data)

    if (res.data.type === 'addons') {
      dispatch({ type: SERVICES.ADD_ONE_ADD_ON, payload: res.data })
    } else {
      dispatch({ type: SERVICES.ADD_ONE_SERVICE, payload: res.data })

      // ========= Updating addOns services field, if addons were selected ======
      if (data.data.addons) {
        const { userServices } = getState()

        data.data.addons.forEach(addOnId => {
          const addOnData = userServices.addOns.data.find(
            addOn => addOn._id === addOnId
          )

          if (addOnData) {
            dispatch({
              type: SERVICES.UPDATE_ONE_ADD_ON,
              payload: {
                ...addOnData,
                services: [...addOnData.services, res.data._id]
              }
            })
          }
        })
      }
    }
  }, callback)
}

/**
 * Fetch all service of a user
 * @param {Object|null} data
 * @param {Function} callback Callback function
 */
export const fetchAllServices = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { generalAppState } = getState()

    dispatch({ type: SERVICES.LOADING })

    const res = await axios({
      method: 'GET',
      url: '/service/user',
      apiVersion: generalAppState.isMarketplaceDomain ? 2 : undefined
    })

    const services = [],
      addOns = []

    res.data.forEach(item => {
      if (item.type === 'addons') {
        addOns.push(item)
      } else {
        services.push(item)
      }
    })

    if (callback) callback(services)
    dispatch({ type: SERVICES.FETCHED, payload: services })
    dispatch({ type: SERVICES.ADD_ONS_FETCHED, payload: addOns })
  }, callback)
}

/**
 * fetch all services by domain
 * @param {Object|null} data
 * @param {Function} callback callback function
 */
export const fetchAllServicesByDomain = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    dispatch({ type: SERVICES.LOADING })
    const res = await axios({
      method: 'GET',
      url: '/service/domain',
      isPublic: true
    })

    const services = [],
      addOns = []

    res.data.forEach(item => {
      if (item.type === 'addons') {
        addOns.push(item)
      } else {
        services.push(item)
      }
    })

    if (callback) callback(services)
    dispatch({ type: SERVICES.FETCHED, payload: services })
    dispatch({ type: SERVICES.ADD_ONS_FETCHED, payload: addOns })
  }, callback)
}

/**
 * fetch services by id
 * @param {String} data.id Service id
 * @param {Function} callback callback function
 */
export const fetchServiceById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/service/public/${data.id}`,
    isPublic: true
  })

  if (callback) callback(res.data)
})

/**
 * it will update one service.
 * @param {String} data.id service id
 * @param {Object} data.data description,offerings,displayOnCatalog,free trial,amount,url,isrecurring
 * @param {Function} callback  callback function
 */

export const updateService = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { generalAppState } = getState()

    const res = await axios({
      method: 'PUT',
      url: `/service/${data.id}`,
      data: data.data,
      apiVersion: generalAppState.isMarketplaceDomain ? 2 : undefined
    })

    if (callback) callback(res.data)

    if (res.data.type === 'addons') {
      dispatch({ type: SERVICES.UPDATE_ONE_ADD_ON, payload: res.data })
    } else {
      const { userServices } = getState()
      dispatch({ type: SERVICES.UPDATE_ONE_SERVICE, payload: res.data })

      if (data.data.addons) {
        data.data.addons.forEach(addOnId => {
          const addOnData = userServices.addOns.data.find(
            addOn => addOn._id === addOnId
          )

          if (addOnData) {
            dispatch({
              type: SERVICES.UPDATE_ONE_ADD_ON,
              payload: {
                ...addOnData,
                services: [...addOnData.services, res.data._id]
              }
            })
          }
        })
      }

      if (data.data.removeAddons) {
        data.data.removeAddons.forEach(addOnId => {
          const addOnData = userServices.addOns.data.find(
            addOn => addOn._id === addOnId
          )

          if (addOnData) {
            dispatch({
              type: SERVICES.UPDATE_ONE_ADD_ON,
              payload: {
                ...addOnData,
                services: addOnData.services.filter(
                  serviceId => serviceId !== res.data._id
                )
              }
            })
          }
        })
      }
    }
  }, callback)
}

/**
 * it will delete one service.
 * @param {String} data.id service id
 * @param {Function} callback  callback function
 */
export const deleteService = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { generalAppState } = getState()

    const res = await axios({
      method: 'DELETE',
      url: `/service/${data.id}`,
      apiVersion: generalAppState.isMarketplaceDomain ? 2 : undefined
    })

    if (callback) callback(res.data)

    if (res.data.type === 'addons') {
      dispatch({ type: SERVICES.DELETE_ADD_ON, payload: res.data._id })
    } else {
      dispatch({ type: SERVICES.DELETE_ONE_SERVICE, payload: res.data._id })
    }
  }, callback)
}

/**
 * Create Service Category
 * @param {Object} data.data title, description, type, image(optional)
 * @param {Number | undefined} data.apiVersion
 * @param {Function} callback
 */
export const createServiceCategory = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/category',
      method: 'POST',
      data: data.data
    })

    dispatch({ type: SERVICES.ADD_NEW_SERVICE_CATEGORY, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch Service categories
 * @param {String} data.type
 * @param {Function} callback
 */
export const fetchServiceCategories = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/category/category?type=${data.type}`,
      method: 'GET'
    })

    dispatch({ type: SERVICES.FETCHED_CATEGORIES, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * fetch Service categories for public pages
 * @param {String} data.type
 * @param {Function} callback
 */
export const fetchServiceCategoriesPublic = catchAsync(async callback => {
  const res = await axios({
    url: `/category/public?type=service`,
    method: 'GET',
    isPublic: true
  })

  if (callback) callback(res.data)
})

/**
 * Update Service category
 * @param {String} data.id category id
 * @param {Object} data.data title, description, image(optional)
 * @param {Function} callback
 */
export const updateServiceCategory = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/category/${data.id}`,
      method: 'PUT',
      data: data.data
    })

    dispatch({ type: SERVICES.UPDATE_SERVICE_CATEGORY, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete Service category
 * @param {String} data.id category id
 * @param {Function} callback
 */
export const deleteServiceCategory = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/category/${data.id}`,
      method: 'DELETE'
    })

    dispatch({ type: SERVICES.DELETE_SERVICE_CATEGORY, payload: data.id })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Creates service template
 * @param {Object} data.body
 * @param {Function} callback
 */
export const createServiceTemplate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/configuration',
      method: 'POST',
      data: data.body
    })

    dispatch({ type: SERVICES.ADD_NEW_SERVICE_TEMPLATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetches all the service templates created by user
 * @param {Function} callback
 */
export const fetchAllUserServiceTemplates = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/configuration/user?type=serviceTemplate',
      method: 'GET'
    })

    dispatch({ type: SERVICES.FETCHED_SERVICE_TEMPLATES, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Updates service template by id
 * @param {String} data.id template id
 * @param {Object} data.body update body
 * @param {Function} callback
 */
export const updateServiceTemplateById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/configuration/${data.id}`,
      method: 'PUT',
      data: data.body
    })

    dispatch({ type: SERVICES.UPDATE_SERVICE_TEMPLATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Deletes service template by id
 * @param {String} data.id template id
 * @param {Function} callback
 */
export const deleteServiceTemplateById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/configuration/${data.id}`,
      method: 'DELETE'
    })

    dispatch({ type: SERVICES.DELETE_SERVICE_TEMPLATE, payload: data.id })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Changes service template image
 * @param {Object} data image, fileName, removeImage
 * @param {Function} callback
 */
export const updateServiceTemplateImage = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/configuration/attachment/${data.id}`,
      data: data.body
    })

    // dispatch({ type: SERVICES.DELETE_SERVICE_TEMPLATE, payload: data.id })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetches active service template
 * @param {Function} callback
 */
export const fetchActiveServiceTemplate = catchAsync(async callback => {
  const res = await axios({
    url: '/configuration/servicetemplate/active',
    method: 'GET',
    isPublic: true
  })

  if (callback) callback(res.data)
})
