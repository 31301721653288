import React from 'react'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import Currency from 'static/Currency/Currency'
import { userRoles } from 'utils'
import { useTranslation } from 'react-i18next'

const InvoiceNotificationMessage = ({ field, source, before, after, user }) => {
  const { t } = useTranslation()
  const currency = Currency[source.currency]?.symbol_native

  const generateMessage = () => {
    if (field === 'create') {
      if (user) {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_INVOICE_GENERATED_BY_USER', {
                invoiceNumber:
                  source.customInvoiceNumber || source.invoiceNumber,
                clientName: source.client?.clientName,
                currency,
                totalAmount: source.totalAmount
              })
            }}
          ></span>
        )
      } else {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_INVOICE_GENERATED_BY_BOT', {
                invoiceNumber:
                  source.customInvoiceNumber || source.invoiceNumber,
                clientName: source.client?.clientName,
                currency,
                totalAmount: source.totalAmount
              })
            }}
          ></span>
        )
      }
    }

    if (field === 'update') {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: t('NOTIFICATION_INVOICE_PROPERTIES_UPDATE', {
              invoiceNumber: source.customInvoiceNumber || source.invoiceNumber
            })
          }}
        ></span>
      )
    }

    if (field === 'status') {
      return (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_INVOICE_STATUS_CHANGE'),
              invoiceNumber: source.customInvoiceNumber || source.invoiceNumber
            }}
          ></span>
          :&nbsp;
          <Pill variant={pillVariantObj[before.status]}>
            {before.status?.toUpperCase()}
          </Pill>
          &nbsp;&#8594;&nbsp;
          <Pill variant={pillVariantObj[before.status]}>
            {after.status?.toUpperCase()}
          </Pill>
        </>
      )
    }

    if (field === 'paid') {
      if (user?.role === userRoles.USER_CLIENT) {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_INVOICE_PAID_BY_CLIENT', {
                invoiceNumber:
                  source.customInvoiceNumber || source.invoiceNumber,
                currency,
                totalAmount: source.totalAmount
              })
            }}
          ></span>
        )
      } else {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t('NOTIFICATION_INVOICE_PAID_BY_AGENCY', {
                invoiceNumber:
                  source.customInvoiceNumber || source.invoiceNumber,
                currency,
                totalAmount: source.totalAmount
              })
            }}
          ></span>
        )
      }
    }
  }

  return (
    <div>
      <div className="text-smaller">{generateMessage()}</div>
    </div>
  )
}

export default InvoiceNotificationMessage
