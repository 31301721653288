import React from 'react'
import { TextField, InputAdornment, useTheme } from '@material-ui/core'
import Select from 'react-select'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { useTranslation } from 'react-i18next'
import Currency from 'static/Currency/Currency'

const ThirdStepFields = ({
  classes,
  formData,
  handleUpdateFormData,
  selectedCurrency,
  handleUpdateCurrency
}) => {
  const { t } = useTranslation()
  const muiTheme = useTheme()
  const paymentOptions = [
    { label: t('TIME_SHEET_BASED'), value: 'timesheet' },
    { label: t('PROJECT_BASED'), value: 'project' }
  ]

  return (
    <>
      <div className="mb-5">
        <FormLabel>{t('BUDGETED_HOURS')}</FormLabel>
        <TextField
          className="w-full"
          type="number"
          value={formData.budgetedHours}
          onChange={e =>
            handleUpdateFormData({
              field: 'budgetedHours',
              value: e.target.value
            })
          }
          placeholder={t('ENTER_BUDGETED_HOURS')}
          name="budgetedHours"
        />
      </div>

      <div className="mb-5">
        <FormLabel>{t('ENTER_REVENUE')}</FormLabel>
        <div className="flex border-gray-300  dark:bg-dark-main4 border rounded pr-2 hover:border-primary-main">
          <input
            className="w-full pl-4 text-sm rounded dark:text-dark-light dark:bg-dark-main4"
            type="number"
            placeholder={t('ENTER_REVENUE')}
            onChange={e =>
              handleUpdateFormData({
                field: 'cost',
                value: e.target.value
              })
            }
            value={formData.cost}
            name="cost"
          />
          <Select
            value={selectedCurrency}
            onChange={handleUpdateCurrency}
            styles={{
              ...reactSelectCustomStyles(muiTheme, selectStyles),
              menuPortal: provided => ({
                ...provided,
                zIndex: 9999
              })
            }}
            options={Object.values(Currency)}
            getOptionLabel={option => option.code}
            getOptionValue={option => option.code}
            menuPosition="fixed"
            maxMenuHeight={120}
            className="w-28"
            size="small"
            name="currency"
          />
        </div>
      </div>

      <div className="mb-5">
        <FormLabel className=" whitespace-nowrap">{t('START_DATE')}</FormLabel>

        <div>
          <DatePicker
            wrapperClassName={classes.datePicker}
            onChange={date =>
              handleUpdateFormData({
                field: 'startDate',
                value: date
              })
            }
            selected={formData.startDate ? new Date(formData.startDate) : null}
            customInput={
              <TextField
                className="text-xs w-full"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <CalenderIcon className="text-base text-gray-600 cursor-pointer dark:text-dark-light" />
                    </InputAdornment>
                  )
                }}
              />
            }
            placeholderText={t('SELECT_DATE')}
            dateFormat="MMMM d, yyyy"
          />
        </div>
      </div>

      <div className="mb-5">
        <FormLabel className="whitespace-nowrap">{t('DUE_DATE')}</FormLabel>

        <div className="w-full">
          <DatePicker
            wrapperClassName={classes.datePicker}
            onChange={date =>
              handleUpdateFormData({
                field: 'dueDate',
                value: date
              })
            }
            selected={formData.dueDate ? new Date(formData.dueDate) : null}
            customInput={
              <TextField
                className="text-xs w-full"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <CalenderIcon className="text-base text-gray-600 cursor-pointer dark:text-dark-light" />
                    </InputAdornment>
                  )
                }}
              />
            }
            placeholderText={t('SELECT_DATE')}
            dateFormat="MMMM d, yyyy"
            minDate={formData.startDate ? new Date(formData.startDate) : null}
          />
        </div>
      </div>

      <div className="mb-12">
        <FormLabel className="whitespace-nowrap capitalize">
          {t('PAYMENT_TYPE')}
        </FormLabel>

        <Select
          value={paymentOptions.find(
            item => item.value === formData.paymentTerm
          )}
          onChange={newVal =>
            handleUpdateFormData({ field: 'paymentTerm', value: newVal.value })
          }
          styles={{
            ...reactSelectCustomStyles(muiTheme),
            menuPortal: provided => ({
              ...provided,
              zIndex: 9999
            })
          }}
          options={paymentOptions}
          className="w-full"
          menuPosition="fixed"
        />
      </div>
    </>
  )
}

const selectStyles = {
  control: {
    border: 'none',
    minHeight: '30px',
    '&:focus-within': {
      boxShadow: `0 0 0 1px transparent`
    }
  },
  singleValue: { fontSize: 12 },
  dropdownIndicator: { padding: 0, margin: 0 },
  option: {
    fontSize: '12px'
  }
}

export default ThirdStepFields
