import React from 'react'
import clsx from 'clsx'

const Loader = ({ className, fullScreen, ...rest }) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center',
        fullScreen ? 'w-screen h-screen' : 'w-full h-full',
        className
      )}
      {...rest}
    >
      <span className="app-loader"></span>
    </div>
  )
}

export default Loader
