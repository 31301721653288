import { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import Templates from './Templates'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import SingleTemplate from './SingleTemplate'
import CreateTemplateModal from './CreateTemplateModal'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { userRoles } from 'utils'

const TemplateModalRightView = ({
  linkData,
  templateData,
  handleSelectTemplate,
  updateDataHandler,
  selectedCategory,
  openDeleteTemplateModal,
  openCreateUpdateTemplateModal
}) => {
  const [userPrivateTemplate, setUserPrivateTemplate] = useState([])
  const [selectedCategoryTemplates, setSelectedCategoryTemplates] = useState([])
  const [pageState, setPageState] = useState()
  const [singleTemplateData, setSingleTemplateData] = useState({})
  const [open, setOpen] = useState(false)
  const matches = useMediaQuery('(min-width:768px)')
  const me = useSelector(state => state.me.data)

  useEffect(() => {
    setUserPrivateTemplate(
      templateData?.filter(temp => temp.type === 'private')
    )
  }, [templateData])

  useEffect(() => {
    if (selectedCategory !== 'all') {
      setSelectedCategoryTemplates(
        templateData?.filter(
          temp =>
            temp.category?.toLowerCase() === selectedCategory?.toLowerCase()
        )
      )
    } else {
      setSelectedCategoryTemplates(templateData)
    }
  }, [selectedCategory, templateData])

  useEffect(() => {
    if (singleTemplateData._id) {
      setSingleTemplateData(prev =>
        templateData.find(el => el._id === prev._id)
      )
    }
  }, [templateData])

  useEffect(() => {
    setPageState(0)
  }, [linkData])
  const { t } = useTranslation()

  return (
    <>
      {linkData !== 1 ? (
        pageState === 0 ? (
          <div>
            <Templates
              templatesData={selectedCategoryTemplates}
              setPageState={setPageState}
              linkData={linkData}
              setSingleTemplateData={setSingleTemplateData}
              updateDataHandler={updateDataHandler}
              openDeleteTemplateModal={openDeleteTemplateModal}
              openCreateUpdateTemplateModal={openCreateUpdateTemplateModal}
            />
          </div>
        ) : (
          <SingleTemplate
            handleBack={() => setPageState(0)}
            data={singleTemplateData}
            handleSelectTemplate={handleSelectTemplate}
            updateDataHandler={updateDataHandler}
          />
        )
      ) : pageState === 0 ? (
        <div>
          {me.role < userRoles.USER_CLIENT && (
            <div
              role="button"
              aria-label="Add new template"
              onClick={() => setOpen(true)}
              className="cursor-pointer  border-2 rounded-xl flex justify-center items-center mb-8 bg-gray-100 border-dashed border-gray-400 hover:shadow-md hover:bg-gray-200 dark:bg-dark-main"
              style={{ width: matches ? '280px' : '100%', height: '150px' }}
            >
              <div
                className="flex justify-center flex-col items-center text-center text-gray-600 dark:text-dark-light"
                onClick={() => setOpen(true)}
              >
                <div className="">
                  <AddIcon className="w-7 h-7 dark:text-dark-light" />
                </div>
                <span className="text-smaller 2xl:text-sm dark:text-dark-light">
                  {t('NEW_TEMPLATE')}
                </span>
              </div>
            </div>
          )}
          <Templates
            templatesData={userPrivateTemplate}
            setPageState={setPageState}
            linkData={linkData}
            setSingleTemplateData={setSingleTemplateData}
            openDeleteTemplateModal={openDeleteTemplateModal}
            updateDataHandler={updateDataHandler}
            openCreateUpdateTemplateModal={openCreateUpdateTemplateModal}
          />
        </div>
      ) : (
        <SingleTemplate
          handleBack={() => setPageState(0)}
          data={singleTemplateData}
          handleSelectTemplate={handleSelectTemplate}
          updateDataHandler={updateDataHandler}
        />
      )}
      <CreateTemplateModal
        open={open}
        handleClose={() => setOpen(false)}
        updateDataHandler={updateDataHandler}
      />
    </>
  )
}

export default TemplateModalRightView
