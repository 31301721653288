import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { startTaskTimer, stopTaskTimer } from 'thunks/Tracking/action'
// import { DASHBOARD } from 'thunks/userDashboard/reducer'
import { toggleTimerPosition } from 'thunks/Tracking/action'
import { getErrorMessages } from 'utils/errorMessages'

const useTimerFunctions = () => {
  const dispatch = useDispatch()
  const errorMessages = getErrorMessages()

  const startTimer = useCallback((data, taskTitle, cb) => {
    dispatch(
      startTaskTimer(
        { ...data, category: 'automatic' },
        taskTitle,
        (res, err) => {
          if (cb) cb(res, err)
          if (err) {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }, [])

  const stopTimer = useCallback((data, taskTitle, cb) => {
    // data for task => {task: taskId, type: 'task'}
    // data for subtask => {subTask: subtaskId, task: taskId, type: 'subtask'}
    dispatch(
      stopTaskTimer(data, taskTitle, (res, err) => {
        if (cb) cb(res, err)
        if (err) {
          dispatch(
            fireErrorToaster(
              res?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
            )
          )
        }
        // if (!err || res?.response?.status === 404) {
        //   dispatch({ type: DASHBOARD.TIMER_UPDATE, payload: [] })
        // } else {
        //   dispatch(
        //     fireErrorToaster(
        //       res?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
        //     )
        //   )
        // }
      })
    )
  }, [])

  const formatTime = useCallback(time => {
    return time < 10 ? `0${time}` : time
  }, [])

  const getTimeFromSeconds = useCallback(sec => {
    const hours = Math.floor(sec / (60 * 60))
    const minutes = Math.floor(Math.floor(sec % (60 * 60)) / 60)
    const seconds = Math.ceil(Math.floor(sec % (60 * 60)) % 60)

    return {
      hours: formatTime(hours),
      minutes: formatTime(minutes),
      seconds: formatTime(seconds)
    }
  }, [])

  const getTime = useCallback((timerStart, timerDuration, cb) => {
    let timerId = setInterval(() => {
      const totalSeconds = Math.floor(
        (Date.now() + timerDuration - timerStart) / 1000
      )

      // backend issue, sometimes getting start time 1 sec ahead of the current time
      cb(getTimeFromSeconds(totalSeconds < 0 ? 0 : totalSeconds))
    }, 1000)

    return timerId
  }, [])

  const hideFloatingTimer = useCallback(() => {
    dispatch(toggleTimerPosition())
  }, [])

  const showFloatingTimer = useCallback(() => {
    dispatch(toggleTimerPosition(true))
  }, [])

  const showPicker = useCallback(e => {
    if (e.currentTarget.showPicker) {
      e.currentTarget.showPicker()
    }
  }, [])

  return {
    startTimer,
    stopTimer,
    formatTime,
    getTime,
    getTimeFromSeconds,
    hideFloatingTimer,
    showFloatingTimer,
    showPicker
  }
}

export default useTimerFunctions
