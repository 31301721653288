import React from 'react'
import clsx from 'clsx'
import { ReactComponent as BasicInfoIcon } from 'static/svg/info.svg'
import { ReactComponent as TeamIcon } from 'static/svg/people.svg'
import { ReactComponent as FieldsIcon } from 'static/svg/file-spreadsheet.svg'
import { ReactComponent as FolderUpload } from 'static/svg/folder-upload.svg'
import { useTranslation } from 'react-i18next'

const AddProjectTabs = ({ projectStep, setProjectStep }) => {
  const { t } = useTranslation()

  const projectTabs = [
    {
      step: 1,
      label: t('BASIC_INFO'),
      icon: <BasicInfoIcon className="h-4 w-4" />
    },
    {
      step: 2,
      label: t('PROJECT_TEAM'),
      icon: <TeamIcon className="h-4 w-4" />
    },
    {
      step: 3,
      label: t('PROJECT_FIELDS'),
      icon: (
        <FieldsIcon
          style={{
            height: '1rem',
            width: '1rem'
          }}
        />
      )
    },
    {
      step: 4,
      label: t('PROJECT_FILES'),
      icon: <FolderUpload className="h-4 w-4" />
    }
  ]

  return (
    <div className="flex sm:justify-evenly overflow-auto my-2 gap-3 text-center text-sm">
      {projectTabs.map((el, index) => (
        <div
          key={el.label}
          onClick={() => setProjectStep(index + 1)}
          className={clsx(
            'w-full py-2 mb-2 text-xs cursor-pointer hover:font-semibold',
            {
              'border-t border-primary-main': el.step <= projectStep
            },
            {
              'font-semibold': el.step === projectStep
            }
          )}
        >
          <p className="flex justify-center gap-2 items-center dark:text-dark-light">
            {el.icon}
            {el.label}
          </p>
        </div>
      ))}
    </div>
  )
}

export default AddProjectTabs
