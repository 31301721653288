import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const ClassicLayout = ({ elmFor, bgImage, children }) => {
  const classes = useStyles({ bgImage })

  return (
    <div
      className={clsx(
        classes.root,
        'bg-white flex justify-center items-center',
        elmFor === 'preview' ? 'w-full h-full' : 'w-screen h-screen'
      )}
    >
      <section
        className="bg-white flex justify-center items-center rounded-2xl"
        style={{ boxShadow: '0 0 2px rgba(0,0,0,0.2)' }}
      >
        <div className="py-8 px-10" style={{ maxWidth: 440, minWidth: 350 }}>
          {children}
        </div>
      </section>
    </div>
  )
}

const useStyles = makeStyles({
  root: ({ bgImage }) => ({
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  })
})

export default ClassicLayout
