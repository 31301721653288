import Button from '@material-ui/core/Button'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

const LoadingButton = props => {
  const { mode: themeForMode } = useSelector(
    state => state.generalAppState.theme
  )
  const classes = useStyles()
  const {
    loading,
    disabled,
    color = themeForMode === 'light' ? 'primary' : 'inherit',
    ...buttonProps
  } = props

  return (
    <Button
      classes={{ root: classes.root }}
      disabled={loading || disabled}
      {...buttonProps}
    >
      {props.children}
      {loading && (
        <CircularProgress
          classes={{ root: classes.progressRoot }}
          size={19}
          aria-busy={true}
          color={color}
        />
      )}
    </Button>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  progressRoot: {
    position: 'absolute'
  }
})

export default LoadingButton
