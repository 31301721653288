import axios from 'axios'
import { catchAsync } from 'utils'

/**
 * @param {String} data.startDate
 * @param {String} data.endDate
 * @param {Function} callback
 */
export const fetchUserTimesheets = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `tracking/timesheet/user?startDate=${data.startDate}&&endDate=${data.endDate}`
  })

  if (callback) callback(res.data)
})

/**
 * @param {String} data.userId
 * @param {String} data.startDate
 * @param {String} data.endDate
 * @param {Function} callback
 */
export const fetchSelectedUserTimesheets = catchAsync(
  async (data, callback) => {
    const res = await axios({
      method: 'GET',
      url: `/tracking/timesheet/agency/user/${data.userId}?startDate=${data.startDate}&&endDate=${data.endDate}`
    })

    if (callback) callback(res.data)
  }
)

export const fetchAllUsersTimesheets = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/tracking/timesheet/agency/${data.agencyOwnerId}?startDate=${data.startDate}&&endDate=${data.endDate}`
  })

  if (callback) callback(res.data)
})
