import React, { useMemo } from 'react'
import SidebarLink from './SidebarLink'
import { getSideNavItems } from './sidebarStatic'
import sidebarStyles from './sidebarStyles'
import { useSelector } from 'react-redux'
import userRoles, { getUserPath } from 'utils/userRoles'
import SidebarEmbeds from './SidebarEmbeds'

const SidebarBody = ({ isDrawerOpen }) => {
  const classes = sidebarStyles()
  const meData = useSelector(state => state.me.data)
  const permission = useSelector(state => state.permission.data?.access)
  const { isMarketplaceDomain } = useSelector(state => state.generalAppState)
  const { unreadMessages, personalUnreadMessages } = useSelector(
    state => state.chats
  )

  const totalUnreadCount = useMemo(() => {
    let total = 0
    Object.values(unreadMessages)
      .concat(Object.values(personalUnreadMessages))
      .forEach(group => (total += group.unreadCount))

    return total
  }, [unreadMessages, personalUnreadMessages])

  const userPath = getUserPath(meData.role)

  const sideDrawerLinks = useMemo(() => {
    return getSideNavItems({
      meRole: meData.role,
      permission,
      basePath: userPath,
      isMarketplaceDomain,
      unreadChatCount: totalUnreadCount,
      accountCreatedAt: meData.createdAt
    })
  }, [
    meData.role,
    meData.createdAt,
    isMarketplaceDomain,
    permission,
    totalUnreadCount,
    userPath
  ])

  return (
    <div className="my-4 overflow-auto">
      {sideDrawerLinks.map(item => (
        <SidebarLink
          key={item.text}
          navItem={item}
          path={`${userPath}${item.link}`}
          classes={classes}
          isDrawerOpen={isDrawerOpen}
        />
      ))}

      {meData.role >= userRoles.USER_CLIENT && (
        <SidebarEmbeds
          meId={meData._id}
          classes={classes}
          isDrawerOpen={isDrawerOpen}
        />
      )}
    </div>
  )
}

export default SidebarBody
