import { t } from 'i18next'
// import { useTranslation } from 'react-i18next'

const permissionObj = {
  billings: { label: 'bills', value: 'billings' },
  clientRequest: { label: t('CLIENT_REQUESTS'), value: 'clientRequest' },
  clients: { label: t('CLIENTS'), value: 'clients' },
  fileAssets: { label: t('FILE_STORAGE'), value: 'fileAssets' },
  onboarding: { label: t('ONBOARDING'), value: 'onboarding' },
  report: { label: t('REPORTS'), value: 'report' },
  services: { label: t('SERVICES'), value: 'services' },
  settings: { label: t('SETTINGS'), value: 'settings' },
  setup: { label: t('SETUP'), value: 'setup' },
  subTask: { label: t('SUBTASKS'), value: 'subTask' },
  subscription: { label: t('SUBSCRIPTION'), value: 'subscription' },
  task: { label: t('TASKS'), value: 'task' },
  teammate: { label: t('TEAM_MEMBERS'), value: 'teammate' },
  timesheet: { label: t('TIMESHEET'), value: 'timesheet' },
  workspace: { label: t('PROJECTS'), value: 'workspace' },
  workspaceView: { label: t('PROJECTS_TAB'), value: 'workspaceView' }
}

export const getPermission = () => ({
  billings: { label: 'bills', value: 'billings' },
  clientRequest: { label: t('CLIENT_REQUESTS'), value: 'clientRequest' },
  clients: { label: t('CLIENTS'), value: 'clients' },
  fileAssets: { label: t('FILE_STORAGE'), value: 'fileAssets' },
  onboarding: { label: t('ONBOARDING'), value: 'onboarding' },
  report: { label: t('REPORTS'), value: 'report' },
  services: { label: t('SERVICES'), value: 'services' },
  settings: { label: t('SETTINGS'), value: 'settings' },
  setup: { label: t('SETUP'), value: 'setup' },
  subTask: { label: t('SUBTASKS'), value: 'subTask' },
  subscription: { label: t('SUBSCRIPTION'), value: 'subscription' },
  task: { label: t('TASKS'), value: 'task' },
  teammate: { label: t('TEAM_MEMBERS'), value: 'teammate' },
  timesheet: { label: t('TIMESHEET'), value: 'timesheet' },
  workspace: { label: t('PROJECTS'), value: 'workspace' },
  workspaceView: { label: t('PROJECTS_TAB'), value: 'workspaceView' }
})

export default permissionObj
