import React from 'react'
import ChannelsListItem from './ChannelsListItem'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const SearchResultsContainer = ({
  search,
  channels,
  personalChannels,
  currentChannelId,
  chatPermission,
  setChannelId,
  setIsPersonalChat
}) => {
  const { t } = useTranslation()
  const { unreadMessages, personalUnreadMessages } = useSelector(
    state => state.chats
  )
  const searchedList1 = channels.filter(channel =>
    channel.title.toLowerCase().includes(search.toLowerCase())
  )
  const searchedList2 = personalChannels.filter(channel =>
    channel.name.toLowerCase().includes(search.toLowerCase())
  )

  const handleOnChannelClick = (chId, isPersonalChannel) => {
    setChannelId(chId)
    setIsPersonalChat(isPersonalChannel)
  }

  return (
    <>
      {searchedList1.length + searchedList2.length ? (
        <div className="p-1 md:p-2">
          <ul className="m-2">
            {searchedList1.map(channel => (
              <ChannelsListItem
                channelId={channel._id}
                channelName={channel.title}
                chatPermission={chatPermission}
                currentChannelId={currentChannelId}
                channelType={channel.type}
                unreadMessageData={unreadMessages[channel._id]}
                avatarUser={
                  channel.type === 'newFeed' &&
                  (channel.members?.find(item => item.role === 'moderator')
                    ?.user ||
                    {})
                }
                handleOnClick={() => handleOnChannelClick(channel._id, false)}
              />
            ))}

            {searchedList2.map(channel => (
              <ChannelsListItem
                channelId={channel._id}
                channelName={channel.name}
                channelType={channel.type}
                chatPermission={chatPermission}
                currentChannelId={currentChannelId}
                unreadMessageData={personalUnreadMessages[channel._id]}
                avatarUser={channel}
                isPersonalChannel={true}
                handleOnClick={() => handleOnChannelClick(channel._id, false)}
              />
            ))}
          </ul>
        </div>
      ) : (
        <div key="noChannel" className="pl-6 dark:text-gray-400 text-smaller">
          {t('NO_CHANNEL_FOUND')}
        </div>
      )}
    </>
  )
}

export default SearchResultsContainer
