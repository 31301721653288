import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import { ColoredAvatars, CustomTooltip } from 'global/globalComponents'
import React from 'react'
import clsx from 'clsx'
import Currency from 'static/Currency/Currency'
import { useTranslation } from 'react-i18next'

export const InvoiceFileCard = ({ invoice, publicView }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  // const [subtotal, setSubtotal] = useState(0)
  const selectedCurrency = invoice.currency
  const agency = invoice.agency ?? {}
  const client = invoice.client ?? {}
  const timestamp = new Date().getTime()
  const { subtotal, total } = useMemo(() => {
    const subtotal = invoice.invoiceItems.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.rate * currentValue.quantity
      },
      0
    )

    const total = invoice.taxPercent
      ? subtotal +
        parseFloat((subtotal * (invoice.taxPercent / 100)).toFixed(2))
      : subtotal

    return { subtotal, total }
  }, [invoice])

  // useEffect(() => {
  //   if (invoice.invoiceItems && invoice.invoiceItems.length) {
  //     let subtotal = 0

  //     for (let item of invoice.invoiceItems) {
  //       subtotal += item.total
  //     }

  //     setSubtotal(subtotal)
  //   }
  // }, [invoice])

  return (
    <div className="invoice-print bg-white border border-gray-300 shadow p-4 rounded flex-1 dark:bg-dark-main dark:border-black">
      <div className="text-right text-sm text-gray-600">
        <div className="mb-4">
          <span className="dark:text-dark-light">{t('INVOICE')} #</span>
          &nbsp;&nbsp;
          <span className="font-medium dark:text-dark-light">
            {invoice.customInvoiceNumber ?? invoice.invoiceNumber}
          </span>
        </div>
        {invoice.status === 'paid' && (
          <div className="inline-block px-10 py-2 border border-primary-main rounded text-primary-main tracking-widest mt-2 origin-bottom-left rotate-45 opacity-60">
            {t('PAID')}
          </div>
        )}
      </div>
      <header className="flex flex-col items-center text-center">
        <div className="py-6 flex flex-col items-center">
          <div className="mb-2">
            {agency.agencyLogo?.smallLogo ? (
              <img
                src={`${agency.agencyLogo.smallLogo}?t=${timestamp}`}
                alt=""
                className="w-24"
              />
            ) : (
              <ColoredAvatars
                user={{ name: agency.agencyCompanyName || 'Unknown' }}
                size="extraLarge"
                tooltip={false}
              />
            )}
          </div>
          <h4 className="font-semibold text-xl dark:text-dark-light2">
            {t('INVOICE')}
          </h4>
          <h5 className="text-xs text-gray-500 dark:text-dark-light">
            {invoice.invoiceTitle}
          </h5>
        </div>
      </header>
      <hr />
      <div className="px-3 sm:px-8">
        {agency && (
          <div className="mb-8">
            <div className="flex flex-wrap space-y-6 sm:space-y-0 sm:space-x-6 py-8">
              {/* ------ From ------- */}
              <div className="flex-1">
                <h6 className="mb-2 text-gray-500 dark:text-dark-light2">
                  {t('FROM')}
                </h6>
                <div>
                  <div className="mb-2">
                    <div className="dark:text-dark-light">
                      {agency.agencyName}
                    </div>
                  </div>

                  <div className="mb-2 text-sm">
                    <div className="dark:text-dark-light">
                      {agency.agencyCompanyName}
                    </div>
                  </div>
                  <div className="mb-2 text-xs text-gray-500 dark:text-dark-light">
                    <div>{agency.agencyAddress}</div>
                  </div>

                  {!publicView && (
                    <div className="mb-2 text-xs text-primary-main">
                      {agency.agencyEmail}
                    </div>
                  )}
                </div>
              </div>

              {/* ------ To ------- */}
              <div className="flex-1">
                <h6 className="mb-2 text-gray-500 dark:text-dark-light2">
                  {t('TO')}
                </h6>
                {client.clientId || client.clientName ? (
                  <div>
                    <div className="mb-2">
                      <div className="dark:text-dark-light">
                        {client.clientName}
                      </div>
                    </div>

                    <div className="mb-2 text-sm">
                      <div className="dark:text-dark-light">
                        {client.clientCompanyName}
                      </div>
                    </div>

                    <div className="mb-2 text-xs text-gray-500 dark:text-dark-light">
                      {client.clientAddress}
                    </div>

                    <div className="text-primary-main mb-2 text-xs dark:text-dark-light">
                      {!publicView && (
                        <div className="mb-2">
                          <CustomTooltip
                            title={t('CLIENT_EMAIL')}
                            placement="top"
                          >
                            <span>{client.clientEmail}</span>
                          </CustomTooltip>
                        </div>
                      )}

                      <div>
                        {client.client_cc_email?.map((item, index) => (
                          <div key={item.id}>
                            <CustomTooltip
                              title={t('CC_EMAIL')}
                              placement="top"
                            >
                              <span>{item}</span>
                            </CustomTooltip>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <span className="text-md dark:text-dark-light">
                    {t('NO_CLIENT_SELECTED')}
                  </span>
                )}
              </div>
            </div>

            <div className="flex space-x-6">
              <div className="flex-1 mb-2 mt-auto">
                <h6 className="uppercase text-xs font-semibold tracking-wide text-gray-500 dark:text-dark-light">
                  {t('ISSUED_ON')}
                </h6>

                <div className="text-sm dark:text-dark-light2">
                  {format(new Date(invoice.issuedDate), 'MMM d, yyyy')}
                </div>
              </div>

              <div className="flex-1">
                <div className="mb-2">
                  <h6 className="uppercase text-xs font-semibold tracking-wide text-gray-500 dark:text-dark-light2">
                    {t('DUE_ON')}
                  </h6>
                  <div className="text-sm dark:text-dark-light">
                    {format(new Date(invoice.dueDate), 'MMM d, yyyy')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <div className={clsx(classes.invoicePricingTable, 'mb-2')}>
            <div className="uppercase text-xs dark:text-dark-light">
              {t('ITEM_NAME')}
            </div>
            <div className="uppercase text-right text-xs dark:text-dark-light">
              {t('UNITS')}
            </div>
            <div className="uppercase text-right text-xs dark:text-dark-light">
              {t('RATE')}
            </div>
            <div className="uppercase text-right text-xs dark:text-dark-light">
              {t('TOTAL')}
            </div>
          </div>

          {invoice.invoiceItems.map((item, index) => (
            <div
              key={index}
              className={clsx(
                classes.invoicePricingTable,
                'border-b py-4 mb-2 relative'
              )}
            >
              {item.type === 'addon' && (
                <div
                  style={{ position: 'absolute', left: 0, top: 0 }}
                  className="text-primary-main font-semibold text-xs"
                >
                  {t('ADD_ON')}
                </div>
              )}

              <div className="text-sm py-1 dark:text-dark-light">
                {item.name}
              </div>
              <div className="text-sm py-1 text-right dark:text-dark-light">
                {item.quantity}
              </div>
              <div className="text-sm py-1 text-right dark:text-dark-light">
                {item.rate}
              </div>

              <div className="text-right dark:text-dark-light">
                <span>{Currency[selectedCurrency].symbol_native}</span>
                <span>{item.total || 0}</span>
              </div>
            </div>
          ))}

          <div>
            <div className="flex justify-between">
              <div className="text-sm font-semibold py-2 dark:text-dark-light2">
                {t('SUBTOTAL')}
              </div>
              <div className="text-sm font-semibold py-2 dark:text-dark-light">
                {Currency[selectedCurrency].symbol_native}
                {subtotal}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="text-sm text-gray-500 dark:text-dark-light2">
                {t('DISCOUNT')}
              </div>
              <div className="text-sm text-gray-500 py-2 dark:text-dark-light">
                -{Currency[selectedCurrency].symbol_native}
                {invoice.discountAmount || 0}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="text-sm text-gray-500 capitalize dark:text-dark-light2">
                {invoice.taxName}
              </div>
              <div className="text-sm text-gray-500 py-2 dark:text-dark-light">
                {invoice.taxPercent || 0}%
              </div>
            </div>
          </div>
          <hr />

          <div className="flex justify-between">
            <div className="font-bold text-xl py-4 dark:text-dark-light2">
              {t('TOTAL')}
            </div>
            <div className="font-bold text-xl py-4 dark:text-dark-light">
              {Currency[selectedCurrency].symbol_native}
              {total}
            </div>
          </div>
        </div>

        <div className="mt-16 mb-16">
          {invoice.taxDetail && invoice.taxDetail[0] && (
            <div className="mb-6">
              <h6 className="text-gray-400 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
                {t('TAX_INFO')}
              </h6>
              <p className="text-smaller text-gray-900 dark:text-dark-light">
                {typeof invoice.taxDetail[0] === 'string'
                  ? invoice.taxDetail[0]
                      ?.split('\n')
                      .map(line => (
                        <p className="dark:text-dark-light py-0.5">{line}</p>
                      ))
                  : invoice.taxDetail.map(item => (
                      <div className="flex items-center dark:text-dark-light">
                        <p className="dark:text-dark-light">{item.label}</p>
                        <p> - </p>
                        <p className="dark:text-dark-light">{item.value}</p>
                      </div>
                    ))}
              </p>
            </div>
          )}

          {invoice.bankDetail && invoice.bankDetail[0] && (
            <div className="mb-6">
              <h6 className="text-gray-400 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
                {t('BANK_DETAILS')}
              </h6>
              <p className="text-smaller text-gray-900 dark:text-dark-light">
                {typeof invoice.bankDetail[0] === 'string'
                  ? invoice.bankDetail[0]
                      ?.split('\n')
                      .map(line => (
                        <p className="dark:text-dark-light py-0.5">{line}</p>
                      ))
                  : invoice.bankDetail.map(item => (
                      <div className="flex items-center">
                        <p className="dark:text-dark-light">{item.label}</p>
                        <p> - </p>
                        <p className="dark:text-dark-light">{item.value}</p>
                      </div>
                    ))}
              </p>
            </div>
          )}

          {invoice.notes && (
            <div className="mb-6">
              <h6 className="text-gray-400 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
                {t('NOTES')}
              </h6>

              {/* <p className="text-sm text-gray-700">{invoice.notes}</p> */}
              <div className="text-smaller text-gray-900">
                {invoice.notes.split('\n').map(line => (
                  <p className="py-0.5">{line}</p>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  invoicePricingTable: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '1rem',
    alignItems: 'center'
  },
  paidBadge: {
    transform: 'rotate(40deg)',
    transformOrigin: 'bottom left'
  }
})

export default InvoiceFileCard
