import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const usePermission = () => {
  const permissions = useSelector(state => state.permission)
  const [permissionsData, setPermissionsData] = useState({
    workspace: {},
    workspaceView: {},
    task: {},
    subTask: {},
    teammate: {},
    services: {},
    clients: {},
    billings: {},
    subscription: {},
    setup: {},
    settings: {
      general: {},
      profile: {},
      security: {}
    },
    fileAssets: {},
    onboarding: {},
    clientRequest: {},
    timesheet: {},
    report: {}
  })

  useEffect(() => {
    if (permissions.fetched && typeof permissions.data.access === 'object') {
      const obj = { ...permissionsData }

      for (let key in permissions.data.access) {
        if (typeof permissions.data.access[key] === 'object') {
          obj[key] = { ...permissions.data.access[key] }
        }
      }

      setPermissionsData(obj)
    }
  }, [permissions])

  return {
    loading: permissions.loading,
    ...permissionsData
  }
}

export default usePermission
