import React from 'react'
import {
  CustomModal,
  CustomModalBody
} from 'global/globalComponents/CustomModal'
import { Loader } from 'global/globalComponents'
import { ReactComponent as CheckCircleFilledIcon } from 'static/svg/check-circle-filled.svg'
import { ReactComponent as AlertCircleIcon } from 'static/svg/alert-circle.svg'

const PaymentStatusModal = ({
  open,
  status,
  onError,
  onSuccess,
  errorButtonText,
  successButtonText,
  errorHeading,
  errorMessage,
  successHeading,
  successMessage,
  loadingMessage
}) => {
  const args = {
    errorHeading: errorHeading || 'Payment Failed',
    errorMessage: errorMessage || 'Please check your details and try again.',
    errorButtonText: errorButtonText || 'Close',

    successHeading: successHeading || 'Payment Successful',
    successMessage:
      successMessage ||
      'Congratulations! Your payment has been successfully processed',
    successButtonText: successButtonText || 'Close',

    loadingMessage:
      loadingMessage || 'Please wait while we are processing your request...'
  }

  return (
    <CustomModal open={open} size="small">
      <CustomModalBody>
        {status === 'fail' ? (
          <div className="flex flex-col justify-center items-center text-center py-10">
            <AlertCircleIcon className="text-red-500 w-16 h-16 mb-2" />
            <p className="text-red-500 text-xl font-semibold mb-2">
              {args.errorHeading}
            </p>
            <p className="text-sm w-2/3">{args.errorMessage}</p>
            <button
              className="bg-red-500 text-white px-4 py-2 mt-4 rounded text-sm font-semibold"
              onClick={onError}
            >
              {args.errorButtonText}
            </button>
          </div>
        ) : status === 'success' ? (
          <div className="flex flex-col justify-center items-center text-center py-10">
            <CheckCircleFilledIcon className="text-green-500 w-16 h-16 mb-2" />
            <p className="text-green-500 text-xl font-semibold mb-2">
              {args.successHeading}
            </p>
            <p className="text-sm w-2/3">{args.successMessage}</p>
            <button
              className="bg-green-500 text-white px-4 py-2 mt-4 rounded text-sm font-semibold"
              onClick={onSuccess}
            >
              {args.successButtonText}
            </button>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center text-center py-10">
            <Loader />
            <p className="mt-8">{args.loadingMessage}</p>
          </div>
        )}
      </CustomModalBody>
    </CustomModal>
  )
}

export default PaymentStatusModal
