import React from 'react'
import { ReactComponent as CheckIcon } from 'static/svg/check.svg'
import Currency from 'static/Currency/Currency'
import { CircularProgress } from '@material-ui/core'

const plansData = {
  free: [
    '1 Team Account',
    '20 Client Accounts',
    'Unlimited Projects',
    '2GB Storage Drive',
    'Invoicing and Payments',
    'Integrations',
    'Resource Management'
  ],
  starter: [
    '2 Team Accounts',
    'Unlimited Client Accounts',
    'Unlimited Projects',
    '5GB Storage Drive',
    'Branded Whitelabel Portal',
    'Invoicing and Payments',
    'Integrations',
    'Resource Management'
  ],
  professional: [
    '+ Everything in Starter plan, plus',
    '5 Team Members',
    '100 GB Storage Drive',
    'Timesheets',
    'Proposals and Workflows',
    'Priority support'
  ],
  enterprise: [
    '+ Everything in Professional plan, plus',
    '20 Team Members',
    '500 GB Storage Drive',
    'Dedicated Accounts Manager',
    'Custom Development',
    '1 on 1 Custom Onboarding & Coaching',
    'Advanced Integrations'
  ]
}

const PlansCard2 = ({
  title,
  // metadata,
  amount,
  currency,
  interval,
  selectPlanHandler,
  loading,
  btnText
}) => {
  const currencySymbol = Currency[currency.toUpperCase()].symbol_native
  const planCardContents = plansData[title.toLowerCase()]

  return (
    <div
      className="flex-1 bg-white shadow-md px-12 py-8 rounded-lg border flex flex-col"
      style={{ maxWidth: 400 }}
    >
      <header className="h-20 flex flex-col items-center mb-4">
        {title.toLowerCase() === 'professional' ? (
          <PlanBadge text="Popular" style={{ backgroundColor: '#47d647' }} />
        ) : title.toLowerCase() === 'enterprise' ? (
          <PlanBadge text="Best Value" style={{ backgroundColor: '#ffa800' }} />
        ) : (
          // placeholder
          <span className="h-6 mb-3"></span>
        )}
        <h3 className="font-semibold text-center text-xl">{title}</h3>
      </header>

      <div style={{ minHeight: 245 }}>
        <ul>
          {planCardContents.map((item, index) => (
            <li key={index} className="flex items-center border-t py-3 px-2">
              <CheckIcon className="text-primary-main w-5 h-5" />
              <span className="font-medium text-sm ml-3">{item}</span>
            </li>
          ))}
        </ul>
      </div>

      <footer className="flex justify-center flex-col items-center mt-auto">
        <div className="my-4">
          <div className="text-4xl font-semibold mb-1">
            {currencySymbol}
            {amount}
          </div>
          <div className="uppercase text-tiny text-gray-500 text-center">
            per {interval}
          </div>
        </div>

        <button
          onClick={selectPlanHandler}
          className="uppercase bg-primary-main text-white w-full py-3 rounded text-sm font-semibold relative"
        >
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
              <CircularProgress size={20} color="#fff" />
            </div>
          )}
          {btnText}
        </button>
      </footer>
    </div>
  )
}

const PlanBadge = ({ text, style }) => {
  return (
    <div
      className="px-2 py-1 rounded round text-white text-xs mb-3 uppercase"
      style={style}
    >
      {text}
    </div>
  )
}

export default PlansCard2
