import { CustomPopper, LoadingButton } from 'global/globalComponents'
import AssignedWorkspaces from './AssignedWorkspaces'
import React, { useEffect, useState, useMemo } from 'react'
import { useTheme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import UserDashboardStyles from 'components/UserDashboard/userDashboardStyles'
import { updateTeammate } from 'thunks/teammate/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useTranslation } from 'react-i18next'

const ProjectsDropdown = ({ anchorEl, onClose, teammate, list }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = UserDashboardStyles()
  const dispatch = useDispatch()
  const [userProjects, setUserProjects] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (teammate?.assignedWorkspace) {
      setUserProjects(teammate.assignedWorkspace)
    } else {
      setUserProjects([])
    }
  }, [teammate._id, teammate.assignedWorkspace])

  const projectsList = useMemo(() => {
    const userProjectsIds = userProjects?.map(item => item._id)
    return list?.filter(item => !userProjectsIds?.includes(item._id))
  }, [list, userProjects])

  const handleUpdateTeammate = () => {
    let bodyData = { removedWorkspace: [], newWorkspace: [] }
    // Removed Workspaces
    for (let ws of teammate?.assignedWorkspace) {
      const match = userProjects.find(item => item._id === ws._id)

      if (!match) {
        bodyData.removedWorkspace.push(ws._id)
      }
    }

    // Added Workspaces
    for (let ws of userProjects) {
      const match = teammate.assignedWorkspace.find(item => item._id === ws._id)

      if (!match) {
        bodyData.newWorkspace.push(ws._id)
      }
    }

    setLoading(true)
    dispatch(
      updateTeammate({ id: teammate._id, body: bodyData }, (res, err) => {
        setLoading(false)
        onClose()
        if (!err) {
          dispatch(fireSuccessToaster(t('SAVED_SUCCESSFULLY')))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <div style={{ maxWidth: '300px' }}>
        <AssignedWorkspaces
          // teammate={teammate}
          userProjects={userProjects}
          setUserProjects={setUserProjects}
          className="flex-wrap"
        />
        <div className={classes.iconScrollableWrapper}>
          {projectsList.map(item => (
            <>
              <div
                className={`py-2 pl-2 text-sm text-primary-dark w-full flex justify-between items-center cursor-pointer ${classes.tags}`}
                onClick={() =>
                  setUserProjects(prev => [
                    ...prev,
                    { _id: item._id, name: item.name }
                  ])
                }
                key={item._id}
              >
                <p className="capitalize dark:text-dark-light">{item.name}</p>
              </div>
            </>
          ))}
        </div>
        <div className=" p-2">
          <LoadingButton
            size="small"
            onClick={handleUpdateTeammate}
            loading={loading}
            style={{
              backgroundColor:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.textField
                  : ''
            }}
          >
            {t('SAVE')}
          </LoadingButton>
        </div>
      </div>
    </CustomPopper>
  )
}

export default ProjectsDropdown
