import React, { useState, useImperativeHandle, useEffect } from 'react'
import { FormLabel } from 'global/globalComponents'
import RichTextEditor from 'global/globalComponents/QuillEditor/RichTextEditor'

const ServiceInstructions = React.forwardRef(({ instructions }, ref) => {
  const [html, setHtml] = useState('')

  useImperativeHandle(ref, () => ({
    getInstructions: () => {
      return html
    }
  }))

  useEffect(() => {
    if (instructions) {
      setHtml(instructions)
    }
  }, [instructions])

  return (
    <>
      <FormLabel htmlFor="offerings">Instructions</FormLabel>

      <RichTextEditor
        html={html}
        onChange={setHtml}
        placeholder="Enter instructions"
        style={{ height: 225 }}
        options={{ floatingToolbar: true }}
      />
    </>
  )
})

export default ServiceInstructions
