import axios from 'axios'
import { getErrorMessages } from 'utils/errorMessages'
import { CANCEL_CLIENT_SERVICE_REQUEST } from './reducers'
const errorMessages = getErrorMessages()

/**
 * fetch all cancel client service request  by domain
 * @param {Function} callback callback function
 */

export const fetchCancelServiceRequests = callback => async dispatch => {
  dispatch({ type: CANCEL_CLIENT_SERVICE_REQUEST.LOADING })

  try {
    const res = await axios({
      url: `/clientpayment/subscription/cancelrequest/view`,
      method: 'GET'
    })

    const sortedData = res.data.sort(
      (a, b) =>
        new Date(b.createdAt || b.created * 1000) -
        new Date(a.createdAt || a.created * 1000)
    )

    if (callback) callback(res.data)
    dispatch({
      type: CANCEL_CLIENT_SERVICE_REQUEST.FETCHED,
      payload: sortedData
    })
  } catch (err) {
    dispatch({ type: CANCEL_CLIENT_SERVICE_REQUEST.ERROR })
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Approve or reject user's cancel service request
 * @param {String} id service request id
 * @param {Object} data object
 * @param {Function} callback function
 */
export const approveRejectServiceCancelReq = (
  serviceReqId,
  data,
  callback
) => async dispatch => {
  try {
    const res = await axios({
      url: `/clientpayment/subscription/cancelrequest/${serviceReqId}`,
      method: 'PUT',
      data
    })
    dispatch({
      type: CANCEL_CLIENT_SERVICE_REQUEST.REMOVE,
      payload: res.data._id
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
