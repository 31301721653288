import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import TeammatesHeader from './TeammatesHeader'
import TeammatesTable from './TeammatesTable'
// import AddNewTeammatePanel from './AddNewTeammatePanel'
// import EditTeammatePanelNew from './EditTeammatePanelNew'
import {
  deleteTeammate
  // fetchTeammateOfCurrentUser,
  // updateClientTeammate
} from 'thunks/teammate/actions'
import { userRoles } from 'utils'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
// import { getErrorMessages } from 'utils/errorMessages'
import { AlertModal } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import AddNewTeammatePanel from './AddNewTeammatePanel'

const ClientTeammates = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const search = useSelector(state => state.generalAppState.headerSearch)
  // const [editTeammatePanel, setEditTeammatePanel] = useState({
  //   open: false,
  //   data: {}
  // })
  const teammates = useSelector(state => state.userTeammates)
  const { fetchedFirstTime } = useSelector(state => state.userWorkspaces)
  const [teammatesData, setTeammatesData] = useState([])
  const meData = useSelector(state => state.me.data)
  const [deleteTeammateAlert, setDeleteTeammateAlert] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [teammatePanel, setTeammatePanel] = useState({
    open: false,
    data: {}
  })

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  useEffect(() => {
    if (search.length > 0) {
      let arr = teammates.data.filter(item =>
        item?.name.toLowerCase().includes(search.toLowerCase())
      )

      setTeammatesData(arr)
    } else {
      setTeammatesData([...teammates.data])
    }
  }, [teammates, search])

  const openEditPanel = teammateData => {
    setTeammatePanel({ open: true, data: teammateData })
  }

  // const closeEditPanel = () => {
  //   setEditTeammatePanel(prev => ({ ...prev, open: false }))
  // }

  // const handleUpdateTeammate = (id, data, panelCallback) => {
  //   dispatch(
  //     updateClientTeammate({ id, data }, (res, err) => {
  //       panelCallback()
  //       updateTeammateCallback(res, err)
  //     })
  //   )
  // }

  // const updateTeammateCallback = (res, err) => {
  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //   } else {
  //     const errorMessages = getErrorMessages()
  //     dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
  //   }
  // }

  const canEditAccount = mateRole => {
    //manager, admin, super admin

    // user cannot edit himself or his superiors
    if (meData.role === mateRole) return false
    if (meData.role === userRoles.USER_CLIENT) return true // client owner can edit everyone
    if (
      meData.role === userRoles.CLIENT_ADMIN &&
      mateRole > userRoles.USER_CLIENT
    )
      return true
    if (
      meData.role === userRoles.CLIENT_MANAGER &&
      mateRole > userRoles.CLIENT_MANAGER &&
      mateRole !== userRoles.CLIENT_ADMIN
    )
      return true
    return false
  }

  const toggleDeleteTeammateAlert = teammate => {
    if (deleteTeammateAlert.open) {
      setDeleteTeammateAlert(prev => ({ ...prev, open: false }))
    } else {
      setDeleteTeammateAlert({
        open: true,
        data: teammate,
        loading: false
      })
    }
  }

  const handleDeleteTeammate = () => {
    setDeleteTeammateAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteTeammate(
        {
          id: deleteTeammateAlert.data._id
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            setDeleteTeammateAlert(prev => ({
              ...prev,
              loading: false
            }))
          } else {
            setDeleteTeammateAlert(prev => ({
              ...prev,
              open: false
            }))
            dispatch(fireSuccessToaster(t('DELETED_SUCCESSFULLY')))
          }
        }
      )
    )
  }

  return (
    <OuterContentWrapper>
      <TeammatesHeader
        openAddTeammatePanel={() => setTeammatePanel({ open: true, data: {} })}
      />
      {search !== '' && (
        <p className="text-sm mb-2 ml-1 text-gray-500 font-medium dark:text-dark-light">
          Search results for:{' '}
          <span className="text-primary-dark dark:text-dark-light">
            {search}
          </span>
        </p>
      )}

      <TeammatesTable
        loading={teammates.loading}
        teammates={teammatesData}
        openEditPanel={openEditPanel}
        canEditAccount={canEditAccount}
        elmFor="client"
        openDeleteTeammateAlert={toggleDeleteTeammateAlert}
      />
      {/* <AddNewTeammatePanel
        open={openAddTeammatePanel}
        onClose={setOpenAddTeammatePanel}
        handleAddTeammate={handleAddTeammate}
      /> */}
      {/* <EditTeammatePanelNew
        open={editTeammatePanel.open}
        onClose={closeEditPanel}
        data={editTeammatePanel.data}
        updateTeammate={handleUpdateTeammate}
        // elmFor="client"
        canEditAccount={canEditAccount}
      /> */}
      <AlertModal
        warningText={t('WARNING_DELETE', {
          data: `"${deleteTeammateAlert.data.name}"`
        })}
        open={deleteTeammateAlert.open}
        handleDialog={toggleDeleteTeammateAlert}
        handleDeleteAction={handleDeleteTeammate}
        loading={deleteTeammateAlert.loading}
      />

      <AddNewTeammatePanel
        open={teammatePanel.open}
        data={teammatePanel.data}
        onClose={() => setTeammatePanel(prev => ({ ...prev, open: false }))}
      />
    </OuterContentWrapper>
  )
}

export default ClientTeammates
