import axios from 'axios'
import { CLIENT_REQUEST } from './reducer'
import { catchAsyncDispatch } from 'utils'
import { Uploader, catchAsync } from 'utils'
import { APP_TOASTER } from 'thunks/fireToaster/reducers'
import { getErrorMessages } from 'utils/errorMessages'

/**
 * Fetch raised requests by client
 * @param {Object|null} data
 * @param {Function} callback callback function
 */
export const fetchClientRequests = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/clientrequest/user',
      method: 'GET'
    })

    // let sortedClientRequests = res.data
    //   .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    //   .sort(item => {
    //     if (item.status === 'resolved') return 0
    //     else if (item.status === 'pending') return -1
    //     return 1
    //   })

    dispatch({
      type: CLIENT_REQUEST.FETCHED,
      payload: res.data
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Create a new client request
 * @param {object} data.body title, description, file (optional), priority(optional)
 * @param {Function} callback callback function
 */
export const createClientRequest = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/clientrequest',
      data: data.body
    })

    dispatch({ type: CLIENT_REQUEST.ADD_ONE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update raised client request
 * @param {String} id request id
 * @param {object} data.data
 * @param {Function} callback callback function
 */
export const updateClientRequest = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/clientrequest/${data.id}`,
      data: data.body
    })

    dispatch({ type: CLIENT_REQUEST.UPDATE_ONE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete raised request
 * @param {String} data.id request id
 * @param {object} data.body
 * @param {Function} callback callback function
 */
export const deleteClientRequest = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/clientrequest/${data.id}`
    })

    dispatch({
      type: CLIENT_REQUEST.DELETE_ONE,
      payload: data.id
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Upload File
 * @param {Object} data.data file data
 * @param {Object} data.metaData
 * @param {Function} callback
 */
export const uploadAttachment = (data, callback) => {
  const uploader = new Uploader({
    ...data.data,
    module: 'request',
    metaData: data.metadata
  })

  return new Promise((resolve, reject) => {
    uploader
      .onComplete(res => {
        if (callback) callback(res)
        resolve(res)
      })
      .onError(error => {
        if (callback) callback(error.message ?? 'File uploading failed!', true)
        reject(error.message ?? 'File uploading failed!')
      })

    uploader.start()
  })
}

/**
 * Delete File
 * @param {String} id file id
 * @param {Function} callback callback function
 */
export const deleteAttachment = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/fileassetsfile/${data.fileId}`
  })

  if (callback) callback(res.data)
})

/**
 * Fetch comment by request ID
 * @param {String} data.id request id
 * @param {Function} callback callback function
 */
export const fetchRequestsComments = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/comments?clientRequest=${data.id}`,
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * Add comment in a particular request
 * @param {String} id request id
 * @param {Function} callback callback function
 */
export const addRequestComment = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/comments`,
    method: 'POST',
    data: data.data
  })

  callback(res.data)
})

/**
 * Create client request form template
 * @param {object} data.body category, items
 * @param {Function} callback callback function
 */
export const createRequestFormTemplate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/clientrequesttemplate',
      data: data.body
    })

    dispatch({
      type: CLIENT_REQUEST.ADD_FORM_TEMPLATE,
      payload: res.data
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update client request form template
 * * @param {object} data.id template id
 * @param {object} data.body category, items
 * @param {Function} callback callback function
 */
export const updateRequestFormTemplate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/clientrequesttemplate/${data.id}`,
      data: data.body
    })

    dispatch({
      type: CLIENT_REQUEST.UPDATE_FORM_TEMPLATE,
      payload: res.data
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete client request form template
 * @param {object} data.id template id
 * @param {Function} callback callback function
 */
export const deleteRequestFormTemplate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/clientrequesttemplate/${data.id}`
    })

    dispatch({
      type: CLIENT_REQUEST.DELETE_FORM_TEMPLATE,
      payload: data.id
    })

    console.log('HELLO!', res.data)

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch request form templates
 * @param {object} data.params
 * @param {Function} callback callback function
 */
export const fetchRequestFormTemplates = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/clientrequesttemplate'
    })

    dispatch({
      type: CLIENT_REQUEST.FETCHED_FORM_TEMPLATE,
      payload: res.data
    })

    if (callback) callback(res.data)
  }, callback)
}
