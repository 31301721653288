import React, { useRef } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import WhyChooseUs from './WhyChooseUs'
import AboutUs from './AboutUs'
import Achievements from './Achievements'
import Services from './Services'
import Testimonials from './Testimonials'
import FAQ from './FAQ'
import Footer from './Footer'
import DemoCheckoutSection from '../DemoCheckoutSection/DemoCheckoutSection'
import clsx from 'clsx'

const Template1 = ({
  templateSections,
  updateTemplateSection,
  updateSectionBlock,
  addBlockItem,
  removeBlockItem,
  isEditable,
  firstSectionElement
}) => {
  const ref = useRef()
  const classes = useStyles()

  const renderSection = section => {
    const commonProps = {
      classes,
      data: section,
      isEditable,
      updateTemplateSection: ({ field, value }) => {
        updateTemplateSection({ sectionId: section.id, field, value })
      },
      updateSectionBlock: ({ blockId, field, value }) => {
        updateSectionBlock({
          sectionId: section.id,
          blockId,
          field,
          value
        })
      },
      addBlockItem: ({ newItem }) => {
        addBlockItem({
          sectionId: section.id,
          newItem
        })
      },
      removeBlockItem: ({ blockId }) => {
        removeBlockItem({
          sectionId: section.id,
          blockId
        })
      },
      handleScrollToTop
    }

    // Note: Here I'm using JS currying technic: updateTemplateSection, updateSectionBlock
    switch (section.name) {
      case 'whyChooseUs':
        return <WhyChooseUs {...commonProps} />

      case 'about':
        return <AboutUs {...commonProps} />

      case 'achievements':
        return <Achievements {...commonProps} />

      case 'services':
        return <Services {...commonProps} />

      case 'testimonials':
        return <Testimonials {...commonProps} />

      case 'faq':
        return <FAQ {...commonProps} />
      case 'footer':
        return <Footer {...commonProps} />

      default:
        return null
    }
  }

  const handleScrollToTop = () => {
    ref.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <div className={classes.root} ref={ref}>
      <div className={clsx('mt-8', classes.sectionContainer)}>
        {firstSectionElement || <DemoCheckoutSection />}
      </div>
      {templateSections
        .filter(section => !section.isHidden)
        .map(section => (
          <div key={section.id} style={{ marginTop: '4rem' }}>
            {renderSection(section)}
          </div>
        ))}
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      // ...cssVariables,
      backgroundColor: '#fff',
      height: '100%',
      overflow: 'auto'
    },
    sectionContainer: {
      width: '90%',
      maxWidth: 1200,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    sectionHeading: {
      fontWeight: 700,
      fontSize: '2rem',
      marginBottom: '1.75rem'
      // color: 'var(--color-dark)'
    },
    whyChooseUsCardsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridGap: '3rem 1.5rem',
      alignItems: 'center',
      gap: '1.5rem',

      '& > div': {
        marginTop: '2rem',
        transition: 'transform 500ms ease',

        '&:hover': {
          transform: 'translateY(-16px)'
        }
      },

      '@media (min-width: 640px)': {
        '&': {
          gridTemplateColumns: 'repeat(2, 1fr)'
        }
      },

      '@media (min-width: 992px)': {
        '&': {
          gridTemplateColumns: 'repeat(3, 1fr)'
        },
        '& > div:nth-child(2), & > div:nth-child(5)': {
          marginTop: 0
        }
      }
    },
    whyChooseUsCard: {
      backgroundColor: '#ffffff',
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: '4px 0px 0px 0px',
      borderRadius: 10,
      boxShadow: '0 0 50px rgba(0, 0, 0, 0.20)',
      padding: '2rem'
    },
    whyChooseUsCardImage: {
      width: 65,
      height: 'auto'
    },
    whyChooseUsCardHeading: {
      fontSize: 18,
      fontWeight: 600,
      margin: '8px 0'
      // color: 'var(--color-dark)'
    },
    whyChooseUsCardDescription: {
      fontSize: 14,
      color: '#4c4d56'
      // color: 'var(--color-gray-2)'
    },
    getStartedButton: {
      color: '#fff',
      padding: '16px 60px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 6,
      fontWeight: 600,
      fontSize: 16
    },
    aboutUs: {
      display: 'flex',
      flexDirection: 'column',

      '@media (min-width: 992px)': {
        '&': {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        },
        '& > div': {
          width: '48%'
        }
      }
    },
    aboutUsImagesContainer: {
      display: 'flex'
    },
    aboutUsImage: {
      width: '48%',
      height: 400,
      objectFit: 'cover',
      borderRadius: 10,
      filter: 'grayscale(1)',

      '&:last-child': {
        marginTop: '4rem',
        marginLeft: '1rem'
      }
    },
    aboutUsDescription: {
      fontSize: 18,
      // color: 'var(--color-gray-1)',
      color: '#212529',
      marginBottom: '1.5rem'
    },
    achievementsWrapper: {
      backgroundColor: '#e8f0f3',
      padding: '2rem 0'
    },
    achievements: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: '1rem',

      '& > div': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    achievementImageWrapper: {
      backgroundColor: '#fff',
      padding: '1rem',
      borderRadius: 10,
      marginRight: '1rem',
      boxShadow: '0 0 50px rgba(0, 0, 0, 0.15)',
      lineHeight: 0
    },
    achievementImage: {
      width: 50
    },
    achievementHeading: {
      fontSize: '1.75rem',
      color: theme.palette.primary.main,
      fontWeight: 700
    },
    achievementSubHeading: {
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontWeight: 600
      // color: 'var(--color-dark)'
    },
    serviceBlocks: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '2rem',
      textAlign: 'center',

      '@media(min-width: 992px)': {
        '&': {
          flexDirection: 'row'
        }
      },

      '& > div': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    serviceBlockHeading: {
      fontSize: 18,
      fontWeight: 600
      // color: 'var(--color-dark)'
    },
    testimonialSlider: {
      display: 'flex',
      overflow: 'auto',
      gap: '1.5rem',
      padding: '1.5rem'
    },
    testimonialCard: {
      border: '1px solid #dedede',
      padding: '2rem',
      borderRadius: 10,
      textAlign: 'center',
      minWidth: 500
    },
    testimonialReview: {
      fontSize: 14,
      // color: 'var(--color-gray-1)',
      color: '#212529'
    },
    testimonialReviewAuthor: {
      fontSize: 18,
      fontWeight: 600,
      marginTop: 8
    },
    faqAccordion: {
      backgroundColor: '#fff',
      marginBottom: '1rem',
      borderRadius: 8,
      padding: '1rem',
      boxShadow: '0 0 40px rgba(0, 0, 0, 0.15)',
      position: 'relative',

      '&:hover $removeFaqAccordionBtn': {
        opacity: 1
      }
    },
    removeFaqAccordionBtn: {
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translate(50%,-50%)',
      width: '1.5rem',
      height: '1.5rem',
      background: '#ff0000',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      opacity: 0,
      transition: 'opacity 300ms ease'
    },
    faqAccordionQuestionWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 600,
      // color: 'var(--color-dark)',

      '& button': {
        width: 32,
        height: 32,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: '1.25rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    faqAccordionAnswerWrapper: {
      fontSize: 13,
      // color: 'var(--color-gray-2)',
      color: '#4c4d56'
    },
    footer: {
      backgroundColor: '#010717'
    },
    footerContentWrapper: {
      padding: '1rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& > div': {
        fontSize: 13,
        color: '#fff'
      }
    },
    footerLinkWrapper: {
      display: 'flex',
      position: 'relative',
      padding: '0 8px',

      '& button': {
        position: 'absolute',
        width: '1.5rem',
        height: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        right: -4,
        top: -4,
        opacity: 0,
        transition: 'opacity 300ms ease',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: '#000',
        borderRadius: '50%'
      },

      '&:hover button': {
        opacity: 1
      }
    }
  })
)

export default Template1
