import React, { useEffect, useMemo, useState } from 'react'
import { useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import currencySymbol from 'static/Currency/Currency'
import Pill from 'global/globalComponents/Pill/Pill'
import invoiceStyles from '../InvoiceStyles'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { t } from 'i18next'

const ServiceSelector = ({ selectedServices, setSelectedServices }) => {
  const theme = useTheme()
  const userServices = useSelector(state => state.userServices)
  const classes = invoiceStyles()
  const oneTimeServices = useMemo(() => {
    return userServices.data.filter(item => !item.isRecurring)
  }, [userServices.data])

  // useEffect(() => {
  //   const firstSelectedService = selectedServices[0]

  //   // Show services with the same interval
  //   if (firstSelectedService) {
  //     if (!firstSelectedService.isRecurring) {
  //       setServices(
  //         userServices.data.filter(
  //           service =>
  //             !service.isRecurring &&
  //             service.currency === firstSelectedService.currency
  //         )
  //       )
  //     } else {
  //       setServices(
  //         userServices.data.filter(
  //           service =>
  //             service.isRecurring &&
  //             service.recurring?.interval ===
  //               firstSelectedService.recurring.interval &&
  //             service.recurring.trial_period_days ===
  //               firstSelectedService.recurring.trial_period_days &&
  //             service.currency === firstSelectedService.currency
  //         )
  //       )
  //     }
  //   } else {
  //     setServices([...userServices.data])
  //   }
  // }, [userServices.data, selectedServices])

  const optionLabel = service => {
    return (
      <div className="self-center flex justify-between items-center">
        <span className="pr-2">
          {service.name} (
          {currencySymbol[service?.currency?.toUpperCase()]?.symbol_native}
          {service.amount * 0.01}){' '}
        </span>

        <Pill variant={service.isRecurring ? 'yellow' : 'blue'}>
          {service.isRecurring ? 'Recurring' : 'One Time'}
        </Pill>
      </div>
    )
  }

  return (
    <>
      <FormLabel>{t('SELECT_SERVICE')}</FormLabel>
      <Select
        menuPosition={'fixed'}
        placeholder={t('SELECT_SERVICE')}
        isMulti={true}
        className={classes.textFieldStyle}
        options={oneTimeServices}
        getOptionLabel={optionLabel}
        getOptionValue={option => option._id}
        onChange={setSelectedServices}
        styles={{
          ...reactSelectCustomStyles(theme),
          menuPortal: provided => ({ ...provided, zIndex: 9999 })
        }}
        maxMenuHeight={155}
        value={selectedServices}
        isSearchable={false}
      />
    </>
  )
}

export default ServiceSelector
