import { useEffect, useState, useRef, useImperativeHandle } from 'react'
import React from 'react'
import FieldLabel from './FieldLabel'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import DescriptionEditor from 'components/Editors/DescriptionEditor/DescriptionEditor'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const TaskDescription = React.forwardRef(
  (
    {
      taskId,
      collaborators,
      taskDescription,
      innerProjectPermission,
      updateDescription,
      handleAttachmentUpload,
      workspace,
      elmFor
    },
    ref
  ) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [updateReqCount, setUpdateReqCount] = useState(0)
    const [prevTaskId, setPrevTaskId] = useState('')
    const editorRef = useRef()
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
      if (taskId && taskId !== prevTaskId && editorRef.current) {
        setTimeout(() => {
          const editor = editorRef.current?.editor
          if (editor) {
            if (typeof taskDescription === 'string') {
              editor.setText(taskDescription)
            } else {
              editor.setContents(taskDescription)
            }
          }
        }, 100)

        setPrevTaskId(taskId)
      }
    }, [taskDescription, taskId, editorRef])

    useEffect(() => {
      if (updateReqCount) {
        let timer = setTimeout(() => {
          handleUpdateDescription()
        }, 1000)

        return () => clearTimeout(timer)
      }
    }, [updateReqCount])

    useImperativeHandle(ref, () => ({
      handleInsertEmbed
    }))

    const handleInsertEmbed = ({ source, fileType, index }) => {
      if (fileType === 'image' || fileType === 'video') {
        const editor = editorRef.current?.editor
        if (!editor) return

        index = index !== undefined ? index : editor.getLength()
        editor.insertEmbed(index, fileType, source)
        editor.insertText(index + 1, '\n')
        setUpdateReqCount(prev => prev + 1)
      }
    }

    const handleImagePaste = (file, index) => {
      // const editor = editorRef.current.editor

      // editor.insertText(index, loadingText)
      setUploading(true)
      handleAttachmentUpload(file, source => {
        // editor.deleteText(index, loadingText.length)
        setUploading(false)
        handleInsertEmbed({ source, fileType: 'image', index })
      })
    }

    const handleUpdateDescription = () => {
      if (workspace?.isComplete) {
        dispatch(
          fireErrorToaster(t('THIS_TASK_BELONG_TO_A_COMPLETE_WORKSPACE'))
        )
      } else {
        if (updateDescription) {
          const editor = editorRef.current?.editor
          if (editor) {
            updateDescription(editor.getContents())
          }
        }
      }
    }

    const handleEditorChange = () => {
      if (document.activeElement.className.includes('ql-editor')) {
        setUpdateReqCount(prev => prev + 1)
      }
    }

    const handleDrop = (file, cb) => {
      handleAttachmentUpload(file, source => {
        const fileType = file.type.split('/')[0]
        if (fileType === 'image' || fileType === 'video') {
          handleInsertEmbed({ source, fileType })
          cb()
        }
      })
    }

    if (!taskId) return null

    return (
      <>
        <div className="mb-4">
          <FieldLabel>{t('DESCRIPTION')}</FieldLabel>
        </div>
        <div className="text-sm">
          <DescriptionEditor
            ref={editorRef}
            readOnly={!innerProjectPermission.update || uploading}
            workspace={workspace}
            onDrop={true}
            dropHandler={handleDrop}
            filePasteHandler={handleImagePaste}
            className={classes.editor}
            onChange={handleEditorChange}
            loading={uploading}
            triggerUpdate={() => setUpdateReqCount(prev => prev + 1)}
            taskId={taskId}
            elmFor={elmFor}
            collaborators={collaborators}
          />
        </div>
      </>
    )
  }
)

const useStyles = makeStyles({
  editor: {
    '& .ql-editor': {
      minHeight: 260,
      overflow: 'auto'
    }
  }
})

export default React.memo(TaskDescription)
