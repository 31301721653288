import React, { useState, useEffect } from 'react'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Tr,
  Td
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { useSelector, useDispatch } from 'react-redux'
import { PopupMenu, Tooltip } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { updateService, deleteService } from 'thunks/service/actions'
import currency from 'static/Currency/Currency'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { useTranslation } from 'react-i18next'
import { useModal } from 'customHooks'

const totalServicesToShow = 8

const AddOnsTable = ({ search = '', openEditAddOnModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [visibleAddons, setVisibleAddOns] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const permission = useSelector(state => state.permission)
  const { loading: addOnsLoading, data: addOnsData } = useSelector(
    state => state.userServices.addOns
  )
  const {
    modal: alertModal,
    openModal: openAlert,
    closeModal: closeAlert,
    toggleLoading: toggleAlertLoading
  } = useModal()
  // const [copy, setCopy] = useState('')

  useEffect(() => {
    if (addOnsData.length) {
      if (search.length > 0) {
        let arr = [...addOnsData].filter(item =>
          item?.name.toLowerCase().includes(search.toLowerCase())
        )
        setVisibleAddOns(
          arr.splice(page * totalServicesToShow, totalServicesToShow)
        )
        setPaginationTotalDataCount(arr?.length)
      } else {
        setVisibleAddOns(
          [...addOnsData].splice(
            page * totalServicesToShow,
            totalServicesToShow
          )
        )
        setPaginationTotalDataCount(addOnsData.length)
      }
    } else {
      setVisibleAddOns([])
    }
  }, [addOnsData, page, search, paginationTotalDataCount])

  const openServiceAlert = data => {
    if (permission.data.access?.services.delete) {
      openAlert(data)
    } else {
      dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
    }
  }

  const handleDeleteService = () => {
    toggleAlertLoading()
    dispatch(
      deleteService({ id: alertModal.data._id }, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(t('SERVICE_DELETED_SUCCESSFULLY')))
          closeAlert()
        } else {
          toggleAlertLoading()
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th className="whitespace-nowrap">{t('NAME')}</Th>
          <Th className="text-right">{t('AMOUNT')}</Th>
          <Th className="text-center whitespace-nowrap">{t('CREATED_AT')}</Th>
          <Th className="text-center">{t('OFFERINGS')}</Th>
          {(permission.data.access?.services?.update ||
            permission.data.access?.services?.delete) && (
            <Th className="text-center">{t('ACTION')}</Th>
          )}
        </Thead>
        <Tbody>
          {addOnsLoading ? (
            <TableDataLoading cols={7} />
          ) : visibleAddons.length > 0 ? (
            visibleAddons.map(item => (
              <Row
                key={item?._id}
                item={item}
                access={permission.data.access}
                openEditAddOnModal={openEditAddOnModal}
                openServiceAlert={openServiceAlert}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <td
                colSpan="8"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                {t('THERE_ARE_NO_SERVICES')}
              </td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>

      <Pagination
        handlePagination={setPage}
        totalResultsCount={paginationTotalDataCount}
        page={page}
        limit={totalServicesToShow}
      />
      <AlertModal
        warningText={t('WARNING_DELETE_MESSAGE', {
          data: alertModal.data.name
        })}
        open={alertModal.open}
        handleDialog={closeAlert}
        handleDeleteAction={handleDeleteService}
        loading={alertModal.loading}
      />
    </div>
  )
}

const Row = ({ item, access, openEditAddOnModal, openServiceAlert }) => {
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  const dateFormatter = date => {
    return date.slice(0, 10)
  }

  const getMenuItems = () => {
    let arr = []
    if (access?.services.update) {
      arr.push({
        label: t('EDIT'),
        method: handleEditClick,
        icon: <EditIcon className="text-primary-main dark:text-dark-light" />
      })
    }
    if (access?.services.delete) {
      arr.push({
        label: t('DELETE'),
        method: handleDeleteClick,
        icon: <DeleteIcon />,
        isDanger: true
      })
    }
    return arr
  }

  const handleEditClick = () => {
    openEditAddOnModal(item)
  }

  const handleDeleteClick = () => {
    openServiceAlert(item)
  }

  return (
    <Tr key={item._id}>
      <Td className=" px-6 py-4 whitespace-nowrap">
        <div>
          <span className="text-primary-mid-dark font-medium dark:text-dark-light">
            {item.name}
          </span>
        </div>
      </Td>
      <Td className="text-right text-custom-gray-main">
        {item.currency && currency[item.currency.toUpperCase()]?.symbol_native}
        {item.amount * 0.01}
      </Td>
      <Td className="text-center text-xs text-custom-gray-main">
        {new Date(dateFormatter(item.createdAt)).toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })}
      </Td>
      <Td className="text-center text-xs text-custom-gray-main">
        <Tooltip
          title={item.offerings.length + ' offering(s) offered'}
          style={{ marginTop: '10px' }}
        >
          <span>{item?.offerings?.length}</span>
        </Tooltip>
      </Td>
      {(access?.services.update || access?.services.delete) && (
        <Td className="text-center whitespace-nowrap">
          <PopupMenu menuItems={getMenuItems()} />
        </Td>
      )}
    </Tr>
  )
}

export default AddOnsTable
