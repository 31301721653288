import axios from 'axios'
import { GETTING_STARTED } from './reducers'
import { catchAsyncDispatch } from 'utils'

export const gettingAllTasksStatus = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    dispatch({ type: GETTING_STARTED.LOADING })

    const res = await axios({
      url: '/user/getting-started',
      method: 'GET'
    })

    dispatch({ type: GETTING_STARTED.FETCHED, payload: res.data })
    if (callback) callback(res.data, false)
  }, callback)
}

// export const gettingStartedClient = callback => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: '/onboarding/user'
//       })

//       dispatch({ type: GETTING_STARTED.FETCHED, payload: res.data })
//       callback(res.data)
//     } catch (err) {
//       callback(
//         err?.response?.data?.message ?? "Couldn't fetch template data!",
//         true
//       )
//     }
//   }
// }

// /**
//  * Fetch steps completed or not data
//  * @param {Function} callback optional
//  */
// export const fetchGettingStartedAnalytics = callback => {
//   return async (dispatch, getState) => {
//     try {
//       const res = await axios({
//         url: '/clientonboardingresponse/user',
//         method: 'GET'
//       })

//       dispatch({
//         type: GETTING_STARTED.FETCHED_ANALYTICS,
//         payload: typeof res.data[0] === 'object' ? res.data[0] : {}
//       })
//       if (callback) callback(res.data)
//     } catch (err) {
//       callback(
//         err?.response?.data?.message ?? "Couldn't fetch template data!",
//         true
//       )
//     }
//   }
// }

// /**
//  * Fetch steps completed or not data
//  * @param {Object} data
//  * @param {Function} callback optional
//  */
// export const createGettingStartedAnalytics = (data, callback) => {
//   return async (dispatch, getState) => {
//     const { gettingStartedTasks } = getState()

//     try {
//       const res = await axios({
//         url: '/clientonboardingresponse',
//         method: 'POST',
//         data: {
//           template: gettingStartedTasks.data._id,
//           response: data
//         }
//       })

//       dispatch({ type: GETTING_STARTED.UPDATE_ANALYTICS, payload: res.data })
//       if (callback) callback(res.data)
//     } catch (err) {
//       callback(
//         err?.response?.data?.message ?? "Couldn't fetch template data!",
//         true
//       )
//     }
//   }
// }

// /**
//  * Update steps completed or not data
//  * @param {String} ID analytics data id
//  * @param {Object} data
//  * @param {Function} callback optional
//  */
// export const updateGettingStartedAnalytics = (analyticsId, data, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         url: `/clientonboardingresponse/${analyticsId}`,
//         method: 'PUT',
//         data: {
//           response: data
//         }
//       })

//       dispatch({
//         type: GETTING_STARTED.UPDATE_ANALYTICS,
//         payload: res.data
//       })
//       if (callback) callback(res.data)
//     } catch (err) {
//       callback(
//         err?.response?.data?.message ?? "Couldn't fetch template data!",
//         true
//       )
//     }
//   }
// }
