import React, { useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import { InvoiceTemplate1, InvoiceTemplate2 } from 'static/Images'
import { FilePreview, OutlinedButton } from 'global/globalComponents'
import { Button } from '@material-ui/core'

const TemplatesPreviewModal = ({ open, onClose, ...rest }) => {
  return (
    <CustomModal open={open} handleClose={onClose}>
      <TemplatesPreviewModalContent onClose={onClose} {...rest} />
    </CustomModal>
  )
}

const TemplatesPreviewModalContent = ({
  onClose,
  handleSelectTemplate,
  selectedInvoiceTemplateId
}) => {
  const { t } = useTranslation()
  const [filePreviewModal, setFilePreviewModal] = useState({
    open: false,
    files: [],
    fileIndex: 0
  })
  const templates = [
    { id: 1, name: `${t('TEMPLATE')}-1`, url: InvoiceTemplate1 },
    { id: 2, name: `${t('TEMPLATE')}-2`, url: InvoiceTemplate2 }
  ]

  const handleOpenPreview = fileIndex => {
    if (filePreviewModal.open) {
      setFilePreviewModal(prev => ({ ...prev, open: false }))
    } else {
      const files = templates.map(template => {
        const file = {
          ...template,
          type: 'image',
          extension: template.url.slice(template.url.lastIndexOf('.'))
        }

        return file
      })

      setFilePreviewModal({ open: true, files, fileIndex })
    }
  }

  return (
    <>
      <CustomModalHeader heading={t('TEMPLATES')} handleClose={onClose} />

      <CustomModalBody>
        <div className="flex flex-wrap space-x-4 pt-4 pb-12">
          {templates.map((template, index) => (
            <div key={template.name}>
              <div
                className="w-40 h-28 rounded-md bg-gray-100 cursor-pointer border"
                onClick={() => handleOpenPreview(index)}
              >
                <img
                  src={template.url}
                  alt={template.name}
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex items-center text-smaller mt-2">
                <span className="mr-1">{template.name}</span>
                <OutlinedButton
                  isActive={template.id === selectedInvoiceTemplateId}
                  className="text-primary-main"
                  onClick={() => handleSelectTemplate(template.id)}
                >
                  {template.id === selectedInvoiceTemplateId
                    ? t('SELECTED')
                    : t('SELECT')}
                </OutlinedButton>
              </div>
            </div>
          ))}
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button onClick={onClose} size="large">
          {t('CLOSE')}
        </Button>
      </CustomModalFooter>

      <FilePreview
        open={filePreviewModal.open}
        files={filePreviewModal.files}
        fileIndex={filePreviewModal.fileIndex}
        onClose={handleOpenPreview}
      />
    </>
  )
}

export default TemplatesPreviewModal
