import React from 'react'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { Skeleton } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { useBreadcrumbs } from 'customHooks'
import { useTranslation } from 'react-i18next'
import { ServiceCard } from 'components/_shared'
import { useHistory, useRouteMatch } from 'react-router-dom'

const Services = ({ selectedCategory }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { path } = useRouteMatch()
  const userServices = useSelector(state => state.userServices)
  let filteredServices = userServices.data.filter(service => {
    if (!selectedCategory) return true
    return service.category === selectedCategory
  })

  useBreadcrumbs([
    {
      name: t('SERVICES'),
      link: `/services`,
      index: 0
    }
  ])

  const handleServiceCardClick = serviceId => {
    history.push(`${path}/${serviceId}/checkout`)
  }

  return (
    <OuterContentWrapper className="w-full overflow-auto">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
          gridGap: '2rem 1rem'
        }}
      >
        {userServices.loading ? (
          <>
            {[...Array(3)].map(() => (
              <div className="mt-6 grid">
                <Skeleton
                  variant="rect"
                  height={180}
                  animation="wave"
                  className="rounded-lg"
                />
              </div>
            ))}
          </>
        ) : (
          <>
            {filteredServices.length > 0 ? (
              filteredServices.map(service => (
                <ServiceCard
                  key={service._id}
                  service={service}
                  onClick={handleServiceCardClick}
                />
              ))
            ) : (
              <div className="col-span-7">
                <p className="text-gray-500 font-medium">
                  {t('NO_DATA_FOUND')}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </OuterContentWrapper>
  )
}

export default Services
