import { makeStyles, createStyles } from '@material-ui/core'

const globalCustomStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      width: '70vw',
      maxWidth: '600px',
      marginTop: theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    },

    optionBtnLeft: {
      borderWidth: ' 0px 2px 0px 0px',
      lineHeight: '1',
      padding: '2px 8px',
      fontSize: '12px',
      fontWeight: 500
    },

    inputField: {
      padding: '0.5rem',
      fontSize: '13px',

      '&:focus-within': {
        border: `1px solid ${theme.palette.primary.main}`
      }
    },

    card: {
      minWidth: '400px'
    },

    loginHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      // alignItems: 'center',
      fontWeight: '400',
      // padding: '5%',
      paddingTop: '0%',
      width: 'inherit'
    },

    para: {
      marginBottom: '5px',
      fontWeight: '500',
      color: '#464646',
      fontSize: '0.75rem',
      letterSpacing: '0.321px'
    },
    signupBtn: {
      width: '100%',
      marginTop: '9px',
      position: 'relative',
      height: '2.2rem',
      fontSize: '1rem'
    },
    buttonProgress: {
      color: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    subHeading: {
      textAlign: 'center',
      margin: '0px 18%'
    },

    paper: {
      background: 'transparent',
      textAlign: 'center'
    },
    Login: {
      fontWeight: '900',
      fontSize: '1.6rem',
      lineHeight: '1.6rem',
      color: '#464646',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    loginParent: {
      display: 'flex',
      flexDirection: 'row',
      width: '100 %',
      justifyContent: 'center',
      position: 'relative',
      margin: '0',
      padding: '0',
      border: '0 solid #c8c8c8',
      borderCollapse: 'inherit',
      boxShadow: 'none',
      boxSizing: 'border-box',
      // minHeight: '30rem',
      flexWrap: 'wrap'
    },
    leftCard: {
      display: 'flex',
      // minHeight: '30rem',
      maxHeight: '50rem',
      borderRadius: '5px',
      alignSelf: 'center',
      background: '#fff',
      //boxShadow: '0 10px 12px 0 rgba(0,43,86,.22)',
      marginRight: '2rem',
      width: '25.5rem'
    },
    formContainer: {
      width: '100%',
      minWidth: '409px',
      padding: '4rem 1.5rem',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'baseline'
    },
    signinInput: {
      backgroundColor: '#fff',
      borderRadius: '0.375rem',
      // border: 'none',
      padding: '0%',
      paddingLeft: '2%',
      margin: '2% 0% 6%',
      height: '2.375rem ',
      fontSize: '0.8rem',
      '&:hover, &:focus-within': {
        border: `1px solid ${theme.palette.primary.main}`
      }
    },
    errorSigninInput: {
      backgroundColor: '#fff',
      borderRadius: '4px',
      paddingLeft: '0px',
      border: '1px solid #ff5a50',
      padding: '0%',
      margin: '3% 0%',
      height: '2.2rem ',
      fontSize: '1rem'
    },
    [theme.breakpoints.down('lg')]: {
      formHeading: {
        fontSize: '2em',
        marginTop: '8%',
        lineHeight: '1.25em'
      },
      subHeading: {
        margin: '3%',
        lineHeight: '3.75em',
        fontSize: '0.71em',
        textAlign: 'center'
      },
      bottomText: {
        display: 'none'
      },
      formContainer: {
        width: '100%',
        padding: '4.5%',
        minWidth: '215px',
        justifyContent: 'center'
      },
      loginParent: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        margin: '0',
        padding: '0%',
        width: 'inherit'
      },
      leftCard: {
        display: 'flex',
        maxHeight: '30rem',
        padding: '1%',
        alignSelf: 'center',
        background: '#fff',
        //  boxShadow: '0 10px 12px 0 rgba(0,43,86,.22)',
        margin: '0% 1%',
        marginRight: '2rem'
      },
      signinInput: {
        margin: '2% 0% 1%'
      }
    },

    [theme.breakpoints.down('md')]: {
      subHeading: {
        margin: '3%',
        lineHeight: '3.75em',
        fontSize: '0.71em',
        textAlign: 'center'
      },
      bottomText: {
        display: 'none'
      },
      formContainer: {
        width: '100%',
        padding: '4.5%',
        minWidth: '215px',
        justifyContent: 'center'
      },
      loginParent: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        margin: '0',
        padding: '0%',
        width: 'inherit'
      },
      leftCard: {
        display: 'flex',
        maxHeight: '60rem',
        padding: '1%',
        alignSelf: 'center',
        background: '#fff',
        //boxShadow: '0 10px 12px 0 rgba(0,43,86,.22)',
        margin: '0% 1%',
        marginRight: '2rem'
      },
      signinInput: {
        margin: '2% 0% 10%'
      }
    },

    [theme.breakpoints.down('sm')]: {
      formHeading: {
        fontSize: '2em',
        marginTop: '8%',
        lineHeight: '1.25em'
      },
      subHeading: {
        margin: '3%',
        lineHeight: '3.75em',
        fontSize: '0.71em',
        textAlign: 'center'
      },
      bottomText: {
        display: 'none'
      },
      formContainer: {
        width: '100%',
        padding: '4.5%',
        minWidth: '215px'
      },
      loginParent: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        margin: '0',
        padding: '0%',
        width: 'inherit'
      },
      leftCard: {
        display: 'flex',
        maxHeight: '30rem',
        padding: '0%',
        alignSelf: 'center',
        background: '#fff',
        boxShadow: 'none',
        margin: '0% 0%',
        border: 'none'
      },
      signinInput: {
        margin: '2% 0% 10%'
      }
    }
  })
)

export default globalCustomStyles
