import React from 'react'
import clsx from 'clsx'
import { CircularProgress } from '@material-ui/core'

const variantClasses = {
  primary:
    'text-primary-main bg-primary-light border-primary-light dark:bg-dark-main',
  primary__hover:
    'hover:bg-primary-light hover:text-primary-main hover:border-primary-main',
  success: 'bg-green-50 text-green-500 border-green-500 dark:bg-dark-main4',
  success__hover:
    'hover:bg-green-50 hover:border-green-500 hover:text-green-500',
  danger: 'bg-red-50 text-red-500 border-red-500 dark:bg-dark-main4',
  danger__hover: 'hover:bg-red-50 hover:border-red-500 hover:text-red-500'
}

const OutlinedButton = ({
  variant = 'primary',
  onClick,
  isActive,
  children,
  roundedFull = true,
  hoverColorChange = true,
  className,
  loading,
  ...rest
}) => {
  return (
    <button
      className={clsx(
        'text-xs 2xl:text-smaller flex justify-center items-center px-2 py-1 border whitespace-nowrap dark:border-dark-main3 relative',
        hoverColorChange && variantClasses[`${variant}__hover`],
        isActive
          ? variantClasses[variant]
          : 'text-gray-500 bg-white dark:bg-dark-main dark:text-dark-light',
        roundedFull ? 'rounded-full' : 'rounded',
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <CircularProgress size={20} color="inherit" />
        </div>
      )}
      {children}
    </button>
  )
}

export default OutlinedButton
