import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const BasicLayout = ({ elmFor, children }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.root,
        'flex items-center justify-center',
        elmFor === 'preview' ? 'w-full h-full' : 'w-screen h-screen'
      )}
    >
      <div
        className="flex-1 py-8 px-10"
        style={{ maxWidth: 450, minWidth: 350 }}
      >
        {children}
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fafafd'
  }
})

export default BasicLayout
