import axios from 'axios'
import { INTEGRATIONS } from './reducers'
import { catchAsync, catchAsyncDispatch } from 'utils'

/**
 * Add integration
 * @param {Object} data.data app data
 * @param {Function} callback callback function
 */
export const addIntegration = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/connectedapps',
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: INTEGRATIONS.ADD_ONE_INTEGRATION, payload: res.data })
  }, callback)
}

// /**
//  * Find INTEGRATION APP by Id
//  * @param {String} appId APP ID
//  * @param {Function} callback callback function
//  */
// export const findIntegrationById = (appId, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/connectedapps/${appId}`
//       })
//       if (callback) callback(res.data, false)
//       dispatch({ type: INTEGRATIONS.FETCHED, payload: res.data })
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//       dispatch({
//         type: INTEGRATIONS.ERROR,
//         payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
//       })
//     }
//   }
// }

/**
 * update data of a integrated app
 * @param {String} aapid integrated app id
 * @param {Object} data updating data
 * @param {Function} callback callback function
 */
export const updateIntegratedApp = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/connectedapps/${data.appId}`,
      data: data.data
    })
    if (callback) callback(res.data)
    dispatch({ type: INTEGRATIONS.UPDATE_ONE_INTEGRATION, payload: res.data })
  }, callback)
}

/**
 * delete integrated app
 * @param {String} data.appId integrated app id
 * @param {Function} callback callback function
 */
export const deleteIntegratedApp = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/connectedapps/${data.appId}`
    })
    if (callback) callback(res.data)
    dispatch({ type: INTEGRATIONS.DELETE_ONE_INTEGRATION, payload: res.data })
  }, callback)
}

/**
 * fetches all integrations of a user
 * @param {Function} callback callback function
 */
export const fetchAllIntegrationsForCurrentUser = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/connectedapps/user'
    })

    if (callback) callback(res.data, false)
    dispatch({ type: INTEGRATIONS.FETCHED, payload: res.data })
  }, callback)
}

/**
 * Integrate dropbox
 * @param {Function} callback callback function
 */
export const integrateDropbox = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/connectedapps/auth',
    data: {
      appName: 'dropbox',
      redirect_url: data.redirectUrl
    }
  })
  if (callback) callback(res.data)
})

/**
 * Integrate g-drive
 * @param {Function} callback callback function
 */
export const integrateGDrive = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/gdrive',
    data: data.data
  })
  if (callback) callback(res.data)
})

export const fetchConnectedApps = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `connectedapps/client/${data.agencyOwnerId}`
  })

  if (callback) callback(res.data)
})

/**
 *
 * @param {String} data.displayName
 * @param {String} data.type
 * @param {Object} data.data.credentials clientId, clientSecret
 * @param {*} callback
 * @returns
 */
export const addPaypalIntegration = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/connectaccount/paypal',
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: INTEGRATIONS.ADD_ONE_INTEGRATION, payload: res.data })
  }, callback)
}

/**
 *
 * @param {String} data.id integration id
 * @param {Object} data.data type, credentials
 * @param {*} callback
 * @returns
 */
export const updatePaypalIntegration = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/connectaccount/${data.id}`,
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: INTEGRATIONS.UPDATE_ONE_INTEGRATION, payload: res.data })
  }, callback)
}

export const integrateNewApp = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/connectedapps/auth/new',
    data: {
      provider: data.app,
      redirectUrl: data.redirectUrl
    }
  })
  if (callback) callback(res.data)
})

export const addNewIntegration = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/connectedapps/new',
      data: data
    })

    if (callback) callback(res.data)
    dispatch({ type: INTEGRATIONS.ADD_ONE_INTEGRATION, payload: res.data })
  }, callback)
}

export const createZoomMeeting = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/connectedapps/zoom/createMeeting',
      data: data
    })
    if (callback) callback(res.data)
  }, callback)
}

export const deleteZoomMeeting = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/connectedapps/zoom/deleteMeeting/${data.meetingId}`
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * @param {null} data
 * @param {Function} callback
 * @returns
 */
export const fetchWoocommerceProducts = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/connectedapps/sync/woocommerce_product'
  })
  if (callback) callback(res.data)
})

/**
 * Fetches Google calendar events
 * @param {Object} data.params startDate, endDate
 * @param {Function} callback
 * @returns
 */
export const fetchGoogleCalendarEvents = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/connectedapps/calender/events',
    params: data.params
  })

  if (callback) callback(res.data)
})

/**
 * Fetches HubSpot contacts
 * @param {Function} callback
 * @returns
 */
export const fetchHubSpotContacts = catchAsync(async callback => {
  const res = await axios({
    method: 'GET',
    url: '/connectedapps/hubspot/properties/contacts'
  })

  const filteredData = res.data.filter(
    field => field.groupName === 'contactinformation' && !field.hidden
  )

  if (callback) callback(filteredData)
})
