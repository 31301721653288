import React, { useMemo, useRef, useState } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { getMentionConfig } from '../editorsStatic'

const EditCommentEditor = ({
  comment,
  socketId,
  users,
  handleCancel,
  handleUpdateComment
}) => {
  // const dispatch = useDispatch()
  const classes = useStyles()
  const editorRef = useRef()
  const meData = useSelector(state => state.me.data)
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const [loading, setLoading] = useState(false)

  const modules = useMemo(() => {
    const mention = getMentionConfig(users)

    const obj = {
      toolbar: {
        container: '#quill-toolbar'
      },
      magicUrl: true,
      mention: {
        ...mention,
        onSelect: (item, insertItem) => {
          const user = users.find(user => user._id === item.id)
          insertItem({ ...item, ...user, notify: meData._id !== user._id })
        }
      }
    }
    return obj
  }, [])

  const handleOnSave = () => {
    if (loading) return
    const editor = editorRef.current.editor
    const commentData = {
      description: editor.getContents(),
      socketId
    }

    setLoading(true)
    handleUpdateComment(commentData)
  }

  return (
    <div className="flex-1 ml-2 border rounded bg-white dark:bg-dark-main">
      <ReactQuill
        theme="snow"
        defaultValue={comment.description}
        modules={modules}
        ref={editorRef}
        placeholder="Ask a question or post an update..."
        className={classes.editor}
      />

      <CustomToolbar
        isWSExternal={currentWorkspace.type === 'External'}
        loading={loading}
        classes={classes}
        handleCancel={handleCancel}
        handleOnSave={handleOnSave}
      />
    </div>
  )
}

const CustomToolbar = ({ loading, classes, handleCancel, handleOnSave }) => {
  return (
    <div
      className={clsx('flex items-center', classes.toolbar)}
      id="quill-toolbar"
    >
      <div>
        <button className="ql-bold" title="bold" />
        <button className="ql-italic" title="italic" />
      </div>

      <div className="ml-auto">
        <button
          className="custom-btn btn-cancel"
          disabled={loading}
          onClick={handleCancel}
        >
          Cancel
        </button>

        <button className="relative custom-btn btn-save" onClick={handleOnSave}>
          <div
            className={clsx(
              'absolute top-0 left-0 w-full h-full justify-center items-center',
              loading ? 'flex' : 'hidden'
            )}
          >
            <CircularProgress size={20} color="#ccc" />
          </div>
          Save
        </button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  editor: {
    height: 100,
    overflow: 'auto',
    '& .ql-container.ql-snow': {
      border: 'none'
    }
  },

  toolbar: {
    '&.ql-toolbar.ql-snow': {
      border: 'none',
      padding: '0 10px',
      height: 55
    },

    '&.ql-toolbar.ql-snow .custom-btn': {
      width: 82,
      height: 38,
      borderRadius: 8,
      fontSize: 13,
      cursor: 'pointer',
      opacity: 0.8,

      '&:hover': {
        opacity: 1
      },

      '&:disabled': {
        opacity: 0.7,
        cursor: 'default'
      }
    },

    '&.ql-toolbar.ql-snow .btn-save': {
      background: theme.palette.primary.main,
      color: '#fff'
    },

    '&.ql-toolbar.ql-snow .btn-cancel': {
      background: '#fff',
      color: '#111',
      border: '1px solid #818181',
      marginRight: 10
    }
  }
}))

export default EditCommentEditor
