import React, { useEffect } from 'react'
import CreateCouponModal from './CreateCouponModal/CreateCouponModal'
import { useModal } from 'customHooks'
import { useDispatch } from 'react-redux'
import { fetchAllCoupons } from 'thunks/coupon/actions'
import CouponsTable from './CouponsTable'
import CouponsSectionHeader from './CouponsSectionHeader'

const CouponsSection = () => {
  const dispatch = useDispatch()
  const { modal, openModal, closeModal } = useModal()

  useEffect(() => {
    dispatch(fetchAllCoupons())
  }, [])

  return (
    <>
      <CouponsSectionHeader openModal={openModal} />
      <CouponsTable openModal={openModal} />
      <CreateCouponModal
        data={modal.data}
        open={modal.open}
        onClose={closeModal}
      />
    </>
  )
}

export default CouponsSection
