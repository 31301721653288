import React, { useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  // Popover,
  makeStyles
  // Popper,
  // ClickAwayListener
} from '@material-ui/core'
import clsx from 'clsx'
import CustomPopper from '../CustomPopper/CustomPopper'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
import { toCamelCase } from 'utils'

const PopupMenu = ({ menuItems, targetComp, onClick, placement, btnId }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  // const arrowRef = useRef();
  // const [arrowRef, setArrowRef] = useState(null)

  const handleOpenMenu = e => {
    e.stopPropagation()
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      if (onClick) onClick()
      setAnchorEl(e.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {!targetComp ? (
        <button id={btnId} onClick={handleOpenMenu}>
          <ThreeDotsIcon className="w-4 h-4 lg:w-5 lg:h-5 dark:text-dark-light" />
        </button>
      ) : (
        <>{React.cloneElement(targetComp, { onClick: handleOpenMenu })}</>
      )}

      <CustomPopper
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        placement={placement ? placement : 'bottom-end'}
      >
        <div className={clsx('dark:text-dark-light', classes.menuPaper)}>
          <List className={classes.list}>
            {menuItems.map((item, index) => (
              <SingleListItem
                key={index}
                item={item}
                handleCloseMenu={handleCloseMenu}
                classes={classes}
              />
            ))}
          </List>
        </div>
      </CustomPopper>
    </>
  )
}

export const SingleListItem = ({ item, handleCloseMenu, classes }) => {
  const handleClick = e => {
    handleCloseMenu()
    item.method(e, item)
  }

  return (
    <ListItem
      onClick={handleClick}
      id={toCamelCase(`popup ${item.label}`)}
      // classes={{ gutters: classes.listItem }}
      button
    >
      <div className="flex items-center py-1">
        {item.icon && (
          <span
            className={clsx(
              'mr-2 dark:text-dark-light',
              item.isDanger ? 'text-red-400' : 'text-primary-main'
            )}
          >
            {item.icon}
          </span>
        )}
        <ListItemText
          primary={
            <p
              className={clsx(
                'capitalize text-xs 2xl:text-sm dark:text-dark-light',
                item.isDanger ? 'text-red-400' : 'text-primary-main'
              )}
            >
              {item.label}
            </p>
          }
          classes={{ root: classes.listItemText }}
        />
      </div>
    </ListItem>
  )
}

export const useStyles = makeStyles(theme => ({
  menuPaper: {
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    maxHeight: 350,
    overflow: 'auto',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.custom.darkMode.dark2 : '#fff'
  },

  list: {
    padding: '4px 0'
  },

  listItem: {
    padding: '0.5rem 1.5rem',
    minWidth: 115
  },

  listItemText: {
    margin: 0
  }
}))

export default React.memo(PopupMenu)
