import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { FormLabel } from 'global/globalComponents'
import React from 'react'
import { useTranslation } from 'react-i18next'

const RaiseForRadioContainer = ({ raiseFor, setRaiseFor }) => {
  const { t } = useTranslation()

  return (
    <div className="mb-3">
      <FormLabel>{t('RAISE_FOR')}</FormLabel>
      <RadioGroup
        row
        value={raiseFor}
        onChange={e => setRaiseFor(Number(e.target.value))}
        name="invoice_type"
      >
        <FormControlLabel
          control={<Radio />}
          value={0}
          label={
            <span className="text-smaller 2xl:text-sm font-normal">
              {t('NONE')}
            </span>
          }
        />
        <FormControlLabel
          control={<Radio />}
          value={1}
          label={
            <span className="text-smaller 2xl:text-sm font-normal">
              {t('SERVICE')}
            </span>
          }
        />
        <FormControlLabel
          control={<Radio />}
          value={2}
          label={
            <span className="text-smaller 2xl:text-sm font-normal">
              {t('PROJECT')}
            </span>
          }
        />
      </RadioGroup>
    </div>
  )
}

export default RaiseForRadioContainer
