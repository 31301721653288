import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import { translationEn, translationEs } from 'static/i18n/Translation'
import SpanishTranslation from './SpanishTranslation.json'
import EnglishTranslation from './EnglishTranslation.json'
import FrenchTranslation from './FrenchTranslation.json'
import DutchTranslation from './DutchTranslation.json'
import PortugueseTranslation from './PortugueseTranslation.json'
import ItalianTranslation from './ItalianTranslation.json'
import GermanTranslation from './GermanTranslation.json'
import PolishTranslation from './PolishTranslation.json'
import TurkishTranslation from './TurkishTranslation.json'

export const languageOptions = [
  {
    language: 'English',
    abbr: 'en',
    translation: EnglishTranslation
  },
  { language: 'Spanish', abbr: 'es', translation: SpanishTranslation },
  { language: 'French', abbr: 'fs', translation: FrenchTranslation },
  { language: 'Portuguese', abbr: 'pt', translation: PortugueseTranslation },
  { language: 'Dutch', abbr: 'nl', translation: DutchTranslation },
  { language: 'Italian', abbr: 'it', translation: ItalianTranslation },
  { language: 'German', abbr: 'de', translation: GermanTranslation },
  { language: 'Polish', abbr: 'pl', translation: PolishTranslation },
  { language: 'Turkish', abbr: 'tr', translation: TurkishTranslation }
]

const i18nConfig = language => {
  const resources = {}

  languageOptions.forEach(item => {
    resources[item.abbr] = { translation: item.translation }
  })

  i18n.use(initReactI18next).init({
    resources,
    lng: language,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })
}

export default i18nConfig
