import React, { useState } from 'react'
import { FormLabel, LoadingButton } from 'global/globalComponents'
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const PasscodeModal = ({ open, onSubmit }) => {
  const { t } = useTranslation()
  const [passcode, setPasscode] = useState({
    value: '',
    error: false,
    errorMessages: ''
  })
  const [loading, setLoading] = useState(false)

  const handlePasscodeChange = e => {
    setPasscode({ value: e.target.value, error: false, errorMessages: '' })
  }

  const verifyPasscode = () => {
    if (!passcode.value) {
      setPasscode(prev => ({
        ...prev,
        error: true,
        errorMessages: t('FIELD_REQUIRED_MESSAGE')
      }))
      return
    }

    setLoading(true)

    onSubmit(passcode.value, err => {
      setLoading(false)
      setPasscode(prev => ({ ...prev, error: true, errorMessages: err }))
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    verifyPasscode()
  }

  return (
    <CustomModal open={open} size="small">
      <CustomModalHeader heading={t('PASSCODE')} />
      <form onSubmit={handleSubmit}>
        <CustomModalBody>
          <div className="h-32">
            <FormLabel required>{t('ENTER_PASSCODE')}</FormLabel>
            <input
              type="number"
              value={passcode.value}
              onChange={handlePasscodeChange}
              className={clsx(
                'w-full block py-2 px-2 border rounded text-sm dark:bg-dark-main',
                passcode.error && 'border-red-400'
              )}
              autoFocus
            />

            <span className="text-xs text-red-400">
              {passcode.error && passcode.errorMessages}
            </span>
          </div>
        </CustomModalBody>
        <CustomModalFooter>
          <LoadingButton type="submit" loading={loading}>
            {t('SEND')}
          </LoadingButton>
        </CustomModalFooter>
      </form>
    </CustomModal>
  )
}

export default PasscodeModal
