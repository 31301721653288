const initialState = []

export const BREADCRUMBS = {
  POP: 'POP_BREADCRUMB',
  PUSH: 'PUSH_BREADCRUMB',
  REPLACE_ALL: 'REPLACE_ALL_BREADCRUMB'
}

const getSortedData = (data) => {
  return data.sort(function (a, b) {
    return a.index - b.index
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BREADCRUMBS.PUSH:
      return getSortedData([...state, action.payload])

    case BREADCRUMBS.POP:
      return getSortedData(state.slice(0, state.length - 1))

    case BREADCRUMBS.REPLACE_ALL:
      return [...action.payload]

    default:
      return state
  }
}
export default reducer
