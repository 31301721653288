import axios from 'axios'
import { catchAsync } from 'utils'

export const createCustomURL = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/custom ',
    data: data.data
  })
  if (callback) callback(res.data)
})

export const updateCustomURL = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/custom/${data.linkId}`,
    data: data.data
  })
  if (callback) callback(res.data)
})

export const fetchCustomURLData = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/custom?url=${data.originalURL}`
  })
  if (callback) callback(res.data)
})

export const deleteCustomURL = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/custom/${data.linkId}`
  })
  if (callback) callback(res.data)
})

export const fetchActualPublicUrlData = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/custom/public?customUrl=${data.customURL}`,
    isPublic: true
  })

  if (callback) callback(res.data)
})
