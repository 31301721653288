import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import StandupsList from './StandupsList'

const EveningStandups = ({
  toggleStandupCompletion,
  toggleDeleteStandupAlert,
  openEditTaskPanel,
  standupsData
}) => {
  const meData = useSelector(state => state.me.data)
  const { t } = useTranslation()

  return (
    <div>
      {!Boolean(standupsData.length) && (
        <div>
          <h3 className="font-semibold text-lg 2xl:text-xl mb-1 dark:text-dark-light2">
            {t('HELLO')} {meData.name}!
          </h3>
          <p className="text-smaller 2xl:text-sm leading-6 dark:text-dark-light">
            {t('EVENING_STANDUP_MSG')}
          </p>
        </div>
      )}

      <StandupsList
        data={standupsData}
        toggleStandupCompletion={toggleStandupCompletion}
        toggleDeleteStandupAlert={toggleDeleteStandupAlert}
        openEditTaskPanel={openEditTaskPanel}
        canUpdate={true}
        canDelete={true}
      />
    </div>
  )
}

export default React.memo(EveningStandups)
