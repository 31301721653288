import axios from 'axios'
import { getErrorMessages } from 'utils/errorMessages'
import { VIEWS } from './reducer'
import { catchAsyncDispatch, storeCustomizeData } from 'utils'

const errorMessages = getErrorMessages()

/**
 * Create view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const createReportsView = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/views',
        data
      })

      dispatch({ type: VIEWS.ADD_REPORTS_VIEW, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const createProjectListView = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/views',
        data
      })

      dispatch({ type: VIEWS.ADD_PROJECT_LIST_VIEW, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const updateReportsView = (viewId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/views/${viewId}`,
        data
      })

      dispatch({ type: VIEWS.UPDATE_REPORTS_VIEW, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
/**
 * Create view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const updateProjectListView = (viewId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/views/${viewId}`,
        data
      })

      dispatch({ type: VIEWS.UPDATE_PROJECT_LIST_VIEW, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch single view
 * @param {Function} callback
 **/
export const fetchReportsView = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/views?type=report`
      })

      dispatch({ type: VIEWS.FETCHED_REPORTS_VIEW, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (err?.response?.status === 404) {
        dispatch({ type: VIEWS.FETCHED_REPORTS_VIEW, payload: {} })
        if (callback) callback({})
      } else {
        if (callback) {
          callback(
            err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
            true
          )
        }
      }
    }
  }
}

/**
 * Fetch single view
 * @param {String} type (report / projectList)
 * @param {Function} callback
 **/
export const fetchProjectListView = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/views?type=projectList`
      })

      dispatch({ type: VIEWS.FETCHED_PROJECT_LIST_VIEW, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (err?.response?.status === 404) {
        dispatch({ type: VIEWS.FETCHED_PROJECT_LIST_VIEW, payload: {} })
        if (callback) callback({})
      } else {
        if (callback) {
          callback(
            err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
            true
          )
        }
      }
    }
  }
}

// Project Tab APIS

/**
 * Create view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const createProjectTabView = (data, key, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/views',
        data
      })
      if (callback) callback(res.data)
      if (key === 'private') {
        dispatch({ type: VIEWS.ADD_PROJECT_TAB_VIEW, payload: res.data })
      }
    } catch (err) {
      console.error(err)
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const updateProjectTabView = (viewId, data, key, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/views/${viewId}`,
        data
      })
      if (key === 'private') {
        dispatch({ type: VIEWS.UPDATE_PROJECT_TAB_VIEW, payload: res.data })
      }
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch single view
 * @param {String} type (report / projectList)
 * @param {Function} callback
 **/
export const fetchProjectTabView = (wsId, type, key, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/views?type=${type}&workspace=${wsId}`
      })
      // if (key === 'private') {
      //   dispatch({ type: VIEWS.FETCHED_PROJECT_TAB_VIEW, payload: res.data })
      // }
      if (callback) callback(res.data, false)
    } catch (err) {
      if (err?.response?.status === 404) {
        // if (key === 'private') {
        //   dispatch({ type: VIEWS.FETCHED_PROJECT_TAB_VIEW, payload: {} })
        // }
        // if (callback && key === 'private') callback({})
        if (callback && key === 'share') callback({}, true)
      } else {
        if (callback) {
          callback(
            err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
            true
          )
        }
      }
    }
  }
}

// Project Widget Columns

/**
 * Create view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const createProjectWidgetView = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/views',
        data
      })
      if (callback) callback(res.data)
      dispatch({ type: VIEWS.ADD_PROJECT_WIDGET_VIEW, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const updateProjectWidgetView = (viewId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/views/${viewId}`,
        data
      })
      if (callback) callback(res.data)
      dispatch({ type: VIEWS.UPDATE_PROJECT_WIDGET_VIEW, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch single view
 * @param {String} type (projectWidget)
 * @param {Function} callback
 **/
export const fetchProjectWidgetView = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/views?type=projectWidget`
      })

      if (callback) callback(res.data, false)
      dispatch({ type: VIEWS.FETCHED_PROJECT_WIDGET_VIEW, payload: res.data })
    } catch (err) {
      if (err?.response?.status === 404) {
        dispatch({ type: VIEWS.FETCHED_PROJECT_WIDGET_VIEW, payload: {} })
        if (callback) callback({})
      } else {
        if (callback) {
          callback(
            err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
            true
          )
        }
      }
    }
  }
}

// Project Table Columns

/**
 * Create view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const createProjectTableView = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/views',
        data
      })
      if (callback) callback(res.data)
      dispatch({ type: VIEWS.ADD_PROJECT_TABLE_VIEW, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update view
 * @param {Object} contents, type
 * @param {Function} callback
 **/
export const updateProjectTableView = (viewId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/views/${viewId}`,
        data
      })
      if (callback) callback(res.data)
      dispatch({ type: VIEWS.UPDATE_PROJECT_TABLE_VIEW, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch single view
 * @param {String} type (projectWidget)
 * @param {Function} callback
 **/
export const fetchProjectTableView = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/views?type=projectTable`
      })

      if (callback) callback(res.data, false)
      dispatch({ type: VIEWS.FETCHED_PROJECT_TABLE_VIEW, payload: res.data })
    } catch (err) {
      if (err?.response?.status === 404) {
        dispatch({ type: VIEWS.FETCHED_PROJECT_TABLE_VIEW, payload: {} })
        if (callback) callback({})
      } else {
        if (callback) {
          callback(
            err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
            true
          )
        }
      }
    }
  }
}

/**
 * Update view
 * @param {Object} data { type: string, content: array}
 * @param {Function} callback
 **/
export const updateProjectPageView = (viewId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/views/${viewId}`,
        data
      })
      if (callback) callback(res.data)
      dispatch({ type: VIEWS.UPDATE_PROJECT_PAGE_VIEW, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create view
 * @param {Object} data { type: string, content: array}
 * @param {Function} callback
 **/
export const createProjectPageView = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/views`,
        data
      })
      if (callback) callback(res.data)
      dispatch({ type: VIEWS.ADD_PROJECT_PAGE_VIEW, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch project page view
 **/
export const fetchProjectPageView = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/views?type=projectPage`
      })
      dispatch({ type: VIEWS.ADD_PROJECT_PAGE_VIEW, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Update servicePage view
 * @param {Object} data { type: string, content: array}
 * @param {Function} callback
 **/
export const updateServicePageView = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/views/${data.id}`,
        data: data.data
      })
      if (callback) callback(res.data)
      dispatch({ type: VIEWS.UPDATE_SERVICE_PAGE_VIEW, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create service page view
 * @param {Object} data { type: string, content: array}
 * @param {Function} callback
 **/
export const createServicePageView = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/views`,
        data
      })
      if (callback) callback(res.data)
      dispatch({ type: VIEWS.SET_SERVICE_PAGE_VIEW, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch service page view
 **/
export const fetchServicePageView = (data, callback) =>
  catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/views?type=servicePage`
    })
    dispatch({ type: VIEWS.SET_SERVICE_PAGE_VIEW, payload: res.data })
    if (callback) callback(res.data)
  }, callback)

/**
 * fetch service page view
 **/
export const fetchPublicServicePageView = (data, callback) =>
  catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/views/public/service?type=servicePage`,
      isPublic: true
    })
    let {
      views: { content }
    } = res.data
    if (content) {
      const themeData = JSON.parse(localStorage.getItem('customize'))
      let { primaryColor, secondaryColor } = content[0]
      storeCustomizeData({
        theme: {
          ...themeData.theme,
          primary: primaryColor,
          heading: secondaryColor
        }
      })
    }
    dispatch({ type: VIEWS.SET_SERVICE_PUBLIC_PAGE_VIEW, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
