import React from 'react'
import { useTranslation } from 'react-i18next'
import { Info } from '@material-ui/icons'
import { CustomTooltip } from 'global/globalComponents'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'

const FeedHeader = ({
  toggleSidebar,
  editMode,
  handleDeleteWidgetClick,
  onOpen
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between items-center border-b border-gray-300 dark:border-dark-main1 p-3">
      <div className="text-base font-medium text-gray-900 dark:text-dark-light">
        {t('YOUR_FEEDS')}
      </div>
      {!toggleSidebar &&
        (editMode ? (
          // meData.role <= userRoles.USER_CLIENT ?
          <button
            className="text-red-400 mr-2"
            onClick={handleDeleteWidgetClick}
          >
            <TrashIcon />
          </button>
        ) : (
          <CustomTooltip title={t('MORE_INFO')} placement="top">
            <button
              className="hover:text-primary-main text-custom-gray-main"
              onClick={onOpen}
            >
              <Info />
            </button>
          </CustomTooltip>
        ))}
    </div>
  )
}

export default FeedHeader
