import { Button } from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'
import { format } from 'date-fns'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import currency from 'static/Currency/Currency'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import { ColoredAvatars } from 'global/globalComponents'
import { useCalendly } from 'customHooks'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import { useState } from 'react'
// import { getInvoiceSummary } from 'components/_agency/PaymentsSection/paymentStatic'

function Sidebar({
  onClose,
  openReqModal,
  setToggleQuote,
  handleDeleteWidgetClick,
  editMode
}) {
  const { t } = useTranslation()
  const { handleCalendlyUrlClick } = useCalendly()
  const meData = useSelector(state => state.me.data)
  const clientRequests = useSelector(state => state.clientRequests)
  const invoices = useSelector(state => state.clientInvoices)

  return (
    <div className="w-72 flex flex-col border-l border-gray-300 dark:border-dark-main1 dark:text-white">
      <div className="flex justify-end items-center text-sm py-3 pr-2">
        {editMode ? (
          <button
            className="text-red-400 mt-2 text-base"
            onClick={handleDeleteWidgetClick}
          >
            <TrashIcon />
          </button>
        ) : (
          <button onClick={onClose}>
            <CrossIcon className="w-5 h-5 hover:text-primary-main" />
          </button>
        )}
      </div>

      <div className="flex-1 overflow-auto flex flex-col mb-4 px-4">
        <div
          className={clsx('w-full mb-4', {
            'mt-4 px-2': !meData.agencyRepresentative
          })}
        >
          {Boolean(meData.agencyRepresentative) ? (
            <div className="flex items-center">
              <ColoredAvatars user={meData.agencyRepresentative} size="large" />

              <div className="ml-2">
                <div className="font-semibold dark:text-dark-light text-sm w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {meData.agencyRepresentative.name}
                </div>
                <div className="text-extraSmaller text-gray-600 dark:text-gray-400 hover:underline w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {meData.agencyRepresentative.email}
                </div>
              </div>
            </div>
          ) : (
            <span className="text-xs dark:text-dark-light">
              {t('NO_AGENCY_REPRESENTATIVE')}
            </span>
          )}
          {Boolean(meData.agencyRepresentative?.calendlyLink) && (
            <div className="flex justify-end">
              <Button
                size="small"
                className="font-semibold text-xs cursor-pointer"
                onClick={() =>
                  handleCalendlyUrlClick(
                    meData.agencyRepresentative.calendlyLink
                  )
                }
              >
                {t('CONTACT_SUPPORT')}
              </Button>
            </div>
          )}
        </div>

        <Card title={t('REQUESTS')}>
          <div className="flex flex-col gap-2 w-full">
            {Boolean(clientRequests.data.length) ? (
              clientRequests.data.map(req => (
                <div className="w-full border p-2 flex justify-between items-center show-on-hover-parent rounded-md dark:bg-dark-main3 dark:border-dark-main1">
                  <div className="text-xs flex flex-wrap ">
                    <span className="font-semibold mr-2 overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {req.title}
                    </span>
                    <span className=" text-gray-600 dark:text-gray-400">
                      {format(new Date(req.createdAt), 'dd/MM/yyyy')}
                    </span>
                  </div>
                  <span
                    onClick={() => openReqModal({ open: true, data: req })}
                    className="p-2 py-1 bg-gray-200 hover:bg-blue-100 dark:bg-dark-main show-on-hover-child rounded-md cursor-pointer"
                  >
                    <RemoveRedEye fontSize="small" />
                  </span>
                </div>
              ))
            ) : (
              <span className="text-xs dark:text-dark-light">
                {t('NO_REQUESTS_AVAILABLE')}
              </span>
            )}
          </div>
        </Card>

        <Card title={t('INVOICES')}>
          <div className="flex flex-col gap-2 w-full">
            {Boolean(invoices.data.length) ? (
              invoices.data.map(invoice => (
                <div className="w-full border p-2 flex justify-between items-center show-on-hover-parent rounded-md text-xs dark:bg-dark-main3 dark:border-dark-main1">
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-wrap gap-2 items-center">
                      <Pill
                        variant={pillVariantObj[invoice.status]}
                        className="p-1 rounded-md"
                      >
                        {t(invoice.status.toUpperCase())}
                      </Pill>
                      <span className="rounded-md p-1 bg-gray-100 dark:bg-dark-main4">
                        {currency[invoice.currency].symbol_native}
                        {invoice.totalAmount.toLocaleString()}
                        {/* {
                          getInvoiceSummary({
                            invoiceItems: invoice.invoiceItems,
                            coupon: invoice.coupon,
                            discountAmount: invoice.discountAmount,
                            taxPercent: invoice.taxPercent
                          }).totalAmount
                        } */}
                      </span>

                      <span className=" text-gray-600 dark:text-gray-400">
                        {format(new Date(invoice.createdAt), 'dd/MM/yyyy')}
                      </span>
                    </div>

                    <span className="font-semibold mr-2 p-1">
                      {invoice.customInvoiceNumber ?? invoice.invoiceNumber}
                    </span>
                  </div>
                  <span
                    onClick={() => setToggleQuote({ open: true, invoice })}
                    className="p-2 py-1 bg-blue-100 dark:bg-dark-main show-on-hover-child rounded-md cursor-pointer"
                  >
                    <RemoveRedEye fontSize="small" />
                  </span>
                </div>
              ))
            ) : (
              <span className="text-xs dark:text-dark-light">
                {t('NO_INVOICE_AVAILABLE')}
              </span>
            )}
          </div>
        </Card>
      </div>
    </div>
  )
}

const Card = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="my-2 flex flex-col gap-5">
      <div
        role="button"
        onClick={() => setExpanded(prev => !prev)}
        className="flex justify-between items-center cursor-pointer font-semibold text-sm border px-4 py-2.5 rounded border-gray-300 text-gray dark:border-dark-main1 dark:text-dark-light2"
      >
        {title}
        <ChevronRight
          style={{ transform: `rotate(${expanded ? -90 : 90}deg)` }}
          className="transition-transform duration-300 w-5 h-5"
        />
      </div>

      {expanded && <div>{children}</div>}
    </div>
  )
}

export default Sidebar
