import React from 'react'
import clsx from 'clsx'

const CustomTabs = ({ tabsData, value, onChange, count = true }) => {
  return (
    <div className="w-full bg-white rounded-xl shadow px-6 xl:px-8 dark:bg-dark-main2 dark:text-dark-light overflow-x-auto">
      <div className="flex space-x-8">
        {tabsData.map(item => (
          <SingleTab
            key={item.label}
            item={item}
            selectedValue={value}
            onChange={onChange}
            count={count}
          />
        ))}
      </div>
    </div>
  )
}

const SingleTab = ({ item, selectedValue, onChange, count }) => {
  const handleTabChange = () => {
    if (selectedValue === item.value) return
    onChange(item.value, item)
  }

  return (
    <div
      className={clsx(
        'pt-4 font-medium cursor-pointer select-none dark:text-dark-light2',
        selectedValue === item.value
          ? 'text-primary-main border-primary-main dark:text-dark-light dark:border-dark-main '
          : 'text-primary-mid-dark-2 dark:text-dark-light'
      )}
      role="button"
      onClick={handleTabChange}
    >
      <span className="mb-3 inline-block text-smaller 2xl:text-sm capitalize dark:text-dark-light whitespace-nowrap">
        {item.label}{' '}
        {count && item.number !== undefined && (
          <span
            className={clsx(
              'text-smaller 2xl:text-sm',
              selectedValue === item.value
                ? 'text-primary-main dark:text-dark-light'
                : 'text-gray-400 dark:text-dark-light'
            )}
          >
            ({item.number ?? 0})
          </span>
        )}
      </span>

      {selectedValue === item.value && (
        <div
          className="bg-primary-main rounded-t w-11/12 mx-auto dark:bg-dark-light"
          style={{ height: 4 }}
        ></div>
      )}
    </div>
  )
}

export default React.memo(CustomTabs)
