import React from 'react'
import { makeStyles } from '@material-ui/core'

const CustomModalFooter = ({ children, className = '' }) => {
  const classes = useStyles()

  return (
    <footer
      className={`${classes.modalFooter} py-4 2xl:py-5 px-6 flex justify-end rounded-t-2xl 2xl:rounded-t-3x items-center ${className} dark:text-dark-light`}
      // style={{ borderRadius: '0 0 8px 8px' }}
    >
      {children}
    </footer>
  )
}

const useStyles = makeStyles({
  modalFooter: {
    '& > * + *': {
      marginLeft: '0.75rem'
    }
  }
})

export default CustomModalFooter
