import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import useTitle from 'customHooks/useTitle'
import { LoadingButton } from 'global/globalComponents'
// import clientVenueAssets from 'static/clientVenueAssets'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import { PatternScreen, RoundedTextInput } from 'global/globalComponents'
import { checkDomainAvailability } from 'thunks/passwordAndDomain/actions'
// import loginPattern from 'static/Images/login-pattern.png'
import { useTranslation } from 'react-i18next'

const Domain = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  // const [agencyInfo, setAgencyInfo] = useState({
  //   brandName: '',
  //   favicon: '',
  //   smallLogo: '',
  //   largeLogo: ''
  // })
  const [domain, setDomain] = useState()

  // useTitle(agencyInfo.brandName, agencyInfo.favicon)

  useEffect(() => {
    dispatch(
      getAgencyInfoByDomain((res, err) => {
        // if (!err) {
        //   setAgencyInfo(res.brand ?? clientVenueAssets)
        // } else {
        //   setAgencyInfo(clientVenueAssets)
        // }
      })
    )
  }, [])

  // useEffect(() => {
  //   setAgencyInfo(meData.brand ?? clientVenueAssets)
  // }, [meData])

  const domainHandler = async e => {
    e.preventDefault()

    if (!domain)
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_YOUR_DOMAIN')))

    setLoading(true)
    checkDomainAvailability({ domain }, proceedDomainCallback)
  }

  const proceedDomainCallback = (res, err) => {
    setLoading(false)

    if (err) {
      dispatch(fireErrorToaster(res))
      return
    }

    if (res.domainExist) {
      dispatch(fireSuccessToaster(t('REDIRECTING_TO_YOU_DOMAIN')))
      window.open(`https://${domain}.clientvenue.com`, '_self')
    } else {
      dispatch(fireErrorToaster(t('DOMAIN_DOES_NOT_EXIST', { data: domain })))
    }
  }

  // const fetchAgencyInfo = async () => {
  //   try {
  //     const res = await getAgencyInfoByDomain()
  //     setAgencyInfo(res?.data?.brand ?? clientVenueAssets)
  //   } catch (err) {
  //     setAgencyInfo(clientVenueAssets)
  //   }
  // }

  const formInputHandler = e => {
    setDomain(e.target.value.split('.')[0])
  }

  return (
    <PatternScreen customize={true}>
      <div>
        <header className="mb-8">
          {/* {agencyInfo.largeLogo && (
            <img
              src={agencyInfo.largeLogo?.replace('s3.wasabisys.com/', '')}
              alt="logo"
              target="_self"
              style={{
                height: '3rem',
                margin: 'auto'
              }}
            />
          )} */}

          <h1 className="text-4xl font-bold dark:text-dark-light">
            {t('SIGN_IN_TO_YOUR')} <br />
            <span className="text-primary-main">{t('ACCOUNT')}</span>
          </h1>
        </header>

        <div>
          <form onSubmit={domainHandler}>
            <label
              htmlFor="agencyDomain"
              className="inline-block text-sm font-medium tracking-wide mb-2 dark:text-dark-light"
            >
              {t('ADENCY_DOMAIN')}
            </label>
            <div className="flex">
              <RoundedTextInput
                id="agencyDomain"
                placeholder={t('DOMAIN_NAME')}
                type="text"
                name="domain"
                onChange={formInputHandler}
                value={domain}
              />
              <p className="self-center text-gray-500 dark:text-gray-400">
                .clientvenue.com
              </p>
            </div>
            <div className="mt-4">
              <LoadingButton
                size="large"
                loading={loading}
                type="submit"
                className="w-full"
              >
                {t('PROCEED_TO_YOUR_DOMAIN')}
              </LoadingButton>
            </div>
          </form>

          <p className="text-xs mt-2 dark:text-dark-light">
            {t('NOT_REGISTERED_YET')}{' '}
            <Link to="/signup" className="text-primary-main font-bold">
              {t('SIGNUP_HERE')}
            </Link>
          </p>

          <p className="text-xs mt-1 dark:text-dark-light">
            {t('FORGOT_DOMAIN')}{' '}
            <Link to="/forgot-domain" className="text-primary-main font-bold">
              {t('CLICK_HERE')}
            </Link>
          </p>
        </div>
      </div>
    </PatternScreen>
  )
}

export default Domain
