import React, { useEffect, useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import { Button, TextField } from '@material-ui/core'
import { FormLabel, LoadingButton } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { generateEmbed, updateEmbed } from 'thunks/embed/action'
import { useParams } from 'react-router-dom'

const AddEmbedModal = ({ open, onClose, data }) => {
  const { t } = useTranslation()

  return (
    <CustomModal size="small" open={open} handleClose={onClose}>
      <CustomModalHeader
        heading={data._id ? t('EDIT_EMBED') : t('ADD_EMBED')}
        handleClose={onClose}
      />

      <ModalContent open={open} onClose={onClose} data={data} t={t} />
    </CustomModal>
  )
}

const ModalContent = ({ onClose, data, t }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    url: ''
  })
  const { clientId } = useParams()

  useEffect(() => {
    if (data._id) {
      setFormData({ name: data.name, url: data.embed_settings.url })
    }
  }, [data])

  const handleAddNewEmbed = () => {
    if (!formData.name || !formData.url) {
      return dispatch(fireErrorToaster(t('PLEASE_FILL_ALL_THE_FIELDS')))
    }

    setLoading(true)
    const data = {
      name: formData.name,
      type: 'embed',
      category: 'External',
      client: clientId,
      embed_settings: { url: formData.url }
    }

    dispatch(
      generateEmbed({ data }, (res, err) => {
        setLoading(false)
        if (err) dispatch(fireErrorToaster(res))
        else onClose()
      })
    )
  }

  const handleUpdateEmbed = () => {
    if (!formData.name || !formData.url) {
      return dispatch(fireErrorToaster(t('PLEASE_FILL_ALL_THE_FIELDS')))
    }

    setLoading(true)
    dispatch(
      updateEmbed(
        {
          id: data._id,
          data: { name: formData.name, embed_settings: { url: formData.url } }
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
            setLoading(false)
            onClose()
          }
        }
      )
    )
  }

  return (
    <>
      <CustomModalBody>
        <div className="mb-3">
          <FormLabel required>{t('TITLE')}</FormLabel>
          <TextField
            value={formData.name}
            onChange={e =>
              setFormData(prev => ({ ...prev, name: e.target.value }))
            }
            fullWidth
            placeholder="Ex: Google sheet"
          />
        </div>
        <div className="mb-4">
          <FormLabel required>{t('URL')}</FormLabel>
          <TextField
            value={formData.url}
            onChange={e =>
              setFormData(prev => ({ ...prev, url: e.target.value }))
            }
            fullWidth
            placeholder={'Ex: https://docs.google.com/spreadsheets'}
          />
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button onClick={onClose} variant="outlined">
          {t('CANCEL')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={data._id ? handleUpdateEmbed : handleAddNewEmbed}
        >
          {data._id ? t('SAVE') : t('ADD')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default AddEmbedModal
