import React, { useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { useState } from 'react'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { CustomFlag } from 'global/globalComponents'
import templateStyles from './templateStyles'
import { NavLink } from 'react-router-dom'
import AsanaIcons from 'static/asana/AsanaIcons'
import { ReactComponent as ElementsIcons } from 'static/svg/element-5.svg'
import { ReactComponent as KanbanIcon } from 'static/svg/kanban.svg'
import { ReactComponent as ListIcon } from 'static/svg/list.svg'
import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import { ReactComponent as ActivityIcon } from 'static/svg/activity.svg'
import { useTranslation } from 'react-i18next'
import { LinearProgress } from '@material-ui/core'
import BoardsStyles from 'components/Boards/BoardView/BoardsStyles'
import { ReactComponent as Vector2 } from 'static/svg/vector-2.svg'

const tabsData = [
  {
    name: 'overview',
    label: 'Overview',
    icon: <ElementsIcons />
  },
  {
    name: 'board',
    label: 'Board',
    icon: <KanbanIcon />
  },
  {
    name: 'list',
    label: 'List',
    icon: <ListIcon />
  },
  {
    name: 'calendar',
    label: 'Calendar',
    icon: <CalendarIcon />
  },
  {
    name: 'status-updates',
    label: 'Reporting',
    icon: <ActivityIcon />
  }
]

const TemplateView = ({ templateData }) => {
  const { t } = useTranslation()
  const classes = templateStyles()
  const [sections, setSections] = useState([])

  useEffect(() => {
    if (templateData) {
      setSections(templateData.content?.section ?? [])
    }
  }, [templateData.content?.section])

  return (
    <div className="relative shadow border w-full my-2 select-none dark:border-black">
      <div className={clsx(classes.headerContainer, '')}>
        <div className="w-full min-w-full h-full px-4 overflow-auto dark:bg-dark-main2">
          <div className="w-full min-w-full h-full flex items-center flex-shrink-0 relative ">
            <div className="flex items-center h-full flex-auto pr-4">
              <div className="flex items-center h-full flex-auto pr-4">
                <div
                  className="h-10 w-10 rounded-xl overflow-hidden mr-4 flex items-center justify-center"
                  style={{
                    backgroundColor: 'pink',
                    boxShadow: 'inset 0 -2px rgb(0 0 0 / 5%)'
                  }}
                >
                  <AsanaIcons.Bug height="20px" width="20px" />
                </div>

                <div className="flex items-center h-full">
                  <h1 className="text-xs font-medium truncate mr-2 dark:text-dark-light">
                    {templateData?.title}
                  </h1>
                  <div
                    className={clsx(
                      'flex items-end pt-2 overflow-x-scroll overflow-y-hidden dark:text-dark-light',
                      classes.tabsContainer
                    )}
                  >
                    {tabsData.map(tabData => (
                      <NavLink
                        to="#"
                        className={classes.tab}
                        isActive={(match, location) =>
                          tabData.label === 'Board'
                        }
                        activeClassName="isActive"
                      >
                        <>
                          <span className="inline-flex my-auto justify-center items-center border-l px-3 w-full gap-1 dark:text-dark-light">
                            {tabData.icon}
                            <span className="dark:text-dark-light">
                              {tabData.label}
                            </span>
                          </span>
                          <div className="tab-line w-3/4 h-1 mx-auto"></div>
                        </>
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx('relative w-full px-10 overflow-scroll')}>
        <div className="mt-12 pt-10 h-full">
          <div className="flex space-x-4 pt-8 h-full w-full ">
            {sections.length ? (
              sections.map((section, idx) => (
                <div
                  key={idx}
                  style={{
                    height: 'fit-content',
                    width: 'fit-content'
                  }}
                  className={clsx(
                    'pl-4 pt-4 pb-6 rounded-lg bg-custom-gray-light-4 dark:bg-dark-main1',
                    classes.columnContainer
                  )}
                >
                  <div
                    className={clsx(
                      classes.columnHeaderContainer,
                      'flex mb-4 items-center justify-between overflow-hidden bg-transparent flex-shrink-0 pr-4'
                    )}
                  >
                    <div className="flex items-center">
                      <h3 className="font-semibold text-xs capitalize">
                        {section.label}
                      </h3>
                      <span className="w-6 h-6 inline-flex items-center justify-center ml-2 text-xs font-bold bg-gray-200 text-primary-dark rounded-full">
                        {section.tasks?.length}
                      </span>
                    </div>
                  </div>
                  <div
                    className={clsx(classes.taskCardsContainer, 'space-y-2')}
                  >
                    {section.tasks?.map(task => (
                      <div
                        key={task.taskId}
                        className={clsx(
                          classes.taskCardContainer,
                          'rounded-lg bg-white'
                        )}
                      >
                        <TaskCardHeader
                          title={task.title}
                          taskId={task.taskId}
                          attachments={task.imageUrl}
                          estimateTimeInString={task.estimateTimeInString}
                          isComplete={task.markAsComplete ?? false}
                          totalSubtasks={task.subTasks?.length ?? 0}
                        />
                        <footer className="px-3 py-1.5 text-custom-gray-light-3 cursor-default">
                          <div className="flex justify-between items-center">
                            {task.priority && task.priority !== 5 && (
                              <CustomFlag
                                priority={task.priority}
                                size="small"
                                className="mr-2"
                              />
                            )}
                            {/* <span className="leading-none w-10 text-tiny font-medium">
                            {format(
                              new Date(
                                Date.now() +
                                  Math.random() * 10 * 24 * 3600 * 1000
                              ),
                              'MMM d'
                            )}
                          </span>
                          <div className="ml-auto">
                            <ColoredAvatarGroup
                              users={[
                                { name: 'John Doe' },
                                { name: 'Catherine' }
                              ]}
                              size="tiny"
                            />
                          </div> */}
                          </div>
                        </footer>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p className="pb-12 text-gray-700">{t('NO_DATA_AVAILABLE')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const TaskCardHeader = React.memo(
  ({
    title,
    taskId,
    attachments,
    estimateTimeInString,
    isComplete,
    totalSubtasks
  }) => {
    const classes = BoardsStyles()
    const firstImageAttachment = useMemo(() => {
      if (attachments) {
        return attachments.find(item =>
          /\.(png|svg|jpe?g|gif|tiff?|webp)$/i.test(item)
        )
      }
      return null
    }, [attachments])

    return (
      <div>
        <header className="p-3">
          <div className="mb-3 flex items-center justify-between">
            <h4 className="text-tiny font-semibold text-primary-dark capitalize truncate">
              {title}
            </h4>

            <div className="flex items-center space-x-2 ml-3">
              <CheckCircleIcon
                className={clsx(
                  'h-4 w-4',
                  isComplete ? 'text-custom-green-main' : 'text-gray-400'
                )}
              />
            </div>
          </div>

          {firstImageAttachment && (
            <img
              src={firstImageAttachment}
              alt=""
              className="w-full h-32 object-cover mb-2"
            />
          )}

          {Boolean(totalSubtasks) && (
            <div className="inline-flex items-center rounded border border-gray-300 text-gray-500 dark:text-dark-light dark:border-black">
              <Vector2 fontSize="12px" />
              <span className="mr-1 font-medium dark:text-dark-light text-extraSmaller">
                {totalSubtasks}
              </span>
            </div>
          )}
          <div className="flex items-center justify-between">
            {Boolean(totalSubtasks) && (
              <span className="w-1/2 flex items-center">
                <LinearProgress
                  variant="determinate"
                  value={Math.round((0 / totalSubtasks) * 100)}
                  className="flex-1"
                  classes={{ root: classes.linearProgressRoot }}
                />
                <span className=" text-extraSmaller font-medium ml-2 text-gray-600 dark:text-dark-light">
                  {0}%
                </span>
              </span>
            )}
            {estimateTimeInString && (
              <span className="px-2 py-0.5 rounded bg-custom-gray-light-4 font-medium">
                <span className="text-xs text-custom-gray-light-3">
                  Est. {estimateTimeInString}
                </span>
              </span>
            )}
          </div>
        </header>
        <hr />
      </div>
    )
  }
)

export default TemplateView
