import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import {
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useEffect, useState } from 'react'
import { deleteCustomInvoice, markInvoiceAsPaid } from 'thunks/invoices/actions'
import InvoiceTableRow from './InvoiceTableRow'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { sendInvoiceEmail } from 'thunks/ProjectInvoices/actions'
import Currency from 'static/Currency/Currency'
import { format } from 'date-fns'
import { Pagination } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
// import { getInvoiceSummary } from 'components/_agency/PaymentsSection/paymentStatic'

const totalInvoicesToShow = 8

const InvoiceTable = ({
  invoices,
  openInvoicePreview,
  handleEditInvoice,
  elmFor = 'agency',
  clientCol = true,
  loading,
  markAsPaidCallback,
  deleteInvoiceCallback
}) => {
  const { t } = useTranslation()
  const [markAsPaidModal, setMarkAsPaidModal] = useState({
    open: false,
    loading: false,
    data: ''
  })

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    loading: false,
    data: ''
  })
  const [visibleInvoices, setVisibleInvoices] = useState([])
  const [page, setPage] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    setVisibleInvoices(
      [...invoices].splice(page * totalInvoicesToShow, totalInvoicesToShow)
    )
  }, [invoices, page])

  const handleMarkInvoiceAsPaid = () => {
    setMarkAsPaidModal(prev => ({ ...prev, loading: true }))

    dispatch(
      markInvoiceAsPaid({ id: markAsPaidModal.data }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setMarkAsPaidModal(prev => ({ ...prev, loading: false }))
        } else {
          setMarkAsPaidModal(prev => ({ ...prev, open: false }))
          if (markAsPaidCallback) markAsPaidCallback(res)
        }
      })
    )
  }

  const handleDeleteInvoice = () => {
    setDeleteModal(prev => ({ ...prev, loading: true }))

    dispatch(
      deleteCustomInvoice({ id: deleteModal.data }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setDeleteModal(prev => ({ ...prev, loading: false }))
        } else {
          setDeleteModal(prev => ({ ...prev, open: false }))
          if (deleteInvoiceCallback) {
            deleteInvoiceCallback(res._id)
          }
        }
      })
    )
  }

  const handleSendEmail = invoice => {
    // const { totalAmount } = getInvoiceSummary({
    //   invoiceItems: invoice.invoiceItems,
    //   coupon: invoice.coupon,
    //   discountAmount: invoice.discountAmount,
    //   taxPercent: invoice.taxPercent
    // })

    sendInvoiceEmail(
      {
        id: invoice._id,
        data: {
          subject: `You have an outstanding invoice from ${invoice.agency.agencyName}`,
          message: `
        <h2>You have an outstanding invoice.</h2>
        <p>This is a reminder that ${
          invoice.agency.agencyCompanyName
        } sent you an invoice for ${Currency[invoice.currency]?.symbol_native}${
            invoice.totalAmount
          }, due on ${format(
            new Date(invoice.dueDate),
            'MMMM, dd, yyyy'
          )} with ${Currency[invoice.currency]?.symbol_native}${
            invoice.totalAmount
          } to be paid.</p>

        <p>This is just a friendly reminder to take a look at the invoice I've sent. Please email me at ${
          invoice.agency.agencyEmail
        } with any questions.</p>
        <p>&nbsp;</p>
        <p>Thanks!</p>
      `,
          mailType: 'reminder'
        }
      },
      (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(t('REMINDER_SENT_SUCCESSFULLY')))
        }
      }
    )
  }

  const openMarkAsPaidAlert = invoiceId => {
    setMarkAsPaidModal({ open: true, loading: false, data: invoiceId })
  }

  const closeMarkAsPaidAlert = () => {
    setMarkAsPaidModal(prev => ({ ...prev, open: false }))
  }

  const openDeleteAlert = invoiceId => {
    setDeleteModal({ open: true, loading: false, data: invoiceId })
  }

  const closeDeleteAlert = () => {
    setDeleteModal(prev => ({ ...prev, open: false }))
  }

  return (
    <>
      <TableContainer mb={6}>
        <Thead>
          {clientCol && (
            <Th className="text-left whitespace-nowrap">{t('CLIENT')}</Th>
          )}
          <Th className="whitespace-nowrap">{t('INVOICE_NO')}</Th>
          <Th className="text-center whitespace-nowrap">{t('ITEMS')}</Th>
          <Th className="text-center whitespace-nowrap">{t('STATUS')}</Th>
          <Th className="text-right whitespace-nowrap">{t('AMOUNT')}</Th>
          <Th className="whitespace-nowrap">{t('ISSUED_DATE')}</Th>
          <Th className="text-center whitespace-nowrap"></Th>
        </Thead>
        <Tbody>
          {loading ? (
            <TableDataLoading cols={6} />
          ) : visibleInvoices.length === 0 ? (
            <Tr>
              <Td colSpan="6">{t('NO_INVOICE_AVAILABLE')}</Td>
            </Tr>
          ) : (
            visibleInvoices.map(item => (
              <InvoiceTableRow
                invoice={item}
                openInvoicePreview={openInvoicePreview}
                handleEditInvoice={handleEditInvoice}
                openMarkAsPaidAlert={openMarkAsPaidAlert}
                handleSendReminder={handleSendEmail}
                openDeleteAlert={openDeleteAlert}
                elmFor={elmFor}
                clientCol={clientCol}
              />
            ))
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={invoices.length}
        page={page}
        limit={totalInvoicesToShow}
      />

      <AlertModal
        heading={t('ARE_YOU_SURE')}
        alertType="confirm"
        warningText={t('WARNING_INVOICE_MARK_AS_PAID')}
        open={markAsPaidModal.open}
        handleDialog={closeMarkAsPaidAlert}
        handleDeleteAction={handleMarkInvoiceAsPaid}
        loading={markAsPaidModal.loading}
        deleteBtnText={t('MARK_AS_PAID')}
      />

      <AlertModal
        heading={t('ARE_YOU_SURE')}
        warningText={t('WARNING_INVOICE_DELETE')}
        open={deleteModal.open}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handleDeleteInvoice}
        loading={deleteModal.loading}
        deleteBtnText={t('DELETE')}
      />
    </>
  )
}

export default InvoiceTable
