import { catchAsync } from 'utils'
import { GENERAL_APP_STATE } from './reducers'
import axios from 'axios'

/**
 *
 * @param {Object} value
 * @description set theme key in companyInfo
 */
export const updateTheme = value => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.UPDATE_THEME, payload: value })
  }
}

/**
 *
 * @param {*} value
 * @description set theme key in the generalAppState
 */
export const updateStateTheme = value => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.THEME_UPDATE, payload: value })
  }
}

export const toggleSideDrawer = value => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.TOGGLE_SIDE_DRAWER, payload: value })
  }
}

export const toggleSidebar = value => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.TOGGLE_SIDE_BAR, payload: value })
  }
}

/**
 * Deprecated. No longer maintained
 */
export const showSideBar = data => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.SHOW_SIDEDRAWER, payload: data })
  }
}

export const showIntegratedHeader = () => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.SHOW_INTEGRATED_HEADER })
  }
}

export const toggleCreateInvoiceModal = invoiceType => {
  return dispatch => {
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_CREATE_INVOICE_MODAL,
      payload: invoiceType
    })
  }
}

export const showNormalHeader = () => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.SHOW_NORMAL_HEADER })
  }
}

// export const updateUserAcces = (role) => {
//   return (dispatch, getState) => {
//     // const {
//     //   me: { data }
//     // } = getState()
//     dispatch({
//       type: GENERAL_APP_STATE.UPDATE_USER_ACCESS,
//       payload: role
//     })
//   }
// }

/**
 *
 * @param {Boolean} open
 * @param {Object} data
 * @param {Boolean} aboveModal
 * @param {String} elmFor => "team" or "client"
 * @returns
 */
export const toggleAddTeammateSidePanel = ({
  open,
  data = {},
  aboveModal = false,
  elmFor,
  clientOwner,
  callback
}) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        addTeammate: {
          open:
            open === undefined
              ? !state.generalAppState.sidePanel.addTeammate.open
              : open,
          data: data || {},
          aboveModal: aboveModal || false,
          elmFor: elmFor || '',
          clientOwner: clientOwner || '',
          callback
        }
      }
    })
  }
}

export const toggleEmbedModal = (value, anchorPos = '') => {
  return {
    type: GENERAL_APP_STATE.TOGGLE_MODAL,
    payload: {
      embedModal: {
        open: value,
        anchorPos
      }
    }
  }
}

// export const toggleAddClientSidePanel = (
//   open,
//   data = {},
//   aboveModal = false
// ) => {
//   return (dispatch, getState) => {
//     const state = getState()
//     dispatch({
//       type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
//       payload: {
//         addClient: {
//           open:
//             open === undefined
//               ? !state.generalAppState.sidePanel.addClient.open
//               : open,
//           data,
//           aboveModal
//         }
//       }
//     })
//   }
// }

// export const toggleAddWorkspaceSidePanel = (value, category = 'Internal') => {
//   return (dispatch, getState) => {
//     const state = getState()
//     dispatch({
//       type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
//       payload: {
//         addWorkspace: {
//           open:
//             value === undefined
//               ? !state.generalAppState.sidePanel.addWorkspace.open
//               : value,
//           category
//         }
//       }
//     })
//   }
// }

export const toggleAddServiceSidePanel = () => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: { addService: !state.generalAppState.sidePanel.addService }
    })
  }
}

export const toggleProjectPermissionModal = value => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        editTeammate:
          value === undefined
            ? !state.generalAppState.sidePanel.editTeammate
            : value
      }
    })
  }
}

export const toggleLogsSidePanel = value => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        logsPanel:
          value === undefined
            ? !state.generalAppState.sidePanel.logsPanel
            : value
      }
    })
  }
}

export const setTeammateRole = value => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.SET_TEAMMATE_ROLE, payload: value })
  }
}

export const setExpandState = value => {
  return dispatch => {
    dispatch({ type: GENERAL_APP_STATE.SET_EXPAND_STATE, payload: value })
  }
}

export const removedFromWSModal = (openStatus = true) => {
  return {
    type: GENERAL_APP_STATE.OPEN_REMOVED_FROM_WS_MODAL,
    payload: openStatus
  }
}

export const toggleUpgradeModal = callback => {
  return {
    type: GENERAL_APP_STATE.TOGGLE_UPGRADE_PLAN_MODAL
  }
}

// export const handleFilePreview = ({ open = true, file, files }) => {
//   return (dispatch, getState) => {
//     const state = getState()

//     dispatch({
//       type: GENERAL_APP_STATE.TOGGLE_DOCUMENT_PREVIEW,
//       payload: { open: open, file, files }
//     })
//   }
// }

export const showHeaderShadow = value => {
  return { type: GENERAL_APP_STATE.TOGGLE_HEADER_SHADOW, payload: value }
}

export const setHeaderSearch = value => {
  return { type: GENERAL_APP_STATE.SET_HEADER_SEARCH, payload: value }
}

export const setBoardSearch = value => {
  return { type: GENERAL_APP_STATE.SET_BOARD_SEARCH, payload: value }
}

export const toggleCreateTaskPanel = (open, data = {}) => {
  return dispatch => {
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        addTask: {
          open,
          data
        }
      }
    })
  }
}

export const toggleScreenRecord = value => {
  return dispatch => {
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SCREEN_RECORDING,
      payload: value
    })
  }
}

export const toggleScreenRecordSave = value => {
  return dispatch => {
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SCREEN_RECORDING_SAVE,
      payload: value
    })
  }
}

export const setUnreadGroupMessagesCount = value => {
  return dispatch => {
    dispatch({
      type: GENERAL_APP_STATE.SET_UNREAD_GROUP_MESSAGES_COUNT,
      payload: value
    })
  }
}

export const setShowMoreState = value => {
  return dispatch => {
    dispatch({
      type: GENERAL_APP_STATE.SET_SHOWMORE_STATE,
      payload: { loading: false, value }
    })
  }
}

export const setShowMore = value => {
  return dispatch => {
    dispatch({
      type: GENERAL_APP_STATE.SET_SHOWMORE_STATE,
      payload: value
    })
  }
}

export const emptyTrash = callback =>
  catchAsync(async () => {
    const res = await axios('/user/trash', {
      method: 'DELETE'
    })
    callback(res.data)
  }, callback)

export const openApprovalModal = data => {
  return { type: GENERAL_APP_STATE.OPEN_APPROVAL_MODAL, payload: data }
}

export const closeApprovalModal = () => {
  return { type: GENERAL_APP_STATE.CLOSE_APPROVAL_MODAL }
}

export const showPayNowStripe = redirectUrl => {
  return { type: GENERAL_APP_STATE.SHOW_PAY_NOW_STRIPE, payload: redirectUrl }
}

export const hidePayNowStripe = () => {
  return { type: GENERAL_APP_STATE.HIDE_PAY_NOW_STRIPE }
}

/**
 * Submit user feedback
 * @param {Object} data type, subject, description
 * @param {Function} callback callback function
 */
export const submitUserFeedback = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/feedback',
    data: data.body
  })

  if (callback) callback(res.data)
})

export const openUserFeedbackPopper = () => {
  return {
    type: GENERAL_APP_STATE.UPDATE_USER_FEEDBACK_POPUP,
    payload: { open: true }
  }
}

export const closeUserFeedbackPopper = () => {
  return {
    type: GENERAL_APP_STATE.UPDATE_USER_FEEDBACK_POPUP,
    payload: { open: false }
  }
}

export const toggleChatModal = value => {
  return {
    type: GENERAL_APP_STATE.TOGGLE_CHAT_MODAL,
    payload: value
  }
}

export const toggleCVChatWidget = value => {
  return {
    type: GENERAL_APP_STATE.TOGGLE_CHAT_WIDGET,
    payload: value
  }
}
