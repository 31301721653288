import React, {
  Suspense,
  useContext,
  useReducer,
  useRef,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import {
  ProposalContext,
  initialState,
  proposalReducer
} from '../ProposalContext/ProposalContext'
import { Loader } from 'global/globalComponents'
import {
  fetchedTemplate,
  getProposalByPasscode
} from '../ProposalContext/actions'
import ReactToPrint from 'react-to-print'
import { Button } from '@material-ui/core'
import PasscodeModal from './PasscodeModal'
import { userRoles } from 'utils'
import { templates } from '../proposalsStatic'
import { useTranslation } from 'react-i18next'
// import useSaveChanges from '../useSaveChanges'

const PublicProposal = () => {
  const { id } = useParams()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [templateState, templateDispatch] = useReducer(
    proposalReducer,
    initialState
  )

  // useSaveChanges({ templateState, templateDispatch })

  const handleSubmitPasscode = (passcode, onError) => {
    getProposalByPasscode({ id, passcode }, (res, err) => {
      if (err) {
        onError(res)
      } else {
        templateDispatch(fetchedTemplate(res))
        setIsAuthenticated(true)
      }
    })
  }

  return (
    <div className="bg-white dark:bg-dark-main4">
      <ProposalContext.Provider value={{ templateState, templateDispatch }}>
        {isAuthenticated ? (
          <ProposalContent />
        ) : (
          <PasscodeModal open={true} onSubmit={handleSubmitPasscode} />
        )}
      </ProposalContext.Provider>
    </div>
  )
}

const ProposalContent = () => {
  const { t } = useTranslation()
  const ref = useRef()
  const { templateState } = useContext(ProposalContext)
  const Template = templates[templateState.data.uniqueId]

  return (
    <>
      <div className="flex justify-end py-3 px-4 border-b sticky top-0 bg-white dark:bg-dark-main4 z-10">
        <ReactToPrint
          trigger={() => {
            return <Button>{t('DOWNLOAD')}</Button>
          }}
          content={() => ref.current}
          documentTitle={templateState.data.name}
        />
      </div>
      <div ref={ref}>
        {!Template ? (
          <p>{t('ERROR_OCCURRED')}</p>
        ) : (
          <Suspense fallback={<Loader />}>
            {/* On this public route anyone with the passcode can sign and we will assume that he is the client */}
            <Template
              user={{
                _id: templateState.data.client || null,
                role: userRoles.USER_CLIENT
              }}
            />
          </Suspense>
        )}
      </div>
    </>
  )
}

export default PublicProposal
