import axios from 'axios'
import { catchAsync, getAccessToken } from 'utils'
// import { getErrorMessages } from 'utils/errorMessages'
// const errorMessages = getErrorMessages()
/**
 * Create project invoice
 * @param {Object} data.data
 * @param {Function} callback function
 */
export const createCustomInvoice = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/custominvoice',
    method: 'POST',
    data: data.data
  })

  if (callback) callback(res.data)
})

/**
 * Get project invoices
 * @param {String} data.id workspace id
 * @param {Function} callback function
 */
export const getProjectInvoices = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/custominvoice/workspace/${data.id}`,
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * GET Invoice by id (PUBLIC API)
 * @param {String} id invoice id
 * @param {String} token
 * @param {Function} callback function
 */
export const getPublicInvoice = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/custominvoice/view/checkout/${data.invoiceId}`,
    method: 'GET',
    isPublic: true
  })

  if (callback) callback(res.data)
})

/**
 * Invoice Payment
 * @param {Object} data payment data
 * @param {Function} callback function
 */
export const invoicePayment = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/custominvoice/pay',
    method: 'POST',
    data: data.data,
    isPublic: true
  })

  if (callback) callback(res.data)
})

/**
 * Invoice Payment (private)
 * @param {Object} data payment data
 * @param {Function} callback function
 */
export const invoicePaymentPrivate = catchAsync(async (data, callback) => {
  const accessToken = getAccessToken()

  const res = await axios({
    url: '/custominvoice/pay',
    method: 'POST',
    data: data.body,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  if (callback) callback(res.data)
})

/**
 * Send Invoice Email
 * @param {String} data.id invoice id
 * @param {data} data.data
 * @param {Function} callback function
 */
export const sendInvoiceEmail = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/customInvoice/sendmail/${data.id}`,
    data: data.data
  })

  if (callback) callback(res.data)
})

/**
 * Mark Invoice as Paid
 * @param {String} data.id invoice id
 * @param {Function} callback function
 */
export const markAsPaid = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/custominvoice/markAsPaid/${data.id}`
  })

  if (callback) callback(res.data)
})

/**
 * Fetch Client project invoices
 * @param {String} data.clientId
 * @param {String} data.workspaceId
 * @param {Function} callback function
 */
export const fetchClientInvoices = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/custominvoice/client/${data.clientId}/${data.workspaceId}`,
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * Fetch info about payment (whether the payment is done or not)
 * @param {String} Id invoice id
 * @param {String} paymentIntent paymentIntent id
 * @param {Function} callback function
 */
export const fetchPaymentInfo = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/custominvoice/checkpaymentstatus/${data.invoiceId}/${data.paymentIntent}`,
    method: 'GET',
    isPublic: true
  })

  if (callback) callback(res.data)
})
