import { io } from 'socket.io-client'

const URL =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://clientvenue-socket-eff243e18193.herokuapp.com/'
    : process.env.REACT_APP_CONNECTION_URL.slice(0, -3)
const socket = io(URL, { autoConnect: false, transports: ['websocket'] })
// const socket = io("http://localhost:5000", { autoConnect: false, transports: ['websocket'] })

window.addEventListener('beforeunload', event => {
  if (socket.connected) socket.disconnect()
})

export default socket
