import { removeAuthTokens } from 'utils'

export const LOGIN = {
  LOADING: 'LOGIN_LOADING',
  LOGGED_IN: 'LOGGED_IN',
  ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  FETCHED_TEMP_AUTH_TOKEN: 'LOGIN_FETCHED_TEMP_AUTH_TOKEN'
}

const initialState = {
  loading: false,
  loggedIn: false,
  error: false,
  tempAccessToken: null,
  tempRefreshToken: null,
  agencyPreview: false
}

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.LOADING:
      return { ...state, loading: true, error: false }
    case LOGIN.LOGGED_IN:
      return { ...state, loggedIn: true, loading: false }
    case LOGIN.LOGOUT:
      //Don't logout if it's only preview mode
      if (state.agencyPreview) return state
      else {
        removeAuthTokens()
        return { ...state, loggedIn: false }
      }

    case LOGIN.ERROR:
      return { ...state, error: action.payload, loading: false }

    case LOGIN.FETCHED_TEMP_AUTH_TOKEN: {
      return {
        ...state,
        tempAccessToken: action.payload.tempAccessToken,
        tempRefreshToken: action.payload.tempRefreshToken,
        agencyPreview: true
      }
    }
    default:
      return state
  }
}
