import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import SidebarCustomTooltip from './SidebarCustomTooltip'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import stringToHslColor from 'utils/stringToHslColor'

const SidebarEmbeds = ({ classes, isDrawerOpen, meId }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [showEmbeds, setShowEmbeds] = useState(false)
  const embed = useSelector(state => state.embed)
  const clientEmbeds = useMemo(() => {
    return embed.data.filter(item => item.client === meId)
  }, [embed, meId])

  useEffect(() => {
    const urlMatch = clientEmbeds.find(item => pathname.includes(item._id))

    if (urlMatch) setShowEmbeds(true)
  }, [pathname, clientEmbeds])

  if (clientEmbeds.length === 0) return null

  return (
    <div className="mt-2 border-t border-b">
      <div
        role="button"
        className={clsx(
          'inline-flex hover:bg-gray-50 px-4 py-2 w-full justify-between rounded cursor-pointer items-center text-gray-500 font-medium'
          // showEmbeds && 'mb-2'
        )}
        onClick={() => setShowEmbeds(prev => !prev)}
      >
        {isDrawerOpen && <div className="text-xs">{t('EMBEDS')}</div>}
        <ChevronRight
          className="transition-transform"
          style={{ transform: `rotate(${showEmbeds ? -90 : 90}deg)` }}
        />
      </div>

      <div
        className={clsx(
          'px-2 transition-all overflow-hidden duration-300',
          showEmbeds && 'mt-1 mb-2'
        )}
        style={{ maxHeight: showEmbeds ? 175 * clientEmbeds.length : 0 }}
      >
        {clientEmbeds.map(embedData => (
          <NavLink
            to={`/client/embeds/${embedData._id}`}
            className={clsx(
              'flex items-center w-full rounded-md mt-0.5 py-1.5 px-2 hover:font-medium',
              pathname.includes(embedData._id)
                ? 'bg-primary-light text-primary-main font-medium dark:bg-dark-main'
                : 'hover:bg-primary-light dark:hover:bg-dark-main3'
            )}
          >
            <div className="flex flex-1 w-full">
              <SidebarCustomTooltip title={embedData.name}>
                <div className="flex flex-1 items-center w-full">
                  <div
                    style={{
                      backgroundColor: stringToHslColor(embedData.name)
                    }}
                    className="w-5 h-5 leading-3 flex justify-center items-center mr-2 text-tiny rounded-full flex-shrink-0 text-white"
                  >
                    {getNameInitials(embedData.name)}
                  </div>

                  {isDrawerOpen && (
                    <span className={clsx(classes.label, 'truncate')}>
                      {embedData.name}
                    </span>
                  )}
                </div>
              </SidebarCustomTooltip>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  )
}

const getNameInitials = (name = '') =>
  name
    .split(' ')
    .slice(0, 2)
    .map(item => item[0])
    .join('')

export default SidebarEmbeds
