import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { hideChatMessenger, showChatMessenger } from 'utils'
import { useLocation } from 'react-router-dom'
import { toggleCVChatWidget } from 'thunks/generlAppState/actions'

const hideChatWidgetPaths = [
  '/agency/workspace',
  '/client/workspace',
  '/agency/chat-stream',
  '/client/chat-stream',
  '/agency/service-marketplace',
  '/agency/payments',
  '/agency/storage',
  '/client/storage'
]

const useToggleChatWidget = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { displayChatWidget } = useSelector(state => state.generalAppState)

  useEffect(() => {
    let timerId = window.setTimeout(() => {
      const match = hideChatWidgetPaths.find(path => pathname.includes(path))

      if (match && displayChatWidget) {
        dispatch(toggleCVChatWidget(false))
      } else if (!match && !displayChatWidget) {
        dispatch(toggleCVChatWidget(true))
      }
    }, 1000)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [pathname])

  return null
}

export default useToggleChatWidget
