import axios from 'axios'
import {
  catchAsync,
  catchAsyncDispatch,
  errorMessages,
  getQueryString,
  Uploader
} from 'utils'
import { CHANNEL } from './reducer'

/**
 * Creates a chat group
 * @param {Object} data title, members, workspace
 * @param {String} data.title
 * @param {Array} data.members
 * @param {String} data.workspace (workspace id)
 * @param {Function} callback callback function
 */

export const createChatGroup = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/group',
      data: data.body
    })
    dispatch({
      type: CHANNEL.CREATE_GROUP,
      payload: { ...res.data, unread: 0 }
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * @deprecated Fetch group by workspace id
 * @param {String} workspaceId
 * @param {Function} callback callback function
 */
// export const fetchGroupByWorkspaceId = catchAsync(async (data, callback) => {
//   const res = await axios({
//     method: 'GET',
//     url: `/group/workspace/${data.workspaceId}`
//   })
//   if (callback) callback(res.data)
// })

/**
 * Fetch group messaged
 * @param {String} id channel id
 * @param {String} query category, limit, skip
 * @param {Function} callback callback function
 */
export const fetchMessagesByCategory = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/chat/${data.id}${getQueryString(data.query)}`
  })
  if (callback) callback(res.data)
})

// /**
//  * Fetch unread group messages count
//  * @param {String} groupId
//  * @param {Function} callback callback function
//  */
// export const fetchUnreadGroupMessagesCount = catchAsync(
//   async (data, callback) => {
//     const res = await axios({
//       method: 'GET',
//       url: `/chat/unread/group/${data.groupId}`
//     })
//     if (callback) callback(res.data.unreadMessagesCount)
//   }
// )

/**
 * Fetch unread messages data
 * @param {String} data.category direct, group
 * @param {Function} callback callback function
 */
export const fetchUnreadMessagesByCategory = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/chat/unread?category=${data.category}`
    })

    const mappedData = {}

    if (data.category === 'group') {
      for (let unreadMessageData of res.data) {
        mappedData[unreadMessageData._id] = {
          ...unreadMessageData
        }
      }
      dispatch({ type: CHANNEL.FETCHED_UNREAD_MESSAGES, payload: mappedData })
    } else {
      for (let unreadMessageData of res.data) {
        mappedData[unreadMessageData.user] = {
          ...unreadMessageData
        }
      }
      dispatch({
        type: CHANNEL.FETCHED_PERSONAL_UNREAD_MESSAGES,
        payload: mappedData
      })
    }

    if (callback) callback(res.data)
  }, callback)
}

export const uploadChatAttachment = (data, callback) => {
  const uploader = new Uploader({
    ...data.fileData,
    module: 'chat',
    metaData: data.data
  })
  uploader
    .onError(error => {
      callback(error.message ?? 'File uploading failed!', true)
    })
    .onComplete(res => {
      callback(res)
    })

  uploader.start()
}

export const fetchGroups = callback => {
  return async (dispatch, getState) => {
    const { chats } = getState()
    try {
      const res = await axios({
        method: 'GET',
        url: '/group/user'
      })
      // to at least show the list while unread counts are getting fetched for each group
      if (!chats.groups.length)
        dispatch({
          type: CHANNEL.FETCH_GROUPS,
          payload: {
            groups: res.data.map(grp => ({ ...grp, unread: 0 })),
            totalUnreadCount: 0
          }
        })
      // var totalUnreadCount = 0
      // const modified = res.data.map(async ch => {
      //   let chUnread = 0
      //   const res = await axios({
      //     method: 'GET',
      //     url: `/chat/unread/group/${ch._id}`
      //   })
      //   if (typeof res.data.unreadMessagesCount === 'number')
      //     chUnread = res.data.unreadMessagesCount
      //   totalUnreadCount += chUnread
      //   return { ...ch, unread: chUnread }
      // })
      // Promise.all(modified).then(modified => {
      //   dispatch({
      //     type: CHANNEL.FETCH_GROUPS,
      //     payload: { groups: modified, totalUnreadCount }
      //   })
      // })
      if (callback) callback(res, false)
    } catch (err) {
      // if (callback) {
      //   callback(errorMessages.ERROR_MESSAGE, true)
      // }
    }
  }
}

// export const setReadConversation = group => {
//   return dispatch => {
//     dispatch({
//       type: CHANNEL.SET_READ_ALL_IN_CHAT,
//       payload: group.id
//     })
//   }
// }

export const updateUnreadMessageCount = ({ groupId, value }) => {
  return {
    type: CHANNEL.UPDATE_UNREAD_MESSAGE_COUNT,
    payload: { groupId, value }
  }
}

export const updatePersonalUnreadMessageCount = ({ channelId, value }) => {
  return {
    type: CHANNEL.UPDATE_PERSONAL_UNREAD_MESSAGE_COUNT,
    payload: { channelId, value }
  }
}

export const updateGroupByGroupId = (group, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/group/${group.id}`,
        data: {
          title: group.title,
          newMembers: group.newMembers,
          removeMembers: group.removeMembers,
          description: group.description
        }
      })
      if (callback) {
        callback(res.data, false)
      }
      dispatch({
        type: CHANNEL.UPDATE_GROUP_BY_ID,
        payload: { ...res.data, unread: 0 }
      })
    } catch (err) {
      if (callback) {
        callback(errorMessages.ERROR_MESSAGE, true)
      }
    }
  }
}

export const archiveChatGroup = (data, callback) => {
  // sending channel key in data<object> param, from /chat's navbar only, to not lose 'unread' count
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/group/${data.id}`,
      data: data.data
    })

    if (callback)
      callback(
        data.channel
          ? { ...data.channel, isArchive: !data.channel.isArchive }
          : res.data
      )

    dispatch({
      type: CHANNEL.UPDATE_GROUP_BY_ID,
      payload: res.data
    })
  }, callback)
}

export const deleteGroupByGroupId = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/group/${data.groupId}`
    })

    callback(res.data)
    dispatch({
      type: CHANNEL.DELETE_GROUP_BY_ID,
      payload: data.groupId
    })
  }, callback)
}

// export const SetEditable = val => {
//   return dispatch => {
//     dispatch({
//       type: CHANNEL.SET_EDITABLE,
//       payload: val
//     })
//   }
// }

/**
 * Update group user's role
 * @param {String} data.groupId
 * @param {Object} data.body user, role
 * @param {Function} callback callback function
 */
export const updateChannelRoleForUser = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/group/${data.groupId}`,
      method: 'PUT',
      data: data.body
    })

    if (callback) callback(res.data)
    dispatch({
      type: CHANNEL.UPDATE_GROUP_BY_ID,
      payload: res.data
    })
  }, callback)
}

export const toggleChatPanel = () => dispatch => {
  dispatch({
    type: CHANNEL.TOGGLE_CHAT_PANEL
  })
}

export const changeGroupUpdatedAt = ({ groupId, updatedAt }) => {
  return {
    type: CHANNEL.CHANGE_GROUP_UPDATED_AT,
    payload: { groupId, updatedAt }
  }
}

/**
 * Set currently active group id
 * @param {String} data.groupId
 * @param {Function} callback callback function
 * @returns {Object} type, payload
 */
export const handleSetCurrentChannelId = channelId => {
  return { type: CHANNEL.SET_CURRENT_CHANNEL_ID, payload: channelId }
}

export const addNewChatGroup = data => {
  return {
    type: CHANNEL.CREATE_GROUP,
    payload: data
  }
}

export const updateGroup = data => {
  return {
    type: CHANNEL.UPDATE_GROUP_BY_ID,
    payload: data
  }
}

export const deleteGroup = groupId => {
  return {
    type: CHANNEL.DELETE_GROUP_BY_ID,
    payload: groupId
  }
}
