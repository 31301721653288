import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { Button } from '@material-ui/core'
import TemplateView from './TemplateView'
import Pill from 'global/globalComponents/Pill/Pill'
import CreateTemplateModal from './CreateTemplateModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SingleTemplate = ({
  handleBack,
  data,
  handleSelectTemplate,
  updateDataHandler
}) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <div>
        <button
          onClick={handleBack}
          className="text-primary-main text-sm dark:text-dark-light"
        >
          <ArrowBackRoundedIcon
            fontSize="small"
            className="dark:text-dark-light"
          />
          &nbsp; {t('BACK')}
        </button>
        <div className="my-4 flex flex-wrap justify-between items-center">
          <div className="flex items-center space-x-2">
            <h1 className="text-primary-dark text-lg 2xl:text-xl font-medium my-2 dark:text-dark-light2">
              {data.title}
            </h1>
            <span>
              <Pill variant="green">
                {t(data.category?.toUpperCase()) ?? t('OTHERS')}
              </Pill>
            </span>
          </div>
          <div className="flex flex-wrap gap-2 sm:gap-0 items-center sm:space-x-2">
            <Button onClick={() => handleSelectTemplate(data)}>
              {t('USE_TEMPLATE')}
            </Button>
            {data.type === 'private' && (
              <Button variant="outlined" onClick={() => setOpen(true)}>
                {t('EDIT_TEMPLATE')}
              </Button>
            )}
          </div>
        </div>

        <p className=" text-primary-mid-dark-2 text-sm 2xl:text-lg pb-6 dark:text-dark-light">
          {data.description}
        </p>
        <p className="text-right text-primary-dark font-medium text-sm pr-2 dark:text-dark-light">
          {t('TEMPLATE_PREVIEW')}
        </p>
        <TemplateView templateData={data} />
      </div>
      <CreateTemplateModal
        open={open}
        handleClose={() => setOpen(false)}
        tempData={data}
        updateDataHandler={updateDataHandler}
      />
    </div>
  )
}

export default SingleTemplate
