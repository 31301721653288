import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Button, TextField } from '@material-ui/core'
import { Tooltip } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { t } from 'i18next'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'

const offeringFields = {
  title: '',
  description: '',
  list: ['']
}

const ServiceOfferings = React.forwardRef(({ offerings }, ref) => {
  const [offeringsData, setOfferingsData] = useState([{ ...offeringFields }])

  useImperativeHandle(ref, () => ({
    getOfferingsList: () => {
      const offerings = offeringsData
        .filter(item => item.title.trim() !== '')
        .map(offering => {
          offering.list = offering.list.filter(item => Boolean(item.trim()))
          return offering
        })

      return offerings
    }
  }))

  useEffect(() => {
    const mappedOfferings = offerings.map(item => {
      if (item.list.length === 0) {
        item.list.push('')
      }
      return item
    })

    setOfferingsData(
      mappedOfferings.length > 0 ? mappedOfferings : [{ ...offeringFields }]
    )
  }, [offerings])

  const handleAddOfferingField = () => {
    setOfferingsData(prev => [...prev, { ...offeringFields }])
  }

  const handleAddSubOfferingField = offeringIndex => {
    setOfferingsData(prev =>
      prev.map((offering, index) => {
        if (index === offeringIndex)
          return { ...offering, list: [...offering.list, ''] }
        return offering
      })
    )
  }

  const handleRemoveOffering = index => {
    setOfferingsData(prev => prev.filter((item, idx) => idx !== index))
  }

  const handleRemoveSubOffering = (offeringIdx, subOfferingIdx) => {
    const offerings = [...offeringsData]

    offerings[offeringIdx] = {
      ...offerings[offeringIdx],
      list: offerings[offeringIdx].list.filter(
        (item, index) => index !== subOfferingIdx
      )
    }

    setOfferingsData(offerings)
  }

  const handleUpdateOffering = e => {
    setOfferingsData(prev =>
      prev.map((offering, index) => {
        if (index === parseInt(e.target.dataset.index))
          return { ...offering, [e.target.name]: e.target.value }
        return offering
      })
    )
  }

  const handleUpdateSubOffering = e => {
    setOfferingsData(prev =>
      prev.map((offering, index) => {
        if (index === parseInt(e.target.dataset.offeringIndex))
          return {
            ...offering,
            list: offering.list.map((item, idx) => {
              if (idx === parseInt(e.target.dataset.index)) {
                return e.target.value
              }
              return item
            })
          }
        return offering
      })
    )
  }

  return (
    <>
      <FormLabel htmlFor="offerings">Offerings</FormLabel>

      <div>
        {offeringsData.map((offering, index) => (
          <div key={index} className="mb-4 flex items-start">
            <div className="flex-1">
              <div className="mb-2">
                <TextField
                  name="title"
                  value={offering.title}
                  onChange={handleUpdateOffering}
                  placeholder={t('TITLE')}
                  inputProps={{ 'data-index': index }}
                  fullWidth
                />
              </div>

              <div className="mb-2">
                <TextField
                  name="description"
                  value={offering.description}
                  onChange={handleUpdateOffering}
                  placeholder={`${t('DESCRIPTION')} (optional)`}
                  multiline={true}
                  rows={2}
                  inputProps={{ 'data-index': index }}
                  fullWidth
                />
              </div>

              <div className="flex items-start">
                <Tooltip title="Add sub offering">
                  <button
                    onClick={() => handleAddSubOfferingField(index)}
                    className="flex-shrink-0 border-gray-600 text-gray-800 border-dashed w-5 h-5 text-smaller inline-flex justify-center items-center mr-2 border rounded-full"
                  >
                    <PlusIcon />
                  </button>
                </Tooltip>

                <div className="flex-1">
                  {offering.list.map((item, idx) => (
                    <div className="mb-2 flex items-center" key={idx}>
                      <TextField
                        name={`subOffering-${idx}`}
                        value={item}
                        onChange={handleUpdateSubOffering}
                        placeholder={`${t('Sub offering')} (optional)`}
                        inputProps={{
                          'data-offering-index': index,
                          'data-index': idx
                        }}
                        fullWidth
                      />

                      {idx > 0 && (
                        <Tooltip title="Remove sub offering">
                          <button
                            onClick={() => handleRemoveSubOffering(index, idx)}
                            className="ml-2"
                          >
                            <CrossIcon className="w-4 h-4" />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {index > 0 && (
              <Tooltip title="Remove offering">
                <button
                  onClick={() => handleRemoveOffering(index)}
                  className="ml-2"
                >
                  <CrossIcon className="w-5 h-5" />
                </button>
              </Tooltip>
            )}
          </div>
        ))}

        <Button
          size="small"
          onClick={handleAddOfferingField}
          variant="outlined"
          startIcon={<PlusIcon />}
        >
          New Offering
        </Button>
      </div>
    </>
  )
})

export default ServiceOfferings
