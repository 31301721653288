import React, { useState } from 'react'
import { Button, TextField, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'

const TaxDetails = ({ taxDetails, handleAddField, onRemove }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [field, setField] = useState({
    label: '',
    value: ''
  })

  const handleAddNewField = () => {
    handleAddField(field)
    setField({ label: '', value: '' })
  }

  return (
    <div>
      <div className="mb-4 font-medium text-sm">
        {t('ADD_YOUR')} {t('TAX_DETAILS')}
        <span className="text-xs font-normal ml-2">{t('MAXIMUM_5')}</span>
      </div>
      <div className="flex flex-wrap gap-2 mt-2 mb-4">
        <TextField
          value={field.label}
          placeholder={t('FIELD')}
          className="border rounded border-custom-gray-main px-2 mr-4"
          onChange={e => setField(prev => ({ ...prev, label: e.target.value }))}
          style={{
            background:
              theme.palette.type !== 'light'
                ? theme.custom.darkMode.background
                : ''
          }}
        />
        <TextField
          value={field.value}
          onChange={e => setField(prev => ({ ...prev, value: e.target.value }))}
          placeholder={t('VALUE')}
          className="border rounded border-custom-gray-main px-2 sm:flex-1 mr-4"
          style={{
            background:
              theme.palette.type !== 'light'
                ? theme.custom.darkMode.background
                : ''
          }}
        />
        <Button onClick={handleAddNewField} disabled={taxDetails.length >= 5}>
          {t('ADD')}
        </Button>
      </div>
      <div>
        {taxDetails.map((item, index) => (
          <div key={index} className="flex mb-4 gap-4 text-smaller font-normal">
            <p className=" border rounded px-2 py-1 w-1/4 mr-4 truncate">
              {item.label}
            </p>
            <p className="border rounded px-2 py-1 w-3/4 truncate">
              {item.value}
            </p>

            <button
              onClick={() => onRemove(index)}
              data-index={index}
              className="ml-2 text-red-500"
            >
              <CloseIcon className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TaxDetails
