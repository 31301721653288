import axios from 'axios'
import { LOGIN } from './reduers'
import { catchAsyncDispatch, storeAccessToken, storeRefreshToken } from 'utils'

/**
 * Agency user login action
 * @route `user/login`
 * @param {Object} data User data
 * @param {Function} callback
 */
export const agencyUserLogin = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/user/login',
      data: data.data,
      isPublic: true
    })

    storeAccessToken(res.data.token.accessToken)
    storeRefreshToken(res.data.token.refreshToken)
    dispatch({ type: LOGIN.LOGGED_IN })
    if (callback) callback(res.data)
  }, callback)
}

export const setUserLoggedIn = () => {
  return { type: LOGIN.LOGGED_IN }
}

export const setUserLoggedOut = () => {
  return { type: LOGIN.LOGOUT }
}

export const storeTempAuthTokens = ({ tempAccessToken, tempRefreshToken }) => ({
  type: LOGIN.FETCHED_TEMP_AUTH_TOKEN,
  payload: { tempAccessToken, tempRefreshToken }
})
