export const FEEDS = {
  FETCH_FEEDS: 'FETCH_FEEDS',
  SELECTED_FEED: 'SELECTED_FEED',
  SET_LOADING: 'SET_LOADING_FEED',
  UPDATE_FEEDS_BY_ONE: 'UPDATE_FEEDS',
  RESET_FEEDS: 'RESET_FEEDS'
}

const initialState = {
  fetched: false,
  feeds: [],
  page: 0,
  selectedFeed: {},
  loading: true,
  loadMore: true
}

const FeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case FEEDS.RESET_FEEDS:
      return {
        fetched: false,
        feeds: [],
        page: 0,
        selectedFeed: {},
        loading: true,
        loadMore: true
      }
    case FEEDS.FETCH_FEEDS: {
      if (action.payload.page === state.page)
        return {
          ...state,
          fetched: true,
          feeds: [...state.feeds, ...action.payload.data],
          page: state.page + 1,
          loadMore: action.payload.data.length >= 5
        }
      else return state
    }

    case FEEDS.SELECTED_FEED:
      return {
        ...state,
        selectedFeed: action.payload
      }

    case FEEDS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case FEEDS.UPDATE_FEEDS_BY_ONE:
      return {
        ...state,
        feeds: [action.payload, ...state.feeds]
      }

    default:
      return state
  }
}

export default FeedReducer
