import React from 'react'
import Features from './Features'
import About from './About'
import Services from './Services'
import Testimonials from './Testimonials'
import FAQ from './FAQ'
import Footer from './Footer'
import clsx from 'clsx'
import DemoCheckoutSection from '../DemoCheckoutSection/DemoCheckoutSection'
import { createStyles, makeStyles } from '@material-ui/core'

const Template2 = ({
  templateSections,
  updateTemplateSection,
  updateSectionBlock,
  addBlockItem,
  removeBlockItem,
  isEditable,
  firstSectionElement
}) => {
  const classes = useStyles()

  const renderSection = section => {
    const commonProps = {
      classes,
      data: section,
      isEditable,
      updateTemplateSection: ({ field, value }) => {
        updateTemplateSection({ sectionId: section.id, field, value })
      },
      updateSectionBlock: ({ blockId, field, value }) => {
        updateSectionBlock({
          sectionId: section.id,
          blockId,
          field,
          value
        })
      },
      addBlockItem: ({ newItem }) => {
        addBlockItem({
          sectionId: section.id,
          newItem
        })
      },
      removeBlockItem: ({ blockId }) => {
        removeBlockItem({
          sectionId: section.id,
          blockId
        })
      }
    }

    // Note: Here I'm using JS currying technic: updateTemplateSection, updateSectionBlock
    switch (section.name) {
      case 'about':
        return <About {...commonProps} />

      case 'testimonials':
        return <Testimonials {...commonProps} />

      case 'features':
        return <Features {...commonProps} />

      case 'services':
        return <Services {...commonProps} />

      case 'faq':
        return <FAQ {...commonProps} />

      case 'footer':
        return <Footer {...commonProps} />

      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <div className={clsx('mt-8', classes.sectionContainer)}>
        {firstSectionElement || <DemoCheckoutSection />}
      </div>
      {templateSections
        .filter(section => !section.isHidden)
        .map(section => (
          <div key={section.id}>{renderSection(section)}</div>
        ))}
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      height: '100%',
      overflow: 'auto'
    },
    sectionContainer: {
      width: '90%',
      maxWidth: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: 48,
      paddingBottom: 48
    },
    sectionHeader: {
      textAlign: 'center',
      marginBottom: 48
    },
    sectionHeading: {
      fontSize: 32,
      marginBottom: 10,
      fontWeight: 600,
      color: '#384047'
    },
    sectionSubHeading: {
      fontSize: 20,
      color: '#777',
      fontWeight: 300
    },
    aboutBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'space-between',
      margin: '48px 0',
      gap: 22,

      '@media (min-width: 992px)': {
        '&': {
          flexDirection: 'row'
        },

        '&:last-child': {
          flexDirection: 'row-reverse'
        }
      }
    },
    aboutBlockImage: {
      width: 300,
      flexShrink: 0
    },
    aboutBlockText: {
      '& > div:first-child': {
        fontSize: 20,
        fontWeight: 600,
        color: '#384047',
        marginBottom: 8
      },
      '& > div:last-child': {
        fontSize: 16,
        color: '#777',
        fontWeight: 300
      },

      '@media (min-width: 992px)': {
        '&': {
          width: '70%'
        }
      }
    },
    testimonialsSection: {
      backgroundColor: '#e5e8ec'
    },
    testimonials: {
      display: 'flex',
      gap: 32,
      alignItems: 'flex-end',

      '& > div': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    reviewTextContainer: {
      padding: 22,
      backgroundColor: '#fff',
      fontSize: 17,
      color: '#777',
      fontFamily: 'serif',
      borderRadius: 8,
      position: 'relative',
      marginBottom: 20,
      boxShadow: '0 0 30px -10px rgba(0, 0, 0, 0.35)',

      '&::before': {
        content: "''",
        borderWidth: 16,
        borderColor: '#ffffff transparent transparent transparent',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 100%)'
      }
    },
    featuresContainer: {
      display: 'grid',
      alignItems: 'start',
      gridGap: 48,

      '& > div': {
        display: 'flex',
        alignItems: 'center'
      },

      '@media (min-width: 992px)': {
        '&': {
          gridTemplateColumns: 'repeat(2, 1fr)'
        }
      }
    },
    featureBlockImage: {
      flexShrink: 0,
      width: 120,
      height: 120,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#e5e8ec',
      borderRadius: '50%',
      marginRight: 32
    },
    featureBlockText: {
      '& div:first-child': {
        fontSize: 20,
        fontWeight: 600,
        color: '#384047',
        marginBottom: 8
      },

      '& div:last-child': {
        fontSize: 15,
        fontWeight: 300,
        color: '#777'
      }
    },
    serviceBlocks: {
      display: 'flex',
      flexDirection: 'column',

      '& > div': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '18px'
      },

      '@media (min-width: 992px)': {
        '&': {
          flexDirection: 'row'
        }
      }
    },
    serviceBlockImage: {
      flexShrink: 0,
      width: 140,
      height: 140,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#e5e8ec',
      borderRadius: '50%',
      marginBottom: 8
    },
    serviceBlockText: {
      '& div:first-child': {
        fontSize: 20,
        fontWeight: 600,
        color: '#384047',
        marginBottom: 8
      },

      '& div:last-child': {
        fontSize: 15,
        fontWeight: 300,
        color: '#777'
      }
    },
    faqBlocks: {
      display: 'grid',
      gridGap: 48,

      '@media (min-width: 992px)': {
        '&': {
          gridTemplateColumns: 'repeat(2, 1fr)'
        }
      }
    },
    gridBlockText: {
      position: 'relative',
      '& div:first-child': {
        fontSize: 18,
        fontWeight: 600,
        color: '#384047',
        marginBottom: 8
      },

      '& div:last-child': {
        fontSize: 14,
        fontWeight: 300,
        color: '#777'
      }
    },
    footerBlocks: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#fff',
      fontSize: 14,

      '& > div': {
        flex: 1
      }
    }
  })
)

export default Template2
