import React, { useEffect, useState } from 'react'
import { Popper, Backdrop } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
// import NotificationGroupItem from './NotificationGroupCard/NotificationGroupItem'
// import { Loader } from 'global/globalComponents'
import {
  fetchNotifications
  // markAllNotificationsRead
} from 'thunks/accountLogs/action'
import {
  fireErrorToaster
  // fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom'
// import { ReactComponent as CheckIcon } from 'static/svg/check.svg'
import NotificationsList from './NotificationList/NotificationsList'

const NotificationPopper = ({ anchorEl, onClose }) => {
  return (
    <Backdrop
      open={Boolean(anchorEl)}
      onClick={onClose}
      style={{ zIndex: 9999, background: 'transparent' }}
    >
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        // placement="bottom right"
        disablePortal={false}
        style={{ zIndex: 9999 }}
      >
        <NotificationPopperContent onClose={onClose} />
      </Popper>
    </Backdrop>
  )
}

const NotificationPopperContent = ({ onClose }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const notificationsData = useSelector(state => state.accountLogs.data)
  const [loading, setLoading] = useState(true)
  // const [loadingBtn, setLoadingBtn] = useState(false)
  const allNotifications =
    notificationsData['all']?.filter(item => Boolean(item._id)) || []

  // let notifs = useMemo(() => {
  //   let arr = []
  //   notificationsData['all'].forEach(item => arr.push(...item.notif))
  //   return arr
  // }, [notificationsData])

  useEffect(() => {
    if (notificationsData['all'].length === 0) {
      fetchNotifs()
    } else {
      setLoading(false)
    }
  }, [])

  const fetchNotifs = () => {
    dispatch(
      fetchNotifications({ group: 'all', page: 0 }, (res, err) => {
        setLoading(false)
        if (err) dispatch(fireErrorToaster(res))
      })
    )
  }

  const handleRedirect = () => {
    history.push(`${getUserPath(meData.role)}/notification/all`)
    onClose()
  }

  // const handleMarkAllRead = () => {
  //   setLoadingBtn(true)
  //   dispatch(
  //     markAllNotificationsRead({ category: 'all' }, (res, err) => {
  //       setLoadingBtn(false)
  //       if (err) dispatch(fireErrorToaster(res))
  //       else {
  //         dispatch(fireSuccessToaster(t('MARKED_ALL_NOTIFICATIONS_AS_READ')))
  //         fetchNotifs()
  //       }
  //     })
  //   )
  // }

  return (
    <div className="bg-white dark:bg-dark-main4 p-4 rounded shadow border dark:border-dark-main">
      <div className="flex justify-between items-center">
        <div className="font-medium mb-2 dark:text-dark-light2">
          {t('NOTIFICATIONS')}
        </div>
      </div>
      <div
        className="overflow-auto dark:text-dark-light2"
        style={{ width: 600, maxHeight: '50vh' }}
      >
        <NotificationsList notifications={allNotifications} loading={loading} />
      </div>
      <div className="text-center">
        <button
          onClick={handleRedirect}
          className="text-blue-600 dark:text-dark-light2 text-sm font-medium inline-flex items-center mt-2"
        >
          <span>{t('MORE_INFO')}</span>
          <ChevronRight className="mt-0.5" />
        </button>
      </div>
    </div>
  )
}

export default NotificationPopper
