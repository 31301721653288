import React, { useEffect, useState, useMemo } from 'react'
import { Popover } from '@material-ui/core'
import { ColoredAvatars } from 'global/globalComponents'
import taskSidePanelStyles from 'components/Workspace/Panel/taskSidePanelStyles'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Diagram } from 'static/svg/project-diagram.svg'
import TimerFooter from './TimerFooter'
import useTimerFunctions from './useTimerFunctions'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { deleteTimeTracked } from 'thunks/Tracking/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useTranslation } from 'react-i18next'

const TimeTrackingPopup = ({
  open,
  anchorEl,
  onClose,
  time,
  isActive,
  startTimer,
  stopTimer,
  // timeSpend,
  totalDuration,
  elmFor,
  task,
  totalTimeTracking
}) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      style={{
        top: elmFor === 'header' ? '15px' : '8px',
        left: '-70px',
        zIndex: 2000
      }}
    >
      <TimeTrackingPopupContent
        onClose={onClose}
        time={time}
        isActive={isActive}
        startTimer={startTimer}
        stopTimer={stopTimer}
        totalDuration={totalDuration}
        elmFor={elmFor}
        task={task}
        totalTimeTracking={totalTimeTracking}
      />
    </Popover>
  )
}

const TimeTrackingPopupContent = ({
  onClose,
  time,
  isActive,
  startTimer,
  stopTimer,
  totalDuration,
  elmFor,
  task,
  totalTimeTracking
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = taskSidePanelStyles()
  const [expandedUser, setExpandedUser] = useState(null)
  const [taskSubtaskTime, setTaskSubtaskTime] = useState()
  const meData = useSelector(state => state.me.data)
  const { getTimeFromSeconds } = useTimerFunctions()
  const { taskTimer, subtaskTimer } = useSelector(state => state.timer)

  const userWiseIntervals = useMemo(() => {
    return elmFor === 'task' ? taskTimer.data : subtaskTimer.data

    // if (elmFor === 'task') return taskTimer.data?.intervals ?? []
    // return subtaskTimer.data?.intervals ?? []
  }, [elmFor, taskTimer, subtaskTimer])

  // useEffect(() => {
  //   if (timeSpend?.totalDuration && elmFor === 'task') {
  //     const totalSeconds =
  //       timeSpend.totalDuration + (task.subtaskTimerDuration ?? 0) / 1000
  //     const { hours, minutes, seconds } = getTimeFromSeconds(totalSeconds)
  //     setTaskSubtaskTime(`${hours}h ${minutes}m ${seconds}s`)
  //   }
  // }, [task.subtaskTimerDuration, timeSpend?.totalDuration, elmFor])
  useEffect(() => {
    if (elmFor === 'task') {
      // const totalTaskMS = userWiseIntervals.reduce((total, curr) => {
      //   return total + curr.time
      // }, 0)
      const totalSeconds =
        (totalDuration + (task.subtaskTimerDuration ?? 0)) / 1000
      // timeSpend.totalDuration + (task.subtaskTimerDuration ?? 0) / 1000
      const { hours, minutes, seconds } = getTimeFromSeconds(totalSeconds)
      setTaskSubtaskTime(`${hours}h ${minutes}m ${seconds}s`)
    }
  }, [totalDuration, task])

  const calculateInterval = count => {
    const { hours, minutes, seconds } = getTimeFromSeconds(count)

    return {
      second: seconds,
      minute: minutes,
      hour: hours
    }
  }

  const getSingleUserTime = time => {
    const { hours, minutes, seconds } = getTimeFromSeconds(
      Math.floor(time / 1000)
    )
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    )
  }

  const handleExpandUser = e => {
    const userId = e.currentTarget.dataset.user
    if (expandedUser === userId) setExpandedUser(null)
    else setExpandedUser(userId)
  }

  const handleDeleteEntryTime = data => {
    dispatch(
      deleteTimeTracked(data._id, 'workspace', (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(t('TIME_DELETED_SUCCESSFULLY')))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <div style={{ width: '280px' }}>
      {userWiseIntervals.length > 0 ? (
        <div>
          <div className="flex flex-col pt-5 mb-4">
            <div className="flex space-x-2 px-5 items-center relative">
              <p
                className="text-xs text-gray-500 font-medium flex-1"
                style={{ fontSize: '10px' }}
              >
                {/* THIS {elmFor === 'subtask' ? 'SUBTASK' : 'TASK'} ONLY */}
                {t('TOTAL_TASK_ONLY', {
                  data: elmFor === 'subtask' ? 'SUBTASK' : 'TASK'
                })}
              </p>
              <p
                className="text-xs text-gray-700 font-medium"
                style={{ fontSize: 11 }}
              >{`${time.hours}h ${time.minutes}m ${time.seconds}s`}</p>
            </div>
            {elmFor === 'task' && (
              <div className="flex space-x-2 px-5 mt-2 items-center relative">
                <p
                  className="flex items-center text-xs text-gray-500 font-medium flex-1"
                  style={{ fontSize: '10px' }}
                >
                  <Diagram className="h-3 w-3 mr-2" style={{ fill: 'grey' }} />
                  <span>{t('TOTAL_WITH_SUBTASKS')}</span>
                </p>
                <p
                  className="text-xs text-gray-700 font-medium"
                  style={{ fontSize: 11 }}
                >
                  {taskSubtaskTime}
                </p>
              </div>
            )}
          </div>
          <div style={{ maxHeight: '35vw', overflow: 'auto' }}>
            {userWiseIntervals.map((item, index) => (
              <>
                <div
                  className={`flex justify-between items-center text-sm text-gray-500 cursor-pointer py-2.5 px-4 border-b border-primary-light hover:bg-primary-light dark:hover:bg-dark-main`}
                  key={index}
                  onClick={handleExpandUser}
                  data-user={item.user._id}
                >
                  <div className="flex items-center space-x-2">
                    <ColoredAvatars user={item.user} />
                    <p className="font-medium dark:text-dark-light">
                      {meData._id === item.user._id ? 'Me' : item.user.name}
                    </p>
                  </div>
                  <div className="text-xs">{getSingleUserTime(item.time)}</div>
                </div>
                {expandedUser === item.user._id && (
                  <div className={classes.timeSpend}>
                    {item.intervals.map(item => (
                      <Interval
                        calculateInterval={calculateInterval}
                        data={item}
                        meData={meData}
                        deleteEntryTime={handleDeleteEntryTime}
                      />
                    ))}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      ) : null}
      <TimerFooter
        onClose={onClose}
        time={time}
        isActive={isActive}
        startTimer={startTimer}
        stopTimer={stopTimer}
        elmFor={elmFor}
        task={task}
        totalTimeTracking={totalTimeTracking}
      />
    </div>
  )
}

const Interval = ({ calculateInterval, data, meData, deleteEntryTime }) => {
  const time = calculateInterval(data.duration / 1000)
  return (
    <div
      className="flex items-center text-xs text-gray-500"
      style={{
        borderBottom: '1px solid #efeef0',
        padding: '10px 5px 10px 10px'
      }}
    >
      <div className="w-1/3">
        <span>{time.hour}</span>
        <span>:</span>
        <span>{time.minute}</span>
        <span>:</span>
        <span>{time.second}</span>
      </div>
      <div className="pl-4 w-2/3 flex items-center justify-between">
        <div>{format(new Date(data?.start ?? ''), 'd MMM')}</div>
        <div>
          {(data.user._id ?? data.user) === meData._id &&
            data.category === 'manual' && (
              <DeleteIcon
                className="text-custom-red-main cursor-pointer"
                width="1.35em"
                height="1.35em"
                onClick={() => deleteEntryTime(data)}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default TimeTrackingPopup
