import { makeStyles, createStyles } from '@material-ui/core'

const setupStyles = makeStyles(theme =>
  createStyles({
    dialogPaper: {
      width: '40vw'
    },
    integrationCards: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
      gridGap: '1.5rem'
    },
    tabWrapper: {
      alignItems: 'start'
    },
    tabRoot: {
      padding: '0'
    }
  })
)

export default setupStyles
