import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  CustomPopper,
  CustomTooltip,
  LoadingButton
} from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { updateService } from 'thunks/service/actions'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { useTranslation } from 'react-i18next'
import Pill from 'global/globalComponents/Pill/Pill'
import { ReactComponent as InfoIcon } from 'static/svg/i.svg'

const AttachAddOnsPopper = ({ anchorEl, onClose, ...rest }) => {
  if (!anchorEl) return null

  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <AttachAddOnsPopperContent {...rest} onClose={onClose} />
    </CustomPopper>
  )
}

const AttachAddOnsPopperContent = ({
  allAddOns,
  selectedAddOns,
  serviceId,
  onClose
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [addOnsList, setAddOnsList] = useState([...selectedAddOns])
  const [hasChanged, setHasChanged] = useState(false)

  const handleUpdateServiceAddOns = () => {
    setLoading(true)

    const addons = addOnsList
      .filter(addOn => {
        let match = selectedAddOns.find(item => item._id === addOn._id)
        return !Boolean(match)
      })
      .map(addOn => addOn._id)

    const removeAddons = selectedAddOns
      .filter(addOn => {
        let match = addOnsList.find(item => item._id === addOn._id)
        return !Boolean(match)
      })
      .map(addOn => addOn._id)

    dispatch(
      updateService(
        {
          id: serviceId,
          data: {
            addons,
            removeAddons
          }
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            setLoading(false)
          } else {
            dispatch(fireSuccessToaster(t('SERVICE_CREATED_SUCCESSFULLY')))
            onClose()
          }
        }
      )
    )
  }

  const handleUpdateAddOnsList = (value, action) => {
    setHasChanged(true)

    if (action === 'add') {
      setAddOnsList(prev => [...prev, value])
    } else {
      setAddOnsList(prev => prev.filter(addOn => addOn._id !== value._id))
    }
  }

  const filteredAddOns = allAddOns.filter(item => {
    const match = addOnsList.find(addOn => addOn._id === item._id)
    return !Boolean(match)
  })

  return (
    <div className="w-72">
      <header className="px-4 py-2 border-b">
        {addOnsList.length > 0 ? (
          <div className="flex items-center space-x-2">
            {addOnsList.map((item, index) => (
              <div
                key={item._id}
                // className="text-tiny bg-pink-100 px-3 py-2 rounded-full text-pink-800 font-medium leading-3 relative show-on-hover-parent"
                className="show-on-hover-parent relative"
              >
                <button
                  style={{ top: -4, right: -4 }}
                  className="absolute bg-red-500 text-white text-sm rounded-full p-0.5 show-on-hover-child"
                  onClick={() => handleUpdateAddOnsList(item, 'remove')}
                >
                  <CrossIcon />
                </button>
                <Pill variant={index % 2 === 0 ? 'pink' : 'blue'}>
                  {item.name}
                </Pill>
                {/* <span>{item.name}</span> */}
              </div>
            ))}
          </div>
        ) : (
          <div className="flex items-center">
            <div className="text-smaller font-medium text-gray-600 mr-1">
              {t('ATTACH_ADD_ONS')}{' '}
            </div>
            <CustomTooltip
              title={t('ADD_ONS_SAME_CURRENCY_INFO')}
              placement="top"
            >
              <InfoIcon className="text-primary-main" />
            </CustomTooltip>
          </div>
        )}
      </header>

      <div>
        {filteredAddOns.length ? (
          filteredAddOns.map(item => (
            <>
              <div
                className="px-4 py-2 hover:bg-gray-100 text-smaller text-primary-dark dark:text-dark-light dark:hover:bg-dark-main w-full flex justify-between items-center cursor-pointer"
                onClick={() => handleUpdateAddOnsList(item, 'add')}
                key={item._id}
              >
                {item.name}
              </div>
            </>
          ))
        ) : (
          <div className="px-4 py-2 text-smaller text-gray-400">
            {t('NO_ADD_ONS_TO_SELECT')}
          </div>
        )}
      </div>
      <div className="text-right px-4 py-2">
        <LoadingButton
          size="small"
          onClick={handleUpdateServiceAddOns}
          loading={loading}
          disabled={!hasChanged}
        >
          {t('SAVE')}
        </LoadingButton>
      </div>
    </div>
  )
}

export default AttachAddOnsPopper
