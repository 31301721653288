import { ReactComponent as ClockIcon } from 'static/svg/clock.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { fetchFileById, fetchFolderById } from 'thunks/fileAssets/actions'
import { fetchCustomInvoiceById } from 'thunks/invoices/actions'
import { fetchSubtaskById } from 'thunks/subTask/actions'
import { fetchSingleTaskById } from 'thunks/task/actions'
import { fetchWorkspaceById } from 'thunks/workspace/actions'

export const statusData = {
  pending: {
    status: 'Pending',
    theme: {
      color: '#f9bc00'
    },
    icon: ClockIcon
  },
  rejected: {
    status: 'Rejected',
    theme: {
      color: '#ef4035'
    },
    icon: CrossIcon
  },
  approved: {
    status: 'Approved',
    theme: {
      color: '#36c344'
    },
    icon: CheckCircleIcon
  }
}

export const getApprovalItemData = ({
  module,
  moduleId,
  callback,
  dispatch
}) => {
  if (module === 'task') {
    fetchSingleTaskById({ taskId: moduleId }, callback)
  } else if (module === 'subTask') {
    fetchSubtaskById({ id: moduleId }, callback)
  } else if (module === 'project') {
    fetchWorkspaceById({ id: moduleId }, callback)
  } else if (module === 'invoice') {
    dispatch(fetchCustomInvoiceById({ id: moduleId }, callback))
  } else if (module === 'file') {
    fetchFileById({ id: moduleId }, callback)
  } else if (module === 'folder') {
    fetchFolderById({ id: moduleId }, callback)
  }
}

export const getCurrentStage = approvalData => {
  return (
    approvalData.stages.find(
      stage => stage.status === 'rejected' || stage.status === 'pending'
    ) || approvalData.stages[approvalData.stages.length - 1]
  )
}

export const getApprovalStatus = approvalData => {
  const currentStage = getCurrentStage(approvalData)
  let approvalStatus = currentStage.status

  // If current stage is approved that doesn't mean all stages are approved
  if (approvalStatus === 'approved') {
    let areAllStagesApproved = approvalData.stages.every(
      item => item.status === 'approved'
    )
    if (!areAllStagesApproved) approvalStatus = 'pending'
  }

  return approvalStatus
}
