import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import styles from './Tooltip.module.css'

// As of now supported placement values => top, bottom
const Tooltip = ({ children, title, placement = 'top', offset, className }) => {
  const ref = useRef()
  const [rendered, setRendered] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [displayTooltip, setDisplayTooltip] = useState(false)

  useEffect(() => {
    if (!rendered && ref.current) {
      handleSetPosition(ref.current.firstElementChild)
      setRendered(true)
    }
  }, [rendered])

  const handleSetPosition = elm => {
    const rect = elm.getBoundingClientRect()
    const offsetSpace = offset ? offset : placement === 'bottom' ? 5 : 30
    const top =
      placement === 'bottom'
        ? Math.round(rect.top + rect.height + offsetSpace) //5 is offset
        : Math.round(rect.top - offsetSpace) //30 is offset

    setPosition({ left: Math.round(rect.left + rect.width / 2), top })
  }

  const handleShowTooltip = e => {
    handleSetPosition(e.target)
    setDisplayTooltip(true)

    if (children.props.onMouseEnter) children.props.onMouseEnter(e)
  }

  const handleHideTooltip = e => {
    setDisplayTooltip(false)

    if (children.props.onMouseLeave) children.props.onMouseLeave(e)
  }

  return (
    <>
      {rendered ? (
        React.cloneElement(children, {
          onMouseEnter: handleShowTooltip,
          onMouseLeave: handleHideTooltip
        })
      ) : (
        <div ref={ref}>
          {React.cloneElement(children, {
            onMouseEnter: handleShowTooltip,
            onMouseLeave: handleHideTooltip
          })}
        </div>
      )}

      {displayTooltip && (
        <span
          className={clsx(
            'text-tiny bg-gray-900 text-white fixed py-1 px-2 rounded',
            styles.customTooltip,
            placement === 'top'
              ? styles.customTooltip__top
              : styles.customTooltip__bottom,
            className
          )}
          style={{ top: position.top, left: position.left }}
        >
          {title}
        </span>
      )}
    </>
  )
}

export default Tooltip
