import React from 'react'
import { ColoredAvatars } from 'global/globalComponents'
import { ReactComponent as BotIcon } from 'static/svg/bot.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import WorkspaceNotificationMessage from './WorkspaceNotificationMessage'
import ClientAndTeammateNotificationMessage from './ClientAndTeammateNotificationMessage'
import ApprovalNotificationMessage from './ApprovalNotificationMessage'
import TaskNotificationMessage from './TaskNotificationMessage'
import { Skeleton } from '@material-ui/lab'
import InvoiceNotificationMessage from './InvoiceNotificationMessage'
import ProposalNotificationMessage from './ProposalNotificationMessage'
import { useTranslation } from 'react-i18next'
import userRoles, { getUserPath } from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSingleTaskById } from 'thunks/task/actions'
import ServiceNotificationMessage from './ServiceNotificationMessage'
import clsx from 'clsx'
import { markSingleNotificationAsRead } from 'thunks/accountLogs/action'

const NotificationsList = ({ loading, notifications, selectedCategory }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const dispatch = useDispatch()

  return (
    <div className="rounded-b-md">
      {loading
        ? new Array(3).fill('').map((item, index) => (
            <div
              key={index}
              className={clsx('px-4 pb-4', index === 0 && 'pt-4')}
            >
              <Skeleton variant="rect" height={40} className="rounded" />
            </div>
          ))
        : notifications.map(notif => (
            <SingleNotification
              history={history}
              key={notif._id}
              notificationData={notif}
              meRole={meData.role}
              meId={meData._id}
              dispatch={dispatch}
              selectedCategory={selectedCategory}
            />
          ))}

      {!loading && notifications.length === 0 && (
        <p className="text-sm text-center py-4 dark:text-dark-light2">
          {t('NO_NEW_NOTIFICATIONS_YET')}
        </p>
      )}
    </div>
  )
}

const SingleNotification = ({
  notificationData,
  history,
  meRole,
  meId,
  dispatch,
  selectedCategory
}) => {
  const { user, category } = notificationData

  const handleRedirect = () => {
    const userPath = getUserPath(meRole)

    //Note: this selectedCategory is different then the notif category
    // for example selectedCategory workspace covers "workspace", "workspaceView" and "workspaceSection"
    dispatch(
      markSingleNotificationAsRead({
        id: notificationData._id,
        category: selectedCategory,
        meId
      })
    )

    if (['workspace', 'workspaceView'].includes(category)) {
      if (notificationData.field === 'delete') {
        history.push(`${userPath}/workspace/all`)
      } else {
        history.push(
          `${userPath}/workspace/${notificationData.workspace._id}/board/${notificationData.workspace.defaultBoard}?view=board`
        )
      }
      return
    }

    if (category === 'workspaceSection') {
      history.push(
        `${userPath}/workspace/${notificationData.workspace._id}/board/${notificationData.workspace.defaultBoard}?view=board`
      )
      return
    }

    if (category === 'task') {
      fetchSingleTaskById(
        { taskId: notificationData.task?._id },
        (res, err) => {
          if (!err) {
            history.push(
              `${userPath}/workspace/${res.workSpace}/board/${res.workspaceBoard}/task/${res._id}?view=board`
            )
          }
        }
      )
      return
    }

    if (category === 'service') {
      if (meRole >= userRoles.USER_CLIENT) {
        history.push(`/client/services`)
      } else {
        history.push(`/agency/payments/services/all`)
      }
      return
    }

    if (category === 'invoice') {
      history.push(
        `${userPath}/payments/client-billing/${
          notificationData.invoice?._id
            ? `i/${notificationData.invoice?._id}`
            : 'all'
        }`
      )
      return
    }

    if (category === 'client') {
      if (notificationData.field === 'delete' || !notificationData.after?._id) {
        history.push(`${userPath}/clients/all`)
      } else {
        history.push(
          `${userPath}/clients/all/${
            notificationData.after?._id
              ? `/profile/${notificationData.after._id}`
              : ''
          }`
        )
      }
      return
    }

    if (category === 'agencyTeammate' || category === 'clientTeammate') {
      if (!notificationData.after?._id || notificationData.field === 'delete') {
        history.push(`${userPath}/teammates/all`)
      } else {
        history.push(
          `${userPath}/teammates/all/profile/${notificationData.after._id}`
        )
      }
      return
    }

    if (category === 'proposal') {
      window.open(
        `/public/agency/proposals/${notificationData.proposal._id}`,
        '_blank'
      )
    }

    if (category === 'approval') {
      history.push(`${userPath}/approvals`)
    }
  }

  return (
    <div
      onClick={handleRedirect}
      className={clsx(
        'px-4 py-3 border-b dark:border-black flex items-start cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-main3 relative',
        !notificationData.readBy?.includes(meId) &&
          'bg-primary-light dark:bg-dark-main1'
      )}
    >
      <div className="mr-2">
        {notificationData.isBot ? (
          <BotIcon className="w-6 h-6" />
        ) : user ? (
          <div className="flex items-center">
            <ColoredAvatars size="medium" user={user} />
            <div className="text-smaller font-semibold capitalize ml-2 whitespace-nowrap">
              {user.name}
            </div>
          </div>
        ) : (
          <BotIcon className="w-6 h-6" />
        )}
      </div>

      <div className="mr-auto" style={{ lineHeight: 1.8 }}>
        {['workspace', 'workspaceSection', 'workspaceView'].includes(
          category
        ) ? (
          <WorkspaceNotificationMessage
            {...notificationData}
            source={notificationData.workspace}
          />
        ) : category === 'task' || category === 'subTask' ? (
          <TaskNotificationMessage
            {...notificationData}
            source={notificationData.task}
          />
        ) : ['client', 'agencyTeammate', 'clientTeammate'].includes(
            category
          ) ? (
          <ClientAndTeammateNotificationMessage {...notificationData} />
        ) : category === 'approval' ? (
          <ApprovalNotificationMessage
            {...notificationData}
            source={notificationData.approval}
          />
        ) : category === 'invoice' ? (
          <InvoiceNotificationMessage
            {...notificationData}
            source={notificationData.invoice}
          />
        ) : category === 'proposal' ? (
          <ProposalNotificationMessage
            {...notificationData}
            source={notificationData.proposal}
          />
        ) : category === 'service' ? (
          <ServiceNotificationMessage
            {...notificationData}
            source={notificationData.service}
          />
        ) : null}
      </div>

      <div className="ml-4">
        <span className="ml-auto text-xs text-gray-500 dark:text-white">
          {formatDate(notificationData.createdAt)}
        </span>
      </div>
    </div>
  )
}

const formatDate = dateString => {
  // Parse the date string
  const date = new Date(dateString)

  // Get today's date
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  // Get yesterday's date
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  // Check if it's today
  if (date.toDateString() === today.toDateString()) {
    return `Today at ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })}`
  }
  // Check if it's yesterday
  else if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday at ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })}`
  }
  // Otherwise, return the date in the format <month> <date> at <time>
  else {
    const month = date.toLocaleString('default', { month: 'long' })
    const day = date.getDate()
    const time = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })
    return `${month} ${day} at ${time}`
  }
}

export default NotificationsList
