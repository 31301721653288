import React, { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import clsx from 'clsx'
import { useChange } from 'customHooks'
import { fileToDataURL } from 'utils'
import { updateServiceCategory } from 'thunks/service/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import DeleteIcon from '@material-ui/icons/Delete'
import userServiceStyles from './userServiceStyles'
import { useTranslation } from 'react-i18next'
import categoryImage from 'static/Images/category.jpg'

const CategoryCard = ({
  data,
  handleUpdateServiceCategory,
  openCategoryAlert
}) => {
  const { t } = useTranslation()
  const [edit, setEdit] = useState(false)
  const classes = userServiceStyles()
  const [title, handleTitleChange] = useChange(data.name)
  const [desc, handleDescChange] = useChange(data.description)
  const [image, setImage] = useState(data.image)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleEdit = () => {
    setEdit(true)
  }

  const handleSave = () => {
    const update = {}
    if (title !== data.name) update.name = title
    if (data.description !== desc) update.description = desc
    if (data.image !== image) update.image = image

    if (Object.keys(update).length) {
      setLoading(true)
      dispatch(
        updateServiceCategory(
          { id: data._id, data: update },
          updateServiceCategoryCallback
        )
      )
    } else {
      setEdit(false)
    }
  }

  const updateServiceCategoryCallback = (res, err) => {
    setLoading(false)
    setEdit(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      handleUpdateServiceCategory(res)
    }
  }

  const handleImgChange = e => {
    const file = e.target.files[0]

    if (file) {
      fileToDataURL(file).then(res => setImage(res))
    }
  }

  const handleDeleteCategory = () => {
    openCategoryAlert(data)
  }

  return (
    <div
      className={clsx(
        classes.card,
        'bg-gray-50 border border-gray-300 dark:bg-dark-main rounded-md dark:border-black'
      )}
    >
      <div className="h-40 relative">
        {!edit && (
          <button
            onClick={handleDeleteCategory}
            className={
              'absolute top-0 right-0 bg-red-400 text-white text-sm p-1 opacity-0 transition delete-btn'
            }
          >
            <DeleteIcon />
          </button>
        )}
        <img
          src={image || categoryImage}
          alt=""
          className="w-full h-full object-cover"
        />
        {edit && (
          <div
            className={clsx(
              'absolute w-full h-full opacity-0 inset-0 flex justify-center items-center transition',
              classes.cardImageOverlay
            )}
            style={{ background: 'rgba(0, 0, 0, 0.25)' }}
          >
            <label
              htmlFor="singleCategoryImg2"
              className="border border-white rounded text-white text-xs inline-block p-2 cursor-pointer"
            >
              Choose Image
            </label>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="singleCategoryImg2"
              multiple={false}
              type="file"
              onChange={handleImgChange}
            />
          </div>
        )}
      </div>
      <div className="p-4">
        {edit ? (
          <>
            <TextField
              placeholder={t('TITLE')}
              value={title}
              onChange={handleTitleChange}
              style={{ marginBottom: 6 }}
              classes={{ root: 'w-full' }}
            />
            <TextField
              placeholder={t('DESCRIPTION')}
              value={desc}
              classes={{ root: 'w-full' }}
              onChange={handleDescChange}
              multiline={true}
              rows={2}
            />
          </>
        ) : (
          <>
            <h4 className="font-heading font-medium dark:text-dark-light">
              {title}
            </h4>
            <p className="text-sm text-gray-500 dark:text-dark-light">{desc}</p>
          </>
        )}
        {edit ? (
          <LoadingButton
            loading={loading}
            onClick={handleSave}
            className={clsx('w-full', classes.wideButton)}
            size="small"
          >
            {t('SAVE')}
          </LoadingButton>
        ) : (
          <Button
            onClick={handleEdit}
            className={clsx('w-full', classes.wideButton)}
            size="small"
          >
            {t('EDIT')}
          </Button>
        )}
        {/* <Button
          onClick={handleDeleteCategory}
          className={clsx('w-full', classes.wideButton)}
          size="small"
          color="secondary"
        >
          Delete
        </Button> */}
        {/* <button
          className={`w-full mt-4 text-white p-2 text-sm font-medium rounded uppercase bg-primary-main tracking-wider ${classes.button}`}
        >
          {edit ? 'Save' : 'Edit'}
        </button> */}
      </div>
    </div>
  )
}

// const useStyles = makeStyles({
//   card: {
//     '&:hover .delete-btn': {
//       opacity: 1
//     }
//   },
//   cardImageOverlay: {
//     '&:hover': {
//       opacity: 1
//     }
//   },
//   wideButton: {
//     marginTop: 8,
//     textTransform: 'uppercase'
//   }
// })

export default CategoryCard
