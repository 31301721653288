import { Create } from '@material-ui/icons'
import React from 'react'

const PersonAvatar = (props) => {
  const {
    img = 'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png',
    ...restPorps
  } = props

  return (
    <div
      className="flex flex-row shadow-md rounded-full w-16 h-16 relative cursor-pointer"
      {...restPorps}
    >
      <img
        src={
          img.includes('https://s3')
            ? `${img}?${Date.now()}`?.replace('s3.wasabisys.com/', '')
            : img
        }
        alt=""
        width="64px"
        height="64px"
        className="object-cover rounded-full text-xs"
      />
      <span className="absolute bottom-0 right-0 rounded-full p-1 bg-primary-main w-6 h-6 flex items-center justify-center border-2 border-white">
        <Create
          style={{
            color: 'white',
            fontSize: 14
          }}
        />
      </span>
    </div>
  )
}

export default React.memo(PersonAvatar)
