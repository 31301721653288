export const fileToDataURL = file => {
  const reader = new FileReader()

  return new Promise(function (resolve, reject) {
    reader.onload = function (event) {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export const dataUrlToFile = async (dataUrl, fileName, mimeType) => {
  const res = await fetch(dataUrl)
  const blob = await res.blob()
  return new File([blob], fileName, { type: mimeType })
}

export const getFileUrl = file => {
  return (
    file.url || `${process.env.REACT_APP_CONNECTION_URL}/attachment/${file._id}`
  )
}
