import axios from 'axios'
import { catchAsync, catchAsyncDispatch, getQueryString } from 'utils'
import { APPROVAL } from './reducer'

/**
 * Fetch approval
 * @param {Object} data.query
 * @param {*} callback
 * @returns
 */
export const fetchApprovals = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/approval${getQueryString(data.query)}`
  })

  // filtering out old data
  const filteredData = res.data.filter(item => !item.requestTo)

  if (callback) callback(filteredData)
})

// /**
//  * Fetch all the received approvals
//  * @param {Object} data.query
//  * @param {*} callback
//  * @returns
//  */
// export const fetchReceivedApprovals = (data, callback) => {
//   return catchAsyncDispatch(async dispatch => {
//     const res = await axios({
//       method: 'GET',
//       url: `/approval${getQueryString(data.query)}`
//     })

//     dispatch({ type: APPROVAL.FETCHED_RECEIVED_APPROVALS, payload: res.data })
//     if (callback) callback(res.data)
//   }, callback)
// }

/**
 * Create approval
 * @param {Object} data
 * @param {*} callback
 * @returns
 */
export const createApproval = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/approval',
      data: data.body
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * fetch all task approvals
 * @param {Object} data
 * @param {String} data.taskId
 * @param {String} data.type (task, subtask)
 * @param {Function} callback callback function
 */
export const fetchTaskApprovals = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/approval/${data.type}/${data.taskId}`
  })
  if (callback) callback(res.data)
})

/**
 * Create task approval
 * @param {Object} data.data task, type, category, status, approvalBy, requestedTo, approvedBy, notes dueDate, taskSection, workspace
 * @param {Function} callback callback function
 */
export const createTaskApproval = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/approval',
    data: data.data
  })
  if (callback) callback(res.data)
})

/**
 * Update approval
 * @param {String} id approval id
 * @param {Object} data
 * @param {Function} callback callback function
 */
export const updateTaskApproval = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/approval/${data.approvalId}`,
    data: data.data
  })
  if (callback) callback(res.data)
})

/**
 * Delete approval
 * @param {String} data.approvalId approval id
 * @param {Function} callback callback function
 */
export const deleteApprovalById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/approval/${data.approvalId}`
  })
  if (callback) callback(res.data)
})

/**
 * Update approval status
 * @param {String} data.approvalId approval id
 * @param {Function} callback callback function
 */
export const updateApprovalStatus = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/approval/${data.approvalId}/${data.stageId}`,
      data: data.body
    })

    dispatch({ type: APPROVAL.UPDATE_RECEIVED_APPROVAL, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

export const removeRaisedApproval = approvalId => ({
  type: APPROVAL.REMOVE_RAISED_APPROVAL,
  payload: approvalId
})
