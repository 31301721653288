/**
 * Get query string from object
 * @param {Object} query
 */
const getQueryString = query => {
  let queryStr = '?'
  for (let key in query) {
    queryStr += `${key}=${query[key]}&`
  }
  return queryStr.slice(0, -1)
}

export default getQueryString
