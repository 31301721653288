import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { Header, Sidebar } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import { userRoles } from 'utils'
import { LOGIN } from 'thunks/login/reduers'
import useTitle from 'customHooks/useTitle'
import WorkspacesPusherWrapper from 'components/Pusher/WorkspacesPusherWrapper'
import { fetchPermissions } from 'thunks/permission/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { facebookPixelConnect } from 'utils'
import { fetchAllIntegrationsForCurrentUser } from 'thunks/integration/actions'
import ErrorBoundary from 'global/globalComponents/ErrorBoundary/ErrorBoundary'
import { NotificationsPusherWrapper } from 'components/_shared'
import { useLocation } from 'react-router'
import {
  fetchAllWorkspacesForCurrentUser,
  fetchProjectCategories
} from 'thunks/workspace/actions'
import { postUserActivityLogs } from 'thunks/pulse/action'
import PulsePusherWrapper from 'components/Pusher/PulsePusherWrapper'
import { fetchAllAgencyInvoices } from 'thunks/agencyPayment/actions'
import { useTranslation } from 'react-i18next'
import { fetchOpenTimeTracking } from 'thunks/Tracking/action'
import DashboardPanelAndModals from './DashboardPanelAndModals'
import NewReleasePopupCard from 'components/NewReleasePopupCard/NewReleasePopupCard'
import { fetchProjectPageView } from 'thunks/views/actions'
import { fetchRootFolder } from 'thunks/fileAssets/actions'
import { getClient } from 'thunks/addClient/actions'
import UserFeedbackPopup from 'components/UserFeedbackPopup/UserFeedbackPopup'
import { fetchClientEmbedsById } from 'thunks/embed/action'
import { fetchRequestFormTemplates } from 'thunks/clientRequests/actions'
import { ClientWelcomeModal } from 'components/_client'
import { AgencyWelcomeModal } from 'components/_agency'
import ChatModal from 'components/_shared/Chat/ChatModal'
import ChatWidget from 'components/_shared/Chat/ChatWidget'

const Dashboard = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const me = useSelector(state => state.me)
  const userIntegrations = useSelector(state => state.userIntegrations)
  const [timerStarted, setTimerStarted] = useState(false)
  const workspacesFetched = useSelector(
    state => state.userWorkspaces.fetchedFirstTime
  )
  const { sidebarOpen, chatModalState, displayChatWidget } = useSelector(
    state => state.generalAppState
  )
  const { search } = useLocation()
  const newAgency = useMemo(() => {
    const query = new URLSearchParams(search)
    return query.get('newAgency')
  }, [search])

  useTitle(
    me.data.profile?.brand?.brandName ??
      me.data.team?.profile?.brand?.brandName,
    me.data.profile?.brand?.favicon ?? me.data.team?.profile?.brand?.favicon
  )

  useEffect(() => {
    if (newAgency && window.dataLayer) {
      window.dataLayer.push({
        event: 'signup'
      })
    }
  }, [newAgency])

  useEffect(() => {
    dispatch(fetchRootFolder())
  }, [me._id])

  useEffect(() => {
    if (me.fetched) {
      dispatch(
        fetchPermissions((res, err) => {
          if (err) {
            dispatch(fireErrorToaster(t('ERROR_OCCURED_DURING_LOGIN')))
            dispatch({ type: LOGIN.LOGOUT })
          }
        })
      )
      dispatch(fetchTeammateOfCurrentUser())

      if (me.data.role < userRoles.USER_CLIENT) {
        dispatch(getClient())
      }

      if (me.data.role >= userRoles.USER_CLIENT) {
        dispatch(fetchClientEmbedsById({ id: me.data._id }))
      }
      // dispatch(fetchWorkspacePermissions())

      if (!workspacesFetched) {
        dispatch(fetchAllWorkspacesForCurrentUser())
      }
    }
  }, [me.fetched])

  useEffect(() => {
    dispatch(fetchProjectCategories())
    dispatch(fetchProjectPageView())
    dispatch(fetchRequestFormTemplates())
    // dispatch(fetchProjectPermissions())
  }, [])

  useEffect(() => {
    if (
      !userIntegrations?.fetchedFirstTime &&
      me.data.role <= userRoles.USER_CLIENT
    ) {
      dispatch(fetchAllIntegrationsForCurrentUser())
    }
  }, [userIntegrations?.fetchedFirstTime, me.data.role])

  useEffect(() => {
    if (userIntegrations.data.length) {
      let app = userIntegrations.data?.find(
        el => el?.appName === 'facebook pixel'
      )
      facebookPixelConnect(app?.key)
    }
  }, [userIntegrations.data])

  useEffect(() => {
    if (
      [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(me.data.role) &&
      (me.data.payment?.status ?? me.data.team?.payment?.status) !== 'paid'
    )
      dispatch(fetchAllAgencyInvoices())
  }, [me.data])

  useEffect(() => {
    // if(document.hidden) return current tab is in view/focus
    if (timerStarted) return
    const timer = setInterval(() => {
      setTimerStarted(true)
      postUserActivityLogs({ data: { source: window.location.href } })
    }, 15 * 60 * 1000)
    return () => {
      setTimerStarted(false)
      clearInterval(timer)
    }
  }, [timerStarted])

  useEffect(() => {
    postUserActivityLogs({ data: { source: window.location.href } })
    dispatch(fetchOpenTimeTracking())
  }, [])

  return (
    <>
      <Suspense fallback="Loading...">
        <ErrorBoundary>
          <NotificationsPusherWrapper>
            <PulsePusherWrapper>
              <WorkspacesPusherWrapper>
                <div className="flex h-screen" id="appDashboard">
                  <Sidebar isDrawerOpen={sidebarOpen} />
                  <div className="flex-1 flex flex-col overflow-auto">
                    <Header />
                    <div className="flex-1 overflow-auto">
                      {children}
                      <DashboardPanelAndModals />
                    </div>
                  </div>
                </div>
              </WorkspacesPusherWrapper>
            </PulsePusherWrapper>
          </NotificationsPusherWrapper>
        </ErrorBoundary>
      </Suspense>
      <ClientWelcomeModal />
      <NewReleasePopupCard />
      {me.data.role === userRoles.USER_AGENCY && newAgency && (
        <AgencyWelcomeModal />
      )}

      {(me.data.role === userRoles.USER_AGENCY ||
        me.data.role === userRoles.AGENCY_ADMIN) && <UserFeedbackPopup />}

      {chatModalState.open && <ChatModal />}
      {displayChatWidget && <ChatWidget />}
    </>
  )
}

export default Dashboard
