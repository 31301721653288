import React, { useState } from 'react'
import { ColoredAvatars, Pagination } from 'global/globalComponents'
import {
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTranslation } from 'react-i18next'
import ApprovalActionModal from './ApprovalActionModal/ApprovalActionModal'
import { useModal } from 'customHooks'
import { useSelector } from 'react-redux'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import { getApprovalStatus, getCurrentStage } from './approvalStatic'

const limit = 10

const ReceivedApprovalsTable = () => {
  const { t } = useTranslation()
  const { loading, data: receivedApprovals } = useSelector(
    state => state.approvals.received
  )
  const { modal, openModal, closeModal } = useModal()
  const [page, setPage] = useState(0)

  const visibleApprovals = receivedApprovals.slice(
    limit * page,
    limit * page + limit
  )

  return (
    <>
      <TableContainer mb={6}>
        <Thead>
          <Th className="whitespace-nowrap">{t('NAME')}</Th>
          <Th className="text-center whitespace-nowrap">{t('STATUS')}</Th>
          <Th className="text-center whitespace-nowrap">{t('REQUESTED_BY')}</Th>
          <Th className="text-center whitespace-nowrap">
            {t('CURRENT_STAGE')}
          </Th>
          <Th className="text-center whitespace-nowrap">{t('SUBMITTED_AT')}</Th>
          <Th className="text-center whitespace-nowrap">{t('DUE_DATE')}</Th>
        </Thead>
        <Tbody>
          {loading ? (
            <TableDataLoading cols={6} />
          ) : visibleApprovals.length === 0 ? (
            <Tr>
              <Td colSpan="6">{t('NO_DATA_FOUND')}</Td>
            </Tr>
          ) : (
            visibleApprovals.map(approval => (
              <Row
                key={approval._id}
                approvalData={approval}
                handleViewRequest={() => openModal(approval)}
              />
            ))
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={receivedApprovals.length}
        page={page}
        limit={limit}
      />
      <ApprovalActionModal
        open={modal.open}
        onClose={closeModal}
        data={receivedApprovals.find(item => item._id === modal.data._id)}
      />
    </>
  )
}

const Row = ({ approvalData, handleViewRequest }) => {
  const currentStage = getCurrentStage(approvalData)
  const approvalStatus = getApprovalStatus(approvalData)
  const dueDate = approvalData.dueDate || currentStage.dueDate

  return (
    <>
      <Tr>
        <Td onClick={handleViewRequest} className="cursor-pointer">
          {approvalData.name}
        </Td>
        <Td center className="leading-3 capitalize">
          <Pill variant={pillVariantObj[currentStage.status]}>
            {approvalStatus}
          </Pill>
        </Td>
        <Td center>
          <ColoredAvatars
            user={approvalData.user || { name: 'Deleted User' }}
          />
        </Td>
        <Td center>{currentStage.name}</Td>
        <Td className="text-center">
          {new Date(approvalData.createdAt).toLocaleDateString(undefined, {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}
        </Td>
        <Td className="text-center">
          {dueDate &&
            new Date(dueDate).toLocaleDateString(undefined, {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
        </Td>
      </Tr>
    </>
  )
}

export default ReceivedApprovalsTable
