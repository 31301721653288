import SidebarCustomTooltip from 'components/Sidebar/SidebarCustomTooltip'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as ChatIcon } from 'static/svg/chat.svg'
import { toggleChatModal } from 'thunks/generlAppState/actions'

const ChatWidget = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDisplayChatWidget = () => {
    dispatch(toggleChatModal(true))
  }

  return (
    <SidebarCustomTooltip title={t('CV_CHAT_NAME')} placement="top">
      <div
        style={{
          position: 'fixed',
          zIndex: 9999,
          bottom: 28,
          right: 28,
          width: 60,
          height: 60,
          boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.15)'
        }}
        className="bg-primary-main text-white rounded-full flex justify-center items-center shadow cursor-pointer"
        onClick={handleDisplayChatWidget}
      >
        <ChatIcon className="w-7 h-7" />
      </div>
    </SidebarCustomTooltip>
  )
}

export default ChatWidget
