import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ApprovalStageTab from '../ApprovalStageTab'
import {
  ColoredAvatarGroup,
  ColoredAvatars,
  CustomFlag
} from 'global/globalComponents'
import { ReactComponent as CheckIcon } from 'static/svg/check.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import clsx from 'clsx'
import { updateApprovalStatus } from 'thunks/approvals/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { fetchSingleTaskById } from 'thunks/task/actions'
import { getApprovalItemData, getCurrentStage } from '../approvalStatic'
import UserActionArea from './UserActionArea'
import ApprovalCommentsArea from './ApprovalCommentsArea'
import { useTranslation } from 'react-i18next'

const ApprovalDetailsCard = ({ approvalData }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const [currentStage, setCurrentStage] = useState(
    getCurrentStage(approvalData)
  )
  const [activeTab, setActiveTab] = useState(
    approvalData.stages.findIndex(stage => stage._id === currentStage._id)
  )
  const [approvalItem, setApprovalItem] = useState({ loading: true, data: {} })

  useEffect(() => {
    getApprovalItemData({
      dispatch,
      module: approvalData.module,
      moduleId: approvalData.moduleId,
      callback: (res, err) => {
        setApprovalItem({
          loading: false,
          data: err
            ? {}
            : {
                name:
                  approvalData.module === 'task' ||
                  approvalData.module === 'subTask'
                    ? res.title
                    : approvalData.module === 'invoice'
                    ? `Invoice ${res.invoiceNumber}`
                    : approvalData.module === 'file'
                    ? `${res.name} ${res.extension}`
                    : res.name
              }
        })
      }
    })
  }, [])

  useEffect(() => {
    if (approvalData.module === 'task') {
      fetchSingleTaskById({ taskId: approvalData.moduleId }, (res, err) => {
        setApprovalItem({
          loading: false,
          data: err ? {} : { name: res.title }
        })
      })
    }
  }, [])

  const handleSaveUserResponse = (status, callback) => {
    dispatch(
      updateApprovalStatus(
        {
          approvalId: approvalData._id,
          stageId: currentStage._id,
          body: { status }
        },
        (res, err) => {
          if (callback) callback(res, err)
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            const updatedStage = res.stages.find(
              stage => stage._id === currentStage._id
            )
            setCurrentStage(updatedStage)
          }
        }
      )
    )
  }

  const handleSwitchTab = index => {
    setCurrentStage(approvalData.stages[index])
    setActiveTab(index)
  }

  const previousStage = approvalData.stages[activeTab - 1]
    ? { ...approvalData.stages[activeTab - 1] }
    : null

  const myResponse = currentStage.members.find(
    item => item.user?._id === meData._id
  )?.status

  const dueDate = currentStage.dueDate || approvalData.dueDate
  const approvedBy = currentStage.members
    .filter(item => item.status === 'approved')
    .map(item => (item.user?._id ? item.user : { name: 'Deleted user' }))
  const rejectedBy = currentStage.members
    .filter(item => item.status === 'rejected')
    .map(item => (item.user?._id ? item.user : { name: 'Deleted user' }))

  return (
    <>
      <div className="flex items-center mb-4">
        {approvalData.stages.map((stage, index) => (
          <ApprovalStageTab
            key={stage._id}
            label={stage.name}
            isActive={currentStage._id === stage._id}
            onClick={() => handleSwitchTab(index)}
            status={stage.status}
          />
        ))}
      </div>

      <div
        className={clsx(
          'border rounded p-4 mb-6 dark:bg-dark-main2',
          currentStage.status === 'rejected' &&
            'bg-red-50 dark:bg-dark-main2 border-red-500',
          currentStage.status === 'approved' &&
            'bg-green-50 dark:bg-dark-main2 border-green-500'
        )}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-sm font-semibold dark:text-dark-light">
            {t('APPROVAL_DETAILS')}
          </h3>

          {currentStage.status === 'rejected' ? (
            <div className="text-red-500 flex items-center text-smaller font-medium">
              <CrossIcon className="w-4 h-4 mr-1" />
              <span>{t('REJECTED')}</span>
            </div>
          ) : currentStage.status === 'approved' ? (
            <div className="text-green-500 flex items-center text-smaller font-medium">
              <CheckIcon className="w-4 h-4 mr-1" />
              <span>{t('APPROVED')}</span>
            </div>
          ) : null}
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <FieldWrapper
            label="Requested By"
            value={
              <ColoredAvatars
                user={
                  approvalData.user?._id
                    ? approvalData.user
                    : { name: 'Deleted user' }
                }
              />
            }
          />
          <FieldWrapper
            label={t('APPROVAL_ITEM')}
            value={
              approvalItem.loading
                ? 'Loading...'
                : approvalItem.data.name || '-'
            }
          />
          {/* <FieldWrapper label="Title" value={approvalData.name} />
          <FieldWrapper label="Description" value={approvalData.description} /> */}
          <FieldWrapper
            label={t('DUE_DATE')}
            value={
              dueDate
                ? new Date(dueDate).toLocaleDateString(undefined, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                  })
                : '-'
            }
          />
          <FieldWrapper
            label={t('PRIORITY')}
            value={
              approvalData.priority && approvalData.priority !== 5 ? (
                <CustomFlag priority={approvalData.priority} />
              ) : (
                '-'
              )
            }
          />

          <FieldWrapper
            label={t('REQUESTED_TO')}
            value={
              <ColoredAvatarGroup
                users={currentStage.members.map(item =>
                  item.user?._id ? item.user : { name: 'Deleted user' }
                )}
                tooltip={true}
              />
            }
          />

          <FieldWrapper
            label={t('APPROVED_BY')}
            value={
              approvedBy.length > 0 ? (
                <ColoredAvatarGroup users={approvedBy} tooltip={true} />
              ) : (
                '-'
              )
            }
          />

          <FieldWrapper
            label={t('REJECTED_BY')}
            value={
              rejectedBy.length > 0 ? (
                <ColoredAvatarGroup users={rejectedBy} tooltip={true} />
              ) : (
                '-'
              )
            }
          />
        </div>

        {myResponse && (
          <UserActionArea
            myResponse={myResponse}
            previousStage={previousStage}
            handleSaveUserResponse={handleSaveUserResponse}
          />
        )}
      </div>

      <ApprovalCommentsArea
        approvalId={approvalData._id}
        approvalDescription={approvalData.description}
        currentStage={currentStage}
      />
    </>
  )
}

const FieldWrapper = ({ label, value }) => {
  return (
    <div className="flex items-start mb-2">
      <div className="w-28 flex-shrink-0 text-smaller font-semibold">
        {label}:{' '}
      </div>
      <div className="text-smaller">{value}</div>
    </div>
  )
}

export default ApprovalDetailsCard
