import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

const AddAttachment = ({ id, children, handleFile, labelProps, disabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )

  const handleClick = e => {
    if (disabled) {
      e.preventDefault()
    }
  }

  const handleUploadImage = async e => {
    if (currentWorkspace.isComplete) {
      dispatch(fireErrorToaster(t('THIS_TASK_BELONG_TO_A_COMPLETE_WORKSPACE')))
    } else {
      let file = e.target.files[0]
      handleFile(file)
      e.target.value = ''
    }
  }

  return (
    <div className="flex flex-row justify-evenly">
      <input
        type="file"
        id={id}
        style={{ display: 'none' }}
        multiple={false}
        accept="*"
        onChange={handleUploadImage}
      />
      <label
        onClick={handleClick}
        role="button"
        component="span"
        htmlFor={id}
        {...labelProps}
      >
        {children}
      </label>
    </div>
  )
}

export default AddAttachment
