import { makeStyles, createStyles } from '@material-ui/core'

const timesheetStyles = makeStyles(theme =>
  createStyles({
    root: {
      overflowY: 'visible'
    },
    btn: {
      '&> svg': {
        color: 'gray',
        // fontSize: '1rem',
        cursor: 'pointer',
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeInOut
        })
      },
      '&.expanded': {
        '&> svg': {
          transform: 'rotate(90deg)',
          transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.leavingScreen
          })
        }
      }
    },
    editTime: {
      '&:hover': {
        backgroundColor: '#d0e2ff'
      },
      // '&:hover .pen': {
      //   opacity: 1
      // },
      '&.active': {
        backgroundColor: '#d0e2ff',
        boxShadow: `inset 0 0 1px ${theme.palette.primary.main}`
      }
    },
    activeCell: {
      backgroundColor: '#d0e2ff'
    }
  })
)

export default timesheetStyles
