import React, { useEffect, useState } from 'react'
import { Tab, Tabs, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  ColoredAvatars,
  CustomTooltip,
  QuillEditorWithToolbar
} from 'global/globalComponents'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteComment,
  findCommentsByApprovalId,
  postTaskLog
} from 'thunks/logs/action'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { getQuillHTML } from 'utils'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as UndoIcon } from 'static/svg/undo.svg'
import clsx from 'clsx'

const ApprovalComments = ({
  approvalId,
  approvalDescription,
  currentStage
}) => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(0)
  const [comments, setComments] = useState({ loading: true, data: [] })

  useEffect(() => {
    findCommentsByApprovalId(
      { id: approvalId, stageNumber: currentStage.stageNumber },
      (res, err) => {
        setComments({ loading: false, data: err ? [] : res })
      }
    )

    return () => {
      setComments({ loading: true, data: [] })
    }
  }, [approvalId, currentStage.stageNumber])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  return (
    <div>
      <header>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {[t('COMMENTS'), t('DESCRIPTION')].map((item, i) => (
            <Tab label={item} key={item} value={i} />
          ))}
        </Tabs>
      </header>

      <div className="pt-2 px-6 pb-4" style={{ margin: '0 -1.5rem' }}>
        {selectedTab === 0 ? (
          <Comments
            approvalId={approvalId}
            currentStage={currentStage}
            comments={comments}
            setComments={setComments}
          />
        ) : (
          <div className="text-smaller text-gray-500">
            {approvalDescription}
          </div>
        )}
      </div>
    </div>
  )
}

const Comments = ({ approvalId, currentStage, comments, setComments }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const users = currentStage.members.map(item => item.user)

  const handleSubmitComment = comment => {
    const prevComment = comments.data[comments.data.length - 1]
    // const mentions = comment.ops
    //   .filter(item => item.insert.mention)
    //   .map(item => item.insert.mention)

    const commentData = {
      description: comment,
      descriptionString: getQuillHTML(comment, 'html'),
      activityType: 'comment',
      createdAt: new Date(),
      updatedAt: new Date(),
      user: meData._id,
      pComment: prevComment ? prevComment._id : 0,
      category: 'approval',
      isExternal: true,
      approval: approvalId,
      approvalStage: currentStage.stageNumber
    }

    let tempId = Date.now()

    // Adding comment to the list right away
    setComments(prev => ({
      loading: false,
      data: [
        ...prev.data,
        { ...commentData, _id: tempId, isTemp: true, user: meData }
      ]
    }))

    dispatch(
      postTaskLog({ data: commentData }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
          // removing temp comment on error
          setComments(prev => ({
            loading: false,
            data: prev.data.filter(comment => comment._id !== tempId)
          }))
        } else {
          // replacing temp comment with the real one
          setComments(prev => ({
            loading: false,
            data: prev.data.map(comment =>
              comment._id === tempId ? res : comment
            )
          }))
        }
      })
    )
  }

  const handleDeleteComment = commentId => {
    setComments(prev => ({
      loading: false,
      data: prev.data.filter(item => item._id !== commentId)
    }))
    deleteComment({ id: commentId })
  }

  return (
    <div>
      <div className="mb-6">
        <QuillEditorWithToolbar onSubmit={handleSubmitComment} users={users} />
      </div>

      {!comments.loading
        ? comments.data.map((item, index) => (
            <SingleComment
              key={item._id}
              commentData={item}
              meData={meData}
              prevCommentUser={
                index === 0 ? null : comments.data[index - 1].user
              }
              t={t}
              handleDeleteComment={handleDeleteComment}
            />
          ))
        : null}
    </div>
  )
}

const SingleComment = ({
  commentData,
  prevCommentUser,
  t,
  handleDeleteComment,
  meData
}) => {
  const [deleteComment, setDeleteComment] = useState(false)
  const classes = useStyles()
  useEffect(() => {
    let timerId

    if (deleteComment) {
      // delete comment in 5 seconds
      timerId = window.setTimeout(() => {
        handleDeleteComment(commentData._id)
      }, 5000)
    }

    return () => window.clearTimeout(timerId)
  }, [deleteComment, commentData._id])

  // NOTE: if the same user send 2 or msgs in a row the, there is no
  // need to show his/her avatar and other details with every single msg

  return (
    <div
      className={clsx(
        'relative flex justify-center',
        deleteComment && 'opacity-40',
        prevCommentUser === null
          ? ''
          : prevCommentUser._id !== commentData.user._id
          ? 'mt-4'
          : 'mt-2'
      )}
    >
      <div style={{ minWidth: 28 }}>
        {prevCommentUser === null ||
        prevCommentUser._id !== commentData.user._id ? (
          <ColoredAvatars alt="" user={commentData.user} tooltip={true} />
        ) : null}
      </div>
      <div className="min-w-0 flex-1 rounded-lg ml-2">
        {prevCommentUser === null ||
        prevCommentUser._id !== commentData.user._id ? (
          <div className="text-sm flex items-center space-x-2 mb-2">
            <div className="font-semibold text-sm dark:text-dark-light">
              {commentData.user?.name ?? 'Deleted user'}
            </div>
            <div className="text-xs">
              {moment(commentData.createdAt).fromNow()}
            </div>
          </div>
        ) : null}

        <div className="relative show-on-hover-parent">
          {meData._id === commentData.user._id && !commentData.isTemp && (
            <>
              {deleteComment ? (
                <button
                  onClick={() => setDeleteComment(false)}
                  className="absolute top-2 right-2 text-xs text-blue-700 font-medium inline-flex items-center"
                >
                  <UndoIcon />
                  <span className="ml-1">{t('UNDO')}</span>
                </button>
              ) : (
                <button
                  className="absolute top-2 right-2 text-red-500 show-on-hover-child"
                  onClick={() => setDeleteComment(true)}
                >
                  <CustomTooltip title={t('DELETE')} placement="top">
                    <TrashIcon />
                  </CustomTooltip>
                </button>
              )}
            </>
          )}

          <div
            className={clsx(
              classes.commentsContainer,
              'bg-gray-100 dark:bg-dark-main4 p-2 text-smaller rounded text-gray-700 dark:text-dark-light2 break-long-word'
            )}
            dangerouslySetInnerHTML={{
              __html: getQuillHTML(commentData.description, 'html')
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  commentsContainer: {
    '& p': {
      color: theme.palette.type === 'dark' ? '#ccc' : '#000'
    },
    '& a': {
      color: 'blue',
      textDecoration: 'underline'
    },
    '& .emoji': {
      fontSize: 20
    }
  }
}))

export default ApprovalComments
