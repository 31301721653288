import React, { useEffect, useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton, OutlinedButton } from 'global/globalComponents'
import { useTheme } from '@material-ui/core'
import Select, { components } from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useSelector, useDispatch } from 'react-redux'
import { getListViewTasks, getPriorityTasksNew } from 'thunks/task/actions'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { ReactComponent as PlaneIcon } from 'static/svg/plane.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import {
  createStandups,
  fetchTodaysStandups,
  updateStandupById
} from 'thunks/standups/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { triggerOnScrollToBottom, debounce } from 'utils'
import CreateTaskPanel from 'components/CreateTaskPanel/CreateTaskPanel'
import CreateStandupsModalTop from './CreateStandupsModalTop'
import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import { useTranslation } from 'react-i18next'
import StandupsBody from './StandupsBody'
import StandupsFooter from './StandupsFooter'
import { useWorkspacePermission } from 'customHooks'

const infiniteScroll = triggerOnScrollToBottom()
const debounceFn = debounce()

const CreateStandupsModal = ({ onClose, open }) => {
  return (
    <CustomModal open={open} size="extraLarge">
      <CreateStandupsModalContent open={open} onClose={onClose} />
    </CustomModal>
  )
}

const CreateStandupsModalContent = ({ open, onClose }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
    fetchedFirstTime,
    loading: projectsLoading,
    data: allProjects
  } = useSelector(state => state.userWorkspaces)

  const [projectsOptions, setProjectsOptions] = useState([])
  const [taskOptions, setTaskOptions] = useState({
    loading: false,
    data: []
  })
  const [selectedProject, setSelectedProject] = useState({})
  const [selectedTasks, setSelectedTasks] = useState([])
  // const meData = useSelector(state => state.me.data)
  const { data: standupsData } = useSelector(state => state.standups)
  const { data: allUserTasksData, listViewTasks, priorityTasks } = useSelector(
    state => state.userTasks
  )
  const [standupsDataState, setStandupsDataState] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState('morning')
  // const { data: workspacePermission } = useSelector(
  //   state => state.workspacePermission
  // )
  const innerProjectPermission = useWorkspacePermission(selectedProject.value)
  // const [
  //   canCreateTask,
  //   setCanCreateTask
  // ] = useState(false)
  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false)
  const [allTasksData, setAllTasksData] = useState([])
  const [showMyTasks, setShowMyTasks] = useState(false)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [fetchingData, setFetchingData] = useState(true)

  useEffect(() => {
    dispatch(
      fetchTodaysStandups(null, () => {
        setFetchingData(false)
      })
    )
  }, [])

  useEffect(() => {
    if (selectedProject.boardId) {
      setTaskOptions({ data: [], loading: true })
      // setSelectedTasks([])
      handleFetchListViewTasks((res, err) => {
        setTaskOptions(prev => ({ ...prev, loading: false }))
      })
    } else if (selectedProject.value === 'all') {
      handleFetchPriorityTasks((res, err) => {
        setTaskOptions(prev => ({ ...prev, loading: false }))
      })
    }
  }, [selectedProject.value, selectedProject.boardId, showMyTasks, search])

  useEffect(() => {
    const tasks =
      selectedProject.value === 'all' ? priorityTasks.data : listViewTasks.data
    const tasksData = tasks
      .filter(taskId => allUserTasksData[taskId])
      .map(taskId => allUserTasksData[taskId])

    setAllTasksData(tasksData)
  }, [listViewTasks, priorityTasks, allUserTasksData, selectedProject.value])

  useEffect(() => {
    if (standupsData._id) {
      setStandupsDataState([...standupsData.standups])
    }
  }, [standupsData])

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  useEffect(() => {
    if (allProjects.length) {
      const arr = allProjects
        .filter(item => !item.isArchived && !item.isComplete)
        .map(item => ({
          ...item,
          label: item.name,
          value: item._id,
          boardId: item.defaultBoard?._id
        }))

      setProjectsOptions([{ label: 'All', value: 'all' }, ...arr])
    }
  }, [allProjects])

  useEffect(() => {
    if (selectedProject.value) {
      setTaskOptions(prev => ({
        ...prev,
        data: allTasksData
          .filter(item => !item.markAsComplete)
          .map((item, index) => ({
            label: item.title,
            value: item._id,
            index: index
          }))
      }))
    } else {
      setTaskOptions(prev => ({ ...prev, data: [] }))
    }
  }, [allTasksData, standupsData, showMyTasks])

  // useEffect(() => {
  //   if (selectedProject.value === 'all') return

  //   if (selectedProject.value) {
  //     let permissionObj = {}
  //     for (let item of workspacePermission) {
  //       if (typeof item[selectedProject.value] === 'object') {
  //         permissionObj = item[selectedProject.value].access
  //       }
  //     }

  //     setCanCreateTask(permissionObj.task ?? {})
  //   }
  // }, [selectedProject.value, workspacePermission])

  const handleFetchPriorityTasks = (cb, pageCount = 0) => {
    const data = {}

    dispatch(
      getPriorityTasksNew(
        {
          query: {
            limit: 10,
            page: pageCount
          },
          data
        },
        cb
      )
    )
  }

  const handleSelectProject = projectOption => {
    setSelectedProject(projectOption)
  }

  const handleFetchListViewTasks = (cb, pageCount = 0) => {
    dispatch(
      getListViewTasks(
        {
          data: {
            workspace: selectedProject.value,
            workspaceBoard: selectedProject.boardId,
            myTasks: showMyTasks,
            search: search || null
          },
          query: { limit: 20, page: pageCount, type: 'list' }
        },
        cb
      )
    )
  }

  const handleSelectTask = newVal => {
    setSelectedTasks(
      newVal.map(e => ({
        ...e,
        label: e?.label
      }))
    )
  }

  const handleAddStandup = () => {
    const newData = selectedTasks.map(item => {
      const obj = {
        projectName: selectedProject.label,
        projectId: selectedProject.value,
        name: item.label,
        task: item.value,
        isComplete: false,
        type: selectedTab,
        idx: item.index
      }
      return obj
    })

    if (newData.length !== 0) {
      const reqBody = {
        type: selectedTab === 'morning' ? 'morningStandups' : 'eveningStandups',
        standups: newData
      }

      setLoading(true)
      if (!standupsData._id) {
        dispatch(createStandups({ data: reqBody }, standupsCallback))
      } else {
        dispatch(
          updateStandupById(
            { id: standupsData._id, data: reqBody },
            standupsCallback
          )
        )
      }
      setSelectedProject({})
      setSelectedTasks([])
    } else {
      dispatch(fireErrorToaster('Add Tasks'))
    }
  }

  const standupsCallback = (res, err) => {
    setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
    }
  }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const toggleCreateTaskModal = () => {
    if (createTaskModalOpen) {
      setCreateTaskModalOpen(false)
    } else {
      setCreateTaskModalOpen(true)
    }
  }

  const addSelectedTask = ({ taskTitle, taskId, projectId }) => {
    if (selectedProject.value === projectId) {
      setSelectedTasks(prev => [...prev, { label: taskTitle, value: taskId }])
    }
  }

  const loadMoreTasks = e => {
    infiniteScroll(e, {
      cb: activateInfiniteScroll => {
        setTaskOptions(prev => ({ ...prev, loading: true }))

        const fetchTasksCallback = (res, err) => {
          activateInfiniteScroll()
          setTaskOptions(prev => ({ ...prev, loading: false }))
        }

        if (selectedProject.value === 'all') {
          handleFetchPriorityTasks(fetchTasksCallback, page + 1)
        } else {
          handleFetchListViewTasks(fetchTasksCallback, page + 1)
        }
        setPage(prev => prev + 1)
      }
    })
  }

  const handleSearchTasks = (input, eventObj) => {
    if (eventObj.action === 'input-change') {
      debounceFn(() => {
        setSearch(input)
      }, 300)
    }
  }

  const handleMenuClose = () => {
    setSearch('')
    setPage(0)
  }

  return (
    <>
      <CustomModalHeader
        handleClose={onClose}
        heading={t('DAILY_STANDUPS')}
        icon={
          <div className="w-8 h-8 2xl:w-10 2xl:h-10 bg-primary-light rounded flex justify-center items-center text-xl dark:bg-dark-main">
            <PlaneIcon className="text-primary-main dark:text-dark-light" />
          </div>
        }
      />
      <CustomModalBody overflow="visible">
        <CreateStandupsModalTop
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          showMyTasks={showMyTasks}
          setShowMyTasks={setShowMyTasks}
        />

        <div className="pt-6">
          {/* ======= Add Area ====== */}
          <div className="flex space-x-4 mb-8">
            <div className="flex-1">
              <Select
                options={projectsOptions}
                value={selectedProject.value ? selectedProject : ''}
                className="w-full"
                styles={reactSelectCustomStyles(theme, {
                  multiValue: { maxWidth: 270 }
                })}
                onChange={handleSelectProject}
                isLoading={projectsLoading}
                placeholder={t('SELECT_PROJECT')}
              />
            </div>
            <div className="flex-1">
              <Select
                options={taskOptions.data}
                value={selectedTasks.length ? selectedTasks : ''}
                className="w-full"
                styles={reactSelectCustomStyles(theme, {
                  multiValue: { maxWidth: 270 }
                })}
                onChange={handleSelectTask}
                isLoading={taskOptions.loading}
                placeholder={t('SELECT_TASK')}
                isMulti
                components={{ MenuList: CustomMenu }}
                onInputChange={handleSearchTasks}
                onMenuClose={handleMenuClose}
                selectProps={{
                  toggleCreateTaskModal: toggleCreateTaskModal,
                  canAdd:
                    Boolean(selectedProject.value) &&
                    selectedProject.value !== 'all' &&
                    innerProjectPermission.create,
                  showMyTasks,
                  setShowMyTasks,
                  onScroll:
                    (selectedProject.value !== 'all' &&
                      listViewTasks.metadata.total >
                        listViewTasks.data.length) ||
                    (selectedProject.value === 'all' &&
                      priorityTasks.metadata.total > priorityTasks.data.length)
                      ? loadMoreTasks
                      : undefined,
                  isLoading: taskOptions.loading
                }}
              />
            </div>
            <LoadingButton
              variant="outlined"
              onClick={handleAddStandup}
              loading={loading}
            >
              {t('ADD')}
            </LoadingButton>
          </div>

          <StandupsBody
            isLoading={fetchingData}
            selectedTab={selectedTab}
            allUserTasksData={allUserTasksData}
            allProjects={allProjects}
            standupsDataState={standupsDataState}
          />
        </div>
      </CustomModalBody>
      <StandupsFooter onClose={onClose} />
      {createTaskModalOpen && (
        <CreateTaskPanel
          open={createTaskModalOpen}
          onClose={toggleCreateTaskModal}
          addSelectedTask={addSelectedTask}
          project={selectedProject}
        />
      )}
    </>
  )
}

const CustomMenu = props => {
  const toggleShowMyTasks = () => {
    props.selectProps.selectProps.setShowMyTasks(prev => !prev)
  }
  const { t } = useTranslation()
  return (
    <components.MenuList
      {...props}
      innerProps={{
        ...props.innerProps,
        onScroll: props.selectProps.selectProps.onScroll
      }}
    >
      {props.selectProps.selectProps.canAdd && (
        <div className="flex items-center justify-between px-4 py-2">
          <button
            onClick={props.selectProps.selectProps.toggleCreateTaskModal}
            className="text-sm text-primary-main flex items-center px-2 py-1"
          >
            <AddIcon className="mr-1 dark:text-dark-light" />
            <span className="dark:text-dark-light">{t('CREATE_NEW_TASK')}</span>
          </button>
          <OutlinedButton
            isActive={props.selectProps.selectProps.showMyTasks}
            onClick={toggleShowMyTasks}
            hoverColorChange={false}
          >
            <FilterIcon className="dark:text-dark-light" />
            <span className="ml-2 dark:text-dark-light">{t('MY_TASKS')}</span>
          </OutlinedButton>
        </div>
      )}
      {props.children}
      {/* {Array.isArray(props.children) &&
        props.selectProps.selectProps.isLoading && (
          <Loader style={{ transform: 'scale(0.6)' }} />
          // <div className="w-full flex justify-center">
          //   <div>
          //   </div>
          // </div>
        )} */}
    </components.MenuList>
  )
}

export default CreateStandupsModal
