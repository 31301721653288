import { useEffect, useMemo, useState } from 'react'
import { ColoredAvatars, ToggleSwitch } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { updateTeammateStatus, updateTeammate } from 'thunks/teammate/actions'
import { format } from 'date-fns'
import Pill from 'global/globalComponents/Pill/Pill'
// import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import { PopupMenu } from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { userRoles, getRoleBadge } from 'utils'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as Calendar } from 'static/svg/calendar.svg'
import { CustomTooltip } from 'global/globalComponents'
import CompanyDropdown from 'components/_agency/ClientCompanyComponents/CompanyDropdown'
import ProjectsDropdown from './ProjectsDropdown'
import { ReactComponent as AngleIcon } from 'static/svg/angle-down.svg'
import { useTranslation } from 'react-i18next'
import { getErrorMessages } from 'utils/errorMessages'
import { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const totalTeammatesToShow = 8

const TeammatesTable = ({
  loading,
  openEditPanel,
  teammates,
  handleInviteTeammate,
  elmFor,
  canEditAccount,
  openDeleteTeammateAlert
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const errorMessages = getErrorMessages()
  const { t } = useTranslation()
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])
  const access = useSelector(state => state.permission.data.access)
  const meData = useSelector(state => state.me.data)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const teamVerticals = useSelector(state => state.teamVerticals.data)
  // const [deleteTeammateData, setdeleteTeammateData] = useState({
  //   open: false,
  //   data: {},
  //   loading: false
  // })
  const [roleAnchorEl, setRoleAnchorEl] = useState({
    anchorEl: null,
    data: {},
    key: ''
  })
  // ------ Related to the pagination --------
  const [visibleTeammates, setVisibleTeammates] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)

  const activeProjects = useMemo(() => {
    return userWorkspaces.data
      .filter(item => item => !item.isArchived)
      .map(item => ({ ...item, label: item.name }))
  }, [userWorkspaces])

  const verticals = useMemo(() => {
    return teamVerticals.map(item => ({ ...item, label: item.name }))
  }, [teamVerticals])

  const handleViewProfile = userId => {
    const path = getUserPath(meData.role)
    history.push(`${path}/teammates/all/profile/${userId}`)
  }

  useEffect(() => {
    if (teammates) {
      setVisibleTeammates(
        [...teammates].splice(page * totalTeammatesToShow, totalTeammatesToShow)
      )
      setPaginationTotalDataCount(teammates.length)
    }
  }, [teammates, page, paginationTotalDataCount])

  const handleSwitch = teammate => {
    if (access?.teammate.update) {
      if (
        teammate.role === userRoles.AGENCY_ADMIN &&
        meData.role === userRoles.AGENCY_MANAGER
      ) {
        return dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
      } else {
        setToggleSwitchLoading(prevState => [...prevState, teammate._id])
        dispatch(
          updateTeammateStatus(
            { id: teammate._id, data: { isActive: !teammate.isActive } },
            (res, err) => {
              setToggleSwitchLoading(prevState =>
                prevState.filter(item => teammate._id !== item)
              )

              if (err) {
                dispatch(fireErrorToaster(res))
              } else {
                dispatch(
                  fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE)
                )
              }
            }
          )
        )
      }
    } else {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
    }
  }

  // const deleteTeammateCallback = (res, err) => {
  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //     setdeleteTeammateData(prev => ({
  //       ...prev,
  //       loading: false
  //     }))
  //   } else {
  //     setdeleteTeammateData({
  //       open: false,
  //       data: {},
  //       loading: false
  //     })
  //     dispatch(fireSuccessToaster(t('DELETED_SUCCESSFULLY')))
  //   }
  // }

  // const openDeleteTeammateAlert = teammate => {
  //   if (access?.teammate.delete) {
  //     if (deleteTeammateData.open) {
  //       setdeleteTeammateData({
  //         open: false,
  //         data: {},
  //         loading: false
  //       })
  //     } else {
  //       setdeleteTeammateData({
  //         open: true,
  //         data: teammate,
  //         loading: false
  //       })
  //     }
  //   } else dispatch(fireErrorToaster(t('ERROR_NOT_AN_AUTHORIZED_USER')))
  // }

  // const handleTeammateData = () => {
  //   setdeleteTeammateData(prev => ({ ...prev, loading: true }))
  //   dispatch(
  //     deleteTeammate(deleteTeammateData.data._id, deleteTeammateCallback)
  //   )
  // }

  const handleUpdateTeammate = data => {
    let updatedData = {}
    if (roleAnchorEl.key === 'role') {
      updatedData.verticals = [data._id]
    } else {
      updatedData.newWorkspace = [data._id]
    }

    dispatch(
      updateTeammate(
        { id: roleAnchorEl.data._id, body: updatedData },
        (res, err) => {
          setRoleAnchorEl({ anchorEl: null, data: {}, key: '' })
          if (!err) {
            dispatch(fireSuccessToaster(t('SUCCESS_UPDATED')))
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th>{t('NAME')}</Th>
          {meData.role < userRoles.USER_CLIENT && (
            <Th className="whitespace-nowrap">{t('ROLES')}</Th>
          )}
          <Th className="whitespace-nowrap">{t('ASSIGNED_PROJECTS')}</Th>
          <Th className="whitespace-nowrap">{t('LAST_ACTIVE')}</Th>
          <Th className="text-center whitespace-nowrap">{t('STATUS')}</Th>
          {(access?.teammate.update || access?.teammate.delete) && (
            <Th className=" whitespace-nowrap text-center">{t('ACTION')}</Th>
          )}
        </Thead>

        <Tbody>
          {!loading ? (
            visibleTeammates.length > 0 ? (
              visibleTeammates.map((item, index) => (
                <Row
                  key={item._id}
                  teammateData={item}
                  // classes={classes}
                  // setAnchorEl={setAnchorEl}
                  openEditPanel={openEditPanel}
                  openDeleteTeammateAlert={openDeleteTeammateAlert}
                  // setTeammateToEdit={setTeammateToEdit}
                  toggleSwitchLoading={toggleSwitchLoading}
                  handleSwitch={handleSwitch}
                  access={access}
                  handleInviteTeammate={handleInviteTeammate}
                  elmFor={elmFor}
                  meData={meData}
                  canEditAccount={canEditAccount}
                  setRoleAnchorEl={setRoleAnchorEl}
                  handleViewProfile={handleViewProfile}
                />
              ))
            ) : (
              <Tr className="bg-white dark:bg-dark-main">
                <th
                  colSpan={meData.role < userRoles.USER_CLIENT ? 6 : 5}
                  className="text-left py-3 px-6 text-gray-500 font-normal dark:text-dark-light"
                >
                  {t('NO_TEAM_MEMBERS')}
                </th>
              </Tr>
            )
          ) : (
            <TableDataLoading
              cols={meData.role < userRoles.USER_CLIENT ? 6 : 5}
            />
          )}
        </Tbody>
      </TableContainer>

      {!loading.loading && paginationTotalDataCount > totalTeammatesToShow && (
        <Pagination
          handlePagination={setPage}
          totalResultsCount={paginationTotalDataCount}
          visibleResultsCount={visibleTeammates.length}
          page={page}
          limit={totalTeammatesToShow}
        />
      )}

      {/* <DeleteModal
        warningText={t('WARNING_DELETE')}
        open={deleteTeammateData.open}
        handleDialog={openDeleteTeammateAlert}
        handleDeleteAction={handleTeammateData}
        loading={deleteTeammateData.loading}
      /> */}
      {roleAnchorEl.key === 'role' && (
        <CompanyDropdown
          anchorEl={roleAnchorEl.anchorEl}
          onClose={() => setRoleAnchorEl({ anchorEl: null, data: {}, key: '' })}
          handleDialogue={handleUpdateTeammate}
          list={roleAnchorEl.key === 'role' ? verticals : activeProjects}
          elmFor={roleAnchorEl.key}
          canCreate={true}
          zIndex={1250}
        />
      )}
      {roleAnchorEl.key !== 'role' && (
        <ProjectsDropdown
          anchorEl={roleAnchorEl.anchorEl}
          onClose={() => setRoleAnchorEl({ anchorEl: null, data: {}, key: '' })}
          handleDialogue={handleUpdateTeammate}
          list={activeProjects}
          teammate={roleAnchorEl.data}
        />
      )}
    </div>
  )
}

const Row = ({
  teammateData,
  elmFor,
  toggleSwitchLoading,
  handleSwitch,
  access,
  openEditPanel,
  openDeleteTeammateAlert,
  handleInviteTeammate,
  meData,
  canEditAccount,
  setRoleAnchorEl,
  handleViewProfile
}) => {
  const { t } = useTranslation()
  const teamVerticals = useSelector(state => state.teamVerticals)
  const errorMessages = getErrorMessages()
  const dispatch = useDispatch()
  const paymentStatus = useMemo(() => {
    return meData.payment?.status ?? meData.team?.payment?.status
  }, [meData])

  const userVertical = useMemo(() => {
    if (teammateData.verticals.length && teamVerticals.fetched) {
      const vertical = teamVerticals.data.find(
        el => el._id === teammateData.verticals[0]?._id
      )
      return vertical ? vertical.name : null
    } else {
      return null
    }
  }, [teammateData._id, teamVerticals, teammateData.verticals])

  const handleEdit = () => {
    openEditPanel(teammateData)
  }

  const handleDelete = e => {
    if (!canEditAccount(teammateData.role)) {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
      return
    }
    openDeleteTeammateAlert(teammateData)
  }

  const handleSendInvitation = () => {
    if (!canEditAccount(teammateData.role)) {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
      return
    }

    handleInviteTeammate(teammateData._id)
  }

  const handleUpdate = (e, key) => {
    e.stopPropagation()
    setRoleAnchorEl({ anchorEl: e.currentTarget, data: teammateData, key: key })
  }

  const canAssign =
    access?.teammate?.update &&
    [
      userRoles.AGENCY_MANAGER,
      userRoles.AGENCY_EDITOR,
      userRoles.AGENCY_VIEWER
    ].includes(teammateData.role)

  const assignProject = () => {
    if (canAssign) {
      return (
        <div>
          <CustomTooltip title={t('ASSIGN_PROJECTS')} placement="top">
            <AddIcon
              className="h-5 w-5 p-1 ml-1 cursor-pointer border rounded-full"
              onClick={e => handleUpdate(e, 'project')}
            />
          </CustomTooltip>
        </div>
      )
    }
  }

  let options = []
  if (access?.teammate.update) {
    options.push({
      label: t('EDIT'),
      method: handleEdit,
      icon: <EditIcon className="text-primary-main dark:text-dark-light" />
    })

    if (elmFor === 'agency') {
      options.push({
        label: t('RESEND_INVITE'),
        method: handleSendInvitation,
        icon: <Calendar className="text-primary-main dark:text-dark-light" />
      })
    }

    if (access?.teammate.delete) {
      options.push({
        label: t('DELETE'),
        method: handleDelete,
        icon: <DeleteIcon />,
        isDanger: true
      })
    }
  }

  return (
    <Tr>
      <Td
        className=" px-6 py-4 whitespace-nowrap flex items-center space-x-2 cursor-pointer"
        onClick={() => {
          handleViewProfile(teammateData._id)
        }}
      >
        <ColoredAvatars size="medium" user={teammateData} />
        <div className="flex flex-col">
          <div className="flex items-center leading-3">
            <span className="text-primary-mid-dark font-medium mr-2 dark:text-dark-light2">
              {teammateData.name.length < 35
                ? teammateData.name
                : `${teammateData.name.substring(0, 34)}...`}
            </span>{' '}
            {getRoleBadge(teammateData.role)}
          </div>
          <span className="text-primary-mid-dark-2 text-xs dark:text-dark-light">
            {teammateData.email}
          </span>
        </div>
      </Td>
      {meData.role < userRoles.USER_CLIENT && (
        <Td className="show-on-hover-parent">
          {userVertical ? (
            <div className="flex items-center">
              <Pill variant="yellow">{userVertical}</Pill>
              {access?.teammate?.update && (
                <CustomTooltip title={t('CHANGE_ROLE')} placement="top">
                  <AngleIcon
                    className={`show-on-hover-child mt-1 h-5 w-5 p-0.5 rounded-full hover:bg-gray-200`}
                    onClick={e => handleUpdate(e, 'role')}
                  />
                </CustomTooltip>
              )}
            </div>
          ) : access?.teammate?.update ? (
            <div>
              <CustomTooltip title={t('ASSIGN_ROLE')} placement="top">
                <AddIcon
                  className="h-5 w-5 p-1 cursor-pointer border rounded-full"
                  onClick={e => handleUpdate(e, 'role')}
                />
              </CustomTooltip>
            </div>
          ) : (
            <div>-</div>
          )}
        </Td>
      )}
      <Td className="whitespace-nowrap truncate">
        {teammateData.assignedWorkspace?.length ? (
          <>
            {teammateData.assignedWorkspace.slice(0, 2).map((ws, index) => (
              <div key={ws._id} className={index === 0 ? 'leading-6' : 'mt-2'}>
                {index === 0 ? (
                  <div className="flex items-center leading-3">
                    <Pill variant="pink">
                      {ws.name.slice(0, 1).toUpperCase() + ws.name.slice(1)}
                    </Pill>
                    {teammateData?.assignedWorkspace?.length === 1 &&
                      assignProject()}
                  </div>
                ) : (
                  <div className="flex items-center leading-3">
                    <Pill variant="blue">
                      {ws.name.slice(0, 1).toUpperCase() + ws.name.slice(1)}
                    </Pill>

                    {teammateData.assignedWorkspace?.length > 2 ? (
                      <span
                        className="px-3 py-0.5"
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold'
                        }}
                      >
                        +{teammateData.assignedWorkspace?.length - 2}
                      </span>
                    ) : null}
                    {assignProject()}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : canAssign ? (
          assignProject()
        ) : (
          <div>-</div>
        )}
      </Td>
      <Td className="text-xs text-custom-gray-main whitespace-nowrap dark:text-dark-light">
        {teammateData.lastActive
          ? format(new Date(teammateData.lastActive), 'd MMM p')
          : '-'}
      </Td>
      <Td>
        <ToggleSwitch
          checked={teammateData.isActive}
          onChange={() => handleSwitch(teammateData)}
          disable={paymentStatus === 'canceled'}
          loading={toggleSwitchLoading.includes(teammateData._id)}
        />
      </Td>
      {(access?.teammate.update || access?.teammate.delete) && (
        <Td className="text-center">
          <PopupMenu menuItems={options} />
        </Td>
      )}
    </Tr>
  )
}

export default TeammatesTable
