import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ChatsNavbar from './ChatsNavbar'
import { useMediaQuery } from '@material-ui/core'
import { useEffect, useState } from 'react'
import CreateChannelModal from './CreateChannelModal'
import { useModal } from 'customHooks'
import { handleSetCurrentChannelId } from 'thunks/chat/actions'
import { fetchAllAgencyTeammates } from 'thunks/teammate/actions'
import NoChatsFound from './NoChatsFound'
import ChatWindow from './ChatWindow/ChatWindow'

const Chat = ({
  isPersonalChat,
  channelId,
  setChannelId,
  setIsPersonalChat
}) => {
  const { loading, groups } = useSelector(state => state.chats)
  const meData = useSelector(state => state.me.data)
  const [teammateChannels, setTeammateChannels] = useState({
    loading: true,
    data: []
  })
  // const { channelId } = useParams()
  const dispatch = useDispatch()
  const {
    modal: createModal,
    openModal: openCreateModal,
    closeModal: closeCreateModal
  } = useModal()

  const channel =
    (isPersonalChat
      ? teammateChannels.data.find(item => item._id === channelId)
      : groups.data.find(item => item._id === channelId)) || {}

  useEffect(() => {
    fetchAllAgencyTeammates(null, (res, err) => {
      setTeammateChannels({
        loading: false,
        data: err ? [] : res.filter(user => user._id !== meData._id)
      })
    })
  }, [meData._id])

  useEffect(() => {
    if (channelId) dispatch(handleSetCurrentChannelId(channelId))

    return () => {
      dispatch(handleSetCurrentChannelId(null))
    }
  }, [channelId])

  const matches = useMediaQuery('(min-width:768px)')

  const navElm = (
    <ChatsNavbar
      loading={loading || teammateChannels.loading}
      channel={channel}
      channels={groups.data}
      personalChannels={teammateChannels.data}
      openCreateChannelModal={openCreateModal}
      setChannelId={setChannelId}
      setIsPersonalChat={setIsPersonalChat}
    />
  )

  const chatElm = channel._id ? (
    <ChatWindow
      isSocketConnected={true}
      key={channel._id}
      isPersonalChat={isPersonalChat}
      channelId={channel._id}
      channelName={isPersonalChat ? channel.name : channel.title}
      channelMembers={channel.members}
    />
  ) : (
    <NoChatsFound />
  )

  return (
    <>
      <div className="h-full">
        <div className="bg-white dark:bg-dark-main2 flex h-full overflow-hidden rounded-md">
          {matches ? (
            <>
              <div className="flex-1 h-full" style={{ maxWidth: 350 }}>
                {navElm}
              </div>
              <div className="flex-1 h-full">{chatElm}</div>
            </>
          ) : !channel._id ? (
            <div className="flex-1 h-full">{navElm}</div>
          ) : (
            <div className="flex-1 h-full">{chatElm}</div>
          )}
        </div>
      </div>

      <CreateChannelModal open={createModal.open} onClose={closeCreateModal} />
    </>
  )
}

export default Chat
