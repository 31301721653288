import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from 'store'
import * as Sentry from '@sentry/react'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
})

// const configureStore = () => {
//   let middlewares = [thunk]
//   const store = createStore(
//     rootReducer,
//     process.env.REACT_APP_ENVIRONMENT === 'development'
//       ? window.__REDUX_DEVTOOLS_EXTENSION__
//         ? compose(
//             applyMiddleware(...middlewares),
//             window.__REDUX_DEVTOOLS_EXTENSION__({
//               trace: true,
//               traceLimit: 25
//             }),
//             sentryReduxEnhancer
//           )
//         : compose(applyMiddleware(...middlewares), sentryReduxEnhancer)
//       : compose(applyMiddleware(...middlewares), sentryReduxEnhancer)
//   )
//   return store
// }

const configureStore = () => {
  let middlewares = [thunk]
  const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          applyMiddleware(...middlewares),
          window.__REDUX_DEVTOOLS_EXTENSION__({
            trace: true,
            traceLimit: 25
          }),
          sentryReduxEnhancer
        )
      : compose(applyMiddleware(...middlewares), sentryReduxEnhancer)
  )
  return store
}

export default configureStore
