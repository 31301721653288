import React, { useMemo } from 'react'
import { getFileIcon, getFileType } from 'utils'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import clsx from 'clsx'

const MessageAttachments = ({ attachments, handleOpenPreview, dir }) => {
  const groupedAttachments = useMemo(() => {
    const obj = { images: [], videos: [], audios: [], others: [] }

    for (let i = 0; i < attachments.length; i++) {
      const attachment = {
        name: attachments[i].slice(attachments[i].lastIndexOf('/') + 1),
        url: attachments[i],
        type: getFileType(attachments[i])
      }

      if (attachment.type === 'image') obj.images.push(attachment)
      else if (attachment.type === 'video') obj.videos.push(attachment)
      else if (attachment.type === 'audio') obj.audios.push(attachment)
      else obj.others.push(attachment)
    }

    return obj
  }, [attachments])

  return (
    <>
      <Images
        attachments={groupedAttachments.images}
        dir={dir}
        handleOpenPreview={handleOpenPreview}
      />
      <Audios attachments={groupedAttachments.audios} />
      <Videos attachments={groupedAttachments.videos} />
      <Others
        attachments={groupedAttachments.others}
        handleOpenPreview={handleOpenPreview}
      />
    </>
  )
}

const Images = ({ attachments, dir, handleOpenPreview }) => {
  const urls = attachments.map(item => item.url)

  if (attachments.length === 0) return null
  return (
    <div className={clsx('flex mt-2')}>
      {attachments.slice(0, 2).map((attachment, index) => (
        <div key={index} className="mr-2">
          <img
            src={attachment.url}
            style={{
              pointerEvents: 'initial'
            }}
            className="h-36 rounded-lg border cursor-pointer dark:border-dark-main1"
            alt={attachment.name}
            onClick={() => handleOpenPreview(index, urls)}
          />
        </div>
      ))}
      {attachments.length > 2 && (
        <div
          className="cursor-pointer relative ml-2"
          onClick={() => handleOpenPreview(0, urls)}
        >
          <img
            style={{
              pointerEvents: 'initial'
            }}
            src={attachments[2].url}
            className="h-36 rounded-lg border cursor-pointer"
            alt={attachments[2].name}
          />
          <div
            style={{ background: 'rgba(0, 0, 0, 0.5)' }}
            className="absolute rounded-lg top-0 left-0 w-full h-full flex justify-center items-center text-white"
          >
            <PlusIcon />
            <span>{attachments.length - 2}</span>
          </div>
        </div>
      )}
    </div>
  )
}

const Audios = ({ attachments }) => {
  if (attachments.length === 0) return null
  return (
    <div className="mt-2">
      {attachments.map((attachment, index) => (
        <audio key={index} controls>
          <source src={attachment.url} />
          Your browser does not support this audio format.
        </audio>
      ))}
    </div>
  )
}

const Videos = ({ attachments }) => {
  if (attachments.length === 0) return null
  return (
    <div className="mt-2">
      {attachments.map((attachment, index) => (
        <video
          key={index}
          style={{ width: 250, maxHeight: 250 }}
          controls
          className="rounded-lg"
        >
          <source src={attachment.url} />
          Your browser does not support this video format.
        </video>
      ))}
    </div>
  )
}

const Others = ({ attachments, handleOpenPreview }) => {
  const urls = attachments.map(item => item.url)

  if (attachments.length === 0) return null

  return (
    <div className="mt-2" style={{ maxWidth: 300 }}>
      {attachments.map((attachment, index) => (
        <div
          key={index}
          className="flex border rounded-lg items-center p-4 bg-white mt-2"
        >
          <div className="text-2xl mr-4">{getFileIcon(attachment.url)}</div>
          <div>
            <div
              onClick={() => handleOpenPreview(index, urls)}
              className="text-sm font-medium text-gray-700 hover:text-blue-500 cursor-pointer"
            >
              {attachment.name}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MessageAttachments
