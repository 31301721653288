const generatedIds = {}

function generateUniqueId(length = 8) {
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const charactersLength = characters.length
  let uniqueId = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength)
    uniqueId += characters.charAt(randomIndex)
  }

  if (generatedIds[uniqueId]) {
    return generateUniqueId()
  }

  generatedIds[uniqueId] = true
  return uniqueId
}

export default generateUniqueId
