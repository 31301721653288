import React, { useEffect, useState, useMemo } from 'react'
// import { useSelector } from 'react-redux'
// import {
//   fetchUsersWorkload,
//   fetchUserWiseWorkload
// } from 'thunks/Tracking/action'
// import { userRoles } from 'utils'
import WorkloadHead from './WorkloadHead'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import WorkloadTable from './WorkloadTable'
import moment from 'moment'

moment.locale('en-gb', {
  week: {
    dow: 1 /// Date offset
  }
})

function getWeekDays(weekStart) {
  const days = []
  for (let i = 0; i < 7; i++) {
    days.push(moment(weekStart).add(i, 'days').toDate().toDateString())
  }
  return days
}

const Workload = () => {
  // const meData = useSelector(state => state.me.data)
  const [loading, setLoading] = useState(false)
  const [timelineData, setTimelineData] = useState([])
  const [selectedDays, setSelectedDays] = useState(getWeekDays(new Date()))
  const [selectedDate, setSelectedDate] = useState(new Date())

  const weekStartDate = useMemo(() => {
    return moment(selectedDate).startOf('week').toISOString()
  }, [selectedDate])

  const weekEndDate = useMemo(() => {
    return moment(selectedDate).endOf('week').toISOString()
  }, [selectedDate])

  // useEffect(() => {
  //   // setLoading(true)
  //   setLoading(false)
  //   if ([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)) {
  //     fetchUsersWorkload(
  //       '2022-12-31T18:30:00.000Z',
  //       '2023-02-10T07:28:55.212Z',
  //       fetchDataCallback
  //     )
  //   } else {
  //     fetchUserWiseWorkload(weekStartDate, weekEndDate, fetchDataCallback)
  //   }
  // }, [weekStartDate, weekEndDate])

  // const fetchDataCallback = (res, err) => {
  //   setLoading(false)
  //   if (!err) {
  //     // setTimelineData(res)
  //   }
  // }

  useEffect(() => {
    setSelectedDays(getWeekDays(moment(selectedDate).startOf('week').toDate()))
  }, [selectedDate])

  return (
    <Wrapper>
      <WorkloadHead
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <WorkloadTable
        timelineData={timelineData}
        loading={loading}
        setLoading={setLoading}
        selectedDays={selectedDays}
        weekStartDate={weekStartDate}
        weekEndDate={weekEndDate}
      />
    </Wrapper>
  )
}

export default Workload
