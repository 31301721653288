export const GETTING_STARTED = {
  FETCHED: 'GETTING_STARTED_FETCHED',
  ERROR: 'GETTING_STARTED_ERROR',
  LOADING: 'GETTING_STARTED_LOADING',
  FETCHED_ANALYTICS: 'GETTING_STARTED_FETCHED_ANALYTICS',
  UPDATE_ANALYTICS: 'GETTING_STARTED_UPDATE_ANALYTICS'
}

const initialState = {
  loading: true,
  fetched: false,
  error: false,
  data: {},
  analytics: {}
}

const gettingStartedTasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETTING_STARTED.LOADING:
      return { ...state, loading: true }

    case GETTING_STARTED.FETCHED:
      return {
        ...state,
        fetched: true,
        loading: false,
        data: action.payload
      }

    case GETTING_STARTED.ERROR:
      return { ...state, loading: false, error: true }

    case GETTING_STARTED.FETCHED_ANALYTICS:
      return { ...state, analytics: action.payload }

    case GETTING_STARTED.UPDATE_ANALYTICS:
      return {
        ...state,
        analytics: action.payload
      }

    default:
      return state
  }
}

export default gettingStartedTasksReducer
