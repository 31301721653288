import React, { useEffect, useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlaneIcon } from 'static/svg/plane.svg'
import { addNewChatGroup } from 'thunks/chat/actions'
import { differenceWith } from 'lodash'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import userRoles, { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Select from 'react-select'
import { TextField, useTheme } from '@material-ui/core'
import { CustomAvatar, FormLabel } from 'global/globalComponents'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Pill from 'global/globalComponents/Pill/Pill'
import socket from 'config/socketConfig'

function CreateChannelModal({ open, onClose }) {
  return (
    <CustomModal
      open={open}
      size="extraLarge"
      modalStyles={{ zIndex: 9999999 }}
    >
      <ModalContent onClose={onClose} />
    </CustomModal>
  )
}

const ModalContent = ({ onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { loading: projectsLoading, data: allProjects } = useSelector(
    state => state.userWorkspaces
  )
  const theme = useTheme()
  const meData = useSelector(state => state.me.data)
  const { groups } = useSelector(state => state.chats)
  const [projectsOptions, setProjectsOptions] = useState([])
  const [selectedProject, setSelectedProject] = useState({})
  const [loading, setLoading] = useState(false)
  const [groupData, setGroupData] = useState({
    title: '',
    description: '',
    members: [],
    type: 'internal'
  })
  const { data: userTeammates, loading: userTeammatesLoading } = useSelector(
    state => state.userTeammates
  )
  const { data: clients, loading: clientsLoading } = useSelector(
    state => state.userClients
  )
  const [teammateOptions, setTeammateOptions] = useState([])

  const filterOption = (candidate, input) => {
    return (
      candidate.data.name.toLowerCase().includes(input.toLowerCase()) ||
      candidate.data.email.toLowerCase().includes(input.toLowerCase())
    )
  }

  useEffect(() => {
    const arr = []
    const addedMembers = groupData.members.map(item => item._id)

    userTeammates.forEach(mate => {
      if (!addedMembers.includes(mate._id)) {
        arr.push({
          ...mate,
          label: (
            <div className="flex justify-between items-center gap-1">
              <div>{`${mate.name} (${mate?.email})`}</div>
              <Pill variant={'pink'}>{'team'}</Pill>
            </div>
          ),
          value: mate._id
        })
      }
    })

    clients.forEach(item => {
      if (!addedMembers.includes(item._id)) {
        arr.push({
          ...item,
          label: (
            <div className="flex justify-between items-center gap-2">
              <div>{`${item.name} (${item.email})`}</div>
              <Pill variant={'yellow'}>{'client'}</Pill>
            </div>
          ),
          value: item._id
        })
      }
    })
    setTeammateOptions(arr)
  }, [userTeammates, groupData.members, clients])

  // const [channel, setChannel] = useState(false)
  // const customClass = useStyles()
  // const theme = useTheme()

  useEffect(() => {
    if (groupData.type === 'workspace') {
      //creating project channel
      if (allProjects.length) {
        const arr = differenceWith(
          allProjects,
          groups.data,
          (p, g) => p._id === g.workspace
        )
          .filter(project => !project.isArchived)
          .map(item => ({
            ...item,
            label: item.name,
            value: item._id,
            boardId: item.defaultBoard?._id
          }))
        setProjectsOptions(arr)
      }
    }
  }, [groupData.type, groups, allProjects])

  const handleSelectProject = projectOption => {
    setSelectedProject(projectOption)
    setGroupData(prev => ({
      ...prev,
      title: projectOption.name,
      type: 'workspace',
      members: [
        ...projectOption.assignedAgencyTeam.map(userObj => ({
          ...userObj.user,
          role: userObj.role
        })),
        ...projectOption.assignedClientTeam.map(userObj => ({
          ...userObj.user,
          role: userObj.role
        }))
      ]
    }))
  }

  // CREATES AND UPDATES A CHANNEL
  const handleCreateNewGroup = () => {
    const nameMatch = groups.data.find(
      group => group.title === groupData.title.trim()
    )

    if (nameMatch)
      return dispatch(fireErrorToaster(groupData.title + '  already exists'))

    const body = {
      title: groupData.title,
      description: groupData.description,
      type: groupData.type,
      icon: groupData.type === 'workspace' ? selectedProject.image : '',
      members: groupData.members
        .map(item => ({
          user: item._id,
          role: 'moderator'
        }))
        .concat({ user: meData._id, role: 'admin' })
    }

    if (groupData.type === 'workspace') body.workspace = selectedProject._id

    setLoading(true)

    socket.emit('groupCreate', body, ({ result, error, status }) => {
      if (status !== 200) {
        dispatch(
          fireErrorToaster(error.message || t('ERROR_SOME_ERROR_OCCURED'))
        )
      } else {
        dispatch(addNewChatGroup({ ...result, unread: 0 }))
        history.push(`${getUserPath(meData.role)}/chat-stream/${result._id}`)
        dispatch(fireSuccessToaster(t('CHANNEL_CREATED_SUCCESSFULLY')))
        setLoading(false)
        // setSelectedMembers([])
        onClose()
      }
    })
    // dispatch(
    //   createChatGroup(
    //     {
    //       data: body
    //     },
    //     (res, err) => {
    //       if (err) dispatch(fireErrorToaster(res.message))
    //       else {
    //         history.push(`${getUserPath(meData.role)}/chat-stream/${res._id}`)
    //         dispatch(fireSuccessToaster(t('CHANNEL_CREATED_SUCCESSFULLY')))
    //       }
    //       setLoading(false)
    //       // setSelectedMembers([])
    //       onClose()
    //     }
    //   )
    // )
  }

  const handleToggleChange = e => {
    setGroupData(prev => ({
      ...prev,
      type: e.target.checked ? 'workspace' : 'internal',
      members: [],
      title: ''
    }))
    setSelectedProject({})
    // setIsProjectChat(!isProjectChat)
  }

  return (
    <>
      <header className="flex items-center justify-between gap-2 py-4 2xl:py-5 px-6 select-none rounded-t-2xl 2xl:rounded-t-3xl dark:text-dark-light">
        <div className="flex flex-1 space-x-2 items-center">
          <span>
            <PlaneIcon className="text-primary-main w-6 h-6" />
          </span>

          <div className="w-full">
            <h2 className="text-lg 2xl:text-xl font-semibold text-primary-dark dark:text-dark-light">
              {t('CREATE_CHANNEL')}
            </h2>
            <p className="text-xs text-custom-gray-dark-3 dark:text-dark-light">
              {t('ADD_NEW_CHANNEL_ACTIVATE_CHAT_FOR_AN_EXISTING_PROJECT')}
            </p>
          </div>
        </div>

        <div className="flex text-sm items-center">
          {t('PROJECT_CHAT')}
          <ToggleSwitch
            onChange={handleToggleChange}
            checked={groupData.type === 'workspace'}
          />
        </div>
      </header>

      <CustomModalBody>
        <div className="pt-2 h-96">
          <div className="mb-4">
            <FormLabel required>{t('NAME_OF_THE_CHANNEL')}</FormLabel>
            {groupData.type === 'workspace' ? (
              <Select
                options={projectsOptions}
                value={selectedProject.value ? selectedProject : ''}
                className="w-full"
                onChange={handleSelectProject}
                isLoading={projectsLoading}
                placeholder={t('SELECT_PROJECT')}
                styles={{
                  ...reactSelectCustomStyles(theme)
                }}
              />
            ) : (
              <TextField
                className="w-full"
                name="name"
                value={groupData.title}
                type="text"
                placeholder={t('ENTER_CHANNEL_NAME')}
                onChange={e =>
                  setGroupData(prev => ({
                    ...prev,
                    title: e.target.value
                  }))
                }
              />
            )}
          </div>

          <div className="mb-4">
            <FormLabel>{t('DESCRIPTION')}</FormLabel>
            <TextField
              className="w-full"
              name="description"
              value={groupData.description}
              type="text"
              placeholder={t('ENTER_CHANNEL_DESCRIPTION')}
              onChange={e =>
                setGroupData(prev => ({
                  ...prev,
                  description: e.target.value
                }))
              }
            />
          </div>

          <div className="mb-4">
            {groupData.type !== 'workspace' ? (
              <>
                <FormLabel
                  className="text-md font-semibold text-custom-dark-main"
                  required
                >
                  {t('CHANNEL_MEMBERS')}
                </FormLabel>

                <Select
                  placeholder={t('ADD_MEMBERS')}
                  isSearchable={true}
                  filterOption={filterOption}
                  onChange={e =>
                    setGroupData(prev => ({ ...prev, members: e }))
                  }
                  value={groupData.members}
                  isMulti
                  isDisabled={groupData.type === 'workspace'}
                  closeMenuOnSelect={false}
                  options={teammateOptions}
                  styles={reactSelectCustomStyles(theme, {
                    multiValueRemove: {
                      top: 6
                    }
                  })}
                  // className="flex-grow"
                  maxMenuHeight={120}
                  isLoading={userTeammatesLoading || clientsLoading}
                />
              </>
            ) : (
              <>
                <FormLabel className="text-md font-semibold text-custom-dark-main">
                  {t('CHANNEL_MEMBERS')} (
                  {selectedProject?.assignedAgencyTeam
                    ? selectedProject.assignedAgencyTeam.concat(
                        selectedProject.assignedClientTeam
                      ).length
                    : 0}
                  )
                </FormLabel>

                {selectedProject?.assignedAgencyTeam &&
                  selectedProject.assignedAgencyTeam
                    .concat(selectedProject.assignedClientTeam)
                    .map(mate => (
                      <div key={mate.user._id} className="mt-2">
                        <div className="flex items-center gap-4">
                          <CustomAvatar user={mate.user} />
                          <div>
                            <div className="text-sm leading-3">
                              {mate.user.name}
                            </div>
                            {[
                              userRoles.USER_AGENCY,
                              userRoles.AGENCY_ADMIN
                            ].includes(meData.role) && (
                              <span className="text-gray-500 text-xs mt-1 dark:text-dark-light">
                                {mate.user.email}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
              </>
            )}
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>

        <LoadingButton
          size="large"
          disabled={groupData.title.trim() === ''}
          loading={loading}
          onClick={handleCreateNewGroup}
        >
          {t('CREATE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default CreateChannelModal
