import React from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import ApprovalDetailsCard from './ApprovalDetailsCard'

const ApprovalActionModal = ({ open, onClose, data, readOnly }) => {
  return (
    <CustomModal open={open} size="extraLarge">
      <ModalContent onClose={onClose} approvalData={data} readOnly={readOnly} />
    </CustomModal>
  )
}

const ModalContent = ({ onClose, approvalData, readOnly }) => {
  return (
    <>
      <CustomModalHeader heading={approvalData.name} handleClose={onClose} />
      <CustomModalBody>
        <ApprovalDetailsCard approvalData={approvalData} readOnly={readOnly} />
      </CustomModalBody>

      <CustomModalFooter></CustomModalFooter>
    </>
  )
}

export default ApprovalActionModal
