import { ReactComponent as ImageFile } from 'static/svg/image-file.svg'
import { ReactComponent as AudioFile } from 'static/svg/music-note.svg'
import { ReactComponent as VideoFile } from 'static/svg/film-reel.svg'
import { ReactComponent as File } from 'static/svg/file.svg'
import { ReactComponent as PDFFile } from 'static/svg/file-pdf.svg'

/**
 * Get file type by extension
 * @param {String} extension extension or file url (if it contains extension)
 * @returns audio/video/image/other
 */

export const getFileType = fileUrl => {
  if (/\.(png|svg|jpe?g|gif|tiff?|webp|avif)$/i.test(fileUrl)) return 'image'
  if (/\.(m4a|flac|mp3|wav|aiff)$/i.test(fileUrl)) return 'audio'
  if (
    /\.(mp4|mov|avi|flv|mkv|wmv|avchd|webm|mpeg-4)(\??[\w=&%.-]*)$/i.test(
      fileUrl
    )
  )
    return 'video'
  if (/\.(pdf)$/i.test(fileUrl)) return 'pdf'
  if (/\.(json)$/i.test(fileUrl)) return 'json'
  if (/\.(txt)$/i.test(fileUrl)) return 'text'
  if (/\.(psd)$/i.test(fileUrl)) return 'photoshop'
  if (/\.(csv)$/i.test(fileUrl)) return 'comma-separated values'
  if (/\.(xlsx)$/i.test(fileUrl)) return 'excel spreadsheet'
  if (/\.(pptx)$/i.test(fileUrl)) return 'powerPoint slideshow'
  if (/\.(docx)$/i.test(fileUrl)) return 'word'
  return 'unknown'
}

export const getFileIcon = fileExtension => {
  const fileType = getFileType(fileExtension)

  switch (fileType) {
    case 'image':
      return <ImageFile />
    case 'audio':
      return <AudioFile />
    case 'video':
      return <VideoFile />
    case 'pdf':
      return <PDFFile />
    default:
      return <File />
  }
}

export const getFileExtFromBaseUrl = baseUrl => {
  return '.' + baseUrl.split(';')[0].split(':')[1].split('/')[1]
}
