import React, { useEffect, useState, useImperativeHandle } from 'react'
import RequestDynamicFormTemplate from '../RequestDynamicFormTemplate/RequestDynamicFormTemplate'

const RequestFormTemplateWrapper = React.forwardRef(
  ({ templateFields }, ref) => {
    const [formFields, setFormFields] = useState([])

    useImperativeHandle(ref, () => ({
      getFieldsData: () => {
        // filtering out the fields which are not being stored in the backend
        return formFields.map(item => {
          const obj = { ...item }
          delete obj.type
          delete obj.id

          return obj
        })
      }
    }))

    useEffect(() => {
      if (templateFields.length) {
        setFormFields(
          templateFields.map(field => {
            // adding type and id key for local uses and "value" to store user input
            const obj = {
              ...field,
              type: field.datatype,
              id: field._id,
              value: field.value || ''
            }

            return obj
          })
        )
      }
    }, [templateFields])

    const handleUpdateFieldProperty = ({ fieldId, prop, value }) => {
      setFormFields(prev =>
        prev.map(field => {
          if (fieldId === field._id) return { ...field, [prop]: value }
          return field
        })
      )
    }

    return (
      <div>
        <RequestDynamicFormTemplate
          formFields={formFields}
          updateFieldProperty={handleUpdateFieldProperty}
          canEdit={false}
          canFillData={true}
        />
      </div>
    )
  }
)

export default RequestFormTemplateWrapper
