import { ReactComponent as EmbedCalender } from 'static/svg/embed-calender.svg'
import { ReactComponent as EmbedSheet } from 'static/svg/embed-sheet.svg'
import { ReactComponent as EmbedDocs } from 'static/svg/embed-docs.svg'
import { ReactComponent as EmbedImg } from 'static/svg/embed.svg'
import { ReactComponent as BoardImg } from 'static/svg/board.svg'
import TableIcon from 'static/svg/table.svg'
import {
  GoogleCalender,
  GoogleDocs,
  GoogleSheet,
  CodeIcon,
  GoogleSlides
} from 'static/Images'
// import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

export const getEmbedObj = () => ({
  board: {
    id: 'board',
    name: 'Board',
    description: t('BOARD_DESC_MSG'),
    image: <BoardImg />
  },
  calendar: {
    id: 'calendar',
    name: 'Calendar',
    description: t('CALENDAR_DESC_MSG'),
    image: <EmbedCalender />
  },
  docs: {
    id: 'docs',
    name: 'Docs',
    description: t('DOCS_DESC_MSG'),
    image: <EmbedDocs />
  },
  sheet: {
    id: 'sheet',
    name: 'Sheet',
    description: t('SHEET_DESC_MSG'),
    image: <EmbedSheet />
  },
  slides: {
    id: 'slides',
    name: 'Slides',
    description: t('SLIDES_DESC_MSG'),
    image: <EmbedSheet />
  },
  embed: {
    id: 'embed',
    name: 'Embed',
    description: t('EMBED_DESC_MSG'),
    image: <EmbedImg />
  }
})

export const sideDrawerLinks = [
  {
    id: 'board',
    text: 'Board',
    icon: TableIcon
  },
  {
    id: 'calendar',
    text: 'Calendar',
    icon: GoogleCalender
  },
  {
    id: 'docs',
    text: 'Docs',
    icon: GoogleDocs
  },
  {
    id: 'sheet',
    text: 'Sheet',
    icon: GoogleSheet
  },
  {
    id: 'slides',
    text: 'Slides',
    icon: GoogleSlides
  },
  {
    id: 'embed',
    text: 'Embed',
    icon: CodeIcon
  }
]
