import {
  VisaImage,
  MasterCardImage,
  Discover,
  AmericanExpress
} from 'static/Images'

export const paymentCardImages = {
  visa: VisaImage,
  mastercard: MasterCardImage,
  amex: AmericanExpress,
  discover: Discover
}
