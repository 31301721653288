import { useEffect, useRef, useState } from 'react'
import { Button, TextField, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { createService, updateService } from 'thunks/service/actions'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import currencyObj from '../../static/Currency/Currency'
import { fileToDataURL } from 'utils'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
// import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
import { t } from 'i18next'

const AddOnModal = ({ open, onClose, data = null, serviceCategories }) => {
  return (
    <CustomModal size="large" open={open} handleClose={onClose}>
      <CustomModalHeader
        heading={data._id ? t('EDIT_ADD_ON') : t('CREATE_ADD_ON')}
        handleClose={onClose}
      />
      <ModalContent onClose={onClose} data={data} />
    </CustomModal>
  )
}

const ModalContent = ({ onClose, data }) => {
  const dispatch = useDispatch()
  const defaultCurrency = useSelector(state => state.me.data.defaultCurrency)
  const userServicesData = useSelector(state => state.userServices.data)
  const inputFileRef = useRef()
  const [offering, setOffering] = useState('')
  const [loading, setLoading] = useState(false)
  const descriptionRef = useRef(null)
  const [addOnData, setAddOnData] = useState({
    name: '',
    description: '',
    image: null,
    offerings: [],
    displayOnCatalog: false,
    isRecurring: false,
    isActive: true,
    amount: 0,
    currency: defaultCurrency
      ? {
          label: defaultCurrency.toUpperCase(),
          value: defaultCurrency,
          minimum_charge_amount: 0.5
        }
      : { value: 'usd', label: 'USD', minimum_charge_amount: 0.5 }
  })
  const [selectedServices, setSelectedServices] = useState([])
  const isEditing = Boolean(data._id)
  // const theme = useTheme()

  useEffect(() => {
    if (data._id) {
      setAddOnData({
        name: data?.name ?? '',
        description: data?.description ?? '',
        image: data?.image ?? null,
        offerings: data?.offerings ?? [],
        displayOnCatalog: false,
        isRecurring: false,
        isActive: true,
        amount: data?.amount * 0.01 || 0,
        currency: data?.currency
          ? {
              label: data.currency.toUpperCase(),
              value: data.currency,
              minimum_charge_amount: 0.5
            }
          : defaultCurrency
          ? {
              label: defaultCurrency.toUpperCase(),
              value: defaultCurrency,
              minimum_charge_amount: 0.5
            }
          : { value: 'usd', label: 'USD', minimum_charge_amount: 0.5 }
      })

      const servicesData = data.services
        .map(serviceId => {
          const match = userServicesData.find(
            service => service._id === serviceId
          )

          if (match) return match
          return null
        })
        .filter(item => Boolean(item))

      setSelectedServices(servicesData)
    }
  }, [data, userServicesData])

  const imageLoadHandler = e => {
    const file = e.target.files[0]
    fileToDataURL(file).then(res =>
      setAddOnData(prev => ({ ...prev, image: res }))
    )
  }

  const handleChange = e => {
    setAddOnData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleCurrencyChange = e => {
    setAddOnData(prev => ({ ...prev, currency: e }))
  }

  const handleOffering = (e, index) => {
    setAddOnData(prev => ({
      ...prev,
      offerings: Object.assign([], addOnData.offerings, {
        [index]: e.target.value
      })
    }))
  }

  const handleOfferingChange = e => {
    setOffering(e.target.value)
  }

  const handleAddOffering = e => {
    e.preventDefault()

    if (addOnData.offerings.length >= 12) {
      dispatch(fireErrorToaster(t('ERROR_YOU_CAN_ADD_MAXIMUM_12_OFFERINGS')))
      return
    }

    setAddOnData(prev => ({
      ...prev,
      offerings: [...prev.offerings, offering]
    }))

    setOffering('')
  }

  const handleDeleteOfferings = value => {
    const arr = addOnData.offerings.filter((item, index) => index !== value)
    setAddOnData(prev => ({
      ...prev,
      offerings: arr
    }))
  }

  // const handleSaveServiceCallback = (res, error) => {
  //   onClose()
  //   if (error) {
  //     dispatch(fireErrorToaster(res))
  //   } else {
  //     dispatch(fireSuccessToaster(t('SERVICE_CREATED_SUCCESSFULLY')))
  //   }
  // }

  const handleCreateAddOn = () => {
    const currency =
      typeof addOnData.currency === 'object'
        ? addOnData.currency.value.toLowerCase()
        : addOnData.currency.toLowerCase()

    const minimumChargeAmount =
      currencyObj[currency.toUpperCase()].minimum_charge_amount || 1

    if (!addOnData.name || !addOnData.description) {
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS'))
      )
    }

    if (!addOnData.currency) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_SELECT_CURRENCY')))
    }

    if (addOnData.amount < minimumChargeAmount) {
      return dispatch(
        fireErrorToaster(
          t('ERROR_AMOUNT_CAN_NOT_BE_LESS_THAN', {
            data1: minimumChargeAmount,
            data2: currencyObj[currency.toUpperCase()].symbol_native
          })
        )
      )
    }

    // if (serviceData.isRecurring && !recurring) {
    //   return dispatch(fireErrorToaster(t('ERROR_PLEASE_SELECT_INTERVAL')))
    // }

    /*
    Filtering serviceData before sending API Req (coverting currency from object to str)
    Here serviceData.currency could either be an object or a string, depending on whether
    the user is connected to the stripe or not. If the user is connected then the serviceData.currency will
    be a string (rupee) else the we'll show a dropdown and that results in an object
    */

    // if (selectedCategoryOption) {
    //   filteredServiceData.category = selectedCategoryOption.value
    // }

    // const totalServices = userServicesData.length

    setLoading(true)

    // Here we are using the same API we use to create services but the type would be addons
    dispatch(
      createService(
        {
          data: {
            ...addOnData,
            currency,
            services: selectedServices.map(item => item._id),
            type: 'addons'
          }
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            onClose()
            dispatch(fireSuccessToaster(t('ADD_ON_CREATED_SUCCESSFULLY')))
          }
          // handleSaveServiceCallback(res, err)
          // if (!err && totalServices === 0) {
          //   dispatch(gettingAllTasksStatus())
          // }
        }
      )
    )
  }

  const handleUpdateAddOn = () => {
    if (!addOnData.name || !addOnData.description) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    if (addOnData.amount < 1) {
      dispatch(fireErrorToaster(t('ERROR_AMOUNT_CAN_NOT_BE_LESS_THAN_UNIT')))
      return
    }

    setLoading(true)
    let filteredServiceData = {
      ...addOnData,
      amount: addOnData.amount * 100,
      // field: 'update', //notification related
      currency:
        typeof addOnData.currency === 'object'
          ? addOnData.currency.value.toLowerCase()
          : addOnData.currency.toLowerCase(),
      type: 'addons'
    }

    dispatch(
      updateService({ id: data._id, data: filteredServiceData }, (err, res) => {
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          onClose()
          dispatch(fireSuccessToaster(t('ADD_ON_UPDATED_SUCCESSFULLY')))
        }
      })
    )
  }

  // const handleUpdateServiceCallback = (res, err) => {
  //   setLoading(false)
  //   onClose()
  //   if (!err) {
  //     dispatch(fireSuccessToaster(t('SERVICE_CREATED_SUCCESSFULLY')))
  //   } else {
  //     dispatch(fireErrorToaster(res))
  //   }
  // }

  // const handleCategoryChange = option => {
  //   setSelectedCategoryOption(option)
  // }

  return (
    <>
      <CustomModalBody>
        <div className="flex justify-between space-x-8">
          {/* ========= Left ======== */}
          <div className="flex-1">
            <div className="mb-4">
              <FormLabel htmlFor="name" required>
                {t('NAME')}
              </FormLabel>

              <TextField
                name="name"
                value={addOnData.name}
                onChange={handleChange}
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder={t('ENTER_NAME')}
                id="name"
              />
            </div>

            <div className="mb-4">
              <FormLabel className="text-sm">{t('ADD_IMAGE_ICON')}</FormLabel>
              <div
                className="relative h-14 w-14 show-on-hover-parent cursor-pointer rounded-full overflow-hidden flex justify-center items-center border"
                onClick={() => inputFileRef.current.click()}
              >
                <div
                  className="absolute top-0 left-0 w-full h-full z-10 show-on-hover-child flex justify-center items-center"
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                >
                  <PenIcon className="text-white" />
                </div>
                {/* <FormLabel htmlFor="user-image-uploadbtn">
                  <Button component="span" size="small">
                    {t('CHOOSE_FILE')}
                  </Button>
                </FormLabel> */}
                <img
                  src={
                    addOnData.image
                      ? addOnData.image.startsWith('https')
                        ? `${addOnData.image}?t=${Date.now()}`
                        : addOnData.image
                      : 'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'
                  }
                  alt="service icon"
                  className="w-full h-full object-cover"
                />

                <input
                  ref={inputFileRef}
                  accept="image/*"
                  style={{ display: 'none' }}
                  multiple={false}
                  type="file"
                  onChange={imageLoadHandler}
                />
              </div>
            </div>

            <div className="mb-4">
              <FormLabel required htmlFor="description">
                {t('DESCRIPTION')}
              </FormLabel>
              <TextField
                name="description"
                value={addOnData.description}
                onChange={handleChange}
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder={t('ENTER_DESCRIPTION')}
                id="description"
                ref={descriptionRef}
                multiline={true}
              />
            </div>

            <div className="flex items-center space-x-4">
              <SelectCurrency
                serviceData={addOnData}
                handleCurrencyChange={handleCurrencyChange}
                disabled={isEditing}
              />
              <div>
                <FormLabel htmlFor="amount">{t('AMOUNT')}</FormLabel>
                <TextField
                  name="amount"
                  value={addOnData.amount}
                  onChange={handleChange}
                  type="number"
                  className="hover:border-blue-500 border border-custom-gray-main p-2 rounded-md outline-none w-24"
                  placeholder={t('AMOUNT')}
                  id="amount"
                  // disabled={Boolean(data)}
                />
              </div>
            </div>
          </div>

          {/* ========= Right ======== */}
          <div className="flex-1">
            {/* <div className="mb-4">
              <div className="flex items-center">
                <FormLabel htmlFor="offerings">
                  {t('ATTACH_SERVICES')}
                </FormLabel>
                <span className="ml-2 -mt-1">
                  <QuestionMarkTooltip
                    text={t('ADD_ONS_ATTACH_SERVICE_MESSAGE')}
                    direction="top"
                  />
                </span>
              </div>
              <Select
                styles={reactSelectCustomStyles(theme)}
                options={userServicesData}
                value={selectedServices}
                onChange={setSelectedServices}
                maxMenuHeight={120}
                getOptionLabel={option => option.name}
                getOptionValue={option => option._id}
                isMulti
              />
            </div> */}

            <div className="mb-4">
              <FormLabel htmlFor="offerings">
                {t('ADD_OFFERINGS')}{' '}
                <span className="font-normal ml-1 text-xs">
                  ({t('MAXIMUM')} 12)
                </span>
              </FormLabel>
              <form onSubmit={handleAddOffering}>
                <div className="flex justify-between gap-2">
                  <TextField
                    name="offering"
                    value={offering}
                    onChange={handleOfferingChange}
                    className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                    placeholder={t('ENTER_SERVICE_OFFERING')}
                    id="offerings"
                  />
                  <Button
                    type="submit"
                    disabled={offering.trim().length === 0}
                    size="small"
                  >
                    {t('ADD')}
                  </Button>
                </div>
              </form>

              <div className="mt-2">
                {addOnData.offerings?.map((item, index) => (
                  <SingleOffering
                    key={index}
                    offering={item}
                    handleOffering={handleOffering}
                    handleDeleteOfferings={handleDeleteOfferings}
                    index={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          onClick={data._id ? handleUpdateAddOn : handleCreateAddOn}
          loading={loading}
          disabled={loading}
        >
          {data._id ? t('SAVE_CHANGES') : t('CREATE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

const SelectCurrency = ({ handleCurrencyChange, serviceData, disabled }) => {
  const theme = useTheme()
  const [currencyOptions, setCurrencyOptions] = useState([])

  useEffect(() => {
    let arr = Object.keys(currencyObj).map(el => ({
      value: el.toLowerCase(),
      label: el,
      minimumChargeAmount: currencyObj[el]?.minimum_charge_amount ?? 1
    }))
    setCurrencyOptions(arr)
  }, [])

  return (
    <>
      <div>
        <FormLabel htmlFor="currency">{t('CURRENCY')}</FormLabel>
        <div className="w-28">
          <Select
            styles={reactSelectCustomStyles(theme)}
            options={currencyOptions}
            onChange={handleCurrencyChange}
            value={serviceData?.currency}
            maxMenuHeight={120}
            menuPlacement="top"
            isDisabled={disabled}
          />
        </div>
      </div>
    </>
  )
}

const SingleOffering = ({
  offering,
  handleOffering,
  handleDeleteOfferings,
  index
}) => {
  return (
    // <div className="flex items-center show-on-hover-parent">
    //   <div className="flex items-center">
    //     <CheckIcon className="text-green-500" />
    //     <span className="ml-2 text-sm">{offering}</span>
    //   </div>

    //   <div className="flex items-center space-x-2 ml-6">
    //     <button className="text-gray-500">
    //       <PenIcon className="w-3 h-3" />
    //     </button>

    //     <button className="text-red-500">
    //       <CloseIcon className="w-3 h-3" />
    //     </button>
    //   </div>
    // </div>
    <div className="w-full border p-2 hover:border-primary-main focus-within:border-primary-main rounded-md outline-none flex flex-row justify-between mt-3">
      <input
        name="offerings"
        onChange={e => handleOffering(e, index)}
        value={offering}
        className="text-sm flex-grow pr-4 text-gray-500 dark:bg-dark-main2 dark:focus:text-dark-light"
        id="offerings"
      />
      <button
        onClick={() => handleDeleteOfferings(index)}
        className="leading-3"
      >
        <CloseIcon
          className="cursor-pointer w-4 h-4 text-gray-600"
          fontSize="small"
        />
      </button>
    </div>
  )
}

export default AddOnModal
