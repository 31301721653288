import { makeStyles, createStyles } from '@material-ui/core'

const userServiceStyles = makeStyles((theme) =>
  createStyles({
    active: {
      borderBottom: '1px solid #4aa3d4',
      color: '#4aa3d4'
    },
    editIcon: {
      fontSize: 34,
      padding: 8,
      color: theme.palette.custom.gray.main,
      cursor: 'pointer',
      borderRadius: '9999px'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      width: '1000px',
      minHeight: '500px',
      height: 'fit-content',
      outline: 'none',
      borderRadius: 8
    },
    selectStyle: {
      width: '100%'
    },
    serviceTypeContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(3),
      height: 32,
      paddingTop: 2,
      '&>p, &>a': {
        fontSize: 14,
        fontWeight: 500,
        color: '#6f7782',
        paddingBottom: theme.spacing(0.5),
        cursor: 'pointer',
        minWidth: 32,
        textAlign: 'center',
        userSelect: 'none',
        transitionDuration: '.2s',
        transitionProperty: 'box-shadow,color',
        whiteSpace: 'nowrap',
        '&.active': {
          boxShadow: `0px 2px ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        }
      }
    },
    dialogPaper: {
      minWidth: 400,
      borderRadius: '10px'
    },
    categoriesWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '1rem',
      alignItems: 'start'
    },
    card: {
      '&:hover .delete-btn': {
        opacity: 1
      }
    },
    cardImageOverlay: {
      '&:hover': {
        opacity: 1
      }
    },
    wideButton: {
      marginTop: 8,
      textTransform: 'uppercase'
    }
  })
)

export default userServiceStyles
