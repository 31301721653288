import { ReactComponent as Board } from 'static/asana/board.svg'
import { ReactComponent as BriefCase } from 'static/asana/briefcase.svg'
import { ReactComponent as Bug } from 'static/asana/bug.svg'
import { ReactComponent as Calender } from 'static/asana/calender.svg'
import { ReactComponent as ChatBubbles } from 'static/asana/chat_bubbles.svg'
import { ReactComponent as Check } from 'static/asana/check.svg'
import { ReactComponent as Coins } from 'static/asana/coins.svg'
import { ReactComponent as Computer } from 'static/asana/computer.svg'
import { ReactComponent as Gear } from 'static/asana/gear.svg'
import { ReactComponent as Globe } from 'static/asana/globe.svg'
import { ReactComponent as Graph } from 'static/asana/graph.svg'
import { ReactComponent as Html } from 'static/asana/html.svg'
import { ReactComponent as LightBulb } from 'static/asana/light_bulb.svg'
import { ReactComponent as LineAndSymbols } from 'static/asana/line_and_symbols.svg'
import { ReactComponent as List } from 'static/asana/list.svg'
import { ReactComponent as Map } from 'static/asana/map.svg'
import { ReactComponent as MegaPhone } from 'static/asana/megaphone.svg'
import { ReactComponent as MountainFlag } from 'static/asana/mountain_flag.svg'
import { ReactComponent as Notebook } from 'static/asana/notebook.svg'
import { ReactComponent as PageLayout } from 'static/asana/page_layout.svg'
import { ReactComponent as People } from 'static/asana/people.svg'
import { ReactComponent as Presentation } from 'static/asana/presentation.svg'
import { ReactComponent as Puzzle } from 'static/asana/puzzle.svg'
import { ReactComponent as Ribbon } from 'static/asana/ribbon.svg'
import { ReactComponent as Rocket } from 'static/asana/rocket.svg'
import { ReactComponent as Shoe } from 'static/asana/shoe.svg'
import { ReactComponent as ShoppingBasket } from 'static/asana/shopping_basket.svg'
import { ReactComponent as SpeedDial } from 'static/asana/speed_dial.svg'
import { ReactComponent as Star } from 'static/asana/star.svg'
import { ReactComponent as Target } from 'static/asana/target.svg'
import { ReactComponent as Ticket } from 'static/asana/ticket.svg'
import { ReactComponent as Timeline } from 'static/asana/timeline.svg'
import { ReactComponent as Plus } from 'static/asana/plusIcon.svg'
const AsanaIcons = {
  Board: (props) => <Board {...props} />,
  BriefCase: (props) => <BriefCase {...props} />,
  Bug: (props) => <Bug {...props} />,
  Calender: (props) => <Calender {...props} />,
  ChatBubbles: (props) => <ChatBubbles {...props} />,
  Check: (props) => <Check {...props} />,
  Coins: (props) => <Coins {...props} />,
  Computer: (props) => <Computer {...props} />,
  Gear: (props) => <Gear {...props} />,
  Globe: (props) => <Globe {...props} />,
  Graph: (props) => <Graph {...props} />,
  Html: (props) => <Html {...props} />,
  LightBulb: (props) => <LightBulb {...props} />,
  LineAndSymbols: (props) => <LineAndSymbols {...props} />,
  List: (props) => <List {...props} />,
  Map: (props) => <Map {...props} />,
  MegaPhone: (props) => <MegaPhone {...props} />,
  MountainFlag: (props) => <MountainFlag {...props} />,
  Notebook: (props) => <Notebook {...props} />,
  PageLayout: (props) => <PageLayout {...props} />,
  People: (props) => <People {...props} />,
  Presentation: (props) => <Presentation {...props} />,
  Puzzle: (props) => <Puzzle {...props} />,
  Ribbon: (props) => <Ribbon {...props} />,
  Rocket: (props) => <Rocket {...props} />,
  Shoe: (props) => <Shoe {...props} />,
  ShoppingBasket: (props) => <ShoppingBasket {...props} />,
  SpeedDial: (props) => <SpeedDial {...props} />,
  Star: (props) => <Star {...props} />,
  Target: (props) => <Target {...props} />,
  Ticket: (props) => <Ticket {...props} />,
  Timeline: (props) => <Timeline {...props} />,
  Plus: (props) => <Plus {...props} />
}

export default AsanaIcons
