import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PULSE } from 'thunks/pulse/reducer'
import { TEAMMATES } from 'thunks/teammate/reducers'
import { userRoles } from 'utils'

const PulsePusherWrapper = (props) => {
  const meData = useSelector((state) => state.me.data)
  const dispatch = useDispatch()
  const [pusherConnected, setPusherConnected] = useState(false)

  useEffect(() => {
    setPusherConnected(Boolean(window.pusherInstance))
  })

  useEffect(() => {
    if (!window.pusherInstance) return

    let agencyOwner
    if (meData.role === userRoles.USER_AGENCY) {
      agencyOwner = meData._id
    } else if (
      [
        userRoles.USER_CLIENT,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_EDITOR,
        userRoles.AGENCY_VIEWER,
        userRoles.AGENCY_ADMIN
      ].includes(meData.role)
    ) {
      agencyOwner = meData.team._id
    } else if (
      [
        userRoles.CLIENT_MANAGER,
        userRoles.CLIENT_EDITOR,
        userRoles.CLIENT_VIEWER
      ].includes(meData.role)
    ) {
      agencyOwner = meData.agency
    }

    // 1. Looking for the channel in the pusher instance (whether it exists or not)
    const pulseSubscribe = window.pusherInstance.channel(
      `userActivity_${agencyOwner}`
    )

    // 2. If channel does not exist or channel does exist but not subscribed then do the following
    if (!pulseSubscribe || !pulseSubscribe.subscribed) {
      const subscribedPulses = window.pusherInstance.subscribe(
        `userActivity_${agencyOwner}`
      )

      if (subscribedPulses) {
        subscribedPulses.bind('userActivity_add', (data) => {
          dispatch({ type: PULSE.UPDATE_USER_PULSE, payload: data })
          dispatch({ type: TEAMMATES.UPDATE_ONE_TEAMMATE, payload: data })
        })
      }
    }
  }, [meData._id, meData.team?._id, meData.agency, pusherConnected])

  return <>{props.children}</>
}

export default PulsePusherWrapper
