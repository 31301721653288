import axios from 'axios'
import { catchAsync, catchAsyncDispatch, getQueryString } from 'utils'
import { FILES_ASSETS } from './reducers'
import { Uploader, DropboxUploader } from 'utils'
import uniqueId from 'lodash/uniqueId'

const uploaders = {
  data: [],
  remove(uid) {
    this.data = this.data.filter(item => item.file.uid !== uid)
  },
  addNew(uploaderData) {
    this.data.push(uploaderData)
  }
}

const getDropboxFilesWithUrl = async files => {
  const promises = []

  files.forEach(file =>
    promises.push(
      axios(`/connectedapps/file/preview?type=dropbox&path=${file.path_lower}`)
    )
  )

  const res = await Promise.all(promises)
  return files.map((item, index) => {
    return {
      ...item,
      _id: item.id,
      name: item.name.slice(0, item.name.lastIndexOf('.')),
      extension: item.name.slice(item.name.lastIndexOf('.')),
      createdAt: item.client_modified,
      updatedAt: item.server_modified,
      url: res[index].data.link,
      size: item.size / 1024,
      storageName: 'dropbox'
    }
  })
}

export const getUserRootFolder = (data, callback) => {
  return dispatch => {
    dispatch(
      fetchFolders(
        { data: { parent: null, user: data.userId } },
        (res, err) => {
          if (err) return
          if (res[0]) {
            callback(res[0])
            return
          }

          dispatch(
            createFolder(
              {
                data: {
                  name: 'My Storage',
                  parent: null,
                  type: 'user'
                },
                parent: null
              },
              (res, err) => {
                if (!err) callback(res)
              }
            )
          )
        }
      )
    )
  }
}

/**
 * Fetch Folders
 * @param {String} data.parent parent dir id
 * @param {String | Undefined} data.forWorkspace conditional (only for project storage)
 * @param {String | Undefined} data.user user id conditional
 * @param {String | Undefined} data.category conditional (for team storage only)
 * @param {Function} callback callback function
 */
export const fetchFolders = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/fileassetsfolder/fetch',
      data: data.data
    })

    dispatch({
      type: FILES_ASSETS.FOLDERS_FETCHED,
      payload: {
        parentDir: data.data.parent,
        data: res.data
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch workspace folders
 * @param {String} data.workspaceId workspace id
 * @param {Function} callback (optional)
 */
export const fetchWorkspaceFolders = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/fileassetsfolder/folder/workspace?workspace=${data.workspaceId}`
    })

    dispatch({
      type: FILES_ASSETS.FOLDERS_FETCHED,
      payload: {
        parentDir: data.workspaceId,
        data: res.data
      }
    })
    if (callback) callback(res.data)
  }, callback)
}
/**
 * Fetch workspace files
 * @param {String} data.workspaceId workspace id
 * @param {Function} callback callback function
 */
export const fetchWorkspaceFiles = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/fileassetsfile/folder/workspace/${data.workspaceId}`
    })

    dispatch({
      type: FILES_ASSETS.FILES_FETCHED,
      payload: {
        parentDir: data.workspaceId,
        data: res.data
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch teammate's or client's root folder
 * @param {String} data.user userId
 * @param {String|null} data.parent parent dir id
 * @param {Function} callback callback function
 */
export const fetchTeamPersonalRootFolder = catchAsync(
  async (data, callback) => {
    const res = await axios({
      method: 'POST',
      url: '/fileassetsfolder/fetch',
      data: data.data
    })

    if (callback) callback(res.data[0])
  }
)

/**
 * Fetch root folder
 * @param {Object} data.data category (withMe, team)
 * @param {String} data.parent parent dir id
 * @param {Function} callback callback function
 */
export const fetchTeamSharedFolders = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/fileassetsfolder/fetch`,
      data: data.data
    })

    dispatch({
      type: FILES_ASSETS.FOLDERS_FETCHED,
      payload: {
        parentDir: data.parent,
        data: res.data
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

// /**
//  * Fetch folders shared with client
//  * @param {String} clientId
//  * @param {Function} callback callback function
//  */
// export const fetchClientSharedFolders = (clientId, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/fileassetsfolder/folder/agency?type=team&user=${clientId}`
//       })

//       dispatch({
//         type: FILES_ASSETS.FOLDERS_FETCHED,
//         payload: {
//           parentDir: clientId,
//           data: res.data
//         }
//       })
//       if (callback) callback(res.data)
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//     }
//   }
// }

// /**
//  * Fetch files shared with client
//  * @param {String} clientId
//  * @param {Function} callback callback function
//  */
// export const fetchClientSharedFiles = (clientId, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/fileassetsfile/file/agency?type=team&user=${clientId}`
//       })

//       dispatch({
//         type: FILES_ASSETS.FILES_FETCHED,
//         payload: {
//           parentDir: clientId,
//           data: res.data
//         }
//       })
//       if (callback) callback(res.data)
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//     }
//   }
// }

/**
 * Create folder
 * NOTE: data.data.parent is for backend while data.parent is for frontend
 * i'm using it to store data in redux in some cases it is different from data.data.parent
 * @param {Object} data.data name, parent, type
 * @param {String|null} data.parent parent dir id
 * @param {Function} callback
 */
export const createFolder = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/fileassetsfolder',
      data: data.data
    })

    dispatch({
      type: FILES_ASSETS.ADD_FOLDER,
      payload: {
        parentDir: data.parent,
        data: res.data
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update folder by id
 * @param {String} data.id folder id
 * @param {Object} data.data update
 * @param {Function} callback callback function
 */
export const updateFolderById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/fileassetsfolder/${data.id}`,
      data: data.data
    })

    dispatch({
      type: FILES_ASSETS.UPDATE_FOLDER,
      payload: res.data
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update file by id
 * @param {String} data.id file id
 * @param {Object} data.data update
 * @param {String} data.parent parent dir id
 * @param {Function} callback callback function
 */
export const updateFileById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/fileassetsfile/${data.id}`,
      data: data.data
    })

    dispatch({
      type: FILES_ASSETS.UPDATE_FILE,
      payload: {
        data: res.data,
        parentDir: data.parent
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete Folder
 * @param {String} id folder id
 * @param {Function} callback callback function
 */
export const deleteFolder = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/fileassetsfolder/${data.folderId}`
    })

    dispatch({
      type: FILES_ASSETS.DELETE_FOLDER,
      payload: {
        id: data.folderId
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch Files
 * @param {String} data.parent parent dir id
 * @param {Object} data.query optional
 * @param {Function} callback callback function
 */
export const fetchFiles = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/fileassetsfile/folder/${data.parent}${getQueryString(data.query)}`
    })

    dispatch({
      type: FILES_ASSETS.FILES_FETCHED,
      payload: { parentDir: data.parent, data: res.data }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch Files
 * @param {String} data.type team, withMe
 * @param {String} data.parent parent dir id
 * @param {Function} callback callback function
 */
export const fetchRootFiles = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/fileassetsfile?type=${data.type}`
    })

    dispatch({
      type: FILES_ASSETS.FILES_FETCHED,
      payload: { parentDir: data.parent, data: res.data }
    })
  }, callback)
}

/**
 * Upload File
 * @param {Object} data.data file data
 * @param {Object} data.metaData
 * @param {String} data.parent parent dir id
 * @param {Function} callback
 */
export const uploadFile = (data, callback) => {
  return dispatch => {
    let percentage = undefined
    const uid = uniqueId('file_')

    const uploader = new Uploader({
      ...data.data,
      module: 'fileAssets',
      metaData: data.metadata
    })

    uploaders.addNew({ uploader, file: data.data })

    dispatch({
      type: FILES_ASSETS.UPLOADING_FILE,
      payload: {
        name: data.data.fileName,
        extension: data.data.file.name.slice(
          data.data.file.name.lastIndexOf('.')
        ),
        percentage: 0,
        uid: uid
      }
    })

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage
          dispatch({
            type: FILES_ASSETS.UPDATE_PERCENTAGE,
            payload: { uid: uid, percentage, isComplete: false }
          })
        }
      })
      .onComplete(res => {
        callback(res)
        dispatch({
          type: FILES_ASSETS.UPLOADING_COMPLETE,
          payload: uid
        })
        dispatch({
          type: FILES_ASSETS.ADD_FILE,
          payload: {
            parentDir: data.parent,
            data: res
          }
        })

        uploaders.remove(uid)
      })
      .onError(error => {
        callback(error.message ?? 'File uploading failed!', true)
        dispatch({
          type: FILES_ASSETS.REMOVE_UPLOADING,
          payload: uid
        })
        uploaders.remove(uid)
      })

    uploader.start()
  }
}

export const cancelFileUpload = (data, callback) => {
  return dispatch => {
    const uploaderObj = uploaders.data.find(item => item.file.uid === data.uid)

    if (uploaderObj) {
      uploaderObj.uploader.abort()
      if (callback) callback(uploaderObj)
    }
  }
}

/**
 * Delete File
 * @param {String} id file id
 * @param {String} parentDir parent dir id
 * @param {Function} callback callback function
 */
export const deleteFile = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/fileassetsfile/${data.fileId}`
    })

    dispatch({
      type: FILES_ASSETS.DELETE_FILE,
      payload: {
        id: data.fileId,
        parentDir: data.parentDir
      }
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Upload File to project wihtout dispatching actions and call callback fns instead
 * @param {Object} data.data file data
 * @param {Object} data.metaData
 * @param {String} data.parent parent dir id
 * @param {Function} callback
 */
export const uploadProjectFiles = (data, callback) => {
  return dispatch => {
    let percentage = undefined
    const uid = uniqueId('file_')

    const uploader = new Uploader({
      ...data.data,
      module: 'fileAssets',
      metaData: data.metadata
    })

    uploaders.addNew({ uploader, file: data.data, uid })

    callback({
      name: data.data.fileName,
      extension: data.data.file.name.slice(
        data.data.file.name.lastIndexOf('.')
      ),
      percentage: 0,
      uid: uid
    })

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage
          callback({ uid: uid, percentage, isComplete: false })
        }
      })
      .onComplete(res => {
        callback({
          uid: uid,
          percentage: 100,
          isComplete: true
        })
        dispatch({
          type: FILES_ASSETS.ADD_FILE,
          payload: {
            parentDir: data.parent,
            data: res
          }
        })

        uploaders.remove(uid)
      })
      .onError(error => {
        callback(
          { message: error.message ?? 'File uploading failed!', uid },
          true
        )
        dispatch({
          type: FILES_ASSETS.REMOVE_UPLOADING,
          payload: uid
        })
        uploaders.remove(uid)
      })

    uploader.start()
  }
}

// /**
//  * Fetch all files and assets by task id
//  * @param {String} taskId task id
//  * @param {Function} callback callback function. arguments (response, errStatus)
//  */
// export const fetchFileAndAssetsBytask = (taskId, callback) => {
//   return async dispatch => {
//     dispatch({ type: FILES_ASSETS.LOADING })
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/fileassets/task/${taskId}`
//       })
//       dispatch({ type: FILES_ASSETS.FETCHED_BY_TASK, payload: res.data })
//       if (callback) callback(res.data, false)
//     } catch (err) {
//       dispatch({ type: FILES_ASSETS.LOADING_ERROR, payload: err.response })
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//     }
//   }
// }

// /**
//  * Delete a single
//  * @param {String} id file asset id
//  * @param {Function} callback callback function. arguments (response, errStatus)
//  */
// export const deleteOneFileAsset = (id, callback) => {
//   return async dispatch => {
//     dispatch({ type: FILES_ASSETS.DELETING_A_FILE, paylaod: id })
//     try {
//       const res = await axios({
//         url: `/fileassets/${id}`,
//         method: 'DELETE'
//       })

//       dispatch({ type: FILES_ASSETS.DELETED_A_FILE, payload: id })
//       callback(res.data, false)
//     } catch (err) {
//       dispatch({ type: FILES_ASSETS.DELETING_ERROR, payload: err.response })
//       callback(
//         err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//         true
//       )
//     }
//   }
// }

/**
 * Get breadcrumbs
 * @param {String} data.currentDirId
 * @param {Function} callback
 */
export const getBreadcrumbs = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/fileassetsfolder/breadcrumbs/${data.currentDirId}`
  })

  callback(res.data)
})

/**
 *
 * @param {null | Object} data
 * @param {Function} callback
 * @returns
 */
export const getDropboxFilesAndFolders = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/connectedapps/storage',
      data: {
        appName: 'dropbox',
        path: data.path
      }
    })

    // transforming data as per our structure
    const mappedFoldersData = res.data
      .filter(item => item['.tag'] === 'folder')
      .map(item => {
        const obj = {
          ...item,
          _id: item.id,
          storageName: 'dropbox'
        }
        return obj
      })

    if (callback) callback(res.data)
    dispatch({
      type: FILES_ASSETS.FOLDERS_FETCHED,
      payload: { parentDir: data.parent, data: mappedFoldersData }
    })

    const mappedFilesData = await getDropboxFilesWithUrl(
      res.data.filter(item => item['.tag'] === 'file')
    )

    dispatch({
      type: FILES_ASSETS.FILES_FETCHED,
      payload: { parentDir: data.parent, data: mappedFilesData }
    })
  }, callback)
}

/**
 * Create folder for dropbox
 * @param {String} data.appName
 * @param {String} data.name folder name
 * @param {String} data.path folder path or id
 * @param {String} data.parent parent folder id
 * @param {Function} callback
 * @returns
 */
export const createDropboxFolder = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/connectedapps/storage/folder',
      data: data.data
    })

    const transformedData = {
      ...res.data,
      _id: res.data.id,
      storageName: 'dropbox'
    }

    dispatch({
      type: FILES_ASSETS.ADD_FOLDER,
      payload: {
        parentDir: data.parent,
        data: transformedData
      }
    })

    if (callback) callback(transformedData)
  }, callback)
}
/**
 * Delete file & folder for dropbox
 * @param {String} data.id file or folder id
 * @param {String} data.type folder / file
 * @param {Function} callback
 * @returns
 */
export const deleteDropboxFileAndFolder = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/connectedapps/file/${data.id}?type=dropbox`
    })

    if (data.type === 'folder') {
      dispatch({
        type: FILES_ASSETS.DELETE_FOLDER,
        payload: {
          id: data.id
        }
      })
    } else if (data.type === 'file') {
      dispatch({
        type: FILES_ASSETS.DELETE_FILE,
        payload: {
          id: data.id,
          parentDir: data.parentDir
        }
      })
    }

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update file & folder for dropbox
 * @param {String} data.id file or folder id
 * @param {Object} data.data appName, from_path, to_path
 * @param {String} data.type folder / file
 * @param {Function} callback
 * @returns
 */
export const updateDropboxFileAndFolder = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/connectedapps/file/${data.id}`,
      data: data.data
    })

    if (data.type === 'folder') {
      const transformedData = {
        ...res.data,
        _id: res.data.id,
        storageName: 'dropbox'
      }
      dispatch({
        type: FILES_ASSETS.UPDATE_FOLDER,
        payload: transformedData
      })
    } else if (data.type === 'file') {
      const transformedData = await getDropboxFilesWithUrl([res.data])

      dispatch({
        type: FILES_ASSETS.UPDATE_FILE,
        payload: {
          data: transformedData[0],
          parentDir: data.parent
        }
      })
    }

    if (callback) callback(res.data)
  }, callback)
}
/**
 * Upload dropbox file
 * @param {Object} data.data file, fileId
 * @param {String} data.parent parent folder id
 * @param {String} data.uid unique id
 * @param {Function} callback
 * @returns
 */
export const uploadDropboxFile = (data, callback) => {
  return dispatch => {
    const uploader = new DropboxUploader({
      file: data.data.file,
      metadata: {
        fileId: data.data.fileId
      }
    })

    const uid = uniqueId('file_')
    const idx = data.data.file.name.lastIndexOf('.')

    dispatch({
      type: FILES_ASSETS.UPLOADING_FILE,
      payload: {
        name: data.data.file.name.slice(0, idx),
        extension: data.data.file.name.slice(idx),
        percentage: 0,
        uid: uid
      }
    })

    uploader
      .onProgress(percentage => {
        dispatch({
          type: FILES_ASSETS.UPDATE_PERCENTAGE,
          payload: { uid: uid, percentage, isComplete: false }
        })
      })
      .onComplete(async res => {
        const transformedData = await getDropboxFilesWithUrl([res])

        dispatch({
          type: FILES_ASSETS.UPLOADING_COMPLETE,
          payload: uid
        })

        dispatch({
          type: FILES_ASSETS.ADD_FILE,
          payload: {
            parentDir: data.parent,
            data: transformedData[0]
          }
        })
      })
      .onError(error => {
        if (callback) callback(error.message ?? 'File uploading failed!', true)
        dispatch({ type: FILES_ASSETS.REMOVE_UPLOADING, payload: uid })
        uploaders.remove(uid)
      })
  }
}

/**
 * Fetch single file by id
 * @param {String} data.id file id
 * @param {Function} callback callback function
 */
export const fetchFileById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/fileassetsfile/${data.id}`
  })

  if (callback) callback(res.data)
})

/**
 * fetch folder by id
 * @param {String} data.id folder id
 * @param {Object} data.data update
 * @param {Function} callback callback function
 */
export const fetchFolderById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/fileassetsfolder/${data.id}`
  })

  if (callback) callback(res.data)
})

export const cleanStorageData = () => {
  return { type: FILES_ASSETS.RESET }
}

export const fetchRootFolder = (data, callback) =>
  catchAsyncDispatch(async dispatch => {
    let root
    const res = await axios({
      method: 'POST',
      url: '/fileassetsfolder/fetch',
      data: { parent: null }
    })

    if (res.data[0]) {
      root = res.data[0]
    } else {
      const response = await axios({
        method: 'POST',
        url: '/fileassetsfolder',
        data: {
          name: 'My Storage',
          parent: null,
          type: 'user'
        }
      })
      root = response.data
    }

    dispatch({
      type: FILES_ASSETS.SET_ROOT_FOLDER,
      payload: root
    })

    if (callback) callback(root)
  }, callback)
