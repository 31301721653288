import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import { getAuthToken } from 'utils'
import {
  fetchGroups,
  fetchUnreadMessagesByCategory,
  updateUnreadMessageCount,
  changeGroupUpdatedAt,
  updatePersonalUnreadMessageCount,
  updateGroup,
  deleteGroup,
  addNewChatGroup
} from 'thunks/chat/actions'
import socket from 'config/socketConfig'
import { getUserPath } from 'utils/userRoles'
import { getAccessToken } from 'utils'

const useWebSocket = () => {
  const {
    groups: channels, //each group is a channel
    unreadMessages,
    personalUnreadMessages,
    currentChannelId
  } = useSelector(state => state.chats)
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const { loggedIn } = useSelector(state => state.login)
  const [isSocketConnected, setIsSocketConnected] = useState(false)
  const [userJoined, setUserJoined] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!loggedIn) return

    if (isSocketConnected) {
      joinRoom()
    }
  }, [loggedIn, isSocketConnected])

  useEffect(() => {
    if (userJoined && channels.data.length) {
      const channelds = channels.data.map(item => item._id)
      socket.emit(
        'join',
        {
          ids: channelds
        },
        res => {
          // empty callback
        }
      )
    }
  }, [userJoined, channels.data.length])

  useEffect(() => {
    if (!loggedIn) return

    // If groups are not fetched, fetch them
    if (!channels.fetched) {
      dispatch(fetchGroups())
    }
  }, [channels.fetched, loggedIn])

  const joinRoom = () => {
    socket.emit('isUserConnected', ({ result }) => {
      socket.emit(
        'userJoin',
        {
          user: meData._id,
          name: meData.name
        },
        res => {
          console.log({ userJoin: res })
          if (res.status === 200) {
            setUserJoined(true)
          }
        }
      )
    })
  }

  const handleUpdateGroupUnreadMessageData = useCallback(
    receivedMessage => {
      // If the currently active channel is different then the message's channel only then update the chat count
      if (receivedMessage.group !== currentChannelId) {
        dispatch(
          updateUnreadMessageCount({
            groupId: receivedMessage.group,
            value: unreadMessages[receivedMessage.group]
              ? {
                  ...unreadMessages[receivedMessage.group],
                  lastUnreadMessage: receivedMessage,
                  unreadCount:
                    unreadMessages[receivedMessage.group].unreadCount + 1
                }
              : {
                  lastUnreadMessage: receivedMessage,
                  unreadCount: 1
                }
          })
        )

        dispatch(
          changeGroupUpdatedAt({
            groupId: receivedMessage.group,
            updatedAt: receivedMessage.createdAt
          })
        )
      } else {
        dispatch(
          updateUnreadMessageCount({
            groupId: receivedMessage.group,
            value: {
              lastUnreadMessage: receivedMessage,
              unreadCount: 0
            }
          })
        )
      }
    },
    [currentChannelId, unreadMessages, dispatch]
  )

  const handleUpdatePersonalUnreadMessageData = useCallback(
    receivedMessage => {
      const unreadMsgData = personalUnreadMessages[receivedMessage.user]

      // If the currently active channel is different then the message's channel only then update the chat count
      if (receivedMessage.user !== currentChannelId) {
        dispatch(
          updatePersonalUnreadMessageCount({
            channelId: receivedMessage.user,
            value: unreadMsgData
              ? {
                  ...unreadMsgData,
                  lastUnreadMessage: receivedMessage,
                  unreadCount: unreadMsgData.unreadCount + 1
                }
              : {
                  lastUnreadMessage: receivedMessage,
                  unreadCount: 1
                }
          })
        )
      } else {
        if (unreadMsgData) {
          dispatch(
            updatePersonalUnreadMessageCount({
              channelId: receivedMessage.user,
              value: {
                ...unreadMsgData,
                lastUnreadMessage: receivedMessage
              }
            })
          )
        }
      }
    },
    [currentChannelId, personalUnreadMessages, dispatch]
  )

  const onReceiveMessage = receivedMessage => {
    if (receivedMessage.receiver)
      handleUpdatePersonalUnreadMessageData(receivedMessage)
    else handleUpdateGroupUnreadMessageData(receivedMessage)
  }

  useEffect(() => {
    if (!loggedIn) return

    socket.auth = {
      token: `Bearer ${getAccessToken()}`
    }

    if (!socket.connected) {
      socket.connect()
    }

    const onConnect = () => {
      console.log('socket connected!')
      setIsSocketConnected(true)
    }

    const onReceiveGroupUpdate = data => {
      console.log('onReceiveGroupUpdate')
      dispatch(updateGroup(data))
    }

    const onAddReceiveGroup = data => {
      console.log('onAddReceiveGroup')
      dispatch(addNewChatGroup({ ...data, unread: 0 }))
    }

    const onDeleteReceiveGroup = data => {
      console.log('onDeleteReceiveGroup')
      dispatch(deleteGroup(data._id))

      if (window.location.pathname.includes(`/chat-stream/${data._id}`)) {
        history.push(`${getUserPath(meData.role)}/chat-stream`)
      }
    }

    if (!socket.hasListeners('connect')) {
      socket.on('connect', onConnect)
    }

    if (!socket.hasListeners('receiveMessage')) {
      socket.on('receiveMessage', onReceiveMessage)
    }

    if (!socket.hasListeners('updateReceiveGroup')) {
      socket.on('updateReceiveGroup', onReceiveGroupUpdate)
    }

    if (!socket.hasListeners('addReceiveGroup')) {
      socket.on('addReceiveGroup', onAddReceiveGroup)
    }

    if (!socket.hasListeners('deleteReceiveGroup')) {
      socket.on('deleteReceiveGroup', onDeleteReceiveGroup)
    }

    // socket.on('deleteReceiveGroup', onDeleteReceiveGroup)

    // return () => {
    //   socket.off('connect', onConnect)
    //   socket.off('receiveMessage', onReceiveMessage)
    //   socket.off('updateReceiveGroup', onReceiveGroupUpdate)
    //   socket.off('addReceiveGroup', onAddReceiveGroup)
    // }
  }, [loggedIn])

  useEffect(() => {
    if (!loggedIn) return

    dispatch(fetchUnreadMessagesByCategory({ category: 'group' }))
    dispatch(fetchUnreadMessagesByCategory({ category: 'direct' }))
  }, [loggedIn])

  return null
}

export default useWebSocket
