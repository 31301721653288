import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { CVLogo } from 'static/Images/index'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeUserFeedbackPopper,
  submitUserFeedback
} from 'thunks/generlAppState/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { Loader } from 'global/globalComponents'

const feedbackOptions = [
  { type: 'new_idea', label: 'New idea', color: '#5a5ae9' },
  { type: 'feature_request', label: 'Feature request', color: '#55d955' },
  { type: 'bug_report', label: 'Bug report', color: '#fbc157' }
]

const UserFeedbackPopup = () => {
  const userFeedbackPopup = useSelector(
    state => state.generalAppState.userFeedbackPopup
  )

  if (!userFeedbackPopup.open) return null

  return <UserFeedbackPopupContent />
}

const UserFeedbackPopupContent = () => {
  const dispatch = useDispatch()
  const [selectedFeedbackOption, setSelectedFeedbackOption] = useState(null)
  const [openThanksPopup, setOpenThanksPopup] = useState(false)

  const handleSubmitUserFeedback = ({ title, description, type }, cb) => {
    submitUserFeedback(
      {
        body: {
          title,
          description,
          type
        }
      },
      (res, err) => {
        cb(res, err)
        if (err) dispatch(fireErrorToaster(res))
        else {
          setOpenThanksPopup(true)
        }
      }
    )
  }

  const closeAllPopups = () => {
    setOpenThanksPopup(false)
    setSelectedFeedbackOption(null)
    dispatch(closeUserFeedbackPopper())
  }

  return (
    <>
      <div
        className="popup-animation transform-origin_bottom-left fixed bottom-4 left-5 rounded-md bg-white"
        style={{
          width: 300,
          zIndex: 9999,
          boxShadow: '0px 0px 30px -5px rgba(0, 0, 0, 0.2)'
        }}
      >
        <header className="text-center px-6 pb-4 pt-6 relative">
          <button
            className="absolute top-2 right-2"
            onClick={() => dispatch(closeUserFeedbackPopper())}
          >
            <CrossIcon />
          </button>
          <img src={CVLogo} alt="logo" className="inline-block w-10 mb-2" />
          <h2 className="font-semibold text-lg leading-6">
            Let us know what you need
          </h2>
        </header>
        <hr />

        <div className="px-6 pb-2 pt-3">
          <p className="text-smaller text-center font-medium mb-4">
            What type of feedback do you have?
          </p>
          <ul>
            {feedbackOptions.map(item => (
              <li key={item.type} className="mb-3">
                <div
                  className="flex items-center border rounded px-6 py-3 cursor-pointer"
                  role="button"
                  onClick={() => setSelectedFeedbackOption(item)}
                >
                  <span
                    className="inline-block w-3 h-3 rounded-full mr-2"
                    style={{ background: item.color }}
                  ></span>
                  <span className="text-sm">{item.label}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {selectedFeedbackOption && (
          <RequestDetailsCard
            selectedFeedbackOption={selectedFeedbackOption}
            onClose={() => setSelectedFeedbackOption(null)}
            handleSubmitUserFeedback={handleSubmitUserFeedback}
          />
        )}

        {openThanksPopup && <ThankYouCard onClose={closeAllPopups} />}
      </div>
    </>
  )
}

const RequestDetailsCard = ({
  selectedFeedbackOption,
  onClose,
  handleSubmitUserFeedback
}) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    type: selectedFeedbackOption.type
  })
  const [loading, setLoading] = useState(false)

  const handleUpdateFormData = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmitClick = () => {
    if (formData.title.trim() === '' || formData.description.trim() === '') {
      dispatch(fireErrorToaster('Title and Description fields are required!'))
      return
    }

    setLoading(true)
    handleSubmitUserFeedback(formData, (res, err) => {
      setLoading(false)
    })
  }

  return (
    <div className="popup-animation transform-origin_bottom-left absolute top-0 left-0 w-full h-full bottom-4 rounded-md bg-white flex flex-col">
      <header className="px-6 pt-6 relative mb-2">
        <button className="absolute top-2 right-2" onClick={onClose}>
          <CrossIcon />
        </button>
        <h2 className="font-semibold text-lg">
          {selectedFeedbackOption.label}
        </h2>
        <p className="text-smaller font-medium">
          Please fill the following fields:
        </p>
      </header>

      <div className="px-6 pb-2 pt-3">
        <div className="mb-4">
          <TextField
            type="text"
            value={formData.title}
            placeholder="Title"
            name="title"
            onChange={handleUpdateFormData}
            className="w-full"
          />
        </div>

        <div>
          <TextField
            type="text"
            value={formData.description}
            placeholder="Description"
            name="description"
            onChange={handleUpdateFormData}
            multiline
            rows={7}
            className="w-full"
          />
        </div>
      </div>

      <div className="mt-auto">
        <button
          onClick={handleSubmitClick}
          className="w-full h-full py-3 bg-primary-main text-white text-sm font-semibold rounded-b-md"
          disabled={loading}
        >
          {loading ? <Loader /> : 'Submit'}
        </button>
      </div>
    </div>
  )
}

const ThankYouCard = ({ onClose }) => {
  return (
    <div className="popup-animation transform-origin_bottom-left absolute top-0 left-0 w-full h-full bottom-4 rounded-md bg-white flex flex-col">
      <button className="absolute top-2 right-2" onClick={onClose}>
        <CrossIcon />
      </button>

      <div className="px-6 pt-6 relative mt-auto text-center">
        <h2 className="font-semibold text-lg">Thank You!</h2>
        <p className="text-smaller font-medium">
          We will send you your request related updates via email
        </p>
      </div>

      <div className="mt-auto">
        <button
          onClick={onClose}
          className="w-full h-full py-3 bg-primary-main text-white text-sm font-semibold rounded-b-md"
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default UserFeedbackPopup
