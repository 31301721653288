import React, { useState, useEffect } from 'react'
import { Button, Tabs, Tab } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import BankingDetails from './BankingDetails'
import TaxDetails from './TaxDetails'
import OtherDetails from './OtherDetails'
import {
  createConfig,
  fetchConfigData,
  updateConfigData
} from 'thunks/configuration/actions'

const CONFIG_TYPE = 'invoiceSetting'

const InvoiceSettingsModal = ({ open, onClose }) => {
  const { t } = useTranslation()
  const [fetchedInvoiceSettings, setFetchedInvoiceSettings] = useState({
    loading: true,
    data: {}
  })

  useEffect(() => {
    fetchConfigData({ params: { type: CONFIG_TYPE } }, (res, err) => {
      setFetchedInvoiceSettings({
        loading: false,
        data: !err && res[0] ? res[0] : {}
      })
    })
  }, [])

  return (
    <CustomModal open={open} handleClose={onClose}>
      <CustomModalHeader
        heading={t('INVOICE_SETTINGS')}
        handleClose={onClose}
      />
      <ModalContent
        onClose={onClose}
        fetchedInvoiceSettings={fetchedInvoiceSettings.data}
      />
    </CustomModal>
  )
}

const ModalContent = ({ onClose, fetchedInvoiceSettings }) => {
  const { t } = useTranslation()
  const [invoiceSettings, setInvoiceSettings] = useState({
    bankDetail: [],
    taxDetail: [],
    invoiceEmail: '',
    invoiceAddress: {}
  })
  // const [bankingDetails, setBankingDetails] = useState([])
  // const [taxDetails, setTaxDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  // const [filterState, setFilterState] = useState(0)
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (fetchedInvoiceSettings._id) {
      setInvoiceSettings({
        bankDetail: fetchedInvoiceSettings.configuration.bankDetail,
        taxDetail: fetchedInvoiceSettings.configuration.taxDetail,
        invoiceEmail: fetchedInvoiceSettings.configuration.invoiceEmail || '',
        invoiceAddress:
          fetchedInvoiceSettings.configuration.invoiceAddress || {}
      })
    }
  }, [fetchedInvoiceSettings])

  const handleAddField = (fieldName, newField) => {
    setInvoiceSettings(prev => ({
      ...prev,
      [fieldName]: [...prev[fieldName], newField]
    }))
  }

  const handleRemoveField = (fieldName, index) => {
    setInvoiceSettings(prev => ({
      ...prev,
      [fieldName]: prev[fieldName].filter((item, idx) => idx !== index)
    }))
  }

  const handleSave = () => {
    setLoading(true)

    if (!fetchedInvoiceSettings._id) {
      createConfig(
        {
          body: {
            isActive: true,
            type: CONFIG_TYPE,
            configuration: invoiceSettings
          }
        },
        handleSaveCallback
      )
    } else {
      updateConfigData(
        {
          id: fetchedInvoiceSettings._id,
          body: {
            configuration: invoiceSettings
          }
        },
        handleSaveCallback
      )
    }

    // setLoading(true)
    // let data = {}
    // if (filterState === 0) {
    //   data = {
    //     bankDetail: bankingDetails
    //   }
    // } else {
    //   data = {
    //     taxDetail: taxDetails
    //   }
    // }
    // dispatch(whitelabel({ id: agencyOwner, body: data }, handleSaveCallback))
  }

  const handleSaveCallback = (res, err) => {
    setLoading(false)
    if (err) dispatch(fireErrorToaster(res))
    else dispatch(fireSuccessToaster(t('SUCCESS_UPDATED')))
  }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  return (
    <>
      <CustomModalBody>
        {/* <div className="mb-6">
          <div className="font-semibold text-sm mb-2 capitalize">
            {t('BANKING_DETAILS')}
          </div>
          <div className="mb-2">
            {bankDetails.map((item, index) => (
              <div
                key={index}
                className="flex items-center mb-1 show-on-hover-parent"
              >
                <div className="font-medium text-smaller w-28">
                  {item.label}:{' '}
                </div>
                <div className="ml-2 text-smaller">{item.value}</div>

                <CustomTooltip title={t('REMOVE')} placement="top">
                  <button className="ml-2 text-red-500 show-on-hover-child">
                    <CrossIcon />
                  </button>
                </CustomTooltip>
              </div>
            ))}
          </div>

          <Button variant="outlined" size="small">
            <PlusIcon />
            <span className="ml-1">{t('NEW')}</span>
          </Button>
        </div>

        <div>
          <div className="font-semibold text-sm mb-2 capitalize">
            {t('TAX_DETAILS')}
          </div>
          <div className="mb-2">
            {taxDetails.map((item, index) => (
              <div
                key={index}
                className="flex items-center mb-1 show-on-hover-parent"
              >
                <div className="font-medium text-smaller w-28">
                  {item.label}:{' '}
                </div>
                <div className="ml-2 text-smaller">{item.value}</div>

                <CustomTooltip title={t('REMOVE')} placement="top">
                  <button className="ml-2 text-red-500 show-on-hover-child">
                    <CrossIcon />
                  </button>
                </CustomTooltip>
              </div>
            ))}
          </div>

          <Button variant="outlined" size="small">
            <PlusIcon />
            <span className="ml-1">{t('NEW')}</span>
          </Button>
        </div> */}

        <div className="flex flex-col h-96">
          <div className="mb-4">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              {[t('BANKING_DETAILS'), t('TAX_DETAILS'), t('OTHER')].map(
                (item, index) => (
                  <Tab label={item} key={index} />
                )
              )}
            </Tabs>
          </div>

          {selectedTab === 0 ? (
            <BankingDetails
              bankingDetails={invoiceSettings.bankDetail}
              handleAddField={fieldData =>
                handleAddField('bankDetail', fieldData)
              }
              onRemove={fieldIndex =>
                handleRemoveField('bankDetail', fieldIndex)
              }
            />
          ) : selectedTab === 1 ? (
            <TaxDetails
              taxDetails={invoiceSettings.taxDetail}
              handleAddField={fieldData =>
                handleAddField('taxDetail', fieldData)
              }
              onRemove={fieldIndex =>
                handleRemoveField('taxDetail', fieldIndex)
              }
            />
          ) : (
            <OtherDetails
              invoiceEmail={invoiceSettings.invoiceEmail}
              invoiceAddress={invoiceSettings.invoiceAddress}
              handleChangeInvoiceEmail={value =>
                setInvoiceSettings(prev => ({ ...prev, invoiceEmail: value }))
              }
              handleChangeInvoiceAddress={(fieldName, value) =>
                setInvoiceSettings(prev => ({
                  ...prev,
                  invoiceAddress: { ...prev.invoiceAddress, [fieldName]: value }
                }))
              }
            />
          )}
          {/* <div>

            <div>
              <form onSubmit={handleAddField} className="mb-4">
                <div className="flex flex-wrap gap-2 mt-2">
                  <TextField
                    type="text"
                    placeholder={t('FIELD')}
                    className="border rounded border-custom-gray-main px-2 mr-4"
                    name="fieldName"
                    required
                    style={{
                      background:
                        theme.palette.type !== 'light'
                          ? theme.custom.darkMode.background
                          : ''
                    }}
                  />
                  <TextField
                    type="text"
                    placeholder={t('VALUE')}
                    className="border rounded border-custom-gray-main px-2 sm:flex-1 mr-4"
                    name="fieldValue"
                    required
                    style={{
                      background:
                        theme.palette.type !== 'light'
                          ? theme.custom.darkMode.background
                          : ''
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={
                      (filterState === 0 ? bankingDetails : taxDetails)
                        .length >= 5
                    }
                  >
                    {t('ADD')}
                  </Button>
                </div>
              </form>

              <div>
                {(filterState === 0 ? bankingDetails : taxDetails).map(
                  (item, index) => (
                    <div
                      key={index}
                      className="flex mb-4 gap-4 text-smaller font-normal"
                    >
                      <p className=" border rounded shadow px-2 py-1 w-1/4 mr-4 truncate">
                        {item.label}
                      </p>
                      <p className="border rounded shadow px-2 py-1 w-3/4 truncate">
                        {item.value}
                      </p>

                      <button
                        onClick={handleRemoveField}
                        data-index={index}
                        className="ml-2 text-primary-main"
                      >
                        <CloseIcon style={{ height: '16px', width: '16px' }} />
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </div> */}
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          loading={loading}
          variant="contained"
          onClick={handleSave}
        >
          {t('SAVE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default InvoiceSettingsModal
