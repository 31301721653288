import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'

import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { useTranslation } from 'react-i18next'
import { fetchAgencyStripeAccountId } from 'thunks/stripeAccount/actions'

export default function CheckoutLinkModal({ service, handleClose }) {
  const { t } = useTranslation()
  const [agencyStripeId, setAgencyStripeId] = useState('')
  const [linkGenerated, setLinkGenerated] = useState(null)
  const [copy, setCopy] = useState(false)

  useEffect(() => {
    if (service?.data?.serviceType === 'stripe') {
      fetchAgencyStripeAccountId(null, fetchStripeAgencyIdCallback)
    } else {
      setAgencyStripeId('acct_1Ig7xfSA168CHLxd')
      setLinkGenerated(true)
    }
  }, [])

  const fetchStripeAgencyIdCallback = (res, err) => {
    if (!err) {
      const stripeAccount = res.data.find(item => item.type === 'stripe')

      if (!stripeAccount) {
        setLinkGenerated(false)
        return
      }

      setAgencyStripeId(stripeAccount.accountId)
      setLinkGenerated(true)
    } else {
      setLinkGenerated(false)
    }
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.origin}/services/${service.data._id}/checkout`
    )
    setCopy(true)
  }

  return (
    <CustomModal
      open={service.open}
      handleClose={handleClose}
      size="extraSmall"
    >
      <CustomModalHeader
        heading={t('SHARE_SERVICE_URL')}
        handleClose={handleClose}
      />
      <CustomModalBody>
        {linkGenerated && agencyStripeId ? (
          <div>
            <p className="mb-4">{t('SHARE_WITH_CUSTOMERS')}</p>
            <Button
              onClick={handleCopyLink}
              className="w-full"
              variant="outlined"
            >
              {!copy && <CopyIcon fontSize="1rem" />}
              <span className="inline-block py-1 font-medium ml-2">
                {copy ? t('COPIED') : t('COPY_SERVICE_URL')}
              </span>
            </Button>
          </div>
        ) : linkGenerated !== null ? (
          <p>{t('ACCOUNT_NOT_FETCHED_TRY_AGAIN')}</p>
        ) : (
          <p className="flex justify-center items-center my-4 bg-white text-md text-gray-700">
            {t('Creating a unique checkout link. Please wait...')}
          </p>
        )}
      </CustomModalBody>
      <CustomModalFooter>
        <Button onClick={handleClose}>{t('CLOSE')}</Button>
      </CustomModalFooter>
    </CustomModal>
  )
}
