import React, { useCallback, useState } from 'react'
import { Calendar } from 'react-big-calendar'
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Skeleton } from '@material-ui/lab'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// import AddIcon from '@material-ui/icons/Add'
// import { CustomTooltip } from 'global/globalComponents'
import { colorTints } from 'utils/hexToRgb'
import { useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

// const DragAndDropCalendar = withDragAndDrop(Calendar)

const CalendarWrapper = ({
  handleNavigate,
  events,
  isLoading,
  localizer,
  openTaskSidePanel,
  openGoogleCalendarPopup
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const eventPropGetter = (event, start, end, isSelected) => ({
    style: {
      backgroundColor: event.taskSection?.color,
      fontSize: '12px',
      fontWeight: 500,
      padding: '1px 6px'
    }
  })

  const calendarStyle = date => {
    return {
      style: {
        backgroundColor:
          new Date(date).getDay() === 0 &&
          new Date().getMonth() === new Date(date).getMonth()
            ? colorTints(theme.palette.primary.main, 0.97)
            : '',
        fontSize: '12px'
      }
    }
  }

  const slotGroupPropGetter = useCallback(
    () => ({
      style: {
        // minHeight: 60,
        fontSize: '12px'
      }
    }),
    []
  )

  const handleEventClick = (event, syntheticEvent) => {
    if (!event.isGoogleCalendarEvent) {
      openTaskSidePanel(event)
    } else {
      openGoogleCalendarPopup({
        anchorEl: syntheticEvent.target,
        eventData: event
      })
    }
  }

  return (
    <div className="px-2 py-2 h-full">
      {isLoading ? (
        <CalendarViewLoading />
      ) : (
        <div
          className={clsx(
            'App__project-calendar-view h-full bg-white dark:bg-dark-main'
          )}
        >
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventPropGetter}
            onSelectEvent={handleEventClick}
            views={['month', 'day', 'week']}
            slotGroupPropGetter={slotGroupPropGetter}
            dayPropGetter={calendarStyle}
            onNavigate={handleNavigate}
          />
        </div>
      )}
    </div>
  )
}

export const CalendarViewLoading = () => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gridAutoRows: 'calc((100vh - 48px) / 5)',
        gridGap: 2
      }}
    >
      {Array.from({ length: 35 }).map(item => (
        <Skeleton height="100%" className="h-full" variant="rect" />
      ))}
    </div>
  )
}

export default CalendarWrapper
