import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import { COLOR_DANGER, COLOR_SUCCESS } from 'constants/colors'

const StyledBadge = ({
  color = 'primary',
  badgeContent,
  max,
  className,
  ...rest
}) => {
  const theme = useTheme()

  const colorCode = {
    primary: theme.palette.primary.main,
    success: COLOR_SUCCESS,
    danger: COLOR_DANGER
  }

  const classes = useStyles({ bgColor: colorCode[color] })

  return (
    <Badge
      classes={{ badge: classes.root }}
      badgeContent={badgeContent}
      max={max}
      className={className}
      {...rest}
    />
  )
}

const useStyles = makeStyles({
  root: ({ bgColor }) => ({
    backgroundColor: bgColor,
    color: 'white'
  })
})

export default StyledBadge
