import React from 'react'
import ContentEditable from 'react-contenteditable'
import ImageWrapper from '../ImageWrapper'

const About = ({
  data,
  classes,
  updateTemplateSection,
  updateSectionBlock,
  isEditable
}) => {
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionHeader}>
        <ContentEditable
          html={data.heading}
          onChange={e =>
            updateTemplateSection({ field: 'heading', value: e.target.value })
          }
          disabled={!isEditable}
          className={classes.sectionHeading}
        />
        <ContentEditable
          html={data.subHeading}
          onChange={e =>
            updateTemplateSection({
              field: 'subHeading',
              value: e.target.value
            })
          }
          disabled={!isEditable}
          className={classes.sectionSubHeading}
        />
      </div>

      <div>
        {data.blocks.map(block => (
          <div key={block.id} className={classes.aboutBlock}>
            <ImageWrapper
              src={block.image}
              alt=""
              isEditable={isEditable}
              onImageUpdate={imgUrl =>
                updateSectionBlock({
                  blockId: block.id,
                  field: 'image',
                  value: imgUrl
                })
              }
              className={classes.aboutBlockImage}
            />
            <div className={classes.aboutBlockText}>
              <ContentEditable
                html={block.heading}
                onChange={e =>
                  updateSectionBlock({
                    blockId: block.id,
                    field: 'heading',
                    value: e.target.value
                  })
                }
                disabled={!isEditable}
              />
              <ContentEditable
                html={block.description}
                onChange={e =>
                  updateSectionBlock({
                    blockId: block.id,
                    field: 'description',
                    value: e.target.value
                  })
                }
                disabled={!isEditable}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default About
