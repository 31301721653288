import React from 'react'
import { makeStyles, Popper } from '@material-ui/core'

const StatsPopper = ({ anchorEl, onClose, children }) => {
  const classes = useStyles()

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="right"
      disablePortal={false}
      className={classes.popper}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window'
        },
        arrow: {
          enabled: false
          // element: arrowRef
        }
      }}
    >
      {children}
    </Popper>
  )
}

const useStyles = makeStyles({
  popper: {
    zIndex: 2000,
    borderRadius: '10px'
  }
})

export default StatsPopper
