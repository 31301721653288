import React from 'react'
import ContentEditable from 'react-contenteditable'

const Testimonials = ({
  data,
  classes,
  isEditable,
  updateTemplateSection,
  updateSectionBlock
}) => {
  return (
    <div className={classes.sectionContainer}>
      {/* <div
        className={classes.sectionHeading}
        style={{ textAlign: 'center' }}
        dangerouslySetInnerHTML={{ __html: data.heading }}
      ></div> */}

      <ContentEditable
        html={data.heading}
        onChange={e =>
          updateTemplateSection({ field: 'heading', value: e.target.value })
        }
        disabled={!isEditable}
        className={classes.sectionHeading}
        style={{ textAlign: 'center' }}
      />

      <div className={classes.testimonialSlider}>
        {data.blocks.map(item => (
          <div key={item.id} className={classes.testimonialCard}>
            <div style={{ marginBottom: '1rem' }}>⭐⭐⭐⭐⭐</div>
            <ContentEditable
              html={item.description}
              onChange={e =>
                updateSectionBlock({
                  blockId: item.id,
                  field: 'description',
                  value: e.target.value
                })
              }
              disabled={!isEditable}
              className={classes.testimonialReview}
            />

            <ContentEditable
              html={item.heading}
              onChange={e =>
                updateSectionBlock({
                  blockId: item.id,
                  field: 'heading',
                  value: e.target.value
                })
              }
              disabled={!isEditable}
              className={classes.testimonialReviewAuthor}
            />
            {/* <div
              dangerouslySetInnerHTML={{ __html: item.description }}
              style={{ fontSize: 14, color: 'var(--color-gray-1)' }}
            ></div> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Testimonials
