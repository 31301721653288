export const ReportsForClient = {
  FETCH_PROJECT_STATUS_POST: 'FETCH_PROJECT_STATUS_POST'
}

const initialState = {
  fetchedFirstTime: false,
  data: {}
}

const reportsForClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReportsForClient.FETCH_PROJECT_STATUS_POST:
      if (!state.data.hasOwnProperty(`${action.payload.wsId}`)) {
        const Id = action.payload.wsId
        return {
          ...state,
          fetchedFirstTime: true,
          data: {
            ...state.data,
            [Id]: action.payload.data.filter(e => e.state === 'publish')
          }
        }
      } else {
        return state
      }

    default:
      return state
  }
}

export default reportsForClientReducer
