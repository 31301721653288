import axios from 'axios'
import { catchAsync } from 'utils'

/**
 * Create pixel configuration data
 * @param {Object} data.body isActive, configuration
 * @param {Function} callback function (optional)
 */
export const createConfig = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/configuration',
    method: 'POST',
    data: data.body
  })

  if (callback) callback(res.data)
})

/**
 * Fetch pixel configuration data
 * @param {Object} data.params type
 * @param {Function} callback function (optional)
 */
export const fetchConfigData = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/configuration/user',
    method: 'GET',
    params: data.params
  })

  // let widgetData = res.data.filter(item => item.type === 'widget')
  if (callback) callback(res.data)
})

/**
 * Update pixel configuration data
 * @param {String} data.id config id
 * @param {Object} data.body updated data
 * @param {Function} callback function (optional)
 */
export const updateConfigData = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/configuration/${data.id}`,
    method: 'PUT',
    data: data.body
  })

  if (callback) callback(res.data)
})

// export const deleteConfig = catchAsync(async () => {
//   await axios({
//     url: `/configuration/667d5dcbf5bd793767ae075a`,
//     method: 'DELETE'
//   })
// })

// deleteConfig()
