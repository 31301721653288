// import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { useEffect, useState } from 'react'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import {
  // Box,
  Button,
  TextField,
  useTheme
  // IconButton,
  // InputAdornment
} from '@material-ui/core'
// import { CustomTooltip, ToggleSwitch } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
// import { ReactComponent as EditIcon } from 'static/svg/edit-2.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as DotIcon } from 'static/svg/circle.svg'
import { ReactComponent as VerticalThreeDot } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ArrowRight } from '@material-ui/icons'
import templateStyles from './templateStyles'
import clsx from 'clsx'
import { LoadingButton } from 'global/globalComponents'
import Select from 'react-select'
import {
  createProjectTemplate,
  updateProjectTemplate
} from 'thunks/projectTemplate/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { PopupMenu } from 'global/globalComponents'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useTranslation } from 'react-i18next'
// import { id } from 'date-fns/locale'

const colorOptions = [
  '#8d84e8',
  '#f06a6a',
  '#aecf55',
  '#ec8d71',
  '#f1bd6c',
  '#f8df72',
  '#5da283',
  '#4ecbc4',
  '#4573d2',
  '#b36bd4',
  '#f9aaef',
  '#f26fb2',
  '#fc979a',
  '#6d6e6f',
  '#c7c4c4',
  '#9ee7e3'
]

const CreateTemplateModal = ({
  open,
  handleClose,
  updateDataHandler,
  tempData = {}
}) => {
  const { t } = useTranslation()
  const categoriesOptions = [
    { label: t('DESIGN'), value: 'design' },
    { label: t('MARKETING'), value: 'marketing' },
    { label: t('SOFTWARE_DEVELOPMENT'), value: 'software development' },
    { label: t('OTHER'), value: 'other' }
  ]
  const theme = useTheme()
  const classes = templateStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    title: tempData.title ?? '',
    description: tempData.description ?? '',
    category: tempData.category ?? '',
    sections: tempData.content?.section ?? []
  })
  const [sectionName, setSectionName] = useState('')
  const [task, setTask] = useState({
    taskName: '',
    index: ''
  })
  const [subTask, setSubTask] = useState({
    subTaskName: '',
    sectionIndex: '',
    taskIndex: ''
  })
  const [expanded, setExpanded] = useState({}) // for Sections
  const [expandedTask, setExpandedTask] = useState({})
  const [editable, setEditable] = useState({
    type: '',
    sectionId: '',
    taskId: '',
    subTaskId: '',
    text: ''
  })

  useEffect(() => {
    if (open && tempData?._id) {
      let selectedCategory = { label: 'Other', value: 'other' }
      if (tempData.category) {
        const tempCategory = categoriesOptions.find(
          el => el.value === tempData.category?.toLowerCase()
        )
        if (tempCategory) {
          selectedCategory = tempCategory
        }
      }
      setFormData({
        title: tempData.title ?? '',
        description: tempData.description ?? '',
        category: selectedCategory,
        sections: tempData.content?.section ?? []
      })
    }
  }, [tempData, open])

  const handleFormData = e => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSectionInput = e => {
    setSectionName(e.target.value)
  }

  const handleSectionData = e => {
    e.preventDefault()
    if (sectionName?.trim()) {
      const id = parseInt(Math.random() * 10000000000)
      setFormData(prev => ({
        ...prev,
        sections: [
          ...formData.sections,
          {
            id: id,
            label: sectionName,
            order: formData.sections.length + 1,
            color:
              colorOptions[Math.floor(Math.random() * colorOptions.length)],
            tasks: []
          }
        ]
      }))
      setSectionName('')
      setExpanded(prev => ({ ...prev, [id]: true }))
    } else {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_SECTION_NAME')))
    }
  }

  const handleTaskInput = (e, index) => {
    setTask({
      taskName: e.target.value,
      index: index
    })
  }

  const handleSubTaskInput = (e, sectionIndex, taskIndex) => {
    setSubTask({
      subTaskName: e.target.value,
      sectionIndex,
      taskIndex
    })
  }

  const handleEditInput = e => {
    setEditable(prev => ({
      ...prev,
      text: e.target.value
    }))
  }

  const handleSelectCategory = e => {
    setFormData(prev => ({ ...prev, category: e }))
  }

  // add tasks to sections
  const handleTaskData = e => {
    e.preventDefault()

    if (task.taskName?.trim()) {
      let newTaskId = parseInt(Math.random() * 10000000000)
      const updatedData = [...formData.sections]
      updatedData[task.index] = {
        ...formData.sections[task.index],
        tasks: [
          ...formData.sections[task.index].tasks,
          {
            taskId: newTaskId,
            sectionId: formData.sections[task.index].id,
            title: task.taskName,
            subTasks: []
          }
        ]
      }
      setFormData(prev => ({
        ...prev,
        sections: updatedData
      }))
      setExpandedTask(prev => ({ ...prev, [newTaskId]: true }))
      setTask('')
    } else {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_TASK_NAME')))
    }
  }

  // add subtasks to a task in a section
  const handleSubTaskData = e => {
    e.preventDefault()

    let tasks = formData.sections[subTask.sectionIndex].tasks

    let modifiedTasks = tasks.map((task, index) => {
      if (subTask.taskIndex === index)
        return {
          ...task,
          subTasks: [
            ...tasks[index].subTasks,
            {
              title: subTask.subTaskName,
              subTaskId: parseInt(Math.random() * 10000000000)
            }
          ]
        }
      else return task
    })

    if (subTask.subTaskName?.trim()) {
      const updatedData = [...formData.sections]
      updatedData[subTask.sectionIndex] = {
        ...formData.sections[subTask.sectionIndex],
        tasks: modifiedTasks
      }
      setFormData(prev => ({
        ...prev,
        sections: updatedData
      }))
      setTask('')
      setSubTask('')
    } else {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_TASK_NAME')))
    }
  }

  const handleListExpansion = id => {
    setExpanded(prev => ({
      ...prev,
      [id]: !prev[id]
    }))
  }

  const handleTaskExpansion = id => {
    setExpandedTask(prev => ({
      ...prev,
      [id]: !prev[id]
    }))
  }

  const EditSectionData = (e, sectionIndex, taskIndex, subTaskIndex) => {
    e.preventDefault()
    // if (!e.target.value) {
    //   return dispatch(fireErrorToaster('Please enter the valid name'))
    // }
    let updatedData = [...formData.sections]
    if (editable.type === 'section') {
      updatedData[sectionIndex] = {
        ...updatedData[sectionIndex],
        label: editable.text
      }
      setFormData(prev => ({
        ...prev,
        sections: updatedData
      }))
    } else if (editable.type === 'task') {
      // const updatedSectionTasks = [...updatedData[sectionIndex].tasks]
      updatedData[sectionIndex].tasks[taskIndex] = {
        ...updatedData[sectionIndex].tasks[taskIndex],
        title: editable.text
      }

      setFormData(prev => ({
        ...prev,
        sections: updatedData
      }))
    } else {
      //type ==='subTask'
      updatedData[sectionIndex].tasks[taskIndex].subTasks[subTaskIndex] = {
        ...updatedData[sectionIndex].tasks[taskIndex].subTasks[subTaskIndex],
        title: editable.text
      }

      setFormData(prev => ({
        ...prev,
        sections: updatedData
      }))
    }
    setEditable({
      type: '',
      sectionId: '',
      taskId: '',
      text: ''
    })
  }

  const handleSave = () => {
    if (!formData.title || !formData.description || !formData.category.value) {
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS'))
      )
    }
    setLoading(true)
    const data = {
      title: formData.title,
      category: formData.category.value,
      description: formData.description,
      type: 'private',
      content: { private: 'true', section: formData.sections }
    }
    if (tempData._id) {
      updateProjectTemplate({ id: tempData._id, data }, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster(t('TEMPLATE_UPDATE_SUCCESSFULLY')))
          updateDataHandler(res, { new: false })
          handleCloseModal()
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    } else {
      createProjectTemplate({ data }, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster(t('TEMPLATED_CREATED_SUCCESSFULLY')))
          updateDataHandler(res, { new: true })
          handleCloseModal()
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    }
  }

  const handleCloseModal = () => {
    setFormData({
      title: '',
      description: '',
      category: '',
      sections: []
    })
    setExpanded({})
    setEditable({ type: '', sectionId: '', taskId: '', text: '' })
    handleClose()
    setLoading(false)
  }

  const deleteSection = (type, sectionIndex, taskIndex, subTaskIndex) => {
    let updatedData = [...formData.sections]
    if (type === 'section') {
      updatedData.splice(sectionIndex, 1)
      setFormData(prev => ({
        ...prev,
        sections: updatedData
      }))
    } else if (type === 'task') {
      const updatedSectionTasks = [...updatedData[sectionIndex].tasks]
      updatedSectionTasks.splice(taskIndex, 1)
      updatedData[sectionIndex].tasks = updatedSectionTasks
      setFormData(prev => ({
        ...prev,
        sections: updatedData
      }))
    } else {
      const updatedTaskSubTasks = [
        ...updatedData[sectionIndex].tasks[taskIndex].subTasks
      ]
      updatedTaskSubTasks.splice(subTaskIndex, 1)
      updatedData[sectionIndex].tasks[taskIndex].subTasks = updatedTaskSubTasks
      setFormData(prev => ({
        ...prev,
        sections: updatedData
      }))
    }
  }

  return (
    <CustomModal
      size="medium"
      open={open}
      handleClose={handleCloseModal}
      className={classes.templateWrapper}
    >
      <CustomModalHeader
        heading={t('NEW_TEMPLATE')}
        handleClose={handleCloseModal}
      />
      <CustomModalBody>
        <div style={{ minHeight: '400px', paddingBottom: '16px' }}>
          <div className=" h-full">
            <div className="shadow-r">
              <div className="mt-2 flex flex-col space-y-1">
                <FormLabel required className="text-xs">
                  {t('TEMPLATE_NAME')}
                </FormLabel>
                <TextField
                  name="title"
                  placeholder={t('NAME')}
                  value={formData.title}
                  onChange={handleFormData}
                />
              </div>

              <div className="mt-4 flex">
                <div className="flex-1 flex flex-col space-y-1 mr-2">
                  <FormLabel required>{t('DESCRIPTION')}</FormLabel>
                  <TextField
                    placeholder={t('TEMPLATE_DESCRIPTION')}
                    name="description"
                    value={formData.description}
                    onChange={handleFormData}
                  />
                </div>

                <div className="ml-2 flex-1 flex flex-col space-y-1">
                  <FormLabel required>{t('CATEGORY')}</FormLabel>
                  <Select
                    menuPlacement="auto"
                    // isSearchable={true}
                    value={formData.category}
                    options={categoriesOptions}
                    onChange={handleSelectCategory}
                    styles={reactSelectCustomStyles(theme)}
                    maxMenuHeight="200px"
                  />
                </div>
              </div>

              <div className="pt-8">
                <div className="text-sm font-semibold text-primary-mid-dark p-2 bg-gray-50 border-b dark:bg-dark-main3 dark:text-dark-light2">
                  {t('CREATE_SECTIONS')}
                </div>
                <div>
                  {formData.sections.map((item, sectionIndex) => (
                    <div>
                      {editable.type === 'section' &&
                      editable.sectionId === item.id ? (
                        <form
                          onSubmit={e => EditSectionData(e, sectionIndex)}
                          className="relative flex items-center space-x-2  pl-8 border-b"
                        >
                          <input
                            type="text"
                            value={editable.text}
                            onChange={handleEditInput}
                            autoFocus
                            className="pl-4 bg-transparent p-2 w-full text-sm template"
                            // onBlur={e => EditSectionData(e, sectionIndex)}
                          />
                          <button
                            className="absolute right-0 text-primary-main text-smaller font-medium px-2 hover:bg-primary-light h-full"
                            onClick={e => EditSectionData(e, sectionIndex)}
                            type="submit"
                          >
                            {t('SAVE')}
                          </button>
                        </form>
                      ) : (
                        <div
                          className={`pl-4 p-2 flex items-between justify-between border-b ${classes.editIcon}`}
                        >
                          <div className="flex items-center">
                            <span
                              onClick={() => handleListExpansion(item.id)}
                              className={clsx(
                                {
                                  expanded: expanded[item.id]
                                },
                                classes.btn,
                                ' dark:text-dark-light'
                              )}
                            >
                              {/* <CustomTooltip
                                title={
                                  expanded[item.id]
                                    ? 'Hide Tasks'
                                    : 'View Tasks'
                                }
                                placement="top"
                              > */}
                              <ArrowRight
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  fill: 'currentColor',
                                  cursor: 'pointer'
                                }}
                              />
                              {/* </CustomTooltip> */}
                            </span>
                            <p className="text-sm capitalize font-semibold text-primary-dark dark:text-dark-light mr-2">
                              {item.label}
                            </p>
                          </div>

                          {/* <EditIcon
                            className={`text-primary-main p-1 hover:bg-primary-light rounded-md pen ${classes.penIcon}`}
                            width="1.5em"
                            height="1.5em"
                            onClick={() =>
                              setEditable((prev) => ({
                                ...prev,
                                sectionId: item.id,
                                type: 'section',
                                text: item.label
                              }))
                            }
                          /> */}
                          <PopupMenu
                            targetComp={
                              <VerticalThreeDot
                                className={` text-primary-main dark:text-dark-light p-1 hover:bg-primary-light rounded-md pen ${classes.penIcon} hover:cursor-pointer dark:hover:bg-dark-main`}
                                width="2em"
                                height="1.5em"
                              />
                            }
                            menuItems={[
                              {
                                label: t('EDIT'),
                                method: () =>
                                  setEditable(prev => ({
                                    ...prev,
                                    sectionId: item.id,
                                    type: 'section',
                                    text: item.label
                                  })),
                                icon: (
                                  <EditIcon className="text-primary-main dark:text-dark-light" />
                                )
                              },
                              {
                                label: t('DELETE'),
                                method: () =>
                                  deleteSection('section', sectionIndex),
                                icon: <DeleteIcon />,
                                isDanger: true
                              }
                            ]}
                          />
                        </div>
                      )}

                      {expanded[item.id] && (
                        <div>
                          {item.tasks?.map((task, taskIndex) => (
                            <>
                              {editable.type === 'task' &&
                              editable.taskId === task.taskId ? (
                                <form
                                  onSubmit={e =>
                                    EditSectionData(e, sectionIndex, taskIndex)
                                  }
                                  className="relative flex items-center pl-8 border-b"
                                >
                                  <input
                                    type="text"
                                    value={editable.text}
                                    onChange={handleEditInput}
                                    autoFocus
                                    className="pl-4 bg-transparent p-2 w-full text-sm template"
                                    // onBlur={e =>
                                    //   EditSectionData(
                                    //     e,
                                    //     sectionIndex,
                                    //     taskIndex
                                    //   )
                                    // }
                                  />
                                  <button
                                    className="absolute right-0 text-primary-main text-smaller font-medium px-2 hover:bg-primary-light h-full"
                                    onClick={e =>
                                      EditSectionData(
                                        e,
                                        sectionIndex,
                                        taskIndex
                                      )
                                    }
                                    type="submit"
                                  >
                                    {t('SAVE')}
                                  </button>
                                </form>
                              ) : (
                                <div
                                  className={` pl-10 p-2 flex items-center justify-between border-b ${classes.editIcon}`}
                                >
                                  <div className="flex items-center">
                                    <span
                                      onClick={() =>
                                        handleTaskExpansion(task.taskId)
                                      }
                                      className={clsx(
                                        {
                                          expanded: expandedTask[task.taskId]
                                        },
                                        classes.btn,
                                        ' dark:text-dark-light'
                                      )}
                                    >
                                      {/* <CustomTooltip
                                title={
                                  expanded[item.id]
                                    ? 'Hide Tasks'
                                    : 'View Tasks'
                                }
                                placement="top"
                              > */}
                                      <ArrowRight
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          fill: 'currentColor',
                                          cursor: 'pointer'
                                        }}
                                      />
                                      {/* </CustomTooltip> */}
                                    </span>
                                    <p className="text-sm capitalize font-semibold text-primary-dark  dark:text-dark-light mr-2">
                                      {task.title}
                                    </p>
                                  </div>

                                  {/* <EditIcon
                                    className={`text-primary-main p-1 hover:bg-primary-light rounded-md pen ${classes.penIcon}`}
                                    width="1.5em"
                                    height="1.5em"
                                    onClick={() =>
                                      setEditable((prev) => ({
                                        sectionId: item.id,
                                        taskId: task.taskId,
                                        type: 'task',
                                        text: task.title
                                      }))
                                    }
                                  /> */}
                                  <PopupMenu
                                    targetComp={
                                      <VerticalThreeDot
                                        className={clsx(
                                          `text-primary-main dark:text-dark-light p-1 hover:bg-primary-light rounded-md pen hover:cursor-pointer dark:hover:bg-dark-main`,
                                          classes.penIcon
                                        )}
                                        width="2em"
                                        height="1.5em"
                                      />
                                    }
                                    menuItems={[
                                      {
                                        label: t('EDIT'),
                                        method: () =>
                                          setEditable(prev => ({
                                            sectionId: item.id,
                                            taskId: task.taskId,
                                            type: 'task',
                                            text: task.title
                                          })),
                                        icon: (
                                          <EditIcon className="text-primary-main dark:text-dark-light" />
                                        )
                                      },
                                      {
                                        label: t('DELETE'),
                                        method: () =>
                                          deleteSection(
                                            'task',
                                            sectionIndex,
                                            taskIndex
                                          ),
                                        icon: <DeleteIcon />,
                                        isDanger: true
                                      }
                                    ]}
                                  />
                                </div>
                              )}

                              {/* subtasks */}
                              {expandedTask[task.taskId] && (
                                <div className="pl-8">
                                  {task.subTasks?.map(
                                    (subTask, subTaskIndex) => (
                                      <>
                                        {editable.type === 'subTask' &&
                                        editable.subTaskId ===
                                          subTask.subTaskId ? (
                                          <form
                                            onSubmit={e =>
                                              EditSectionData(
                                                e,
                                                sectionIndex,
                                                taskIndex,
                                                subTaskIndex
                                              )
                                            }
                                            className="relative flex items-center pl-8 border-b"
                                          >
                                            <input
                                              type="text"
                                              value={editable.text}
                                              onChange={handleEditInput}
                                              autoFocus
                                              className="pl-4 bg-transparent p-2 w-full text-sm template"
                                              // onBlur={e =>
                                              //   EditSectionData(
                                              //     e,
                                              //     sectionIndex,
                                              //     taskIndex
                                              //   )
                                              // }
                                            />
                                            <button
                                              className="absolute right-0 text-primary-main text-smaller font-medium px-2 hover:bg-primary-light h-full"
                                              onClick={e =>
                                                EditSectionData(
                                                  e,
                                                  sectionIndex,
                                                  taskIndex,
                                                  subTaskIndex
                                                )
                                              }
                                              type="submit"
                                            >
                                              {t('SAVE')}
                                            </button>
                                          </form>
                                        ) : (
                                          <div
                                            className={` pl-10 p-2 flex items-center justify-between border-b ${classes.editIcon}`}
                                          >
                                            <div className="flex items-center">
                                              <DotIcon className="h-1 w-1 mr-2 dark:text-dark-light" />
                                              <p className="text-sm text-primary-dark-2 dark:text-dark-light mr-2">
                                                {subTask.title}
                                              </p>
                                            </div>

                                            <PopupMenu
                                              targetComp={
                                                <VerticalThreeDot
                                                  className={clsx(
                                                    `text-primary-main dark:text-dark-light p-1 hover:bg-primary-light rounded-md pen hover:cursor-pointer dark:hover:bg-dark-main`,
                                                    classes.penIcon
                                                  )}
                                                  width="2em"
                                                  height="1.5em"
                                                />
                                              }
                                              menuItems={[
                                                {
                                                  label: t('EDIT'),
                                                  method: () =>
                                                    setEditable(prev => ({
                                                      sectionId: item.id,
                                                      taskId: task.taskId,
                                                      subTaskId:
                                                        subTask.subTaskId,
                                                      type: 'subTask',
                                                      text: subTask.title
                                                    })),
                                                  icon: (
                                                    <EditIcon className="text-primary-main dark:text-dark-light" />
                                                  )
                                                },
                                                {
                                                  label: t('DELETE'),
                                                  method: () =>
                                                    deleteSection(
                                                      'subTask',
                                                      sectionIndex,
                                                      taskIndex,
                                                      subTaskIndex
                                                    ),
                                                  icon: <DeleteIcon />,
                                                  isDanger: true
                                                }
                                              ]}
                                            />
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                  <form
                                    onSubmit={handleSubTaskData}
                                    className="relative flex items-center border-b pl-8"
                                  >
                                    <AddIcon
                                      className="absolute text-primary-main dark:text-dark-light2 ml-1"
                                      width="0.8em"
                                      height="0.8em"
                                    />
                                    <input
                                      type="text"
                                      placeholder={t('ADD_NEW_SUBTASK')}
                                      value={
                                        subTask.sectionIndex === sectionIndex &&
                                        subTask.taskIndex === taskIndex
                                          ? subTask.subTaskName
                                          : ''
                                      }
                                      onChange={e =>
                                        handleSubTaskInput(
                                          e,
                                          sectionIndex,
                                          taskIndex
                                        )
                                      }
                                      autoFocus
                                      className="pl-5 bg-transparent p-2 w-full text-sm template"
                                      // onBlur={handleTaskData}
                                    />
                                    {subTask.subTaskName && (
                                      <button
                                        className="absolute right-0 text-primary-main text-smaller font-medium px-2 hover:bg-primary-light h-full"
                                        onClick={handleSubTaskData}
                                        type="submit"
                                      >
                                        {t('SAVE')}
                                      </button>
                                    )}
                                  </form>
                                </div>
                              )}
                            </>
                          ))}
                          <form
                            onSubmit={handleTaskData}
                            className="relative flex items-center pl-8 border-b"
                          >
                            <AddIcon
                              className="absolute text-primary-main dark:text-dark-light2 ml-1"
                              width="0.8em"
                              height="0.8em"
                            />
                            <input
                              type="text"
                              placeholder={t('ADD_NEW_TASK')}
                              value={
                                task.index === sectionIndex ? task.taskName : ''
                              }
                              onChange={e => handleTaskInput(e, sectionIndex)}
                              autoFocus
                              className="pl-5 bg-transparent p-2 w-full text-sm template"
                              // onBlur={handleTaskData}
                            />
                            {task.taskName && (
                              <button
                                className="absolute right-0 text-primary-main text-smaller font-medium px-2 hover:bg-primary-light h-full"
                                onClick={handleTaskData}
                                type="submit"
                              >
                                {t('SAVE')}
                              </button>
                            )}
                          </form>
                        </div>
                      )}
                    </div>
                  ))}
                  <form
                    onSubmit={handleSectionData}
                    className="relative flex items-center pl-4"
                  >
                    <AddIcon
                      className="absolute text-primary-main dark:text-dark-light2 ml-1"
                      width="0.8em"
                      height="0.8em"
                    />
                    <input
                      type="text"
                      placeholder={t('ADD_NEW_SECTION')}
                      value={sectionName}
                      className="pl-5 template bg-transparent p-2 w-full text-sm"
                      onChange={handleSectionInput}
                      // onBlur={handleSectionData}
                    />
                    {sectionName && (
                      <button
                        className="absolute right-0 text-primary-main text-smaller font-medium px-2 hover:bg-primary-light h-full dark:text-dark-light2"
                        onClick={handleSectionData}
                        type="submit"
                      >
                        {t('SAVE')}
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex justify-center items-center h-full w-full bg-pink-100 text-center">
            <CreateTemplateRightView />
          </div> */}
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={handleCloseModal}>
          {t('CANCEL')}
        </Button>
        <LoadingButton size="large" onClick={handleSave} loading={loading}>
          {tempData._id ? t('UPDATE') : t('SAVE')}
        </LoadingButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default CreateTemplateModal
