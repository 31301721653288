import clsx from 'clsx'

const CustomModalBody = ({
  children,
  className,
  overflow = 'auto',
  style = {}
}) => (
  <div
    className={clsx('px-6', className, 'dark:text-dark-light')}
    style={{ overflow, ...style }}
  >
    {children}
  </div>
)
export default CustomModalBody
