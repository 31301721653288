import axios from 'axios'
import { catchAsync, catchAsyncDispatch } from 'utils'
import { LOGS } from './reducer'

/**
 * Delete comments
 * @param {String} data.id comment id
 * @param {Function} callback callback function
 */
export const deleteComment = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/comments/${data.id}`
  })

  if (callback) callback(res.data)
})

/**
 * fetch comments by task id
 * @param {String} data.id task id
 * @param {Function} callback callback function
 */
export const findCommentsByTaskId = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/comments?task=${data.id}`
    })

    if (callback) callback(res.data)
    dispatch({ type: LOGS.ALL, payload: res.data })
  }, callback)
}

/**
 * fetch comments by subtask id
 * @param {String} data.id subtask id
 * @param {Function} callback callback function
 */
export const findCommentsBySubtaskId = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/comments?subTask=${data.id}`
    })

    if (callback) callback(res.data)
    dispatch({ type: LOGS.SUBTASK_LOGS, payload: res.data })
  }, callback)
}

/**
 * fetch comments by approval id
 * @param {String} data.id approval id
 * @param {Function} callback callback function
 */
export const findCommentsByApprovalId = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/comments?approval=${data.id}&approvalStage=${data.stageNumber}`
  })

  if (callback) callback(res.data)
})

// /**
//  * fetch logs by workspace id
//  * @param {String} id workspace id
//  * @param {Function} callback callback function
//  */
// export const fetchLogsByWorkspaceId = (id, page, limit, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/comments/workspace/${id}?page=${page}&limit=${limit}`
//       })
//       const sortedData = res.data.sort(
//         (a, b) =>
//           new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
//       )
//       if (callback) callback(res.data, false)
//       dispatch({
//         type: LOGS.WORKSPACE_LOGS,
//         payload: { _id: id, data: sortedData }
//       })
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//     }
//   }
// }

/**
 * Post a log
 * @param {Object} data logs data
 * @param {Function} callback callback function
 */
export const postTaskLog = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/comments',
      data: data.data
    })
    if (callback) callback(res.data, false)
    if (res.data.category === 'task') {
      dispatch({ type: LOGS.POSTED_LOG, payload: res.data })
    } else if (res.data.category === 'subtask') {
      dispatch({ type: LOGS.UPDATE_SUBTASK_LOGS, payload: res.data })
    } else {
      dispatch({ type: LOGS.UPDATE_WORKSPACE_LOGS, payload: res.data })
    }
  }, callback)
}

/**
 * Update log (NOTE: comment is a log)
 * @param {String} data.id comment id
 * @param {String} data.elmFor task/subtask
 * @param {Object} data.data update
 * @param {Function} callback callback function
 */
export const updateComment = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/comments/${data.id}`,
      data: data.data
    })

    dispatch({
      type:
        data.elmFor === 'task'
          ? LOGS.UPDATE_TASK_LOG_BY_ID
          : LOGS.UPDATE_SUBTASK_LOG_BY_ID,
      payload: res.data
    })

    if (callback) callback(res.data)
  }, callback)
}
/**
 * Like / Unlike comment
 * @param {Object} data.comment comment object
 * @param {String} data.action like/unlike
 * @param {String} data.elmFor task/subtask
 * @param {Function} callback callback function
 */
export const toggleLikeComment = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { me } = getState()
    const likedBy = data.comment.likedBy || []

    dispatch({
      type:
        data.elmFor === 'task'
          ? LOGS.UPDATE_TASK_LOG_BY_ID
          : LOGS.UPDATE_SUBTASK_LOG_BY_ID,
      payload: {
        ...data.comment,
        likedBy:
          data.action === 'like'
            ? [...likedBy, me.data._id]
            : likedBy.filter(item => item !== me.data._id)
      }
    })
    const res = await axios({
      method: 'PUT',
      url: `/comments/like/${data.comment._id}?type=${data.action}`
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * get all mentions (task / subtask)
 * @param {Object|null} data
 * @param {Function} callback callback function
 */
export const getTaskSubtaskUserMentions = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { me } = getState()
    const res = await axios({
      method: 'GET',
      url: '/comments/mentions'
    })
    const commentMentions = res.data.comment
      .filter(item => item.task !== null && item.subTask !== null)
      .map(item => ({
        _id: item._id,
        description: item.description,
        user: item.user,
        createdAt: item.createdAt,
        type: 'comment',
        taskData: item.task || item.subTask
      }))

    const descriptionMentions = res.data.task.map(item => {
      const mentionObj = item.description.ops.find(op => {
        return op.insert.mention?.id === me.data._id
      })

      if (!mentionObj) return {}

      return {
        _id: item._id,
        description: item.description,
        user: JSON.parse(mentionObj.insert.mention.createdBy),
        createdAt: mentionObj.insert.mention.createdAt,
        type: item.task ? 'subtask' : 'task',
        taskData: item
      }
    })

    const mentionsData = commentMentions.concat(descriptionMentions)
    if (callback) callback(mentionsData)
    dispatch({ type: LOGS.SET_MENTIONS_COUNT, payload: mentionsData.length })
  }, callback)
}

/**
 * Mark mention as read
 * @param {String} data.query ?taskId="..." or ?commentId="..."
 * @param {Function} callback callback function
 */
export const markMentionAsRead = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/comments/mentions/${data.query}`
    })

    if (callback) callback(res.data)
    dispatch({ type: LOGS.UPDATE_MENTIONS_COUNT, payload: -1 })
  }, callback)
}

/**
 * Remove logs
 * @param {String} target task/subtask/workspace
 */
export const removeLogs = target => {
  return { type: LOGS.REMOVE_LOGS, payload: { target } }
}

/**
 * Update mentions count
 * @param {Number} value
 */
export const updateMentionsCount = value => {
  return { type: LOGS.UPDATE_MENTIONS_COUNT, payload: value }
}

export const removeComment = commentData => ({
  type: LOGS.DELETE_COMMENT,
  payload: commentData
})
