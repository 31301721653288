import axios from 'axios'
import { catchAsync } from 'utils'
// import { getAuthToken } from 'utils'
import { getErrorMessages } from 'utils/errorMessages'
const errorMessages = getErrorMessages()

/**
 * Create Project Template
 * @param {Object} data.data
 * @param {Function} callback function.
 */
export const createProjectTemplate = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: 'workspacetemplate',
    data: data.data
  })

  if (callback) callback(res.data)
})

/**
 * Fetch Project Template
 * @param {Object|null} data
 * @param {Function} callback function.
 */
export const fetchAllProjectTemplates = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: 'workspacetemplate/agency'
  })

  if (callback) callback(res.data)
})

// /**
//  * Fetch Project Template
//  * @param {Function} callback function.
//  */
// export const fetchPublicProjectTemplates = async callback => {
//   try {
//     const res = await axios({
//       method: 'GET',
//       url: 'workspacetemplate/public'
//     })
//     if (callback) callback(res.data)
//   } catch (err) {
//     if (callback)
//       callback(
//         err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//         true
//       )
//   }
// }

/**
 * Update Project Template
 * @param {String} data.id template id
 * @param {Object} data.data update
 * @param {Function} callback function.
 */
export const updateProjectTemplate = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `workspacetemplate/${data.id}`,
    data: data.data
  })

  if (callback) callback(res.data)
})

/**
 * Delete Project Template
 * @param {String} data.id template id
 * @param {Function} callback function.
 */
export const deleteProjectTemplate = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `workspacetemplate/${data.id}`
  })

  if (callback) callback(res.data)
})
