import { makeStyles, createStyles } from '@material-ui/core'

const sidebarStyles = makeStyles(theme =>
  createStyles({
    sidebar: {
      transform: 'translateX(0px) !important',
      // color: '#242236',
      color: 'rgba(34, 34, 34, 0.7)',
      zIndex: theme.zIndex.drawer + 10,
      minWidth: theme.custom.sidebar.width,
      maxWidth: theme.custom.sidebar.width,
      // transition: 'all 240ms cubic-bezier(0.4, 0, 0.2, 1)'
      transition: theme.transitions.create(['all'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen
      }),
      '&.expanded': {
        transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeInOut
        })
      }
    },

    iconItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '34px',
      minWidth: '34px',
      height: '34px',
      padding: '4px',
      textAlign: 'center',
      marginLeft: '1px'
    },

    label: {
      fontSize: '0.82rem'
    },
    arrow: {
      color: theme.palette.common.white
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      padding: '8px 12px',
      fontSize: '13px',
      fontWeight: '600',
      color: '#222',
      boxShadow: '0 4px 14px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05)',
      borderRadius: '6px',
      lineHeight: '20px'
    }
  })
)

export default sidebarStyles
