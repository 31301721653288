import React, {
  forwardRef,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle
} from 'react'
import { TextField, useTheme } from '@material-ui/core'
import DatePicker from 'react-datepicker'
import Close from '@material-ui/icons/Close'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useTranslation } from 'react-i18next'

const InvoiceFromTo = forwardRef(
  ({ invoiceData, isEditable, publicView }, ref) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [selectedClient, setSelectedClient] = useState('')
    const [agencyData, setAgencyData] = useState({})
    const [clientData, setClientData] = useState({})
    const [dates, setDates] = useState({
      issuedDate: null,
      dueDate: null
    })
    const userClients = useSelector(state => state.userClients.data)
    const clientOptions = useMemo(() => {
      return userClients.map(client => ({
        label: client.name,
        value: client._id,
        email: client.email
      }))
    }, [userClients])

    useEffect(() => {
      if (invoiceData._id) {
        setAgencyData({
          ...invoiceData.agency,
          agencyCompanyName: invoiceData.agency.agencyCompanyName || '',
          agencyAddress: invoiceData.agency.agencyAddress || ''
        })

        setClientData(invoiceData.client)
        setDates({
          issuedDate: invoiceData.issuedDate,
          dueDate: invoiceData.dueDate
        })
      }
    }, [invoiceData])

    useEffect(() => {
      if (!invoiceData.client?.clientId) return
      setSelectedClient({
        email: invoiceData.client.clientEmail,
        label: invoiceData.client.clientName,
        value: invoiceData.client.clientId
      })
    }, [invoiceData.client])

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          agency: agencyData,
          client: {
            ...clientData,
            clientEmail: clientData.clientEmail,
            clientName: selectedClient.label,
            clientId: selectedClient.value
          },
          issuedDate: dates.issuedDate
            ? new Date(dates.issuedDate).toISOString()
            : null,
          dueDate: dates.dueDate ? new Date(dates.dueDate).toISOString() : null
        }
      }
    }))

    const addNewRecipient = () => {
      setClientData(prev => ({
        ...prev,
        client_cc_email: [...prev.client_cc_email, '']
      }))
    }

    const handleRecipientChange = e => {
      setClientData(prev => ({
        ...prev,
        client_cc_email: prev.client_cc_email.map((item, index) => {
          if (index === parseInt(e.target.dataset.index)) {
            item = e.target.value
          }
          return item
        })
      }))
    }

    const removeRecipientField = index => {
      setClientData(prev => ({
        ...prev,
        client_cc_email: prev.client_cc_email.filter(
          (item, idx) => idx !== index
        )
      }))
    }

    const handleAgencyFieldChange = e => {
      setAgencyData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }))
    }

    const handleClientFieldChange = e => {
      setClientData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleSelectClient = newVal => {
      setClientData(prev => ({ ...prev, clientEmail: newVal.email }))
      setSelectedClient(newVal)
    }

    return (
      <div className="mb-8">
        <div className="flex space-x-6 py-8">
          {/* ------ From ------- */}
          <div className="flex-1">
            <h6 className="mb-2 text-sm font-medium text-gray-500 dark:text-dark-light">
              {t('FROM')}
            </h6>
            <div>
              <div className="mb-2">
                {isEditable ? (
                  <TextField
                    value={agencyData.agencyName}
                    onChange={handleAgencyFieldChange}
                    placeholder={t('YOUR_NAME')}
                    className="w-full"
                    name="agencyName"
                  />
                ) : (
                  <div className="text-sm">{agencyData.agencyName}</div>
                )}
              </div>

              <div className="mb-2">
                {isEditable ? (
                  <TextField
                    value={agencyData.agencyCompanyName}
                    onChange={handleAgencyFieldChange}
                    placeholder={t('YOUR_ORGANIZATION')}
                    className="w-full"
                    name="agencyCompanyName"
                  />
                ) : (
                  <div className="text-sm">{agencyData.agencyCompanyName}</div>
                )}
              </div>

              <div className="mb-2">
                {isEditable ? (
                  <TextField
                    value={agencyData.agencyAddress}
                    onChange={handleAgencyFieldChange}
                    placeholder={t('ADDRESS')}
                    className="w-full"
                    name="agencyAddress"
                    multiline={true}
                  />
                ) : (
                  <div className="text-xs text-gray-500">
                    {agencyData.agencyAddress}
                  </div>
                )}
              </div>

              {!publicView && (
                <div className="mb-2 text-xs text-primary-main">
                  {agencyData.agencyEmail}
                </div>
              )}
            </div>
          </div>

          {/* ------ To ------- */}
          <div className="flex-1">
            <h6 className="mb-2 text-sm font-medium text-gray-500 dark:text-dark-light">
              {t('TO')}
            </h6>
            <div>
              <div className="mb-2">
                {isEditable ? (
                  <Select
                    onChange={handleSelectClient}
                    placeholder={t('SELECT_CLIENT')}
                    value={selectedClient}
                    options={clientOptions}
                    styles={reactSelectCustomStyles(theme)}
                  />
                ) : (
                  <div className="text-sm">{clientData.clientName}</div>
                )}
              </div>

              <div className="mb-2">
                {isEditable ? (
                  <TextField
                    value={clientData.clientCompanyName}
                    onChange={handleClientFieldChange}
                    placeholder={t('CLIENT_ORGANIZATION')}
                    className="w-full"
                    name="clientCompanyName"
                  />
                ) : (
                  <div className="text-sm">{clientData.clientCompanyName}</div>
                )}
              </div>

              <div className="mb-2">
                {isEditable ? (
                  <TextField
                    value={clientData.clientAddress}
                    onChange={handleClientFieldChange}
                    placeholder={t('CLIENT_ADDRESS')}
                    className="w-full"
                    name="clientAddress"
                    multiline={true}
                  />
                ) : (
                  <div className="text-xs text-gray-500">
                    {clientData.clientAddress}
                  </div>
                )}
              </div>

              <div className="text-primary-main mb-2">
                {selectedClient.value && (
                  <div className="mb-2">
                    {isEditable ? (
                      <TextField
                        value={selectedClient.email}
                        onChange={handleClientFieldChange}
                        placeholder={t('CLIENT_EMAIL')}
                        className="w-full"
                        name="clientEmail"
                      />
                    ) : (
                      <div className="text-xs">{clientData.clientEmail}</div>
                    )}
                  </div>
                )}

                <div>
                  {isEditable ? (
                    <>
                      {clientData.client_cc_email?.map((item, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between mb-2"
                        >
                          <TextField
                            type="email"
                            value={item}
                            onChange={handleRecipientChange}
                            inputProps={{ 'data-index': index }}
                            className="w-full"
                            autoFocus
                            placeholder="client-cc@gmail.com"
                          />

                          <button
                            onClick={() => removeRecipientField(index)}
                            className="ml-2"
                          >
                            <Close fontSize="small" />
                          </button>
                        </div>
                      ))}
                      <div
                        role="button"
                        onClick={addNewRecipient}
                        className="text-xs font-semibold dark:text-dark-light"
                      >
                        + {t('ADD_RECIPIENT')}
                      </div>
                    </>
                  ) : (
                    <>
                      {clientData.client_cc_email?.map(item => (
                        <div className="text-xs mb-1">{item}</div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex sm:space-x-6">
          <div className="flex-1 mb-2 mt-auto">
            <h6 className="uppercase text-xs font-semibold tracking-wide text-gray-500 dark:text-dark-light">
              {t('ISSUED_ON')}
            </h6>

            {isEditable ? (
              <DatePicker
                selected={
                  dates.issuedDate ? new Date(dates.issuedDate) : new Date()
                }
                customInput={
                  dates.issuedDate ? (
                    <input
                      style={{
                        width: 180,
                        padding: '4px 0'
                      }}
                    />
                  ) : (
                    <CalendarTodayOutlinedIcon
                      fontSize="small"
                      className="cursor-pointer text-custom-gray-main "
                    />
                  )
                }
                onChange={date =>
                  setDates(prev => ({ ...prev, issuedDate: date }))
                }
                dateFormat="MMM d, yyyy"
                className="text-sm dark:bg-dark-main dark:rounded"
              />
            ) : (
              <div className="text-sm">
                {new Date(invoiceData.issuedDate).toLocaleDateString(
                  undefined,
                  {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  }
                )}
              </div>
            )}
          </div>

          <div className="flex-1">
            <div className="mb-2">
              <h6 className="uppercase text-xs font-semibold tracking-wide text-gray-500 dark:text-dark-light">
                {t('DUE_ON')}
              </h6>
              {isEditable ? (
                <DatePicker
                  selected={dates.dueDate ? new Date(dates.dueDate) : null}
                  customInput={
                    dates.dueDate ? (
                      <input
                        style={{
                          width: 180,
                          padding: '4px 0'
                        }}
                      />
                    ) : (
                      <CalendarTodayOutlinedIcon
                        fontSize="small"
                        className="cursor-pointer text-custom-gray-main "
                      />
                    )
                  }
                  onChange={date =>
                    setDates(prev => ({ ...prev, dueDate: date }))
                  }
                  dateFormat="MMM d, yyyy"
                  className="text-sm dark:bg-dark-main"
                />
              ) : (
                <div className="text-sm">
                  {invoiceData.dueDate &&
                    new Date(invoiceData.dueDate).toLocaleDateString(
                      undefined,
                      {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      }
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

// const CustomMenu = props => {
//   const { t } = useTranslation()
//   return (
//     <components.MenuList {...props}>
//       <button
//         onClick={props.selectProps.selectProps.inviteClient}
//         className="text-sm text-primary-main flex items-center w-full py-2 px-4"
//       >
//         <AddIcon className="mr-1" />
//         <span>{t('ADD_NEW_CLIENT')}</span>
//       </button>
//       {props.children}
//     </components.MenuList>
//   )
// }

// const checkEquality = (prevProps, nextProps) => {
//   return isEqual(prevProps.fromToData, nextProps.fromToData)
// }

// const getStringAddress = meData => {
//   let addressObj =
//     meData.role === userRoles.USER_AGENCY
//       ? meData.profile?.address
//       : meData.team?.profile?.address

//   if (!addressObj) return ''

//   const { line1, city, state, country, zipCode } = addressObj

//   return `${line1 ? `${line1}, ` : ''}${city ? `${city}, ` : ''}${
//     state ? `${state}, ` : ''
//   }${country.label ? `${country.label}, ` : ''}${zipCode ? zipCode : ''}`
// }

// const getBrandObj = meData => {
//   return meData.role === userRoles.USER_AGENCY
//     ? meData.profile?.brand
//     : meData.team?.profile?.brand
// }

export default InvoiceFromTo
