import React, { useMemo, useState } from 'react'
import { Button, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import Select from 'react-select'
import { updateClient } from 'thunks/addClient/actions'
import { LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import Members from './Members'
import { getRoleBadge, userRoles } from 'utils'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import AddNewTeammatePanel from 'components/Teammates/AddNewTeammatePanel'

const TeamMembers = ({ clientInfo, setClientInfo, elmFor = 'client' }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const clientData = useSelector(state => state.userClients.currentClient)
  const allAgencyTeammates = useSelector(state => state.userTeammates.data)
  const teammates = useSelector(state => state.userTeammates.data)
  const representative = useMemo(() => {
    let mates = [
      ...teammates,
      {
        _id: meData._id,
        name: meData.name,
        profileImage: meData.profileImage,
        email: meData.email
      }
    ]
    if (meData.role !== userRoles.USER_AGENCY) mates.push(meData.team)
    return mates.find(mate => mate._id === clientData?.agencyRepresentative)
  }, [clientData?.agencyRepresentative, teammates])

  const [teammateSelectOptions, setTeammateSelectOptions] = useState([])
  const [
    updateRepresentativeDialogData,
    setUpdateRepresentativeDialogData
  ] = useState({
    isNew: false,
    open: false
  })
  const [selectedTeammate, setSelectedTeammate] = useState(representative)
  const [savingChanges, setSavingChanges] = useState(false)
  const userPermission = useSelector(state => state.permission.data)
  const [teammatePanel, setTeammatePanel] = useState({
    open: false,
    data: {}
  })
  // const [addClientTeammate, setAddClientTeammate] = useState(false)

  const handleOpenUpdateRepresentativeDialog = isNew => {
    setUpdateRepresentativeDialogData({
      isNew,
      open: true
    })

    setTeammateSelectOptions(
      allAgencyTeammates.filter(i => i._id !== representative?._id)
    )
  }

  const handleCloseUpdateRepresentativeDialog = () => {
    setUpdateRepresentativeDialogData({
      isNew: false,
      open: false
    })
  }

  const handleSelectTeammate = newVal => {
    setSelectedTeammate(newVal)
  }

  const handleSaveRepresentative = () => {
    setSavingChanges(true)
    dispatch(
      updateClient(
        {
          clientId: clientData._id,
          data: {
            agencyRepresentative: selectedTeammate._id
          }
        },
        saveRepresentativeCallback
      )
    )
  }

  const saveRepresentativeCallback = (res, err) => {
    setSavingChanges(false)
    handleCloseUpdateRepresentativeDialog()
    if (err) dispatch(fireErrorToaster(res))
    else dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
  }

  const handleAddClientTeammate = () => {
    if (clientData?._id) {
      setTeammatePanel({ open: true, data: {} })
    } else {
      dispatch(fireWarningToaster(t('ORGANIZATION_REPRESENTATIVE_REQUIRED')))
    }
  }

  const addClientTeammateCallback = addedTeammate => {
    setClientInfo(prev => ({
      ...prev,
      data: {
        ...prev.data,
        clientTeammates: [
          ...prev.data.clientTeammates,
          {
            ...addedTeammate,
            assignedWorkspace: addedTeammate.profile?.assignedWorkspace ?? []
          }
        ]
      }
    }))
  }

  const openEditTeammatePanel = teammateData => {
    setTeammatePanel({ open: true, data: teammateData })
  }

  const updateClientTeammateCallback = teammate => {
    setClientInfo(prev => ({
      ...prev,
      data: {
        ...prev.data,
        clientTeammates: prev.data.clientTeammates.map(item => {
          if (item._id === teammate._id) return teammate
          return item
        })
      }
    }))
  }

  return (
    <div>
      {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
        meData.role
      ) && (
        <div className="text-right">
          <Button
            size="small"
            variant="outlined"
            startIcon={<AddIcon fontSize="small" />}
            onClick={handleAddClientTeammate}
          >
            {t('ADD_CLIENT_TEAM_MEMBER')}
          </Button>
        </div>
      )}
      {elmFor === 'client' && (
        <Wrapper className="my-8">
          <h5 className="font-medium mb-4 text-sm text-black dark:text-dark-light2">
            {t('ACCOUNT_MANAGER')}
          </h5>
          <div className="flex flex-wrap gap-3 justify-between items-start">
            {representative ? (
              <>
                <div>
                  <div
                    className="flex items-center"
                    style={{ minWidth: '300px' }}
                  >
                    <ColoredAvatars user={representative} size="medium" />

                    <p className="capitalize font-medium text-xs text-primary-mid-dark ml-2 mr-4 dark:text-dark-light">
                      {representative.name}
                    </p>
                    {representative.role && getRoleBadge(representative.role)}
                  </div>
                </div>

                {userPermission.access?.clients?.update && (
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={
                      <EditIcon className="text-primary-main dark:text-dark-light" />
                    }
                    onClick={() => handleOpenUpdateRepresentativeDialog(false)}
                  >
                    {t('CHANGE_MANAGER')}
                  </Button>
                )}
              </>
            ) : (
              <>
                <p className="text-xs text-custom-gray-light-6 font-medium dark:text-dark-light">
                  {t('NO_MANAGER_IS_ASSIGNED')}
                </p>

                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<AddIcon fontSize="small" />}
                  loading={savingChanges}
                  onClick={() => handleOpenUpdateRepresentativeDialog(true)}
                >
                  {t('ASSIGN_A_MANAGER')}
                </Button>
              </>
            )}
          </div>
        </Wrapper>
      )}

      <Members
        clientData={clientData}
        clientInfo={clientInfo}
        setClientInfo={setClientInfo}
        openEditTeammatePanel={openEditTeammatePanel}
      />

      <CustomModal
        // classes={{ paper: classes.dialogPaper }}
        open={updateRepresentativeDialogData.open}
        handleClose={handleCloseUpdateRepresentativeDialog}
      >
        <CustomModalHeader
          heading={t('ACCOUNT_MANAGER')}
          handleClose={handleCloseUpdateRepresentativeDialog}
        />

        <CustomModalBody className="pb-4">
          <p className="mb-2 dark:text-dark-light text-sm">
            {t('SELECT_ANY_TEAM_MEMBER_TO_ASSIGN_TO_THIS_CLIENT')}
          </p>
          <Select
            value={selectedTeammate}
            menuPosition="fixed"
            onChange={handleSelectTeammate}
            options={teammateSelectOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option._id}
            styles={reactSelectCustomStyles(theme)}
            maxMenuHeight={150}
          />
        </CustomModalBody>
        <CustomModalFooter>
          <Button
            size="large"
            onClick={handleCloseUpdateRepresentativeDialog}
            variant="outlined"
          >
            {t('CANCEL')}
          </Button>
          <LoadingButton
            size="large"
            disabled={!selectedTeammate}
            loading={savingChanges}
            onClick={handleSaveRepresentative}
          >
            {t('SAVE_CHANGES')}
          </LoadingButton>
        </CustomModalFooter>
      </CustomModal>

      <AddNewTeammatePanel
        open={teammatePanel.open}
        data={teammatePanel.data}
        onClose={() => setTeammatePanel(prev => ({ ...prev, open: false }))}
        metadata={{
          isAgencyAddingClientTeammate: true,
          clientOwner: clientData?._id
        }}
        callback={
          teammatePanel.data._id
            ? updateClientTeammateCallback
            : addClientTeammateCallback
        }
      />
    </div>
  )
}

export default TeamMembers
