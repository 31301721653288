import React, { useState, useEffect } from 'react'
import { CheckBoxField } from 'global/globalComponents'
import { TextField, InputAdornment, useTheme } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import BoardsStyles from './BoardView/BoardsStyles'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  createCustomURL,
  deleteCustomURL,
  updateCustomURL
} from 'thunks/customUrl/action'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ShareBoardAdvanceOptions = ({
  sharing,
  workspace,
  passcode,
  setPasscode,
  handleSaveChanges,
  handleTabSelection,
  hiddenTab,
  visibleTabs,
  customizeLink,
  setCustomizeLink
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [passcodeProtected, setPasscodeProtected] = useState(
    Boolean(passcode.value)
  )
  const [customize, setCustomize] = useState(false)
  const [expand, setExpand] = useState(false)
  const classes = BoardsStyles()
  const dispatch = useDispatch()
  const [validate, setValidate] = useState({ loading: false, valid: true })
  const [customURL, setCustomURL] = useState('')

  useEffect(() => {
    if (customizeLink._id) {
      setCustomURL(customizeLink.customUrl)
    } else {
      setCustomURL('')
    }
  }, [customizeLink])

  const togglePasscodeProtected = e => {
    setPasscodeProtected(e.currentTarget.checked)
  }

  const toggleCustomizeLink = e => {
    setCustomize(e.currentTarget.checked)
  }

  const handlePasscodeChange = e => {
    setPasscode(prev => ({ ...prev, value: e.target.value }))
  }

  const handleLinkChange = e => {
    const value = e.target.value.trim().toLowerCase()
    if (/^([\w\d]-?)*$/.test(value)) {
      setValidate({ loading: false, valid: true })
      setCustomURL(value)
    }
  }

  const saveCustomizeLink = () => {
    if (customURL) {
      const data = {
        customUrl: `${customURL}`,
        type: 'sharedBoard',
        data: {
          url: `/public/share/board/${workspace._id}`,
          data: {
            workspace: workspace._id
          }
        }
      }

      if (customizeLink._id) {
        if (customizeLink.customUrl !== customURL) {
          setValidate({ loading: true, valid: true })
          updateCustomURL(
            { data, linkId: customizeLink._id },
            saveCustomizeLinkCallback
          )
        } else {
          setValidate({ loading: true, valid: true })
          deleteCustomURL({ linkId: customizeLink._id }, (res, err) => {
            setValidate({ loading: false, valid: true })
            if (!err) {
              setCustomizeLink({})
              dispatch(fireSuccessToaster(t('URL_DELETED_SUCCESSFULLY')))
            } else {
              dispatch(fireErrorToaster(res))
            }
          })
        }
      } else {
        setValidate({ loading: true, valid: true })
        createCustomURL({ data }, saveCustomizeLinkCallback)
      }
    }
  }

  const saveCustomizeLinkCallback = (res, err) => {
    if (!err) {
      setCustomizeLink(res)
      setValidate({ loading: false, valid: true })
      dispatch(fireSuccessToaster(t('URL_SAVED_SUCCESSFULLY')))
    } else {
      dispatch(fireErrorToaster(res))
      setValidate({ loading: false, valid: false })
    }
  }

  const toggleExpand = () => {
    setExpand(prev => !prev)
  }

  return (
    <div className="bg-white mt-4 dark:bg-dark-main2">
      <div
        className="flex m-auto justify-center cursor-pointer items-center text-gray-900 mb-4 pb-1 dark:text-dark-light2"
        onClick={toggleExpand}
      >
        <p className="text-smaller 2xl:text-sm font-bold dark:text-dark-light">
          {t('SHOW_ADVANCE_OPTIONS')}
        </p>
        <ExpandMoreIcon
          className={clsx(
            classes.icons,
            {
              expanded: expand
            },
            'dark:text-dark-light'
          )}
        />
      </div>

      {expand && (
        <div className="mb-4">
          <div>
            <CheckBoxField
              label={
                <div className="flex flex-wrap items-center">
                  <p className="text-smaller 2xl:text-sm font-normal dark:text-dark-light">
                    {t('CUSTOMIZE_YOUR_PUBLIC_SHARE_LINK')}
                  </p>
                </div>
              }
              checked={customize}
              onChange={toggleCustomizeLink}
            />

            <div className="relative">
              <div className="flex items-center">
                <TextField
                  error={!validate.valid}
                  id="outlined-error"
                  value={customURL}
                  onChange={handleLinkChange}
                  placeholder={t('ENTER_YOUR_CUSTOM_URL')}
                  className="w-full mt-4"
                  autoFocus
                  disabled={!customize}
                  onBlur={
                    customizeLink.customUrl && !customURL
                      ? () => setCustomURL(customizeLink.customUrl)
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {customize && Boolean(customURL) ? (
                          validate.loading ? (
                            <span className="cursor-pointer text-primary-main text-xs font-medium dark:text-dark-light">
                              <CircularProgress
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  color: theme.palette.primary.main
                                }}
                              />
                            </span>
                          ) : (
                            <button
                              type="submit"
                              className={clsx(
                                'text-sm font-medium text-primary-main dark:text-dark-light'
                              )}
                              onClick={saveCustomizeLink}
                            >
                              {customURL === customizeLink.customUrl
                                ? t('DELETE')
                                : t('VALIDATE')}
                            </button>
                          )
                        ) : null}
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {customize && (
                <p className="text-xs my-1 dark:text-dark-light">
                  {window.location.origin}/public/
                  {customURL ? customURL : `share/board/${workspace._id}`}
                </p>
              )}
            </div>
          </div>
          <div className="my-4">
            <CheckBoxField
              label={
                <p className="text-smaller 2xl:text-sm font-normal dark:text-dark-light">
                  {t('MAKE_YOUR_LINK_SECURE')}
                </p>
              }
              checked={passcodeProtected}
              onChange={togglePasscodeProtected}
            />

            {/* {passcodeProtected && ( */}
            <div>
              <form onSubmit={handleSaveChanges}>
                <TextField
                  type="number"
                  value={passcode.value}
                  onChange={handlePasscodeChange}
                  placeholder={t('ENTER_PASSCODE')}
                  className="w-full mt-4"
                  disabled={!passcodeProtected}
                  // onBlur={handleSaveChanges}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {passcode.loading ? (
                          <span className="cursor-pointer text-primary-main text-xs font-medium dark:text-dark-light">
                            <CircularProgress
                              style={{
                                width: '12px',
                                height: '12px',
                                color: theme.palette.primary.main
                              }}
                            />
                          </span>
                        ) : sharing.passcode === passcode.value ? null : (
                          <button
                            type="submit"
                            className={clsx(
                              'text-sm font-medium text-primary-main dark:text-dark-light'
                            )}
                            onClick={handleSaveChanges}
                          >
                            {t('SAVE')}
                          </button>
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </form>
            </div>
            {/* )} */}
          </div>
          <div className="my-4">
            <p className="text-sm dark:text-dark-light">
              {t('SELECTED_TABS_WILL_BE_AVAILABLE_FOR_SHARE_BOARD')}
            </p>
            <ul className="flex items-center flex-wrap">
              {visibleTabs.map(el => (
                <li key={el.accessor} className="w-1/3">
                  <CheckBoxField
                    label={el.label}
                    checked={!hiddenTab.includes(el.accessor)}
                    onChange={e =>
                      handleTabSelection(el.accessor, e.target.checked)
                    }
                    disabled={el.disable}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default ShareBoardAdvanceOptions
