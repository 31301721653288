const { useEffect } = require('react')

const useCalendly = () => {
  useEffect(() => {
    if (window.Calendly) return

    const head = document.querySelector('head')
    const link = document.createElement('link')
    const script = document.createElement('script')

    link.setAttribute(
      'href',
      'https://assets.calendly.com/assets/external/widget.css'
    )

    link.setAttribute('rel', 'stylesheet')
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    )
    script.setAttribute('defer', 'defer')
    head.appendChild(link)
    head.appendChild(script)
  }, [])

  // useEffect(() => {
  //   if (autoOpen) {
  //     setTimeout(handleCalendlyClick, 4000)
  //   }
  // }, [autoOpen])
  // useEffect(() => {
  //   if(cancelling) handleCalendlyClick()
  //  },[cancelling])

  const handleCalendlyClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        // url: 'https://calendly.com/clientvenuedemo/30min'
        url: 'https://calendly.com/influence/technical'
      })
    }
  }

  const handleCalendlyUrlClick = url => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url
      })
    }
  }

  return { handleCalendlyClick, handleCalendlyUrlClick }
}

export default useCalendly
