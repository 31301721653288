import { useRef } from 'react'
import { List, ListItem, Popover } from '@material-ui/core'
import workspaceStyles from './workspaceStyles'
import { downloadFile } from 'utils'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const AttachmentDropDown = props => {
  const {
    open,
    onClose,
    anchorEl,
    data,
    handleDeleteAttachment,
    handlePreviewImage,
    ...rest
  } = props
  const classes = workspaceStyles()
  const imageAnchorRef = useRef(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleDownloadAttachment = () => {
    downloadFile({ url: data }, (res, err) => {
      onClose()
      if (err) dispatch(fireErrorToaster(res))
    })
  }

  const onPreview = () => {
    handlePreviewImage()
    onClose()
  }

  const onDelete = () => {
    handleDeleteAttachment(data)
    onClose()
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      {...rest}
    >
      <List
        className="dark:text-dark-light"
        classes={{ root: classes.listRoot }}
        dense
      >
        <ListItem onClick={onPreview} button>
          {t('PREVIEW')}
        </ListItem>
        <ListItem onClick={handleDownloadAttachment} button>
          {t('DOWNLOAD_ATTACHMENT')}
        </ListItem>
        <ListItem style={{ color: '#ff5263' }} button onClick={onDelete}>
          {t('DELETE_ATTACHMENT')}
        </ListItem>
        <a
          href={data}
          ref={imageAnchorRef}
          download
          style={{ display: 'none' }}
        >
          <img src={data} alt="" />
        </a>
      </List>
    </Popover>
  )
}

export default AttachmentDropDown
