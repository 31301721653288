import { Button } from '@material-ui/core'
import { ToggleSwitch } from 'global/globalComponents'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { updateUserProfile } from 'thunks/user/actions'

function ServiceSettingsModal({ open, onClose }) {
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (meData.profile.hideServiceAfterClientSignup)
      setChecked(meData.profile.hideServiceAfterClientSignup)
  }, [meData])

  const handleChange = () => {
    setLoading(true)
    dispatch(
      updateUserProfile(
        {
          id: meData._id,
          data: {
            hideServiceAfterClientSignup: !checked
          }
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res.message))
          } else {
            dispatch(fireSuccessToaster(t('CHANGES_SAVED')))
            setChecked(prev => !prev)
          }
          setLoading(false)
        }
      )
    )
  }

  return (
    <CustomModal size="small" open={open} handleClose={onClose}>
      <CustomModalHeader heading={t('SETTINGS')} />
      <CustomModalBody>
        <div className="flex items-center w-full gap-4">
          <span className=" text-gray-700 text-sm dark:text-gray-50 ">
            {t('DISABLE_SERVICES_PAGE_CLIENT_SIGNUP')}
          </span>
          <div>
            <ToggleSwitch
              loading={loading}
              onChange={handleChange}
              checked={checked}
            />
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button onClick={onClose}>{t('CLOSE')}</Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default ServiceSettingsModal
