import axios from 'axios'
import {
  getAccessToken,
  getRefreshToken,
  // removeAuthTokens,
  storeAccessToken
} from 'utils'
import * as Sentry from '@sentry/react'
import { useCallback, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { setUserLoggedOut } from 'thunks/login/actions'

const APP_BASE_URL = process.env.REACT_APP_CONNECTION_URL
const APP_BASE_URL2 = process.env.REACT_APP_CONNECTION_URL.replace('v1', 'v2')

axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.baseURL = APP_BASE_URL

let isFetchingToken = false
const tokenPromises = []

const useAxiosConfig = () => {
  const dispatch = useDispatch()
  const { tempAccessToken, tempRefreshToken } = useSelector(
    state => state.login
  )

  const redirectToLoginPage = useCallback(() => {
    if (window.location.pathname !== '/login') {
      dispatch(setUserLoggedOut())
      dispatch(
        fireErrorToaster('Your session has expired! Please login again.')
      )
      // window.location.href = `${window.location.origin}/login`
      // history.push('/login')
    }
    // window.setTimeout(() => {
    //   if (window.location.pathname !== '/login') {
    //     dispatch(setUserLoggedOut())
    //     dispatch(
    //       fireErrorToaster('Your session has expired! Please login again.')
    //     )
    //     // history.push('/login')
    //     // window.location.href = `${window.location.origin}/login`
    //   }
    // }, 1000)
  }, [dispatch])

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      function (config) {
        if (config.apiVersion === 2) {
          config.baseURL = APP_BASE_URL2
        }

        if (config.headers['Authorization'] || config.isPublic) return config

        const accessToken = getAccessToken()

        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${
            tempAccessToken || accessToken
          }`
          return config
        }

        const refreshToken = tempRefreshToken || getRefreshToken()

        if (!refreshToken) {
          const CancelToken = axios.CancelToken
          redirectToLoginPage()
          return {
            ...config,
            cancelToken: new CancelToken(cancel => cancel('Cancel request'))
          }
        }

        if (!isFetchingToken) {
          return handleFetchAccessToken(refreshToken)
            .then(res => {
              if (res.accessToken) {
                config.headers['Authorization'] = `Bearer ${res.accessToken}`
                return config
              } else {
                throw Error(new Error('Session Expired!'))
              }
            })
            .catch(err => {
              redirectToLoginPage()
              return Promise.reject(err)
            })
        } else {
          return new Promise((resolve, reject) => {
            const fn = token => {
              if (token) {
                config.headers['Authorization'] = `Bearer ${token}`
                resolve(config)
              } else {
                redirectToLoginPage()
                reject(new Error('Session Expired!'))
              }
            }

            tokenPromises.push(fn)
          })
        }
      },
      function (err) {
        console.log({ err })
      }
    )

    const responseInterceptor = axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        const originalRequest = error.config

        if (
          originalRequest &&
          !originalRequest.isPublic &&
          error.response?.status === 401 &&
          !originalRequest._retry
        ) {
          if (!isFetchingToken) {
            const refreshToken = getRefreshToken()
            originalRequest._retry = true
            return handleFetchAccessToken(refreshToken)
              .then(res => {
                originalRequest.headers.Authorization = `Bearer ${res.accessToken}`
                return axios(originalRequest)
              })
              .catch(err => {
                redirectToLoginPage()
                return Promise.reject(new Error('Session expired!'))
              })
          } else {
            return new Promise((resolve, reject) => {
              const fn = token => {
                if (token) {
                  originalRequest.headers.Authorization = `Bearer ${token}`
                  return resolve(axios(originalRequest))
                } else {
                  reject(new Error('Session Expired!'))
                }
              }

              tokenPromises.push(fn)
            })
          }
        }

        if (
          error.response?.data?.message?.toLowerCase() ===
          'account has been blocked'
        ) {
          redirectToLoginPage()
        }
        if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
          Sentry.captureException(error)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.request.eject(requestInterceptor)
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [redirectToLoginPage, tempAccessToken, tempRefreshToken])

  const handleFetchAccessToken = async refreshToken => {
    isFetchingToken = true

    try {
      const res = await axios({
        url: '/token/access',
        method: 'POST',
        data: { refreshToken },
        isPublic: true
      })

      storeAccessToken(res.data.accessToken)
      tokenPromises.forEach(fn => fn(res.data.accessToken))
      tokenPromises.length = 0
      isFetchingToken = false
      return res.data
    } catch (err) {
      isFetchingToken = false
      tokenPromises.length = 0
      throw new Error('Session Expired!')
    }
  }

  return null
}

export default useAxiosConfig
