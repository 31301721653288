import React from 'react'
import { Box, FormGroup, withStyles, FormControlLabel } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import PropTypes from 'prop-types'

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 17,
    padding: 0,
    margin: theme.spacing(1),
    borderRadius: '28px'
  },
  switchBase: {
    padding: 1,
    // paddingTop: "1px",
    '& + $track': {
      backgroundColor: theme.palette.type === 'dark' ? '#a0a0a0' : '#E8E8EA'
    },
    '&$checked': {
      transform: 'translateX(11px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#4BC062',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      //   backgroundColor: "#fff",
      //   border: "6px solid #fff"
    }
  },
  thumbLoader: {
    width: 15,
    height: 15,
    boxShadow: 'none',
    boxSizing: 'border-box',
    animation: 'around 5s infinite',
    position: 'relative',
    backgroundColor: '#fff',
    '&::after, &::before': {
      content: "''",
      //   padding: '5px',
      //   background: "transparent",
      position: 'absolute',
      display: 'inline-block',
      width: '10px',
      height: '10px',
      borderWidth: '1px',
      //borderColor: '#0066FF #0066FF transparent transparent',
      // boxShadow: '1px 1px #0066FF',
      boxShadow: 'inset 1px 1px #4BC062',
      borderStyle: 'solid',
      borderRadius: '20px',
      boxSizing: 'border-box',
      top: 2,
      left: 2,
      animation: '$around 0.5s ease-in-out infinite'
    }
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.08)',
    boxSizing: 'border-box',
    backgroundColor: '#fff'
  },
  '@keyframes around': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  track: {
    borderRadius: '28px',
    // border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, loading, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: loading ? classes.thumbLoader : classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
})

const ToggleSwitch = props => {
  const { loading, disable, checked, ...rest } = props
  return (
    <Box width="38px" style={{ margin: '0 auto' }}>
      <FormGroup>
        <FormControlLabel
          style={{ margin: 0 }}
          control={
            <IOSSwitch
              {...rest}
              checked={checked}
              disabled={loading || disable}
              loading={loading}
            />
          }
        />
      </FormGroup>
    </Box>
  )
}

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  loading: PropTypes.bool
}

export default ToggleSwitch

/**
 *
 * have loading animation with disabled state.
 * Pass loading (Type: Boolean) prop and while loading is true state, switch will
 * be in disabled mode and loading animation will keep on running.
 *
 * No need to pass disabled prop seperately
 *
 *
 *
 **/
