import React from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
// import EmbedFormContainer from './EmbedFormContainer'
// import SavedEmbedCard from './SavedEmbedCard'
import { useModal } from 'customHooks'
import { AlertModal } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
import { deleteEmbed } from 'thunks/embed/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import { useParams } from 'react-router-dom'
// import { Skeleton } from '@material-ui/lab'
import EmbedTable from './EmbedTable'
import AddEmbedModal from './AddEmbedModal'

const ClientEmbed = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const { clientId } = useParams()
  // const embed = useSelector(state => state.embed)
  // const clientEmbeds = embed.data.filter(item => item.client === clientId)
  // const [addNew, setAddNew] = useState(clientEmbeds.length === 0)
  const {
    modal: deleteAlert,
    openModal: openDeleteAlert,
    closeModal: closeDeleteAlert,
    toggleLoading
  } = useModal()

  const {
    modal: embedModal,
    openModal: openEmbedModal,
    closeModal: closeEmbedModal
  } = useModal()

  const handelDeleteEmbed = () => {
    toggleLoading()
    dispatch(
      deleteEmbed({ id: deleteAlert.data._id }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          toggleLoading()
        } else {
          dispatch(fireSuccessToaster(t('DELETED_SUCCESSFULLY')))
          closeDeleteAlert()
        }
      })
    )
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <div>
          <div className="text-sm font-semibold mb-1">{t('EMBED_LINKS')}</div>
          <p className="text-smaller text-gray-600">
            {t('EMBED_LINK_DESCRIPTION')}
          </p>
        </div>

        <Button startIcon={<AddIcon />} onClick={() => openEmbedModal()}>
          {t('NEW')}
        </Button>
      </div>

      {/* {addNew && (
        <div className="mb-6">
          <EmbedFormContainer
            clientId={clientId}
            t={t}
            onCancel={() => setAddNew(false)}
          />
        </div>
      )} */}

      <EmbedTable
        handleDeleteEmbed={openDeleteAlert}
        handleEditEmbed={openEmbedModal}
      />

      {/* {embed.loading ? (
        <>
          <div className="mb-4">
            <Skeleton variant="rect" height={100} className="rounded" />
          </div>
          <div>
            <Skeleton variant="rect" height={100} className="rounded" />
          </div>
        </>
      ) : (
        clientEmbeds.map(embed => (
          <SavedEmbedCard
            t={t}
            key={embed._id}
            {...embed}
            handleDeleteEmbed={() => openDeleteAlert(embed)}
          />
        ))
      )} */}

      <AlertModal
        open={deleteAlert.open}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handelDeleteEmbed}
        warningText={`${t('WARNING_DELETE_MESSAGE', {
          data: deleteAlert.data.name
        })} ${t('EMBED').toLocaleLowerCase()}?`}
        loading={deleteAlert.loading}
      />

      <AddEmbedModal
        open={embedModal.open}
        onClose={closeEmbedModal}
        data={embedModal.data}
      />
    </>
  )
}

export default ClientEmbed
