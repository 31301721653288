const hexToRgb = hex => {
  const hexNumber = hex.substring(1).toUpperCase()
  let R = hexToDecimal(hexNumber.substring(0, 2))
  let G = hexToDecimal(hexNumber.substring(2, 4))
  let B = hexToDecimal(hexNumber.substring(4, 6))
  return { R, G, B }
}

const hexToDecimal = number => {
  let unitPlace = digit(number[1])
  let tenthPlace = digit(number[0])
  return tenthPlace * 16 ** 1 + unitPlace * 16 ** 0
}

const digit = str => {
  const hexNo = { A: 10, B: 11, C: 12, D: 13, E: 14, F: 15 }
  if (str * 1 >= 0) {
    return str * 1
  } else {
    return hexNo[str]
  }
}

export const colorShades = (color, shade_factor) => {
  const rgb = hexToRgb(color)
  const r = rgb['R'] * shade_factor
  const g = rgb['G'] * shade_factor
  const b = rgb['B'] * shade_factor
  return `rgb(${r}, ${g}, ${b})`
}

export const colorTints = (color, tint_factor) => {
  const rgb = hexToRgb(color)
  const r = rgb['R'] + (255 - rgb['R']) * tint_factor
  const g = rgb['G'] + (255 - rgb['G']) * tint_factor
  const b = rgb['B'] + (255 - rgb['B']) * tint_factor
  return `rgb(${r}, ${g}, ${b})`
}

export function darkenHex(colorCode, factor = 0.9) {
  // Remove the '#' from the start of the color code
  colorCode = colorCode.slice(1)

  // Convert the color code to RGB
  let r = parseInt(colorCode.slice(0, 2), 16)
  let g = parseInt(colorCode.slice(2, 4), 16)
  let b = parseInt(colorCode.slice(4, 6), 16)

  // Darken each of the RGB components
  r = Math.floor(r * factor)
  g = Math.floor(g * factor)
  b = Math.floor(b * factor)

  // Ensure the values are within the valid range (0-255)
  r = Math.max(Math.min(255, r), 0)
  g = Math.max(Math.min(255, g), 0)
  b = Math.max(Math.min(255, b), 0)

  // Convert the RGB values back to a hex color code
  let newColorCode =
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0')

  return newColorCode
}

export default hexToRgb
