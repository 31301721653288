import React, { useState } from 'react'
import {
  List
  // ListItem,
  // ListItemText,
  // // Popover,
  // makeStyles
  // Popper,
  // ClickAwayListener
} from '@material-ui/core'
import clsx from 'clsx'
import { CustomPopper } from 'global/globalComponents'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
import {
  SingleListItem,
  useStyles
} from 'global/globalComponents/PopupMenu/PopupMenu'

const ProfileMenuPopup = ({ menuItems, targetComp, onClick, placement }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  // const arrowRef = useRef();
  // const [arrowRef, setArrowRef] = useState(null)

  const handleOpenMenu = e => {
    e.stopPropagation()
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      if (onClick) onClick()
      setAnchorEl(e.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {!targetComp ? (
        <button onClick={handleOpenMenu}>
          <ThreeDotsIcon className="w-4 h-4 lg:w-5 lg:h-5 dark:text-dark-light" />
        </button>
      ) : (
        <>{React.cloneElement(targetComp, { onClick: handleOpenMenu })}</>
      )}

      <CustomPopper
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        placement={placement ? placement : 'bottom-end'}
      >
        <div
          className={clsx(
            'bg-white dark:bg-dark-main2 dark:text-dark-light',
            classes.menuPaper
          )}
        >
          <List className={classes.list}>
            {menuItems.map((item, index) =>
              item.element ? (
                item.element
              ) : (
                <SingleListItem
                  key={index}
                  item={item}
                  index={index}
                  handleCloseMenu={handleCloseMenu}
                  classes={classes}
                />
              )
            )}
          </List>
        </div>
      </CustomPopper>
    </>
  )
}

export default React.memo(ProfileMenuPopup)
