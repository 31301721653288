import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'

export const TableContainer = props => {
  const {
    children,
    containerProps = {},
    mb = 16,
    // shadow = 'lg',
    className,
    ...restProps
  } = props
  return (
    <div
      {...containerProps}
      className={clsx(
        `align-middle min-w-full overflow-x-auto overflow-auto sm:rounded-xl mb-${mb} border border-gray-300 rounded-xl dark:bg-dark-main dark:border-dark-main1`,
        className
      )}
    >
      <table
        className="min-w-full divide-y divide-gray-200 border-collapse border-hidden dark:bg-dark-main bg-white dark:border-dark-main1 dark:divide-gray-500"
        {...restProps}
      >
        {children}
      </table>
    </div>
  )
}

export const Thead = props => {
  const { children, className, ...restProps } = props

  return (
    <thead
      {...restProps}
      className={clsx(
        'font-medium uppercase bg-custom-gray-light-5 text-left h-14 dark:bg-dark-main2 dark:text-dark-light',
        className
      )}
    >
      <tr>{children}</tr>
    </thead>
  )
}

export const Th = props => {
  const { children, className, center = false, ...restProps } = props
  return (
    <th
      className={clsx(
        'tracking-wider font-medium py-3 px-4 2xl:px-6  text-extraSmaller 2xl:text-xs text-custom-gray-dark dark:text-dark-light',
        className,
        { 'text-center': center }
      )}
      {...restProps}
    >
      {children}
    </th>
  )
}

export const Tbody = props => {
  const { children, className, ...restProps } = props
  return (
    <tbody
      {...restProps}
      className={clsx(
        'bg-white divide-y divide-gray-200 text-gray-500 text-xs 2xl:text-sm dark:bg-dark-main dark:text-dark-light dark:divide-gray-500',
        className
      )}
    >
      {children}
    </tbody>
  )
}

export const Tr = props => {
  const { children, className, ...restProps } = props
  return (
    <tr
      className={clsx(
        'hover:bg-gray-100 dark:bg-dark-main dark:hover:bg-dark-main3 dark:text-dark-light',
        className
      )}
      {...restProps}
    >
      {children}
    </tr>
  )
}

export const Td = props => {
  const { children, className, center = false, ...restProps } = props
  return (
    <td
      {...restProps}
      className={clsx(
        'px-4 py-3 2xl:px-6 2xl:py-4 text-gray-500 dark:text-dark-light',
        className,
        {
          'text-center': center
        }
      )}
    >
      {children}
    </td>
  )
}

export const TableDataLoading = ({ cols, rows = 3 }) => {
  return (
    <>
      {Array(rows)
        .fill('')
        .map((i, index) => (
          <tr key={index} className="bg-white dark:bg-dark-main">
            {Array(cols)
              .fill('')
              .map((item, index) =>
                index === 0 ? (
                  <td
                    key={index}
                    className="px-4 2xl:px-6 py-1 whitespace-nowrap text-sm text-gray-900 dark:bg-dark-main dark:text-dark-light"
                  >
                    <Skeleton height={40} />
                  </td>
                ) : (
                  <td
                    key={index}
                    className="px-6 py-1 text-right whitespace-nowrap text-sm text-gray-500 dark:bg-dark-main dark:text-dark-light"
                  >
                    <Skeleton height={40} />
                  </td>
                )
              )}
          </tr>
        ))}
    </>
  )
}
