import React, { useEffect, useState } from 'react'
// import { Dialog, Button } from '@material-ui/core'
import { ErrorOutlineRounded } from '@material-ui/icons'
import clsx from 'clsx'
// import CloseButton from 'global/globalComponents/CloseButton/CloseButton'
import setupStyles from './setupStyles'
import {
  CustomModal,
  CustomModalBody
} from 'global/globalComponents/CustomModal'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { Button, CircularProgress } from '@material-ui/core'

const AppConnectModal = ({ status, onClose, appName, errorMessage }) => {
  const { t } = useTranslation()
  const classes = setupStyles()
  const [loading, setLoading] = useState(true)
  const [errorInPostindData, setErrorInPostingData] = useState(null)

  useEffect(() => {
    if (status === 'loading') setLoading(true)
    else {
      setLoading(false)
      setErrorInPostingData(status === 'error' ? true : false)
    }
  }, [status])

  return (
    <CustomModal open={true} size="small">
      <div className="p-4">
        {/* ===== Header ====== */}
        <div className="flex items-center justify-center mb-2">
          <div>
            {status === 'loading' ? (
              <CircularProgress size={18} />
            ) : status === 'error' ? (
              <div className="flex items-center flex-shrink-0 justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 dark:text-dark-light bg-red-100">
                <ErrorOutlineRounded className="h-6 w-6 text-red-600" />
              </div>
            ) : (
              <div className="flex items-center flex-shrink-0 justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 dark:text-dark-light bg-green-100">
                <CheckCircleIcon className="h-6 w-6 text-green-600" />
              </div>
            )}
          </div>
          <div className="text-lg font-medium text-gray-900 ml-2 capitalize">
            {appName} Integration
          </div>
        </div>

        {/* ==== Body ==== */}
        <div className="py-4 font-medium text-sm text-center text-gray-600 mb-2">
          {status === 'loading' ? (
            <div>
              {t('INTEGRATION_MODAL_LOADING', { appName })}
              <div className="text-small mt-1">
                {t('DONT_CLOSE_REFRESH_BROWSER')}
              </div>
            </div>
          ) : status === 'error' ? (
            <div>{errorMessage}</div>
          ) : (
            t('INTEGRATION_MODAL_SUCCESS_MESSAGE', { appName })
          )}
        </div>

        {/* ==== Footer ==== */}
        <div className="text-center">
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </CustomModal>
    // <Dialog open={true} maxwidth="md" classes={{ paper: classes.dialogPaper }}>
    //   <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:w-full relative">
    //     {!loading && <CloseButton onClick={onClose} />}
    //     <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
    //       <div className="sm:flex sm:items-center">
    //         <div
    //           className={clsx(
    //             'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10',
    //             {
    //               'bg-blue-100': loading,
    //               'bg-red-100': errorInPostindData,
    //               'bg-green-100': !errorInPostindData
    //             }
    //           )}
    //         >
    //           <ErrorOutlineRounded
    //             className={clsx('h-6 w-6', {
    //               'text-blue-600': loading,
    //               'text-red-600': errorInPostindData,
    //               'text-green-600': !errorInPostindData
    //             })}
    //             aria-hidden="true"
    //           />
    //         </div>
    //         <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
    //           <h3
    //             as="h3"
    //             className="text-lg leading-6 font-medium text-gray-900"
    //           >
    //             {appName} Integration
    //           </h3>
    //         </div>
    //       </div>

    //       <div className="mt-8">
    //         <div className="mt-8">
    //           {errorInPostindData ? (
    //             <div>
    //               <p className="font-medium text-center">
    //                 {errorInPostindData && errorMessage}
    //               </p>
    //               <div className="text-center mt-6">
    //                 <Button onClick={onClose}>Try Again</Button>
    //               </div>
    //             </div>
    //           ) : loading ? (
    //             <div className="text-center py-4">
    //               <p className="font-medium">
    //                 Please wait while we're verifying the {appName} connection
    //                 status...
    //               </p>
    //               <span className="text-sm font-medium text-custom-gray-dark">
    //                 Don't close window or refresh the browser
    //               </span>
    //             </div>
    //           ) : (
    //             <div className="text-center py-4">
    //               <p className="text-lg font-medium">
    //                 Your {appName} account connected successfully!
    //               </p>
    //               <div
    //                 onClick={onClose}
    //                 className="flex items-center justify-center mt-6"
    //               >
    //                 <Button variant="outlined">Close</Button>
    //               </div>
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Dialog>
  )
}

export default AppConnectModal
