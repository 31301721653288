import { makeStyles } from '@material-ui/core'

const passwordResetStyles = makeStyles(theme => ({
  resetPasswordContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.custom.darkMode.dark2
        : theme.palette.grey[200],
    width: `calc(100vw - ${2 * theme.spacing(2)}px)`,
    height: `calc(100vh - ${2 * theme.spacing(2)}px)`,
    '&>h4': {
      paddingTop: theme.spacing(3)
    }
  },

  heading: {
    fontWeight: '900',
    fontSize: '1.6rem',
    lineHeight: '1.6rem',
    color: theme.palette.type === 'dark' ? '#ccc' : '#464646',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginBottom: '4rem'
  },

  formCard: {
    maxWidth: '500px',
    margin: '0 auto',
    padding: '3.5rem 2.5rem'
  },

  formContainer: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '100%'
  },
  btnReset: {
    fontSize: '1rem'
  }
}))

export default passwordResetStyles
