import React, { useEffect, useState } from 'react'
import {
  Button,
  TextField,
  InputAdornment,
  useTheme,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Select, { components } from 'react-select'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import clsx from 'clsx'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import {
  LoadingButton,
  RoundedTextInput,
  ToggleSwitch
} from 'global/globalComponents'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { userRoles } from 'utils'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as ChevronRightIcon } from 'static/svg/chevron-right.svg'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import Currency from 'static/Currency/Currency'
import { useTranslation } from 'react-i18next'
import QuestionMarkTooltip from 'global/globalComponents/QuestionMarkTooltip'
import RichTextEditor from 'global/globalComponents/QuillEditor/RichTextEditor'

const EditProjectModal = ({ open, onClose, workspaceData }) => {
  const { t } = useTranslation()

  return (
    <>
      <CustomModal size="large" open={open}>
        <CustomModalHeader
          heading={t('EDIT_PROJECT')}
          handleClose={onClose}
          className="border-b border-gray-300"
        />

        <EditProjectModalContent
          onClose={onClose}
          workspaceData={workspaceData}
        />
      </CustomModal>
    </>
  )
}

const EditProjectModalContent = ({ onClose, workspaceData = {} }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  // let selectRef = null
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: userTeammates, loading: teammatesLoading } = useSelector(
    state => state.userTeammates
  )
  // const userClients = useSelector(state => state.userClients)
  const meData = useSelector(state => state.me.data)
  const permission = useSelector(state => state.permission.data?.access)
  // const [teammateOptions, setTeammateOptions] = useState({})
  // const [clientOptions, setClientOptions] = useState({})
  const [loading, setLoading] = useState(false)
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: 'USD',
    label: 'USD'
  })
  const [formData, setFormData] = useState({
    name: workspaceData.name,
    description: workspaceData.description,
    startDate: workspaceData.startDate,
    dueDate: workspaceData.dueDate,
    type: workspaceData.type,
    budgetedHours: workspaceData.budgetedHours || '',
    assignedAgencyTeam:
      workspaceData.assignedAgencyTeam
        ?.filter(
          item =>
            item.role !== userRoles.USER_AGENCY && item.role !== meData.role
        )
        .map(item => ({
          label: item.user?.name,
          value: item.user?._id,
          ...item.user
        })) || [],
    assignedClientTeam:
      workspaceData.assignedClientTeam?.map(item => ({
        label: item.user?.name,
        value: item.user?._id,
        ...item.user
      })) || {},
    cost: workspaceData.cost || '',
    currency: workspaceData.currency || 'USD',
    isRecurring: workspaceData.isRecurring || false,
    restrictDueDate: workspaceData.restrictDueDate ?? {
      dueDateChangeCounter: 0,
      isDueDateRestrict: false,
      totalDueDateChangeCounter: null
    },
    addAdmins:
      meData.role >= userRoles.USER_CLIENT
        ? false
        : workspaceData.addAdmins || true,
    paymentTerm: workspaceData.paymentTerm || undefined
  })
  const [selectedCategory, setSelectedCategory] = useState(
    workspaceData.category || {}
  )
  const projectCategories = useSelector(
    state => state.userWorkspaces.categories
  )
  // const innerProjectPermission = useWorkspacePermission(workspaceData._id)

  const paymentOptions = [
    { label: t('TIME_SHEET_BASED'), value: 'timesheet' },
    { label: t('PROJECT_BASED'), value: 'project' }
  ]

  useEffect(() => {
    let options = userTeammates.filter(mate => mate._id !== meData._id)

    if (!formData.addAdmins) {
      options = options.filter(mate => mate.role !== userRoles.AGENCY_ADMIN)
      setFormData(prev => ({
        ...prev,
        assignedAgencyTeam: prev.assignedAgencyTeam.filter(
          mate => mate.role !== userRoles.AGENCY_ADMIN
        )
      }))
    }

    // setTeammateOptions(options)
  }, [userTeammates, meData._id, formData.addAdmins])

  useEffect(() => {
    let arr = Object.keys(Currency).map(el => ({
      value: el,
      label: el
    }))
    setCurrencyOptions(arr)
  }, [])

  // useEffect(() => {
  //   const options = userClients.data
  //     .filter(client => client.name.trim().length)
  //     .map(client => ({
  //       label: client.name,
  //       value: client._id
  //     }))

  //   setClientOptions(options)
  // }, [userClients])

  // useEffect(() => {
  //   setFormData(prev => ({
  //     ...prev,
  //     type: type
  //   }))
  // }, [type])

  const selectCurrency = e => {
    setSelectedCurrency(e)
    setFormData(prev => ({
      ...prev,
      currency: e.value
    }))
  }

  // const teammateOptionsLabel = teammate => {
  //   return (
  //     <div className="flex items-center">
  //       <span>
  //         {teammate.name}{' '}
  //         <span className="pl-2">
  //           {teammate.role ? getRoleBadge(teammate.role) : ''}
  //         </span>
  //       </span>
  //     </div>
  //   )
  // }

  const handleUpdateProject = () => {
    // let newClientAdded

    // if (formData.assignedClientTeam.value) {
    //   newClientAdded = workspaceData.assignedClientTeam.find(
    //     item => item.user._id !== formData.assignedClientTeam.value
    //   )
    // }

    const updatedData = {
      ...formData,
      category: selectedCategory._id
      // assignToClientTeammate: Boolean(newClientAdded)
      //   ? [formData.assignedClientTeam.value]
      //   : []
    }

    delete updatedData.assignedAgencyTeam
    delete updatedData.assignedClientTeam

    setLoading(true)
    dispatch(
      updateWorkSpace1(workspaceData._id, updatedData, (res, err) => {
        if (err) dispatch(fireErrorToaster(res))
        else {
          onClose()
          setLoading(false)
          dispatch(fireSuccessToaster('Changes saved!'))
        }
      })
    )
  }

  const handleAddClient = e => {
    setFormData(prev => ({
      ...prev,
      assignedClientTeam: e
    }))
  }

  const handleFormInputChange = e => {
    const { name, value } = e.target
    if (name === 'budgetedHours' && (+value < 0 || value === '0')) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_NUMBER')))
    }

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleDateChange = (e, prop) => {
    setFormData(prev => ({ ...prev, [prop]: e?.toISOString() }))
  }

  const filterCategoryOption = (candidate, input) => {
    return Boolean(candidate.data.name.match(new RegExp(input, 'i')))
  }

  return (
    <>
      <CustomModalBody className="pt-5">
        <div className="mb-4">
          <FormLabel required>{t('PROJECT_NAME')}</FormLabel>
          <TextField
            className="w-full"
            onChange={handleFormInputChange}
            value={formData.name}
            placeholder={t('ENTER_NAME')}
            name="name"
            required
            autoFocus
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('DESCRIPTION')}</FormLabel>
          <RichTextEditor
            style={{ height: 150 }}
            html={formData.description}
            onChange={htmlValue =>
              setFormData(prev => ({
                ...prev,
                description: htmlValue
              }))
            }
            placeholder={t('ENTER_DESCRIPTION')}
          />
        </div>

        <div className="w-full sm:flex-1 mb-4">
          <FormLabel>{t('CHOOSE_CATEGORY')}</FormLabel>
          <Select
            filterOption={filterCategoryOption}
            menuPlacement="bottom"
            value={selectedCategory}
            options={projectCategories}
            onChange={setSelectedCategory}
            styles={reactSelectCustomStyles(theme)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option._id}
            maxMenuHeight="120px"
          />
        </div>

        <div className="mb-4">
          <div className="flex items-center">
            <FormLabel>{t('ADD_CLIENT')}</FormLabel>
            <p className="text-xs text-gray-600 ml-2 mb-1">
              ({t('MANAGE_PROJECT_MEMBERS_FROM_SHARE_PROJECT')})
            </p>
          </div>

          <div>
            <Select
              isDisabled={true}
              value={formData.assignedClientTeam}
              styles={reactSelectCustomStyles(theme)}
            />
          </div>
        </div>

        <div className="mb-4">
          <div className="flex items-center">
            <FormLabel>{t('ADD_TEAM_MEMBERS')}</FormLabel>
            <p className="text-xs text-gray-600 ml-2 mb-1">
              ({t('MANAGE_PROJECT_MEMBERS_FROM_SHARE_PROJECT')})
            </p>
          </div>

          <Select
            value={formData.assignedAgencyTeam}
            isMulti
            styles={reactSelectCustomStyles(theme)}
            isDisabled={true}
          />
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '0.75rem 1.5rem'
          }}
        >
          <div>
            <FormLabel>{t('BUDGETED_HOURS')}</FormLabel>
            <TextField
              className="w-full"
              type="number"
              placeholder={t('ENTER_BUDGETED_HOURS')}
              onChange={handleFormInputChange}
              value={formData.budgetedHours}
              name="budgetedHours"
            />
          </div>

          <div>
            <FormLabel>{t('ENTER_REVENUE')}</FormLabel>
            <div className="flex border-gray-300  dark:bg-dark-main4 border rounded pr-2 hover:border-primary-main">
              <input
                className="w-full pl-4 text-sm rounded dark:text-dark-light dark:bg-dark-main4"
                type="number"
                placeholder={t('ENTER_REVENUE')}
                onChange={handleFormInputChange}
                value={formData.cost}
                name="cost"
              />
              <Select
                styles={reactSelectCustomStyles(theme, selectStyles)}
                options={currencyOptions}
                // menuPosition="fixed"
                onChange={selectCurrency}
                value={selectedCurrency}
                maxMenuHeight={120}
                className="w-28"
                size="small"
                name="currency"
              />
            </div>
          </div>

          <div>
            <FormLabel className=" whitespace-nowrap">
              {t('START_DATE')}
            </FormLabel>

            <div>
              <DatePicker
                wrapperClassName={classes.datePicker}
                onChange={date => handleDateChange(date, 'startDate')}
                selected={
                  formData.startDate ? new Date(formData.startDate) : null
                }
                customInput={
                  <TextField
                    className="text-xs w-full"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <CalenderIcon className="text-base text-gray-600 cursor-pointer dark:text-dark-light" />
                        </InputAdornment>
                      )
                    }}
                  />
                }
                placeholderText={t('SELECT_DATE')}
                dateFormat="MMMM d, yyyy"
              />
            </div>
          </div>

          <div>
            <FormLabel className="whitespace-nowrap">{t('DUE_DATE')}</FormLabel>

            <div className="w-full">
              <DatePicker
                wrapperClassName={classes.datePicker}
                onChange={date => handleDateChange(date, 'dueDate')}
                selected={formData.dueDate ? new Date(formData.dueDate) : null}
                customInput={
                  <TextField
                    className="text-xs w-full"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <CalenderIcon className="text-base text-gray-600 cursor-pointer dark:text-dark-light" />
                        </InputAdornment>
                      )
                    }}
                  />
                }
                placeholderText={t('SELECT_DATE')}
                dateFormat="MMMM d, yyyy"
                minDate={
                  formData.startDate ? new Date(formData.startDate) : null
                }
              />
            </div>
          </div>

          <div>
            <FormLabel className="whitespace-nowrap">
              {t('PAYMENT_TYPE')}
            </FormLabel>

            <Select
              value={paymentOptions.find(
                item => item.value === formData.paymentTerm
              )}
              onChange={newVal =>
                setFormData(prev => ({ ...prev, paymentTerm: newVal.value }))
              }
              styles={reactSelectCustomStyles(theme)}
              options={paymentOptions}
              className="w-full"
            />
          </div>
        </div>

        <div className="mt-6" style={{ minHeight: 112 }}>
          <AdvanceSettings formData={formData} setFormData={setFormData} />
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          onClick={handleUpdateProject}
          loading={loading}
          style={{ color: '#fff' }}
          disabled={!formData.name || !permission?.workspace?.update}
        >
          {t('SAVE_CHANGES')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

const CustomMenu = props => {
  const { t } = useTranslation()
  return (
    <components.MenuList {...props}>
      <button
        onClick={props.selectProps.selectProps.inviteTeammate}
        className="text-sm text-primary-main dark:text-gray-400 flex items-center w-full py-2 px-4 dark:hover:bg-dark-main"
      >
        <AddIcon className="mr-1" />
        <span>
          {t('ADD_NEW')}{' '}
          {props.selectProps.selectProps.elmFor === 'agency'
            ? t('TEAM_MEMBER')
            : t('CLIENT')}
        </span>
      </button>
      {props.children}
    </components.MenuList>
  )
}

const AdvanceSettings = ({ formData, setFormData }) => {
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false)

  const toggleDueDateChangeHandler = e => {
    if (e.target.checked) {
      setFormData(prev => ({
        ...prev,
        restrictDueDate: {
          ...formData.restrictDueDate,
          isDueDateRestrict: e.target.checked
        }
      }))
    } else {
      setFormData(prev => ({
        ...prev,
        restrictDueDate: {
          isDueDateRestrict: e.target.checked,
          totalDueDateChangeCounter: null
        }
      }))
    }
  }

  const handleDueDateChange = e => {
    setFormData(prev => ({
      ...prev,
      restrictDueDate: {
        ...formData.restrictDueDate,
        totalDueDateChangeCounter: e.target.value
      }
    }))
  }

  const toggleRestrictMode = e => {
    setFormData(prev => ({
      ...prev,
      restrictMode: e.target.checked
    }))
  }

  return (
    <div className="sm:w-1/2">
      <button
        className="text-sm text-primary-main font-bold dark:text-dark-light flex items-center"
        onClick={() => setShowAdvanceSettings(prev => !prev)}
      >
        <span>{t('ADVANCE_SETTINGS')}</span>
        <span
          className={clsx(
            'ml-0.5 transition-transform duration-200 ease-in-out dark:text-dark-light',
            showAdvanceSettings && 'rotate-90'
          )}
        >
          <ChevronRightIcon className="dark:text-dark-light" />
        </span>
      </button>

      {showAdvanceSettings && (
        <>
          <div className="flex items-center justify-between py-1">
            <div>
              <p className="text-smaller 2xl:text-sm text-custom-gray-dark-3 font-semibold dark:text-dark-light">
                {t('RESTRICT_USERS_TO_CHANGE_THE_DUE_DATE')}
              </p>
            </div>

            <div>
              <ToggleSwitch
                checked={formData.restrictDueDate.isDueDateRestrict}
                onChange={toggleDueDateChangeHandler}
                loading={false}
              />
            </div>
          </div>

          {formData.restrictDueDate.isDueDateRestrict && (
            <div className="w-52 dark:text-dark-light">
              <RoundedTextInput
                size="tiny"
                name="dueDate"
                value={formData?.restrictDueDate.totalDueDateChangeCounter}
                type="number"
                onChange={handleDueDateChange}
                placeholder="Number of times due-date is editable"
                id="dueDate"
              />
            </div>
          )}

          {[
            userRoles.USER_AGENCY,
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_MANAGER
          ].includes(meData?.role) && (
            <div className="flex items-center justify-between py-1">
              <div className="flex items-center">
                <p className="text-smaller 2xl:text-sm text-custom-gray-dark-3 font-semibold mr-2 dark:text-dark-light">
                  {t('RESTRICT_MODE')}
                </p>
                <QuestionMarkTooltip
                  text={t('MEMBERS_WILL_BE_ABLE_TO_SEE_ONLY_TASKS')}
                  direction="right"
                  toolTipstyles="lowercase"
                  info={true}
                />
              </div>

              <div>
                <ToggleSwitch
                  checked={formData.restrictMode}
                  loading={false}
                  onChange={toggleRestrictMode}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const selectStyles = {
  control: {
    border: 'none',
    minHeight: '30px',
    '&:focus-within': {
      boxShadow: `0 0 0 1px transparent`
    }
  },
  singleValue: { fontSize: 12 },
  dropdownIndicator: { padding: 0, margin: 0 },
  option: {
    fontSize: '12px'
  }
}

const useStyles = makeStyles(theme => {
  const isDarkMode = theme.palette.type === 'dark'

  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
      // userSelect: 'none'
    },
    paper: {
      width: '80vw',
      height: '90vh',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none',
      '&.addProject': {
        height: '600px',
        width: '1000px',
        '@media (max-width: 1024px)': {
          width: '80vw'
        },
        '@media (max-width: 768px)': {
          height: '90%'
        }
      }
    },
    datePicker: {
      width: '100%',
      '& .react-datepicker': {
        backgroundColor: isDarkMode ? theme.custom.darkMode.dark2 : '#fff',
        color: isDarkMode ? theme.custom.darkMode.color : '#000'
      },
      '& .react-datepicker__header': {
        backgroundColor: isDarkMode ? theme.custom.darkMode.dark1 : '#fff',
        color: isDarkMode ? theme.custom.darkMode.color : '#000'
      }
    },
    fieldsContainer: {
      width: '100%',
      '@media (min-width: 768px)': {
        minWidth: '70%',
        flex: 1
      }
    }
  })
})

export default EditProjectModal
