import produce from 'immer'

export const FILES_ASSETS = {
  FETCHED: 'FILES_ASSETS_FETCHED',
  FOLDERS_FETCHED: 'FILES_ASSETS_FOLDERS_FETCHED',
  FILES_FETCHED: 'FILES_ASSETS_FILES_FETCHED',
  ADD_FOLDER: 'FILES_ASSETS_ADD_FOLDER',
  UPDATE_FOLDER: 'FILES_ASSETS_UPDATE_FOLDER',
  UPDATE_FILE: 'FILES_ASSETS_UPDATE_FILE',
  DELETE_FOLDER: 'FILES_ASSETS_DELETE_FOLDER',
  DELETE_FILE: 'FILES_ASSETS_DELETE_FILE',
  ADD_FILE: 'FILES_ASSETS_ADD_FILE',
  UPLOADING_FILE: 'FILES_ASSETS_UPLOADING_FILE',
  UPDATE_PERCENTAGE: 'FILES_ASSETS_UPDATE_PERCENTAGE',
  REMOVE_UPLOADING: 'FILES_ASSETS_REMOVE_UPLOADING',
  UPLOADING_COMPLETE: 'FILES_UPLOADING_COMPLETE',
  MOVE_FILE: 'FILES_ASSETS_MOVE_FILE',
  COPY_FILE: 'FILES_ASSETS_COPY_FILE',
  RESET: 'FILES_ASSETS_RESET',
  SET_ROOT_FOLDER: 'FILES_ASSETS_SET_ROOT'
}

const initialState = {
  loading: true,
  isActive: false,
  data: {},
  allStorage: {},
  uploadingFiles: [], //[{name: "<fileName>", percentage: "0-100%", isComplete: false}]
  rootDir: null
}

const fileAssetReducer = produce((draft, action) => {
  switch (action.type) {
    case FILES_ASSETS.FOLDERS_FETCHED: {
      const { parentDir, data } = action.payload

      data.forEach(folder => {
        draft.data[folder._id] = folder
      })

      if (parentDir === null) return draft

      const mappedData = data.map(item => item._id)
      if (draft.allStorage[parentDir]) {
        draft.allStorage[parentDir].folders = mappedData
      } else {
        draft.allStorage[parentDir] = {
          folders: mappedData,
          files: null
        }
      }

      return draft
    }

    case FILES_ASSETS.ADD_FOLDER: {
      const { parentDir, data } = action.payload

      if (parentDir) {
        draft.data[data._id] = data
        draft.allStorage[parentDir].folders.push(data._id)
      }
      return draft
    }

    case FILES_ASSETS.FILES_FETCHED: {
      const { parentDir, data } = action.payload

      if (draft.allStorage[parentDir]) {
        draft.allStorage[parentDir].files = data
      } else {
        draft.allStorage[parentDir] = {
          folders: null,
          files: data
        }
      }

      return draft
    }

    case FILES_ASSETS.UPDATE_FOLDER: {
      draft.data[action.payload._id] = action.payload
      return draft
    }

    case FILES_ASSETS.ADD_FILE: {
      const { parentDir, data } = action.payload
      if (!draft.allStorage[parentDir]) return draft

      if (draft.allStorage[parentDir].files) {
        draft.allStorage[parentDir].files.push(data)
      } else {
        draft.allStorage[parentDir].files = [data]
      }
      return draft
    }

    case FILES_ASSETS.UPDATE_FILE: {
      const { data, parentDir } = action.payload

      const index = draft.allStorage[parentDir].files.findIndex(
        item => item._id === data._id
      )
      if (index === -1) return draft

      const fileUrl = draft.allStorage[parentDir].files[index].url //use the prev transformed url

      draft.allStorage[parentDir].files.splice(index, 1, {
        ...data,
        url: fileUrl
      })
      return draft
    }

    case FILES_ASSETS.DELETE_FOLDER: {
      const { id } = action.payload
      delete draft.data[id]
      return draft
    }

    case FILES_ASSETS.DELETE_FILE: {
      const { parentDir, id } = action.payload
      const index = draft.allStorage[parentDir].files.findIndex(
        item => item._id === id
      )

      if (index === -1) return
      draft.allStorage[parentDir].files.splice(index, 1)
      return draft
    }

    case FILES_ASSETS.UPLOADING_FILE:
      draft.uploadingFiles.push(action.payload)
      return draft

    case FILES_ASSETS.UPDATE_PERCENTAGE:
      for (let file of draft.uploadingFiles) {
        if (file.uid === action.payload.uid) {
          file.percentage = action.payload.percentage
          return draft
        }
      }
      return draft

    case FILES_ASSETS.REMOVE_UPLOADING:
      const index = draft.uploadingFiles.findIndex(
        item => item.uid === action.payload
      )
      if (index !== -1) {
        draft.uploadingFiles.splice(index, 1)
      }
      return draft

    case FILES_ASSETS.UPLOADING_COMPLETE: {
      const index = draft.uploadingFiles.findIndex(
        item => item.uid === action.payload
      )

      if (index !== -1) {
        draft.uploadingFiles[index].isComplete = true
      }
      return draft
    }

    case FILES_ASSETS.MOVE_FILE: {
      const { newFileAssetsFolder, oldFileAssetsFolder, file } = action.payload
      const oldIndex = draft.currentWSAssets.data[
        oldFileAssetsFolder
      ].files.findIndex(item => item._id === file._id)
      draft.currentWSAssets.data[oldFileAssetsFolder].files.splice(oldIndex, 1)
      draft.currentWSAssets.data[newFileAssetsFolder].files.push(file)
      return draft
    }
    case FILES_ASSETS.COPY_FILE: {
      const { newFileAssetsFolder, file } = action.payload
      draft.currentWSAssets.data[newFileAssetsFolder].files.push(file)
      return draft
    }

    case FILES_ASSETS.RESET: {
      draft.allStorage = {}
      draft.data = {}
      draft.loading = true
      return draft
    }

    case FILES_ASSETS.SET_ROOT_FOLDER: {
      draft.rootDir = action.payload

      return draft
    }

    default:
      return draft
  }
}, initialState)

export default fileAssetReducer
