import React from 'react'
import clsx from 'clsx'
import { ReactComponent as ArrowRight } from 'static/svg/angle-right.svg'
import { useTranslation } from 'react-i18next'

const Pagination = ({
  page,
  handlePagination,
  totalResultsCount,
  limit = 5
}) => {
  const { t } = useTranslation()
  const totalBtns = Math.ceil(totalResultsCount / limit)

  const paginate = e => {
    const type = e.currentTarget.dataset.type

    if (type === 'next') {
      handlePagination(page + 1)
    } else {
      handlePagination(page + -1)
    }
  }

  const jumpTo = e => {
    handlePagination(Number(e.target.dataset.page))
  }

  if (totalResultsCount < limit) return null
  return (
    <nav
      className="bg-white rounded-xl py-4 flex items-center justify-between pr-12 border shadow dark:bg-dark-main2 overflow-x-auto"
      aria-label="Pagination"
    >
      <div className="flex-1 flex justify-end text-smaller 2xl:text-sm">
        <button
          data-type="prev"
          onClick={paginate}
          disabled={page === 0}
          className={clsx(
            'flex items-center font-semibold mr-4',
            page === 0
              ? 'text-primary-mid-light dark:text-dark-main1'
              : 'text-primary-main dark:text-dark-light2'
          )}
        >
          <ArrowRight className="rotate-180 text-lg mt-0.5" />
          <span>{t('PREVIOUS')}</span>
        </button>

        <div className="flex items-center space-x-2">
          {Array(totalBtns)
            .fill('')
            .map((item, index) => (
              <PaginationButton
                key={index}
                index={index}
                isActive={page === index}
                onClick={jumpTo}
              />
            ))}
        </div>

        <button
          data-type="next"
          onClick={paginate}
          disabled={(page + 1) * limit + 1 > totalResultsCount}
          className={clsx(
            'flex items-center font-semibold ml-4',
            (page + 1) * limit + 1 > totalResultsCount
              ? 'text-primary-mid-light dark:text-dark-main1'
              : 'text-primary-main dark:text-dark-light2'
          )}
        >
          <span>{t('NEXT')}</span>
          <ArrowRight className="text-lg mt-0.5" />
        </button>
      </div>
    </nav>
  )
}

const PaginationButton = ({ index, isActive, onClick }) => {
  return (
    <div
      className={clsx(
        'w-6 h-6  rounded-lg flex justify-center items-center cursor-pointer font-semibold ',
        isActive
          ? 'text-primary-main bg-primary-light  dark:text-black'
          : 'text-mid-dark-2 dark:text-dark-main1'
      )}
      data-page={index}
      onClick={onClick}
    >
      {index + 1}
    </div>
  )
}

export default React.memo(Pagination)
