import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { CLIENT } from 'thunks/addClient/reducers'
import { fetchClientInfo } from 'thunks/addClient/actions'
import { ColoredAvatars, CustomTabs, GoBack } from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import ProjectCardsPage from 'components/_agency/ClientCompanyComponents/ProjectCardsPage'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { useTranslation } from 'react-i18next'
import ClientInvoices from './ClientInvoices'
import TeamMembers from './TeamMembers'
import { usePermission } from 'customHooks'
import ClientEmbed from './Embed/ClientEmbed'
import { fetchClientEmbedsById } from 'thunks/embed/action'

const PROJECTS = 'projects',
  MEMBERS = 'members',
  INVOICES = 'invoices',
  EMBED = 'embed'

const ClientProfile = () => {
  const { t } = useTranslation()
  const { clientId } = useParams()
  const dispatch = useDispatch()
  const allClientsData = useSelector(state => state.userClients)
  const [clientInfo, setClientInfo] = useState({
    loading: true,
    data: {}
  })
  const clientData = useSelector(state => state.userClients.currentClient)
  const userWorkspaces = useSelector(state => state.userWorkspaces)

  const [selectedTab, setSelectedTab] = useState(PROJECTS)
  const { billings, clients } = usePermission()

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [userWorkspaces.fetchedFirstTime])

  useEffect(() => {
    dispatch(fetchClientEmbedsById({ id: clientId }))
  }, [])

  useEffect(() => {
    if (allClientsData.currentClient?._id) {
      fetchClientInfo(
        { clientId: allClientsData.currentClient._id },
        (res, err) => {
          if (!err) {
            setClientInfo({ loading: false, data: res })
          } else {
            dispatch(
              fireErrorToaster(t('ERROR_OCCURED_WHILE_FETCHING_CLIENT_INFO'))
            )
          }
        }
      )
    }
  }, [allClientsData.currentClient])

  useEffect(() => {
    if (allClientsData.fetched) {
      dispatch({ type: CLIENT.ADD_CURRENT_CLIENT, payload: clientId })
    }
  }, [clientId, allClientsData.fetched])

  const handleTabChange = newVal => {
    setSelectedTab(newVal)
  }

  const tabItems = [
    { label: t('PROJECTS'), value: PROJECTS },
    { label: t('MEMBERS'), value: MEMBERS }
  ]

  if (billings.view) {
    tabItems.push({ label: t('INVOICES'), value: INVOICES })
  }

  if (clients.update) {
    tabItems.push({ label: t('EMBED'), value: EMBED })
  }

  return (
    <OuterContentWrapper>
      {allClientsData.currentClient && (
        <>
          <GoBack />
          <div className="w-full bg-white rounded-xl shadow px-8 py-8 mb-9 mt-4 flex flex-wrap items-center gap-3 dark:bg-dark-main3">
            <ColoredAvatars
              user={allClientsData.currentClient}
              size="extraLarge"
            />
            <div className="max-w-full">
              <p className="text-primary-dark truncate font-semibold capitalize text-sm md:text-base 2xl:text-lg dark:text-dark-light2">
                {allClientsData.currentClient.name}
              </p>
              <p className="text-custom-gray-light-6 truncate text-xs 2xl:text-sm dark:text-dark-light">
                {allClientsData.currentClient.email}
              </p>
            </div>
          </div>
          <div>
            <div className="w-full mb-6">
              <CustomTabs
                tabsData={tabItems}
                value={selectedTab}
                onChange={handleTabChange}
              />
            </div>
          </div>

          <div>
            {selectedTab === PROJECTS && (
              <ProjectCardsPage
                userWorkspaces={userWorkspaces.data}
                clientInfo={clientInfo}
                clientData={clientData}
                elmFor="client"
              />
            )}
            {selectedTab === MEMBERS && (
              <TeamMembers
                clientInfo={clientInfo.data}
                setClientInfo={setClientInfo}
              />
            )}
            {selectedTab === INVOICES && <ClientInvoices />}
            {selectedTab === EMBED && <ClientEmbed />}
          </div>
        </>
      )}
    </OuterContentWrapper>
  )
}

export default ClientProfile
