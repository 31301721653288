import { Button, SvgIcon } from '@material-ui/core'
import { ReactComponent as PlayCircle } from 'static/svg/play-circle.svg'
import { userRoles } from 'utils'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { ReactComponent as Setting } from 'static/svg/setting.svg'
import { fireWarningToaster } from 'thunks/fireToaster/actions'
import { PopupMenu } from 'global/globalComponents'
import TeammateOnboardingLinkModal from './TeammateOnboardingLinkModal'
import { useTranslation } from 'react-i18next'

const TeammatesHeader = ({ openAddTeammatePanel }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const access = useSelector(state => state.permission.data.access)
  const me = useSelector(state => state.me)
  const [addTeammateValid, setAddTeammateValid] = useState(null)
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false)

  useEffect(() => {
    if (!me.loading) {
      ;(me.data.profile?.quota?.teammate?.left ??
        me.data.team?.profile?.quota?.teammate?.left) <= 0
        ? setAddTeammateValid(false)
        : setAddTeammateValid(true)
    }
  }, [me])

  const teammateValidMessage = () => {
    dispatch(
      fireWarningToaster(
        'Your teammate quota has been exhausted. Please upgrade your plan to add new teammates'
      )
    )
  }

  const toggleTeammateOnboardingModal = () => {
    setOpenOnboardingModal(prev => !prev)
  }

  return (
    <>
      <div>
        <div className="flex flex-wrap justify-between items-center mb-6 2xl:mb-8">
          <div className="flex items-center">
            <SectionHeading>{t('TEAM')}</SectionHeading>
            {me.data.role === userRoles.AGENCY_MANAGER && (
              <a
                href="https://youtu.be/46wH7yZ-IEA"
                target="_blank"
                className="pl-1"
                rel="noreferrer"
              >
                <SvgIcon
                  component={PlayCircle}
                  style={{
                    fontSize: '1rem',
                    marginBottom: '3px'
                  }}
                  className="cursor-pointer text-primary-main"
                  viewBox="0 0 600 476.6"
                />
              </a>
            )}
          </div>
          {access?.teammate.create ? (
            me.data.role === userRoles.USER_CLIENT &&
            (me.data.canAddTeammates !== undefined
              ? !me.data.canAddTeammates
              : false) ? null : (
              <div className="flex items-center space-x-4">
                <Button
                  // variant="outlined"
                  startIcon={<AddIcon fontSize="small" />}
                  onClick={
                    addTeammateValid
                      ? openAddTeammatePanel
                      : teammateValidMessage
                  }
                >
                  {t('TEAM_MEMBER')}
                </Button>
                {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
                  me.data.role
                ) && (
                  <PopupMenu
                    targetComp={
                      <button className="sm:w-8 h-8 rounded-full text-primary-mid-dark-2 flex justify-center items-center">
                        <Setting className="text-lg dark:text-dark-light" />
                      </button>
                    }
                    menuItems={[
                      // { label: 'Manage Roles', method: openAddVerticalModal },
                      {
                        label: t('ONBOARDING'),
                        method: toggleTeammateOnboardingModal
                      }
                    ]}
                  />
                )}
              </div>
            )
          ) : null}
        </div>
        <TeammateOnboardingLinkModal
          open={openOnboardingModal}
          handleClose={toggleTeammateOnboardingModal}
        />
      </div>
    </>
  )
}

export default TeammatesHeader
