import { useEffect, useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSingleClientInvoices } from 'thunks/invoices/actions'
import InvoiceTable from 'components/Invoices/InvoiceTable/InvoiceTable'
import { useHistory } from 'react-router-dom'
import { NoAccess } from 'static/Images'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { Button } from '@material-ui/core'
import { toggleCreateInvoiceModal } from 'thunks/generlAppState/actions'
import CreateInvoiceModal from 'components/Invoices/CreateInvoices/CreateInvoiceModal'
import { useTranslation } from 'react-i18next'

const ClientInvoices = () => {
  // const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentClient } = useSelector(state => state.userClients)
  const access = useSelector(state => state.permission.data?.access)
  const [invoices, setInvoices] = useState({
    loading: true,
    data: []
  })
  const [selectedTab, setSelectedTab] = useState('all')
  const history = useHistory()
  const [invoiceData, setInvoiceData] = useState({
    paid: [],
    pending: [],
    draft: [],
    overdue: []
  })

  useEffect(() => {
    if (currentClient?._id) {
      fetchSingleClientInvoices({ id: currentClient._id }, (res, err) => {
        if (!err) {
          setInvoices({ loading: false, data: res })
        } else {
          setInvoices({ loading: false, data: [] })
        }
      })
    }
  }, [currentClient?._id])

  useEffect(() => {
    const invoiceGroup = {
      paid: [],
      pending: [],
      draft: [],
      overdue: []
    }

    for (let invoice of invoices.data) {
      if (invoiceGroup[invoice.status]) {
        invoiceGroup[invoice.status].push(invoice)
      }
    }

    setInvoiceData(invoiceGroup)
  }, [invoices])

  const openInvoicePreview = invoice => {
    history.push(`/agency/payments/client-billing/i/${invoice._id}`)
  }

  const handleEditInvoice = invoice => {
    history.push(`/agency/payments/client-billing/i/${invoice._id}/edit`)
  }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const markAsPaidCallback = invoice => {
    setInvoices({
      loading: false,
      data: invoices.data.map(item => {
        if (item._id === invoice._id) return invoice
        return item
      })
    })
  }

  const deleteInvoiceCallback = invoiceId => {
    setInvoices({
      loading: false,
      data: invoices.data.filter(item => item._id !== invoiceId)
    })
  }

  const handleCreateInvoice = () => {
    dispatch(toggleCreateInvoiceModal('stripe'))
  }

  const tabArr = [
    { label: t('ALL'), value: 'All' },
    { label: t('DRAFT'), value: 'Draft' },
    { label: t('PENDING'), value: 'Pending' },
    { label: t('PAID'), value: 'Paid' },
    { label: t('OVERDUE'), value: 'Overdue' }
  ]

  return (
    <>
      {currentClient?._id ? (
        <div>
          <div className="mb-4 flex items-center justify-between dark:text-dark-light">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              {tabArr.map((item, index) => (
                <Tab
                  label={item.label}
                  value={item.value.toLowerCase()}
                  key={index}
                />
              ))}
            </Tabs>

            {access?.billings.create && (
              <Button
                size="small"
                variant="outlined"
                onClick={handleCreateInvoice}
                startIcon={<AddIcon fontSize="small" />}
              >
                {t('CREATE_INVOICE')}
              </Button>
            )}
          </div>

          <InvoiceTable
            invoices={
              selectedTab === 'all' ? invoices.data : invoiceData[selectedTab]
            }
            openInvoicePreview={openInvoicePreview}
            handleEditInvoice={handleEditInvoice}
            markAsPaidCallback={markAsPaidCallback}
            deleteInvoiceCallback={deleteInvoiceCallback}
            clientCol={false}
            loading={invoices.loading}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <div
            style={{ width: '100%', maxHeight: '300px' }}
            className="bg-white my-auto dark:bg-dark-main4"
          >
            <img
              src={NoAccess}
              alt="No Access"
              className="w-full h-full object-contain"
              style={{
                maxWidth: '800px',
                maxHeight: '300px',
                margin: 'auto'
              }}
            />
          </div>
        </div>
      )}
      <CreateInvoiceModal
        currentClient={currentClient}
        handleEditInvoice={handleEditInvoice}
        handleViewInvoice={openInvoicePreview}
      />
    </>
  )
}

export default ClientInvoices
