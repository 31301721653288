import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPopper, Loader, LoadingButton } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import { fetchAllWorkflowTemplates } from 'thunks/onboarding/actions'
import { updateService } from 'thunks/service/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

const AttachWorkflowPopper = ({ anchorEl, onClose, ...rest }) => {
  if (!anchorEl) return null

  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <AttachWorkflowPopperContent {...rest} onClose={onClose} />
    </CustomPopper>
  )
}

const AttachWorkflowPopperContent = ({ onClose, serviceId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const onboarding = useSelector(state => state.onboarding)
  const [loadingWorkflowId, setLoadingWorkflowId] = useState(null)

  useEffect(() => {
    if (!onboarding.fetched) {
      dispatch(fetchAllWorkflowTemplates())
    }
  }, [onboarding.fetched])

  const handleAttachWorkflow = workflowId => {
    setLoadingWorkflowId(workflowId)

    dispatch(
      updateService(
        {
          id: serviceId,
          data: {
            clientOnboarding: workflowId
          }
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            setLoadingWorkflowId(null)
          } else {
            onClose()
          }
        }
      )
    )
  }

  return (
    <div className="w-72">
      <div className="px-4 py-2 border-b">
        <div className="text-smaller font-medium text-gray-600 mr-1 capitalize">
          {t('ATTACH_WORKFLOW')}
        </div>
      </div>

      <div className="px-4 py-2">
        {!onboarding.fetched ? (
          <Loader />
        ) : (
          <ul>
            {onboarding.data.map(item => (
              <li key={item._id} className="py-1 show-on-hover-parent">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600 dark:text-dark-light">
                    {item.templateName}
                  </span>
                  <LoadingButton
                    size="small"
                    variant="outlined"
                    className="show-on-hover-child"
                    onClick={() => handleAttachWorkflow(item._id)}
                    loading={loadingWorkflowId === item._id}
                    disabled={Boolean(loadingWorkflowId)}
                  >
                    {t('ATTACH')}
                  </LoadingButton>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default AttachWorkflowPopper
