class Validator {
  isPasswordValid(password) {
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      return true
    } else {
      return false
    }
  }

  isEmailValid(email) {
    return /^\S+@\S+\.\S+$/.test(email)
  }

  isURL(url) {
    return /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(
      url
    )
  }
}

const validator = new Validator()
export default validator
