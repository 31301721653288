import axios from 'axios'
import { Uploader, catchAsync, catchAsyncDispatch } from 'utils'
import { SUBTASKS } from './reducers'
import { TASKS } from 'thunks/task/reducers'

/**
 * fetch all subtask of a task
 * @param {String} id  task_id
 * @param {Function} callback callback function
 */
export const getAllSubTask = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    dispatch({ type: SUBTASKS.LOADING })
    const res = await axios({
      method: 'GET',
      url: `/subtask/task/${data.taskId}`
    })

    dispatch({ type: SUBTASKS.FETCHED, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete a task
 * @param {String} data.id subtask id
 * @param {Function} callback callback function
 */
export const deleteSubTaskByID = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/subtask/${data.id}`
    })
    dispatch({ type: SUBTASKS.DELETE_ONE_TASK, payload: data.id })
    dispatch({
      type: TASKS.REMOVE_ONE_SUBTASK_COUNT,
      payload: { taskId: res.data.task }
    })

    if (res.data.markAsComplete) {
      dispatch({
        type: TASKS.REMOVE_ONE_COMPLETED_SUBTASK,
        payload: { taskId: res.data.task }
      })
    }

    if (callback) callback(res.data)
  }, callback)
}

/**
 * update Subtask  assigned team
 * @param {string} data.id subtask id
 * @param {Object} data.data update
 * @param {Function} callback callback function
 */

export const updateSubTaskTeammate = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/subtask/addteammate/${data.id}`,
      data: data.data
    })
    if (callback) callback(res.data)
    dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
  }, callback)
}

/**
 * update Subtask  assigned team
 * @param {string} data.id subtask id
 * @param {Object} data.data  assigned teammate id
 * @param {Function} callback callback function
 */
export const deleteSubtaskCollaborator = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/subtask/deleteteammate/${data.id}`,
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
  }, callback)
}

/**
 * update Subtask  details
 * @param {String} data.subtaskId sub task id
 * @param {Object} data.data  user,workspace_id,subtask title,task id
 * @param {String} data.updateField title, markAsComplete etc
 * @param {Function} callback callback function
 */

export const updateSubTaskDetails = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `subtask/${data.subtaskId}`,
      data: data.data
    })
    if (callback) callback(res.data, false)
    dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })

    if (data.updateField === 'markAsComplete') {
      dispatch({
        type: data.data.markAsComplete
          ? TASKS.ADD_ONE_COMPLETED_SUBTASK
          : TASKS.REMOVE_ONE_COMPLETED_SUBTASK,
        payload: { taskId: res.data.task }
      })
    }
  }, callback)
}
/**
 * Add a new Subtask
 * @param {Object} data  user,workspace_id,subtask title,task id
 * @param {Function} callback callback function
 */
export const createSubTask = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/subtask',
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: SUBTASKS.ADD_ONE_TASK, payload: res.data })
    dispatch({
      type: TASKS.ADD_ONE_SUBTASK_COUNT,
      payload: { taskId: res.data.task }
    })
  }, callback)
}

/**
 * Update sub task Priority
 * @param {String} subtaskId subtask id
 * @param {object} data  sub task priority
 * @param {Function} callback callback function
 */
export const updateSubTaskPriority = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/subtask/taskPriority/${data.id}`,
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
  }, callback)
}

/**
 * upload  sub task  attachment
 * @param {Object} data workspace id,task id,file url
 * @param {Function} callback callback function
 */

export const uploadSubTaskAttachment = (fileData, data, callback) => {
  return async dispatch => {
    const uploader = new Uploader({
      ...fileData,
      module: 'subTask',
      metaData: data
    })

    uploader
      .onError(error => {
        callback(error.message ?? 'File uploading failed!', true)
      })
      .onComplete(res => {
        // res has 2 keys => link (image url) and data (subtask data)
        callback(res)
        dispatch({
          type: SUBTASKS.UPLOAD_SUBTASK_ATTACHMENT,
          payload: res.data
        })
      })

    uploader.start()
  }
}

/**
 * Delete a task
 * @param {String} data.subtaskId
 * @param {String} data.fileUrl attachment url
 * @param {Function} callback
 */
export const deleteSubtaskAttachment = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `subtask/delete/Attachment?subtask=${data.subtaskId}&url=${data.fileUrl}`
    })

    dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Get Subtask by id
 * @param {String} data.id subtask id
 * @param {Function} callback callback function
 */
export const fetchSubtaskById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/subtask/${data.id}`
  })

  if (callback) callback(res.data)
})

export const clearSubtasksData = () => {
  return { type: SUBTASKS.RESET_DATA }
}
