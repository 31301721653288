import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// status => saving, saved, failed
const AutoSavingIndicator = ({ status }) => {
  const { t } = useTranslation()
  const [remove, setRemove] = useState(false)

  useEffect(() => {
    setRemove(false)
    let timer = setTimeout(() => {
      if (status === 'saved' || status === 'failed') {
        setRemove(true)
      }
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [status])

  if (remove || !status) return null

  return (
    <div className="flex items-center text-xs">
      {status === 'saving' ? (
        <CircularProgress size={12} />
      ) : status === 'saved' ? (
        <CheckCircle className="w-4 h-4 text-green-500" />
      ) : (
        <CrossIcon className="w-4 h-4 text-red-500 border rounded-full border-red-400" />
      )}
      <span
        className={clsx(
          'ml-2',
          status === 'saved'
            ? 'text-green-500'
            : status === 'saving'
            ? 'text-gray-500 dark:text-dark-light'
            : 'text-red-500'
        )}
      >
        {status === 'saving'
          ? t('AUTO_SAVING')
          : status === 'saved'
          ? t('AUTO_SAVED')
          : t('SAVING_FAILED')}
      </span>
    </div>
  )
}

export default AutoSavingIndicator
