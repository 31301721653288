import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { Switch, Route } from 'react-router-dom'
import userRoles, { getUserPath } from 'utils/userRoles'
import Connect from 'components/Invoices/StripeConnect/Connect'
import { Services } from 'components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ServiceIcon } from 'static/svg/flash.svg'
import { ReactComponent as InvoicesIcon } from 'static/svg/invoice.svg'
import { ReactComponent as TicketIcon } from 'static/svg/ticket.svg'
import { ReactComponent as BellIcon } from 'static/svg/bell.svg'
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CouponsSection from '../CouponsSection/CouponsSection'
import SubscriptionsSection from '../SubscriptionsSection/SubscriptionsSection'

const PaymentsSection = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(
    pathname.includes('/services/all') ? `${path}/services/all` : pathname
  )
  const meData = useSelector(state => state.me.data)
  const permission = useSelector(state => state.permission.data)
  const userPath = getUserPath(meData.role)

  const tabsData = [
    {
      label: t('INVOICES'),
      value: path + '/client-billing/all',
      icon: <InvoicesIcon />,
      access: permission.access?.billings
    },
    {
      label: t('SERVICES'),
      value: path + '/services/all',
      icon: <ServiceIcon />,
      access: permission.access?.services
    },
    {
      label: t('SUBSCRIPTIONS'),
      value: path + '/subscriptions',
      icon: <BellIcon />,
      access: [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
        meData.role
      )
        ? { view: true }
        : null
    },
    {
      label: t('COUPONS'),
      value: path + '/coupons',
      icon: <TicketIcon />,
      access: [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
        meData.role
      )
        ? { view: true }
        : null
    }
  ]

  useEffect(() => {
    // We want this tab to be active when there is a url change in the services section
    setSelectedTab(
      pathname.includes('/services/all') ? `${path}/services/all` : pathname
    )
  }, [pathname])

  const handleTabChange = (e, newVal) => {
    // setSelectedTab(newVal)
    history.push(newVal)
  }

  return (
    <OuterContentWrapper>
      <SectionHeading className="mb-3 2xl:mb-4">{t('PAYMENTS')}</SectionHeading>
      <div>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {tabsData
            .filter(
              tab =>
                tab.access?.create ||
                tab.access?.update ||
                tab.access?.view ||
                tab.access?.delete
            )
            .map((item, index) => (
              <Tab
                label={
                  <div className="flex items-center gap-1">
                    <span className="dark:text-dark-light">{item.icon}</span>{' '}
                    <span className="dark:text-dark-light">{item.label}</span>
                  </div>
                }
                key={index}
                value={item.value}
              />
            ))}
        </Tabs>
      </div>
      <Switch>
        <Route
          exact
          path={`${userPath}/payments/client-billing/all`}
          component={Connect}
        />

        <Route
          path={`${userPath}/payments/services/all`}
          component={Services}
        />

        <Route
          path={`${userPath}/payments/subscriptions`}
          component={SubscriptionsSection}
        />

        {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
          meData.role
        ) && (
          <Route
            path={`${userPath}/payments/coupons`}
            component={CouponsSection}
          />
        )}
      </Switch>
    </OuterContentWrapper>
  )
}

export default PaymentsSection
