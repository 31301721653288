import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

/* Possible Statuses:
  1. Approved (approved by all): Green tab color
  2. Pending (partially approved or not taken any action yet)
  3. Rejected (rejected by one)
*/

const ApprovalStageTab = ({ label, isActive, status, onClick }) => {
  const classes = useStyles({ status, isActive })

  return (
    <div
      role="button"
      onClick={onClick}
      className={clsx(
        'px-10 py-1.5 text-smaller relative text-white',
        classes.root
      )}
    >
      {label}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: ({ isActive, status }) => ({
    '--tab-color': isActive ? 'blue' : '#aaa6a6',
    '--tab-background':
      theme.palette.type === 'dark' ? theme.custom.darkMode.dark3 : 'white',
    background: 'var(--tab-color)',

    '&::before': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      border: '15.5px solid',
      borderColor: `var(--tab-color) var(--tab-color) var(--tab-color) var(--tab-background)`
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      border: '15.5px solid',
      borderColor:
        'var(--tab-background) var(--tab-background) var(--tab-background) var(--tab-color)'
    }
  })
}))

export default ApprovalStageTab
