import React, { useState } from 'react'
import SubscriptionsTable from './SubscriptionsTable'
import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import { useTranslation } from 'react-i18next'
import { PopupMenu } from 'global/globalComponents'
import { Button } from '@material-ui/core'
import NewSubscriptionModal from './NewSubscriptionModal'
import { useModal } from 'customHooks'

const SubscriptionsSection = () => {
  const { t } = useTranslation()
  const filterOptions = [
    { label: t('ALL'), value: 'all' },
    { label: t('ACTIVE'), value: 'active' },
    { label: t('PAUSED'), value: 'paused' },
    { label: t('CANCELED'), value: 'canceled' }
  ]
  const [selectedStatus, setSelectedStatus] = useState(filterOptions[0])
  const { modal, openModal, closeModal } = useModal()

  return (
    <div>
      <div className="my-4 flex justify-end space-x-4">
        <PopupMenu
          targetComp={
            <Button
              variant="outlined"
              // onClick={e => setAnchorEl(e.currentTarget)}
              className="flex items-center text-sm cursor-pointer opacity-90 hover:opacity-100 text-gray-600 font-medium capitalize dark:text-dark-light dark:hover:text-dark-light2"
            >
              <FilterIcon className="mr-1" />
              {selectedStatus.label}
            </Button>
          }
          menuItems={filterOptions.map(item => ({
            ...item,
            method: () => setSelectedStatus(item)
          }))}
        />
        <Button startIcon={<PlusIcon />} onClick={() => openModal()}>
          {t('NEW')}
        </Button>
      </div>
      <NewSubscriptionModal open={modal.open} onClose={closeModal} />
      <SubscriptionsTable selectedStatus={selectedStatus.value} />
    </div>
  )
}

export default SubscriptionsSection
