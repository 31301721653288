import React from 'react'
import { Button } from '@material-ui/core'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import { useTranslation } from 'react-i18next'

const CouponsSectionHeader = ({ openModal }) => {
  const { t } = useTranslation()

  return (
    <div className="mb-4 mt-3 flex justify-end">
      <Button
        onClick={() => openModal()}
        startIcon={<PlusIcon className="w-5 h-5" />}
      >
        {t('ADD')}
      </Button>
    </div>
  )
}

export default CouponsSectionHeader
