import React, { useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as MinusIcon } from 'static/svg/minus.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { Button, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useModal } from 'customHooks'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { FormLabel } from 'global/globalComponents'

const FAQ = ({
  classes,
  data,
  isEditable,
  updateTemplateSection,
  updateSectionBlock,
  addBlockItem,
  removeBlockItem
}) => {
  const { t } = useTranslation()
  const { modal, openModal, closeModal } = useModal()

  return (
    <>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>
          <ContentEditable
            html={data.heading}
            onChange={e =>
              updateTemplateSection({ field: 'heading', value: e.target.value })
            }
            disabled={!isEditable}
            className={classes.sectionHeading}
          />
          <ContentEditable
            html={data.subHeading}
            onChange={e =>
              updateTemplateSection({
                field: 'subHeading',
                value: e.target.value
              })
            }
            disabled={!isEditable}
            className={classes.sectionSubHeading}
          />
        </div>

        <div className={classes.faqBlocks}>
          {data.blocks.map((block, index) => (
            <div key={block.id} className={classes.gridBlockText}>
              {index > 4 && isEditable && (
                <button
                  onClick={() => {
                    removeBlockItem({ blockId: block.id })
                  }}
                  style={{ position: 'absolute', top: 0, right: 0 }}
                >
                  <CrossIcon />
                </button>
              )}
              <ContentEditable
                html={block.heading}
                onChange={e =>
                  updateSectionBlock({
                    blockId: block.id,
                    field: 'heading',
                    value: e.target.value
                  })
                }
                disabled={!isEditable}
              />
              <ContentEditable
                html={block.description}
                onChange={e =>
                  updateSectionBlock({
                    blockId: block.id,
                    field: 'description',
                    value: e.target.value
                  })
                }
                disabled={!isEditable}
              />
            </div>
          ))}
        </div>

        {isEditable && (
          <div className="mt-8 text-right">
            <Button onClick={openModal} startIcon={<AddIcon />}>
              {t('ADD')}
            </Button>
          </div>
        )}
      </div>

      {modal.open && (
        <AddFAQModal
          open={true}
          onClose={closeModal}
          addBlockItem={addBlockItem}
        />
      )}
    </>
  )
}

const AddFAQModal = ({ open, onClose, addBlockItem }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    question: '',
    answer: ''
  })

  const handleAddFaq = () => {
    if (!formData.question) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    addBlockItem({
      newItem: {
        heading: formData.question,
        description: formData.answer
      }
    })

    onClose()
  }

  return (
    <CustomModal size="small" open={open} handleClose={onClose}>
      <CustomModalHeader heading={`${t('ADD')} FAQ`} handleClose={onClose} />
      <CustomModalBody>
        <div className="mb-2">
          <FormLabel required>{t('QUESTION')}</FormLabel>
          <TextField
            value={formData.question}
            onChange={e =>
              setFormData(prev => ({ ...prev, question: e.target.value }))
            }
            fullWidth
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('ANSWER')}</FormLabel>
          <TextField
            value={formData.answer}
            onChange={e =>
              setFormData(prev => ({ ...prev, answer: e.target.value }))
            }
            multiline
            fullWidth
            rows={4}
          />
        </div>

        <Button onClick={handleAddFaq}>{t('ADD')}</Button>
      </CustomModalBody>

      <CustomModalFooter></CustomModalFooter>
    </CustomModal>
  )
}
export default FAQ
