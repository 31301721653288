import axios from 'axios'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { EMBEDS } from './reducer'
import { TASKS } from 'thunks/task/reducers'
import { WORKSPACES } from 'thunks/workspace/reducers'
import { t } from 'i18next'
import { catchAsync, catchAsyncDispatch } from 'utils'
/**
 * Generate Embed
 * @param {object} data
 * @param {Function} callback callback function
 */
export const generateEmbed = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const res = await axios({
      method: 'POST',
      url: `/workspaceview`,
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({ type: EMBEDS.ADD, payload: res.data })
  }, callback)
}

/**
 * Fetch Embed
 * @param {String} data.id workspace id
 * @param {Function} callback callback function
 */
export const fetchEmbed = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/workspaceview/workspace/${data.id}`
    })

    dispatch({ type: EMBEDS.FETCHED, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch Embed
 * @param {String} id embed id
 * @param {Function} callback callback function
 */
export const fetchSingleEmbedById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/workspaceview/${data.id}`
  })

  if (callback) callback(res.data)
})

/**
 * Update Embed
 * @param {String} data.id embed id
 * @param {Object} data.data update data
 * @param {Function} callback callback function
 */
export const updateEmbed = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspaceview/${data.id}`,
      data: data.data
    })

    dispatch({ type: EMBEDS.UPDATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update Embed
 * @param {String} data.id board id
 * @param {Array} data.data array of objects [{name: "groupName", user: meID}]
 * @param {Function} callback callback function
 */
export const saveBoardView = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspaceview/${data.id}`,
      data: {
        groupedByDetail: data.data
      }
    })

    dispatch({ type: EMBEDS.UPDATE_GROUP_BY, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete Embed
 * @param {id} deleteEmbedId
 * @param {Function} callback callback function
 */
export const deleteEmbed = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/workspaceview/${data.id}`
    })

    dispatch({ type: EMBEDS.DELETE, payload: res.data })
    dispatch(fireSuccessToaster(t('DELETED_SUCCESSFULLY')))
    if (callback) callback(res.data)
  }, callback)
}

/**
 * creates new workspace
 * @param {Object} data New section data
 * @param {String} data.label Workspace section label
 * @param {Number} data.order Workspace section label
 * @param {String} data.workSpace Workspace section label
 *
 * @param {Function} callback callback function
 */

export const createWorkspaceSection = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/workspacesection`,
      data: data.data
    })

    if (callback) callback(res.data)
    dispatch({
      type: EMBEDS.BOARD_SECTION,
      payload: res.data
    })
  }, callback)
}

/**
 * Update workspace section data
 * @param {String} id Section id
 * @param {Object} data Updating data object
 * @param {Function} callback callback function
 */
export const updateWorkspaceSection = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `workspacesection/${data.id}`,
      data: data.data
    })

    if (callback) callback(res.data, false)
    dispatch({
      type: EMBEDS.BOARD_SECTION,
      payload: res.data
    })
  }, callback)
}

/**
 * delete a section order
 * @param {String} data.id section id
 * @param {Function} callback callback function
 */
export const deleteSectionOrders = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/workspacesection/${data.id}`
    })

    if (callback) callback(res.data)

    dispatch({
      type: EMBEDS.BOARD_SECTION,
      payload: res.data
    })

    dispatch({
      type: TASKS.DELETE_TASK_SECTION,
      payload: { sectionId: data.id }
    })
  }, callback)
}

/**
 * Mark board as pinned (default)
 * @param {String} id workspace id
 * @param {String} id board id
 * @param {Function} callback callback function
 */

export const markBoardAsPinned = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspaceview/update/pinView/${data.workspaceId}/${data.boardId}`
    })

    if (callback) callback(res.data)

    dispatch({
      type: EMBEDS.UPDATE_ALL,
      payload: res.data
    })

    dispatch({
      type: WORKSPACES.CHANGE_DEFAULT_BOARD,
      payload: res.data.find(item => item.pinView)
    })
  }, callback)
}

/**
 * Fetch applied filters of board (default)
 * @param {String} data.id board id
 * @param {Function} callback callback function
 */
export const fetchBoardFilters = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/filter/user/board/${data.id}`
  })

  callback(res.data)
})

/**
 * Create filter for board tasks (default)
 * @param {Object} data op, fields, workspace, workspaceBoard
 * @param {Function} callback callback function
 */

export const createFilterForBoardTask = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/filter',
    data: data.data
  })

  callback(res.data)
})

/**
 * Update board filter
 * @param {String} id filter id
 * @param {Object} data.data op, fields, workspace, workspaceBoard
 * @param {Function} callback callback function
 */

export const updateBoardFilter = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/filter/${data.id}`,
    data: data.data
  })

  callback(res.data)
})

/**
 * Delete board filter
 * @param {String} id filter id
 * @param {Function} callback callback function
 */

export const deleteBoardFilterById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/filter/${data.id}`
  })

  callback(res.data)
})

/**
 * Delete board filter
 * @param {String} data.id board id
 * @param {Object} data.data sections updated sections data, _id, label, color, category
 * @param {Function} callback callback function
 */
export const updateBoardStatuses = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/workspacesection/category/board/${data.id}`,
      data: data.data
    })

    dispatch({
      type: EMBEDS.BOARD_SECTION,
      payload: res.data
    })

    callback(res.data)
  }, callback)
}

export const fetchClientEmbedsById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/workspaceview/client/embed/${data.id}`
    })

    dispatch({ type: EMBEDS.FETCHED_CLIENT_EMBEDS, payload: res.data })

    if (callback) callback(res)
  }, callback)
}

export const resetEmbedsData = () => {
  return { type: EMBEDS.RESET }
}
