import React from 'react'
import ChannelsListItem from './ChannelsListItem'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const ArchivedChannelsList = ({
  currentChannelId,
  chatPermission,
  setChannelId,
  setIsPersonalChat
}) => {
  const { t } = useTranslation()
  const { groups: groupsData, unreadMessages } = useSelector(
    state => state.chats
  )
  const archivedGroups = groupsData.data
    .filter(group => group.isArchive)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  const handleOnChannelClick = (chId, isPersonalChannel) => {
    setChannelId(chId)
    setIsPersonalChat(isPersonalChannel)
  }

  return (
    <>
      <ul className="p-2">
        {archivedGroups.length ? (
          archivedGroups.map(channel => (
            <ChannelsListItem
              channelId={channel._id}
              channelName={channel.title}
              isArchived={true}
              currentChannelId={currentChannelId}
              channelType={channel.type}
              unreadMessageData={unreadMessages[channel._id]}
              chatPermission={chatPermission}
              avatarUser={
                channel.type === 'newFeed' &&
                (channel.members?.find(item => item.role === 'moderator')
                  ?.user ||
                  {})
              }
              handleOnClick={() => handleOnChannelClick(channel._id, false)}
            />
          ))
        ) : (
          <li className="text-smaller pl-6 text-gray-600 dark:text-dark-light">
            {t('NO_CHANNEL_FOUND')}
          </li>
        )}
      </ul>
    </>
  )
}

export default ArchivedChannelsList
