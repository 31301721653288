import React from 'react'

const RequestUpdate = ({ request, t }) => {
  return (
    <div className="p-2 bg-gray-50 border dark:border-dark-main1 dark:bg-dark-main3 dark:text-dark-light rounded-md">
      <div className="mb-4 dark:text-dark-light text-xs">
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              new Date(request.createdAt) < new Date(request.updatedAt)
                ? 'UPDATE_REQUEST_FEED'
                : 'NEW_REQUEST_FEED',
              {
                data: `<span style='font-weight: 600;' >${request.title}</span>`
              }
            )
          }}
        ></span>
      </div>
      <div className="mb-2">
        <div className="font-bold text-xs dark:text-dark-light">
          {t('DESCRIPTION')}
        </div>
        <div
          className="text-extraSmaller"
          dangerouslySetInnerHTML={{ __html: request.description }}
        ></div>
      </div>
    </div>
  )
}

export default RequestUpdate
