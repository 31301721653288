const FormLabel = props => {
  const {
    children,
    required = false,
    size = 'smaller',
    className,
    ...restProps
  } = props
  return (
    <label
      className={`block font-medium text-primary-mid-dark pb-1 dark:text-dark-light ${
        required ? 'required' : ''
      } ${className} ${sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </label>
  )
}

const sizeClasses = {
  large: 'text-lg',
  base: 'text-base',
  small: 'text-sm',
  smaller: 'text-smaller',
  extraSmall: 'text-xs'
}

export default FormLabel
