import React from 'react'
import { useTranslation } from 'react-i18next'

const ServiceNotificationMessage = ({ field, source, before, after, user }) => {
  const { t } = useTranslation()

  const generateMessage = () => {
    if (field === 'create') {
      return (
        <>
          {t('NOTIFICATION_SERVICE_CREATE')}&nbsp;
          <span className="font-semibold">'{after}'</span>
        </>
      )
    }

    if (field === 'update') {
      return (
        <>
          {t('NOTIFICATION_SERVICE_UPDATE')}&nbsp;
          <span className="font-semibold">'{source.name}'</span>
        </>
      )
    }

    if (field === 'displayOnCatalog') {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: t('NOTIFICATION_SERVICE_DISPLAY_CATALOG', {
              serviceName: source.name
            })
          }}
        ></span>
      )
    }

    if (field === 'delete') {
      return (
        <>
          {t('NOTIFICATION_SERVICE_DELETE')}&nbsp;
          <span className="font-semibold">'{before?.name}'</span>
        </>
      )
    }
  }

  return (
    <div>
      <div className="text-smaller">{generateMessage()}</div>
    </div>
  )
}

export default ServiceNotificationMessage
