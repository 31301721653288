import { t } from 'i18next'

const taskStatus = {
  0: 'Pending',
  1: 'Open',
  2: 'In Progress',
  3: 'Completed',
  4: 'Review',
  5: 'Blocked'
}

export const taskStatusColors = {
  0: 'rgba(206, 205, 202, 0.5)',
  1: 'rgb(204 228 249)',
  2: 'rgb(204 231 225)',
  3: 'rgb(204 231 225)',
  4: 'rgb(225 211 248)',
  5: 'rgb(255 204 209)'
}

// export const recurringTime = {
//   0 'Recurring Off',
//   1: 'Every Day',
//   2: 'Every Week',
//   3: 'Every 15 Days',
//   4: 'Every Month'
// }

export const getRecurringTime = () => ({
  0: [t('RECURRING_OFF'), 0],
  1: [t('EVERY_DAY'), 1],
  2: [t('TWICE_WEEK'), 4],
  3: [t('EVERY_WEEK'), 7],
  4: [t('EVERY_15_DAYS'), 15],
  5: [t('EVERY_MONTH'), 30]
})
export const recurringTime = {
  0: ['Recurring Off', 0],
  1: ['Every Day', 1],
  2: ['Twice Week', 4],
  3: ['Every Week', 7],
  4: ['Every 15 Days', 15],
  5: ['Every Month', 30]
}

export default taskStatus
