import { makeStyles, createStyles } from '@material-ui/core'

const workloadStyles = makeStyles((theme) =>
  createStyles({
    tableRight: {
      '&::-webkit-scrollbar-thumb': {
        height: 4
      }
    },
    tableCell: {
      minWidth: 120,
      flex: 1
    }
  })
)

export default workloadStyles
