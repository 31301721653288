import { Avatar, Badge } from '@material-ui/core'
// import { styled } from '@material-ui/styles'
import React, { useMemo } from 'react'
import stringToHslColor from 'utils/stringToHslColor'
import { CustomTooltip } from '..'
import { useSelector } from 'react-redux'
import { AvatarGroup } from '@material-ui/lab'
import { makeStyles, styled } from '@material-ui/styles'
// import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
// import { useHistory } from 'react-router-dom'
// import { getUserPath } from 'utils/userRoles'

const getNameInitials = (name = '') =>
  name
    .split(' ')
    .slice(0, 2)
    .map(item => item[0])
    .join('')

const ColoredAvatars = props => {
  const {
    user = { name: 'Unknown' },
    tooltip,
    style,
    hideBadge = false,
    size = 'medium',
    bgColor = false,
    online = false,
    ...restProps
  } = props

  const classes = useStyles({ size })
  // const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const userPulses = useSelector(state => state.pulse.data)
  let nameInitials = getNameInitials(user.name ?? 'Unknown')

  const userActive = useMemo(() => {
    if (user?._id) {
      const userPulse = userPulses.find(pulse => pulse._id === user._id)
      if (
        userPulse &&
        userPulse.lastActive &&
        new Date(userPulse.lastActive).getTime() >=
          new Date().getTime() - 16 * 60 * 1000
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [userPulses, user])

  // const handleViewProfile = () => {
  //   const path = getUserPath(meData.role)
  //   history.push(`${path}/user/profile/${user._id}`)
  // }

  const avatarElm = (
    <StyledBadge
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant="dot"
      size={sizeStyles[size].width.split('px')[0]}
      invisible={
        hideBadge ? true : online ? false : Boolean(bgColor) || !userActive
      }
    >
      <Avatar
        style={{
          backgroundColor: bgColor ? bgColor : stringToHslColor(user.name ?? '')
        }}
        classes={{ root: classes.avatar }}
        src={user.profileImage}
        {...restProps}
        // onClick={handleViewProfile}
      >
        <span className="dark:text-dark-light">{nameInitials}</span>
      </Avatar>
    </StyledBadge>
  )

  return (
    <div className="relative">
      {tooltip === false ? (
        <StyledBadge
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant="dot"
          invisible={!userActive}
          size={sizeStyles[size].width.split('px')[0]}
        >
          {avatarElm}
        </StyledBadge>
      ) : (
        <CustomTooltip
          title={user._id === meData._id ? t('I_AM_ONLINE') : user.name}
          isme={user._id === meData._id}
          placement="top"
        >
          {avatarElm}
        </CustomTooltip>
      )}
    </div>
  )
}

export const ColoredAvatarGroup = ({
  users,
  tooltip,
  size = 'medium',
  // expandOnHover = false,
  max = 3,
  ...rest
}) => {
  // const history = useHistory()
  const classes = useStyles({ size })
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  // const [maxAvatars, setMaxAvatars] = useState(max)

  // const handleViewProfile = userId => {
  //   const path = getUserPath(meData.role)
  //   history.push(`${path}/user/profile/${userId}`)
  // }

  return (
    <AvatarGroup
      max={max}
      style={{ display: 'inline-flex' }}
      classes={{ avatar: classes.avatar }}
      {...rest}
      // onMouseEnter={expandOnHover ? () => setMaxAvatars(20) : undefined}
      // onMouseLeave={expandOnHover ? () => setMaxAvatars(max) : undefined}
    >
      {users.map((user, index) =>
        tooltip ? (
          <CustomTooltip
            title={user?._id === meData._id ? t('I_AM_ONLINE') : user?.name}
            isme={user?._id === meData._id}
            placement="top"
            key={index}
          >
            <Avatar
              style={{
                backgroundColor: stringToHslColor(user?.name ?? '')
              }}
              // classes={{ root: classes.avatar }}
              src={user?.profileImage}
              alt={user?.name}
              // onClick={() => handleViewProfile(user._id)}
            >
              <span>{getNameInitials(user?.name)}</span>
            </Avatar>
          </CustomTooltip>
        ) : (
          <Avatar
            key={index}
            style={{
              backgroundColor: stringToHslColor(user?.name ?? '')
            }}
            // classes={{ root: classes.avatar }}
            src={user?.profileImage}
            alt={user?.name}
          >
            {getNameInitials(user?.name)}
          </Avatar>
        )
      )}
    </AvatarGroup>
  )
}

// The ColoredAvatars component is kinda heavy compared to normal Avatar component bcoz it checks whether the user is
// active or not, name initials, different stylings, so using ColoredAvatars in React Select hits the performance
// and makes rendering slow bcoz here we render so many users at once (list menu) that's why i'm creating this component
export const CustomAvatar = ({ user }) => {
  let nameInitials = getNameInitials(user.name ?? 'Unknown')

  return (
    <Avatar
      style={{
        backgroundColor: stringToHslColor(user?.name ?? ''),
        width: '24px',
        height: '24px',
        lineHeight: '24px',
        fontSize: 10
      }}
      src={user?.profileImage}
    >
      {nameInitials}
    </Avatar>
  )
}

export const sizeStyles = {
  tiny: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    paddingLeft: '1px',
    fontSize: 9,
    letterSpacing: '1px'
  },
  small: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    paddingLeft: '1px',
    fontSize: 10,
    letterSpacing: '1px',
    '@media (min-width: 1684px)': {
      width: '24px',
      height: '24px',
      lineHeight: '24px'
    }
  },
  medium: {
    width: '24px',
    height: '24px',
    lineHeight: '24px',
    fontSize: 10,
    '@media (min-width: 1684px)': {
      width: '32px',
      height: '32px',
      lineHeight: '32px',
      fontSize: 12
    }
  },
  normal: {
    width: '32px',
    height: '32px',
    lineHeight: '32px',
    fontSize: 12,
    '@media (min-width: 1684px)': {
      width: '38px',
      height: '38px',
      lineHeight: '40px',
      fontSize: 17
    }
  },
  large: {
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    fontSize: 20
  },
  extraLarge: {
    width: '60px',
    height: '60px',
    lineHeight: '60px',
    fontSize: 22
  }
}

const StyledBadge = styled(Badge, {
  shouldForwardProp: prop => prop !== 'size'
})(({ theme, size }) => ({
  '& .MuiBadge-badge': {
    position: 'absolute',
    top: `${size * 0.15}px`,
    right: `${size * 0.15}px`,
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`
  },
  '& .MuiBadge-invisible': {
    display: 'none'
  }
}))

const useStyles = makeStyles(() => ({
  avatar: ({ size }) => ({
    ...sizeStyles[size],
    textTransform: 'uppercase'
    // cursor: 'pointer'
    // transition: 'all .2s',
    // '&:hover': {
    //   transform: 'scale(1.3)'
    // }
  })
}))

export default ColoredAvatars
