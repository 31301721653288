import React, { useEffect, useRef, useState, useMemo } from 'react'
import { RemoveRedEye } from '@material-ui/icons'
import { format } from 'date-fns'
import clsx from 'clsx'
import { ColoredAvatars, FilePreview } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { fetchFeeds, updateFeeds } from 'thunks/feeds/action'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { errorMessages, getFileType, getQuillHTML } from 'utils'
import socket from 'config/socketConfig'
import {
  createChatGroup,
  fetchGroups,
  handleSetCurrentChannelId,
  uploadChatAttachment
} from 'thunks/chat/actions'
import { getUserRootFolder } from 'thunks/fileAssets/actions'
import QuillChatEditor from 'global/globalComponents/QuillEditor/QuillChatEditor'
import InvoiceUpdate from './InvoiceUpdate'
import ChatMessage from './ChatMessage'
import RequestUpdate from './RequestUpdate'

let timerId
const feedsLimit = 5

const ChatWindow = ({ openReqModal, setToggleQuote }) => {
  const feedsContainer = useRef()
  const meData = useSelector(state => state.me.data)
  const [pagination, setPagination] = useState({
    page: 0,
    limit: feedsLimit,
    loadMore: true
  })
  const [feedsData, setFeedsData] = useState({
    loading: true,
    data: []
  })
  const { groups } = useSelector(state => state.chats)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [typing, setTyping] = useState(false)
  const [rootDir, setRootDir] = useState(null)
  const [users, setUsers] = useState([])
  const [filePreviewModal, setFilePreviewModal] = useState({
    open: false,
    files: [],
    fileIndex: 0
  })
  const [feedChannel, setFeedChannel] = useState({})

  useEffect(() => {
    // If groups are not fetched, fetch them
    if (!groups.fetched) {
      dispatch(fetchGroups())
    }
  }, [groups.fetched])

  useEffect(() => {
    if (groups.fetched) {
      const matchedGroup = groups.data.find(item => {
        if (item.type === 'newFeed') {
          const idx = item.members.findIndex(
            member => member.user._id === meData._id
          )
          if (idx !== -1) return true
        }

        return false
      })

      if (matchedGroup) setFeedChannel(matchedGroup)
      else handleCreateChatGroup()
    }
  }, [groups, meData._id])

  useEffect(() => {
    if (!feedChannel._id) return

    const onReceiveMessage = value => {
      let newFeed = {
        ...value,
        user: value.createdBy
      }

      setFeedsData(prev => ({ ...prev, data: [...prev.data, newFeed] }))
      setTimeout(() => {
        feedsContainer.current.scrollTo(0, feedsContainer.current.scrollHeight)
      }, 100)

      socket.emit(
        'readOneMessage',
        { _id: value._id, room: feedChannel._id },
        props => {
          // empty callback
        }
      )
    }

    socket.on('receiveMessage', onReceiveMessage)

    return () => {
      socket.off('receiveMessage', onReceiveMessage)
    }
  }, [feedChannel._id])

  useEffect(() => {
    if (feedChannel._id) {
      setUsers(
        feedChannel.members.map(item => ({
          id: item.user?._id,
          value: item.user?.name,
          //for backend and frontend
          _id: item.user?._id,
          name: item.user?.name,
          profileImage: item.user?.profileImage,
          role: item.role
        }))
      )

      dispatch(handleSetCurrentChannelId(feedChannel._id))
    }

    return () => {
      dispatch(handleSetCurrentChannelId(null))
    }
  }, [feedChannel])

  useEffect(() => {
    dispatch(
      fetchFeeds({ page: 0, limit: feedsLimit }, (res, err) => {
        setFeedsData({ loading: false, data: err ? [] : res })
        setPagination(prev => ({
          ...prev,
          loadMore: err ? false : res.length >= feedsLimit
        }))

        feedsContainer.current?.scrollTo(0, feedsContainer.current.scrollHeight)
      })
    )
  }, [])

  useEffect(() => {
    dispatch(
      getUserRootFolder({ userId: meData._id }, rootDir => {
        setRootDir(rootDir)
      })
    )
  }, [meData._id])

  const handleCreateChatGroup = () => {
    dispatch(
      createChatGroup({
        body: {
          title: `${meData.name.split(' ')[0]}'s Feed`,
          description: '',
          icon: '',
          type: 'newFeed',
          agencyOwner: meData.team._id,
          user: meData._id,
          members: [
            { user: meData._id, role: 'moderator' },
            { user: meData.team._id, role: 'admin' }
          ]
        }
      })
    )
  }

  const handleOpenPreview = (fileIndex, allFiles) => {
    if (filePreviewModal.open) {
      setFilePreviewModal(prev => ({ ...prev, open: false }))
    } else {
      const files = allFiles.map(fileUrl => {
        const file = {}

        const arr = fileUrl.split('/')
        file.name = arr[arr.length - 1]
        file.type = getFileType(fileUrl)
        file.url = fileUrl
        file.extension = fileUrl.slice(fileUrl.lastIndexOf('.'))

        return file
      })

      setFilePreviewModal({ open: true, files, fileIndex })
    }
  }

  const handleScroll = e => {
    if (!pagination.loadMore) return

    if (e.target.scrollTop <= 2) {
      let prevScrollHeight = feedsContainer.current.scrollHeight

      setFeedsData(prev => ({ ...prev, loading: true }))
      dispatch(
        fetchFeeds({ page: pagination.page + 1, limit: feedsLimit }, res => {
          const sortedFeeds = res
            .concat(feedsData.data)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

          setFeedsData(prev => ({
            loading: false,
            data: sortedFeeds
          }))

          setPagination(prev => ({
            ...prev,
            page: prev.page + 1,
            loadMore: res.length >= feedsLimit
          }))

          feedsContainer.current.scrollTo(
            0,
            feedsContainer.current.scrollHeight - prevScrollHeight
          )
        })
      )
    }
  }

  const handleSendMessage = ({
    data,
    attachments,
    attachmentIds,
    mentions,
    hasEmoji,
    hasMentions,
    callback
  }) => {
    const textValue = getQuillHTML(data).trim()
    const htmlValue = getQuillHTML(data, 'html')
    // console.log(group, 'group')
    if (!feedChannel._id) {
      dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
      return
    }

    //if msg is empty don't store empty html tags
    const messageValue =
      !textValue && !hasEmoji && !hasMentions ? '' : htmlValue

    socket.emit('notTyping', { room: feedChannel._id }, res => {
      // empty callback
    })

    socket.emit(
      'sendMessage',
      {
        message: messageValue,
        group: feedChannel._id,
        room: feedChannel._id,
        metadata: { mentions },
        attachmentIds,
        attachments
      },
      ({ status, result }) => {
        if (status === 200) {
          let newFeed = {
            ...result,
            user: {
              _id: meData._id,
              name: meData.name,
              profileImage: meData.profileImage,
              role: meData.role,
              email: meData.email
            }
          }

          setFeedsData(prev => ({ ...prev, data: [...prev.data, newFeed] }))
          dispatch(updateFeeds(newFeed))
          feedsContainer.current.scrollTo(
            0,
            feedsContainer.current.scrollHeight
          )
        }
      }
    )
    // setContainsFile(false)
    callback()
  }

  const fileUploadHandler = (file, callback) => {
    uploadChatAttachment(
      {
        fileData: {
          fileName: file.name.slice(0, file.name.lastIndexOf('.')),
          file: file
        },
        data: {
          fileAssetsFolder: rootDir._id
        }
      },
      (res, err) => {
        if (err) dispatch(fireErrorToaster(res))
        callback(res, err)
      }
    )
  }

  const handleOnChange = () => {
    clearTimeout(timerId)

    timerId = setTimeout(() => {
      socket.emit('notTyping', { room: feedChannel._id }, res => {
        // empty callback
      })
      setTyping(false)
    }, 2000)

    if (typing) return
    setTyping(true)
    socket.emit('typing', { room: feedChannel._id }, res => {
      // empty callback
    })
  }

  const handleOnBlur = () => {
    if (!typing) return
    setTyping(false)
    socket.emit('notTyping', { room: feedChannel._id }, res => {
      // empty callback
    })
  }

  return (
    <>
      <div
        className={clsx('relative flex flex-col')}
        style={{ height: 'calc(100% - 48px)' }}
      >
        {pagination.loadMore && feedsData.loading && (
          <p className="absolute top-0 w-full text-center text-xs text-gray-500 py-1">
            {t('LOADING_FEEDS')}...
          </p>
        )}

        <div
          onScroll={handleScroll}
          className="flex-1 overflow-y-auto overflow-x-hidden py-2 px-4"
          ref={feedsContainer}
        >
          {feedsData.data.map(feed =>
            meData._id === feed.user?._id ? (
              <div
                key={feed._id}
                className="flex flex-col-reverse md:flex-row gap-2 md:justify-end mb-4"
              >
                <Container
                  openReqModal={openReqModal}
                  feed={feed}
                  me={meData}
                  setToggleQuote={setToggleQuote}
                  handleOpenPreview={handleOpenPreview}
                />
                <ColoredAvatars user={meData} size="normal" />
              </div>
            ) : (
              <div
                key={feed._id}
                className="flex flex-col md:flex-row gap-2 mb-4"
              >
                <ColoredAvatars
                  user={feed.user ?? 'Deleted Account'}
                  size="normal"
                />
                <Container
                  openReqModal={openReqModal}
                  feed={feed}
                  team={meData.team}
                  setToggleQuote={setToggleQuote}
                  handleOpenPreview={handleOpenPreview}
                />
              </div>
            )
          )}
        </div>

        <div className="pb-4 px-4">
          {Boolean(feedChannel._id) && (
            <QuillChatEditor
              users={users}
              onSubmit={handleSendMessage}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              fileUploadHandler={fileUploadHandler}
            />
          )}
        </div>
      </div>

      <FilePreview
        open={filePreviewModal.open}
        files={filePreviewModal.files}
        fileIndex={filePreviewModal.fileIndex}
        onClose={handleOpenPreview}
      />
    </>
  )
}

const Container = ({
  me = false,
  feed,
  openReqModal,
  setToggleQuote,
  handleOpenPreview
}) => {
  const { t } = useTranslation()
  const type = useMemo(() => {
    if (feed.invoiceNumber) return 'invoice'
    else if (feed.hasOwnProperty('message')) return 'chat'
    else if (feed.raisedCount) return 'request'
  }, [feed])

  const handleEdit = () => {
    if (type === 'request') return openReqModal({ open: true, data: feed })
    else if (type === 'invoice')
      return setToggleQuote({ open: true, invoice: feed })
  }

  return (
    <div
      className={clsx(
        'p-4 border dark:bg-dark-main dark:border-dark-main1 show-on-hover-parent',
        me
          ? 'bg-white rounded-b-md rounded-l-md'
          : 'bg-gray-100 rounded-r-md rounded-b-md'
      )}
      style={{ maxWidth: '50%' }}
    >
      <header className="flex flex-wrap justify-between items-center mb-1">
        <div className="flex flex-wrap gap-2 items-end">
          <span className="text-xs font-bold dark:text-white">
            {me ? me.name : feed.user?.name ?? 'Deleted User'}
          </span>
          <span className="text-extraSmaller text-gray-600 dark:text-dark-light2 font-light">
            {format(
              new Date(
                new Date(feed.createdAt) < new Date(feed.updatedAt)
                  ? feed.updatedAt
                  : feed.createdAt
              ),
              "dd/MM/yyyy 'at' HH:mm"
            )}
          </span>
        </div>
        {['request', 'invoice'].includes(type) && (
          <div className="ml-auto flex dark:text-dark-light gap-1 items-center">
            <span
              onClick={() => handleEdit()}
              className="bg-white dark:bg-dark-main rounded-md px-2 py-1 hover:bg-gray-100 cursor-pointer show-on-hover-child"
            >
              <RemoveRedEye fontSize="small" />
            </span>
          </div>
        )}
      </header>
      <div>
        {type === 'invoice' ? (
          <InvoiceUpdate
            className="mt-1 md:mr-4 text-sm"
            invoice={feed}
            t={t}
          />
        ) : type === 'chat' ? (
          <ChatMessage message={feed} handleOpenPreview={handleOpenPreview} />
        ) : type === 'request' ? (
          <RequestUpdate request={feed} t={t} />
        ) : null}
      </div>
    </div>
  )
}

export default ChatWindow
