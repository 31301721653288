const storeCustomizeData = data => {
  let customizeData = JSON.parse(localStorage.getItem('customize'))

  if (customizeData) {
    const updatedData = { ...customizeData, ...data }
    localStorage.setItem('customize', JSON.stringify(updatedData))
  } else {
    localStorage.setItem('customize', JSON.stringify(data))
  }
}

export default storeCustomizeData
