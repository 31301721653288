import React from 'react'
import { ReactComponent as ChevronRightIcon } from 'static/svg/chevron-right.svg'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const GoBack = ({
  text = t('BACK'),
  path,
  icon,
  className,
  onClick,
  ...rest
}) => {
  const history = useHistory()

  const navigateBack = () => {
    if (path) {
      history.push(path)
    } else {
      history.goBack()
    }
  }

  return (
    <button
      className={clsx(
        'text-primary-main flex items-center text-sm leading-3 space-x-1 hover:space-x-0 dark:text-dark-light pr-2',
        className
      )}
      {...rest}
      onClick={onClick ? onClick : navigateBack}
    >
      {icon ? icon : <ChevronRightIcon className="rotate-180" />}
      <span className="font-medium transition-all">{text}</span>
    </button>
  )
}

export default GoBack
