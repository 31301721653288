import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { getRoleBadge, userRoles } from 'utils'
import { CheckBoxField } from 'global/globalComponents'
import AddNewTeammatePanel from 'components/Teammates/AddNewTeammatePanel'
import AddNewClient from 'components/Clients/AddNewClient'

const SecondStepFields = ({ formData, handleUpdateFormData }) => {
  const { t } = useTranslation()
  const muiTheme = useTheme()
  const userClients = useSelector(state => state.userClients)
  const [teammateOptions, setTeammateOptions] = useState({})
  const [isAddTeammatePanelOpen, setIsAddTeammatePanelOpen] = useState(false)
  const { data: userTeammates, loading: teammatesLoading } = useSelector(
    state => state.userTeammates
  )
  const [isAddClientPanelOpen, setIsAddClientPanelOpen] = useState(false)

  const meData = useSelector(state => state.me.data)

  const clientOptions = userClients.data.filter(
    client => client.name.trim().length
  )

  useEffect(() => {
    // NOTE: If addAdmins is set to true that means during project creation backend will add all the admins automatically
    // So to avoid duplication don't show teammates with the admin role
    let options = userTeammates.filter(mate => mate._id !== meData._id)

    if (formData.addAdmins) {
      options = options.filter(mate => mate.role !== userRoles.AGENCY_ADMIN)
    }

    setTeammateOptions(options)
  }, [userTeammates, meData._id, formData.addAdmins])

  const filterClientOptions = (candidate, input) => {
    return Boolean(candidate.data.name.match(new RegExp(input, 'i')))
  }

  const filterAgencyOptions = (candidate, input) => {
    return Boolean(candidate.data.name.match(new RegExp(input, 'i')))
  }

  const teammateOptionsLabel = teammate => {
    return (
      <div className="flex items-center">
        <span>
          {teammate.name}{' '}
          <span className="pl-2">
            {teammate.role ? getRoleBadge(teammate.role) : ''}
          </span>
        </span>
      </div>
    )
  }

  const inviteTeammate = () => {
    setIsAddTeammatePanelOpen(true)
  }

  const handleAddNewTeammate = teammate => {
    handleUpdateFormData({
      field: 'assignedAgencyTeam',
      value: [...formData.assignedAgencyTeam, teammate]
    })
    // setSelectedAgencyTeammates(prev => [...prev.assignedAgencyTeam, teammate])
  }

  const handleAddNewClient = clientData => {
    handleUpdateFormData({
      field: 'assignedClientTeam',
      value: clientData
    })
  }

  return (
    <>
      <div className="mb-5">
        <FormLabel>{t('ADD_CLIENT')}</FormLabel>

        <div>
          <Select
            isSearchable={true}
            isDisabled={formData.type === 'Internal'}
            filterOption={filterClientOptions}
            value={formData.assignedClientTeam}
            options={clientOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option._id}
            onChange={newVal =>
              handleUpdateFormData({
                field: 'assignedClientTeam',
                value: newVal
              })
            }
            styles={reactSelectCustomStyles(muiTheme)}
            maxMenuHeight="200px"
            components={{ MenuList: CustomMenu }}
            isLoading={userClients.loading}
            selectProps={{
              inviteTeammate: () => setIsAddClientPanelOpen(true),
              elmFor: 'client'
            }}
          />
        </div>
      </div>
      <div className="mb-5">
        <FormLabel>{t('ADD_TEAM_MEMBERS')}</FormLabel>

        <div>
          <Select
            closeMenuOnSelect={false}
            value={formData.assignedAgencyTeam}
            options={teammateOptions}
            isMulti
            onChange={newVal =>
              handleUpdateFormData({
                field: 'assignedAgencyTeam',
                value: newVal
              })
            }
            styles={reactSelectCustomStyles(muiTheme, {
              multiValue: {
                display: 'flex',
                alignItems: 'baseline'
              },
              valueContainer: {
                maxHeight: '100px',
                overflowY: 'auto'
              }
            })}
            maxMenuHeight="200px"
            getOptionLabel={teammateOptionsLabel}
            getOptionValue={option => option._id}
            isLoading={teammatesLoading}
            components={{ MenuList: CustomMenu }}
            filterOption={filterAgencyOptions}
            selectProps={{
              inviteTeammate: inviteTeammate,
              elmFor: 'agency'
            }}
            isDisabled={meData.role >= userRoles.USER_CLIENT}
          />
          {meData.role < userRoles.USER_CLIENT && (
            <div className="flex">
              <CheckBoxField
                onChange={() =>
                  handleUpdateFormData({
                    field: 'addAdmins',
                    value: !formData.addAdmins
                  })
                }
                checked={formData.addAdmins}
                label={
                  <span className="pb-1 text-gray-500 py-1 dark:text-dark-light">
                    {t('INCLUDE_ADMINS')}
                  </span>
                }
              />
            </div>
          )}
        </div>
      </div>

      <AddNewTeammatePanel
        open={isAddTeammatePanelOpen}
        onClose={() => setIsAddTeammatePanelOpen(false)}
        metadata={{
          styles: {
            root: { zIndex: '1999 !important' },
            drawerPaper: {
              marginTop: 0,
              maxHeight: '100vh',
              borderTop: '1px solid #efefef'
            }
          }
        }}
        callback={handleAddNewTeammate}
      />

      <AddNewClient
        open={isAddClientPanelOpen}
        onClose={() => setIsAddClientPanelOpen(false)}
        styles={{
          root: { zIndex: '1999 !important' },
          drawerPaper: {
            marginTop: 0,
            maxHeight: '100vh',
            borderTop: '1px solid #efefef'
          }
        }}
        callback={handleAddNewClient}
      />
    </>
  )
}

const CustomMenu = props => {
  const { t } = useTranslation()
  return (
    <components.MenuList {...props}>
      <button
        onClick={props.selectProps.selectProps.inviteTeammate}
        className="text-sm text-primary-main dark:text-gray-400 flex items-center w-full py-2 px-4 dark:hover:bg-dark-main"
      >
        <AddIcon className="mr-1" />
        <span>
          {t('ADD_NEW')}{' '}
          {props.selectProps.selectProps.elmFor === 'agency'
            ? t('TEAM_MEMBER')
            : t('CLIENT')}
        </span>
      </button>
      {props.children}
    </components.MenuList>
  )
}

export default SecondStepFields
