import React, { useEffect, useRef, useState } from 'react'
import { useStyles } from 'components/Boards/TableView/AttachmentCell'
import { ReactComponent as VideoIcon } from 'static/svg/video.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { Button, CircularProgress } from '@material-ui/core'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import { ReactComponent as FileUploadIcon } from 'static/svg/file-upload.svg'
import { fileToDataURL, stopEventPropagation } from 'utils'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

let radius = 10
let circumference = radius * 2 * Math.PI

const ProjectFiles = ({
  loading,
  projectFiles,
  setProjectFiles,
  handlePreview
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const ref = useRef()

  const removeFile = index => {
    let files = [...projectFiles]
    files.splice(index, 1)
    setProjectFiles(files)
  }

  const imageLoadHandler = e => {
    const files = e.target.files
    for (const file of files) {
      fileToDataURL(file).then(res => {
        setProjectFiles(prev => [...prev, { file, url: res }])
      })
    }
  }

  return (
    <div className="text-center text-black dark:text-dark-light">
      <div className="mt-6 mb-4 border-2 border-dashed h-52 flex justify-center items-center">
        <div>
          <FileUploadIcon className="w-16 mb-4 h-auto mx-auto text-primary-main" />
          <Button
            disabled={loading}
            size="small"
            variant="outlined"
            onClick={() => ref.current.click()}
          >
            {t('UPLOAD_FILES')}
          </Button>
        </div>
      </div>

      {projectFiles.length > 0 && (
        <div className="flex flex-wrap gap-4 items-center">
          {projectFiles.map((file, index) => (
            <div
              style={{
                height: 150,
                width: 150
              }}
              className="relative show-on-hover-parent"
            >
              <div
                style={{
                  left: '40%',
                  top: '33%'
                }}
                className="absolute m-auto h-full w-full z-10 flex"
              >
                {file.percentage === 0 ||
                (file.percentage === 100 && !file.isComplete) ? (
                  <CircularProgress size={23} />
                ) : file.isComplete ? (
                  <CheckCircle className="w-5 h-5 text-custom-green-main" />
                ) : (
                  Boolean(file.percentage) && (
                    <>
                      <svg className="relative z-10" width="26" height="26">
                        <circle
                          stroke="#32c632"
                          stroke-width="4"
                          fill="transparent"
                          r={radius}
                          cx="13"
                          cy="13"
                          style={{
                            strokeDasharray: `${circumference} ${circumference}`,
                            strokeDashoffset:
                              circumference -
                              (file.percentage / 100) * circumference,
                            transition: 'all 1s ease-in-out',
                            transformOrigin: 'center',
                            transform: 'rotate(-90deg)'
                          }}
                          strokeLinecap="round"
                          className="origin-center"
                        ></circle>
                      </svg>
                      <svg className="absolute top-0" width="26" height="26">
                        <circle
                          stroke="#efefef"
                          stroke-width="4"
                          fill="transparent"
                          r={radius}
                          cx="13"
                          cy="13"
                        />
                      </svg>
                    </>
                  )
                )}
              </div>
              <AttachmentPill
                index={index}
                file={file}
                allFiles={projectFiles}
                handleRemoveAttachment={removeFile}
                handlePreviewAttachment={handlePreview}
                classes={classes}
              />
            </div>
          ))}
        </div>
      )}

      <input
        ref={ref}
        accept="*"
        style={{ display: 'none' }}
        type="file"
        multiple={true}
        onChange={imageLoadHandler}
      />
    </div>
  )
}

const AttachmentPill = React.memo(
  ({
    index,
    file,
    handleRemoveAttachment,
    handlePreviewAttachment,
    allFiles,
    classes
  }) => {
    const [fileType, setFileType] = useState('file')

    useEffect(() => {
      if (!file?.file) return
      let fileName = file.file.name
      if (/\.(mp3|mp4|mkv|avi|webm|mov)$/.test(fileName)) setFileType('video')
      else if (/\.(png|svg|jpe?g|gif|tiff?)$/.test(fileName))
        setFileType('image')
    }, [file])

    const handleRemove = () => {
      handleRemoveAttachment(index)
    }

    const handleFileClick = () => {
      handlePreviewAttachment(index, allFiles)
    }

    return (
      <div
        className={clsx(
          classes.badge,
          'relative inline-block w-full h-full flex-1'
        )}
        onClick={stopEventPropagation}
      >
        <div
          onClick={handleFileClick}
          className="cursor-pointer flex items-center w-full h-full border"
        >
          {fileType === 'image' ? (
            <img alt="" className="w-full h-full object-cover" src={file.url} />
          ) : fileType === 'video' ? (
            <span className="text-2xl text-gray-500">
              <VideoIcon />
            </span>
          ) : (
            <span className="text-4xl mx-auto text-center py-4 text-gray-500">
              <FileIcon />
            </span>
          )}
        </div>
        <button
          className={clsx(
            'w-5 h-5 rounded-full bg-red-400 text-white flex justify-center items-center',
            classes.btnRemove
          )}
          onClick={handleRemove}
        >
          <CloseIcon style={{ fontSize: 12 }} />
        </button>
      </div>
    )
  }
)

export default ProjectFiles
