import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { getUserPath } from 'utils/userRoles'
import headerStyles from '../../headerStyles'
import EmbedDropdown from './EmbedDropdown'
import { deleteEmbed } from 'thunks/embed/action'
import { useHistory } from 'react-router-dom'
import useWorkspacePermission from 'customHooks/useWorkspacePermission'
import { userRoles } from 'utils'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { toggleEmbedModal } from 'thunks/generlAppState/actions'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import SelectTabPopup from 'components/UserDashboard/Workspaces/selectedTabPopup'
import isEqual from 'lodash/isEqual'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import BoardStaticTabs from './BoardStaticTabs'
import BoardDynamicTabs from './BoardDynamicTabs'
import { useTranslation } from 'react-i18next'
// import { useParams } from 'react-router-dom'

const embedTypes = ['sheet', 'docs', 'slides', 'calendar', 'embed']

const WorkspaceTabs = () => {
  const { t } = useTranslation()
  const classes = headerStyles()
  const dispatch = useDispatch()

  const viewWidth = useRef(null)
  const [workspaceTab, setWorkspaceTab] = useState({ tab: [], data: {} })
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const meData = useSelector(state => state.me.data)
  const innerProjectPermission = useWorkspacePermission(currentWorkspace?._id)
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)
  const userReportings = useSelector(state => state.userReportings.data)
  const [anchorE2, setAnchorE2] = useState(null)
  const [popoverData, setPopoverData] = useState([])
  const history = useHistory()
  const [iconPopoverData, setIconPopoverData] = useState({
    open: false,
    anchorEl: null,
    id: ''
  })
  const [deletedTab, setDeletedTab] = useState({
    open: false,
    data: {},
    loading: false
  })
  const embedData = useSelector(state => state.embed)

  const [selectedTabPopup, setSelectedTabPopup] = useState({
    anchorEl: null,
    columns: [
      { label: t('OVERVIEW'), accessor: 'overview', disable: false },
      { label: t('BOARD'), accessor: 'board', disable: false },
      { label: t('CALENDAR'), accessor: 'calendar', disable: false },
      { label: t('LIST'), accessor: 'list', disable: false },
      { label: t('REPORTING'), accessor: 'reporting', disable: false },
      { label: t('FILES'), accessor: 'files', disable: false },
      {
        label: t('INVOICES'),
        accessor: 'invoice',
        disable: false
      },
      {
        label: t('EMBED_VIEWS'),
        show: true,
        accessor: 'embeds',
        disable: false
      }
    ]
  })
  const [hiddenCols, setHiddenCols] = useState([])
  const childRef = useRef()
  const [saveView, setSaveView] = useState(false)
  const [visibleArrow, setVisibleArrow] = useState('')
  const ref = useRef()

  const projectOwner = useMemo(() => {
    const owner = currentWorkspace.assignedAgencyTeam?.find(
      el => el.role === userRoles.PROJECT_OWNER
    )
    if (owner) {
      return owner.user._id
    } else {
      return null
    }
  }, [currentWorkspace.assignedAgencyTeam])

  useEffect(() => {
    if (
      currentWorkspace?.type === 'Internal' ||
      ![
        userRoles.USER_AGENCY,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_ADMIN,
        userRoles.USER_CLIENT,
        userRoles.CLIENT_MANAGER
      ].includes(meData.role)
    ) {
      setSelectedTabPopup(prev => ({
        ...prev,
        columns: prev.columns.filter(el => el.accessor !== 'invoice')
      }))
    }
  }, [currentWorkspace?.type])

  useEffect(() => {
    if (currentWorkspace.projectTabs) {
      setHiddenCols(currentWorkspace.projectTabs[0].hiddenColumns)
    } else {
      setHiddenCols([])
    }
  }, [currentWorkspace])

  useEffect(() => {
    if (currentWorkspace.projectTabs) {
      setSaveView(
        !isEqual(currentWorkspace.projectTabs[0].hiddenColumns, hiddenCols)
      )
    } else if (hiddenCols.length) {
      setSaveView(true)
    } else {
      setSaveView(false)
    }
  }, [currentWorkspace, hiddenCols])

  useEffect(() => {
    const obj = {}
    if (!embedData.loading) {
      setWorkspaceTab({ tab: Object.keys(obj), data: obj })

      if (embedData.data?.length) {
        embedTypes.forEach(key => {
          let arr = embedData.data?.filter(
            el =>
              (el.type === key || el.type.toLowerCase().includes(key)) &&
              !el.client //removing client embeds
          )
          if (arr.length) obj[key] = arr
        })

        setWorkspaceTab(prev => ({
          tab: Object.keys(obj),
          data: obj
        }))
      } else {
        setWorkspaceTab(prev => ({
          tab: Object.keys(obj),
          data: obj
        }))
      }
    } else {
      setWorkspaceTab(prev => ({
        tab: Object.keys(obj),
        data: obj
      }))
    }
  }, [embedData, currentWorkspace?.type])

  useEffect(() => {
    if (ref.current) {
      if (ref.current.offsetWidth < ref.current.scrollWidth)
        setVisibleArrow('right')
      else setVisibleArrow('')
    }
  }, [ref, workspaceTab])

  const handleDeleteWorkspaceTab = data => {
    if (deletedTab.open) {
      setDeletedTab({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeletedTab({
        open: true,
        data: data,
        loading: false
      })
    }
  }

  const deleteEmbedLink = () => {
    setDeletedTab(prevState => ({ ...prevState, loading: true }))
    dispatch(deleteEmbed({ id: deletedTab.data._id }, deleteEmbedCallback))
    setIconPopoverData({ open: false, anchorEl: null })
    handleClosePopover()
  }

  const deleteEmbedCallback = (res, err) => {
    setDeletedTab({
      open: false,
      data: {},
      loading: false
    })
    if (!err) {
      history.push(
        `${getUserPath(meData.role)}/workspace/${
          currentWorkspace?._id
        }/overview`
      )
    }
  }

  const handleEmbedToggle = () => {
    const { left } = viewWidth.current.getBoundingClientRect()
    dispatch(toggleEmbedModal(true, left))
  }

  const handleClosePopover = () => {
    setAnchorE2(null)
  }

  const handleTabClick = (e, tabName) => {
    e.preventDefault()
    // e.stopPropagation()
    setAnchorE2(e.currentTarget)
    setPopoverData(workspaceTab.data[tabName])
  }

  const handleSelectedTab = e => {
    e.stopPropagation()
    if (selectedTabPopup.anchorEl) {
      setSelectedTabPopup(prev => ({ ...prev, anchorEl: null }))
    } else {
      setSelectedTabPopup(prev => ({ ...prev, anchorEl: e.currentTarget }))
    }
  }

  const toggleColumnsVisibility = (accessor, checked) => {
    if (checked) {
      setHiddenCols(prev => prev.filter(el => el !== accessor))
    } else {
      setHiddenCols(prev => [...prev, accessor])
    }
  }

  const handleSaveView = () => {
    childRef.current.setLoading(true)
    dispatch(
      updateWorkSpace1(
        currentWorkspace._id,
        {
          projectTabs: [{ hiddenColumns: hiddenCols }]
        },
        saveViewCallback
      )
    )
  }

  const saveViewCallback = (res, err) => {
    childRef.current.setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
      setSelectedTabPopup(prev => ({ ...prev, anchorEl: null }))
    }
  }

  const handleOnScroll = e => {
    if (e.target.scrollLeft === 0) setVisibleArrow('right')
    else if (
      Math.ceil(e.target.offsetWidth + e.target.scrollLeft + 5) >=
      e.target.scrollWidth
    ) {
      setVisibleArrow('left')
    }
  }

  const handleScroll = e => {
    const dir = e.currentTarget.dataset.dir

    ref.current.scrollTo({
      top: 0,
      left: dir === 'left' ? 0 : ref.current.scrollWidth,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <div className="flex items-center">
        <div className="w-full relative" style={{ maxWidth: '35vw' }}>
          {visibleArrow === 'left' && (
            <button
              className="absolute w-4 h-4 rounded-full bg-primary-main text-white flex justify-center items-center "
              style={{
                top: '50%',
                left: '0',
                transform: 'translate(-130%, -40%)'
              }}
              onClick={handleScroll}
              data-dir="left"
            >
              <ChevronRight className="rotate-180 dark:text-dark-light" />
            </button>
          )}
          {visibleArrow === 'right' && (
            <button
              className="absolute w-4 h-4 rounded-full bg-primary-main text-white flex justify-center items-center"
              style={{
                top: '50%',
                right: '0',
                transform: 'translate(130%, -40%)'
              }}
              onClick={handleScroll}
              data-dir="right"
            >
              <ChevronRight className="dark:text-dark-light" />
            </button>
          )}
          <div
            ref={ref}
            onScroll={handleOnScroll}
            className={clsx(
              'flex items-end pt-2 overflow-x-scroll overflow-y-hidden',
              classes.tabsContainer
            )}
          >
            <BoardStaticTabs
              hiddenCols={hiddenCols}
              workspaceId={currentWorkspace?._id}
              workspaceType={currentWorkspace?.type}
              boardId={currentBoard._id || currentWorkspace.defaultBoard?._id}
              reportingId={userReportings[0]?._id}
            />
            {!hiddenCols.includes('embeds') && (
              <BoardDynamicTabs
                workspaceTab={workspaceTab}
                canDelete={innerProjectPermission.delete}
                workspaceId={currentWorkspace?._id}
                handleDeleteWorkspaceTab={handleDeleteWorkspaceTab}
                handleTabClick={handleTabClick}
              />
            )}
          </div>
        </div>
        {innerProjectPermission.create && (
          <button
            ref={viewWidth}
            onClick={handleEmbedToggle}
            className={clsx(
              'relative inline-flex text-smaller items-center hover:bg-gray-100 px-2 py-1 rounded ml-6 dark:text-dark-light dark:hover:bg-gray-900'
            )}
          >
            <AddIcon className="dark:text-dark-light" />
            <span>{t('VIEW')}</span>
          </button>
        )}
        {([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
          meData.role
        ) ||
          (projectOwner && meData._id === projectOwner)) && (
          <div className="relative cursor-pointer" onClick={handleSelectedTab}>
            <ThreeDotsIcon className="dark:text-dark-light" />
          </div>
        )}
      </div>
      <EmbedDropdown
        deleteEmbedLink={handleDeleteWorkspaceTab}
        handleClose={handleClosePopover}
        data={popoverData}
        anchorEl={anchorE2}
        // setActiveTab={setActiveTab}
        iconPopoverData={iconPopoverData}
        setIconPopoverData={setIconPopoverData}
        innerProjectPermission={innerProjectPermission}
      />
      <DeleteModal
        warningText={t('WARNING_THE_SELECTED_BOARD_WILL_BE_DELETED', {
          data1: deletedTab.data.type,
          data2:
            deletedTab.data.type === 'board' ? 'sections and tasks' : 'data'
        })}
        open={deletedTab.open}
        handleDialog={handleDeleteWorkspaceTab}
        handleDeleteAction={deleteEmbedLink}
        loading={deletedTab.loading}
      />
      <SelectTabPopup
        anchorEl={selectedTabPopup.anchorEl}
        handleClose={handleSelectedTab}
        colOptions={selectedTabPopup.columns}
        toggleColumnsVisibility={toggleColumnsVisibility}
        hiddenCols={hiddenCols}
        handleSaveView={handleSaveView}
        saveView={saveView}
        ref={childRef}
      />
    </>
  )
}

export default WorkspaceTabs
