import React, { useState, useEffect } from 'react'
import { Loader } from 'global/globalComponents'
import clsx from 'clsx'

const TextPreviewer = ({ fileUrl, style, className }) => {
  const [preview, setPreview] = useState('')

  useEffect(() => {
    if (!fileUrl) return
    const reader = new FileReader()

    reader.onload = e => {
      setPreview(e.target.result)
    }

    fetch(fileUrl)
      .then(res => res.blob())
      .then(blob => reader.readAsText(blob))
  }, [fileUrl])

  return (
    <>
      {!preview ? (
        <Loader />
      ) : (
        <div
          className={clsx(
            'bg-white overflow-auto p-4 rounded w-full',
            className
          )}
          style={{ height: '80vh', width: '50vw', ...style }}
        >
          <pre>{preview}</pre>
        </div>
      )}
    </>
  )
}

export default TextPreviewer
