import { useMemo, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

const Completion = ({ completion, size = 60 }) => {
  const [percentData, setPercentData] = useState(0)
  const themeForMode = useSelector(state => state.generalAppState.theme)
  const circleElement1 = useRef()
  const circleElement2 = useRef()
  const circleCenter = size / 2

  const color = useMemo(() => {
    return completion <= 25
      ? '#FF6100'
      : completion <= 50
      ? '#8B4AF0'
      : '#20DF78'
  }, [completion])

  useEffect(() => {
    setTimeout(() => {
      setPercentData(isNaN(completion) ? 0 : Math.round(completion))
    }, 500)
  }, [completion])

  useEffect(() => {
    // Safari does not support cx and cy directly as circle elm attributes, that is why setting it via apis
    if (circleElement1.current) {
      circleElement1.current.setAttribute('cx', circleCenter)
      circleElement1.current.setAttribute('cy', circleCenter)
    }

    if (circleElement2) {
      circleElement2.current.setAttribute('cx', circleCenter)
      circleElement2.current.setAttribute('cy', circleCenter)
    }
  }, [circleElement1, circleElement2])

  let radius = size / 2 - 10
  let circumference = radius * 2 * Math.PI

  return (
    <div className="relative">
      <svg className="relative z-10" width={size} height={size}>
        <g>
          <circle
            stroke={color}
            stroke-width="4"
            fill="transparent"
            r={radius}
            ref={circleElement1}
            cx={circleCenter}
            cy={circleCenter}
            style={{
              strokeDasharray: `${circumference} ${circumference}`,
              strokeDashoffset:
                circumference - (percentData / 100) * circumference,
              transition: 'all 1s ease-in-out',
              transformOrigin: 'center',
              transform: 'rotate(-90deg)'
            }}
            strokeLinecap="round"
            className="origin-center"
          ></circle>

          <text
            x="50%"
            y="50%"
            text-anchor="middle"
            stroke={themeForMode.mode === 'light' ? '#000' : '#ccc'}
            stroke-width="0.5"
            dy=".3em"
            fontSize="10"
            className="dark:text-dark-light"
          >
            {percentData}
          </text>
          {/* </CustomTooltip> */}
        </g>
      </svg>
      <svg className="absolute top-0" width={size} height={size}>
        <circle
          ref={circleElement2}
          stroke="#efefef"
          stroke-width="4"
          fill="transparent"
          r={radius}
          cx={circleCenter}
          cy={circleCenter}
        />
      </svg>
    </div>
  )
}

export default Completion
