import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import Chat from './Chat'
import { useDispatch } from 'react-redux'
import { toggleChatModal } from 'thunks/generlAppState/actions'

const ChatModal = () => {
  const dispatch = useDispatch()
  const [isPersonalChat, setIsPersonalChat] = useState(false)
  const [channelId, setChannelId] = useState(null)

  const closeChatModal = () => {
    dispatch(toggleChatModal(false))
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999999,
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
      }}
      className="flex justify-center items-center"
    >
      <div
        style={{
          width: '95%',
          height: '90%'
        }}
        className="relative shadow-2xl"
      >
        <Chat
          isPersonalChat={isPersonalChat}
          channelId={channelId}
          setChannelId={setChannelId}
          setIsPersonalChat={setIsPersonalChat}
        />
        <button
          className="absolute rounded-full bg-white flex justify-center items-center top-0 right-0 w-7 h-7 shadow text-gray-700 hover:text-red-500"
          style={{ transform: 'translate(30%, -30%)' }}
          onClick={closeChatModal}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  )
}

export default ChatModal
