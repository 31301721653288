import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { storeAccessToken, storeRefreshToken, userRoles } from 'utils'
import { userSignup } from 'thunks/signup/actions'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import {
  FormLabel,
  LoadingButton,
  PasswordField
} from 'global/globalComponents'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import { RoundedTextInput, PatternScreen } from 'global/globalComponents'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import clientVenueAssets from 'static/clientVenueAssets'

const ClientSignup = ({ redirectUrl, userPath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { companyInfo, isMarketplaceDomain } = useSelector(
    state => state.generalAppState
  )
  const [loading, setLoading] = useState(false)
  const [passwordValid, setPasswordValid] = useState(true)
  const [customization, setCustomization] = useState({
    email: {
      required: true,
      view: true
    },
    password: {
      required: true,
      view: true
    },
    firstName: {
      required: true,
      view: true
    },
    lastName: {
      required: true,
      view: true
    },
    phoneNumber: {
      required: false,
      view: true
    }
  })

  const isBasicLayoutActive =
    companyInfo.data.pageCustomization?.layout === 'basic'

  const brandName = companyInfo.data.isWhiteLabelActive
    ? companyInfo.data.brand?.brandName
    : clientVenueAssets.brandName

  const classes = useStyles({ isBasicLayoutActive })

  // const [logoLoader, setLogoLoader] = useState(false)
  // const [agencyInfo, setAgencyInfo] = useState({
  //   brandName: '',
  //   favicon: '',
  //   smallLogo: '',
  //   largeLogo: ''
  // })

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    website: '',
    role: userRoles.USER_CLIENT,
    phoneNumber: ''
  })

  // useTitle(agencyInfo.brandName, agencyInfo.favicon)

  useEffect(() => {
    dispatch(
      getAgencyInfoByDomain()
      //   (res, err) => {
      //   if (!err) {
      //     setAgencyInfo(res.brand ?? clientVenueAssets)
      //   } else {
      //     setAgencyInfo(clientVenueAssets)
      //   }
      // }
    )
  }, [])

  useEffect(() => {
    if (companyInfo.data.clientSignupCustomization)
      setCustomization(companyInfo.data.clientSignupCustomization)
  }, [companyInfo.data])

  // useEffect(() => {
  //   setAgencyInfo(meData.brand ?? clientVenueAssets)
  // }, [meData])

  // useEffect(() => {
  //   if (inviteToken) {
  //     let { iat, ...userInviteData } = getParsedJwt(inviteToken)
  //     setFormData(oldData => ({
  //       ...oldData,
  //       ...userInviteData
  //     }))
  //   }
  // }, [inviteToken])

  const userSignupHandler = e => {
    e.preventDefault()

    if (
      (customization.firstName.required && !formData.firstName.trim()) ||
      (customization.lastName.required && !formData.lastName.trim()) ||
      (customization.phoneNumber.required && !formData.phoneNumber.trim()) ||
      !formData.email.trim() ||
      !formData.password.trim()
    ) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      dispatch(fireErrorToaster(t('PASSWORD_WARNING_TEXT')))
      return
    }

    setLoading(true)

    const signupData = {
      ...formData,
      name: getName(formData.firstName, formData.lastName, formData.email),
      agencyDomain:
        process.env.REACT_APP_ENVIRONMENT === 'development'
          ? 'roni.clientvenue.com'
          : window.location.host,
      agencyRepresentative: companyInfo.data._id,
      language: companyInfo.data.platformLanguage || 'en' //default english
    }

    if (isMarketplaceDomain) signupData.path = '/client/dashboard'
    if (userPath) signupData.path = userPath

    userSignup(
      {
        data: signupData
      },
      signupCallback
    )
  }

  // const handleDomain = (e) => {
  //   setDomain(e.target.value)
  // }

  const signupCallback = (res, err) => {
    setLoading(false)
    if (err) {
      // alert(res)
      dispatch(fireErrorToaster(res))
    } else {
      const searchParams = new URLSearchParams(window.location.search)
      let redirectTo = searchParams.get('redirectUrl') || redirectUrl

      if (redirectTo) {
        storeAccessToken(res.login.token.accessToken)
        storeRefreshToken(res.login.token.refreshToken)
      } else {
        redirectTo = `${window.location.origin}${res.path}?signup=true&access_token=${res.login.token.accessToken}&refresh_token=${res.login.token.refreshToken}`
      }

      window.open(redirectTo, '_self')
    }
  }

  // const fetchAgencyInfo = async () => {
  //   try {
  //     // setLogoLoader(true)
  //     const res = await getAgencyInfoByDomain()
  //     // setLogoLoader(false)
  //     setAgencyInfo(res?.data?.brand ?? clientVenueAssets)
  //   } catch (err) {
  //     setAgencyInfo(clientVenueAssets)
  //     // setLogoLoader(false)
  //   }
  // }

  const formInputHandler = e => {
    const { name, value } = e.target
    setFormData(oldState => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const phoneNumberIputHandler = (valid, phone, country) => {
    setFormData(oldData => ({
      ...oldData,
      phoneNumber: phone.length > 0 ? '+' + country.dialCode + ' ' + phone : ''
    }))
  }

  // const togglePasswordView = () => {
  //   setShowPassword((prev) => !prev)
  // }

  return (
    <>
      <PatternScreen customize={true}>
        <div>
          <header className="mb-8">
            {isBasicLayoutActive ? (
              <div className="text-center">
                <div className="text-primary-main text-4xl font-bold mb-2">
                  {brandName}
                </div>
                <div className="text-xl font-semibold text-gray-700">
                  {t('CREATE_AN')} {t('ACCOUNT')}
                </div>
              </div>
            ) : (
              <h1 className="text-4xl font-bold dark:text-dark-light">
                {t('CREATE_AN')}{' '}
                <span className="text-primary-main capitalize">
                  {t('ACCOUNT')}
                </span>
              </h1>
            )}
          </header>

          <div>
            <form onSubmit={userSignupHandler}>
              <div className="flex gap-2 mb-4">
                {customization.firstName.view && (
                  <div className="w-1/2">
                    <FormLabel
                      required={customization.firstName.required}
                      htmlFor="firstName"
                      size="small"
                    >
                      {t('FIRST_NAME')}
                    </FormLabel>
                    <RoundedTextInput
                      onChange={formInputHandler}
                      name="firstName"
                      placeholder={t('FIRST_NAME')}
                      value={formData.firstName}
                      id="firstName"
                      required={customization.firstName.required}
                      style={
                        isBasicLayoutActive
                          ? { border: '1px solid #C9CED6' }
                          : {}
                      }
                    />
                  </div>
                )}
                {customization.lastName.view && (
                  <div className="w-1/2">
                    <FormLabel
                      required={customization.lastName.required}
                      htmlFor="lastName"
                      size="small"
                    >
                      {t('LAST_NAME')}
                    </FormLabel>
                    <RoundedTextInput
                      onChange={formInputHandler}
                      name="lastName"
                      placeholder={t('LAST_NAME')}
                      value={formData.lastName}
                      id="lastName"
                      required={customization.lastName.required}
                      style={
                        isBasicLayoutActive
                          ? { border: '1px solid #C9CED6' }
                          : {}
                      }
                    />
                  </div>
                )}
              </div>

              <div className="mb-4">
                <FormLabel required htmlFor="email" size="small">
                  {t('EMAIL')}
                </FormLabel>
                <RoundedTextInput
                  type="email"
                  placeholder={t('EMAIL')}
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={formInputHandler}
                  required
                  style={
                    isBasicLayoutActive ? { border: '1px solid #C9CED6' } : {}
                  }
                />
              </div>

              {customization.phoneNumber.view && (
                <div className="mb-4">
                  <FormLabel
                    htmlFor="phoneNumber"
                    size="small"
                    required={customization.phoneNumber.required}
                  >
                    {t('PHONE_NUMBER')}
                  </FormLabel>
                  <IntlTelInput
                    onPhoneNumberChange={phoneNumberIputHandler}
                    name="phone"
                    value={formData.phoneNumber.split(' ')[1] || ''}
                    inputClassName={clsx(
                      classes.intelInput,
                      'w-full py-2.5 px-2 rounded-md text-sm dark:text-dark-light'
                    )}
                    containerClassName="intl-tel-input w-full"
                  />
                </div>
              )}

              <div className="mb-4">
                <FormLabel required htmlFor="password" size="small">
                  {t('PASSWORD')}
                </FormLabel>
                <PasswordField
                  onChange={formInputHandler}
                  id="password"
                  name="password"
                  placeholder={t('PASSWORD_MINIMUM_8_CHARACTERS')}
                  value={formData.password}
                  required
                  onBlur={validPasswordHandler}
                  style={
                    isBasicLayoutActive ? { border: '1px solid #C9CED6' } : {}
                  }
                />
                {formData.password && !passwordValid && (
                  <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                    {t('PASSWORD_WARNING_TEXT')}
                  </p>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              ></div>

              <div className="mt-4">
                <LoadingButton
                  size="large"
                  loading={loading}
                  variant="contained"
                  className="w-full"
                  type="submit"
                >
                  {t('CREATE_ACCOUNT')}
                </LoadingButton>
              </div>
            </form>

            <p className="text-xs font-medium mt-2 dark:text-dark-light">
              {t('ALREADY_REGISTERED')}{' '}
              <Link
                to={`/login${redirectUrl ? '?redirectUrl=' + redirectUrl : ''}`}
                className="text-primary-main inline-block font-bold"
              >
                {t('LOGIN_HERE')}
              </Link>
            </p>
          </div>
        </div>
      </PatternScreen>
    </>
  )
}

const getName = (firstName, lastName, email) => {
  let name = (firstName + ' ' + lastName).trim()
  return name.length > 0 ? name : email.slice(0, email.indexOf('@'))
}

const useStyles = makeStyles({
  intelInput: ({ isBasicLayoutActive }) => ({
    backgroundColor: 'transparent',
    border: isBasicLayoutActive
      ? '1px solid #C9CED6'
      : '1px solid hsl(0,0%,80%)'
  })
})

export default ClientSignup
