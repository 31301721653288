import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import userRoles, { getProjectPermissionByRole } from 'utils/userRoles'
import usePermission from './usePermission'

const defaultPermission = {
  create: false,
  delete: false,
  update: false,
  view: true
}

const useWorkspacePermission = workspaceId => {
  const workspaces = useSelector(state => state.userWorkspaces.data)
  const meData = useSelector(state => state.me.data)
  const accountPermission = usePermission()
  const innerProjectPermission = useMemo(() => {
    const ws = workspaces.find(item => item._id === workspaceId)

    if (!ws) return defaultPermission

    const matchedTeammate = ws.assignedAgencyTeam
      .concat(ws.assignedClientTeam)
      .find(mate => {
        if (typeof mate.user === 'object') {
          return mate.user._id === meData._id
        }
        return mate.user === meData._id
      })

    if (!matchedTeammate) return defaultPermission
    if (meData.role === userRoles.USER_CLIENT)
      return { ...accountPermission.workspace, role: meData.role }

    return getProjectPermissionByRole(matchedTeammate.role)
  }, [
    workspaces,
    workspaceId,
    meData._id,
    meData.role,
    accountPermission.workspace
  ])

  return innerProjectPermission
}

export default useWorkspacePermission
