import React, { useState, useEffect } from 'react'
import {
  Button,
  // CircularProgress,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  // Typography,
  TextField
} from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
// import { fetchEmbed } from 'thunks/embed/action'
import {
  CheckBoxField,
  // FormLabel,
  LoadingButton
} from 'global/globalComponents'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useTranslation } from 'react-i18next'
import { fetchEmbed } from 'thunks/embed/action'

const DuplicateModal = ({
  open,
  handleDialog,
  handleDuplicateAction,
  workspace = {},
  loading
}) => {
  const { t } = useTranslation()

  return (
    <CustomModal open={open} size="small" handleClose={handleDialog}>
      <CustomModalHeader
        heading={t('DUPLICATE_PROJECT')}
        size="medium"
        handleClose={handleDialog}
      />
      <DuplicateModalContent
        workspace={workspace}
        onClose={handleDialog}
        handleOnSubmit={handleDuplicateAction}
        loading={loading}
      />
    </CustomModal>
  )
}

const DuplicateModalContent = ({
  workspace,
  onClose,
  handleOnSubmit,
  loading
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectAll, setSelectAll] = useState(false)
  const [checkList, setCheckList] = useState([
    { key: 'Teammates', value: false, name: 'teammate' },
    { key: 'Client', value: false, name: 'client' },
    { key: 'All Tasks', value: false, name: 'task' },
    { key: 'All Subtasks', value: false, name: 'subtask' },
    { key: 'Tags', value: false, name: 'tag' },
    { key: ' Views', value: false, name: 'views' }
  ])

  const [boardList, setBoardList] = useState([])
  const [projectName, setProjectName] = useState(workspace.name)
  const [workspaceBoard, setWorkspaceBoard] = useState([])

  useEffect(() => {
    dispatch(
      fetchEmbed({ id: workspace._id }, (res, err) => {
        if (!err) {
          setWorkspaceBoard(res)
        }
      })
    )
  }, [workspace._id])

  useEffect(() => {
    const board = workspaceBoard
      .filter(el => el.type === 'board')
      .map(item => ({
        key: item,
        name: 'board',
        id: item._id,
        value: false
      }))
    setBoardList(board)
  }, [workspace._id, workspaceBoard])

  useEffect(() => {
    if (selectAll) {
      setCheckList(prev => prev.map(item => ({ ...item, value: true })))
      setBoardList(prev => prev.map(item => ({ ...item, value: true })))
    } else {
      setCheckList(prev => prev.map(item => ({ ...item, value: false })))
      setBoardList(prev => prev.map(item => ({ ...item, value: false })))
    }
  }, [selectAll])

  const handleCheckListItem = index => {
    const newData = [...checkList]
    newData[index] = { ...newData[index], value: !newData[index].value }
    setCheckList(newData)
  }

  const handleBoardListItem = index => {
    const newData = [...boardList]
    newData[index] = { ...newData[index], value: !newData[index].value }
    setBoardList(newData)
  }

  const createDuplicateProject = () => {
    const listObj = {}
    const filteredBoardList = boardList
      .filter(item => item.value === true)
      .map(el => el.id)

    checkList.forEach(item => {
      listObj[item.name] = item.value
    })

    if (filteredBoardList.length === 0) {
      return dispatch(fireErrorToaster(t('ERROR_CHECK_ATLEAST_ONE_BOARD')))
    }

    if (listObj.subtask && !listObj.task) {
      return dispatch(
        fireErrorToaster(
          t('ERROR_PLEASE_CHECK_TASK_FOR_DUPLICATING_THE_SUBTASK')
        )
      )
    }
    handleOnSubmit({
      ...listObj,
      board: filteredBoardList,
      name: projectName
    })
  }

  return (
    <>
      <div>
        <div className="text-xs px-8 py-2 mb-4 font-medium text-gray-700 bg-primary-light dark:text-dark-light">
          {t('DUPLICATING_THIS_PROJECT_WILL_MAKE_A_COPY_MSG')}
        </div>

        <CustomModalBody>
          <div className="w-full mb-4">
            <p className="text-xs font-medium text-gray-700 mb-2 dark:text-dark-light2">
              {t('PROJECT_NAME')}
            </p>
            <TextField
              required
              onChange={e => setProjectName(e.target.value)}
              value={projectName}
              className="w-full"
            />
          </div>
          <div className="py-2">
            <CheckBoxField
              value={selectAll}
              onChange={() => setSelectAll(prev => !prev)}
              label={
                <p className="text-sm font-semibold text-gray-700 dark:text-dark-light">
                  {selectAll
                    ? t('UNSELECT_ALL_CHECKBOX')
                    : t('SELECT_ALL_CHECKBOX')}
                </p>
              }
            />
          </div>
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-col">
              {checkList.map((item, index) => (
                <FormControlLabel
                  name={item.key}
                  onChange={() => handleCheckListItem(index)}
                  checked={item.value}
                  label={
                    <span className="text-xs font-medium text-gray-700 dark:text-dark-light">
                      {item.key}
                    </span>
                  }
                  control={
                    <Checkbox
                      icon={
                        <CheckBoxOutlineBlank
                          fontSize="small"
                          className="dark:text-dark-light"
                        />
                      }
                      checkedIcon={<CheckBox fontSize="small" />}
                    />
                  }
                />
              ))}
            </div>
            <div className="flex flex-col">
              <p
                className="text-xs pt-3 pb-2 font-medium dark:text-dark-light"
                sty
              >
                {t('BOARD_ATLEAST_ONE_BOARD_REQUIRED')}
              </p>
              {boardList.length === 0 ? (
                <CircularProgress className="mt-2" size={15} />
              ) : (
                boardList.map((item, index) => (
                  <FormControlLabel
                    name={item.key.name}
                    onChange={() => handleBoardListItem(index)}
                    checked={item.value}
                    label={
                      <span className="text-xs font-medium text-gray-700 dark:text-dark-light">
                        {item.key.name}
                      </span>
                    }
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlank
                            fontSize="small"
                            className="dark:text-dark-light"
                          />
                        }
                        checkedIcon={<CheckBox fontSize="small" />}
                      />
                    }
                  />
                ))
              )}
            </div>
          </div>
        </CustomModalBody>
      </div>
      <CustomModalFooter>
        <Button variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton loading={loading} onClick={createDuplicateProject}>
          {t('CREATE_NEW_PROJECT')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default DuplicateModal
