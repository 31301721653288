export const COUPON = {
  FETCHED: 'COUPON_FETCHED',
  ADD_ONE: 'COUPON_ADD_ONE',
  UPDATE: 'COUPON_UPDATE',
  DELETE: 'COUPON_DELETE'
}

const initialState = {
  loading: true,
  data: [],
  fetched: false
}

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUPON.FETCHED:
      return { loading: false, data: action.payload, fetched: true }

    case COUPON.ADD_ONE:
      return { ...state, data: [...state.data, action.payload] }

    case COUPON.UPDATE:
      return {
        ...state,
        data: state.data.map(item =>
          item._id === action.payload._id ? action.payload : item
        )
      }

    case COUPON.DELETE:
      return {
        ...state,
        data: state.data.filter(item => item._id !== action.payload)
      }

    default:
      return state
  }
}

export default couponReducer
