import React from 'react'
import ContentEditable from 'react-contenteditable'
import ImageWrapper from '../ImageWrapper'

const Testimonials = ({
  data,
  classes,
  updateTemplateSection,
  updateSectionBlock,
  isEditable
}) => {
  return (
    <div className={classes.testimonialsSection}>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>
          <ContentEditable
            html={data.heading}
            onChange={e =>
              updateTemplateSection({ field: 'heading', value: e.target.value })
            }
            disabled={!isEditable}
            className={classes.sectionHeading}
          />
          <ContentEditable
            html={data.subHeading}
            onChange={e =>
              updateTemplateSection({
                field: 'subHeading',
                value: e.target.value
              })
            }
            disabled={!isEditable}
            className={classes.sectionSubHeading}
          />
        </div>

        <div className={classes.testimonials}>
          {data.blocks.map(block => (
            <div key={block.id}>
              <div className={classes.reviewTextContainer}>
                <ContentEditable
                  html={block.description}
                  onChange={e =>
                    updateSectionBlock({
                      blockId: block.id,
                      field: 'description',
                      value: e.target.value
                    })
                  }
                  disabled={!isEditable}
                />
              </div>

              <div style={{ textAlign: 'center' }}>
                <ImageWrapper
                  src={block.image}
                  alt=""
                  isEditable={isEditable}
                  onImageUpdate={imgUrl =>
                    updateSectionBlock({
                      blockId: block.id,
                      field: 'image',
                      value: imgUrl
                    })
                  }
                />

                <ContentEditable
                  html={block.heading}
                  onChange={e =>
                    updateSectionBlock({
                      blockId: block.id,
                      field: 'heading',
                      value: e.target.value
                    })
                  }
                  disabled={!isEditable}
                  style={{ fontWeight: 600 }}
                />
                <ContentEditable
                  html={block.subHeading}
                  onChange={e =>
                    updateSectionBlock({
                      blockId: block.id,
                      field: 'subHeading',
                      value: e.target.value
                    })
                  }
                  disabled={!isEditable}
                  style={{ fontSize: 13, color: '#555' }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Testimonials
