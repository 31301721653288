import React from 'react'
import { ClickAwayListener } from '@material-ui/core'

const ServicePopup = ({
  anchorEl,
  services,
  handleSelectService,
  itemIndex,
  onClose
}) => {
  if (!anchorEl || services.length === 0) return null

  const handleSelect = service => {
    handleSelectService(service, itemIndex)
    onClose()
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div
        className="absolute bg-white rounded shadow-lg border py-2 z-10 w-40"
        style={{ top: 30 }}
      >
        {services.map(service => (
          <div
            key={service._id}
            className="px-4 py-1.5 text-smaller hover:bg-gray-100 cursor-pointer"
            onClick={() => handleSelect(service)}
          >
            {service.name}
          </div>
        ))}
      </div>
    </ClickAwayListener>
  )
}

export default ServicePopup
