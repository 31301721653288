import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import UniversalToast from 'global/globalComponents/UniversalToast/UniversalToast'
import { useSelector } from 'react-redux'
import { FilePreview } from 'global/globalComponents'
import FileUploading from 'components/FilesAssets/FileUploading'
import { AccountNotApproved } from 'components'
import FloatingTimer from 'components/Timer/FloatingTimer'

const App = props => {
  const history = useHistory()
  const { loggedIn } = useSelector(state => state.login)
  const userPath = useSelector(state => state.user.data)
  const { data: meData } = useSelector(state => state.me)
  const timerData = useSelector(state => state.timer.data)

  // If user is on "/" path then the following hook redirects user based on some conditions
  useEffect(() => {
    if (props.location.pathname !== '/') return
    if (!loggedIn) {
      history.push({
        pathname: '/login',
        search: window.location.search
      })
      return
    }
    if (userPath.path)
      history.push({ pathname: userPath.path, search: window.location.search })
  }, [loggedIn, userPath])

  return (
    <>
      {meData?.status === 'need_approval' ? (
        <AccountNotApproved open={meData?.status === 'need_approval'} />
      ) : (
        <>
          {props.children}
          <UniversalToast />
          <FilePreview />
          <FileUploading />
          {timerData.float && timerData.activeTimer && <FloatingTimer />}
        </>
      )}
    </>
  )
}

export default App
