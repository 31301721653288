import { TextField } from '@material-ui/core'
import { ColoredAvatars } from 'global/globalComponents'
import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect
} from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Pen } from 'static/svg/pen.svg'

const InvoiceHeader = forwardRef(({ invoiceData, isEditable }, ref) => {
  const { t } = useTranslation()
  const [edit, setEdit] = useState(false)
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [invoiceTitle, setInvoiceTitle] = useState('')
  const smallLogo = invoiceData.agency.agencyLogo?.smallLogo
  const agencyCompanyName = invoiceData.agency.agencyCompanyName

  useEffect(() => {
    setInvoiceNumber(
      invoiceData.customInvoiceNumber ?? invoiceData.invoiceNumber
    )

    setInvoiceTitle(invoiceData.invoiceTitle)
  }, [invoiceData])

  useImperativeHandle(ref, () => ({
    getData: () => {
      return {
        customInvoiceNumber: invoiceNumber,
        invoiceTitle
      }
    }
  }))

  const toggleEditInvoice = () => {
    setEdit(prev => !prev)
  }

  return (
    <div className="relative">
      {invoiceData.status === 'paid' && (
        <div className="absolute top-7 right-12 uppercase text-sm inline-block px-10 py-2 border border-primary-main rounded text-primary-main tracking-widest origin-bottom-left rotate-45 opacity-60">
          {t('PAID')}
        </div>
      )}

      <div className="text-right text-sm">
        {isEditable ? (
          <>
            {edit ? (
              <div className="inline-flex items-center border border-primary-main rounded px-2 py-1">
                <span>{t('INVOICE')} #</span>{' '}
                <input
                  type="text"
                  value={invoiceNumber}
                  onChange={e => setInvoiceNumber(e.target.value)}
                  className="w-24 text-right"
                  onBlur={toggleEditInvoice}
                  name="customInvoiceNumber"
                  autoFocus
                />
              </div>
            ) : (
              <div className="flex items-center space-x-2 justify-end">
                <div className="border px-2 py-1 rounded dark:border-black">
                  <span className="text-gray-700 dark:text-dark-light">
                    {t('INVOICE')} #
                  </span>
                  &nbsp;&nbsp;
                  <span className="font-medium">{invoiceNumber}</span>
                </div>
                <Pen
                  className="px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 hover:bg-gray-200"
                  onClick={toggleEditInvoice}
                />
              </div>
            )}
          </>
        ) : (
          <div className="dark:border-black">
            <span className="text-gray-700 dark:text-dark-light">
              {t('INVOICE')} #
            </span>
            &nbsp;&nbsp;
            <span className="font-medium">{invoiceNumber}</span>
          </div>
        )}
      </div>

      <div className="flex flex-col items-center text-center">
        <div className="py-6 flex flex-col items-center">
          <div>
            {smallLogo ? (
              <img src={smallLogo} alt="" className="w-24" />
            ) : (
              <div className="mb-1">
                <ColoredAvatars
                  user={
                    agencyCompanyName
                      ? { name: agencyCompanyName }
                      : {
                          name: invoiceData.agency.agencyName || ''
                        }
                  }
                  tooltip={false}
                  size="extraLarge"
                />
              </div>
            )}
          </div>
          <h4 className="font-semibold text-xl dark:text-dark-light">
            {t('INVOICE')}
          </h4>

          {isEditable ? (
            <TextField
              className="text-xs text-gray-500"
              value={invoiceTitle}
              name="invoiceTitle"
              placeholder={t('ENTER_INVOICE_TITLE')}
              onChange={e => setInvoiceTitle(e.target.value)}
            />
          ) : (
            <div className="text-xs text-gray-500">{invoiceTitle}</div>
          )}
        </div>
      </div>
    </div>
  )
})

export default InvoiceHeader
