import React, { Suspense, useEffect, useState } from 'react'
import Routes from 'Routes/Routes'
import './App.css'
import './AntdDatePicker.css'
import ThemeProvider from 'themes/themes'
import useTitle from 'customHooks/useTitle'
import 'react-datepicker/dist/react-datepicker.css'
import { Loader } from 'global/globalComponents'
import AppConnectModal from 'components/Setup/AppConnectModal'
import 'config/quillConfig'
import {
  useAppTheme,
  useAxiosConfig,
  useIntegrations,
  useSidebarState,
  useToggleChatWidget,
  useWebSocket
} from 'customHooks'
import { useDispatch } from 'react-redux'
import { setUserLoggedIn, storeTempAuthTokens } from 'thunks/login/actions'
import { getRefreshToken, storeAccessToken, storeRefreshToken } from 'utils'
import { CV_HOST } from 'constants/constantKeys'

function App() {
  useAxiosConfig() //keeping it to the top

  const dispatch = useDispatch()
  const { modal, onClose } = useIntegrations()
  const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false)

  // Storing auth token got from the url (query params)
  useEffect(() => {
    // NOTE: Don't save tokens if the domain is app.clientvenue.com

    if (window.location.host === CV_HOST) return

    let url_string = window.location.href
    let url = new URL(url_string)
    let accessToken = url.searchParams.get('access_token')
    let refreshToken = url.searchParams.get('refresh_token')

    let tempAccessToken = url.searchParams.get('temp_access_token')
    let tempRefreshToken = url.searchParams.get('temp_refresh_token')

    // These temp tokens are for client profile view from agency side (in iframe)
    if (tempAccessToken || tempRefreshToken) {
      dispatch(storeTempAuthTokens({ tempAccessToken, tempRefreshToken }))
      dispatch(setUserLoggedIn())
      return
    }

    if (accessToken || refreshToken) {
      if (accessToken) storeAccessToken(accessToken)
      if (refreshToken) storeRefreshToken(refreshToken)

      dispatch(setUserLoggedIn())
    }
  }, [])

  useEffect(() => {
    // On refresh or page visit, if the browser contains refresh token
    // set loggedIn to true
    if (getRefreshToken()) {
      dispatch(setUserLoggedIn())
    }

    window.setTimeout(() => {
      setIsAuthCheckComplete(true)
    }, 500)
  }, [])

  useAppTheme()
  useTitle()
  useSidebarState()
  useWebSocket()
  useToggleChatWidget()

  if (!isAuthCheckComplete)
    return <Loader fullScreen={true} className="dark:bg-dark-main4" />

  return (
    <Suspense
      fallback={<Loader fullScreen={true} className="dark:bg-dark-main4" />}
    >
      <ThemeProvider>
        <div className="font-sans">
          <Routes />
          {modal.isOpen && (
            <AppConnectModal
              status={modal.status}
              onClose={onClose}
              appName={modal.appName}
              errorMessage={modal.errorMessage}
            />
          )}
        </div>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
