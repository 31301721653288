import { makeStyles, createStyles } from '@material-ui/core'

const embedStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: `calc(100vh - ${theme.custom.projectHeader.height + 6}px)`,
      overflow: 'auto'
    },
    modalBackdrop: {
      position: 'fixed',
      inset: 0,
      zIndex: 1300
    },
    // embedWrapper: {
    //   height: 'calc(100vh - 100px)', //hard coded
    //   '&.expanded': {
    //     marginTop: '-21px',
    //     marginLeft: '-12px',
    //     marginRight: '-12px'
    //   }
    // },

    addViewPopup: anchorPosFromLeft => ({
      position: 'relative',
      display: 'flex',
      flexDirection:
        window.innerWidth - anchorPosFromLeft > 700 ? 'row' : 'row-reverse'
    }),
    sidebarContainer: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '180px',
      zIndex: 1,
      flexShrink: 0,
      padding: '18px 4px',
      backgroundColor:
        theme.palette.type === 'light' ? '#fff' : theme.custom.darkMode.dark1,
      borderLeft: '1px solid #e9ebf0',
      borderRight: '1px solid #e9ebf0',
      borderTop: 'none',
      borderRadius: '0 0 0 6px'
    },
    sidebarItems: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '9px',
      cursor: 'pointer',
      marginBottom: '6px',
      color:
        theme.palette.type === 'light'
          ? '#343434'
          : theme.custom.darkMode.color,
      fontSize: '13px',
      '&:hover': {
        backgroundColor: 'rgb(209, 224, 224, 0.8)'
      },
      '&.active': {
        backgroundColor: '#202020',
        color: '#fff'
      }
    },
    search: anchorPosFromLeft => ({
      width: '160px',
      position: 'absolute',
      right: window.innerWidth - anchorPosFromLeft > 700 ? 'initial' : 0,
      left: window.innerWidth - anchorPosFromLeft > 700 ? 0 : 'initial',
      // left: anchorPosFromLeft > 730 ? 'auto' : 0,
      // right: anchorPosFromLeft > 730 && 0,
      top: '-40px',
      borderRadius: '6px 6px 0 0',
      border: '1px solid #e9ebf0',
      borderBottom: 'none',
      backgroundColor:
        theme.palette.type === 'dark' ? theme.custom.darkMode.dark1 : '#fff',
      padding: '10px 16px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 0 7px rgba(0, 0, 0, 0.25)'
    }),
    wrapper: anchorPosFromLeft => ({
      position: 'fixed',
      top: theme.custom.header.height - 10,
      left: anchorPosFromLeft,
      transform:
        window.innerWidth - anchorPosFromLeft > 700
          ? 'none'
          : 'translateX(-77%)',
      willChange: 'transform',
      marginTop: '10px',
      zIndex: 1300,
      opacity: 1,
      boxShadow: '0px 0 7px rgba(0, 0, 0, 0.25)'
    }),
    area: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '490px',
      padding: '30px 0',
      backgroundColor:
        theme.palette.type === 'light'
          ? '#fafbfc'
          : theme.custom.darkMode.background,
      whiteSpace: 'normal',
      boxSizing: 'border-box'
    },
    image: {
      width: '425px',
      minHeight: '198px',
      height: 'auto',
      margin: '0 auto 35px'
    }
  })
)

export default embedStyles
