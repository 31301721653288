import React, { useEffect, useState } from 'react'
import { TextField, useTheme } from '@material-ui/core'
import Select from 'react-select'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Pill from 'global/globalComponents/Pill/Pill'
import RichTextEditor from 'global/globalComponents/QuillEditor/RichTextEditor'

const FirstStepFields = ({
  formData,
  handleUpdateFormData,
  selectedTemplate,
  handleSelectTemplate,
  openTemplatesSection,
  allTemplatesData
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const projectCategories = useSelector(
    state => state.userWorkspaces.categories
  )
  const [templateOptions, setTemplateOptions] = useState([])

  useEffect(() => {
    setTemplateOptions(allTemplatesData)
  }, [allTemplatesData])

  const handleFieldsChange = e => {
    handleUpdateFormData({ field: [e.target.name], value: e.target.value })
  }

  const filterTemplateOption = (candidate, input) => {
    return (
      Boolean(candidate.data.title.match(new RegExp(input, 'i'))) ||
      Boolean(candidate.data.category.match(new RegExp(input, 'i')))
    )
  }

  const filterCategoryOption = (candidate, input) => {
    return Boolean(candidate.data.name.match(new RegExp(input, 'i')))
  }

  const getTemplateOptionLabel = template => {
    let color
    if (template.category?.toLowerCase() === 'marketing') {
      color = 'pink'
    } else if (template.category?.toLowerCase() === 'software development') {
      color = 'blue'
    } else if (template.category?.toLowerCase() === 'design') {
      color = 'yellow'
    } else {
      color = 'green'
    }

    return (
      <div className="flex justify-between items-center">
        <span className="pr-2">
          {template.title}{' '}
          <span className="pl-2">
            {template.category ? (
              <Pill variant={color}>{template.category}</Pill>
            ) : (
              ''
            )}
          </span>
        </span>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4">
        <FormLabel required>{t('PROJECT_NAME')}</FormLabel>
        <TextField
          className="w-full"
          onChange={handleFieldsChange}
          value={formData.name}
          placeholder={t('ENTER_NAME')}
          name="name"
          required
          autoFocus
        />
      </div>
      <div className="mb-4">
        <FormLabel>{t('DESCRIPTION')}</FormLabel>
        <RichTextEditor
          style={{ height: 150 }}
          html={formData.description}
          onChange={htmlValue =>
            handleUpdateFormData({
              field: 'description',
              value: htmlValue
            })
          }
          placeholder={t('ENTER_DESCRIPTION')}
        />
        {/* <TextField
          multiline
          className="w-full"
          onChange={handleFieldsChange}
          minRows={4}
          name="description"
          value={formData.description}
          placeholder={t('ENTER_DESCRIPTION')}
        /> */}
      </div>
      <div className="mb-12">
        <div className="flex flex-wrap gap-4">
          <div className="w-full sm:flex-1">
            <FormLabel>{t('CHOOSE_PROJECT_TEMPLATE')}</FormLabel>
            <Select
              filterOption={filterTemplateOption}
              menuPlacement="bottom"
              value={selectedTemplate}
              options={templateOptions}
              onChange={handleSelectTemplate}
              styles={reactSelectCustomStyles(theme)}
              maxMenuHeight="120px"
              menuPosition="fixed"
              getOptionLabel={getTemplateOptionLabel}
              getOptionValue={option => option._id}
            />
            <span className="text-xs text-gray-500 dark:text-dark-light ">
              {t('EXPLORE_OR_CREATE_TEMPLATE')}{' '}
              <button
                onClick={openTemplatesSection}
                className="text-primary-main dark:text-dark-light"
              >
                {t('HERE')}
              </button>
            </span>
          </div>
          <div className="w-full sm:flex-1">
            <FormLabel>{t('CHOOSE_CATEGORY')}</FormLabel>
            <Select
              filterOption={filterCategoryOption}
              menuPlacement="bottom"
              value={formData.category}
              options={projectCategories}
              onChange={newVal =>
                handleUpdateFormData({ field: 'category', value: newVal })
              }
              styles={reactSelectCustomStyles(theme)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option._id}
              maxMenuHeight="120px"
              menuPosition="fixed"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default FirstStepFields
