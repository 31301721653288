import { ChevronRightRounded, HomeRounded } from '@material-ui/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUserPath } from 'utils/userRoles'

const BreadCrumbs = () => {
  const breadCrumbData = useSelector(state => state.breadcrumbs)
  const meData = useSelector(state => state.me.data)

  return (
    <ol className="flex items-center space-x-1 mb-0">
      <li className="flex items-center">
        <Link to={`${getUserPath(meData?.role)}/dashboard`}>
          <span>
            <HomeRounded
              className="flex-shrink-0 w-3 h-3 2xl:h-5 2xl:w-5 text-gray-600 cursor-pointer hover:text-gray-900 dark:text-dark-light2"
              fontSize="small"
            />
          </span>
        </Link>
      </li>
      {breadCrumbData.map(item => (
        <li key={item.name} className="flex items-center">
          <ChevronRightRounded
            className="flex-shrink-0 w-3 h-3 2xl:h-5 2xl:w-5 text-gray-400"
            fontSize="small"
          />
          <Link
            className="ml-2 text-xs 2xl:text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-dark-light"
            to={item.link}
          >
            {item.name}
          </Link>
        </li>
      ))}
    </ol>
  )
}

export default BreadCrumbs
