import { makeStyles, createStyles } from '@material-ui/core'

const clientCompanyStyles = makeStyles(theme =>
  createStyles({
    companyInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        marginTop: '2rem'
      },

      '@media(min-width:768px)': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > * + *': {
          marginTop: 0,
          marginLeft: '2rem'
        }
      }
    },

    companyInfoRight: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '1.5rem 3rem'
    }
    // container: {
    //   display: 'grid',
    //   gridTemplateColumns: '1fr 1fr 180px',
    //   gridGap: '1rem'
    // },
    // companyRep: {
    //   justifySelf: 'start',
    //   '&:hover .angleClient': {
    //     opacity: 1
    //   }
    // },
    // agencyRep: {
    //   justifySelf: 'start'
    // },
    // brandName: {
    //   '&:hover .pen1': {
    //     opacity: 1
    //   }
    // }
    // address: {
    //   '&:hover .pen2': {
    //     opacity: 1
    //   }
    // },
    // tax: {
    //   justifySelf: 'start',
    //   '&:hover .pen3': {
    //     opacity: 1
    //   }
    // },
    // currency: {
    //   justifySelf: 'start',
    //   '&:hover .angle': {
    //     opacity: 1
    //   }
    // }
  })
)

export default clientCompanyStyles
