import React from 'react'
import clsx from 'clsx'
import ContentEditable from 'react-contenteditable'
import ImageWrapper from '../ImageWrapper'

const Achievements = ({ data, classes, updateSectionBlock, isEditable }) => {
  return (
    <div className={classes.achievementsWrapper}>
      <div className={clsx(classes.achievements, classes.sectionContainer)}>
        {data.blocks.map(item => (
          <div key={item.id}>
            <div className={classes.achievementImageWrapper}>
              <ImageWrapper
                src={item.image}
                alt=""
                className={classes.achievementImage}
                isEditable={isEditable}
                onImageUpdate={imgUrl =>
                  updateSectionBlock({
                    blockId: item.id,
                    field: 'image',
                    value: imgUrl
                  })
                }
              />
              {/* <img src={item.image} alt="" /> */}
            </div>
            <div>
              <ContentEditable
                html={item.heading}
                onChange={e =>
                  updateSectionBlock({
                    blockId: item.id,
                    field: 'heading',
                    value: e.target.value
                  })
                }
                disabled={!isEditable}
                className={classes.achievementHeading}
              />
              <ContentEditable
                html={item.subHeading}
                onChange={e =>
                  updateSectionBlock({
                    blockId: item.id,
                    field: 'subHeading',
                    value: e.target.value
                  })
                }
                disabled={!isEditable}
                className={classes.achievementSubHeading}
              />
              {/* <div
                className={classes.achievementHeading}
                dangerouslySetInnerHTML={{ __html: item.heading }}
              ></div> */}
              {/* <div
                className={classes.achievementSubHeading}
                dangerouslySetInnerHTML={{ __html: item.subHeading }}
              ></div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Achievements
