import React, { useMemo } from 'react'
import { ReactComponent as CaretDown } from 'static/svg/caret-down.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getUserPath } from 'utils/userRoles'
import { NavLink, useLocation } from 'react-router-dom'
import { userRoles } from 'utils'
import { ReactComponent as ElementsIcons } from 'static/svg/element-5.svg'
import { ReactComponent as KanbanIcon } from 'static/svg/kanban.svg'
import { ReactComponent as ListIcon } from 'static/svg/list.svg'
import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { ReactComponent as ActivityIcon } from 'static/svg/activity.svg'
import { ReactComponent as InvoiceIcon } from 'static/svg/invoice.svg'
// import { ReactComponent as CommentTextIcon } from 'static/svg/comment-text.svg'
import StaticTabs from './StaticTabs'
import { useTranslation } from 'react-i18next'
// import { Badge } from '@material-ui/core'
// import headerStyles from '../../headerStyles'

const BoardStaticTabs = ({
  hiddenCols = [],
  workspaceId,
  workspaceType,
  boardId,
  reportingId
}) => {
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const userPath = useMemo(() => {
    return getUserPath(meData.role)
  }, [meData.role])
  const { search } = useLocation()

  const staticTabsData = useMemo(() => {
    return [
      {
        name: 'overview',
        label: t('OVERVIEW'),
        path: `${userPath}/workspace/${workspaceId}/overview`,
        query: '',
        icon: <ElementsIcons />,
        hide: hiddenCols.includes('overview')
      },
      {
        name: 'board',
        label: t('BOARD'),
        path: `${userPath}/workspace/${workspaceId}/board/${boardId}`,
        query: '?view=board',
        icon: <KanbanIcon />,
        hide: hiddenCols.includes('board')
      },
      {
        name: 'list',
        label: t('LIST'),
        path: `${userPath}/workspace/${workspaceId}/board/${boardId}`,
        query: '?view=list',
        icon: <ListIcon />,
        hide: hiddenCols.includes('list')
      },
      {
        name: 'calendar',
        label: t('CALENDAR'),
        path: `${userPath}/workspace/${workspaceId}/board/${boardId}`,
        query: '?view=calendar',
        icon: <CalendarIcon />,
        hide: hiddenCols.includes('calendar')
      },
      {
        name: 'invoices',
        label: t('INVOICES'),
        path: `${userPath}/workspace/${workspaceId}/invoices`,
        query: '',
        icon: <InvoiceIcon />,
        hide:
          hiddenCols.includes('invoice') ||
          (workspaceType === 'External' &&
            ![
              userRoles.USER_AGENCY,
              userRoles.AGENCY_MANAGER,
              userRoles.AGENCY_ADMIN,
              userRoles.USER_CLIENT,
              userRoles.CLIENT_MANAGER
            ].includes(meData.role))
      },
      {
        name: 'files',
        label: t('FILES'),
        path: `${userPath}/workspace/${workspaceId}/files`,
        query: '',
        icon: <FileIcon />,
        hide: hiddenCols.includes('files')
      },
      {
        name: 'reporting',
        label: t('REPORTING'),
        path: `${userPath}/workspace/${workspaceId}/status-updates${
          reportingId ? '/' + reportingId : ''
        }`,
        query: '',
        icon: <ActivityIcon />,
        hide: hiddenCols.includes('reporting')
      }
    ]
  }, [
    userPath,
    workspaceId,
    boardId,
    meData.role,
    reportingId,
    hiddenCols,
    workspaceType,
    t
  ])

  return <StaticTabs tabsData={staticTabsData} />
}

export default React.memo(BoardStaticTabs)
