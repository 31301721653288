import React, { useEffect, useState } from 'react'
import { CustomModal } from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import CalendarWrapper from './CalendarWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import TaskPanel from 'components/Workspace/Panel/TaskPanel'
// import CreateTaskPopover from 'components/Boards/CalendarView/CreateTaskPopover'
// import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { GoBack } from 'global/globalComponents'
import { getCalendarViewTasks } from 'thunks/task/actions'
import { useHistory } from 'react-router-dom'
import { fetchGoogleCalendarEvents } from 'thunks/integration/actions'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import GoogleCalendarPopup from './GoogleCalendarPopup'
import { Button } from '@material-ui/core'

const localizer = momentLocalizer(moment)

const AllTasksCalendar = ({ open, onClose }) => {
  if (!open) return null

  return (
    <CustomModal
      open={open}
      style={{
        width: '100vw',
        height: '100vh',
        maxHeight: '100vh',
        borderRadius: 0,
        maxWidth: '100vw'
      }}
      size="doublexl"
    >
      <AllTasksCalendarContent onClose={onClose} />
    </CustomModal>
  )
}

const AllTasksCalendarContent = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  // const meData = useSelector(state => state.me.data)
  const [taskPanel, setTaskPanel] = useState({
    open: false,
    data: {},
    workspace: {}
  })
  const {
    data: allTasksData,
    calendarViewTasks: calendarViewTaskIds
  } = useSelector(state => state.userTasks)
  const { data: allProjects, fetchedFirstTime } = useSelector(
    state => state.userWorkspaces
  )
  const [selectedDateRangeTasks, setSelectedDateRangeTasks] = useState([])
  const [events, setEvents] = useState([])
  const [googleCalendarEvents, setGoogleCalendarEvents] = useState([])
  const [currentDate, setCurrentDate] = useState(new Date())
  const userIntegrations = useSelector(state => state.userIntegrations)
  const [googleCalendarPopup, setGoogleCalendarPopup] = useState({
    anchorEl: null,
    eventData: {}
  })

  const isGoogleIntegrationActive =
    userIntegrations.data.findIndex(
      item => item.appName === 'google' && item.isActive
    ) !== -1

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [])

  useEffect(() => {
    setSelectedDateRangeTasks(
      calendarViewTaskIds.data
        .filter(taskId => allTasksData[taskId])
        .map(taskId => allTasksData[taskId])
    )
  }, [calendarViewTaskIds, allTasksData])

  useEffect(() => {
    fetchEventsForMonth(currentDate)
  }, [currentDate, isGoogleIntegrationActive])

  useEffect(() => {
    const mappedData = selectedDateRangeTasks
      .filter(item => item.dueDate)
      .map(item => {
        const ws = allProjects.find(ws => ws._id === item.workSpace)

        return {
          id: item._id,
          title: `${item.title} ${ws?.name ? '(' + ws.name + ')' : ''}`,
          allDay: false,
          start: item.startDate
            ? new Date(item.startDate)
            : new Date(item.createdAt),
          end: new Date(item.dueDate),
          taskSection: item.taskSection,
          workSpace: item.workSpace
        }
      })

    setEvents(mappedData)
  }, [selectedDateRangeTasks, allProjects])

  const fetchEventsForMonth = date => {
    const startDate = moment(date)
      .startOf('month')
      .startOf('week')
      .toISOString()
    const endDate = moment(date).endOf('month').endOf('week').toISOString()

    const body = {
      filters: {
        op: 'AND',
        fields: []
      },
      myApprovals: false,
      startDate: startDate,
      endDate: endDate,
      search: null
    }

    dispatch(
      getCalendarViewTasks({
        data: body,
        query: { limit: 500, type: 'calendar', page: 0 }
      })
    )

    if (isGoogleIntegrationActive) {
      fetchGoogleCalendarEvents(
        {
          params: {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD')
          }
        },
        (res, err) => {
          if (!err) {
            setGoogleCalendarEvents(
              res.map(event => ({
                id: event.id,
                title: `${event.summary} (Google Calendar)`,
                allDay: false,
                start: new Date(event.start.dateTime),
                end: new Date(event.end.dateTime),
                isGoogleCalendarEvent: true,
                data: {
                  ...event
                }
              }))
            )
          }
        }
      )
    }
  }

  const openEditTaskPanel = task => {
    const taskData = allTasksData[task.id]

    if (taskData) {
      const workspace = allProjects.find(item => item._id === task.workSpace)

      if (workspace) {
        setTaskPanel(prev => ({
          data: taskData,
          open: true,
          workspace
        }))
      }
    }
  }

  const redirectToCalendarIntegration = () => {
    onClose()
    history.push('/agency/settings/integrations/google')
  }

  return (
    <>
      <div className="p-2 flex items-center justify-between">
        <GoBack onClick={onClose} />
        {/* {!calendarViewTaskIds.loading && (
          <Button
            role="link"
            tabIndex="0"
            className="text-smaller text-blue-800 cursor-pointer hover:underline"
            onClick={redirectToCalendarIntegration}
            disabled={isGoogleIntegrationActive}
          >
            {t('SYNC_GOOGLE_CALENDAR_MESSAGE')}
          </Button>
        )} */}
      </div>
      <CalendarWrapper
        isLoading={calendarViewTaskIds.loading}
        events={[...events, ...googleCalendarEvents]}
        openTaskSidePanel={openEditTaskPanel}
        openGoogleCalendarPopup={setGoogleCalendarPopup}
        localizer={localizer}
        handleNavigate={date => setCurrentDate(date)}
      />

      <TaskPanel
        taskData={taskPanel.data}
        open={taskPanel.open}
        onClose={() => setTaskPanel(prev => ({ ...prev, open: false }))}
        workspace={taskPanel.workspace}
        inDashboardArea={true}
        isOutsideBoardArea={true}
        displayProjectField={true}
      />

      {googleCalendarPopup.anchorEl && (
        <GoogleCalendarPopup
          anchorEl={googleCalendarPopup.anchorEl}
          eventData={googleCalendarPopup.eventData}
          onClose={() =>
            setGoogleCalendarPopup(prev => ({ ...prev, anchorEl: null }))
          }
        />
      )}
    </>
  )
}

export default AllTasksCalendar
