import React, { useEffect, useState } from 'react'
import { Backdrop, Button, CircularProgress, Radio } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import { userRoles } from 'utils'
import {
  fetchClientCardDetails,
  updateClientPaymentCard
} from 'thunks/clientPayment/actions'
import { fetchAgencyStripeAccountId } from 'thunks/stripeAccount/actions'
import CardDetails from './CardDetails'
import AddNewCard from './AddNewCard'
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { createClientSubscription } from 'thunks/subscription/actions'
import { LoadingButton } from 'global/globalComponents'

const SubscribeServiceModal = ({ open, onClose, serviceData }) => {
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const [fetchedCardsData, setFetchedCardsData] = useState({
    card: [],
    defaultCard: ''
  })
  const [stripeAccount, setStripeAccount] = useState({
    loading: true,
    data: {}
  })
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_KEY,
    stripeAccount.data.accountId
      ? {
          stripeAccount: stripeAccount.data.accountId
        }
      : undefined
  )

  useEffect(() => {
    if (meData.role === userRoles.USER_CLIENT)
      fetchClientCardDetails((res, err) => {
        if (!err) {
          setFetchedCardsData(res)
        }
      })
  }, [meData])

  useEffect(() => {
    fetchAgencyStripeAccountId(null, (res, err) => {
      if (!err) {
        const stripeData = res.data.find(item => item.type === 'stripe')
        if (stripeData) {
          setStripeAccount({ loading: false, data: stripeData })
        } else {
          setStripeAccount({ loading: false, data: {} })
        }
      } else {
        setStripeAccount({ loading: false, data: {} })
      }
    })
  }, [])

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <CustomModalHeader heading={t('SELECT_PAYMENT_METHOD')} />
      <Elements stripe={stripePromise}>
        <ModalContent
          t={t}
          onClose={onClose}
          serviceData={serviceData}
          fetchedCardsData={fetchedCardsData}
          stripeAccount={stripeAccount}
          meData={meData}
        />
      </Elements>
    </CustomModal>
  )
}

const ModalContent = ({
  t,
  fetchedCardsData,
  serviceData,
  onClose,
  stripeAccount,
  meData
}) => {
  const dispatch = useDispatch()
  const [allCards, setAllCards] = useState([])
  const [selectedCardId, setSelectedCardId] = useState(null)
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const addNewCard = !selectedCardId

  useEffect(() => {
    setAllCards(fetchedCardsData.card)
    setSelectedCardId(fetchedCardsData.defaultCard)
  }, [fetchedCardsData])

  const handleSubscribe = async () => {
    setLoading(true)

    if (!selectedCardId) {
      try {
        await handleAddCard()
      } catch (err) {
        setLoading(false)
        return
      }
    } else if (selectedCardId !== fetchedCardsData.defaultCard) {
      try {
        await handleUpdateDefaultCard(selectedCardId)
      } catch (err) {
        setLoading(false)
        return
      }
    }

    dispatch(
      createClientSubscription(
        {
          body: {
            services: [serviceData._id],
            trialDays: serviceData.recurring.trial_period_days,
            client: meData._id
          }
        },
        (res, err) => {
          setLoading(false)
          if (err) dispatch(fireErrorToaster(res))
          else {
            dispatch(fireSuccessToaster(t('SUBSCRIPTION_SUCCESSFUL')))
            onClose()
          }
        }
      )
    )
  }

  const handleAddCard = async () => {
    let token = await stripe.createToken(elements.getElement(CardElement))

    return new Promise((resolve, reject) => {
      updateClientPaymentCard(
        { body: { token: { id: token.token.id } } },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            reject(res)
          } else {
            resolve(res)
          }
        }
      )
    })
  }

  const handleUpdateDefaultCard = cardId => {
    return new Promise((resolve, reject) => {
      updateClientPaymentCard({ body: { card: cardId } }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }

  return (
    <>
      <CustomModalBody>
        <div className="h-92">
          {allCards.map((card, index) => (
            <CardDetails
              t={t}
              key={index}
              card={card}
              isSelected={selectedCardId === card.id}
              onSelect={() => setSelectedCardId(card.id)}
            />
          ))}
          <div>
            <div
              onClick={() => setSelectedCardId(null)}
              className="mt-4 inline-flex items-center text-primary-main text-smaller font-semibold cursor-pointer"
            >
              <Radio checked={addNewCard} />
              &nbsp;
              {t('USE_ANOTHER_CARD')}
            </div>
            {addNewCard ? (
              <>
                {stripeAccount.loading ? (
                  <div className="pl-10">
                    <CircularProgress size={20} />
                  </div>
                ) : stripeAccount.data.accountId ? (
                  <AddNewCard />
                ) : (
                  <div className="text-smaller pl-10">
                    {t('CANNOT_ADD_PAYMENT_METHOD_MESSAGE')}
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton loading={loading} size="large" onClick={handleSubscribe}>
          {t('SUBSCRIBE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default SubscribeServiceModal
