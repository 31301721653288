import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import sidebarStyles from './sidebarStyles'
import clsx from 'clsx'
import { toggleSidebar } from 'thunks/generlAppState/actions'
import SidebarHeader from './SidbarHeader'
import SidebarBody from './SidebarBody'
import SidebarFooter from './SidebarFooter'

const Sidebar = ({ isDrawerOpen }) => {
  const classes = sidebarStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    const closeSidebar = () => {
      if (window.innerWidth <= 992) {
        dispatch(toggleSidebar(false))
      }
    }

    if (window.innerWidth <= 992) {
      closeSidebar() // invoke this function after reload if screen size is less than 992
    }

    window.addEventListener('resize', closeSidebar)

    return () => {
      window.removeEventListener('resize', closeSidebar)
    }
  }, [])

  return (
    <div
      className={clsx(
        'relative border-t border-r bg-white dark:bg-dark-main2 dark:text-dark-light dark:border-dark-main3',
        classes.sidebar,
        isDrawerOpen && 'expended'
      )}
      id="appSidebar"
    >
      <div className="h-full flex flex-col overflow-y-auto overflow-x-hidden">
        <SidebarHeader isDrawerOpen={isDrawerOpen} />
        <SidebarBody isDrawerOpen={isDrawerOpen} />
        <SidebarFooter isDrawerOpen={isDrawerOpen} />
      </div>
    </div>
  )
}

export default Sidebar
