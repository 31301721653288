import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'

const CustomMenu = props => {
  const { t } = useTranslation()
  return (
    <components.MenuList {...props}>
      {props.selectProps.selectProps.canAdd && (
        <div className="flex items-center justify-between px-4 py-2">
          <button
            onClick={props.selectProps.selectProps.toggleCreateRoleModal}
            className="text-sm text-primary-main flex items-center px-2 py-1"
          >
            <AddIcon className="mr-1" />
            <span>{t('CREATE_NEW_ROLE')}</span>
          </button>
        </div>
      )}
      {props.children}
    </components.MenuList>
  )
}

export default CustomMenu
