import React, { useMemo, useRef, useState, useEffect } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { ColoredAvatars } from 'global/globalComponents'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { ReactComponent as PaperClipIcon } from 'static/svg/paperclip.svg'
import { ReactComponent as EmotionHappy } from 'static/svg/emotion-happy.svg'
import AttachmentFiles from '../AttachmentFiles'
import uniqueId from 'lodash/uniqueId'
import Picker from '@emoji-mart/react'
import { getMentionConfig } from '../editorsStatic'
import { useTranslation } from 'react-i18next'
let Quill = ReactQuill.Quill

const CommentEditor = ({
  users,
  onSubmit,
  // onSelectUser,
  fileUploadHandler,
  options = {},
  secondaryBtn,
  placeholder,
  emojiWrapperStyles = {}
}) => {
  const classes = useStyles()
  const editorRef = useRef()
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const [fileStack, setFileStack] = useState([])
  const [loading, setLoading] = useState(false)

  const modules = useMemo(() => {
    const mention = getMentionConfig(users)
    const obj = {
      toolbar: {
        container: '.quill-toolbar'
      },
      magicUrl: true,
      mention: {
        ...mention,
        onSelect: (item, insertItem) => {
          const user = users.find(user => user._id === item.id)
          insertItem({ ...item, ...user, notify: meData._id !== user._id })
          // onSelectUser(user)
        }
      }
    }
    return obj
  }, [])

  useEffect(() => {
    if (!editorRef.current) return
    const editor = editorRef.current.editor

    const handleFilePaste = e => {
      for (let i = 0; i < e.clipboardData.items.length; i++) {
        const item = e.clipboardData.items[i]

        //currently only supporting image paste
        if (item?.type.indexOf('image') === 0) {
          e.preventDefault()
          const file = item.getAsFile()
          addFileToStack(file)
        }
      }
    }

    editor.root.addEventListener('paste', handleFilePaste)

    return () => {
      if (!handleFilePaste) return
      editor.root.removeEventListener('paste', handleFilePaste)
    }
  }, [])

  const addEmoji = selectedEmoji => {
    if (!selectedEmoji) return
    const editor = editorRef.current.editor
    const range = editor.getSelection()
    const idx = range?.index || 0

    editor.insertEmbed(idx, 'emojiEmbed', selectedEmoji)
    editor.insertText(idx + 1, '')
    editor.setSelection(idx + 2, Quill.sources.SILENT)
  }

  const commentCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      editorRef.current.editor.setContents({})
      setFileStack([])
    }
  }

  const addFileToStack = file => {
    let uniqId = uniqueId()

    setFileStack(prev => [
      ...prev,
      { id: uniqId, name: file.name, loading: true, url: null }
    ])

    fileUploadHandler(file, (res, err) => {
      if (err) {
        setFileStack(prev => prev.filter(item => item.id !== uniqId)) // filter out this file
      } else {
        setFileStack(prev =>
          prev.map(item =>
            item.id === uniqId
              ? { ...item, loading: false, url: res.link }
              : item
          )
        )
      }
    })
  }

  const handleSubmitComment = () => {
    const editor = editorRef.current.editor
    const editorContents = editor.getContents()
    const mention = editorContents.ops.find(
      item => item.insert.mention || item.insert.emojiEmbed
    )
    // console.log(editorContents)
    if (editor.getText().trim() === '' && fileStack.length === 0 && !mention)
      return

    setLoading(true)
    onSubmit({
      comment: editorContents,
      attachments: fileStack.map(item => item.url),
      callback: commentCallback
    })
  }

  return (
    <div className="pt-4 border-t">
      <div className={clsx(classes.container, 'flex')}>
        <ColoredAvatars user={meData} size="normal" />

        <div
          className="ml-2 border rounded dark:bg-dark-main4"
          style={{ width: '95%' }}
        >
          <ReactQuill
            theme="snow"
            modules={modules}
            ref={editorRef}
            placeholder={`${placeholder || t('COMMENT_PLACEHOLDER')}...`}
            className={classes.editor}
          />

          {options.file && <AttachmentFiles files={fileStack} />}
          <CustomToolbar
            addFileToStack={addFileToStack}
            handleSubmitComment={handleSubmitComment}
            disabled={fileStack.find(item => item.loading)}
            loading={loading}
            addEmoji={addEmoji}
            options={options}
            secondaryBtn={secondaryBtn}
            emojiWrapperStyles={emojiWrapperStyles}
          />
        </div>
      </div>
    </div>
  )
}

const CustomToolbar = ({
  addFileToStack,
  handleSubmitComment,
  disabled,
  loading,
  addEmoji,
  options,
  secondaryBtn,
  emojiWrapperStyles
}) => {
  const ref = useRef()
  const [data, setData] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
      .then(res => res.json())
      .then(res => setData(res))
  }, [])

  const handleTriggerUpload = () => {
    ref.current.click()
  }

  const handleUploadImage = e => {
    for (let i = 0; i < e.target.files.length; i++) {
      addFileToStack(e.target.files[i])
    }
    e.target.value = ''
  }

  const onComment = () => {
    if (loading) return
    handleSubmitComment()
  }

  const handleSelectEmoji = emoji => {
    addEmoji(emoji.native)
    setIsOpen(false)
  }

  return (
    <div className="flex flex-wrap gap-2  items-center quill-toolbar">
      <div>
        <button className="ql-bold" title="bold" />
        <button className="ql-italic" title="italic" />
        {options.file && (
          <button title="attach file" onClick={handleTriggerUpload}>
            <PaperClipIcon />
          </button>
        )}
        <button title="add emoji" onClick={() => setIsOpen(true)}>
          <EmotionHappy className="w-5 h-5" />
        </button>
        <div
          style={{
            position: 'fixed',
            transform: 'translate(100px, -100%)',
            zIndex: 9999,
            ...emojiWrapperStyles
          }}
          className={clsx(!isOpen && 'hidden')}
        >
          {data.emojis && (
            <Picker
              data={data}
              onEmojiSelect={handleSelectEmoji}
              onClickOutside={isOpen ? () => setIsOpen(false) : undefined}
            />
          )}
        </div>
      </div>

      <div className="flex gap-2 ml-auto">
        <span className="secondary-btn-wrapper">{secondaryBtn}</span>

        <button
          className="btn-comment relative"
          disabled={disabled}
          onClick={onComment}
        >
          <div
            className={clsx(
              'absolute top-0 left-0 w-full h-full justify-center items-center',
              loading ? 'flex' : 'hidden'
            )}
          >
            <CircularProgress size={20} color="#ccc" />
          </div>
          Comment
        </button>
      </div>

      <input
        ref={ref}
        onChange={handleUploadImage}
        type="file"
        accept="*"
        className="hidden"
        multiple
      />
    </div>
  )
}

// container height => 150, toolbar height = 55
const useStyles = makeStyles(theme => ({
  container: {
    // ===== Toolbar styling ====
    '& .ql-toolbar.ql-snow': {
      border: 'none',
      padding: '0 10px',
      height: 0,
      overflow: 'hidden',
      transition: 'height 220ms ease-in-out'
    },

    '&:focus-within .ql-toolbar.ql-snow': {
      height: 55
    },

    '&:focus-within .ql-editor': {
      minHeight: 80
    },

    '& .ql-snow.ql-toolbar .btn-comment': {
      width: 82,
      height: 38,
      background: theme.palette.primary.main,
      color: '#fff',
      borderRadius: 8,
      fontSize: 13,
      cursor: 'pointer',
      opacity: 0.8,

      '&:hover': {
        opacity: 1
      },

      '&:disabled': {
        opacity: 0.7,
        cursor: 'default'
      }
    },

    '& .ql-snow.ql-toolbar .secondary-btn-wrapper button': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 82,
      height: 38,
      background: '#fff',
      color: '#111',
      borderRadius: 8,
      fontSize: 13,
      cursor: 'pointer',
      opacity: 0.8,
      border: '1px solid #818181',
      marginRight: 10,

      '&:hover': {
        opacity: 1
      }
    },
    '@media (max-width: 768px)': {
      '&:focus-within .ql-toolbar.ql-snow': {
        height: 85
      },
      '& .ql-snow.ql-toolbar .btn-comment': {
        height: 30
      },
      '& .ql-snow.ql-toolbar .secondary-btn-wrapper button': {
        height: 30
      }
    }
  },
  editor: {
    '& .ql-editor': {
      minHeight: 42,
      maxHeight: 215,
      transition: 'min-height 220ms ease-in-out'
    },
    '& .ql-container.ql-snow': {
      border: 'none'
    },
    '& .emoji': {
      fontSize: 15
    }
  },
  emojiContainer: {
    position: 'fixed',
    bottom: 70,
    zIndex: 9,

    '& button.epr-emoji': {
      display: 'inline-flex !important',
      width: 'var(--epr-emoji-fullsize) !important',
      height: 'var(--epr-emoji-fullsize) !important',
      float: 'none'
    }
  }
}))

export default CommentEditor
