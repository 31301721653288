export const WIDGET = {
  LOADING: 'WIDGET_LOADING',
  FETCHED: 'WIDGET_FETCHED',
  ADD_NEW: 'WIDGET_ADD_NEW',
  UPDATE_ONE: 'WIDGET_UPDATE_ONE',
  REMOVE_ONE: 'WIDGET_REMOVE_ONE',
  FETCHED_PROJECT_ANALYTICS: 'FETCHED_PROJECT_ANALYTICS',
  FETCHED_TEAMMATE_TIMESHEET: 'FETCHED_TEAMMATE_TIMESHEET',
  FETCHED_STANDUPS_LOADING: 'FETCHED_STANDUPS_LOADING',
  FETCHED_STANDUPS: 'FETCHED_STANDUPS'
}

const initialState = {
  loading: true,
  fetched: false,
  data: [],
  projectAnalytics: {
    loading: true,
    fetched: false,
    data: {}
  },
  teammateTimesheet: {
    loading: true,
    fetched: false,
    data: {}
  },
  standups: {
    loading: true,
    fetched: false,
    data: []
  }
}

export const widgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case WIDGET.FETCHED:
      return { ...state, loading: false, fetched: true, data: action.payload }

    case WIDGET.ADD_NEW:
      return { ...state, data: [...state.data, action.payload] }

    case WIDGET.UPDATE_ONE:
      const updatedData = state.data.map((item) => {
        if (item._id === action.payload._id) return action.payload
        return item
      })
      return { ...state, data: updatedData }

    case WIDGET.REMOVE_ONE:
      const filteredData = state.data.filter(
        (item) => item._id !== action.payload
      )
      return { ...state, data: filteredData }

    case WIDGET.FETCHED_PROJECT_ANALYTICS:
      return {
        ...state,
        projectAnalytics: {
          loading: false,
          fetched: true,
          data: action.payload
        }
      }

    case WIDGET.FETCHED_TEAMMATE_TIMESHEET:
      return {
        ...state,
        teammateTimesheet: {
          loading: false,
          fetched: true,
          data: action.payload
        }
      }

    case WIDGET.FETCHED_STANDUPS_LOADING:
      return {
        ...state,
        standups: {
          ...state.standups,
          loading: true,
          fetched: false
        }
      }

    case WIDGET.FETCHED_STANDUPS:
      return {
        ...state,
        standups: {
          loading: false,
          fetched: true,
          data: action.payload
        }
      }

    default:
      return state
  }
}
export default widgetReducer
