import React from 'react'
import InvoiceFromTo from './InvoiceFromTo'
import InvoicePricingTable from './InvoicePricingTable'
import InvoiceOtherInfo from './InvoiceOtherInfo'
import InvoiceHeader from './InvoiceHeader'

const PreviewInvoiceTemplate1 = ({ invoiceData, publicView }) => {
  return (
    <div className="bg-white border p-4 rounded dark:text-dark-light dark:bg-dark-main dark:border-black">
      <div className="mb-4">
        <InvoiceHeader invoiceData={invoiceData} />
      </div>

      <hr className="dark:border-dark-main1" />
      <div className="px-2 sm:px-8">
        <InvoiceFromTo publicView={publicView} invoiceData={invoiceData} />

        <InvoicePricingTable invoiceData={invoiceData} />

        <div className="mt-12">
          <InvoiceOtherInfo invoiceData={invoiceData} />
        </div>
        <div className="pb-8"></div>
      </div>
    </div>
  )
}

export default PreviewInvoiceTemplate1
