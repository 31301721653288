import { t } from 'i18next'
import ForgotModal from './ForgotModal'

const ForgotPassword = () => {
  return (
    <ForgotModal
      name="password"
      title={t('RESET_LINK_HAS_BEEN_SENT_TO_YOUR_MAIL')}
      buttonText={t('BUTTON_TEXT_RESET_PASSWORD')}
      link="/login"
    />
  )
}

export default ForgotPassword
