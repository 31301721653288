import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleProjectPermissionModal,
  toggleEmbedModal
} from 'thunks/generlAppState/actions'
import EmbedModal from 'components/EmbedModal/EmbedModal'
import CreateApprovalModal from 'components/_shared/Approvals/CreateApprovalModal'
import { ProjectPermissionModal } from 'components/_shared'

const DashboardPanelAndModals = () => {
  const dispatch = useDispatch()
  const { modal, sidePanel } = useSelector(state => state.generalAppState)
  const { editTeammate: editTeammateSidePanelStatus } = sidePanel

  const handleCloseEmbedModal = () => {
    dispatch(toggleEmbedModal(false))
  }

  return (
    <>
      <ProjectPermissionModal
        open={editTeammateSidePanelStatus}
        onClose={() => dispatch(toggleProjectPermissionModal())}
      />
      <CreateApprovalModal />
      {modal.embedModal.open && (
        <EmbedModal
          onClose={handleCloseEmbedModal}
          anchorPosFromLeft={modal.embedModal.anchorPos}
        />
      )}
    </>
  )
}

export default DashboardPanelAndModals
