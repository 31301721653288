import React from 'react'
import { useDispatch } from 'react-redux'
import LinkIcon from '@material-ui/icons/Link'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { useTranslation } from 'react-i18next'

const ShareBoardCopyLink = ({ url }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url)
    dispatch(fireSuccessToaster(t('LINK_COPIED')))
  }

  return (
    <div className="bg-custom-gray-light py-2">
      <div className="flex flex-row justify-between py-2">
        <div className="flex">
          <LinkIcon fontSize="small" />
          <p className="ml-2 text-smaller 2xl:text-sm font-medium dark:text-dark-light2">
            {t('PUBLIC_LINK')}
          </p>
        </div>
        <p className="text-xs font-normal text-gray-600 dark:text-dark-light">
          {t('ONLY_THOSE_WITH_THE_LINK_CAN_ACCESS')}
        </p>
      </div>

      <div className="border my-2 p-2 flex justify-between bg-white dark:bg-dark-main4">
        <p className="text-smaller 2xl:text-sm font-medium truncate dark:text-dark-light">
          {url}
        </p>
        <span
          role="button"
          className="text-smaller 2xl:text-sm font-medium text-primary-main cursor-pointer whitespace-nowrap"
          onClick={handleCopyLink}
        >
          {t('COPY_LINK')}
        </span>
      </div>
    </div>
  )
}

export default React.memo(ShareBoardCopyLink)
