import React, { useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as MinusIcon } from 'static/svg/minus.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { Button, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useModal } from 'customHooks'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { FormLabel } from 'global/globalComponents'
import clsx from 'clsx'

const FAQ = ({
  classes,
  data,
  isEditable,
  updateTemplateSection,
  updateSectionBlock,
  addBlockItem,
  removeBlockItem
}) => {
  const { t } = useTranslation()
  const { modal, openModal, closeModal } = useModal()

  const handleDeleteFAQ = index => {
    removeBlockItem({ itemIndex: index })
  }

  return (
    <>
      <div style={{ backgroundColor: '#e8f0f3', padding: '2rem 0' }}>
        <div className={classes.sectionContainer}>
          <ContentEditable
            html={data.heading}
            onChange={e =>
              updateTemplateSection({ field: 'heading', value: e.target.value })
            }
            disabled={!isEditable}
            className={classes.sectionHeading}
          />

          {data.blocks.map((item, index) => (
            <FAQAccordion
              {...item}
              key={item.id}
              classes={classes}
              updateSectionBlock={updateSectionBlock}
              isEditable={isEditable}
              onDelete={
                index > 2
                  ? () => {
                      removeBlockItem({ blockId: item.id })
                    }
                  : undefined
              }
            />
          ))}

          <div className="mt-8 text-right">
            {isEditable && (
              <Button onClick={openModal} startIcon={<AddIcon />}>
                {t('ADD')}
              </Button>
            )}
          </div>
        </div>
      </div>

      {modal.open && (
        <AddFAQModal
          open={true}
          onClose={closeModal}
          addBlockItem={addBlockItem}
        />
      )}
    </>
  )
}

const FAQAccordion = ({
  id,
  classes,
  heading,
  description,
  updateSectionBlock,
  isEditable,
  onDelete
}) => {
  const [showAnswer, setShowAnswer] = useState(false)

  return (
    <div className={classes.faqAccordion}>
      {onDelete && (
        <button onClick={onDelete} className={classes.removeFaqAccordionBtn}>
          <CrossIcon />
        </button>
      )}
      <div className={classes.faqAccordionQuestionWrapper}>
        <ContentEditable
          html={heading}
          onChange={e =>
            updateSectionBlock({
              blockId: id,
              field: 'heading',
              value: e.target.value
            })
          }
          disabled={!isEditable}
        />
        <button onClick={() => setShowAnswer(prev => !prev)}>
          {showAnswer ? <MinusIcon /> : <AddIcon />}
        </button>
      </div>

      <ContentEditable
        html={description}
        onChange={e =>
          updateSectionBlock({
            blockId: id,
            field: 'description',
            value: e.target.value
          })
        }
        disabled={!isEditable}
        className={classes.faqAccordionAnswerWrapper}
        style={{
          maxHeight: !showAnswer ? 0 : '100%',
          opacity: !showAnswer ? 0 : 1,
          overflow: 'hidden',
          marginTop: showAnswer ? '0.5rem' : 0,
          transition: 'opacity 500ms 100ms ease'
        }}
      />
    </div>
  )
}

const AddFAQModal = ({ open, onClose, addBlockItem }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    question: '',
    answer: ''
  })

  const handleAddFaq = () => {
    if (!formData.question) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    addBlockItem({
      newItem: {
        heading: formData.question,
        description: formData.answer
      }
    })

    onClose()
  }

  return (
    <CustomModal size="small" open={open} handleClose={onClose}>
      <CustomModalHeader heading={`${t('ADD')} FAQ`} handleClose={onClose} />
      <CustomModalBody>
        <div className="mb-2">
          <FormLabel required>{t('QUESTION')}</FormLabel>
          <TextField
            value={formData.question}
            onChange={e =>
              setFormData(prev => ({ ...prev, question: e.target.value }))
            }
            fullWidth
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('ANSWER')}</FormLabel>
          <TextField
            value={formData.answer}
            onChange={e =>
              setFormData(prev => ({ ...prev, answer: e.target.value }))
            }
            multiline
            fullWidth
            rows={4}
          />
        </div>

        <Button onClick={handleAddFaq}>{t('ADD')}</Button>
      </CustomModalBody>

      <CustomModalFooter></CustomModalFooter>
    </CustomModal>
  )
}

export default FAQ
