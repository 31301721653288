import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Currency from 'static/Currency/Currency'
import ServicesPopup from '../ServicePopup'

const InvoiceItem = ({
  invoiceItem,
  itemIndex,
  classes,
  removeInvoiceItem,
  updateInvoiceItemField,
  currency,
  services,
  handleSelectService,
  type,
  isEditable
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState('')
  const [matchedServices, setMatchedServices] = useState([])
  const ref = useRef()

  const handleItemNameChange = e => {
    const value = e.target.value.trim().toLowerCase()

    updateInvoiceItemField(e)

    if (type === 'service') {
      setMatchedServices(
        services.filter(item => item.name.toLowerCase().includes(value))
      )
    }
  }

  return (
    <div className={clsx('border-b py-2 mb-2')}>
      <div className={clsx(classes.invoicePricingTable, 'relative')}>
        {invoiceItem.type === 'addon' && (
          <div
            style={{ position: 'absolute', left: 0, top: -13 }}
            className="text-primary-main font-semibold text-xs"
          >
            {t('ADD_ON')}
          </div>
        )}

        <div className="relative">
          {isEditable ? (
            <input
              className="text-sm py-1 hover:bg-gray-100 rounded border border-transparent focus:bg-gray-100  focus:border-primary-main px-1 dark:focus:bg-dark-main1 dark:bg-dark-main1 dark:border-dark-main w-full"
              type="text"
              placeholder={t('EXAMPLE_ITEM')}
              value={invoiceItem.name}
              name="name"
              data-index={itemIndex}
              onChange={handleItemNameChange}
              ref={ref}
              autoComplete="none"
              onFocus={
                type === 'service' ? e => setAnchorEl(e.target) : undefined
              }
              onClick={e => e.stopPropagation()}
            />
          ) : (
            <div className="text-sm py-1 px-1 dark:bg-dark-main1">
              {invoiceItem.name}
            </div>
          )}

          {isEditable && (
            <ServicesPopup
              anchorEl={anchorEl}
              services={matchedServices}
              handleSelectService={handleSelectService}
              itemIndex={itemIndex}
              onClose={() => setAnchorEl(null)}
            />
          )}
        </div>

        {isEditable ? (
          <input
            className="text-sm text-right py-1 rounded border border-transparent hover:bg-gray-100 focus:bg-gray-100 focus:border-primary-main px-1 dark:focus:bg-dark-main1 dark:bg-dark-main1 dark:border-dark-main"
            type="number"
            placeholder="0"
            value={invoiceItem.quantity}
            name="quantity" //unit
            data-index={itemIndex}
            onChange={updateInvoiceItemField}
          />
        ) : (
          <div className="text-sm text-right py-1 px-1 dark:bg-dark-main1">
            {invoiceItem.quantity}
          </div>
        )}

        {isEditable ? (
          <input
            className="text-sm text-right rounded border border-transparent py-1 hover:bg-gray-100 focus:bg-gray-100 focus:border-primary-main px-1 dark:focus:bg-dark-main1 dark:bg-dark-main1 dark:border-dark-main"
            type="number"
            placeholder="0.0"
            value={invoiceItem.rate}
            name="rate"
            data-index={itemIndex}
            onChange={updateInvoiceItemField}
          />
        ) : (
          <div className="text-sm text-right py-1 px-1 dark:bg-dark-main1">
            {invoiceItem.rate}
          </div>
        )}

        <div className="text-right text-sm">
          <span>{Currency[currency.toUpperCase()].symbol_native}</span>
          <span>
            {(invoiceItem.rate * invoiceItem.quantity).toLocaleString()}
          </span>
        </div>

        {isEditable && (
          <>
            {itemIndex !== 0 && (
              <button
                className="absolute p-1 text-primary-main text-lg"
                style={{ right: -16 }}
                onClick={() => removeInvoiceItem(itemIndex)}
              >
                &#8722;
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default InvoiceItem
