import { t } from 'i18next'

const userRoles = {
  USER_ADMIN: 1,

  PROJECT_OWNER: 15,

  USER_AGENCY: 20,
  AGENCY_MANAGER: 21,
  AGENCY_EDITOR: 22,
  AGENCY_VIEWER: 23,
  AGENCY_ADMIN: 24,

  USER_CLIENT: 30,
  CLIENT_MANAGER: 31,
  CLIENT_EDITOR: 32,
  CLIENT_VIEWER: 33,
  CLIENT_ADMIN: 34
}

export default userRoles
export const getAgencyRoleOptions = () => [
  { value: 24, label: t('C-LEVEL') },
  { value: 21, label: t('MANAGER') },
  { value: 22, label: t('COLLABORATOR') },
  { value: 23, label: t('VIEWER') }
]

export const getAgencyRoleOptionsForManager = () => [
  { value: 21, label: t('MANAGER') },
  { value: 22, label: t('COLLABORATOR') },
  { value: 23, label: t('VIEWER') }
]
export const getClientRoleOptions = () => [
  { value: 31, label: t('MANAGER') },
  { value: 32, label: t('EDITOR') },
  { value: 33, label: t('VIEWER') }
]

// export const agencyRoleOptions = [
//   { value: 24, label: 'Admin' },
//   { value: 21, label: 'Manager' },
//   { value: 22, label: 'Editor' },
//   { value: 23, label: 'Viewer' }
//   // { value: 20, label: 'Super Admin' }
// ]

export const agencyRoleOptionsForManager = [
  { value: 21, label: 'Manager' },
  { value: 22, label: 'Editor' },
  { value: 23, label: 'Viewer' }
]

export const clientRoleOptions = [
  // { value: 30, label: 'Owner' },
  { value: 31, label: 'Manager' },
  { value: 32, label: 'Editor' },
  { value: 33, label: 'Viewer' }
]

export const clientCompanyRoleOption = [
  { value: 30, label: 'Owner' },
  { value: 34, label: 'Admin' }
]

export const agencyRoleLabel = {
  20: 'Super Admin',
  21: 'Manager',
  22: 'Editor',
  23: 'Viewer',
  24: 'Admin'
}

export const clientRoleLabel = {
  30: 'Owner',
  31: 'Manager',
  32: 'Editor',
  33: 'Viewer',
  34: 'Admin'
}

export const getRoleLabel = role => {
  const res = [
    ...getAgencyRoleOptions(),
    ...clientRoleOptions,
    { value: 20, label: 'Super Admin' },
    { value: 34, label: 'Admin' },
    { value: 15, label: 'Project Owner' }
  ].find(item => item.value === role)

  return res?.label
}

export const getUserPath = role => {
  const arr = [
    userRoles.USER_ADMIN,
    userRoles.USER_AGENCY,
    userRoles.AGENCY_MANAGER,
    userRoles.AGENCY_EDITOR,
    userRoles.AGENCY_VIEWER,
    userRoles.AGENCY_ADMIN
  ]
  if (arr.includes(role)) return '/agency'
  else return '/client'
}

// get super admin from me data
export const getAgencyOwner = meData => {
  if (meData.role === userRoles.USER_AGENCY) {
    return meData
  } else if (
    [
      userRoles.USER_CLIENT,
      userRoles.AGENCY_MANAGER,
      userRoles.AGENCY_EDITOR,
      userRoles.AGENCY_VIEWER,
      userRoles.AGENCY_ADMIN
    ].includes(meData.role)
  ) {
    return meData.team
  } else if (
    [
      userRoles.CLIENT_MANAGER,
      userRoles.CLIENT_EDITOR,
      userRoles.CLIENT_VIEWER
    ].includes(meData.role)
  ) {
    return meData.agency
  }
  return null
}

export const getProjectPermissionByRole = role => {
  if (
    [
      userRoles.PROJECT_OWNER,
      userRoles.USER_AGENCY,
      userRoles.USER_ADMIN,
      userRoles.AGENCY_MANAGER,
      userRoles.AGENCY_ADMIN
    ].includes(role)
  ) {
    return { create: true, delete: true, update: true, view: true, role }
  }

  if (
    [
      userRoles.AGENCY_EDITOR
      // userRoles.CLIENT_MANAGER,
      // userRoles.CLIENT_EDITOR
    ].includes(role)
  ) {
    return { create: false, delete: false, update: true, view: true, role }
  }

  return { create: false, delete: false, update: false, view: true, role }
}

export const isCurrentUserRoleHigher = ({ currentUserRole, otherUserRole }) => {
  // Note: Currently, the clientVenue Admin role is 24, which is greater than 23 (agency viewer).
  // However, according to the hierarchy rule (higher the permission, lower the role), the admin's role should be greater than the super admin (20) but less than the Manager (21).
  // To resolve this conflict, here I'm assuming that the admin's role is 20.5.
  // Same way I'm handling the project owner's role conflict

  const user1 =
    currentUserRole === userRoles.AGENCY_ADMIN
      ? userRoles.USER_AGENCY + 0.5
      : currentUserRole === userRoles.PROJECT_OWNER
      ? userRoles.USER_AGENCY + 0.75
      : currentUserRole
  const user2 =
    otherUserRole === userRoles.AGENCY_ADMIN
      ? userRoles.USER_AGENCY + 0.5
      : otherUserRole === userRoles.PROJECT_OWNER
      ? userRoles.USER_AGENCY + 0.75
      : otherUserRole

  return user1 < user2
}
