import { useState } from 'react'

const useModal = () => {
  const [modal, setModal] = useState({
    open: false,
    data: {},
    loading: false
  })

  const openModal = (data = {}) => {
    setModal({ open: true, data, loading: false })
  }

  const closeModal = () => {
    setModal(prev => ({ ...prev, open: false }))
  }

  const toggleLoading = () => {
    setModal(prev => ({ ...prev, loading: !prev.loading }))
  }

  return { modal, openModal, closeModal, toggleLoading }
}

export default useModal
