import { PageNotFoundImage } from 'static/svg'
import { useTranslation } from 'react-i18next'

const PageNotFound = () => {
  const { t } = useTranslation()

  return (
    <div className="w-full h-full flex flex-col justify-center items-center text-center">
      <img src={PageNotFoundImage} alt="" style={{ maxWidth: '300px' }} />
      <h2 className="text-2xl mt-4 font-medium text-gray-800 dark:text-gray-400 tracking-wide">
        {t('PAGE_NOT_FOUND')}
      </h2>
      <a
        href="/"
        className="mt-4 bg-primary-main text-white px-4 py-1 rounded font-medium"
      >
        {t('GO_HOME')}
      </a>
    </div>
  )
}

export default PageNotFound
