import { getErrorMessages } from './errorMessages'

/**
 * Async function that doesn't use dispatch
 * @param {Function} fn
 * @returns function
 */
export const catchAsync = fn => {
  const errorMessages = getErrorMessages()

  return (...rest) => {
    fn(...rest).catch(err => {
      const errMsg = err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      const callback = rest[rest.length - 1]
      if (typeof callback === 'function') callback(errMsg, true)
      console.log('error', err)
    })
  }
}

/**
 *
 * @param {Function} fn
 * @param {Function} callback
 * @returns function
 */
export const catchAsyncDispatch = (fn, callback) => {
  const errorMessages = getErrorMessages()

  return (...rest) => {
    fn(...rest).catch(err => {
      const errMsg = err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      if (typeof callback === 'function') callback(errMsg, true)
      console.log('error', err)
    })
  }
}
