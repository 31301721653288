import { List, ListItem, Popover } from '@material-ui/core'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getUserPath } from 'utils/userRoles'
import headerStyles from '../../headerStyles'
import { ReactComponent as DotIcon } from 'static/svg/3dots-h.svg'
import { useTranslation } from 'react-i18next'

const EmbedDropdown = ({
  deleteEmbedLink,
  handleClose,
  anchorEl,
  data,
  iconPopoverData,
  setIconPopoverData,
  // handleDeleteLink,
  innerProjectPermission
}) => {
  const { t } = useTranslation()
  const classes = headerStyles()
  const { customURL } = useParams()
  const history = useHistory()
  const me = useSelector(state => state.me)
  const { currentWorkspace } = useSelector(state => state.userWorkspaces)
  const [deletedData, setDeletedData] = useState({})

  const firePopper = item => {
    handleClose()
    if (!innerProjectPermission) {
      history.push(
        customURL
          ? `/public/${customURL}/${item?.type
              ?.replace('google', '')
              ?.toLowerCase()}/${item?._id}`
          : `/public/share/board/${currentWorkspace._id}/${item?.type
              ?.replace('google', '')
              ?.toLowerCase()}/${item?._id}`
      )
    } else {
      history.push(
        `${getUserPath(me.data?.role)}/workspace/${
          currentWorkspace?._id
        }/${item?.type?.replace('google', '')?.toLowerCase()}/${item?._id}`
      )
    }
  }
  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.popoverPaper }}
        style={{
          top: 10
          // left: 40
        }}
      >
        <List disablePadding style={{ minWidth: '140px', maxWidth: '250px' }}>
          {data?.map((item, index) => (
            <ListItem key={index} button>
              <p
                onClick={() => firePopper(item)}
                style={{ width: '90%', fontSize: '14px', paddingRight: '5px' }}
              >
                <span className="capitalize">{item.name}</span>
              </p>

              {innerProjectPermission.delete && (
                <DotIcon
                  onClick={e => {
                    setIconPopoverData({
                      open: true,
                      anchorEl: e.currentTarget
                    })
                    setDeletedData(item)
                  }}
                  className="h-4 w-4 pl-2"
                />
              )}
            </ListItem>
          ))}
        </List>
      </Popover>
      <Popover
        open={iconPopoverData.open}
        onClose={() => {
          setIconPopoverData({ open: false, anchorEl: null })
          // onClose()
        }}
        anchorEl={iconPopoverData.anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        classes={{ paper: classes.iconsPopoverPaper }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List
          disablePadding
          dense
          style={{ minWidth: '140px', maxWidth: '250px' }}
        >
          <ListItem onClick={() => deleteEmbedLink(deletedData)} button>
            <p style={{ fontSize: '14px' }}>{t('DELETE')}</p>
          </ListItem>
          {/* <ListItem onClick={() =>{}} button>
            <p style={{ fontSize: '14px' }}>Change Name</p>
          </ListItem> */}
        </List>
      </Popover>
    </>
  )
}

export default EmbedDropdown
