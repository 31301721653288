import axios from 'axios'
import { USER } from 'thunks/user/reducers'
import { ME } from '../me/reducers'
import { catchAsyncDispatch } from 'utils'

/**
 * Update data of user
 * @param {Function} callback function will run after fetch. Recieve (err/response, error status) as arguments
 */
export const whitelabel = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/agencyprofile/agency/whitelabel/${data.id}`,
      data: data.body
    })

    dispatch({ type: USER.UPDATE_PROFILE_DATA, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * update whitelabel url for agency
 * @param {String} id user id
 * @param {data} data  oldWhitelabelUrl,WhitelabelUrl
 * @param {Function} callback function will run after fetch. Recieve (err/response, error status) as arguments
 */

export const whitelabelSubDomain = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/user/whitelabelurl/${data.id}`,
      data: data.body
    })

    dispatch({
      type: ME.UPDATE_AGENCY_DOMAIN,
      payload: res.data.whitelabelUrl
    })
    if (callback) callback(res.data, false)
  }, callback)
}
