import { useTranslation } from 'react-i18next'
import ForgotModal from './ForgotModal'

const ForgotDomain = () => {
  const { t } = useTranslation()
  return (
    <ForgotModal
      name="domain"
      title=""
      buttonText={t('SEND')}
      link="/login/domain"
    />
  )
}

export default ForgotDomain
