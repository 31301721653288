import React from 'react'
import { Fade, Popper } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import { ReactComponent as InfoIcon } from 'static/svg/info.svg'
import { ReactComponent as BellIcon } from 'static/svg/bell.svg'

const GoogleCalendarPopup = ({ anchorEl, eventData, onClose }) => {
  const startDate = new Date(eventData.start)
    .toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric'
    })
    .replace(' at', ',')
    .replace(' PM', 'pm')
    .replace(' AM', 'am')
  const endDate = new Date(eventData.end)
    .toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric'
    })
    .replace(' at', ',')
    .replace(' PM', 'pm')
    .replace(' AM', 'am')

  let remindBefore = []

  if (eventData.data.reminders) {
    if (eventData.data.reminders.useDefault) {
      remindBefore.push({ number: 30, unit: 'minutes' })
    } else if (eventData.data.reminders.overrides) {
      const units = ['minutes', 'hours', 'days', 'weeks']

      eventData.data.reminders.overrides.forEach(item => {
        units.forEach(u => {
          if (item[u]) {
            remindBefore.push({ number: item[u], unit: u })
          }
        })
      })
    }
  }

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transition
      style={{ zIndex: 2000, background: 'transparent' }}
      // placement="top-end"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div className="bg-white shadow-2xl border rounded-md pb-2">
            <div className="flex justify-end px-2 py-2">
              <button
                className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-full"
                onClick={onClose}
              >
                <CrossIcon />
              </button>
            </div>
            <div className="flex items-start px-6 mb-4">
              <InfoIcon className="w-5 h-5 text-primary-main" />

              <div className="ml-4">
                <div className="text-lg font-medium leading-4 text-primary-main mb-1">
                  {eventData.data.summary}
                </div>
                <div className="text-sm text-gray-800">
                  {startDate} - {endDate}
                </div>
              </div>
            </div>

            {remindBefore.length > 0 && (
              <div className="flex items-center px-6 mb-4">
                <BellIcon className="w-5 h-5 text-primary-main" />

                <div className="ml-4">
                  {remindBefore.map((item, index) => (
                    <div key={index} className="text-sm text-gray-800">
                      {item.number} {item.unit} before
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="flex items-center px-6 mb-4">
              <CalendarIcon className="w-5 h-5 text-primary-main" />

              <div className="ml-4 text-sm text-gray-800">
                {eventData.data.creator.email}
              </div>
            </div>
          </div>
        </Fade>
      )}
    </Popper>
  )
}

export default GoogleCalendarPopup
