import React, { useEffect } from 'react'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { fetchAllServices } from 'thunks/service/actions'
import { ServiceCard } from 'components/_shared'
import { useRouteMatch } from 'react-router-dom'
import LandingServicesContainerHeader from './LandingServicesContainerHeader'

const LandingServicesContainer = () => {
  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const userServices = useSelector(state => state.userServices)

  useEffect(() => {
    dispatch(fetchAllServices())
  }, [])

  const handleServiceCardClick = serviceId => {
    history.push(`${path}/${serviceId}/checkout`)
  }

  return (
    <div>
      <LandingServicesContainerHeader />

      <div className="px-6 my-10">
        <SectionHeading>{t('OUR_SERVICES')}</SectionHeading>

        <div className="mt-8">
          {!userServices.loading && userServices.data.length === 0 && (
            <p className="text-center text-sm">{t('NO_DATA_FOUND')}</p>
          )}

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
              gridGap: '1rem',
              gap: '1rem'
            }}
          >
            {userServices.loading ? (
              <LoadingCards />
            ) : (
              userServices.data.map((service, index) => (
                <ServiceCard
                  key={index}
                  service={service}
                  onClick={handleServiceCardClick}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const LoadingCards = () => {
  return (
    <>
      {new Array(5).fill('').map((item, index) => (
        <Skeleton key={index} variant="rect" height={300} />
      ))}
    </>
  )
}
export default LandingServicesContainer
