import React, { useEffect, useState } from 'react'
import { Button, TextField, useTheme } from '@material-ui/core'
import { FormLabel, LoadingButton, ToggleSwitch } from 'global/globalComponents'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { t } from 'i18next'
import QuestionMarkTooltip from 'global/globalComponents/QuestionMarkTooltip'
// import { ReactComponent as Ticket } from 'static/svg/ticket.svg'
import DatePicker from 'react-datepicker'
// import RichTextEditor from 'global/globalComponents/QuillEditor/RichTextEditor'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useDispatch, useSelector } from 'react-redux'
import { createCoupon, updateCoupon } from 'thunks/coupon/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import clsx from 'clsx'

const CreateCouponModal = ({ open, onClose, data = {} }) => {
  if (!open) return null

  return (
    <CustomModal size="large" open={open} handleClose={onClose}>
      <CustomModalHeader
        heading={data._id ? t('EDIT_COUPON') : t('CREATE_COUPON')}
        handleClose={onClose}
      />

      <ModalContent open={open} onClose={onClose} data={data} />
    </CustomModal>
  )
}

const ModalContent = ({ onClose, data }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const discountTypeOptions = [
    {
      label: t('PERCENTAGE'),
      value: 'percentage'
    },
    { label: t('FIXED_AMOUNT'), value: 'fixed' }
  ]

  const [formData, setFormData] = useState({
    code: '',
    description: '',
    discount: '',
    once_per_customer: false,
    max_redemptions: '',
    expiryDate: ''
  })
  const userServices = useSelector(state => state.userServices)
  const couponsData = useSelector(state => state.coupons.data)
  const [selectedDiscountType, setSelectedDiscountType] = useState(
    discountTypeOptions[0]
  )
  const [selectedServices, setSelectedServices] = useState([])
  const [isMaxRedemptionsOn, setIsMaxRedemptionsOn] = useState(false)
  const [isExpiryDateOn, setIsExpiryDateOn] = useState(false)
  const [loading, setLoading] = useState(false)
  const isEditing = Boolean(data._id)

  useEffect(() => {
    if (data._id) {
      setFormData({
        code: data.code,
        description: data.description,
        discount: data.discount,
        once_per_customer: data.once_per_customer,
        max_redemptions: data.max_redemptions,
        expiryDate: data.expiryDate
      })

      setSelectedDiscountType(
        discountTypeOptions.find(item => item.value === data.type)
      )

      const mappedServices = data.services
        .map(serviceId => {
          return userServices.data.find(service => service._id === serviceId)
        })
        .filter(item => Boolean(item))

      setSelectedServices(mappedServices)
      setIsMaxRedemptionsOn(Boolean(data.max_redemptions))
      setIsExpiryDateOn(Boolean(data.expiryDate))
    }
  }, [data, userServices])

  const handleChange = e => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]:
        e.target.name === 'code' ? e.target.value.toUpperCase() : e.target.value
    }))
  }

  const handleCreateCoupon = () => {
    // ====== Validation ======
    if (formData.code.trim === '' || !parseInt(formData.discount)) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    if (parseInt(formData.discount) <= 0) {
      dispatch(fireErrorToaster(t('PLEASE_ENTER_VALID_INPUT')))
      return
    }

    const matchedCoupon = couponsData.find(item => item.code === formData.code)

    if (matchedCoupon) {
      dispatch(
        fireErrorToaster(
          t('COUPON_EXIST_MESSAGE', { couponCode: matchedCoupon.code })
        )
      )
      return
    }

    setLoading(true)
    const body = {
      ...formData,
      type: selectedDiscountType.value,
      discount: parseInt(formData.discount),
      max_redemptions: parseInt(formData.max_redemptions),
      services: selectedServices.map(service => service._id),
      expiryDate: formData.expiryDate
        ? new Date(formData.expiryDate).toISOString()
        : null
    }

    dispatch(
      createCoupon({ body }, (res, err) => {
        setLoading(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('COUPON_CREATED_MESSAGE')))
          onClose()
        }
      })
    )
  }

  const generateCouponCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const len = 6
    let code = ''

    for (let i = 0; i < len; i++) {
      code += chars[Math.floor(Math.random() * chars.length)]
    }

    setFormData(prev => ({ ...prev, code }))
  }

  const handleUpdateCoupon = () => {
    setLoading(true)

    const body = {
      description: formData.description
    }

    dispatch(
      updateCoupon({ id: data._id, body }, (res, err) => {
        setLoading(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
          onClose()
        }
      })
    )
  }

  return (
    <>
      <CustomModalBody>
        <div className="mb-4">
          <div className="flex items-center">
            <FormLabel required className="mr-2">
              {t('COUPON_CODE')}
            </FormLabel>

            <div className="mb-1">
              <QuestionMarkTooltip text={t('COUPON_INFO')} direction="right" />
            </div>
          </div>

          <div className="flex">
            <TextField
              name="code"
              value={formData.code}
              onChange={handleChange}
              className="flex-1 hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
              placeholder="EX: NEW25"
              disabled={isEditing}
            />

            <button
              onClick={generateCouponCode}
              className={clsx(
                'px-4 ml-2 rounded border transition-colors text-smaller font-semibold uppercase',
                isEditing
                  ? 'bg-gray-200 cursor-default'
                  : 'border-primary-main text-primary-main hover:bg-primary-main hover:text-white'
              )}
              disabled={isEditing}
            >
              {t('GENERATE')}
            </button>
          </div>
        </div>

        <div className="mb-4">
          <FormLabel>{t('DESCRIPTION')}</FormLabel>
          <TextField
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="flex-1 hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
            multiline
            rows={4}
          />
        </div>

        <div
          className="mb-4"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '1rem',
            gridGap: '1rem'
          }}
        >
          <div>
            <FormLabel>{t('DISCOUNT_TYPE')}</FormLabel>
            <Select
              styles={reactSelectCustomStyles(theme)}
              options={discountTypeOptions}
              value={selectedDiscountType}
              onChange={setSelectedDiscountType}
              placeholder={t('SELECT_DISCOUNT_TYPE')}
              isDisabled={isEditing}
            />
          </div>

          <div>
            <FormLabel required>{t('DISCOUNT')}</FormLabel>
            <TextField
              name="discount"
              type="number"
              value={formData.discount}
              onChange={handleChange}
              className="flex-1 hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
              placeholder="25"
              disabled={isEditing}
            />
          </div>

          {/* <div>
              <FormLabel required htmlFor="description">
                {t('DISCOUNT_DURATION_FOR_RECURRING_SERVICES')}
              </FormLabel>
              <Select
                styles={reactSelectCustomStyles(theme)}
                options={durationOptions}
                onChange={(value) => setFormData(prev => ({...prev, durationForRecurringServices: value }))}
                value={formData.durationForRecurringServices}
                placeholder={t('SELECT_WORKFLOW')}
              />
            </div> */}
        </div>

        <div className="mb-4">
          <FormLabel htmlFor="description">
            {t('APPLIES_TO_SERVICES')}
          </FormLabel>
          <Select
            styles={reactSelectCustomStyles(theme)}
            options={userServices.data}
            getOptionLabel={option => option.name}
            getOptionValue={option => option._id}
            value={selectedServices}
            onChange={setSelectedServices}
            placeholder={t('SELECT_SERVICES')}
            isMulti={true}
            maxMenuHeight={120}
            isDisabled={isEditing}
          />
        </div>

        <div>
          <div className="font-semibold">{t('SET_LIMITS')}</div>

          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-600">
              {t('ONE_USE_PER_CUSTOMER')}
            </div>

            <div>
              <ToggleSwitch
                checked={formData.once_per_customer}
                onChange={e =>
                  setFormData(prev => ({
                    ...prev,
                    once_per_customer: e.target.checked
                  }))
                }
                disable={isEditing}
              />
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center">
              <div className="text-sm text-gray-600">
                {t('SET_TOTAL_NUMBER_OF_USES')}
              </div>

              <div>
                <ToggleSwitch
                  checked={isMaxRedemptionsOn}
                  onChange={e => setIsMaxRedemptionsOn(e.target.checked)}
                  disable={isEditing}
                />
              </div>
            </div>
            {isMaxRedemptionsOn && (
              <input
                type="number"
                className="text-smaller border px-2 py-1 rounded w-20 border-gray-300 dark:border-dark-main"
                value={formData.max_redemptions}
                onChange={e =>
                  setFormData(prev => ({
                    ...prev,
                    max_redemptions: e.target.value
                  }))
                }
                alt=""
                disabled={isEditing}
              />
            )}
          </div>

          <div className="mb-8">
            <div className="flex justify-between items-center">
              <div className="text-sm text-gray-600">
                {t('SET_EXPIRY_DATE')}
              </div>

              <div>
                <ToggleSwitch
                  checked={isExpiryDateOn}
                  onChange={e => setIsExpiryDateOn(e.target.checked)}
                  disable={isEditing}
                />
              </div>
            </div>

            {isExpiryDateOn && (
              <DatePicker
                // className="text-smaller bg-gray-100 border px-2 py-1 rounded"
                selected={
                  formData.expiryDate
                    ? new Date(formData.expiryDate)
                    : new Date()
                }
                onChange={date =>
                  setFormData(prev => ({
                    ...prev,
                    expiryDate: date
                  }))
                }
                className="text-smaller px-2 py-1 border rounded w-28"
                dateFormat="MMM d, yyyy"
                disabled={isEditing}
              />
            )}
          </div>

          {/* <div className="flex justify-between items-center">
            <div className="text-sm text-gray-600">
              {t('APPLIES_TO_PAID_TRIALS')}
            </div>

            <div>
              <ToggleSwitch
                checked={formData.appliesToPaidTrials}
                onChange={e =>
                  setFormData(prev => ({
                    ...prev,
                    appliesToPaidTrials: e.target.checked
                  }))
                }
              />
            </div>
          </div> */}
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <div className="flex items-center gap-4">
          <Button size="large" variant="outlined" onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <LoadingButton
            onClick={data._id ? handleUpdateCoupon : handleCreateCoupon}
            loading={loading}
            disabled={loading}
            size="large"
          >
            {data._id ? t('SAVE_CHANGES') : t('CREATE')}
          </LoadingButton>
        </div>
      </CustomModalFooter>
    </>
  )
}

export default CreateCouponModal
