import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { fetchUserActivePlanInfo } from 'thunks/userActivePlanInfo/actions'
import headerStyles from './headerStyles'
import GlobalHeader from './GlobalHeader/GlobalHeader'
import WorkspaceHeader from './WorkspaceHeader/WorkspaceHeader'
import { userRoles } from 'utils'
import { fetchUnreadNotificationsCount } from 'thunks/accountLogs/action'
import {
  hidePayNowStripe,
  showPayNowStripe
} from 'thunks/generlAppState/actions'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const { payNowStripe, normalHeader } = useSelector(
    state => state.generalAppState
  )
  const { sidebarOpen } = useSelector(state => state.generalAppState)
  const { allInvoices: agencyInvoices } = useSelector(
    state => state.agencyPayment
  )

  const classes = headerStyles()
  const unreadNotificationsCount = useSelector(
    state => state.accountLogs.totalUnread
  )

  useEffect(() => {
    if (meData.role === userRoles.USER_AGENCY) {
      dispatch(fetchUserActivePlanInfo())
    }
  }, [meData.role])

  useEffect(() => {
    dispatch(fetchUnreadNotificationsCount())
  }, [])

  useEffect(() => {
    const paidStatus = meData.payment?.status ?? meData.team?.payment?.status
    const newInvoice = agencyInvoices
      .slice(0, 2)
      .filter(invoice => invoice.status !== 'paid')

    if (
      newInvoice.length > 0 &&
      paidStatus === 'incomplete' &&
      meData.role === userRoles.USER_AGENCY
    ) {
      dispatch(showPayNowStripe(newInvoice[0].hosted_invoice_url))
    }
  }, [agencyInvoices, meData])

  return (
    <div
      className={clsx(
        classes.headerContainer,
        {
          sidebarExpanded: sidebarOpen
        },
        'bg-white dark:bg-dark-main'
      )}
    >
      {payNowStripe.isVisible && (
        <div
          className={clsx(
            `relative flex justify-center items-center space-x-4 bg-primary-main z-10`,
            classes.payStripe
          )}
        >
          <button
            className="absolute right-2 text-white"
            onClick={() => dispatch(hidePayNowStripe())}
          >
            <CrossIcon className="w-4 h-4" />
          </button>

          <p className="mb-0 text-white text-sm">
            {t('PAYMENT_FAILED_STRIP_MESSAGE')}
          </p>
          <a
            href={payNowStripe.redirectUrl}
            // target="_blank"
            className="ml-4 bg-white text-primary-main px-4 py-2 rounded
                  font-medium text-xs"
          >
            {t('PAY_NOW')}
          </a>
        </div>
      )}

      {normalHeader ? (
        <div
          className={clsx(
            'flex border-t border-b justify-between items-center dark:border-dark-main',
            classes.globalHeader
          )}
        >
          <GlobalHeader
            unreadNotificationsCount={unreadNotificationsCount}
            isSidebarOpen={sidebarOpen}
          />
        </div>
      ) : (
        <div
          className={clsx(
            classes.projectHeader,
            'flex items-center border-t border-b dark:border-dark-main3'
          )}
        >
          <WorkspaceHeader isSidebarOpen={sidebarOpen} />
        </div>
      )}
    </div>
  )
}

export default React.memo(Header)
