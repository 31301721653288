import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
// import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
// import { OutlinedButton } from 'global/globalComponents'

const CreateStandupsModalTop = ({
  selectedTab,
  handleTabChange
  // showMyTasks,
  // setShowMyTasks
}) => {
  const { t } = useTranslation()
  // const toggleShowMyTasks = () => {
  //   setShowMyTasks((prev) => !prev)
  // }
  return (
    <div className="flex items-center justify-between">
      <div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab
            label={
              <div className="flex items-center gap-1 dark:text-dark-light">
                {t('MORNING')}
              </div>
            }
            value={'morning'}
          />
          <Tab
            label={
              <div className="flex items-center gap-1 dark:text-dark-light">
                {t('EVENING')}
              </div>
            }
            value={'evening'}
          />
        </Tabs>
      </div>

      {/* <OutlinedButton
        isActive={showMyTasks}
        onClick={toggleShowMyTasks}
        hoverColorChange={false}
      >
        <FilterIcon />
        <span className="ml-2">Assigned to me</span>
      </OutlinedButton> */}
    </div>
  )
}

export default CreateStandupsModalTop
