import React, { useState, useImperativeHandle } from 'react'
import { CustomPopper, LoadingButton } from 'global/globalComponents'
import { CheckBoxField } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
// import { useEffect } from 'react'
// import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
// import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
// import clsx from 'clsx'
// import { Popover } from '@material-ui/core'

const SelectTabPopup = React.forwardRef(
  (
    {
      anchorEl,
      handleClose,
      colOptions,
      toggleColumnsVisibility,
      hiddenCols,
      saveView,
      handleSaveView
    },
    ref
  ) => {
    // const [colOptions, setColOption] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
      setLoading: setLoading
    }))

    return (
      <CustomPopper anchorEl={anchorEl} onClose={handleClose}>
        <div
          className="py-4 rounded-xl"
          // style={{ width: 250, maxHeight: 415 }}
        >
          <header className="px-4 mb-2">
            <div className=" mb-2">
              <h5 className="text-sm font-bold dark:text-dark-light2">
                {t('SHOW_TABS_FOR_THIS_PROJECT')}
              </h5>
              <p className="text-xs w-64 dark:text-dark-light">
                {t('SELECTED_TABS_WILL_BE_AVAILABLE_FOR_YOU')}
              </p>
            </div>
          </header>

          <ul
            className="px-4 overflow-y-auto overflow-x-hidden"
            style={{ width: 250, maxHeight: 250 }}
          >
            {colOptions.map(item => (
              <li key={item.accessor}>
                <CheckBoxField
                  name="toggle column"
                  onChange={e => {
                    toggleColumnsVisibility(item.accessor, e.target.checked)
                  }}
                  checked={!hiddenCols.includes(item.accessor)}
                  label={item.label.toUpperCase()}
                  disabled={item.disable}
                />
              </li>
            ))}
          </ul>
          <hr className="my-2" />

          <div className="px-4 flex justify-end">
            <LoadingButton
              size="small"
              disabled={!saveView}
              onClick={handleSaveView}
              loading={loading}
            >
              {t('SAVE')}
            </LoadingButton>
          </div>
        </div>
      </CustomPopper>
    )
  }
)

export default SelectTabPopup
