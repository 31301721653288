import { useDispatch } from 'react-redux'
import taskSidePanelStyles from '../Workspace/Panel/taskSidePanelStyles'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { FieldLabel } from './CreateTaskPanel'
import clsx from 'clsx'
import { setHours } from 'date-fns'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import Collaborator from 'components/Workspace/Panel/Collaborator'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useTranslation } from 'react-i18next'

const TaskBody = ({
  taskState,
  triggerPopover,
  innerProjectPermission,
  handleDateChange,
  handleRemoveTeammate,
  project
}) => {
  const { t } = useTranslation()
  const classes = taskSidePanelStyles()

  return (
    <div className={clsx(classes.taskBodyContainer, 'px-6 mb-4')}>
      <RowContainer>
        <div>
          <FieldLabel>{t('COLLABORATORS')}</FieldLabel>
        </div>
        <div>
          <TaskCollaborators
            workspace={project}
            taskState={taskState}
            innerProjectPermission={innerProjectPermission}
            handleRemoveTeammate={handleRemoveTeammate}
            triggerPopover={triggerPopover}
          />
        </div>
      </RowContainer>
      <RowContainer>
        <div>
          <FieldLabel>{t('START_DATE')}</FieldLabel>
        </div>
        <div className="flex gap-1">
          <div className={classes.pseudoDropDownContainer}>
            <DatePicker
              selected={
                taskState.startDate
                  ? new Date(taskState.startDate)
                  : setHours(new Date(), new Date().getHours())
              }
              onChange={date => {
                handleDateChange(date, 'startDate')
              }}
              customInput={
                taskState.startDate ? (
                  <input style={{ width: '180px', padding: '4px 0' }} />
                ) : (
                  <CalenderIcon className="text-base text-gray-600 dark:text-dark-light cursor-pointer" />
                )
              }
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              showDisabledMonthNavigation
            />
          </div>
        </div>
      </RowContainer>
      <RowContainer>
        <div>
          <FieldLabel>{t('DUE_DATE')}</FieldLabel>
        </div>
        <div className="flex gap-1">
          <div className={classes.pseudoDropDownContainer}>
            <div>
              <DatePicker
                selected={
                  taskState.dueDate
                    ? new Date(taskState.dueDate)
                    : setHours(new Date(), new Date().getHours())
                }
                onChange={date => {
                  handleDateChange(date, 'dueDate')
                }}
                showTimeSelect
                customInput={
                  taskState.dueDate ? (
                    <input style={{ width: '180px', padding: '4px 0' }} />
                  ) : (
                    <CalenderIcon className="text-base text-gray-600 dark:text-dark-light cursor-pointer" />
                  )
                }
                minDate={
                  taskState.startDate
                    ? new Date(taskState.startDate)
                    : new Date()
                }
                placeholderText="Select Date"
                dateFormat="MMM d, yyyy h:mm aa"
              />
            </div>
          </div>
        </div>
      </RowContainer>
    </div>
  )
}

export const RowContainer = props => {
  const { children, ...restProps } = props
  return (
    <div {...restProps} className="flex items-center space-x-4 leading-3">
      {children}
    </div>
  )
}

const TaskCollaborators = ({
  workspace,
  taskState,
  innerProjectPermission,
  handleRemoveTeammate,
  triggerPopover
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleAddCollaborator = e => {
    if (workspace.isComplete) {
      dispatch(
        fireErrorToaster(
          t(
            'ERROR_WORKSPACE_IS_ALREADY_COMPLETED_AND_CANNOT_ADD_TEAM_MEMBERS',
            {
              data: workspace.name
            }
          )
        )
      )
      return
    }

    triggerPopover(e, 'collab')
  }

  return (
    <div className="flex space-x-2 items-center ">
      {taskState.collaborators.map(teammate => (
        <Collaborator
          key={teammate._id}
          teammate={teammate}
          handleRemove={handleRemoveTeammate}
          removeBtn={true}
        />
      ))}
      <button
        type="button"
        onClick={handleAddCollaborator}
        className="flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
      >
        <AddIcon style={{ fontSize: '16px' }} />
      </button>
    </div>
  )
}

export default TaskBody
