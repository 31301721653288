import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

const ServicesSidebar = ({ selectedCategory, handleChangeCategory }) => {
  const { t } = useTranslation()
  const { data: servicesData, serviceCategories } = useSelector(
    state => state.userServices
  )
  const invoices = useSelector(state => state.clientInvoices.data)
  const subscriptions = useSelector(state => state.subscriptions)
  const [totalCategoriesToShow, setTotalCategoriesToShow] = useState(5)
  const purchasedServices = useMemo(() => {
    const arr = []

    invoices.forEach(invoice => {
      if (invoice.type === 'service') {
        arr.push(invoice.invoiceItems[0])
      }
    })

    return arr
  }, [servicesData, invoices])

  const mappedServiceCategories = useMemo(() => {
    return (
      serviceCategories.data
        .map(category => {
          const totalCategoryServices = servicesData.filter(
            service => service.category === category._id
          ).length
          return { ...category, servicesCount: totalCategoryServices }
        })
        // .filter(category => category.servicesCount > 0)
        .sort((a, b) => b.servicesCount - a.servicesCount)
    )
  }, [servicesData, serviceCategories.data])

  return (
    <div
      className="flex-shrink-0 bg-white dark:bg-dark-main dark:text-white py-4 border-r h-full dark:border-dark-main3"
      style={{ width: 220 }}
    >
      {/* =========== Available Services ========== */}
      <div className="mb-6">
        <div className="text-sm font-semibold mb-2 px-4">{t('CATEGORIES')}</div>
        <div>
          {serviceCategories.loading ? (
            <>
              <Skeleton variant="rect" height={25} className="mb-2" />
              <Skeleton variant="rect" height={25} className="mb-2" />
              <Skeleton variant="rect" height={25} className="mb-2" />
            </>
          ) : (
            <>
              <div
                role="navigation"
                aria-label="All category"
                className={clsx(
                  'flex items-center justify-between text-sm px-4 py-1.5 cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-main1 mb-1',
                  !selectedCategory && 'bg-gray-100 dark:bg-dark-main1'
                )}
                onClick={() => handleChangeCategory(null)}
              >
                <div>All</div>
                <div className="text-smaller bg-white w-6 h-6 flex justify-center items-center rounded-full border text-xs">
                  {servicesData.length}
                </div>
              </div>
              {mappedServiceCategories
                .slice(0, totalCategoriesToShow)
                .map(category => (
                  <div
                    role="navigation"
                    aria-label={category.name + 'category'}
                    key={category._id}
                    className={clsx(
                      'flex items-center justify-between text-sm px-4 py-1.5 cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-main1 mb-1',
                      selectedCategory === category._id &&
                        'bg-gray-100 dark:bg-dark-main1'
                    )}
                    onClick={() => handleChangeCategory(category._id)}
                  >
                    <div>{category.name}</div>
                    <div className="text-smaller bg-white w-6 h-6 flex justify-center items-center rounded-full border text-xs">
                      {category.servicesCount}
                    </div>
                  </div>
                ))}
            </>
          )}

          <div className="px-4">
            {mappedServiceCategories.length > totalCategoriesToShow && (
              <button
                onClick={() =>
                  setTotalCategoriesToShow(mappedServiceCategories.length)
                }
                className="text-xs underline text-blue-400"
              >
                {t('VIEW_ALL')}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* =========== My Services ========== */}
      {purchasedServices.length > 0 && (
        <div className="mb-6">
          <div className="font-semibold mb-2 text-sm px-4">
            {t('PURCHASED_SERVICES')}
          </div>
          <div
            role="navigation"
            aria-label="My services"
            className={clsx(
              'flex items-center justify-between text-sm px-4 py-1.5 cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-main1',
              selectedCategory === 'purchased' &&
                'bg-gray-100 dark:bg-dark-main1'
            )}
            onClick={() => handleChangeCategory('purchased')}
          >
            <div>{t('ALL')}</div>
            <div className="text-smaller bg-white w-6 h-6 flex justify-center items-center rounded-full border text-xs">
              {purchasedServices.length}
            </div>
          </div>
        </div>
      )}

      {/* =========== Subscriptions ========== */}
      {subscriptions.data.length > 0 && (
        <div className="mb-6">
          <div className="font-semibold mb-2 text-sm px-4">
            {t('SUBSCRIPTIONS')}
          </div>
          <div
            role="navigation"
            aria-label="My services"
            className={clsx(
              'flex items-center justify-between text-sm px-4 py-1.5 cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-main1',
              selectedCategory === 'subscriptions' &&
                'bg-gray-100 dark:bg-dark-main1'
            )}
            onClick={() => handleChangeCategory('subscriptions')}
          >
            <div>{t('ALL')}</div>
            <div className="text-smaller bg-white w-6 h-6 flex justify-center items-center rounded-full border text-xs">
              {subscriptions.data.length}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ServicesSidebar
