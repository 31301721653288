import React, { useState } from 'react'
import { AlertModal, Pagination, PopupMenu } from 'global/globalComponents'
import {
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import {
  deleteApprovalById,
  removeRaisedApproval
} from 'thunks/approvals/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useModal } from 'customHooks'
import ApprovalActionModal from './ApprovalActionModal/ApprovalActionModal'
import { getApprovalStatus, getCurrentStage } from './approvalStatic'

const limit = 10

const ApprovalsTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading, data: raisedApprovals } = useSelector(
    state => state.approvals.raised
  )
  const [page, setPage] = useState(0)
  const {
    modal: deleteAlert,
    openModal: openDeleteAlert,
    closeModal: closeDeleteAlert,
    toggleLoading: toggleDeleteAlertLoading
  } = useModal()
  const { modal, openModal, closeModal } = useModal()
  const visibleApprovals = raisedApprovals.slice(
    page * limit,
    page * limit + limit
  )

  const handleDeleteApproval = () => {
    toggleDeleteAlertLoading()
    deleteApprovalById({ approvalId: deleteAlert.data._id }, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        toggleDeleteAlertLoading()
      } else {
        dispatch(removeRaisedApproval(deleteAlert.data._id))
        closeDeleteAlert()
      }
    })
  }

  return (
    <>
      <TableContainer mb={6}>
        <Thead>
          <Th className="whitespace-nowrap">{t('NAME')}</Th>
          <Th className="text-center whitespace-nowrap">{t('STATUS')}</Th>
          <Th className="text-center whitespace-nowrap">
            {t('CURRENT_STAGE')}
          </Th>
          <Th className="text-center whitespace-nowrap">{t('DUE_DATE')}</Th>
          <Th className="text-center whitespace-nowrap"></Th>
        </Thead>
        <Tbody>
          {loading ? (
            <TableDataLoading cols={6} />
          ) : visibleApprovals.length === 0 ? (
            <Tr>
              <Td colSpan="6">{t('NO_DATA_FOUND')}</Td>
            </Tr>
          ) : (
            visibleApprovals.map(approval => (
              <Row
                key={approval._id}
                approvalData={approval}
                t={t}
                onDelete={() => openDeleteAlert(approval)}
                onPreview={() => openModal(approval)}
              />
            ))
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={raisedApprovals.length}
        page={page}
        limit={limit}
      />

      <AlertModal
        warningText={t('WARNING_DELETE', {
          data: deleteAlert.data.name
        })}
        open={deleteAlert.open}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handleDeleteApproval}
        loading={deleteAlert.loading}
      />

      <ApprovalActionModal
        open={modal.open}
        onClose={closeModal}
        data={modal.data}
        readOnly={true}
      />
    </>
  )
}

const Row = ({ approvalData, t, onDelete, onPreview }) => {
  const currentStage = getCurrentStage(approvalData)
  const approvalStatus = getApprovalStatus(approvalData)
  const dueDate = approvalData.dueDate || currentStage.dueDate

  return (
    <>
      <Tr>
        <Td onClick={onPreview} className="cursor-pointer">
          {approvalData.name}
        </Td>
        <Td center className="leading-3 capitalize">
          <Pill variant={pillVariantObj[currentStage.status]}>
            {approvalStatus}
          </Pill>
        </Td>
        <Td center>{currentStage.name}</Td>
        <Td center>
          {dueDate
            ? new Date(dueDate).toLocaleDateString(undefined, {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })
            : null}
        </Td>
        <Td center>
          <PopupMenu
            menuItems={[
              {
                label: t('DELETE'),
                method: onDelete,
                isDanger: true
              }
            ]}
          />
        </Td>
      </Tr>
    </>
  )
}

export default ApprovalsTable
