import React, { useRef } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import { Skeleton } from '@material-ui/lab'
import ReactToPrint from 'react-to-print'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import PreviewInvoiceTemplate1 from 'components/_shared/InvoiceTemplates/InvoiceTemplate1/PreviewInvoiceTemplate1'

const AgencyMarketplaceInvoicePreview = ({ invoices }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { invoiceId } = useParams()
  const invoiceRef = useRef()
  const invoice = invoices.find(item => item._id === invoiceId)

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <div
      className="px-2 sm:px-10 w-97 sm:w-90 mx-auto my-10"
      style={{ maxWidth: 700 }}
    >
      {!invoice ? (
        <>
          <Skeleton
            variant="rect"
            width={650}
            height={600}
            className="rounded"
          />
        </>
      ) : (
        <>
          <div className="flex flex-wrap items-center justify-between mb-2">
            <Button
              variant="outlined"
              startIcon={<ArrowLeftIcon />}
              onClick={handleGoBack}
            >
              {t('GO_BACK')}
            </Button>

            <div className="flex items-center space-x-2">
              <div className="text-center my-4">
                <ReactToPrint
                  trigger={() => {
                    return (
                      <Button
                        style={{
                          backgroundColor: '#222',
                          color: '#fff',
                          border: 'none'
                        }}
                      >
                        {t('DOWNLOAD_PDF')}
                      </Button>
                    )
                  }}
                  content={() => invoiceRef.current}
                  documentTitle={`${invoice.invoiceNumber}_invoice`}
                />
              </div>
            </div>
          </div>

          <div ref={invoiceRef}>
            <PreviewInvoiceTemplate1 invoiceData={invoice} />
          </div>
        </>
      )}
    </div>
  )
}

export default AgencyMarketplaceInvoicePreview
