import React from 'react'
import { ColoredAvatars } from 'global/globalComponents'
import moment from 'moment'
// import workspaceStyles from "components/Workspace/workspaceStyles"
import LoadingAnimation from './LoadingAnimation'
import { getBeforeAfterElm, getElmType, getKeywords } from './logsStatic'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const TaskLogs = ({
  logs,
  showLoadMoreBtn,
  handleLoadMoreLogs,
  fetchingMore
}) => {
  // const keywords = getKeywords()
  const { t } = useTranslation()
  return (
    <div className="px-8">
      {logs.loading ? (
        <LoadingAnimation />
      ) : Boolean(logs.data.length) ? (
        logs.data.map(item => <SingleLog key={item._id} {...item} />)
      ) : (
        <p className="text-sm text-gray-600 py-2 text-center">
          {t('NO_LOGS_AVAILABLE_YET')} :(
        </p>
      )}
      {showLoadMoreBtn && (
        <button
          className="flex items-center text-primary-main"
          onClick={handleLoadMoreLogs}
        >
          {fetchingMore ? <CircularProgress size={12} /> : <ChevronRight />}
          <span className="text-xs ml-2">{t('LOAD_MORE_UPDATES')}</span>
        </button>
      )}
    </div>
  )
}

const SingleLog = ({ user, category, field, createdAt, before, after }) => {
  const keywords = getKeywords()
  return (
    <div className="flex items-center space-x-2 mb-4">
      {/* 1. Notification Generator */}
      <div className="flex items-center">
        <ColoredAvatars size="medium" user={user || {}} />
        <span className="ml-2 text-xs font-medium text-gray-600 dark:text-dark-light">
          {user.name || 'Deleted user'}
        </span>
      </div>
      {/* 2. Action taken */}
      <span className="text-xs text-gray-600 dark:text-dark-light">
        {keywords[field]}
      </span>
      {/* 3. Previous State */}

      {!['attachment_add', 'attachment_delete', 'create'].includes(field) && (
        <BeforeAfter
          field={field}
          before={before}
          after={after}
          category={category}
        />
      )}
      <span className="ml-auto text-xs text-gray-600 dark:text-dark-light">
        {moment(createdAt).format('MMM Do, h:mm a')}
      </span>
    </div>
  )
}

const BeforeAfter = ({ category, field, before, after }) => {
  let elmType = getElmType(category, field),
    beforeElm,
    afterElm

  if (field === 'assignee_add' || field === 'assignee_delete') beforeElm = null
  else beforeElm = getBeforeAfterElm(elmType, before)

  afterElm = getBeforeAfterElm(elmType, after)
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-2">
      {beforeElm && (
        <div className="flex items-center text-gray-600 dark:text-dark-light">
          {elmType !== 'avatar' && (
            <span className="text-xs mr-2">{t('FROM')}</span>
          )}
          {beforeElm}
        </div>
      )}
      {afterElm && (
        <div className="flex items-center text-gray-600 dark:text-dark-light">
          {elmType !== 'avatar' && elmType !== 'collaboratorsGroup' && (
            <span className="text-xs mr-2">{t('TO')}</span>
          )}
          {afterElm}
        </div>
      )}
    </div>
  )
}

export default React.memo(TaskLogs)
