import React from 'react'
import { ReactComponent as NoteTextIcon } from 'static/svg/note-text.svg'
import { LinearProgress } from '@material-ui/core'

const AttachmentFiles = ({ files }) => {
  if (files.length === 0) return null
  return (
    <div className="flex gap-2 p-2 flex-wrap">
      {files.map((file, index) => (
        <div key={index} className="flex p-4 border rounded-md w-40">
          <NoteTextIcon
            className="w-6 h-6 text-primary-main"
            style={{ flexShrink: 0 }}
          />
          <div className="flex-1 ml-2">
            <div className="text-sm font-medium w-24 truncate text-gray-600 dark:text-gray-400 text-smaller">
              {file.name}
            </div>
            {file.loading && <LinearProgress className="rounded mt-1" />}
          </div>
        </div>
      ))}
    </div>
  )
}

export default AttachmentFiles
