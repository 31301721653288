import React from 'react'
import { Button } from '@material-ui/core'

const WelcomeMessage = ({ goNext }) => {
  return (
    <div className="pb-8">
      <div className="text-center flex justify-center flex-col items-center">
        <img
          src="https://cdni.iconscout.com/illustration/premium/thumb/welcome-garland-3688620-3231451.png?f=webp"
          alt=""
          className="w-96"
        />
        <h2 className="text-lg font-bold mb-2">Welcome to ClientVenue</h2>
        <p className="text-sm text-gray-600 w-2/3 mb-6">
          Delight your clients by completing more projects in less time with
          ClientVenue's powerful agency project management software.
        </p>

        <Button size="large" onClick={goNext}>
          Setup Your Account
        </Button>
      </div>
    </div>
  )
}

export default WelcomeMessage
