import React from 'react'
import { ColoredAvatarGroup } from 'global/globalComponents'
import { Close, InfoOutlined } from '@material-ui/icons'
import { CustomTooltip } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import { Chat } from 'static/Images'

const ChatWindowHeader = ({
  channelMembers,
  channelName,
  openEditChannelModal,
  onClose,
  usersTyping
}) => {
  const { t } = useTranslation()

  return (
    <div
      className="flex items-center px-4 border-b sticky top-0 bg-white"
      style={{ height: 62 }}
    >
      <div className="w-full flex items-center justify-between p-0 m-0">
        <div className="flex-1">
          <div className="flex items-center text-primary-main rounded-md">
            <img className="flex-shrink-0 w-8" src={Chat} alt="" />
            <div className="text-sm w-full ml-2 text-primary-main font-semibold">
              {channelName}
            </div>
          </div>
          <p className="absolute -mt-1 pl-10 text-tiny font-medium text-gray-500 h-5">
            {Boolean(usersTyping.length) &&
              `${usersTyping[0].name} ${t('IS_TYPING')}...`}
          </p>
        </div>
        <div className="flex items-center">
          <div className="mr-2">
            <ColoredAvatarGroup
              tooltip={true}
              size="normal"
              users={channelMembers
                .filter(item => typeof item.user === 'object')
                .map(item => item.user)}
              max={5}
            />
          </div>

          {openEditChannelModal && (
            <CustomTooltip title={t('MORE_INFO')}>
              <button
                onClick={openEditChannelModal}
                className="-mt-1 rounded-md hover:text-primary-main text-custom-gray-main"
              >
                <InfoOutlined
                  style={{
                    fontSize: '20px'
                  }}
                />{' '}
              </button>
            </CustomTooltip>
          )}

          {onClose && (
            <span
              className="cursor-pointer ml-2 dark:text-dark-light"
              onClick={onClose}
            >
              <Close style={{ fontSize: '20px' }} />
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChatWindowHeader
