/**
 *
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object | Number} expiry Date object or minutes
 */
export const setCookie = (name, value, expiry) => {
  let expires = ''

  // if type if a date object
  if (typeof expiry === 'object') {
    expires = `;expires=${new Date(expiry).toUTCString()}`
  } else if (typeof expiry === 'number') {
    const date = new Date()
    date.setTime(date.getTime() + 1000 * 60 * expiry) //expiry minutes
    expires = `;expires=${date.toUTCString()}`
  }

  document.cookie = `${name}=${value}${expires}; path=/;`
}

export const getCookie = name => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    let [cookieName, cookieValue] = cookies[i].split('=')

    if (cookieName.trim() === name) {
      return cookieValue
    }
  }
}

export const deleteCookie = name => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}
