import React, { useEffect, useRef, useState } from 'react'
import CustomDrawer, {
  DrawerContent,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import { useTranslation } from 'react-i18next'
import {
  AlertModal,
  ColoredAvatars,
  CustomTooltip
} from 'global/globalComponents'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import {
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { Button } from '@material-ui/core'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
// import { ReactComponent as PauseIcon } from 'static/svg/pause-circle.svg'
// import { ReactComponent as CancelIcon } from 'static/svg/minus-circle.svg'
import { fetchSubscriptionInvoice } from 'thunks/invoices/actions'
import { useModal } from 'customHooks'
import { useDispatch } from 'react-redux'
import { updateClientSubscription } from 'thunks/subscription/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import PreviewInvoiceTemplate1 from 'components/_shared/InvoiceTemplates/InvoiceTemplate1/PreviewInvoiceTemplate1'
import ReactToPrint from 'react-to-print'
import Currency from 'static/Currency/Currency'

const SubscriptionDetailsPanel = ({ open, onClose, subscriptionData }) => {
  const { t } = useTranslation()

  return (
    <CustomDrawer onClose={onClose} anchor="right" open={open}>
      <PanelContent
        open={open}
        onClose={onClose}
        t={t}
        subscription={subscriptionData}
      />
    </CustomDrawer>
  )
}

const PanelContent = ({ open, onClose, t, subscription }) => {
  const dispatch = useDispatch()
  const [subscriptionInvoices, setSubscriptionInvoices] = useState({
    loading: true,
    data: []
  })
  const {
    modal: alertModal,
    openModal: openAlertModal,
    closeModal: closeAlertModal,
    toggleLoading
  } = useModal()

  const [subscriptionData, setSubscriptionData] = useState({ ...subscription })
  const serviceData = subscriptionData.services[0] || {}

  let recurringPeriod = ''
  if (serviceData._id) {
    if (serviceData.recurring.interval === 'year') {
      recurringPeriod = t('YEARLY')
    } else if (serviceData.recurring.interval_count === 1) {
      recurringPeriod = t('MONTHLY')
    } else {
      recurringPeriod = `${
        serviceData.recurring.interval_count + ' ' + t('MONTHS')
      }`
    }
  }

  useEffect(() => {
    fetchSubscriptionInvoice({ id: subscriptionData._id }, (res, err) => {
      setSubscriptionInvoices({ loading: false, data: err ? [] : res })
    })
  }, [])

  const cancelOrPauseSubscription = ({ action }) => {
    // action could be: "active", "paused", "cancelled"
    toggleLoading()
    dispatch(
      updateClientSubscription(
        { id: subscriptionData._id, body: { status: action } },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            toggleLoading()
          } else {
            setSubscriptionData(res)
            dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
            closeAlertModal()
          }
        }
      )
    )
  }

  return (
    <>
      <DrawerHeader onClose={onClose} className="capitalize">
        {t('SUBSCRIPTION_DETAILS')}
      </DrawerHeader>
      <DrawerContent open={open}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: 10
          }}
          className="mt-2"
        >
          <FieldGroup label={t('CLIENT')}>
            <ColoredAvatars user={subscriptionData.client || {}} />
          </FieldGroup>

          <FieldGroup label={t('STATUS')}>
            <Pill
              variant={
                subscriptionData.status === 'active'
                  ? 'green'
                  : subscriptionData.status === 'paused'
                  ? 'orange'
                  : 'red'
              }
              className="capitalize"
            >
              {subscriptionData.status}
            </Pill>
          </FieldGroup>

          <FieldGroup label={t('BILLING')}>
            <span className="text-smaller lower">
              {Currency[serviceData.currency.toUpperCase()].symbol_native}
              {serviceData.amount / 100} / {recurringPeriod}
            </span>
          </FieldGroup>

          <FieldGroup label={t('SUBSCRIBED_ON')}>
            <time className="text-xs">
              {new Date(subscriptionData.createdAt).toLocaleDateString(
                undefined,
                {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                }
              )}
            </time>
          </FieldGroup>

          {subscriptionData.lastInvoiceDate && (
            <FieldGroup label={t('LAST_INVOICE_DATE')}>
              <time className="text-xs">
                {new Date(subscriptionData.lastInvoiceDate).toLocaleDateString(
                  undefined,
                  {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                  }
                )}
              </time>
            </FieldGroup>
          )}

          <FieldGroup label={t('NEXT_INVOICE_DATE')}>
            <time className="text-xs">
              {new Date(subscriptionData.nextInvoiceDate).toLocaleDateString(
                undefined,
                {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                }
              )}
            </time>
          </FieldGroup>
        </div>
        <hr className="my-4" />
        <TableContainer mb={6}>
          <Thead>
            <Th>{t('INVOICE_NO')}</Th>
            <Th>{t('CREATED_AT')}</Th>
            <Th center>{t('STATUS')}</Th>
            <Th center></Th>
          </Thead>
          <Tbody>
            {subscriptionInvoices.loading ? (
              <TableDataLoading cols={3} />
            ) : subscriptionInvoices.data.length > 0 ? (
              subscriptionInvoices.data.map(invoice => (
                <InvoiceTableRow
                  t={t}
                  key={invoice._id}
                  invoiceData={invoice}
                />
              ))
            ) : (
              <Tr>
                <Td colSpan={3}>{t('NO_DATA_FOUND')}</Td>
              </Tr>
            )}
          </Tbody>
        </TableContainer>

        {subscriptionData.status === 'canceled' ? (
          <p className="text-smaller text-red-400">
            {t('SUBSCRIPTION_CANCELED_MESSAGE')}
          </p>
        ) : (
          <div className="mb-8">
            <div className="text-sm font-semibold mb-1">
              {t('MANAGE_SUBSCRIPTION')}
            </div>
            <p className="mb-4 text-smaller text-gray-600">
              {t('MANAGE_SUBSCRIPTION_DESCRIPTION')}
            </p>
            <div className="flex space-x-2">
              <Button onClick={() => openAlertModal({ action: 'canceled' })}>
                {t('CANCEL_SUBSCRIPTION')}
              </Button>

              <Button
                variant="outlined"
                onClick={() =>
                  openAlertModal({
                    action:
                      subscriptionData.status === 'paused' ? 'active' : 'paused'
                  })
                }
              >
                {subscriptionData.status === 'paused'
                  ? t('ACTIVATE')
                  : t('PAUSE')}{' '}
                {t('SUBSCRIPTION')}
              </Button>
            </div>
          </div>
        )}
      </DrawerContent>

      <AlertModal
        open={alertModal.open}
        handleDeleteAction={() =>
          cancelOrPauseSubscription({
            action: alertModal.data.action
          })
        }
        handleDialog={closeAlertModal}
        warningText={
          alertModal.data.action === 'canceled'
            ? t('CANCEL_SUBSCRIPTION_WARNING')
            : alertModal.data.action === 'paused'
            ? t('PAUSE_SUBSCRIPTION_WARNING')
            : t('ACTIVATE_SUBSCRIPTION_WARNING')
        }
        deleteBtnText={t('YES')}
        loading={alertModal.loading}
      />
    </>
  )
}

const FieldGroup = ({ label, children }) => {
  return (
    <div className="flex items-center space-x-2">
      <label className="text-smaller font-medium text-gray-600">
        {label}:{' '}
      </label>
      <div className="leading-3">{children}</div>
    </div>
  )
}

const InvoiceTableRow = ({ t, invoiceData }) => {
  const invoiceRef = useRef()

  return (
    <Tr>
      <Td>{invoiceData.invoiceNumber}</Td>
      <Td>
        {new Date(invoiceData.createdAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        })}
      </Td>
      <Td center>
        <Pill variant={pillVariantObj[invoiceData.status]}>
          {t(invoiceData.status.toUpperCase())}
        </Pill>
      </Td>
      <Td center className="space-x-2">
        <ReactToPrint
          trigger={() => {
            return (
              <CustomTooltip title={t('DOWNLOAD')} placement="top">
                <button>
                  <DownloadIcon className="w-4 h-4 text-primary-main" />
                </button>
              </CustomTooltip>
            )
          }}
          content={() => invoiceRef.current}
          documentTitle={`${invoiceData.invoiceNumber}_invoice`}
        />

        <div
          style={{
            position: 'fixed',
            left: '-1000px',
            visibility: 'hidden',
            height: '1000px'
          }}
        >
          <div ref={invoiceRef}>
            <PreviewInvoiceTemplate1 invoiceData={invoiceData} />
          </div>
        </div>
      </Td>
    </Tr>
  )
}

export default SubscriptionDetailsPanel
