import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
// import stringToHslColor from 'utils/stringToHslColor'
// import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getMentionConfig } from '../editorsStatic'
import userRoles, { getAgencyOwner } from 'utils/userRoles'
import { assignUserToWorkspace } from 'thunks/workspace/actions'
import { updateTaskDetailsTeam } from 'thunks/task/actions'
import { updateSubTaskTeammate } from 'thunks/subTask/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { getFileType } from 'utils'
import { FilePreview } from 'global/globalComponents'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['video']
]

const DescriptionEditor = React.forwardRef(
  (
    {
      workspace,
      collaborators = [],
      imageDrop,
      triggerUpdate,
      onDrop,
      dropHandler,
      readOnly,
      filePasteHandler,
      loading,
      taskId,
      elmFor,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const dispatch = useDispatch()
    const meData = useSelector(state => state.me.data)
    const [draggedOver, setDraggedOver] = useState(false)
    const [uploadingFile, setUploadingFile] = useState(false)
    const agencyTeammates = useSelector(state => state.userTeammates.data)
    const [filePreviewModal, setFilePreviewModal] = useState({
      open: false,
      files: [],
      fileIndex: 0
    })

    const users = useMemo(() => {
      if (!workspace._id) return
      const agencyOwner = getAgencyOwner(meData)

      const clientUsers = workspace.assignedClientTeam.map(item => ({
        //for quill mention
        id: item.user._id,
        value: item.user.name,
        //for backend and frontend
        _id: item.user._id,
        name: item.user.name,
        profileImage: item.user.profileImage,
        role: item.role
      }))

      const agencyUsers = agencyTeammates.map(item => ({
        //for quill mention
        id: item._id,
        value: item.name,
        //for backend and frontend
        _id: item._id,
        email: item.email,
        profileImage: item.profileImage,
        role: item.role
      }))

      const otherUsers = [
        {
          id: meData._id,
          value: meData.name,
          _id: meData._id,
          email: meData.email,
          profileImage: meData.profileImage,
          role: meData.role
        }
      ]

      if (
        meData._id !== agencyOwner._id &&
        agencyOwner._id &&
        agencyOwner.name
      ) {
        otherUsers.push({
          id: agencyOwner._id,
          value: agencyOwner.name,
          _id: agencyOwner._id,
          email: agencyOwner.email,
          profileImage: agencyOwner.profileImage,
          role: 20
        })
      }

      return [...clientUsers, ...agencyUsers, ...otherUsers]
    }, [workspace, agencyTeammates, meData])

    useEffect(() => {
      if (!ref.current || !filePasteHandler) return
      const editor = ref.current.editor
      editor.root.addEventListener('paste', handleFilePaste)

      return () => {
        if (!filePasteHandler) return
        editor.root.removeEventListener('paste', handleFilePaste)
      }
    }, [filePasteHandler])

    const handleFilePaste = e => {
      const item = e.clipboardData.items[0]

      //currently only supporting image paste
      if (item.type.indexOf('image') === 0) {
        e.preventDefault()
        const editor = ref.current.editor
        const file = item.getAsFile()

        // quill looses focus on paste that's why focusing it manually so that
        // getSelection doesn't return null
        editor.focus()

        let index = (editor.getSelection() || {}).index
        if (index === undefined || index < 0) {
          index = editor.getLength()
        }

        filePasteHandler(file, index)
      } else {
        if (triggerUpdate) triggerUpdate()
      }
    }

    const handleClick = e => {
      if (e.target.tagName === 'A' && e.target.href !== '') {
        const a = document.createElement('a')
        a.setAttribute('href', e.target.href)
        a.setAttribute('target', '_blank')
        a.click()
      }
      if (e.target.tagName === 'IMG') {
        const file = {}

        const arr = e.target.src.split('/')
        file.name = arr[arr.length - 1]
        file.type = getFileType(e.target.src)
        file.url = e.target.src
        file.extension = e.target.src.slice(e.target.src.lastIndexOf('.'))

        setFilePreviewModal(prev => ({ open: true, files: [file] }))
      }
    }

    const handleDrop = e => {
      e.preventDefault()
      const file = e.dataTransfer.files[0]
      if (!file) return

      setDraggedOver(false)
      setUploadingFile(true)
      dropHandler(file, () => {
        setUploadingFile(false)
      })
    }

    const handleDragOver = () => {
      setDraggedOver(true)
    }

    const handleDragLeave = () => {
      setDraggedOver(false)
    }

    const onSelectUser = mentionedUser => {
      dispatch(
        assignUserToWorkspace({
          workspace: workspace._id,
          assignToAgencyTeammate:
            mentionedUser.role < userRoles.USER_CLIENT
              ? [mentionedUser._id]
              : [],
          assignToClientTeammate:
            mentionedUser.role < userRoles.USER_CLIENT
              ? []
              : [mentionedUser._id]
        })
      )

      if (!taskId) return

      // finding if the mentioned user already added to the task's collaborators list
      const idx = collaborators.findIndex(
        user => user._id === mentionedUser._id
      )

      if (idx !== -1) return

      if (elmFor === 'task') {
        dispatch(
          updateTaskDetailsTeam(
            {
              taskId,
              data: {
                collaborators: [mentionedUser._id]
              }
            },
            (res, err) => {
              if (err) dispatch(fireErrorToaster(res))
            }
          )
        )
      } else {
        dispatch(
          updateSubTaskTeammate(
            {
              id: taskId,
              data: {
                collaborators: [mentionedUser._id]
              }
            },
            (res, err) => {
              if (err) dispatch(fireErrorToaster(res))
            }
          )
        )
      }
    }

    return (
      <div
        className={clsx(
          'text-sm w-full h-full relative dark:text-dark-light',
          draggedOver && 'bg-primary-light',
          classes.container
        )}
        onClick={handleClick}
        onDrop={onDrop ? handleDrop : undefined}
        onDragOver={onDrop ? handleDragOver : undefined}
        onDragLeave={onDrop ? handleDragLeave : undefined}
        data-text-editor="editor"
      >
        {(draggedOver || uploadingFile || loading) && (
          <div
            className={clsx(
              'font-medium dark:text-dark-light',
              classes.processIndicator
            )}
          >
            <div className="flex flex-col items-center text-primary-main">
              {draggedOver ? (
                <>
                  <div className="border border-dashed border-primary-main p-4 rounded-md mb-2">
                    <PlusIcon className="w-5 h-5 dark:text-dark-light" />
                  </div>
                  <span className="dark:text-dark-light">{t('DROP_HERE')}</span>
                </>
              ) : (
                <>
                  <CircularProgress size={30} />
                  <p className="text-sm mt-2 dark:text-dark-light">
                    {t('UPLOAD_FILE')}...
                  </p>
                </>
              )}
            </div>
          </div>
        )}
        <DescriptionEditorContent
          ref={ref}
          users={users}
          meData={meData}
          onSelectUser={onSelectUser}
          uploadingFile={uploadingFile}
          readOnly={readOnly}
          {...rest}
        />

        <FilePreview
          open={filePreviewModal.open}
          files={filePreviewModal.files}
          fileIndex={filePreviewModal.fileIndex}
          onClose={() =>
            setFilePreviewModal(prev => ({ ...prev, open: false }))
          }
        />
      </div>
    )
  }
)

const DescriptionEditorContent = React.forwardRef(
  ({ users, meData, onSelectUser, uploadingFile, readOnly, ...rest }, ref) => {
    const modules = useMemo(() => {
      const mention = getMentionConfig(users)

      const obj = {
        toolbar: toolbarOptions,
        mention: {
          ...mention,
          onSelect: (item, insertItem) => {
            const user = users.find(user => user._id === item.id)
            const createdBy = {
              _id: meData._id,
              name: meData.name,
              profileImage: meData.profileImage
            }

            insertItem({
              ...item,
              ...user,
              notify: meData._id !== user._id,
              createdAt: new Date().toISOString(),
              createdBy: JSON.stringify(createdBy)
            })
            onSelectUser(user)
          }
        },
        magicUrl: true
      }
      return obj
    }, [])

    return (
      <ReactQuill
        theme="snow"
        modules={modules}
        readOnly={uploadingFile || readOnly}
        ref={ref}
        bounds={`[data-text-editor="editor"]`}
        {...rest}
      />
    )
  }
)

const useStyles = makeStyles({
  container: {
    '& img': {
      cursor: 'pointer'
    }
  },
  processIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    pointerEvents: 'none'
  }
})

export default React.memo(DescriptionEditor)
