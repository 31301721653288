import axios from 'axios'
import { getErrorMessages } from 'utils/errorMessages'
import { TIMER } from './reducer'
const errorMessages = getErrorMessages()

export const startTaskTimer = (data, title, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/tracking/start`,
        data
      })

      dispatch({ type: TIMER.START_TIMER, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const updateTaskTimer = (timerId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/tracking/${timerId}`,
        data
      })

      dispatch({ type: TIMER.TOGGLE_IS_BILLABLE, payload: res.data.isBillable })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const stopTaskTimer = (data, title, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/tracking/end`,
        data
      })

      if (data.type === 'task') {
        dispatch(totalTimeOnTask(data.task))
      } else {
        dispatch(totalTimeOnSubtask(data.subTask))
      }

      dispatch({ type: TIMER.STOP_TIMER, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback) callback(err, true)
    }
  }
}

// export const getTimeSpendByUser = (taskId, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/tracking/task/${taskId}/timespend`
//       })
//       if (callback) callback(res.data, false)
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//     }
//   }
// }

export const totalTimeOnTask = (taskId, callback) => {
  return async dispatch => {
    try {
      dispatch({ type: TIMER.LOADING_TASK_TIMER_DATA })

      const res = await axios({
        method: 'GET',
        url: `/tracking/task/${taskId}`
      })

      const arr = Object.values(res.data) // getting data => {taskId: {...}}, getting only tha value not id
      dispatch({
        type: TIMER.FETCHED_TASK_TIMER,
        payload: arr
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const totalTimeOnSubtask = (subtaskId, callback) => {
  return async dispatch => {
    try {
      dispatch({ type: TIMER.LOADING_SUBTASK_TIMER_DATA })
      const res = await axios({
        method: 'GET',
        url: `/tracking/subtask/${subtaskId}`
      })

      const arr = Object.values(res.data)
      dispatch({
        type: TIMER.FETCHED_SUBTASK_TIMER,
        payload: arr
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchOpenTimeTracking = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/tracking/user`
      })
      // if timer has task with null value, save empty array(default value) (backend improvement is required)
      let response = res.data[0] && res.data[0].task ? res.data : []
      dispatch({ type: TIMER.FETCHED_ACTIVE_TIMER, payload: response })
      if (callback) callback(response, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const addManualTime = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/tracking`,
        data
      })

      if (data.type === 'task') {
        dispatch(totalTimeOnTask(data.task))
      } else {
        dispatch(totalTimeOnSubtask(data.subTask))
      }

      dispatch({ type: TIMER.UPDATE_ACTIVE_TIMER, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const deleteTimeTracked = (id, key, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/tracking/${id}`,
        method: 'DELETE'
      })
      if (callback) callback(res.data, false)

      if (key === 'workspace') {
        res.data.type === 'task'
          ? dispatch({ type: TIMER.UPDATE_TASK_TIMER, payload: res.data })
          : dispatch({ type: TIMER.UPDATE_SUBTASK_TIMER, payload: res.data })
      }
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch user workload
 * @param {String} startDate
 * @param {String} endDate
 * @param {Array} userIds
 * @param {Function} callback
 */
export const fetchUsersWorkload = async (
  startDate,
  endDate,
  data,
  callback
) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/task/resource/agency?startDate=${startDate}&endDate=${endDate}`,
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const fetchUserWiseWorkload = async (startDate, endDate, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `task/resource/user?startDate=${startDate}&&endDate=${endDate}`
    })
    if (callback) callback([res.data], false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const toggleTimerPosition = () => {
  return { type: TIMER.TOGGLE_TIMER_POSITION }
}

export const removeTimer = (data, title, callback) => {
  return async dispatch => {
    dispatch(
      stopTaskTimer(data, title, (res, err) => {
        if (!err) {
          dispatch({ type: TIMER.REMOVE })
        }
        callback(res, err)
      })
    )
  }
}
