import { useState } from 'react'
// import InfoIcon from '@material-ui/icons/Info'
import {
  Tooltip,
  makeStyles,
  ClickAwayListener,
  SvgIcon,
  useTheme
} from '@material-ui/core'
import { ReactComponent as QuestionCircleIcon } from 'static/svg/question-circle.svg'
// import theme from 'themes/themes'

const QuestionMarkTooltip = props => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const {
    text,
    direction,
    toolTipstyles = '',
    icon,
    color = theme.palette.type === 'light'
      ? theme.palette.primary.main
      : theme.custom.darkMode.color
  } = props
  const classes = useStyles()
  const handleTooltipOpen = () => {
    setOpen(true)
  }
  const handleTooltipClose = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={toolTipstyles}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          classes={{ tooltip: classes.customStyling }}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={text}
          placement={direction}
          arrow
        >
          {icon || (
            <SvgIcon
              component={QuestionCircleIcon}
              style={{ color, fontSize: '1rem' }}
              onClick={handleTooltipOpen}
              className="cursor-pointer"
              viewBox="0 0 600 476.6"
            />
          )}
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles(theme => ({
  customStyling: {
    maxWidth: 200,
    textTransform: 'capitalize'
  },
  iconbutton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

export default QuestionMarkTooltip
