import axios from 'axios'
import { catchAsync, userRoles } from 'utils'
import loadSampleData from 'thunks/Sample Data/sampleDataAPI'

/**
 * User signup api call
 * @param {Object} data Use sigup data
 * @param {Function} callback Callback function. Receives (res/err, errStatus) as params
 */
export const userSignup = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/user/signup',
    data: data.data,
    isPublic: true
  })

  if (callback) callback(res.data)
  if (data.data.role === userRoles.USER_AGENCY) {
    await loadSampleData({ token: `Bearer ${res.data.login.token}` })
  }
})
