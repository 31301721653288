import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Backdrop,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'
import { CustomPopper } from 'global/globalComponents'
import { userRoles } from 'utils'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle-filled.svg'
import { useDispatch, useSelector } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import {
  removeTeammateFromWorkspace,
  updateTeammateRole
} from 'thunks/workspace/actions'

const PermissionOptionsPopper = ({
  teammate,
  anchorEl,
  onClose,
  permissionOptions
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )

  const handleSelectPermission = selectedRole => {
    let recentProjectOwner = null

    if (selectedRole === teammate.role) return // if same role, do nothing

    if (selectedRole === userRoles.PROJECT_OWNER) {
      recentProjectOwner = currentWorkspace.assignedAgencyTeam.find(
        mate => mate.role === userRoles.PROJECT_OWNER
      )
    }

    // Updating user's role to the selected role
    dispatch(
      updateTeammateRole(
        currentWorkspace._id,
        {
          [teammate.role < userRoles.USER_CLIENT
            ? 'assignToAgencyTeammate'
            : 'assignToClientTeammate']: {
            user: teammate.user._id,
            role: selectedRole
          }
        },
        teammate.user.name,
        (res, err) => {
          if (err) dispatch(fireErrorToaster(res))
        }
      )
    )

    // If user's selected role was project owner in that case we need
    // to remove the previous project owner, bcoz each project can have only 1 project owner

    if (recentProjectOwner) {
      dispatch(
        updateTeammateRole(
          currentWorkspace._id,
          {
            assignToAgencyTeammate: {
              user: recentProjectOwner.user._id,
              role: userRoles.AGENCY_EDITOR
            }
          },
          recentProjectOwner.user.name
        )
      )
    }
  }

  const handleRemoveTeammate = () => {
    const body = {}

    if (teammate.role < userRoles.USER_CLIENT) {
      body.assignToAgencyTeammate = {
        user: teammate.user._id,
        role: teammate.role
      }
    } else {
      body.assignToClientTeammate = {
        user: teammate.user._id,
        role: teammate.role
      }
    }

    dispatch(
      removeTeammateFromWorkspace({
        workspaceId: currentWorkspace._id,
        body
      })
    )
  }

  return (
    <Backdrop
      open={Boolean(anchorEl)}
      onClick={onClose}
      style={{ zIndex: 2000, background: 'transparent' }}
    >
      <CustomPopper anchorEl={anchorEl} onClose={onClose}>
        <div
          className={clsx(
            'bg-white dark:bg-dark-main2 dark:text-dark-light',
            classes.menuPaper
          )}
        >
          <List className={classes.list}>
            {permissionOptions.map((item, index) => (
              <SingleListItem
                key={index}
                permissionData={item}
                handleSelectPermission={handleSelectPermission}
                handleCloseMenu={onClose}
                classes={classes}
                isActive={item.access.includes(teammate.role)}
              />
            ))}

            <ListItem onClick={handleRemoveTeammate} button>
              <ListItemText
                primary={
                  <span className="inline-block text-xs py-1 text-red-500">
                    {t('REMOVE_FROM_PROJECT')}
                  </span>
                }
                classes={{ root: classes.listItemText }}
              />
            </ListItem>
          </List>
        </div>
      </CustomPopper>
    </Backdrop>
  )
}

const SingleListItem = ({
  permissionData,
  handleSelectPermission,
  classes,
  isActive
}) => {
  return (
    <ListItem
      onClick={() => handleSelectPermission(permissionData.role)}
      button
    >
      <div className="flex items-center">
        <ListItemText
          primary={
            <span className="flex items-center dark:text-dark-light mb-1">
              <span className="font-semibold text-xs capitalize text-gray-800">
                {permissionData.title}
              </span>
              {isActive && (
                <CheckCircle className="rounded-full border border-green-500 w-5 h-5 ml-2 text-green-500" />
              )}
            </span>
          }
          secondary={
            <span className="text-xs text-gray-600 dark:text-dark-light">
              {permissionData.description}
            </span>
          }
          classes={{ root: classes.listItemText }}
        />
      </div>
    </ListItem>
  )
}

const useStyles = makeStyles({
  menuPaper: {
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    maxWidth: 280
  },

  list: {
    padding: '4px 0'
  },

  listItem: {
    padding: '0.5rem 1.5rem',
    minWidth: 115
  },

  listItemText: {
    margin: 0
  }
})

export default PermissionOptionsPopper
