import React, { useEffect, useRef } from 'react'
import Quill from 'quill'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const EDITOR_CONTAINER = 'editorContainer'
const defaultOptions = {
  floatingToolbar: false
}

const RichTextEditor = ({
  html = '',
  onChange,
  placeholder,
  disabled,
  style,
  options = defaultOptions
}) => {
  const editor = useRef()
  const classes = useStyles()
  const quill = useRef()
  const toolbarRef = useRef()
  const config = {
    theme: 'snow',
    modules: {
      magicUrl: true,
      toolbar: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'link',
          { list: 'ordered' },
          { list: 'bullet' }
        ] // toggled buttons
      ]
    },
    placeholder: placeholder || 'Type here...',
    bounds: `#${EDITOR_CONTAINER}`
  }

  useEffect(() => {
    attachQuill()
  }, [])

  const attachQuill = () => {
    quill.current = new Quill(editor?.current, config)

    if (options.floatingToolbar) {
      toolbarRef.current = quill.current.getModule('toolbar')
      toolbarRef.current.container.style.visibility = 'hidden'
      toolbarRef.current.container.style.position = 'absolute'
      toolbarRef.current.container.style.zIndex = 9
      toolbarRef.current.container.style.backgroundColor = '#ffffff'
      toolbarRef.current.container.style.color = '#222222'
      toolbarRef.current.container.style.border = '1px solid #ddd'
    }

    if (disabled) {
      quill.current.enable(false)
    }

    if (onChange) {
      quill.current.on('text-change', function (delta, oldDelta, source) {
        // const editorText = quill.current.getText()
        // const variablePattern = /\{\{(\w+)\}\}/
        // const matched = editorText.match(variablePattern)
        // const quillText = getQuillHTML(quill.current.getContents())

        // if (matched) {
        //   const index = editorText.indexOf(matched[0])
        //   console.log({
        //     editorText,
        //     quillText,
        //     contents: quill.current.getContents()
        //   })
        //   quill.current.deleteText(index, matched[0].length, 'user')
        //   setTimeout(() => {
        //     quill.current.insertEmbed(index, 'quillVariable', matched[1])
        //   }, 1000)
        // }

        onChange(quill.current.root.innerHTML)
      })
    }

    if (options.floatingToolbar) {
      quill.current.on('selection-change', onSelectionChange)
    }

    setDefaultContent()
  }

  const onSelectionChange = range => {
    const toolbarElement = toolbarRef.current.container

    if (range) {
      if (range.length === 0) {
        hideInactiveToolbar()
      } else {
        const { top, left } = quill.current.getBounds(range)
        const shift = 49
        toolbarElement.style.visibility = 'visible'
        toolbarElement.style.top = `${top - shift}px` // TODO need additional calculation for edge cases
        toolbarElement.style.left = `${left}px`
      }
    } else {
      hideInactiveToolbar()
    }
  }

  const hideInactiveToolbar = () => {
    const element = toolbarRef.current?.container
    const isToolbarFocused =
      element &&
      (element.contains(document.activeElement) ||
        element === document.activeElement)
    if (!isToolbarFocused) {
      element.style.visibility = 'hidden'
    }
  }

  const setDefaultContent = () => {
    const quote = quill.current.clipboard.convert(html || '<p> </p>')
    quill.current.setContents(quote, Quill.sources.SILENT)
  }

  return (
    <div
      className={clsx(
        'border border-gray-300 rounded dark:text-dark-light',
        classes.root
      )}
      id={EDITOR_CONTAINER}
    >
      <div
        ref={editor}
        style={{
          border: 'none',
          height: 100,
          ...style
        }}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.custom.darkMode.dark2 : 'inherit',
    color: theme.palette.type === 'dark' ? '#fff' : 'inherit',
    '& .ql-toolbar.ql-snow': {
      border: 'none',
      borderBottom: '1px solid #ccc'
    }
  }
}))

export default RichTextEditor
