import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import StandupsList from './StandupsList'

const MorningStandups = ({
  toggleDeleteStandupAlert,
  toggleStandupCompletion,
  openEditTaskPanel,
  standupsData
}) => {
  const meData = useSelector(state => state.me.data)
  const filteredStandupsData = useMemo(() => {
    return standupsData.filter(item => item.type === 'morning')
  }, [standupsData])
  const { t } = useTranslation()

  return (
    <div>
      {!Boolean(filteredStandupsData.length) && (
        <div className="mb-2">
          <h3 className="font-semibold text-lg 2xl:text-xl mb-1 dark:text-dark-light2">
            {t('GOOD_MORNING')} {meData.name}!
          </h3>
          <p className="text-smaller 2xl:text-sm leading-6 dark:text-dark-light">
            {t('MORNING_STANDUP_MSG')}
          </p>
        </div>
      )}

      <StandupsList
        data={filteredStandupsData}
        canUpdate={true}
        canDelete={true}
        toggleStandupCompletion={toggleStandupCompletion}
        toggleDeleteStandupAlert={toggleDeleteStandupAlert}
        openEditTaskPanel={openEditTaskPanel}
      />
    </div>
  )
}

export default React.memo(MorningStandups)
