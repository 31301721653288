import axios from 'axios'
import { catchAsync, catchAsyncDispatch } from 'utils'
import { SERVICE_MARKETPLACE } from './reducer'

/**
 * Fetch marketplace services
 * @param {Function} callback Callback function
 */
export const fetchMarketplaceServices = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/service/user',
      apiVersion: 2
    })

    dispatch({ type: SERVICE_MARKETPLACE.FETCHED_SERVICES, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch marketplace services
 * @param {Function} callback Callback function
 */
export const fetchMarketplaceServicesPublic = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/service/domain',
      isPublic: true,
      apiVersion: 2
    })

    dispatch({ type: SERVICE_MARKETPLACE.FETCHED_SERVICES, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch marketplace service categories
 * @param {Function} callback Callback function
 */
export const fetchServiceCategoriesPublic = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/category/public?type=marketplaceService',
      isPublic: true
    })

    dispatch({
      type: SERVICE_MARKETPLACE.FETCHED_CATEGORIES,
      payload: res.data
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch marketplace service categories
 * @param {Function} callback Callback function
 */
export const fetchServiceCategories = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/category/category?type=marketplaceService'
    })

    dispatch({
      type: SERVICE_MARKETPLACE.FETCHED_CATEGORIES,
      payload: res.data
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch marketplace service invoice
 * @param {Function} callback Callback function
 */
export const createMarketplaceServiceInvoice = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/invoice',
      apiVersion: 2,
      data: data.body
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch marketplace service invoices
 * @param {Function} callback Callback function
 */
export const fetchMarketplaceInvoices = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/invoice/user',
      apiVersion: 2
    })

    dispatch({
      type: SERVICE_MARKETPLACE.FETCHED_INVOICES,
      payload: res.data
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch marketplace stripe invoice
 * @param {Function} callback Callback function
 */
export const fetchMarketplaceStripeInvoice = catchAsync(
  async (data, callback) => {
    const res = await axios({
      method: 'GET',
      url: `/invoice/stripe/${data.paymentId}`,
      apiVersion: 2
    })

    if (callback) callback(res.data)
  }
)

/**
 * Checkout marketplace service
 * @param {String} _id invoice id
 * @param {Object} paymentMethod stripe paymentMethod
 * @param {Function} callback Callback function
 */
export const marketplaceServiceCheckout = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/invoice/pay',
      apiVersion: 2,
      data: data.body
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch stripe cards
 * @param {Function|undefined} callback Callback function
 */
export const fetchStripeCards = catchAsync(async callback => {
  const res = await axios({
    method: 'GET',
    url: `/user/card`
  })

  if (callback) callback(res.data)
})
// ========= Only for development =============
// const createServiceCategories = async data => {
//   const res = await axios({
//     method: 'POST',
//     url: '/category',
//     data
//   })

//   console.log({ res })
// }

// const serviceCategoryData = [
//   {
//     name: 'Marketing',
//     description:
//       'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis assumenda alias voluptates. Sunt sint, reprehenderit iure dignissimos cupiditate culpa sed. Sunt, a. Maxime laboriosam repellendus, sit perspiciatis impedit voluptatem deleniti?',
//     image:
//       'https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg',
//     type: 'marketplaceService'
//   },
//   {
//     name: 'Designing',
//     description:
//       'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis assumenda alias voluptates. Sunt sint, reprehenderit iure dignissimos cupiditate culpa sed. Sunt, a. Maxime laboriosam repellendus, sit perspiciatis impedit voluptatem deleniti?',
//     image:
//       'https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg',
//     type: 'marketplaceService'
//   },
//   {
//     name: 'SEO',
//     description:
//       'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis assumenda alias voluptates. Sunt sint, reprehenderit iure dignissimos cupiditate culpa sed. Sunt, a. Maxime laboriosam repellendus, sit perspiciatis impedit voluptatem deleniti?',
//     image:
//       'https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg',
//     type: 'marketplaceService'
//   }
// ]

// serviceCategoryData.forEach(createServiceCategories)

// const createMarketplaceServices = async data => {
//   const res = await axios({
//     method: 'POST',
//     url: '/service',
//     apiVersion: 2,
//     data
//   })
//   console.log({ res })
// }

// const servicesData = [
//   {
//     name: 'Website Design',
//     category: '660d3dbb284f4b6897c5930a',
//     description:
//       'Create stunning and responsive websites tailored to your needs.',
//     amount: 499.99,
//     // image: {
//     //   cover:
//     //     'https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg',
//     //   thumbnail:
//     //     'https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg'
//     // },
//     currency: 'USD',
//     // isPinned: true,
//     offerings: [
//       {
//         title: 'Flat 50% on first purchase',
//         description: 'new offer description',
//         list: []
//       },
//       {
//         title: '10% discount on SBI Cards',
//         description: 'new offer description',
//         list: []
//       },
//       {
//         title: 'Get Amazon shopping voucher',
//         description: 'new offer description',
//         list: []
//       },
//       {
//         title: '100% cashback for the 10 luckies customers',
//         description: 'new offer description',
//         list: []
//       }
//     ]
//   },
//   {
//     name: 'Graphic Design',
//     category: '660d3dbb284f4b6897c5930a',
//     description:
//       'Bring your brand to life with captivating graphic design solutions.',
//     amount: 299.99,
//     // image: {
//     //   cover:
//     //     'https://www.pixelstalk.net/wp-content/uploads/2016/08/Free-Random-Wallpaper-Download.jpg',
//     //   thumbnail:
//     //     'https://www.pixelstalk.net/wp-content/uploads/2016/08/Free-Random-Wallpaper-Download.jpg'
//     // },
//     currency: 'USD',
//     // isPinned: false,
//     offerings: [
//       {
//         title: 'Flat 50% on first purchase',
//         description: 'new offer description',
//         list: []
//       },
//       {
//         title: '10% discount on SBI Cards',
//         description: 'new offer description',
//         list: []
//       },
//       {
//         title: 'Get Amazon shopping voucher',
//         description: 'new offer description',
//         list: []
//       },
//       {
//         title: '100% cashback for the 10 luckies customers',
//         description: 'new offer description',
//         list: []
//       }
//     ]
//   }
// ]

// servicesData.forEach(createMarketplaceServices)
