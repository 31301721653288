const initialState = {
  loading: false,
  error: false,
  data: {}
}

export const USER = {
  LOADING: 'USER_LOADING',
  ERROR: 'USER_ERROR',
  FETCHED: 'USER_FETCHED',
  UPDATED: 'USER_UPDATED',
  USERNAMEUPDATED: 'USER_USERNAMEUPDATED',
  PROFILE_UPDATED: 'USER_PROFILE_UPDATED',
  UPDATE_PROFILE_DATA: 'UPDATE_PROFILE_DATA',
  UPDATE_PROFILE_LANGUAGE: 'UPDATE_PROFILE_LANGUAGE'
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER.LOADING:
      return { ...state, loading: true, error: false }

    case USER.ERROR:
      return { ...state, loading: false, error: action.payload }

    case USER.FETCHED:
      return { ...state, loading: false, error: false, data: action.payload }

    case USER.UPDATED:
      return { ...state, loading: false, error: false, data: action.payload }

    case USER.UPDATE_PROFILE_DATA:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          profile: action.payload
        }
      }

    case USER.USERNAMEUPDATED:
      return { ...state, loading: false, error: false, data: action.payload }
    case USER.PROFILE_UPDATED:
      return { ...state, loading: false, error: false, data: action.payload }

    default:
      return state
  }
}

export default userReducer
