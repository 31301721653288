import { useSelector } from 'react-redux'
import { userRoles } from 'utils'
import { CVLogo, Clientvenue_logo } from 'static/Images/index'

const useAgencyLogo = () => {
  const meData = useSelector(state => state.me.data)
  const brand =
    meData.role === userRoles.USER_AGENCY
      ? meData.profile?.brand
      : meData.role > userRoles.USER_CLIENT
      ? meData.agency?.brand
      : meData.team?.profile?.brand
  const isWhiteLabelActive = meData.isWhiteLabelActive

  return {
    brandName: brand?.brandName,
    favicon: brand?.favicon
      ? brand.favicon
      : !isWhiteLabelActive
      ? Clientvenue_logo
      : '',
    largeLogo: brand?.largeLogo
      ? brand.largeLogo
      : !isWhiteLabelActive
      ? Clientvenue_logo
      : '',
    smallLogo: brand?.smallLogo
      ? brand.smallLogo
      : !isWhiteLabelActive
      ? CVLogo
      : ''
  }
}

export default useAgencyLogo
