import { makeStyles, createStyles } from '@material-ui/core'

const templateStyles = makeStyles(theme => {
  const isDarkMode = theme.palette.type === 'dark'
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
      // userSelect: 'none'
    },
    paper: {
      width: '80vw',
      height: '90vh',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none',
      '&.addProject': {
        height: '600px',
        width: '1000px',
        '@media (max-width: 1024px)': {
          width: '80vw'
        },
        '@media (max-width: 768px)': {
          height: '90%'
        }
      }
    },
    closeButton: {
      position: 'absolute',
      top: '1rem',
      right: '1rem'
    },
    btn: {
      '&> svg': {
        color: 'gray',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeInOut
        })
      },
      '&.expanded': {
        '&> svg': {
          transform: 'rotate(90deg)',
          transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.leavingScreen
          })
        }
      }
    },
    templateWrapper: {
      // width: '400px',
      minHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      backgroundColor: '#fff'
    },
    templateGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '1.5rem'
    },

    [theme.breakpoints.down('lg')]: {
      templateGrid: {
        gridTemplateColumns: 'repeat(3, 1fr)'
      }
      // paper: {
      //   width: '95vw',
      //   height: '95vh'
      // }
    },

    [theme.breakpoints.down('md')]: {
      templateGrid: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
    },

    [theme.breakpoints.down('sm')]: {
      templateGrid: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    activeRow: {
      '&.active': {
        backgroundColor: isDarkMode
          ? theme.custom.darkMode.dark1
          : theme.palette.primary.light
      }
    },
    headerContainer: ({ headerShadow }) => ({
      position: 'absolute',
      width: '100%',
      minHeight: theme.custom.projectHeader.height,
      boxShadow: '0px 4px 24px -4px rgba(0, 0, 0, 0.08)',
      userSelect: 'none',
      // position: 'fixed',
      zIndex: theme.zIndex.drawer + 1,
      background: '#fff',
      top: 0,
      right: 0,
      borderBottom: '1px solid #e8ecee',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen
      })
    }),
    tabsContainer: {
      height: theme.custom.projectHeader.height,
      '&::-webkit-scrollbar': {
        height: 1
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'transparent'
      }
    },
    tab: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      cursor: 'default',
      textAlign: 'center',
      userSelect: 'none',
      fontSize: '10px',
      boxSizing: 'content-box',
      '& .tab-line': {
        backgroundColor: 'transparent'
      },
      // '&:hover .tab-line': {
      //   backgroundColor: '#ddd'
      // },
      '&.isActive': {
        color: theme.palette.primary.main,
        '& .tab-line': {
          backgroundColor: theme.palette.primary.main
        }
      },
      '@media (min-width: 1684px)': {
        fontSize: '14px'
      }
    },
    columnContainer: {
      flex: `0 0 ${200}`
    },
    columnHeaderContainer: {
      minWidth: '100%'
    },

    taskCardsContainer: {
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingBottom: '4rem',
      borderRadius: 3,
      transition: 'background-color 0.18s ease 0s',
      minHeight: '100px',
      width: '100%',
      minWidth: `calc(200px + 1rem)`,
      height: `calc(90vh - 290px)`,
      paddingRight: 2,
      '&::-webkit-scrollbar-track': {
        background: '#E2E2E2'
      },
      '&.emtpyColumn': {
        width: '100%',
        paddingRight: 0,
        overflowY: 'hidden'
      },

      '&:hover::-webkit-scrollbar-thumb': {
        background: '#ACACAC'
      }
    },

    taskCardContainer: {
      maxWidth: 200,
      minWidth: 200,
      marginBottom: theme.spacing(2),
      boxShadow: '0 1px 2px #0000004d',
      marginRight: 16
    }
  })
})

export default templateStyles
