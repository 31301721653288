import React from 'react'
import { useTheme } from '@material-ui/core'
import serviceDefaultImage from 'static/Images/default-service.png'
import { ReactComponent as CheckIcon } from 'static/svg/check-circle.svg'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

const DemoCheckoutSection = () => {
  return (
    <div className="flex items-start pb-12">
      <div className="flex-1 mr-8">
        <div className="mb-6">
          <img
            src={serviceDefaultImage}
            alt=""
            className="w-full object-cover mb-6 rounded"
            style={{ maxHeight: 300 }}
          />
          <h1 className="text-2xl font-semibold">Service Name</h1>
        </div>

        <div className="flex items-center mb-6">
          <div className="text-base font-semibold mr-4">Amount:</div>
          <div className="text-base font-medium text-gray-700">$123</div>
        </div>

        <div className="flex items-center mb-6">
          <div className="text-base font-semibold mr-4">Type:</div>
          <div className="text-base font-medium text-gray-700">
            One time{' '}
            <span className="text-smaller font-normal">(one time payment)</span>
          </div>
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-2">Description</h2>
          <p
            className="text-sm text-gray-700 mb-8 whitespace-pre-wrap"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
            reprehenderit animi esse tempora, fugit dolores voluptas assumenda
            quis. Explicabo doloremque possimus quas est qui corrupti? Ducimus
            doloremque suscipit porro culpa!
          </p>

          <div>
            {[{ title: '10% OFF' }, { title: '5% OFF on ABCD card' }].map(
              (offer, index) => (
                <div key={index} className="flex start mb-4">
                  <div className="flex-shrink-0">
                    <CheckIcon className="text-green-500 w-6 h-6" />
                  </div>
                  <div className="ml-2 mt-0.5">
                    <div className="text-sm font-medium">{offer.title}</div>
                    {offer.description && (
                      <div className="text-smaller mt-2">
                        {offer.description}
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div
        className="bg-white border shadow px-6 pt-4 pb-6 sticky top-5 rounded"
        style={{ width: 375 }}
      >
        <DemoCheckoutForm />
      </div>
    </div>
  )
}

const DemoCheckoutForm = () => {
  const muiTheme = useTheme()

  return (
    <form className="flex flex-col w-full h-full">
      <div className="mb-2">
        <div className="mb-4">
          <label className="block font-medium text-primary-mid-dark mb-2 text-sm">
            Customer Information
          </label>
          <div className="mb-2">
            <input
              className="px-2 py-2 rounded w-full text-smaller border"
              value=""
              name="name"
              type="text"
              placeholder="Full Name"
              fullWidth
              disabled
            />
          </div>
          <div className="mb-2">
            <input
              className="px-2 py-2 rounded w-full text-smaller border"
              type="email"
              value=""
              name="email"
              placeholder="Email Address"
              fullWidth
              disabled
            />
          </div>
          <div className="mb-2">
            <input
              className="px-2 py-2 rounded w-full text-smaller border"
              type="text"
              value=""
              name="phoneNumber"
              placeholder="Phone Number"
              fullWidth
              disabled
            />
          </div>
        </div>

        <div className="mb-4">
          <label className="block font-medium text-primary-mid-dark mb-2 text-sm">
            Billing Address
          </label>
          <div className="mb-2">
            <input
              className="px-2 py-2 rounded w-full text-smaller border"
              type="text"
              value=""
              name="address"
              placeholder="Address"
              fullWidth
              disabled
            />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridGap: 6,
              gap: 6
            }}
          >
            <input
              className="px-2 py-2 rounded w-full text-smaller border"
              type="text"
              value=""
              name="city"
              placeholder="City"
              fullWidth
              disabled
            />

            <input
              className="px-2 py-2 rounded w-full text-smaller border"
              type="text"
              value=""
              name="state"
              placeholder="State"
              fullWidth
              disabled
            />
            <input
              className="px-2 py-2 rounded w-full text-smaller border"
              type="text"
              value=""
              name="zipCode"
              placeholder="Zip / Postal code"
              fullWidth
              disabled
            />

            <Select
              className="w-full"
              placeholder="Country"
              isSearchable={true}
              options={[]}
              isDisabled
              styles={reactSelectCustomStyles(
                {
                  ...muiTheme,
                  palette: { ...muiTheme.palette, type: 'light' }
                },
                {
                  control: {
                    maxHeight: 35,
                    minHeight: 35
                  }
                }
              )}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between border-b py-1 mb-4 px-1">
        <div className="text-xs font-medium uppercase text-gray-600">
          Subtotal
        </div>
        <div className="text font-semibold">$123</div>
      </div>

      <div className="mt-auto">
        <div className="flex justify-end mb-2">
          <div className="flex items-center">
            <span className="text-sm mr-4">Total:</span>
            <span className="font-bold text-xl">$123</span>
          </div>
        </div>

        <button
          type="button"
          className="flex items-center justify-center w-full py-3 bg-primary-main text-white rounded cursor-default"
          disabled
        >
          <span className="mr-2 font-semibold">Complete Checkout</span>
          <CheckIcon className="w-5 h-5" />
        </button>
      </div>
    </form>
  )
}

export default DemoCheckoutSection
