import { useState } from 'react'

const useChange = (initialValue) => {
  const [field, setField] = useState(initialValue)

  const handleFieldChange = (e) => {
    setField(e.target.value)
  }

  return [field, handleFieldChange]
}

export default useChange
