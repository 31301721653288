import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TextField, Button, useTheme } from '@material-ui/core'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { LoadingButton } from 'global/globalComponents'
import { userRoles } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useDispatch, useSelector } from 'react-redux'
import { createTeamVerticals } from 'thunks/Verticals/action'
// import Select from 'react-select'
// import { getRoleBadge } from 'utils'
import VerticalsTable from './VerticalsTable'
import { useTranslation } from 'react-i18next'

const VerticalsModal = ({ open, onClose, addNewVertical }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [selectedTab, setSelectedTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const userTeammates = useSelector(state => state.userTeammates.data)
  const verticals = useSelector(state => state.teamVerticals.data)
  // const [selectTeammatesOptions, setSelectTemmatesOptions] = useState([])
  const [verticalData, setVerticalData] = useState({
    name: '',
    description: 'role',
    assignedTeammates: []
  })

  // const optionLabel = assignee => {
  //   return (
  //     <div className="flex">
  //       <span>
  //         {assignee.name}{' '}
  //         <span className="pl-2">
  //           {assignee.role ? getRoleBadge(assignee.role, 'py-0.5') : ''}
  //         </span>
  //       </span>
  //     </div>
  //   )
  // }

  useEffect(() => {
    if (userTeammates.length) {
      userTeammates
        .filter(
          mate =>
            mate.role !== userRoles.AGENCY_ADMIN &&
            (!mate.verticals || mate.verticals?.length === 0)
        )
        .map(item => ({
          label: item.name,
          value: item._id,
          ...item
        }))
      // setSelectTemmatesOptions(options)
    }
  }, [userTeammates])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const inputDataHandler = e => {
    const { name, value } = e.target
    setVerticalData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  // const handleSelectTeammates = e => {
  //   setVerticalData(prev => ({
  //     ...prev,
  //     assignedTeammates: e
  //   }))
  // }

  const handleCreateVertical = async e => {
    e.preventDefault()

    if (!verticalData.name)
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_THE_ROLE_NAME')))

    const match = verticals.find(
      item => item.name.toLowerCase() === verticalData.name.toLowerCase()
    )

    if (match)
      return dispatch(
        fireErrorToaster(t('ERROR_ALREADY_EXISTS', { data: match.name }))
      )

    const bodyData = {
      name: verticalData.name,
      description: verticalData.description,
      newTeammates: [],
      type: 'vertical'
    }

    if (verticalData.assignedTeammates.length) {
      bodyData.newTeammates = verticalData.assignedTeammates.map(
        item => item.value
      )
    }

    setLoading(true)
    dispatch(
      createTeamVerticals(bodyData, (res, err) => {
        setLoading(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          setVerticalData({
            name: '',
            description: '',
            assignedTeammates: []
          })
          dispatch(fireSuccessToaster(t('NEW_ROLE_CREATED_SUCCESSFULLY')))
          addNewVertical({ ...res, label: res.name, value: res._id })
          handleCloseModal()
        }
      })
    )
  }

  const handleCloseModal = () => {
    setLoading(false)
    setSelectedTab(0)
    setVerticalData({
      name: '',
      description: '',
      assignedTeammates: []
    })
    onClose()
  }

  return (
    <CustomModal open={open} handleClose={handleCloseModal}>
      <CustomModalHeader
        heading={t('MANAGE_ROLE')}
        handleClose={handleCloseModal}
      />
      <CustomModalBody style={{ minHeight: '150px' }}>
        <div className="mb-6">
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {[t('CREATE_ROLE'), t('ROLES')].map((item, index) => (
              <Tab label={item} key={index} />
            ))}
          </Tabs>
        </div>

        {selectedTab === 1 ? (
          <VerticalsTable />
        ) : (
          <form onSubmit={handleCreateVertical}>
            <div className="mb-4">
              <FormLabel htmlFor="name" required>
                {t('ROLE_NAME')}
              </FormLabel>

              <TextField
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder={t('ENTER_NAME')}
                id="name"
                name="name"
                value={verticalData.name}
                onChange={inputDataHandler}
              />
            </div>
          </form>
        )}
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={handleCloseModal}>
          {t('CANCEL')}
        </Button>
        {selectedTab === 0 && (
          <LoadingButton
            size="large"
            loading={loading}
            onClick={handleCreateVertical}
          >
            {t('CREATE')}
          </LoadingButton>
        )}
      </CustomModalFooter>
    </CustomModal>
  )
}

export default VerticalsModal
