import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

const CellWrapper = ({ children, styles = {}, className, ...rest }) => {
  const ref = useRef(null)
  const [height, setHeight] = useState(30)

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.parentElement.offsetHeight)
    }
  }, [ref])

  return (
    <div
      ref={ref}
      style={{ minHeight: height, ...styles }}
      className={clsx(className, 'flex items-center cursor-pointer')}
      {...rest}
    >
      {children}
    </div>
  )
}

export default CellWrapper
