// import { Flag } from '@material-ui/icons'
import { ReactComponent as Flag } from 'static/svg/pennant.svg'
import CloseIcon from '@material-ui/icons/Close'
import { CustomTooltip } from '..'
import clsx from 'clsx'
import { t } from 'i18next'

export const priorityColors = [
  'rgb(245, 0, 0)',
  'rgb(255, 204, 0)',
  'rgb(111, 221, 255)',
  'rgb(216, 216, 216)'
]

export const getPriorityLabels = () => {
  return [t('URGENT'), t('HIGH'), t('MEDIUM'), t('LOW'), t('CLEAR')]
}

const CustomFlag = ({ priority, size = 'medium' }) => {
  const priorityLabels = getPriorityLabels()

  return (
    <>
      {priorityLabels[priority - 1] !== 'Clear' ? (
        <CustomTooltip title={priorityLabels[priority - 1]} placement="top">
          <span
            className={clsx(
              'inline-block',
              size === 'small'
                ? 'text-sm'
                : size === 'base'
                ? 'text-base'
                : 'text-lg'
            )}
          >
            <Flag
              style={{
                color: priorityColors[priority - 1]
              }}
            />
          </span>
        </CustomTooltip>
      ) : (
        <CustomTooltip title={t('CLEAR_PRIORITY')} placement="top">
          <CloseIcon fontSize="small" style={{ fill: 'gray' }} />
        </CustomTooltip>
      )}
    </>
  )
}

export default CustomFlag
