import React, { useMemo } from 'react'
import {
  // useHistory,
  NavLink
  // useRouteMatch,
  // useLocation
} from 'react-router-dom'
import headerStyles from '../../headerStyles'
import clsx from 'clsx'
import {
  GoogleDocs,
  GoogleSheet,
  GoogleSlides,
  GoogleCalender
} from 'static/Images'
// import { useSelector } from 'react-redux'
// import { getUserPath } from 'utils/userRoles'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import { IconButton } from '@material-ui/core'
import { ReactComponent as CaretDown } from 'static/svg/caret-down.svg'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { useTranslation } from 'react-i18next'

const tabIcon = {
  sheet: GoogleSheet,
  docs: GoogleDocs,
  slides: GoogleSlides,
  calendar: GoogleCalender
}

const DynamicTabs = ({
  dynamicTabsData,
  handleDeleteWorkspaceTab,
  handleTabClick
}) => {
  const classes = headerStyles()
  const { t } = useTranslation()
  return (
    <>
      {dynamicTabsData.map((tabData, index) => (
        <NavLink
          key={index}
          to={tabData.path + tabData.query}
          className={clsx(classes.tab, 'show-on-hover-parent relative')}
          isActive={(match, location) =>
            match && location.search === tabData.query
          }
          activeClassName="isActive"
        >
          <span className="inline-flex my-auto justify-center items-center border-l px-4 w-full gap-1">
            <span className="flex items-center justify-center space-x-1">
              {tabIcon[tabData.name] && (
                <img
                  src={tabIcon[tabData.name]}
                  alt=""
                  height="14px"
                  width="14px"
                  className="block"
                />
              )}
              <span className="dark:text-dark-light">{tabData.label}</span>

              {tabData.isRemovable && handleDeleteWorkspaceTab && (
                <CustomTooltip
                  title={`${t('DELETE')} ${tabData.label}`}
                  placement="top"
                >
                  <IconButton
                    onClick={() => handleDeleteWorkspaceTab(tabData)}
                    size="small"
                    className={`cross-btn show-on-hover-child dark:text-dark-light ${classes.deleteEmbedBtn}`}
                  >
                    <CancelRoundedIcon
                      fontSize="small"
                      style={{ width: '18px', height: '18px' }}
                      className="dark:text-dark-light"
                    />
                  </IconButton>
                </CustomTooltip>
              )}
              {tabData.total > 1 && (
                <button
                  className="flex items-center hover:bg-primary-light p-0.5 dark:text-dark-light"
                  onClick={e => handleTabClick(e, tabData.name)}
                >
                  <span className="text-smaller 2xl:text-sm font-semibold dark:text-dark-light">
                    ({tabData.total})
                  </span>
                  <CaretDown className="h-3 w-3" />
                </button>
              )}
            </span>
          </span>
          <div className="tab-line w-3/4 h-1 mx-auto"></div>
        </NavLink>
      ))}
    </>
  )
}

export default DynamicTabs
