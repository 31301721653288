export const EMAIL_NOTIFICATION_EVENTS = {
  LOADING: 'EMAIL_NOTIFICATION_EVENTS_LOADING',
  FETCHED: 'EMAIL_NOTIFICATION_EVENTS_FETCHED',
  UPDATED: 'EMAIL_NOTIFICATION_EVENTS_UPDATED',
  USER_SUBSCRIBED_EVENTS: 'EMAIL_NOTIFICATION_EVENTS_USER_SUBSCRIBED_EVENTS',
  UPDATE_STATUS: 'EMAIL_NOTIFICATION_EVENTS_UPDATE',
  SAVING: 'EMAIL_NOTIFICATION_EVENTS_SAVING',
  SAVED: 'EMAIL_NOTIFICATION_EVENTS_SAVED'
}

const initialState = {
  loading: true,
  savingChanges: false,
  events: [],
  subscribedEvents: {},
  error: false
}

const emailNotificationEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_NOTIFICATION_EVENTS.LOADING:
      return { ...state, loading: true }

    case EMAIL_NOTIFICATION_EVENTS.FETCHED:
      return { ...state, loading: false, events: action.payload }

    case EMAIL_NOTIFICATION_EVENTS.UPDATED:
      return { ...state, events: action.payload }

    case EMAIL_NOTIFICATION_EVENTS.USER_SUBSCRIBED_EVENTS:
      return { ...state, loading: false, subscribedEvents: action.payload }

    case EMAIL_NOTIFICATION_EVENTS.UPDATE_STATUS:
      return { ...state, subscribedEvents: action.payload }

    case EMAIL_NOTIFICATION_EVENTS.SAVING:
      return { ...state, savingChanges: true }

    case EMAIL_NOTIFICATION_EVENTS.SAVED:
      return { ...state, savingChanges: false }

    default:
      return state
  }
}

export default emailNotificationEventsReducer
