// import theme from 'themes/themes'
// import { useMemo } from 'react'
// import { useTheme } from '@material-ui/core'

const reactSelectCustomStyles = (theme, styles = {}) => {
  const isLightMode = theme.palette.type === 'light'
  const clrPrimary = isLightMode
    ? theme.palette.primary.main
    : theme.custom.darkMode.dark2
  const clrPrimaryLight = isLightMode
    ? theme.palette.primary.light
    : theme.custom.darkMode.dark2

  return {
    control: (base, { isDisabled }, state) => ({
      ...base,
      ...(theme.palette.type !== 'light' && {
        backgroundColor: isDisabled ? '#706e6e' : theme.custom.darkMode.dark2
      }),
      ...(theme.palette.type !== 'light' &&
        isDisabled && {
          border: '1px solid #424242'
        }),
      ...(theme.palette.type !== 'light' && {
        color: isDisabled ? '#00ff00' : '#00ff00'
      }),
      paddingLeft: 3,
      paddingRight: 3,
      '&:focus-within': {
        borderColor: clrPrimary,
        boxShadow: `0 0 0 1px ${clrPrimary}`
      },
      ...styles.control
    }),

    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      // ...(theme.palette.type !== 'light' && {
      //   color: theme.custom.darkMode.background
      // }),
      color: theme.palette.type === 'dark' ? '#ccc' : provided.color,
      fontSize: '14px',
      backgroundColor: isSelected
        ? clrPrimary
        : isFocused
        ? clrPrimaryLight
        : null,
      ':active': {
        ...provided[':active'],
        backgroundColor: clrPrimaryLight
      },
      ...styles.option
    }),

    input: (provided, state) => ({
      ...provided,
      ...(theme.palette.type !== 'light' && {
        color: theme.custom.darkMode.color
      }),
      fontSize: '14px',
      ...styles.input
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      color: '#a9adb4',
      fontWeight: '300',
      ...styles.placeholder
    }),
    valueContainer: provided => ({
      ...provided,
      ...styles.valueContainer
    }),
    singleValue: provided => ({
      ...provided,
      color: theme.palette.type === 'dark' ? '#ccc' : '',
      fontSize: '14px',
      ...styles.singleValue
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (base, state) => ({
      ...base,
      ...styles.dropdownIndicator
    }),
    multiValue: provided => ({
      ...provided,
      padding: '2px 7px',
      borderRadius: '100vh',
      fontWeight: 500,
      backgroundColor: isLightMode ? '#ECEAFF' : '#333',
      ...styles.multiValue
    }),
    multiValueLabel: provided => {
      return {
        ...provided,
        color: isLightMode ? clrPrimary : '#ccc',
        ...styles.multiValueLabel
      }
    },
    multiValueRemove: provided => ({
      ...provided,
      top: 3,
      position: 'relative',
      left: 2,
      width: 22,
      height: 22,
      color: theme.palette.type === 'dark' ? '#fff' : clrPrimary,
      borderRadius: '50%',
      cursor: 'pointer',
      backgroundColor: isLightMode ? '#fff' : theme.custom.darkMode.dark1,
      ':hover': {
        backgroundColor: isLightMode
          ? clrPrimary
          : theme.custom.darkMode.background,
        color: '#fff'
      },
      ...styles.multiValueRemove
    }),
    menuPortal: provided => ({
      ...provided,
      ...styles.menuPortal
    }),
    menu: provided => ({
      ...provided,
      backgroundColor:
        theme.palette.type === 'dark' ? theme.custom.darkMode.dark1 : '#fff',
      ...styles.menu
    })
  }
}

export default reactSelectCustomStyles
