import React from 'react'
import { Avatar, FilePreview, PopupMenuList } from 'global/globalComponents'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateAndTimeHelper, getFileType } from 'utils'
import MessageAttachments from './MessageAttachments'
import { format } from 'date-fns'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const ChatMessages = ({
  messages,
  meId,
  openDeleteMessageAlert,
  noMessages
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [filePreviewModal, setFilePreviewModal] = useState({
    open: false,
    files: [],
    fileIndex: 0
  })

  const handleOpenPreview = (fileIndex, allFiles) => {
    if (filePreviewModal.open) {
      setFilePreviewModal(prev => ({ ...prev, open: false }))
    } else {
      const files = allFiles.map(fileUrl => {
        const file = {}

        const arr = fileUrl.split('/')
        file.name = arr[arr.length - 1]
        file.type = getFileType(fileUrl)
        file.url = fileUrl
        file.extension = fileUrl.slice(fileUrl.lastIndexOf('.'))

        return file
      })

      setFilePreviewModal({ open: true, files, fileIndex })
    }
  }

  return (
    <div>
      {noMessages ? (
        <div className="w-full text-center text-xs text-gray-500 py-1">
          {t('NO_MESSAGES')}
        </div>
      ) : (
        messages.map((item, index) => (
          <>
            {index === 0 ? (
              <div className="text-extraSmaller text-gray-500 my-1 text-center inline-block w-full">
                {format(new Date(item.createdAt), 'dd MMMM yyyy')}
              </div>
            ) : (
              dateAndTimeHelper.getDateDiffInDays(
                messages[index - 1].createdAt,
                item.createdAt
              ) >= 1 && (
                <div
                  className={clsx(
                    'relative z-10 text-extraSmaller text-gray-500 my-1 text-center inline-block w-full',
                    classes.dateContainer
                  )}
                >
                  <span className="px-4 border rounded-full py-1 font-semibold bg-white">
                    {format(new Date(item.createdAt), 'dd MMMM yyyy')}
                  </span>
                </div>
              )
            )}
            <MessageReceived
              key={index}
              messageData={item}
              user={item.createdBy}
              isNewUser={
                index === 0 ||
                messages[index - 1].createdBy._id !== item.createdBy._id ||
                dateAndTimeHelper.getDateDiffInDays(
                  messages[index - 1].createdAt,
                  item.createdAt
                ) >= 1
              }
              t={t}
              handleOpenPreview={handleOpenPreview}
              canDelete={item.createdBy._id === meId}
              openDeleteMessageAlert={() => openDeleteMessageAlert(item)}
              classes={classes}
            />
          </>
        ))
      )}

      <FilePreview
        open={filePreviewModal.open}
        files={filePreviewModal.files}
        fileIndex={filePreviewModal.fileIndex}
        onClose={handleOpenPreview}
      />
    </div>
  )
}

const MessageReceived = ({
  messageData,
  user,
  isNewUser,
  t,
  handleOpenPreview,
  openDeleteMessageAlert,
  canDelete,
  classes
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <>
      {messageData.isNew && (
        <div className="flex items-center pr-2">
          <hr style={{ border: '1px solid #ff000073' }} className="flex-1" />
          <span className="ml-2 text-red-500 text-xs font-medium">
            {t('NEW')}
          </span>
        </div>
      )}
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="flex items-start px-5 py-1.5 hover:bg-gray-100 show-on-hover-parent"
      >
        {isNewUser ? (
          <Avatar user={messageData.createdBy} tooltip={false} size="normal" />
        ) : (
          <div className="w-8 text-tiny text-gray-500 mt-1 inline-block show-on-hover-child">
            {format(new Date(messageData.createdAt), 'HH:mm')}
          </div>
        )}

        <div className="ml-3">
          {Boolean(messageData.message) && (
            <div className="text-sm leading-5">
              {isNewUser && (
                <div className="font-semibold capitalize mb-1 dark:text-dark-light2">
                  {user.name}

                  <span className="text-tiny text-gray-500 ml-2">
                    {format(new Date(messageData.createdAt), 'HH:mm')}
                  </span>
                </div>
              )}
              <div
                className={clsx(
                  'break-long-word dark:text-dark-light',
                  classes.messageContainer
                )}
                dangerouslySetInnerHTML={{
                  __html: messageData.message
                }}
              ></div>
            </div>
          )}

          {Boolean(messageData.attachments?.length) && (
            <MessageAttachments
              attachments={messageData.attachments || []}
              handleOpenPreview={handleOpenPreview}
              dir="left"
            />
          )}
        </div>

        {canDelete && isHovered && (
          <div className="ml-auto leading-3 my-auto">
            <OptionsButton
              t={t}
              openDeleteMessageAlert={openDeleteMessageAlert}
            />
          </div>
        )}
      </div>
    </>
  )
}

const OptionsButton = ({ t, openDeleteMessageAlert }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <button onClick={e => setAnchorEl(e.currentTarget)}>
        <ThreeDotsIcon className="text-gray-600" />
      </button>
      <PopupMenuList
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        menuItems={[
          {
            label: t('DELETE'),
            icon: <TrashIcon />,
            method: openDeleteMessageAlert,
            isDanger: true
          }
        ]}
      />
    </>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    messageContainer: {
      '& p': { color: theme.palette.type === 'dark' ? '#ccc' : '#000' },
      '& a': {
        color: theme.palette.type === 'dark' ? 'cornflowerblue' : 'blue'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    dateContainer: {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 6,
        left: 0,
        right: 0,
        height: 1,
        background: '#edecec',
        zIndex: -1
      }
    }
  })
)

export default ChatMessages
