export const CALENDAR = {
  LOADING: 'LOADING_CALENDAR',
  ERROR: 'CALENDAR_ERROR',
  FETCHED: 'CALENDAR_DATA_FETCHED',
  ADD_ONE_CALENDAR: 'ADD_ONE_CALENDAR',
  DELETE_ONE_CALENDAR: 'DELETE_ONE_CALENDAR',
  UPDATE_ONE_CALENDAR: 'UPDATE_ONE_CALENDAR',
  FETCHED_CALENDAR_EVENT: 'FETCHED_CALENDAR_EVENT',
  ADD_ONE_CALENDAR_EVENT: 'ADD_ONE_CALENDAR_EVENT',
  DELETE_ONE_CALENDAR_EVENT: 'DELETE_ONE_CALENDAR_EVENT',
  UPDATE_ONE_CALENDAR_EVENT: 'UPDATE_ONE_CALENDAR_EVENT',
  FETCHED_PROJECTS_EVENT: 'FETCHED_PROJECTS_EVENT',
  FETCHED_TASKS_EVENT: 'FETCHED_TASKS_EVENT'
}

const initialStates = {
  error: false,
  loading: true,
  data: [],
  events: [],
  projects: [],
  tasks: { loading: false, data: [] },
  fetched: false
}

const calendarReducer = (state = initialStates, action) => {
  switch (action.type) {
    // case CALENDAR.LOADING:
    //   return { ...state, loading: true, error: false }

    // case CALENDAR.FETCHED:
    //   return { ...state, loading: false, fetched: true, data: action.payload }

    // case CALENDAR.ADD_ONE_CALENDAR:
    //   return { ...state, data: [action.payload, ...state.data] }

    // case CALENDAR.UPDATE_ONE_CALENDAR:
    //   return { ...state }

    // case CALENDAR.DELETE_ONE_CALENDAR:
    //   return { ...state }

    case CALENDAR.FETCHED_CALENDAR_EVENT:
      return { ...state, events: action.payload }

    case CALENDAR.ADD_ONE_CALENDAR_EVENT:
      return { ...state, events: [action.payload, ...state.events] }

    case CALENDAR.UPDATE_ONE_CALENDAR_EVENT:
      const updatedEvents = [...state.events]

      const ind = updatedEvents.findIndex(el => el._id === action.payload._id)

      updatedEvents[ind] = { ...updatedEvents[ind], ...action.payload }

      return { ...state, events: updatedEvents }

    case CALENDAR.DELETE_ONE_CALENDAR_EVENT:
      return {
        ...state,
        events: state.events.filter(item => !(item._id === action.payload._id))
      }

    case CALENDAR.FETCHED_PROJECTS_EVENT:
      return { ...state, projects: action.payload }

    case CALENDAR.FETCHED_TASKS_EVENT:
      return { ...state, tasks: { loading: false, data: action.payload } }

    case CALENDAR.ERROR:
      return { ...state, loading: false, error: true }

    default:
      return { ...state }
  }
}

export default calendarReducer
