import produce from 'immer'

export const SERVICE_MARKETPLACE = {
  FETCHED_SERVICES: 'SERVICE_MARKETPLACE_FETCHED_SERVICES',
  FETCHED_CATEGORIES: 'SERVICE_MARKETPLACE_FETCHED_CATEGORIES',
  FETCHED_INVOICES: 'SERVICE_MARKETPLACE_FETCHED_INVOICES',
  ADD_INVOICE: 'SERVICE_MARKETPLACE_ADD_INVOICE'
}

const initialState = {
  services: {
    loading: true,
    data: [],
    fetched: false
  },
  serviceCategories: {
    loading: true,
    data: [],
    fetched: false
  },
  invoices: {
    loading: true,
    data: [],
    fetched: false
  }
}

const serviceReducer = produce((draft, action) => {
  switch (action.type) {
    case SERVICE_MARKETPLACE.FETCHED_SERVICES:
      draft.services.loading = false
      draft.services.fetched = true
      draft.services.data = action.payload
      return draft

    case SERVICE_MARKETPLACE.FETCHED_CATEGORIES:
      draft.serviceCategories.loading = false
      draft.serviceCategories.fetched = true
      draft.serviceCategories.data = action.payload
      return draft

    case SERVICE_MARKETPLACE.FETCHED_INVOICES:
      draft.invoices.loading = false
      draft.invoices.fetched = true
      draft.invoices.data = action.payload
      return draft

    case SERVICE_MARKETPLACE.ADD_INVOICE:
      draft.invoices.data.push(action.payload)
      return draft
    default:
      return draft
  }
}, initialState)

export default serviceReducer
