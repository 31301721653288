import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { Button, Drawer, makeStyles } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import DescriptionEditor from 'components/Editors/DescriptionEditor/DescriptionEditor'
import { useDispatch, useSelector } from 'react-redux'
import {
  DrawerContent,
  DrawerFooter
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import clsx from 'clsx'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import { userRoles } from 'utils'
import PriorityDropDown from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import { createTask } from 'thunks/task/actions'
import CreateTaskPanelHeader from './CreateTaskPanelHeader'
import taskSidePanelStyles from '../Workspace/Panel/taskSidePanelStyles'
import StatusDropDown from 'components/Workspace/StatusDropDown'
import EstimateTimePopup from 'components/Workspace/Panel/EstimateTimePopup'
import CreateTaskBody from './CreateTaskBody'
import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'
import { useTranslation } from 'react-i18next'
import { fetchSingleEmbedById } from 'thunks/embed/action'

const CreateTaskPanel = ({
  open,
  onClose,
  addSelectedTask,
  project,
  options,
  isLoading,
  showProjectSelect, //for adding task from sidenav
  handleSelectProject
}) => {
  const { t } = useTranslation()
  // const theme = useTheme()
  const dispatch = useDispatch()
  // const { currentWorkspace: project, boardData } = useSelector(
  //   state => state.userWorkspaces
  // )

  const classes = taskSidePanelStyles({ inDashboardArea: true })
  const editorClasses = useStyles()
  // const [selectedProject, setSelectedProject] = useState({})
  const meData = useSelector(state => state.me.data)
  const [taskState, setTaskState] = useState({
    title: '',
    collaborators: [],
    description: '',
    priority: null,
    taskSection: {},
    estimateTimeInMilliSecond: null,
    estimateTimeInString: null,
    markAsComplete: null,
    startDate: '',
    dueDate: ''
  })

  const [popper, setPopper] = useState({
    anchorEl: null,
    type: ''
  })
  const [collabOptions, setCollabOptions] = useState([])
  const editorRef = useRef()
  const [loading, setLoading] = useState(false)
  // const [estimateTimeAnchor, setEstimateTimeAnchor] = useState(null)
  const [statusList, setStatusList] = useState([])
  const { getCollaboratorsOptions } = useWorkspaceFunctions()
  const [boardData, setBoardData] = useState({})

  // const users = useMemo(() => {
  //   if (taskState.collaborators) {
  //     return taskState.collaborators.map(item => ({
  //       ...item,
  //       id: item._id,
  //       value: item.name
  //     }))
  //   }
  // }, [taskState.collaborators])

  useLayoutEffect(() => {
    setTaskState({
      title: '',
      collaborators: [meData],
      description: '',
      priority: null,
      taskSection: boardData.boardSection ? boardData.boardSection[0] : [],
      estimateTimeInMilliSecond: null,
      estimateTimeInString: null,
      markAsComplete: null,
      startDate: '',
      endDate: ''
    })

    setStatusList(boardData.boardSection || [])
    setPopper({ anchorEl: null, type: '' })
  }, [open, project, boardData])

  useEffect(() => {
    if (!boardData?._id && project) {
      fetchSingleEmbedById(project?.defaultBoard?._id, (res, err) => {
        if (!err) setBoardData(res)
      })
      setTaskState({
        ...taskState,
        taskSection: boardData.boardSection ? boardData.boardSection[0] : []
      })
    }
  }, [project])

  useEffect(() => {
    if (!boardData._id && project) {
      fetchSingleEmbedById({ id: project.defaultBoard?._id }, (res, err) => {
        if (!err) setBoardData(res)
      })
    }
  }, [project])

  useEffect(() => {
    if (project) {
      const collabs = getCollaboratorsOptions(taskState.collaborators, project)
      setCollabOptions(collabs)
    }
  }, [taskState])

  const handleTitleChange = e => {
    setTaskState(prev => ({ ...prev, title: e.target.value }))
  }

  const handleRemoveTeammate = teammate => {
    setTaskState(prev => ({
      ...prev,
      collaborators: prev.collaborators.filter(
        item => item._id !== teammate._id
      )
    }))
  }

  // const handleAddTeammate = (teammate) => {}

  const handlePopperClose = () => {
    setPopper({ type: '', anchorEl: null })
  }

  const handleCollaborator = val => {
    setTaskState(prev => ({
      ...prev,
      collaborators: [...prev.collaborators, { ...val.user, role: val.role }]
    }))
    handlePopperClose()
  }

  const triggerPopover = (e, type) => {
    setPopper({ type, anchorEl: e.currentTarget })
  }

  const handleDescriptionChange = (range, source, user, editor) => {
    setTaskState(prev => ({ ...prev, description: editor.getContents() }))
  }

  const handlePriorityChange = priority => {
    handlePopperClose()
    setTaskState(prev => ({
      ...prev,
      priority: priority.order === 5 ? '' : priority.order
    }))
  }

  const handleCreateTask = e => {
    e.preventDefault()

    if (!taskState.title.trim()) {
      dispatch(fireErrorToaster(t('ERROR_TASK_TITLE_IS_REQUIRED')))
      return
    }

    const data = {
      title: taskState.title.trim(),
      workSpace: project?._id,
      workspaceBoard: boardData._id,
      taskSection: taskState.taskSection._id,
      description: taskState.description,
      priority: taskState.priority,
      assignedAgencyTeam: taskState.collaborators
        .filter(item => item.role < userRoles.USER_CLIENT)
        .map(item => item._id),
      assignedClientTeam: taskState.collaborators
        .filter(item => item.role >= userRoles.USER_CLIENT)
        .map(item => item._id),
      markAsComplete: taskState.markAsComplete,
      startDate: taskState.startDate,
      dueDate: taskState.dueDate,
      estimateTimeInMilliSecond: taskState.estimateTimeInMilliSecond,
      estimateTimeInString: taskState.estimateTimeInString
    }

    setLoading(true)
    dispatch(
      createTask({ data }, (res, err) => {
        setLoading(false)
        addSelectedTask({
          taskTitle: res.title,
          taskId: res._id,
          projectId: res.workSpace
        })
        onClose()
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('TASK_CREATED_SUCCESSFULLY')))
        }
      })
    )
  }

  // const toggleEstimateTimePopup = (e) => {
  //   setEstimateTimeAnchor(estimateTimeAnchor ? null : e.currentTarget)
  // }

  const handleChangeEstimateTime = ({ timeStr, totalMs }) => {
    setTaskState(prev => ({
      ...prev,
      estimateTimeInString: timeStr,
      estimateTimeInMilliSecond: totalMs
    }))

    handlePopperClose()
  }

  const handleChangeSection = section => {
    setTaskState(prev => ({ ...prev, taskSection: section }))
  }

  const toggleMarkAsComplete = () => {
    setTaskState(prev => ({ ...prev, markAsComplete: !prev.markAsComplete }))
  }

  const handleDateChange = (e, prop) => {
    setTaskState(prev => ({ ...prev, [prop]: e.toISOString() }))
  }

  return (
    <>
      <Drawer
        onClose={onClose}
        classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
        anchor="right"
        open={open}
      >
        {/* ============== HEADER =============== */}
        <CreateTaskPanelHeader
          onClose={onClose}
          taskState={taskState}
          triggerPopover={triggerPopover}
          changeEstimateTime={handleChangeEstimateTime}
          // updateSection={updateSection}
          toggleMarkAsComplete={toggleMarkAsComplete}
          showProjectSelect={showProjectSelect}
          options={options}
          handleSelectProject={handleSelectProject}
          selectedProject={project}
          isLoading={isLoading}
        />
        {project && (
          <>
            <DrawerContent open={open} style={{ padding: 0 }}>
              <div className="overflow-y-auto">
                <div className="mt-2 mb-4 px-2">
                  <input
                    type="text"
                    name="title"
                    onChange={handleTitleChange}
                    value={taskState.title}
                    className="py-2 px-3 text-2xl font-semibold border border-transparent hover:border-gray-300 block w-full rounded-md dark:bg-dark-main3"
                    placeholder="Task Title"
                  />
                </div>
                <CreateTaskBody
                  taskState={taskState}
                  triggerPopover={triggerPopover}
                  handleDateChange={handleDateChange}
                  handleRemoveTeammate={handleRemoveTeammate}
                  project={project}
                  // assignedTags={assignedTags}
                  // setTagsPopoverData={setTagsPopoverData}
                  // updateProjectStats={updateProjectStats}
                />

                <div className="mb-4 px-6">
                  <FieldLabel className="mb-2">{t('DESCRIPTION')}</FieldLabel>
                  <div className="text-sm">
                    <DescriptionEditor
                      ref={editorRef}
                      value={taskState.description}
                      onChange={handleDescriptionChange}
                      className={editorClasses.editor}
                      workspace={project}
                      elmFor="task"
                    />
                  </div>
                </div>
              </div>
            </DrawerContent>
            <StatusDropDown
              open={popper.type === 'status'}
              anchorEl={popper.anchorEl}
              onClose={handlePopperClose}
              changeStatus={handleChangeSection}
              statusList={statusList}
              taskSection={taskState.taskSection?._id}
              style={{ zIndex: 2000 }}
            />
            <CollabDrowdown
              open={popper.type === 'collab'}
              anchorEl={popper.anchorEl}
              onClose={handlePopperClose}
              list={collabOptions}
              handleListItemClick={handleCollaborator}
              style={{ zIndex: 2000 }}
              getUser={listItem => ({ ...listItem.user, role: listItem.role })}
            />
            <PriorityDropDown
              open={popper.type === 'priority'}
              anchorEl={popper.anchorEl}
              onClose={handlePopperClose}
              currentPriority={taskState.priority}
              onChange={handlePriorityChange}
              style={{ zIndex: 2000 }}
            />
            <EstimateTimePopup
              open={popper.type === 'estimateTime'}
              anchorEl={popper.anchorEl}
              onClose={handlePopperClose}
              selectedTime={taskState.estimateTimeInString}
              handleChangeEstimateTime={handleChangeEstimateTime}
              style={{ zIndex: 2000 }}
            />

            <DrawerFooter>
              <Button size="large" variant="outlined" onClick={onClose}>
                {t('CANCEL')}
              </Button>

              <LoadingButton
                size="large"
                loading={loading}
                onClick={handleCreateTask}
                className="bg-dark-main1"
              >
                {t('CREATE')}
              </LoadingButton>
            </DrawerFooter>
          </>
        )}
      </Drawer>
    </>
  )
}

export const FieldLabel = props => {
  const { className, children, ...restProps } = props
  return (
    <label
      {...restProps}
      className={clsx(
        'block text-xs text-gray-600 dark:text-dark-light',
        className
      )}
    >
      {children}
    </label>
  )
}

const useStyles = makeStyles({
  editor: {
    '& .ql-editor': {
      minHeight: 260,
      overflow: 'auto'
    }
  }
})

export default CreateTaskPanel
