import { useEffect, useState } from 'react'
import { CustomTooltip } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { TextField, CircularProgress } from '@material-ui/core'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import {
  deleteTeamVertical,
  fetchTeamVerticals,
  updateTeamVerticals
} from 'thunks/Verticals/action'
import teammateStyles from './teammateStyles'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { IconButton } from '@material-ui/core'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import Check from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const VerticalsTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const verticals = useSelector(state => state.teamVerticals)
  const [loading, setLoading] = useState(false)
  const [editable, setEditable] = useState({
    data: {},
    open: false,
    value: ''
  })

  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    data: {},
    loading: false
  })

  useEffect(() => {
    if (!verticals.fetched) {
      dispatch(fetchTeamVerticals())
    }
  }, [verticals])

  const handleEditField = e => {
    e.preventDefault()
    if (!editable.value) {
      return dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_EMPTY_FIELD')))
    }
    const match = verticals.data.find(
      item => item.name.toLowerCase() === editable.value?.toLowerCase()
    )

    if (match)
      return dispatch(
        fireErrorToaster(t('ERROR_ALREADY_EXISTS', { data: match.name }))
      )

    if (editable.value.toLowerCase() === editable.data.name?.toLowerCase()) {
      return setEditable({
        data: {},
        open: false,
        value: ''
      })
    }
    setLoading(true)
    dispatch(
      updateTeamVerticals(
        editable.data._id,
        { name: editable.value },
        updateVerticalCallback
      )
    )
  }

  const updateVerticalCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      setEditable({
        data: {},
        open: false,
        value: ''
      })
    }
  }

  const handleOpenDeleteModal = data => {
    setOpenDeleteModal({
      loading: false,
      open: true,
      data
    })
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal({
      loading: false,
      open: false,
      data: {}
    })
  }

  const handleDeleteVertical = () => {
    setOpenDeleteModal(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteTeamVertical(openDeleteModal.data._id, (res, err) => {
        setOpenDeleteModal({
          loading: false,
          open: false,
          data: {}
        })
        if (!err) {
          dispatch(fireSuccessToaster(t('VERTICAL_DELTED_SUCCESSFULLY')))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <div
      style={{ height: verticals.data.length > 2 ? '285px' : '185px' }}
      className="pb-4"
    >
      <TableContainer
        className="min-w-full divide-y divide-gray-200"
        shadow="sm"
        mb={0}
        containerProps={{
          style: {
            overflowY: 'auto',
            height: '100%',
            background: '#fff'
          }
        }}
      >
        <Thead className="sticky top-0" style={{ zIndex: 99 }}>
          <Th>{t('ROLES')}</Th>
          <Th className="text-right whitespace-nowrap">{t('ACTIONS')}</Th>
        </Thead>

        <Tbody>
          {!verticals.loading ? (
            verticals.data.length > 0 ? (
              verticals.data.map((item, index) => (
                <Row
                  key={item._id}
                  item={item}
                  handleDelete={() => handleOpenDeleteModal(item)}
                  handleEditField={handleEditField}
                  editable={editable}
                  setEditable={setEditable}
                  loading={loading}
                />
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan="5"
                  className="text-left py-3 px-6 text-gray-500 font-normal"
                >
                  {t('THERE_ARE_NO_VERTICALS')}
                </th>
              </Tr>
            )
          ) : (
            <TableDataLoading cols={4} />
          )}
        </Tbody>
      </TableContainer>
      <DeleteModal
        warningText={t('WARNING_ROLE_WILL_BE_DELETED')}
        open={openDeleteModal.open}
        handleDialog={handleCloseDeleteModal}
        handleDeleteAction={handleDeleteVertical}
        loading={openDeleteModal.loading}
      />
    </div>
  )
}

const Row = ({
  item,
  handleDelete,
  handleEditField,
  editable,
  setEditable,
  loading
}) => {
  const classes = teammateStyles()
  const handleEditText = e => {
    const value = e.target.value
    setEditable(prev => ({
      ...prev,
      value: value
    }))
  }

  // const truncateText = text => {
  //   const maxWord = 45
  //   if (text?.length >= maxWord) {
  //     const transformed = text.substr(0, maxWord - 2)
  //     return `${transformed}...`
  //   }
  //   return text
  // }

  return (
    <Tr>
      <Td>
        {!(editable.data._id === item._id && editable.open) ? (
          <div className="show-on-hover-parent flex items-center space-x-2 ">
            <span className=" text-custom-table-primaryText dark:text-blue-400">
              {item.name}
            </span>
            <CustomTooltip title={t('EDIT_ROLE')} placement="top">
              <Pen
                className={`show-on-hover-child ${classes.penIcon}`}
                onClick={() =>
                  setEditable(() => ({
                    data: item,
                    open: true,
                    value: item.name
                  }))
                }
              />
            </CustomTooltip>
          </div>
        ) : (
          <form
            onSubmit={handleEditField}
            className="flex items-center space-x-2"
          >
            <TextField
              type="text"
              value={editable.value}
              name="name"
              onChange={handleEditText}
              autoFocus
            />
            {!loading ? (
              <IconButton size="small">
                <Check
                  className=" text-primary-main "
                  fontSize="small"
                  onClick={handleEditField}
                />
              </IconButton>
            ) : (
              <CircularProgress
                fontSize="small"
                style={{ width: '10px', height: '10px' }}
              />
            )}
            <IconButton size="small">
              <CloseIcon
                className=" text-red-500 "
                fontSize="small"
                onClick={() =>
                  setEditable(() => ({
                    data: {},
                    open: false,
                    value: ''
                  }))
                }
              />
            </IconButton>
          </form>
        )}
      </Td>

      <Td className="text-right whitespace-nowrap cursor-pointer">
        <div className="flex justify-end space-x-4">
          <IconButton size="small" onClick={handleDelete}>
            <TrashIcon className="h-4 w-4 text-custom-red-main" />
          </IconButton>
        </div>
      </Td>
    </Tr>
  )
}

export default VerticalsTable
