import React, { useEffect, useState } from 'react'
import { Box, Popover, List, ListItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
import UserDashboardStyles from '../userDashboardStyles'
import CheckIcon from '@material-ui/icons/Check'
import { ReactComponent as DotIcon } from 'static/svg/3dots-h.svg'
// import { ReactComponent as TagsIcon } from 'static/svg/tags.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { ReactComponent as PaintIcon } from 'static/svg/paint-brush.svg'
// import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
// import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
// import { IconButton, Typography } from '@material-ui/core'
import {
  fetchTagsByUser,
  updateTagsById,
  deleteTagsById,
  createTags
  // fetchWorkspaceTagsById
} from 'thunks/tags/actions'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import AssignedTags from './AssignedTags'
import { useTranslation } from 'react-i18next'

const colorOptions = [
  '#ff4ba6',
  '#04A9F4',
  '#800101',
  '#FF7801',
  '#FF4081',
  '#7C4DFF',
  '#aa4ba6',
  '#000000',
  '#DC143C',
  '#D2691E',
  '#006400',
  '#000080',
  '#800080',
  '#FF0000',
  '#2F4F4F',
  '#696969',
  '#008000',
  '#2E8B57',
  '#008080',
  '#9ACD32',
  '#EE82EE',
  '#F4A460',
  '#FF4500'
]

const Tags = props => {
  const { t } = useTranslation()
  const {
    workspace,
    taskId,
    tagsPopoverData,
    setTagsPopoverData,
    elmFor
  } = props
  const dispatch = useDispatch()
  const classes = UserDashboardStyles()
  const userTags = useSelector(state => state.userTags)

  const [editTagsPopoverData, setEditTagsPopoverData] = useState({
    open: false,
    anchorEl: null
  })

  const [tagsColorPopoverData, setTagsColorPopoverData] = useState({
    open: false,
    anchorEl: null
  })

  // const [userTags, setUserTags] = useState([])
  const [visibleTags, setVisibleTags] = useState([])
  const [assignedTag, setAssignedTag] = useState([])
  const [availableColors, setAvailableColors] = useState(colorOptions)
  const [tagData, setTagData] = useState({ name: '', new: false })
  const [updatedTag, setUpdateTag] = useState({
    open: false,
    name: '',
    data: {}
  })

  const [deleteTagModal, setDeleteTagModal] = useState({
    open: false,
    data: {},
    loading: false
  })

  useEffect(() => {
    if (!userTags.fetched) {
      dispatch(fetchTagsByUser())
    }
  }, [userTags.fetched])

  useEffect(() => {
    let tags
    let visibleData
    if (elmFor === 'workspace') {
      tags = userTags.data?.filter(el =>
        el.workspaceIds.includes(workspace._id)
      )
      setAssignedTag(tags)

      visibleData = userTags.data.filter(
        el => !tags.map(i => i._id).includes(el._id)
      )
      setVisibleTags(visibleData)
    } else {
      tags = userTags.currentWorkspaceTags?.filter(el =>
        el.taskIds.includes(taskId)
      )
      setAssignedTag(tags)
      visibleData = userTags.currentWorkspaceTags.filter(
        el => !tags.map(i => i._id).includes(el._id)
      )
      setVisibleTags(visibleData)
    }
  }, [userTags.data, userTags.currentWorkspaceTags, workspace, taskId])

  useEffect(() => {
    if (elmFor === 'workspace') {
      const usedColors = userTags.data?.map(el => el.textColor)
      let availColors = colorOptions.filter(
        color => !usedColors.includes(color)
      )
      if (availColors.length === 0) availColors = colorOptions
      setAvailableColors(availColors)
    } else {
      const usedColors = userTags.currentWorkspaceTags.map(
        item => item.textColor
      )
      let availColors = colorOptions.filter(
        color => !usedColors.includes(color)
      )
      if (availColors.length === 0) availColors = colorOptions
      setAvailableColors(availColors)
    }
  }, [userTags.data, userTags.currentWorkspaceTags])

  const handleTags = e => {
    let filterTags
    if (elmFor === 'workspace') {
      filterTags = userTags.data.filter(el => el.name.includes(e.target.value))
    } else {
      filterTags = userTags.currentWorkspaceTags?.filter(el =>
        el.name.includes(e.target.value)
      )
    }
    setVisibleTags(filterTags)
    setTagData({ name: e.target.value, new: true })
  }

  const editTagCallback = (res, err) => {
    setTagData({ name: '', new: false })
    handleClose()
  }

  const editTagHandler = (item, key) => {
    const tagsData = {
      name: item.name,
      backgroundColor: item.backgroundColor,
      textColor: item.textColor,
      new: key
    }
    if (!key) {
      tagsData._id = item._id
    }
    if (elmFor === 'task') {
      tagsData.taskId = taskId
      tagsData.type = 'workspaceLevelTag'
      tagsData.workspace = workspace._id
    } else {
      tagsData.type = 'workspaceTag'
      tagsData.workspaceId = workspace._id
    }
    dispatch(createTags({ data: tagsData }, editTagCallback))
  }

  const createTag = e => {
    if (e.key === 'Enter') {
      let tag
      if (elmFor === 'workspace') {
        tag = userTags.data.find(el => el.name === tagData.name)
      } else {
        tag = userTags.currentWorkspaceTags?.find(
          el => el.name === tagData.name
        )
      }
      if (tag) {
        editTagHandler(tag, false)
      } else {
        const index = (Math.random() * availableColors.length).toFixed()
        const data = {
          name: tagData.name,
          backgroundColor: `${availableColors[index]}15`,
          textColor: availableColors[index]
        }
        editTagHandler(data, true)
      }
    }
  }

  const renameTagHandler = () => {
    setUpdateTag(prev => ({
      ...prev,
      open: true
    }))
    setEditTagsPopoverData({ open: false, anchorEl: null })
  }

  const updateTagCallback = (res, err) => {
    setUpdateTag({ open: false, name: '', data: {} })
    if (!err) {
    }
    setEditTagsPopoverData({ open: false, anchorEl: null })
    setTagsColorPopoverData({ open: false, anchorEl: null })
  }

  const updateTagName = e => {
    if (e.key === 'Enter') {
      const data = {
        name: updatedTag.name
      }
      dispatch(
        updateTagsById({ id: updatedTag.data._id, data }, updateTagCallback)
      )
    }
  }

  const handleUpdateColor = color => {
    const data = {
      textColor: color,
      backgroundColor: `${color}15`
    }
    dispatch(
      updateTagsById({ id: updatedTag.data._id, data }, updateTagCallback)
    )
  }

  const handleDeleteTagModal = () => {
    if (deleteTagModal.open) {
      setDeleteTagModal(prev => ({
        ...prev,
        open: false,
        loading: false
      }))
    } else {
      setDeleteTagModal({
        open: true,
        data: updatedTag.data,
        loading: false
      })
    }
  }

  const deleteTagById = () => {
    setDeleteTagModal(prev => ({ ...prev, loading: true }))
    dispatch(deleteTagsById({ id: updatedTag.data._id }, deleteTagCallback))
    handleClose()
  }

  const deleteTagCallback = (res, err) => {
    setUpdateTag(prev => ({ ...prev, data: '', name: '' }))
    if (err) {
      setDeleteTagModal(prev => ({ ...prev, loading: false }))
    } else {
      handleDeleteTagModal()
    }
  }

  const handleClose = () => {
    setTagsPopoverData({ open: false, anchorEl: null })
    setEditTagsPopoverData({ open: false, anchorEl: null })
    setTagsColorPopoverData({ open: false, anchorEl: null })

    setUpdateTag(prev => ({ ...prev, open: false }))
  }

  return (
    <>
      <Popover
        open={tagsPopoverData.open}
        onClose={handleClose}
        anchorEl={tagsPopoverData.anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        classes={{ paper: classes.iconsPopoverPaper }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        style={{ zIndex: 2000, top: '-20px' }}
      >
        <AssignedTags
          assignedTag={assignedTag}
          taskId={taskId}
          workspace={workspace}
          elmFor={elmFor}
          className="flex-wrap"
        />
        <Box
          className={classes.colorsContainer}
          style={{
            borderTop: assignedTag.length > 0 ? '0.5px solid #e8ecee' : 'none',
            paddingTop: assignedTag.length > 0 ? '10px' : 0
          }}
        >
          <input
            required
            placeholder={t('SEARCH_AND_CREATE_NEW')}
            style={{ outline: 'none', border: 'none' }}
            className="pl-2 pt-1 text-sm dark:bg-dark-main3 dark:text-dark-light"
            name="tags"
            value={tagData.name}
            onChange={handleTags}
            onKeyDown={createTag}
          />
        </Box>
        <div className={classes.iconScrollableWrapper}>
          {visibleTags.map(item => (
            <>
              {updatedTag.open && updatedTag.data?._id === item?._id ? (
                <input
                  className="py-1 pl-2 border text-sm"
                  style={{ border: '1px solid black' }}
                  value={updatedTag.name}
                  autoFocus
                  onChange={e =>
                    setUpdateTag(prev => ({
                      ...prev,
                      name: e.target.value
                    }))
                  }
                  onKeyDown={updateTagName}
                />
              ) : (
                <div
                  className={`py-2 pl-2 text-sm font-medium w-full flex justify-between items-center cursor-pointer ${classes.tags}`}
                >
                  <p
                    style={{
                      color: item.textColor,
                      width: '80%',
                      textTransform: 'capitalize'
                    }}
                    onClick={() => editTagHandler(item, false)}
                  >
                    {item.name}
                  </p>
                  <DotIcon
                    onClick={e => {
                      setEditTagsPopoverData({
                        open: true,
                        anchorEl: e.currentTarget
                      })
                      setUpdateTag(prev => ({
                        ...prev,
                        name: item.name,
                        data: item
                      }))
                    }}
                    style={{ fill: item.textColor }}
                    className={`dot-icon h-4 w-4 mr-2 ${classes.dotIcon}`}
                  />
                </div>
              )}
            </>
          ))}
          <div className="text-sm p-2 text-gray-400 italic">
            {tagData.name ? t('PRESS_ENTER_TO_CREATE_A_NEW_TAG') : ''}
          </div>
        </div>
      </Popover>
      <Popover
        open={editTagsPopoverData.open}
        onClose={handleClose}
        anchorEl={editTagsPopoverData.anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        classes={{ paper: classes.iconsPopoverPaper }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        style={{ zIndex: 2000 }}
      >
        <List
          disablePadding
          dense
          style={{ minWidth: '140px', maxWidth: '250px' }}
        >
          <ListItem button onClick={handleDeleteTagModal}>
            <TrashIcon className="w-4 h-4 mr-4" />
            <p style={{ fontSize: '14px' }}>{t('DELETE')}</p>
          </ListItem>
          <ListItem button onClick={renameTagHandler}>
            <Pen className="w-4 h-4 mr-4" />
            <p style={{ fontSize: '14px' }}>{t('RENAME')}</p>
          </ListItem>
          <ListItem
            button
            onClick={e =>
              setTagsColorPopoverData(prev => ({
                ...prev,
                open: true,
                anchorEl: e.currentTarget
              }))
            }
          >
            <PaintIcon className="w-4 h-4 mr-4" />
            <p style={{ fontSize: '14px' }}>{t('CHANGE_COLOR')}</p>
          </ListItem>
        </List>
      </Popover>
      <Popover
        open={tagsColorPopoverData.open}
        onClose={handleClose}
        anchorEl={tagsColorPopoverData.anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        classes={{ paper: classes.iconsPopoverPaper }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        style={{ zIndex: 2000 }}
      >
        <Box className={classes.colorsContainer}>
          {colorOptions.map(item => (
            <label
              onClick={() => handleUpdateColor(item)}
              key={item}
              style={{ backgroundColor: item }}
              className="flex justify-center items-center"
            >
              {updatedTag.data.textColor === item && (
                <CheckIcon
                  style={{
                    color: updatedTag.data.textColor.includes('#fff')
                      ? '#000'
                      : '#fff'
                  }}
                />
              )}
            </label>
          ))}
        </Box>
      </Popover>
      <AlertModal
        heading={deleteTagModal.data?.name}
        warningText={t('WARNING_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TAG')}
        open={deleteTagModal.open}
        handleDialog={handleDeleteTagModal}
        handleDeleteAction={deleteTagById}
        loading={deleteTagModal.loading}
        style={{ zIndex: 2000 }}
      />
    </>
  )
}

export default Tags
