import React, { useRef, useState } from 'react'
import { Button } from '@material-ui/core'
import { RoundedTextInput } from 'global/globalComponents'
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { useTranslation } from 'react-i18next'
// import { useDispatch } from 'react-redux'
// import { updateIntakeFormField } from 'thunks/onboarding/actions'

const AddSelectOptionModal = ({ open, onClose, ...rest }) => {
  return (
    <CustomModal size="small" open={open} handleClose={onClose}>
      <ModalContent onClose={onClose} {...rest} />
    </CustomModal>
  )
}

const ModalContent = ({ onClose, data, updateOptions }) => {
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  const [options, setOptions] = useState([...data.options])
  const ref = useRef()

  const handleAddOption = e => {
    e.preventDefault()

    let value = ref.current.value.trim()
    if (value !== '') {
      const updatedOptions = [...options, value]

      setOptions(updatedOptions)
      updateOptions(updatedOptions)
      ref.current.value = ''
    }
  }

  const removeOption = e => {
    let index = parseInt(e.currentTarget.dataset.index)
    const filteredData = options.filter((item, idx) => idx !== index)

    setOptions(filteredData)
    updateOptions(filteredData)
    // dispatch(
    //   updateIntakeFormField({
    //     stepId: data.stepId,
    //     fieldId: data.fieldId,
    //     prop: 'options',
    //     value: filteredData
    //   })
    // )
  }

  return (
    <>
      <CustomModalHeader heading={t('ADD_OPTIONS')} handleClose={onClose} />
      <CustomModalBody>
        <form onSubmit={handleAddOption}>
          <div className="flex items-center">
            <RoundedTextInput
              radius={10}
              inputProps={{ ref }}
              className="mr-4"
              placeholder={t('TYPE_OPTION')}
            />
            <Button type="submit">{t('ADD')}</Button>
          </div>
        </form>

        <div className="py-4 h-48">
          <ul>
            {options.map((option, index) => (
              <li
                key={index}
                className="px-2 py-2 text-sm flex justify-between hover:bg-gray-100"
              >
                <span>{option}</span>
                <button onClick={removeOption} data-index={index}>
                  <CrossIcon className="w-4 h-4" />
                </button>
              </li>
            ))}
          </ul>

          <div className="h-8"></div>
        </div>
      </CustomModalBody>
    </>
  )
}

export default AddSelectOptionModal
