import { useEffect, useRef, useState } from 'react'
import { generateInvoice } from 'thunks/invoices/actions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllServices } from 'thunks/service/actions'
import { CheckBoxField, LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { toggleCreateInvoiceModal } from 'thunks/generlAppState/actions'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { t } from 'i18next'
import ProjectSelector from './ProjectSelector'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import RaiseForRadioContainer from './RaiseForRadioContainer'
import ClientSelector from './ClientSelector'
import TemplateSelector from './TemplateSelector'
import ServiceSelector from './ServiceSelector'

export default function CreateInvoiceModal({
  handleClose,
  currentClient,
  handleEditInvoice,
  handleViewInvoice
}) {
  const dispatch = useDispatch()
  const createInvoiceModal = useSelector(
    state => state.generalAppState.createInvoiceModal
  )

  const handleCloseModal = () => {
    dispatch(toggleCreateInvoiceModal())
  }

  return (
    <CustomModal open={createInvoiceModal.open} handleClose={handleCloseModal}>
      <CreateInvoiceModalContent
        handleClose={handleClose}
        handleCloseModal={handleCloseModal}
        currentClient={currentClient}
        handleEditInvoice={handleEditInvoice}
        handleViewInvoice={handleViewInvoice}
      />
    </CustomModal>
  )
}

const transformTaxAndBankDetails = dataArr => {
  const dataStr = dataArr
    .map(item => `${item.label} - ${item.value}`)
    .join('\n')
  return [dataStr]
}

const CreateInvoiceModalContent = ({
  handleClose,
  currentClient,
  handleEditInvoice,
  handleViewInvoice,
  handleCloseModal
}) => {
  const invoiceUntilOptions = [
    { label: 'I manually stop it', value: 'manual' },
    { label: 'A specific date', value: 'date' }
  ]
  const dispatch = useDispatch()
  const userClients = useSelector(state => state.userClients)
  const userServices = useSelector(state => state.userServices)
  const me = useSelector(state => state.me.data)
  const [bankingDetails, setBankingDetails] = useState([])
  const [taxDetails, setTaxDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedServices, setSelectedServices] = useState([])
  const [selectedProject, setSelectedProject] = useState({})
  const [markAsPaid, setMarkAsPaid] = useState(false)
  const [raiseFor, setRaiseFor] = useState(0)
  const [selectedInvoiceUntil, setSelectedInvoiceUntil] = useState(
    invoiceUntilOptions[0]
  )
  const [selectedInvoiceFrequency, setSelectedInvoiceFrequency] = useState({
    label: t('EVERY_WEEK'),
    value: 7
  })
  const [chosenDueDate, setChosenDueDate] = useState(new Date())
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [isScheduleInvoice, setIsScheduleInvoice] = useState(false)
  const invoiceTemplateOptions = [
    { label: `${t('TEMPLATE')}-1`, value: 1 },
    { label: `${t('TEMPLATE')}-2`, value: 2 }
  ]
  const [selectedInvoiceTemplate, setSelectedInvoiceTemplate] = useState(
    invoiceTemplateOptions[0]
  )
  const raiseForProjectRef = useRef()

  useEffect(() => {
    if (me._id) {
      setBankingDetails(
        me.profile?.bankDetail ?? me.team?.profile?.bankDetail ?? []
      )
      setTaxDetails(me.profile?.taxDetail ?? me.team?.profile?.taxDetail ?? [])
    }
  }, [me])

  useEffect(() => {
    if (currentClient) {
      setSelectedClient({
        label: `${currentClient.name} (${currentClient.email?.toLowerCase()})`,
        value: currentClient._id,
        ...currentClient
      })
    }
  }, [currentClient])

  useEffect(() => {
    if (!userServices.fetched) {
      dispatch(fetchAllServices())
    }
  }, [userServices.fetched])

  useEffect(() => {
    if (typeof currentClient === 'object') {
      setSelectedClient(currentClient)
    }
  }, [currentClient])

  const getInvoiceData = ({ type }) => {
    const invoiceItems = []

    if (type === 'service') {
      selectedServices.forEach(selectedService => {
        invoiceItems.push({
          ...selectedService,
          quantity: 1,
          rate: selectedService.amount * 0.01
        })

        userServices.addOns.data.forEach(addOn => {
          if (addOn.services.includes(selectedService._id)) {
            invoiceItems.push({
              _id: addOn._id,
              name: addOn.name,
              quantity: 1,
              rate: addOn.amount * 0.01,
              description: addOn.description,
              type: 'addon'
            })
          }
        })
      })
    }

    let data = {
      invoiceTemplate: selectedInvoiceTemplate.value,
      taxDetail: transformTaxAndBankDetails(taxDetails),
      bankDetail: transformTaxAndBankDetails(bankingDetails),
      invoiceItems: invoiceItems,
      type
    }

    if (selectedClient?._id) {
      data.client = {
        clientName: selectedClient.name,
        clientEmail: selectedClient.email,
        clientId: selectedClient._id,
        clientCompanyName: selectedClient.company
          ? selectedClient.company.companyLegalName
          : '',
        clientAddress: getCompanyAddress(selectedClient.company)
      }
    }

    if (markAsPaid) {
      data.status = 'paid'
    }

    return data
  }

  const generateCustomInvoice = () => {
    let data = {
      ...getInvoiceData({ type: 'custom' }),
      autoRepeat: false,
      dueDate: '',
      autoRepeatFrequency: 30,
      invoiceItems: [],
      type: 'custom',
      nextInvoice: null
    }

    setLoading(true)
    dispatch(generateInvoice({ data }, invoiceCallback))
  }

  const generateServiceInvoice = () => {
    const now = new Date()
    const days =
      new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate() || 30

    const intervalDays = getInvoiceIntervalDaysByService(selectedServices[0])

    let data = {
      ...getInvoiceData({ type: 'service' }),
      autoRepeat: Boolean(selectedServices[0].isRecurring),
      dueDate: new Date(now.setDate(now.getDate() + days)).toISOString(), //will change it soon,
      autoRepeatFrequency: intervalDays,
      nextInvoice: selectedServices[0].isRecurring
        ? new Date(
            new Date().setDate(new Date().getDate() + intervalDays)
          ).toISOString()
        : null
    }

    setLoading(true)
    dispatch(generateInvoice({ data }, invoiceCallback))
  }

  const generateProjectInvoice = () => {
    const dueDate =
      selectedInvoiceUntil.value === 'date'
        ? new Date(chosenDueDate).toISOString()
        : addDaysToCurrentDate(30)

    const data = {
      ...getInvoiceData({ type: 'workspace' }),
      autoRepeat:
        raiseForProjectRef.current.selectedInvoiceType === 'recurring',
      dueDate,
      autoRepeatFrequency: selectedInvoiceFrequency.value,
      workspace: selectedProject.value,
      workspaceName: selectedProject.data.name,
      nextInvoice:
        raiseForProjectRef.current.selectedInvoiceType === 'recurring'
          ? new Date(
              new Date().setDate(
                new Date().getDate() + selectedInvoiceFrequency.value
              )
            ).toISOString()
          : null
    }

    setLoading(true)
    dispatch(generateInvoice({ data }, invoiceCallback))
  }

  const handleScheduleInvoice = () => {
    setLoading(true)
    dispatch(
      updateWorkSpace1(
        selectedProject.value,
        {
          automaticInvoice: true,
          invoiceCycle: selectedInvoiceFrequency.value
        },
        (res, err) => {
          setLoading(false)
          if (err) dispatch(fireErrorToaster(res))
          else {
            handleClose()
            dispatch(fireSuccessToaster(t('INVOICE_SCHEDULED_SUCCESSFULLY')))
          }
        }
      )
    )
  }

  const invoiceCallback = (result, err) => {
    setLoading(false)

    if (!err) {
      handleCloseModal()
      dispatch(fireSuccessToaster(t('INVOICE_GENERATED')))
      if (result.status !== 'draft') {
        handleViewInvoice(result)
      } else {
        handleEditInvoice(result)
      }
    } else {
      dispatch(fireErrorToaster(result))
    }
  }

  const handleMarkAsPaid = () => {
    if (markAsPaid) {
      setMarkAsPaid(false)
    } else {
      setMarkAsPaid(true)
      setAlertModalOpen(true)
    }
  }

  return (
    <>
      <CustomModalHeader
        heading={t('CREATE_INVOICE')}
        handleClose={handleCloseModal}
      />
      <CustomModalBody>
        <div className="h-96">
          <RaiseForRadioContainer
            raiseFor={raiseFor}
            setRaiseFor={setRaiseFor}
          />
          <ClientSelector
            userClients={userClients}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />

          {raiseFor === 1 ? (
            <div className="mb-3">
              <ServiceSelector
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
            </div>
          ) : raiseFor === 2 ? (
            <div className="mb-3">
              <ProjectSelector
                ref={raiseForProjectRef}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                selectedClient={selectedClient}
                selectedInvoiceUntil={selectedInvoiceUntil}
                setSelectedInvoiceUntil={setSelectedInvoiceUntil}
                selectedInvoiceFrequency={selectedInvoiceFrequency}
                setSelectedInvoiceFrequency={setSelectedInvoiceFrequency}
                chosenDueDate={chosenDueDate}
                setChosenDueDate={setChosenDueDate}
                setScheduleInvoice={setIsScheduleInvoice}
              />
            </div>
          ) : null}

          <div className="mb-3">
            <TemplateSelector
              invoiceTemplateOptions={invoiceTemplateOptions}
              selectedInvoiceTemplate={selectedInvoiceTemplate}
              setSelectedInvoiceTemplate={setSelectedInvoiceTemplate}
            />
          </div>

          {Boolean(selectedServices.length) && (
            <div>
              <FormLabel>{t('PAYMENT')}</FormLabel>
              <CheckBoxField
                label={t('MARK_AS_PAID')}
                checked={markAsPaid}
                onChange={handleMarkAsPaid}
              />
            </div>
          )}
        </div>
      </CustomModalBody>
      <CustomModalFooter className="items-center">
        {isScheduleInvoice ? (
          <LoadingButton
            size="large"
            onClick={handleScheduleInvoice}
            loading={loading}
            disabled={!selectedProject.value}
          >
            {t('SCHEDULE_INVOICE')}
          </LoadingButton>
        ) : (
          <LoadingButton
            size="large"
            onClick={
              raiseFor === 2
                ? generateProjectInvoice
                : raiseFor === 1
                ? generateServiceInvoice
                : generateCustomInvoice
            }
            loading={loading}
            disabled={raiseFor === 2 && !selectedProject.value ? true : false}
          >
            {t('CREATE_INVOICE')}
          </LoadingButton>
        )}
      </CustomModalFooter>
      <AlertModal
        open={alertModalOpen}
        handleDialog={() => setAlertModalOpen(false)}
        heading={t('ALERT')}
        warningText={t('WARNING_MARK_AS_PAID')}
        cancelText={t('OK')}
        deleteBtnText=""
      />
    </>
  )
}

const getCompanyAddress = clientCompany => {
  if (!clientCompany) return ''
  const { line1, line2, city, state, country, zipCode } = clientCompany.address

  return `${line1 ? `${line1}, ` : ''}${line2 ? `${line2}, ` : ''}${
    city ? `${city}, ` : ''
  }${state ? `${state}, ` : ''}${country.label ? `${country.label}, ` : ''}${
    zipCode ? zipCode : ''
  }`
}

const getInvoiceIntervalDaysByService = service => {
  let days = 30
  if (service.isRecurring) {
    if (service.recurring.interval === 'month') {
      days = Math.round((365 * service.recurring.interval_count) / 12)
    } else {
      days = 365
    }
  }
  return days
}

function addDaysToCurrentDate(number) {
  const newDate = new Date()
  return new Date(newDate.setDate(newDate.getDate() + number)).toISOString()
}
