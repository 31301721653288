import {
  ColoredAvatarGroup,
  ColoredAvatars,
  CustomFlag,
  CustomTooltip
} from 'global/globalComponents'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import { adjustColor, getFileType, getRoleBadge } from 'utils'
import AsanaIcons from 'static/asana/AsanaIcons'
import { ReactComponent as AudioFile } from 'static/svg/music-note.svg'
import { ReactComponent as VideoFile } from 'static/svg/film-reel.svg'
import { ReactComponent as NoteText } from 'static/svg/note-text.svg'
import { ReactComponent as CheckIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import clsx from 'clsx'
import { t } from 'i18next'
import { ReactComponent as ArrowRight } from 'static/svg/arrow-right.svg'
import { ReactComponent as Vector2 } from 'static/svg/vector-2.svg'
import moment from 'moment'

// ========= Common Notification Components Starts =========
const getTextElm = value => (
  <span className="text-smaller font-medium capitalize">{value}</span>
)

// note: color must be in hex format ex: #ffffff
const getPillElm = ({ label, color }) => (
  <Pill
    style={{
      color: adjustColor(color, -70),
      backgroundColor: color + '20'
    }}
  >
    {label}
  </Pill>
)

const getDateElm = value => (
  <span className="text-smaller font-medium">
    {value
      ? new Date(value).toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
      : 'None'}
  </span>
)

const getAvatarElm = user => (
  <ColoredAvatars user={user || {}} tooltip={true} size="medium" />
)

const getAvatarGroupElm = users => (
  <ColoredAvatarGroup users={users} tooltip={true} size="medium" />
)

const NotificationTemplate = ({
  user,
  image, //optional
  action,
  beforeElm,
  afterElm,
  createdAt,
  isSubtask
}) => {
  return (
    <div className="flex flex-wrap items-center gap-4 py-3 px-4 border-t dark:border-dark-main">
      {/* ==== User === */}
      <div className="flex items-center">
        {isSubtask && (
          <div className="mr-2">
            <CustomTooltip title="Subtask">
              <Vector2 />
            </CustomTooltip>
          </div>
        )}

        {user ? (
          <>
            <ColoredAvatars size="normal" user={user || {}} />
            <span className="ml-2 text-smaller dark:text-dark-light2">
              {user?.name ?? 'Deleted user'}
            </span>
          </>
        ) : (
          <div className="w-8 h-8">{image}</div>
        )}
      </div>

      {/* 2. Action taken */}
      {action && (
        <span className="uppercase inline-block px-3 py-1 rounded-full border border-gray-300 text-xs font-medium text-primary-main dark:bg-dark-main dark:border-dark-main dark:text-dark-light2">
          {action}
        </span>
      )}

      <div className="flex items-center gap-2 dark:text-dark-light2">
        {beforeElm}
        {beforeElm && afterElm && <ArrowRight />}
        {afterElm}
      </div>

      <span className="ml-auto text-xs text-gray-500 dark:text-gray-400">
        {moment(createdAt).format('MMM Do, h:mm a')}
      </span>
    </div>
  )
}

// ========= Common Notification Components Ends =========

// ========= Task and Subtask =========
export const generateTaskNotification = ({
  category,
  user,
  field,
  before,
  after,
  createdAt
}) => {
  const fieldKeywords = {
    create: t('CREATED'),
    title: t('CHANGED_TITLE'),
    startDate: t('CHANGED_START_DATE'),
    dueDate: t('CHANGED_DUE_DATE'),
    assignee_add: t('ASSIGNED_TO'),
    description: t('CHANGED_DESCRIPTION'),
    priority: t('CHANGED_PRIORITY'),
    attachment_add: t('ADDED_ATTACHMENT'),
    attachment_delete: t('DELETED_ATTACHMENT'),
    isTaskRecurring:
      before && after
        ? t('CHANGED_RECURRING_DURATION')
        : t('CHANGED_RECURRING_MODE'),
    estimateTimeInString: t('CHANGED_ESTIMATE_TIME'),
    taskSection: t('CHANGED_STATUS'),
    markAsComplete: t('MARKED_AS'),
    assignee_delete: t('REMOVED'),
    delete: t('DELETED_SUBTASK'),
    comment_like: t('LIKED_YOUR_COMMENT'),
    collaborators_add: t('ADDED_COLLABORATOR')
  }

  const getFileElm = fileUrl => {
    const fileType = getFileType(fileUrl)
    if (fileType === 'image')
      return <img src={fileUrl} alt="" className="w-8 h-8" />
    if (fileType === 'audio')
      return <AudioFile className="text-primary-main w-5 h-5" />
    if (fileType === 'video')
      return <VideoFile className="text-primary-main w-5 h-5" />
    return <NoteText className="text-primary-main w-5 h-5" />
  }

  let beforeElm = null,
    afterElm = null

  switch (field) {
    case 'create':
      afterElm = getTextElm(after)
      break

    case 'title':
      beforeElm = getTextElm(before)
      afterElm = getTextElm(after)
      break

    case 'startDate':
    case 'dueDate':
      beforeElm = getDateElm(before)
      afterElm = getDateElm(after)
      break

    case 'attachment_add':
      afterElm = getFileElm(after)
      break

    case 'collaborators_add':
      // after => [{user: {<data>}, role: "role"}]
      afterElm = getAvatarGroupElm(after.map(item => item.user))
      break

    case 'assignee_delete':
      afterElm = getAvatarElm(after)
      break

    case 'priority':
      beforeElm =
        before === 5 ? (
          getTextElm('None')
        ) : (
          <CustomFlag priority={Number(before)} />
        )
      afterElm =
        after === 5 ? (
          getTextElm('None')
        ) : (
          <CustomFlag priority={Number(after)} />
        )
      break

    case 'isTaskRecurring':
      if (before !== after) {
        beforeElm = getTextElm(before ? 'On' : 'Off')
        afterElm = getTextElm(after ? 'On' : 'Off')
      }
      break

    case 'taskSection':
      beforeElm = getPillElm({ label: before.label, color: before.color })
      afterElm = getPillElm({ label: after.label, color: after.color })
      break

    case 'markAsComplete':
      afterElm = getTextElm(after ? 'Complete' : 'In Complete')
      break

    default:
      beforeElm = null
      afterElm = null
  }

  return (
    <NotificationTemplate
      user={user}
      action={fieldKeywords[field]}
      beforeElm={beforeElm}
      afterElm={afterElm}
      createdAt={createdAt}
      isSubtask={category === 'subTask'}
    />
  )
}

// ========= Workspace =========
export const generateWorkspaceNotification = ({
  user,
  field,
  before,
  after,
  createdAt
}) => {
  const fieldKeywords = {
    assignee_delete: t('REMOVED'),
    create: t('CREATED'),
    assignee_add: t('ASSIGNED_TO'),
    category: t('CHANGED_CATEGORY'),
    update: t('UPDATED_WORKSPACE'),
    image: t('CHANGED_IMAGE'),
    backgroundcolor: t('CHANGED_BACKGROUND'),
    role_update: t('CHANGED_ROLE'),
    workspace_archived: t('MARKED_AS') //language
  }

  const getAsanaImageElm = value => (
    <span className="bg-primary-light inline-block p-1.5 rounded">
      {AsanaIcons[value]({
        height: '24px',
        width: '24px',
        className: 'selected-icon'
      })}
    </span>
  )

  const getBGColorBox = value => (
    <span
      className="inline-block w-8 h-8 rounded border"
      style={{ backgroundColor: value }}
    ></span>
  )

  const getCategoryPill = value => (
    <Pill variant={value.toLowerCase() === 'internal' ? 'blue' : 'pink'}>
      {t(`${value.toUpperCase()}`)}
    </Pill>
  )

  let beforeElm = null,
    afterElm = null

  switch (field) {
    case 'create':
      afterElm = getTextElm(after)
      break

    case 'assignee_add':
      afterElm = getAvatarGroupElm(after.map(item => item.user))
      break

    case 'assignee_delete':
      afterElm = getAvatarElm(after)
      break

    case 'image':
      beforeElm = before ? getAsanaImageElm(before) : null
      afterElm = after ? getAsanaImageElm(after) : null
      break

    case 'backgroundcolor':
      beforeElm = before ? getBGColorBox(before) : null
      afterElm = after ? getBGColorBox(after) : null
      break

    case 'category':
      beforeElm = before ? getCategoryPill(before) : null
      afterElm = after ? getCategoryPill(after) : null
      break

    case 'role_update':
      beforeElm = (
        <div className="flex items-center">
          <span className="text-smaller font-medium">of</span>
          <div className="ml-2 mr-4">
            <ColoredAvatars user={before.user || {}} />
          </div>
          <span>{before?.role && getRoleBadge(before.role)}</span>
        </div>
      )
      afterElm = <span>{after?.role && getRoleBadge(after.role)}</span>
      break

    case 'workspace_archived':
      beforeElm = getTextElm(before ? 'archived' : 'unarchived')
      afterElm = getTextElm(after ? 'archived' : 'unarchived')
      break

    default:
  }

  return (
    <NotificationTemplate
      user={user}
      action={fieldKeywords[field]}
      beforeElm={beforeElm}
      afterElm={afterElm}
      createdAt={createdAt}
    />
  )
}

// ========= Workspace board and sections  =========
export const generateOtherWorkspaceNotification = ({
  user,
  field,
  category,
  before,
  after,
  createdAt
}) => {
  const fieldKeywords = {
    workspaceView_create: t('CREATED_BOARD'),
    workspaceSection_create: t('CREATED_SECTION'),
    workspaceView_update_section_order: t('CHANGED_SECTION_ORDER'),
    workspaceView_name: t('CHANGED_NAME'),
    workspaceView_delete: t('DELETED_BOARD'),
    workspaceSection_label: t('CHANGED_SECTION_HEADING'),
    workspaceSection_delete: t('DELETED_SECTION')
  }

  let beforeElm = null,
    afterElm = null

  switch (field) {
    case 'create':
    case 'delete':
      afterElm = getTextElm(after)
      break

    case 'name':
    case 'label':
      beforeElm = before ? getTextElm(before) : null
      afterElm = after ? getTextElm(after) : null
      break

    default:
  }

  return (
    <NotificationTemplate
      user={user}
      action={fieldKeywords[`${category}_${field}`]}
      beforeElm={beforeElm}
      afterElm={afterElm}
      createdAt={createdAt}
    />
  )
}

// ========= Invoice =========
export const generateInvoiceNotification = ({
  user,
  field,
  before,
  after,
  createdAt
}) => {
  const fieldKeywords = {
    create: t('CREATED'),
    reminderSend: t('SENT_REMINDER'),
    update: t('UPDATED_PROPERTIES'),
    status: t('CHANGED_STATUS'),
    paid: user?.role === 30 ? t('PAID') : t('MARKED_AS')
  }

  let beforeElm = null,
    afterElm = null

  if (field === 'create') {
    if (user?.role === 30) {
      afterElm = (
        <div className="flex items-center space-x-2">
          <span>{getTextElm(t('AN_INVOICE_GENERATED_AGAINST'))}</span>
          <ColoredAvatars user={{ name: after.client.clientName }} />
        </div>
      )
    } else {
      afterElm = (
        <div className="flex items-center space-x-4">
          <span className="text-smaller font-medium inline-block px-2 py-1 border rounded">
            {after.customInvoiceNumber || after.invoiceNumber}
          </span>
          <span className="text-smaller font-medium">For</span>
          <ColoredAvatars user={{ name: after.client.clientName }} />
        </div>
      )
    }
  } else if (field === 'status') {
    beforeElm = before ? (
      <Pill variant={pillVariantObj[before.status]}>
        {t(before.status?.toUpperCase())}
      </Pill>
    ) : null

    afterElm = after ? (
      <Pill variant={pillVariantObj[after.status]}>
        {t(after.status?.toUpperCase())}
      </Pill>
    ) : null
  } else if (field === 'paid') {
    if (user?.role === 30) {
      afterElm = getTextElm(t('CLIENT_COMPLETED_INVOICE_PAYMENT_NOFICATION'))
    } else {
      afterElm = <Pill variant="green">{t('PAID')}</Pill>
    }
  }

  return (
    <NotificationTemplate
      user={user?.role !== 30 && user}
      image={
        user?.role === 30 && (
          <CheckIcon className="w-full h-full text-green-600" />
        )
      }
      action={user?.role !== 30 && fieldKeywords[field]}
      beforeElm={beforeElm}
      afterElm={afterElm}
      createdAt={createdAt}
    />
  )
}

// ========= Service =========
export const generateServiceNotification = ({
  user,
  field,
  before,
  after,
  createdAt
}) => {
  const fieldKeywords = {
    create: t('CREATED'),
    update: t('UPDATED_PROPERTIES'),
    displayOnCatalog: t('SWITCHED_DISPLAY_CATALOG')
  }

  let beforeElm = null,
    afterElm = null

  switch (field) {
    case 'create':
      afterElm = getTextElm(after)
      break

    case 'displayOnCatalog':
      beforeElm = (
        <span className="text-smaller font-medium">
          {before ? 'On' : 'Off'}
        </span>
      )
      afterElm = (
        <span className="text-smaller font-medium">{after ? 'On' : 'Off'}</span>
      )
      break

    default:
  }

  return (
    <NotificationTemplate
      user={user}
      action={fieldKeywords[field]}
      beforeElm={beforeElm}
      afterElm={afterElm}
      createdAt={createdAt}
    />
  )
}

// ========= Agency & Client teammates  =========
export const generateAgencyClientTeammateNotification = ({
  user,
  field,
  before,
  after,
  createdAt,
  category
}) => {
  const fieldKeywords = {
    client_update: t('UPDATED_PROPERTIES'),
    agencyTeammate_update: t('UPDATED_PROPERTIES'),
    agencyTeammate_add: t('ADDED_NEW_TEAM_MEMBER'),
    client_add: t('ADDED_NEW_CLIENT'),
    client_create: t('ADDED_NEW_CLIENT'),
    clientTeammate_add: t('ADDED_NEW_CLIENT_TEAM_MEMBER')
  }

  let beforeElm = null,
    afterElm = null

  if (category === 'agencyTeammate' && field === 'add')
    afterElm = getAvatarElm(after)

  return (
    <NotificationTemplate
      user={
        ['client_add', 'client_create', 'clientTeammate_add'].includes(
          `${category}_${field}`
        )
          ? after
          : user
      }
      action={fieldKeywords[`${category}_${field}`]}
      beforeElm={beforeElm}
      afterElm={afterElm}
      createdAt={createdAt}
    />
  )
}

// ========= Proposal =========
export const generateProposalNotification = ({
  user,
  field,
  before,
  after,
  createdAt,
  category
}) => {
  const fieldKeywords = {
    create: t('CREATED'),
    client_signed: t('SIGNED'),
    agency_signed: t('SIGNED')
  }

  let beforeElm = null,
    afterElm = null

  switch (field) {
    case 'create':
      afterElm = getTextElm(after)
      break

    case 'client_signed':
    case 'agency_signed':
      afterElm = getTextElm(t('THIS_PROPOSAL'))
      break
    default:
  }

  return (
    <NotificationTemplate
      user={user}
      action={fieldKeywords[field]}
      beforeElm={beforeElm}
      afterElm={afterElm}
      createdAt={createdAt}
    />
  )
}

// ========= Proposal =========
export const generateApprovalNotification = ({
  user,
  field,
  before,
  after,
  createdAt,
  category,
  approval
}) => {
  if (after?.members) {
    after.members.find(item => item.user?._id)
  }

  const fieldKeywords = {
    approval_requested: t('REQUESTED_YOUR_APPROVAL'),
    approval_member_status_change:
      after === 'approved' ? t('APPROVED') : t('REJECTED')
  }

  let beforeElm = null,
    afterElm = null

  switch (field) {
    case 'approval_requested':
      afterElm = (
        <div className="flex items-center space-x-3">
          {getTextElm(t('FOR'))}{' '}
          {getPillElm({ label: approval?.name, color: '#0000ff' })}
        </div>
      )
      break

    case 'approval_member_status_change':
      // afterElm = getPillElm({
      //   label: approval.name,
      //   color: after === 'approved' ? '#00ff00' : '#ff0000'
      // })
      afterElm = getTextElm(t('THIS_APPROVAL'))
      break

    case 'approval_status':
      afterElm = (
        <div
          className={clsx(
            'text-sm font-medium',
            after === 'approved' ? 'text-green-600' : 'text-red-600'
          )}
        >
          {after === 'approved'
            ? t('APPROVAL_APPROVED_NOTIFICATION_MESSAGE')
            : t('APPROVAL_REJECTED_NOTIFICATION_MESSAGE')}
        </div>
      )
      break
    default:
  }

  return (
    <NotificationTemplate
      user={field !== 'approval_status' && user}
      action={fieldKeywords[field]}
      beforeElm={beforeElm}
      afterElm={afterElm}
      createdAt={createdAt}
      image={
        field === 'approval_status' ? (
          after === 'approved' ? (
            <CheckIcon className="w-full h-full text-green-600" />
          ) : (
            <CrossIcon className="w-full h-full text-red-600" />
          )
        ) : null
      }
    />
  )
}

/* ======================
  The functions below are not being used in the Notification section.
  However, right now, we can't remove them because they are being used in other areas of the product.
================ */

export const getKeywords = () => ({
  task_create: t('CREATED'),
  task_title: t('CHANGED_TITLE'),
  task_startDate: t('CHANGED_START_DATE'),
  task_dueDate: t('CHANGED_DUE_DATE'),
  task_assignee_add: t('ASSIGNED_TO'),
  task_description: t('CHANGED_DESCRIPTION'),
  task_priority: t('CHANGED_PRIORITY'),
  task_attachment_add: t('ADDED_ATTACHMENT'),
  task_attachment_delete: t('DELETED_ATTACHMENT'),
  task_isTaskRecurring: t('CHANGED_RECURRING_MODE'),
  task_estimateTimeInString: t('CHANGED_ESTIMATE_TIME'),
  task_taskSection: t('CHANGED_STATE'),
  task_markAsComplete: t('CHANGED_STATE'),
  task_assignee_delete: t('REMOVED'),
  task_delete: t('DELETED_TASK'),
  task_comment_like: t('LIKED_YOUR_COMMENT'),
  task_collaborators_add: t('ADDED_COLLABORATOR'),

  // ======= Subtask ==========
  subTask_create: t('CREATED'),
  subTask_title: t('CHANGED_TITLE'),
  subTask_startDate: t('CHANGED_START_DATE'),
  subTask_dueDate: t('CHANGED_DUE_DATE'),
  subTask_assignee_add: t('ASSIGNED_TO'),
  subTask_attachment_delete: 'Deleted Attachment',
  subTask_description: t('CHANGED_DESCRIPTION'),
  subTask_priority: t('CHANGED_PRIORITY'),
  subTask_attachment_add: t('ADDED_ATTACHMENT'),
  subTask_isTaskRecurring: t('CHANGED_RECURRING_MODE'),
  subTask_estimateTimeInString: t('CHANGED_ESTIMATE_TIME'),
  subTask_taskSection: t('CHANGED_STATE'),
  subTask_markAsComplete: t('CHANGED_COMPLETE_STATUS'),
  subTask_assignee_delete: t('REMOVED'),
  subTask_delete: t('DELETED_SUBTASK'),
  subTask_comment_like: t('LIKED_YOUR_COMMENT'),
  subTask_collaborators_add: t('ADDED_COLLABORATOR'),

  // ======== Workspace =========
  workspace_assignee_delete: t('REMOVED'),
  workspace_create: t('CREATED'),
  workspaceView_create: t('CREATED_BOARD'),
  workspaceSection_create: t('CREATED_SECTION'),
  workspace_assignee_add: t('ASSIGNED_TO'),
  workspace_category: t('CHANGED_CATEGORY'),
  workspace_update: t('UPDATED_WORKSPACE'),
  workspace_image: t('CHANGED_IMAGE'),
  workspace_backgroundcolor: t('CHANGED_BACKGROUND'),
  workspace_role_update: t('CHANGED_ROLE'),
  workspaceView_update_section_order: t('CHANGED_SECTION_ORDER'),
  workspaceView_name: t('CHANGED_NAME'),
  workspaceView_delete: t('DELETED_BOARD'),
  workspaceSection_label: t('CHANGED_SECTION_HEADING'),
  workspaceSection_delete: t('DELETED_SECTION'),

  // ======== Teammates =====
  client_update: t('UPDATED_PROPERTIES'),
  agencyTeammate_update: t('UPDATED_PROPERTIES'),
  agencyTeammate_add: t('ADDED_NEW_TEAM_MEMBER'),
  client_add: t('ADDED_NEW_CLIENT'),
  client_create: t('ADDED_NEW_CLIENT'),
  clientTeammate_add: t('ADDED_NEW_CLIENT_TEAM_MEMBER'),

  // ======= Invoice =======
  invoice_create: t('CREATED'),
  invoice_reminderSend: t('SENT_REMINDER'),
  invoice_update: t('UPDATED_PROPERTIES'),
  invoice_status: t('CHANGED_STATUS'),

  // ======= Service =======
  service_create: t('CREATED'),
  service_update: t('UPDATED_PROPERTIES'),
  service_displayOnCatalog: t('SWITCHED_DISPLAY_CATALOG'),

  // ======== Proposal ========
  proposal_create: t('CREATED'),
  proposal_client_signed: t('SIGNED'),
  proposal_agency_signed: t('SIGNED')
})

export const getElmType = (category, field) => {
  if (category === 'task' || category === 'subTask') {
    if (
      field === 'create' ||
      field === 'title' ||
      field === 'estimateTimeInString' ||
      field === 'delete'
    )
      return 'string'

    if (field === 'startDate' || field === 'dueDate') return 'date'
    if (field === 'assignee_add' || field === 'assignee_delete') {
      return 'avatar'
    }
    if (field === 'priority') return 'flag'
    if (field === 'taskSection') return 'statusPill'
    if (field === 'attachment_add') return 'attachment'
    if (field === 'markAsComplete') return 'completeStatus'
    if (field === 'isTaskRecurring') return 'switch'
    if (field === 'collaborators_add') return 'collaboratorsGroup'
    return false
  }

  if (category === 'workspace') {
    if (field === 'create') return 'string'
    if (field === 'assignee_add') return 'avatarGroup'
    if (field === 'assignee_delete') return 'avatar'
    if (field === 'image') return 'asanaImage'
    if (field === 'backgroundcolor') return 'colorBox'
    if (field === 'category') return 'categoryPill'
    if (field === 'role_update') return 'rolePill'
  }

  if (category === 'workspaceView') {
    if (field === 'create' || field === 'name' || field === 'delete')
      return 'string'
  }

  if (category === 'workspaceSection') {
    if (field === 'label' || field === 'create' || field === 'delete')
      return 'string'
  }

  if (category === 'service') {
    if (field === 'displayOnCatalog') return 'switch'
  }

  if (category === 'invoice') {
    if (field === 'create') return 'invoiceInfo'
    if (field === 'status') return 'invoiceStatusPill'
  }

  if (category === 'agencyTeammate') {
    if (field === 'add') return 'avatar'
  }

  if (category === 'proposal') {
    if (field === 'create') return 'string'
    if (field === 'client_signed' || field === 'agency_signed')
      return 'proposalSignedMessage'
  }
}

export const getBeforeAfterElm = (elmType, value, dir) => {
  if (elmType === 'string')
    return (
      <span className="text-smaller font-medium capitalize">
        {!value ? 'None' : value}
      </span>
    )
  if (elmType === 'date')
    return (
      <span className="text-smaller font-medium">
        {value
          ? new Date(value).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })
          : 'None'}
      </span>
    )
  if (elmType === 'avatar') {
    if (!value) return null
    return <ColoredAvatars user={value || {}} tooltip={true} size="medium" />
  }
  if (elmType === 'flag') {
    if (value == 5)
      return <span className="text-smaller font-medium">None</span>
    return <CustomFlag priority={Number(value)} />
  }
  if (elmType === 'statusPill') {
    return (
      <Pill
        style={{
          color: adjustColor(value.color, -70),
          backgroundColor: value.color + '20'
        }}
      >
        {value.label}
      </Pill>
    )
  }
  if (elmType === 'avatarGroup') {
    if (!value) return null
    return (
      <ColoredAvatarGroup
        users={value.map(item => item.user)}
        tooltip={true}
        size="medium"
      />
    )
  }
  if (elmType === 'collaboratorsGroup') {
    if (!value) return null
    return (
      <ColoredAvatarGroup
        users={value.map(item => item)}
        tooltip={true}
        size="medium"
      />
    )
  }
  if (elmType === 'asanaImage') {
    if (!value) return null
    return (
      <span className="bg-primary-light inline-block p-1.5 rounded">
        {AsanaIcons[value]({
          height: '24px',
          width: '24px',
          className: 'selected-icon'
        })}
      </span>
    )
  }
  if (elmType === 'colorBox') {
    return (
      <span
        className="inline-block w-8 h-8 rounded border"
        style={{ backgroundColor: value }}
      ></span>
    )
  }
  if (elmType === 'switch') {
    return (
      <span className="text-smaller font-medium">{value ? 'On' : 'Off'}</span>
    )
  }
  if (elmType === 'invoiceInfo') {
    return (
      <div className="flex items-center space-x-4">
        <span className="text-smaller font-medium inline-block px-2 py-1 border rounded">
          {value.customInvoiceNumber || value.invoiceNumber}
        </span>
        <span className="text-smaller font-medium">For</span>
        <ColoredAvatars user={{ name: value.client.clientName }} />
      </div>
    )
  }

  if (elmType === 'invoiceStatusPill') {
    return (
      <Pill variant={pillVariantObj[value.status]}>
        {t(value.status?.toUpperCase())}
      </Pill>
    )
  }
  if (elmType === 'attachment') {
    const fileType = getFileType(value)
    if (fileType === 'image')
      return <img src={value} alt="" className="w-8 h-8" />
    if (fileType === 'audio')
      return <AudioFile className="text-primary-main w-5 h-5" />
    if (fileType === 'video')
      return <VideoFile className="text-primary-main w-5 h-5" />
    return <NoteText className="text-primary-main w-5 h-5" />
  }

  if (elmType === 'completeStatus')
    return (
      <span
        className={clsx(
          'text-xs font-medium inline-block border rounded px-2 py-1 text-gray-600',
          value ? 'bg-green-100' : 'bg-gray-100'
        )}
      >
        {value ? 'Complete' : 'In Complete'}
      </span>
    )

  if (elmType === 'categoryPill') {
    if (!value) return null
    return (
      <Pill variant={value.toLowerCase() === 'internal' ? 'blue' : 'pink'}>
        {t(`${value.toUpperCase()}`)}
      </Pill>
    )
  }

  if (elmType === 'rolePill') {
    if (!value?.user?._id) return null

    if (dir === 'before') {
      return (
        <div className="flex items-center">
          <span className="text-smaller font-medium">of</span>
          <div className="ml-2 mr-4">
            <ColoredAvatars user={value.user} />
          </div>
          <span>{getRoleBadge(value.role)}</span>
        </div>
      )
    }
    return <span>{getRoleBadge(value.role)}</span>
  }

  if (elmType === 'proposalSignedMessage' && dir === 'after') {
    return <span className="text-smaller font-medium">this proposal</span>
  }
  return null
}
