import React from 'react'
import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch.svg'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import { PopupMenu } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemText } from '@material-ui/core'
import { useStyles } from 'global/globalComponents/PopupMenu/PopupMenu'

const TimerPopupOptions = ({
  toggleTimerModal,
  toggleLogModal,
  toggleRecording
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <PopupMenu
      targetComp={
        <ListItem
          // onClick={handleClick}
          // classes={{ gutters: classes.listItem }}
          button
        >
          <div className="flex items-center py-1">
            <span className="mr-2 dark:text-dark-light text-primary-main">
              <button>
                <StopWatchIcon />
              </button>
            </span>

            <ListItemText
              primary={
                <p className="capitalize text-xs 2xl:text-sm dark:text-dark-light text-primary-main">
                  {t('TIMER')}
                </p>
              }
              classes={{ root: classes.listItemText }}
            />
          </div>
        </ListItem>
      }
      menuItems={[
        {
          label: t('START_TIMER'),
          icon: <StopWatchIcon className="dark:text-dark-light" />,
          method: toggleTimerModal
        },
        {
          label: t('LOG_TIME'),
          icon: <PlusIcon className="dark:text-dark-light" />,
          method: toggleLogModal
        }
        // {
        //   label: 'Start Recording',
        //   method: toggleRecording
        // }
      ]}
      placement="left"
    />
  )
}

export default TimerPopupOptions
