export const VERTICALS = {
  LOADING: 'VERTICALS_LOADING',
  FETCHED: 'ALL_VERTICALS_FETCHED',
  ADD_ONE_VERTICAL: 'ADD_ONE_VERTICAL',
  DELETE_ONE_VERTICAL: 'DELETE_ONE_VERTICAL',
  ERROR: 'VERTICALS_ERROR',
  UPDATE_ONE_VERTICAL: 'UPDATE_ONE_VERTICAL'
}

const initialState = {
  error: false,
  loading: false,
  data: [],
  fetched: false
}

export const verticalsReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case VERTICALS.LOADING:
      return { ...state, loading: true, error: false }

    case VERTICALS.FETCHED:
      return { ...state, loading: false, fetched: true, data: action.payload }

    case VERTICALS.ADD_ONE_VERTICAL:
      return { ...state, data: [action.payload, ...state.data] }

    case VERTICALS.DELETE_ONE_VERTICAL:
      return {
        ...state,
        data: state.data.filter((item) => item._id !== action.payload)
      }
    case VERTICALS.UPDATE_ONE_VERTICAL:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        loading: false,
        data: updatedData1
      }
    default:
      return state
  }
}
export default verticalsReducer
