import React from 'react'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import currency from 'static/Currency/Currency'
import pillVariantObj from 'utils/pillVariantObj'
import { pillColors } from 'global/globalComponents/Pill/Pill'
// import { getInvoiceSummary } from 'components/_agency/PaymentsSection/paymentStatic'

const InvoiceUpdate = ({ invoice, t }) => {
  const handlePayInvoice = () => {
    let a = document.createElement('a')
    a.target = '_blank'
    a.href = `/i/${invoice._id}/checkout?vtID=${invoice.viewToken}`
    a.click()
  }

  return (
    <div className="bg-gray-50 border dark:border-dark-main1 dark:bg-dark-main3 rounded-md px-1 py-2 md:p-2">
      <div className="flex flex-wrap items-center gap-2 p-1 mb-2 leading-8">
        <span
          className={clsx(
            pillColors[pillVariantObj[invoice.status]],
            'text-black rounded-md font-semibold p-2 text-xs'
          )}
        >
          {t(invoice.status.toUpperCase())}
        </span>
        <span className="rounded-md text-xs p-2 font-medium bg-gray-100 dark:bg-dark-main2 dark:text-gray-400">
          {currency[invoice.currency].symbol_native}
          {invoice.totalAmount.toLocaleString()}
        </span>
      </div>

      <div className="text-smaller dark:text-dark-light whitespace-normal p-1 mb-2 md:leading-8">
        <span
          dangerouslySetInnerHTML={
            new Date(invoice.createdAt) < new Date(invoice.updatedAt)
              ? {
                  __html: t('UPDATE_INVOICE_FEED', {
                    data: ` <span style='font-weight: 600;'} className="font-semibold ">
                ${invoice.customInvoiceNumber ?? invoice.invoiceNumber}
              </span>`
                  })
                }
              : {
                  __html: t('NEW_INVOICE_FEED', {
                    data: ` <span style='font-weight: 600;'} className="font-semibold ">
              ${invoice.customInvoiceNumber ?? invoice.invoiceNumber}
            </span>`
                  })
                }
          }
        ></span>
      </div>

      {['pending', 'overdue'].includes(invoice.status) && (
        <footer className="mt-2 w-full flex justify-end">
          <Button
            onClick={handlePayInvoice}
            className="ml-auto bg-primary-main"
            size="small"
            variant="outlined"
          >
            {t('PAY_NOW')}
          </Button>
        </footer>
      )}
    </div>
  )
}

export default InvoiceUpdate
