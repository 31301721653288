import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomTooltip, FormLabel } from 'global/globalComponents'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import FormField from './FormField'

const RequestDynamicFormTemplate = ({ formFields, ...rest }) => {
  return (
    <>
      {formFields.map(field => (
        <FieldGroup key={field._id || field.id} fieldData={field} {...rest} />
      ))}
    </>
  )
}

const FieldGroup = ({
  fieldData,
  updateFieldProperty,
  removeFieldById,
  canEdit,
  canFillData
}) => {
  const [labelText, setLabelText] = useState(fieldData.label)
  const [editLabel, setEditLabel] = useState(false)

  const handleLabelBlur = e => {
    let value = e.target.value.trim()

    if (value !== '') {
      updateFieldProperty({
        fieldId: fieldData._id || fieldData.id,
        prop: 'label',
        value
      })
      setLabelText(value)
    }

    setEditLabel(false)
  }

  return (
    <div className="mb-4">
      <div className="flex">
        {editLabel ? (
          <input
            type="text"
            defaultValue={labelText}
            onBlur={handleLabelBlur}
            autoFocus
            className="text-smaller block w-full font-medium text-primary-mid-dark pb-1 dark:text-dark-light"
          />
        ) : (
          <div className="flex items-center">
            <FormLabel required={fieldData.required}>{labelText}</FormLabel>
            {canEdit && (
              <button className="ml-2 mr-2" onClick={() => setEditLabel(true)}>
                <PenIcon className="w-3 h-3" />
              </button>
            )}
          </div>
        )}

        {canEdit && (
          <div className="ml-auto">
            <MarkRequiredAndRemoveBtn
              fieldId={fieldData._id || fieldData.id}
              isRequired={fieldData.required}
              updateFieldProperty={updateFieldProperty}
              removeFieldById={removeFieldById}
            />
          </div>
        )}
      </div>

      <FormField
        canEdit={canEdit}
        canFillData={canFillData}
        fieldData={{
          ...fieldData,
          type: fieldData.datatype,
          ...(fieldData.id ? {} : { id: fieldData._id })
        }}
        updateFieldProperty={updateFieldProperty}
        error={false}
      />
    </div>
  )
}

const MarkRequiredAndRemoveBtn = ({
  fieldId,
  isRequired,
  updateFieldProperty,
  removeFieldById
}) => {
  const { t } = useTranslation()

  const toggleRequiredField = e => {
    updateFieldProperty({
      fieldId: fieldId,
      prop: 'required',
      value: e.target.checked
    })
  }

  const handleRemoveField = () => {
    removeFieldById(fieldId)
  }

  return (
    <div className="flex items-center">
      <CustomTooltip title={t('MARK_THIS_FIELD_AS_REQUIRED')} placement="top">
        <div>
          <FormControlLabel
            className="flex items-center"
            control={
              <Checkbox
                onChange={toggleRequiredField}
                checked={isRequired}
                icon={<CheckBoxOutlineBlank style={{ fontSize: '20px' }} />}
                checkedIcon={<CheckBox style={{ fontSize: '20px' }} />}
              />
            }
            label={
              <span className="-ml-2 text-xs font-medium text-gray-700 truncate leading-9">
                {t('REQUIRED')}
              </span>
            }
          />
        </div>
      </CustomTooltip>
      <CustomTooltip title={t('REMOVE_THIS_FIELD')} placement="top">
        <button
          type="button"
          className="text-red-500"
          onClick={handleRemoveField}
        >
          <CrossIcon />
        </button>
      </CustomTooltip>
    </div>
  )
}

export default RequestDynamicFormTemplate
