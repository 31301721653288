import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewNotification } from 'thunks/accountLogs/action'
import { updateMentionsCount } from 'thunks/logs/action'
import { getAgencyOwner } from 'utils/userRoles'

const NotificationsPusherWrapper = props => {
  const meData = useSelector(state => state.me.data)
  const dispatch = useDispatch()
  const [connection, setConnection] = useState({
    count: 0,
    isConnected: false
  })

  useEffect(() => {
    if (connection.isConnected || connection.count >= 10) return

    let agencyOwner = getAgencyOwner(meData)
    if (!agencyOwner) return

    // 1. Getting pusher with this name
    const notificationSubscribe = window.pusherInstance?.channel(
      `accountNotification_${agencyOwner._id}`
    )

    // 2. If no pusher found with the given name then creating one
    if (!notificationSubscribe) {
      const subscribeNotifications = window.pusherInstance?.subscribe(
        `accountNotification_${agencyOwner._id}`
      )

      if (subscribeNotifications) {
        subscribeNotifications.bind('notification_add', data => {
          const idx = data.notifyUsers.findIndex(
            userId => userId === meData._id
          )

          if (idx !== -1) {
            dispatch(addNewNotification(data))
          }
        })

        subscribeNotifications.bind('notification_mention', data => {
          // checking if me data exists in the mention obj
          const idx = data.description.ops.findIndex(item => {
            if (item.insert.mention) {
              return item.insert.mention._id === meData._id
            }
            return false
          })

          if (idx !== -1) {
            dispatch(updateMentionsCount(1))
          }
        })
        setConnection(prev => ({ ...prev, isConnected: true }))
      } else {
        setTimeout(() => {
          setConnection(prev => ({ ...prev, count: prev.count + 1 }))
        }, 1000)
      }
    }

    return () => {
      window.pusherInstance?.unsubscribe(`notification_${agencyOwner?._id}`)
    }
  }, [meData, connection])

  return <>{props.children}</>
}

export default NotificationsPusherWrapper
