import produce from 'immer'

export const ACCOUNT_NOTIFICATION = {
  FETCHED: 'ACCOUNT_NOTIFICATION_FETCHED',
  UPDATE_ALL: 'ACCOUNT_NOTIFICATION_UPDATE_ALL',
  MARK_AS_READ: 'ACCOUNT_NOTIFICATION_MARK_AS_READ',
  MARK_ALL_AS_READ: 'ACCOUNT_NOTIFICATION_MARK_ALL_AS_READ',
  LOAD_MORE: 'ACCOUNT_NOTIFICATION_LOAD_MORE',
  FETCHED_UNREAD_NOTIFICATIONS_COUNT:
    'ACCOUNT_FETCHED_UNREAD_NOTIFICATIONS_COUNT',
  ADD_NEW: 'ACCOUNT_NOTIFICATIONS_ADD_NEW'
}

const groupNameByCategory = {
  task: 'task',
  subTask: 'task',
  workspace: 'workspace',
  workspaceView: 'workspace',
  workspaceBoard: 'workspace',
  workspaceSection: 'workspace'
}

const initialState = {
  error: false,
  data: {
    all: [],
    task: [],
    workspace: [],
    invoice: [],
    service: [],
    proposal: [],
    approval: [],
    teammate: []
  },
  totalUnread: null
}

const accountLogs = produce((draft, action) => {
  switch (action.type) {
    case ACCOUNT_NOTIFICATION.FETCHED: {
      draft.data[action.payload.group] = action.payload.data
      return draft
    }

    case ACCOUNT_NOTIFICATION.LOAD_MORE: {
      if (action.payload.data.length) {
        draft.data[action.payload.group] = draft.data[
          action.payload.group
        ].concat(action.payload.data)
      }
      return draft
    }

    case ACCOUNT_NOTIFICATION.UPDATE_ALL: {
      const notifGroup = draft.data[action.payload.category].find(
        item => item._id?._id === action.payload.groupId
      )

      if (notifGroup) {
        notifGroup.notif = action.payload.data
      }
      return draft
    }

    case ACCOUNT_NOTIFICATION.MARK_AS_READ: {
      const idx = draft.data[action.payload.category].findIndex(
        item => item._id === action.payload.notificationId
      )

      if (idx !== -1) {
        draft.data[action.payload.category][idx].readBy.push(
          action.payload.meId
        )
      }

      // notifGroup.notif.forEach(item => {
      //   item.readBy.push(action.payload.meId)
      // })

      return draft
    }

    case ACCOUNT_NOTIFICATION.MARK_ALL_AS_READ: {
      draft.data[action.payload.category].forEach(notif =>
        notif.readBy.push(action.payload.meId)
      )

      return draft
    }

    case ACCOUNT_NOTIFICATION.FETCHED_UNREAD_NOTIFICATIONS_COUNT: {
      draft.totalUnread = action.payload
      return draft
    }

    case ACCOUNT_NOTIFICATION.ADD_NEW: {
      draft.totalUnread = draft.totalUnread ? draft.totalUnread + 1 : 1
      draft.data.all.unshift(action.payload)

      return draft
    }

    default:
      return draft
  }
}, initialState)

export default accountLogs
