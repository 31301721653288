import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import SearchSuggestionPopup from './SearchSuggestionPopup'
import { getUserTasks } from 'thunks/task/actions'
import moment from 'moment'

const AdvanceGlobalSearch = () => {
  const { t } = useTranslation()
  const teammates = useSelector(state => state.userTeammates)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const [search, setSearch] = useState('')
  const [searchedTeammates, setSearchedTeammates] = useState({
    loading: false,
    data: []
  })
  const [searchedProjects, setSearchedProjects] = useState({
    loading: false,
    data: []
  })
  const [searchedTasks, setSearchedTasks] = useState({
    loading: false,
    data: []
  })
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (search === '') {
      setSearchedProjects(prev => ({ ...prev, loading: false }))
      setSearchedTeammates(prev => ({ ...prev, loading: false }))
      setSearchedTasks(prev => ({ ...prev, loading: false }))
      return
    }
    let keywords = search.toLowerCase()

    setSearchedProjects(prev => ({ ...prev, loading: true }))
    setSearchedTeammates(prev => ({ ...prev, loading: true }))
    setSearchedTasks(prev => ({ ...prev, loading: true }))

    // Debounce
    let timer = setTimeout(() => {
      handleTeammatesSearch(keywords)
      handleProjectsSearch(keywords)
      handleTasksSearch(keywords)
    }, 500)

    return () => clearTimeout(timer)
  }, [search, teammates, userWorkspaces])

  useEffect(() => {
    if (anchorEl && !search) {
      setSearchedTasks({ loading: true, data: [] })
      handleTasksSearch() // will fetch recent tasks
    }
  }, [anchorEl])

  const handleTeammatesSearch = keywords => {
    if (teammates.loading) return
    const filteredMates = teammates.data.filter(item =>
      item.name.toLowerCase().includes(keywords)
    )

    setSearchedTeammates({ loading: false, data: filteredMates.slice(0, 3) })
  }

  const handleProjectsSearch = keywords => {
    if (userWorkspaces.loading) return
    const filteredProjects = userWorkspaces.data.filter(item =>
      item.name.toLowerCase().includes(keywords)
    )

    setSearchedProjects({
      loading: false,
      data: filteredProjects.slice(0, 3)
    })
  }

  const handleTasksSearch = keywords => {
    const data = {
      search: keywords || null
    }

    if (!keywords) {
      data.filters = {
        fields: [
          {
            field: 'updatedAt',
            op: 'EQ',
            idx: 0,
            values: [
              {
                startDate: moment()
                  .subtract(7, 'days')
                  .set('hours', 0)
                  .set('minutes', 0)
                  .set('seconds', 0)
                  .toISOString(),
                endDate: moment()
                  .set('hours', 23)
                  .set('minutes', 59)
                  .set('seconds', 59)
                  .toISOString()
              }
            ]
          }
        ],
        op: 'AND'
      }
    }

    getUserTasks(
      {
        query: {
          limit: 5,
          page: 0
        },
        data
      },
      (res, err) => {
        // here res has 2 keys => data and metadata
        setSearchedTasks({ loading: false, data: err ? [] : res.data })
      }
    )
  }

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }

  const toggleAnchorEl = e => {
    if (anchorEl) setAnchorEl(null)
    else setAnchorEl(e.target)
  }

  return (
    <>
      <div className="bg-gray-100 border dark:bg-dark-main3 rounded-full 2xl:w-72 flex items-center px-2">
        <SearchIcon className="text-primary-main flex-shrink-0 dark:text-dark-light" />
        <input
          type="text"
          placeholder={t('SEARCH_HERE')}
          className="bg-transparent px-2 py-1.5 w-full text-smaller 2xl:text-sm dark:text-white"
          value={search}
          onChange={handleSearchChange}
          onFocus={toggleAnchorEl}
        />
        {search && (
          <CancelRoundedIcon
            fontSize="small"
            style={{ fill: 'gray' }}
            onClick={() => setSearch('')}
            className="cursor-pointer dark:text-dark-light"
          />
        )}
      </div>
      <SearchSuggestionPopup
        anchorEl={anchorEl}
        onClose={toggleAnchorEl}
        keywords={search}
        projects={searchedProjects}
        teammates={searchedTeammates}
        tasks={searchedTasks}
      />
    </>
  )
}

export default AdvanceGlobalSearch
