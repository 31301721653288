import React, { useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Button, makeStyles, useTheme } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import Currency from 'static/Currency/Currency'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { fetchAllCoupons } from 'thunks/coupon/actions'
import { getInvoiceSummary } from 'components/_agency/PaymentsSection/paymentStatic'

const invoiceItemFields = {
  invoiceId: '',
  name: '',
  description: '',
  quantity: '1',
  rate: ''
}

const PricingTable = React.forwardRef(({ invoice }, ref) => {
  // const dispatch = useDispatch()
  const [invoiceItems, setInvoiceItems] = useState([{ ...invoiceItemFields }])
  const [taxAndCurrency, setTaxAndCurrency] = useState({
    currency: invoice.currency || 'USD',
    taxName: invoice.taxName || 'tax',
    tax: invoice.taxPercentage || 0
  })
  const [appliedCoupon, setAppliedCoupon] = useState(null)
  const coupons = useSelector(state => state.coupons)
  // const [filteredCoupons, setFilteredCoupons] = useState([])
  const [discountAmount, setDiscountAmount] = useState(null)

  // const currencySymbol =
  //   Currency[taxAndCurrency.currency.toUpperCase()].symbol_native
  // border-b border-gray-400

  useEffect(() => {
    if (invoice.invoiceItems.length) setInvoiceItems([...invoice.invoiceItems])
    setDiscountAmount(invoice.discountAmount)
  }, [invoice])

  useEffect(() => {
    if (invoice.coupon) {
      setAppliedCoupon(coupons.data.find(item => item._id === invoice.coupon))
    }
  }, [invoice.coupon, coupons])

  // useEffect(() => {
  //   const couponsArr = coupons.data.filter(coupon => {
  //     if (coupon.services.length === 0) return true
  //     if (invoice.type !== 'service') return false

  //     // There could me multiple services in invoiceItems (in future)
  //     // So filtering out only the service items
  //     const serviceItems = invoice.invoiceItems.filter(
  //       item => item.type === 'service'
  //     )
  //     return serviceItems.every(item => coupon.services.includes(item._id))
  //   })

  //   setFilteredCoupons(couponsArr)
  // }, [coupons, invoice.type, invoice.invoiceItems.length])

  useImperativeHandle(ref, () => ({
    getInvoiceItems: () => [...invoiceItems],
    getTaxAndCurrency: () => {
      const data = {
        ...taxAndCurrency,
        discountAmount
      }
      if (appliedCoupon?._id) {
        data.coupon = appliedCoupon._id
      }

      return data
    }
  }))

  const handleChange = e => {
    setInvoiceItems(prev =>
      prev.map((item, index) => {
        if (index === parseInt(e.target.dataset.index)) {
          return { ...item, [e.target.name]: e.target.value }
        }
        return item
      })
    )
  }

  const addNewRow = () => {
    setInvoiceItems(prev => [...prev, { ...invoiceItemFields }])
  }

  const removeRow = rowIndex => {
    setInvoiceItems(prev => prev.filter((item, index) => index !== rowIndex))
  }

  const updateOtherInfo = (key, value) => {
    setTaxAndCurrency(prev => ({ ...prev, [key]: value }))
  }

  return (
    <div>
      <InvoiceItemsTable
        invoiceItems={invoiceItems}
        addNewRow={addNewRow}
        removeRow={removeRow}
        handleChange={handleChange}
        currency={taxAndCurrency.currency}
        type={invoice.type}
      />
      <TotalAmountTable
        currency={taxAndCurrency.currency}
        isEditable={true}
        taxPercent={taxAndCurrency.tax}
        taxName={taxAndCurrency.taxName}
        invoiceItems={invoiceItems}
        updateOtherInfo={updateOtherInfo}
        appliedCoupon={appliedCoupon}
        // couponsData={filteredCoupons}
        updateAppliedCoupon={setAppliedCoupon}
        discountAmount={discountAmount}
        handleUpdateDiscountAmount={setDiscountAmount}
      />
      {/* ===== Bottom empty space ===== */}
      <div className="h-40"></div>
    </div>
  )
})

export const InvoiceItemsTable = ({
  invoiceItems,
  addNewRow,
  removeRow,
  handleChange,
  currency,
  readOnly,
  type
}) => {
  const { t } = useTranslation()
  const headerClasses = 'uppercase text-tiny sm:text-xs py-2 '

  return (
    <div className="bg-gray-100 pb-2 mb-8">
      <table className="w-full">
        <thead>
          <th className={clsx(headerClasses, 'text-left pl-2')}>{t('ITEM')}</th>
          <th className={clsx(headerClasses, 'text-right')}>{t('PRICE')}</th>
          <th className={clsx(headerClasses, 'text-right')}>{t('UNITS')}</th>
          {/* <th className={clsx(headerClasses, 'text-right')}>Subtotal</th> */}
          {/* <th className={clsx(headerClasses, 'text-right')}>Tax</th> */}
          <th
            className={clsx(
              headerClasses,
              'sm:pr-2',
              readOnly ? 'text-right' : 'text-center'
            )}
          >
            {t('TOTAL')}
          </th>
        </thead>
        <tbody>
          {invoiceItems.map((item, index) => (
            <Row
              key={index}
              invoiceItem={item}
              index={index}
              handleChange={handleChange}
              removeRow={() => removeRow(index)}
              currency={currency}
              readOnly={readOnly}
              t={t}
            />
          ))}
        </tbody>
      </table>

      {!readOnly && type !== 'service' && (
        <div className="text-right pr-2 pb-2">
          <Button
            onClick={addNewRow}
            className="p-1 text-primary-main text-lg"
            startIcon={<AddIcon />}
            size="small"
          >
            {t('ITEM')}
          </Button>
        </div>
      )}
    </div>
  )
}

const Row = ({
  t,
  invoiceItem,
  index,
  handleChange,
  removeRow,
  currency,
  readOnly
}) => {
  const inputClasses =
    'text-smaller rounded border border-transparent focus:border-primary-main p-1 bg-transparent hover:bg-gray-200'

  const price = parseFloat(parseFloat(invoiceItem.rate || 0).toFixed(2)),
    quantity = invoiceItem.quantity || 0,
    total = parseFloat((price * quantity).toFixed(2))
  const currencySymbol = Currency[currency].symbol_native

  return (
    <tr className="text-smaller border-t border-gray-400">
      <td className="pt-1 pl-2">
        <div>
          {invoiceItem.type === 'addon' && (
            <div
              // style={{ position: 'absolute', left: 0, top: -10 }}
              className="text-primary-main font-semibold text-xs"
            >
              {t('ADD_ON')}
            </div>
          )}

          {readOnly ? (
            <div>
              <div className="font-medium">{invoiceItem.name}</div>
              <div className="text-gray-600">{invoiceItem.description}</div>
            </div>
          ) : (
            <div className="flex flex-col">
              <input
                className={inputClasses}
                type="text"
                placeholder={t('EXAMPLE_ITEM')}
                value={invoiceItem.name}
                name="name"
                data-index={index}
                onChange={handleChange}
                // ref={ref}
                autoComplete="none"
              />

              <input
                className={clsx(inputClasses, 'text-xs')}
                type="text"
                placeholder={`${t('DESCRIPTION')} (${t('OPTIONAL')})`}
                value={invoiceItem.description}
                name="description"
                data-index={index}
                onChange={handleChange}
                // ref={ref}
                autoComplete="none"
              />
            </div>
          )}
        </div>
      </td>
      <td className="text-right">
        {readOnly ? (
          <div style={{ minWidth: 65, maxWidth: 75 }} className="ml-auto">
            {price.toLocaleString()}
          </div>
        ) : (
          <input
            className={clsx(inputClasses, 'text-right')}
            type="number"
            placeholder="0"
            value={price}
            name="rate"
            data-index={index}
            onChange={handleChange}
            style={{ minWidth: 65, maxWidth: 75 }}
          />
        )}
      </td>
      <td className="text-right">
        {readOnly ? (
          <div style={{ minWidth: 45, maxWidth: 50 }} className="ml-auto">
            {quantity}
          </div>
        ) : (
          <input
            className={clsx(inputClasses, 'text-right')}
            type="number"
            placeholder="0"
            value={quantity}
            name="quantity"
            data-index={index}
            onChange={handleChange}
            style={{ minWidth: 45, maxWidth: 50 }}
          />
        )}
      </td>
      <td
        className={clsx(
          'relative sm:pr-2',
          readOnly ? 'text-right' : 'text-center'
        )}
      >
        <div style={{ minWidth: 65, maxWidth: 75 }} className="ml-auto">
          {/* if currency is euro, move it to the right */}
          {currency === 'EUR'
            ? `${total.toLocaleString()}${currencySymbol}`
            : `${currencySymbol}${total.toLocaleString()}`}
        </div>

        {!readOnly && index > 0 && (
          <button
            className="p-1 text-primary-main text-lg absolute right-0"
            onClick={removeRow}
            data-index={index}
            style={{ top: '30%' }}
          >
            <CrossIcon />
          </button>
        )}
      </td>
    </tr>
  )
}

export const TotalAmountTable = ({
  // taxAndCurrency,
  currency,
  updateOtherInfo,
  isEditable,
  taxPercent,
  taxName,
  appliedCoupon,
  // couponsData,
  updateAppliedCoupon,
  invoiceItems,
  discountAmount,
  handleUpdateDiscountAmount,
  invoiceSubtotal,
  invoiceTotal
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const currencySymbol = Currency[currency].symbol_native
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: currency.toUpperCase(),
    value: currency.toUpperCase()
  })
  // const [selectedCoupon, setSelectedCoupon] = useState(appliedCoupon)

  // useEffect(() => {
  //   setSelectedCoupon(appliedCoupon)
  // }, [appliedCoupon])

  useEffect(() => {
    if (!isEditable) return
    let arr = Object.keys(Currency).map(el => ({
      value: el,
      label: el
    }))
    setCurrencyOptions(arr)
  }, [])

  useEffect(() => {
    if (!isEditable) return
    setSelectedCurrency({
      label: currency.toUpperCase(),
      value: currency.toUpperCase()
    })
  }, [currency])

  const { subtotal, totalAmount, discount, taxAmount } = getInvoiceSummary({
    invoiceItems: invoiceItems,
    taxPercent: taxPercent,
    coupon: appliedCoupon,
    discountAmount: discountAmount
  })

  // const subTotal = invoiceItems.length
  //   ? invoiceItems.reduce((accumulator, currentValue) => {
  //       return accumulator + currentValue.rate * currentValue.quantity
  //     }, 0)
  //   : 0

  // const { subtotal, total } = useMemo(() => {
  //   const subtotal = invoiceItems.reduce((accumulator, currentValue) => {
  //     return accumulator + currentValue.rate * currentValue.quantity
  //   }, 0)

  //   const total = tax
  //     ? subtotal + parseFloat((subtotal * (tax / 100)).toFixed(2))
  //     : subtotal

  //   return { subtotal, total }
  // }, [invoiceItems, tax])

  const handleTaxUpdate = e => {
    updateOtherInfo(e.target.name, e.target.value)
  }

  const selectCurrency = option => {
    updateOtherInfo('currency', option.value)
  }

  const handleRemoveCoupon = () => {
    updateAppliedCoupon(null)
    handleUpdateDiscountAmount(0)
  }

  return (
    <>
      <div
        className={clsx('text-smaller', classes.invoiceSummary)}
        // style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 130px', gridGap: 4, gap: 4 }}
      >
        {/* ==== Subtotal ==== */}
        <div className="flex">
          <div className="flex-1 text-right uppercase">{t('SUBTOTAL')}</div>
          <div className="text-right w-44">
            {currencySymbol}
            {isEditable
              ? subtotal.toLocaleString()
              : invoiceSubtotal.toLocaleString()}
          </div>
        </div>

        {/* ==== Discount ==== */}
        {Boolean(discount) && (
          <div className="flex">
            <div className="uppercase flex-1 text-right relative">
              {t('DISCOUNT')}{' '}
              <span
                style={{ top: 1 }}
                className="absolute text-green-500 ml-2 text-xs uppercase"
              >
                ({appliedCoupon?.code || t('SPECIAL_DISCOUNT')})
              </span>
            </div>
            <div className="text-right relative w-44">
              -{discount}{' '}
              {isEditable && (
                <button
                  onClick={handleRemoveCoupon}
                  className="absolute"
                  style={{ right: -20, top: 1 }}
                >
                  <CrossIcon className="w-4 h-4 text-red-500" />
                </button>
              )}
            </div>
          </div>
        )}

        {/* ==== Tax ==== */}
        <div className="flex">
          <div className="flex-1 text-right">
            {!isEditable ? (
              taxName
            ) : (
              <input
                className="text-smaller rounded border border-transparent focus:border-primary-main px-1 bg-transparent hover:bg-gray-200 uppercase text-right"
                type="text"
                placeholder={t('TAX')}
                value={taxName}
                name="taxName"
                onChange={handleTaxUpdate}
                style={{ maxWidth: 80 }}
              />
            )}
          </div>
          <div className="text-right w-44">
            {!isEditable ? (
              taxAmount
            ) : (
              <input
                className="text-smaller rounded border border-transparent focus:border-primary-main px-1 bg-transparent hover:bg-gray-200 text-right"
                type="number"
                placeholder="0"
                value={taxPercent}
                name="tax"
                onChange={handleTaxUpdate}
                style={{ minWidth: 50, maxWidth: 50 }}
              />
            )}
            <span>%</span>
          </div>
        </div>

        {/* ==== Total ==== */}
        <div className="flex items-center">
          <div className="flex-1 text-right uppercase">{t('TOTAL')}</div>
          <div className="flex items-center justify-end w-44">
            {!isEditable ? (
              <span>
                {currencySymbol}
                {invoiceTotal.toLocaleString()}
              </span>
            ) : (
              <>
                <span className="mr-1">{totalAmount}</span>
                <Select
                  styles={reactSelectCustomStyles(theme, {
                    control: {
                      height: 34,
                      minHeight: 0
                    },
                    option: {
                      fontSize: 12
                    },
                    valueContainer: {
                      padding: '0px 4px'
                    },
                    singleValue: {
                      fontSize: 12
                    },
                    dropdownIndicator: { padding: 0 },
                    input: {
                      paddingTop: 0,
                      marginTop: 0
                    }
                  })}
                  options={currencyOptions}
                  onChange={selectCurrency}
                  value={selectedCurrency}
                  maxMenuHeight={150}
                  className="w-16 border-0 border-none"
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* {isEditable && !appliedCoupon && (
        <div className="w-1/2 ml-auto my-4">
          <label className="inline-block text-smaller text-gray-600 mb-1">
            {t('ADD_DISCOUNT')}
          </label>
          <div className="flex">
            <Select
              options={couponsData}
              value={selectedCoupon}
              styles={reactSelectCustomStyles(theme)}
              getOptionLabel={option =>
                `${option.code} (${option.discount} ${
                  option.type === 'percentage' ? '%' : currency
                })`
              }
              getOptionValue={option => option._id}
              onChange={setSelectedCoupon}
              maxMenuHeight={150}
              className="w-full border-0 border-none"
            />
            <button
              onClick={() => updateAppliedCoupon(selectedCoupon)}
              className="ml-2 px-3 border text-primary-main border-primary-main rounded text-xs font-semibold hover:bg-primary-main hover:text-white uppercase"
            >
              {t('ADD')}
            </button>
          </div>
        </div>
      )} */}
    </>
  )
}

const useStyles = makeStyles({
  invoiceSummary: {
    '& > div': {
      padding: 2,
      marginBottom: 2
    },
    '& > div:nth-child(odd)': {
      backgroundColor: '#f4f4f5'
    }
  }
})

export default PricingTable
