import { createContext } from 'react'
import produce from 'immer'

export const ProposalContext = createContext(null)

export const PROPOSAL = {
  FETCHED: 'PROPOSAL_FETCHED',
  UPDATE: 'PROPOSAL_UPDATE_TEMPLATE',
  UPDATE_THEME_COLORS: 'PROPOSAL_UPDATE_THEME_COLORS',
  UPDATE_LOGO: 'PROPOSAL_UPDATE_LOGO',
  UPDATE_LOGO_SIZE: 'PROPOSAL_UPDATE_LOGO_SIZE',
  UPDATE_IMAGE: 'PROPOSAL_UPDATE_IMAGE',
  OPEN_SIGN_MODAL: 'PROPOSAL_OPEN_SIGN_MODAL',
  CLOSE_SIGN_MODAL: 'PROPOSAL_CLOSE_SIGN_MODAL',
  UPDATE_SINGLE_CUSTOM_VARIABLE: 'PROPOSAL_UPDATE_SINGLE_CUSTOM_VARIABLE',
  UPDATE_MULTI_CUSTOM_VARIABLES: 'PROPOSAL_UPDATE_MULTI_CUSTOM_VARIABLES',
  REMOVE_PAGE: 'PROPOSAL_REMOVE_PAGE',
  COPY_PAGE: 'PROPOSAL_COPY_PAGE',
  UPDATE_STATUS: 'PROPOSAL_UPDATE_STATUS',
  ASSIGN_AGENCY_TEAMMATE: 'PROPOSAL_ASSIGN_AGENCY_TEAMMATE',
  REMOVE_AGENCY_TEAMMATE: 'PROPOSAL_REMOVE_AGENCY_TEAMMATE',
  UPDATE_PASSCODE: 'PROPOSAL_UPDATE_PASSCODE',
  UNDO: 'PROPOSAL_UNDO',
  REDO: 'PROPOSAL_REDO',
  UPDATE_AUTO_SAVING_STATUS: 'PROPOSALS_UPDATE_AUTO_SAVING_STATUS'
}

export const initialState = {
  loading: true,
  fetched: false,
  data: {
    // These are some common fields across all templates
    type: '',
    status: '',
    name: '',
    logo: '',
    metadata: {
      style: {
        theme: {
          colors: {}
        },
        logo: {}
      }
    },
    pages: []
  },
  customVariables: {},
  history: [],
  pointer: 0,
  autoSavingStatus: null //could be saving, saved and failed.
}

export const proposalReducer = produce((draft, action) => {
  switch (action.type) {
    case PROPOSAL.FETCHED:
      draft.loading = false
      draft.fetched = true
      draft.data = action.payload
      draft.customVariables = action.payload.metadata.value.variables
      draft.history = [action.payload]
      return draft

    case PROPOSAL.UPDATE:
      draft.data = action.payload
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )

      // if (draft.history.length - 1 === draft.pointer) {
      //   draft.history.push(draft.data)
      // }

      draft.pointer += 1
      return draft

    case PROPOSAL.UPDATE_THEME_COLORS:
      draft.data.metadata.style.theme.colors[action.payload.key] =
        action.payload.value
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )
      draft.pointer += 1
      return draft

    case PROPOSAL.UPDATE_LOGO:
      draft.data.logo = action.payload
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )
      draft.pointer += 1
      return

    case PROPOSAL.UPDATE_LOGO_SIZE:
      draft.data.metadata.style.theme.logo[action.payload.key] =
        action.payload.value
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )
      draft.pointer += 1
      return draft

    case PROPOSAL.UPDATE_IMAGE:
      draft.data.metadata.style.theme.images[action.payload.key] =
        action.payload.value
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )
      draft.pointer += 1
      return draft

    case PROPOSAL.UPDATE_MULTI_CUSTOM_VARIABLES:
      action.payload.forEach(item => {
        draft.customVariables[item.variableName] = item.value
      })
      return draft

    case PROPOSAL.UPDATE_SINGLE_CUSTOM_VARIABLE:
      draft.customVariables[action.payload.variableName] = action.payload.value
      return draft

    case PROPOSAL.REMOVE_PAGE:
      draft.data.pages.splice(action.payload, 1)
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )
      draft.pointer += 1
      return draft

    case PROPOSAL.COPY_PAGE:
      draft.data.pages.splice(action.payload.pageIndex, 0, action.payload.page)
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )
      draft.pointer += 1
      return draft

    case PROPOSAL.UPDATE_STATUS:
      draft.data.status = action.payload
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )
      draft.pointer += 1
      return draft

    case PROPOSAL.ASSIGN_AGENCY_TEAMMATE:
      draft.data.metadata.value.agencyTeammates.push(action.payload)
      draft.history.splice(
        draft.pointer + 1,
        draft.history.length - draft.pointer,
        draft.data
      )
      draft.pointer += 1
      return draft

    case PROPOSAL.REMOVE_AGENCY_TEAMMATE:
      let agencyTeammates = draft.data.metadata.value.agencyTeammates

      if (!agencyTeammates.includes(action.payload)) {
        agencyTeammates.splice(agencyTeammates.indexOf(action.payload, 1))
        draft.history.splice(
          draft.pointer + 1,
          draft.history.length - draft.pointer,
          draft.data
        )
        draft.pointer += 1
      }

      return draft

    case PROPOSAL.UPDATE_PASSCODE:
      draft.data.passcode = action.payload
      return draft

    case PROPOSAL.UNDO:
      if (draft.pointer > 0) {
        draft.pointer -= 1
        draft.data = draft.history[draft.pointer]
      }
      return draft

    case PROPOSAL.REDO:
      if (draft.pointer + 1 < draft.history.length) {
        draft.pointer += 1
        draft.data = draft.history[draft.pointer]
      }
      return draft

    case PROPOSAL.UPDATE_AUTO_SAVING_STATUS:
      draft.autoSavingStatus = action.payload
      return draft

    case PROPOSAL.RESET_DATA:
      draft = initialState
      return draft

    default:
      return draft
  }
})
