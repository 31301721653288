export const CLIENT = {
  LOADING: 'LOADING_CLIENT',
  ERROR: 'CLIENT_ERROR',
  FETCHED: 'CLIENT_DATA_FETCHED',
  ADD_ONE_CLIENT: 'ADD_ONE_CLIENT',
  DELETE_ONE_CLIENT: 'DELETE_ONE_CLIENT',
  UPDATE_ONE_CLIENT: 'UPDATE_ONE_CLIENT',
  ADD_CURRENT_CLIENT: 'ADD_CURRENT_CLIENT',
  REMOVE_CURRENT_CLIENT: 'REMOVE_CURRENT_CLIENT',
  ASSIGN_SERVICE_TO_CLIENT: 'ASSIGN_SERVICE_TO_CLIENT',
  DELETE_DRAFT_SERVICE_OF_CLIENT: 'DELETE_DRAFT_SERVICE_OF_CLIENT',
  UPDATE_CURRENT_CLIENT_PAYMENT_STATUS:
    'CLIENT_UPDATE_CURRENT_CLIENT_PAYMENT_STATUS',
  UPDATE_MANY_CLIENTS: 'UPDATE_MANY_CLIENTS',
  UPDATE_CLIENT_COMPANY: 'UPDATE_CLIENT_COMPANY'
}

const initialState = {
  error: false,
  loading: true,
  data: [],
  fetched: false,
  currentClient: undefined
}

const clientReducer = (state = initialState, action) => {
  const getCurrentIndex = id => state.data.findIndex(i => i._id === id)
  switch (action.type) {
    case CLIENT.LOADING:
      return {
        ...state,
        loading: true,
        error: false
      }

    case CLIENT.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLIENT.ADD_ONE_CLIENT:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data]
      }

    case CLIENT.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case CLIENT.DELETE_ONE_CLIENT:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data.filter(item => item._id !== action.payload._id)
      }

    case CLIENT.ADD_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: state.data.find(i => i._id === action.payload)
      }

    case CLIENT.REMOVE_CURRENT_CLIENT:
      return { ...state, currentClient: undefined }

    case CLIENT.UPDATE_ONE_CLIENT:
      const index = getCurrentIndex(action.payload._id)
      const updatedClientsData = state.data.map(client => {
        if (client._id === action.payload.data._id) {
          return {
            ...client,
            ...action.payload.data,
            profile: action.payload.quota
              ? {
                  ...client.profile,
                  quota: action.payload.quota
                }
              : client.profile
          }
        }
        return client
      })

      return {
        ...state,
        data: updatedClientsData,
        currentClient:
          state.currentClient?._id === action.payload.data._id
            ? updatedClientsData[index]
            : state.currentClient
      }

    case CLIENT.ASSIGN_SERVICE_TO_CLIENT:
      return {
        ...state,
        data: state.data.map(i =>
          i._id === action.payload.user._id
            ? {
                ...i,
                payment: [
                  ...action.payload.clientPayment.map(el => ({
                    mode: el?.mode,
                    _id: el?._id,
                    name: el?.name,
                    status: el?.status,
                    type: el?.type
                  })),
                  ...i.payment
                ],
                activeServices: [
                  ...action.payload.clientPayment,
                  ...i.activeServices
                ]
              }
            : i
        ),
        currentClient:
          state.currentClient?._id === action.payload.user._id
            ? {
                ...state.currentClient,
                payment: [
                  ...action.payload.clientPayment.map(el => ({
                    mode: el?.mode,
                    _id: el?._id,
                    name: el?.name,
                    status: el?.status,
                    type: el?.type
                  })),
                  ...state.currentClient.payment
                ],
                activeServices: [
                  ...action.payload.clientPayment,
                  ...state.currentClient.activeServices
                ]
              }
            : state.currentClient
      }

    case CLIENT.DELETE_DRAFT_SERVICE_OF_CLIENT:
      return {
        ...state,
        data: state.data.map(i =>
          i._id === action.payload.user._id
            ? {
                ...i,
                activeServices: i.activeServices.filter(
                  service =>
                    service._id !== action.payload.clientPayment[0]?._id
                )
              }
            : i
        ),
        currentClient:
          state.currentClient?._id === action.payload.user._id
            ? {
                ...state.currentClient,
                activeServices: state.currentClient.activeServices.filter(
                  service =>
                    service._id !== action.payload.clientPayment[0]?._id
                )
              }
            : state.currentClient
      }

    case CLIENT.UPDATE_CURRENT_CLIENT_PAYMENT_STATUS:
      return {
        ...state,
        data: state.data.map(el =>
          el._id === action.payload.user._id
            ? {
                ...el,
                activeServices: el.activeServices.map(service => {
                  const element = action.payload.clientPayment.find(
                    i => i._id === service._id
                  )
                  return element ? element : service
                })
              }
            : el
        ),
        currentClient:
          state.currentClient._id === action.payload.user._id
            ? {
                ...state.currentClient,
                activeServices: state.currentClient.activeServices.map(
                  service => {
                    const element = action.payload.clientPayment.find(
                      i => i._id === service._id
                    )
                    return element ? element : service
                  }
                )
              }
            : state.currentClient
      }

    case CLIENT.UPDATE_MANY_CLIENTS:
      const updatedData4 = state.data.map(item => {
        if (item.agencyRepresentative?._id === action.payload)
          item.agencyRepresentative = null
        return item
      })

      return {
        ...state,
        data: updatedData4
      }

    case CLIENT.UPDATE_CLIENT_COMPANY:
      const updatedData5 = state.data.map(item => {
        if (item._id === action.payload.clientId)
          item.company = action.payload.company
        return item
      })

      return {
        ...state,
        data: updatedData5
      }

    default:
      return state
  }
}

export default clientReducer
