export const WEBHOOK = {
  FETCHING: 'FETCHING_WEBHOOK',
  FETCHED_CREATED: 'FETCHED_CREATED_WEBHOOk',
  CREATED: 'CREATED_WEBHOOK',
  FETCHED_ALL_EVENTS: 'FETCHED_WEBHOOK_ALL_EVENTS',
  FETCHED_ONE_WEBHOOK: 'FETCHED_ONE_WEBHOOK_DATA',
  UPDATE_ONE_WEBHOOK: 'UPDATE_ONE_WEBHOOK_DATA',
  DELETE_ONE_WEBHOOK: 'DELETE_ONE_WEBHOOK_DATA'
}

const initialState = {
  fetching: false,
  data: [],
  createdWebhook: [],
  error: false,
  allEvents: [],
  slackData: [],
  teamsData: []
}

const webhookReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEBHOOK.CREATED: {
      let modifiedKeys = {}
      if (action.payload.type === 'slack')
        modifiedKeys.slackData = [...state.slackData, action.payload]
      else if (action.payload.type === 'teams')
        modifiedKeys.teamsData = [...state.teamsData, action.payload]
      else
        modifiedKeys.createdWebhook = [...state.createdWebhook, action.payload]
      return {
        ...state,
        ...modifiedKeys
      }
    }

    case WEBHOOK.FETCHED_ALL_EVENTS:
      return {
        ...state,
        allEvents: action.payload
      }

    case WEBHOOK.FETCHED_CREATED: {
      let createdWebhook = [],
        slackData = [],
        teamsData = []
      action.payload.forEach(element => {
        if (element.type === 'slack') slackData.push(element)
        else if (element.type === 'teams') teamsData.push(element)
        else createdWebhook.push(element)
      })
      return {
        ...state,
        createdWebhook,
        slackData,
        teamsData
      }
    }

    case WEBHOOK.UPDATE_ONE_WEBHOOK:
      // const createdWebhookIndex = state.createdWebhook.findIndex(
      //   (i) => i._id === action.payload._id
      // )

      // const slackWebhookIndex = state.slackData.findIndex(
      //   (i) => i._id === action.payload._id
      // )

      // const updatedState = [...state.createdWebhook]
      // updatedState[updatingIndex] = action.payload

      return {
        ...state,
        createdWebhook: state.createdWebhook.map(item => {
          if (item._id === action.payload._id) return action.payload
          return item
        }),
        slackData: state.slackData.map(item => {
          if (item._id === action.payload._id) return action.payload
          return item
        }),
        teamsData: state.teamsData.map(item => {
          if (item._id === action.payload._id) return action.payload
          return item
        })
        // slackData:
        //   action.payload._id === state.slackData._id
        //     ? action.payload
        //     : state.slackData
      }

    case WEBHOOK.DELETE_ONE_WEBHOOK:
      return {
        ...state,
        createdWebhook: state.createdWebhook.filter(
          el => el._id !== action.payload._id
        ),
        slackData: state.slackData.filter(
          item => item._id !== action.payload._id
        ),
        teamsData: state.teamsData.filter(
          item => item._id !== action.payload._id
        )
      }

    default:
      return state
  }
}

export default webhookReducer
