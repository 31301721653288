import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { CircularProgress } from '@material-ui/core'

const ApplyCoupon = ({ className, onSubmit, disabled, loading }) => {
  const { t } = useTranslation()
  const [code, setCode] = useState('')

  const handleOnChange = e => {
    setCode(e.target.value.toUpperCase())
  }

  const handleApplyCoupon = () => {
    if (!disabled) {
      setCode('')
      onSubmit(code.trim())
    }
  }

  return (
    <div>
      <label className="inline-block text-smaller text-gray-600 mb-1">
        {t('HAVE_COUPON_CODE')}
      </label>
      <div className={clsx('flex', className)}>
        <input
          value={code}
          disabled={disabled}
          onChange={handleOnChange}
          type="text"
          className="flex-1 mr-4 transition-colors border rounded py-1.5 px-2 border-gray-300 hover:border-primary-main"
        />
        <button
          onClick={handleApplyCoupon}
          className={clsx(
            'w-20 border rounded text-smaller font-semibold',
            disabled
              ? 'bg-gray-100 cursor-default'
              : 'text-primary-main border-primary-main hover:bg-primary-light'
          )}
          disabled={disabled}
        >
          {loading ? <CircularProgress size={18} /> : t('APPLY')}
        </button>
      </div>
    </div>
  )
}

export default ApplyCoupon
