import axios from 'axios'
import { getErrorMessages } from 'utils/errorMessages'
import { AGENCY_PLAN } from './reducers'
const errorMessages = getErrorMessages()

/**
 * fetch all plan for agency
 * @param {Function} callback callback function
 */
export const fetchAgencyPlan = callback => {
  return async dispatch => {
    dispatch({ type: AGENCY_PLAN.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/agencypayment/plan',
        isPublic: true //public api
      })
      dispatch({ type: AGENCY_PLAN.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
