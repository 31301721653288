import axios from 'axios'
import { catchAsync } from './catchAsync'

/**
 *
 * @param {String} url or base64
 * @param {Function} cb optional
 */

export const downloadBase64File = (file, cb) => {
  fetch(file, {
    method: 'GET',
    headers: {
      Accept: '*'
    }
  })
    .then(response => response.blob())
    .then(blob => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `FileName${file.slice(file.lastIndexOf('.'))}` //getting file extension from url
      )

      // Append to html link element page
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)

      if (cb) cb()
    })
    .catch(err => {
      if (cb) cb(true)
    })
}

/**
 * common download file
 * @param {Object} file file data (_id, url)
 * @param {Function} callback
 */
export const downloadFile = catchAsync(async (file, callback) => {
  if (file.storageName === 'dropbox') {
    window.open(file.url)
    if (callback) callback()
  } else {
    const query = file.url ? `url=${file.url}` : `id=${file._id}`
    const res = await axios({
      method: 'GET',
      url: `/attachment/download?${query}`
    })

    window.open(res.data)
    if (callback) callback(res.data)
  }
})
