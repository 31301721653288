import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle
} from 'react'
import clsx from 'clsx'
import { Button, makeStyles } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useTranslation } from 'react-i18next'
import ObjectID from 'bson-objectid'
import { useSelector } from 'react-redux'
import InvoiceItem from './InvoiceItem'
import InvoiceExpensesAndTotal from './InvoiceExpensesAndTotal'
// import { fetchAllCoupons } from 'thunks/coupon/actions'

const InvoicePricingTable = forwardRef(({ invoiceData, isEditable }, ref) => {
  const classes = useStyles()
  // const dispatch = useDispatch()
  const { t } = useTranslation()
  const [invoiceCurrency, setInvoiceCurrency] = useState(invoiceData.currency)
  const [invoiceItems, setInvoiceItems] = useState([getInvoiceFieldData()])
  const userServicesData = useSelector(state => state.userServices.data)
  const coupons = useSelector(state => state.coupons)
  // const [filteredCoupons, setFilteredCoupons] = useState([])
  const [appliedCoupon, setAppliedCoupon] = useState(invoiceData.coupon)
  const [taxData, setTaxData] = useState({
    taxName: '',
    taxPercent: ''
  })
  const [discountAmount, setDiscountAmount] = useState(
    invoiceData.discountAmount
  )

  useEffect(() => {
    if (invoiceData._id) {
      if (invoiceData.invoiceItems.length) {
        setInvoiceItems(invoiceData.invoiceItems)
      }

      setInvoiceCurrency(invoiceData.currency)
      setTaxData({
        taxName: invoiceData.taxName,
        taxPercent: invoiceData.taxPercent
      })

      setAppliedCoupon(invoiceData.coupon)
      setDiscountAmount(invoiceData.discountAmount)
    }
  }, [invoiceData, coupons])

  useImperativeHandle(ref, () => ({
    getData: () => {
      return {
        invoiceItems,
        taxName: taxData.taxName,
        taxPercent: taxData.taxPercent,
        coupon: appliedCoupon?._id || null,
        currency: invoiceCurrency,
        discountAmount
      }
    }
  }))

  // useEffect(() => {
  //   const couponsArr = coupons.data.filter(coupon => {
  //     if (coupon.services.length === 0) return true
  //     if (invoiceData.type !== 'service') return false

  //     // There could me multiple services in invoiceItems (in future)
  //     // So filtering out only the service items
  //     const serviceItems = invoiceData.invoiceItems.filter(
  //       item => item.type === 'service'
  //     )
  //     return serviceItems.every(item => coupon.services.includes(item._id))
  //   })

  //   setFilteredCoupons(couponsArr)
  // }, [coupons, invoiceData.type, invoiceData.invoiceItems.length])

  const addInvoiceItemField = () => {
    setInvoiceItems(prev => [...prev, getInvoiceFieldData()])
  }

  const removeInvoiceItem = index => {
    setInvoiceItems(invoiceItems.filter((item, idx) => idx !== index))
  }

  const updateInvoiceItemField = e => {
    const field = e.target.name
    const fieldIndex = parseInt(e.target.dataset.index)

    // Preventing negative values
    if (
      (field === 'quantity' || field === 'rate') &&
      parseInt(e.target.value) < 0
    ) {
      return
    }

    setInvoiceItems(prev =>
      prev.map((item, index) => {
        if (index !== fieldIndex) return item

        let value = e.target.value

        if (field === 'quantity') value = parseInt(e.target.value) || ''
        else if (field === 'rate') value = parseFloat(e.target.value) || ''

        return {
          ...item,
          [field]: value
        }
      })
    )
  }

  const handleSelectService = (service, index) => {
    const invoiceItemsClone = [...invoiceItems]

    invoiceItemsClone[index] = { ...service }
    invoiceItemsClone[index].quantity = 1
    invoiceItemsClone[index].rate = service.amount * 0.01
    invoiceItemsClone[index].total = service.amount * 0.01

    setInvoiceItems(invoiceItemsClone)
  }

  const handleUpdateTaxData = (field, value) => {
    setTaxData(prev => ({ ...prev, [field]: value }))
  }

  return (
    <div>
      <div className={clsx(classes.invoicePricingTable, 'mb-2')}>
        <div className="uppercase text-xs">{t('ITEM_NAME')}</div>
        <div className="uppercase text-right text-xs">{t('UNITS')}</div>
        <div className="uppercase text-right text-xs">{t('RATE')}</div>
        <div className="uppercase text-right text-xs">{t('TOTAL')}</div>
      </div>
      {invoiceItems.map((item, index) => (
        <InvoiceItem
          key={index}
          invoiceItem={item}
          type={invoiceData.type}
          itemIndex={index}
          addInvoiceItemField={addInvoiceItemField}
          removeInvoiceItem={removeInvoiceItem}
          // addAnotherInvoiceItem={addAnotherInvoiceItem}
          updateInvoiceItemField={updateInvoiceItemField}
          currency={invoiceCurrency}
          services={userServicesData}
          classes={classes}
          handleSelectService={handleSelectService}
          isEditable={isEditable}
        />
      ))}

      {isEditable && (
        <>
          {invoiceData.type !== 'service' && (
            <div className="flex justify-end mb-2">
              <Button
                onClick={addInvoiceItemField}
                className="p-1 text-primary-main text-lg"
                startIcon={<AddIcon />}
                size="small"
              >
                {t('ITEM')}
              </Button>
            </div>
          )}
        </>
      )}

      <InvoiceExpensesAndTotal
        // couponsData={filteredCoupons}
        appliedCoupon={appliedCoupon}
        updateAppliedCoupon={setAppliedCoupon}
        invoiceCurrency={invoiceCurrency}
        updateInvoiceCurrency={value => setInvoiceCurrency(value)}
        taxData={taxData}
        handleUpdateTaxData={handleUpdateTaxData}
        invoiceItems={invoiceItems}
        discountAmount={discountAmount}
        handleUpdateDiscountAmount={setDiscountAmount}
        isEditable={isEditable}
        invoiceSubtotal={invoiceData.subTotalAmount}
        invoiceTotal={invoiceData.totalAmount}
      />
    </div>
  )
})

const getInvoiceFieldData = () => {
  return {
    id: ObjectID().toString(),
    name: '',
    description: '',
    quantity: 1,
    rate: '',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  }
}

const useStyles = makeStyles({
  invoicePricingTable: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(75px, 1fr)) 10px',
    // gridGap: '1rem',
    alignItems: 'center'
  },
  paidBadge: {
    transform: 'rotate(40deg)',
    transformOrigin: 'bottom left'
  }
})

export default React.memo(InvoicePricingTable)
