import React, { useMemo } from 'react'
import { LinearProgress, Tooltip, useTheme } from '@material-ui/core'
import { ColoredAvatars } from 'global/globalComponents'
// import { fetchUsersWorkload } from 'thunks/Tracking/action'
// import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { dateAndTimeHelper } from 'utils'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import { DiagonalLines } from 'static/Images'
import clsx from 'clsx'
import Pill from 'global/globalComponents/Pill/Pill'
import { darkenHex } from 'utils/hexToRgb'

const WorkloadTableRow = ({
  user,
  selectedDays,
  weeklyWorkCapacity,
  classes,
  workingDays,
  teamVerticals,
  handleHoverCol,
  handleMouseLeave,
  rowIndex,
  isHovered,
  dayWiseData
}) => {
  const { t } = useTranslation()
  const vertical = teamVerticals.find(el => {
    if (user.verticals) {
      return el._id === user.verticals[0]
    }
    return null
  })

  const { dailyWorkCapacity, totalTrackedTime, completion } = useMemo(() => {
    let totalWorkingDays = 0

    for (let key in workingDays) {
      if (workingDays[key]) totalWorkingDays += 1
    }

    const obj = {
      dailyWorkCapacity: parseFloat(
        (weeklyWorkCapacity / totalWorkingDays).toFixed(1)
      )
    }
    let totalWorkloadInMS = 0

    selectedDays.forEach(item => {
      const arr = dayWiseData[item]

      if (arr) {
        totalWorkloadInMS += arr.reduce(
          (total, currentValue) => total + currentValue.totalDuration,
          0
        )
      }
    })

    obj.totalTrackedTime = dateAndTimeHelper.getMinutesAndHours(
      totalWorkloadInMS
    )
    obj.completion = Math.floor(
      (totalWorkloadInMS / (weeklyWorkCapacity * 60 * 60 * 1000)) * 100
    )

    return obj
  }, [dayWiseData, weeklyWorkCapacity, workingDays])

  // useEffect(() => {
  //   handleFetchUserData(user._id)
  // }, [user._id, selectedDays])

  return (
    <div
      className="w-full flex"
      onMouseEnter={handleHoverCol}
      onMouseLeave={handleMouseLeave}
      data-index={rowIndex}
    >
      {/* ===== User Col Starts ===== */}
      <div
        className={clsx(
          'border-b border-r text-xs px-2 py-4 flex flex-col justify-center',
          isHovered && 'bg-gray-100 dark:bg-dark-main4'
        )}
        style={{ minWidth: 250, maxWidth: 300 }}
      >
        <div className="w-full flex space-x-2 items-center">
          <ColoredAvatars size="medium" user={user} />
          <div className="flex flex-col w-full">
            <div className="flex items-center">
              <span className="text-primary-mid-dark font-medium mr-2 truncate dark:text-dark-light2">
                {user.name}
              </span>
              {vertical && <Pill variant="pink">{vertical.name}</Pill>}
            </div>

            <span className="mt-1 mb-2 text-xs font-medium text-gray-500">
              {totalTrackedTime.hours}h {totalTrackedTime.minutes}m/
              {weeklyWorkCapacity}h
            </span>
          </div>
        </div>
        <div className="pl-2 pr-2 w-full">
          <LinearProgress
            variant="determinate"
            value={completion}
            style={{
              width: '100%',
              borderRadius: '3px'
            }}
          />
        </div>
      </div>
      {/* ====== User Col Ends ====== */}

      <div className="flex w-full" style={{ height: 100 }}>
        {selectedDays.map(item => (
          <WorkloadTableCol
            key={item}
            day={item}
            isLoading={!dayWiseData[item]}
            data={dayWiseData[item] || []}
            weeklyWorkCapacity={weeklyWorkCapacity}
            dailyWorkCapacity={dailyWorkCapacity}
            classes={classes}
            workingDays={workingDays}
            t={t}
            isHovered={isHovered}
          />
        ))}
      </div>
    </div>
  )
}

const WorkloadTableCol = ({
  data,
  isLoading,
  t,
  day,
  // weeklyWorkCapacity,
  dailyWorkCapacity,
  workingDays,
  isHovered,
  classes
}) => {
  let element
  const weekDay = day.split(' ')[0].toLowerCase()
  const theme = useTheme()
  const isDarkMode = theme.palette.type === 'dark'
  // If loading show loading skeleton
  if (isLoading) {
    element = (
      <Skeleton variant="rect" className="w-full rounded-md" height={70} />
    )
  } else if (!workingDays[weekDay]) {
    // If day off
    element = (
      <img
        src={DiagonalLines}
        alt="Diagonal line"
        className="w-full h-full object-contain"
      />
    )
  } else if (data.length === 0) {
    // If NO workload data found display "Free" column
    element = (
      <div
        className={clsx(
          'w-full h-full rounded-md font-medium text-white p-2 text-right text-xs',
          classes.greenColumn
        )}
      >
        {t('FREE')}
      </div>
    )
  } else {
    // If workload data found display data
    const totalAllTasksDuration = data.reduce((total, currentValue) => {
      return total + currentValue.totalDuration
    }, 0)
    const { hours, minutes } = dateAndTimeHelper.getMinutesAndHours(
      totalAllTasksDuration
    )
    const percent = (hours / dailyWorkCapacity) * 100

    element = (
      <CustomTooltip
        classes={classes}
        title={
          <TooltipTitle
            scheduled={{ hours, minutes }}
            dailyWorkCapacity={dailyWorkCapacity}
            workloadData={data}
          />
        }
      >
        <div
          className={clsx(
            'relative w-full rounded-md overflow-hidden cursor-pointer h-full',
            classes.greenColumn
          )}
        >
          <div className="absolute z-10 right-0 text-white p-2 text-xs cursor-pointer text-right">
            {hours}h {minutes}m / {dailyWorkCapacity}h
            {percent > 100 && (
              <span className="block font-medium text-gray-100 text-xs dark:text-dark-light cursor-pointer uppercase">
                {t('OVER_CAPACITY')}
              </span>
            )}
          </div>
          <div
            className={clsx('w-full', classes.columnFill)}
            style={{
              height: `${percent}%`,
              backgroundColor:
                percent > 100
                  ? isDarkMode
                    ? darkenHex('#ef4444', 0.5)
                    : 'rgba(239, 68, 68, 0.8)'
                  : isDarkMode
                  ? darkenHex('#15803d', 0.5)
                  : 'rgba(21, 128, 61, 0.8)'
            }}
          ></div>
        </div>
      </CustomTooltip>
    )
  }

  return (
    <div
      className={clsx(
        'px-2 py-4 flex-1 border-b border-r',
        classes.column,
        isHovered && 'bg-gray-100 dark:bg-dark-main4'
      )}
    >
      {element}
    </div>
  )
}

// const WorkloadCell = (time, tasks) => {
//   let percent = (time * 100) / dayHours
//   let element

//   if (percent === 0) {
//     element = (
//       <div
//         className="w-full rounded-md font-medium text-white p-2 text-right text-xs cursor-pointer"
//         style={{
//           height: '100%',
//           backgroundColor: 'rgb(134, 239, 172)'
//         }}
//       >
//         {t('FREE')}
//       </div>
//     )
//   } else if (percent > 0 && percent <= 100) {
//     element = (
//       <CustomTooltip
//         title={<TooltipTitle time={time} dayHours={dayHours} data={tasks} />}
//       >
//         <div
//           className="relative w-full rounded-md overflow-hidden"
//           style={{ height: '100%' }}
//         >
//           <div className="absolute z-10 right-0 text-white p-2 text-xs cursor-pointer">
//             {getTimeString(time * 3600)}/{Math.round(dayHours)}h
//           </div>
//           <div
//             className="w-full "
//             style={{
//               height: `${100 - percent}%`,
//               backgroundColor: 'rgb(134, 239, 172)'
//             }}
//           ></div>
//           <div
//             className="w-full"
//             style={{
//               height: `${percent}%`,
//               backgroundColor: 'rgba(21, 128, 61, 0.8)'
//             }}
//           ></div>
//         </div>
//       </CustomTooltip>
//     )
//   } else if (percent > 100) {
//     element = (
//       <CustomTooltip
//         title={<TooltipTitle time={time} dayHours={dayHours} data={tasks} />}
//       >
//         <div
//           className="w-full text-right text-white p-2 bg-red-500 rounded-md cursor-pointer"
//           style={{
//             height: `100%`,
//             backgroundColor: 'rgba(239, 68, 68, 0.8)'
//           }}
//         >
//           <span className="cursor-pointer">
//             {' '}
//             {getTimeString((time - dayHours) * 3600)}
//           </span>
//           <span className="block font-medium text-gray-100 text-xs dark:text-dark-light cursor-pointer">
//             OVER CAPACITY
//           </span>
//         </div>
//       </CustomTooltip>
//     )
//   } else {
//     element = null
//   }
//   return element
// }

/**
 * <ColGroup
          heading={`${t('ASSIGNEE')} (${timelineData.length})`}
          sort={false}
          styles={{ minWidth: 250, maxWidth: 300 }}
          loading={loading}
        >
          {visibleData.length ? (
            <div
              className={
                (classes.colWrapper,
                'border-r dark:border-dark-main1 dark:bg-dark-main')
              }
            >
              {visibleData.map((item, index) => (
                <Td
                  className="flex items-center space-x-1 text-xs 2xl:text-sm cursor-pointer whitespace-nowrap truncate"
                  key={item._id}
                  data-id={item._id}
                  index={index}
                  isHovered={hoverIndex === index}
                  onMouseEnter={handleHoverCol}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="w-full">
                    <div className="w-full pl-2 flex space-x-2 items-center">
                      <ColoredAvatars size="medium" user={item} />
                      <div className="flex flex-col w-full">
                        <div className="flex items-center leading-3">
                          <span className="text-primary-mid-dark font-medium mr-2 truncate dark:text-dark-light2">
                            {item.name}
                          </span>{' '}
                          {userVertical(item)}
                        </div>

                        <span className="mt-1 mb-2 text-xs  font-medium">
                          {getTimeString(
                            Object.values(item.workload).reduce(
                              (acc, obj) => acc + obj.time,
                              0
                            ) * 3600
                          )}
                          /{capacity}h
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 pl-2  pr-2 w-full">
                      <LinearProgress
                        variant="determinate"
                        value={
                          (Object.values(item.workload).reduce(
                            (acc, obj) => acc + obj.time,
                            0
                          ) *
                            100) /
                          capacity
                        }
                        style={{
                          width: '100%',
                          borderRadius: '3px'
                        }}
                      />
                    </div>
                  </div>
                </Td>
              ))}
            </div>
          ) : (
            <Td>
              <span className="text-sm">{t('NO_DATA_FOUND')}</span>
            </Td>
          )}
        </ColGroup>
        <div
          className={clsx(
            classes.tableRight,
            'flex-1 flex overflow-x-auto w-full'
          )}
        >
          {selectedDays?.map(day => (
            <ColGroup
              heading={
                <div className="flex justify-between">
                  {' '}
                  <span className="font-medium capitalize">
                    {day.split(' ')[0]}
                  </span>
                  <span className="font-base tect-xs text-gray-500 capitalize dark:text-dark-light">
                    {day.split(' ').slice(2, 3).join(' ')}
                  </span>
                </div>
              }
              classes={{ root: classes.tableCell }}
              loading={loading}
            >
              <div className="border-r dark:border-dark-main1">
                {visibleData.map((item, index) => (
                  <Td
                    className="text-xs p-0"
                    key={item._id}
                    data-id={item._id}
                    index={index}
                    isHovered={hoverIndex === index}
                    onMouseEnter={handleHoverCol}
                    onMouseLeave={handleMouseLeave}
                  >
                    {!workingDays[day.split(' ')[0].toLowerCase()] ? (
                      // <CustomTooltip>
                      <div
                        className="w-full"
                        style={{ height: '100%', overflow: 'hidden' }}
                      >
                        <img
                          src={DiagonalLines}
                          alt="Diagonal line"
                          className="w-full h-full object-contain"
                        />
                      </div>
                    ) : (
                      // </CustomTooltip>
                      timePercentage(
                        item.workload[day]?.time,
                        item.workload[day]?.tasks
                      )
                    )}
                  </Td>
                ))}
              </div>
            </ColGroup>
          ))}
        </div>
 */

const CustomTooltip = ({ classes, ...rest }) => {
  return (
    <Tooltip
      placement="top"
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      {...rest}
    />
  )
}

const TooltipTitle = ({ scheduled, dailyWorkCapacity, workloadData }) => {
  const { t } = useTranslation()
  const totalCompletedTasks = workloadData.filter(item => item.markAsComplete)

  const getSingleTaskDurationElm = totalDuration => {
    const { hours, minutes } = dateAndTimeHelper.getMinutesAndHours(
      totalDuration
    )

    return (
      <span className="text-xs font-medium text-gray-500 whitespace-nowrap pl-2 dark:text-dark-light">
        {hours}h {minutes}m
      </span>
    )
  }

  return (
    <div className="p-4" style={{ width: 260 }}>
      <header>
        <div className="flex justify-between items-center mb-1">
          <span className="text-smaller font-medium text-gray-500 dark:text-dark-light">
            {t('SCHEDULED')}
          </span>
          <span className="text-smaller font-medium text-gray-500 dark:text-dark-light">
            {scheduled.hours}h {scheduled.minutes}m
          </span>
        </div>

        <div className="flex justify-between items-center mb-1">
          <span className="text-smaller font-medium text-gray-500 dark:text-dark-light">
            {t('CAPACITY')}
          </span>
          <span className="text-smaller font-medium text-gray-500 dark:text-dark-light">
            {dailyWorkCapacity}h
          </span>
        </div>

        <div className="flex justify-between items-center mb-1">
          <span className="text-smaller font-medium text-gray-500 dark:text-dark-light">
            {t('TOTAL_COMPLETED_TASKS')}
          </span>
          <span className="text-smaller font-medium text-gray-500 dark:text-dark-light">
            {totalCompletedTasks.length}
          </span>
        </div>
      </header>

      <hr className="my-2" />

      <div>
        <h6 className="font-semibold text-smaller mb-2">{t('TASKS')}</h6>
        <ul>
          {workloadData.map((item, index) => (
            <li key={index} className="py-1 flex justify-between items-start">
              <div className="flex space-x-1 items-center text-smaller text-gray-600 dark:text-dark-light">
                <CheckCircle
                  className={clsx(
                    'w-4 h-4',
                    item.markAsComplete ? 'text-green-400' : 'text-gray-400'
                  )}
                  style={{ flexShrink: 0, marginRight: '4px' }}
                />
                <span>{item.title}</span>
              </div>
              {/* <span className="text-xs font-medium text-gray-500 whitespace-nowrap pl-2 dark:text-dark-light">
              </span> */}
              {getSingleTaskDurationElm(item.totalDuration)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default WorkloadTableRow
