import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'
import { Button, useTheme } from '@material-ui/core'
import Select, { components } from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useSelector, useDispatch } from 'react-redux'
import {
  getListViewTasks
  // getTaskByWorkspaceBoardId
} from 'thunks/task/actions'
// import { startTaskTimer } from 'thunks/Tracking/action'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import { DASHBOARD } from 'thunks/userDashboard/reducer'
import useTimerFunctions from './useTimerFunctions'
// import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { debounce, triggerOnScrollToBottom } from 'utils'
import { useTranslation } from 'react-i18next'

const infiniteScroll = triggerOnScrollToBottom()
const debounceFn = debounce()

const StartTimerModal = ({ onClose, open }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const {
    fetchedFirstTime,
    loading: projectsLoading,
    data: allProjects
  } = useSelector(state => state.userWorkspaces)

  const [projectsOptions, setProjectsOptions] = useState([])
  const [taskOptions, setTaskOptions] = useState({
    loading: true,
    data: []
  })
  const [selectedProject, setSelectedProject] = useState({})
  const [selectedTask, setSelectedTask] = useState({})
  const {
    // data: userTasksData,
    // loading: { all: loadingTasks }
    data: userNewTasksData,
    listViewTasks
  } = useSelector(state => state.userTasks)
  const { startTimer } = useTimerFunctions()
  const [allTasksData, setAllTasksData] = useState([])
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')

  // useEffect(() => {
  //   if (!fetchedFirstTime) {
  //     dispatch(fetchAllWorkspacesForCurrentUser())
  //   }
  // }, [fetchedFirstTime])

  useLayoutEffect(() => {
    if (open) {
      setSelectedProject({})
      setSelectedTask({})
      setTaskOptions([])
      setPage(0)
    }
  }, [open])

  useEffect(() => {
    if (allProjects.length) {
      setProjectsOptions(
        allProjects.map(item => ({
          label: item.name,
          value: item._id,
          boardId: item.defaultBoard?._id
        }))
      )
    }
  }, [allProjects])

  useEffect(() => {
    const tasks = listViewTasks.data
      .filter(taskId => userNewTasksData[taskId])
      .map(taskId => userNewTasksData[taskId])

    setAllTasksData(tasks)
  }, [listViewTasks, userNewTasksData])

  useEffect(() => {
    setTaskOptions({
      loading: false,
      data: allTasksData.map(item => ({
        label: item.title,
        value: item._id
      }))
    })
  }, [allTasksData])

  useEffect(() => {
    if (selectedProject.boardId) {
      setTaskOptions({ data: [], loading: true })
      setSelectedTask({})
      handleFetchListViewTasks()
      // dispatch(getTaskByWorkspaceBoardId(selectedProject.boardId))
    }
  }, [selectedProject.value, search])

  const handleFetchListViewTasks = (cb, pageCount = 0) => {
    dispatch(
      getListViewTasks(
        {
          data: {
            workspace: selectedProject.value,
            workspaceBoard: selectedProject.boardId,
            search: search || null
          },
          query: { limit: 40, page: pageCount, type: 'list' }
        },
        cb
      )
    )
  }

  const handleStartTimer = () => {
    let data = {
      workspace: selectedProject.value,
      workspaceBoard: selectedProject.boardId,
      task: selectedTask.value,
      type: 'task'
    }

    setLoading(true)
    startTimer(data, selectedTask.label, () => {
      setLoading(false)
      onClose()
    })
  }

  const handleSelectProject = newVal => {
    setSelectedProject(newVal)
  }

  const handleSelectTask = newVal => {
    setSelectedTask(newVal)
  }

  const loadMoreTasks = e => {
    infiniteScroll(e, {
      cb: activateInfiniteScroll => {
        setTaskOptions(prev => ({ ...prev, loading: true }))
        handleFetchListViewTasks((res, err) => {
          activateInfiniteScroll()
          setTaskOptions(prev => ({ ...prev, loading: false }))
        }, page + 1)
        setPage(prev => prev + 1)
      }
    })
  }

  const handleSearchTasks = (input, eventObj) => {
    if (eventObj.action === 'input-change') {
      debounceFn(() => {
        setSearch(input)
      }, 300)
    } else if (eventObj.action === 'menu-close') {
      setSearch('')
    }
  }

  return (
    <CustomModal onClose={onClose} open={open} size="small" overflow="visible">
      <CustomModalHeader heading={t('START_TIMER')} handleClose={onClose} />
      <CustomModalBody overflow="visible">
        <div className="mb-4">
          <label className="text-sm font-medium text-primary-mid-dark-2 mb-1 inline-block dark:text-dark-light">
            {t('SELECT_PROJECT')}
          </label>
          <Select
            options={projectsOptions}
            value={selectedProject}
            className="w-full"
            styles={reactSelectCustomStyles(theme)}
            onChange={handleSelectProject}
            isLoading={projectsLoading}
          />
        </div>

        {selectedProject && (
          <div className="mb-8">
            <label className="text-sm font-medium text-primary-mid-dark-2 mb-1 inline-block dark:text-dark-light">
              {t('SELECT_TASK')}
            </label>
            <Select
              options={taskOptions.data}
              value={selectedTask}
              className="w-full"
              styles={reactSelectCustomStyles(theme)}
              onChange={handleSelectTask}
              isLoading={taskOptions.loading}
              components={{ MenuList: CustomMenu }}
              selectProps={{
                onScroll:
                  listViewTasks.metadata.total > listViewTasks.data.length
                    ? loadMoreTasks
                    : undefined
              }}
              maxMenuHeight={200}
              onInputChange={handleSearchTasks}
            />
          </div>
        )}
      </CustomModalBody>
      <CustomModalFooter>
        <Button onClick={onClose} variant="outlined">
          {t('CLOSE')}
        </Button>
        <LoadingButton
          onClick={handleStartTimer}
          loading={loading}
          disabled={!selectedProject.value || !selectedTask.value}
        >
          {t('START_TIMER')}
        </LoadingButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

const CustomMenu = props => {
  return (
    <components.MenuList
      {...props}
      innerProps={{
        ...props.innerProps,
        onScroll: props.selectProps.selectProps.onScroll
      }}
    >
      {props.children}
    </components.MenuList>
  )
}

export default StartTimerModal
