import React, { useEffect, useState } from 'react'
import taskSidePanelStyles from './taskSidePanelStyles'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import clsx from 'clsx'
import { ReactComponent as VideoIcon } from 'static/svg/video.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { ReactComponent as AudioIcon } from 'static/svg/music-note.svg'
import { getFileType } from 'utils'
import { CustomTooltip, FilePreview } from 'global/globalComponents'
import AttachmentDropDown from '../AttachmentDropDown'

const AttachmentImage = ({
  fileUrl,
  handleDeleteAttachment,
  allFileUrls,
  fileIndex
}) => {
  const classes = taskSidePanelStyles()
  const fileType = getFileType(fileUrl)
  const [fileName, setFileName] = useState('')
  const [filePreviewModal, setFilePreviewModal] = useState({
    open: false,
    files: [],
    fileIndex: 0
  })
  const [attachmentDropdownData, setAttachmentDropdownData] = useState({
    anchorEl: null,
    data: {}
  })

  useEffect(() => {
    if (!fileUrl) return

    const arr = fileUrl.split('/')
    setFileName(arr[arr.length - 1])
  }, [fileUrl])

  const toggleAttachmentDropdown = e => {
    if (attachmentDropdownData.anchorEl) {
      setAttachmentDropdownData(prev => ({ ...prev, anchorEl: null }))
    } else {
      e.stopPropagation()
      setAttachmentDropdownData({ anchorEl: e.currentTarget, data: fileUrl })
    }
  }

  const toggleFilePreview = () => {
    if (filePreviewModal.open) {
      setFilePreviewModal(prev => ({ ...prev, open: false }))
    } else {
      const files = allFileUrls.map(fileUrl => {
        const file = {}

        const arr = fileUrl.split('/')
        file.name = arr[arr.length - 1]
        file.type = getFileType(fileUrl)
        file.url = fileUrl
        file.extension = fileUrl.slice(fileUrl.lastIndexOf('.'))

        return file
      })

      setFilePreviewModal(prev => ({ open: true, files, fileIndex }))
    }
  }

  return (
    <>
      <div className="relative mr-2 mb-4">
        <div
          className={clsx(classes.attachmentWrapper, {
            active: Boolean(attachmentDropdownData.anchorEl)
          })}
          onClick={toggleFilePreview}
        >
          {fileType === 'image' ? (
            <img src={fileUrl} alt="" />
          ) : fileType === 'audio' ? (
            <span className="text-2xl text-gray-500 dark:text-dark-light">
              <AudioIcon className="dark:text-dark-light" />
            </span>
          ) : fileType === 'video' ? (
            <span className="text-2xl text-gray-500 dark:text-dark-light">
              <VideoIcon className="dark:text-dark-light" />
            </span>
          ) : (
            <span className="text-2xl text-gray-500 dark:text-dark-light">
              <FileIcon className="dark:text-dark-light" />
            </span>
          )}
          <ExpandMoreRoundedIcon onClick={toggleAttachmentDropdown} />
        </div>
        <CustomTooltip title={fileName} placement="top">
          <div className="text-xs truncate w-24 dark:text-dark-light">
            {fileName}
          </div>
        </CustomTooltip>

        <FilePreview
          open={filePreviewModal.open}
          files={filePreviewModal.files}
          fileIndex={filePreviewModal.fileIndex}
          onClose={toggleFilePreview}
        />
      </div>
      <AttachmentDropDown
        open={Boolean(attachmentDropdownData.anchorEl)}
        onClose={toggleAttachmentDropdown}
        anchorEl={attachmentDropdownData.anchorEl}
        data={attachmentDropdownData.data}
        handleDeleteAttachment={handleDeleteAttachment}
        handlePreviewImage={toggleFilePreview}
        style={{ zIndex: 2000 }}
      />
    </>
  )
}

export default AttachmentImage
