import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from 'store/store'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { BrowserRouter } from 'react-router-dom'
// import { fetchNewReleaseInfo } from 'thunks/user/actions'
// import packageJson from '../package.json'

const store = configureStore()

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn:
      'https://4bc84c5545104b77a57b2ac86f4b0771@o577793.ingest.sentry.io/5739538',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })

  // replace console.* for disable log on production
  // console.error = () => {}
  // console.debug = () => {}
  // console.log = () => {}
  // console.warning = () => {}
}

// fetchNewReleaseInfo((res, err) => {
//   if (!err) {
//     localStorage.setItem('app_version', res.appVersion)
//   }
// })

// let savedVersion = localStorage.getItem('app_version')
// if (!savedVersion || savedVersion !== packageJson.version) {
//   localStorage.setItem('app_version', packageJson.version)
//   window.location.reload(true)
// }
// This will cause a SyntaxError

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
