import React from 'react'
import { Button } from '@material-ui/core'
import { LOGIN } from 'thunks/login/reduers'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const LandingServicesContainerHeader = () => {
  const meData = useSelector(state => state.me)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch({ type: LOGIN.LOGOUT })
  }

  return (
    <div className="px-6 py-2 flex items-center justify-end bg-white border-b border-t">
      {meData.data.path === '/client/dashboard' && (
        <Button
          onClick={() => history.push(meData.data.path)}
          variant="outlined"
          style={{ marginRight: 16 }}
          button
        >
          Go to dashboard
        </Button>
      )}
      <Button
        onClick={handleLogout}
        style={{ marginRight: 16 }}
        variant="outlined"
      >
        Logout
      </Button>
    </div>
  )
}

export default LandingServicesContainerHeader
