export const TAGS = {
  LOADING: 'TAGS_LOADING',
  FETCHED: 'ALL_TAGS_FETCHED',
  ADD_ONE_TAG: 'ADD_ONE_TAG',
  DELETE_ONE_TAG: 'DELETE_ONE_TAG',
  ERROR: 'TAG_ERROR',
  UPDATE_ONE_TAG: 'UPDATE_ONE_TAG',
  OPEN_CURRENT_WORKSPACE_TAGS: 'OPEN_CURRENT_WORKSPACE_TAGS',
  REMOVE_ONE_TAG: 'REMOVE_ONE_TAG'
}

const initialState = {
  error: false,
  loading: false,
  data: [],
  currentWorkspaceTags: [],
  fetched: false
}

export const tagReducer = (state = initialState, action) => {
  const getDataIndex = (id) => state.data.findIndex((i) => i._id === id)
  const getCurrentIndex = (id) =>
    state.currentWorkspaceTags.findIndex((i) => i._id === id)
  switch (action.type) {
    case TAGS.LOADING:
      return { ...state, loading: true, error: false }

    case TAGS.FETCHED:
      return { ...state, loading: false, fetched: true, data: action.payload }

    case TAGS.ADD_ONE_TAG:
      let currentData = [...state.currentWorkspaceTags]
      let Data = [...state.data]
      if (action.payload.response.type === 'workspaceLevelTag') {
        if (!action.payload.new) {
          currentData[getCurrentIndex(action.payload.response._id)] =
            action.payload.response
        } else {
          currentData = [...state.currentWorkspaceTags, action.payload.response]
        }
      } else {
        if (!action.payload.new) {
          Data[getDataIndex(action.payload.response._id)] =
            action.payload.response
        } else {
          Data = [...state.data, action.payload.response]
        }
      }
      return {
        ...state,
        loading: false,
        data: Data,
        currentWorkspaceTags: currentData
      }

    case TAGS.DELETE_ONE_TAG:
      let currentUpdatedData = [...state.currentWorkspaceTags]
      let updatedData = [...state.data]
      if (action.payload.type === 'workspaceLevelTag') {
        currentUpdatedData = state.currentWorkspaceTags.filter(
          (item) => item._id !== action.payload._id
        )
      } else {
        updatedData = state.data.filter(
          (item) => item._id !== action.payload._id
        )
      }
      return {
        ...state,
        loading: false,
        data: updatedData,
        currentWorkspaceTags: currentUpdatedData
      }
    case TAGS.UPDATE_ONE_TAG:
      let currentUpdatedData1 = [...state.currentWorkspaceTags]
      let updatedData1 = [...state.data]
      if (action.payload.type === 'workspaceLevelTag') {
        currentUpdatedData1[getCurrentIndex(action.payload._id)] =
          action.payload
      } else {
        updatedData1[getDataIndex(action.payload._id)] = action.payload
      }
      return {
        ...state,
        loading: false,
        data: updatedData1,
        currentWorkspaceTags: currentUpdatedData1
      }
    // case TAGS.REMOVE_ONE_TAG:
    //   let currentUpdatedData2 = [...state.currentWorkspaceTags]
    //   let updatedData2 = [...state.data]
    //   if (action.payload.type === 'workspaceLevelTag') {
    //     currentUpdatedData2[getCurrentIndex(action.payload._id)] =
    //       action.payload
    //   } else {
    //     updatedData2[getDataIndex(action.payload._id)] = action.payload
    //   }
    //   return {
    //     ...state,
    //     loading: false,
    //     data: updatedData2,
    //     currentWorkspaceTags: currentUpdatedData2
    //   }
    case TAGS.OPEN_CURRENT_WORKSPACE_TAGS:
      return {
        ...state,
        currentWorkspaceTags: action.payload
      }
    default:
      return state
  }
}
export default tagReducer
