import React, { useEffect, useState, useRef } from 'react'
import { Box, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import InvoiceTable from 'components/Invoices/InvoiceTable/InvoiceTable'
import { fetchCustomInvoices } from 'thunks/invoices/actions'
import {
  Switch,
  useHistory,
  useRouteMatch,
  Route,
  useParams
} from 'react-router-dom'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import { Skeleton } from '@material-ui/lab'
import ReactToPrint from 'react-to-print'
import { useTranslation } from 'react-i18next'
import PreviewInvoiceTemplate1 from 'components/_shared/InvoiceTemplates/InvoiceTemplate1/PreviewInvoiceTemplate1'
import PreviewInvoiceTemplate2 from 'components/_shared/InvoiceTemplates/InvoiceTemplate2/PreviewInvoiceTemplate2'

// const totalInvoicesToShow = 10
export default function ClientInvoices() {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  useEffect(() => {
    dispatch(fetchCustomInvoices())
  }, [])

  return (
    <OuterContentWrapper>
      <Switch>
        <Route path={url} exact>
          <Invoices />
        </Route>
        <Route path={url + '/:invoiceId/preview'} exact>
          <InvoicePreview />
        </Route>
      </Switch>
    </OuterContentWrapper>
  )
}

const Invoices = () => {
  const { t } = useTranslation()
  // const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const invoices = useSelector(state => state.clientInvoices)
  const [visibleInvoices, setVisibleInvoices] = useState({
    loading: true,
    data: []
  })
  const history = useHistory()
  const { url } = useRouteMatch()
  const search = useSelector(state => state.generalAppState.headerSearch)

  useEffect(() => {
    if (!invoices.loading) {
      let arr = invoices.data
        .filter(el => el.status !== 'draft')
        .filter(item => {
          let items = item.invoiceItems
            .map(item => item.name?.toLowerCase())
            .join(' ')
          return (
            item.status.toLowerCase().includes(search.toLowerCase()) ||
            items.includes(search.toLowerCase())
          )
        })

      // const sliced = arr.splice(page * totalInvoicesToShow, totalInvoicesToShow)
      setVisibleInvoices({ loading: false, data: arr })
      // setPaginationTotalDataCount(myPayments.data.length)
    }
  }, [invoices, search])

  const openInvoicePreview = invoice => {
    history.push(`${url}/${invoice._id}/preview`)
  }

  return (
    <Box>
      <header className="mb-6 2xl:mb-8">
        <SectionHeading>{t('INVOICES')}</SectionHeading>
      </header>
      {search !== '' && (
        <p className="text-sm mb-2 ml-1 text-gray-500 font-medium dark:text-dark-light2">
          {t('SEARCH_RESULT')}:{' '}
          <span className="text-primary-dark dark:text-dark-light">
            {search}
          </span>
        </p>
      )}

      <InvoiceTable
        invoices={visibleInvoices.data}
        openInvoicePreview={openInvoicePreview}
        loading={visibleInvoices.loading}
        elmFor="client"
        clientCol={false}
      />
    </Box>
  )
}

const InvoicePreview = () => {
  const { t } = useTranslation()
  const [invoice, setInvoice] = useState({
    loading: true,
    data: {}
  })
  const history = useHistory()
  const invoices = useSelector(state => state.clientInvoices)
  const { invoiceId } = useParams()
  const invoiceRef = useRef()

  useEffect(() => {
    if (!invoices.loading) {
      const inv = invoices.data.find(item => item._id === invoiceId)

      if (inv) {
        setInvoice({ loading: false, data: inv })
      }
    }
  }, [invoices])

  const handleGoBack = () => {
    history.goBack()
  }

  const handlePayInvoice = () => {
    let a = document.createElement('a')
    a.target = '_blank'
    a.href = `/i/${invoice.data._id}/checkout?vtID=${invoice.data.viewToken}`
    a.click()
  }

  return (
    <div
      className="px-2 sm:px-10 w-97 sm:w-90 mx-auto"
      style={{ maxWidth: 700 }}
    >
      {invoice.loading ? (
        <>
          <Skeleton
            variant="rect"
            width={650}
            height={600}
            className="rounded"
          />
        </>
      ) : (
        <>
          <div className="flex flex-wrap items-center justify-between mb-2">
            <Button
              variant="outlined"
              startIcon={<ArrowLeftIcon />}
              onClick={handleGoBack}
            >
              {t('GO_BACK')}
            </Button>

            <div className="flex items-center space-x-2">
              <div className="text-center my-4">
                <ReactToPrint
                  trigger={() => {
                    return (
                      <Button
                        // size="small"
                        // className="px-3 py-2 rounded text-gray-100 text-sm inline-flex items-center gap-2"
                        style={{
                          backgroundColor: '#222',
                          color: '#fff',
                          border: 'none'
                        }}
                      >
                        {t('DOWNLOAD_PDF')}
                      </Button>
                    )
                  }}
                  content={() => invoiceRef.current}
                  documentTitle={`${
                    invoice.data.customInvoiceNumber ??
                    invoice.data.invoiceNumber
                  }_invoice`}
                />
              </div>

              {invoice.data.status !== 'paid' && (
                <Button onClick={handlePayInvoice}>Pay now</Button>
              )}
            </div>
          </div>

          <div ref={invoiceRef}>
            {invoice.data.invoiceTemplate === 2 ? (
              <PreviewInvoiceTemplate2 invoiceData={invoice.data} />
            ) : (
              <PreviewInvoiceTemplate1 invoiceData={invoice.data} />
            )}
          </div>
        </>
      )}
    </div>
  )
}
