const getTimeString = (time) => {
  if (time === 0) return 0
  const hours = Math.floor(time / (60 * 60))
  const minutes = Math.round(Math.floor(time % (60 * 60)) / 60)
  const seconds = Math.ceil(Math.floor(time % (60 * 60)) % 60)
  if (hours !== 0) {
    return minutes !== 0 ? `${hours}h ${formatTime(minutes)}m` : `${hours}h 00m`
  } else if (hours === 0 && minutes === 0) {
    return `${seconds}s`
  } else {
    // return seconds === 0 ? `${minutes}m` : `${minutes}m ${seconds}s`
    return `${minutes}m`
  }
}

const formatTime = (num) => {
  return num < 10 ? `0${num}` : num
}

export default getTimeString
