import userRoles, {
  getAgencyRoleOptions,
  getClientRoleOptions
} from './userRoles'
import Pill from 'global/globalComponents/Pill/Pill'
// import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const obj = {
  [userRoles.AGENCY_ADMIN]: 'purple',
  [userRoles.CLIENT_ADMIN]: 'purple',
  [userRoles.AGENCY_MANAGER]: 'blue',
  [userRoles.CLIENT_MANAGER]: 'blue',
  [userRoles.AGENCY_VIEWER]: 'red',
  [userRoles.CLIENT_VIEWER]: 'red'
}

const getRoleBadge = (roleNum, style) => {
  let roles = [
    ...getAgencyRoleOptions(),
    ...getClientRoleOptions(),
    { value: 20, label: t('SUPER_ADMIN') },
    { value: 30, label: t('CLIENT_OWNER') },
    { value: 15, label: t('PROJECT_OWNER') }
  ]
  const role = roles.find(obj => obj.value === roleNum)

  return (
    <Pill
      style={style}
      className="capitalize"
      variant={obj[role.value] ?? 'yellow'}
    >
      {role?.label}
    </Pill>
  )
}

export default getRoleBadge
