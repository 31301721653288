import { deleteCookie, getCookie, setCookie } from './manageCookies'
import getParsedJwt from './parseJwt'

export const storeRefreshToken = token => {
  const parsedToken = getParsedJwt(token)

  if (parsedToken) {
    setCookie('refreshToken', token, new Date(parsedToken.exp * 1000))
  }
}

export const storeAccessToken = token => {
  const parsedToken = getParsedJwt(token)

  if (parsedToken) {
    setCookie('accessToken', token, new Date(parsedToken.exp * 1000))
  }
}

export const getRefreshToken = () => {
  return getCookie('refreshToken')
}

export const getAccessToken = () => {
  return getCookie('accessToken')
}

export const removeAuthTokens = () => {
  deleteCookie('accessToken')
  deleteCookie('refreshToken')
}
