import React, { useEffect, useRef, useState } from 'react'
import {
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { Button, TextField, useTheme } from '@material-ui/core'
import ct from 'countries-and-timezones'
import Select from 'react-select'
import Countries from 'static/countries/Countries'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { LoadingButton } from 'global/globalComponents'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import PersonAvatar from 'global/globalComponents/PersonAvtar/PersonAvtar'
import ImageUploadPopover from 'global/globalComponents/ImageUploadPopover/ImageUploadPopover'
import { useTranslation } from 'react-i18next'
import { whitelabel } from 'thunks/whiteLabel/action'
import Currency from 'static/Currency/Currency'

const defaultImage =
  'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'

const currencyOptions = Object.values(Currency)

const SetupAccount = ({ onClose }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const imageUploadPopoverAnchorEl = useRef(null)
  const [imageUploadPopoverStatus, setImageUloadPopoverStatus] = useState(false)
  const [savingLoading, setSavingLoading] = useState(false)
  const [timezoneOptions, setTimezoneOptions] = useState([])
  const [formData, setFormData] = useState({
    defaultCurrency: 'usd',
    brand: {
      favicon: '',
      largeLogo: '',
      smallLogo: '',
      brandName: ''
    },
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    defaultTimeZone: '',
    facebookurl: '',
    linkedinUrl: '',
    twitterurl: '',
    pinteresturl: ''
  })
  const [errorFields, setErrorFields] = useState({})

  const handleImageUploadPopover = () => {
    setImageUloadPopoverStatus(prev => !prev)
  }

  useEffect(() => {
    const countries = ct.getAllCountries()
    let arr = []
    Object.values(countries).forEach(country => {
      arr = arr.concat(country.timezones)
    })
    if (arr.length > 0) {
      setTimezoneOptions(
        [...new Set(arr)].sort().map(el => (el = { value: el, label: el }))
      )
    }
  }, [])

  const timezoneHandler = e => {
    setFormData(prev => ({ ...prev, defaultTimeZone: e }))
  }

  const handleAddressChange = e => {
    const { name, value } = e.target
    let items = { ...formData }
    let item = items.address
    items.address = { ...item, [name]: value }
    setFormData(items)
  }

  const handleCurrencyChange = e => {
    setFormData(prev => ({ ...prev, defaultCurrency: e.code }))
  }

  const handleCountryChange = e => {
    let items = { ...formData }
    let item = items.address
    items.address = { ...item, country: e }

    setFormData(prev => ({
      ...prev,
      address: { ...prev.address, country: e }
    }))
  }

  const handleImageChange = value => {
    let items = { ...formData }
    let item = items.brand
    items.brand = {
      ...item,
      favicon: value,
      smallLogo: value,
      largeLogo: value
    }
    setFormData(items)
  }

  const whitelabelCallback = (res, err) => {
    if (err) {
      setSavingLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(t('SAVED_SUCCESSFULLY')))
      onClose()
    }
  }

  const handleSave = () => {
    const { brandName: companyName } = formData.brand
    const { city, country, line1, state, zipCode } = formData.address
    const { defaultTimeZone } = formData
    if (
      !companyName ||
      !city ||
      !country.label ||
      !line1 ||
      !state ||
      !zipCode ||
      !defaultTimeZone
    ) {
      setErrorFields({
        brandName: !companyName,
        city: !city,
        country: !country.label,
        line1: !line1,
        state: !state,
        zipCode: !zipCode,
        defaultTimeZone: !defaultTimeZone
      })

      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS'))
      )
    }

    setErrorFields({})
    setSavingLoading(true)
    dispatch(
      whitelabel(
        {
          id: meData._id,
          body: {
            ...formData,
            defaultTimeZone: formData.defaultTimeZone.value
          }
        },
        whitelabelCallback
      )
    )
  }

  const handleImageUploadFromSystem = e => {
    const file = e.target.files[0]
    const name = e.target.name
    const imgReader = new FileReader()
    imgReader.onloadend = function () {
      if (file) {
        setFormData(prev => ({
          ...prev,
          brand: {
            ...prev.brand,
            [name]: imgReader.result
          }
        }))
      }
    }
    imgReader.readAsDataURL(file)
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (e.target.name === 'brandName') {
      var item = { ...formData }
      var items = { ...item.brand, brandName: e.target.value }
      item.brand = items
      setFormData(item)
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }))
    }
  }

  return (
    <>
      <CustomModalBody overflow="visible" className="pb-16 mb-9">
        <form className="text-xs md:text-smaller 2xl:text-sm">
          <div className="">
            <div className="hidden">
              {['favicon', 'smallLogo', 'largeLogo'].map(i => (
                <input
                  name={i}
                  key={i}
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={i}
                  multiple={false}
                  type="file"
                  onChange={handleImageUploadFromSystem}
                />
              ))}
            </div>

            <div className="flex flex-wrap gap-8 mb-6 lg:mb-8">
              <div>
                <FormLabel className="pb-2">{t('FAVICONS')}</FormLabel>
                <label htmlFor="favicon">
                  <PersonAvatar
                    img={
                      formData.brand?.favicon?.length
                        ? formData.brand.favicon
                        : defaultImage
                    }
                  />
                </label>
              </div>
              <div>
                <FormLabel className="pb-2">{t('ICON')}</FormLabel>
                <label htmlFor="smallLogo">
                  <PersonAvatar
                    img={
                      formData.brand?.smallLogo?.length
                        ? formData.brand.smallLogo
                        : defaultImage
                    }
                  />
                </label>
              </div>
              <div>
                <FormLabel className="pb-2">
                  {t('LOGO')}{' '}
                  <span
                    style={{
                      color: theme.palette.type === 'light' ? 'grey' : '#ccc',
                      fontSize: '10px'
                    }}
                  >
                    ({t('ICON_TEXT')})
                  </span>
                </FormLabel>
                <label htmlFor="largeLogo">
                  <PersonAvatar
                    img={
                      formData.brand?.largeLogo?.length
                        ? formData.brand.largeLogo
                        : defaultImage
                    }
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-between items-center gap-4">
            <div className="w-full sm:flex-1">
              <FormLabel htmlFor="brandName" required>
                {t('ORGANIZATION_NAME')}
              </FormLabel>
              <TextField
                required
                placeholder={t('ENTER_YOUR_ORGANIZATION_NAME')}
                name="brandName"
                inputProps={{ id: 'brandName' }}
                className="w-full"
                onChange={handleChange}
                value={formData.brand.brandName}
                error={errorFields.brandName}
                style={{
                  background:
                    theme.palette.type !== 'light'
                      ? theme.custom.darkMode.background
                      : ''
                }}
              />
            </div>

            <div className="w-full sm:flex-1">
              <FormLabel required>{t('TIMEZONE')}</FormLabel>
              <Select
                isSearchable={true}
                placeholder={t('TIMEZONE')}
                onChange={timezoneHandler}
                maxMenuHeight={350}
                value={formData.defaultTimeZone}
                options={timezoneOptions}
                styles={{
                  ...reactSelectCustomStyles(theme, {
                    control: errorFields.defaultTimeZone
                      ? { borderColor: 'red' }
                      : {}
                  })
                }}
              />
            </div>
          </div>

          <div className="mt-4">
            <FormLabel required htmlFor="companyName">
              {t('ADDRESS')}
            </FormLabel>
            <div className="grid gap-4 grid-cols-8">
              <TextField
                required
                placeholder={t('LINE_1')}
                name="line1"
                inputProps={{ id: 'companyName' }}
                className="w-full col-span-4"
                value={formData.address.line1}
                error={errorFields.line1}
                onChange={handleAddressChange}
                style={{
                  background:
                    theme.palette.type !== 'light'
                      ? theme.custom.darkMode.background
                      : ''
                }}
              />
              <TextField
                required
                placeholder={t('LINE_2_OPTIONAL')}
                name="line2"
                value={formData.address.line2}
                error={errorFields.line2}
                className="w-full col-span-4"
                onChange={handleAddressChange}
                style={{
                  background:
                    theme.palette.type !== 'light'
                      ? theme.custom.darkMode.background
                      : ''
                }}
              />
              <TextField
                required
                placeholder={t('CITY')}
                value={formData.address.city}
                error={errorFields.city}
                name="city"
                className="col-span-4 md:col-span-2"
                onChange={handleAddressChange}
                style={{
                  background:
                    theme.palette.type !== 'light'
                      ? theme.custom.darkMode.background
                      : ''
                }}
              />
              <TextField
                required
                value={formData.address.state}
                error={errorFields.state}
                name="state"
                placeholder={t('STATE')}
                className="col-span-4 md:col-span-2"
                onChange={handleAddressChange}
                style={{
                  background:
                    theme.palette.type !== 'light'
                      ? theme.custom.darkMode.background
                      : ''
                }}
              />

              <Select
                className="col-span-4 md:col-span-2 text-sm"
                placeholder={t('COUNTRY')}
                isSearchable={true}
                options={Countries}
                value={formData.address.country}
                maxMenuHeight={250}
                onChange={handleCountryChange}
                menuPlacement="top"
                styles={{
                  ...reactSelectCustomStyles(theme, {
                    control: errorFields.country ? { borderColor: 'red' } : {}
                  })
                }}
              />
              <TextField
                required
                placeholder={t('ZIP_CODE')}
                name="zipCode"
                value={formData.address.zipCode}
                error={errorFields.zipCode}
                className="col-span-4 md:col-span-2"
                onChange={handleAddressChange}
                type="text"
                style={{
                  background:
                    theme.palette.type !== 'light'
                      ? theme.custom.darkMode.background
                      : ''
                }}
              />
            </div>
          </div>

          <div className="mt-4">
            <FormLabel>{t('SET_CURRENCY')}</FormLabel>
            <Select
              styles={reactSelectCustomStyles(theme)}
              options={currencyOptions}
              getOptionLabel={option => option.code.toUpperCase()}
              getOptionValue={option => option.code.toLowerCase()}
              onChange={handleCurrencyChange}
              value={currencyOptions.find(
                c => c.code === formData.defaultCurrency
              )}
              maxMenuHeight={150}
              placeholder={t('SELECT')}
              className="border-0 border-none w-1/2"
            />
          </div>

          <ImageUploadPopover
            onClose={handleImageUploadPopover}
            open={imageUploadPopoverStatus}
            anchorEl={imageUploadPopoverAnchorEl.current}
            profileImageChange={handleImageChange}
          />
        </form>
      </CustomModalBody>
      <CustomModalFooter>
        <Button variant="outlined" onClick={onClose}>
          {t('SKIP')}
        </Button>
        <LoadingButton
          size="large"
          loading={savingLoading}
          className="w-auto"
          onClick={handleSave}
        >
          {t('SAVE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default SetupAccount
