import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  AlertModal,
  FormLabel,
  LoadingButton,
  ToggleSwitch
} from 'global/globalComponents'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import {
  createNewWebhook,
  deleteSingleWebhook,
  updateSingleWebhook
} from 'thunks/webhooks/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { useModal } from 'customHooks'

const SlackConnectModal = ({ open, onClose, data }) => {
  return (
    <CustomModal open={open} handleClose={onClose}>
      <CustomModalHeader heading="Slack Integration" handleClose={onClose} />
      <ModalContent onClose={onClose} data={data} />
    </CustomModal>
  )
}

const ModalContent = ({ onClose, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { allEvents } = useSelector(state => state.userWebhooks)
  const { currentWorkspace } = useSelector(state => state.userWorkspaces)
  const [formData, setFormData] = useState({
    name: currentWorkspace.name,
    url: ''
  })
  const [projectAndTaskEvents, setProjectAndTaskEvents] = useState([])
  const [activeEvents, setActiveEvents] = useState({})
  const [loading, setLoading] = useState(false)
  const {
    modal: deleteAlert,
    openModal: openDeleteAlert,
    closeModal: closeDeleteAlert,
    toggleLoading: toggleDeleteAlert
  } = useModal()

  useEffect(() => {
    if (data) {
      setFormData({ name: data.name, url: data.endpoint })
      const eventsData = data.events.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})

      setActiveEvents(eventsData)
    }
  }, [data])

  useEffect(() => {
    setProjectAndTaskEvents(
      allEvents.filter(
        item =>
          (item.type === 'workspace' || item.type == 'task') &&
          item.name.toLowerCase() !== 'add project'
      )
    )
  }, [allEvents])

  const handleChangeActiveEvent = eventId => {
    const obj = { ...activeEvents }

    if (activeEvents[eventId]) delete obj[eventId]
    else obj[eventId] = true

    setActiveEvents(obj)
  }

  const handleSlackIntegration = () => {
    if (!formData.url.startsWith('https://hooks.slack.com')) {
      dispatch(fireErrorToaster(t('ERROR_INVALID_URL')))
      return
    }

    setLoading(true)
    const body = {
      name: formData.name,
      endpoint: formData.url,
      events: Object.keys(activeEvents),
      workspace: currentWorkspace._id,
      type: 'slack'
    }

    if (data) {
      dispatch(updateSingleWebhook(data._id, body, webhookSaveCallback))
    } else {
      dispatch(createNewWebhook(body, webhookSaveCallback))
    }
  }

  const webhookSaveCallback = (res, err) => {
    setLoading(false)
    if (err) dispatch(fireErrorToaster(res))
    else {
      dispatch(fireSuccessToaster(t('CHANGES_SAVED')))
      onClose()
    }
  }

  const handleDeleteWebhook = () => {
    toggleDeleteAlert()
    dispatch(
      deleteSingleWebhook(data._id, (res, err) => {
        toggleDeleteAlert()
        if (err) dispatch(fireErrorToaster(res))
        else {
          dispatch(fireSuccessToaster(t('WEBHOOK_DELETED_SUCCESSFULLY')))
          closeDeleteAlert()
          onClose()
        }
      })
    )
  }

  return (
    <>
      <CustomModalBody>
        <p className="text-sm mb-4">{t('PROJECT_SLACK_CONNECT_MESSAGE')}</p>
        <div className="mb-4">
          <FormLabel required>{t('WEBHOOK_NAME')}</FormLabel>
          <TextField
            value={formData.name}
            placeholder={t('WEBHOOK_NAME')}
            onChange={e =>
              setFormData(prev => ({ ...prev, name: e.target.value }))
            }
            fullWidth
          />
        </div>
        <div>
          <FormLabel required>{t('URL')}</FormLabel>
          <TextField
            value={formData.url}
            placeholder="https://hooks.slack.com/123"
            onChange={e =>
              setFormData(prev => ({ ...prev, url: e.target.value }))
            }
            fullWidth
          />
          <span className="text-smaller text-gray-400">
            {t('WHERE_DO_YOU_WANT_US_TO_HIT')}
          </span>
        </div>

        <div className="mt-4">
          <h4 className="text-primary-dark font-bold text-lg mb-2 dark:text-dark-light2">
            {t('EVENTS')}
          </h4>

          <div>
            {projectAndTaskEvents.map(item => (
              <SingleEvent
                key={item._id}
                event={item}
                isActive={activeEvents[item._id] || false}
                handleChangeActiveEvent={handleChangeActiveEvent}
              />
            ))}
          </div>
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        {data && (
          <div className="mr-auto">
            <Button
              style={{ backgroundColor: '#ff0000', borderColor: '#ff0000' }}
              startIcon={<TrashIcon className="w-4 h-4" />}
              onClick={() => openDeleteAlert(data)}
            >
              {t('DELETE')}
            </Button>
          </div>
        )}

        <Button variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton
          style={{ marginLeft: 8 }}
          loading={loading}
          onClick={handleSlackIntegration}
        >
          {data ? t('SAVE') : t('CREATE')}
        </LoadingButton>
      </CustomModalFooter>

      <AlertModal
        open={deleteAlert.open}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handleDeleteWebhook}
        warningText={t('WARNING_DO_YOU_WANT_TO_DELETE_WEBHOOK', {
          data: deleteAlert.data.name
        })}
        heading="Alert"
        loading={deleteAlert.loading}
      />
    </>
  )
}

const SingleEvent = ({ event, isActive, handleChangeActiveEvent }) => {
  const handleChange = () => {
    handleChangeActiveEvent(event._id)
  }

  return (
    <div className="flex items-center justify-between mb-1">
      <label
        htmlFor={event.name}
        className="text-smaller 2xl:text-sm font-medium text-primary-dark dark:text-dark-light"
      >
        {event.name}
      </label>
      <span>
        <ToggleSwitch
          checked={isActive}
          onChange={handleChange}
          loading={false}
          id={event.name}
        />
      </span>
    </div>
  )
}

export default SlackConnectModal
