import templateStyles from './templateStyles'
import clsx from 'clsx'
// import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useTranslation } from 'react-i18next'
// import { useState } from 'react'

const TemplateModalLeftView = ({
  linkData,
  setLinkData,
  setSelectedCategory
  // setStep,
  // component
}) => {
  const classes = templateStyles()
  const { t } = useTranslation()

  return (
    <>
      {/* {component === 'project' && (
        <div className="px-2 pt-4">
          <button
            onClick={() => setStep(1)}
            className="text-primary-main text-smaller 2xl:text-sm dark:text-dark-light"
          >
            <ArrowBackRoundedIcon fontSize="small" />
            &nbsp; {t('BACK')}
          </button>
        </div>
      )} */}
      <div className="px-2 pt-4">
        <div className=" ml-1 mb-2 text-sm 2xl:text-base font-medium dark:text-dark-light">
          {t('EXPLORE')}
        </div>
        <div className="mb-6 text-sm">
          {[t('ALL_TEMPLATES'), t('YOUR_TEMPLATES')].map((item, index) => (
            <div
              className={clsx(
                { active: linkData === index },
                classes.activeRow,
                'text-smaller 2xl:text-sm px-4 py-2 mb-1 rounded-sm cursor-pointer hover:bg-primary-light dark:text-dark-light dark:hover:bg-dark-main2'
              )}
              onClick={() => {
                setLinkData(index)
                setSelectedCategory('all')
              }}
            >
              {item}
            </div>
          ))}
        </div>
        <div className=" border-b"></div>

        <div className="my-6 text-sm">
          {[
            { label: t('MARKETING'), value: 'Marketing' },
            { label: t('DESIGN'), value: 'Design' },
            { label: t('SOFTWARE_DEVELOPMENT'), value: 'Software Development' },
            { label: t('OTHERS'), value: 'other' }
          ].map((item, index) => (
            <div
              className={clsx(
                { active: linkData === index + 2 },
                classes.activeRow,
                'text-smaller 2xl:text-sm px-4 py-2 mb-1 rounded-sm cursor-pointer hover:bg-primary-light dark:text-dark-light active:text-dark-main1 dark:hover:bg-dark-main2'
              )}
              onClick={() => {
                setLinkData(index + 2)
                setSelectedCategory(item.value)
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TemplateModalLeftView
