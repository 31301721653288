// import produce from 'immer'

const initialState = {
  loading: false,
  data: {},
  fetched: false
}

export const STRIPE_ACCOUNT = {
  LOADING: 'LOADING_USER_STRIPE_INFO',
  FETCHED: 'USER_STRIPE_INFO_FETCHED',
  UPDATE: 'USER_STRIPE_INFO_UPDATE',
  DELETE: 'USER_STRIPE_INFO_DELETE'
}

const stripeInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case STRIPE_ACCOUNT.LOADING:
      return { ...state, loading: true }

    case STRIPE_ACCOUNT.FETCHED:
      return {
        ...state,
        loading: false,
        fetched: true,
        data: action.payload
      }

    case STRIPE_ACCOUNT.UPDATE:
      return {
        ...state,
        data: action.payload
      }

    case STRIPE_ACCOUNT.DELETE:
      return {
        ...state,
        data: {}
      }

    default:
      return state
  }
}

export default stripeInfoReducer
