import React, { useState } from 'react'
import { RoundedTextInput } from '..'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'
import { IconButton, InputAdornment } from '@material-ui/core'

const PasswordField = ({ ...rest }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <RoundedTextInput
      {...rest}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            size="small"
            onClick={() => setShowPassword(prev => !prev)}
          >
            {!showPassword ? (
              <EyeSlashIcon className="text-gray-800" />
            ) : (
              <EyeIcon className="text-gray-800" />
            )}
          </IconButton>
        </InputAdornment>
      }
      autoComplete="one-time-code"
    />
  )
}

export default PasswordField
