import React from 'react'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { Skeleton } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { useBreadcrumbs } from 'customHooks'
import { useTranslation } from 'react-i18next'
import { ServiceCard } from 'components/_shared'
import { useHistory, useRouteMatch } from 'react-router-dom'

const PurchasedServices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { path } = useRouteMatch()
  const invoices = useSelector(state => state.clientInvoices)

  let purchasedServiceInvoices = invoices.data.filter(invoice => {
    return Boolean(invoice.type === 'service' && invoice.invoiceItems)
  })

  useBreadcrumbs([
    {
      name: t('SERVICES'),
      link: `/services`,
      index: 0
    }
  ])

  const handleServiceCardClick = invoiceId => {
    history.push(`${path}/invoice/${invoiceId}/details`)
  }

  return (
    <OuterContentWrapper className="w-full overflow-auto">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
          gridGap: '2rem 1rem'
        }}
      >
        {invoices.loading ? (
          <>
            {[...Array(3)].map(() => (
              <div className="mt-6 grid">
                <Skeleton
                  variant="rect"
                  height={180}
                  animation="wave"
                  className="rounded-lg"
                />
              </div>
            ))}
          </>
        ) : (
          <>
            {purchasedServiceInvoices.length > 0 ? (
              purchasedServiceInvoices.map(invoice => (
                <ServiceCard
                  key={invoice._id}
                  service={invoice.invoiceItems[0]}
                  onClick={() => handleServiceCardClick(invoice._id)}
                />
              ))
            ) : (
              <div className="col-span-7">
                <p className="text-gray-500 font-medium">
                  {t('NO_DATA_FOUND')}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </OuterContentWrapper>
  )
}

export default PurchasedServices
