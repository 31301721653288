import { useState, useRef } from 'react'
import CustomModal from 'global/globalComponents/CustomModal/CustomModal'
import { useSelector } from 'react-redux'
import { Divider, Tabs, Tab, Button } from '@material-ui/core'
import { ReactComponent as AngleDown } from 'static/svg/angle-down.svg'
import userRoles from 'utils/userRoles'
import { useWorkspacePermission } from 'customHooks'
import {
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import ShareTabContents from './ShareTabContents'
import { PopupMenuList } from 'global/globalComponents'
import AgencyTeammatesTab from './AgencyTeammatesTab'
import ClientTeammatesTab from './ClientTeammatesTab'

// Note: User with the permission Project update can view and update this modal
// Which excludes client teammates (role > 30)

const ProjectPermissionModal = props => {
  const { open, onClose } = props
  const { t } = useTranslation()

  return (
    <CustomModal open={open} handleClose={onClose} size="medium">
      <CustomModalHeader heading={t('SHARE_PROJECT')} handleClose={onClose} />
      <ProjectPermissionModalContent onClose={onClose} />
      <CustomModalFooter>
        <Button size="large" onClick={onClose}>
          {t('CLOSE')}
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

const ProjectPermissionModalContent = () => {
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  const shareTabRef = useRef()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const userAccountPermission = useSelector(
    state => state.permission.data.access
  )
  const innerProjectPermission = useWorkspacePermission(currentWorkspace._id)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const isClient = innerProjectPermission.role === userRoles.USER_CLIENT
  // const canAddClient = meData.role !== userRoles.USER_CLIENT // client can add teammates but not clients

  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const handleAddTeammate = () => {
    if (shareTabRef.current?.openTeammatePanel) {
      shareTabRef.current.openTeammatePanel({
        defaultValues: {
          assignedWorkspace: [
            { name: currentWorkspace.name, _id: currentWorkspace._id }
          ]
        }
      })
    }
  }

  const inviteClientUser = () => {
    shareTabRef.current.openAddClientPanel({
      email: '',
      assignedWorkspace: [
        { name: currentWorkspace.name, _id: currentWorkspace._id }
      ]
    })
  }

  return (
    <>
      <div className="px-8">
        <div className="flex justify-between items-center">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            {[
              t('SHARE'),
              t('AGENCY_TEAM'),
              isClient ? t('YOUR_TEAM') : t('CLIENT_TEAM')
            ].map((item, index) => (
              <Tab label={item} key={index} />
            ))}
          </Tabs>
          {selectedTab === 0 && (
            <div
              className="flex text-sm font-medium cursor-pointer text-gray-500 hover:text-gray-900 dark:text-dark-light"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              {t('ADD_NEW_USER')}
              <AngleDown
                className={`h-6 w-6 text-white fill-white`}
                style={{ fill: 'gray', fontSize: '5px' }}
              />
            </div>
          )}
        </div>

        <Divider orientation="horizontal" />

        {selectedTab === 0 ? (
          <ShareTabContents
            ref={shareTabRef}
            innerProjectPermission={innerProjectPermission}
            userAccountPermission={userAccountPermission}
            switchToTeammatesTab={() => setSelectedTab(1)}
            switchToClientsTab={() => setSelectedTab(2)}
          />
        ) : selectedTab === 1 ? (
          <AgencyTeammatesTab
            members={currentWorkspace.assignedAgencyTeam?.filter(
              mate => mate.role !== userRoles.USER_AGENCY
            )}
            projectRole={innerProjectPermission.role}
          />
        ) : (
          <ClientTeammatesTab
            members={
              isClient
                ? currentWorkspace.assignedClientTeam?.filter(
                    mate => mate.role !== userRoles.USER_CLIENT
                  )
                : currentWorkspace.assignedClientTeam
            }
            projectRole={innerProjectPermission.role}
          />
        )}
      </div>
      <PopupMenuList
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        menuItems={
          isClient
            ? [{ label: t('ADD_TEAM_MEMBER_N'), method: handleAddTeammate }]
            : [
                { label: t('ADD_TEAM_MEMBER_N'), method: handleAddTeammate },
                { label: t('ADD_CLIENT'), method: inviteClientUser }
              ]
        }
      />
    </>
  )
}

export default ProjectPermissionModal
