// import { userRoles } from 'utils'

import { getMarketplaceDomain } from 'utils'

export const GENERAL_APP_STATE = {
  TOGGLE_SIDE_DRAWER: 'TOGGLE_SIDE_DRAWER',
  TOGGLE_SIDE_BAR: 'TOGGLE_SIDE_BAR',
  SHOW_INTEGRATED_HEADER: 'SHOW_INTEGRATED_HEADER',
  SHOW_NORMAL_HEADER: 'SHOW_NORMAL_HEADER F',
  // UPDATE_USER_ACCESS: 'UPDATE_USER_ACCESS',
  TOGGLE_SIDE_PANEL: 'TOGGLE_SIDE_PANEL',
  SHOW_SIDEDRAWER: 'SHOW_SIDEDRAWER',
  SET_TEAMMATE_ROLE: 'SET_TEAMMATE_ROLE',
  SET_EXPAND_STATE: 'SET_EXPAND_STATE',
  OPEN_REMOVED_FROM_WS_MODAL: 'OPEN_REMOVED_FROM_WS_MODAL',
  TOGGLE_UPGRADE_PLAN_MODAL: 'TOGGLE_UPGRADE_PLAN_MODAL',
  TOGGLE_CREATE_INVOICE_MODAL: 'TOGGLE_CREATE_INVOICE_MODAL',
  TOGGLE_DOCUMENT_PREVIEW: 'TOGGLE_DOCUMENT_PREVIEW',
  TOGGLE_HEADER_SHADOW: 'TOGGLE_HEADER_SHADOW',
  SET_HEADER_SEARCH: 'HEADER_SET_HEADER_SEARCH',
  SET_BOARD_SEARCH: 'HEADER_SET_BOARD_SEARCH',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  // THEME_UPDATE: 'THEME_UPDATE',
  LANGUAGE_UPDATE: 'LANGUAGE_UPDATE',
  THEME_UPDATE: 'THEME_UPDATE',
  SET_COMPANY_INFO: 'GENERAL_APP_STATE_SET_COMPANY_INFO',
  UPDATE_THEME: 'GENERAL_APP_STATE_UPDATE_THEME',
  SET_UNREAD_GROUP_MESSAGES_COUNT:
    'GENERAL_APP_STATE_SET_UNREAD_GROUP_MESSAGES_COUNT',
  TOGGLE_SCREEN_RECORDING: 'TOGGLE_SCREEN_RECORDING',
  TOGGLE_SCREEN_RECORDING_SAVE: 'TOGGLE_SCREEN_RECORDING_SAVE',
  SET_SHOWMORE_STATE: 'SET_SHOWMORE_STATE',
  OPEN_APPROVAL_MODAL: 'GENERAL_APP_STATE_OPEN_APPROVAL_MODAL',
  CLOSE_APPROVAL_MODAL: 'GENERAL_APP_STATE_CLOSE_APPROVAL_MODAL',
  SHOW_PAY_NOW_STRIPE: 'GENERAL_APP_STATE_SHOW_PAY_NOW_STRIPE',
  HIDE_PAY_NOW_STRIPE: 'GENERAL_APP_STATE_HIDE_PAY_NOW_STRIPE',
  UPDATE_USER_FEEDBACK_POPUP: 'GENERAL_APP_STATE_UPDATE_USER_FEEDBACK_POPUP',
  TOGGLE_CHAT_MODAL: 'GENERAL_APP_STATE_TOGGLE_CHAT_MODAL',
  TOGGLE_CHAT_WIDGET: 'GENERAL_APP_STATE_TOGGLE_CHAT_WIDGET'
}

const marketplaceDomain = getMarketplaceDomain()

const initialState = {
  sideDrawerOpen: true,
  sidebarOpen: true,
  showMoreOptions: { value: false, loading: true },
  normalHeader: true,
  headerHeight: 60,
  teammaterole: { value: 0, label: 'All' },
  expandState: false,
  workspaceRemoveModal: false,
  upgradePlanModal: false,
  createInvoiceModal: {
    type: 'stripe',
    open: false
  },
  sidePanel: {
    addTeammate: {
      open: false,
      data: {},
      aboveModal: false,
      elmFor: '',
      clientOwner: '',
      callback: null
    },
    addClient: {
      open: false,
      data: {}
    },
    addWorkspace: {
      open: false,
      type: 'Internal'
    },
    addTask: {
      open: false,
      data: {}
    },
    addService: false,
    editTeammate: false,
    logsPanel: false
  },
  modal: {
    embedModal: false,
    anchorPos: ''
  },
  // filePreview: {
  //   open: false,
  //   file: '',
  //   files: []
  // },
  theme: {
    mode: 'light'
  },
  headerShadow: true,
  headerSearch: '',
  boardSearch: '',
  companyInfo: {
    loading: true,
    data: {}
  },
  screenRecorder: {
    start: false,
    save: false,
    file: null,
    url: null
  },
  onlineStatus: { status: true, updated: false },
  approvalModal: {
    open: false,
    data: {}
  },
  payNowStripe: {
    isVisible: false,
    redirectUrl: ''
  },
  userFeedbackPopup: {
    open: false
  },
  isMarketplaceDomain: window.location.host === marketplaceDomain,
  chatModalState: {
    open: false
  },
  displayChatWidget: false
}

const generalAppStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_APP_STATE.TOGGLE_SIDE_DRAWER:
      return {
        ...state,
        sideDrawerOpen:
          action.payload === undefined ? !state.sideDrawerOpen : action.payload
      }

    case GENERAL_APP_STATE.TOGGLE_SIDE_BAR:
      return {
        ...state,
        sidebarOpen:
          action.payload === undefined ? !state.sidebarOpen : action.payload
      }

    case GENERAL_APP_STATE.SHOW_INTEGRATED_HEADER:
      return {
        ...state,
        normalHeader: false,
        headerHeight: 72
      }

    case GENERAL_APP_STATE.SHOW_NORMAL_HEADER:
      return {
        ...state,
        normalHeader: true,
        headerHeight: 60
      }

    // case GENERAL_APP_STATE.UPDATE_USER_ACCESS:
    //   const obj = setUserAccess(action.payload)
    //   return {
    //     ...state,
    //     userAccess: { ...obj }
    //   }

    case GENERAL_APP_STATE.TOGGLE_SIDE_PANEL:
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          ...action.payload
        }
      }

    case GENERAL_APP_STATE.SHOW_SIDEDRAWER:
      return {
        ...state,
        sideDrawerOpen: action.payload
      }

    case GENERAL_APP_STATE.SET_TEAMMATE_ROLE:
      return {
        ...state,
        teammaterole: action.payload
      }

    case GENERAL_APP_STATE.SET_EXPAND_STATE: {
      return {
        ...state,
        expandState: action.payload
      }
    }

    case GENERAL_APP_STATE.TOGGLE_CREATE_INVOICE_MODAL: {
      return {
        ...state,
        createInvoiceModal: {
          open: !state.createInvoiceModal.open,
          invoiceType: action.payload
        }
      }
    }

    case GENERAL_APP_STATE.OPEN_REMOVED_FROM_WS_MODAL: {
      return {
        ...state,
        workspaceRemoveModal: action.payload
      }
    }

    case GENERAL_APP_STATE.TOGGLE_UPGRADE_PLAN_MODAL: {
      return {
        ...state,
        upgradePlanModal: !state.upgradePlanModal
      }
    }

    // case GENERAL_APP_STATE.TOGGLE_DOCUMENT_PREVIEW:
    //   return { ...state, filePreview: action.payload }

    case GENERAL_APP_STATE.TOGGLE_HEADER_SHADOW:
      return { ...state, headerShadow: action.payload }

    case GENERAL_APP_STATE.SET_HEADER_SEARCH:
      return { ...state, headerSearch: action.payload }

    case GENERAL_APP_STATE.SET_BOARD_SEARCH:
      return { ...state, boardSearch: action.payload }

    case GENERAL_APP_STATE.TOGGLE_MODAL:
      return { ...state, modal: { ...state.modal, ...action.payload } }

    case GENERAL_APP_STATE.THEME_UPDATE:
      return { ...state, theme: action.payload }

    case GENERAL_APP_STATE.SET_COMPANY_INFO:
      return { ...state, companyInfo: { loading: false, data: action.payload } }
    case GENERAL_APP_STATE.UPDATE_THEME:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          data: { ...state.companyInfo.data, theme: action.payload }
        }
      }
    case GENERAL_APP_STATE.TOGGLE_SCREEN_RECORDING:
      // console.log(state.screenRecorder)
      return {
        ...state,
        screenRecorder: { ...state.screenRecorder, start: action.payload }
      }
    case GENERAL_APP_STATE.TOGGLE_SCREEN_RECORDING_SAVE:
      return {
        ...state,
        screenRecorder: {
          ...state.screenRecorder,
          save: action.payload.save,
          file: action.payload.file,
          url: action.payload.url
        }
      }

    case GENERAL_APP_STATE.TOGGLE_ONLINE_STATUS:
      return {
        ...state,
        onlineStatus: { updated: true, status: action.payload }
      }
    case GENERAL_APP_STATE.TOGGLE_ONLINE_STATUS_UPDATE:
      return {
        ...state,
        onlineStatus: { ...state.onlineStatus, updated: action.payload }
      }

    case GENERAL_APP_STATE.SET_SHOWMORE_STATE:
      return {
        ...state,
        showMoreOptions: action.payload
      }
    case GENERAL_APP_STATE.OPEN_APPROVAL_MODAL:
      return {
        ...state,
        approvalModal: {
          open: true,
          data: action.payload
        }
      }
    case GENERAL_APP_STATE.CLOSE_APPROVAL_MODAL:
      return {
        ...state,
        approvalModal: {
          ...state.approvalModal,
          open: false
        }
      }

    case GENERAL_APP_STATE.SHOW_PAY_NOW_STRIPE:
      return {
        ...state,
        payNowStripe: {
          isVisible: true,
          redirectUrl: action.payload
        }
      }

    case GENERAL_APP_STATE.HIDE_PAY_NOW_STRIPE:
      return {
        ...state,
        payNowStripe: {
          ...state.payNowStripe,
          isVisible: false
        }
      }

    case GENERAL_APP_STATE.UPDATE_USER_FEEDBACK_POPUP:
      return {
        ...state,
        userFeedbackPopup: action.payload
      }

    case GENERAL_APP_STATE.TOGGLE_CHAT_MODAL:
      return {
        ...state,
        chatModalState: {
          open: action.payload
        }
      }

    case GENERAL_APP_STATE.TOGGLE_CHAT_WIDGET:
      return {
        ...state,
        displayChatWidget: action.payload
      }

    default:
      return state
  }
}

export default generalAppStateReducer
