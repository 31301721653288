import { useMemo } from 'react'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { Button } from '@material-ui/core'
import { CustomTooltip } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { userRoles } from 'utils'
import { useTranslation } from 'react-i18next'

const TeammateOnboardingLinkModal = ({ open, handleClose }) => {
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const agencyId = useMemo(() => {
    if (meData.role === userRoles.USER_AGENCY) return meData._id
    return meData.team?._id
  }, [meData.role])

  const { t } = useTranslation()
  const copyTeammateSignupLink = () => {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/teammate-signup/${agencyId}`
    )

    dispatch(fireSuccessToaster(t('LINK_COPIED')))
  }

  return (
    <CustomModal open={open} handleClose={handleClose} size="small">
      <CustomModalHeader
        heading={t('TEAM_ONBOARD_LINK')}
        handleClose={handleClose}
      />

      <CustomModalBody>
        <p className="text-sm mb-4 dark:text-dark-light">
          {t('SHARE_ONBOARD_LINK')}
        </p>

        <div className="flex justify-between items-center border border-gray-300 px-2 py-2 mb-6 rounded text-sm dark:text-dark-light dark:border-black">
          <span className="truncate mr-2">{`${window.location.protocol}//${window.location.host}/teammate-signup/${agencyId}`}</span>
          <CustomTooltip title={t('COPY')} placement="top">
            <button
              onClick={copyTeammateSignupLink}
              className="text-gray-500 hover:text-primary-main dark:text-dark-light"
            >
              <CopyIcon className="w-4 h-4" />
            </button>
          </CustomTooltip>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button variant="outlined" onClick={handleClose}>
          {t('CLOSE')}
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default TeammateOnboardingLinkModal
