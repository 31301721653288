import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearAndReplaceBreadCumbs } from 'thunks/breadcrumbs/action'
import { getUserPath } from 'utils/userRoles'

const useBreadcrumbs = (breadcrumbs = []) => {
  const me = useSelector((state) => state.me.data)
  const dispatch = useDispatch()

  useEffect(() => {
    if (me?.role) {
      const data = breadcrumbs.map((bc, index) => ({
        ...bc,
        link: `${getUserPath(me.role)}${bc.link}`
      }))

      dispatch(clearAndReplaceBreadCumbs(data))
    }
  }, [me, breadcrumbs])
}

export default useBreadcrumbs
