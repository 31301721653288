import React from 'react'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const BigToggle = props => {
  const { onChange, value } = props
  const classes = useStyles()

  const handleToggle = e => {
    onChange(e, `${value === 'month' ? 'year' : 'month'}`)
  }

  return (
    <div className={classes.swtichContainer} onClick={handleToggle}>
      <div className={classes.leftSide}>
        <Typography className={classes.durationText}>Monthly</Typography>
      </div>
      <div className={classes.toggleNew}>
        <div className={clsx(classes.toggleDot, value)}></div>
        <div className={classes.toggleGreen}></div>
      </div>
      <div className={classes.rightSide}>
        <Typography className={classes.durationText}>Yearly</Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    swtichContainer: {
      display: 'flex',
      maxWidth: '200px',
      margin: '16px auto 8px',
      WebkitJustifyContent: 'space-around',
      MsFlexPack: 'distribute',
      justifyContent: 'space-around'
    },
    leftSide: {
      display: 'inline-block',
      padding: '4px 9px',
      borderRadius: '4px',
      fontSize: '15px',
      fontWeight: '500',
      color: '#000',
      cursor: 'pointer'
    },
    toggleNew: {
      left: 'auto',
      right: 'auto',
      display: 'flex',
      width: '40px',
      minHeight: '30px',
      WebkitBoxAlign: 'center',
      WebkitAlignItems: 'center',
      alignItems: 'center',
      MsFlexAlign: 'center',
      cursor: 'pointer'
    },
    toggleDot: {
      position: 'absolute',
      width: '20px',
      height: '20px',
      borderRadius: '99px',
      backgroundColor: '#fff',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .25)',
      transition: '0.3s',
      '&.month': {
        transform:
          ' translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;'
      },
      '&.year': {
        transform:
          ' translate3d(16px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;'
      }
    },
    toggleGreen: {
      width: '40px',
      height: '24px',
      marginRight: 'auto',
      marginLeft: '-2px',
      borderRadius: '999px',
      backgroundColor: '#33d900',
      boxShadow: '0px 1px 6px 2px inset #2dbf00'
    },
    rightSide: {
      display: 'inline-block',
      padding: '4px 9px',
      borderRadius: '4px',
      fontSize: '15px',
      fontWeight: '500',
      color: '#000',
      cursor: 'pointer'
    },
    durationText: {
      color: '#000',
      fontSize: '15px',
      fontWeight: '500',
      userSelect: 'none'
    }
  })
)

BigToggle.propTypes = {
  value: PropTypes.oneOf(['month', 'year']).isRequired,
  onChange: PropTypes.func.isRequired
}

export default BigToggle
