// import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import TaskCollaborators from './TaskCollaborators'
import taskSidePanelStyles from './taskSidePanelStyles'
import DatePicker, { registerLocale } from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import FieldLabel from './FieldLabel'
import clsx from 'clsx'
import AssignedTags from 'components/UserDashboard/Workspaces/AssignedTags'
import { setHours } from 'date-fns'
import { ReactComponent as TagsIcon } from 'static/svg/tags.svg'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { useMemo } from 'react'
import enGb from 'date-fns/locale/en-GB'
import { useTranslation } from 'react-i18next'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import Pill from 'global/globalComponents/Pill/Pill'

registerLocale('en-gb', enGb)

const TaskBody = ({
  taskState,
  triggerPopover,
  innerProjectPermission,
  handleDateChange,
  elmFor,
  assignedTags = [],
  setTagsPopoverData,
  workspace,
  removeCollaborator,
  displayProjectField
}) => {
  const classes = taskSidePanelStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const hasPassedDueDate = useMemo(() => {
    if (taskState.markAsComplete) return false
    if (taskState.dueDate && new Date(taskState.dueDate) < new Date())
      return true
  }, [taskState.dueDate, taskState.markAsComplete])

  const collaborators = useMemo(() => {
    if (taskState?.assignedAgencyTeam) {
      return [...taskState.assignedAgencyTeam, ...taskState.assignedClientTeam]
    }
    return []
  }, [taskState])

  const handleAddTeammate = e => {
    if (workspace.isComplete) {
      dispatch(
        fireErrorToaster(
          t(
            'ERROR_WORKSPACE_IS_ALREADY_COMPLETED_AND_CANNOT_ADD_TEAM_MEMBERS',
            { data: workspace.name }
          )
        )
      )
      return
    }

    if (!innerProjectPermission.update) {
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return
    }

    triggerPopover(e, 'collab')
  }

  const handleOpenTagsPopper = e => {
    if (!innerProjectPermission.update) {
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return
    }

    setTagsPopoverData({ open: true, anchorEl: e.currentTarget })
  }

  return (
    <div className={clsx(classes.taskBodyContainer, 'px-6 mb-4')}>
      <RowContainer>
        <div>
          <FieldLabel>{t('ASSIGNEE')}</FieldLabel>
        </div>
        <div className="flex items-center">
          <TaskCollaborators
            taskId={taskState._id}
            innerProjectPermission={innerProjectPermission}
            elmFor={elmFor}
            removeCollaborator={removeCollaborator}
            collaborators={collaborators}
          />

          <button
            type="button"
            onClick={handleAddTeammate}
            className="flex-shrink-0 ml-2 bg-white dark:bg-dark-main3 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
          >
            <span className="sr-only">Add team member</span>
            <Add style={{ fontSize: '16px' }} aria-hidden="true" />
          </button>
        </div>
      </RowContainer>
      {elmFor === 'task' && (
        <RowContainer>
          <div>
            <FieldLabel>{t('TAGS')}</FieldLabel>
          </div>
          <div className="flex space-x-2 items-center">
            {Boolean(assignedTags.length) && (
              <AssignedTags
                assignedTag={assignedTags}
                taskId={taskState?._id}
                elmFor="task"
                setTagsPopoverData={setTagsPopoverData}
                tagIcon={true}
                className="flex-wrap"
              />
            )}
            <button
              onClick={handleOpenTagsPopper}
              className="w-6 h-6 border border-dashed flex justify-center items-center text-base rounded-full text-gray-400"
            >
              <TagsIcon className="dark:text-dark-light" />
            </button>
          </div>
        </RowContainer>
      )}
      <RowContainer>
        <div>
          <FieldLabel>{t('START_DATE')}</FieldLabel>
        </div>
        <div className="flex gap-1">
          <div
            className={clsx(
              classes.pseudoDropDownContainer,
              innerProjectPermission.update && 'cursor-pointer'
            )}
          >
            <DatePicker
              selected={
                taskState.startDate
                  ? new Date(taskState.startDate)
                  : setHours(new Date(), new Date().getHours())
              }
              onChange={date => {
                handleDateChange(date, 'startDate')
              }}
              disabled={workspace.isComplete || !innerProjectPermission.update}
              customInput={
                taskState.startDate ? (
                  <input
                    className="text-xs dark:bg-dark-main3 dark:text-dark-light"
                    style={{ width: '180px', padding: '4px 0' }}
                  />
                ) : (
                  <CalenderIcon className="text-base text-gray-600 dark:text-dark-light cursor-pointer" />
                )
              }
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              showDisabledMonthNavigation
              locale="en-gb"
            />
          </div>
        </div>
      </RowContainer>
      <RowContainer>
        <div>
          <FieldLabel>{t('DUE_DATE')}</FieldLabel>
        </div>
        <div className="flex gap-1">
          <div className={classes.pseudoDropDownContainer}>
            <div>
              <DatePicker
                selected={
                  taskState.dueDate
                    ? new Date(taskState.dueDate)
                    : setHours(new Date(), new Date().getHours())
                }
                disabled={
                  workspace.isComplete || !innerProjectPermission.update
                }
                onChange={date => {
                  handleDateChange(date, 'dueDate')
                }}
                showTimeSelect
                customInput={
                  taskState.dueDate ? (
                    <input
                      className={clsx(
                        'dark:text-dark-light',
                        hasPassedDueDate && 'text-red-400',
                        'text-xs dark:bg-dark-main3'
                      )}
                      style={{ width: '180px', padding: '4px 0' }}
                    />
                  ) : (
                    <CalenderIcon className="text-base text-gray-600 dark:text-dark-light cursor-pointer" />
                  )
                }
                placeholderText="Select Date"
                dateFormat="MMM d, yyyy h:mm aa"
                popperPlacement={elmFor === 'task' ? 'bottom-end' : undefined}
                locale="en-gb"
              />
            </div>
          </div>
        </div>
      </RowContainer>
      {displayProjectField && (
        <RowContainer>
          <div>
            <FieldLabel>{t('PROJECT')}</FieldLabel>
          </div>
          <div className="leading-3">
            <Pill variant="purple">{workspace.name}</Pill>
          </div>
        </RowContainer>
      )}
    </div>
  )
}

export const RowContainer = props => {
  const { children, ...restProps } = props
  return (
    <div {...restProps} className="flex items-center space-x-4 leading-3">
      {children}
    </div>
  )
}

export default TaskBody
