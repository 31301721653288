import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { dateAndTimeHelper, userRoles } from 'utils'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowRight } from 'static/svg/arrow-right.svg'
import { CustomModalFooter } from 'global/globalComponents/CustomModal'

const StandupsFooter = ({ onClose }) => {
  const { t } = useTranslation()
  const standupsData = useSelector(state => state.standups.data.standups)
  const meData = useSelector(state => state.me.data)
  const { estimatedTime, trackedTime, completion } = useMemo(() => {
    const allStandups = standupsData || []
    let totalTasks = allStandups.length,
      completedTasks = 0,
      trackedTime = 0,
      estimatedTime = 0

    for (let standup of standupsData) {
      if (standup.isComplete) completedTasks++
      if (standup.task.estimateTimeInMilliSecond)
        estimatedTime += standup.task.estimateTimeInMilliSecond
      trackedTime += standup.timeTracked
    }

    return {
      estimatedTime: dateAndTimeHelper.formatMilliseconds(estimatedTime),
      trackedTime: dateAndTimeHelper.formatMilliseconds(trackedTime),
      completion: Math.round((completedTasks / totalTasks) * 100)
    }
  }, [standupsData])
  const summaryUrl = useMemo(() => {
    return meData.role === userRoles.AGENCY_ADMIN ||
      meData.role === userRoles.USER_AGENCY
      ? '/agency/reports/standups'
      : `/agency/reports/standups/${meData._id}`
  }, [meData])

  return (
    <CustomModalFooter className="pb-5">
      <div className="w-full flex items-center">
        {Boolean(standupsData.length) && (
          <div
            className="text-xs flex items-center space-x-8 text-gray-700 dark:text-gray-500 py-2 pr-4"
            // style={{ margin: '0 -24px' }}
          >
            <div>
              <span className="font-semibold">{t('ESTIMATED_TIME')}: </span>
              <span className="font-medium">{estimatedTime}</span>
            </div>
            <div>
              <span className="font-semibold">{t('TIME_TRACKED')}: </span>
              <span className="font-medium">{trackedTime}</span>
            </div>
            <div>
              <span className="font-semibold ">{t('COMPLETION')}: </span>
              <span className="font-medium">{completion}%</span>
            </div>
          </div>
        )}
        <div className="ml-auto">
          <Link
            to={summaryUrl}
            onClick={onClose}
            className="text-primary-main text-smaller flex items-center"
          >
            <span className="mr-1 dark:text-dark-light">
              {t('VIEW_STANDUPS_SUMMARY')}
            </span>{' '}
            <ArrowRight className="dark:text-dark-light" />
          </Link>
        </div>
      </div>
    </CustomModalFooter>
  )
}

export default StandupsFooter
