import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-datepicker/dist/react-datepicker.css'
import {
  addIntegration,
  addNewIntegration,
  integrateGDrive
} from 'thunks/integration/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useHistory } from 'react-router-dom'
import { liveChatConnect, userRoles } from 'utils'
import { intercomConnect, dashlyConnect, crispConnect } from 'utils'
import { HUBSPOT_INTEGRATION_NAME } from 'constants/constantKeys'

const useIntegrations = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [modal, setModal] = useState({
    isOpen: false,
    appName: '',
    status: 'loading',
    errorMessage: ''
  })
  // const [openModal, setOpenModal] = useState(false)
  // const [appName, setAppName] = useState()
  // const [status, setStatus] = useState('loading')
  const meData = useSelector(state => state.me.data)
  const { loggedIn } = useSelector(state => state.login)
  const userIntegrations = useSelector(state => state.userIntegrations)

  useEffect(() => {
    const url_string = window.location.href
    const url = new URL(url_string)
    const type = url.searchParams.get('type')
    const code = url.searchParams.get('code')

    if (type === 'gdrive' && code) {
      integrateGDrive({ data: { code } }, (res, err) => {
        if (err) dispatch(fireErrorToaster(res))
        else
          dispatch(fireSuccessToaster('Google drive integration successful!'))
      })
      return
    }

    if (type === 'dropbox' && code) {
      dispatch(
        addIntegration({ data: { appName: 'dropbox', code } }, (res, err) => {
          history.push(url.pathname)
          if (err) dispatch(fireErrorToaster(res))
          // else history.push(url.pathname);
        })
      )
    } else if (type === 'google' && code) {
      setModal(prev => ({ ...prev, isOpen: true, appName: 'google' }))
      // setAppName('Google')
      // setStatus('loading')
      // setOpenModal(true)
      dispatch(
        addIntegration({ data: { appName: type, code } }, (res, err) => {
          if (err) {
            setModal(prev => ({ ...prev, status: 'error', errorMessage: res }))
            dispatch(fireErrorToaster(res))
          } else {
            setModal(prev => ({ ...prev, status: 'success' }))
          }
        })
      )
    } else if (type === 'zoom' && code) {
      setModal(prev => ({ ...prev, isOpen: true, appName: 'Zoom' }))
      // setAppName('Zoom')
      // setStatus('loading')
      // setOpenModal(true)
      dispatch(
        addNewIntegration({ provider: type, code }, (res, err) => {
          if (err) {
            setModal(prev => ({ ...prev, status: 'error', errorMessage: res }))
            dispatch(fireErrorToaster(res))
          } else {
            setModal(prev => ({ ...prev, status: 'success' }))
          }
        })
      )
    } else if (type === HUBSPOT_INTEGRATION_NAME && code) {
      setModal(prev => ({ ...prev, isOpen: true, appName: 'HubSpot' }))
      dispatch(
        addNewIntegration(
          { provider: type, code, appName: type },
          (res, err) => {
            if (err) {
              setModal(prev => ({
                ...prev,
                status: 'error',
                errorMessage: res
              }))
              dispatch(fireErrorToaster(res))
            } else {
              setModal(prev => ({ ...prev, status: 'success' }))
            }
          }
        )
      )
    }
  }, [])

  // useEffect(() => {
  //   if (window.mixpanel) {
  //     window.mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  //       debug: true
  //     })
  //   }
  // }, [])

  // ============= Chat setup for Agency (crisp) ==========
  useEffect(() => {
    if (meData.role !== userRoles.USER_AGENCY) return
    crispConnect('64dce30f-86d0-4c29-97ff-523f448912a8')
  }, [meData.role])

  // ========= Chat Integration for Agency Client =========
  useEffect(() => {
    if (!loggedIn) return

    if (meData.role >= userRoles.USER_CLIENT) {
      const agencyOwnerId =
        meData.role === 30 ? meData.team._id : meData.agency._id

      if (!agencyOwnerId) return

      userIntegrations.data.forEach(integration => {
        if (integration.isActive) {
          switch (integration.appName) {
            case 'intercom':
              intercomConnect(integration.key, meData)
              break
            case 'dashly':
              dashlyConnect(integration.key)
              break
            case 'crisp':
              crispConnect(integration.key)
              break
            case 'livechat':
              liveChatConnect(integration.key)
              break
            default:
              break
          }
        }
      })
    }
  }, [meData, loggedIn, userIntegrations.data])

  useEffect(() => {
    if (!window.Pusher || !loggedIn) return

    window.pusherInstance = new window.Pusher(
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? '4a4b1295055bb008c6b4'
        : '9a342689f95378748dc0',
      {
        cluster:
          process.env.REACT_APP_ENVIRONMENT === 'production' ? 'mt1' : 'ap2'
      }
    )
  }, [loggedIn])

  return {
    modal,
    onClose: () => setModal(prev => ({ ...prev, isOpen: false }))
  }
  // return { openModal, setOpenModal, appName, status }
}

export default useIntegrations
