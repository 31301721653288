import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { ReactComponent as Check } from 'static/svg/check.svg'

const AddNewCard = () => {
  return (
    <div className="mt-2 ml-10 mb-8 w-2/3">
      <div className="mt-2 border-gray-300 hover:border-primary-main dark:bg-dark-main4 flex items-center border py-2 px-2 rounded">
        <div className="flex-1 py-0.5 dark:text-dark-light">
          <CardElement />
        </div>
      </div>
      <div className="mt-2 flex items-center justify-end dark:text-dark-light">
        <Check style={{ color: '#044fff' }} />
        <em
          style={{ color: '#044fff' }}
          className="text-tiny ml-1 whitespace-nowrap"
        >
          Powered By Stripe
        </em>
      </div>
    </div>
  )
}

export default AddNewCard
