import React from 'react'
import loginPattern from 'static/Images/login-pattern.png'
import { useSelector } from 'react-redux'
import ClassicLayout from './ClassicLayout'
import SplitLayout from './SplitLayout'
import BasicLayout from './BasicLayout'

const PatternScreen = ({ bgImage, children, layout, elmFor }) => {
  const { companyInfo } = useSelector(state => state.generalAppState)
  let customizeData = JSON.parse(localStorage.getItem('customize'))
  const formLayout =
    layout ||
    companyInfo.data.pageCustomization?.layout ||
    customizeData?.pageCustomization?.layout ||
    'split'

  const formBackgroundImage =
    bgImage ||
    companyInfo.data.pageCustomization?.backgroundImage ||
    customizeData?.pageCustomization?.backgroundImage ||
    loginPattern

  const Component =
    formLayout === 'classic'
      ? ClassicLayout
      : formLayout === 'basic'
      ? BasicLayout
      : SplitLayout

  return (
    <Component
      elmFor={elmFor}
      bgImage={formBackgroundImage}
      children={children}
    />
  )
}

export default PatternScreen
