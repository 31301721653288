export const STANDUPS = {
  LOADING: 'STANDUPS_LOADING',
  FETCHED: 'ALL_STANDUPS_FETCHED',
  ADD_STANDUPS: 'ADD_STANDUPS',
  REMOVE_ONE_STANDUP: 'REMOVE_ONE_STANDUP',
  UPDATE: 'STANDUPS_UPDATE'
}

const initialState = {
  error: false,
  loading: false,
  data: {
    standups: []
  },
  fetched: false
}

export const standupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STANDUPS.FETCHED:
      return { ...state, loading: false, fetched: true, data: action.payload }

    case STANDUPS.ADD_STANDUPS:
      return { ...state, data: action.payload }

    case STANDUPS.UPDATE:
      return { ...state, data: action.payload }
    case STANDUPS.REMOVE_ONE_STANDUP:
      return {
        ...state,
        data: {
          ...state.data,
          standups: state.data.standups.filter(
            (item) => item._id !== action.payload._id
          )
        }
      }

    default:
      return state
  }
}
export default standupsReducer
