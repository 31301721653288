import React from 'react'
import { TextField, useTheme } from '@material-ui/core'
import { FormLabel } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Countries from 'static/countries/Countries'

const OtherDetails = ({
  invoiceEmail,
  invoiceAddress,
  handleChangeInvoiceEmail,
  handleChangeInvoiceAddress
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div>
      <div className="mb-4 font-medium">
        <div className="mb-1 text-sm">{t('DEFAULT_INVOICE_FIELD_HEADING')}</div>
        <div className="text-xs font-normal text-gray-600">
          {t('DEFAULT_INVOICE_FIELD_DESCRIPTION')}
        </div>
      </div>

      <div>
        <FormLabel>{t('EMAIL')}</FormLabel>
        <TextField
          value={invoiceEmail}
          placeholder={t('EMAIL')}
          onChange={e => handleChangeInvoiceEmail(e.target.value)}
          style={{
            background:
              theme.palette.type !== 'light'
                ? theme.custom.darkMode.background
                : ''
          }}
          fullWidth
        />
      </div>

      <div className="mt-4">
        <FormLabel>{t('ADDRESS')}</FormLabel>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '0.5rem 1rem',
            gap: '0.5rem 1rem'
          }}
        >
          {/* line1: '', line2: '', city: '', state: '', zipCode: '', country: '' */}
          <TextField
            placeholder={t('LINE_1')}
            name="line1"
            value={invoiceAddress.line1}
            onChange={e => handleChangeInvoiceAddress('line1', e.target.value)}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
          <TextField
            required
            placeholder={t('LINE_2_OPTIONAL')}
            name="line2"
            value={invoiceAddress.line2}
            onChange={e => handleChangeInvoiceAddress('line2', e.target.value)}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
          <TextField
            placeholder={t('CITY')}
            value={invoiceAddress.city}
            name="city"
            onChange={e => handleChangeInvoiceAddress('city', e.target.value)}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
          <TextField
            placeholder={t('STATE')}
            value={invoiceAddress.state}
            onChange={e => handleChangeInvoiceAddress('state', e.target.value)}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
          <Select
            className="text-sm"
            placeholder={t('COUNTRY')}
            isSearchable={true}
            options={Countries}
            value={invoiceAddress.country}
            maxMenuHeight={250}
            onChange={newVal => handleChangeInvoiceAddress('country', newVal)}
            styles={reactSelectCustomStyles(theme)}
            menuPlacement="top"
          />
          <TextField
            placeholder={t('ZIP_CODE')}
            value={invoiceAddress.zipCode}
            onChange={e =>
              handleChangeInvoiceAddress('zipCode', e.target.value)
            }
            type="text"
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default OtherDetails
