import { makeStyles, createStyles } from '@material-ui/core'

const UserDashboardStyles = makeStyles((theme) =>
  createStyles({
    icons: {
      color: '#B4B4BA',
      transition: '0.2s transform, 0.2s color, 0.2s backround-color',
      '&:hover': {
        backgroundColor: 'rgb(214 214 214 / 58%)',
        color: '#333'
      },
      '&.expanded': {
        transform: 'rotate(90deg)'
      }
    },
    divider: {
      height: '1.5px',
      backgroundColor: '#E3E2E8',
      margin: '1rem 0 2.5rem'
    },
    cardLoader: {
      width: 152,
      height: 226,
      borderRadius: 30,
      padding: theme.spacing(2, 0),
      display: 'flex',
      justifyContent: 'center'
    },

    cardsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(232px, 1fr))',
      gridGap: '2rem'
    },
    cardWrapper: {
      // width: 232,
      '&:hover .link': {
        opacity: 1
      }
    },
    owner: {
      display: 'flex',
      flexDirection: 'column'
      // marginTop: -5
    }
  })
)

export default UserDashboardStyles
