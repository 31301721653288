import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as CheckIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { GoBack, Loader, Tooltip } from 'global/globalComponents'
import serviceDefaultImage from 'static/Images/default-service.png'
import Currency from 'static/Currency/Currency'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CheckoutForm from './CheckoutForm'
import { DefaultServiceImage } from 'static/Images'
import { fetchActiveServiceTemplate } from 'thunks/service/actions'
import { serviceTemplateComponents } from '../ServiceTemplates/helper'
import { fetchClientSubscriptions } from 'thunks/subscription/actions'

const ServiceCheckout = ({ removeLogin, backPath }) => {
  const { serviceId } = useParams()
  const dispatch = useDispatch()
  const userServices = useSelector(state => state.userServices)
  const subscriptions = useSelector(state => state.subscriptions)
  const serviceData = userServices.data.find(
    service => service._id === serviceId
  )
  const [activeTemplate, setActiveTemplate] = useState({
    loading: false,
    data: {}
  })
  const { loggedIn } = useSelector(state => state.login)

  useEffect(() => {
    fetchActiveServiceTemplate((res, err) => {
      setActiveTemplate(prev => ({
        loading: false,
        data: err ? prev.data : res
      }))
    })
  }, [])

  useEffect(() => {
    if (loggedIn && !subscriptions.fetched) dispatch(fetchClientSubscriptions())
  }, [loggedIn, subscriptions.fetched])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!serviceData || activeTemplate.loading) return <Loader />
  const TemplateComponent = serviceTemplateComponents[activeTemplate.data.name]

  return (
    <>
      {TemplateComponent ? (
        <TemplateComponent
          templateSections={activeTemplate.data.configuration.sections}
          firstSectionElement={
            <ServiceCheckoutChild
              removeLogin={removeLogin}
              backPath={backPath}
            />
          }
        />
      ) : (
        <div style={{ width: '90%', maxWidth: 1200, margin: '2rem auto' }}>
          <ServiceCheckoutChild removeLogin={removeLogin} backPath={backPath} />
        </div>
      )}
    </>
  )
}

const ServiceCheckoutChild = ({ removeLogin, backPath }) => {
  const { serviceId } = useParams()
  const userServices = useSelector(state => state.userServices)
  const serviceData = userServices.data.find(
    service => service._id === serviceId
  )
  const [selectedAddOns, setSelectedAddOns] = useState([])

  const serviceAddOns = useMemo(() => {
    const arr = []

    userServices.addOns.data.forEach(addOn => {
      const match = addOn.services.find(id => id === serviceId)
      if (match) arr.push(addOn)
    })

    return arr.filter(addOn => {
      const idx = selectedAddOns.findIndex(
        selAddOn => selAddOn._id === addOn._id
      )

      return idx === -1
    })
  }, [userServices.addOns, serviceId, selectedAddOns])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let recurringPeriod = ''
  if (serviceData.isRecurring && serviceData.recurring) {
    if (serviceData.recurring.interval === 'year') {
      recurringPeriod = 'Billed every year'
    } else {
      recurringPeriod = `Billed every ${
        serviceData.recurring.interval_count === 1
          ? 'month'
          : serviceData.recurring.interval_count + ' months'
      }`
    }
  }

  const removeAddOn = addOnId => {
    setSelectedAddOns(prev => prev.filter(addOn => addOn._id !== addOnId))
  }

  return (
    <div className="mx-auto px-8" style={{ maxWidth: 1200 }}>
      <GoBack className="mb-4" path={backPath} />
      <div className="flex items-start pb-12">
        <div className="flex-1 mr-8">
          <div className="mb-6">
            <img
              src={serviceData.image || serviceDefaultImage}
              alt=""
              className="w-full object-cover mb-6 rounded"
              style={{ maxHeight: 300 }}
            />
            <h1 className="text-2xl font-semibold dark:text-white">
              {serviceData.name}
            </h1>
          </div>

          <div className="mb-6">
            <ServiceInfoFieldValue
              label="Amount"
              value={
                <>
                  {Currency[serviceData.currency.toUpperCase()].symbol_native}
                  {(serviceData.amount / 100).toLocaleString()}
                </>
              }
            />

            <ServiceInfoFieldValue
              label="Type"
              value={
                <>
                  {!serviceData.isRecurring ? (
                    <>
                      One time{' '}
                      <span className="text-smaller font-normal">
                        (one time payment)
                      </span>
                    </>
                  ) : (
                    <>
                      Recurring{' '}
                      <span className="text-smaller font-normal">
                        ({recurringPeriod})
                      </span>
                    </>
                  )}
                </>
              }
            />

            {serviceData.recurring?.trial_period_days > 0 && (
              <ServiceInfoFieldValue
                label="Trial Days"
                value={serviceData.recurring.trial_period_days + ' Days'}
              />
            )}
          </div>

          <div className="mb-6">
            <div className="tracking-wider text-sm font-semibold dark:text-white mb-2">
              Description:
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: serviceData.description }}
              className="leading-6 dangerous-html-container text-sm text-gray-700 dark:text-dark-light mb-8"
              // style={{ whiteSpace: 'pre-wrap' }}
            ></div>
          </div>

          {serviceData.offerings?.length > 0 && (
            <div className="mb-6">
              <div className="text-base font-semibold dark:text-white mb-4">
                Offerings:
              </div>

              <div>
                {serviceData.offerings.map((offer, index) => (
                  <div key={index} className="flex start mb-4">
                    <div className="flex-shrink-0">
                      <CheckIcon className="text-green-500 w-6 h-6" />
                    </div>
                    <div className="ml-2 dark:text-white mt-0.5">
                      <div className="text-sm font-medium">{offer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {serviceAddOns.length > 0 && (
            <div className="mb-12">
              <div className="text-sm font-semibold mb-2 uppercase dark:text-dark-light">
                Add-Ons
              </div>

              {serviceAddOns.map(addOn => (
                <div key={addOn._id} className="flex items-start mb-6">
                  <img
                    className="w-14 h-14 object-contain"
                    src={(addOn.image
                      ? `${addOn.image}?t=${Date.now()}`
                      : DefaultServiceImage
                    )?.replace('s3.wasabisys.com/', '')}
                    alt=""
                  />

                  <div className="ml-6 relative flex-1 pr-4">
                    <div className="absolute top-0 right-0">
                      <Tooltip title="ADD">
                        <button
                          onClick={() =>
                            setSelectedAddOns(prev => [...prev, addOn])
                          }
                          className="border rounded border-primary-main text-primary-main p-0.5"
                        >
                          <AddIcon />
                        </button>
                      </Tooltip>
                    </div>
                    <div className="flex items-center text-sm font-semibold mb-1 dark:text-dark-light">
                      <div className="mr-4">{addOn.name}</div>

                      <div className="inline-block border px-2 border-primary-main rounded text-sm text-primary-main font-semibold dark:text-gray-400">
                        {
                          Currency[serviceData.currency?.toUpperCase()]
                            .symbol_native
                        }
                        {addOn.amount * 0.01}
                      </div>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">
                      {addOn.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div style={{ width: 400 }} className="sticky top-0">
          <CheckoutForm
            selectedPlan={serviceData}
            selectedAddOns={selectedAddOns}
            removeAddOn={removeAddOn}
            removeLogin={removeLogin}
          />
        </div>
      </div>
    </div>
  )
}

const ServiceInfoFieldValue = ({ label, value }) => {
  return (
    <div className="flex items-center mb-2">
      <div className="w-20 text-sm font-semibold dark:text-white mr-4">
        {label} :
      </div>
      <div className="text-sm font-medium text-gray-700 dark:text-dark-light">
        {value}
      </div>
    </div>
  )
}

export default ServiceCheckout
