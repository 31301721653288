import React, { useEffect, useRef } from 'react'
import Quill from 'quill'
import { ReactComponent as SendIcon } from 'static/svg/send.svg'
import { FormatUnderlined, FormatBold, FormatItalic } from '@material-ui/icons'
import { getMentionConfig } from 'components/Editors/editorsStatic'

// Note: In this editor toolbar shows up on the bottom

const QuillEditorWithToolbar = ({
  html = '',
  onChange,
  placeholder,
  disabled,
  height,
  onSubmit,
  users = [],
  toolbar,
  allowVariables
}) => {
  const editor = useRef()
  const quill = useRef()
  const mention = getMentionConfig(users)
  const config = {
    theme: 'snow',
    modules: {
      toolbar: toolbar || {
        container: '#quill-toolbar'
      },
      magicUrl: true,
      mention: {
        ...mention,
        onSelect: (item, insertItem) => {
          // const user = users.find(user => user._id === item.id)
          // insertItem({ ...item, ...user, notify: meData._id !== user._id })
          // onSelectUser(user)
        }
      }
    },
    placeholder: placeholder || 'Type here...'
  }

  useEffect(() => {
    attachQuill()
  }, [])

  useEffect(() => {
    if (html !== quill.current?.root.innerHTML) {
      const quote = quill.current.clipboard.convert(html || '<p> </p>')
      quill.current.setContents(quote, Quill.sources.SILENT)
    }
  }, [html])

  const attachQuill = () => {
    quill.current = new Quill(editor?.current, config)

    if (disabled) {
      quill.current.enable(false)
    }

    if (onChange) {
      quill.current.on('text-change', function (delta, oldDelta, source) {
        // const editorText = quill.current.getText()
        // const variablePattern = /\{\{(\w+)\}\}/
        // const matched = editorText.match(variablePattern)
        // const quillText = getQuillHTML(quill.current.getContents())

        // if (matched) {
        //   const index = editorText.indexOf(matched[0])
        //   console.log({
        //     editorText,
        //     quillText,
        //     contents: quill.current.getContents()
        //   })
        //   quill.current.deleteText(index, matched[0].length, 'user')
        //   setTimeout(() => {
        //     quill.current.insertEmbed(index, 'quillVariable', matched[1])
        //   }, 1000)
        // }

        onChange(quill.current.root.innerHTML)
      })
    }

    // quill.current.clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
    quill.current.root.addEventListener('paste', e => {
      if (allowVariables) {
        const selection = quill.current.selection
        const variablePattern = /^\{\{(\w+)\}\}$/
        const clipboardData = e.clipboardData || window.clipboardData
        const pastedData = clipboardData.getData('Text')
        const matched = pastedData.match(variablePattern)

        if (matched) {
          e.preventDefault()
          window.setTimeout(() => {
            quill.current.insertEmbed(
              selection.savedRange.index,
              'quillVariable',
              `{{${matched[1]}}}`
            )

            quill.current.setSelection(
              selection.savedRange.index + 1,
              Quill.sources.SILENT
            )
          }, 100)
        }
      }
    })

    setDefaultContent()
  }

  const setDefaultContent = () => {
    const quote = quill.current.clipboard.convert(html || '<p> </p>')
    quill.current.setContents(quote, Quill.sources.SILENT)
  }

  const handleFormat = type => {
    const format = quill.current.getFormat(
      quill.current.selection.savedRange.index,
      quill.current.selection.savedRange.length
    )

    quill.current.format(type, !format[type])
  }

  const handleSubmit = () => {
    if (quill.current.getText().trim() !== '') {
      onSubmit(quill.current.getContents())
      quill.current.setContents([{ insert: '\n' }])
    }
  }

  return (
    <div className="border rounded dark:text-dark-light">
      <div
        ref={editor}
        style={{
          height: height || 50,
          border: 'none'
        }}
      />

      {!toolbar && (
        <CustomToolbar
          handleFormat={handleFormat}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  )
}

const CustomToolbar = ({ handleFormat, handleSubmit }) => {
  return (
    <div
      className="flex items-center gap-2"
      id="quill-toolbar"
      style={{ border: 'none' }}
    >
      <div className="w-full flex overflow-x-auto items-center">
        <button title="Bold" onClick={() => handleFormat('bold')}>
          <FormatBold />
        </button>
        <button title="Italic" onClick={() => handleFormat('italic')}>
          <FormatItalic />
        </button>
        <button title="Underline" onClick={() => handleFormat('underline')}>
          <FormatUnderlined />
        </button>
      </div>

      <div className="ml-auto">
        <button onClick={handleSubmit}>
          <SendIcon className="ml-0.5 rotate-45" />
        </button>
      </div>
    </div>
  )
}

export default QuillEditorWithToolbar
