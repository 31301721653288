import { useEffect, useState } from 'react'
import { AlertModal, ColoredAvatars } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import {
  rejectSignupRequest,
  approveSignupRequest
} from 'thunks/teammate/actions'
import { format } from 'date-fns'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { userRoles, getRoleBadge } from 'utils'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const totalTeammatesToShow = 8

const ApproveTeammateTable = ({ loading, teammates }) => {
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)

  // ------ Related to the pagination --------
  const [visibleTeammates, setVisibleTeammates] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const [requestApproveAlert, setRequestApproveAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })

  const [requestRejectAlert, setRequestRejectAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })

  useEffect(() => {
    if (teammates) {
      setVisibleTeammates(
        [...teammates].splice(page * totalTeammatesToShow, totalTeammatesToShow)
      )
      setPaginationTotalDataCount(teammates.length)
    }
  }, [teammates, page, paginationTotalDataCount])

  const toggleApproveAlert = data => {
    if (requestApproveAlert.open) {
      setRequestApproveAlert(prev => ({ ...prev, open: false }))
    } else {
      if (
        ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
      ) {
        return dispatch(
          fireErrorToaster(
            t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION')
          )
        )
      }
      setRequestApproveAlert(prev => ({ open: true, data, loading: false }))
    }
  }

  const toggleRejectAlert = data => {
    if (requestRejectAlert.open) {
      setRequestRejectAlert(prev => ({ ...prev, open: false }))
    } else {
      if (
        ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
      ) {
        return dispatch(
          fireErrorToaster(
            t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION')
          )
        )
      }
      setRequestRejectAlert(prev => ({ open: true, data, loading: false }))
    }
  }

  const handleRejectUserRequest = () => {
    setRequestRejectAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      rejectSignupRequest(
        { teammateId: requestRejectAlert.data._id },
        rejectRequestCallback
      )
    )
  }

  const rejectRequestCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setRequestRejectAlert(prev => ({ ...prev, loading: false }))
    } else {
      setRequestRejectAlert(prev => ({
        ...prev,
        loading: false,
        open: false
      }))
      dispatch(fireSuccessToaster(t('REQUEST_REMOVED_SUCCESSFULLY')))
    }
  }

  const handleApproveUserRequest = () => {
    setRequestApproveAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      approveSignupRequest(
        {
          teammateId: requestApproveAlert.data._id,
          data: { status: 'approved' }
        },
        approveRequestCallback
      )
    )
  }

  const approveRequestCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setRequestApproveAlert(prev => ({ ...prev, loading: false }))
    } else {
      setRequestApproveAlert(prev => ({
        ...prev,
        loading: false,
        open: false
      }))
      dispatch(fireSuccessToaster(t('REQUEST_APPROVED_SUCCESSFULLY')))
    }
  }
  const { t } = useTranslation()

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th>{t('NAME')}</Th>
          <Th className="whitespace-nowrap text-center">{t('PERMISSION')}</Th>
          <Th className="whitespace-nowrap">{t('REGISTRATION_DATE')}</Th>
          <Th className="text-center whitespace-nowrap">{t('ACTION')}</Th>
        </Thead>

        <Tbody>
          {!loading ? (
            visibleTeammates.length > 0 ? (
              visibleTeammates.map((item, index) => (
                <Row
                  key={item._id}
                  user={item}
                  toggleApproveAlert={toggleApproveAlert}
                  toggleRejectAlert={toggleRejectAlert}
                />
              ))
            ) : (
              <Tr className="bg-white dark:bg-dark-main">
                <th
                  colSpan={meData.role < userRoles.USER_CLIENT ? 6 : 5}
                  className="text-left py-3 px-6 text-gray-500 font-normal dark:text-dark-light"
                >
                  {t('NO_TEAM_MEMBERS')}
                </th>
              </Tr>
            )
          ) : (
            <TableDataLoading
              cols={meData.role < userRoles.USER_CLIENT ? 6 : 5}
            />
          )}
        </Tbody>
      </TableContainer>

      {!loading.loading && paginationTotalDataCount > totalTeammatesToShow && (
        <Pagination
          handlePagination={setPage}
          totalResultsCount={paginationTotalDataCount}
          visibleResultsCount={visibleTeammates.length}
          page={page}
          limit={totalTeammatesToShow}
        />
      )}

      {/* <DeleteModal
        warningText="Teammate will be deleted."
        open={deleteTeammateData.open}
        handleDialog={handleDeleteTeammate}
        handleDeleteAction={handleTeammateData}
        loading={deleteTeammateData.loading}
      /> */}
      <AlertModal
        open={requestApproveAlert.open}
        handleDialog={toggleApproveAlert}
        heading={t('ARE_YOU_SURE')}
        warningText={t('WARNING_DO_YOU_WANT_TO_APPROVE_REQUEST', {
          data: requestApproveAlert.data.name
        })}
        cancelText={t('CANCEL')}
        deleteBtnText={t('YES')}
        alertType="confirmation"
        loading={requestApproveAlert.loading}
        handleDeleteAction={handleApproveUserRequest}
      />
      <AlertModal
        open={requestRejectAlert.open}
        handleDialog={toggleRejectAlert}
        heading={t('ARE_YOU_SURE')}
        warningText={t('WARNING_DO_YOU_WANT_TO_REJECT_REQUEST', {
          data: requestRejectAlert.data.name
        })}
        cancelText={t('CANCEL')}
        deleteBtnText={t('YES')}
        loading={requestRejectAlert.loading}
        handleDeleteAction={handleRejectUserRequest}
      />
    </div>
  )
}

const Row = ({ user, toggleApproveAlert, toggleRejectAlert }) => {
  const handleApproveUser = () => {
    toggleApproveAlert(user)
  }

  const handleRejectUser = () => {
    toggleRejectAlert(user)
  }
  const { t } = useTranslation()

  return (
    <Tr>
      <Td className=" px-6 py-4 whitespace-nowrap flex items-center space-x-2">
        <ColoredAvatars size="medium" user={user} />
        <div className="flex flex-col">
          <div className="flex items-center leading-3">
            <span className="text-primary-mid-dark font-medium mr-2 dark:text-dark-light">
              {user.name}
            </span>
          </div>
          <span className="text-primary-mid-dark-2 text-xs dark:text-dark-light">
            {user.email}
          </span>
        </div>
      </Td>
      <Td className="whitespace-nowrap text-center">
        {getRoleBadge(user.role)}
      </Td>
      <Td className="text-custom-gray-main dark:text-dark-light">
        {format(new Date(user.createdAt), 'd MMM')}
      </Td>
      <Td className="text-custom-gray-main whitespace-nowrap dark:text-dark-light">
        <div className="flex items-center justify-center space-x-2">
          <Button
            size="small"
            onClick={handleApproveUser}
            className="dark:bg-dark-main1"
          >
            {t('APPROVE')}
          </Button>
          <Button variant="outlined" size="small" onClick={handleRejectUser}>
            {t('REJECT')}
          </Button>
        </div>
      </Td>
    </Tr>
  )
}

export default ApproveTeammateTable
