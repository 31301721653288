import React, { useMemo } from 'react'
import { ReactComponent as BugIcon } from 'static/asana/bug.svg'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { getUserPath } from 'utils/userRoles'
import { useSelector } from 'react-redux'
import AsanaIcons from 'static/asana/AsanaIcons'
import {
  ColoredAvatarGroup,
  CustomAvatar
} from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { Card } from 'global/globalComponents'
import useStyles from './projectsCardViewStyles'
import { useTranslation } from 'react-i18next'
import WorkspacePopupMenu from '../WorkspacePopupMenu'

const ProjectCard = ({ workspaceData, elmFor, workspacePermission }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const userRole = useSelector(state => state.me.data?.role)

  const owner = useMemo(() => {
    if (workspaceData.assignedAgencyTeam) {
      const userObj = [
        ...workspaceData.assignedAgencyTeam,
        ...workspaceData.assignedClientTeam
      ].find(mate => mate.user._id === workspaceData.user)

      if (userObj) return userObj.user
    }
  }, [workspaceData.user])

  const members = useMemo(() => {
    return [
      ...workspaceData.assignedAgencyTeam,
      ...workspaceData.assignedClientTeam
    ].map(obj => obj.user)
  }, [workspaceData.assignedAgencyTeam, workspaceData.assignedClientTeam])

  return (
    <ConditionalLink
      to={`${getUserPath(userRole)}/workspace/${workspaceData._id}/overview`}
      condition={!workspaceData.isArchived && elmFor === 'workspace'}
    >
      <Card className={classes.cardWrapper}>
        <div>
          <header className="flex items-start justify-between p-4">
            <div
              className="rounded-full p-2"
              style={{
                backgroundColor: workspaceData.backgroundcolor + '70',
                width: '36px',
                height: '36px'
              }}
            >
              {AsanaIcons[workspaceData.image] ? (
                AsanaIcons[workspaceData.image]({ className: 'selected-icon' })
              ) : (
                <BugIcon className="selected-icon" />
              )}
            </div>
            {owner && (
              <div className={clsx('leading-none', classes.owner)}>
                <span
                  className="inline-block mb-1 text-custom-gray-light-3 font-medium"
                  style={{ fontSize: 9 }}
                >
                  {t('OWNER')}
                </span>
                <CustomAvatar size="small" user={owner} tooltip={true} />
              </div>
            )}
          </header>

          <div className="mb-4 2xl:mb-6 px-4">
            <h4 className="font-medium text-primary-dark dark:text-dark-light2 truncate capitalize text-smaller 2xl:text-sm">
              {workspaceData.name}
            </h4>
          </div>

          <footer
            onClick={e => e.preventDefault()} //preventing event propagation
            className="py-3 px-4 border-t flex justify-between items-center"
          >
            <ColoredAvatarGroup users={members} size="small" tooltip={true} />
            {elmFor === 'workspace' ? (
              workspacePermission.update && (
                <WorkspacePopupMenu
                  workspace={workspaceData}
                  iconSize="small"
                  workspacePermission={workspacePermission}
                />
              )
            ) : (
              <Link
                to={`${getUserPath(userRole)}/workspace/${
                  workspaceData._id
                }/overview`}
                className="link text-blue-400 rounded-md text-xs px-2 py-1 hover:bg-gray-50 dark:hover:bg-dark-main2 dark:hover:text-dark-light opacity-0"
              >
                {t('GO_TO_PROJECT')}
              </Link>
            )}
          </footer>
        </div>
      </Card>
    </ConditionalLink>
  )
}

const ConditionalLink = ({ condition, to, children }) => {
  return <>{condition ? <Link to={to}>{children}</Link> : children}</>
}

export default ProjectCard
