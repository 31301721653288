import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowMore } from 'thunks/generlAppState/actions'

const useSidebarState = () => {
  const { showMoreOptions: activeShowMore } = useSelector(
    state => state.generalAppState
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (!activeShowMore.loading)
      window.localStorage.setItem(
        'showMoreState',
        JSON.stringify(activeShowMore.value)
      )
  }, [activeShowMore])

  useEffect(() => {
    const showMoreState = JSON.parse(
      window.localStorage.getItem('showMoreState')
    )
    if (activeShowMore.loading) {
      dispatch(setShowMore({ loading: false, value: showMoreState ?? false }))
    }
  }, [activeShowMore])

  return null
}

export default useSidebarState
