const fetchUrlPreview = async (url, callback) => {
  try {
    const res = await fetch(url)
    const html = await res.text()
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    const title = doc
      .querySelector('meta[property="og:title"]')
      .getAttribute('content')

    const image = doc
      .querySelector('meta[property="og:image"]')
      .getAttribute('content')

    if (callback) callback({ title, image })
    return { title, image }
  } catch (err) {
    if (callback) callback(err, true)
    throw new Error(err)
  }
}

export default fetchUrlPreview
