import axios from 'axios'
import { STANDUPS } from './reducer'
import { TASKS } from '../task/reducers'
import { getErrorMessages } from 'utils/errorMessages'
import moment from 'moment'
import { catchAsync, catchAsyncDispatch } from 'utils'
const errorMessages = getErrorMessages()

export const transformStandupsData = (data, meId) => {
  const transformedData = { ...data }

  transformedData.standups = transformedData.standups.map(item => {
    const obj = { ...item }

    obj.timeTracked = obj.timeTracked.length
      ? obj.timeTracked
          .filter(durationObj => durationObj.user === meId)
          .reduce((prevVal, currVal) => {
            return prevVal + currVal.duration
          }, 0)
      : 0
    return obj
  })

  return transformedData
}

/**
 * Create today's standups
 * @param {String} data.id standup id
 * @param {Object} data.data update
 * @param {Function} callback Callback function
 */
export const updateStandupById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/standups/${data.id}`,
      method: 'PUT',
      data: data.data
    })

    dispatch({ type: STANDUPS.ADD_STANDUPS, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Create today's standups
 * @param {Object} data.data
 * @param {Function} callback Callback function
 */
export const createStandups = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: '/standups',
      method: 'POST',
      data: data.data
    })

    dispatch({ type: STANDUPS.ADD_STANDUPS, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete Standup
 * @param {String} data.id standup id
 * @param {String} data.taskId
 * @param {Function} callback Callback function
 */
export const deleteStandup = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/standups/task/${data.id}/${data.taskId}`,
      method: 'DELETE'
    })

    dispatch({ type: STANDUPS.UPDATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch current user's today's standups
 * @param {Object|null} data
 * @param {Function} callback Callback function
 */
export const fetchTodaysStandups = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      me: { data: meData },
      userWorkspaces
    } = getState()

    try {
      const res = await axios({
        url: '/standups/current',
        method: 'GET'
      })

      const transformedData = transformStandupsData(res.data, meData._id)

      dispatch({ type: STANDUPS.FETCHED, payload: transformedData })
      if (callback) callback(transformedData)
    } catch (err) {
      if (err.response.status === 404) {
        fetchCurrentDayTasks(null, (res, err) => {
          if (!err) {
            if (res.data.length === 0) {
              callback()
              return
            }

            const mappedData = res.data.map(item => {
              const ws = userWorkspaces.data.find(
                ws => ws._id === item.workSpace
              )

              const obj = {
                projectName: ws?.name || null,
                projectId: ws?._id || item.workSpace,
                name: item.title,
                task: item._id,
                isComplete: false,
                type: 'morning'
              }
              return obj
            })

            dispatch(
              createStandups({
                data: { standups: mappedData, type: 'morningStandups' }
              })
            )
          }
        })
      } else {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Fetch current user's today's standups
 * @param {Object|null} data
 * @param {Function} callback Callback function
 */
export const fetchCurrentDayTasks = catchAsync(async (data, callback) => {
  let startDate = moment()
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .toISOString()
  let endDate = moment()
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 59)
    .toISOString()

  const res = await axios({
    url: `/task/currentdayTask?limit=50&page=0&startDate=${startDate}&endDate=${endDate}`,
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * Fetch current user's today's standups
 * @param {String} data.id standup id
 * @param {String} data.taskId id
 * @param {Function} callback
 */
export const markStandupAsComplete = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/standups/complete/${data.taskId}/${data.id}`,
      method: 'PUT',
      data: data.data
    })

    dispatch({ type: STANDUPS.UPDATE, payload: res.data })
    dispatch({
      type: TASKS.MARK_AS_COMPLETE,
      payload: { taskId: data.taskId, isComplete: data.data.markAsComplete }
    })

    if (callback) callback(res.data)
  }, callback)
}
