export const LOGS = {
  LOADING: 'LOADING_LOGS',
  ERROR: 'LOGS_ERROR',
  FETCHED: 'LOGS_DATA_FETCHED',
  ALL: 'LOGS_ALL',
  POSTED_LOG: 'POSTED_LOG',
  DELETE_COMMENT: 'DELETE_COMMENT',
  WORKSPACE_LOGS: 'WORKSPACE_LOGS',
  SUBTASK_LOGS: 'SUBTASK_LOGS',
  UPDATE_WORKSPACE_LOGS: 'UPDATE_WORKSPACE_LOGS',
  UPDATE_SUBTASK_LOGS: 'UPDATE_SUBTASK_LOGS',
  REMOVE_LOGS: 'LOGS_REMOVE_LOGS',
  UPDATE_TASK_LOG_BY_ID: 'LOGS_UPDATE_TASK_LOG_BY_ID',
  UPDATE_SUBTASK_LOG_BY_ID: 'LOGS_UPDATE_SUBTASK_LOG_BY_ID',
  UPDATE_MENTIONS_COUNT: 'LOGS_UPDATE_MENTIONS_COUNT',
  SET_MENTIONS_COUNT: 'LOGS_SET_MENTIONS_COUNT'
}

const initialState = {
  loading: { task: true, subtask: true, workspace: true },
  data: [],
  currentWorkspaceLogs: { _id: '', data: [] },
  subtaskLogs: [],
  mentionsCount: 0
}

const logsReducer = (state = initialState, action) => {
  // const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case LOGS.FETCHED:
      return {
        ...state,
        loading: { ...state.loading, task: false },
        error: false,
        data: [...state.data, action.payload]
      }
    case LOGS.ALL:
      return {
        ...state,
        loading: { ...state.loading, task: false },
        error: false,
        data: action.payload
      }
    case LOGS.SUBTASK_LOGS:
      return {
        ...state,
        loading: { ...state.loading, subtask: false },
        subtaskLogs: action.payload
      }
    case LOGS.WORKSPACE_LOGS:
      let Data
      if (action.payload._id === state.currentWorkspaceLogs._id) {
        Data = [
          ...new Map(
            [
              ...state.currentWorkspaceLogs.data,
              ...action.payload.data
            ].map(item => [item._id, item])
          ).values()
        ].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      } else {
        Data = action.payload.data
      }

      return {
        ...state,
        loading: { ...state.loading, workspace: false },
        currentWorkspaceLogs: { _id: action.payload._id, data: Data }
      }
    case LOGS.UPDATE_WORKSPACE_LOGS:
      return {
        ...state,
        currentWorkspaceLogs: {
          ...state.currentWorkspaceLogs,
          data: [action.payload, ...state.currentWorkspaceLogs.data]
        }
      }
    case LOGS.UPDATE_SUBTASK_LOGS:
      return {
        ...state,
        subtaskLogs: [...state.subtaskLogs, action.payload]
      }

    case LOGS.POSTED_LOG:
      const log = state.data.find(item => item._id === action.payload._id)

      // if log exists do nothing!
      if (log) return state
      return {
        ...state,
        data: [...state.data, action.payload]
      }

    case LOGS.DELETE_COMMENT:
      return {
        ...state,
        data: [...state.data].filter(item => item._id !== action.payload._id),
        subtaskLogs: [...state.subtaskLogs].filter(
          item => item._id !== action.payload._id
        ),
        currentWorkspaceLogs: {
          ...state.currentWorkspaceLogs,
          data: [
            ...state.currentWorkspaceLogs.data.filter(
              item => item._id !== action.payload._id
            )
          ]
        }
      }

    case LOGS.REMOVE_LOGS:
      const target = action.payload.target

      return {
        ...state,
        loading: { ...state.loading, [target]: true },
        data: target === 'task' ? [] : state.data,
        currentWorkspaceLogs:
          target === 'workspace'
            ? { _id: '', data: [] }
            : state.currentWorkspaceLogs,
        subtaskLogs: target === 'subtask' ? [] : state.subtaskLogs
      }

    case LOGS.UPDATE_TASK_LOG_BY_ID:
      return {
        ...state,
        data: state.data.map(item =>
          item._id === action.payload._id ? action.payload : item
        )
      }
    case LOGS.UPDATE_SUBTASK_LOG_BY_ID:
      return {
        ...state,
        subtaskLogs: state.subtaskLogs.map(item =>
          item._id === action.payload._id ? action.payload : item
        )
      }

    case LOGS.UPDATE_MENTIONS_COUNT:
      return {
        ...state,
        mentionsCount: state.mentionsCount + action.payload
      }
    case LOGS.SET_MENTIONS_COUNT:
      return {
        ...state,
        mentionsCount: action.payload
      }

    default:
      return state
  }
}

export default logsReducer
