import axios from 'axios'
import { COUPON } from './reducer'
import { catchAsync, catchAsyncDispatch } from 'utils'
import { INVOICES } from 'thunks/invoices/reducer'

/**
 * Create coupon
 * @param {Object} data
 * @param {Function} callback function.
 */
export const createCoupon = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: '/clientcoupon',
      data: data.body
    })

    dispatch({ type: COUPON.ADD_ONE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch all coupons
 * @param {Function} callback function.
 */
export const fetchAllCoupons = callback => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/clientcoupon/user'
    })

    dispatch({
      type: COUPON.FETCHED,
      payload: res.data
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update coupon by id
 * @param {Function} callback function.
 */
export const updateCoupon = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/clientcoupon/${data.id}`,
      data: data.body
    })

    dispatch({ type: COUPON.UPDATE, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete coupon by id
 * @param {Function} callback function.
 */
export const deleteCoupon = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    await axios({
      method: 'DELETE',
      url: `/clientcoupon/${data.id}`
    })

    dispatch({ type: COUPON.DELETE, payload: data.id })
    if (callback) callback()
  }, callback)
}

/**
 * Validate coupon
 * @param {Function} callback function.
 */
export const validateCoupon = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/clientcoupon/validate',
      params: data.params,
      isPublic: true
    })
    if (callback) callback(res.data)
    dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data.invoice })
  }, callback)
}

/**
 * remove coupon
 * @param {Function} callback function.
 */
export const removeCoupon = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/clientcoupon/remove',
      params: data.params,
      isPublic: true
    })
    if (callback) callback(res.data)
    dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data.invoice })
  }, callback)
}
