import produce from 'immer'

export const ONBOARDING = {
  FETCHED: 'ONBOARDING_FETCHED',
  LOADING: 'ONBOARDING_LOADING',
  ERROR: 'ONBOARDING_ERROR',
  ADD_TEMPLATE: 'ONBOARDING_ADD_TEMPLATE',
  UPDATE_TEMPLATE: 'ONBOARDING_UPDATE_TEMPLATE',
  UPDATE_ALL: 'ONBOARDING_UPDATE_ALL',
  DELETE_ONE: 'ONBOARDING_DELETE_ONE',
  SWITCH_ACTIVATION: 'ONBOARDING_SWITCH_WORKFLOW_ACTIVATION',
  ADD_CURRENT_TEMPLATE: 'ONBOARDING_ADD_CURRENT_TEMPLATE',
  ADD_STEP: 'ONBOARDING_ADD_STEP',
  REMOVE_STEP: 'ONBOARDING_REMOVE_STEP',
  UPDATE_STEP: 'ONBOARDING_UPDATE_STEP',
  UPDATE_STEP_ALL: 'ONBOARDING_UPDATE_STEP_ALL',
  UPDATE_STEP_PROPERTIES: 'ONBOARDING_UPDATE_STEP_PROPERTIES',
  ADD_INTAKE_FIELD: 'ONBOARDING_ADD_INTAKE_FIELD',
  UPDATE_INTAKE_FIELD: 'ONBOARDING_UPDATE_INTAKE_FIELD',
  REMOVE_INTAKE_FIELD: 'ONBOARDING_REMOVE_INTAKE_FIELD',
  UPDATE_FORM_FIELD: 'ONBOARDING_UPDATE_FORM_FIELD',
  ADD_INSTRUCTIONS_FIELD: 'ONBOARDING_ADD_INSTRUCTIONS_FIELD',
  UPDATE_INSTRUCTIONS_Field: 'ONBOARDING_UPDATE_INSTRUCTIONS_Field',
  REMOVE_INSTRUCTIONS_Field: 'ONBOARDING_REMOVE_INSTRUCTIONS_Field',
  REORDER_STEPS: 'ONBOARDING_REORDER_STEPS',
  CHANGES_SAVED: 'ONBOARDING_CHANGES_SAVED',
  CHANGES_DETECTED: 'ONBOARDING_CHANGES_DETECTED',
  CONTENTS_FETCHED: 'ONBOARDING_CONTENTS_FETCHED',
  ADD_EVENT: 'ONBOARDING_ADD_EVENT',
  REMOVE_EVENT: 'ONBOARDING_REMOVE_EVENT',
  UPDATE_EVENT: 'ONBOARDING_UPDATE_EVENT'
}

const initialState = {
  loading: true,
  error: false,
  fetched: false,
  data: [],
  currentTemplate: {},
  changesDetected: false,
  contents: [] //fixed non editable steps
}

const onboardingReducer = produce((draft, action) => {
  switch (action.type) {
    case ONBOARDING.FETCHED:
      draft.loading = false
      draft.data = action.payload
      draft.fetched = true
      return draft

    case ONBOARDING.CONTENTS_FETCHED:
      draft.contents = action.payload
      return draft

    case ONBOARDING.ADD_TEMPLATE:
      draft.data.unshift(action.payload)
      return draft

    case ONBOARDING.UPDATE_TEMPLATE:
      const idx = draft.data.findIndex(item => item._id === action.payload._id)
      draft.data[idx] = action.payload
      return draft

    case ONBOARDING.UPDATE_ALL:
      draft.data = action.payload
      return draft

    case ONBOARDING.ERROR:
      draft.loading = false
      return draft

    case ONBOARDING.DELETE_ONE:
      const idx2 = draft.data.findIndex(item => item._id === action.payload)
      if (idx2 !== -1) {
        draft.data.splice(idx2, 1)
      }

      return draft

    case ONBOARDING.SWITCH_ACTIVATION: {
      draft.data.forEach(item => {
        if (item._id !== action.payload._id && item.displayAfterSignup) {
          item.displayAfterSignup = false
        }
      })
      return draft
    }

    case ONBOARDING.ADD_CURRENT_TEMPLATE: {
      draft.currentTemplate = action.payload
      return
    }

    case ONBOARDING.ADD_STEP: {
      const { data, index } = action.payload

      if (!isNaN(index)) {
        draft.currentTemplate.steps.splice(index, 0, data)
      } else {
        draft.currentTemplate.steps.push(data)
      }

      draft.changesDetected = true
      return
    }

    case ONBOARDING.REMOVE_STEP: {
      const stepIndex = draft.currentTemplate.steps.findIndex(
        item => item.stepId === action.payload.id
      )
      draft.currentTemplate.steps.splice(stepIndex, 1)
      draft.changesDetected = true
      break
    }

    case ONBOARDING.UPDATE_STEP: {
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === action.payload.id
      )
      step[action.payload.prop] = action.payload.value
      draft.changesDetected = true
      break
    }

    case ONBOARDING.UPDATE_STEP_ALL: {
      const stepIndex = draft.currentTemplate.steps.findIndex(
        item => item.stepId === action.payload.stepId
      )

      console.log(action.payload)

      if (stepIndex !== -1) {
        draft.currentTemplate.steps.splice(stepIndex, 1, action.payload.data)
        draft.changesDetected = true
      }

      break
    }

    case ONBOARDING.UPDATE_STEP_PROPERTIES: {
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === action.payload.id
      )
      step.properties[action.payload.prop] = action.payload.value
      draft.changesDetected = true
      break
    }

    case ONBOARDING.ADD_INTAKE_FIELD: {
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === action.payload.id
      )
      step.properties.fields.push(action.payload.data)
      draft.changesDetected = true
      break
    }

    case ONBOARDING.REMOVE_INTAKE_FIELD: {
      const { stepId, fieldId } = action.payload
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === stepId
      )
      const fieldIndex = step.properties.fields.findIndex(
        item => item.id === fieldId
      )

      if (fieldIndex !== -1) {
        step.properties.fields.splice(fieldIndex, 1)
        draft.changesDetected = true
      }
      break
    }

    case ONBOARDING.UPDATE_INTAKE_FIELD: {
      const { stepId, fieldId, prop, value } = action.payload
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === stepId
      )
      const field = step.properties.fields.find(item => item.id === fieldId)

      field[prop] = value
      draft.changesDetected = true
      break
    }

    case ONBOARDING.UPDATE_FORM_FIELD: {
      const { stepId, key, value } = action.payload
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === stepId
      )
      step.properties[key].value = value
      step.properties[key].error = false
      draft.changesDetected = true
      break
    }

    case ONBOARDING.ADD_INSTRUCTIONS_FIELD: {
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === action.payload.stepId
      )
      step.properties.list.push(action.payload.value)
      draft.changesDetected = true
      break
    }

    case ONBOARDING.UPDATE_INSTRUCTIONS_Field: {
      const { stepId, fieldId, value } = action.payload
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === stepId
      )
      const fieldIndex = step.properties.list.findIndex(
        item => item.id === fieldId
      )
      step.properties.list[fieldIndex].text = value
      draft.changesDetected = true
      break
    }

    case ONBOARDING.REMOVE_INSTRUCTIONS_Field: {
      const step = draft.currentTemplate.steps.find(
        item => item.stepId === action.payload.stepId
      )
      const fieldIndex = step.properties.list.findIndex(
        item => item.id === action.payload.fieldId
      )
      step.properties.list.splice(fieldIndex, 1)
      draft.changesDetected = true
      break
    }

    case ONBOARDING.REORDER_STEPS: {
      let splicedStep = draft.currentTemplate.steps.splice(
        action.payload.sourceIndex,
        1
      )

      draft.currentTemplate.steps.splice(
        action.payload.destinationIndex,
        0,
        splicedStep[0]
      )
      draft.changesDetected = true
      break
    }

    case ONBOARDING.ADD_EVENT: {
      draft.currentTemplate.automation = draft.currentTemplate.automation || []
      const stepEventObj = draft.currentTemplate.automation.find(
        eventObj => eventObj.stepId === action.payload.stepId
      )

      if (stepEventObj) {
        stepEventObj.events.push(action.payload.eventData)
      } else {
        draft.currentTemplate.automation.push({
          stepId: action.payload.stepId,
          events: [action.payload.eventData]
        })
      }
      draft.changesDetected = true
      break
    }

    case ONBOARDING.REMOVE_EVENT: {
      if (!draft.currentTemplate.automation) return draft

      const stepEventObj = draft.currentTemplate.automation.find(
        eventObj => eventObj.stepId === action.payload.stepId
      )

      if (!stepEventObj) return draft

      stepEventObj.events.splice(action.payload.eventIndex, 1)
      draft.changesDetected = true
      break
    }

    case ONBOARDING.UPDATE_EVENT: {
      const stepEventObj = draft.currentTemplate.automation.find(
        eventObj => eventObj.stepId === action.payload.stepId
      )

      if (!stepEventObj) return draft

      stepEventObj.events[action.payload.eventIndex][action.payload.key] =
        action.payload.value
      draft.changesDetected = true
      break
    }

    case ONBOARDING.CHANGES_SAVED: {
      draft.changesDetected = false
      break
    }

    case ONBOARDING.CHANGES_DETECTED: {
      draft.changesDetected = true
      break
    }

    default:
      return draft
  }
}, initialState)

export default onboardingReducer
