export const INVOICES = {
  LOADING: 'INVOICES_LOADING',
  FETCHED: 'INVOICES_FETCHED',
  UPDATE_ONE_INVOICE: 'UPDATE_ONE_INVOICE',
  ERROR: 'INVOICES_ERROR',
  ADD: 'INVOICES_ADD',
  DELETE_ONE_INVOICE: 'DELETE_ONE_INVOICE',
  DELETE_MANY_INVOICE: 'DELETE_MANY_INVOICE'
}

const initialState = {
  loading: true,
  error: false,
  data: [],
  fetched: false
}

const clientInvoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICES.LOADING:
      return { ...state, loading: true }

    case INVOICES.ADD:
      return { ...state, data: [action.payload, ...state.data] }

    case INVOICES.FETCHED:
      return { ...state, loading: false, fetched: true, data: action.payload }

    case INVOICES.UPDATE_ONE_INVOICE:
      const index = state.data.findIndex((el) => el._id === action.payload?._id)

      if (index !== -1) {
        const newData = [...state.data]
        newData[index] = action.payload
        return { ...state, loading: false, fetched: true, data: newData }
      } else {
        return state
      }

    case INVOICES.DELETE_ONE_INVOICE:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }

    case INVOICES.DELETE_MANY_INVOICE:
      return {
        ...state,
        data: state.data.filter((item) => item.user._id !== action.payload.id)
      }

    case INVOICES.ERROR:
      return { ...state, error: true, loading: false }

    default:
      return state
  }
}

export default clientInvoicesReducer
