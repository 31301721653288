import React, { useEffect, useState } from 'react'
import { TextField, useTheme } from '@material-ui/core'
import {
  ColoredAvatars,
  CustomFlag,
  // CustomTooltip,
  FormLabel,
  Loader,
  LoadingButton
  // RoundedTextInput
} from 'global/globalComponents'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import PriorityDropDown from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FlagOutlined } from 'static/svg/pennant-outlined.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import DatePicker from 'react-datepicker'
// import { setHours } from 'date-fns'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import { useDispatch, useSelector } from 'react-redux'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { closeApprovalModal } from 'thunks/generlAppState/actions'
import { createApproval, fetchApprovals } from 'thunks/approvals/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import RaisedApprovalCard from './RaisedApprovalCard'
import clsx from 'clsx'
import QuestionMarkTooltip from 'global/globalComponents/QuestionMarkTooltip'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

const CreateApprovalModal = () => {
  const { open, data } = useSelector(
    state => state.generalAppState.approvalModal
  )
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(closeApprovalModal())
  }

  return (
    <CustomModal open={open} size="extraLarge">
      <ModalContent onClose={onClose} data={data} />
    </CustomModal>
  )
}

const getInitialData = ({ module, moduleId }) => {
  return {
    name: '',
    description: '',
    priority: null,
    module: module,
    moduleId: moduleId,
    stages: [
      {
        name: 'Stage 1',
        stageNumber: 1,
        dueDate: null,
        members: []
      }
    ],
    rules: {
      approvalRequirement: 1
    }
  }
}

const ModalContent = ({ onClose, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [formData, setFormData] = useState(
    getInitialData({ module: data.module, moduleId: data.moduleId })
  )
  // const [coordinatorAnchorEl, setCoordinatorAnchorEl] = useState(null)
  // const coordinatorOptions = data.approvers.filter(user => {
  //   let idx = formData.coordinators.findIndex(item => item._id === user._id)
  //   return idx === -1
  // })
  const [loading, setLoading] = useState(false)
  const [approvalsData, setApprovalsData] = useState({
    loading: true,
    data: []
  })
  // const [showAddApprovalForm, setShowAddApprovalForm] = useState(true)

  useEffect(() => {
    fetchApprovals({ query: { moduleId: data.moduleId } }, (res, err) => {
      if (!err) {
        setApprovalsData({ loading: false, data: res })
      }
    })
  }, [data.moduleId])

  const handlePriorityChange = val => {
    setFormData(prev => ({ ...prev, priority: val }))
    setAnchorEl(null)
  }

  // const handleCoordinatorSelect = val => {
  //   // setCoordinatorAnchorEl(null)
  //   setFormData(prev => ({
  //     ...prev,
  //     coordinators: [...prev.coordinators, val]
  //   }))
  // }

  // const handleRemoveCoordinator = userId => {
  //   setFormData(prev => ({
  //     ...prev,
  //     coordinators: prev.coordinators.filter(item => item._id !== userId)
  //   }))
  // }

  const handleCreateApproval = () => {
    // =========== Validation ==========
    let isValid = true
    if (formData.name.trim() === '' || formData.description.trim() === '') {
      isValid = false
    }

    formData.stages.forEach(stage => {
      if (
        stage.name.trim() === '' ||
        !stage.dueDate ||
        stage.members.length === 0
      ) {
        isValid = false
      }
    })

    if (!isValid) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS')))
      return
    }

    setLoading(true)
    dispatch(
      createApproval(
        {
          body: {
            name: formData.name,
            description: formData.description,
            module: data.module,
            moduleId: data.moduleId,
            dueDate: formData.stages[0].dueDate,
            priority: formData.priority?.order,
            rules: formData.rules,
            stages: formData.stages.map((stage, index) => ({
              ...stage,
              stageNumber: index + 1,
              members: stage.members.map(item => ({
                ...item,
                user: item.user._id
              }))
            }))
          }
        },
        (res, err) => {
          setLoading(false)
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            // setting it to default
            resetFormData()
            setApprovalsData(prev => ({
              loading: false,
              data: [res, ...prev.data]
            }))
            dispatch(fireSuccessToaster('Approval created successfully!'))
          }
        }
      )
    )
  }

  const resetFormData = () => {
    setFormData(
      getInitialData({ module: data.module, moduleId: data.moduleId })
    )
  }

  const handleOnChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleAddNewStage = () => {
    setFormData(prev => ({
      ...prev,
      stages: [
        ...prev.stages,
        {
          name: `Stage ${prev.stages.length + 1}`,
          stageNumber: prev.stages.length + 1,
          dueDate: null,
          members: []
        }
      ]
    }))
  }

  const handleUpdateStageData = ({ index, data }) => {
    setFormData(prev => ({
      ...prev,
      stages: prev.stages.map((item, idx) => (idx === index ? data : item))
    }))
  }

  const handleRemoveStage = index => {
    setFormData(prev => ({
      ...prev,
      stages: prev.stages.filter((item, idx) => idx !== index)
    }))
  }

  const handleDeleteApproval = approvalId => {
    setApprovalsData(prev => ({
      ...prev,
      data: prev.data.filter(item => item._id !== approvalId)
    }))
  }

  const handleUpdateRules = rules => {
    setFormData(prev => ({ ...prev, rules }))
  }

  return (
    <>
      <CustomModalHeader heading={t('APPROVAL')} handleClose={onClose} />

      <CustomModalBody>
        {approvalsData.loading ? (
          <Loader />
        ) : (
          <>
            <div className="p-6 border rounded mb-8">
              <div>
                <div className="flex items-center mb-4">
                  <div className="w-36 flex-shrink-0">
                    <FormLabel className="pb-0">
                      {t('APPROVAL_ITEM')}:
                    </FormLabel>
                  </div>
                  <div className="px-2 py-1 bg-gray-200 dark:bg-dark-main3 rounded text-smaller">
                    {data.approvalItemName}
                  </div>
                </div>

                <div className="flex items-center mb-4">
                  <div className="w-36 flex-shrink-0">
                    <FormLabel className="pb-0" required>
                      {t('TITLE')}:
                    </FormLabel>
                  </div>
                  <div>
                    <TextField
                      value={formData.name}
                      onChange={handleOnChange}
                      inputProps={{ name: 'name' }}
                    />
                  </div>
                </div>

                <div className="flex items-center mb-4">
                  <div className="w-36 flex-shrink-0">
                    <FormLabel className="pb-0" required>
                      {t('DESCRIPTION')}:
                    </FormLabel>
                  </div>
                  <div className="w-full max-w-lg">
                    <TextField
                      value={formData.description}
                      onChange={handleOnChange}
                      multiline={true}
                      rows={5}
                      className="w-full"
                      inputProps={{ name: 'description' }}
                    />
                  </div>
                </div>

                <div className="flex items-center mb-4">
                  <div className="w-36 flex-shrink-0">
                    <FormLabel className="pb-0">{t('PRIORITY')}:</FormLabel>
                  </div>
                  <div className="w-full max-w-lg">
                    <button onClick={e => setAnchorEl(e.currentTarget)}>
                      {formData.priority && formData.priority.order !== 5 ? (
                        <CustomFlag priority={formData.priority.order} />
                      ) : (
                        <span className="text-base text-gray-400 w-6 h-6 border border-gray-400 border-dashed rounded-full flex justify-center items-center">
                          <FlagOutlined className="text-xs 2xl:text-base" />
                        </span>
                      )}
                    </button>
                  </div>
                </div>

                {/* <div className="flex items-center mb-4">
                  <div className="w-36 flex-shrink-0">
                    <FormLabel className="pb-0" required>
                      {t('DUE_DATE')}:
                    </FormLabel>
                  </div>
                  <div>
                    <DatePicker
                      selected={
                        formData.dueDate
                          ? new Date(formData.dueDate)
                          : undefined
                      }
                      onChange={date =>
                        setFormData(prev => ({
                          ...prev,
                          dueDate: new Date(date).toISOString()
                        }))
                      }
                      customInput={
                        formData.dueDate ? (
                          <input
                            className="text-xs dark:bg-dark-main3"
                            style={{ width: '80px', padding: '4px 0' }}
                          />
                        ) : (
                          <CalenderIcon className="text-base text-gray-600 dark:text-dark-light cursor-pointer" />
                        )
                      }
                      dateFormat="MMM d, yyyy"
                      showDisabledMonthNavigation
                      locale="en-gb"
                    />
                  </div>
                </div> */}

                {/* <div className="flex items-center">
                  <div className="w-36 flex-shrink-0">
                    <FormLabel className="pb-0">{t('COORDINATORS')}:</FormLabel>
                  </div>
                  <div className="flex items-center">
                    {formData.coordinators.map(user => (
                      <div
                        key={user._id}
                        className="relative show-on-hover-parent mr-1"
                      >
                        <ColoredAvatars user={user} size="small" />
                        <button
                          onClick={() => handleRemoveCoordinator(user._id)}
                          className="absolute text-red-600 bg-white rounded-full show-on-hover-child"
                          style={{ top: -8, right: -8 }}
                        >
                          <CancelRoundedIcon fontSize="small" />
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={e => setCoordinatorAnchorEl(e.currentTarget)}
                      className="flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
                    >
                      <AddIcon
                        style={{ fontSize: '16px' }}
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div> */}
              </div>

              <div className="mb-4">
                <Stages
                  stagesData={formData.stages}
                  approvers={data.approvers}
                  handleUpdateStageData={handleUpdateStageData}
                  handleAddNewStage={handleAddNewStage}
                  handleRemoveStage={handleRemoveStage}
                />
              </div>

              <div className="mb-6">
                <Rules
                  rules={formData.rules}
                  handleUpdateRules={handleUpdateRules}
                />
              </div>

              <div className="flex justify-end gap-4">
                {/* {approvalsData.data.length > 0 && (
                  <Button
                    variant="outlined"
                    onClick={() => setShowAddApprovalForm(false)}
                  >
                    {t('CANCEL')}
                  </Button>
                )} */}

                <LoadingButton onClick={handleCreateApproval} loading={loading}>
                  {t('REQUEST')}
                </LoadingButton>
              </div>
            </div>

            {approvalsData.data.length > 0 && (
              <div>
                <h3 className="font-semibold mb-2">{t('PREVIOUSLY_RAISED')}</h3>

                {approvalsData.data.map(approval => (
                  <div key={approval._id} className="mb-4">
                    <RaisedApprovalCard
                      approvalData={approval}
                      handleDeleteApproval={handleDeleteApproval}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </CustomModalBody>

      <CustomModalFooter></CustomModalFooter>

      <PriorityDropDown
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onChange={handlePriorityChange}
        currentPriority={formData.priority?.order}
      />

      {/* <CollabDrowdown
        open={Boolean(coordinatorAnchorEl)}
        anchorEl={coordinatorAnchorEl}
        onClose={() => setCoordinatorAnchorEl(null)}
        list={coordinatorOptions}
        handleListItemClick={handleCoordinatorSelect}
      /> */}
    </>
  )
}

const Stages = ({
  stagesData,
  approvers,
  handleUpdateStageData,
  handleAddNewStage,
  handleRemoveStage
}) => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedStage, setSelectedStage] = useState(stagesData[selectedTab])
  const filteredApprovers = approvers.filter(user => {
    let idx = selectedStage.members.findIndex(
      item => item.user._id === user._id
    )
    return idx === -1
  })

  useEffect(() => {
    let timerId = window.setTimeout(() => {
      handleUpdateStageData({
        index: selectedTab,
        data: {
          ...selectedStage,
          name: selectedStage.name || `Stage ${selectedTab + 1}`
        }
      })
    }, 500)

    return () => window.clearTimeout(timerId)
  }, [selectedStage])

  useEffect(() => {
    if (selectedTab < stagesData.length) {
      setSelectedStage(stagesData[selectedTab])
    } else {
      setSelectedTab(0)
      setSelectedStage(stagesData[0])
    }
  }, [selectedTab, stagesData.length])

  const handleStageNameChange = e => {
    setSelectedStage(prev => ({ ...prev, name: e.target.value }))
    // handleUpdateStageData({index: selectedTab, data: })
  }

  const handleApproverSelect = val => {
    setAnchorEl(null)
    setSelectedStage(prev => ({
      ...prev,
      members: [...prev.members, { user: val, status: 'pending' }]
    }))
  }

  const handleRemoveApprover = userId => {
    setSelectedStage(prev => ({
      ...prev,
      members: prev.members.filter(item => item.user._id !== userId)
    }))
  }

  const handleRemove = e => {
    const index = parseInt(e.currentTarget.dataset.index)

    if (selectedTab >= index) {
      setSelectedTab(0)
    }

    handleRemoveStage(index)
  }

  return (
    <div>
      <div className="flex gap-4 overflow-auto w-full pt-10 pb-3 mb-1">
        {stagesData.map((stage, index) => (
          <div className="relative show-on-hover-parent">
            <div
              role="button"
              onClick={() => setSelectedTab(index)}
              key={index}
              className={clsx(
                'px-10 py-1.5 text-smaller border rounded whitespace-nowrap',
                selectedTab === index
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-600'
              )}
            >
              {stage.name}
            </div>
            {index > 0 && (
              <button
                style={{ position: 'absolute', top: -8, right: -8 }}
                className="w-5 h-5 inline-flex justify-center items-center border border-red-500 bg-red-500 text-white rounded-full text-sm show-on-hover-child"
                onClick={handleRemove}
                data-index={index}
              >
                <CrossIcon />
              </button>
            )}
          </div>
        ))}
        <button
          onClick={handleAddNewStage}
          className="px-8 py-1.5 border rounded flex items-center text-smaller text-gray-500"
        >
          <AddIcon className="w-4 h-4" />
          <span className="ml-1">{t('ADD')}</span>
        </button>
      </div>

      <div className="border px-4 py-5 rounded">
        <div className="flex items-center mb-4">
          <div className="w-36 flex-shrink-0">
            <FormLabel className="pb-0" required>
              {t('NAME')}:
            </FormLabel>
          </div>
          <div>
            <TextField
              value={selectedStage.name}
              onChange={handleStageNameChange}
            />
          </div>
        </div>

        <div className="flex items-center mb-4">
          <div className="w-36 flex-shrink-0">
            <FormLabel className="pb-0" required>
              {t('DUE_DATE')}:
            </FormLabel>
          </div>
          <div>
            <DatePicker
              selected={
                selectedStage.dueDate
                  ? new Date(selectedStage.dueDate)
                  : undefined
              }
              onChange={date =>
                setSelectedStage(prev => ({
                  ...prev,
                  dueDate: new Date(date).toISOString()
                }))
              }
              customInput={
                selectedStage.dueDate ? (
                  <input
                    className="text-xs dark:bg-dark-main3"
                    style={{ width: '80px', padding: '4px 0' }}
                  />
                ) : (
                  <CalenderIcon className="text-base text-gray-600 dark:text-dark-light cursor-pointer" />
                )
              }
              dateFormat="MMM d, yyyy"
              showDisabledMonthNavigation
              locale="en-gb"
            />
          </div>
        </div>

        <div className="flex items-center mb-4">
          <div className="w-36 flex-shrink-0">
            <FormLabel className="pb-0" required>
              {t('APPROVERS')}:
            </FormLabel>
          </div>
          <div className="flex items-center">
            {selectedStage.members.map(item => (
              <div
                key={item.user._id}
                className="relative show-on-hover-parent mr-1"
              >
                <ColoredAvatars user={item.user} size="small" />
                <button
                  onClick={() => handleRemoveApprover(item.user._id)}
                  className="absolute text-red-600 bg-white rounded-full show-on-hover-child"
                  style={{ top: -8, right: -8 }}
                >
                  <CancelRoundedIcon fontSize="small" />
                </button>
              </div>
            ))}
            <button
              onClick={e => setAnchorEl(e.currentTarget)}
              className="flex-shrink-0 bg-white dark:bg-dark-main2 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
            >
              <AddIcon style={{ fontSize: '16px' }} aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <CollabDrowdown
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        list={filteredApprovers}
        handleListItemClick={handleApproverSelect}
      />
    </div>
  )
}

const Rules = ({ rules, handleUpdateRules }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [selectedUserApprovalRule, setSelectedUserApprovalRule] = useState({})
  const userApprovalRuleOptions = [
    { label: t('ANY_USER'), value: 1 },
    { label: t('HALF_USERS'), value: 50 },
    { label: t('ALL_USERS'), value: 100 }
  ]

  useEffect(() => {
    setSelectedUserApprovalRule(
      userApprovalRuleOptions.find(
        item => item.value === rules.approvalRequirement
      )
    )
  }, [rules])

  const handleApprovalRuleChange = newVal => {
    setSelectedUserApprovalRule(newVal)
    handleUpdateRules({ approvalRequirement: newVal.value })
  }

  return (
    <div>
      <div className="flex items-center">
        <div className="text-sm font-medium mr-2">{t('RULES')}</div>
        <QuestionMarkTooltip text="Set rules for approval" direction="right" />
      </div>

      <ul>
        <li className="flex items-start justify-between">
          <FormLabel>{t('APPROVAL_RULE_MESSAGE')}:</FormLabel>
          <Select
            value={selectedUserApprovalRule}
            isSearchable={false}
            onChange={handleApprovalRuleChange}
            options={userApprovalRuleOptions}
            styles={reactSelectCustomStyles(theme)}
            className="w-28"
            menuPosition="fixed"
          />
        </li>
      </ul>
    </div>
  )
}

export default CreateApprovalModal
