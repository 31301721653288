import React, { useEffect } from 'react'
// import { Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
// import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
// import SectionHeading from 'global/globalComponents/SectionHeading'
import { Switch, useRouteMatch, Route } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'
import { fetchMarketplaceInvoices } from 'thunks/serviceMarketplace/actions'
import AgencyMarketplaceInvoicePreview from './AgencyMarketplaceInvoicePreview'
import AgencyMarketplaceInvoicesTable from './AgencyMarketplaceInvoicesTable'

// const totalInvoicesToShow = 10
const AgencyMarketplaceInvoices = ({ invoices }) => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  useEffect(() => {
    if (!invoices.fetched) {
      dispatch(fetchMarketplaceInvoices())
    }
  }, [invoices.fetched])

  return (
    <div className="mt-6">
      <Switch>
        <Route path={url} exact>
          <AgencyMarketplaceInvoicesTable invoices={invoices.data} />
        </Route>
        <Route path={url + '/:invoiceId/preview'} exact>
          <AgencyMarketplaceInvoicePreview invoices={invoices.data} />
        </Route>
      </Switch>
    </div>
  )
}

// const Invoices = ({ invoices }) => {
//   const { t } = useTranslation()
//   const [visibleInvoices, setVisibleInvoices] = useState({
//     loading: true,
//     data: []
//   })
//   const search = useSelector(state => state.generalAppState.headerSearch)

//   useEffect(() => {
//     if (!invoices.loading) {
//       let arr = invoices.data
//         .filter(el => el.status !== 'draft')
//         .filter(item => {
//           let items = item.invoiceItems
//             .map(item => item.service.toLowerCase())
//             .join(' ')
//           return (
//             item.status.toLowerCase().includes(search.toLowerCase()) ||
//             items.includes(search.toLowerCase())
//           )
//         })

//       // const sliced = arr.splice(page * totalInvoicesToShow, totalInvoicesToShow)
//       setVisibleInvoices({ loading: false, data: arr })
//       // setPaginationTotalDataCount(myPayments.data.length)
//     }
//   }, [invoices, search])

//   return (
//     <Box>
//       <header className="mb-6 2xl:mb-8">
//         <SectionHeading>{t('INVOICES')}</SectionHeading>
//       </header>
//       {search !== '' && (
//         <p className="text-sm mb-2 ml-1 text-gray-500 font-medium dark:text-dark-light2">
//           {t('SEARCH_RESULT')}:{' '}
//           <span className="text-primary-dark dark:text-dark-light">
//             {search}
//           </span>
//         </p>
//       )}

//       <AgencyMarketplaceInvoicesTable invoices={visibleInvoices.data} />
//     </Box>
//   )
// }

export default AgencyMarketplaceInvoices
