import React, { useState } from 'react'
import { CustomModalHeader } from 'global/globalComponents/CustomModal'
import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core'
import { useLocation, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import WelcomeMessage from './WelcomeMessage'
import SetupAccount from './SetupAccount'

const AgencyWelcomeModal = () => {
  const history = useHistory()
  const [open, setOpen] = useState(true)
  const { pathname } = useLocation()
  const classes = useStyles()
  const [step, setStep] = useState(0)

  const handleCloseModal = () => {
    history.push(pathname)
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        invisible: false
      }}
      className={classes.modal}
    >
      <Fade in={open}>
        <div
          className={clsx(
            'relative rounded-xl overflow-auto dark:bg-dark-main2',
            classes.paper
          )}
        >
          {step === 0 ? (
            <WelcomeMessage goNext={() => setStep(1)} />
          ) : (
            <>
              <CustomModalHeader heading="Account Details" />
              <SetupAccount onClose={handleCloseModal} />
            </>
          )}
        </div>
      </Fade>
    </Modal>
  )
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
    // userSelect: 'none'
  },
  paper: props => ({
    width: '90%',
    maxWidth: 900,
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none'
    // borderRadius: '1.5rem',
  }),
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  }
}))

export default AgencyWelcomeModal
