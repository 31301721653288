import React, { useState } from 'react'
import Currency from 'static/Currency/Currency'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Select from 'react-select'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { getInvoiceSummary } from 'components/_agency/PaymentsSection/paymentStatic'

const InvoiceExpensesAndTotal = ({
  // couponsData,
  invoiceCurrency,
  // subTotal,
  invoiceItems,
  updateInvoiceCurrency,
  appliedCoupon,
  updateAppliedCoupon,
  taxData,
  handleUpdateTaxData,
  discountAmount,
  handleUpdateDiscountAmount,
  isEditable,
  invoiceSubtotal,
  invoiceTotal
}) => {
  const theme = useTheme()
  const currencySymbol = Currency[invoiceCurrency.toUpperCase()].symbol_native
  const { t } = useTranslation()
  const [currencyOptions, setCurrencyOptions] = useState(
    Object.keys(Currency).map(el => ({
      value: el,
      label: el
    }))
  )
  // const [selectedCoupon, setSelectedCoupon] = useState(appliedCoupon)
  const { subtotal, totalAmount, discount, taxAmount } = getInvoiceSummary({
    invoiceItems: invoiceItems,
    taxPercent: taxData.taxPercent,
    coupon: appliedCoupon,
    discountAmount: discountAmount
  })

  // const handleAddCoupon = () => {
  //   updateAppliedCoupon(selectedCoupon)
  // }

  const handleRemoveCoupon = () => {
    // setSelectedCoupon(null)
    updateAppliedCoupon(null)
    handleUpdateDiscountAmount(0)
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="text-sm font-semibold py-2">{t('SUBTOTAL')}</div>
        <div className="text-sm font-semibold py-2">
          {currencySymbol}
          {isEditable ? subtotal : invoiceSubtotal}
        </div>
      </div>

      {Boolean(discount) && (
        <div className="flex justify-between items-start text-smaller">
          <div className="text-gray-600 mb-1">
            <span className="font-medium mr-2">{t('DISCOUNT')}</span>
            <span className="text-green-500 uppercase">
              ({appliedCoupon?.code || t('SPECIAL_DISCOUNT')})
            </span>
          </div>
          <div className="font-medium relative">
            -{discount}
            {isEditable && (
              <button
                onClick={handleRemoveCoupon}
                className="absolute ml-1 text-red-500 opacity-90 hover:opacity-100"
                style={{ right: -20, top: 1 }}
              >
                <CrossIcon className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      )}

      <div className="flex text-gray-600 text-smaller justify-between dark:text-dark-light">
        <div className="flex font-medium">
          {isEditable ? (
            <DivInput
              type="number"
              value={taxData.taxPercent}
              onChange={e => handleUpdateTaxData('taxPercent', e.target.value)}
              placeholder="0"
              name="taxPercent"
            />
          ) : (
            <span>{taxData.taxPercent}</span>
          )}
          <span>%</span>

          {isEditable ? (
            <DivInput
              type="text"
              value={taxData.taxName}
              onChange={e => handleUpdateTaxData('taxName', e.target.value)}
              name="taxName"
              className="ml-1"
            />
          ) : (
            <span className="ml-1">{taxData.taxName}</span>
          )}
        </div>
        <div className="font-medium dark:text-dark-light">{taxAmount}</div>
      </div>

      <hr className="mt-4" />

      <div className="flex justify-between font-bold my-2">
        <div>{t('TOTAL')}</div>
        {isEditable ? (
          <div className="flex items-center">
            <div className="mr-2">{totalAmount.toLocaleString()}</div>

            <Select
              styles={reactSelectCustomStyles(theme, {
                control: {
                  height: 34,
                  minHeight: 0
                },
                option: {
                  fontSize: 12
                },
                valueContainer: {
                  padding: '0px 4px'
                },
                singleValue: {
                  fontSize: 12
                },
                dropdownIndicator: { padding: 0 },
                input: {
                  paddingTop: 0,
                  marginTop: 0
                }
              })}
              options={currencyOptions}
              onChange={newVal => updateInvoiceCurrency(newVal.value)}
              value={currencyOptions.find(
                item => item.value === invoiceCurrency.toUpperCase()
              )}
              maxMenuHeight={150}
              className="w-16 border-0 border-none"
            />
          </div>
        ) : (
          <div className="font-bold text-xl dark:text-dark-light">
            {Currency[invoiceCurrency.toUpperCase()].symbol_native}
            {invoiceTotal.toLocaleString()}
          </div>
        )}
      </div>

      {/* {isEditable && (
        <>
          {!appliedCoupon && (
            <div className="w-1/2 my-4">
              <label className="inline-block text-smaller text-gray-600 mb-1">
                {t('ADD_DISCOUNT')}
              </label>
              <div className="flex">
                <Select
                  options={couponsData}
                  value={selectedCoupon}
                  styles={reactSelectCustomStyles(theme)}
                  getOptionLabel={option =>
                    `${option.code} (${option.discount} ${
                      option.type === 'percentage' ? '%' : invoiceCurrency
                    })`
                  }
                  getOptionValue={option => option._id}
                  onChange={setSelectedCoupon}
                  maxMenuHeight={150}
                  className="w-full border-0 border-none"
                />
                <button
                  onClick={handleAddCoupon}
                  className="ml-2 px-3 border text-primary-main border-primary-main rounded text-xs font-semibold hover:bg-primary-main hover:text-white uppercase"
                >
                  {t('ADD')}
                </button>
              </div>
            </div>
          )}
        </>
      )} */}
    </>
  )
}

const DivInput = ({ value, className, name, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false)

  const toggleFocus = () => {
    setIsFocused(prev => !prev)
  }

  let fieldValue = value
  if (!fieldValue) {
    fieldValue = name === 'taxPercent' ? 0 : 'Tax' //setting default value
  }

  return (
    <>
      {isFocused ? (
        <input
          value={value}
          className={clsx(
            'w-10 px-1 hover:text-primary-main rounded border border-transparent focus:bg-gray-100  focus:border-primary-main mr-1',
            className
          )}
          {...rest}
          onBlur={toggleFocus}
          autoFocus
        />
      ) : (
        <span
          className={clsx(
            'hover:text-primary-main cursor-pointer rounded',
            className
          )}
          onClick={toggleFocus}
        >
          {fieldValue}
        </span>
      )}
    </>
  )
}

export default InvoiceExpensesAndTotal
