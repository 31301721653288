import axios from 'axios'
import { STRIPE_ACCOUNT } from './reducers'
import { catchAsync, catchAsyncDispatch } from 'utils'

/**
 * get stripe account data whether the client is connected or not.
 * @param {Object | null} data
 * @param {Function} callback callback function
 */
export const getStripeConnectStatus = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    dispatch({ type: STRIPE_ACCOUNT.LOADING })

    const res = await axios({
      url: '/connectaccount/stripeconnect',
      method: 'GET'
    })

    if (callback) callback(res.data)
    dispatch({ type: STRIPE_ACCOUNT.FETCHED, payload: res.data })
  }, callback)
}

/**
 * post api to get client connected or verified to stripe.
 * @param {String}  data.code auth code received in query params
 * @param {Function} callback callback function
 */
export const postStripeAccountData = catchAsync(async (data, callback) => {
  const res = await axios.post('/connectaccount', {
    authorization_code: data.code
  })
  if (callback) callback(res.data)
})

/**
 * delete api to delete or deactivate stripe.
 * @param {String} data.accountId
 * @param {Function} callback callback function
 */
export const deleteStripeAccount = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/connectaccount/${data.accountId}`,
      method: 'DELETE'
    })
    if (callback) callback(res.data)
    dispatch({ type: STRIPE_ACCOUNT.DELETE, payload: res.data })
  }, callback)
}

/**
 * delete api to delete or deactivate stripe.
 * @param {String} data.accountId
 * @param {Function} callback callback function
 */
export const updateStripeAccount = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      url: `/connectaccount/${data.id}`,
      method: 'PUT',
      data: data.data
    })
    if (callback) callback(res.data)
    dispatch({ type: STRIPE_ACCOUNT.UPDATE, payload: res.data })
  }, callback)
}

/**
 * fetch stripe customers.
 * @param {Object | null} data
 * @param {Function} callback callback function
 */
export const fetchStripeCustomers = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/user/stripe-customer'
  })

  if (callback) callback(res.data)
})

/**
 * fetch agency stripe accountId
 * @param {Object | null} data
 * @param {Function} callback callback function
 */
export const fetchAgencyStripeAccountId = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/connectaccount/fetchaccount',
    isPublic: true
  })

  if (callback) callback(res)
})

/**
 * fetch agency stripe accountId
 * @param {Object | null} data
 * @param {Function} callback callback function
 */
export const fetchConnectedAccounts = catchAsync(async callback => {
  const res = await axios({
    method: 'GET',
    url: '/connectaccount/user'
  })

  if (callback) callback(res.data)
})
