import React from 'react'
import { Td, Tr } from 'global/globalComponents/TailwindTable/TailwindTable'
import { ReactComponent as SquareIcon } from 'static/svg/stop.svg'

const NestedTimesheetTable = ({
  timesheetData,
  selectedDays,
  startTime,
  endTime,
  openEditTaskPanel
}) => {
  const gettimeOfProject = data => {
    const time = data.reduce((acc, obj) => acc + obj.duration, 0)
    return getTimeString(time / 1000)
  }

  const gettimeByDay = (data, day) => {
    const dayData = data.filter(el => new Date(el.start).toDateString() === day)
    const time = dayData.reduce((acc, obj) => acc + obj.duration, 0)
    return getTimeString(time / 1000)
  }

  const getTimeString = time => {
    if (time === 0) return 0
    const hours = Math.floor(time / (60 * 60))
    const minutes = Math.floor(Math.floor(time % (60 * 60)) / 60)
    const seconds = Math.ceil(Math.floor(time % (60 * 60)) % 60)
    if (hours !== 0) {
      return minutes !== 0
        ? seconds !== 0
          ? `${hours}h ${minutes}m ${seconds}s`
          : `${hours}h ${minutes}m`
        : `${hours}h`
    } else if (hours === 0 && minutes === 0) {
      return `${seconds}s`
    } else {
      return seconds === 0 ? `${minutes}m` : `${minutes}m ${seconds}s`
    }
  }

  const handleTaskPanel = item => {
    // let wId = item?.workSpace?._id
    // let wBoard = item?.workSpace?.defaultBoard
    let taskId = item?._id

    // if (wId && taskId && wBoard) {
    //   history.push(
    //     `${getUserPath(
    //       meData.role
    //     )}/workspace/${wId}/board/${wBoard}/task/${taskId}?view=board`
    //   )
    // }
    if (taskId) {
      openEditTaskPanel(taskId)
    }
  }
  return (
    <>
      {timesheetData.map((item, index) => (
        <Tr
          onClick={() => {
            handleTaskPanel(item)
          }}
          className="cursor-pointer"
        >
          <Td className="pl-8 flex items-center space-x-2">
            {/* <CustomTooltip title={'COMPLETED'} placement="top"> */}
            <SquareIcon className="h-2 w-2" style={{ fill: 'blue' }} />
            {/* </CustomTooltip> */}
            <span className="truncate"> {item.title ?? 'Deleted Task'}</span>
          </Td>
          <Td className="text-center text-xs">
            {gettimeOfProject(
              item.data?.filter(
                time =>
                  time.start >= new Date(startTime).getTime() &&
                  time.start <= new Date(endTime).getTime()
              )
            )}
          </Td>
          {selectedDays.map(day => (
            <Td className="text-left text-xs">
              {gettimeByDay(item.data, day) || '-'}
            </Td>
          ))}
        </Tr>
      ))}
    </>
  )
}

export default NestedTimesheetTable
