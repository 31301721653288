import { Skeleton } from '@material-ui/lab'
import React from 'react'

const TaskPanelLoading = () => {
  return (
    <div>
      <header className="flex items-center space-x-6 py-3 border-b px-4">
        <Skeleton
          variant="rect"
          width={80}
          height={25}
          className="rounded-full"
        />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rect" width={100} height={40} className="rounded" />
        <Skeleton variant="rect" width={120} height={30} className="rounded" />
      </header>

      <div className="py-4 px-8">
        <div className="mb-8">
          <Skeleton variant="text" width="30%" height={50} />
        </div>

        <div className="flex items-center space-x-8 mb-4">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="circle" width={30} height={30} />
        </div>
        <div className="flex space-x-8 mb-4">
          <Skeleton variant="text" width={80} />
          <Skeleton variant="text" width={50} />
        </div>
        <div className="flex space-x-8 mb-4">
          <Skeleton variant="text" width={70} />
          <Skeleton variant="text" width={50} />
        </div>
        <div className="flex space-x-8 mb-6">
          <Skeleton variant="text" width={80} />
          <Skeleton variant="text" width={50} />
        </div>

        <Skeleton
          variant="rect"
          width="100%"
          height={300}
          className="rounded"
        />
      </div>
    </div>
  )
}

export default TaskPanelLoading
