import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { CustomTooltip } from 'global/globalComponents'
import { ReactComponent as Comments } from 'static/svg/comments.svg'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { getChatPermission } from './chatStatic'

const NavbarHeader = ({
  t,
  meData,
  hideArchived,
  setHideArchived,
  handleSetSearch,
  openCreateChannelModal
}) => {
  const [searchActive, setSearchActive] = useState(false)
  const [search, setSearch] = useState('')
  const tabs = [t('ACTIVE'), t('ARCHIVED')]
  const [selectedTab, setSelectedTab] = useState(hideArchived ? 0 : 1)
  const chatPermission = getChatPermission(meData.role)

  useEffect(() => {
    setSelectedTab(hideArchived ? 0 : 1)
  }, [hideArchived])

  useEffect(() => {
    // Debounce
    let timerId = window.setTimeout(() => {
      handleSetSearch(search)
    }, 300)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [search])

  const handleTabChange = val => {
    setSelectedTab(val)
    setHideArchived(val === 0)
  }

  const toggleSearch = () => {
    if (searchActive) {
      setSearch('')
      setSearchActive(false)
    } else {
      setSearchActive(true)
    }
  }

  return (
    <header className="mb-2">
      <div
        className="px-6 flex justify-between items-center w-full border-b"
        style={{ height: 62 }}
      >
        {searchActive ? (
          <div className="flex items-center space-x-2 w-full">
            <SearchIcon className="text-gray-400 w-5 h-5 flex-shrink-0 dark:text-dark-light" />
            <input
              type="text"
              placeholder={t('SEARCH_HERE')}
              value={search}
              onChange={e => setSearch(e.target.value)}
              className="border-b border-gray-300 px-2 w-full py-1 text-gray-600"
              autoFocus={true}
            />

            <button onClick={toggleSearch}>
              <CrossIcon className="text-gray-400 w-5 h-5 flex-shrink-0 dark:text-dark-light" />
            </button>
          </div>
        ) : (
          <>
            <h2 className="font-semibold text-lg dark:text-dark-light2">
              {t('CHANNELS')}
            </h2>

            <div className="flex items-center space-x-2">
              {chatPermission.create && (
                <CustomTooltip title={t('NEW_CHANNEL')} placement="top">
                  <button
                    size="small"
                    variant="outlined"
                    onClick={openCreateChannelModal}
                    className="w-10 h-10 inline-flex bg-gray-50 hover:bg-gray-100 rounded-full justify-center items-center text-primary-main"
                  >
                    <Comments className="w-5 h-5" />
                  </button>
                </CustomTooltip>
              )}

              <CustomTooltip title={t('SEARCH')} placement="top">
                <button
                  size="small"
                  variant="outlined"
                  onClick={toggleSearch}
                  className="w-10 h-10 inline-flex bg-gray-50 hover:bg-gray-100 rounded-full justify-center items-center text-primary-main"
                >
                  <SearchIcon className="w-5 h-5" />
                </button>
              </CustomTooltip>
            </div>
          </>
        )}
      </div>
      <>
        {!searchActive && (
          <div className="px-6 mb-4">
            <Tabs
              value={selectedTab}
              onChange={(e, val) => handleTabChange(val)}
            >
              {tabs.map((item, i) => (
                <Tab
                  label={
                    <span className="text-gray-800 dark:text-dark-light">
                      {item}
                    </span>
                  }
                  key={item}
                  value={i}
                  className="flex-1"
                />
              ))}
            </Tabs>
          </div>
        )}
      </>
    </header>
  )
}

export default NavbarHeader
