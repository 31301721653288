import { Button } from '@material-ui/core'
import { PopupMenu } from 'global/globalComponents'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fireWarningToaster } from 'thunks/fireToaster/actions'
import { useTranslation } from 'react-i18next'
import { userRoles } from 'utils'
import { ReactComponent as SettingsIcon } from 'static/svg/setting.svg'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ArrowDropDown } from '@material-ui/icons'
import ServiceSettingsModal from './ServiceSettingsModal'
import { usePermission } from 'customHooks'

const ServiceHeader = ({
  services,
  handlePreviewModal,
  openCategoriesModal,
  openCreateAddOnModal,
  openAddServiceModal
}) => {
  const { t } = useTranslation()
  const { services: servicesPermission } = usePermission()
  const meData = useSelector(state => state.me.data)
  const dispatch = useDispatch()
  const [toggleSettingModal, setSettingToggle] = useState(false)

  const handleAddService = () => {
    if (
      (meData?.profile?.quota?.service?.left ??
        meData?.team.profile?.quota?.service?.left) <= 0
    ) {
      dispatch(
        fireWarningToaster(
          'Your service quota has been exhausted. Please upgrade your plan to add new services'
        )
      )
    } else {
      openAddServiceModal()
      // handleOpenNewServiceModal()
    }
  }

  const handleModalOpen = () => {
    setSettingToggle(true)
  }
  return (
    <header className="flex justify-end items-center mb-6 mt-3">
      <div className="flex flex-wrap justify-end items-center gap-4">
        {services.length ? (
          <Button
            variant="outlined"
            startIcon={<EyeIcon className="w-4 h-4" />}
            onClick={handlePreviewModal}
          >
            {t('PREVIEW')}
          </Button>
        ) : null}
        {servicesPermission.create && (
          <PopupMenu
            menuItems={[
              { label: t('ADD_SERVICE'), method: handleAddService },
              { label: t('CREATE_ADD_ON'), method: openCreateAddOnModal },
              { label: t('CATEGORIES'), method: openCategoriesModal }
            ]}
            targetComp={
              <Button endIcon={<ArrowDropDown />}>
                {t('SERVICE_OPTIONS')}
              </Button>
            }
          />
        )}
        {meData?.role === userRoles.USER_AGENCY && (
          <div
            className="cursor-pointer dark:text-dark-light"
            onClick={handleModalOpen}
          >
            <SettingsIcon />
          </div>
        )}
      </div>
      <ServiceSettingsModal
        open={toggleSettingModal}
        onClose={() => setSettingToggle(false)}
      />
    </header>
  )
}

export default ServiceHeader
