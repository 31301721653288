import axios from 'axios'
import { catchAsyncDispatch, storeCustomizeData } from 'utils'
import { ME } from './reducers'
import serviceWorker from 'serviceWorker'

/**
 * Fetch data of user
 * @param {Object|null} data
 * @param {Function} callback function will run after fetch. Recieve (err/response, error status) as arguments
 */
export const fetchMeData = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: '/user/me'
    })

    // Subscribing to service worker after getting me data
    serviceWorker({ meId: res.data._id })
    storeCustomizeData({
      isWhiteLabelActive: res.data.isWhiteLabelActive,
      // theme: res.data.isWhiteLabelActive
      //   ? res.data.theme ?? { primary: '#1600E5', heading: '#17191C' }
      //   : { primary: '#1600E5', heading: '#17191C' },
      brand:
        res.data.profile?.brand ??
        res.data.team?.profile?.brand ??
        res.data.agency?.brand ??
        {}
    })
    dispatch({
      type: ME.FETCHED,
      payload: {
        ...res.data,
        theme: res.data.theme || { primary: '#1600E5', heading: '#17191C' }
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

export const setLeftTrialDays = days => ({
  type: ME.SET_LEFT_TRIAL_DAYS,
  payload: days
})
