const getTimeSuggestion = (str) => {
  let ms = 0

  str.replace(/(\d+)\s(\w+)/gi, (match, p1, p2) => {
    switch (p2) {
      case 'seconds':
        ms += p1 * 1000
        break
      case 'minutes':
        ms += p1 * 1000 * 60
        break
      case 'hours':
        ms += p1 * 1000 * 60 * 60
        break
      case 'days':
        ms += p1 * 1000 * 60 * 60 * 24
        break
      case 'months':
        ms += p1 * 1000 * 60 * 60 * 24 * 30
        break
      case 'years':
        ms += p1 * 1000 * 60 * 60 * 24 * 30 * 365
        break
      default:
        ms += p1
    }
  })

  let timeStr = ''
  const hours = ms / (1000 * 60 * 60)
  const mins = (hours % 1) * 60
  const seconds = (mins % 1) * 60

  if (Math.floor(hours) > 0) timeStr += Math.floor(hours) + 'h '
  if (Math.floor(mins) > 0) timeStr += Math.floor(mins) + 'm '
  if (Math.floor(seconds) > 0) timeStr += Math.floor(seconds) + 's'

  return { timeStr, totalMs: ms }
}

export default getTimeSuggestion
