import React from 'react'
import ImageWrapper from '../ImageWrapper'
import ContentEditable from 'react-contenteditable'
import clsx from 'clsx'

const Footer = ({
  data,
  classes,
  updateTemplateSection,
  updateSectionBlock,
  isEditable
}) => {
  const [logoBlock, ...restBlocks] = data.blocks
  return (
    <div style={{ backgroundColor: '#384047' }}>
      <div className={clsx(classes.sectionContainer, classes.footerBlocks)}>
        <div>
          <ImageWrapper
            src={logoBlock.image}
            alt=""
            isEditable={isEditable}
            onImageUpdate={imgUrl =>
              updateSectionBlock({
                blockId: logoBlock.id,
                field: 'image',
                value: imgUrl
              })
            }
            style={{ width: 50 }}
          />
        </div>
        {restBlocks.map(block => (
          <ContentEditable
            key={block.id}
            html={block.description}
            onChange={e =>
              updateSectionBlock({
                blockId: block.id,
                field: 'description',
                value: e.target.value
              })
            }
            disabled={!isEditable}
          />
        ))}
      </div>
    </div>
  )
}

export default Footer
