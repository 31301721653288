import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { forgotPassword, forgotDomain } from 'thunks/passwordAndDomain/actions'
import { LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import globalCustomStyles from 'global/stlyeClasses/GlobalCustomStyles'
import { PatternScreen, RoundedTextInput } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import clientVenueAssets from 'static/clientVenueAssets'

const ForgotModal = ({ name, title, buttonText, link }) => {
  const { t } = useTranslation()
  const classes = globalCustomStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [email, setEmail] = useState('')
  const [domainRes, setDomainRes] = useState('')
  const companyInfoData = useSelector(
    state => state.generalAppState.companyInfo.data
  )
  const brandName = companyInfoData.isWhiteLabelActive
    ? companyInfoData.brand?.brandName
    : clientVenueAssets.brandName
  const isBasicLayoutActive =
    companyInfoData.pageCustomization?.layout === 'basic'

  useEffect(() => {
    dispatch(getAgencyInfoByDomain())
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    if (email.trim() === '')
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_ENTER_YOUR_EMAIL_ADDRESS'))
      )
    if (!/\S+@\S+\.\S+/.test(email))
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_EMAIL_ADDRESS'))
      )

    setLoading(true)

    if (name === 'domain') forgotDomain(email, domainCallback)
    else if (name === 'password') forgotPassword(email, passwordCallback)
  }

  const domainCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setLinkSent(true)
      setDomainRes(res.agencyDomain)
    }
  }

  const passwordCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(res.message))
      setLinkSent(true)
    }
  }

  const handleEmailInput = e => {
    setEmail(e.target.value)
  }

  return (
    <>
      <PatternScreen customize={true}>
        <div>
          <header className="mb-8">
            {isBasicLayoutActive ? (
              <div className="text-center">
                <div className="text-primary-main text-4xl font-bold mb-2">
                  {brandName}
                </div>
                <div className="text-xl font-semibold text-gray-700">
                  {t('FORGOT_YOUR')} {name}?
                </div>
              </div>
            ) : (
              <h1 className="text-4xl font-bold dark:text-dark-light">
                {t('FORGOT_YOUR')} <br />
                <span className="text-primary-main capitalize">{name}?</span>
              </h1>
            )}
          </header>

          <div>
            <form onSubmit={handleSubmit}>
              {linkSent ? (
                <div>
                  {title ? (
                    <Typography>{title}</Typography>
                  ) : (
                    <div className="mb-4">
                      <h3 className="font-bold">{t('YOUR_DOMAIN')}</h3>
                      <a
                        href={`https://${domainRes}`}
                        style={{ color: 'blue', display: 'block' }}
                      >
                        {domainRes}
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <label
                    htmlFor="email"
                    className="inline-block text-sm font-medium tracking-wide mb-2 dark:text-dark-light"
                  >
                    {t('ENTER_YOUR_REGISTERED_EMAIL')}
                  </label>
                  <RoundedTextInput
                    id="email"
                    placeholder={t('ENTER_EMAIL')}
                    type="text"
                    name="domain"
                    onChange={handleEmailInput}
                    value={email}
                    style={
                      isBasicLayoutActive ? { border: '1px solid #C9CED6' } : {}
                    }
                    autoComplete="one-time-code"
                  />
                  <div className="mt-4">
                    <LoadingButton
                      size="large"
                      type="submit"
                      loading={loading}
                      className="w-full"
                    >
                      {buttonText}
                    </LoadingButton>
                  </div>
                </>
              )}
            </form>

            {!domainRes ? (
              <p className="text-xs mt-2 dark:text-dark-light">
                {t('BACK_TO')}{' '}
                <Link to={link} className="font-bold text-primary-main">
                  {t('LOGIN')}
                </Link>
              </p>
            ) : (
              <p
                className={classes.para}
                style={{
                  textDecoration: 'none',
                  letterSpacing: '0px',
                  fontSize: '13px',
                  fontWeight: '400'
                }}
              >
                <p className="text-xs">
                  {t('PROCEED_TO')}{' '}
                  <a
                    href={`https://${domainRes}`}
                    className="text-primary-main font-bold"
                  >
                    {t('LOGIN')}
                  </a>
                </p>
              </p>
            )}
          </div>
        </div>
      </PatternScreen>
    </>
  )
}

export default ForgotModal
