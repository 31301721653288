import React from 'react'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PopupMenu } from 'global/globalComponents'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'

const EmbedTable = ({ handleEditEmbed, handleDeleteEmbed }) => {
  const { t } = useTranslation()
  const embed = useSelector(state => state.embed)
  const { clientId } = useParams()
  const clientEmbeds = embed.data.filter(item => item.client === clientId)

  return (
    <TableContainer mb={6}>
      <Thead>
        <Th>{t('TITLE')}</Th>
        <Th>{t('URL')}</Th>
        <Th></Th>
      </Thead>
      <Tbody>
        {embed.loading ? (
          <TableDataLoading cols={3} />
        ) : clientEmbeds.length > 0 ? (
          clientEmbeds.map((item, index) => (
            <Row
              t={t}
              key={item._id}
              {...item}
              handleEditEmbed={() => handleEditEmbed(item)}
              handleDeleteEmbed={() => handleDeleteEmbed(item)}
            />
          ))
        ) : (
          <Tr>
            <Td colSpan="3">{t('NO_EMBED_MESSAGE')}</Td>
          </Tr>
        )}
      </Tbody>
    </TableContainer>
  )
}

const Row = ({
  t,
  name,
  embed_settings,
  handleEditEmbed,
  handleDeleteEmbed
}) => {
  const menuOptions = [
    { label: t('EDIT'), icon: <PenIcon />, method: handleEditEmbed },
    {
      label: t('DELETE'),
      icon: <TrashIcon />,
      method: handleDeleteEmbed,
      isDanger: true
    }
  ]
  return (
    <Tr>
      <Td onClick={handleEditEmbed} className="cursor-pointer">
        {name}
      </Td>
      <Td>
        <a
          href={embed_settings.url}
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 hover:underline"
        >
          {embed_settings.url}
        </a>
      </Td>
      <Td center>
        <PopupMenu menuItems={menuOptions} />
      </Td>
    </Tr>
  )
}

export default EmbedTable
