import { makeStyles, createStyles } from '@material-ui/core'

const BoardsStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: `calc(100vh - ${theme.custom.projectHeader.height + 6}px)`,
      overflow: 'auto'
    },
    addNewCategoryBtn: {
      width: '278px',
      minWidth: '180px',
      height: '40px',
      color: 'gray',
      '& svg': {
        color: 'gray'
      }
    },

    columnContainer: {
      flex: `0 0 ${theme.custom.taskCard.width}`
    },
    columnHeaderContainer: {
      minWidth: '100%'
    },
    taskCardsContainer: {
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingBottom: '4rem',
      borderRadius: 3,
      transition: 'background-color 0.18s ease 0s',
      // minHeight: '100px',
      width: '100%',
      minWidth: `calc(${theme.custom.taskCard.width}px + 1rem)`, // card width + margin right
      // height: `calc(100vh - 240px)`, //picking 240 static value
      paddingRight: 2,
      '&.emtpyColumn': {
        width: '100%',
        paddingRight: 0,
        overflowY: 'hidden'
      },

      '&::-webkit-scrollbar-track': {
        // background: '#E2E2E2'
        background: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'transparent'
      },
      '&:hover::-webkit-scrollbar-track': {
        background: '#E2E2E2'
      },
      '&:hover::-webkit-scrollbar-thumb': {
        background: '#ACACAC'
      }
    },

    taskCardContainer: {
      maxWidth: theme.custom.taskCard.width,
      minWidth: theme.custom.taskCard.width,
      marginBottom: theme.spacing(2),
      boxShadow: '0 1px 2px #0000004d',
      marginRight: 16,
      '&.request': {
        boxShadow:
          ' 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
      }
    },

    linearProgressRoot: {
      height: 8,
      borderRadius: 5,
      background: '#efefef'
    },
    icons: {
      color: '#6f7782',
      transition: '0.2s transform',
      '&.expanded': {
        transform: 'rotate(180deg)'
      }
    }
  })
)

export default BoardsStyles
