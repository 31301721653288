import { Button } from '@material-ui/core'
import LoopRoundedIcon from '@material-ui/icons/LoopRounded'
import { useTranslation } from 'react-i18next'

const RefreshButton = ({ refreshing, onClick, size = 'small' }) => {
  const { t } = useTranslation()
  return (
    <Button
      startIcon={<LoopRoundedIcon className={refreshing ? 'spin' : ''} />}
      variant="outlined"
      onClick={onClick}
      size={size}
    >
      {t('REFRESH')}
    </Button>
  )
}

export default RefreshButton
