import { useState } from 'react'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ServicePreviewModal = ({ open, handleClose }) => {
  const [copy, setCopy] = useState(false)

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/services`
    )
    setCopy(true)
  }

  const visitServicesPage = () => {
    let a = document.createElement('a')
    a.target = '_blank'
    a.href = `${window.location.protocol}//${window.location.host}/services`
    a.click()
  }
  const { t } = useTranslation()

  return (
    <CustomModal open={open} handleClose={handleClose} size="extraSmall">
      <CustomModalHeader
        heading={t('SERVICE_PAGE_LINK')}
        handleClose={handleClose}
      />

      <CustomModalBody>
        <p className="mb-4 text-sm dark:text-dark-light">
          {t('SHARE_WITH_CUSTOMERS')}
        </p>
        <div className="space-y-2">
          <Button
            onClick={handleCopyLink}
            // size="small"
            className="w-full"
            // style={{ fontSize: 14, height: 32, marginBottom: 8 }}
          >
            {!copy && <CopyIcon fontSize="1rem" />}
            <span className="inline-block py-1 font-medium ml-2">
              {copy ? t('COPIED') : t('COPY_SERVICES_LINK')}
            </span>
          </Button>
          <Button
            variant="outlined"
            // size="small"
            className="w-full"
            onClick={visitServicesPage}
          >
            <span className="inline-block py-1">
              {t('VISIT_SERVICES_PAGE')}
            </span>
          </Button>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button onClick={handleClose}>{t('CLOSE')}</Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default ServicePreviewModal
