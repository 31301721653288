const initialState = {
  loading: false,
  data: [],
  error: null
}

export const AGENCY_PLAN = {
  FETCHED: 'FETCHED_AGENCY_PLANS',
  LOADING: 'LOADING_AGENCY_PLANS',
  ERROR: 'ERROR_AGENCY_PLANS'
}

const fetchAgencyPlansReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGENCY_PLAN.LOADING:
      return { ...state, loading: true, error: null }

    case AGENCY_PLAN.FETCHED:
      return { ...state, loading: false, data: action.payload }

    case AGENCY_PLAN.ERROR:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export default fetchAgencyPlansReducer
