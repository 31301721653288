import moment from 'moment'

const dateAndTimeHelper = {
  getWeekDays: date => {
    const days = []

    for (let i = 0; i < 7; i++) {
      days.push(moment(date).startOf('week').add(i, 'days').toDate())
    }

    return days
  },

  formatMilliseconds(ms) {
    if (!ms) return '0 min'

    let hours, mins
    hours = Math.floor(ms / (1000 * 60 * 60))

    if (hours > 0) {
      mins = Math.round((ms % (1000 * 60 * 60)) / (1000 * 60))
      return `${hours} h ${mins} min`
    } else {
      return `${Math.round(ms / (1000 * 60))} min`
    }
  },

  getMinutesAndHours(ms = 0) {
    let hours = 0,
      minutes = 0

    hours = Math.floor(ms / (1000 * 60 * 60))
    minutes = Math.round((ms % (1000 * 60 * 60)) / (1000 * 60))

    return { hours, minutes }
  },
  // if date1 is greater then returns +1
  compareDate(date1, date2) {
    if (date1.getFullYear() !== date2.getFullYear())
      return date1.getFullYear() > date2.getFullYear() ? 1 : -1
    if (date1.getMonth() !== date2.getMonth())
      return date1.getMonth() > date2.getMonth() ? 1 : -1
    if (date1.getDate() !== date2.getDate())
      return date1.getDate() > date2.getDate() ? 1 : -1
    return 0
  },

  getDateDiffInHours(date1, date2) {
    return Math.floor(Math.abs(date1 - date2) / (60 * 60 * 1000))
  },

  getDateDiffInDays(date1, date2) {
    return Math.round(
      ((date2 ? new Date(date2) : new Date()) - new Date(date1)) /
        (1000 * 60 * 60 * 24)
    )
  },

  fromNow(date, nowDate = Date.now()) {
    const SECOND = 1000
    const MINUTE = 60 * SECOND
    const HOUR = 60 * MINUTE
    const DAY = 24 * HOUR
    const WEEK = 7 * DAY
    const YEAR = 365 * DAY
    const MONTH = YEAR / 12
    const intervals = [
      { ge: YEAR, divisor: YEAR, text: '#yr ago' },
      { ge: MONTH, divisor: MONTH, text: '#mo ago' },
      { ge: WEEK, divisor: WEEK, text: '#wk ago' },
      { ge: DAY, divisor: DAY, text: '#d ago' },
      { ge: HOUR, divisor: HOUR, text: '#h ago' },
      { ge: MINUTE, divisor: MINUTE, text: '#m ago' },
      { ge: 0, divisor: 1, text: 'just now' }
    ]
    const now =
      typeof nowDate === 'object'
        ? nowDate.getTime()
        : new Date(nowDate).getTime()
    const diff =
      now - (typeof date === 'object' ? date : new Date(date)).getTime()
    const diffAbs = Math.abs(diff)

    for (const interval of intervals) {
      if (diffAbs >= interval.ge) {
        const x = Math.round(diffAbs / interval.divisor)
        return interval.text.replace('#', x)
        // const x = Math.round(Math.abs(diff) / interval.divisor);
        // const isFuture = diff < 0;
      }
    }
  },
  getRemainingTime(futureDateStr) {
    let currentDate = new Date()
    let futureDate = new Date(futureDateStr)
    let differenceInMilliseconds = futureDate - currentDate

    let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24))

    let hours = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )

    let minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    )

    let seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000)

    return {
      days: days < 0 ? 0 : days,
      hours: hours < 0 ? 0 : hours,
      minutes: minutes < 0 ? 0 : minutes,
      seconds: seconds < 0 ? 0 : seconds
    }
  }
}

export default dateAndTimeHelper
