import React from 'react'
import { useTranslation } from 'react-i18next'

const ProposalNotificationMessage = ({ field, source, after }) => {
  const { t } = useTranslation()

  const generateMessage = () => {
    if (field === 'create') {
      return (
        <>
          {t('NOTIFICATION_PROPOSAL_CREATE')}&nbsp;
          <span className="font-semibold">"{after}"</span>
        </>
      )
    }

    if (field === 'client_signed' || field === 'agency_signed') {
      return (
        <>
          {t('NOTIFICATION_PROPOSAL_SIGNED')}&nbsp;
          <span className="font-semibold">"{source.name}"</span>
        </>
      )
    }
  }

  return (
    <div>
      <div className="text-smaller">{generateMessage()}</div>
    </div>
  )
}

export default ProposalNotificationMessage
