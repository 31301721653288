import { useEffect, useState, useCallback } from 'react'
import { Button, Badge } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { useHistory, useRouteMatch } from 'react-router-dom'
import InvoiceTable from './InvoiceTable/InvoiceTable'
import { CustomTabs } from 'global/globalComponents'
import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import PopupMenu from 'global/globalComponents/PopupMenu/PopupMenu'
import { useTranslation } from 'react-i18next'
import ScheduledInvoicesTable from './ScheduledInvoicesTable/ScheduledInvoicesTable'
import { userRoles } from 'utils'
import InvoiceSettingsModal from 'components/_agency/InvoiceSettingsModal/InvoiceSettingsModal'

export default function CreateInvoices({
  invoices,
  handleToggleInvoiceModal,
  openMarkAsPaidAlert,
  openDeleteAlert
}) {
  const { t } = useTranslation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const search = useSelector(state => state.generalAppState.headerSearch)
  const access = useSelector(state => state.permission.data?.access)
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedTabKey, setSelectedTabKey] = useState('all')
  const [filter, setFilter] = useState(null)
  const [invoiceData, setInvoiceData] = useState({
    all: [],
    paid: [],
    pending: [],
    draft: [],
    overdue: []
  })
  const [scheduledInvoicesData, setScheduledInvoicesData] = useState({
    loading: true,
    data: []
  })
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false)
  const userWorkspaces = useSelector(state => state.userWorkspaces.data)

  const handleTabChange = useCallback((newVal, option) => {
    setSelectedTab(newVal)
    setSelectedTabKey(option.targetTab.toLowerCase())
  }, [])

  useEffect(() => {
    const invoiceGroup = {
      paid: [],
      pending: [],
      draft: [],
      overdue: []
    }
    let totalInvoices = [...invoices]
    if (search.trim().length) {
      totalInvoices = totalInvoices.filter(item =>
        item.client.clientName
          ? item.client.clientName
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.client.clientEmail.toLowerCase().includes(search.toLowerCase())
          : false
      )
    }
    if (filter) {
      const newDate = new Date().getTime() - filter * 24 * 3600 * 1000
      totalInvoices = totalInvoices.filter(
        item =>
          new Date(item.createdAt).getTime() >
          new Date(newDate).setHours(0, 0, 0, 0)
      )
    }

    for (let invoice of totalInvoices) {
      if (invoiceGroup[invoice.status]) {
        invoiceGroup[invoice.status].push(invoice)
      }
    }

    setInvoiceData({ all: totalInvoices, ...invoiceGroup })
  }, [invoices, search, filter])

  useEffect(() => {
    const mappedData = userWorkspaces
      .filter(item => item.automaticInvoice)
      .map(item => {
        const client = item.assignedClientTeam.find(
          item => item.role === userRoles.USER_CLIENT
        )

        return {
          project: {
            _id: item._id,
            name: item.name
          },
          client: client?.user || {},
          invoiceCycle: item.invoiceCycle
        }
      })

    setScheduledInvoicesData({ loading: false, data: mappedData })
  }, [userWorkspaces])

  const handleCreateInvoice = () => {
    handleToggleInvoiceModal('stripe')
  }

  const openInvoicePreview = invoice => {
    history.push(`${url.split('/all')[0]}/i/${invoice._id}`)
  }

  const handleEditInvoice = invoice => {
    history.push(`${url.split('/all')[0]}/i/${invoice._id}/edit`)
  }

  return (
    <div className="flex flex-col h-full">
      <div>
        <div className="flex items-center justify-end mb-6 mt-3">
          {/* <SectionHeading>Invoices</SectionHeading> */}
          <div className="flex space-x-4">
            <PopupMenu
              targetComp={
                <Badge
                  color="primary"
                  variant="dot"
                  badgeContent={filter ? 1 : 0}
                  // classes={{ badge: classes.badge }}
                >
                  <Button
                    variant="outlined"
                    startIcon={
                      <FilterIcon className="w-4 h-4 dark:text-dark-light" />
                    }
                  >
                    <span>{t('FILTER')}</span>
                    <span>{filter ? `: Last ${filter} days` : null}</span>
                  </Button>
                </Badge>
              }
              menuItems={[
                {
                  label: t('7_DAYS'),
                  method: () => setFilter(7)
                },
                {
                  label: t('14_DAYS'),
                  method: () => setFilter(14)
                },
                {
                  label: t('28_DAYS'),
                  method: () => setFilter(28)
                },
                {
                  label: t('CLEAR'),
                  method: () => setFilter(null)
                }
              ]}
            />

            {access?.billings.create && (
              <>
                <Button onClick={handleCreateInvoice} startIcon={<Add />}>
                  {t('INVOICE')}
                </Button>
                <button onClick={() => setInvoiceModalOpen(true)}>
                  <SettingIcon />
                </button>
              </>
            )}
          </div>
        </div>

        <div className="mb-6">
          <CustomTabs
            tabsData={[
              {
                label: t('ALL'),
                targetTab: 'All',
                value: 0,
                number: invoiceData['all'].length
              },
              {
                label: t('DRAFT'),
                targetTab: 'Draft',
                value: 1,
                number: invoiceData['draft'].length
              },
              {
                label: t('PENDING'),
                targetTab: 'Pending',
                value: 2,
                number: invoiceData['pending'].length
              },
              {
                label: t('PAID'),
                targetTab: 'Paid',
                value: 3,
                number: invoiceData['paid'].length
              },
              {
                label: t('OVERDUE'),
                targetTab: 'Overdue',
                value: 4,
                number: invoiceData['overdue'].length
              },
              {
                label: t('SCHEDULED_INVOICES'),
                targetTab: 'Scheduled',
                value: 5,
                number: scheduledInvoicesData.data.length
              }
            ]}
            value={selectedTab}
            onChange={handleTabChange}
          />
        </div>
      </div>

      {selectedTabKey === 'scheduled' ? (
        <ScheduledInvoicesTable
          loading={scheduledInvoicesData.loading}
          invoicesData={scheduledInvoicesData.data}
        />
      ) : (
        <InvoiceTable
          invoices={invoiceData[selectedTabKey]}
          openInvoicePreview={openInvoicePreview}
          handleEditInvoice={handleEditInvoice}
          openMarkAsPaidAlert={openMarkAsPaidAlert}
          openDeleteAlert={openDeleteAlert}
          clientCol={true}
        />
      )}

      <InvoiceSettingsModal
        open={invoiceModalOpen}
        onClose={() => setInvoiceModalOpen(false)}
      />
    </div>
  )
}
