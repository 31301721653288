import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { Divider, useTheme } from '@material-ui/core'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import {
  ColoredAvatarGroup,
  LoadingButton,
  ToggleSwitch
} from 'global/globalComponents'
import userRoles from 'utils/userRoles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { assignWorkspace, updateWorkspaceType } from 'thunks/workspace/actions'
import { projectCategories } from 'constants/constantKeys'
import AddNewTeammatePanel from 'components/Teammates/AddNewTeammatePanel'
import AddNewClient from 'components/Clients/AddNewClient'

const ShareTabContents = React.forwardRef(
  (
    {
      innerProjectPermission,
      userAccountPermission,
      switchToTeammatesTab,
      switchToClientsTab
    },
    ref
  ) => {
    const isClient = innerProjectPermission.role === userRoles.USER_CLIENT
    const meData = useSelector(state => state.me.data)
    const [selectedTeammates, setSelectedTeammates] = useState([])
    const currentWorkspace = useSelector(
      state => state.userWorkspaces.currentWorkspace
    )
    const [teammatePanel, setTeammatePanel] = useState({
      open: false,
      data: {}
    })
    const [addClientPanel, setAddClientPanel] = useState({
      open: false,
      data: {}
    })

    useImperativeHandle(ref, () => ({
      openTeammatePanel: (data = {}) => {
        setTeammatePanel({ open: true, data })
      },
      openAddClientPanel: (data = {}) => {
        setAddClientPanel({ open: true, data })
      }
    }))

    const inviteTeammate = (inputValue = '') => {
      setTeammatePanel({
        open: true,
        data: {
          defaultValues: {
            email: inputValue,
            assignedWorkspace: [
              { name: currentWorkspace.name, _id: currentWorkspace._id }
            ]
          }
        }
      })
    }

    const inviteClient = (inputValue = '') => {
      setAddClientPanel({
        open: true,
        data: {
          email: inputValue,
          assignedWorkspace: [
            { name: currentWorkspace.name, _id: currentWorkspace._id }
          ]
        }
      })
    }

    return (
      <div className="w-full mt-6 mb-3">
        {!isClient ? (
          <AddTeammatesForAgency
            userAccountPermission={userAccountPermission}
            currentWorkspace={currentWorkspace}
            inviteTeammate={inviteTeammate}
            meData={meData}
            switchToTeammatesTab={switchToTeammatesTab}
            switchToClientsTab={switchToClientsTab}
            selectedTeammates={selectedTeammates}
            setSelectedTeammates={setSelectedTeammates}
            inviteClient={inviteClient}
          />
        ) : (
          <AddTeammatesForClient
            userAccountPermission={userAccountPermission}
            currentWorkspace={currentWorkspace}
            inviteTeammate={inviteTeammate}
            meData={meData}
            switchToClientsTab={switchToClientsTab}
            selectedTeammates={selectedTeammates}
            setSelectedTeammates={setSelectedTeammates}
          />
        )}

        <AddNewTeammatePanel
          open={teammatePanel.open}
          metadata={{
            defaultValues: teammatePanel.data.defaultValues,
            styles: {
              root: { zIndex: '1999 !important' },
              drawerPaper: {
                marginTop: 0,
                maxHeight: '100vh',
                borderTop: '1px solid #efefef'
              }
            }
          }}
          onClose={() => setTeammatePanel(prev => ({ ...prev, open: false }))}
        />

        <AddNewClient
          open={addClientPanel.open}
          onClose={() => setAddClientPanel(prev => ({ ...prev, open: false }))}
          data={addClientPanel.data}
          styles={{
            root: { zIndex: '1999 !important' },
            drawerPaper: {
              marginTop: 0,
              maxHeight: '100vh',
              borderTop: '1px solid #efefef'
            }
          }}
        />
      </div>
    )
  }
)

const AddTeammatesForAgency = ({
  userAccountPermission,
  currentWorkspace,
  inviteTeammate,
  inviteClient,
  meData,
  switchToTeammatesTab,
  switchToClientsTab,
  selectedTeammates,
  setSelectedTeammates
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const muiTheme = useTheme()
  const [teammateOptions, setTeammateOptions] = useState([])
  const [addTeammateLoading, setAddTeammateLoading] = useState(false)
  const userTeammates = useSelector(state => state.userTeammates.data)
  const agencyTeammates = useMemo(() => {
    return currentWorkspace.assignedAgencyTeam.filter(
      mate => mate.role !== userRoles.USER_AGENCY //filtering out super admin
    )
  }, [currentWorkspace.assignedAgencyTeam])

  useEffect(() => {
    const addedMates = agencyTeammates.map(i => i.user?._id)

    const teammatesArray = userTeammates.filter(
      mate => !addedMates.includes(mate._id)
    )

    setTeammateOptions(teammatesArray)
  }, [userTeammates, agencyTeammates, meData.role])

  const handleAddTeammate = () => {
    const selectedTeammate = selectedTeammates.map(i => i._id)

    const updatedData = {
      workspace: currentWorkspace._id,
      assignToAgencyTeammate: selectedTeammate,
      assignToClientTeammate: []
    }

    setAddTeammateLoading(true)
    dispatch(
      assignWorkspace(updatedData, (res, err) => {
        setAddTeammateLoading(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('TEAM_MEMBER_ADDED_SUCCESSFULLY')))
          setSelectedTeammates([])
        }
      })
    )
  }

  return (
    <>
      <div className="font-medium text-sm mb-2 dark:text-dark-light">
        {t('ADD_TEAM_MEMBER_N')}
      </div>
      <div className="flex justify-between gap-2">
        <Select
          placeholder={t('ADD_TEAM_MEMBER_N')}
          isSearchable={true}
          onChange={e => setSelectedTeammates(e)}
          value={selectedTeammates}
          isMulti
          options={teammateOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          styles={reactSelectCustomStyles(muiTheme)}
          className="flex-grow"
          maxMenuHeight={150}
          noOptionsMessage={({ inputValue }) => {
            return (
              <>
                {userAccountPermission?.teammate?.create ? (
                  <span
                    className="text-md text-blue-700 dark:text-blue-400 text-left cursor-pointer flex items-center"
                    onClick={() => inviteTeammate(inputValue)}
                  >
                    <AddIcon fontSize="small" className="mr-2" />
                    {t('INVITE')} {inputValue && `'${inputValue}'`}
                  </span>
                ) : (
                  t('NO_DATA_FOUND')
                )}
              </>
            )
          }}
          isDisabled={addTeammateLoading}
        />

        <LoadingButton
          onClick={handleAddTeammate}
          loading={addTeammateLoading}
          size="small"
          disabled={!selectedTeammates.length}
        >
          {t('ADD')}
        </LoadingButton>
      </div>
      <TotalMembers
        members={agencyTeammates}
        handleViewMembersList={switchToTeammatesTab}
        noMembersMessage={t('NO_TEAM_MEMBERS_IN_PROJECT')}
      />

      <Divider />
      <AddClientContents
        isExternal={currentWorkspace.type === projectCategories.EXTERNAL}
        assignedClientTeam={currentWorkspace.assignedClientTeam}
        projectName={currentWorkspace.name}
        projectId={currentWorkspace._id}
        switchToClientsTab={switchToClientsTab}
        inviteClient={inviteClient}
      />
    </>
  )
}

const AddTeammatesForClient = ({
  userAccountPermission,
  currentWorkspace,
  inviteTeammate,
  meData,
  switchToClientsTab,
  selectedTeammates,
  setSelectedTeammates
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const muiTheme = useTheme()
  const [teammateOptions, setTeammateOptions] = useState([])
  const [addTeammateLoading, setAddTeammateLoading] = useState(false)
  const userTeammates = useSelector(state => state.userTeammates.data)
  const clientTeammates = useMemo(() => {
    return currentWorkspace.assignedClientTeam.filter(
      mate => mate.role !== userRoles.USER_CLIENT //filtering out client owner
    )
  }, [currentWorkspace.assignedClientTeam])

  useEffect(() => {
    const addedMates = clientTeammates.map(i => i.user?._id)

    const teammatesArray = userTeammates.filter(
      mate => !addedMates.includes(mate._id)
    )

    setTeammateOptions(teammatesArray)
  }, [userTeammates, clientTeammates, meData.role])

  const handleAddTeammate = () => {
    const selectedTeammate = selectedTeammates.map(i => i._id)

    const updatedData = {
      workspace: currentWorkspace._id,
      assignToAgencyTeammate: [],
      assignToClientTeammate: selectedTeammate
    }

    setAddTeammateLoading(true)
    dispatch(
      assignWorkspace(updatedData, (res, err) => {
        setAddTeammateLoading(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('TEAM_MEMBER_ADDED_SUCCESSFULLY')))
          setSelectedTeammates([])
        }
      })
    )
  }

  return (
    <>
      <div className="font-medium text-sm mb-2 dark:text-dark-light">
        {t('ADD_TEAM_MEMBER_N')}
      </div>
      <div className="flex justify-between gap-2">
        <Select
          placeholder={t('ADD_TEAM_MEMBER_N')}
          isSearchable={true}
          onChange={e => setSelectedTeammates(e)}
          value={selectedTeammates}
          isMulti
          options={teammateOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          styles={reactSelectCustomStyles(muiTheme)}
          className="flex-grow"
          maxMenuHeight={150}
          noOptionsMessage={({ inputValue }) => {
            return (
              <>
                {userAccountPermission?.teammate?.create ? (
                  <span
                    className="text-md text-blue-700 dark:text-blue-400 text-left cursor-pointer flex items-center"
                    onClick={() => inviteTeammate(inputValue)}
                  >
                    <AddIcon fontSize="small" className="mr-2" />
                    {t('INVITE')} {inputValue && `'${inputValue}'`}
                  </span>
                ) : (
                  t('NO_DATA_FOUND')
                )}
              </>
            )
          }}
          isDisabled={addTeammateLoading}
        />

        <LoadingButton
          onClick={handleAddTeammate}
          loading={addTeammateLoading}
          size="small"
          disabled={!selectedTeammates.length}
        >
          {t('ADD')}
        </LoadingButton>
      </div>
      <TotalMembers
        members={clientTeammates}
        handleViewMembersList={switchToClientsTab}
        noMembersMessage={t('NO_TEAM_MEMBERS_IN_PROJECT')}
      />
    </>
  )
}

const AddClientContents = ({
  isExternal,
  assignedClientTeam = [],
  // projectName,
  projectId,
  switchToClientsTab,
  inviteClient
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const muiTheme = useTheme()
  const clients = useSelector(state => state.userClients)
  const [toggleSwitch, setToggleSwitch] = useState({
    checked: false,
    disable: false,
    load: false
  })
  const [clientOptions, setClientOptions] = useState([])
  const [selectedClient, setSelectedClient] = useState({})
  const [loading, setLoading] = useState(false)
  const clientOwner = assignedClientTeam.find(
    mate => mate.role === userRoles.USER_CLIENT
  )

  useEffect(() => {
    setClientOptions(clients.data)
  }, [clients.data, assignedClientTeam])

  const handleSwitch = () => {
    setToggleSwitch(prev => ({
      ...prev,
      load: true
    }))

    dispatch(
      updateWorkspaceType(
        projectId,
        isExternal ? projectCategories.INTERNAL : projectCategories.EXTERNAL,
        (res, err) => {
          setToggleSwitch(prev => ({
            ...prev,
            load: false
          }))
          if (err) {
          } else {
            setToggleSwitch(prev => ({
              ...prev,
              checked: !toggleSwitch.checked,
              disable: false
            }))
          }
        }
      )
    )
  }

  const handleAddClient = () => {
    setLoading(true)

    dispatch(
      assignWorkspace(
        {
          workspace: projectId,
          assignToAgencyTeammate: [],
          assignToClientTeammate: [selectedClient._id]
        },
        (res, err) => {
          setLoading(false)
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            dispatch(fireSuccessToaster(t('CLIENT_INVITED_SUCCESSFULLY')))
          }
        }
      )
    )
  }

  return (
    <>
      <div className="mt-6 font-medium text-sm dark:text-dark-light">
        {t('ADD_CLIENTS')}
      </div>
      <div className="flex items-center justify-between mb-3">
        <p className="text-xs text-gray-700 dark:text-dark-light">
          {!isExternal
            ? t('MAKE_THIS_PROJECT_EXTERNAL_TO_ADD_CLIENTS')
            : t('THIS_IS_AN_EXTERNAL_PROJECT')}
        </p>

        <div className="flex items-center gap-1 text-gray-700 dark:text-gray-400">
          <span className="text-xs font-medium">{t('NO')}</span>
          <ToggleSwitch
            checked={isExternal}
            onChange={handleSwitch}
            disableRipple={true}
            loading={toggleSwitch.load}
          />
          <span className="text-sm font-medium">{t('YES')}</span>
        </div>
      </div>

      {isExternal && (
        <>
          {!clientOwner && (
            <div className="flex justify-between gap-2">
              <Select
                menuPlacement="top"
                placeholder={t('ADD_CLIENT')}
                onChange={setSelectedClient}
                value={selectedClient}
                options={clientOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option._id}
                styles={reactSelectCustomStyles(muiTheme)}
                className="flex-grow"
                maxMenuHeight={150}
                noOptionsMessage={({ inputValue }) => {
                  return (
                    <span
                      className="text-md text-blue-700 dark:text-blue-400 text-left cursor-pointer flex items-center"
                      onClick={() => inviteClient(inputValue)}
                    >
                      <AddIcon fontSize="small" className="mr-2" />
                      {t('INVITE')} {inputValue && `'${inputValue}'`}
                    </span>
                  )
                }}
                isDisabled={loading}
              />
              <LoadingButton
                onClick={handleAddClient}
                loading={loading}
                size="small"
                disabled={!selectedClient._id}
              >
                {t('ADD')}
              </LoadingButton>
            </div>
          )}
          <TotalMembers
            members={assignedClientTeam}
            handleViewMembersList={switchToClientsTab}
            noMembersMessage={t('NO_CLIENT_MEMBERS_IN_PROJECT')}
          />
        </>
      )}
    </>
  )
}

const TotalMembers = ({
  members = [],
  handleViewMembersList,
  noMembersMessage
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex justify-between items-center my-4">
      {members.length > 0 ? (
        <>
          <div className="relative cursor-pointer flex justify-start items-center flex-shrink-0 gap-0">
            <ColoredAvatarGroup
              users={members.map(item => item.user)}
              max={4}
              style={{ zIndex: 0 }}
              tooltip={true}
            />
            <p className="pl-2 text-sm dark:text-dark-light">
              {members[0]?.user?.name}{' '}
              {members?.length - 1 > 0 && `and ${members?.length - 1} others`}
            </p>
          </div>
          <p
            className="font-medium text-green-500 text-sm cursor-pointer"
            onClick={handleViewMembersList}
          >
            {t('VIEW_ALL')}
          </p>
        </>
      ) : (
        <p className="text-xs text-gray-700 dark:text-dark-light">
          {noMembersMessage}
        </p>
      )}
    </div>
  )
}

export default ShareTabContents
