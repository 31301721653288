import { TextField, Button, useTheme } from '@material-ui/core'
import { FormLabel, LoadingButton } from 'global/globalComponents'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchEmbed } from 'thunks/embed/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { createProjectTemplate } from 'thunks/projectTemplate/action'
import { fetchTaskTableColumns, getSectionTasks } from 'thunks/task/actions'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useTranslation } from 'react-i18next'

const CreateTemplateFormProject = ({ open, handleClose, workspace }) => {
  const { t } = useTranslation()

  return (
    <CustomModal open={open} handleClose={handleClose} size="extraSmall">
      <CustomModalHeader
        heading={t('CREATE_TEMPLATE')}
        handleClose={handleClose}
      />
      <CreateTemplateFromProjectContent
        workspace={workspace}
        handleClose={handleClose}
      />
    </CustomModal>
  )
}

const CreateTemplateFromProjectContent = ({ workspace, handleClose }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    type: 'private',
    content: { private: true, section: [] },
    fetchSubTask: true
  })
  const categoriesOptions = [
    { label: t('DESIGN'), value: 'design' },
    { label: t('MARKETING'), value: 'marketing' },
    { label: t('SOFTWARE_DEVELOPMENT'), value: 'software development' },
    { label: t('OTHER'), value: 'other' }
  ]
  const [loading, setLoading] = useState(false)
  const [taskColumn, setTaskColumn] = useState([])

  const fetchSectionTasks = (cb, section) => {
    const data = {
      workspace: workspace._id,
      workspaceBoard: workspace.defaultBoard?._id,
      myApprovals: false,
      search: null,
      section: section
    }

    // data, query, sectionId, groupBy, callback
    dispatch(
      getSectionTasks(
        {
          data,
          query: {
            view: 'board',
            type: 'section',
            limit: 500,
            page: 0
          },
          sectionId: section,
          groupBy: 'status'
        },
        cb
      )
    )
  }

  useEffect(() => {
    if (!workspace._id) return
    dispatch(fetchEmbed({ id: workspace._id }, fetchEmbedCallback))
  }, [workspace._id])

  const fetchEmbedCallback = (boardRes, boardErr) => {
    if (boardErr) {
      handleClose()
      return dispatch(fireErrorToaster(boardRes))
    }
    const defaultBoard = boardRes.find(
      board => board.type === 'board' && board.pinView === true
    )
    const sectionData = defaultBoard?.boardSection?.map(section => {
      const sectionId = parseInt(Math.random() * 10000000000)
      const data = {
        id: sectionId,
        label: section.label,
        order: section.order,
        color: section.color,
        tasks: []
      }
      fetchSectionTasks((res, err) => {
        if (!err) {
          const newTaskData = res.data?.map(item => ({
            title: item.title,
            description: item.description,
            priority: item.priority ?? 5,
            dynamicField: item.dynamicField,
            orderIndex: item.orderIndex,
            noneOrderIndex: item.noneOrderIndex,
            dueDateOrderIndex: item.dueDateOrderIndex,
            assigneeOrderIndex: item.assigneeOrderIndex,
            imageUrl: item.imageUrl,
            sectionId: sectionId,
            taskId: item._id
          }))
          data.tasks = newTaskData
        }
      }, section._id)
      return data
    })
    setFormData(prev => ({
      ...prev,
      content: { private: true, section: sectionData }
    }))
  }

  useEffect(() => {
    if (!workspace.defaultBoard?._id) return
    fetchTaskTableColumns(
      { boardId: workspace.defaultBoard._id },
      (res, err) => {
        if (!err) {
          const dynamicsColumns = res.map(item => {
            if (item.metaData) {
              return {
                _id: item._id,
                id: parseInt(Math.random() * 10000000000),
                name: item.name,
                datatype: item.datatype,
                metaData: item.metaData
              }
            } else {
              return {
                _id: item._id,
                id: parseInt(Math.random() * 10000000000),
                name: item.name,
                datatype: item.datatype
              }
            }
          })
          setTaskColumn(dynamicsColumns)
        }
      }
    )
  }, [workspace._id])

  const handleTitle = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleSelectCategory = e => {
    setFormData(prev => ({ ...prev, category: e.value }))
  }

  const handleCreateTemplate = () => {
    if (!formData.title || !formData.category)
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS'))
      )

    const sectionData = formData.content?.section?.map(item => {
      const newSectionTasks = item.tasks?.map(task => {
        const updateDynamicField = task.dynamicField?.map(col => {
          const column = taskColumn.find(el => el._id === col.taskColumnId)
          return { ...col, taskColumnId: column?.id }
        })
        return { ...task, dynamicField: updateDynamicField }
      })
      return { ...item, tasks: newSectionTasks }
    })

    const updatedTaskColumn = taskColumn?.map(item => {
      delete item._id
      return { ...item }
    })
    setLoading(true)
    createProjectTemplate(
      {
        data: {
          ...formData,
          content: {
            ...formData.content,
            section: sectionData,
            taskColumn: updatedTaskColumn
          }
        }
      },
      handleSaveCallback
    )
  }

  const handleSaveCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      dispatch(fireSuccessToaster(t('TEMPLATED_CREATED_SUCCESSFULLY')))
      handleClose()
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  return (
    <>
      <CustomModalBody>
        <div className="py-2">
          <FormLabel required>{t('TEMPLATE_NAME')}</FormLabel>
          <TextField
            onChange={handleTitle}
            name="title"
            value={formData.title}
            className="w-full"
          />
        </div>
        <div className="py-2">
          <FormLabel>{t('TEMPLATE_DESCRIPTION')}</FormLabel>
          <TextField
            onChange={handleTitle}
            name="description"
            value={formData.description}
            className="w-full"
          />
        </div>
        <div className="py-2">
          <FormLabel required>{t('TEMPLATE_CATEGORY')}</FormLabel>
          <Select
            menuPlacement="top"
            // isSearchable={true}
            options={categoriesOptions}
            onChange={handleSelectCategory}
            styles={reactSelectCustomStyles(theme)}
            maxMenuHeight="200px"
          />
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button variant="outlined" onClick={handleClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton onClick={handleCreateTemplate} loading={loading}>
          {t('CREATE_TEMPLATE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default CreateTemplateFormProject
