import React, { useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LightBulbIcon } from 'static/svg/bulb.svg'
import { ReactComponent as BugIcon } from 'static/svg/bug.svg'
import { ReactComponent as GiftIcon } from 'static/svg/gift.svg'
import { LoadingButton } from 'global/globalComponents'
import clsx from 'clsx'
import { Button, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { submitUserFeedback } from 'thunks/generlAppState/actions'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'

const FeedbackModal = ({ open, onClose }) => {
  const { t } = useTranslation()
  const [openThanksPopup, setOpenThanksPopup] = useState(false)

  return (
    <>
      <CustomModal open={open} handleClose={onClose}>
        <CustomModalHeader heading={t('FEEDBACK')} handleClose={onClose} />
        <ModalContent
          t={t}
          onClose={onClose}
          handleOpenThanksPopup={() => setOpenThanksPopup(true)}
        />
      </CustomModal>

      {openThanksPopup && (
        <ThankYouCard onClose={() => setOpenThanksPopup(false)} />
      )}
    </>
  )
}

const ModalContent = ({ t, onClose, handleOpenThanksPopup }) => {
  const dispatch = useDispatch()
  const feedbackOptions = [
    {
      type: 'new_idea',
      label: t('NEW_IDEA'),
      color: '#5a5ae9',
      icon: <LightBulbIcon />
    },
    {
      type: 'feature_request',
      label: t('FEATURE_REQUEST'),
      color: '#55d955',
      icon: <GiftIcon />
    },
    {
      type: 'bug_report',
      label: t('BUG_REPORT'),
      color: '#fbc157',
      icon: <BugIcon />
    }
  ]
  const [formData, setFormData] = useState({
    type: feedbackOptions[0].type,
    title: '',
    description: ''
  })
  const [loading, setLoading] = useState(false)

  const handleSubmitFeedback = () => {
    if (formData.title.trim() === '' || formData.description.trim() === '') {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_FILL_ALL_THE_FIELDS')))
      return
    }

    setLoading(true)
    submitUserFeedback(
      {
        body: {
          title: formData.title,
          description: formData.description,
          type: formData.type
        }
      },
      (res, err) => {
        setLoading(false)
        if (err) dispatch(fireErrorToaster(res))
        else {
          onClose()
          handleOpenThanksPopup()
        }
      }
    )
  }

  return (
    <>
      <CustomModalBody>
        <div className="mb-6">
          <div className="text-smaller mb-2 font-medium text-gray-600">
            {t('PLEASE_SELECT_FEEDBACK_TYPE')}:{' '}
          </div>
          <div className="flex items-center space-x-4">
            {feedbackOptions.map(item => (
              <div
                key={item.type}
                role="button"
                aria-label="Select feedback type"
                className={clsx(
                  'cursor-pointer flex items-center px-2 py-1 border rounded',
                  item.type === formData.type
                    ? 'text-yellow-500 border-yellow-500 bg-yellow-50'
                    : 'bg-gray-100 text-gray-700'
                )}
                onClick={() =>
                  setFormData(prev => ({ ...prev, type: item.type }))
                }
              >
                {item.icon}
                <span className="ml-2 text-sm capitalize">{item.label}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <div className="text-smaller mb-2 font-medium text-gray-600">
            {t('TITLE')}
          </div>
          <div>
            <TextField
              placeholder={t('TITLE')}
              value={formData.title}
              fullWidth
              onChange={e =>
                setFormData(prev => ({ ...prev, title: e.target.value }))
              }
            />
          </div>
        </div>

        <div className="mb-6">
          <div className="text-smaller mb-2 font-medium text-gray-600">
            {t('DESCRIPTION')}
          </div>
          <div>
            <TextField
              placeholder={t('DESCRIPTION')}
              value={formData.description}
              fullWidth
              multiline
              rows={4}
              onChange={e =>
                setFormData(prev => ({ ...prev, description: e.target.value }))
              }
            />
          </div>
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" onClick={onClose} variant="outlined">
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          loading={loading}
          onClick={handleSubmitFeedback}
        >
          {t('SUBMIT')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

const ThankYouCard = ({ onClose }) => {
  return (
    <div
      className="rounded-md bg-white flex flex-col pb-6 pt-6 border shadow-xl"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        zIndex: 99999
      }}
    >
      <button className="absolute top-4 right-4" onClick={onClose}>
        <CrossIcon />
      </button>

      <div className="relative text-center my-6">
        <h2 className="font-semibold text-lg">Thank You!</h2>
        <p className="text-smaller font-medium">
          We will send you your request related updates via email
        </p>
      </div>

      <div className="flex justify-center">
        <Button onClick={onClose}>Close</Button>
      </div>
    </div>
  )
}

export default FeedbackModal
