const toFixedFloat = number => {
  return parseFloat(number.toFixed(2))
}

export const getInvoiceSummary = ({
  invoiceItems,
  taxPercent,
  coupon,
  discountAmount
}) => {
  let totalAmount = 0,
    discount = 0,
    taxAmount = 0,
    subtotal = invoiceItems.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.rate * currentValue.quantity
    }, 0)
  subtotal = toFixedFloat(subtotal)

  if (coupon) {
    if (coupon.type === 'fixed') {
      discount = coupon.discount
    } else {
      discount = toFixedFloat((subtotal / 100) * coupon.discount)
    }
  } else if (discountAmount) {
    discount = discountAmount
  }

  if (taxPercent) {
    taxAmount = toFixedFloat(((subtotal - discount) / 100) * taxPercent)
  }

  totalAmount = toFixedFloat(subtotal - discount + taxAmount)
  if (totalAmount < 0) totalAmount = 0

  return {
    subtotal,
    totalAmount,
    taxAmount,
    discount
  }
}
