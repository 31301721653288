import React, { useEffect, useMemo, useState } from 'react'
import CommentEditor from 'components/Editors/CommentEditor/CommentEditor'
import { useDispatch, useSelector } from 'react-redux'
import userRoles, { getAgencyOwner } from 'utils/userRoles'
import { assignUserToWorkspace } from 'thunks/workspace/actions'
import { postTaskLog } from 'thunks/logs/action'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { ReactComponent as ArrowSwapIcon } from 'static/svg/arrow-swap.svg'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import TaskCollaborators from './TaskCollaborators'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import CollabDrowdown from './CollabDrowdown'
import {
  deleteTaskDetailsTeam,
  updateTaskDetailsTeam
} from 'thunks/task/actions'
import {
  deleteSubtaskCollaborator,
  updateSubTaskTeammate
} from 'thunks/subTask/actions'
import { getQuillHTML } from 'utils'

function TaskPanelFooter({
  elmFor,
  taskId,
  subtaskId,
  workspace,
  boardId,
  socketId,
  uploadTaskAttachment,
  scrollToBottom,
  checkAndGetNewFileName,
  imageUrls,
  innerProjectPermission,
  collaborators = [],
  getCollaboratorsOptions,
  updateTaskState
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const agencyTeammates = useSelector(state => state.userTeammates.data)
  const meData = useSelector(state => state.me.data)
  const logs = useSelector(state => state.logs.data)
  const [isExternal, setIsExternal] = useState(
    Boolean(meData.role >= userRoles.USER_CLIENT)
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const [collabOptions, setCollabOptions] = useState([])

  const users = useMemo(() => {
    if (!workspace._id) return []
    const agencyOwner = getAgencyOwner(meData)

    const clientUsers = workspace.assignedClientTeam.map(item => ({
      //for quill mention
      id: item.user._id,
      value: item.user.name,
      //for backend and frontend
      _id: item.user._id,
      name: item.user.name,
      profileImage: item.user.profileImage,
      role: item.role
    }))

    const agencyUsers = agencyTeammates.map(item => ({
      //for quill mention
      id: item._id,
      value: item.name,
      //for backend and frontend
      _id: item._id,
      email: item.email,
      profileImage: item.profileImage,
      role: item.role
    }))

    const otherUsers = [
      {
        id: meData._id,
        value: meData.name,
        _id: meData._id,
        email: meData.email,
        profileImage: meData.profileImage,
        role: meData.role
      }
    ]

    if (meData._id !== agencyOwner._id) {
      otherUsers.push({
        id: agencyOwner._id,
        value: agencyOwner.name,
        _id: agencyOwner._id,
        email: agencyOwner.email,
        profileImage: agencyOwner.profileImage,
        role: agencyOwner.role
      })
    }

    return [...clientUsers, ...agencyUsers, ...otherUsers]
  }, [workspace, agencyTeammates, meData])

  useEffect(() => {
    setCollabOptions(getCollaboratorsOptions(collaborators, workspace))
  }, [collaborators, workspace])

  const callbackHandler = (res, err) => {
    if (err) dispatch(fireErrorToaster(res))
    else {
      if (res?._id) {
        updateTaskState(res)
      }
    }
  }

  const handleSubmitComment = ({ comment, attachments, callback }) => {
    const mentions = comment.ops
      .filter(item => item.insert.mention)
      .map(item => item.insert.mention)

    const commentData = {
      description: comment,
      descriptionString: getQuillHTML(comment, 'html'),
      activityType: 'comment',
      isExternal: isExternal,
      workSpace: workspace._id,
      workspaceBoard: boardId,
      createdAt: new Date(),
      updatedAt: new Date(),
      user: meData._id,
      pComment: logs[logs.length - 1]?._id ?? 0,
      category: elmFor === 'task' ? 'task' : 'subtask',
      attachment: attachments,
      task: taskId,
      socketId
    }

    if (elmFor === 'subtask') {
      commentData.subTask = subtaskId
    }

    dispatch(
      postTaskLog({ data: commentData }, (res, err) => {
        if (callback) callback(res, err)

        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          setTimeout(() => {
            scrollToBottom()
          }, 500)
        }
      })
    )

    mentions.forEach(user =>
      dispatch(
        assignUserToWorkspace({
          workspace: workspace._id,
          assignToAgencyTeammate:
            user.role < userRoles.USER_CLIENT ? [user._id] : [],
          assignToClientTeammate:
            user.role < userRoles.USER_CLIENT ? [] : [user._id]
        })
      )
    )

    // filtering out mentioned users who are not in collaborators list
    const notInCollaborators = mentions
      .filter(
        mentionedUser =>
          !collaborators.find(user => user._id === mentionedUser._id)
      )
      .map(item => item._id)

    // if the user (who is commenting) does not exist in the collaborators list
    // add him/her to the list
    const idx = collaborators.findIndex(user => user._id === meData._id)
    if (idx === -1) notInCollaborators.push(meData._id)

    if (!notInCollaborators.length) return

    if (elmFor === 'task') {
      dispatch(
        updateTaskDetailsTeam(
          {
            taskId,
            data: {
              collaborators: notInCollaborators
            }
          },
          callbackHandler
        )
      )
    } else {
      dispatch(
        updateSubTaskTeammate(
          {
            id: subtaskId,
            data: {
              collaborators: notInCollaborators
            }
          },
          callbackHandler
        )
      )
    }
  }

  const fileUploadHandler = (file, callback) => {
    const fileName = checkAndGetNewFileName(file, imageUrls)
    const newFile = new File([file], fileName, {
      type: file.type,
      lastModified: file.lastModified
    })

    uploadTaskAttachment({
      taskId,
      subtaskId,
      workspaceId: workspace._id,
      file: newFile,
      cb: (res, err) => {
        callback(res, err)

        if (err) {
          dispatch(fireErrorToaster(res))
        }
      }
    })
  }

  const toggleIsExternal = () => {
    setIsExternal(prev => !prev)
  }

  const handleRemoveCollaborator = ({ userId }) => {
    if (elmFor === 'task') {
      dispatch(
        deleteTaskDetailsTeam(
          {
            taskId,
            data: { collaborators: userId }
          },
          callbackHandler
        )
      )
    } else {
      dispatch(
        deleteSubtaskCollaborator(
          { id: subtaskId, data: { collaborators: userId } },
          callbackHandler
        )
      )
    }
  }

  const togglePopper = e => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  const handleTriggerPopper = e => {
    if (workspace.isComplete) {
      dispatch(
        fireErrorToaster(
          t(
            'ERROR_WORKSPACE_IS_ALREADY_COMPLETED_AND_CANNOT_ADD_TEAM_MEMBERS',
            {
              data: workspace.name
            }
          )
        )
      )
    } else {
      innerProjectPermission.update
        ? togglePopper(e)
        : dispatch(fireErrorToaster(t('YOU_ARE_NOT_AUTHORIZED_USER')))
    }
  }

  const handleAddCollaborator = selectedUser => {
    togglePopper()

    if (elmFor === 'task') {
      dispatch(
        updateTaskDetailsTeam(
          {
            taskId,
            data: {
              collaborators: [selectedUser.userId]
            }
          },
          callbackHandler
        )
      )
    } else {
      dispatch(
        updateSubTaskTeammate(
          { id: subtaskId, data: { collaborators: [selectedUser.userId] } },
          callbackHandler
        )
      )
    }
  }

  return (
    <>
      <div className="px-6 sm:px-8">
        <CommentEditor
          key={users.length}
          users={users}
          options={{ file: true }}
          onSubmit={handleSubmitComment}
          fileUploadHandler={fileUploadHandler}
          secondaryBtn={
            <>
              {workspace.type === 'External' &&
                meData.role < userRoles.USER_CLIENT && (
                  <button onClick={toggleIsExternal}>
                    <ArrowSwapIcon
                      className={clsx(
                        'transition-transform duration-300',
                        !isExternal && 'rotate-180'
                      )}
                    />
                    <span className="ml-1">
                      {isExternal ? 'External' : 'Internal'}
                    </span>
                  </button>
                )}
            </>
          }
        />

        <div className="py-4 flex items-center">
          <div className="text-xs mr-4 dark:text-dark-light">
            {t('COLLABORATORS')}
          </div>
          <div className="flex items-center">
            <TaskCollaborators
              taskId={elmFor === 'task' ? taskId : subtaskId}
              innerProjectPermission={innerProjectPermission}
              elmFor={elmFor}
              removeCollaborator={handleRemoveCollaborator}
              collaborators={collaborators}
            />

            <button
              onClick={handleTriggerPopper}
              className="flex-shrink-0 ml-2 bg-white dark:bg-dark-main3 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
            >
              <span className="sr-only">{t('ADD_TEAM_MEMBER')}</span>
              <Add style={{ fontSize: '16px' }} aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <CollabDrowdown
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={togglePopper}
        list={collabOptions}
        handleListItemClick={team =>
          handleAddCollaborator({
            userId: team.user._id,
            userRole: team.role
          })
        }
        getUser={listItem => ({ ...listItem.user, role: listItem.role })}
        style={{ zIndex: 2000 }}
      />
    </>
  )
}

export default React.memo(TaskPanelFooter)
