import axios from 'axios'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { catchAsync, catchAsyncDispatch } from 'utils'
import { USER } from './reducers'
import { ME } from '../me/reducers'
// import { GENERAL_APP_STATE } from 'thunks/generlAppState/reducers'
import { updateStateTheme } from 'thunks/generlAppState/actions'
// import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { getErrorMessages } from 'utils/errorMessages'
import { GENERAL_APP_STATE } from 'thunks/generlAppState/reducers'
const errorMessages = getErrorMessages()

/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchUser = callback => {
  return async dispatch => {
    dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/user'
      })
      dispatch({ type: USER.FETCHED, payload: res.data })
      if (res.data.isDarkMode) {
        dispatch(updateStateTheme({ mode: 'dark' })) // in generalAppState.theme
      }
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: USER.ERROR, payload: err?.response?.data?.message })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch user by id
 * @param {string} data.userId userId
 * @param {Object} data.params
 * @param {Function} callback callback function
 */

export const fetchUserById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/user/${data.userId}`,
    params: data.params
  })

  if (callback) callback(res.data)
})

/**
 * update a user
 * @param {Object} data profile image ,name
 * @param {Function} callback callback function
 */

export const updateUser = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      user: {
        data: { _id: userId }
      }
    } = getState()

    dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${userId}`,
        data
      })

      dispatch({ type: USER.UPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: USER.ERROR, payload: err?.response?.data?.message })
      if (callback)
        callback(err?.response?.data?.message ?? 'Unsuccessful', true)
    }
  }
}

export const updateUserPath = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/user/${data.userId}`,
    data: data.data
  })
  if (callback) callback(res.data)
})

/**
 * update a user
 * @param {String} username user name
 * @param {Function} callback callback function
 */

export const updateUsername = (username, callback) => {
  return async dispatch => {
    dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/username`,
        params: {
          username
        }
      })
      // dispatch(fireSuccessToaster('Username Updated'))
      dispatch({ type: USER.USERNAMEUPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({
        type: USER.ERROR,
        payload: err?.response?.data?.message ?? 'Unsuccessful'
      })
      // dispatch(fireErrorToaster(err?.response?.data?.message ?? 'Unsuccessful'))
      if (callback)
        callback(err?.response?.data?.message ?? 'Unsuccessful', true)
    }
  }
}

/**
 * update a user agency details
 * @param {String} id user id
 * @param {Object} data  agency logo,address,agency name
 * @param {Function} callback callback function
 */

export const updateUserProfile = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/agencyprofile/agency/${data.id}`,
      data: data.data
    })

    dispatch({ type: USER.PROFILE_UPDATED, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * update a user agency details
 * @param {Object} data  old password,new password
 * @param {Function} callback callback function
 */

export const changePassword = (data, callback) => {
  return async dispatch => {
    // dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: `/user/changepassword`,
        data
      })
      dispatch(fireSuccessToaster(t('PASSWORD_CHANGED_SUCCESSFULLY')))
      // dispatch({ type: USER.USERNAMEUPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({
      //   type: USER.ERROR,
      //   payload: err?.response?.data?.message ?? 'Some Error Occured'
      // })
      dispatch(fireErrorToaster(err?.response?.data?.message ?? 'Unsuccessful'))
      if (callback)
        callback(err?.response?.data?.message ?? 'Unsuccessful', true)
    }
  }
}

/**
 * update a user agency details
 * @param {String} id user id
 * @param {Function} callback callback function
 */

export const deactivateUser = (id, callback) => {
  return async dispatch => {
    // dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `user/deactivate/${id}`
      })
      // dispatch(fireSuccessToaster('Profile Updated'))
      // dispatch({ type: USER.UPDATED, payload: res.data })
      if (callback) callback(res?.data, false)
    } catch (err) {
      // dispatch({ type: USER.ERROR, payload: err?.response?.data?.message })
      dispatch(fireErrorToaster(err?.response?.data?.message ?? 'Unsuccessful'))
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

// export const getAgencyInfoByDomain = () => {
//   return axios({
//     method: 'GET',
//     url: '/user/companyinfo'
//   })
// }

export const getAgencyInfoByDomain = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/companyinfo',
        isPublic: true
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: ME.UPDATE,
        payload: {
          isWhiteLabelActive: res.data.isWhiteLabelActive,
          theme: res.data.theme || {}
        }
      })
      dispatch({ type: GENERAL_APP_STATE.SET_COMPANY_INFO, payload: res.data })
      localStorage.setItem(
        'customize',
        JSON.stringify({
          isWhiteLabelActive: res.data.isWhiteLabelActive,
          theme: res.data.theme || { primary: '#1600E5', heading: '#17191C' },
          brand: res.data.brand,
          pageCustomization: res.data.pageCustomization,
          language: res.data.language ?? 'en'
        })
      )
    } catch (err) {
      dispatch({ type: GENERAL_APP_STATE.SET_COMPANY_INFO, payload: {} })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * get new api ket
 * @param {Function} callback callback function
 */
export const getNewApiKey = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/user/apikey`
      })
      dispatch({ type: ME.UPDATE, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * download all files store on client venue
 * @param {Function} callback callback function
 */

function downloader(data, name, time) {
  let blob = new Blob([data], { type: 'application/octet-stream' })

  let url = window.URL.createObjectURL(blob)
  downloadURI(url, name)

  window.URL.revokeObjectURL(url)
}

function downloadURI(uri, name) {
  let link = document.createElement('a')
  link.download = name
  link.href = uri
  link.click()
}

// export const downloadBackupFiles = async (handlePercentage, callback) => {
//   try {
//     let getPercentageComplete = function (val) {
//       handlePercentage(val)
//     }
//     const res = await axios({
//       method: 'GET',
//       url: `/user/backup/download`,
//       responseType: 'blob',
//       onDownloadProgress: progressEvent => {
//         let percentCompleted = Math.floor(
//           (progressEvent.loaded / progressEvent.total) * 100
//         )
//         getPercentageComplete(percentCompleted)
//       }
//     })

//     downloader(res.data, 'backup.zip')
//     if (callback) callback('success', false)
//   } catch (err) {
//     if (callback)
//       callback(
//         err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//         true
//       )
//   }
// }

export const exportAccountData = catchAsync(async callback => {
  await axios({
    method: 'GET',
    url: `/user/backup/export`
  })

  if (callback) callback('success', false)
})

/**
 * Get new release related info
 * @param {Function} callback callback function
 */
export const fetchNewReleaseInfo = catchAsync(async callback => {
  const res = await axios({
    method: 'GET',
    url: '/user/cvconfig',
    isPublic: true
  })
  if (callback) callback(res.data)
})
