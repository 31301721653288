import { Button } from '@material-ui/core'
import { t } from 'i18next'
import { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(err) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) return <FallbackUI />
    return this.props.children
  }
}

const FallbackUI = () => {
  const handleReload = () => {
    window.location.reload(true) //hard refresh
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center text-center">
      <div style={{ maxWidth: 500 }}>
        <h2 className="text-sm dark:text-dark-light">{t('ERROR_OCCURED')}</h2>
        <p className="text-xs text-gray-500 dark:text-gray-400 mb-3">
          {t('ERROR_BOUNDARY_MSG_1')} <br /> {t('ERROR_BOUNDARY_MSG_2')}
        </p>
        <Button size="small" variant="outlined" onClick={handleReload}>
          {t('RELOAD')}
        </Button>
      </div>
    </div>
  )
}

export default ErrorBoundary
