import { ReactComponent as AtIcon } from 'static/svg/at.svg'
import { ReactComponent as SelectIcon } from 'static/svg/caret-circle-down.svg'
import { ReactComponent as CheckboxIcon } from 'static/svg/check-square.svg'
import { ReactComponent as HashTagIcon } from 'static/svg/hashtag.svg'
import { ReactComponent as PhoneAlt } from 'static/svg/phone-alt.svg'
import { ReactComponent as TextIcon } from 'static/svg/text-left.svg'
import { ReactComponent as UsersIcon } from 'static/svg/user-friends.svg'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { ReactComponent as PaperclipIcon } from 'static/svg/paperclip.svg'
import { ReactComponent as LinkIcon } from 'static/svg/link.svg'
// import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
// import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { t } from 'i18next'

export const getOptionsData = () => [
  { label: t('TEXT'), value: 'text', icon: <TextIcon /> },
  { label: t('MULTILINE'), value: 'multiline', icon: <TextIcon /> },
  { label: t('URL'), value: 'url', icon: <LinkIcon /> },
  { label: t('PERSON'), value: 'person', icon: <UsersIcon /> },
  { label: t('SINGLE_SELECT'), value: 'singleSelect', icon: <SelectIcon /> },
  { label: t('MULTI_SELECT'), value: 'multiSelect', icon: <SelectIcon /> },
  { label: t('DATE'), value: 'date', icon: <CalenderIcon /> },
  { label: t('EMAIL_ADDRESS'), value: 'email', icon: <AtIcon /> },
  { label: t('PHONE'), value: 'phone', icon: <PhoneAlt /> },
  { label: t('NUMBERS'), value: 'numbers', icon: <HashTagIcon /> },
  { label: t('CHECKBOX'), value: 'checkbox', icon: <CheckboxIcon /> },
  { label: t('ATTACHMENT'), value: 'attachment', icon: <PaperclipIcon /> }
]

const optionsData = [
  { label: 'Text', value: 'text', icon: <TextIcon /> },
  { label: 'Multiline', value: 'multiline', icon: <TextIcon /> },
  { label: 'Url', value: 'url', icon: <LinkIcon /> },
  { label: 'Person', value: 'person', icon: <UsersIcon /> },
  { label: 'Single Select', value: 'singleSelect', icon: <SelectIcon /> },
  { label: 'Multi Select', value: 'multiSelect', icon: <SelectIcon /> },
  { label: 'Date', value: 'date', icon: <CalenderIcon /> },
  { label: 'Email Address', value: 'email', icon: <AtIcon /> },
  { label: 'Phone', value: 'phone', icon: <PhoneAlt /> },
  { label: 'Numbers', value: 'numbers', icon: <HashTagIcon /> },
  { label: 'Checkbox', value: 'checkbox', icon: <CheckboxIcon /> },
  { label: 'Attachment', value: 'attachment', icon: <PaperclipIcon /> }
]

export default optionsData
