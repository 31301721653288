import React, { useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import {
  FormLabel,
  LoadingButton,
  PasswordField,
  RoundedTextInput
} from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { catchAsync, storeAccessToken, storeRefreshToken } from 'utils'
import axios from 'axios'
import { fetchMeData } from 'thunks/me/actions'
import { fetchUser } from 'thunks/user/actions'

const LoginModal = ({ open, onClose, afterLoginCallback }) => {
  return (
    <CustomModal open={open} handleClose={onClose} size="extraSmall">
      <CustomModalHeader heading="Login" handleClose={onClose} />
      <CustomModalBody>
        <LoginModalContent
          onClose={onClose}
          afterLoginCallback={afterLoginCallback}
        />
      </CustomModalBody>
    </CustomModal>
  )
}

const LoginModalContent = ({ onClose, afterLoginCallback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    agencyDomain:
      process.env.NODE_ENV === 'development'
        ? 'roni.clientvenue.com'
        : window.location.host
  })
  const [loading, setLoading] = useState(false)

  const formInputHandler = e => {
    setFormData(data => ({ ...data, [e.target.name]: e.target.value }))
  }

  const loginHandler = e => {
    e.preventDefault()
    setLoading(true)
    userLogin({ data: formData }, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        setLoading(false)
      } else {
        dispatch(
          fetchMeData(null, (res2, err) => {
            if (!err) {
              if (afterLoginCallback) afterLoginCallback(res2)
              onClose()
            } else {
              dispatch(fireErrorToaster(res2))
            }
            setLoading(false)
          })
        )
        dispatch(fetchUser())
      }
    })
  }

  return (
    <div>
      <form onSubmit={loginHandler}>
        <div className="mb-6">
          <FormLabel htmlFor="field1">{t('EMAIL_ADDRESS')}</FormLabel>
          <RoundedTextInput
            type="email"
            id="field1"
            placeholder={t('ENTER_YOUR_BUSINESS_EMAIL_ADDRESS')}
            name="email"
            onChange={formInputHandler}
            value={formData.email}
            autocomplete="off"
            // className={classes.inputField}
          />
        </div>

        <div className="mb-6">
          <FormLabel htmlFor="field2">{t('PASSWORD')}</FormLabel>
          <PasswordField
            placeholder={t('ENTER_YOUR_PASSWORD')}
            id="field2"
            name="password"
            onChange={formInputHandler}
            required
            value={formData.password}
            // className={classes.inputField}
            style={{ backgroundColor: 'white' }}
          />
        </div>

        <div className="mt-3 flex justify-between items-center">
          <p className="text-xs font-medium">
            {t('FORGOT_PASSWORD')}{' '}
            <Link to="/forgot-password" className="text-primary-main font-bold">
              {t('CLICK_HERE')}
            </Link>
          </p>
        </div>
        <div className="my-8">
          <LoadingButton loading={loading} type="submit" className="w-full">
            {t('SIGN_IN')}
          </LoadingButton>
        </div>
      </form>
    </div>
  )
}

/**
 * Agency user login action
 * @route `user/login`
 * @param {Object} data User data
 * @param {Function} callback
 */
export const userLogin = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/user/login',
    data: data.data,
    isPublic: true
  })

  storeAccessToken(res.data.token.accessToken)
  storeRefreshToken(res.data.token.refreshToken)
  if (callback) callback(res.data)
})

export default LoginModal
