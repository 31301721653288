import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as ExclamationCircleIcon } from 'static/svg/exclamation-circle.svg'
import PageNotFound from 'components/Opening/PageNotFound'
import { verifyPaymentBySessionId } from 'thunks/agencyPayment/actions'
import { Loader } from 'global/globalComponents'

const PaymentStatus = () => {
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const [paymentStatus, setPaymentStatus] = useState({
    loading: true,
    status: null
  })

  useEffect(() => {
    verifyPaymentBySessionId(
      {
        sessionId: params.get('session_id')
      },
      (res, err) => {
        if (err) {
          setPaymentStatus({ loading: false, status: 'unpaid' })
        } else {
          setPaymentStatus({ loading: false, status: res.status })
        }
      }
    )
  }, [])

  const redirectToDashboard = () => {
    window.location.href = `https://${params.get('host')}`
  }

  const redirectToPlans = () => {
    history.push('/plans')
  }

  if (paymentStatus.loading) return <Loader fullScreen={true} />

  return (
    <div
      className="w-11/12 h-screen mx-auto flex justify-center items-center"
      style={{ maxWidth: 900 }}
    >
      {paymentStatus.status === 'paid' ? (
        <PaymentSuccess redirectToDashboard={redirectToDashboard} />
      ) : paymentStatus.status === 'unpaid' ? (
        <PaymentFail redirectToPlans={redirectToPlans} />
      ) : (
        <PageNotFound />
      )}
    </div>
  )
}

const PaymentSuccess = ({ redirectToDashboard }) => {
  return (
    <div className="flex-1 flex justify-between">
      <div className="max-w-lg mr-4">
        <h1 className="text-3xl font-bold text-green-500 mb-2">
          Subscription Successful!
        </h1>
        <p className="text-gray-700 mb-6 text-sm">
          Thank you for choosing Clientvenue. Your subscription has been
          successfully activated.
        </p>
        <button
          onClick={redirectToDashboard}
          className="px-4 py-2 text-sm bg-green-500 text-white rounded font-bold hover:shadow-md transition-all"
        >
          Go to Dashboard
        </button>
      </div>
      <div>
        <CheckCircleIcon className="w-28 h-28 text-green-500" />
      </div>
    </div>
  )
}

const PaymentFail = ({ redirectToPlans }) => {
  return (
    <div className="flex-1 flex justify-between">
      <div className="max-w-lg mr-4">
        <h1 className="text-3xl font-bold text-red-500 mb-2">
          Subscription Unsuccessful!
        </h1>
        <p className="text-gray-700 mb-6 text-sm">
          Thank you for choosing Clientvenue. Unfortunately, there was an issue
          with your payment method please try again or contact support.
        </p>
        <button
          onClick={redirectToPlans}
          className="px-4 py-2 text-sm bg-red-500 text-white rounded font-bold hover:shadow-md transition-all"
        >
          Go Back
        </button>
      </div>
      <div>
        <ExclamationCircleIcon className="w-28 h-28 text-red-500" />
      </div>
    </div>
  )
}

export default PaymentStatus
