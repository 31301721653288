import React, { useEffect, useMemo, useState } from 'react'
import { BigToggle } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgencyPlan } from 'thunks/agencyPlan/actions'
import { agencyPayment, createFreePlan } from 'thunks/agencyPayment/actions'
import { loadStripe } from '@stripe/stripe-js'
import { useHistory } from 'react-router-dom'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import useTitle from 'customHooks/useTitle'
import { Clientvenue_logo } from 'static/Images/index'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import PlansCard2 from './PlanCard2'
import { CV_HOST } from 'constants/constantKeys'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const AgencyPlanCards2 = ({ planId, planExpired, activePlanId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const agencyPlans = useSelector(state => state.agencyPlans)
  const me = useSelector(state => state.me)
  const [loading, setLoading] = useState(null)
  const history = useHistory()
  const [currentPlanDuration, setCurrentPlanDuration] = useState('month')

  const plansData = useMemo(() => {
    const mappedData = agencyPlans.data
      .filter(plan => !plan.tiers)
      .sort((a, b) => a.amount - b.amount)
    // // .filter(plan => plan.nickname.toLowerCase() !== 'free')
    // .map(plan => ({
    //   ...plan,
    //   order:
    //     plan.nickname.toLowerCase() === 'starter'
    //       ? 0
    //       : plan.nickname.toLowerCase() === 'professional'
    //       ? 1
    //       : 2
    // }))
    // .sort((a, b) => a.order - b.order)

    return {
      month: mappedData.filter(item => item.interval === 'month'),
      year: mappedData.filter(item => item.interval === 'year')
    }
  }, [agencyPlans.data])

  const handleDurationToggle = (e, duration) => {
    setCurrentPlanDuration(duration)
  }

  useTitle('ClientVenue | Checkout | Select Plan', Clientvenue_logo)

  useEffect(() => {
    dispatch(fetchAgencyPlan())
  }, [])

  useEffect(() => {
    if (planId && agencyPlans.data.length) {
      const plan = agencyPlans.data.find(item => item.id === planId)

      if (plan) {
        selectPlanHandler(plan)
      }
    }
  }, [planId, agencyPlans.data])

  const selectPlanHandler = plan => {
    const headers = {}

    if (window.location.host === CV_HOST) {
      let url_string = window.location.href
      let url = new URL(url_string)
      let accessToken =
        url.searchParams.get('access_token') ||
        localStorage.getItem('tempAccessToken')

      if (accessToken) {
        localStorage.setItem('tempAccessToken', accessToken)
        headers['Authorization'] = `Bearer ${accessToken}`
      } else {
        history.push('/login/domain')
        return
      }
    }

    setLoading(plan.id)

    if (plan.amount === 0) {
      const freePlanBody = {
        email: me.data.email,
        plan: {
          id: 'id_1234',
          amount: 0,
          amount_decimal: '0',
          currency: 'usd',
          metadata: {
            clientQuota: '20',
            serviceQuota: '10',
            storageQuota: '2',
            teammateQuota: '1'
          },
          nickname: 'Free Plan'
        },
        quantity: 1,
        amount: 0
      }

      createFreePlan(
        {
          body: freePlanBody
        },
        (res, err) => {
          if (err) {
            setLoading(false)
            dispatch(fireErrorToaster(t(res)))
          } else {
            window.open(`https://${res.agencyDomain}${res.path}`, '_self')
          }
        }
      )
    } else {
      agencyPayment(
        {
          body: {
            email: me.data.email,
            plan,
            quantity: 1,
            amount: plan.amount
          },
          headers
        },
        selectPlanHandlerCallback
      )
    }
  }

  const selectPlanHandlerCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(t(res)))
    } else {
      const stripe = await stripePromise
      await stripe.redirectToCheckout({
        sessionId: res.id
      })
    }
  }

  const getBtnText = plan => {
    let btnText = 'Start Trial'

    if (planExpired) {
      btnText = plan.id === activePlanId ? 'Selected' : 'Select'
    }
    return btnText
  }

  return (
    <>
      <div className="text-center">
        <BigToggle
          onChange={handleDurationToggle}
          value={currentPlanDuration}
        />
        <p className="text-tiny text-gray-500">
          Get 2 Months FREE with annual billing
        </p>
      </div>

      <div className="flex justify-center flex-wrap mt-12 gap-6">
        {agencyPlans.loading ? (
          <LoadingAnimation />
        ) : (
          plansData[currentPlanDuration].map(plan => (
            <PlansCard2
              key={plan.id}
              id={plan.id}
              plan={plan}
              title={plan.nickname}
              metadata={plan.metadata}
              amount={plan.amount / 100}
              currency={plan.currency}
              interval={plan.interval}
              selectPlanHandler={() => selectPlanHandler(plan)}
              loading={loading === plan.id}
              btnText={getBtnText(plan)}
            />
          ))
        )}
      </div>
    </>
  )
}

const LoadingAnimation = () => {
  return (
    <>
      {new Array(3).fill('').map((item, index) => (
        <div key={index} className="w-full" style={{ maxWidth: 375 }}>
          <Skeleton variant="rect" style={{ height: 400 }} />
        </div>
      ))}
    </>
  )
}

export default AgencyPlanCards2
