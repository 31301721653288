import React from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as IndentDecrease } from 'static/svg/indent-decrease.svg'
import { ReactComponent as IndentIncrease } from 'static/svg/indent-increase.svg'
import { ReactComponent as QuestionCircleIcon } from 'static/svg/question-circle.svg'
import { toggleSidebar } from 'thunks/generlAppState/actions'
import SidebarCustomTooltip from './SidebarCustomTooltip'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { closeChatMessenger, openChatMessenger } from 'utils'

const VISIBLE = 'visible',
  HIDDEN = 'hidden',
  LIVE_CHAT_HIDDEN_KEY = 'hidden'

const SidebarFooter = ({ isDrawerOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDrawer = () => {
    dispatch(toggleSidebar())
  }

  const toggleChatBox = () => {
    if (!window.$crisp && !window.LiveChatWidget) return
    let visibility = null

    // ===== handling crisp =====
    if (window.$crisp) {
      if (window.$crisp?.is('chat:opened')) visibility = VISIBLE
      else visibility = HIDDEN
    }

    // ===== Handling LiveChat =====
    if (window.LiveChatWidget) {
      if (
        window.LiveChatWidget.get('state').visibility === LIVE_CHAT_HIDDEN_KEY
      )
        visibility = HIDDEN
      else visibility = VISIBLE
    }

    if (visibility === VISIBLE) closeChatMessenger()
    else if (visibility === HIDDEN) openChatMessenger()
  }

  return (
    <div
      className={clsx(
        'mt-auto flex justify-between px-4 border-t pt-2 border-gray-300 dark:border-dark-main3',
        isDrawerOpen ? 'flex-row' : 'flex-col'
      )}
    >
      <div className={clsx(!isDrawerOpen && 'my-1')}>
        <SidebarCustomTooltip title={t('HELP')} placement="top">
          <button
            id="sidebarFooterFeedbackBtn"
            // onClick={() => dispatch(openUserFeedbackPopper())}
            onClick={toggleChatBox}
            className="inline-flex items-center text-gray-700 dark:text-dark-light hover:text-primary-main"
          >
            <QuestionCircleIcon />
            {isDrawerOpen && (
              <span className="ml-1 text-smaller font-medium">{t('HELP')}</span>
            )}
          </button>
        </SidebarCustomTooltip>
      </div>
      {/* {(meData.role === userRoles.USER_AGENCY ||
        meData.role === userRoles.AGENCY_ADMIN) && (
        <div className={clsx(!isDrawerOpen && 'my-1')}>
          <SidebarCustomTooltip title={t('HELP')}>
            <button
              id="sidebarFooterFeedbackBtn"
              // onClick={() => dispatch(openUserFeedbackPopper())}
              onClick={toggleChatBox}
              className="inline-flex items-center text-gray-700 dark:text-dark-light hover:text-primary-main"
            >
              <QuestionCircleIcon />
              {isDrawerOpen && (
                <span className="ml-1 text-sm">{t('HELP')}</span>
              )}
            </button>
          </SidebarCustomTooltip>
        </div>
      )} */}

      <div className={clsx(!isDrawerOpen && 'my-1')}>
        <SidebarCustomTooltip
          title={isDrawerOpen ? t('COLLAPSE_SIDEBAR') : t('EXPAND_SIDEBAR')}
        >
          <button onClick={handleDrawer} id="sidebarFooterToggleBtn">
            {isDrawerOpen ? (
              <IndentDecrease className="cursor-pointer hover:text-primary-main rounded-md dark:text-dark-light" />
            ) : (
              <IndentIncrease className="cursor-pointer hover:text-primary-main rounded-md dark:text-dark-light" />
            )}
          </button>
        </SidebarCustomTooltip>
      </div>
    </div>
  )
}

export default SidebarFooter
