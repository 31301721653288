import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDistance, subDays } from 'date-fns'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import {
  getFileType,
  getQuillHTML,
  userRoles,
  getFileIcon
  // downloadFile
} from 'utils'
// import workspaceStyles from '../../../workspaceStyles'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import { ReactComponent as EditIcon } from 'static/svg/edit.svg'
import { ReactComponent as PinIcon } from 'static/svg/pin.svg'
import { ReactComponent as LikeIcon } from 'static/svg/thumbs-up.svg'
import { ReactComponent as LikeIconFilled } from 'static/svg/thumbs-up-filled.svg'
import {
  deleteComment,
  updateComment,
  toggleLikeComment,
  removeComment
} from 'thunks/logs/action'
// import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import Pill from 'global/globalComponents/Pill/Pill'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import {
  CustomTooltip,
  FilePreview,
  Loader,
  PopupMenu
} from 'global/globalComponents'
import clsx from 'clsx'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import EditCommentEditor from 'components/Editors/EditCommentEditor/EditCommentEditor'
// import { assignUserToWorkspace } from 'thunks/workspace/actions'

const getLikedByStr = (users, meId) => {
  let strArr = users
    .sort(item => (item._id === meId ? -1 : 1))
    .map((item, index, arr) => {
      let name = item._id === meId ? 'You ' : item.name + ' '

      if (arr.length === 1) return name
      if (arr.length === 2 && index === 0) return name + 'and '
      return `${name}${
        arr.length - 2 === index ? 'and ' : arr.length - 1 === index ? '' : ', '
      }`
    })

  return strArr.join('') + 'liked this'
}

const Comments = ({ comments, elmFor, isLoading, collaborators }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const { pathname, hash, search } = useLocation()
  const meData = useSelector(state => state.me.data)
  const [filePreviewModal, setFilePreviewModal] = useState({
    open: false,
    files: [],
    fileIndex: 0
  })

  const users = useMemo(() => {
    return collaborators.map(item => ({
      //for quill mention
      id: item.user?._id,
      value: item.user?.name,
      //for backend and frontend
      _id: item.user?._id,
      name: item.user?.name,
      profileImage: item.user?.profileImage,
      role: item.role
    }))
  }, [collaborators.length])

  const deleteCommentById = id => {
    deleteComment({ id }, (res, err) => {
      if (!err) dispatch(removeComment(res))
    })
  }

  const handleOpenPreview = (fileIndex, allFiles) => {
    if (filePreviewModal.open) {
      setFilePreviewModal(prev => ({ ...prev, open: false }))
    } else {
      const files = allFiles.map(fileUrl => {
        const file = {}

        const arr = fileUrl.split('/')
        file.name = arr[arr.length - 1]
        file.type = getFileType(fileUrl)
        file.url = fileUrl
        file.extension = fileUrl.slice(fileUrl.lastIndexOf('.'))

        return file
      })

      setFilePreviewModal({ open: true, files, fileIndex })
    }
  }

  const downloadFile = e => {
    downloadFile({ url: e.currentTarget.dataset.url }, (res, err) => {
      if (err) dispatch(fireErrorToaster(res))
    })
  }

  const handleRemoveHash = () => {
    history.push(pathname + search) // not adding hash
  }

  const handlePinComment = (commentId, value) => {
    dispatch(
      updateComment({ id: commentId, elmFor, data: { isPinned: value } })
    )
  }

  return (
    <div className={clsx('pb-8', classes.commentsContainer)}>
      {isLoading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : comments.length ? (
        <>
          {comments
            .filter(item => item.isPinned)
            .map(item => (
              <Comment
                key={item._id}
                comment={item}
                deleteOneComment={deleteCommentById}
                handleOpenPreview={handleOpenPreview}
                downloadFile={downloadFile}
                elmFor={elmFor}
                isHighlighted={hash.slice(1) === item._id}
                handleRemoveHash={handleRemoveHash}
                meId={meData._id}
                meRole={meData.role}
                handlePinComment={handlePinComment}
                classes={classes}
                users={users}
                toTop={true}
              />
            ))}

          {comments.map(item => (
            <Comment
              key={item._id}
              comment={item}
              deleteOneComment={deleteCommentById}
              handleOpenPreview={handleOpenPreview}
              downloadFile={downloadFile}
              elmFor={elmFor}
              isHighlighted={hash.slice(1) === item._id}
              handleRemoveHash={handleRemoveHash}
              meId={meData._id}
              meRole={meData.role}
              handlePinComment={handlePinComment}
              classes={classes}
              users={users}
            />
          ))}

          <FilePreview
            open={filePreviewModal.open}
            files={filePreviewModal.files}
            fileIndex={filePreviewModal.fileIndex}
            onClose={handleOpenPreview}
          />
        </>
      ) : (
        <p className="text-sm text-gray-600 py-2 text-center dark:text-dark-light">
          {t('NO_COMMENTS_FOUND')} :(
        </p>
      )}
    </div>
  )
}

const Comment = ({
  comment,
  deleteOneComment,
  handleOpenPreview,
  downloadFile,
  elmFor,
  isHighlighted,
  handleRemoveHash,
  meId,
  meRole,
  handlePinComment,
  classes,
  users,
  toTop
}) => {
  const dispatch = useDispatch()
  const ref = useRef()
  const [edit, setEdit] = useState(false)
  const [highlight, setHighlight] = useState(isHighlighted)
  const [likedBy, setLikedBy] = useState([])
  const likedByMe = likedBy.find(item => item._id === meId)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (comment.likedBy) {
      setLikedBy(
        comment.likedBy.map(userId => {
          let user = users.find(item => item._id === userId)
          return { _id: user?._id, name: user?.name ?? 'deleted user' }
        })
      )
    }
  }, [users, comment.likedBy])

  useEffect(() => {
    if (isHighlighted && ref.current) {
      setTimeout(() => ref.current.scrollIntoView({ behavior: 'smooth' }))
      setTimeout(() => {
        handleRemoveHash()
        setHighlight(false)
      }, 6000)
    }
  }, [isHighlighted])

  useEffect(() => {
    let arr = []

    if (meId === comment.user?._id) {
      arr = arr.concat([
        {
          label: 'Edit',
          icon: <EditIcon />,
          method: toggleEdit
        },
        {
          label: comment.isPinned ? 'Unpin' : 'Pin to top',
          icon: <PinIcon />,
          method: () => handlePinComment(comment._id, !comment.isPinned)
        },
        {
          label: 'Delete',
          icon: <TrashIcon />,
          method: () => deleteOneComment(comment._id),
          isDanger: true
        }
      ])
    } else {
      arr.push({
        label: comment.isPinned ? 'Unpin' : 'Pin to top',
        icon: <PinIcon />,
        method: () => handlePinComment(comment._id, !comment.isPinned)
      })
    }

    setOptions(arr)
  }, [comment.isPinned])

  const handleUpdateComment = data => {
    dispatch(
      updateComment({ id: comment._id, elmFor, data }, (res, err) => {
        if (err) dispatch(fireErrorToaster(res))
        else setEdit(false)
      })
    )
  }

  const toggleEdit = () => {
    setEdit(prev => !prev)
  }

  const handleLikeComment = () => {
    dispatch(
      toggleLikeComment({
        comment,
        action: likedByMe ? 'unlike' : 'like',
        elmFor
      })
    )
  }

  if (meRole >= userRoles.USER_CLIENT && !comment.isExternal) return null

  return (
    <div
      className={clsx(
        'relative flex justify-center space-x-3 px-8 py-4',
        highlight && 'bg-blue-100 dark:bg-dark-main2',
        comment.isPinned &&
          (toTop ? classes.pinnedCommentToTop : classes.pinnedComment)
      )}
      id={comment._id}
      ref={ref}
    >
      <ColoredAvatars
        alt=""
        user={comment.user || { name: 'unknown' }}
        tooltip={false}
      />

      {edit ? (
        <EditCommentEditor
          users={users}
          comment={comment}
          handleCancel={toggleEdit}
          handleUpdateComment={handleUpdateComment}
        />
      ) : (
        <div className="min-w-0 flex-1 rounded-lg">
          <div className="text-sm flex items-center justify-between mb-1">
            <div className="text-sm flex items-center space-x-2">
              <span className="font-semibold text-sm dark:text-dark-light">
                {!comment.user?.isActive && comment.user?.isDeleted
                  ? `${comment.user?.name} (inactive)`
                  : comment.user?.name ?? 'Deleted user'}
              </span>

              {!comment.isExternal && (
                <span>
                  <Pill variant="red">{t('INTERNAL')}</Pill>
                </span>
              )}

              <p className="text-gray-500 text-xs dark:text-dark-light">
                {formatDistance(
                  subDays(new Date(comment.createdAt), 0),
                  new Date(),
                  {
                    addSuffix: true
                  }
                )}
              </p>
            </div>

            <div className="flex items-center">
              {comment.isPinned && (
                <CustomTooltip title="Pinned" placement="top">
                  <PinIcon className="rotate-45 mr-4 text-primary-main" />
                </CustomTooltip>
              )}

              <CustomTooltip
                title={likedBy.length ? getLikedByStr(likedBy, meId) : 'Like'}
                placement="top"
              >
                <button
                  className="mr-2 text-primary-main flex items-center"
                  onClick={handleLikeComment}
                >
                  <span className="text-xs text-primary-main mr-1">
                    {Boolean(likedBy.length) && likedBy.length}
                  </span>
                  {likedByMe ? <LikeIconFilled /> : <LikeIcon />}
                </button>
              </CustomTooltip>

              <PopupMenu
                targetComp={
                  <button className="text-lg rotate-90 dark:text-dark-light">
                    <ChevronRight />
                  </button>
                }
                menuItems={options}
              />
            </div>
          </div>

          <div
            className="text-sm text-gray-700 dark:text-dark-light2 break-long-word"
            dangerouslySetInnerHTML={{
              __html: getQuillHTML(comment?.description, 'html')
            }}
          ></div>

          <div className="py-2 flex flex-wrap">
            {comment.attachment?.map((url, index) => (
              <CommentAttachment
                key={index}
                url={url}
                handleOpenPreview={() =>
                  handleOpenPreview(index, comment.attachment)
                }
                downloadFile={downloadFile}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const CommentAttachment = ({ url, handleOpenPreview, downloadFile }) => {
  const fileType = getFileType(url)
  const fileName = url.slice(url.lastIndexOf('/') + 1)

  return (
    <>
      {fileType === 'image' ? (
        <div className="mr-2 mt-2">
          <CustomTooltip title={fileName} placement="top">
            <img
              src={url}
              className="h-36 rounded-lg border cursor-pointer"
              alt={fileName}
              onClick={handleOpenPreview}
              data-url={url}
            />
          </CustomTooltip>
          {/* <div className="text-xs flex items-center">
            <div className="w-28 truncate">{fileName}</div>
            <div className="hover:text-blue-500 cursor-pointer ml-2">
              Download
            </div>
          </div> */}
        </div>
      ) : (
        <div className="w-full flex border rounded-lg items-center p-4 bg-white mt-2 mr-2">
          <div className="text-2xl mr-4">{getFileIcon(url)}</div>
          <div>
            <div
              onClick={handleOpenPreview}
              data-url={url}
              className="text-sm font-medium text-gray-700 dark:text-gray-400 hover:text-blue-500 cursor-pointer"
            >
              {fileName}
            </div>
            <div className="text-xs flex items-center">
              <span className="capitalize mr-1">{fileType} file</span>
              <span className="w-1 h-1 rounded-full bg-gray-500 mr-1"></span>
              <button
                className="hover:text-blue-500"
                data-url={url}
                onClick={downloadFile}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  commentsContainer: {
    '& a': {
      color: 'blue',
      textDecoration: 'underline'
    },
    '& .emoji': {
      fontSize: 20
    },
    '& p': {
      color: theme.palette.type === 'dark' ? '#fff' : '#000'
    }
  },
  pinnedComment: {
    borderLeft: '4px solid #ffbe47'
  },
  pinnedCommentToTop: {
    background:
      theme.palette.type === 'dark' ? theme.custom.darkMode.dark2 : '#ffe8bd',
    borderLeft: '4px solid #ffbe47'
  }
}))
export default React.memo(Comments)
